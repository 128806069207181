import { DateEx } from '../Utilities/DateEx';
import { DateResolution, FreezePeriod } from '../api/generated/data-contracts';
import { resolutionHelper } from './resolutionHelper';

const getMinDateBoundary = (resolution: DateResolution, freezePeriod?: FreezePeriod, useISO8601?: boolean): Date =>
    freezePeriod == null || freezePeriod.freezeBeforeTodayOnly
        ? DateEx.todaysDate()
        : resolutionHelper.getStartUsingFreezePeriod(freezePeriod, resolution, useISO8601);

const validateStart = (startDate: Date, resolution: DateResolution, freezePeriod?: FreezePeriod, useISO8601?: boolean): boolean =>
    startDate >= getMinDateBoundary(resolution, freezePeriod, useISO8601);

const createRestrictionFilter =
    (resolution: DateResolution, type: 'first' | 'last') =>
    (date: Date): boolean => {
        if (type === 'first') {
            switch (resolution) {
                case DateResolution.Year:
                    return !(date.getMonth() === 0 && date.getDate() === 1);
                case DateResolution.Quarter:
                    return DateEx.getFirstDayOfQuarter(date).getTime() !== date.getTime();
                case DateResolution.Month:
                    return date.getDate() !== 1;
                case DateResolution.Week:
                    return DateEx.firstWeekDay() !== date.getDay();
                default:
                    return true;
            }
        } else {
            switch (resolution) {
                case DateResolution.Year:
                    return !(date.getMonth() === 11 && date.getDate() === 31);
                case DateResolution.Quarter:
                    return DateEx.lastDayOfQuarter(date).getTime() !== date.getTime();
                case DateResolution.Month:
                    return DateEx.lastDayOfMonth(date).getTime() !== date.getTime();
                case DateResolution.Week:
                    return DateEx.lastWeekDay() !== date.getDay();
                default:
                    return true;
            }
        }
    };

const getRestrictedDates = (resolution: DateResolution, type: 'first' | 'last', freezePeriod?: FreezePeriod): Date[] => {
    const restrict = createRestrictionFilter(resolution, type);
    const start = getMinDateBoundary(resolution, freezePeriod);
    const end = DateEx.addYear(start, 5);
    const restrictedDates: Date[] = [];

    for (let loop = new Date(start); loop <= end; loop.setDate(loop.getDate() + 1)) {
        if (restrict(loop)) restrictedDates.push(new Date(loop));
    }
    return restrictedDates;
};

export const datePickerHelper = {
    getMinDateBoundary,
    validateStart,
    getRestrictedDates,
};
