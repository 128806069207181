import { PropertyType } from "../../../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyBasicInfo } from "../../../../Utilities/PropertyInfo/BasicInfo";
import { IObjectEditorItemType } from "../../../Main/IObjectEditorItemType";

export class LookupProject implements IObjectEditorItemType {
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:LookupProject.IncludeInactiveProjects", 1)
	public IncludeInactive: boolean;
	// @PFPropertyBasicInfo(PropertyType.Boolean, "OnlyFromOwn", 3)
	// public OnlyFromOwn: boolean;

	constructor() {

	}
}
