/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-refresh/only-export-components */
import { IContextualMenuItem, IconButton } from '@fluentui/react';
import { WithStore } from '../../Services/StateStore';
import { StoreProps } from '../../Services/StoreProps';
import { GridType } from '../../Entities/GridType';
import { RowMenuItemInfo } from '../../Entities/Dto/RowMenuItemInfo';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { ResourceType } from '../../Entities/ResourceType';
import { language } from '../../Services/LocalizationService';
import { useStore } from '../../context/store';
import { noopFunction } from '../../helpers/functionPrimitives';
import { ApiCalls } from '../../api/api';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { CONTRACT_EXISTS } from '../../context/network/http/QueryProvider/queryKeys';
import { IBlockContent } from '../../context/contexts/pageBlockContext/pageBlockContext';

interface IProps extends StoreProps {
    ResourceId: string;
    ContractId: string;
    GridType: GridType;
    ResourceType: ResourceType;
    IsRequested: boolean;
    AllProperties: any;
    IsActive: boolean;
    ResourceIsInaccessible: boolean;
    RefreshCallback?: () => void;
}

const ResourceRowMenu: React.FunctionComponent<IProps> = ({ ResourceId: resourceId, ContractId: contractId, GridType: gridType, ResourceType: resourceType, IsRequested: isRequested, AllProperties: allProperties, IsActive: isActive, ResourceIsInaccessible: resourceIsInaccessible, RefreshCallback: refreshCallback, Store: store }) => {
    const { error, success, timeline, setBlockDialog } = useStore(store => ({
        error: store.addErrorNotification,
        success: store.addSuccesNotification,
        timeline: store.timeline,
        setBlockDialog: store.setBlockDialog,
    }));

	const {
        data: contractExists,
        refetch: refetchContractExists,
        isStale: isContractExistsStale,
    } = useQuery({
        queryKey: [CONTRACT_EXISTS, contractId],
        queryFn: () => ApiCalls.contractExists(contractId).then(res => res.data),
        staleTime: 5000,
        enabled: false,
    });

    const openDialog = useCallback((content: JSX.Element, options: Omit<IBlockContent, "content">) => {	
        setBlockDialog(content, options);
    }, [setBlockDialog]);

    const menuItems = useMemo(
        () =>
            getMenuItems({
				contractId,
				gridType,
				resourceIsInaccessible,
				allProperties,
				isActive,
				isRequested,
				contractExists,
				refreshCallback,
				resourceId,
				resourceType,
				store,
				openDialog,
				timeline,
				success,
				error
            }),
        [contractId, gridType, resourceIsInaccessible, allProperties, isActive, isRequested, contractExists, refreshCallback, resourceId, resourceType, store, openDialog, timeline, success, error],
    );

    return (
        <IconButton
            className="tp-grid-rowmenu-icon"
            iconProps={{ iconName: 'More' }}
			disabled={!menuItems?.length}
			title={!menuItems?.length ? 'No menu items to show' : undefined}
            menuProps={{
                items: menuItems,
                onMenuOpened: () => {
                    if (isContractExistsStale) {
                        refetchContractExists();
                    }
                },
            }}
        />
    );
};

// generate menuItems for the contract row menu
const getMenuItems = ({
    contractId,
	resourceId,
    gridType,
	timeline,
    isRequested,
	resourceType,
    allProperties,
	contractExists,
    store,
	isActive,
	resourceIsInaccessible,
	openDialog,
	refreshCallback,
	success,
	error
}): IContextualMenuItem[] => {
	if (!isActive) {
		if (gridType === GridType.Allocation && !isRequested) {
			return [
				{
					key: 'deletefuture',
					text: language.ResourceRowMenu.RemoveFutureAllocations.text,
					title: contractExists ? language.ResourceRowMenu.RemoveFutureAllocations.title : 'Contract does not exist yet',
					disabled: !contractExists,
					iconProps: { iconName: 'Delete' },
					onClick: () => {
						openDialog(null, {
							title: language.ResourceRowMenu.RemoveFutureAllocations.dialogTitle,
							subText: language.ResourceRowMenu.RemoveFutureAllocations.dialogSubText,
							dismissEnable: false,
							isBlocking: false,
							buttons: [
								{
									text: language.Common.Ok,
									type: 'primary',
									shouldDismiss: true,
									onClick: async () => {
										try {
											await ApiCalls.removeFutureAllocations({
												ContractId: contractId,
												ResourceId: resourceId,
												Resolution: timeline.resolution,
											});
											refreshCallback();
											success(language.ResourceRowMenu.RemoveFutureAllocations.success);
										} catch (err) {
											error(language.ResourceRowMenu.RemoveFutureAllocations.error);
										}
									},
								},
								{
									text: language.Common.Cancel,
									type: 'default',
									shouldDismiss: true,
									onClick: noopFunction,
								},
							],
						});
					},
				},
			];
		}
	} else {
		if (gridType === GridType.Allocation && isRequested && OverviewContext.Settings.EnableForwardRequest)
			return [
				{
					key: 'forward',
					text: language.ResourceRowMenu.ForwardRequest.text,
					title: contractExists ? language.ResourceRowMenu.ForwardRequest.title : 'Contract does not exist yet',
					disabled: !contractExists,
					iconProps: { iconName: 'Share' },
					onClick: () => {
						const info = new RowMenuItemInfo(contractId, resourceId, gridType, null);
						store.Set({ ShowForwardRequestDialog: true, ForwardRequestInfo: info });
					},
				},
			];
		else if (gridType === GridType.Request && isRequested && OverviewContext.Settings.EnableMoveRequest)
			return [
				{
					key: 'move',
					text: language.ResourceRowMenu.MoveRequest.text,
					title: contractExists ? language.ResourceRowMenu.MoveRequest.title : 'Contract does not exist yet',
					disabled: !contractExists,
					iconProps: { iconName: 'Timeline' },
					onClick: () => {
						const info = new RowMenuItemInfo(contractId, resourceId, gridType);
						store.Set({ ShowMoveRequestDialog: true, MoveRequestInfo: info });
					},
				},
			];
		else if (
			gridType === GridType.Allocation &&
			(resourceType === ResourceType.Named || resourceType === ResourceType.Team || resourceType === ResourceType.Category) &&
			!allProperties.IsRequested &&
			OverviewContext.Settings.EnableCreateMatchingAllocation &&
			!resourceIsInaccessible
		) {
			allProperties.MatchType = 1;
			return [
				{
					key: 'matchingallocation',
					text: language.ResourceRowMenu.MatchRequest.text,
					title: 	contractExists ? language.ResourceRowMenu.MatchRequest.title : 'Contract does not exist yet',
					disabled: !contractExists || allProperties.IsUnknown,
					iconProps: { iconName: 'Compare' },
					onClick: () => {
						const info = new RowMenuItemInfo(contractId, resourceId, gridType, allProperties);
						store.Set({ ShowMatchingActivityDialog: true, MatchingActivityInfo: info });
					},
				},
			];
		}
	}
}

export default WithStore<IProps>(ResourceRowMenu);
