import Big from "big.js";
import { createContext } from "../../../../../../hooks/createContext";
import { ReactDOMAttributes } from "@use-gesture/react/dist/declarations/src/types";
import { atom, useSetRecoilState } from "recoil";
import { GridCell } from "../../../../../../api/generated/data-contracts";
import { UpdateCellInfo } from "../../../../_grid/contexts/gridContext";
import { useGridCellValue } from "../../../../_grid/useGrid";
import { memo, useCallback, useLayoutEffect, useMemo } from "react";
import { mapMap } from "../../../../_grid/helpers/map";

export type DragContext = {
	bindDragHandler: (value: Big, inputRef: React.MutableRefObject<HTMLInputElement>) => ReactDOMAttributes;
	dragState: React.MutableRefObject<boolean>
}

const [useCtx, Provider] = createContext<DragContext>();

export const useDragContext = (props: useDragGridCellProps) => {
	useHandleDraggableCellRegistration(props)
	return useCtx()
}
export const DragContextProvider = memo(Provider);

type DraggableCellEntry = {
    cell: GridCell;
    restoreStoreValue: () => void;
    setLocalCellValue: (val: string) => void;
    onError?: (err: string) => void;
} & Pick<UpdateCellInfo, 'cellIndex' | 'columnIndex' | 'rowId'>;

type DraggableCellRegitry = Map<string, DraggableCellEntry>;

export const renderedCellsAtom = atom<DraggableCellRegitry>({
    key: 'rendered-cell-registry',
    default: new Map(),
});


type useDragGridCellProps = Omit<DraggableCellEntry, 'restoreStoreValue'>
const useHandleDraggableCellRegistration = ({
    cell,
    setLocalCellValue,
    cellIndex,
    columnIndex,
    rowId,
    onError,
}: useDragGridCellProps) => {
    const setCellRegistry = useSetRecoilState(renderedCellsAtom);
    const storeValue = useGridCellValue(cell);
    const restoreStoreValue = useCallback(() => {
        setLocalCellValue(storeValue.toString());
    }, [setLocalCellValue, storeValue]);

    const cellEntry = useMemo((): DraggableCellEntry => {
        return {
            cell,
            restoreStoreValue,
            setLocalCellValue,
            cellIndex,
            columnIndex,
            rowId,
            onError,
        };
    }, [cell, cellIndex, columnIndex, rowId, setLocalCellValue, onError, restoreStoreValue]);

    /**
     * We use useLayoutEffect to run all registration specific stuff synchronously with the DOM updates
     */
    useLayoutEffect(() => {
        // Deregister cell on unmount
        return () => {
            setCellRegistry(filterOutCellFromRegistry(cell.id));
        };
    }, [cell.id, setCellRegistry]);

    useLayoutEffect(() => {
        // Register cell when visible
		setCellRegistry(registry => {
			if (registry.has(cell.id)) {
				// Update registration if cellEntry props has changed
				return mapMap(registry, (currentCellEntry, i, cellId) => {
					if (cellId === cell.id) {
						return [cellId, cellEntry];
					}
					return [cellId, currentCellEntry];
				});
			}
			return new Map([...registry, [cell.id, cellEntry]]);
		});
    }, [cell.id, setCellRegistry, setLocalCellValue, cellEntry]);
};

const filterOutCellFromRegistry = (cellId: string) => (registry: DraggableCellRegitry) => new Map([...registry].filter(([id]) => id !== cellId));
