import { useCallback, useMemo } from 'react';
import { DefaultButton, Label, Panel, PanelType, PrimaryButton, Stack, Toggle } from '@fluentui/react';
import { EntityPropertyFilter } from '../../../../../components_new/EntityPropertyFilter';
import { projectSchema } from '../../../../../forms/project/projectSchema';
import { resourceSchema } from '../../../../../forms/resource/resourceSchema';
import { useUISettings } from '../../../../../context/network/http/QueryProvider/queries/UISettings';
import { ResourcePicker } from './ResourcePicker';
import { ProjectPicker } from './ProjectPicker';
import { QueryGroup } from './helpers/createQueryGroup';
import { useControlledState } from '../../../../../hooks/useControlledState';
import { GridViewFilters } from '../../../../../api/generated/data-contracts';
import { isAdvancedQuery } from './helpers/isAdvancedQuery';

export const FilterPanel = ({
    openPanel,
    setOpenPanel,
    onClickSave,
    onDismissPanel,
	initialFilterValue
}: {
    openPanel: boolean;
    setOpenPanel: React.Dispatch<React.SetStateAction<boolean>>;
    onClickSave?: (filters: GridViewFilters) => void | Promise<void>;
    onDismissPanel?: () => void;
	initialFilterValue?: GridViewFilters | null;
}) => {
    const { data: uiSettings } = useUISettings();
    const [projectFilter, setProjectFilter] = useControlledState<QueryGroup | null>(() => initialFilterValue?.projects || null, [initialFilterValue?.projects]);

    const [resourceFilter, setResourceFilter] = useControlledState<QueryGroup | null>(() => initialFilterValue?.resources || null, [initialFilterValue?.resources]);

    const advancedQuery = useMemo(() => {
        return isAdvancedQuery(resourceFilter) || isAdvancedQuery(projectFilter);
    }, [projectFilter, resourceFilter]);

    const [advancedMode, setAdvancedMode] = useControlledState<boolean>(
        currentState => {
            if (advancedQuery) {
                return advancedQuery;
            }
            return currentState || false;
        },
        [advancedQuery],
    );

    const onSave = useCallback(() => {
        if (onClickSave) {
            onClickSave({
                projects: projectFilter,
                resources: resourceFilter,
            });
        }
    }, [onClickSave, projectFilter, resourceFilter]);

    const onCancel = useCallback(() => {
        onDismissPanel();
    }, [onDismissPanel]);

    const onRenderFooterContent = useCallback(() => {
        return (
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                {onClickSave && <PrimaryButton onClick={onSave}>Apply</PrimaryButton>}
                {onDismissPanel && <DefaultButton onClick={onCancel}>{onClickSave ? 'Cancel' : 'Close'}</DefaultButton>}
            </Stack>
        );
    }, [onClickSave, onDismissPanel, onSave, onCancel]);

    return (
        <Panel
            isOpen={openPanel}
            isLightDismiss={true}
            onDismiss={() => setOpenPanel(false)}
            type={PanelType.custom}
            customWidth={'900px'}
            isBlocking={false}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom
            headerText={'Filter editor'}
        >
            <Toggle
                label="Advanced mode"
                inlineLabel
                onChange={(e, checked) => setAdvancedMode(Boolean(checked))}
                disabled={advancedQuery}
                checked={advancedMode}
            />
            {advancedMode ? (
                <>
                    <Label>Project filter</Label>
                    <EntityPropertyFilter
                        item={projectFilter}
                        schema={projectSchema}
                        customProperties={uiSettings.settings.projectCustomProperties}
						onlyTheseProperties={uiSettings.settings.projectPlannerProjectFilterOptions}
                        onChanged={setProjectFilter}
                        entityType={'project'}
                    />
                    <Label>Resource filter</Label>
                    <EntityPropertyFilter
                        item={resourceFilter}
                        schema={resourceSchema}
                        customProperties={uiSettings.settings.resourceCustomProperties}
						onlyTheseProperties={uiSettings.settings.projectPlannerResourceFilterOptions}
                        onChanged={setResourceFilter}
                        entityType={'resource'}
                    />
                </>
            ) : (
                <>
                    <Label>Pick projects</Label>
                    <ProjectPicker onFilterChange={setProjectFilter} queryGroup={projectFilter} />
                    <Label>Pick resources</Label>
                    <ResourcePicker onFilterChange={setResourceFilter} queryGroup={resourceFilter} />
                </>
            )}
        </Panel>
    );
};


