import React, { FunctionComponent, useRef, useState, useEffect, useCallback } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { DateCell } from '../../../Components/Overview/Timeline/DateCell';
import { TimeLineCell } from '../../../Components/Overview/Timeline/TimeLineCell';
import { UnitType } from '../../../Entities/UnitType';
import { WorkPackagesTimelineCoachmark } from '../../../userGuidance/areas/WorkPackagesCoachmarks';
import { TimelineUtil } from '../../../Utilities/TimelineUtil';
import { UnitTypeUtil } from '../../../Utilities/UnitTypeUtil';

interface IProps {
	OnTimeLineChanged: (start: Date, end: Date) => void;
	SelectedStart?: Date;
}

class SelectedIndexes {
	constructor(public Start: number, public End: number) { }
}

// TODO: reuse regular timeline
//   - support for:
//		- not showing cell headers
//			- include css position adjustment
//   	- manual set end date by prop (for when generating cells)
//   	- override backintime-stuff
//   	- other?

// eslint-disable-next-line react-refresh/only-export-components
const WorkPackageTimeline: FunctionComponent<IProps> = ({ OnTimeLineChanged }) => {

	const [cells, setCells] = useState<Array<DateCell>>();

	const [selectedIndexes, setSelectedIndexes] = useState<SelectedIndexes>();

	const _isDragging = useRef<boolean>(false);
	const _isMoving = useRef<boolean>(false);
	const _isMovingRange = useRef<boolean>(false);
	const _movingRangeIndex = useRef<number>();

	const _scrollTimestamp = useRef<number>(0);
	const _moveTimestamp = useRef<number>();

	const _startBoundary = useRef<number>();
	const _endBoundary = useRef<number>();

	const _cellCount = useRef<number>();
	const _viewIndex = useRef<number>(0);

	const _offSet = useRef<number>(0);

	const CellWidth: number = 55;

	const ClearSelected = useCallback((cellArr: Array<DateCell>) => {
		cellArr.forEach(_ => _.Selected = false);
	}, [])

	
	const SetCellWidth = useCallback(() : void => {
		// set boundaries
		_startBoundary.current = 0;
		const cellCount = Math.round(window.screen.width / CellWidth);
		_cellCount.current = cellCount;
		_endBoundary.current = cellCount;
	}, [CellWidth])

	const TimelineChanged = useCallback((startIndex: number, endIndex: number, data?: Array<DateCell>) => {
		const allCells = data || cells;
		// guard
		if (startIndex < 0) startIndex = 0;
		if (cells != null && endIndex > cells.length - 1) endIndex = cells.length - 1;
		// get dates
		const start = allCells[startIndex].Start;
		const end = allCells[endIndex < allCells.length ? endIndex : allCells.length -1].End;
		// update
		OnTimeLineChanged(start, end);
	}, [OnTimeLineChanged, cells])

	const UpdateSelection = useCallback((startIndex: number, endIndex: number, data?: Array<DateCell>, push?: number) : void => {
		if (push != null && startIndex === 0) {
			startIndex += push;
			endIndex += push;
			document.documentElement.style.setProperty('--wpTimelineOffSet', _offSet.current+"");
		}
		// update ranges
		SetRangeIndexes(startIndex, endIndex);
		// update parent
		TimelineChanged(startIndex, endIndex, data);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [TimelineChanged])

	// did mount
	useEffect(() => {
		// Initialze();
		if (!cells) {
			SetCellWidth()
			const end = GetDefaultColumnCount();
			// const data = LoadData(null, null);
			const dates = { start: new Date(2000,0,1), end: new Date(2000,3,9) };
			const dateArray = TimelineUtil.getDays(dates.start, dates.end);
			const newCells = dateArray.map((_, idx) => new DateCell(_.start, _.end, idx));
			if (_cellCount.current > newCells.length) {
				_cellCount.current = newCells.length;
				_endBoundary.current = newCells.length;
			}
			setCells(newCells);
			UpdateSelection(0, end, newCells, 0);
		}
	}, [SetCellWidth, UpdateSelection, cells]);

	// refresh selection
	const lastSelectedIndexes = useRef<SelectedIndexes | null>(null)
	useEffect(() => {
		// only run this effect if selectedIndexes has changes since last time
		if (lastSelectedIndexes.current !== selectedIndexes) {
			if(!selectedIndexes?.End || !selectedIndexes?.Start)
			if (!cells || selectedIndexes.Start < 0 || selectedIndexes.End < 0) return;
			cells.forEach(_ => _.Selected = false);
	
			const allCells = [...cells];
			ClearSelected(allCells);
	
			const selectedCells = selectedIndexes.End < selectedIndexes.Start
									? cells.filter((el, idx) => idx <= selectedIndexes.Start && idx >= selectedIndexes.End) //reverse select
									: cells.slice(selectedIndexes.Start, selectedIndexes.End + 1); // normal select
	
			allCells.filter(_ => selectedCells.some(sc => sc.Index === _.Index)).forEach(_ => _.Selected = true);
			setCells(allCells);
			lastSelectedIndexes.current = selectedIndexes
		}
	}, [ClearSelected, cells, selectedIndexes])

	

	// refresh resolution
	useEffect(() => {
		// reset offset
		document.documentElement.style.setProperty('--wpTimelineOffSet', "0");
		_viewIndex.current = 0;
		SetCellWidth();
	}, [SetCellWidth]);

	

	

	const SetRangeIndexes = useCallback((start: number, end: number) => {
		setSelectedIndexes(new SelectedIndexes(start, end));
	}, [])

	// const Initialze = () => {
	// 	SetCellWidth()
	// }

	

	const PushSelectedRange = (elementIndex) => {
		const diff = elementIndex - selectedIndexes.Start;
		let newEnd = selectedIndexes.End + diff;
		if (newEnd > cells.length - 1) newEnd = cells.length - 1;		
		// update
		UpdateSelection(elementIndex, newEnd);
	}

	/* window width minus resource column divided by column width */
	const GetDefaultColumnCount = (): number => {
		let columnCount = Math.trunc((window.innerWidth - parseInt(getComputedStyle(document.documentElement).getPropertyValue('--gridRowWidth')) - 10) / 120 ) - 1;
		if (UnitTypeUtil.getCurrentUnitType() !== UnitType.FTE) columnCount--;
		return columnCount;
	}

	const MouseDown = (event) => {
		// right click
		if (IsRightClick(event)) {
			const elementIndex = GetDateCellIndex(event.currentTarget.id) - 1;
			PushSelectedRange(elementIndex);

			event.preventDefault();
			event.stopPropagation();
			return false;
		}

		_isDragging.current = true;
		// handle move
		const index = GetSelectedDateCellIndex(event.currentTarget.id) - 1;
		if (index >= 0 && !event.ctrlKey) {
			_isDragging.current = false;
			_isMovingRange.current = true;
			_movingRangeIndex.current = index;
			return;
		}

		const clickedIndex = GetDateCellIndex(event.currentTarget.id) - 1;
		SetRangeIndexes(clickedIndex, clickedIndex);
	}

	const MouseMove = (event: any) => {
		if (_moveTimestamp.current + 50 > event.timeStamp) return;
		_moveTimestamp.current = event.timeStamp;
		Move(GetDateCellIndex(event.currentTarget.id) - 1);
	}

	const Scroll = (event: React.WheelEvent<any>) => {
		if (_scrollTimestamp.current + 50 > event.timeStamp) return;
		_scrollTimestamp.current = event.timeStamp;

		const offSet = +document.documentElement.style.getPropertyValue('--wpTimelineOffSet');
		let newOffSet = offSet + (event.deltaY > 0 ? 3 : -3);

		if (newOffSet < 0) newOffSet = 0;
		// if (OverviewContext.AllowTimelineBackInTime() && newOffSet >= cells.length - _cellCount.current + 3) {
		// 	newOffSet = cells.length - _cellCount.current + 5;
		// }
		_viewIndex.current = newOffSet;

		document.documentElement.style.setProperty('--wpTimelineOffSet', newOffSet+"");
	}

	const Move = (elementIndex: number) => {
		let newStart: number;
		let newEnd: number;

		// update drag select index
		if (_isDragging.current) {
			_isMoving.current = true;
			newEnd = elementIndex;
			// set selected indexes
			SetRangeIndexes(newStart >= 0 ? newStart : selectedIndexes.Start, newEnd >= 0 ? newEnd : selectedIndexes.End);
		}
		// update selected indexes when dragging range
		if (_isMovingRange.current) {
			_isMoving.current = true;
			
			newStart = selectedIndexes.Start + elementIndex - _movingRangeIndex.current;
			newEnd = selectedIndexes.End + elementIndex - _movingRangeIndex.current;
			_movingRangeIndex.current = elementIndex;
			// set selected indexes
			SetRangeIndexes(newStart, newEnd >= 0 ? newEnd : selectedIndexes.End);
		}
		if (_viewIndex.current >= 0 && newStart < _viewIndex.current && newStart >= 0) {
			const moveCount = 2;
		
			_viewIndex.current -= moveCount;
			
			const offSet = +document.documentElement.style.getPropertyValue('--wpTimelineOffSet');
			document.documentElement.style.setProperty('--wpTimelineOffSet', (offSet - moveCount)+"");
		}
		if (newEnd > (_viewIndex.current + _cellCount.current - 5) && newEnd <= cells.length) {
			const moveCount = 2;
		
			_viewIndex.current += moveCount;
			
			if (newEnd + moveCount <= cells.length) {
				const offSet = +document.documentElement.style.getPropertyValue('--wpTimelineOffSet');
				document.documentElement.style.setProperty('--wpTimelineOffSet', (offSet + moveCount)+"");
			}
		}
	
		if (typeof window.event.preventDefault !== 'undefined') window.event.preventDefault();
	}

	const IsRightClick = (event: any) : boolean => {
		if (event.which) return (event.which === 3);
		else if (event.button) return (event.button === 2);
		return false;
	}

	const MouseUp = () => {
		if (!_isDragging.current && !_isMovingRange.current) return false;

		// single click
		if (!_isMoving.current) {
			SetRangeIndexes(selectedIndexes.Start, selectedIndexes.Start);
		}
			
		_isDragging.current = false;
		_isMoving.current = false;
		_isMovingRange.current = false;

		// reverse select - swap start and end
		if (selectedIndexes.End < selectedIndexes.Start) {
			const oldStart = selectedIndexes.Start;
			// update
			UpdateSelection(selectedIndexes.End, oldStart);
			return;
		}

		document.documentElement.onselectstart = function () {
			return true; 
		};

		// update
		TimelineChanged(selectedIndexes.Start, selectedIndexes.End);
	}

	

	

	const GetSelectedDateCellIndex = (id: string) : number => {
		const cellId = +id.replace("tp-timeline-cell-", "");
		return cells[cellId].Selected ? cellId : -1;
	}

	const GetDateCellIndex = (id: string) : number => {
		return +id.replace("tp-timeline-cell-", "") + 1;
	}

	return 	( 
		<div className="top-wrapper" onMouseUp={ MouseUp} onWheel={Scroll}>
			{ cells &&
				<TransitionGroup>
					<CSSTransition
						timeout={500}
						classNames="messageout"
					>
						<>
							<table className={`tp-workpackages-timeline tp-workpackages-timeline-animation`} >
								<tbody>
									<tr>
										{
											cells.map((_, i) => {
												return 	<td style={ { left:`${ 0 + (i * CellWidth)}px` } } key={`tp-timeline-header-${_}-${i}`}>
															<TimeLineCell CellTitle={(i+1)+""} Selected={_.Selected} MouseDown={MouseDown} MouseMove={MouseMove} Index={i} ClassNameOverride="tp-workpackages-timeline-element" />
														</td>
											})
										}
									</tr>
								</tbody>
							</table>
						<WorkPackagesTimelineCoachmark />
					</>
					</CSSTransition>
				</TransitionGroup>
			}
		</div>
	)
}

const comparisonFn = () => {
	return true;
};

// eslint-disable-next-line react-refresh/only-export-components
export default React.memo(WorkPackageTimeline, comparisonFn);