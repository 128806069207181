import Locale_BaseOverview from "./Overviews.ts/BaseOverview";

export default class Locale_ResourceOverview implements Locale_BaseOverview {
	FailedGettingResources: string = "Failed getting resources";
	UpdatingPleaseWait: string = `Updating... please wait`;
	MissingRelatedGenRes: string = "Saved resource is missing a related generic resource.";
	NewGenericResource: string = `A new generic resource has automatically been created: '[[relatedgenericresourceidasstring]]'`;
	AllocationsMoved: string = `Allocations have been moved from: '[[oldgenresname]]', to: '[[relatedgenericresourceidasstring]]'`;

	OverviewTitle: string = 'Resource overview';
	DialogTitle: string = 'Resource editor';

	UpdatedSingle: string = 'Resource saved!';
	UpdatedMultiple: string = 'Resources saved!';

	DeletedSingle: string = "Resource deleted!";
	DeletedMultiple: string = 'Resources deleted!';

	FailedUpdatingMultiple: string = "Failed updating resources!";
	FailedUpdatingSingle: string = 'Failed saving resource!';
	
	FailedDeletingSingle: string = "Failed deleting resource!";
	FailedDeletingMultiple: string = 'Failed deleting resources!';

	ConfirmDeleteQuestionHeader: string = "Delete";
	ConfirmDeleteQuestionSingle: string = "Are you sure you want to delete this resource";
	ConfirmDeleteQuestionMultiple: string = "Are you sure you want to deletes these resources";
	ConfirmDeleteOk: string = "Yes";
	ConfirmDeleteCancel: string = "Cancel";
	
	SearchPlaceHolder: string = `Search in [[numberofresources]] resources..`;
	MoveAllAllocations: string = 'Move all allocations';
	ConfirmCountCascadingSubtextSingle: string = "ALL the related data for this resource WILL BE DELETED, and cannot be undone!!";
	ConfirmCountCascadingSubtextMultiple: string = "ALL the related data for all these resources WILL BE DELETED, and cannot be undone!!";
	ConfirmCountCascadingTitle: string = "Are you really sure?!";
	ConfirmCascadingSubtextSingle: string = "Do you want to delete the selected resource's related data, as well (requests, allocations, settings etc.)?";
	ConfirmCascadingSubtextMultiple: string = "Do you want to delete all the selected resources' related data, as well (requests, allocations, settings etc.)?";
	ConfirmCascadingTitle: string = "Resource related data";
	ConfirmCascadingOkButton: string = "Delete ALL related data";
	ConfirmCascadingCancelButton: string = "Just delete the resource";

	ConfirmActiveTitle: string = "Are you sure you want to activate this resource?";
	ConfirmInactiveTitle: string = "Are you sure you want to deactivate this resource?";
	ConfirmActiveStateOkButton: string = "Ok";
	ConfirmActiveStateCancelButton: string = "Cancel";
	ConfirmActiveSubtextSingle: string = " Doing so makes it available throughout Team Planner.";
	ConfirmActiveSubtextMultiple: string = "Doing so makes them available throughout Team Planner.";
	ConfirmInactiveSubtextSingle: string = "When deactivating this resource, all it's future activities will be removed!";
	ConfirmInactiveSubtextMultiple: string = "When deactivating these resources, all their future activities will be removed!";
	
	ConfirmCountInactivateTitle: string = "Are you really sure?!";
	ConfirmCountInactivateSubtextSingle: string = "Deactivating this resource will remove all it's future activities";
	ConfirmCountInactivateSubtextMultiple: string = "Deactivating these resources will remove all their future activities";

	ActivateHeaderButton: string = "Set active";
	ActivateButton: string = "Activate";
	DeactivateButton: string = "Deactivate";
	
	AutoInactivationEnabled: string = "Activation and deactivation of resource is controlled automatically, or you don't have permission to set this.";
}