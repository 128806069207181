import { Icon, IIconProps, IStackProps, Stack, TooltipHost } from '@fluentui/react';
import { useGridRowContext } from '../../../../../../../_grid/contexts/rowContext';
import { RowTitle } from './RowTitle';
import { CSSProperties, useCallback, useMemo } from 'react';
import { ResourceType } from '../../../../../../../../../api/generated/data-contracts';
import { useTheme } from '../../../../../../../../../Themes/themeContext';

export const ContractHeader = ({ expanded, nestingLevel }: { expanded: boolean; nestingLevel: number }) => {
    const { row, canExpand, toggleExpanded, subRows } = useGridRowContext();
    const includesCategoryResource = useMemo(() => subRows.length && subRows.some(row => row.metadata?.ResourceType === ResourceType.Category), [subRows]);
    const toggleRowExpanded = useCallback(() => toggleExpanded(), [toggleExpanded]);
    const titleContainer = useMemo(
        () => ({
            paddingLeft: nestingLevel * 10,
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            transition: 'background-color .3s',
            gap: 8,
        }),
        [nestingLevel],
    );
	const theme = useTheme();
    const categoryResourceIconStyles = useMemo(
        (): IIconProps['styles'] => ({ root: { color: theme.semanticColors.kpiCategory, fontSize: 16, cursor: 'help' } }),
        [theme.semanticColors.kpiCategory],
    );
    return (
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" styles={containerStyles}>
            <div
                style={titleContainer}
            >
                {canExpand && (
                    <Icon
                        styles={expandIconStyles}
                        iconName={expanded ? 'ChevronDown' : 'ChevronRight'}
                        onClick={toggleRowExpanded}
                    />
                )}
                <div
                    style={coinContainerStyles}
                >
                    <RowTitle>{row.name}</RowTitle>
                </div>
            </div>
            {includesCategoryResource && (
                <TooltipHost content="Category Resource is allocated to this contract">
                    <Icon iconName="Transition" styles={categoryResourceIconStyles} />
                </TooltipHost>
            )}
        </Stack>
    );
};

const containerStyles: IStackProps['styles'] = { root: { height: '100%', overflow: 'hidden', paddingRight: 12 } };
const coinContainerStyles: CSSProperties = {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
};
const expandIconStyles: IIconProps['styles'] = { root: { transform: 'scale(0.7)', cursor: 'pointer' } };