import { Icon, Stack, IconButton } from '@fluentui/react';
import { useDrag } from '@use-gesture/react';
import { useAllRowsClosedContext } from '../../../../../_grid/contexts/allRowsClosedContext';
import { useExpandedGridContext } from '../../../../../_grid/contexts/expandedContext';
import { BORDER, TOP_HEADERS_HEIGHT } from '../../CONSTANTS';
import { useGridContext } from '../../../../../_grid/contexts/gridContext';
import { useSortedByColumnState } from './useSortedByColumnState';
import { SortOrder, sortRowProperty } from '../../../../../_grid/helpers/sortRowProperty';
import { useCallback, useMemo } from 'react';
import { useGridLayoutContext } from '../../context/LayoutContext';

export const GridHeader = () => {
    const { setExpandedRows } = useExpandedGridContext();
    const allRowsClosed = useAllRowsClosedContext();
    const { rowHeaderSize, setRowHeaderSize } = useGridLayoutContext();
    const bind = useDrag(({ down, memo, movement: [mx] }) => {
        if (!down) {
            return;
        }
        if (!memo) {
            return rowHeaderSize;
        }
        const newX = memo + mx;
        setRowHeaderSize(newX);
    }, {});
    return (
        <div
            style={{
                background: 'white',
                borderBottom: BORDER,
                borderRight: BORDER,
                boxSizing: 'border-box',
                zIndex: 2,
                width: `${rowHeaderSize}px`,
                height: TOP_HEADERS_HEIGHT,
                position: 'sticky',
                left: 0,
                display: 'flex',
                // flexDirection: "row"
            }}
        >
            <Stack horizontal horizontalAlign="start" verticalAlign="end" styles={{ root: { width: '100%', paddingBottom: 10 } }}>
                <IconButton
                    iconProps={{ iconName: 'CalculatorAddition' }}
                    title="Expand all"
                    onClick={() => {
                        setExpandedRows(expanded => {
                            return Object.fromEntries(Object.keys(expanded).map(key => [key, true]));
                        });
                    }}
                />
                <IconButton
                    iconProps={{ iconName: 'CalculatorSubtract' }}
                    title="Collapse all"
                    disabled={allRowsClosed}
                    onClick={() => {
                        setExpandedRows(expanded => {
                            return Object.fromEntries(Object.keys(expanded).map(key => [key, false]));
                        });
                    }}
                />
                <SortNameButton />
            </Stack>

            <Stack
                verticalAlign="center"
                {...bind()}
                horizontalAlign="end"
                styles={{
                    root: {
                        '& > div': {
                            opacity: 0,
                            transition: '.3s ease',
                            userSelect: 'none',
                        },
                        '&:hover > div': {
							opacity: 1,
                        },
						touchAction: 'none',
                        width: 50,
                        cursor: 'col-resize',
                        paddingRight: 5,
                    },
                }}
            >
                <div>
                    <Icon iconName="ImportMirrored" title="Resize resource column" />
                </div>
            </Stack>
        </div>
    );
};

const SortNameButton = () => {
    const { setRootRows } = useGridContext();
    const [currentSortedValue, setCurrentSortedValue] = useSortedByColumnState();
    const id = 'name';
    const onClick = useCallback(async () => {
        let order: SortOrder = 'asc';
        if (currentSortedValue?.id === id) {
            order = currentSortedValue.order === 'desc' ? 'asc' : 'desc';
        }
        setRootRows(rows => [...rows].sort((a, b) => sortRowProperty(a.name, b.name, order)));
        setCurrentSortedValue({ id, isCustom: false, order });
    }, [currentSortedValue?.id, currentSortedValue?.order, setCurrentSortedValue, setRootRows]);
    const iconName = useMemo(() => {
        if (!currentSortedValue || currentSortedValue.id !== id) {
            return 'Sort';
        }
        if (currentSortedValue.order === 'asc') {
            return 'SortUp';
        }
        return 'SortDown';
    }, [currentSortedValue]);
    return <IconButton iconProps={{ iconName }} onClick={onClick} />;
};
