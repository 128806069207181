import 'reflect-metadata';
import { PropertyInfoAreas } from './PropertyInfoAreas';

export class PropertyRender { 
	constructor(public ListColumnRender?: (obj: any, i?: number, column?: any) => string, public ComponentProps?: { [key: string] : any; }, public CustomComponentName?: string, public Hide?: (obj: any) => boolean, public Disable?: (obj: any) => boolean, public ListColumnWidth?: number) {}
	public static PropertyInfoKey: PropertyInfoAreas = PropertyInfoAreas.Render;
}

export const PFPropertyRender = (listColumnRender?: (obj: any, i?: number, column?: any) => string, componentProps?: { [key: string] : any; }, customComponentName?: string, hide?: (obj: any) => boolean, disable?: (obj: any) => boolean, listColumnWidth?: number): PropertyDecorator => {
    return (target, property) => {
      const propertyConstructor = target.constructor;
	  const metadata = Reflect.getMetadata(PropertyRender.PropertyInfoKey, propertyConstructor) || {};
      metadata[property] = new PropertyRender(listColumnRender, componentProps, customComponentName, hide, disable, listColumnWidth);
      Reflect.defineMetadata(PropertyRender.PropertyInfoKey, metadata, propertyConstructor);
    };
};
