/* eslint-disable react-refresh/only-export-components */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CreateStore } from './Services/StateStore';
import { PFErrorBoundary } from './Components/Common/PFErrorBoundary';
import { OverviewContext } from './Utilities/Context/OverviewContext';
import { EnableLogging } from './Logging/EnableLogging';
import { LocalizationService } from './Services/LocalizationService';
import { PropertyInfoService } from './Services/PropertyInfoService';
import { FluentPropertyLabel, fluentUiValidationMessageElement, FluentFormShimmer, createFluentBuilder } from '@wiberg/fluentui-builder';
import { formbuilder } from '@wiberg/formbuilder';
import { Router } from './router/Router';
import { useStore } from './context/store';
import { createTpComplexBuilder } from './forms/_builders/tpComplexBuilder/tpComplexBuilder';
import { createTpSimpleBuilder } from './forms/_builders/tpSimpleBuilder/tpSimpleBuilder';
import { PFSpinner } from './Components/Common/Spinner';
import { useUISettings } from './context/network/http/QueryProvider/queries/UISettings';
import { refactorHelper } from './helpers/refactorHelper';
import shallow from 'zustand/shallow';
import { DateEx } from './Utilities/DateEx';

EnableLogging();

const App = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { data: uiSettings } = useUISettings();
    const { hasHydrated, uiStartUpLoaded } = useStore(store => ({
        hasHydrated: store.hasHydrated,
		uiStartUpLoaded: store.uiStartUpLoaded
    }), shallow);

    useEffect(() => {
        if (!hasHydrated || !uiSettings) return;
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasHydrated, uiSettings]);

    // TODO: usecallback
    const initialize = async () => {

        // init new formbuilder
		if (!isLoading) {
			formbuilder
				.initialize()
				.usingComplexBuilder(FluentPropertyLabel, fluentUiValidationMessageElement, FluentFormShimmer)
				.withBuilders(createFluentBuilder(), createTpComplexBuilder(), createTpSimpleBuilder());
		}

        // load settings
		OverviewContext.Settings = refactorHelper.parseSettingsToLegacy(uiSettings.settings)
        // set settings id on root (use for ie. hiding elements with css, based on subsetting)
        document.getElementById('root').setAttribute('settingid', OverviewContext.Settings.id);
        // load localizations
        await LocalizationService.Instance.Initialize(uiSettings.settings.localization);
        PropertyInfoService.UseLocalization(LocalizationService.Instance);
		// load date locale
		DateEx.setLocale(uiSettings.settings.cultureInfo ?? 'da-dk');
        setIsLoading(false);
    };


    return (
        <>
            {isLoading || !hasHydrated || !uiStartUpLoaded ? (
                <PFSpinner LabelText="Initializing..." />
            ) : (
                <PFErrorBoundary ComponentName="app">
                    <Router />
                </PFErrorBoundary>
            )}
        </>
    );
};

export default CreateStore(App);
