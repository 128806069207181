export default class Locale_Origin {
	OriginId: string = "Origin id";
	ProjectIdentifierFieldMapping: string = "Project identifier field mapping";
	ProjectIdentifierFieldMappingDescription: string = "This mapping defines how the projects are binded between the app, to the origin. Ie. if the origin is coupled through Id, we can tell the integration that the 'Id' field in TP is to be matched with the 'ProjectId' field in the origin.";
	ProjectPeopertiesAllowedForEdit: string = "Project properties allowed for edit";
	ProjectPeopertiesAllowedForEditDescription: string = "Defines which project properties that the origin is allowed to edit. All other properties will not be touched by this origin";
	ResourceIdentifierFieldMapping: string = "Resource identifier field mapping";
	ResourceIdentifierFieldMappingDescription: string = "This mapping defines how the resources are binded between the app, to the origin. Ie. if the origin is coupled through email, we can tell the integration that the 'Email' field in TP is to be matched with the 'UserEmail' field in the origin.";
	ResourcePeopertiesAllowedForEdit: string = "Resource properties allowed for edit";
	ResourcePeopertiesAllowedForEditDescription: string = "Defines which project properties that the origin is allowed to edit. All other properties will not be touched by this origin";
}