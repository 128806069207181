import { FilterType, GridView, GridViewType } from "../../../api/generated/data-contracts"


export const fallbackViewId = "fallback-view"

export const fallbackView: GridView = {
	id: fallbackViewId,
	filters: {},
	filterType: FilterType.Simple,
	name: "My resources",
	type: GridViewType.Global
}