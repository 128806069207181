import { IconButton, Label, TextField } from "@fluentui/react";
import { IFormItem, IPropertyRenderProps } from "@wiberg/formbuilder";
import { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import Cron, { CronError } from "react-js-cron";
import { IDynamicCronFieldConfig } from "./config/IDynamicCronFieldConfig";
import 'antd/dist/antd.css';
import { PFDialog } from "../../../../Components/Common/PFDialog";
import cronstrue from 'cronstrue';

interface IProps<T extends IFormItem> extends IPropertyRenderProps<T, IDynamicCronFieldConfig<T>, string | undefined> { 
    config?: IDynamicCronFieldConfig<T>;
}

export const CronField = <T extends IFormItem>(props: PropsWithChildren<IProps<T>>) : ReactElement | null => {

	const [editMode, setEditMode] = useState<boolean>(false);
	const [value, setValue] = useState<string>(props.value);
	const [textValue, setTextValue] = useState<string>(props.value);

	const [error, onError] = useState<CronError>()

	useEffect(() => {
		setTextValue(value);
	}, [value])

	return 	<div className="tp-croneditor-container">
				<IconButton iconProps={{iconName: props.disabled ? "Zoom" : "Edit"}} onClick={ () => setEditMode(true) } />
				<Cron 
					value={props.value} 
					setValue={value => { }}
					clearButton
					readOnly
				/>
				<PFDialog
					title={props.config?.dialogTitle ?? "Edit cron"} // language.DynamicComponents.CronEditor
					minWidth={ 1000 }
					maxWidth={ 1500 }
					showDialog={ editMode }
					callback={ () => {
						props.onChange(value);
						setEditMode(false);
					}}
					dismissCallback={ () => { 
						setEditMode(false);
						setValue(props.value);
					} }
					eventBubbling={false}
					isBlocking
				>
					<Cron
						value={value} 
						setValue={val => {
 setValue(val) 
}}
						clearButton
						readOnly={props.disabled}
						onError={onError}
					/>
					<TextField 
						value={textValue}
						onChange={ (ev, val) => setTextValue(val)}
						onBlur={(event) => setValue(event.target.value)}
					/>
					{ error && <Label styles={{root:{color: "red"}}}>{error.description}</Label> }
					<Label styles={{root: { fontStyle: "italic", color: "grey" }}}>
						{ cronstrue.toString(textValue, { throwExceptionOnParseError: false }) }
					</Label>
				</PFDialog>
			</div>
}
