import { FunctionComponent } from 'react';
import { TextField } from '@fluentui/react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import EntityPropertySelector from './EntityPropertySelector';
import { Tuple } from '../../../Entities/Tuple';
import { language } from '../../../Services/LocalizationService';
 
interface IProps extends IDynamicEditorComponentProps { 
    Item: Tuple;
    Update: (value: Tuple) => void;
	Key?: string;
	
	EntityType: { new(): any; };
}

const PropertyMapping: FunctionComponent<IProps> = props => {

    const onChange = (index: "Item1" | "Item2", value: string): void => {
		props.Item[index] = value;
		props.Update(props.Item);
    }

	return  (
		<div style={{marginLeft: "20px"}}>
			<EntityPropertySelector 
				Label={language.PropertyMapping.TPProperty}
				EntityType={props.EntityType}
				Item={props.Item.Item1}
				PropertyTypes="Both"
				Update={(value: string) => onChange("Item1", value)}
				FilterArrayProperties
			/>
			<TextField 
				label={language.PropertyMapping.OriginPropertyName}
				defaultValue={ props.Item.Item2 }
				onChange={(obj, value) => onChange("Item2", value)}
			/>
		</div>
	)
}

export default PropertyMapping;