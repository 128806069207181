import { ShimmerElementType, Stack } from '@fluentui/react';
import { ShimmerBox } from '../../../../../Components/Skeleton/ShimmerBox';

export function ListItemShimmer({ opacity }: { opacity?: number; }) {
	return (
		<Stack horizontal horizontalAlign="space-between" styles={{ root: { borderBottom: '1px solid rgba(0,0,0,0.1)', padding: '10px 0', opacity } }}>
			<Stack tokens={{ childrenGap: 10 }} horizontal>
				<ShimmerBox type={ShimmerElementType.line} height={65} width={65} />
				<Stack tokens={{ childrenGap: 10 }}>
					<ShimmerBox height={20} width={300} />
					<div style={{ width: 200 }}>
						<ShimmerBox height={16} />
					</div>
					<div style={{ width: 100 }}>
						<ShimmerBox height={10} />
					</div>
				</Stack>
			</Stack>
			<div style={{ display: 'grid', gridTemplateColumns: '20px 20px', gap: 5, marginRight: 20, height: 'min-content' }}>
				<ShimmerBox height={20} />
				<ShimmerBox height={20} />
				<ShimmerBox height={20} />
				<ShimmerBox height={20} />
			</div>
		</Stack>
	);
}
