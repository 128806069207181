import { FunctionComponent, useState, useCallback, useEffect } from 'react';
import { IconButton, Stack, TooltipHost } from '@fluentui/react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { language } from '../../../Services/LocalizationService';
import { ProjectType } from '../../../Entities/ProjectType';
import { IProjectWithStringVariants } from '../../../forms/_interfaces/IProjectWithStringVariants';
import EnumEx from '../../../Utilities/EnumEx';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import { Plugin } from '../../../Services/PluginInvoker';
import { GenericPicker, IPickerItem, IPickerOptions, IPickerSuggestionsOptions } from '../../../forms/xxx-components-refactor/GenericPicker';
import { ArrayEx } from '../../../Utilities/ArrayEx';
import { stringVariants } from '../../../helpers/stringVariants';
import { Project } from '../../../Entities/Main/Project';

interface IProps extends IDynamicEditorComponentProps {
    Item: string | Array<string>;
    Update: (value: string | Array<string>) => void;
    Key?: string;

    MultiSelect?: boolean;
    OwnerSearchEnabled?: boolean;
    IncludeInActive?: boolean;
    PlaceHolder?: string;
    ClearSelection?: (typeToClear: string) => void;
}

const ProjectPicker: FunctionComponent<IProps> = ({
    Item,
    Update,
    ClearSelection,
    IncludeInActive,
    MultiSelect,
    OwnerSearchEnabled,
    Parent,
    PlaceHolder,
    ReadOnly,
    Required,
    Key,
}) => {
    const [item, setItem] = useState(Item);

    useEffect(() => {
        setItem(Item);
    }, [Item]);

    const getProjects = useCallback(async (): Promise<Project[]> => {
        const options = { OnlyActive: !IncludeInActive, ProjectTypes: EnumEx.all<ProjectType>(ProjectType) };
        return await Plugin.Invoke<Array<IProjectWithStringVariants>>(PluginIDs.GetProjectsForPickerField, options);
    }, [IncludeInActive]);

    const getFieldStringValue = (item: Project, field: keyof Project): string => {
        return stringVariants.getAsStringValue(item, field as string);
    };

    const buildOptions = useCallback(
        (): IPickerOptions<Project> =>
            ({
                entityGetter: getProjects,
                itemParser: (item: Project) =>
                    ({
                        id: item.id,
                        displayName: item.Name,
                        description: getFieldStringValue(item, 'Owner'),
                    } as IPickerItem),
                recentItemsKey: 'projectpicker-recentlyused',
                multiSelect: MultiSelect,
                suggestions: {
                    loadingText: language.Common.Loading,
                    noResultsFound: language.Common.NoResults,
                    showRemoveButtons: true,
                    suggestionsHeaderText: language.ProjectPicker.RecentlyUsedProjects,
                    suggestionsContainerText: language.Common.Resources,
                    suggestionsAvailableAlertText: language.ProjectPicker.RecentlyUsedProjects,
                } as IPickerSuggestionsOptions,
                placeHolder: PlaceHolder,
            } as IPickerOptions<Project>),
            // eslint-disable-next-line react-hooks/exhaustive-deps
        [MultiSelect, getProjects],
    );

    const pickerOptions = useState(() => buildOptions())[0];

    const onItemsChange = (items: string | string[]): void => {
        if (MultiSelect) Update(items);
        else Update(ArrayEx.firstOrNull(items as string[]));
    };

    const clear = () => {
        setItem(null);
        ClearSelection('project');
    };

    return (
        <>
            <Stack horizontal verticalAlign={'start'}>
                <GenericPicker
                    styles={{ root: { flexGrow: 1 } }}
                    item={item}
                    update={values => onItemsChange(values)}
                    pickerOptions={pickerOptions}
                    readOnly={ReadOnly}
                />
                {ClearSelection && (
                    <TooltipHost styles={{ root: { paddingBottom: 4 } }} content={language.LMCapacity.ClearSelection.Project}>
                        <IconButton styles={{ icon: { fontSize: 24 } }} iconProps={{ iconName: 'ClearFormattingEraser' }} onClick={clear} disabled={ReadOnly} />
                    </TooltipHost>
                )}
            </Stack>
        </>
    );
};

export default ProjectPicker;
