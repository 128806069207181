import { IconButton, ITooltipProps, Text, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { Fragment, FunctionComponent } from 'react';

/* 	Add new tooltip types by adding the following:
		Enum for ETooltipContentType
		renderProps function for getTooltipProps in PFTooltipHost
*/
// eslint-disable-next-line react-refresh/only-export-components
export enum ETooltipContentType {
    List,
}

interface ContentProp {
    displayName: string;
    displayText: string | number;
}

interface IProps {
    /** Available contentTypes: List */
    contentType: ETooltipContentType;
    content: ContentProp[];
    listItemFlexDirection: 'row' | 'column';
    enableCopyToClipboard?: boolean;
}

const listRenderProps = (content: ContentProp[], flexDirection, copyToClipboard?: boolean): ITooltipProps => {
    return {
        onRenderContent: () => (
            <Fragment>
                <div className="ms-CalloutExample-header">
                    <p className="ms-CalloutExample-title">Details</p>
                </div>
                <ul style={{ margin: 10, padding: 0, display: 'flex', flexDirection: 'column', gap: 5 }}>
                    {content.map((item, index: number) => {
                        //Don't display list items for null, emptry or undefined values
                        if (Object.values(item).some(k => k === null || k === '' || k === undefined)) return null;
                        return (
                            <li key={index}>
                                <div style={{ display: 'flex', flexDirection: flexDirection }}>
                                    <Text>{`${item.displayName}: `}</Text>
                                    <Text style={{ paddingLeft: flexDirection === 'column' ? 16 : 4 }}>{item.displayText}</Text>
                                    {copyToClipboard && (
                                        <IconButton
                                            iconProps={{ iconName: 'copy' }}
                                            styles={{ root: { height: 22, width: 22 } }}
                                            onClick={() => navigator.clipboard.writeText(item.displayText.toString())}
                                        />
                                    )}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </Fragment>
        ),
    };
};

export const PFTooltipHost: FunctionComponent<IProps> = ({ children, contentType, content, listItemFlexDirection, enableCopyToClipboard }): JSX.Element => {
    const tooltipId = useId('tooltip');

    function getTooltipProps() {
        switch (contentType) {
            case ETooltipContentType.List:
                return listRenderProps(content, listItemFlexDirection, enableCopyToClipboard);
        }
    }

    return (
        <TooltipHost tooltipProps={getTooltipProps()} id={tooltipId} closeDelay={1000}>
            {children}
        </TooltipHost>
    );
};
