import React, { FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import { Plugin } from '../../../Services/PluginInvoker';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import { TimelineResolution } from '../../../Entities/TimelineResolution';
import { TopRow } from '../../../Entities/Table/TopRow';
import Table from '../../../Components/Overview/Table/Table';
import { Icon, IContextualMenuItem, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, TextField, TooltipHost } from '@fluentui/react';
import { Cell } from '../../../Entities/Table/Cell';
import { WorkType } from '../../../Entities/Dto/AddActivityDto';
import { CellRow } from '../../../Entities/Table/CellRow';
import { OverviewContext } from '../../../Utilities/Context/OverviewContext';
import { CellEx } from '../../../Utilities/CellEx';
import { PFSpinner } from '../../../Components/Common/Spinner';
import { ProjectCapacityService } from '../../../Services/ProjectCapacityService';
import { GroupRow } from '../../../Entities/Table/GroupRow';
import { CellUpdateDto } from '../../../Components/Overview/Table/DragCopy/CellUpdateDto';
import { UndoItem } from '../../../Entities/UndoItem';
import { GroupContainerRow } from '../../../Entities/Table/GroupContainerRow';
import { JsonEx } from '../../../Utilities/JsonEx';
import { IDs } from '../../../Utilities/IDs';
import { ResourcePanelItem } from '../../../ListItems/ResourcePanelItem';
import { GridType } from '../../../Entities/GridType';
import { guid } from '../../../helpers/guid';
import { SaveWorkPackageCellDto } from '../../../Entities/Dto/SaveWorkPackageCellDto';
import { IRow, Row } from '../../../Entities/Table/Row';
import { DragAndDrop } from '../../../Utilities/DragAndDrop';
import { WorkPackageDto } from '../../../Entities/Main/WorkPackage';
import { Comments } from '../../../Components/Overview/Comments/Comments';
import { language } from '../../../Services/LocalizationService';
import { UserSettingsService } from '../../../Services/Settings/UserSettingsService';
import { useStore } from '../../../context/store';
import { WorkPackagesTotalCoachmark } from '../../../userGuidance/areas/WorkPackagesCoachmarks';
import { PFDialog } from '../../../Components/Common/PFDialog';
import { stringVariants } from '../../../helpers/stringVariants';
import { ApiCalls } from '../../../api/api';
import shallow from 'zustand/shallow';
import { capitalize } from '../../../context/network/http/QueryProvider/helpers/queryHelper';
import { UserType } from '../../../api/generated/data-contracts';
import ContractRowMenu from '../../../Components/Overview/ContractRowMenu';
import { ObjectEx } from '../../../Utilities/ObjectEx';
import { useParams } from 'react-router';

interface IProps {
    WorkPackage: WorkPackageDto;
    Start: Date;
    End: Date;
    EditNameCallback: (newName: string) => void;
    DeletePackageCallback: () => void;
    GotoPackage: (packageId: string) => void;
}

const WorkPackageEditor: FunctionComponent<IProps> = props => {
    const { setUIContext, error, success, info, warning } = useStore(
        store => ({
            setUIContext: store.setUIContext,
            error: store.addErrorNotification,
            success: store.addSuccesNotification,
            info: store.addInfoNotification,
            warning: store.addWarningNotification,
        }),
        shallow,
    );
    const [workPackage, setWorkPackage] = useState<WorkPackageDto>(props.WorkPackage);
    const workPackageRef = useRef<WorkPackageDto>();
    const [view, _setView] = useState<TopRow>();
    const viewRef = useRef<TopRow>();
    const [loading, setLoading] = useState(true);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showRenameDialog, setShowRenameDialog] = useState(false);

    const { projectId } = useParams<{ projectId: string }>();
    const newPackageName = useRef<string>();
    const groupByRef = useRef<string>();

    const [timeline, setTimeline] = useState<{ Start: Date; End: Date }>();
    const timelineRef = useRef<{ Start: Date; End: Date }>();

    useEffect(() => {
        OverviewContext.RefreshWorkPackages = ForceRefreshData;
        OverviewContext.AddResource = AddResource;
        // willunmount
        return () => {
            OverviewContext.RefreshWorkPackages = null;
            OverviewContext.AddResource = () => {};
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        workPackageRef.current = props.WorkPackage;
        setWorkPackage(props.WorkPackage);
    }, [props.WorkPackage]);

    useEffect(() => {
        if (props.Start == null || props.End == null) return;
        setTimeline({ Start: props.Start, End: props.End });
    }, [props.Start, props.End]);

    useEffect(() => {
        timelineRef.current = { ...timeline };
    }, [timeline]);

	const LoadCapacity = useCallback(async (workPackageRef?: WorkPackageDto, groupBy?: string) => {
        // don't refresh data if timeline or package isn't set
        const wp = workPackageRef || workPackage;
        if (wp == null) return;

        setLoading(true);
		const GetPackage = async (packageId: string, range: { Start: Date; End: Date }, groupBy?: string): Promise<TopRow> => {
			if (!packageId) return null;
			return await Plugin.Invoke<TopRow>(
				PluginIDs.GetWorkPackageView,
				{ WorkPackageId: packageId, Start: range.Start, End: range.End, GroupBy: groupBy },
				'Failed getting request template',
				true,
			);
		};
        const model = await GetPackage(wp.Id, timeline ?? timelineRef.current, groupBy);
        setView(model);
        setLoading(false);
    }, [timeline, workPackage]);


    useEffect(() => {
        if (timeline == null || workPackage == null) return;
        const getView = async () => {
            await LoadCapacity();
        };
        getView();
    }, [LoadCapacity, timeline, workPackage]);

    const setView = (view: TopRow) => {
        if (view != null) view.ChangeKey = IDs.makeId();
        viewRef.current = view; // keep updated
        _setView(view);
    };



    const ForceRefreshData = async (groupBy?: string) => {
        // update contract groupby property
        groupByRef.current = groupBy;
        await LoadCapacity(workPackageRef.current, groupBy);
    };

    const OnCellUpdate = (
        vieww: TopRow,
        columnIndex: number,
        idx: number,
        cell: Cell,
        newValue: number,
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        isUndo?: boolean,
    ): TopRow => {
        const flattenedGroups = ProjectCapacityService.GroupsFlattened(vieww);

        const group = flattenedGroups.find(_ => _.Id === cell.Properties.ContractId);
        const row = group.Rows.find(_ => _.Id === cell.Properties.ResourceId);
        const cellRow = row.CellRows[columnIndex];

        // store action to be used in 'undo' feature
        if (!isUndo && OverviewContext.Settings.EnableGridUndo) SaveActions(idx, [{ Cell: cell, ColumnIndex: columnIndex, Value: cell.Value }]);

        // get container if any
        const container =
            group.Properties.ContainerId !== guid.empty ? (vieww.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow) : null;

        ProjectCapacityService.UpdateCellDomValue(
            vieww,
            flattenedGroups,
            container,
            group,
            row,
            cellRow,
            WorkType.Request,
            columnIndex,
            idx,
            newValue,
            false,
            true,
        );

        // save cell
        SaveCell(cell, cellRow, container, group);

        return vieww;
    };

    const SaveCell = (cell: Cell, parent: CellRow, container: GroupContainerRow, group: GroupRow, newValue?: number, ignoreContainerName?: boolean): void => {
        const dto = new SaveWorkPackageCellDto();
        dto.WorkPackageId = workPackage.Id;
        dto.Start = parent.Start;
        dto.End = parent.End;
        dto.Work = newValue ? newValue : cell.Value;
        dto.ContractId = parent.Properties.ContractId;
        dto.ContractName = group.Name;
        dto.ContractGroupName = ignoreContainerName ? null : container?.Name;
        dto.ResourceId = parent.Properties.ResourceId;
        dto.ResourceName = parent.Properties.ResourceName;

        Plugin.Invoke<any>(PluginIDs.SaveWorkPackageCell, dto)
            .then(_ => {
                console.info('Cell saved');
                HighlightCell([cell.Id], true, 'highlightdone');
            })
            .catch(_ => {
                // NotificationService.Instance.Error("Failed saving cell");
                error('Failed saving cell');
                HighlightCell([cell.Id], true, 'highlighterror');
            });
    };

    const HighlightCell = (cellIds: Array<string>, removeBorder?: boolean, highlightClass: string = 'highlight') => {
        if (cellIds == null) return;
        cellIds.forEach(_ => {
            const element = document.querySelector(`.tp-capacity-cell-container input[data-cellid="${_}"]`) as HTMLElement;
            if (!element) return;
            element.classList.add(highlightClass);
            setTimeout(() => {
                element.classList.remove(highlightClass);
            }, 1000);

            if (removeBorder) element.style.border = 'unset';
        });
    };

    const buildSaveCell = (cell: Cell, parent: CellRow, container: GroupContainerRow, group: GroupRow, newValue: number): SaveWorkPackageCellDto => {
        const dto = new SaveWorkPackageCellDto();
        dto.WorkPackageId = workPackage.Id;
        dto.Start = parent.Start;
        dto.End = parent.End;
        dto.Work = newValue ? newValue : cell.Value;
        dto.ContractId = parent.Properties.ContractId;
        dto.ResourceId = parent.Properties.ResourceId;
        dto.ResourceName = parent.Properties.ResourceName;
        dto.ContractName = group.Name;
        dto.ContractGroupName = container?.Name;
        return dto;
    };

    const DragUpdates = () => {
        const updates = [
            (cells: Array<CellUpdateDto>) => {
                const updatedModel = OnMultipleCellsUpdate(view, 0, cells);
                setView({ ...updatedModel });
            },
        ];
        return updates;
    };

    const OnMultipleCellsUpdate = (view: TopRow, idx: number, cells: Array<CellUpdateDto>, isUndo?: boolean, ignoreContainerName?: boolean): TopRow => {
        // store actions to be used in 'undo' feature
        if (!isUndo && OverviewContext.Settings.EnableGridUndo)
            SaveActions(
                idx,
                cells.map(_ => {
                    return { Cell: _.Cell, ColumnIndex: _.ColumnIndex, Value: _.Cell.Value };
                }),
            );

        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);

        const dtos = cells.map(cell => {
            const group = flattenedGroups.find(_ => _.Id === cell.Cell.Properties.ContractId);
            const resource = group.Rows.find(_ => _.Id === cell.Cell.Properties.ResourceId);
            const cellRow = resource.CellRows[cell.ColumnIndex];

            // get container if any
            const container =
                group.Properties.ContainerId !== guid.empty ? (view.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow) : null;

            ProjectCapacityService.UpdateCellDomValue(
                view,
                flattenedGroups,
                container,
                group,
                resource,
                cellRow,
                WorkType.Request,
                cell.ColumnIndex,
                idx,
                cell.Value,
            );
            const dto = buildSaveCell(cell.Cell, cellRow, container, group, cell.Value);
            return dto;
        });

        const cellIds = cells.map(_ => _.Cell.Id);
        if (ignoreContainerName) dtos.forEach(_ => (_.ContractGroupName = null));
        Plugin.Invoke<any>(PluginIDs.SaveWorkPackageCells, dtos)
            .then(_ => {
                console.info('Cells saved');
                ProjectCapacityService.HighlightCell(cellIds, true, 'highlightdone');
            })
            .catch(_ => {
                // NotificationService.Instance.Error(language.Common.FailedSavingCells);
                error(language.Common.FailedSavingCells);
                ProjectCapacityService.HighlightCell(cellIds, true, 'highlighterror');
            });

        return view;
    };

    const savedActions = useRef<Array<UndoItem>>([]);

    const SaveActions = (idx: number, cells: Array<CellUpdateDto>) => {
        const item = new UndoItem(TimelineResolution.Day, null, idx, cells);
        savedActions.current.push(item);
    };

    const Undo = () => {
        if (!OverviewContext.Settings.EnableGridUndo) return;
        const item = savedActions.current.pop();
        if (item == null) {
            // NotificationService.Instance.Info(language.Common.NothingToUndo, 2000);
            info(language.Common.NothingToUndo, 2000);
            return;
        }
        const updatedModel: TopRow = OnMultipleCellsUpdate(view, item.idx, item.cells, true);
        // updatedModel = OnMultipleCellsUpdate(view, item.idx, item.cells, true);
        // highlight undo'ed items
        ProjectCapacityService.HighlightCell(
            item.cells.map(_ => _.Cell.Id),
            true,
        );
        // update view
        setView({ ...updatedModel });
        // notify
        // NotificationService.Instance.Info(language.Common.UndidPreviousCellEdit, 2000);
        info(language.Common.UndidPreviousCellEdit, 2000);
    };

    const ExcelCopyUpdate = (cells: Array<CellUpdateDto>, excessiveCells: Array<CellUpdateDto>) => {
        const updatedModel = OnMultipleCellsUpdate(view, 0, cells);
        setView({ ...updatedModel });
        // highlight copied items
        ProjectCapacityService.HighlightCell(
            cells.map(_ => _.Cell.Id),
            true,
        );
        if (excessiveCells.length) {
            // NotificationService.Instance.Warning(language.WorkPackageEditor.CopiedDataLargerThanAvailableArea);
            warning(language.WorkPackageEditor.CopiedDataLargerThanAvailableArea);
        }
    };

    const OnGroupAdd = async (event: any, container?: GroupContainerRow) => {
        let containerName: string;
        const groupRow = JsonEx.parse(event.dataTransfer.getData('grouprow')) as GroupRow;
        // if container is different from existing, generate container name
        // if same container, extract/remove from container
        if (!container || groupRow.Properties.ContainerId !== container.Id) containerName = container?.Name ?? `Group_${IDs.makeId()}`;
        // update contract
        setLoading(true);
        await Plugin.Invoke<string>(PluginIDs.UpdateWorkPackageContractGroupName, {
            WorkPackageId: workPackage.Id,
            ContractIds: [groupRow.Id],
            Name: containerName,
        });
        await LoadCapacity();
    };

    const AddResource = async (resource: ResourcePanelItem, groupRow?: GroupRow) => {
        // uiCtx.setScrollToAddedResource(resource.id);
        setUIContext({ scrollToAddedResource: resource.id });
        const field = OverviewContext.Settings.EnableDynamicContractNames ? 'Name' : capitalize(OverviewContext.Settings.ContractDefaultName);
        const groupName = stringVariants.getAsStringValue(resource, field) ?? resource.Name;
        const updatedModel = await ProjectCapacityService.AddResource(
            viewRef.current,
            guid.empty,
            resource.id,
            groupName,
            groupRow?.Id,
            timelineRef.current.Start,
            timelineRef.current.End,
            TimelineResolution.Day,
            GridType.WorkPackage,
            warning,
            null,
            null,
            true,
        );
        if (updatedModel) {
            ProjectCapacityService.ForceRefreshCells(updatedModel);
            setView({ ...updatedModel });
        }
        [].forEach.call(document.querySelectorAll('.tp-list-capacity-dropzone-overlay'), _ => (_.style.display = 'none'));
    };

    const OnResourceAdd = async (event: any, groupRow?: GroupRow) => {
        const resource = JsonEx.parse(event.dataTransfer.getData('resourcelistitem')) as ResourcePanelItem;
        AddResource(resource, groupRow);
    };

    const OnResourceAddToContainer = async (event: any, container: GroupContainerRow) => {
        const resource = JsonEx.parse(event.dataTransfer.getData('resourcelistitem')) as ResourcePanelItem;
        const field = OverviewContext.Settings.EnableDynamicContractNames ? 'Name' : OverviewContext.Settings.ContractDefaultName;
        const groupName = stringVariants.getAsStringValue(resource, field) ?? resource.Name;
        const updatedModel = await ProjectCapacityService.AddResourceToContainer(
            viewRef.current,
            guid.empty,
            container.Id,
            resource.id,
            groupName,
            timelineRef.current.Start,
            timelineRef.current.End,
            TimelineResolution.Day,
            GridType.WorkPackage,
        );
        if (updatedModel) {
            ProjectCapacityService.ForceRefreshCells(updatedModel);
            setView({ ...updatedModel });
        }
    };

    const GroupDragStart = (group: GroupRow, container: GroupContainerRow, event: any) => {
        // remove from own container
        if (container) {
            [].forEach.call(
                document.querySelectorAll(`#gtc_${container.Id} .tp-list-capacity-removefromcontainer-dropzone-overlay`),
                _ => (_.style.display = 'block'),
            );
            // add to another container
            [].forEach.call(
                document.querySelectorAll(`.tp-grid-groupd-header > div:not(#gtc_${container.Id}) .tp-list-capacity-addcontainer-dropzone-overlay`),
                _ => (_.style.display = 'block'),
            );
        } else
            [].forEach.call(
                document.querySelectorAll(`.tp-grid-groupd-header .tp-list-capacity-addcontainer-dropzone-overlay`),
                _ => (_.style.display = 'block'),
            );

        [].forEach.call(document.querySelectorAll(`.tp-list-capacity-addnewcontainer-dropzone-overlay`), _ => (_.style.display = 'block'));

        (event as any).dataTransfer.setData('grouprow', JsonEx.stringify(group));
        (event as any).dataTransfer.effectAllowed = 'all';
    };

    const RemoveResource = async (rowResource: Row, rowGroup: GroupRow): Promise<void> => {
        document.getElementById('tp-list-resources-dropzone-overlay').style.display = 'none';

        // remove resource
        if (!DragAndDrop.RemoveDraggedResource) return;
        DragAndDrop.RemoveDraggedResource = false;

        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);
        const group = flattenedGroups.find(_ => _.Id === rowGroup.Id);
        const resource = group.Rows.find(_ => _.Id === rowResource.Id);

        // update totals
        for (let rIdx = 0; rIdx < resource.CellRows.length; rIdx++) {
            const cellRow = resource.CellRows[rIdx];
            for (let cIdx = 0; cIdx < cellRow.Cells.length; cIdx++) {
                // skip free capacity total
                if (cIdx >= 2) continue;
                if (group.CellRows[rIdx].Cells.length - 1 < cIdx) continue;
                const cell = cellRow.Cells[cIdx];
                CellEx.Update(group.CellRows[rIdx].Cells[cIdx], -cell.Value, true);
                CellEx.Update(view.CellRows[rIdx].Cells[cIdx], -cell.Value, true);
                // container total
                if (group.Properties.ContainerId === guid.empty) continue;
                const container = view.Groups.find(_ => _.Id === group.Properties.ContainerId);
                if (container) CellEx.Update(container.CellRows[rIdx].Cells[cIdx], -cell.Value, true);
            }
        }

        // remove resource
        const index = group.Rows.findIndex(_ => _.Id === rowResource.Id);
        if (index > -1) group.Rows.splice(index, 1);

        // remove possible empty group
        if (group.Rows.length <= 0) {
            ProjectCapacityService.RemoveGroup(view, group);

            // remove possible empty container
            const container = view.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow;
            if (container && container.Groups.length <= 0) {
                ProjectCapacityService.RemoveGroup(view, container);
            }
        }

        await Plugin.Invoke(PluginIDs.RemoveWorkPackageActivities, {
            WorkPackageId: workPackage.Id,
            ContractId: group.Id,
            ResourceId: resource.Id,
            Start: resource.CellRows[0].Start,
        });

        if (view) {
            setView({ ...view });
            // NotificationService.Instance.Success(`${language.WorkPackageEditor.SuccessfullyRemovedFutureActivitiesFor}: ${resource.Name}`);
            success(`${language.WorkPackageEditor.SuccessfullyRemovedFutureActivitiesFor}: ${resource.Name}`);
        }
    };

    const ChangeContractName = (group: GroupRow, oldName: string, newName: string) => {
        if (oldName === newName || !oldName || !newName) return;
        if (!group) return;
        Plugin.Invoke<string>(PluginIDs.UpdateWorkPackageContractName, { WorkPackageId: workPackage.Id, Name: newName, ContractIds: [group.Id] }).catch(_ =>
            error(language.WorkPackageEditor.FailedChangingContractName),
        );
        // .catch(_ => NotificationService.Instance.Error(language.WorkPackageEditor.FailedChangingContractName));
    };

    const ChangeContractContainerName = (container: GroupContainerRow, oldName: string, groupIds: Array<string>) => {
        if (oldName === container.Name || !oldName || !container.Name) return;
        if (!groupIds.length || !container) return;
        Plugin.Invoke<string>(PluginIDs.UpdateWorkPackageContractGroupName, {
            WorkPackageId: workPackage.Id,
            Name: container.Name,
            ContractIds: groupIds,
        }).catch(_ => error(language.WorkPackageEditor.FailedChangingContractGroupName));
        // .catch(_ => NotificationService.Instance.Error(language.WorkPackageEditor.FailedChangingContractGroupName));
    };

    const GetExtraMenuItems = (): Array<IContextualMenuItem> => {
        const menuItems: Array<IContextualMenuItem> = [];

        menuItems.push(
            {
                key: 'rename',
                text: language.WorkPackageEditor.RenameWorkPackage,
                title: language.WorkPackageEditor.RenameWorkPackageDescription,
                iconProps: { iconName: 'Rename' },
                onClick: (ev, item) => setShowRenameDialog(true),
            },
            {
                key: 'delete',
                text: language.WorkPackageEditor.DeleteCurrentWorkPackage,
                title: language.WorkPackageEditor.DeleteCurrentWorkPackageDescription,
                iconProps: { iconName: 'Delete' },
                onClick: (ev, item) => setShowDeleteDialog(true),
            },
        );
        return menuItems;
    };

    const CellUpdates = () => {
        const updates = [
            (cell: Cell, columnIndex: number, value: number, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const updatedModel = OnCellUpdate(view, columnIndex, 0, cell, value, event);
                setView({ ...updatedModel });
            },
        ];
        return updates;
    };

    const fetchComments = useCallback(async (row: IRow) => {
        // const allComments = await Plugin.Invoke<{ [contractId: string] : Array<Comment> }>(PluginIDs.GetWorkpackageContractsComments, workPackageRef.current.Id, "Failed getting comments", true, []);
        const allComments = await ApiCalls.getWorkpackageContractComments(workPackageRef.current.Id).then(res => res.data);
        if (allComments == null) return null;
        const contractComments = allComments[row.Properties.ContractId];
        return contractComments?.filter(_ => _.resourceId === row.Id);
    }, []);

    return (
        <>
            {loading ? (
                <PFSpinner />
            ) : (
                <div
                    onKeyDown={e => {
                        if (e.keyCode === 90 && e.ctrlKey) Undo();
                    }}
                    tabIndex={-1}
                    style={{ outline: 'unset' }}
                >
                    <Table
                        Name={view.Properties.WorkPackageName}
                        Model={view}
                        ContainerClassName="tp-workpackages-view"
                        CellUpdates={CellUpdates()}
                        TopCellStyles={[
                            (cell: Cell) => {
                                return { root: { paddingRight: '10px' } } as IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
                            },
                        ]}
                        GroupCellStyles={[
                            (cell: Cell) => {
                                return { root: { paddingRight: '10px' } } as IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
                            },
                        ]}
                        GroupContainerCellStyles={[
                            (cell: Cell) => {
                                return { root: { paddingRight: '10px' } } as IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
                            },
                        ]}
                        RowCellStyles={[
                            (cell: Cell) => {
                                return { root: { paddingRight: '10px', cursor: 'unset' } } as IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
                            },
                        ]}
                        GridCellDecimals={ProjectCapacityService.GetGridDecimals()}
                        DragHighlighterUpdate={DragUpdates()}
                        PasteFromExcel={OverviewContext.Settings.EnableCopyFromExcel && ExcelCopyUpdate}
                        GroupEditEnabled={group => !group.Properties.FromOtherPackage}
                        GroupContainerEditEnabled={container => groupByRef.current == null && !container.Properties.FromOtherPackage}
                        GroupEdit={ChangeContractName}
                        GroupContainerEdit={ChangeContractContainerName}
                        GroupDraggable={(group: GroupRow) => !group.Properties.FromOtherPackage} // props.GridType === GridType.Request || adminProject}
                        GroupDragStart={GroupDragStart}
                        GroupDragEnd={async (group, event) =>
                            [].forEach.call(
                                document.querySelectorAll(
                                    `.tp-list-capacity-addcontainer-dropzone-overlay, .tp-list-capacity-mergegroup-dropzone-overlay, .tp-list-capacity-removefromcontainer-dropzone-overlay, .tp-list-capacity-addnewcontainer-dropzone-overlay`,
                                ),
                                _ => (_.style.display = 'none'),
                            )
                        }
                        GroupSort={(groups: Array<GroupRow>) => groups.sort((a, b) => a.Name.localeCompare(b.Name))}
                        RowDraggable={(row: Row) => !row.Properties.FromOtherPackage}
                        RowDragStart={(row, group, event) => (document.getElementById('tp-list-resources-dropzone-overlay').style.display = 'block')}
                        RowDragEnd={async (row, group, event) => await RemoveResource(row, group)}
                        AllowContainerCollapse
                        AllowGroupCollapse
                        GridSettingsSpacingAmount={OverviewContext.Settings.GridSpacing}
                        GridSettingsHidePersonaIcons={OverviewContext.Settings.HidePersonaIconsInGrid}
                        SetGridSettingsHidePersonaIconsCallback={value =>
                            UserSettingsService.UpdateUserSettingsPartial({ HidePersonaIconsInGrid: value }, null, true)
                        }
                        SetGridSettingsSpacingAmountCallback={value => UserSettingsService.UpdateUserSettingsPartial({ GridSpacing: value }, null, true)}
                        EnableGridSettings={OverviewContext.Settings.EnableGridSettings}
                        GridSettingsExtraMenuItems={GetExtraMenuItems()}
                        RowExtraContent={(row: Row) => (
                            <div className="tp-capacity-resource-menucontainer">
                                <Comments
                                    elementId={'comment-' + row.Properties.ContractId + '-' + row.Id}
                                    hasComments={row.Properties.HasComments}
                                    hasUnreadComments={false}
                                    canDelete={true}
                                    newCommentFunction={() => {
                                        // const comment = Comment.New(row.Id, "");
                                        // comment.AuthorName = "System";
                                        // comment.AuthorId = guid.empty;
                                        // comment.AuthorRole = UserType.Administrator.toString();
                                        // return comment;
                                        return {
                                            authorId: guid.empty,
                                            authorName: 'System',
                                            resourceId: row.Id,
                                            // authorRole: UserType.Administrator.toString() as string,
                                            authorRole: UserType.Administrator,
                                        };
                                    }}
                                    // FetchCallback={ async () => {
                                    // 	const allComments = await Plugin.Invoke<{ [contractId: string] : Array<Comment> }>(PluginIDs.GetWorkpackageContractsComments, workPackageRef.current.Id, "Failed getting comments", true, []);
                                    // 	if (allComments == null) return null;
                                    // 	const contractComments = allComments[row.Properties.ContractId];
                                    // 	return contractComments?.filter(_ => _.ResourceId == row.Id);
                                    // }}
                                    fetchCallback={fetchComments}
                                    addCallback={comment =>
                                        ApiCalls.createWorkPackageComment({
                                            workPackageId: workPackageRef.current.Id,
                                            contractId: row.Properties.ContractId,
                                            comment,
                                        }).then(res => res.data)
                                    }
                                    // addCallback={comment => Plugin.Invoke<Comment>(PluginIDs.CreateWorkpackageComment, { WorkPackageId: workPackageRef.current.Id, ContractId: row.Properties.ContractId, Comment: comment }, "Failed adding comment! If you have just added the resource, make sure that you have added some hours, as it is not allowed to make comments on an empty request/allocation.", true)}
                                    // removeCallback={comment => Plugin.Invoke<boolean>(PluginIDs.DeleteWorkpackageComment, { WorkPackageId: workPackageRef.current.Id, ContractId: row.Properties.ContractId, Comment: comment})}
                                    removeCallback={comment =>
                                        ApiCalls.deleteWorkpackageComment({
                                            workPackageId: workPackageRef.current.Id,
                                            contractId: row.Properties.ContractId,
                                            comment: comment,
                                        })
                                    }
                                    row={row}
                                />
                            </div>
                        )}
                        GroupExtraContent={(group: GroupRow) => (
                            <>
                                {!!group.Properties.FromOtherPackage && (
                                    <TooltipHost
                                        content={language.WorkPackageEditor.ContractComesFromChildPackage}
                                        calloutProps={{ target: `#childicon-${group.Id}`, gapSpace: 5, isBeakVisible: true }}
                                    >
                                        <Icon
                                            id={`childicon-${group.Id}`}
                                            iconName="RowsChild"
                                            className={`tp-workpackages-contract-childicon`}
                                            onClick={() => props.GotoPackage(group.Properties.SourcePackageId)}
                                        />
                                    </TooltipHost>
                                )}
                                {
                                    <ContractRowMenu
                                        ContractId={group.Id}
                                        GridType={GridType.WorkPackage}
                                        IsProjectOwner={view?.Properties.IsOwner}
                                        AllProperties={ObjectEx.mergeDeep(
                                            {
                                                ContractName: group.Name,
                                                ProjectId: projectId,
                                                ResourceId: group.Properties.RequestedResourceId,
                                                ProposedWorkCallback: (pWork: number) => (group.Properties.ProposedWork = pWork),
                                            },
                                            group.Properties,
                                        )}
                                    />
                                }
                            </>
                        )}
                        GroupContainerExtraContent={(name: string, container: GroupContainerRow) => (
                            <>
                                {!!container.Properties.FromOtherPackage && (
                                    <TooltipHost
                                        content={language.WorkPackageEditor.GroupingContainsContractsFromChildPackages}
                                        calloutProps={{ target: `#childicon-${container.Id}`, gapSpace: 0, isBeakVisible: false }}
                                    >
                                        <Icon
                                            id={`childicon-${container.Id}`}
                                            iconName="RowsChild"
                                            className={`tp-workpackages-contract-childicon`}
                                            styles={{ root: { position: 'absolute' } }}
                                        />
                                    </TooltipHost>
                                )}
                                {!container.Properties.FromOtherPackage && (
                                    <>
                                        <div
                                            className="tp-list-capacity-addcontainer-dropzone-overlay"
                                            onDrop={e => OnGroupAdd(e, container)}
                                            onDragEnter={e => e.preventDefault()}
                                            onDragOver={e => e.preventDefault()}
                                        >
                                            <div className="tp-list-capacity-dropzone-text">{language.WorkPackageEditor.AddToGroup}</div>
                                        </div>
                                        <div
                                            className="tp-list-capacity-removefromcontainer-dropzone-overlay"
                                            onDrop={e => OnGroupAdd(e, container)}
                                            onDragEnter={e => e.preventDefault()}
                                            onDragOver={e => e.preventDefault()}
                                        >
                                            <div className="tp-list-capacity-dropzone-text">{language.WorkPackageEditor.MoveOutOfGroup}</div>
                                        </div>
                                        <div
                                            className="tp-list-capacity-dropzone-overlay tp-grid-top"
                                            onDrop={e => OnResourceAddToContainer(e, container)}
                                            onDragEnter={e => e.preventDefault()}
                                            onDragOver={e => e.preventDefault()}
                                        >
                                            <div className="tp-list-capacity-dropzone-text">{language.WorkPackageEditor.DropHere}</div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        TopExtraContent={(top: TopRow) => (
                            <>
                                <div
                                    className="tp-list-capacity-dropzone-overlay tp-grid-top"
                                    onDrop={e => OnResourceAdd(e)}
                                    onDragEnter={e => e.preventDefault()}
                                    onDragOver={e => e.preventDefault()}
                                >
                                    <div className="tp-list-capacity-dropzone-text">{language.WorkPackageEditor.DropHere}</div>
                                </div>
                                <div
                                    className="tp-list-capacity-addnewcontainer-dropzone-overlay"
                                    onDrop={e => OnGroupAdd(e)}
                                    onDragEnter={e => e.preventDefault()}
                                    onDragOver={e => e.preventDefault()}
                                >
                                    <div className="tp-list-capacity-dropzone-text">{language.WorkPackageEditor.NewGroup}</div>
                                </div>
                            </>
                        )}
                    />
                    <PFDialog
                        title={language.WorkPackageEditor.RenameWorkPackage}
                        showDialog={showRenameDialog}
                        callback={() => {
                            props.EditNameCallback(newPackageName.current);
                            setShowRenameDialog(false);
                        }}
                        dismissCallback={() => {
                            setShowRenameDialog(false);
                        }}
                    >
                        <TextField
                            label={language.WorkPackageEditor.PackageName}
                            defaultValue={workPackage.Name}
                            onChange={(ev, val) => (newPackageName.current = val)}
                        />
                    </PFDialog>
                    <PFDialog
                        title={language.WorkPackageEditor.DeleteWorkPackage}
                        subText={language.WorkPackageEditor.CannotBeUndone}
                        showDialog={showDeleteDialog}
                        callback={() => {
                            props.DeletePackageCallback();
                            setShowDeleteDialog(false);
                        }}
                        dismissCallback={() => {
                            setShowDeleteDialog(false);
                        }}
                    />
                    <WorkPackagesTotalCoachmark />
                </div>
            )}
        </>
    );
};

export default WorkPackageEditor;
