import { CSSProperties, memo, useMemo } from 'react';
import { GridCell } from '../../../../../../../../../api/generated/data-contracts';
import { useGridContext } from '../../../../../../../_grid/contexts/gridContext';
import { useFixedCellValue } from '../../../../../../../_grid/hooks/useFixedCellValue';
import { PlaceholderCell } from './PlaceholderCell';
import { useResouceValueBgColor } from '../../../../hooks/useResouceValueBgColor';
import { ColumnRowProps } from '../../ColumnRowProps';
import { ReadOnlyCell } from './ReadOnlyCell';
import { Cell } from '../../../Cell/Cell';
import { CELL_HEIGHT } from '../../../../CONSTANTS';

export const ResourceColumnRow = memo(function ResourceColumnRow({ columnRow, columnIndex, rowId, isAdminProject }: ColumnRowProps) {
    const render = useMemo(() => {
		return columnRow.cells.map((cell, i) => {
			if (!cell) {
				// Index is safe as key here since every PlaceholderCell is state and prop -less
				// So even if the order changes it will not make a difference
				// NOSONAR
				return <PlaceholderCell key={i} />;
			}
			if (!i) {
				return <ReadOnlyCell key={cell.id} cell={cell} />;
			}
			if (i === 2) {
				return <FreeCapCell key={cell.id} cell={cell} />;
			}
			if (isAdminProject && i === 0) {
				return <ReadOnlyCell key={cell.id} cell={cell} />;
			}
			return <Cell key={cell.id} cell={cell} rowId={rowId} columnIndex={columnIndex} cellIndex={i} />;
		})
	}, [columnIndex, columnRow.cells, isAdminProject, rowId])
	return (
        <ColumnGridContainer>
            {render}
        </ColumnGridContainer>
    );
});

const ColumnGridContainer = ({ children }: { children?: React.ReactNode }) => {
    const style = useMemo(() => {
        return {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            padding: '0px 10px',
            gap: 10,
            alignItems: 'center',
            height: '100%',
        } as CSSProperties;
    }, []);
    return <div style={style}>{children}</div>;
};

const styles: React.CSSProperties = {
    paddingRight: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    height: CELL_HEIGHT,
};

const FreeCapCell = ({ cell }: { cell: GridCell }) => {
    const { useGridCellValue } = useGridContext();
    const storeValue = useGridCellValue(cell);
    const value = useFixedCellValue(storeValue);
    const backgroundColor = useResouceValueBgColor(storeValue.toNumber());
    const style = useMemo(() => ({ ...styles, backgroundColor }), [backgroundColor]);
    return <div style={style}>{value}</div>;
};
