export default class Locale_AutoAllocationDialog { 
	Success: string = "Success!";
	AddedVersionToSelectedScenario: string = "Added version to the selected scenario";
	ContractUpdatedWithChanges: string = "Contract has been updated with the changes";
	AutoAllocate: string = "Auto allocate";
	NotPossible: string = "Not possible";
	CannotFullfillRequest: string = "It is not possible to fulfill the request, using the selected configuration";
	AutoAllocating = {
		AutoAllocatingOnAn: "Auto allocating on an ",
		Existing: "existing",
		ContractWillAlso: " contract, will also ",
		ClearAll: "clear all",
		ExistingActivities: " existing activities within the timespan"
	}
}