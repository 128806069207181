import React, { FunctionComponent, useRef } from 'react';
import { SearchBox } from '@fluentui/react';
import { EventEx } from '../../Utilities/EventEx';
import { language } from '../../Services/LocalizationService';
import { Timeout } from '../../types/runtimeTypes';
 
interface IProps {
	ClassName?: string;
	OnChange: (value: string) => void;
	Delay?: number;
	Disabled?: boolean;
	SearchPlaceHolder?: string;
	onFocus?: (event: any) => void;
}

export const DelayedSearchBox: FunctionComponent<IProps> = props => {

	const _searchTimer = useRef<Timeout>();

	const OnChange = (value: string) : void => {
		const newTimer = EventEx.delay(_searchTimer.current, () => props.OnChange(value), props.Delay);
		_searchTimer.current = newTimer;
    }
	
	return 	<SearchBox 
				className={props.ClassName}
				placeholder={props.SearchPlaceHolder || `${language.Common.Search}..`} 
				onChange={(e, newValue) => OnChange(newValue ?? "")}
				disabled={props.Disabled}
				styles={{ root: { maxWidth: "450px" }}}
				autoFocus
				autoComplete={'off'}
				onFocus={props.onFocus}
			/>
}
