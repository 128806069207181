/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo } from 'react';
import { Checkbox } from '@fluentui/react';
import { DynamicProps } from './DynamicProps';

interface IProps extends DynamicProps<boolean, boolean> {
    HideLabel?: boolean;
}

const DynamicCheckbox: FunctionComponent<IProps> = props => {
    return (
        <Checkbox
            key={props.Key}
            label={props.HideLabel ? null : props.PropertyInfo.DisplayName}
            onChange={(obj, value) => props.OnChange(props.PropertyInfo.PropertyName, value)}
            defaultChecked={props.Value}
            disabled={props.ReadOnly}
        />
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Value === nextProps.Value;
};

export default memo(DynamicCheckbox, comparisonFn);
