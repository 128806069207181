import { Rbs } from "../Entities/Rbs";
import { ArrayEx } from "./ArrayEx";
import { guid } from "../helpers/guid";

// export class RbsEx {

// 	// public static GetRbs = (id: string) : Rbs =>
// 	// {
// 	// 	if (!id) return null;
// 	// 	let rbsStructure = OverviewContext.RbsStructures.find(_ => _.id == OverviewContext.Settings.RbsId);
// 	// 	var root = rbsStructure.Rbs;

// 	// 	// traverse tree without recursion
// 	// 	var stack = new Array<Rbs>();
// 	// 	stack.push(root);
// 	// 	while (stack.length > 0)
// 	// 	{
// 	// 		var current = stack.pop();
// 	// 		if (current.Id == id)
// 	// 			return current;

// 	// 		current.Children.forEach(_ => stack.push(_));
// 	// 	}
// 	// 	return null;
// 	// }
	
// 	// public static GetRbsName = (id: string) : string =>
// 	// {
// 	// 	let rbs = RbsEx.GetRbs(id);
// 	// 	return rbs ? rbs.Name : "";
// 	// }

// 	// public static GetRbsFullName = (id: string) : string =>
// 	// {
// 	// 	let rbs = RbsEx.GetRbs(id);
// 	// 	return rbs ? rbs.FullName : "";
// 	// }

// 	public static RenameNode = (rbs: Rbs, newName: string, delimiter: string) => {
// 		rbs.Name = newName;
// 		rbs.FullName ??= newName;
// 		let splits = rbs.FullName.split(delimiter);
// 		splits.pop();
// 		if (!splits.length)
// 			rbs.FullName = newName;
// 		else
// 			rbs.FullName = `${ArrayEx.joinToString(splits, delimiter)}${delimiter}${newName}`;
// 		RbsEx.RebuildChildFullNames(rbs, delimiter);
// 	}

// 	public static RebuildChildFullNames = (rbs: Rbs, delimiter: string) : void =>
// 	{
// 		if (!rbs || !rbs.Children) return null;
// 		rbs.Children.forEach(_ => {
// 			_.FullName = `${rbs.FullName}${delimiter}${_.Name}`;
// 			RbsEx.RebuildChildFullNames(_, delimiter);
// 		});
// 	}

// 	public static CreateRoot = () : Rbs => {
// 		let rbs = new Rbs();
// 		rbs.Id = guid.empty;
// 		rbs.Name = "Root";
// 		return rbs;
// 	}

// 	public static Count = (rbs: Rbs) : number => {
// 		var count: number = 0;

// 		// traverse tree without recursion
// 		var stack = new Array<Rbs>();
// 		stack.push(rbs);
// 		while (stack.length > 0)
// 		{
// 			count++;
// 			var current = stack.pop();
// 			if (!current || !current.Children) continue;
// 			current.Children.forEach(_ => stack.push(_));
// 		}
// 		return count - 1; // substract root
// 	}
// }

export function renameNode(rbs: Rbs, newName: string, delimiter: string) {
	rbs.Name = newName;
	rbs.FullName ??= newName;
	const splits = rbs.FullName.split(delimiter);
	splits.pop();
	if (!splits.length)
		rbs.FullName = newName;

	else
		rbs.FullName = `${ArrayEx.joinToString(splits, delimiter)}${delimiter}${newName}`;
	rebuildChildFullNames(rbs, delimiter);
}

export function rebuildChildFullNames(rbs: Rbs, delimiter: string): void {
	if (!rbs || !rbs.Children)
		return null;
	rbs.Children.forEach(_ => {
		_.FullName = `${rbs.FullName}${delimiter}${_.Name}`;
		rebuildChildFullNames(_, delimiter);
	});
}

export function createRoot(): Rbs {
	const rbs = new Rbs();
	rbs.Id = guid.empty;
	rbs.Name = "Root";
	return rbs;
}

export function count(rbs: Rbs): number {
	let count: number = 0;

	// traverse tree without recursion
	const stack = new Array<Rbs>();
	stack.push(rbs);
	while (stack.length > 0) {
		count++;
		const current = stack.pop();
		if (!current || !current.Children)
			continue;
		current.Children.forEach(_ => stack.push(_));
	}
	return count - 1; // substract root
}


export const RbsEx = {
	count,
	createRoot,
	rebuildChildFullNames,
	renameNode,
}