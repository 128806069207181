import { Timeout } from "../types/runtimeTypes";

export function delay(timer: Timeout, callback: () => void, ms: number): Timeout {
	clearTimeout(timer as Timeout);
	timer = setTimeout(() => {
		callback();
	}, ms || 0) as any;
	return timer as Timeout;
}

export function delayAndReturn<T>(timer: Timeout, callback: () => T, ms: number): { timer: Timeout; result: Promise<T>; } {
	clearTimeout(timer as Timeout);
	const promise = new Promise<T>((resolve, reject) => {
		timer = setTimeout(() => {
			resolve(callback());
		}, ms || 0) as any;
	});
	return { timer: timer, result: promise };
}

export function preventAndStop(event: any, immediate?: boolean): void {
	event.preventDefault();
	event.stopPropagation();
	if (immediate)
		event.stopImmediatePropagation();
}

export function throttler(func: () => void, wait: number) {
	let previouslyRun: number;
	return function wrapper() {
		const now = Date.now();
		if (!previouslyRun || (now - previouslyRun >= wait)) {
			func();
			previouslyRun = now;
		}
	};
} 

export const EventEx = {
	delay,
	delayAndReturn,
	preventAndStop,
	throttler
}