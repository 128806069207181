import { ReactElement, useState, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { IFieldRenderProps, IFormItem, useFormContext, useValidation, useWatch } from 'spark-forms';
import { FluentValidationMessage } from 'spark-forms-fluent8';
import { ShimmerBox } from '../../../../Components/Skeleton/ShimmerBox';
import { useStore } from '../../../../context/store';
import { changeCase } from '../../../../helpers/changeKeysCase';
import { TreeEditor } from '../../tpComplexBuilder/components/tree/components/TreeEditorNew';
import { ITreeNode as ITreeNodeOLD } from '../../tpComplexBuilder/components/tree/interfaces/ITreeNode';
import { ITreeFieldConfig } from './ITreeFieldConfig';
import { sortableTreeHelper } from '../../../../components_new/sortableTreeview/sortableTreeHelper';
import { ITreeNode } from '@pum-spark/tree-render';

export interface ITreeFieldProp<T extends IFormItem> extends IFieldRenderProps<T, ITreeFieldConfig<T>, string | string[] | ITreeNodeOLD | undefined> {
    config?: ITreeFieldConfig<T>;
}

const initState = (value: string | string[] | ITreeNodeOLD | undefined) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
        return sortableTreeHelper.parseToModernTreeNode(value);
    }
    return value as string | string[] | undefined;
};

export const TreeField = <T extends IFormItem>({ value, fieldName, config, disabled, path }: ITreeFieldProp<T>): ReactElement | null => {
    const [localValue, setLocalValue] = useState<string | string[] | ITreeNode | undefined>(() => initState(value));
    const [selected, setSelected] = useState<string | string[] | undefined>();

    const { onChange: onChangeForm } = useFormContext();
    const { validationMessage } = useValidation(path);

    useWatch(path, () => setLocalValue(initState(value)), [value]);

    const setBlockDialog = useStore(store => store.setBlockDialog);

    const { data: tree } = useQuery(['treefield', config.type, fieldName], async () => {
        try {
            // skip fetch of tree
            if (config.type === 'manage') {
                return localValue as ITreeNode;
            }
            const treeItem = sortableTreeHelper.parseToModernTreeNode(changeCase.toCamel(await config.treeGetter())); // why the fuck do I need to do this (ewi)
            return treeItem;
        } catch (err) {
            setBlockDialog(null, {
                title: 'Error',
                subText: typeof err === 'string' ? err : err.message,
            });
        }
    });

    useEffect(() => {
        if (config.type === 'manage') setSelected(null);
        else setSelected(localValue as string | string[]);
    }, [config.type, localValue]);

    const onChange = useCallback(
        (value: string | string[] | ITreeNode | undefined) => {
            setLocalValue(value);
            onChangeForm(fieldName, value);
        },
        [onChangeForm, setLocalValue, fieldName],
    );

    if (tree == null) {
        return <ShimmerBox height={30} />;
    }

    return (
        <>
            <TreeEditor
                tree={config.type === 'manage' ? (localValue as ITreeNode) : tree}
                type={config.type}
                onChanged={onChange}
                delimiter={config.delimiter}
                buttonTitle={config.buttonTitle}
                dialogTitle={config.dialogTitle}
                multiSelect={config.multiSelect}
                selected={selected}
                readOnly={disabled}
                defaultExpandedDepth={config.defaultExpandedDepth}
                autoExpandSelected
            />
            <FluentValidationMessage message={validationMessage} />
        </>
    );
};
