import React from 'react';
import { DefaultButton } from '@fluentui/react';

export const FilterButton: React.FC<{ onClick: () => void; }> = ({ onClick, children }) => {
	return (
		<DefaultButton
			styles={{
				root: { minWidth: 0, border: 'none', backgroundColor: '#ebebeb', padding: '0 10px', borderRadius: 16, '.ms-Button-label': { fontWeight: 400 } },
			}}
			onClick={onClick}
		>
			{children}
		</DefaultButton>
	);
};
