import React, { useCallback, useMemo, useRef } from 'react';
import { GridType } from '../../../../../Entities/GridType';
import { useEffect, useState } from 'react';
import { useUISettings } from '../../../../../context/network/http/QueryProvider/queries/UISettings';
import { useTPPermissions } from '../../../../../hooks/usePermissions';
import { useQueryClient } from 'react-query';
import { Breadcrumb, Checkbox, IBreadcrumbItem, IconButton, SearchBox, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { ResultList } from './components/ResultList/ResultList';
import { FilterList } from '../components/FilterList/FilterList';
import { buildRbsTree } from '../helpers/buildRbsTree';
import { GetOrderByAsString, makeProjectFilterDefaults, resolveProjectFieldName } from './helpers';
import { functionThatReturnsTrue } from '../../../../../helpers/functionPrimitives';
import { BreadcrumbGroupTitle, TFilter } from '../types';
import { useSearchedFilteredData } from './hooks/useSearchedFilteredData';
import { useCurrentProjectCategory } from './hooks/useCurrentProjectCategory';
import { ProjectListItem, RootSettings, UserType } from '../../../../../api/generated/data-contracts';
import { ListItemShimmer } from '../components/ListItemShimmer';
import { language } from '../../../../../Services/LocalizationService';
import { projectSchema } from '../../../../../forms/project/projectSchema';
import { useControlledState } from '../../../../../hooks/useControlledState';
import EnumEx from '../../../../../Utilities/EnumEx';
import { EntityPropertySelector } from '../../../../../components_new/EntityPropertySelector';
import { sortOverview } from '../../../../overviews/_components/helpers/sortHelper';

type ProjectPickerProps = {
    showAll: boolean;
    setShowAll: React.Dispatch<React.SetStateAction<boolean>>;
    isFetching: boolean;
    setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
    data: ProjectListItem[];
    queryKey: any;
    gridType: GridType;
    isFetched: boolean;
};

export const ProjectPicker: React.FC<ProjectPickerProps> = ({ setIsDragging, data, gridType, isFetching, queryKey, setShowAll, showAll, isFetched }) => {
    const { data: uiSettings } = useUISettings();

    const isSuperuser = useTPPermissions({ userTypes: [UserType.SuperUser] });

    const panelGroupings = useMemo(() => {
		let groupings: string[] = []
        if (EnumEx.equalsAny(gridType, GridType.Allocation, GridType.LmCapacityProjects)) {
            // return uiSettings.settings.projectPanelAllocationGroupings || [];
            // groupings = uiSettings.settings.projectPanelAllocationGroupings || [];

			// Custom properties might exist and might not. Make sure we only work with properties that actually exists
            groupings = uiSettings.settings.projectPanelAllocationGroupings?.filter(grouping => Boolean(resolveProjectFieldName(grouping, uiSettings.settings))) || [];
        }
        if (EnumEx.equalsAny(gridType, GridType.Request, GridType.WorkPackage)) {
            // return uiSettings.settings.projectPanelRequestGroupings || [];
            // groupings = uiSettings.settings.projectPanelRequestGroupings || [];

			// Custom properties might exist and might not. Make sure we only work with properties that actually exists
            groupings = uiSettings.settings.projectPanelRequestGroupings?.filter(grouping => Boolean(resolveProjectFieldName(grouping, uiSettings.settings)))  || [];
        }
		if (uiSettings.settings.includeContractStatusFilterInProjectPanel) {
			if (groupings.indexOf("virtualProperty.Contract status") < 0) {
				groupings.push("virtualProperty.Contract status");
			}
		}
        return groupings;
    // }, [gridType, uiSettings.settings.includeContractStatusFilterInProjectPanel, uiSettings.settings.projectPanelAllocationGroupings, uiSettings.settings.projectPanelRequestGroupings]);
    }, [gridType, uiSettings.settings]);

	const [filters, setFilters] = useState<TFilter[]>(() => makeProjectFilterDefaults(panelGroupings));
	const titles = useMemo(() => {
		return panelGroupings.map(grouping => {
			return resolveProjectFieldName(grouping, uiSettings.settings) || (grouping === 'rbs' ? 'RBS' : grouping);
		});
	}, [panelGroupings, uiSettings.settings]);
	const [breadcrumbTitles, setBreadcrumbTitles] = useControlledState(() => makeBreadcrumbDefaultTitles(panelGroupings, uiSettings.settings), [panelGroupings, uiSettings.settings]);

	const [currentFilterLevel, setCurrentFilterLevel] = useState(0);

	const filteredData = useMemo(() => {
		return filters.slice(0, currentFilterLevel).reduce((acc, { filterFn }) => acc.filter(filterFn), data);
	}, [currentFilterLevel, data, filters]);

    const skipNextReset = useRef(false);
    const queryClient = useQueryClient();
    const updateItem = useCallback(
        (item: ProjectListItem) => {
            skipNextReset.current = true;
            queryClient.setQueryData<ProjectListItem[]>(queryKey, resources => resources.map(resource => (resource.id === item.id ? item : resource)));
        },
        [queryClient, queryKey],
    );

    const updateFilterState = useCallback((grouping: string, filterFn: (resource: ProjectListItem) => boolean, breadcrumbText: string, filterDirection: number = 1) => {
		setFilters(filters => filters.map(filter => {
			if (filter.grouping === grouping) {
				return {...filter, filterFn}
			}
			return filter
		}))
		setBreadcrumbTitles(bcTitles => bcTitles.map(bcTitle => {
			if (bcTitle.grouping === grouping) {
				return {...bcTitle, title: breadcrumbText}
			}
			return bcTitle
		}))
		if (filterDirection) {
			setCurrentFilterLevel(level => level + filterDirection)
		}
	}, [setBreadcrumbTitles])

    const rbsTree = useMemo(() => {
        return buildRbsTree(data);
    }, [data]);

    const { currentCategory, resetCurrentRBSPath } = useCurrentProjectCategory({
        filterLevel: currentFilterLevel,
        data: filteredData,
        filters,
        settings: uiSettings.settings,
        panelGroupings,
        breadcrumbTitles,
		updateFilterState,
    });

    useEffect(() => {
        if (!skipNextReset.current) {
            setFilters(makeProjectFilterDefaults(panelGroupings));
            setCurrentFilterLevel(1);
            resetCurrentRBSPath();
        }
        skipNextReset.current = false;
    }, [showAll, panelGroupings, resetCurrentRBSPath]);

    const breadcrumbItems = useMemo(() => {
        return [
            {
                key: 'root',
                text: '/',
                onClick: () => {
                    setCurrentFilterLevel(1);
                    setFilters(makeProjectFilterDefaults(panelGroupings));
                    resetCurrentRBSPath();
                    setBreadcrumbTitles(makeBreadcrumbDefaultTitles(panelGroupings, uiSettings.settings));
                },
            },
            ...filters.slice(0, currentFilterLevel - 1).map((_, slicedFilterIndex) => {
                const { title } = breadcrumbTitles[slicedFilterIndex];
                return {
                    key: title + slicedFilterIndex,
                    text: title,
                    onClick: () => {
                        setCurrentFilterLevel(slicedFilterIndex + 1);
                        setFilters(filters => {
                            return filters.map((filter, filterIndex) => {
                                if (filterIndex >= slicedFilterIndex) {
                                    return {
                                        ...filter,
                                        filterFn: functionThatReturnsTrue,
                                    };
                                }
                                return filter;
                            });
                        });
                    },
                };
            }),
        ] as IBreadcrumbItem[];
    }, [breadcrumbTitles, currentFilterLevel, filters, panelGroupings, resetCurrentRBSPath, setBreadcrumbTitles, uiSettings.settings]);

    const [searchFilteredData, searchOnChange, searchText] = useSearchedFilteredData(filteredData, uiSettings.settings);

    const [orderBy, setOrderBy] = useState<string | null>(null);
    const [orderByDescending, setOrderByDescending] = useState(false);

    const sortedData = useMemo(() => {
        if (!orderBy) {
            return searchFilteredData;
        }
		const sorted = [...searchFilteredData].sort((a, b) => sortOverview(a, b, orderBy, orderByDescending));
		return sorted;
    }, [orderBy, orderByDescending, searchFilteredData]);

    const currentTitle = titles[currentFilterLevel - 1];

    return (
        <Stack styles={{ root: { height: '100%' } }} tokens={{ childrenGap: 10 }}>
            <SearchBox className="tp-panel-searchbox tp-searcher" onChange={searchOnChange} autoFocus />
            <Stack horizontal horizontalAlign="space-between" tokens={{ padding: '0 24px 0 0' }}>
                {isSuperuser || uiSettings.settings.allowSearchAllProjects ? (
                    <Checkbox
                        className="tp-list-checkbox"
                        label="Search all projects"
                        styles={{ text: { whiteSpace: 'nowrap' } }}
                        onChange={(e, c) => setShowAll(c)}
                        disabled={isFetching}
                        checked={showAll}
                    />
                ) : null}
                <EntityPropertySelector
                    value={orderBy}
                    schema={projectSchema}
                    placeHolder={language.Projects.EntityPropertySelectorPlaceholder}
                    propertyTypes={'both'}
                    onlyTheseProperties={Object.keys(uiSettings.settings.projectPickerDisplayFields).map(
                        _ => uiSettings.settings.projectPickerDisplayFields[_],
                    )}
                    customProperties={uiSettings.settings.projectCustomProperties}
                    onChange={item => setOrderBy(!item ? null : GetOrderByAsString(item as string))}
                    styles={{ root: { width: '100%' } }}
                />
                <IconButton
                    iconProps={{ iconName: 'Sort', styles: { root: { color: `${orderByDescending ? 'red' : ''}` } } }}
                    onClick={() => setOrderByDescending(obd => !obd)}
                    disabled={!orderBy}
                />
            </Stack>
            <Stack styles={{ root: { minHeight: 32, paddingRight: 24 } }} horizontalAlign="end">
                {isFetching && <Spinner size={SpinnerSize.large} labelPosition="right" label="Retrieving data.." />}
            </Stack>
            <Stack>
                {Boolean(panelGroupings.length) && (
                    <>
                        <Breadcrumb
                            styles={{ root: { '.ms-Breadcrumb-listItem:last-child .ms-Breadcrumb-itemLink': { fontWeight: 'inherit' } } }}
                            items={breadcrumbItems}
                        />
                        <Text variant="xLarge">{currentTitle || (searchFilteredData.length === 1 ? 'Result' : 'Results')} &nbsp;</Text>
                        <FilterList
                            // gridType as key ensures that the filter resets when the view changes
                            key={gridType}
                            category={currentCategory}
                            rbsTree={rbsTree}
							setCurrentFilterLevel={setCurrentFilterLevel}
                        />
                    </>
                )}
                <Text>
                    {sortedData.length} {sortedData.length === 1 ? 'result' : 'results'}
                </Text>
            </Stack>
            {!isFetched && (
                <>
                    <ListItemShimmer />
                    <ListItemShimmer opacity={0.5} />
                </>
            )}
            <ResultList
                items={sortedData}
                gridType={gridType}
                settings={uiSettings.settings}
                updateItem={updateItem}
                setIsDragging={setIsDragging}
                showAll={showAll}
                searchText={searchText}
            />
        </Stack>
    );
};


const makeBreadcrumbDefaultTitles = (panelGroupings: string[], settings: RootSettings) =>
    panelGroupings.map(grouping => {
        return {
            title: resolveProjectFieldName(grouping, settings) || (grouping === 'rbs' ? 'RBS' : grouping),
            grouping,
        } as BreadcrumbGroupTitle;
    });
