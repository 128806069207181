/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-refresh/only-export-components */
import React, { FunctionComponent, useState, useEffect, useContext, useRef, useCallback } from 'react';
import { hasVerticalOverflow } from '@fluentui/react/lib/Utilities';
import { Plugin } from '../../Services/PluginInvoker';
import { PluginIDs } from '../../Utilities/PluginIDs';
import { TimelineResolution } from '../../Entities/TimelineResolution';
import { TopRow } from '../../Entities/Table/TopRow';
import Table from './Table/Table';
import { Cell } from '../../Entities/Table/Cell';
import { AddActivityDto, WorkType } from '../../Entities/Dto/AddActivityDto';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { DataService } from '../../Services/DataService';
import { GridType } from '../../Entities/GridType';
import { EntityType } from '../../Entities/EntityTypes';
import { ResourceEx } from '../../Utilities/ResourceEx';
import { ArrayEx } from '../../Utilities/ArrayEx';
import { Row } from '../../Entities/Table/Row';
import { CellType } from '../../Entities/Table/CellType';
import { GroupRow } from '../../Entities/Table/GroupRow';
import { ProjectCapacityService } from '../../Services/ProjectCapacityService';
import { JsonEx } from '../../Utilities/JsonEx';
import { PFSpinner } from '../Common/Spinner';
import { Notification, NotificationStatusType } from '../../Entities/Notification';
import { MessageBarButtonInfo } from '../../Entities/Fabric/MessageBarButtonInfo';
import { IContextualMenuItem, ContextualMenuItemType } from '@fluentui/react';
import { Permission } from '../../Services/Permission';
import { GroupContainerRow } from '../../Entities/Table/GroupContainerRow';
import { IDs } from '../../Utilities/IDs';
import { guid } from '../../helpers/guid';
import { StoreContext } from '../../Services/StateStore';
import { useTheme } from '../../Themes/themeContext';
import { ProjectType } from '../../Entities/ProjectType';
import { ConfirmDialog } from '../Common/ConfirmDialog';
import { UndoItem } from '../../Entities/UndoItem';
import { ResourceType } from '../../Entities/ResourceType';
import { CellUpdateDto } from './Table/DragCopy/CellUpdateDto';
import { UserContext } from '../../Utilities/Context/UserContext';
import { CapacityUtil } from '../../Utilities/CapacityUtil';
import { LocalStorage } from '../../Utilities/LocalStorage';
import { ResourcePanelItem } from '../../ListItems/ResourcePanelItem';
import { language } from '../../Services/LocalizationService';
import ProjectDraftDialog from '../Common/ProjectDraftDialog';
import { ProjectScenario, Scenario, ScenarioType } from '../../Entities/Scenario';
import { UserSettingsService } from '../../Services/Settings/UserSettingsService';
import { ObjectEx } from '../../Utilities/ObjectEx';
import { Sticky } from '../../Entities/Sticky';
import { TableCustomColumn } from './Table/TableCustomColumn';
import { BaseRow } from '../../Entities/Table/BaseRow';
import CompareScenariosDialog from '../Common/ProjectVersionsDialog';
import { ResolutionUtil } from '../../Utilities/ResolutionUtil';
import { DateEx } from '../../Utilities/DateEx';
import { useStore } from '../../context/store';
import shallow from 'zustand/shallow';
import { useNavigate } from 'react-router';
import { buildRoute } from '../../helpers/routeHelpers';
import { stringVariants } from '../../helpers/stringVariants';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { useCurrentProject } from '../../context/network/http/QueryProvider/queries/project';
import { capitalize } from '../../context/network/http/QueryProvider/helpers/queryHelper';
import { ProposalState } from '../../api/generated/data-contracts';
import { resetAlignStatus, shouldResetAlignStatus } from '../../Utilities/GridUtils';

export type ExtraContentEvent = (event: any, row: BaseRow) => Promise<void>;

export interface IProjectCapacityCallbacks {
    getCapacityGrid: (projectId: string, start: Date, end: Date, resolution: TimelineResolution, gridType: GridType, groupBy: string) => Promise<TopRow>;
    changeContractContainerName: (groupIds: Array<string>, newName: string) => Promise<void>;
    changeContractContainer: (groupId: string, containerName: string) => Promise<void>;
    changeContractName: (groupId: string, newName: string) => Promise<void>;
    resourceRemove: (
        gridType: GridType,
        contractId: string,
        resourceId: string,
        start: Date,
        resolution: TimelineResolution,
        groupProperties?: { [key: string]: any },
    ) => Promise<void>;
    updateCell: (dto: AddActivityDto, cellId: string, ignoreContainerName?: boolean, groupProperties?: { [key: string]: any }) => Promise<string>;
    updateMultipleCells: (
        dtos: Array<AddActivityDto>,
        cellIds: Array<string>,
        ignoreContainerName?: boolean,
        groupProperties?: { [key: string]: any },
    ) => Promise<void>;
    titleContentOverride: (title: string) => JSX.Element;
    rowExtraContent: (row: Row) => JSX.Element;
    rowClick?: (row: Row) => void;
    groupExtraContent: (group: GroupRow, onResourceAdd: ExtraContentEvent) => JSX.Element;
    groupExtraTextContent: (group: GroupRow) => JSX.Element;
    groupContainerExtraContent: (
        name: string,
        container: GroupContainerRow,
        onResourceAddToContainer: ExtraContentEvent,
        onGroupAdd: ExtraContentEvent,
    ) => JSX.Element;
    // updateResourceListFilter: (ids: Array<string>, matchRbs: boolean) => void;
    alterNewResourceRow?: (row: Row) => void;
    rowDraggable?: (row: Row) => boolean;
}

interface IProps {
    projectId: string;
    start: Date;
    end: Date;
    resolution: TimelineResolution;
    gridType: GridType;
    callbacks: IProjectCapacityCallbacks;
    customColumns: Array<TableCustomColumn>;
    extraMenuItems: Array<IContextualMenuItem>;
    disableDragUpdates?: boolean;
    refreshKey?: string;
}

const DefaultProjectCapacity: FunctionComponent<IProps> = props => {
    const { setUIContext, setTimeline, addNotification, error, info, success, warning } = useStore(
        store => ({
            setUIContext: store.setUIContext,
            setTimeline: store.setTimeline,
            error: store.addErrorNotification,
            info: store.addInfoNotification,
            warning: store.addWarningNotification,
            addNotification: store.addNotification,
            success: store.addSuccesNotification,
        }),
        shallow,
    );
    const { data: project } = useCurrentProject(props.projectId);
    const { data: uiSettings } = useUISettings();
    const goTo = useNavigate();

    const [view, _setView] = useState<TopRow>();
    const viewRef = useRef<TopRow>(view);
    const [loading, _setLoading] = useState(true);
    const adminRef = useRef<boolean>(false);
    const [name, setName] = useState<string>('');
    const ignoreResolutionMismatch = useRef(false);

    const [showDraftDialog, setShowDraftDialog] = useState<boolean>(false);
    const [refreshAvailableScenarios, setRefreshAvailableScenarios] = useState<string>(null);
    const [showVersionsDialog, setShowVersionsDialog] = useState<boolean>(false);

    const groupByRef = useRef<string>(LocalStorage.get('tp-projectcapacity-groupby'));

    const setView = (view: TopRow) => {
        if (view != null) view.ChangeKey = IDs.makeId();
        viewRef.current = view; // keep updated
        _setView(view);
    };

    const setLoading = (loading: boolean) => {
        _setLoading(loading);
    };
    const setAdminProject = (isAdmin: boolean) => {
        adminRef.current = isAdmin; // keep updated
    };

    const ctx = useContext(StoreContext);
    const theme = useTheme();

    useEffect(() => {
        // Check for valid 'Align Contract' feature configuration
        if (OverviewContext.Settings.EnableAlignContract && !OverviewContext.Settings.AlignContractStatuses.length) {
            error("Please contact your administrator! 'Align Contract' feature is enabled, but Contract Align statuses are not defined in the configuration!");
        }
    }, []);

    useEffect(() => {
        if (loading) {
            // uiCtx.setBottomHasScroll(false)
            setUIContext({ bottomHasScroll: false });
        } else {
            // uiCtx.setBottomHasScroll(hasVerticalOverflow(document.getElementById('bottom-main')))
            setUIContext({ bottomHasScroll: hasVerticalOverflow(document.getElementById('bottom-main')) });
        }
    }, [loading, setUIContext]);

    useEffect(() => {
        savedActions.current = [];
        let mounted = true;
        if (viewRef.current == null && props.start == null && props.end == null) {
            setTimeout(() => {
                // if (!isMounted.current) return;
                if (!mounted) return;
                if (viewRef.current != null || (props.start != null && props.end != null)) return;
                // NotificationService.Instance.Warning(language.ProjectCapacity.CachedSelectedDatesAreInvalid);
                warning(language.ProjectCapacity.CachedSelectedDatesAreInvalid);
                setLoading(false);
            }, 6000);
            return;
        }
        const getView = async () => {
            setLoading(true);
            await LoadCapacity();
        };
        getView();
        OverviewContext.RefreshProjectCapacity = ForceRefreshData;
        OverviewContext.AddResource = AddResource;

        return () => {
            mounted = false;
        };
        // }, [ctx.Get(_ => _.MainView), props.gridType, props.resolution, props.start, props.end, props.projectId]);
    }, [props.gridType, props.resolution, props.start, props.end, props.projectId]);

    useEffect(() => {
        if (props.refreshKey == null) return;
        ForceRefreshData();
    }, [props.refreshKey]);

    const ForceRefreshData = async (groupBy?: string) => {
        // update contract groupby property
        groupByRef.current = groupBy;
        LocalStorage.set('tp-projectcapacity-groupby', groupBy);
        setLoading(true);
        await LoadCapacity(true);
    };

    //TODO: refactor - looks like crap! - ewi
    const LoadCapacity = async (isRefresh?: boolean): Promise<void> => {
        // running.begin();

        if (!props.projectId) {
            setLoading(false);
            // running.end();
            return;
        }
        // const project = await DataService.Get<Project>(EntityType.Project, props.projectId);
        if (!project) {
            setView(null);
            setLoading(false);
            // running.end();
            return;
        }
        // check if user has permission to request on the selected project
        if (props.gridType === GridType.Request) {
            OverviewContext.UserIsPmForSelectedProject = await ResourceEx.projectMatch(project, uiSettings.resource);

            const pmOrSuper = OverviewContext.UserIsPmForSelectedProject || Permission.IsSuperuser();
            const searchAllAndPm = OverviewContext.Settings.AllowSearchAllProjects && Permission.IsPM();
            const isAdmin = project.projectType === ProjectType.Administrative;

            if ((!pmOrSuper && !searchAllAndPm) || isAdmin) {
                info(language.ProjectCapacity.Project.Select);
                setView(null);
                setLoading(false);
                return;
            }
        }

        // get capacity grid
        const model = !props.start || !props.end ? null : await GetCapacity(props.projectId, props.start, props.end, props.resolution, props.gridType);
        if (!model) {
            setView(null);
            setLoading(false);
            return;
        }

        if (project.projectType === ProjectType.Team) ctx.Set({ ProjectTeamSelected: true });
        else ctx.Set({ ProjectTeamSelected: false });

        if (!isRefresh) return;

        setName(project.name);
        setView(model);
        setAdminProject(project.projectType === ProjectType.Administrative);
        setLoading(false);
        setRefreshAvailableScenarios(IDs.makeId());
    };

    const GetCapacity = async (projectId: string, start: Date, end: Date, resolution: TimelineResolution, gridType: GridType): Promise<TopRow> => {
        if (!projectId) return null;
        return props.callbacks.getCapacityGrid(projectId, start, end, resolution, gridType, groupByRef.current);
    };

    const GetActivictyColor = (cell: Cell, index: number, columnIndex: number, cellType: CellType): string => {
        if (adminRef.current) return theme.semanticColors.activityEqualBackground;
        return ProjectCapacityService.GetActivityColor(view, cell, index, columnIndex, cellType, theme);
    };

    const OnResourceAdd = async (event: any, row?: GroupRow) => {
        const resource = JsonEx.parse(event.dataTransfer.getData('resourcelistitem')) as ResourcePanelItem;
        await AddResource(resource, row);
    };

    const OnResourceAddToContainer = async (event: any, container: GroupContainerRow) => {
        const resource = JsonEx.parse(event.dataTransfer.getData('resourcelistitem')) as ResourcePanelItem;
        const groupName = GetPropertyValue(resource) || resource.Name;
        const updatedModel = await ProjectCapacityService.AddResourceToContainer(
            viewRef.current,
            viewRef.current.Id,
            container.Id,
            resource.id,
            groupName,
            props.start,
            props.end,
            props.resolution,
            props.gridType,
            props.callbacks.alterNewResourceRow,
        );
        if (updatedModel) {
            ProjectCapacityService.ForceRefreshCells(updatedModel);
            setView({ ...updatedModel });
        }
    };

    const GetPropertyValue = (item: ResourcePanelItem): string => {
        const field = OverviewContext.Settings.EnableDynamicContractNames
            ? language.CapacityViews.NameLabel
            : capitalize(OverviewContext.Settings.ContractDefaultName);
        return stringVariants.getAsStringValue(item, field);
    };

    const AddResource = async (resource: ResourcePanelItem, groupRow?: GroupRow) => {
        // uiCtx.setScrollToAddedResource(resource.id);
        setUIContext({ scrollToAddedResource: resource.id });
        const groupName = GetPropertyValue(resource) || resource.Name;
        const updatedModel = await ProjectCapacityService.AddResource(
            viewRef.current,
            viewRef.current.Id,
            resource.id,
            groupName,
            groupRow ? groupRow.Id : null,
            props.start,
            props.end,
            props.resolution,
            props.gridType,
            warning,
            resource.RelatedGenericResourceId,
            project?.projectType,
            true,
            groupByRef.current,
            props.callbacks.alterNewResourceRow,
        );
        if (updatedModel) {
            ProjectCapacityService.ForceRefreshCells(updatedModel);
            setView({ ...updatedModel });
        }
        [].forEach.call(document.querySelectorAll('.tp-list-capacity-dropzone-overlay'), _ => (_.style.display = 'none'));
    };

    const RemoveResource = async (resource: Row, group: GroupRow): Promise<void> => {
        document.getElementById('tp-list-resources-dropzone-overlay').style.display = 'none';

        // remove resource
        const updatedModel = await ProjectCapacityService.OnResourceRemove(
            view,
            props.gridType,
            resource,
            group,
            props.resolution,
            (gridType: GridType, contractId: string, resourceId: string, start: Date, resolution: TimelineResolution) =>
                props.callbacks.resourceRemove(gridType, contractId, resourceId, start, resolution, group.Properties),
        );
        if (updatedModel) {
            setView({ ...updatedModel });
            success(`${language.ProjectCapacity.RemoveResource} ${resource.Name}`);
        }
    };

    const OnGroupAdd = async (event: any, container?: GroupContainerRow) => {
        let containerName: string;
        const groupRow = JsonEx.parse(event.dataTransfer.getData('grouprow')) as GroupRow;
        // if container is different from existing, generate container name
        // if same container, extract/remove from container
        if (!container || groupRow.Properties.ContainerId !== container.Id) containerName = container ? container.Name : `Group_${IDs.makeId()}`;
        // update contract
        setLoading(true);
        await props.callbacks.changeContractContainer(groupRow.Id, containerName);
        const model = await GetCapacity(props.projectId, props.start, props.end, props.resolution, props.gridType);
        setView(model);
        setLoading(false);
    };

    const ChangeContractName = (group: GroupRow, oldName: string, newName: string) => {
        if (oldName === newName || !oldName || !newName) return;
        if (!group) return;
        // Plugin.Invoke<string>(PluginIDs.ChangeContractName, { ContractId: group.Id, Name: newName })
        props.callbacks
            .changeContractName(group.Id, newName)
            // .then(_ => NotificationService.Instance.Success("Contract renamed"))
            .catch(_ => error(language.ProjectCapacity.ChangeContractName));
        // .catch(_ => NotificationService.Instance.Error(language.ProjectCapacity.ChangeContractName));
    };

    const ChangeContractContainerName = (container: GroupContainerRow, oldName: string, groupIds: Array<string>) => {
        if (oldName === container.Name || !oldName || !container.Name) return;
        if (!groupIds.length) return;
        // Plugin.Invoke<string>(PluginIDs.ChangeContractGroupName, { NewName: container.Name, ContractIds: groupIds })
        props.callbacks
            .changeContractContainerName(groupIds, container.Name)
            // .then(_ => NotificationService.Instance.Success("Contract group renamed"))
            .catch(_ => error(language.ProjectCapacity.ChangeContractContainerName));
        // .catch(_ => NotificationService.Instance.Error(language.ProjectCapacity.ChangeContractContainerName));
    };

    const savedActions = useRef<Array<UndoItem>>([]);

    const SaveActions = (resolution: TimelineResolution, workType: WorkType, idx: number, cells: Array<CellUpdateDto>) => {
        const item = new UndoItem(resolution, workType, idx, cells);
        savedActions.current.push(item);
    };

    const Undo = () => {
        if (!OverviewContext.Settings.EnableGridUndo) return;
        const item = savedActions.current.pop();
        if (item == null) {
            // NotificationService.Instance.Info(language.CapacityViews.Undo.NothingToUndo, 2000);
            info(language.CapacityViews.Undo.NothingToUndo, 2000);
            return;
        }
        const updatedModel: TopRow = OnMultipleCellsUpdate(view, item.resolution, item.workType, item.idx, item.cells, true);
        // highlight undo'ed items
        ProjectCapacityService.HighlightCell(
            item.cells.map(_ => _.Cell.Id),
            true,
        );
        // update view
        setView({ ...updatedModel });
        // notify
        // NotificationService.Instance.Info(language.CapacityViews.Undo.UndidPreviousCellEdit, 2000);
        info(language.CapacityViews.Undo.UndidPreviousCellEdit, 2000);
    };

    // TODO: refactor so theres only one way of updating cell, using an array - same in API - ewi
    const OnCellUpdate = (
        view: TopRow,
        adminProject: boolean,
        resolution: TimelineResolution,
        workType: WorkType,
        columnIndex: number,
        idx: number,
        cell: Cell,
        newValue: number,
        isUndo?: boolean,
    ): TopRow => {
        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);

        const group = flattenedGroups.find(_ => _.Id === cell.Properties.ContractId);
        // check on 'IsRequested' as well due to the fact that we now can have two resourcerows with the same resource (when a resource is both requested and allocated)
        const resource = group.Rows.find(_ => _.Id === cell.Properties.ResourceId && _.Properties.IsRequested === cell.Properties.IsRequested);
        const cellRow = resource.CellRows[columnIndex];

        // handle allocation on different resolution than request
        if (
            OverviewContext.Settings.EnableGridResolutionMismatchWarning &&
            !adminProject &&
            !ignoreResolutionMismatch.current &&
            cellRow.Properties.ResolutionMismatch
        ) {
            ProjectCapacityService.HighlightCell([cell.Id], true, 'highlighterror'); // TODO: this only works when stepping in debug, WHY GOD WHY?? - ewi

            warning(
                language.ProjectCapacity.OnCellUpdate.DifferentTimelineResolution.replace(
                    '[[timelineresolution]]',
                    TimelineResolution[cellRow.Properties.RequestResolution].toString(),
                ),
            );

            const notification = new Notification();
            notification.Message = language.CapacityViews.OnCellUpdate.NotificationMessage;
            notification.StatusType = NotificationStatusType.SevereWarning;
            notification.Buttons = [
                new MessageBarButtonInfo(language.CapacityViews.OnCellUpdate.Disable, () => (ignoreResolutionMismatch.current = true)),
                new MessageBarButtonInfo(language.ProjectCapacity.OnCellUpdate.Switch, () =>
                    OverviewContext.ChangeResolution(cellRow.Properties.RequestResolution),
                ),
            ];
            addNotification(notification);

            return view;
        }

        // store action to be used in 'undo' feature
        if (!isUndo && OverviewContext.Settings.EnableGridUndo)
            SaveActions(resolution, workType, idx, [{ Cell: cell, ColumnIndex: columnIndex, Value: cell.Value }]);

        // get container if any
        const container =
            group.Properties.ContainerId !== guid.empty ? (view.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow) : null;

        // reset align status
        if (shouldResetAlignStatus(workType)) {
            group.Properties.AlignStatus = resetAlignStatus(workType);
        }

        ProjectCapacityService.UpdateCellDomValue(view, flattenedGroups, container, group, resource, cellRow, workType, columnIndex, idx, newValue);

        // save cell
        const dto = ProjectCapacityService.BuildSaveCellDto(resolution, workType, cell, cellRow, group.Name, container?.Name);
        props.callbacks.updateCell(dto, cell.Id, groupByRef.current != null, group.Properties);
        // ProjectCapacityService.SaveCell(dto, cell.Id, groupByRef.current != null);
        // ctx.Set({GridHasChanges: true});
        setUIContext({ gridHasChanged: true });
        return view;
    };

    const OnMultipleCellsUpdate = (
        view: TopRow,
        resolution: TimelineResolution,
        workType: WorkType,
        idx: number,
        cells: Array<CellUpdateDto>,
        isUndo?: boolean,
    ): TopRow => {
        // store actions to be used in 'undo' feature
        if (!isUndo && OverviewContext.Settings.EnableGridUndo)
            SaveActions(
                resolution,
                workType,
                idx,
                cells.map(_ => {
                    return { Cell: _.Cell, ColumnIndex: _.ColumnIndex, Value: _.Cell.Value };
                }),
            );

        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);
        const dtos = cells
            .filter(_ => _.ColumnIndex < 100000)
            .map(item => {
                const group = flattenedGroups.find(_ => _.Id === item.Cell.Properties.ContractId);
                const resource = group.Rows.find(
                    _ => _.Id === item.Cell.Properties.ResourceId && _.Properties.IsRequested === item.Cell.Properties.IsRequested,
                );
                const cellRow = resource.CellRows[item.ColumnIndex];
                // get container if any
                const container =
                    group.Properties.ContainerId !== guid.empty ? (view.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow) : null;

                // reset align status
                if (shouldResetAlignStatus(workType)) {
                    group.Properties.AlignStatus = resetAlignStatus(workType);
                }

                ProjectCapacityService.UpdateCellDomValue(
                    view,
                    flattenedGroups,
                    container,
                    group,
                    resource,
                    cellRow,
                    workType,
                    item.ColumnIndex,
                    idx,
                    item.Value,
                );

                const dto = ProjectCapacityService.BuildSaveCellDto(resolution, workType, item.Cell, cellRow, group.Name, container?.Name);
                return dto;
            });

        props.callbacks.updateMultipleCells(
            dtos,
            cells.map(_ => _.Cell.Id),
            groupByRef.current != null,
        );
        ProjectCapacityService.SaveCells(
            dtos,
            cells.map(_ => _.Cell.Id),
            error,
        );
        // ctx.Set({GridHasChanges: true});
        setUIContext({ gridHasChanged: true });
        return view;
    };

    const TopCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 0, colIdx, CellType.Top)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return {
                    field: { backgroundColor: `${GetActivictyColor(cell, 1, colIdx, CellType.Top)} !important` },
                    root: { paddingRight: props.gridType === GridType.Allocation && !adminRef.current ? null : '10px' },
                };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' } };
            },
        ];
        if (adminRef.current) styles.splice(0, 1);
        return styles;
    };

    const GroupCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 0, colIdx, CellType.Group)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return {
                    field: { backgroundColor: `${GetActivictyColor(cell, 1, colIdx, CellType.Group)} !important` },
                    root: {
                        paddingRight: props.gridType === GridType.Allocation && !adminRef.current ? null : '10px',
                        cursor: props.gridType === GridType.Request ? 'pointer' : '',
                    },
                };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' } };
            },
        ];
        if (adminRef.current) styles.splice(0, 1);
        return styles;
    };

    const GroupContainerCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 0, colIdx, CellType.Container)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return {
                    field: { backgroundColor: `${GetActivictyColor(cell, 1, colIdx, CellType.Container)} !important` },
                    root: {
                        paddingRight: props.gridType === GridType.Allocation && !adminRef.current ? null : '10px',
                        cursor: props.gridType === GridType.Request ? 'pointer' : '',
                    },
                };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' } };
            },
        ];
        if (adminRef.current) styles.splice(0, 1);
        return styles;
    };

    const RowCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return !cell.Properties.GrandTotal
                    ? null
                    : { root: { backgroundColor: document.documentElement.style.getPropertyValue('--rowHoverBackgroundColor') } };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: props.gridType === GridType.Allocation && !adminRef.current ? null : '10px' } };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' }, field: { backgroundColor: `${CapacityUtil.GetFreeCapacityColor(theme, cell.Value)} !important` } };
            },
        ];
        if (adminRef.current) styles.splice(0, 1);
        return styles;
    };

    const CellUpdates = () => {
        const updates = [
            (cell: Cell, columnIndex: number, value: number, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                let updatedModel: TopRow;
                if (cell.Properties.IsProposedColumn) {
                    const dto = {
                        Origin: guid.empty,
                        Entity: EntityType.Contract,
                        Items: [
                            {
                                TypeName: 'Contract',
                                Id: cell.Properties.ContractId,
                                ProjectId: cell.Properties.ProjectId,
                                ResourceGuid: cell.Properties.ResourceId,
                                Name: cell.Properties.ContractName,
                                ProposedWork: value,
                                Stickyness: Sticky.Always,
                            },
                        ],
                        ChangedProperties: ['Id', 'Name', 'ProjectId', 'ResourceGuid', 'ProposedWork', 'Stickyness'],
                        Force: '2A02C94C-D08A-4F54-B215-FB3A3076E68C', // force upsert key on delta plugin
                    };
                    cell.Properties.ProposedWork = value;
                    cell.Value = value;
                    const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);
                    const group = flattenedGroups.find(_ => _.Id === cell.Properties.ContractId);
                    group.Properties.ProposedWork = value;
                    Plugin.InvokeNoResponse(PluginIDs.DeltaUpsert, dto)
                        .then(_ => {
                            ProjectCapacityService.HighlightCell([cell.Id], true, 'highlightdone');
                            setUIContext({ gridHasChanged: true });
                        })
                        .catch(_ => {
                            error(language.Common.FailedSavingCells);
                            ProjectCapacityService.HighlightCell([cell.Id], true, 'highlighterror');
                            setUIContext({ gridHasChanged: true });
                        });
                } else {
                    updatedModel = OnCellUpdate(view, adminRef.current, props.resolution, WorkType.Request, columnIndex, 0, cell, value);
                    setView({ ...updatedModel });
                }
            },
            (cell: Cell, columnIndex: number, value: number, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const updatedModel = OnCellUpdate(
                    view,
                    adminRef.current,
                    props.resolution,
                    WorkType.Allocation,
                    columnIndex,
                    adminRef.current ? 0 : 1,
                    cell,
                    value,
                );
                setView({ ...updatedModel });
            },
        ];
        if (adminRef.current) updates.splice(0, 1);
        return updates;
    };

    const DragUpdates = () => {
        const updates = [
            (cells: Array<CellUpdateDto>) => {
                const updatedModel = OnMultipleCellsUpdate(view, props.resolution, WorkType.Request, 0, cells);
                setView({ ...updatedModel });
            },
            (cells: Array<CellUpdateDto>) => {
                const updatedModel = OnMultipleCellsUpdate(view, props.resolution, WorkType.Allocation, adminRef.current ? 0 : 1, cells);
                setView({ ...updatedModel });
            },
        ];
        if (adminRef.current) updates.splice(0, 1);

        return updates;
    };

    const ExcelCopyUpdate = (cells: Array<CellUpdateDto>, excessiveCells: Array<CellUpdateDto>) => {
        const index = cells[0].CellIndex;
        const workType = adminRef.current ? WorkType.Allocation : index === 0 ? WorkType.Request : WorkType.Allocation;
        const updatedModel = OnMultipleCellsUpdate(view, props.resolution, workType, index, cells);
        setView({ ...updatedModel });
        // highlight copied items
        ProjectCapacityService.HighlightCell(
            cells.map(_ => _.Cell.Id),
            true,
        );
        if (excessiveCells.length) {
            warning(language.CapacityViews.ExcelCopyUpdate);
        }
    };

    const GetGroupGenericResourceId = (group: GroupRow): string => {
        const row = group.Rows.find(_ => _.Properties.ResourceType === ResourceType.Generic || _.Properties.ResourceType === ResourceType.Pool);
        if (!row) return 'none';
        return row.Properties.ResourceId;
    };

    const GroupDragStart = (group: GroupRow, container: GroupContainerRow, event: any) => {
        if (props.gridType === GridType.Request || adminRef.current) {
            // remove from own container
            if (container) {
                [].forEach.call(
                    document.querySelectorAll(`#gtc_${container.Id} .tp-list-capacity-removefromcontainer-dropzone-overlay`),
                    _ => (_.style.display = 'block'),
                );
                // add to another container
                [].forEach.call(
                    document.querySelectorAll(`.tp-grid-groupd-header > div:not(#gtc_${container.Id}) .tp-list-capacity-addcontainer-dropzone-overlay`),
                    _ => (_.style.display = 'block'),
                );
            } else
                [].forEach.call(
                    document.querySelectorAll(`.tp-grid-groupd-header .tp-list-capacity-addcontainer-dropzone-overlay`),
                    _ => (_.style.display = 'block'),
                );

            [].forEach.call(document.querySelectorAll(`.tp-list-capacity-addnewcontainer-dropzone-overlay`), _ => (_.style.display = 'block'));

            (event as any).dataTransfer.setData('grouprow', JsonEx.stringify(group));
            (event as any).dataTransfer.effectAllowed = 'all';
        }
        if ((OverviewContext.Settings.EnableMergeContracts && props.gridType === GridType.Allocation) || adminRef.current) {
            const row = group.Rows.find(_ => _.Properties.IsRequested);
            const genRes = !row ? null : row.Properties.ResourceId;
            // if row without request row, highlight all other groups
            const hasGenres = GetGroupGenericResourceId(group) !== 'none';
            if (hasGenres)
                [].forEach.call(
                    document.querySelectorAll(
                        `[data-genresid='${genRes}'].tp-list-capacity-mergegroup-dropzone-overlay, [data-genresid='none'].tp-list-capacity-mergegroup-dropzone-overlay`,
                    ),
                    _ => (_.style.display = 'block'),
                );
            else [].forEach.call(document.querySelectorAll(`.tp-list-capacity-mergegroup-dropzone-overlay`), _ => (_.style.display = 'block'));

            (event as any).dataTransfer.setData('grouprow', JsonEx.stringify(group));
            (event as any).dataTransfer.effectAllowed = 'all';
        }
    };

    const RowSort = (group: GroupRow): Array<Row> => {
        group.Rows.sort((a, b) => {
            // if resourcetype is the same, sort by name
            if (a.Properties.ResourceType === b.Properties.ResourceType && a.Properties.IsRequested === b.Properties.IsRequested)
                return a.Name.localeCompare(b.Name);
            // generics is first
            if (a.Properties.IsRequested) return -1;
            // then requested that is also allocated
            if (a.Id === group.Properties.RequestedResourceId && !b.Properties.IsRequested) return -1;
            // then named
            if (a.Properties.ResourceType === ResourceType.Named && !b.Properties.IsRequested && b.Id !== group.Properties.RequestedResourceId) return -1;
            // last category and teams
            else return 1;
        });
        return group.Rows;
    };

    const GetExtraMenuItems = (): Array<IContextualMenuItem> => {
        const menuItems: Array<IContextualMenuItem> = [];

        const groupByItems: Array<IContextualMenuItem> = [
            {
                key: 'default',
                text: language.ProjectCapacity.Default,
                title: language.ProjectRowMenu.GroupBy.ContractDefined,
                onClick: () => void ForceRefreshData(null),
                disabled: groupByRef.current == null,
            },
            {
                key: 'rbs',
                text: language.Common.Rbs,
                title: language.ProjectRowMenu.GroupBy.ResourceRBS,
                onClick: () => void ForceRefreshData('RBS'),
                disabled: groupByRef.current === 'RBS',
            },
            {
                key: 'workpackage',
                text: language.Common.WorkPackage,
                title: language.ProjectRowMenu.GroupBy.WorkPackage,
                onClick: () => void ForceRefreshData('WorkPackage'),
                disabled: groupByRef.current === 'WorkPackage',
            },
        ];

        if (OverviewContext.Settings.EnableWorkPackages) {
            groupByItems.push({
                key: 'requesttemplate',
                text: language.ProjectCapacity.RequestTemplate,
                title: language.ProjectRowMenu.GroupBy.OriginatedRequestTemplate,
                onClick: () => void ForceRefreshData('RequestTemplate'),
                disabled: groupByRef.current === 'RequestTemplate',
            });
            if (props.gridType !== GridType.WorkPackage)
                groupByItems.push({
                    key: 'requesttemplaterun',
                    text: language.ProjectCapacity.RequestTemplateRun,
                    title: language.ProjectRowMenu.GroupBy.OriginatedRequestTemplateRun,
                    onClick: () => void ForceRefreshData('RequestTemplateRun'),
                    disabled: groupByRef.current === 'RequestTemplateRun',
                });
        }

        if (!ArrayEx.isNullOrEmpty(OverviewContext.Settings.ResourceCustomProperties)) {
            groupByItems.push({
                key: 'custom',
                text: 'Custom Properties',
                title: language.ProjectRowMenu.CustomProperties,
                itemType: ContextualMenuItemType.Header,
            });
            OverviewContext.Settings.ResourceCustomProperties.forEach(_ => {
                groupByItems.push({
                    key: _.Id,
                    text: _.DisplayName,
                    title: language.ProjectRowMenu.ResourceCustomProperty.replace('[[displayname]]', `${_.DisplayName}`),
                    onClick: () => void ForceRefreshData(`CustomProperties.${_.Id}`),
                    disabled: groupByRef.current === `CustomProperties.${_.Id}`,
                });
            });
        }

        menuItems.push({
            key: 'groupby',
            text: language.ProjectRowMenu.GroupContractsText,
            title: language.ProjectRowMenu.GroupContractsTitle,
            iconProps: { iconName: 'GroupList' },
            subMenuProps: {
                items: groupByItems,
            },
        });

        if (OverviewContext.Settings.EnableScenarios && OverviewContext.Settings.ScenarioTypesEnabled?.indexOf(ScenarioType.ProjectScenario) >= 0) {
            // TODO: merge version items
            const versionItems = [];
            if (Permission.HasSomePermissions(...(OverviewContext.Settings.UserTypesAllowedToCreateScenarios ?? [])))
                versionItems.push({
                    key: 'drafts',
                    text: language.ProjectCapacity.WorkWithDrafts,
                    title: language.ProjectCapacity.WorkWithDrafts,
                    iconProps: { iconName: 'DoubleColumnEdit' },
                    onClick: (ev, item) => setShowDraftDialog(true),
                } as IContextualMenuItem);

            if (Permission.HasSomePermissions(...(OverviewContext.Settings.UserTypesAllowedToApproveScenarios ?? [])))
                versionItems.push({
                    key: 'versions',
                    text: 'Compare drafts', // TODO: this need to be change when implementing other grids (ewi)
                    title: 'Compare drafts', // TODO: this need to be change when implementing other grids (ewi)
                    iconProps: { iconName: 'Compare' },
                    onClick: (ev, item) => setShowVersionsDialog(true),
                });

            // add menu item if any sub items
            if (versionItems.length) {
                menuItems.push({
                    key: 'scenario',
                    text: language.ProjectCapacity.MenuItems.Scenario.Text,
                    title: language.ProjectCapacity.MenuItems.Scenario.Title,
                    iconProps: { iconName: 'MultiSelect' },
                    subMenuProps: {
                        items: versionItems,
                    },
                });
            }
        }

        const test1 = { items: menuItems };
        const test2 = { items: props.extraMenuItems };

        const result = ObjectEx.mergeDeep(test1, test2);
        return result.items;
    };

    const titleContentOverride = useCallback(
        (title: string) => {
            return <>{props.callbacks.titleContentOverride(title)}</>;
        },
        [refreshAvailableScenarios, props.projectId, props.gridType],
    );

    return (
        <>
            {loading ? (
                <PFSpinner />
            ) : (
                view && (
                    <div
                        onKeyDown={e => {
                            if (e.keyCode === 90 && e.ctrlKey) Undo();
                        }}
                        tabIndex={-1}
                        style={{ outline: 'unset' }}
                    >
                        <Table
                            Name={name}
                            Model={view}
                            ContainerClassName={`project-capacity-${props.gridType === GridType.Request ? 'request' : 'allocation'}`}
                            CellUpdates={CellUpdates()}
                            TopCellStyles={TopCellStyles()}
                            GroupCellStyles={GroupCellStyles()}
                            GroupContainerCellStyles={GroupContainerCellStyles()}
                            RowCellStyles={RowCellStyles()}
                            RowExtraContent={props.callbacks.rowExtraContent}
                            GroupExtraContent={(group: GroupRow) => props.callbacks.groupExtraContent(group, OnResourceAdd)}
                            GroupExtraTextContent={props.callbacks.groupExtraTextContent}
                            GroupContainerExtraContent={(name: string, container: GroupContainerRow) =>
                                props.callbacks.groupContainerExtraContent(name, container, OnResourceAddToContainer, OnGroupAdd)
                            }
                            GridSettingsSpacingAmount={OverviewContext.Settings.GridSpacing}
                            GridSettingsHidePersonaIcons={OverviewContext.Settings.HidePersonaIconsInGrid}
                            SetGridSettingsHidePersonaIconsCallback={value =>
                                UserSettingsService.UpdateUserSettingsPartial({ HidePersonaIconsInGrid: value }, null, true)
                            }
                            SetGridSettingsSpacingAmountCallback={value => UserSettingsService.UpdateUserSettingsPartial({ GridSpacing: value }, null, true)}
                            EnableGridSettings={OverviewContext.Settings.EnableGridSettings}
                            GridSettingsExtraMenuItems={GetExtraMenuItems()}
                            TopExtraContent={(top: TopRow) => (
                                <>
                                    <div
                                        className="tp-list-capacity-dropzone-overlay tp-grid-top"
                                        onDrop={e => OnResourceAdd(e, top)}
                                        onDragEnter={e => e.preventDefault()}
                                        onDragOver={e => e.preventDefault()}
                                    >
                                        <div className="tp-list-capacity-dropzone-text">{language.ProjectCapacity.DropHere}</div>
                                    </div>
                                    <div
                                        className="tp-list-capacity-addnewcontainer-dropzone-overlay"
                                        onDrop={e => OnGroupAdd(e)}
                                        onDragEnter={e => e.preventDefault()}
                                        onDragOver={e => e.preventDefault()}
                                    >
                                        <div className="tp-list-capacity-dropzone-text">{language.ProjectCapacity.NewGroup}</div>
                                    </div>
                                </>
                            )}
                            TitleContentOverride={titleContentOverride}
                            GroupEditEnabled={group =>
                                !OverviewContext.Settings.EnableDynamicContractNames &&
                                (props.gridType === GridType.Request ||
                                    adminRef.current ||
                                    (props.gridType === GridType.Allocation && OverviewContext.Settings.AllowLMsToChangeContractName))
                            }
                            GroupContainerEditEnabled={container => !groupByRef.current && (props.gridType === GridType.Request || adminRef.current)}
                            GroupEdit={ChangeContractName}
                            GroupContainerEdit={ChangeContractContainerName}
                            RowClick={props.callbacks.rowClick}
                            RowDraggable={props.callbacks.rowDraggable}
                            RowDragStart={(row, group, event) => (document.getElementById('tp-list-resources-dropzone-overlay').style.display = 'block')}
                            RowDragEnd={async (row, group, event) => await RemoveResource(row, group)}
                            GroupDraggable={(group: GroupRow) => true} // props.GridType === GridType.Request || adminRef.current}
                            GroupDragStart={GroupDragStart}
                            GroupDragEnd={async (group, event) =>
                                [].forEach.call(
                                    document.querySelectorAll(
                                        `.tp-list-capacity-addcontainer-dropzone-overlay, .tp-list-capacity-mergegroup-dropzone-overlay, .tp-list-capacity-removefromcontainer-dropzone-overlay, .tp-list-capacity-addnewcontainer-dropzone-overlay`,
                                    ),
                                    _ => (_.style.display = 'none'),
                                )
                            }
                            GroupSort={(groups: Array<GroupRow>) => groups.sort((a, b) => a.Name?.localeCompare(b.Name))}
                            RowSort={RowSort}
                            RowClassName={(row: Row) => `${row.Properties.IsRequested ? 'tp-capacity-resource-generic' : ''}`}
                            DragHighlighterUpdate={props.disableDragUpdates ? undefined : DragUpdates()}
                            PasteFromExcel={OverviewContext.Settings.EnableCopyFromExcel && ExcelCopyUpdate}
                            AllowContainerCollapse
                            AllowGroupCollapse
                            GridCellDecimals={ProjectCapacityService.GetGridDecimals()}
                            CustomColumns={props.customColumns}
                        />
                        {/* <ConfirmDialog ConfirmInfo={localConfirmInfo} /> */}
                        <ConfirmDialog ConfirmInfo={null} />
                        <ProjectDraftDialog
                            Show={showDraftDialog}
                            ProjectId={props.projectId}
                            Callback={async dto => {
                                // new scenario, create it
                                if (dto.DraftId == null) {
                                    const newScenario = new ProjectScenario();
                                    newScenario.id = guid.newGuid();
                                    newScenario.Name = dto.DraftName;
                                    newScenario.ProjectId = props.projectId;
                                    newScenario.OwnerId = UserContext.Resource.id;
                                    newScenario.State = ProposalState.Draft;
                                    const created = await DataService.Upsert<Scenario>(EntityType.Scenario, [newScenario]);
                                    dto.DraftId = created[0].id;
                                }
                                if (props.gridType === GridType.Allocation) goTo(buildRoute(`allocation/draft/${props.projectId}/${dto.DraftId}`));
                                else if (props.gridType === GridType.Request) goTo(buildRoute(`request/draft/${props.projectId}/${dto.DraftId}`));
                                setShowDraftDialog(false);
                            }}
                            DismissCallback={() => setShowDraftDialog(false)}
                        />
                        <CompareScenariosDialog
                            show={showVersionsDialog}
                            projectId={props.projectId}
                            // TODO: this need to be change when implementing other grids (ewi)
                            states={[ProposalState.Draft]}
                            onlyMine={true}
                            versionIds={[]}
                            callback={(versionIds, start, end) => {
                                // switch view - set versionids
                                const path = props.gridType === GridType.Request ? 'request' : 'allocation';
                                const freezeDate = ResolutionUtil.getStartUsingFreezePeriod(
                                    OverviewContext.Settings.FreezePeriod,
                                    props.resolution,
                                    OverviewContext.Settings.UseISO8601,
                                );
                                const minStart = freezeDate > start ? freezeDate : start;
                                if (DateEx.isNullOrMin(minStart) || DateEx.isNullOrMin(end)) {
                                    goTo(buildRoute(`${path}/compare/${props.projectId}/${versionIds.join('|')}`));
                                } else {
                                    setTimeline({ start: minStart, end });
                                    goTo(buildRoute(`${path}/compare/${props.projectId}/${versionIds.join('|')}`));
                                }
                            }}
                            dismissCallback={() => setShowVersionsDialog(false)}
                            gridType={props.gridType}
                        />
                    </div>
                )
            )}
        </>
    );
};
const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    const equal =
        prevProps.start === nextProps.start &&
        prevProps.end === nextProps.end &&
        prevProps.projectId === nextProps.projectId &&
        prevProps.gridType === nextProps.gridType &&
        prevProps.resolution === nextProps.resolution;
    return equal;
};

export default React.memo(DefaultProjectCapacity, comparisonFn);
