import { IconButton, TextField } from "@fluentui/react";
import { IFormItem, IPropertyRenderProps, IDynamicPropertyComponentConfig } from "@wiberg/formbuilder";
import { PropsWithChildren, ReactElement } from "react";

interface IProps<T extends IFormItem> extends IPropertyRenderProps<T, IDynamicPropertyComponentConfig<T>, string | undefined> { 
    config?: IDynamicPropertyComponentConfig<T>;
}

export const TextFieldCopyValue = <T extends IFormItem>(props: PropsWithChildren<IProps<T>>) : ReactElement | null => {

    return 	(
		<div className="tp-dynamic-textfieldcopyvalue-container">
			<TextField
				className="tp-dynamic-textfieldcopyvalue-textfield"
				key={ props.key }
				defaultValue={ props.value != null ? props.value : "" }
				onChange={(obj, value) => props.onChange(value) }
				disabled={ props.disabled }
			/>
			<IconButton iconProps={{ iconName: 'Copy', onClick: e => (navigator as any).clipboard.writeText(props.value) }} />
		</div>
	)
}
