import { ReactElement, useCallback, useState } from 'react';
import RichTextEditor from 'react-rte';
import { IFieldConfig, IFieldRenderProps, IFormItem, useFormContext, useValidation, useWatch } from 'spark-forms';
import { FluentValidationMessage } from 'spark-forms-fluent8';

export interface IRichTextFieldProps<T extends IFormItem> extends IFieldRenderProps<T, IFieldConfig<T>, string | undefined> {
    config?: IFieldConfig<T>;
}

export const RichTextField = <T extends IFormItem>({ value, fieldName, config, disabled, path }: IRichTextFieldProps<T>): ReactElement | null => {
	const [localValue, setLocalValue] = useState<string>(() => value == null 
																	? RichTextEditor.createEmptyValue()
																	: RichTextEditor.createValueFromString(value, 'html'));
	
    const { onChange: onChangeForm } = useFormContext();
    const { validate, validationMessage } = useValidation(path);

    useWatch(path, () => setLocalValue(value), [value]);

	const onChange = useCallback((val: any | undefined) => {
		setLocalValue(val);
		onChangeForm(fieldName, val?.toString('html'));
		validate();
	}, [fieldName, onChangeForm, validate]);
	
	return (
		<>
			<RichTextEditor
				value={localValue}
				disabled={disabled}
				onChange={onChange}
				{...config?.componentProps?.key}
				
			/>
			<FluentValidationMessage message={validationMessage} />
		</>
	)
}
