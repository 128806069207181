export default class Locale_LMCapacity { 
	OnCellUpdate = {
		DifferentTimeResolution: `The request you're allocating on, has a different time resolution ([[timelineresolution]]), than the one currently selected!`,
	}
	AddProject: string = 'Add project';
	IncludeResource: string = 'Include resource';
	HitEnterToCreatePlaceholder: string = "Hit enter to create..";
	CreateAsView: string = "Create as view";
	UpdateView: string = "Update view";
	DeleteView: string = "Delete view";
	ClearSelection = {
		Project: 'Clear project selection',
		Resource: 'Clear resource selection',
	}
	DefaultViews = {
		Projects: 'My Projects',
		Resources: 'My Resources'
	}
	ProjectPickerPlaceholder: string = "Add projects by searching here or by dragging them from the panel..";
	ResourcePickerPlaceholder: string = "Include resources by searching here or by dragging them from the panel..";
}