export class ReportFilter {
	public Parts: Array<ReportFilterExpression | ReportFilterCondition | any>;
}

export enum ReportFilterOperator {
	Equals = 0,
	NotEquals = 1
}
export class ReportFilterExpression {
	public TypeName: string = "ReportFilterExpression";
	public Property: string;
	public Operator: ReportFilterOperator = ReportFilterOperator.Equals;
	public Value: string;
}

export enum ReportFilterCondition {
	And = 0,
	Or = 1
}