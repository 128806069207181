import { ILogger, LogLevel } from "@microsoft/signalr";
import { logging } from "projectum-logging/lib/Services/LogManager";

const logger = logging.GetLogger("SignalRHub");

export class HubLogger implements ILogger {
	log(logLevel: LogLevel, message: string): void {
		switch (logLevel) {
			case LogLevel.None:
			case LogLevel.Trace:
				logger.Verbose(message);
				break;
			case LogLevel.Debug:
				logger.Debug(message);
			break;
			case LogLevel.Information:
				logger.Information(message);
			break;
			case LogLevel.Warning:
				logger.Warning(message);
				break;
			case LogLevel.Error:
				logger.Error(message);
				break;
			case LogLevel.Critical:
				logger.Critical(message);
			break;
			default:
				break;
		}
	}
}

export function hubLogger() {
	return {
		log(logLevel: LogLevel, message: string): void {
			switch (logLevel) {
				case LogLevel.None:
				case LogLevel.Trace:
					logger.Verbose(message);
					break;
				case LogLevel.Debug:
					logger.Debug(message);
				break;
				case LogLevel.Information:
					logger.Information(message);
				break;
				case LogLevel.Warning:
					logger.Warning(message);
					break;
				case LogLevel.Error:
					logger.Error(message);
					break;
				case LogLevel.Critical:
					logger.Critical(message);
				break;
				default:
					break;
			}
		}
	}
}