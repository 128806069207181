export default class Locale_ChartPanel {
    ChartCommandBar = {
        RecentlyUsedProjects: 'Recently used projects',
        SuggestedProjects: 'Suggested projects',
        SelectStartDate: 'Select start date...',
        SelectEndDate: 'Select end date...',
        PickAStartDate: 'Pick a start date',
		PickAnEndDate: 'Pick an end date',
		PickAProject: 'Pick a project',
    };
	ChartOverview = {
		ExpandDashboardSection: 'Expand Dashboard section',
		CollapseDashboardSection: 'Collapse Dashboard section',
	}
	BarChart = {
		Date: 'date',
		FreeCapacity: 'Free Capacity',
		TotalAllocated: 'Total Allocated',
		TotalCapacity: 'Total Capacity'
	}
	Tooltip = {
		Hours: 'hours',
	}
}
