import { TimelineResolution } from "../TimelineResolution";

export class SaveWorkPackageCellDto {
    WorkPackageId: string;
	ContractId: string;
	ContractName: string;
	ContractGroupName: string;
    ResourceId: string;
    ResourceName: string;
	Work: number;
	Resolution: TimelineResolution;
    Start: Date;
    End: Date;
}
