import { GridCell } from "../../../../../../../../../api/generated/data-contracts";
import { useGridContext } from "../../../../../../../_grid/contexts/gridContext";
import { useFixedCellValue } from "../../../../../../../_grid/hooks/useFixedCellValue";

const styles: React.CSSProperties = { paddingRight: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end' };

export const ReadOnlyCell = ({ cell }: { cell: GridCell }) => {
    const { useGridCellValue } = useGridContext();
    const storeValue = useGridCellValue(cell);
    const value = useFixedCellValue(storeValue);
    return (
        <div style={styles}>
            {value}
        </div>
    );
};
