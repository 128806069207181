/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, useEffect, useState, useRef, memo } from 'react';
import { IDropdownOption, DropdownMenuItemType, IStyleFunctionOrObject, IDropdownStyleProps, IDropdownStyles } from '@fluentui/react';
import { PropertyInfoService } from '../../../Services/PropertyInfoService';
import { OverviewContext } from '../../../Utilities/Context/OverviewContext';
import { DropdownOptionEx } from '../../../Utilities/DropdownOptionEx';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { DropdownOption } from '../../../Entities/Fabric/DropdownOption';
import { Resource } from '../../../Entities/Main/Resource';
import { Project } from '../../../Entities/Main/Project';
import { CustomProperty } from '../../../Entities/ContentConfiguration/CustomProperty';
import { ArrayEx } from '../../../Utilities/ArrayEx';
import PFDropdown from '../PFDropdown';
import { language } from '../../../Services/LocalizationService';
import { ResourceWithStringVariants } from '../../../ListItems/ResourceWithStringVariants';
import { ProjectWithStringVariants } from '../../../ListItems/ProjectWithStringVariants';
import { Settings } from '../../../Entities/Main/Settings';
import { refactorHelper } from '../../../helpers/refactorHelper';
import { ContractItem } from '../../../Entities/contractItem';

interface IProps extends IDynamicEditorComponentProps {
    Item: string | Array<string>;
    Update: (item: string | Array<string>) => void;
    Key?: string;
    EntityType: { new (): any };
    EntityTypeAsString?: string; // this is ONLY for allowing the settings form to co-exist while changing to the new forms (ewi)
    MultiSelect?: boolean;
    PropertyTypes: 'Fields' | 'Properties' | 'Both';
    FilterSystemProperties?: boolean;
    FilterArrayProperties?: boolean;
    HiddenProperties?: Array<string>;
    OnlyTheseProperties?: Array<string>;
    RemovePrefixFromProperties?: boolean;
    PlaceHolder?: string;
    DisableEmptyEntry?: boolean;
    Styles?: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
    Label?: string;
    Searchable?: boolean;
}

const EntityPropertySelector: FunctionComponent<IProps> = props => {
    const [values, setValues] = useState<Array<DropdownOption>>();
    const itemValues = useRef<Array<string>>(props.MultiSelect ? (props.Item as Array<string>) : []);

    const [item, setItem] = useState<string | Array<string>>(props.Item);

    // this is ONLY for allowing the settings form to co-exist while changing to the new forms (ewi)
    const getItemType = (): any => {
        if (props.EntityTypeAsString) {
            switch (props.EntityTypeAsString) {
                case 'Settings':
                    return Settings;
                default:
                    break;
            }
        } else return props.EntityType;
    };

    useEffect(() => {
        setItem(props.Item);
    }, [props.Item]);

    useEffect(() => {
        const systemProps = ['id', 'Created', 'Modified', 'CreatedBy', 'ModifiedBy', 'DelegatedResourceId', 'OriginIds'];
        const arrayProps = ['Rules', 'AdditionalRBS', 'UserTypes', 'AdditionalOwners'];

        let itemProperties = [];
        if (props.OnlyTheseProperties?.length) {
            itemProperties = PropertyInfoService.GetPFProperties(getItemType(), false).filter(_ => props.OnlyTheseProperties.indexOf(_.PropertyName) >= 0);
        } else {
            itemProperties = PropertyInfoService.GetPFProperties(getItemType(), false).filter(
                _ =>
                    _.PropertyName !== 'CustomProperties' &&
                    (!props.FilterSystemProperties || systemProps.indexOf(_.PropertyName) < 0) &&
                    (!props.FilterArrayProperties || arrayProps.indexOf(_.PropertyName) < 0) &&
                    (!props.HiddenProperties || props.HiddenProperties.indexOf(_.PropertyName) < 0),
            );
        }

        let additionalProps: Array<CustomProperty>;
        switch (getItemType()) {
            case Resource:
            case ResourceWithStringVariants:
                additionalProps = OverviewContext.Settings.ResourceCustomProperties.filter(
                    _ => props.HiddenProperties == null || props.HiddenProperties.indexOf(`CustomProperties.${_.Id}`) < 0,
                );
                break;
            case Project:
            case ProjectWithStringVariants:
                additionalProps = OverviewContext.Settings.ProjectCustomProperties.filter(
                    _ => props.HiddenProperties == null || props.HiddenProperties.indexOf(`CustomProperties.${_.Id}`) < 0,
                );
                break;
            case ContractItem:
                additionalProps = OverviewContext.Settings.ContractCustomProperties.filter(
                    _ => props.HiddenProperties == null || props.HiddenProperties.indexOf(`CustomProperties.${_.Id}`) < 0,
                );
                break;
            default:
                additionalProps = [];
                break;
        }

        let fields = DropdownOptionEx.toDropdownOptions(
            itemProperties,
            _ => _.PropertyName,
            _ => _.DisplayName,
        );
        let properties =
            props.PropertyTypes === 'Properties' || props.PropertyTypes === 'Both'
                ? DropdownOptionEx.toDropdownOptions(
                      additionalProps,
                      _ => (props.RemovePrefixFromProperties ? _.Id : `CustomProperties.${_.Id}`),
                      _ => _.DisplayName,
                  )
                : [];

        fields = ArrayEx.sort(fields, 'text');
        properties = ArrayEx.sort(properties, 'text');

        const fieldHeader = [{ key: 'fieldsHeader', text: language.Common.Fields, itemType: DropdownMenuItemType.Header } as DropdownOption];
        const propertiesHeader = [{ key: 'propertiesHeader', text: language.Common.Properties, itemType: DropdownMenuItemType.Header } as DropdownOption];

        let valueItems: Array<DropdownOption>;
        switch (props.PropertyTypes) {
            case 'Fields':
                valueItems = fieldHeader.concat(fields);
                break;
            case 'Properties':
                valueItems = propertiesHeader.concat(properties);
                break;
            case 'Both':
                valueItems = fieldHeader.concat(fields).concat(propertiesHeader).concat(properties);
                break;
            default:
                break;
        }

        if (!props.DisableEmptyEntry && !props.MultiSelect)
            valueItems = [new DropdownOption(null, language.EntityPropertySelector.NotSelected)].concat(valueItems);
        setValues(valueItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const OnChange = (option: IDropdownOption) => {
        // const value = refactorHelper.tempParsePropertyBack(option.key as string);
        // const value = refactorHelper.tempParseProperty(option.key as string);
        const value = option.key as string;
        if (!props.MultiSelect) {
            props.Update(value);
            setItem(value);
            return;
        }
        itemValues.current = refactorHelper.tempParseProperties(itemValues.current || []);
        if (option.selected) itemValues.current.push(value);
        else {
            const currIndex = itemValues.current.indexOf(value);
            if (currIndex > -1) {
                itemValues.current.splice(currIndex, 1);
            }
        }

        props.Update(itemValues.current);
        setItem(itemValues.current);
    };

    return values ? (
        <>
            <PFDropdown
                Key={props.Key}
                Placeholder={props.PlaceHolder}
                Readonly={props.ReadOnly}
                Multiselect={props.MultiSelect}
                Options={values}
                Searchable={props.Searchable}
                SelectedKey={props.MultiSelect ? null : refactorHelper.tempParseProperty(item as string)}
                SelectedKeys={props.MultiSelect ? refactorHelper.tempParseProperties((item as Array<any>) ?? []) : null}
                // SelectedKeys={ props.MultiSelect ? (item as Array<any>) ?? [] : null }
                OnChange={(event: React.FormEvent<HTMLDivElement>, allitems: Array<any>, option?: IDropdownOption, index?: number) => OnChange(option)}
                Styles={props.Styles}
            />
        </>
    ) : (
        <p>Loading..</p>
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Item === nextProps.Item;
};

export default memo(EntityPropertySelector, comparisonFn);
