export class WorkWeek {
    public Monday: number;
    public Tuesday: number;
    public Wednesday: number;
    public Thursday: number;
    public Friday: number;
	public Saturday: number;
	public Sunday: number;


    constructor() {
        this.Monday = 0;
        this.Tuesday = 0;
        this.Wednesday = 0;
        this.Thursday = 0;
        this.Friday = 0;
		this.Saturday = 0;
		this.Sunday = 0;
    }

    // public static AllDaysEqual = (workWeek: WorkWeek): boolean => {
    //     let values = Object.keys(workWeek).map((_) => workWeek[_]);
    //     return values.every((_) => _ === values[0]);
    // };

	// Previous Validation
	// Changed to accomodate monday-less workweeks
    // public static Validate = (workWeek: WorkWeek): boolean => workWeek.Monday != 0;
	
    public static Validate = (workWeek: WorkWeek): boolean => {
        const weekDays = [
            workWeek.Monday,
            workWeek.Tuesday,
            workWeek.Wednesday,
            workWeek.Thursday,
            workWeek.Friday,
        ];

		// Fail Validation only if ALL weekday values are 0
		const shouldPassValidation = weekDays.some(_ => _ > 0);
		
	    return shouldPassValidation;
    };

    public static Total = (workWeek: WorkWeek) =>
        Object.keys(workWeek)
            .filter((_) => _.substring(_.length - 3) === 'day')
            .reduce((sum, current) => sum + workWeek[current], 0);
}
