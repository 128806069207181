import { IColumn } from "@fluentui/react";
import { ProjectWithStringVariants } from "../../../api/generated/data-contracts";
import { projectSchema } from "../../../forms/project/projectSchema";
import { ArrayEx } from "../../../Utilities/ArrayEx";
import { ListColumnEnhanced } from "../../../_components/OverviewCreator/types/ListColumnEnhanced";
import { generateOverviewColums } from "../_helpers/generateOverviewColums";

export const generateProjectOverviewColumns = () : IColumn[] => {
	return generateOverviewColums('project', projectSchema, extraColumnOptions);
}

const extraColumnOptions: { [field: string] : Partial<ListColumnEnhanced<ProjectWithStringVariants>> } = {
	isActive: {
		minWidth: 100,
		onRender: item => item.isActive?.toString()
	},
	originIds: {
		onRender: item => ArrayEx.joinToString(Object.values(item.originIds), ",")
	}
}