/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect, useContext, useRef, useCallback } from 'react';
import { hasVerticalOverflow } from '@fluentui/react/lib/Utilities';
import {
    IContextualMenuItem,
    ContextualMenuItemType,
    IconButton,
    Text,
    Link,
    AnimationClassNames,
    Icon,
    MessageBar,
    MessageBarType,
    Stack,
    IStackProps,
    TooltipHost,
} from '@fluentui/react';
import { useParams, useNavigate, useLocation } from 'react-router';
import shallow from 'zustand/shallow';
import AutoDistributionSettingsDialog from '../../../../Components/Common/AutoDistributionSettingsDialog';
import { CountDownDialog } from '../../../../Components/Common/CountDownDialog';
import { EntityInformationCallout } from '../../../../Components/Common/EntityInformationCallout';
import ProjectDraftDialog from '../../../../Components/Common/ProjectDraftDialog';
import CompareScenariosDialog from '../../../../Components/Common/ProjectVersionsDialog';
import AlignContractMenu from '../../../../Components/Overview/AlignContractMenu';
import { Comments } from '../../../../Components/Overview/Comments/Comments';
import ContractRowMenu from '../../../../Components/Overview/ContractRowMenu';
import ResourceRowMenu from '../../../../Components/Overview/ResourceRowMenu';
import { ScenariosAvailableIcon } from '../../../../Components/Overview/ScenariosAvailableIcon';
import { CellUpdateDto } from '../../../../Components/Overview/Table/DragCopy/CellUpdateDto';
import { TableCustomColumn, TableCustomColumnInfo } from '../../../../Components/Overview/Table/TableCustomColumn';
import { AddActivityDto, WorkType } from '../../../../Entities/Dto/AddActivityDto';
import { RowMenuItemInfo } from '../../../../Entities/Dto/RowMenuItemInfo';
import { EntityType } from '../../../../Entities/EntityTypes';
import { MessageBarButtonInfo } from '../../../../Entities/Fabric/MessageBarButtonInfo';
import { GridType } from '../../../../Entities/GridType';
import { Notification, NotificationStatusType } from '../../../../Entities/Notification';
import { ProjectType } from '../../../../Entities/ProjectType';
import { ResourceType } from '../../../../Entities/ResourceType';
import { ScenarioType, ProjectScenario, Scenario } from '../../../../Entities/Scenario';
import { BaseRow } from '../../../../Entities/Table/BaseRow';
import { Cell } from '../../../../Entities/Table/Cell';
import { CellType } from '../../../../Entities/Table/CellType';
import { GroupContainerRow } from '../../../../Entities/Table/GroupContainerRow';
import { GroupRow } from '../../../../Entities/Table/GroupRow';
import { HeaderRow } from '../../../../Entities/Table/HeaderRow';
import { SubHeaderRow } from '../../../../Entities/Table/SubHeaderRow';
import { TopRow } from '../../../../Entities/Table/TopRow';
import { TimelineResolution } from '../../../../Entities/TimelineResolution';
import { UndoItem } from '../../../../Entities/UndoItem';
import { activeProposalStatesWithoutDraft } from '../../../../Entities/Versions/ProposalState';
import { buildRoute } from '../../../../helpers/routeHelpers';
import { ResourcePanelItem } from '../../../../ListItems/ResourcePanelItem';
import { DataService } from '../../../../Services/DataService';
import { language } from '../../../../Services/LocalizationService';
import { ProjectCapacityService } from '../../../../Services/ProjectCapacityService';
import { UserSettingsService } from '../../../../Services/Settings/UserSettingsService';
import { StoreContext } from '../../../../Services/StateStore';
import { ArrayEx } from '../../../../Utilities/ArrayEx';
import { CapacityUtil } from '../../../../Utilities/CapacityUtil';
import { OverviewContext } from '../../../../Utilities/Context/OverviewContext';
import { UserContext } from '../../../../Utilities/Context/UserContext';
import { IEntityInfoCallout } from '../../../../context/contexts/entityCalloutContext';
import { useStore } from '../../../../context/store';
import { DateEx } from '../../../../Utilities/DateEx';
import { EventEx } from '../../../../Utilities/EventEx';
import { Glimmer } from '../../../../Utilities/Glimmer';
import { guid } from '../../../../helpers/guid';
import { IDs } from '../../../../Utilities/IDs';
import { JsonEx } from '../../../../Utilities/JsonEx';
import { LocalStorage } from '../../../../Utilities/LocalStorage';
import { ObjectEx } from '../../../../Utilities/ObjectEx';
import { PluginIDs } from '../../../../Utilities/PluginIDs';
import { ProjectChangesUtil } from '../../../../Utilities/ProjectChangesUtil';
import { ResolutionUtil } from '../../../../Utilities/ResolutionUtil';
import { ResourceEx } from '../../../../Utilities/ResourceEx';
import { Plugin } from '../../../../Services/PluginInvoker';
import { useTheme } from '../../../../Themes/themeContext';
import { IRow, Row } from '../../../../Entities/Table/Row';
import { Sticky } from '../../../../Entities/Sticky';
import Table from '../../../../Components/Overview/Table/Table';
import { ColumnType } from '../../../../Entities/Table/ColumnType';
import { ProjectCapacityCoachmarks } from '../../../../userGuidance/areas/ProjectCapacityCoachmarks';
import { ConfirmDialog, ConfirmInfo } from '../../../../Components/Common/ConfirmDialog';
import { stringVariants } from '../../../../helpers/stringVariants';
import { GridSkeleton } from '../../../../Components/Skeleton/GridSkeleton';
import { ApiCalls } from '../../../../api/api';
import { useCurrentProject } from '../../../../context/network/http/QueryProvider/queries/project';
import { useUISettings } from '../../../../context/network/http/QueryProvider/queries/UISettings';
import { useCheckTPPermissions } from '../../../../hooks/usePermissions';
import { capitalize, upperCaseObj } from '../../../../context/network/http/QueryProvider/helpers/queryHelper';
import { useUnitType } from '../../../../Utilities/UnitTypeUtil';
import { RenderHideGenericResourceToggle } from '../../../../Components/Common/RenderHideGenericResourceToggle';
import { Comment, ProposalState, UserType } from '../../../../api/generated/data-contracts';
import { RenderFastDeletionToggle } from '../../../../Components/Common/RenderFastDeletionToggle';
import { DragAndDrop } from '../../../../Utilities/DragAndDrop';
import { Timeout } from '../../../../types/runtimeTypes';
import { isOverAllocated, roundAllocation } from '../../helpers';
import { resetAlignStatus, shouldResetAlignStatus } from '../../../../Utilities/GridUtils';
import { WorkpackageImportDialog } from '../_components/WorkpackageImportDialog';

interface IProps {
    gridType: GridType;
}

const ProjectCapacity: FunctionComponent<IProps> = ({ gridType }) => {
    const { projectId } = useParams<{ projectId: string }>();

    const { data: uiSettings } = useUISettings();
    const checkPermissions = useCheckTPPermissions();

    const { currentUnitType } = useUnitType();

    const { setUIContext, setTimeline, timeline, addNotification, error, info, success, warning, fastDeletionEnabled } = useStore(
        store => ({
            setUIContext: store.setUIContext,
            setTimeline: store.setTimeline,
            timeline: store.timeline,
            error: store.addErrorNotification,
            info: store.addInfoNotification,
            warning: store.addWarningNotification,
            addNotification: store.addNotification,
            success: store.addSuccesNotification,
            fastDeletionEnabled: store.gridSettingsEnableFastDeletion,
        }),
        shallow,
    );

    const { data: project, isFetching } = useCurrentProject(projectId);

    const [view, _setView] = useState<TopRow>();
    const viewRef = React.useRef<TopRow>(view);
    const [loading, _setLoading] = useState(true);
    const adminRef = React.useRef<boolean>(false);
    const [name, setName] = useState<string>('');
    const ignoreResolutionMismatch = useRef(false);
    const [detailDto, setDetailDto] = useState<IEntityInfoCallout>(null);

    const [showDraftDialog, setShowDraftDialog] = useState<boolean>(false);
    const [showVersionsDialog, setShowVersionsDialog] = useState<boolean>(false);
    const [showAutoDistributionSettings, setShowAutoDistributionSettings] = useState(false);
    const [refreshAvailableScenarios, setRefreshAvailableScenarios] = useState<string>(null);
    const location = useLocation();
    const groupByRef = React.useRef<string>(LocalStorage.get('tp-projectcapacity-groupby'));

    // workpackage import
    const [isWorkpackageImportDialogOpen, setWorkpackageImportDialogOpen] = useState(false);

    const [localConfirmInfo, setLocalConfirmInfo] = useState<ConfirmInfo>(null);
    const goTo = useNavigate();

    const setView = (view: TopRow) => {
        if (view != null) view.ChangeKey = IDs.makeId();
        viewRef.current = view; // keep updated
        _setView(view);
    };

    const setLoading = (loading: boolean) => {
        _setLoading(loading);
    };
    const setAdminProject = (isAdmin: boolean) => {
        adminRef.current = isAdmin; // keep updated
    };

    const ctx = useContext(StoreContext);
    const theme = useTheme();

    useEffect(() => {
        ForceRefreshData(groupByRef.current);
    }, [currentUnitType]);

    useEffect(() => {
        // Check for valid 'Align Contract' feature configuration
        if (uiSettings.settings.enableAlignContract && !uiSettings.settings.alignContractStatuses.length) {
            error("Please contact your administrator! 'Align Contract' feature is enabled, but Contract Align statuses are not defined in the configuration!");
        }
    }, [error, uiSettings.settings.alignContractStatuses.length, uiSettings.settings.enableAlignContract]);

    useEffect(() => {
        if (loading) {
            setUIContext({ bottomHasScroll: false });
        } else {
            const element = document.getElementById('bottom-main');
            if (element) {
                setUIContext({ bottomHasScroll: hasVerticalOverflow(element) });
            }
        }
    }, [loading, setUIContext]);

    useEffect(() => {
        // hack due to rehydration of storage dates are strings for a split second, causing rerender here (ewi)
        if (typeof timeline.start === 'string') return;
        if (isFetching) {
            setLoading(true);
            return;
        }

        savedActions.current = [];
        if (viewRef.current == null && timeline.start == null && timeline.end == null) {
            setTimeout(() => {
                if (viewRef.current != null || (timeline.start != null && timeline.end != null)) return;
                warning(language.ProjectCapacity.CachedSelectedDatesAreInvalid);
                setLoading(false);
            }, 6000);
            return;
        }
        const getView = async () => {
            setLoading(true);
            await LoadCapacity();
        };
        getView();
        OverviewContext.RefreshProjectCapacity = ForceRefreshData;
        OverviewContext.AddResource = AddResource;
    }, [gridType, timeline.resolution, timeline.start, timeline.end, projectId, isFetching]);

    const ForceRefreshData = async (groupBy?: string) => {
        if (viewRef.current === undefined || viewRef.current === null) return;
        // update contract groupby property
        groupByRef.current = groupBy;
        LocalStorage.set('tp-projectcapacity-groupby', groupBy);
        setLoading(true);
        await LoadCapacity(true);
    };

    // temporary function to move seen comments to a new structure
    const TransferCommentsToNewStructure = useCallback(
        (model: TopRow) => {
            const commentsSeen = LocalStorage.get('tp-commentsseen') || {};
            const commentsRead = LocalStorage.get('tp-comments') || {};
            // comments have not been updated to new structure
            // move all contract's commentread under project grouping
            if (commentsSeen[projectId] == null) {
                commentsSeen[projectId] = {};
                const flattenedGroups = ProjectCapacityService.GroupsFlattened(model);
                flattenedGroups.forEach(con => {
                    const contractComments = commentsRead[con.Id];
                    if (contractComments == null) return;
                    const rowCommentKeys = Object.keys(contractComments);
                    if (!rowCommentKeys?.length) return;
                    commentsSeen[projectId][con.Id] = {};
                    // there is a comment to move
                    rowCommentKeys.forEach(_ => {
                        // if old structure second node is the date (update all these)
                        const rowComment = commentsRead[con.Id][_];
                        if (typeof rowComment === 'string' || rowComment instanceof String) {
                            const date = new Date(rowComment as string);
                            const updated = DateEx.toUtcString(date);
                            commentsSeen[projectId][con.Id][_] = updated;
                        }
                    });
                    delete commentsRead[con.Id];
                });
                LocalStorage.set('tp-commentsseen', commentsSeen);
                LocalStorage.set('tp-comments', commentsRead);
            }
        },
        [projectId],
    );

    const GetCapacity = useCallback(async (projectId: string, start: Date, end: Date, resolution: TimelineResolution, gridType: GridType): Promise<TopRow> => {
        if (!projectId) return null;
        return await Plugin.Invoke<TopRow>(
            PluginIDs.GetProjectCapacityView,
            {
                ProjectId: projectId,
                Start: start,
                End: end,
                DateResolution: resolution,
                GridType: gridType,
                GroupBy: groupByRef.current,
                CommentsRead: GetCommentsRead(projectId),
            },
            'Failed getting grid data',
            true,
        );
    }, []);

    //TODO: refactor - looks like crap! - ewi
    const LoadCapacity = useCallback(
        async (isRefresh?: boolean): Promise<void> => {

            if (!projectId) {
                setLoading(false);
                return;
            }

            if (!project) {
                setView(null);
                setLoading(false);
                return;
            }

			// check if user has permission to request on the selected project
            if (gridType === GridType.Request) {
                OverviewContext.UserIsPmForSelectedProject = await ResourceEx.projectMatch(project, uiSettings.resource);

                const pmOrSuper =
                    OverviewContext.UserIsPmForSelectedProject || checkPermissions({ userTypes: [UserType.SuperUser, UserType.ProjectOwner], some: true });
                const searchAllAndPm = uiSettings.settings.allowSearchAllProjects && checkPermissions({ userTypes: [UserType.ProjectOwner] });
                const isAdmin = project.projectType === ProjectType.Administrative;

                if ((!pmOrSuper && !searchAllAndPm) || isAdmin) {
                    info(language.ProjectCapacity.Project.Select);
                    setView(null);
                    setLoading(false);
                    return;
                }
            }

            // get capacity grid
            const model = !timeline.start || !timeline.end ? null : await GetCapacity(projectId, timeline.start, timeline.end, timeline.resolution, gridType);
            if (!model) {
                // if (!isMounted.current) return;
                // if (!running.endShouldContinue()) return;
                setView(null);
                setLoading(false);
                return;
            }

            if (project.projectType === ProjectType.Team) ctx.Set({ ProjectTeamSelected: true });
            else ctx.Set({ ProjectTeamSelected: false });

            // if (project.ProjectType == ProjectType.Team)
            // 	ctx.Set({ProjectTeamSelected: true});
            // else
            // 	ctx.Set({ProjectTeamSelected: false});

            // update resource filter (resource picker panel) to align selectable resources with requests
            // if (gridType == GridType.Allocation && model != null) {
            // 	if (fullProject.ProjectType == ProjectType.Administrative)
            // 		updateResourceListFilter(null, true);
            // 	else {
            // 		const flattenedGroups = ProjectCapacityService.GroupsFlattened(model);
            // 		const ids = ArrayEx.Flatten<Row>(flattenedGroups.map(_ => _.Rows));
            // 		updateResourceListFilter(ids.filter(_ => _.Properties.ResourceType == ResourceType.Generic).map(_ => _.Id), true);
            // 	}
            // }
            // if (!isMounted.current && !isRefresh) return;
            // if (!running.endShouldContinue()) return;

            // setName(`${fullProject.Name}${EnumEx.EqualsAny(gridType, GridType.DraftAllocation, GridType.DraftRequest) ? " DRAFT" : ""}`);
            setName(project.name);
            setView(model);
            setAdminProject(project.projectType === ProjectType.Administrative);
            setLoading(false);
            setRefreshAvailableScenarios(IDs.makeId());

            /// REMOVE THIS AT SOME POINT ///
            TransferCommentsToNewStructure(model);
        },
        [
            GetCapacity,
            TransferCommentsToNewStructure,
            checkPermissions,
            gridType,
            info,
            project,
            projectId,
            timeline.end,
            timeline.resolution,
            timeline.start,
            uiSettings.resource,
            uiSettings.settings.allowSearchAllProjects,
        ],
    );

    const GetCommentsRead = (projectId: string) => {
        const commentsSeen = LocalStorage.get<object>('tp-commentsseen') || {};

        // first try to get from new implementation
        if (Object.hasOwn(commentsSeen, projectId)) return commentsSeen[projectId];

        /// REMOVE THIS AT SOME POINT ///
        // temporary function to move seen comments to a new structure
        const commentsRead = LocalStorage.get('tp-comments');
        if (commentsRead == null) return;
        // use this in the transition phase from the old commentread structure to the new one
        // fallback and return all non-nested (old-structure)
        const parsedCommonRead = {};
        // fix wrongly formed dates, from prior commentread structure
        Object.keys(commentsRead).forEach(con => {
            parsedCommonRead[con] = {};
            Object.keys(commentsRead[con]).forEach(_ => {
                // if old structure second node is the date (update all these)
                const rowComment = commentsRead[con][_];
                if (typeof rowComment === 'string' || rowComment instanceof String) {
                    const date = new Date(rowComment as string);
                    const updated = DateEx.toUtcString(date);
                    parsedCommonRead[con][_] = updated;
                }
            });
        });
        return parsedCommonRead;
        /// REMOVE THIS AT SOME POINT ///
    };

    const GetActivictyColor = (cell: Cell, index: number, columnIndex: number, cellType: CellType): string => {
        if (adminRef.current) return theme.semanticColors.activityEqualBackground;
        return ProjectCapacityService.GetActivityColor(view, cell, index, columnIndex, cellType, theme);
    };

    const OnResourceAdd = async (event: any, row?: GroupRow) => {
        const resource = JsonEx.parse(event.dataTransfer.getData('resourcelistitem')) as ResourcePanelItem;
        await AddResource(resource, row);
    };

    const OnResourceAddToContainer = async (event: any, container: GroupContainerRow) => {
        const resource = JsonEx.parse(event.dataTransfer.getData('resourcelistitem')) as ResourcePanelItem;
        const groupName = GetPropertyValue(resource) || resource.Name;
        const updatedModel = await ProjectCapacityService.AddResourceToContainer(
            viewRef.current,
            viewRef.current.Id,
            container.Id,
            resource.id,
            groupName,
            timeline.start,
            timeline.end,
            timeline.resolution,
            gridType,
        );
        if (updatedModel) {
            ProjectCapacityService.ForceRefreshCells(updatedModel);
            setView({ ...updatedModel });
        }
    };

    // TODO: show same dialog as 'Create matching request'
    const OnMergeContract = async (event: any, row?: GroupRow) => {
        // create base dialog for using this view/forever

        const groupRow = JsonEx.parse(event.dataTransfer.getData('grouprow')) as GroupRow;
        if (row && groupRow.Properties.ContractId === row.Id) return;
        const genRes1 = row.Rows.find(_ => _.Properties.ResourceType === ResourceType.Generic);
        const genRes2 = groupRow.Rows.find(_ => _.Properties.ResourceType === ResourceType.Generic);
        if (genRes1 && genRes2 && genRes1.Properties.ResourceId !== genRes2.Properties.ResourceId) {
            warning(language.ProjectCapacity.Project.OnMergeContract);
            return;
        }

        const dto = {
            SourceContractId: groupRow.Id,
            TargetContractId: row.Id,
        };

        const confirmDto = {
            show: true,
            title: language.ProjectCapacity.ConfirmDto.ConfirmDtoTitle,
            subtext: language.ProjectCapacity.ConfirmDto.ConfirmDtoSubtext,
            confirmAction: async () => {
                setLoading(true);
                setLocalConfirmInfo(null);
                Plugin.Invoke(PluginIDs.MergeContracts, dto, language.ProjectCapacity.ConfirmDto.OnMergeContractFail)
                    .then(_ => {
                        setLoading(false);
                        ForceRefreshData();
                    })
                    .catch(err => {
                        setLoading(false);
                        error(language.ProjectCapacity.ConfirmDto.OnMergeContractFail);
                    });
            },
            dismissAction: () => {
                setLocalConfirmInfo(null);
                setLoading(false);
            },
            okButtonText: language.ProjectCapacity.ConfirmDto.Merge,
            cancelButtonText: language.Common.Cancel,
        };

        setLocalConfirmInfo(confirmDto);
    };

    const GetPropertyValue = (item: ResourcePanelItem): string => {
        // TODO remove capitalization when all api calls is swagger and sane
        const field = uiSettings.settings.enableDynamicContractNames ? language.CapacityViews.NameLabel : capitalize(uiSettings.settings.contractDefaultName);
        return stringVariants.getAsStringValue(item, field);
    };

    const AddResource = async (resource: ResourcePanelItem, groupRow?: GroupRow) => {
        setUIContext({ scrollToAddedResource: resource.id });
        const groupName = GetPropertyValue(resource) || resource.Name;
        const updatedModel = await ProjectCapacityService.AddResource(
            viewRef.current,
            viewRef.current.Id,
            resource.id,
            groupName,
            groupRow ? groupRow.Id : null,
            timeline.start,
            timeline.end,
            timeline.resolution,
            gridType,
            warning,
            resource.RelatedGenericResourceId,
            project?.projectType,
            true,
            groupByRef.current,
        );
        if (updatedModel) {
            ProjectCapacityService.ForceRefreshCells(updatedModel);
            setView({ ...updatedModel });
        }
        [].forEach.call(document.querySelectorAll('.tp-list-capacity-dropzone-overlay'), _ => (_.style.display = 'none'));
    };

    const RemoveResource = async (resource: Row, group: GroupRow): Promise<void> => {
        document.getElementById('tp-list-resources-dropzone-overlay').style.display = 'none';

        // remove resource
        const updatedModel = await ProjectCapacityService.OnResourceRemove(
            view,
            gridType,
            resource,
            group,
            timeline.resolution,
            ProjectCapacityService.RemoveActivities,
        );
        if (updatedModel) {
            setView({ ...updatedModel });
            // NotificationService.Instance.Success(`${language.ProjectCapacity.RemoveResource} ${resource.Name}`);
            success(`${language.ProjectCapacity.RemoveResource} ${resource.Name}`);
        }
    };

    const OnGroupAdd = async (event: any, container?: GroupContainerRow) => {
        let containerName: string;
        const groupRow = JsonEx.parse(event.dataTransfer.getData('grouprow')) as GroupRow;
        if (!container || groupRow.Properties.ContainerId !== container.Id) containerName = container ? container.Name : `Group_${IDs.makeId()}`;
        // update contract
        setLoading(true);
        await Plugin.Invoke<string>(PluginIDs.UpdateContractGroup, { NewName: containerName, ContractId: groupRow.Id });
        const model = await GetCapacity(projectId, timeline.start, timeline.end, timeline.resolution, gridType);
        setView(model);
        setLoading(false);
    };

    const ChangeContractName = (group: GroupRow, oldName: string, newName: string) => {
        if (oldName === newName || !oldName || !newName) return;
        if (!group) return;
        Plugin.Invoke<string>(PluginIDs.ChangeContractName, { ContractId: group.Id, Name: newName }).catch(_ =>
            error(language.ProjectCapacity.ChangeContractName),
        );
    };

    const ChangeContractContainerName = (container: GroupContainerRow, oldName: string, groupIds: Array<string>) => {
        if (oldName === container.Name || !oldName || !container.Name) return;
        if (!groupIds.length) return;
        Plugin.Invoke<string>(PluginIDs.ChangeContractGroupName, { NewName: container.Name, ContractIds: groupIds }).catch(_ =>
            error(language.ProjectCapacity.ChangeContractContainerName),
        );
    };

    const savedActions = useRef<Array<UndoItem>>([]);

    const SaveActions = (resolution: TimelineResolution, workType: WorkType, idx: number, cells: Array<CellUpdateDto>) => {
        const item = new UndoItem(resolution, workType, idx, cells);
        savedActions.current.push(item);
    };

    const Undo = () => {
        if (!uiSettings.settings.enableGridUndo) return;
        const item = savedActions.current.pop();
        if (item == null) {
            info(language.CapacityViews.Undo.NothingToUndo, 2000);
            return;
        }
        const updatedModel = OnMultipleCellsUpdate(viewRef.current, item.resolution, item.workType, item.idx, item.cells, true);
        // highlight undo'ed items
        ProjectCapacityService.HighlightCell(
            item.cells.map(_ => _.Cell.Id),
            true,
        );
        // update view
        setView({ ...updatedModel });
        // notify
        info(language.CapacityViews.Undo.UndidPreviousCellEdit, 2000);
    };

    // TODO: refactor so theres only one way of updating cell, using an array - same in API - ewi
    const OnCellUpdate = (
        view: TopRow,
        adminProject: boolean,
        resolution: TimelineResolution,
        workType: WorkType,
        columnIndex: number,
        idx: number,
        cell: Cell,
        newValue: number,
        isUndo?: boolean,
    ): TopRow => {
        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);

        const group = flattenedGroups.find(_ => _.Id === cell.Properties.ContractId);

        // check on 'IsRequested' as well due to the fact that we now can have two resourcerows with the same resource (when a resource is both requested and allocated)
        const resource = group.Rows.find(_ => _.Id === cell.Properties.ResourceId && _.Properties.IsRequested === cell.Properties.IsRequested);
        const cellRow = resource.CellRows[columnIndex];

        // handle allocation on different resolution than request
        if (
            uiSettings.settings.enableGridResolutionMismatchWarning &&
            !adminProject &&
            !ignoreResolutionMismatch.current &&
            cellRow.Properties.ResolutionMismatch
        ) {
            ProjectCapacityService.HighlightCell([cell.Id], true, 'highlighterror'); // TODO: this only works when stepping in debug, WHY GOD WHY?? - ewi

            warning(
                language.ProjectCapacity.OnCellUpdate.DifferentTimelineResolution.replace(
                    '[[timelineresolution]]',
                    TimelineResolution[cellRow.Properties.RequestResolution].toString(),
                ),
            );

            const notification = new Notification();
            notification.Message = language.CapacityViews.OnCellUpdate.NotificationMessage;
            notification.StatusType = NotificationStatusType.SevereWarning;
            notification.Buttons = [
                new MessageBarButtonInfo(language.CapacityViews.OnCellUpdate.Disable, () => (ignoreResolutionMismatch.current = true)),
                new MessageBarButtonInfo(language.ProjectCapacity.OnCellUpdate.Switch, () =>
                    OverviewContext.ChangeResolution(cellRow.Properties.RequestResolution),
                ),
            ];
            // NotificationService.Instance.AddNotification(notification);
            addNotification(notification);

            return view;
        }

        // store action to be used in 'undo' feature
        if (!isUndo && uiSettings.settings.enableGridUndo)
            SaveActions(resolution, workType, idx, [{ Cell: cell, ColumnIndex: columnIndex, Value: cell.Value }]);

        // get container if any
        const container =
            group.Properties.ContainerId !== guid.empty ? (viewRef.current.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow) : null;

        // reset align status
        if (shouldResetAlignStatus(workType)) {
            group.Properties.AlignStatus = resetAlignStatus(workType);
        }

        if (uiSettings.settings?.restrictOverAllocating && gridType === GridType.Allocation) {
            const lastValue = cellRow.Cells[idx].Value;
            // freeCap doesn't exist on category resources, thus infinity capacity
            const freeCap = cellRow.Cells[idx + 1]?.Value ?? Infinity;
            const maxValue = lastValue + freeCap;
            roundAllocation(newValue, maxValue, (roundedNew, roundedMax) => {
                if (roundedNew === roundedMax) {
                    newValue = maxValue;
                }
            });
            if (workType === WorkType.Allocation && isOverAllocated(newValue, maxValue, lastValue)) {
                error(language.CapacityViews.OverAllocation);
                Undo();
                // Skip this cell update
                return view;
            }
        }

        ProjectCapacityService.UpdateCellDomValue(view, flattenedGroups, container, group, resource, cellRow, workType, columnIndex, idx, newValue);

        // save cell
        const dto = ProjectCapacityService.BuildSaveCellDto(resolution, workType, cell, cellRow, group.Name, container?.Name);
        ProjectCapacityService.SaveCell(dto, cell.Id, error, groupByRef.current != null);
        setUIContext({ gridHasChanged: true });
        return view;
    };

    const OnMultipleCellsUpdate = (
        view: TopRow,
        resolution: TimelineResolution,
        workType: WorkType,
        idx: number,
        cells: Array<CellUpdateDto>,
        isUndo?: boolean,
    ): TopRow => {
        // store actions to be used in 'undo' feature
        if (!isUndo && uiSettings.settings.enableGridUndo)
            SaveActions(
                resolution,
                workType,
                idx,
                cells.map(_ => {
                    return { Cell: _.Cell, ColumnIndex: _.ColumnIndex, Value: _.Cell.Value };
                }),
            );

        const flattenedGroups = ProjectCapacityService.GroupsFlattened(viewRef.current);
        const dtos = cells
            .filter(_ => _.ColumnIndex < 100000)
            .reduce((acc, item) => {
                const group = flattenedGroups.find(_ => _.Id === item.Cell.Properties.ContractId);
                const resource = group.Rows.find(_ => _.Id === item.Cell.Properties.ResourceId && _.Properties.IsRequested === item.Cell.Properties.IsRequested);
                const cellRow = resource.CellRows[item.ColumnIndex];
                // get container if any
                const container =
                    group.Properties.ContainerId !== guid.empty ? (view.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow) : null;

                // reset align status
                if (shouldResetAlignStatus(workType)) {
                    group.Properties.AlignStatus = resetAlignStatus(workType);
                }

                if (uiSettings.settings?.restrictOverAllocating) {
                    const lastValue = cellRow.Cells[idx].Value;
                    const freeCap = cellRow.Cells[idx + 1]?.Value ?? Infinity;
                    const maxValue = lastValue + freeCap;
                    roundAllocation(item.Value, maxValue, (roundedNew, roundedMax) => {
                        if (roundedNew === roundedMax) {
                            item.Value = maxValue;
                        }
                    });

                    if (workType === WorkType.Allocation && isOverAllocated(item.Value, maxValue, lastValue)) {
                        error(language.CapacityViews.OverAllocation);
                        Undo();
                        return acc;
                    }
                }

                ProjectCapacityService.UpdateCellDomValue(
                    view,
                    flattenedGroups,
                    container,
                    group,
                    resource,
                    cellRow,
                    workType,
                    item.ColumnIndex,
                    idx,
                    item.Value,
                );

                const dto = ProjectCapacityService.BuildSaveCellDto(resolution, workType, item.Cell, cellRow, group.Name, container?.Name);
                acc.push(dto);
                return acc;
            }, [] as AddActivityDto[]);

        ProjectCapacityService.SaveCells(
            dtos,
            cells.map(_ => _.Cell.Id),
            error,
            groupByRef.current != null,
        );
        setUIContext({ gridHasChanged: true });
        return viewRef.current;
    };

    const TopCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 0, colIdx, CellType.Top)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return {
                    field: { backgroundColor: `${GetActivictyColor(cell, 1, colIdx, CellType.Top)} !important` },
                    root: { paddingRight: gridType === GridType.Allocation && !adminRef.current ? null : '10px' },
                };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' } };
            },
        ];
        if (adminRef.current) styles.splice(0, 1);
        return styles;
    };

    const GroupCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 0, colIdx, CellType.Group)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return {
                    field: { backgroundColor: `${GetActivictyColor(cell, 1, colIdx, CellType.Group)} !important` },
                    root: {
                        paddingRight: gridType === GridType.Allocation && !adminRef.current ? null : '10px',
                        cursor: gridType === GridType.Request ? 'pointer' : '',
                    },
                };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' } };
            },
        ];
        if (adminRef.current) styles.splice(0, 1);
        return styles;
    };

    const GroupContainerCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 0, colIdx, CellType.Container)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return {
                    field: { backgroundColor: `${GetActivictyColor(cell, 1, colIdx, CellType.Container)} !important` },
                    root: {
                        paddingRight: gridType === GridType.Allocation && !adminRef.current ? null : '10px',
                        cursor: gridType === GridType.Request ? 'pointer' : '',
                    },
                };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' } };
            },
        ];
        if (adminRef.current) styles.splice(0, 1);
        return styles;
    };

    const RowCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return !cell.Properties.GrandTotal
                    ? null
                    : { root: { backgroundColor: document.documentElement.style.getPropertyValue('--rowHoverBackgroundColor') } };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: gridType === GridType.Allocation && !adminRef.current ? null : '10px' } };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' }, field: { backgroundColor: `${CapacityUtil.GetFreeCapacityColor(theme, cell.Value)} !important` } };
            },
        ];
        if (adminRef.current) styles.splice(0, 1);
        return styles;
    };

    const CellUpdates = () => {
        const updates = [
            (cell: Cell, columnIndex: number, value: number, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                let updatedModel: TopRow;
                if (cell.Properties.IsProposedColumn) {
                    const dto = {
                        Origin: guid.empty,
                        Entity: EntityType.Contract,
                        Items: [
                            {
                                TypeName: 'Contract',
                                Id: cell.Properties.ContractId,
                                ProjectId: cell.Properties.ProjectId,
                                ResourceGuid: cell.Properties.ResourceId,
                                Name: cell.Properties.ContractName,
                                ProposedWork: value,
                                Stickyness: Sticky.Always,
                            },
                        ],
                        ChangedProperties: ['Id', 'Name', 'ProjectId', 'ResourceGuid', 'ProposedWork', 'Stickyness'],
                        Force: '2A02C94C-D08A-4F54-B215-FB3A3076E68C', // force upsert key on delta plugin
                    };
                    cell.Properties.ProposedWork = value;
                    cell.Value = value;
                    const flattenedGroups = ProjectCapacityService.GroupsFlattened(viewRef.current);
                    const group = flattenedGroups.find(_ => _.Id === cell.Properties.ContractId);
                    group.Properties.ProposedWork = value;
                    Plugin.InvokeNoResponse(PluginIDs.DeltaUpsert, dto)
                        .then(_ => {
                            ProjectCapacityService.HighlightCell([cell.Id], true, 'highlightdone');
                            // ctx.Set({GridHasChanges: true});
                            setUIContext({ gridHasChanged: true });
                        })
                        .catch(_ => {
                            error(language.Common.FailedSavingCells);
                            ProjectCapacityService.HighlightCell([cell.Id], true, 'highlighterror');
                            // ctx.Set({GridHasChanges: true});
                            setUIContext({ gridHasChanged: true });
                        });
                } else {
                    updatedModel = OnCellUpdate(viewRef.current, adminRef.current, timeline.resolution, WorkType.Request, columnIndex, 0, cell, value);
                    setView({ ...updatedModel });
                }
            },
            (cell: Cell, columnIndex: number, value: number, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const updatedModel = OnCellUpdate(
                    viewRef.current,
                    adminRef.current,
                    timeline.resolution,
                    WorkType.Allocation,
                    columnIndex,
                    adminRef.current ? 0 : 1,
                    cell,
                    value,
                );
                setView({ ...updatedModel });
            },
        ];
        if (adminRef.current) updates.splice(0, 1);
        return updates;
    };

    const DragUpdates = () => {
        const updates = [
            (cells: Array<CellUpdateDto>) => {
                const updatedModel = OnMultipleCellsUpdate(view, timeline.resolution, WorkType.Request, 0, cells);
                setView({ ...updatedModel });
            },
            (cells: Array<CellUpdateDto>) => {
                const updatedModel = OnMultipleCellsUpdate(view, timeline.resolution, WorkType.Allocation, adminRef.current ? 0 : 1, cells);
                setView({ ...updatedModel });
            },
        ];
        if (adminRef.current) updates.splice(0, 1);

        return updates;
    };

    const ExcelCopyUpdate = (cells: Array<CellUpdateDto>, excessiveCells: Array<CellUpdateDto>) => {
        const index = cells[0].CellIndex;
        const workType = adminRef.current ? WorkType.Allocation : index === 0 ? WorkType.Request : WorkType.Allocation;
        const updatedModel = OnMultipleCellsUpdate(view, timeline.resolution, workType, index, cells);
        setView({ ...updatedModel });
        // highlight copied items
        ProjectCapacityService.HighlightCell(
            cells.map(_ => _.Cell.Id),
            true,
        );
        if (excessiveCells.length) {
            warning(language.CapacityViews.ExcelCopyUpdate);
        }
    };

    const GetGroupGenericResourceId = (group: GroupRow): string => {
        const row = group.Rows.find(_ => _.Properties.ResourceType === ResourceType.Generic || _.Properties.ResourceType === ResourceType.Pool);
        if (!row) return 'none';
        return row.Properties.ResourceId;
    };

    const GetAlignStatus = (group: GroupRow) => {
        if (!uiSettings.settings.enableAlignContract) return null;
        if (!uiSettings.settings.alignContractStatuses || !uiSettings.settings.alignContractStatuses.length) return null;
        return upperCaseObj(
            uiSettings.settings.alignContractStatuses.find(_ => _.id === group.Properties.AlignStatus) ||
                [...(uiSettings.settings.alignContractStatuses || [])].sort((a, b) => (a.order > b.order ? 1 : -1))[0],
        );
    };

    let _timer: Timeout;
    const OnProjectHover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        _timer = EventEx.delay(
            _timer,
            () => {
                // setDetailDto(new EntityInformationCalloutDto(ctx.Get(_ => _.ProjectId), EntityType.Project, ".project-capacity-top-hover", { Url: ctx.Get(_ => _.Project).Url }));
                // setDetailDto(new EntityInformationCalloutDto(project?.id, EntityType.Project, ".project-capacity-top-hover", { Url: project?.Url }));
                setDetailDto({
                    Id: project?.id,
                    EntityType: EntityType.Project,
                    Target: '.project-capacity-top-hover',
                    // Properties: { Url: project?.Url }
                    Properties: { Url: project?.url },
                });
                // hack to prevent element losing event when re-rendering
                setTimeout(() => {
                    (document.querySelector('.project-capacity-top-hover') as HTMLElement).addEventListener('mouseleave', OnProjectHoverLeave as any);
                }, 10);
            },
            1000,
        );
    };

    const OnProjectHoverLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        clearTimeout(_timer);
        _timer = null;
        setDetailDto(null);
        (document.querySelector('.project-capacity-top-hover') as HTMLElement).removeEventListener('mouseleave', OnProjectHoverLeave as any);
    };

    const GroupDragStart = (group: GroupRow, container: GroupContainerRow, event: any) => {
        if (gridType === GridType.Request || adminRef.current) {
            // remove from own container
            if (container) {
                [].forEach.call(
                    document.querySelectorAll(`#gtc_${container.Id} .tp-list-capacity-removefromcontainer-dropzone-overlay`),
                    _ => (_.style.display = 'block'),
                );
                // add to another container
                [].forEach.call(
                    document.querySelectorAll(`.tp-grid-groupd-header > div:not(#gtc_${container.Id}) .tp-list-capacity-addcontainer-dropzone-overlay`),
                    _ => (_.style.display = 'block'),
                );
            } else
                [].forEach.call(
                    document.querySelectorAll(`.tp-grid-groupd-header .tp-list-capacity-addcontainer-dropzone-overlay`),
                    _ => (_.style.display = 'block'),
                );

            [].forEach.call(document.querySelectorAll(`.tp-list-capacity-addnewcontainer-dropzone-overlay`), _ => (_.style.display = 'block'));

            (event as any).dataTransfer.setData('grouprow', JsonEx.stringify(group));
            (event as any).dataTransfer.effectAllowed = 'all';
        }
        // if (OverviewContext.Settings.EnableMergeContracts && gridType === GridType.Allocation || adminRef.current) {
        if ((uiSettings.settings.enableMergeContracts && gridType === GridType.Allocation) || adminRef.current) {
            const row = group.Rows.find(_ => _.Properties.IsRequested);
            const genRes = !row ? null : row.Properties.ResourceId;
            // if row without request row, highlight all other groups
            const hasGenres = GetGroupGenericResourceId(group) !== 'none';
            if (hasGenres)
                [].forEach.call(
                    document.querySelectorAll(
                        `[data-genresid='${genRes}'].tp-list-capacity-mergegroup-dropzone-overlay, [data-genresid='none'].tp-list-capacity-mergegroup-dropzone-overlay`,
                    ),
                    _ => (_.style.display = 'block'),
                );
            else [].forEach.call(document.querySelectorAll(`.tp-list-capacity-mergegroup-dropzone-overlay`), _ => (_.style.display = 'block'));

            (event as any).dataTransfer.setData('grouprow', JsonEx.stringify(group));
            (event as any).dataTransfer.effectAllowed = 'all';
        }
    };

    const RowSort = (group: GroupRow): Array<Row> => {
        group.Rows.sort((a, b) => {
            // if resourcetype is the same, sort by name
            if (a.Properties.ResourceType === b.Properties.ResourceType && a.Properties.IsRequested === b.Properties.IsRequested)
                return a.Name.localeCompare(b.Name);
            // generics is first
            if (a.Properties.IsRequested) return -1;
            // then requested that is also allocated
            if (a.Id === group.Properties.RequestedResourceId && !b.Properties.IsRequested) return -1;
            // then named
            if (a.Properties.ResourceType === ResourceType.Named && !b.Properties.IsRequested && b.Id !== group.Properties.RequestedResourceId) return -1;
            // last category and teams
            else return 1;
        });
        return group.Rows;
    };

    const GetExtraMenuItems = (): Array<IContextualMenuItem> => {
        const menuItems: Array<IContextualMenuItem> = [];

        const groupByItems: Array<IContextualMenuItem> = [
            {
                key: 'default',
                text: language.ProjectCapacity.Default,
                title: language.ProjectRowMenu.GroupBy.ContractDefined,
                onClick: () => void ForceRefreshData(null),
                disabled: groupByRef.current == null,
            },
            {
                key: 'rbs',
                text: language.Common.Rbs,
                title: language.ProjectRowMenu.GroupBy.ResourceRBS,
                onClick: () => void ForceRefreshData('RBS'),
                disabled: groupByRef.current === 'RBS',
            },
            {
                key: 'workpackage',
                text: language.Common.WorkPackage,
                title: language.ProjectRowMenu.GroupBy.WorkPackage,
                onClick: () => void ForceRefreshData('WorkPackage'),
                disabled: groupByRef.current === 'WorkPackage',
            },
        ];

        if (uiSettings.settings.enableWorkPackages) {
            groupByItems.push({
                key: 'requesttemplate',
                text: language.ProjectCapacity.RequestTemplate,
                title: language.ProjectRowMenu.GroupBy.OriginatedRequestTemplate,
                onClick: () => void ForceRefreshData('RequestTemplate'),
                disabled: groupByRef.current === 'RequestTemplate',
            });
            // TODO: is this needed?
            if (gridType !== GridType.WorkPackage)
                groupByItems.push({
                    key: 'requesttemplaterun',
                    text: language.ProjectCapacity.RequestTemplateRun,
                    title: language.ProjectRowMenu.GroupBy.OriginatedRequestTemplateRun,
                    onClick: () => void ForceRefreshData('RequestTemplateRun'),
                    disabled: groupByRef.current === 'RequestTemplateRun',
                });
        }

        if (uiSettings.settings.enableMoveProjectRequests && gridType === GridType.Request) {
            menuItems.push({
                key: 'moveproject',
                text: language.ProjectCapacity.MenuItems.MoveProject.Text,
                title: language.ProjectCapacity.MenuItems.MoveProject.Title,
                iconProps: { iconName: 'TransitionPop' },
                onClick: () => {
                    const info = new RowMenuItemInfo(null, null, gridType, projectId);
                    ctx.Set({ ShowMoveRequestDialog: true, MoveRequestInfo: info });
                },
            });
        }

        if (!ArrayEx.isNullOrEmpty(uiSettings.settings.resourceCustomProperties)) {
            groupByItems.push({
                key: 'custom',
                text: 'Custom Properties',
                title: language.ProjectRowMenu.CustomProperties,
                itemType: ContextualMenuItemType.Header,
            });
            uiSettings.settings.resourceCustomProperties.forEach(_ => {
                groupByItems.push({
                    key: _.id,
                    text: _.displayName,
                    title: language.ProjectRowMenu.ResourceCustomProperty.replace('[[displayname]]', `${_.displayName}`),
                    onClick: () => void ForceRefreshData(`CustomProperties.${_.id}`),
                    disabled: groupByRef.current === `CustomProperties.${_.id}`,
                });
            });
        }

        if (uiSettings.settings.enableAlignContract) {
            groupByItems.push({
                key: 'align',
                text: 'Align Status',
                title: language.ProjectRowMenu.GroupBy.AlignStatus,
                onClick: () => void ForceRefreshData('AlignStatus'),
                disabled: groupByRef.current === 'AlignStatus',
            });
        }

        menuItems.push({
            key: 'groupby',
            text: language.ProjectRowMenu.GroupContractsText,
            title: language.ProjectRowMenu.GroupContractsTitle,
            iconProps: { iconName: 'GroupList' },
            subMenuProps: {
                items: groupByItems,
            },
        });

        if (uiSettings.settings.enableToggleHideGenericResource && gridType === GridType.Allocation) {
            menuItems.push({
                key: 'hideGenericResources',
                onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => (
                    <RenderHideGenericResourceToggle item={item} dismissMenu={dismissMenu} />
                ),
            });
        }

        if (uiSettings.settings.enableFastDelete) {
            menuItems.push({
                key: 'enableFastDelete',
                onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => <RenderFastDeletionToggle />,
            });
        }

        if (uiSettings.settings.enableFindAvailableCapacity && gridType === GridType.Request) {
            // add group
            menuItems.push({
                key: 'Find available capacity',
                text: language.ProjectCapacity.MenuItems.AutoDistribution.Text,
                title: language.ProjectCapacity.MenuItems.AutoDistribution.Title,
                iconProps: { iconName: 'MultiSelect' },
                subMenuProps: {
                    items: [
                        {
                            key: 'magicbutton',
                            text: language.Common.FindAvailableCapacity,
                            title: language.Common.FindAvailableCapacity,
                            iconProps: { iconName: 'Calculator' },
                            onClick: () => {
                                const info = new RowMenuItemInfo(null, null, null, projectId);
                                ctx.Set({ FindAvailableCapacityDialog: true, FindAvailableCapacityInfo: info });
                            },
                        },
                        {
                            key: 'magicbuttonsettings',
                            text: language.Common.FindAvailableCapacitySettings,
                            title: language.Common.FindAvailableCapacitySettings,
                            iconProps: { iconName: 'FilterSettings' },
                            onClick: () => setShowAutoDistributionSettings(true),
                        },
                    ],
                },
            });

            let hideProposedColumn = LocalStorage.getProperty<boolean>('tp-projectcapacity-proposedcolumn', projectId);
            const textGetter = (hide: boolean) => `${hide ? 'Show' : 'Hide'} proposed column`;
            menuItems.push({
                key: 'proposedcolumn',
                text: textGetter(hideProposedColumn),
                title: textGetter(hideProposedColumn),
                iconProps: { iconName: 'D365TalentInsight' },
                onClick: (ev, item: IContextualMenuItem) => {
                    hideProposedColumn = !hideProposedColumn;
                    item.text = textGetter(hideProposedColumn);
                    item.title = textGetter(hideProposedColumn);
                    LocalStorage.setProperty('tp-projectcapacity-proposedcolumn', projectId, hideProposedColumn);
                    setView({ ...view });
                },
            });
        }

        // if (OverviewContext.Settings.EnableScenarios && OverviewContext.Settings.ScenarioTypesEnabled?.indexOf(ScenarioType.ProjectScenario) >= 0) {
        if (uiSettings.settings.enableScenarios && uiSettings.settings.scenarioTypesEnabled?.indexOf(ScenarioType.ProjectScenario) >= 0) {
            const versionItems = [];
			
            if (checkPermissions({ userTypes: [...(uiSettings.settings.userTypesAllowedToCreateScenarios ?? [])], some: true }))
                versionItems.push({
                    key: 'drafts',
                    text: language.ProjectCapacity.WorkWithDrafts, // + " (temp disabled)",
                    title: language.ProjectCapacity.WorkWithDrafts,
                    iconProps: { iconName: 'DoubleColumnEdit' },
                    onClick: (ev, item) => setShowDraftDialog(true),
                } as IContextualMenuItem);
            if (checkPermissions({ userTypes: [...(uiSettings.settings.userTypesAllowedToApproveScenarios ?? [])], some: true }))
                versionItems.push({
                    key: 'versions',
                    text: language.ProjectCapacity.CompareScenarios,
                    title: language.ProjectCapacity.CompareScenarios,
                    iconProps: { iconName: 'Compare' },
                    onClick: (ev, item) => setShowVersionsDialog(true),
                });

            // add menu item if any sub items
            if (versionItems.length) {
                menuItems.push({
                    key: 'scenario',
                    text: language.ProjectCapacity.MenuItems.Scenario.Text,
                    title: language.ProjectCapacity.MenuItems.Scenario.Title,
                    iconProps: { iconName: 'MultiSelect' },
                    subMenuProps: {
                        items: versionItems,
                    },
                });
            }
        }

        // workpackage
        if (uiSettings.settings.enableWorkPackages && gridType === GridType.Request) {
            menuItems.push({
                key: 'workpackage',
                text: language.ProjectCapacity.MenuItems.Workpackage.Text,
                title: language.ProjectCapacity.MenuItems.Workpackage.Title,
                iconProps: { iconName: 'RowsChild' },
                onClick: (ev, item) => setWorkpackageImportDialogOpen(true),
            });
        }

		// TODO Use correct type when backend gets updated
        if ((uiSettings.settings as any).enableProjectExportRefresh && checkPermissions({ userTypes: [UserType.SuperUser] })) {
            menuItems.push({
                key: 'refreshexport',
                text: language.ProjectCapacity.MenuItems.RefreshExport.Text,
                title: language.ProjectCapacity.MenuItems.RefreshExport.Title,
                iconProps: { iconName: 'AnalyticsReport' },
                onClick: (ev, item) => {
                    refreshProjectReportData();
                },
            });
        }

        return menuItems;
    };

    const refreshProjectReportData = async () => {
        setLoading(true);
        try {
            await Plugin.Invoke(PluginIDs.ContractsReportDataInconsistencyAligner, { ProjectId: projectId, EnableMaintenanceMode: false });
        } catch (error) {
            error('Failed refreshing reports for this project!');
        }
        setLoading(false);
    };

    const BuildCustomColumns = (): Array<TableCustomColumn> => {
        if (!uiSettings.settings.enableFindAvailableCapacity) return null;
        if (gridType !== GridType.Request) return null;
        const hideProposedColumn = LocalStorage.getProperty<boolean>('tp-projectcapacity-proposedcolumn', projectId);
        if (hideProposedColumn) return null;
        const customColumn = BuildProposedWorkColumn(
            'proposedwork',
            '',
            '',
            [new SubHeaderRow('P')],
            false,
            row => row.Properties.ProposedWork ?? 0,
            row => {
                return {
                    IsProposedColumn: true,
                    ContractName: row.Name,
                    ProjectId: projectId,
                    ResourceId: row.Properties.RequestedResourceId,
                    ContractId: row.Id,
                };
            },
        );
        return [customColumn];
    };

    // for now only single cell supported, but multiple should be possible (ewi)
    const BuildProposedWorkColumn = (
        columnId: string,
        headerTopic: string,
        headerName: string,
        subHeaders: Array<SubHeaderRow>,
        cellDisabled: boolean,
        cellValueGetter: (row: BaseRow) => number,
        extraProps?: (row: BaseRow) => { [key: string]: any },
    ): TableCustomColumn => {
        const customColumn = new TableCustomColumn();
        customColumn.Id = columnId;
        customColumn.Header = new HeaderRow(headerTopic, headerName, ColumnType.Custom, null, null, subHeaders);
        customColumn.GroupInfo = new TableCustomColumnInfo(cellValueGetter, cellDisabled, extraProps);
        return customColumn;
    };

    const GetWorkPackageIconText = (group: GroupRow): string => {
        if (group.Properties.AncestorWorkPackageName == null || group.Properties.AncestorWorkPackageName === group.Properties.WorkPackageName)
            return `${language.ProjectCapacity.AddedByWorkPackage}: ${group.Properties.WorkPackageName}`;
        else
            return `${language.ProjectCapacity.AddedByWorkPackage}: ${group.Properties.AncestorWorkPackageName} - ${language.ProjectCapacity.FromChild}: ${group.Properties.WorkPackageName}`;
    };

    const titleContentOverride = useCallback(
        (title: string) => {
            return (
                <>
                    {uiSettings.settings.enableScenarios && (
                        <ScenariosAvailableIcon
                            projectId={projectId}
                            gridType={gridType}
                            refresh={refreshAvailableScenarios}
                            callback={() => setShowVersionsDialog(true)}
                        />
                    )}
                    <div className="project-capacity-top-hover" onMouseEnter={OnProjectHover} onMouseLeave={OnProjectHoverLeave}>
                        <Text className="tp-capacity-project-title">
                            <div className="truncate-text">{title}</div>
                        </Text>
                    </div>
                </>
            );
        },
        [refreshAvailableScenarios, projectId, gridType],
    );

    const fetchComments = useCallback(
        (row: IRow) => ApiCalls.getContractCommentsForResource({ contractId: row.Properties.ContractId, resourceId: row.Id }).then(data => data.data),
        [],
    );

    return (
        <>
            {loading || isFetching ? (
                <GridSkeleton />
            ) : (
                view && (
                    <div
                        onKeyDown={e => {
                            if (e.keyCode === 90 && e.ctrlKey) Undo();
                        }}
                        tabIndex={-1}
                        style={{ outline: 'unset' }}
                        className={AnimationClassNames.fadeIn200}
                    >
                        {!project?.isActive && (
                            <MessageBar messageBarType={MessageBarType.warning}>{`This project is inactive${
                                gridType === GridType.Allocation
                                    ? `! Any allocations here does not affect the allocated resources' free capacity, until the project is activated again.`
                                    : ''
                            }`}</MessageBar>
                        )}
                        <Table
                            Name={name}
                            Model={view}
                            localStorageKey={'ProjectCapacityHideGenericResources'}
                            ContainerClassName={`project-capacity-${gridType === GridType.Request ? 'request' : 'allocation'}`}
                            CellUpdates={CellUpdates()}
                            TopCellStyles={TopCellStyles()}
                            GroupCellStyles={GroupCellStyles()}
                            GroupContainerCellStyles={GroupContainerCellStyles()}
                            RowCellStyles={RowCellStyles()}
                            RowExtraContent={(row: Row, group?: GroupRow) => {
                                const rowResourceInaccessible = row.CellRows.every(cellRow => cellRow.Cells.every(cell => cell.Disabled));
                                return (
                                    <RowMenuContainer>
                                        {(checkPermissions({ userTypes: [UserType.SuperUser] }) && fastDeletionEnabled) ||
                                        (checkPermissions({ userTypes: [UserType.ProjectOwner] }) &&
                                            fastDeletionEnabled &&
                                            location.pathname.startsWith('/request') &&
                                            row.Properties['IsRequested'] === true) ||
                                        (checkPermissions({ userTypes: [UserType.LineManager] }) &&
                                            fastDeletionEnabled &&
                                            location.pathname.startsWith('/allocation') &&
                                            row.Properties['IsRequested'] === false) ? (
                                            <IconButton
                                                className={'tp-grid-fast-deletion-button'}
                                                iconProps={{ iconName: 'RecycleBin' }}
                                                onClick={async ev => {
                                                    DragAndDrop.RemoveDraggedResource = true;
                                                    const newModel = await ProjectCapacityService.OnResourceRemove(
                                                        view,
                                                        location.pathname.startsWith('/request') ? GridType.Request : GridType.Allocation,
                                                        row,
                                                        group,
                                                        timeline.resolution as any,
                                                        ProjectCapacityService.RemoveActivities,
                                                    );
                                                    setView({ ...newModel });
                                                    DragAndDrop.RemoveDraggedResource = false;
                                                }}
                                            />
                                        ) : (
                                            <div className="tp-capacity-resource-menucontainer">
                                                <Comments
                                                    elementId={`comment-${row.Properties.ContractId}-${row.Id}-${row.Properties.IsRequested}`}
                                                    hasComments={row.Properties.HasComments}
                                                    hasUnreadComments={row.Properties.HasUnreadComments}
                                                    canDelete={checkPermissions({ userTypes: [UserType.SuperUser] })}
                                                    fetchCallback={fetchComments}
                                                    onLoaded={(comments: Array<Comment>) => {
                                                        // don't do anything when no comments
                                                        if (!comments?.length) return;
                                                        // update comment read
                                                        row.Properties.HasUnreadComments = false;
                                                        const data = LocalStorage.get<any>('tp-commentsseen') || {};
                                                        const project = data[row.Properties.ProjectId] || {};
                                                        const contract = project[row.Properties.ContractId] || {};
                                                        contract[row.Id] = DateEx.toUtcString(DateEx.fixJsonAutoParse(new Date()));
                                                        project[row.Properties.ContractId] = contract;
                                                        data[row.Properties.ProjectId] = project;

                                                        // remove this at some point
                                                        // fallback for handling switch to the new commentsread structure
                                                        // removing the old commentread entry
                                                        if (Object.hasOwn(data, row.Properties.ContractId)) delete data[row.Properties.ContractId];

                                                        LocalStorage.set('tp-commentsseen', data);
                                                    }}
                                                    addCallback={comment =>
                                                        ApiCalls.upsertComment({
                                                            contractId: row.Properties.ContractId,
                                                            contractName: row.Name,
                                                            projectId: row.Properties.ProjectId,
                                                            resourceId: row.Properties.ResourceId,
                                                            comment: comment,
                                                        }).then(data => data.data)
                                                    }
                                                    removeCallback={async comment =>
                                                        ApiCalls.deleteComment({ contractId: row.Properties.ContractId, commentId: comment.id })
                                                    }
                                                    row={row}
                                                />
                                                {!adminRef.current && (
                                                    <ResourceRowMenu
                                                        ContractId={row.Properties.ContractId}
                                                        ResourceId={row.Id}
                                                        GridType={gridType}
                                                        ResourceType={row.Properties.ResourceType}
                                                        IsRequested={row.Properties.IsRequested}
                                                        AllProperties={row.Properties}
                                                        IsActive={row.Properties.Active}
                                                        ResourceIsInaccessible={rowResourceInaccessible}
                                                        RefreshCallback={() => ForceRefreshData(groupByRef.current)}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </RowMenuContainer>
                                );
                            }}
                            GroupExtraContent={(group: GroupRow) => {

                                return (
                                    <>
                                        <RowMenuContainer>
                                            {(checkPermissions({ userTypes: [UserType.SuperUser] }) && fastDeletionEnabled) ||
                                            (checkPermissions({ userTypes: [UserType.ProjectOwner] }) &&
                                                fastDeletionEnabled &&
                                                location.pathname.startsWith('/request')) ? (
                                                <IconButton
                                                    className={'tp-grid-fast-deletion-button'}
                                                    iconProps={{ iconName: 'RecycleBin' }}
                                                    onClick={async ev => {
                                                        const newModel = await ProjectCapacityService.OnContractRemove(
                                                            view,
                                                            group,
                                                            timeline.resolution,
                                                            ProjectCapacityService.RemoveContractActivities as any,
                                                        );
                                                        setView({ ...newModel });
                                                    }}
                                                />
                                            ) : (
                                                <div className="tp-capacity-contract-menucontainer">
                                                    {uiSettings.settings.enableWorkPackages && group.Properties.WorkPackageId && (
                                                        <IconButton
                                                            iconProps={{ iconName: 'Package' }}
                                                            title={GetWorkPackageIconText(group)}
                                                            className="package-icon"
                                                        />
                                                    )}
                                                    {uiSettings.settings.enableAlignContract && uiSettings.settings.alignContractStatuses.length > 0 && (
                                                        <div>
                                                            <AlignContractMenu
                                                                ContractId={group.Id}
                                                                Status={GetAlignStatus(group)}
                                                                Callback={status => {
                                                                    group.Properties.AlignStatus = status.Id;
                                                                    setView({ ...view });
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    {
                                                        <ContractRowMenu
                                                            ContractId={group.Id}
                                                            GridType={gridType}
                                                            IsProjectOwner={view?.Properties.IsOwner}
                                                            AllProperties={ObjectEx.mergeDeep(
                                                                {
                                                                    IsAdmin: project.projectType === ProjectType.Administrative,
                                                                    ContractName: group.Name,
                                                                    ProjectId: projectId,
                                                                    ResourceId: group.Properties.RequestedResourceId,
                                                                    ProposedWorkCallback: (pWork: number) => (group.Properties.ProposedWork = pWork),
                                                                },
                                                                group.Properties,
                                                            )}
                                                        />
                                                    }
                                                </div>
                                            )}
                                        </RowMenuContainer>
                                        <div
                                            className="tp-list-capacity-dropzone-overlay tp-grid-group"
                                            onDrop={e => OnResourceAdd(e, group)}
                                            onDragEnter={e => e.preventDefault()}
                                            onDragOver={e => e.preventDefault()}
                                        >
                                            <div className="tp-list-capacity-dropzone-text">Drop here</div>
                                            {/* TODO: Localization */}
                                        </div>
                                        <div
                                            className="tp-list-capacity-mergegroup-dropzone-overlay tp-grid-group"
                                            onDrop={e => OnMergeContract(e, group)}
                                            onDragEnter={e => e.preventDefault()}
                                            onDragOver={e => e.preventDefault()}
                                            data-genresid={GetGroupGenericResourceId(group)}
                                        >
                                            <div className="tp-list-capacity-dropzone-text">Merge onto</div>
                                            {/* TODO: Localization */}
                                        </div>
                                    </>
                                );
                            }}
                            GroupExtraTextContent={(group: GroupRow) => (
                                <>
                                    {!ProjectChangesUtil.hasSeenChangesProject(group.Properties.ProjectId, group.Properties.LastModifiedByOthers, true) &&
                                        Glimmer.ShowGlimmer(gridType, UserContext.Resource.UserTypes) && (
                                            <div style={{ display: 'inline-block', textAlign: 'right', marginRight: '6px', paddingLeft: '6px' }}>
                                                <Icon
                                                    id={`changesicon-${group.Id}`}
                                                    iconName="Glimmer"
                                                    className={`tp-capacity-contract-changesicon ${
                                                        group.Properties.ContainerId === guid.empty ? '' : 'incontainer'
                                                    }`}
                                                    title={language.ProjectCapacity.GroupExtraTextContent}
                                                />
                                            </div>
                                        )}
                                </>
                            )}
                            GroupContainerExtraContent={(name: string, container: GroupContainerRow) => (
                                <>
                                    <div
                                        className="tp-list-capacity-addcontainer-dropzone-overlay"
                                        onDrop={e => OnGroupAdd(e, container)}
                                        onDragEnter={e => e.preventDefault()}
                                        onDragOver={e => e.preventDefault()}
                                    >
                                        <div className="tp-list-capacity-dropzone-text">{language.ProjectCapacity.GroupContainerExtraContent.AddToGroup}</div>
                                    </div>
                                    <div
                                        className="tp-list-capacity-removefromcontainer-dropzone-overlay"
                                        onDrop={e => OnGroupAdd(e, container)}
                                        onDragEnter={e => e.preventDefault()}
                                        onDragOver={e => e.preventDefault()}
                                    >
                                        <div className="tp-list-capacity-dropzone-text">
                                            {language.ProjectCapacity.GroupContainerExtraContent.MoveOutOfGroup}
                                        </div>
                                    </div>
                                    {(gridType === GridType.Request || adminRef.current) && !groupByRef.current && (
                                        <div
                                            className="tp-list-capacity-dropzone-overlay tp-grid-top"
                                            onDrop={e => OnResourceAddToContainer(e, container)}
                                            onDragEnter={e => e.preventDefault()}
                                            onDragOver={e => e.preventDefault()}
                                        >
                                            <div className="tp-list-capacity-dropzone-text">{language.ProjectCapacity.DropHere}</div>
                                        </div>
                                    )}
                                </>
                            )}
                            GroupContainerSuffixContent={(name, container) => {
                                return (checkPermissions({ userTypes: [UserType.SuperUser] }) && fastDeletionEnabled) ||
                                    (checkPermissions({ userTypes: [UserType.ProjectOwner] }) &&
                                        fastDeletionEnabled &&
                                        location.pathname.startsWith('/request')) ? (
                                    <IconButton
                                        className={'tp-grid-fast-deletion-button'}
                                        styles={{ root: { marginLeft: 'auto' } }}
                                        iconProps={{ iconName: 'RecycleBin' }}
                                        onClick={async ev => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            const newModel = await ProjectCapacityService.OnGroupRemove(
                                                view,
                                                container,
                                                timeline.resolution,
                                                ProjectCapacityService.RemoveContractActivities as any,
                                            );
                                            setView({ ...newModel });
                                        }}
                                    />
                                ) : null;
                            }}
                            GridSettingsSpacingAmount={uiSettings.settings.gridSpacing}
                            GridSettingsHidePersonaIcons={uiSettings.settings.hidePersonaIconsInGrid}
                            EnableGridSettings={uiSettings.settings.enableGridSettings}
                            SetGridSettingsHidePersonaIconsCallback={value =>
                                UserSettingsService.UpdateUserSettingsPartial({ HidePersonaIconsInGrid: value }, null, true)
                            }
                            SetGridSettingsSpacingAmountCallback={value => UserSettingsService.UpdateUserSettingsPartial({ GridSpacing: value }, null, true)}
                            GridSettingsExtraMenuItems={GetExtraMenuItems()}
                            TopExtraContent={(top: TopRow) => (
                                <>
                                    <div
                                        className="tp-list-capacity-dropzone-overlay tp-grid-top"
                                        onDrop={e => OnResourceAdd(e, top)}
                                        onDragEnter={e => e.preventDefault()}
                                        onDragOver={e => e.preventDefault()}
                                    >
                                        <div className="tp-list-capacity-dropzone-text">{language.ProjectCapacity.DropHere}</div>
                                    </div>
                                    <div
                                        className="tp-list-capacity-addnewcontainer-dropzone-overlay"
                                        onDrop={e => OnGroupAdd(e)}
                                        onDragEnter={e => e.preventDefault()}
                                        onDragOver={e => e.preventDefault()}
                                    >
                                        <div className="tp-list-capacity-dropzone-text">{language.ProjectCapacity.NewGroup}</div>
                                    </div>
                                </>
                            )}
                            TitleContentOverride={titleContentOverride}
                            GroupEditEnabled={group =>
                                !uiSettings.settings.enableDynamicContractNames &&
                                (gridType === GridType.Request ||
                                    adminRef.current ||
                                    (gridType === GridType.Allocation && uiSettings.settings.allowLMsToChangeContractName))
                            }
                            // GroupEditEnabled={group => !OverviewContext.Settings.EnableDynamicContractNames && (gridType == GridType.Request || adminRef.current || (gridType == GridType.Allocation && OverviewContext.Settings.AllowLMsToChangeContractName)) }
                            GroupContainerEditEnabled={container => !groupByRef.current && (gridType === GridType.Request || adminRef.current)}
                            GroupEdit={ChangeContractName}
                            GroupContainerEdit={ChangeContractContainerName}
                            RowClick={(row: Row) =>
                                gridType === GridType.Allocation &&
                                (row.Properties.ResourceType === ResourceType.Named ||
                                    row.Properties.ResourceType === ResourceType.Team ||
                                    row.Properties.ResourceType === ResourceType.Category) &&
                                !!row.Properties.Editable
                                    ? goTo(buildRoute('resourcecapacity', row.Id))
                                    : null
                            }
                            RowDraggable={(row: Row) =>
                                !!row.Properties.Editable &&
                                ((gridType === GridType.Allocation && !row.Properties.IsRequested) ||
                                    (gridType === GridType.Request && row.Properties.IsRequested))
                            }
                            RowDragStart={(row, group, event) => (document.getElementById('tp-list-resources-dropzone-overlay').style.display = 'block')}
                            RowDragEnd={async (row, group, event) => await RemoveResource(row, group)}
                            GroupDraggable={(group: GroupRow) => true}
                            GroupDragStart={GroupDragStart}
                            GroupDragEnd={async (group, event) =>
                                [].forEach.call(
                                    document.querySelectorAll(
                                        `.tp-list-capacity-addcontainer-dropzone-overlay, .tp-list-capacity-mergegroup-dropzone-overlay, .tp-list-capacity-removefromcontainer-dropzone-overlay, .tp-list-capacity-addnewcontainer-dropzone-overlay`,
                                    ),
                                    _ => (_.style.display = 'none'),
                                )
                            }
                            GroupSort={(groups: Array<GroupRow>) => groups.sort((a, b) => a.Name?.localeCompare(b.Name))}
                            RowSort={RowSort}
                            RowClassName={(row: Row) => `${row.Properties.IsRequested ? 'tp-capacity-resource-generic' : ''}`}
                            DragHighlighterUpdate={DragUpdates()}
                            PasteFromExcel={uiSettings.settings.enableCopyFromExcel && ExcelCopyUpdate}
                            AllowContainerCollapse
                            AllowGroupCollapse
                            GridCellDecimals={ProjectCapacityService.GetGridDecimals()}
                            CustomColumns={BuildCustomColumns()}
                        />
                        <ConfirmDialog ConfirmInfo={localConfirmInfo} />
                        {/* Cannot use the callout in main, due to state vs. onmouseleave fuckups!!
							So placing one specific for this view here! #theonlyway? - ewi */}
                        <EntityInformationCallout Dto={detailDto}>
                            {detailDto && detailDto.Properties.Url && (
                                <Link className="ms-CalloutExample-link" href={detailDto.Properties.Url} target="_blank">
                                    {language.ProjectCapacity.GoToProjectAtOrigin}
                                </Link>
                            )}
                        </EntityInformationCallout>
                        <ProjectDraftDialog
                            Show={showDraftDialog}
                            ProjectId={projectId}
                            Callback={async dto => {
                                // new scenario, create it
                                if (dto.DraftId == null) {
                                    const newScenario = new ProjectScenario();
                                    newScenario.id = guid.newGuid();
                                    newScenario.Name = dto.DraftName;
                                    newScenario.ProjectId = projectId;
                                    newScenario.OwnerId = UserContext.Resource.id;
                                    newScenario.State = ProposalState.Draft;
                                    const created = await DataService.Upsert<Scenario>(EntityType.Scenario, [newScenario]);
                                    dto.DraftId = created[0].id;
                                }
                                if (gridType === GridType.Allocation) goTo(buildRoute(`allocation/draft/${projectId}/${dto.DraftId}`));
                                else if (gridType === GridType.Request) goTo(buildRoute(`request/draft/${projectId}/${dto.DraftId}`));
                                setShowDraftDialog(false);
                            }}
                            DismissCallback={() => setShowDraftDialog(false)}
                        />
                        <CompareScenariosDialog
                            show={showVersionsDialog}
                            projectId={projectId}
                            states={activeProposalStatesWithoutDraft}
                            versionIds={[]}
                            callback={(versionIds, start, end) => {
                                // switch view - set versionids
                                const path = gridType === GridType.Request ? 'request' : 'allocation';

                                const freezeDate = ResolutionUtil.getStartUsingFreezePeriod(
                                    upperCaseObj(uiSettings.settings.freezePeriod),
                                    timeline.resolution as any,
                                    uiSettings.settings.useISO8601,
                                );
                                const minStart = freezeDate > start ? freezeDate : start;
                                if (DateEx.isNullOrMin(minStart) || DateEx.isNullOrMin(end)) {
                                    goTo(buildRoute(`${path}/compare/${projectId}/${versionIds.join('|')}`));
                                } else {
                                    setTimeline({ start: minStart, end });
                                    goTo(buildRoute(`${path}/compare/${projectId}/${versionIds.join('|')}`));
                                }
                            }}
                            dismissCallback={() => setShowVersionsDialog(false)}
                            gridType={gridType}
                        />
                        <WorkpackageImportDialog
                            isOpen={isWorkpackageImportDialogOpen}
                            options={{ projectId: projectId, resolution: timeline.resolution, defaultStartDate: timeline.start }}
                            onDismiss={() => setWorkpackageImportDialogOpen(false)}
                            onSuccess={() => {
                                success(language.ProjectCapacity.WorkPackageImportDialog.SuccesfullyAddedWorkpackages);
                                OverviewContext.RefreshProjectCapacity();
                            }}
                            onError={() => {
                                error(language.ProjectCapacity.WorkPackageImportDialog.FailedAddingWorkpackages);
                            }}
                        />
                        <AutoDistributionSettingsDialog
                            callback={() => setShowAutoDistributionSettings(false)}
                            dismiss={() => setShowAutoDistributionSettings(false)}
                            show={showAutoDistributionSettings}
                            projectId={projectId}
                        />
                        <CountDownDialog />
                    </div>
                )
            )}
            {gridType === GridType.Allocation && <ProjectCapacityCoachmarks />}
        </>
    );
};


export default ProjectCapacity;

const RowMenuContainer = ({ children, disabled, styles }: { children?: React.ReactNode; disabled?: boolean; styles?: IStackProps['styles'] }) => {

    const Tooltip = useCallback(
        ({ children }: { children: React.ReactNode }) => {
            if (disabled) {
                return <TooltipHost content={'Cannot edit contract without requests or allocations'}>{children}</TooltipHost>;
            }
            return <>{children}</>;
        },
        [disabled],
    );
    return (
        <Tooltip>
            <Stack
                styles={{
                    root: {
                        position: 'relative',
                        ...(disabled && {
                            '&:after': {
                                position: 'absolute',
                                inset: 0,
                                content: "''",
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                cursor: 'not-allowed',
                                pointerEvents: 'none',
                            },
                            cursor: 'not-allowed',
                            pointerEvents: 'none',
                        }),
                        ...styles,
                    },
                }}
            >
                {children}
            </Stack>
        </Tooltip>
    );
};
