import Locale_SettingsGroupings from "./Locale_SettingsGroupings";

export default class Locale_Settings {

	Groupings: Locale_SettingsGroupings = new Locale_SettingsGroupings();

	//BASIC
	SettingsName: string = "Settings name";
	DisplayNameDescription: string = "The name used for ie. the tab header";
	LogSettings: string = "Log settings";
	DefaultWorkProfile: string = "Default work profile";
	AllowSearchAllProjects: string = "Allow all users to search all projects";
	AllowSearchAllResources: string = "Allow all users to search all resources";
	AllowedToDesignWorkpackages: string = "Who is allowed to design request templates";
	AllowedToDelegate: string = "Who is allowed to delegate?";
	RbsRestrictedUsers: string = "Who is RBS restricted when delegating?";
	AllowChangeRbsAndCustomPropertiesOnGenericResource: string = "Allow change of RBS and Custom Properties on generic resources";
	RbsLevelsBelowAccessible: string = "Allow access to below RBS levels for";
	LmCanEditOwnResources: string = "Show 'Resource Overview' for LMs";
	PmCanEditOwnProjects: string = "Show 'Project Overview' for PMs";
	ShownOwnProjectsForLM: string = "LM can see projects in picker where he is owner";
	LightbulbLimitInMonths: string = "Lightbulb time limit in months";
	LightbulbLimitInMonthsDescription: string = "Only show lightbulb for issues that lies within this number of months from the current date. (0 will disable it)";
	GlimmerConfiguration: string = "Hide Glimmer Options";

	//FEATURES
	EnableForwardRequest: string = "Enable 'Forward request'";
	EnableMoveRequest: string = "Enable 'Move request in time'";
	EnableMoveProjectRequests: string = "Enable 'Move project requests in time'";
	EnableMergeContracts: string = "Enable 'Merge contracts'";
	EnableCreateMatchingRequest: string = "Enable 'Create matching request'";
	EnableCreateMatchingAllocation: string = "Enable 'Create matching allocation'";
	EnableMoveAllAllocations: string = "Enable 'Move all allocations'";
	EnableGridUndo: string = "Enable 'Grid undo' functionality";
	EnableGridUndoDescription: string = "Makes it possible to undo cell edits in grids.";
	EnableCopyFromExcel: string = "Enable 'Copy from excel' functionality";
	EnableCopyFromExcelDescription: string = "Makes it possible to copy data from excel and paste it into grids.";
	EnableLmCapacityView: string = "Enable LM 'Planner view'";
	EnableLmCapacityViewDescription: string = "A view that gives the Line Manager an overview, which allows him/her to plan resources across projects.";
	EnableResourcePlannerView: string = "Enable Resource Planner view";
	EnableRequestNamedResource: string = "Enable request of named resources";
	EnableFastDelete: string = "Enable 'Fast Delete'"
	EnableFastDeleteDescription: string = "Enable fast deletion of resources from grid views. This feature is available in the Grid Settings of the capacity views."
	EnableSearchNamedResources: string = "Enable search generic resources by named resource";
	EnableContractVersions: string = "Enable persisting automatic versions of contracts (PREVIEW)";
	EnableMailNotifications: string = "Enable mail notifications (PREVIEW)";
	EnableWorkPackages: string = "Enable 'Request Templates' functionality";
	EnableResourcePools: string = "Enable 'Resource pool' functionality";
	EnableDynamicResourcePools: string = "Enable 'Dynamic Resource pool' functionality";
	EnableCoachmarks: string = "Enable 'Coachmarks'";
	EnableScenarios: string = "Enable 'Scenarios' (PREVIEW)";
	EnableResourceCapacityView: string = "Enable 'Resource Capacity View'";
	EnableFindAvailableCapacity: string = "Enable 'FindAvailable Capacity' feature";
	EnableGridSettings: string = "Enable 'Grid settings' feature";
	EnableToggleHideGenericResource: string = "Enable 'Hide requested resource' toggles";
	EnableToggleHideGenericResourceDescription: string = "Enable toggles on Allocation- and Planner views to hide generic resources from the grid. On Allocation it's available in the 'Grid Settings' cog. In the Planner View it's available in the ribbon.";
	EnableEmbeddedCharts: string = "Enable 'Embedded Charts'";
	EmbeddedChartsDescription: string = "Remember to enable specific charts under 'Preview and experimental > Preview > Charts'";
	EnableProjectExportRefresh: string = "Enable refresh of a projects export data (placed under grid settings gear)";
	ForcePinnedTopbar: string = "Pin 'Topbar' for all users";
	EnableHelpSection: string = "Enable 'Help section'"

	PrefixContractNamesWhenGroupByWorkpackageRun: string = "When grouping contracts by request template run, prefix all contract names with the name of the request template they were added by";

	//RBS
	RBSTraverseMode: string = "RBS traverse mode";
	RBSTraverseModeDescription: string = "How the RBS is traversed, when searching in it. This will affect performance, depending on how your RBS is structured";
	AdminRbsMatchDirection: string = "Administrative project RBS match direction";
	AdminRbsMatchDirectionDescription: string = "Which way it should match RBS, for administrative projects";

	//CAPACITY SETTINGS
	UnitType: string = "Unit Type";
	UnitTypeDescription: string = "The unit chosen will be reflected in the projects. For 'Both - Hours as primary' hours will be reflected in the projects and both units will be calculated in the backend";
	ForceUnitTypeOnRequestView: string = "Force Unit Type On Request View";
	ForceUnitTypeOnRequestViewDescription: string = "If selected, the request view will be locked to the chosen type.";
	ForceUnitTypeOnAllocationView: string = "Force Unit Type On Allocation View";
	ForceUnitTypeOnAllocationViewDescription: string = "If selected, the allocation view will be locked to the chosen type.";
	OverlappingRulePreferrer: string = "Overlapping rule preferred";
	OverlappingRulePreferrerDescription: string = "When rules from workprofiles overlap, you can choose if one workprofile is preferred";
	OverlappingRuleDecision: string = "Overlapping rule decision";
	OverlappingRuleDecisionDescription: string = "When rules are overlapping, this decides which rule will win. If a preferred workprofile is chosen, this will superseed this setting for rules across two workprofiles.";

	//RESOURCE SETTINGS
	GenericResourceDefinition: string = "Generic resource definition";
	GenericResourceDefinitionDescription: string = "How we define generic resources. Based on the RBS plus the selected properties.";
	AutoGenerateGenericResources: string = "Auto generate non-existing generic resources";
	AutoGenerateGenericResourcesDescription: string = "If a resource hasn't got a mathcing generic resource, should it be automatically created, using below naming convention.";
	GenericResourceNamingConvention: string = "Generic resource naming convention";
	GenericResourceNamingConventionDescription: string = "The expression used to name the auto generated generic resources. Rbs name can be referenced by using '[RBS]'. Properties can be referenced by surrounding them in square brackets. ie. '[RBS]-[Property1_InternalName]_[Property3_InternalName]'";
	EnableResourcePoolsDescription: string = "This will make 'Resource pools' pool available. This opens up for creating resources of the type 'Pool'. A pool resource acts as a RBS agnostic generic gesource.";
	EnableDynamicResourcePoolsDescription: string = "This will make 'Resource pools' more dynamic in it's matching. Instead of matching on custom properties, the dynamic pool uses a highly dynamic filter. (IMPORTANT: All existing resource pools, will be seen as a dynamic, meaning they are not usable until a filter has been created for each of them!)";
	ShowOnlyRelevantResourcesInPicker: string = "Show only relevant resources in resource panel";
	ShowOnlyRelevantResourcesInPickerDescription: string = "If disabled the resource panel for allocations, will contain all resources under a users RBS's. If enabled it will filter these resources, based on the requests in the allocation view.";
	PmResourcePickerGroupBy: string = "Enabled user filters for resource panel (Request view)";
	PmResourcePickerGroupByDescription: string = "Which filters are shown in the 'Resource Panel' when on the Request view. The filters are shown in the order they are selected here.";
	LMResourcePickerGroupBy: string = "Enabled user filters for resource panel (Allocation view)";
	LMResourcePickerGroupByDescription: string = "Which filters are shown in the 'Resource Panel' when on the Allocation view. The filters are shown in the order they are selected here.";
	PMResourcePickerDisplayFields: string = "Resource panel display fields (Request view)";
	LMResourcePickerDisplayFields: string = "Resource panel display fields (Allocation view)";
	

	ResourceFilterPM: string = "Resource panel filter for request view";
	ResourceFilterPMDescription: string = "Filter out resources in the request view panel, based on property values.";
	ResourceFilterLM: string = "Resource panel filter for allocation view";
	ResourceFilterLMDescription: string = "Filter out resources in the allocation view panel, based on property values.";
	ResourceFilterOverview: string = "Resource filter for the overview";
	ResourceFilterOverviewDescription: string = "Filter out resources in the overview, based on property values.";
	AllowedToInactivateResources: string = "Who is allowed to inactivate resources";
	AllowedToDeleteResources: string = "Who is allowed to delete resources";
	AllowedToCreateResources: string = "Who is allowed to create resources";
	AllowedToEditResources: string = "Who is allowed to edit resources";
	AllowedToSeeNamedResourcesWithoutRbs: string = "Who is allowed to see resources without an rbs";
	ResourceOverviewProperties: string = "Properties used in 'Resource Overview'";
	ResourceOverviewPropertiesDescription: string = "Defines which resource properties that is shown in the 'Resource Overview'.";
	ResourceCustomPropertiesDescription: string = "The custom properties for resources.";
	ResourceInformationProperties: string = "Properties used in 'Resource Information' dialog";
	ResourceInformationPropertiesDescription: string = "Defines which resource properties that is shown in the 'Resource Information' dialog.";
	ContractInformationProperties: string = "Properties used in 'Contract Information' dialog";
	ContractInformationPropertiesDescription: string = "Defines which contract properties that is shown in the 'Contract Information' dialog.";
	AllowPlannerViewGenericResourceSelection: string = "Allow selection of generic resources in 'Planner View'";
	AllowPlannerRootRbsMatch: string = "Enable match on RBS 'root'";
	DisableResourcePanelCalculationItemLimit: string = "Item limit for disabling panel calculations";
	DisableResourcePanelCalculationItemLimitDescription: string = "When items in the resource panel exceeds this number of items, KPI calculations will be disabled";
	PlaceholderGenericResource: string = "Placeholder generic resource";
	MininumFreeCapacityPercentageForAutoAllocation: string = "Mininum free capacity for auto-allocation";
	DefaultActivityDistributionConfig: string = "Default activity distribution settings";
	AutoInactivateResources: string = "Auto set active, based on rules";
	AutoInactivateResourcesDescription: string = "Decide how resource's active state is set automatically, based on property values.";
	ResourceFilterDialogTitle: string = "Resource filter editor";
	ResourcePanelAddToGrid: string = "How to add resources to grid, using the Resource panel";

	//PROJECT SETTINGS
	ProjectPickerDisplayFields: string = "Project picker display fields";
	ForceParentOnAdministrativeProjects:string = "Force parent project on administrative projects";
	ProjectFilterPM: string = "Project picker filter for Project Managers";
	ProjectFilterPMDescription: string = "Filter out projects in the picker, when on the request view, based on property values.";
	ProjectFilterLM:string = "Project picker filter for Line Managers";
	ProjectFilterLMDescription:string = "Filter out projects in the picker, when on the allocation view, based on property values.";
	ProjectFilterOverview: string = "Project filter for the overview";
	ProjectFilterOverviewDescription: string = "Filter out resources in the overview, based on property values.";
	ProjectsAlwaysShowRBS: string = "RBS edit allowed on all projects";
	ProjectInformationProperties: string = "Properties used in 'Project Information' dialog";
	ProjectInformationPropertiesDescription: string = "Defines which project properties that is shown in the 'Project Information' dialog.";
	AllowedToInactivateProjects: string = "Who is allowed to inactivate projects";
	AllowedToDeleteProjects: string = "Who is allowed to delete projects";
	AllowedToCreateProjects: string = "Who is allowed to create projects";
	AllowedToEditProjects: string = "Who is allowed to edit projects";
	LimitAdditionalOwnerPropertyEditing: string = "Restrict editing privileges of specific properties by additional owners.";
	LimitAdditionalOwnerPropertyEditingDescription: string = "The selected properties will not be editable by additional owners.";
	ResourcePlannerFilterWhitelist: string = "Resource Planner filter whitelist.";
	ResourcePlannerFilterWhitelistDescription: string = "Defines which resource properties that are shown in the 'Resource Planner' filter panel.";

	ProjectPlannerResourceFilterWhitelist: string = "Project Planner resource filter whitelist.";
	ProjectPlannerResourceFilterWhitelistDescription: string = "Defines which resource properties that are shown in the 'Project Planner' filter panel.";
	ProjectPlannerProjectFilterWhitelist: string = "Project Planner project filter whitelist.";
	ProjectPlannerProjectFilterWhitelistDescription: string = "Defines which project properties that are shown in the 'Project Planner' filter panel.";

	ProjectOverviewProperties: string = "Properties used in 'Project Overview'";
	ProjectOverviewPropertiesDescription: string = "Defines which project properties that is shown in the 'Project Overview'.";
	ProjectCustomPropertiesDescription: string = "The custom properties for projects.";
	DisableProjectPanelCalculationItemLimit: string = "Item limit for disabling panel calculations";
	DisableProjectPanelCalculationItemLimitDescription: string = "When items in the project panel exceeds this number of items, KPI and lightbulb calculations will be disabled";
	AutoInactivateProjects: string = "Auto set active, based on rules";
	AutoInactivateProjectsDescription: string = "Decide how project's active state is set automatically, based on property values.";
	AutoInactiveDialogTitle: string = "Auto set active state";
	ProjectFilterDialogtitle: string = "Project filter editor";
	HideAlignStatusOnProjectPanel: string = "Hide 'Align Status' in Project Panel list items";
	PmProjectPickerGroupBy: string = "Enabled user filters for project panel (Request view)";
	PmProjectPickerGroupByDescription: string = "Which filters are shown in the 'Project Panel' when on the Request view. The filters are shown in the order they are selected here.";
	LMProjectPickerGroupBy: string = "Enabled user filters for project panel (Allocation view)";
	LMProjectPickerGroupByDescription: string = "Which filters are shown in the 'Project Panel' when on the Allocation view. The filters are shown in the order they are selected here.";
	IncludeContractStatusFilterInProjectPanel: string = "Add 'Contract status' filter to project panel.";
	ResourcesCanEditOwnAllocations: string = "Resources can edit own allocations";
	ResourcesCanEditOwnAllocationsDescription: string = "This filter defines which projects that resources can edit their own allocations on.";
	ResourcesCanEditOwnAllocationsDialogTitle: string = "Project filter";
	ResourcesCanAddAllocations: string = "Resources can add allocations";
	ResourcesCanAddAllocationsDescription: string = "This filter defines which projects that resources can add their own allocations on.";
	ResourcesCanAddAllocationsDialogTitle: string = "Project filter";

	//CONTRACTS
	ContractDefaultName: string = "Contract default name";
	ContractDefaultNameDescription: string = "When adding a resource, use this field from the resource to generate the default contract name.";
	AllowLMsToChangeContractName: string = "Allow LM's to change contract names";
	AllowLMsToChangeContractNameDescription: string = "Usually it is only PM's that can.";
	SingletonOnRequests: string = "Only one contract per generic resource per project";
	SingletonOnRequestsDescription: string = "Disables that contracts with already existing generic resources can be added to a project.";
	RestrictOverAllocating: string = "Restrict over allocating resources";
	RestrictOverAllocatingDescription: string = "When this is enabled, it is not possible to overallocate resources, in regards to their free capacity.";
	FreezePeriod: string = "Freeze period";
	FreezePeriodDescription: string = "Enable freeze periods of future or past activities.";
	EnableDynamicContractNames: string = "Enable dynamic contract names";
	EnableDynamicContractNamesDescription: string = "Dynamically show contract names as the name of the related resource. This will lock for edit of contract names. The underlying data is not affected!";
	ContractCustomPropertiesDescription: string = "The custom properties for contracts.";
	EnableAlignContract: string = "Enable 'Align Contract'";
	EnableAlignContractDescription: string = "Allows PMs and LMs to align requests";
	AlignContractStatuses: string = "Align contract statuses";
	AlignContractStatusesDescription: string = "The customizable statuses used when aligning contracts";
	ResetAlignContractOnRequestChange: string = "Reset 'Align Status' on request change";
	ResetAlignContractOnRequestChangeDescription: string = "When a request is changed the Align Status on the contract is reset.";
	ResetAlignContractOnAllocationChange: string = "Reset 'Align Status' on allocation change";
	ResetAlignContractOnAllocationChangeDescription: string = "When an allocation is changed the Align Status on the contract is reset.";
	AutoGroupContractBy: string = "Auto-group new contracts by";
	AutoGroupContractByDescription: string = "New contracts will be automatically grouped by the resource's property value.";
	AutoAlignContractNames: string = "Auto align contract names";
	AutoAlignContractNamesDescription: string = "When generic resources changes names, enabling this, will rename all relevant contracts, using the name of the generic resource.";
	ContractOverviewProperties: string = "Properties used in 'Contract Overview'";
	ContractOverviewPropertiesDescription: string = "Defines which contract properties that is shown in the 'Contract Overview'.";
	AllowedToSeeContractOverview: string = "Who is allowed to see the 'Contract Overview'";
	RestrictDirectAllocations: string = "Restrict direct allocations";
	RestrictDirectAllocationsDescription: string = "When enabled it is not allowed to allocate directly on a default project. But only on existing requests.";
	
	//REPORTS
	SQLExportConnectionString: string = "SQL Export ConnectionString";
	SQLExportConnectionStringDescription: string = "ConnectionString SQLExport Integration is using to connect to SQL (in previously version of Team Planner this was set in Azure).";

	//CHARTS
	ChartsInfoText: string = "Remember to enable 'Embedded Charts'!";
	ChartsInfoDescription: string = "It can be enabled under the 'Features' tab.";
	EnabledCharts: string = "Enable specific Charts!";

	//VERSIONS
	VersionsInfoText: string = "Contract versioning feature needs to be enabled for these configurations.";
	VersionsInfoTextDescription: string = "It can be enabled under the 'Features' tab.";
	ContractVersionResolutions: string = "Create contract versions for these resolutions";
	ContractVersionRetention: string = "Contract version retention";

	//SCENARIOS
	ScenarioTypesEnabled: string = "Enabled scenario types";
	UserTypesAllowedToCreateScenarios: string = "User types, that are allowed to create scenarios";
	UserTypesAllowedToApproveScenarios: string = "User types, that are allowed to approve scenarios";
	FindAvailableCapacityUsingLiveDatePermissions: string = "Who is allowed to 'Find Available Capacity' on live data";

	EnableGridResolutionMismatchWarning: string = "Enable grid resolutioncheck warning";
	EnableGridResolutionMismatchWarningDescription: string = "Warns the user when he/she tries to edit activities on another resolution than initially created.";
	AutoHideNotificationsAfter: string = "Auto hide in-app notification after specified milliseconds";
	AutoHideNotificationsAfterDescription: string = "If set to '-1' they are completely suppressed. If set to '0' it will use defaults.";
	ConfigureMailService: string = "Setup mail service to be used by the notification feature";
	ConfigureMailServiceDescription: string = "The config will be saved directly when the dialog is closed"
	MailNotifications: string = "Enabled mail notifications";
	MailNotificationsDescription: string = "Select which mail notifications, that is enabled.";

	//PERMISSIONS
	EnableADGroupSecurity: string = "Enable AD group based security";
	ADGroupMaps: string = "AD Group mappings";
	ADGroupMapsValidation: string = "The first four AD Group mappings must be defined!";
	EnableProjectOwnerADGroup: string = "Enable using AAD Groups for project additional owners";
	ProjectOwnerADGroupFilter: string = "Filter used to fetch AAD Groups for project additional owners. This is highly recommended to use, particular when having vast amount of groups in the AAD!";

	//TIMELINE
	AllowedTimelineResolution: string = "Allowed timeline resolutions";
	AllowedTimelineResolutionDescription: string = "A minimum of one resolution must be defined.";
	TimelineDayResolutionMaxDaysBackwards: string = "Max number of days the timeline goes backwards, when using days resolution";
	TimelineDayResolutionMaxDaysBackwardsDescription: string = "This ONLY applies to Day resolution!";
	TimelineDayResolutionMaxDaysForward: string = "Max number of days the timeline goes forward, when using days resolution";
	TimelineDayResolutionMaxDaysForwardDescription: string = "This ONLY applies to Day resolution!";

	//GRID SETTINGS
	GridDecimalsFTE: string = "Grid decimals for FTE";
	GridDecimalsFTEDescription: string = "How many decimal are shown in the grids, when using FTE";
	GridDecimalsHours: string = "Grid decimals for Hours";
	GridDecimalsHoursDescription: string = "How many decimal are shown in the grids, when using Hours";
	HidePersonaIconsInGrid: string = "Hide personas icon in grid";
	GridSpacing: string = "Spacing between grid cells";

		// ACTIONS
		MatchRequestDefaultTicked: string = "Toggle in 'Match Request' dialog, should be default ticked";
		CreateMatchingRequestDefaultTicked: string = "Toggle in 'Create Matching Request' dialog, should be default ticked";
		ForwardRequestFullDefaultTicked: string = "Toggle in 'Forward Request' dialog, should be default ticked";

	//THEME
	DefaultTheme: string = "Default theme";

	//MS TEAMS
	TeamsInfoText: string = "Remember to enable the MS Teams integration!";
	TeamsInfoTextDescription: string = "If resources needs to be removed automatically, when npt actually on the team anymore, you need to enable the MS Teams scheduled job as well.";
	EnableResourceSyncToMSTeams: string = "Sync resources back to MS Teams";
	EnableResourceSyncToMSTeamsDescription: string = "Enable/disable updating MS Team members, based on allocations on the team projects.";
	RemoveResourceFromMSTeamsDelay: string = "Delay in days for automatically removing resources from MS Team";
	RemoveResourceFromMSTeamsDelayDescription: string = "If a resource haven't got any allocations on a Team, a scheduled job will automatically remove it from the corresponding MS Team, after the specified delay period. REMEMBER TO ENABLE THE SCHEDULED JOB!";

	//LOCALIZATION
	CultureInfo: string = "Culture Info";
	CultureInfoDescription: string = "Used to define week start etc.";
	UseISO8601: string = "Use ISO8601";
	UseISO8601Description: string = "If your culture info uses ISO8601, tick this on";
	Localization: string = "Language";
	LocalizationDescription: string = "The default language file to use. Using subsettings or editing a resource, it is possible to set a specific language file.";
	CustomLocaleStorageUrl: string = "Custom localization storage url";
	CustomLocaleStorageUrlDescription: string = "Url to blob storage account, containing custom locales.";

	//CACHE
	CacheResourceContraintLabel: string = "!!!IMPORTANT!!! All changes to these settings, require a complete cache refresh!";
	CacheResourceContraintLabelDescription: string = "Set teamplanner in maintenance mode and run the refresh cache plugin. Or simply just reboot teamplanner :)";
	CacheResourceContraint: string = "Resource cache constraint";
	CacheResourceContraintDescription: string = "Filter out resources from the cache, based on property values.";
	CacheResourceDialogTitle: string = "Resource cache filter editor";
	CacheProjectContraint: string = "Project cache constraint";
	CacheProjectContraintDescription: string = "Filter out projects from the cache, based on property values.";
	CacheProjectDialogTitle: string = "Project cache filter editor";
	CacheContractConstraint: string = "Contract cache constraint";
	CacheContractConstraintDescription: string = "Filter out future or past contracts from the cache.";

	//SUB SETTINGS
	SubSettingPropertiesAvailable: string = "Subsetting properties available";
	SubSettingPropertiesAvailableDescription: string = "The properties that sub settings are allowed to override.";
	UserSettingPropertiesAvailable: string = "Usersetting properties available";
	UserSettingPropertiesAvailableDescription: string = "The properties that user settings are allowed to override.";
	Matchfilter: string = "Match filter";
	MatchFilterDescription: string = "Auto assign this sub setting to resources, that match this filter.";

	//OTHER
	MatchPriority: string = "Match priority";
	MatchPriorityDescription: string = "When multiple subsettings match, the priority will determine which to use. (lowest is highest)";
	MatchFilterDialogTitle: string = "Match filter";
	Backups: string = "Backups";
	BackupsDescription: string = "Backups of the settings.";
	EnableTelemitry: string = "Enable Telemitry";

	// BUTTONS
	SimpleSettings: string = "Simple";
	AdvancedSettings: string = "Advanced";
}