export const PluginIDs = {
    CustomerId: "00000000-0000-0000-0000-000000000000",

	// ENTITIES
	Upsert: "45E27F95-10F3-45FF-93F6-54E9C0B053C9", //  Upsert Entity
    Get: "BF0AB17B-1F46-49E6-BFA9-61162993F296", //  Get Entity
    Query: "0AD3305D-578B-4CB2-99CF-7D0FB77EFC48", //  Query Entity
	Delete: "637D057A-10A0-4304-B956-4CD938559C2D", //  Delete Entity
	QueryMinified: "DD759B9D-1120-4CA0-BD6B-2196D89B75D1", //  Query Entity Minified
	GetEntityDetails: "38BD4A4C-8BE9-4295-8932-7A3BB0203DB8", //  Get Entity Details
	QueryExpanded: "F370EA7A-4D4F-4DD6-A973-2BDAE093C72C", //  Query Entity Expanded
	GetExpanded: "E914D7C9-8D50-4A68-BF6F-1E0A61319191", //  Get Entity Expanded
	GetExpandedMultiple: "EA1B4396-C9FE-4E72-A378-FA35BF84765A", //  Get Multiple Entity Expanded
	GetResourcesForPickerField: "69BB2885-B568-4801-8E36-303B35A4D445",
	GetProjectsForPickerField: "38DE4482-87E4-48FF-8F47-52157E39E446",
	CascadeDelete: "7C9D6266-A351-4087-A683-F4F03F0AE84B",
	DeltaUpsert: "2D8B5E3B-61EB-4F52-8B43-D0FCA27CDCF3",
	
	// CONTRACTS
	ChangeContractName: "E6C4752D-429A-45D9-B990-DA8C2142308C", //  Change Contract Name
	ChangeContractGroupName: "878E0C0D-4F3C-4746-BF27-F0770BA4D322", //  Change Contract Group Name
	UpdateContractGroup: "AABD67C9-AD92-46DE-9874-42837B10C4DD", // Update Contract Group Name
	MergeContracts: "41A1AB2E-BC85-4A15-8BE1-D60521BB9697", // Merge Contracts
	ActivitiesHasThisResolution: "FE77FFDD-4B4C-49F1-9EF6-3A060A402789", // Activities Has This Resolution
	UpdateContractAlignStatus: "C4CEDE3A-E89A-4DFB-AD31-FD9CA54A4FE5", // Update Contract Align Status
	MoveAllAllocations: "D5982B35-6C9B-4992-A732-23220DA86066", // Move All Allocations
	GetContractsForPicker: "ED74FB36-6F09-4696-A844-87E0E3EEF960",
	GetContractInfo: "5840177E-A38A-4F8D-AF9E-47FE7424FDB8",
	UpdateContractInfo: "69BF9792-0D9E-46E0-8173-B5E779D4ECD3",
	FindDeliveryDates: "0727DB9A-58B5-452B-8692-665D6031F6E6",
	AutoCreateActivities: "AAC7EB87-C60E-4EE2-A5CF-3414DB07B302",
	RemoveActivities: "C0DF448F-C396-4C7B-B250-025307CAD5DD", //TeamPlanner Remove Activities Plugin
	RemoveAllActivities: "1E5C53C1-9520-43EF-AB55-F3CBA76EE6F7",
	RemoveContractActivities: "9F37DF6D-C2EA-4933-B572-86138521FC4A",

    // UI
	GetProjectsForList: "4011AF0B-D9BE-488E-AC78-1F1A36D6E83C", //TeamPlanner Get Projects ListItem Plugin
	GetResourcesForList: "26755B9E-CD8D-4FFF-96E5-931DF0FFF8EB", //TeamPlanner Get Resources ListItem Plugin
	
    GetProjectCapacityView: "687EC234-16F4-4A33-9C52-188175D89368", //TeamPlanner Get Project Grid Plugin
	SaveProjectCapacityCell: "0D0FD332-0341-473D-9935-FA8CA3C05781", //TeamPlanner Save Project Capacity Cell
	SaveProjectCapacityCells: "3D87BBC2-B87D-48A9-8D5B-F9F79A7BC3EA", //TeamPlanner Save Project Capacity Cells
	
	GetResourceCapacityView: "1BF32D0B-700B-495E-A971-2BC2393B6252", //TeamPlanner Get Resource Capacity View Plugin
	GetLMCapacityView: "BF5440DA-953B-46EC-AECE-B3308FBC80B7", // Get LM Capacity View
	GetNewResourceRow: "2B39B4BE-FF23-482C-98BF-79CD5049A381", //TeamPlanner Get New Resource Row Plugin

	GetResourcesOverview: "B86AC60B-E417-4CEC-B914-16B50DDA6048", // Get Resources Overview
	GetProjectsOverview: "690EECA7-6025-44FB-87E4-106E31297A01", // Get Projects Overview

	ToggleFavourite: "8AAB5307-267E-43A8-A706-3FAE8CDAF4C9", //  Update User's Favorites

	GetPlannerViewDefaultViews: "76E5EAC1-0275-4BD1-A396-CCD26B47E03B", // Get All related projects and resources

	// WORK PACKAGES
	GetWorkPackageView: "F0F52DFA-598C-11EB-AE93-0242AC130002",
	SaveWorkPackageCell: "E7AA74A2-4B7A-4D39-BEAD-73D6EC9F8637",
	SaveWorkPackageCells: "A23CE76E-EF3C-43AC-8C7D-5217E033F22D",
	RemoveWorkPackageActivities: "CC21E7C9-8FA8-451A-8BED-FA30EB17AB93",
	CreateWorkPackage: "C01B8183-6682-4BB5-80E9-E5A0527CDEEE",
	EditWorkPackageName: "2C52A427-96D7-4618-BF23-09282608BB4C",
	GetAllWorkpackagesFlat: "C5F5BAFB-1BA1-436E-BC96-84BC1C635338",
	GetAllWorkpackagesTree: "30335C91-51C4-48DF-B4EB-7F90A367E27E",
	UpdateWorkPackageContractGroupName: "220A5FCD-7D48-49B7-96E4-F3E13C8C682F",
	UpdateWorkPackageContractName: "29A193D2-F5BC-471A-A6CC-B3DF7F55DD03",
	UpdateWorkPackageSubs: "1BA65A05-C046-44FE-BAC7-1EF02D676E19",
	DeleteWorkPackage: "728EF4EE-8691-4645-AE71-389F5CD50E74",
	ImportWorkPackage: "AD69E950-F718-4F61-953A-D92F8246BB4C",

	// WORK PACKAGES COMMENTS
	GetWorkpackageContractsComments: "41A12BA7-3194-4863-AA42-FEBC4B3A4B2F",
	CreateWorkpackageComment: "392F98CC-BF58-43D8-B1EB-0A447DF1DE0F", // Create contract comment
	DeleteWorkpackageComment: "123C0F0F-ADD3-4ABD-8073-1DD4E8B2FABD", // Delete contract comment

	// SCENARIOS
	GetScenarios: "13E33F72-3D28-4FA7-9342-C381EB686897",
	GetDraftProjectCapacityView: "50187220-2E27-4387-A5E5-28196AE4D6BE",
	GetVersionedProjectCapacityView: "5A2DB723-8895-4651-9B58-30770DC55A04",
	AddVersionedActivity: "72AAB88B-BCAA-479D-9C77-3F3480D968E0",
	EntireScenarioApproval: "2544253E-33D5-4B2E-80EB-FB40223E1ED1",
	RemoveVersionedActivities: "45B0A050-7218-42AF-B7C7-E331AE924003",
	ResetScenarioState: "FFEE67BC-DE77-47BB-9A5E-8709CBB41AD7",
	// RespondToScenarioColumn: "2A99D481-11F0-4E03-9C56-12ADC1B05914",

	// COMMON
	UpdateCurrentResourceLastSignedin: "E8DAD6C1-A3A0-40DE-9A39-6E347A5F429E", //  Update Current Resource Last Signedin
	UpdateCurrentResourceUnitType: "4AA4250F-411E-488A-B56E-20A6B2D99A36", // Update Current Resource UnitType

	/* FORWARD REQUEST */
	GetForwardRequestView: "FC6A1094-7429-4689-B859-9BCA6151B863", // Get Forward Request View
	ForwardRequest: "5979CDFF-5A49-4F8D-91D1-D3EDAF86CB6E", // Forward Request
	MoveRequestInTime: "E9F432AC-2C7C-47C9-AAC8-AF205E6060F8", // Move Request In Time
	MoveProjectRequestsInTime: "AF3DA363-A12A-4C55-9C78-006FC281724A",
	CreateMatchingRequest: "3E501AE1-CD4F-40BE-8F87-9A34C8AC47E7", // Create Matching Request
	CreateMatchingAllocation: "733E8D5F-D4D4-4D6E-A2E8-3090715D1CB1", // Create Matching Allocation

	// COMMENTS
	GetResourceComments: "F035C41F-654D-4E0B-B47E-503D82CCB512", // Get resource comments 						// NOT USED!!!
	GetContractsComments: "6F78365F-204B-48B7-B83E-34B5D6B3E236", // Get contracts comments
	CreateComment: "E678E090-F605-4FC1-A4EB-7DA132A85ED7", // Create contract comment
	DeleteComment: "D2D68BED-11A2-4E45-98FA-25C7F31E30C7", // Delete contract comment
	
	// CACHE
	WaitCache: "4998AF18-71D0-4110-88C6-E85BE052319C", //Plugin for resolving when cache is done loading
	CacheInitializer: "5715EEC4-2DC8-4B76-B8B2-F23E4A99EB7E", //Initializes cache when invoked, which means solution can start before maybe daing on long cache

	// DELEGATION
	StopDelegation: "64049D50-9EE8-4495-89AD-ACF722F0AC33", //Plugin for stopping delegation
	StartDelegation: "1E74F6A7-4356-40E5-A7C8-338457C277AE", //Plugin for starting delegation

	// CHART
	// TimelineChart: "BF7AB5B8-1399-4D5C-8C2E-CC55334865D5", //Plugin for retrieve data for timeline chart
	PMRequestFulfillmentPieChartData: "27A21CD6-04D0-4988-8184-0078856F0630", //Plugin for retrieving data for pie chart
	ResourceCapacityBarChartData: "0240196F-BE55-4A38-975E-41C5DB02CF08", //Plugin for retrieving data for bar chart
	
	// PROJECT
	GetRelatedProjects: "DF5F5A21-ECFF-4937-93A7-AE0994573EA6", //Plugin for retrieving projects related to the current resource.

	// LOG
	Log: "4604E123-0946-4939-A615-894BFBE8E2FC", //Log to application insights
	
	// UPGRADE
	FunctionsPackages: "B8D7A82C-6CE4-43EA-8A66-217D7E055929", //Plugin for retrieve functions packages
	AppPackages: "43C22BFD-30A6-4C77-BAB7-37A374684059", //Plugin for retrieve app packages
	Upgrade: "A4D8707A-4B00-4670-8BE5-787CDFB2325B", //Plugin for upgrading the app
	ScriptPackages: "F3B58E12-5F00-47D3-AB85-9A59E16A0CC5", //Plugin for retrieve script packages
	UpgradePackage: "057493E3-805C-48F0-AE84-9C2FE460E599", //Plugin for upgrading the API package
	UpgradeScript: "7F8CC119-6ED9-44C9-AC86-478AAFAFD68B", //Plugin for upgrading the UI package
	Restart: "D2C1F11F-B031-4220-8574-23D8DE8360DE", //Plugin for restarting the app service
	GetReleaseNote: "AD4AE0B0-8540-465B-9446-3B1044FCEC2A", //Gets a release note for at specific version.
		
	// SETTINGS
	GetSettings: "80FF9150-E791-417D-A3F3-D670422F6955", // Get Settings
	UpdateSettings: "263A23C7-6191-45D3-9676-9830DD2F7C13", //Plugin - Update Settings
	GetAllSubSettings: "2012C129-3C36-4039-8B43-83F79E2BF0ED",
	UpsertSubSettings: "4DA9BFA6-FEF0-4813-AA13-0740306F2CA0",
	GetRootSettings: "0D203665-54EA-4565-BE17-484B1B2B4D95",

	//SECURITY
	ClientCredentialsProperties : "1B44F9BD-7C0E-4406-A763-A0F594F5CE70",
	
	//INTEGRATIONS
	GetIntegrations: "18BBBB4A-AEBF-4FCD-917D-2A280FF106FA",
	SetupIntegrations: "64D321C9-3BC7-40E4-A998-2FB34E7202D5",
	SaveIntegrationConfiguration: "631CAD47-832F-4A97-9914-EED0A2F7A68F",
	
	//PLUGINS
	PluginInformation: "1A03722C-1138-401D-AE46-487FDDE40E5A",
	GetMigrations: "B5AA36E4-5114-4A40-899F-E4DCA6DE7FA9",
	RunMigrations: "6B6EA88D-8516-46F8-9451-AC9959F7281E",

	/* Types */
	GetTypes: "1D399EEF-8A46-452F-B7A3-1EE18D0EEE97",
	GetTypeSchema: "C7E633AA-3A4A-4D96-8BBC-1B7690BE78CB",

	//NOTIFICATIONS
	Notify: "0824BB87-1560-481E-95B8-4FF65C877E4F",

	//ANNOUNCEMENTS
	GetAnnouncements: "3CFBEE8D-A51D-4063-B0F6-434313B1A00C",

	//MAINTENANCE
	Maintenance: "B2C3318C-BB67-48C7-AE99-557F5F8F4A59",
	
	//DYNAMICTEMPLATE
	DynamicTemplate: "AE6F578A-A0F7-4C3F-BE39-3B185253D4A9",
	

	//LOCALIZATIONS
	GetLanguageFile: "77DF2169-4C54-4B93-B08B-8795EC3DBD73",
	GetAvailableLocales: "73FC8E4A-51BB-4689-8632-BD6F63355DD1",

	//DEMO DATA
	CreateDemoData: "916A572B-59A0-423C-AB9E-3C37D176D86E",
	GetEnvironmentState: "66B42545-0C10-45BE-97F4-9A1B9671A904",

	//SCHEDULES
	GetSchedules: "DFEAEEFE-3843-4200-A60E-0EEB39CC5AB1",
	SaveSchedule: "B6DBF2EC-8C38-4C79-BD6E-8559821C65B7",

	// AAD
	GetAllAadGroups: "F9EB2A4A-C499-424B-8554-38BC03986AA9",
	IsCurrentResourceInOwnerGroup: "DA2CFAB3-9635-4662-B4C6-E79FE942B06E",

	//Gets UI/User/Basic information
	GetUIStartup: "98509F41-59DB-4160-A33A-F81715CD0381",

	// Mail
	GetMailServiceConfig: "EBCD81E8-A7FB-46A3-B4AE-C5CB1324EBC8",
	SetMailServiceConfig: "0348A2C1-A727-4B2B-949B-839B0CFDA265",
	ParseMailTemplate: "A69E6AA2-DE8E-4763-9AC5-013F3EE19DA9",

	// REPORTS
	ContractsReportDataInconsistencyAligner: "ED5BC9A9-1645-41BD-A624-CC2658C83194",
}
