import React, { useCallback, useState } from 'react';
import { TextField } from '@fluentui/react';
import { ICustomComponentConfig, IFieldRenderProps, IFormItem, useFormContext, useWatch } from 'spark-forms';
import { IWorkWeek } from '../interfaces/IWorkWeek';
import { tpEntityFactory } from '../../common/tpEntityFactory';

export const WorkWeekEditor: React.FC<any> = <T extends IFormItem>({ value: val, fieldName, disabled, path }: IFieldRenderProps<T, ICustomComponentConfig<T>, IWorkWeek | undefined>) => {
	
	const [value, setValue] = useState<IWorkWeek | undefined>(val ?? tpEntityFactory.workweek());
	const { onChange: onChangeForm } = useFormContext();
	useWatch(path, () => setValue(val), [val]);

	const onChange = useCallback(
        (subProp: string, subValue: string | undefined) => {
			const clone = {...value};
			clone[subProp] = subValue;
            setValue(clone);
            onChangeForm(fieldName, clone);
        },
        [value, onChangeForm, fieldName]
    );

	const GenerateInputField = (prop: string) : JSX.Element => {
		return <TextField 
					key={ `workweek-${prop}` }
					styles={ { root: { width: "50px", paddingRight: "5px" }, field: { textAlign: "center" } } }
					label={prop.substring(0, 3)}
					type={"number"}
					disabled={ disabled }
					defaultValue={value[prop]+""}
					onChange={ (e, value) => onChange(prop, value) }
					onClick={e => (e.currentTarget as HTMLInputElement).select()}
				/>
	}

	return 	(
		<div style={{ display: "flex", paddingLeft: "20px" }}>
			{Object.keys(value).filter(_ => _.substring(_.length - 3) === "day").map(_ => GenerateInputField(_))}
		</div>
	)
}
