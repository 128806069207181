import { CSSProperties, memo, useCallback, useMemo, useState } from 'react';
import { useEvent } from '../../../../../../../hooks/useEvent';
import { GridRowContext, RowContext } from '../../../../../_grid/contexts/rowContext';
import { VirtualItem } from '@tanstack/react-virtual';
import { ROW_HEIGHT, ROW_EXPANDED_HEIGHT, COLUMN_STICKY_COUNT, ROOT_ROW_BG, ROW_BG, ROW_BG_HOVER, BORDER, CUSTOM_COLUMN_WIDTH } from '../../CONSTANTS';
import { RowHeader } from '../Headers/RowHeader/RowHeader';
import { ProjectColumnContainer } from './components/project/ProjectColumnContainer';
import { GridRow } from '../../../../../../../api/generated/data-contracts';
import { ExpandedState } from '../../../../../_grid/useGrid';
import { ProjectType } from '../../../../../../../Entities/ProjectType';
import { ResourceColumnContainer } from './components/resource/ResourceColumnContainer';
import { ContractColumnContainer } from './components/contract/ContractColumnContainer';
import { IStackProps, Stack } from '@fluentui/react';
import { useSelectedColumnsValue } from '../../context/customColumnContext';
import { CustomColumnContainer } from './components/custom/CustomColumnContainer';
import { useCustomColumnRange } from '../../hooks/useCustomColumnRange';
import { preventDefault } from '../../../../../../../helpers/killEvent';
import { useGridContext } from '../../../../../_grid/contexts/gridContext';
import { changeCase } from '../../../../../../../helpers/changeKeysCase';
import { ResourceListItemWithMetadata } from '../../../../../_components/panels/types';
import { AddRowData } from '../../AddRowData';
import { useStore } from '../../../../../../../context/store';

type RowWrapperProps = {
    row: GridRow;
    rowIndex: number;
    setExpandedRows: React.Dispatch<React.SetStateAction<ExpandedState>>;
    visible: boolean;
    expanded: boolean;
    getRowAncestorTreeToRoot: (rowId: string) => GridRow[];
    getSubRows: (rowId: string) => GridRow[];
    nestingLevel: number;
    maxGridDepth: number;
    rowRelations: GridRow[];
};

export const RowContainer = memo(function RowContainer({
    index,
    start,
    row,
    expanded,
    getRowAncestorTreeToRoot,
    getSubRows,
    maxGridDepth,
    nestingLevel,
    rowIndex,
    rowRelations,
    setExpandedRows,
    visible,
    columns,
    totalWidth,
}: RowWrapperProps & {
    index: number;
    start: number;
    columns: VirtualItem[];
    totalWidth: number;
}) {
    const subRows = useMemo(() => getSubRows(row.id), [getSubRows, row.id]);

    const { isAdminProject, isProject, isResource, isContract } = useRowType({ row, rowRelations, subRows });

    const styles = useMemo(() => {
        return {
            root: {
                position: 'absolute',
                transform: `translateY(${start}px)`,
                top: 0,
                left: 0,
                height: `${expanded && isProject ? ROW_EXPANDED_HEIGHT : ROW_HEIGHT}px`,
                width: `${totalWidth}px`,
                boxSizing: 'border-box',
                backgroundColor: isProject ? ROOT_ROW_BG : ROW_BG,
                '&:hover': {
                    backgroundColor: ROW_BG_HOVER,
                },
            },
        } as IStackProps['styles'];
    }, [expanded, start, totalWidth, isProject]);

    const canExpand = Boolean(subRows.length);

    const toggleExpanded = useEvent((toggle?: boolean) => {
        if (canExpand) {
            setExpandedRows(state => {
                const toggleState = toggle ?? !state[row.id];
                const newState = { ...state, [row.id]: toggleState };
                // Close children and grand children
                if (!toggleState) {
                    return subRows.reduce((acc, subRow) => {
                        return { ...acc, [subRow.id]: false };
                    }, newState);
                }
                return newState;
            });
        }
    });

    const rowContext = useMemo(() => {
        const currentContext: RowContext = {
            row,
            getRowAncestorTreeToRoot,
            canExpand,
            nestingLevel,
            subRows,
            toggleExpanded,
            visible,
            rowIndex,
            rowRelations,
        };
        return currentContext;
    }, [row, getRowAncestorTreeToRoot, canExpand, nestingLevel, subRows, toggleExpanded, visible, rowIndex, rowRelations]);

    const customColumns = useSelectedColumnsValue();
    const { getIndex, range } = useCustomColumnRange();

    const render = useMemo(() => {
        return columns.map(({ index, start, key }) => {
            if (index === 0) {
                return <RowHeader key={key} expanded={expanded} dropable={isContract || isProject} />;
            }

            if (range.includes(index)) {
                if (isProject) {
                    const columnIndex = index - COLUMN_STICKY_COUNT;
                    const customColumn = customColumns[columnIndex];
                    return <CustomColumnContainer key={key} start={start} option={customColumn} rowCustomColumns={row.customColumnData} expanded={expanded} />;
                }
                return <EmptyCell key={key} start={start} />;
            }
            const columnIndex = getIndex(index);
            const column = row.columnRows[columnIndex];
            if (isResource) {
                return (
                    <ResourceColumnContainer
                        key={key}
                        columnIndex={columnIndex}
                        columnRow={column}
                        rowId={row.id}
                        start={start}
                        isAdminProject={isAdminProject}
                    />
                );
            }
            if (isProject) {
                return (
                    <ProjectColumnContainer
                        key={key}
                        columnIndex={columnIndex}
                        columnRow={column}
                        rowId={row.id}
                        start={start}
                        isAdminProject={isAdminProject}
                        expanded={expanded}
                    />
                );
            }
            // Contract
            return (
                <ContractColumnContainer key={key} columnIndex={columnIndex} columnRow={column} rowId={row.id} start={start} isAdminProject={isAdminProject} />
            );
        });
    }, [columns, customColumns, expanded, getIndex, isAdminProject, isContract, isProject, isResource, range, row.columnRows, row.customColumnData, row.id]);
	
    const onDrop = useCallback(
        (rows: GridRow[], parentRow: GridRow) => {
            setExpandedRows(state =>
                [...rows, parentRow].reduce((acc, row) => {
                    // acc[row.id] = true
                    return {
                        ...acc,
                        [row.id]: true,
                    };
                }, state),
            );
        },
        [setExpandedRows],
    );
    const { dropProps } = useDropRow(row.id, { isContract, isProject, onDrop });
    return (
        <GridRowContext value={rowContext}>
            <Stack styles={styles} {...dropProps}>
                {render}
            </Stack>
        </GridRowContext>
    );
});

const useRowType = ({ subRows, rowRelations, row }: { row: GridRow; rowRelations: GridRow[]; subRows: GridRow[] }) => {
    const isProject = row.metadata?.ProjectType !== undefined;
    const isAdminProject = useMemo(() => {
        if (rowRelations.some(row => row.metadata?.ProjectType === ProjectType.Administrative)) {
            return true;
        }
        if (subRows.some(row => row.metadata?.ProjectType === ProjectType.Administrative)) {
            return true;
        }
        if (!isProject) {
            return false;
        }
        if (row.metadata?.ProjectType === ProjectType.Administrative) {
            return true;
        }
        return false;
    }, [isProject, row.metadata?.ProjectType, rowRelations, subRows]);
    const isResource = row.metadata?.ResourceType !== undefined;
    return {
        isProject,
        isAdminProject,
        isResource,
        isContract: !isProject && !isResource,
    };
};

const EmptyCell = ({ start }: { start: number }) => {
    const style = useMemo(() => {
        return {
            width: `${CUSTOM_COLUMN_WIDTH}px`,
            position: 'absolute',
            transform: `translateX(${start}px)`,
            top: 0,
            left: 0,
            height: `${ROW_HEIGHT}px`,
            boxSizing: 'border-box',
            borderBottom: BORDER,
            borderRight: BORDER,
        } as CSSProperties;
    }, [start]);
    return <div style={style} />;
};

const useDropRow = (
    thisRowId: string,
    {
        onDrop,
        isContract,
        isProject,
    }: {
        //
        enabled?: boolean;
        onDrop?: (rows: GridRow[], parentRow: GridRow) => void;
        isProject: boolean;
        isContract: boolean;
    },
) => {
    const { addRow } = useGridContext<AddRowData>();
    const timelineInfo = useStore(store => store.timeline);
    const [isDraggingOver, setIsDragginOver] = useState(false);
    const [loading, setLoading] = useState(false);
    const dropProps = useMemo((): React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> => {
        if (!isProject && !isContract) {
            return {};
        }
        return {
            onDrop: async e => {
                setIsDragginOver(false);
                const resource = getDataFromDragEvent(e);
                if (resource) {
                    setLoading(true);
                    try {
                        const { newRows, parentRow } = await addRow(thisRowId, { resource, timelineInfo }, isContract);
                        if (onDrop && newRows.length) {
                            onDrop(newRows, parentRow);
                        }
                    } catch (error) {
                        // console.error('🚀 ~ dropProps ~ error', new Error('Error moving row', { cause: error }));
                        console.error('🚀 ~ dropProps ~ error', error);
                    }
                    setLoading(false);
                }
            },

            // An element with the preventDefault combination on onDragEnter and onDragOver makes it a dropable target.
            // The drag and drop api is retarded https://quirksmode.org/blog/archives/2009/09/the_html5_drag.html
            onDragEnter: e => {
                preventDefault(e);
                setIsDragginOver(true);
            },
            onDragLeave: () => {
                setIsDragginOver(false);
            },
            onDragOver: e => {
                setIsDragginOver(true);
                preventDefault(e);
            },
        };
    }, [addRow, isContract, isProject, onDrop, thisRowId, timelineInfo]);

    return {
        dropProps,
        isDraggingOver,
        loading,
    };
};

const getDataFromDragEvent = (e: React.DragEvent<HTMLTableRowElement>, dataKey: string = 'resourcelistitem') => {
    const dataString = e.dataTransfer.getData(dataKey);
    if (dataString) {
        const data = changeCase.toCamel(JSON.parse(dataString)) as ResourceListItemWithMetadata;
        return data;
    }
};
