/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    AADSyncEntity,
    AadSyncTestParams,
    ActivitiesFTEWorkSanitizerFunction,
    ActivitiesScannerDto,
    ActivitiesScannerResultDto,
    ActivityDistributionDto,
    AddActivitiesPayload,
    AddActivityDto,
    AddFirstUserParams,
    AddSecretParams,
    AddTeamMemberDto,
    AddVersionedActivityDto,
    AllocationTimephasedDto,
    Announcement,
    AppPackageGroup,
    BlobFile,
    ChangeContractGroupNameDto,
    ChangeContractNameDto,
    ChartResourceCapacityParams,
    CheckForDuplicateContractsDto,
    ColumnDto,
    Comment,
    ContractInfoDto,
    ContractNotificationDataWrapper,
    ContractPickerDto,
    ContractReportDataInconsistencyResult,
    ContractResolutionCheckDto,
    ContractSanitizerDto,
    ContractSanitizerResultDto,
    ContractsReportDataInconsistencyAlignerDto,
    ContractsReportDataInconsistencyFixerDto,
    ContractWithStringVariants,
    CreateNewConfigurationParams,
    DataExportCollectionDTO,
    DataExportQueryDTO,
    DataExportTimephasedPeriodCapacity,
    DataExportTimephasedPeriodWork,
    DataIntegrationRequestDTO,
    DataIntegrationTimephasedtDTO,
    DataReferenceIdsDto,
    DataverseEnvironment,
    DataverseEnvironmentsTablesColumnsListParams,
    DataverseEnvironmentsTablesColumnsOptionsListParams,
    DataverseEnvironmentsTablesListParams,
    DateTimeRange,
    DeleteCascadeDto,
    DeleteCommentsDto,
    DeleteDangerousDto,
    DeleteDto,
    DeltaUpsertDto,
    DisableScheduleWebJobParams,
    DistributionResult,
    DuplicateOriginSanitizerParams,
    DynamicQuery,
    EnableTeamsEventParams,
    EnsureApplicationAppKeyParams,
    EnsureTeamDto,
    EntireScenarioApprovalDto,
    EnvironmentOptions,
    EnvironmentState,
    FireIntegrationsDto,
    ForwardFullRequestDto,
    ForwardRequestDto,
    ForwardRequestViewDto,
    FunctionAppRenewSubscriptionParams,
    GanttDto,
    GetActivitiesGroupedResult,
    GetAllContractsTimephasedParams,
    GetAllSubSettingsParams,
    GetAllWorkpackagesFlatParams,
    GetAvailableLocalesParams,
    GetCacheTypesParams,
    GetCommentsForContractPayload,
    GetContractChangesForResourceDto,
    GetContractPeriodAllocationsParams,
    GetContractPeriodRequestsParams,
    GetCssOverridesParams,
    GetDataverseProjectsParams,
    GetDemoDataParams,
    GetDraftProjectCapacityViewDto,
    GetDto,
    GetEntityDetailsDto,
    GetFreeCapacityViewDto,
    GetGetCommentsDto,
    GetHolidaysByCountryAndYearParams,
    GetIntegrationsParams,
    GetLanguageFileParams,
    GetLMCapacityViewDto,
    GetMailConfigParams,
    GetMigrationsParams,
    GetMultipleDto,
    GetNewCapacityRowDto,
    GetPrincipalParams,
    GetProjectAllocationsDto,
    GetProjectContractsTimephasedParams,
    GetProjectGridDto,
    GetProjectsForListDto,
    GetProjectsForOverviewDto,
    GetProjectsWhereCurrentResourceIsOwnerParams,
    GetPushNotificationAnnouncementsPayload,
    GetResourceAllocationsDto,
    GetResourcePeriodAllocationsParams,
    GetResourcePeriodCapacityParams,
    GetResourcePeriodRequestsParams,
    GetResourcesForListDto,
    GetResourcesForOverviewDto,
    GetRootSettingsParams,
    GetScenarioResult,
    GetScenariosDto,
    GetTeamsParams,
    GetVersionedProjectGridDto,
    GetWorkPackageViewDto,
    GraphGroup,
    GraphGroupMinified,
    GridData,
    GridRow,
    GridView,
    Holiday,
    ImportActivitesDto,
    ImportWorkPackageDto,
    InitializeIntegrationsParams,
    IntegrationDtoV2,
    IntegrationEventCreateRequest,
    IntegrationEventUpdateRequest,
    IntegrationProviderDto,
    InvokeDefinition,
    IScheduleJob,
    IsContractFullfilledDto,
    IsFunctionAppRunningParams,
    IsUserInEntraGroupsDto,
    ITPEntityWithStringVariants,
    LineManagerStatusData,
    LogFilter,
    LogLicenseUsageParams,
    LogMetricsParams,
    MailServiceConfig,
    MailTemplate,
    MailTemplateTesterDto,
    MaintenanceDto,
    MatchingActivityDto,
    MergeContractsDto,
    MigrationDto,
    MoveAllAllocationsDto,
    MoveAllocationDto,
    MoveContractDto,
    MoveRequestDto,
    NotificationDto,
    PeriodCapacity,
    PeriodWork,
    PeriodWorkUnit,
    PFServiceBusConfiguration,
    PFServiceBusTopicOptions,
    PluginByGuid2Params,
    PORemoteEventConnectionDto,
    PowerPpmHeatmapRow,
    PowerPPMIntegrationDto,
    PowerPPMRemoteEventConnectionDto,
    PowerPPMSBIntegrationDto,
    ProblemDetails,
    Project,
    ProjectListItem,
    ProjectMinifiedWithStringVariants,
    ProjectPanelLineManagerDto,
    ProjectPickerDto,
    ProjectPlannerGridDto,
    ProjectumProblemDetails,
    ProjectumProblemDetailsTemp,
    ProjectWithStringVariants,
    PushNotificationAnnouncementDto,
    QueryDto,
    Rbs,
    RefreshAllResourcesPermissionsFromAadParams,
    RefreshCacheObjectParams,
    RefreshCacheParams,
    RefreshPwaEntityDto,
    RefreshPwaProjectDto,
    ReloadAppServiceConfigurationParams,
    RemoteIntegrationDto,
    RemoveActivityDto,
    RemoveFutureAllocationsParams,
    RemoveTeamMemberParams,
    RemoveUnusedGenericResourcesDto,
    RemoveUnusedRbsNodesDto,
    RemoveWorkOnAllActivitiesDto,
    RemoveWorkPackageActivityDto,
    ReplaceProjectDto,
    ReplaceResourceDto,
    RequestTimephasedDto,
    RerunSqlExportErrorPayload,
    ResetContractNamesDto,
    ResetMailFlagForVersionsDto,
    ResetResourcesMainWorkProfileParams,
    Resource,
    ResourceCapacityTimephasedDto,
    ResourceCapacityTimephasedResult,
    ResourceContractAllocation,
    ResourceListItem,
    ResourceMinifiedWithStringVariants,
    ResourcePickerDto,
    ResourcePlannerGridDto,
    ResourceWithStringVariants,
    RestartFunctionAppParams,
    RootSettings,
    Row,
    RunMailScheduleParams,
    SanitizeContractRequestsDto,
    SaveWorkPackageCellDto,
    SaveWorkpackageCellsPayload,
    SBMessage,
    ScheduleJobExecutorParams,
    SendMailOptions,
    SendTemplateMailOptions,
    ServicebusClientTopicUpdateParams,
    ServicebusClientUpdatePayload,
    ServiceEndpointDTO,
    ServiceEndpointStepDTO,
    SetContractNamesDto,
    SetupIntegrationDto,
    SqlExportErrorExpanded,
    SqlExportResyncDto,
    StopDelegationParams,
    SubscriptionRuleCreation,
    SubscriptionRuleDeletion,
    SubSettings,
    TableDto,
    TopicRuleContainer,
    TopRow,
    TPEntity,
    TPEntityMinified,
    TPPluginInformation,
    Type,
    UnfilfilledContractsDto,
    UnfulfilledContract,
    UnitType,
    UpdateConfigurationsDto,
    UpdateContractAlignStatusDto,
    UpdateContractCustomPropertiesDto,
    UpdateContractGroupDto,
    UpdateContractsCustomPropertiesPayload,
    UpdateCurrentUserLastSignedInParams,
    UpdateFavouritesDto,
    UpdateWorkPackageContractDto,
    UpgradeController,
    UpgradeCycle,
    UpgradeDto,
    UpgradePackageGroup,
    UpgradePackagesPayload,
    UpsertCommentsDto,
    UpsertDto,
    UpsertLogLevelDto,
    UpsertTemporaryLogFiltersDto,
    UpsertWorkpackageCommentDto,
    UserTypeSanitizerDto,
    VersionedAllocationTimephasedDto,
    VersionedRequestTimephasedDto,
    WorkPackageDto,
    WorkPackageSubDto,
    WorkPackageTreeItem,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags aadgroups
     * @name GetUsersAadGroups
     * @request POST:/api/aadgroups/getforuser
     * @secure
     * @response `200` `(string)[]` Success
     */
    getUsersAadGroups = (body: string, params: RequestParams = {}) =>
        this.request<string[], any>({
            path: `/api/aadgroups/getforuser`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags aadgroups
     * @name QueryAadGroups
     * @request POST:/api/aadgroups/query
     * @secure
     * @response `200` `(GraphGroupMinified)[]` Success
     */
    queryAadGroups = (body: DynamicQuery, params: RequestParams = {}) =>
        this.request<GraphGroupMinified[], any>({
            path: `/api/aadgroups/query`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags activitydistribution
     * @name AutoCreateActivities
     * @request POST:/api/activitydistribution/autocreateactivities
     * @secure
     * @response `200` `DistributionResult` Success
     */
    autoCreateActivities = (body: ActivityDistributionDto, params: RequestParams = {}) =>
        this.request<DistributionResult, any>({
            path: `/api/activitydistribution/autocreateactivities`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags allocations
     * @name MoveAllocation
     * @request POST:/api/allocations/moveallocation
     * @secure
     * @response `200` `void` Success
     */
    moveAllocation = (body: MoveAllocationDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/allocations/moveallocation`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags announcements
     * @name GetAnnouncementsForResource
     * @request GET:/api/announcements/getforresource
     * @secure
     * @response `200` `(Announcement)[]` Success
     */
    getAnnouncementsForResource = (body: string, params: RequestParams = {}) =>
        this.request<Announcement[], any>({
            path: `/api/announcements/getforresource`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags announcements
     * @name GetAnnouncementsForResource2
     * @request POST:/api/announcements/getforresource
     * @originalName getAnnouncementsForResource
     * @duplicate
     * @secure
     * @response `200` `(Announcement)[]` Success
     */
    getAnnouncementsForResource2 = (body: string, params: RequestParams = {}) =>
        this.request<Announcement[], any>({
            path: `/api/announcements/getforresource`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags cache
     * @name RefreshCache
     * @request POST:/api/cache/refeshcache
     * @secure
     * @response `200` `boolean` Success
     */
    refreshCache = (query: RefreshCacheParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/cache/refeshcache`,
            method: 'POST',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags cache
     * @name RefreshCacheObject
     * @request POST:/api/cache/refeshcacheobject
     * @secure
     * @response `200` `boolean` Success
     */
    refreshCacheObject = (query: RefreshCacheObjectParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/cache/refeshcacheobject`,
            method: 'POST',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags charts
     * @name ChartResourceCapacity
     * @request GET:/api/charts/resourcecapacitybarchartdata
     * @secure
     * @response `200` `(any)[]` Success
     */
    chartResourceCapacity = (query: ChartResourceCapacityParams, params: RequestParams = {}) =>
        this.request<any[], any>({
            path: `/api/charts/resourcecapacitybarchartdata`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags configuration
     * @name EnsureApplicationAppKey
     * @request GET:/api/configuration/ensureapplicationappkey
     * @secure
     * @response `200` `void` Success
     */
    ensureApplicationAppKey = (query: EnsureApplicationAppKeyParams, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/configuration/ensureapplicationappkey`,
            method: 'GET',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags configuration
     * @name IsFunctionAppRunning
     * @request GET:/api/configuration/functionapprunning
     * @secure
     * @response `200` `boolean` Success
     */
    isFunctionAppRunning = (query: IsFunctionAppRunningParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/configuration/functionapprunning`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags configuration
     * @name GetPrincipal
     * @request GET:/api/configuration/getprincipal
     * @secure
     * @response `200` `void` Success
     */
    getPrincipal = (query: GetPrincipalParams, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/configuration/getprincipal`,
            method: 'GET',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags configuration
     * @name GetFunctionAppInformation
     * @request GET:/api/configuration/getfunctionapp
     * @secure
     * @response `200` `void` Success
     */
    getFunctionAppInformation = (body: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/configuration/getfunctionapp`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags configuration
     * @name ToggleMaintenanceMode
     * @request POST:/api/configuration/maintenance
     * @secure
     * @response `200` `boolean` Success
     */
    toggleMaintenanceMode = (body: MaintenanceDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/configuration/maintenance`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags configuration
     * @name ReloadAppServiceConfiguration
     * @request GET:/api/configuration/reloadconfiguration
     * @secure
     * @response `200` `boolean` Success
     */
    reloadAppServiceConfiguration = (query: ReloadAppServiceConfigurationParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/configuration/reloadconfiguration`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name ForwardFullRequest
     * @request POST:/api/contract/forwardfullrequest
     * @secure
     * @response `200` `boolean` Success
     */
    forwardFullRequest = (body: ForwardFullRequestDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/forwardfullrequest`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name GetCommentsForContract
     * @request POST:/api/contract/comments/getforcontracts
     * @secure
     * @response `200` `Record<string,(Comment)[]>` Success
     */
    getCommentsForContract = (body: GetCommentsForContractPayload, params: RequestParams = {}) =>
        this.request<Record<string, Comment[]>, any>({
            path: `/api/contract/comments/getforcontracts`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name GetContractCommentsForResource
     * @request POST:/api/contract/comments/getforresource
     * @secure
     * @response `200` `(Comment)[]` Success
     */
    getContractCommentsForResource = (body: GetGetCommentsDto, params: RequestParams = {}) =>
        this.request<Comment[], any>({
            path: `/api/contract/comments/getforresource`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name UpsertComment
     * @request POST:/api/contract/comments/upsert
     * @secure
     * @response `200` `Comment` Success
     */
    upsertComment = (body: UpsertCommentsDto, params: RequestParams = {}) =>
        this.request<Comment, any>({
            path: `/api/contract/comments/upsert`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name ContractActivitiesHasThisResolution
     * @request POST:/api/contract/activitieshasthisresolution
     * @secure
     * @response `200` `boolean` Success
     */
    contractActivitiesHasThisResolution = (body: ContractResolutionCheckDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/activitieshasthisresolution`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name ChangeContractGroupName
     * @request POST:/api/contract/changecontractgroupname
     * @secure
     * @response `200` `boolean` Success
     */
    changeContractGroupName = (body: ChangeContractGroupNameDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/changecontractgroupname`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name ChangeContractName
     * @request POST:/api/contract/create
     * @secure
     * @response `200` `boolean` Success
     */
    changeContractName = (body: ChangeContractNameDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/create`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name CreateMatchingAllocation
     * @request POST:/api/contract/creatematchingallocation
     * @secure
     * @response `200` `boolean` Success
     */
    createMatchingAllocation = (body: MatchingActivityDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/creatematchingallocation`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name CreateMathingRequest
     * @request POST:/api/contract/creatematchingrequest
     * @secure
     * @response `200` `boolean` Success
     */
    createMathingRequest = (body: MatchingActivityDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/creatematchingrequest`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name ForwardRequest
     * @request POST:/api/contract/forwardrequest
     * @secure
     * @response `200` `boolean` Success
     */
    forwardRequest = (body: ForwardRequestDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/forwardrequest`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name GetContractCustomProperties
     * @request POST:/api/contract/getcustomproperties
     * @secure
     * @response `200` `Record<string,any>` Success
     */
    getContractCustomProperties = (body: string, params: RequestParams = {}) =>
        this.request<Record<string, any>, any>({
            path: `/api/contract/getcustomproperties`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name GetContractInfo
     * @request POST:/api/contract/getcontractinfo
     * @secure
     * @response `200` `ContractInfoDto` Success
     */
    getContractInfo = (body: string, params: RequestParams = {}) =>
        this.request<ContractInfoDto, any>({
            path: `/api/contract/getcontractinfo`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name GetContractsForPicker
     * @request POST:/api/contract/getcontractsforpicker
     * @secure
     * @response `200` `(TPEntityMinified)[]` Success
     */
    getContractsForPicker = (body: ContractPickerDto, params: RequestParams = {}) =>
        this.request<TPEntityMinified[], any>({
            path: `/api/contract/getcontractsforpicker`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name GetUnfulfilledContracts
     * @request POST:/api/contract/getunfulfilledcontracts
     * @secure
     * @response `200` `(UnfulfilledContract)[]` Success
     */
    getUnfulfilledContracts = (body: UnfilfilledContractsDto, params: RequestParams = {}) =>
        this.request<UnfulfilledContract[], any>({
            path: `/api/contract/getunfulfilledcontracts`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name IsContractFullfilled
     * @request POST:/api/contract/iscontractfullfilled
     * @secure
     * @response `200` `boolean` Success
     */
    isContractFullfilled = (body: IsContractFullfilledDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/iscontractfullfilled`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name MergeContracts
     * @request POST:/api/contract/mergecontracts
     * @secure
     * @response `200` `boolean` Success
     */
    mergeContracts = (body: MergeContractsDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/mergecontracts`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name MoveProjectRequest
     * @request POST:/api/contract/moveprojectrequests
     * @secure
     * @response `200` `boolean` Success
     */
    moveProjectRequest = (body: MoveRequestDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/moveprojectrequests`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name MoveRequest
     * @request POST:/api/contract/moverequest
     * @secure
     * @response `200` `boolean` Success
     */
    moveRequest = (body: MoveRequestDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/moverequest`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name ResetProjectContractsDates
     * @request POST:/api/contract/resetprojectcontractsstartend
     * @secure
     * @response `200` `number` Success
     */
    resetProjectContractsDates = (body: string, params: RequestParams = {}) =>
        this.request<number, any>({
            path: `/api/contract/resetprojectcontractsstartend`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name SetContractNames
     * @request POST:/api/contract/setcontractnames
     * @secure
     * @response `200` `number` Success
     */
    setContractNames = (body: SetContractNamesDto, params: RequestParams = {}) =>
        this.request<number, any>({
            path: `/api/contract/setcontractnames`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name UpdateContractAlignStatus
     * @request POST:/api/contract/updatecontractalignstatus
     * @secure
     * @response `200` `boolean` Success
     */
    updateContractAlignStatus = (body: UpdateContractAlignStatusDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/updatecontractalignstatus`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name UpdateContractCustomProperties
     * @request POST:/api/contract/updatecustomproperties
     * @secure
     * @response `200` `boolean` Success
     */
    updateContractCustomProperties = (body: UpdateContractCustomPropertiesDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/updatecustomproperties`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name UpdateContractGroup
     * @request POST:/api/contract/updatecontractgroup
     * @secure
     * @response `200` `boolean` Success
     */
    updateContractGroup = (body: UpdateContractGroupDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/updatecontractgroup`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name UpsertContractInfo
     * @request POST:/api/contract/updatecontractinfo
     * @secure
     * @response `200` `boolean` Success
     */
    upsertContractInfo = (body: ContractInfoDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/updatecontractinfo`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name UpdateContractsCustomProperties
     * @request POST:/api/contract/updatecustompropertiesmultiple
     * @secure
     * @response `200` `boolean` Success
     */
    updateContractsCustomProperties = (body: UpdateContractsCustomPropertiesPayload, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/updatecustompropertiesmultiple`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name AddActivities
     * @request POST:/api/contract/addactivities
     * @secure
     * @response `200` `boolean` Success
     */
    addActivities = (body: AddActivitiesPayload, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/addactivities`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name AddActivity
     * @request POST:/api/contract/addactivity
     * @secure
     * @response `200` `boolean` Success
     */
    addActivity = (body: AddActivityDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/addactivity`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name GetContractsForOverview
     * @request GET:/api/contract/getcontractsforoverview
     * @secure
     * @response `200` `(ContractWithStringVariants)[]` Success
     */
    getContractsForOverview = (body: boolean, params: RequestParams = {}) =>
        this.request<ContractWithStringVariants[], any>({
            path: `/api/contract/getcontractsforoverview`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name GetContractsForOverview2
     * @request POST:/api/contract/getcontractsforoverview
     * @originalName getContractsForOverview
     * @duplicate
     * @secure
     * @response `200` `(ContractWithStringVariants)[]` Success
     */
    getContractsForOverview2 = (body: boolean, params: RequestParams = {}) =>
        this.request<ContractWithStringVariants[], any>({
            path: `/api/contract/getcontractsforoverview`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name RemoveActivities
     * @request POST:/api/contract/removeactivities
     * @secure
     * @response `200` `boolean` Success
     */
    removeActivities = (body: RemoveActivityDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/removeactivities`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name RemoveContractActivities
     * @request POST:/api/contract/removecontractactivities
     * @secure
     * @response `200` `boolean` Success
     */
    removeContractActivities = (body: RemoveActivityDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/removecontractactivities`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name DeleteComment
     * @request POST:/api/contract/comments/delete
     * @secure
     * @response `200` `boolean` Success
     */
    deleteComment = (body: DeleteCommentsDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/comments/delete`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name ContractExists
     * @summary Returns true if the contract exists, false if not.
     * @request GET:/api/contract/{contractId}/exists
     * @secure
     * @response `200` `boolean` Success
     */
    contractExists = (contractId: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/contract/${contractId}/exists`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name GetActivitiesGrouped
     * @summary Get's basic info about a contract, and group activities by resources.
     * @request GET:/api/contract/activitiesgrouped/{contractId}
     * @secure
     * @response `200` `GetActivitiesGroupedResult` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    getActivitiesGrouped = (contractId: string, params: RequestParams = {}) =>
        this.request<GetActivitiesGroupedResult, ProjectumProblemDetails>({
            path: `/api/contract/activitiesgrouped/${contractId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name GetResourceAllocations
     * @summary Gets the resource's allocations grouped by contract and timephased into periods that correspond with the resolution selected.
     * @request POST:/api/contract/getresourceallocations
     * @secure
     * @response `200` `(ResourceContractAllocation)[]` Success
     */
    getResourceAllocations = (body: GetResourceAllocationsDto, params: RequestParams = {}) =>
        this.request<ResourceContractAllocation[], any>({
            path: `/api/contract/getresourceallocations`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description This will reset the names of all contracts within one of the selected projects. The reset will utilize the 'GenericResourceNamingConvention' setting, when generating the names.
     *
     * @tags contract
     * @name ResetContractNames
     * @summary Reset names for contracts within the specified projects.
     * @request POST:/api/contract/resetcontractnames
     * @secure
     * @response `200` `void` Success
     * @response `400` `void` Bad Request
     * @response `500` `ProblemDetails` Server Error
     */
    resetContractNames = (body: ResetContractNamesDto, params: RequestParams = {}) =>
        this.request<void, void | ProblemDetails>({
            path: `/api/contract/resetcontractnames`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name GetContractPeriodAllocations
     * @summary Gets the sums of a contract's allocations, splitted in to periods.
     * @request GET:/api/contract/periodwork/allocations
     * @secure
     * @response `200` `(PeriodWork)[]` Success
     * @response `500` `ProblemDetails` Server Error
     */
    getContractPeriodAllocations = (query: GetContractPeriodAllocationsParams, params: RequestParams = {}) =>
        this.request<PeriodWork[], ProblemDetails>({
            path: `/api/contract/periodwork/allocations`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags contract
     * @name GetContractPeriodRequests
     * @summary Gets the sums of a contracts's requests, splitted in to periods.
     * @request GET:/api/contract/periodwork/requests
     * @secure
     * @response `200` `(PeriodWork)[]` Success
     * @response `500` `ProblemDetails` Server Error
     */
    getContractPeriodRequests = (query: GetContractPeriodRequestsParams, params: RequestParams = {}) =>
        this.request<PeriodWork[], ProblemDetails>({
            path: `/api/contract/periodwork/requests`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description If the resource has an LatestAvailable set, then this will be used, otherwise it will respect the freeze period.
     *
     * @tags contract
     * @name RemoveFutureAllocations
     * @summary Removes future allocations for a resource on a specific contract.
     * @request POST:/api/contract/allocations/removefuture
     * @secure
     * @response `200` `void` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    removeFutureAllocations = (query: RemoveFutureAllocationsParams, params: RequestParams = {}) =>
        this.request<void, ProjectumProblemDetails>({
            path: `/api/contract/allocations/removefuture`,
            method: 'POST',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * @description This will always result in a new contract being created on the project, and the activities moved to it.
     *
     * @tags contract
     * @name MoveContractToProject
     * @summary Moves a contract to another project, respecting the freeze period
     * @request POST:/api/contract/move
     * @secure
     * @response `200` `void` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    moveContractToProject = (body: MoveContractDto, params: RequestParams = {}) =>
        this.request<void, ProjectumProblemDetails>({
            path: `/api/contract/move`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags dashboard
     * @name GetLinemanagerStatusHtml
     * @request GET:/api/dashboard/linemanagerstatushtml/{templateId}
     * @secure
     * @response `200` `string` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    getLinemanagerStatusHtml = (templateId: string, params: RequestParams = {}) =>
        this.request<string, ProjectumProblemDetails>({
            path: `/api/dashboard/linemanagerstatushtml/${templateId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags dashboard
     * @name GetLinemanagerStatus
     * @request GET:/api/dashboard/linemanagerstatus/{templateId}
     * @secure
     * @response `200` `LineManagerStatusData` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    getLinemanagerStatus = (templateId: string, params: RequestParams = {}) =>
        this.request<LineManagerStatusData, ProjectumProblemDetails>({
            path: `/api/dashboard/linemanagerstatus/${templateId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dataverse
     * @name DataverseEnvironmentsList
     * @request GET:/api/Dataverse/environments
     * @secure
     * @response `200` `(DataverseEnvironment)[]` Success
     * @response `500` `ProjectumProblemDetailsTemp` Server Error
     */
    dataverseEnvironmentsList = (params: RequestParams = {}) =>
        this.request<DataverseEnvironment[], ProjectumProblemDetailsTemp>({
            path: `/api/Dataverse/environments`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dataverse
     * @name DataverseEnvironmentsTablesList
     * @request GET:/api/Dataverse/environments/tables
     * @secure
     * @response `200` `(TableDto)[]` Success
     * @response `500` `ProjectumProblemDetailsTemp` Server Error
     */
    dataverseEnvironmentsTablesList = (query: DataverseEnvironmentsTablesListParams, params: RequestParams = {}) =>
        this.request<TableDto[], ProjectumProblemDetailsTemp>({
            path: `/api/Dataverse/environments/tables`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dataverse
     * @name DataverseEnvironmentsTablesColumnsList
     * @request GET:/api/Dataverse/environments/tables/columns
     * @secure
     * @response `200` `(ColumnDto)[]` Success
     * @response `500` `ProjectumProblemDetailsTemp` Server Error
     */
    dataverseEnvironmentsTablesColumnsList = (query: DataverseEnvironmentsTablesColumnsListParams, params: RequestParams = {}) =>
        this.request<ColumnDto[], ProjectumProblemDetailsTemp>({
            path: `/api/Dataverse/environments/tables/columns`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dataverse
     * @name DataverseEnvironmentsTablesColumnsOptionsList
     * @request GET:/api/Dataverse/environments/tables/columns/options
     * @secure
     * @response `200` `(ColumnDto)[]` Success
     * @response `500` `ProjectumProblemDetailsTemp` Server Error
     */
    dataverseEnvironmentsTablesColumnsOptionsList = (query: DataverseEnvironmentsTablesColumnsOptionsListParams, params: RequestParams = {}) =>
        this.request<ColumnDto[], ProjectumProblemDetailsTemp>({
            path: `/api/Dataverse/environments/tables/columns/options`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags demodata
     * @name GetDemoData
     * @request GET:/api/demodata/get
     * @secure
     * @response `200` `Record<string,string>` Success
     */
    getDemoData = (query: GetDemoDataParams, params: RequestParams = {}) =>
        this.request<Record<string, string>, any>({
            path: `/api/demodata/get`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags deployment
     * @name AddBaseCapacityUser
     * @request POST:/api/deployment/AddBaseCapacityUser
     * @secure
     * @response `200` `boolean` Success
     */
    addBaseCapacityUser = (body: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/deployment/AddBaseCapacityUser`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags deployment
     * @name AddFirstUser
     * @request GET:/api/deployment/addfirstuser
     * @secure
     * @response `200` `boolean` Success
     */
    addFirstUser = (query: AddFirstUserParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/deployment/addfirstuser`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags deployment
     * @name Deploy
     * @request POST:/api/deployment/deploy
     * @secure
     * @response `200` `boolean` Success
     */
    deploy = (body: UpgradeDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/deployment/deploy`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags deployment
     * @name GetPackagesApi
     * @request POST:/api/deployment/getapipackages
     * @secure
     * @response `200` `(BlobFile)[]` Success
     */
    getPackagesApi = (body: UpgradeCycle, params: RequestParams = {}) =>
        this.request<BlobFile[], any>({
            path: `/api/deployment/getapipackages`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags deployment
     * @name GetPackagesUi
     * @request POST:/api/deployment/getuipackages
     * @secure
     * @response `200` `(BlobFile)[]` Success
     */
    getPackagesUi = (body: UpgradeCycle, params: RequestParams = {}) =>
        this.request<BlobFile[], any>({
            path: `/api/deployment/getuipackages`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags deployment
     * @name GetPackagesFunction
     * @request POST:/api/deployment/getfunctionspackages
     * @secure
     * @response `200` `(BlobFile)[]` Success
     */
    getPackagesFunction = (body: UpgradeCycle, params: RequestParams = {}) =>
        this.request<BlobFile[], any>({
            path: `/api/deployment/getfunctionspackages`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags deployment
     * @name GetReleaseNotes
     * @request POST:/api/deployment/getreleasenote
     * @secure
     * @response `200` `string` Success
     */
    getReleaseNotes = (body: string, params: RequestParams = {}) =>
        this.request<string, any>({
            path: `/api/deployment/getreleasenote`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags deployment
     * @name RestartAppService
     * @request POST:/api/deployment/restart
     * @secure
     * @response `200` `boolean` Success
     */
    restartAppService = (body: EnvironmentState, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/deployment/restart`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags deployment
     * @name RestartFunctionApp
     * @request GET:/api/deployment/restartfunctionapp
     * @secure
     * @response `200` `boolean` Success
     */
    restartFunctionApp = (query: RestartFunctionAppParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/deployment/restartfunctionapp`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags deployment
     * @name UpdateWebAppConfiguration
     * @request POST:/api/deployment/updateconfigurations
     * @secure
     * @response `200` `boolean` Success
     */
    updateWebAppConfiguration = (body: UpdateConfigurationsDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/deployment/updateconfigurations`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags diagnostics
     * @name GetRegisteredLogProviders
     * @request GET:/api/diagnostics/logproviders
     * @secure
     * @response `200` `void` Success
     */
    getRegisteredLogProviders = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/diagnostics/logproviders`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags dynamicschema
     * @name GetCacheTypes
     * @request GET:/api/dynamicschema/gettypes
     * @secure
     * @response `200` `(Type)[]` Success
     */
    getCacheTypes = (query: GetCacheTypesParams, params: RequestParams = {}) =>
        this.request<Type[], any>({
            path: `/api/dynamicschema/gettypes`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags dynamicschema
     * @name GetCacheTypesSchema
     * @request POST:/api/dynamicschema/gettypeschema
     * @secure
     * @response `200` `string` Success
     */
    getCacheTypesSchema = (body: string, params: RequestParams = {}) =>
        this.request<string, any>({
            path: `/api/dynamicschema/gettypeschema`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name CascadeDeleteEntity
     * @request POST:/api/entity/cascadedelete
     * @secure
     * @response `200` `boolean` Success
     */
    cascadeDeleteEntity = (body: DeleteCascadeDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/entity/cascadedelete`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name DeleteEntity
     * @request POST:/api/entity/delete
     * @secure
     * @response `200` `boolean` Success
     */
    deleteEntity = (body: DeleteDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/entity/delete`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name DeleteCosmosItemDangerousDangerous
     * @request POST:/api/entity/deleteCosmosItemDangerous
     * @secure
     * @response `200` `boolean` Success
     */
    deleteCosmosItemDangerousDangerous = (body: DeleteDangerousDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/entity/deleteCosmosItemDangerous`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name GetEntityExpanded
     * @request POST:/api/entity/getexpanded
     * @secure
     * @response `200` `ITPEntityWithStringVariants` Success
     */
    getEntityExpanded = (body: GetDto, params: RequestParams = {}) =>
        this.request<ITPEntityWithStringVariants, any>({
            path: `/api/entity/getexpanded`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name GetEntitiesExpanded
     * @request POST:/api/entity/getexpandedmultiple
     * @secure
     * @response `200` `(ITPEntityWithStringVariants)[]` Success
     */
    getEntitiesExpanded = (body: GetMultipleDto, params: RequestParams = {}) =>
        this.request<ITPEntityWithStringVariants[], any>({
            path: `/api/entity/getexpandedmultiple`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name QueryEntitiesExpanded
     * @request POST:/api/entity/queryexpanded
     * @secure
     * @response `200` `(ITPEntityWithStringVariants)[]` Success
     */
    queryEntitiesExpanded = (body: QueryDto, params: RequestParams = {}) =>
        this.request<ITPEntityWithStringVariants[], any>({
            path: `/api/entity/queryexpanded`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name GetEntity
     * @request POST:/api/entity/get
     * @secure
     * @response `200` `TPEntity` Success
     */
    getEntity = (body: GetDto, params: RequestParams = {}) =>
        this.request<TPEntity, any>({
            path: `/api/entity/get`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name GetEntityDetails
     * @request POST:/api/entity/getentitydetails
     * @secure
     * @response `200` `Record<string,string>` Success
     */
    getEntityDetails = (body: GetEntityDetailsDto, params: RequestParams = {}) =>
        this.request<Record<string, string>, any>({
            path: `/api/entity/getentitydetails`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name QueryEntitiesMinified
     * @request POST:/api/entity/queryminified
     * @secure
     * @response `200` `(TPEntityMinified)[]` Success
     */
    queryEntitiesMinified = (body: QueryDto, params: RequestParams = {}) =>
        this.request<TPEntityMinified[], any>({
            path: `/api/entity/queryminified`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name QueryEntity
     * @request POST:/api/entity/query
     * @secure
     * @response `200` `(TPEntity)[]` Success
     */
    queryEntity = (body: QueryDto, params: RequestParams = {}) =>
        this.request<TPEntity[], any>({
            path: `/api/entity/query`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name UpsertEntity
     * @request POST:/api/entity/upsert
     * @secure
     * @response `200` `(TPEntity)[]` Success
     */
    upsertEntity = (body: UpsertDto, params: RequestParams = {}) =>
        this.request<TPEntity[], any>({
            path: `/api/entity/upsert`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name AddVersionedActivity
     * @request POST:/api/entity/version/contract/addversionedactivity
     * @secure
     * @response `200` `boolean` Success
     */
    addVersionedActivity = (body: AddVersionedActivityDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/entity/version/contract/addversionedactivity`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name GetVersionedAllocationsTimephased
     * @request POST:/api/entity/version/contract/getversionedallocationtimephased
     * @secure
     * @response `200` `void` Success
     */
    getVersionedAllocationsTimephased = (body: VersionedAllocationTimephasedDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/entity/version/contract/getversionedallocationtimephased`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name GetVersionedRequestsTimephased
     * @request POST:/api/entity/version/contract/getversionedrequesttimephased
     * @secure
     * @response `200` `void` Success
     */
    getVersionedRequestsTimephased = (body: VersionedRequestTimephasedDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/entity/version/contract/getversionedrequesttimephased`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name DeltaUpsertEntity
     * @request POST:/api/entity/deltaupsert
     * @secure
     * @response `200` `(TPEntity)[]` Success
     */
    deltaUpsertEntity = (body: DeltaUpsertDto, params: RequestParams = {}) =>
        this.request<TPEntity[], any>({
            path: `/api/entity/deltaupsert`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags entity
     * @name UpsertEntityReturnExpanded
     * @request POST:/api/entity/upsertReturnExpanded
     * @secure
     * @response `200` `(ITPEntityWithStringVariants)[]` Success
     */
    upsertEntityReturnExpanded = (body: UpsertDto, params: RequestParams = {}) =>
        this.request<ITPEntityWithStringVariants[], any>({
            path: `/api/entity/upsertReturnExpanded`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags environment
     * @name GetEnvironmentOptions
     * @summary Gets the Environment Options of the current instance
     * @request GET:/api/environment/getenvironmentoptions
     * @secure
     * @response `200` `EnvironmentOptions` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    getEnvironmentOptions = (params: RequestParams = {}) =>
        this.request<EnvironmentOptions, ProjectumProblemDetails>({
            path: `/api/environment/getenvironmentoptions`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags framework
     * @name UpgradeGetCurrent
     * @request GET:/api/framework/upgrade
     * @secure
     * @response `200` `(AppPackageGroup)[]` Success
     */
    upgradeGetCurrent = (params: RequestParams = {}) =>
        this.request<AppPackageGroup[], any>({
            path: `/api/framework/upgrade`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags framework
     * @name UpgradePackages
     * @request POST:/api/framework/upgrade
     * @secure
     * @response `200` `void` Success
     */
    upgradePackages = (body: UpgradePackagesPayload, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/framework/upgrade`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags framework
     * @name UpgradeGetController
     * @request GET:/api/framework/upgrade/controller/{upgradeController}
     * @secure
     * @response `200` `(UpgradeController)[]` Success
     */
    upgradeGetController = (upgradeController: string, params: RequestParams = {}) =>
        this.request<UpgradeController[], any>({
            path: `/api/framework/upgrade/controller/${upgradeController}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags framework
     * @name UpgradeControllers
     * @request GET:/api/framework/upgrade/controller
     * @secure
     * @response `200` `(UpgradeController)[]` Success
     */
    upgradeControllers = (params: RequestParams = {}) =>
        this.request<UpgradeController[], any>({
            path: `/api/framework/upgrade/controller`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags framework
     * @name UpgradeGetPackages
     * @request GET:/api/framework/upgrade/packages/{releaseCycle}
     * @secure
     * @response `200` `(UpgradePackageGroup)[]` Success
     */
    upgradeGetPackages = (releaseCycle: any, params: RequestParams = {}) =>
        this.request<UpgradePackageGroup[], any>({
            path: `/api/framework/upgrade/packages/${releaseCycle}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags grids
     * @name GetProjectPlannerGrid
     * @summary Gets all the rows for Project Planner view.
     * @request POST:/api/grids/projectplanner/getgrid
     * @secure
     * @response `200` `GridData` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    getProjectPlannerGrid = (body: ProjectPlannerGridDto, params: RequestParams = {}) =>
        this.request<GridData, ProjectumProblemDetails>({
            path: `/api/grids/projectplanner/getgrid`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags grids
     * @name UpsertProjectPlannerView
     * @request POST:/api/grids/projectplanner/upsertView
     * @secure
     * @response `200` `void` Success
     */
    upsertProjectPlannerView = (body: GridView, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/grids/projectplanner/upsertView`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags grids
     * @name DeleteProjectPlannerView
     * @request POST:/api/grids/projectplanner/deleteView
     * @secure
     * @response `200` `void` Success
     */
    deleteProjectPlannerView = (body: GridView, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/grids/projectplanner/deleteView`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags grids
     * @name EnrichProjectPlannerGridRow
     * @request POST:/api/grids/projectplanner/enrichgridrow
     * @secure
     * @response `200` `GridRow` Success
     */
    enrichProjectPlannerGridRow = (body: GridRow, params: RequestParams = {}) =>
        this.request<GridRow, any>({
            path: `/api/grids/projectplanner/enrichgridrow`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags grids
     * @name GetResourcePlannerGrid
     * @summary Gets all the rows for Resource Planner view.
     * @request POST:/api/grids/resourceplanner/getgrid
     * @secure
     * @response `200` `GridData` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    getResourcePlannerGrid = (body: ResourcePlannerGridDto, params: RequestParams = {}) =>
        this.request<GridData, ProjectumProblemDetails>({
            path: `/api/grids/resourceplanner/getgrid`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags grids
     * @name UpsertResourcePlannerView
     * @request POST:/api/grids/resourceplanner/upsertView
     * @secure
     * @response `200` `void` Success
     */
    upsertResourcePlannerView = (body: GridView, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/grids/resourceplanner/upsertView`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags grids
     * @name DeleteResourcePlannerView
     * @request POST:/api/grids/resourceplanner/deleteView
     * @secure
     * @response `200` `void` Success
     */
    deleteResourcePlannerView = (body: GridView, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/grids/resourceplanner/deleteView`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags holiday
     * @name GetHolidaysByCountryAndYear
     * @summary Gets the holidays for a specific country by year.
     * @request POST:/api/holiday/holidays/holidays
     * @secure
     * @response `200` `(Holiday)[]` Success
     * @response `500` `ProblemDetails` Server Error
     */
    getHolidaysByCountryAndYear = (query: GetHolidaysByCountryAndYearParams, params: RequestParams = {}) =>
        this.request<Holiday[], ProblemDetails>({
            path: `/api/holiday/holidays/holidays`,
            method: 'POST',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags import
     * @name ImportSqlActivities
     * @summary Imports activities from a SQL database, based on the configuration specified
     * @request POST:/api/import/sql/activities
     * @secure
     * @response `200` `void` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    importSqlActivities = (body: ImportActivitesDto, params: RequestParams = {}) =>
        this.request<void, ProjectumProblemDetails>({
            path: `/api/import/sql/activities`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags integration
     * @name IntegrationDataverseAddStepCreate
     * @request POST:/api/integration/dataverse/addStep
     * @secure
     * @response `200` `void` Success
     */
    integrationDataverseAddStepCreate = (body: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/dataverse/addStep`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags integration
     * @name IntegrationDataverseGetStepsList
     * @request GET:/api/integration/dataverse/getSteps
     * @secure
     * @response `200` `(ServiceEndpointStepDTO)[]` Success
     */
    integrationDataverseGetStepsList = (params: RequestParams = {}) =>
        this.request<ServiceEndpointStepDTO[], any>({
            path: `/api/integration/dataverse/getSteps`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integration
     * @name IntegrationDataverseGetStepCreate
     * @request POST:/api/integration/dataverse/getStep
     * @secure
     * @response `200` `(ServiceEndpointStepDTO)[]` Success
     */
    integrationDataverseGetStepCreate = (body: string, params: RequestParams = {}) =>
        this.request<ServiceEndpointStepDTO[], any>({
            path: `/api/integration/dataverse/getStep`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integration
     * @name IntegrationDataverseRemoveStepCreate
     * @request POST:/api/integration/dataverse/removeStep
     * @secure
     * @response `200` `void` Success
     */
    integrationDataverseRemoveStepCreate = (body: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/dataverse/removeStep`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags integration
     * @name IntegrationDataverseRemoveStepsList
     * @request GET:/api/integration/dataverse/removeSteps
     * @secure
     * @response `200` `void` Success
     */
    integrationDataverseRemoveStepsList = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/dataverse/removeSteps`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags integration
     * @name IntegrationDataverseRegisterEndpointList
     * @request GET:/api/integration/dataverse/registerEndpoint
     * @secure
     * @response `200` `void` Success
     */
    integrationDataverseRegisterEndpointList = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/dataverse/registerEndpoint`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags integration
     * @name IntegrationDataverseGetEndpointList
     * @request GET:/api/integration/dataverse/getEndpoint
     * @secure
     * @response `200` `ServiceEndpointDTO` Success
     */
    integrationDataverseGetEndpointList = (params: RequestParams = {}) =>
        this.request<ServiceEndpointDTO, any>({
            path: `/api/integration/dataverse/getEndpoint`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integration
     * @name IntegrationDataverseRemoveEndpointList
     * @request GET:/api/integration/dataverse/removeEndpoint
     * @secure
     * @response `200` `void` Success
     */
    integrationDataverseRemoveEndpointList = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/dataverse/removeEndpoint`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags integration
     * @name IntegrationDataverseRegisterTeamplannerCreate
     * @request POST:/api/integration/dataverse/registerTeamplanner
     * @secure
     * @response `200` `void` Success
     */
    integrationDataverseRegisterTeamplannerCreate = (body: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/dataverse/registerTeamplanner`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags integration
     * @name IntegrationDataverseCreatePowerPpMsecretCreate
     * @request POST:/api/integration/dataverse/createPowerPPMsecret
     * @secure
     * @response `200` `void` Success
     */
    integrationDataverseCreatePowerPpMsecretCreate = (body: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/dataverse/createPowerPPMsecret`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationEvent
     * @name IntegrationEventList
     * @request GET:/api/IntegrationEvent
     * @secure
     * @response `200` `(IntegrationDtoV2)[]` Success
     */
    integrationEventList = (params: RequestParams = {}) =>
        this.request<IntegrationDtoV2[], any>({
            path: `/api/IntegrationEvent`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationEvent
     * @name IntegrationEventCreate
     * @request POST:/api/IntegrationEvent
     * @secure
     * @response `201` `IntegrationDtoV2` Created
     * @response `500` `ProjectumProblemDetailsTemp` Server Error
     */
    integrationEventCreate = (body: IntegrationEventCreateRequest, params: RequestParams = {}) =>
        this.request<IntegrationDtoV2, ProjectumProblemDetailsTemp>({
            path: `/api/IntegrationEvent`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationEvent
     * @name IntegrationEventUpdate
     * @request PUT:/api/IntegrationEvent
     * @secure
     * @response `200` `IntegrationDtoV2` Success
     * @response `404` `ProblemDetails` Not Found
     * @response `500` `ProjectumProblemDetailsTemp` Server Error
     */
    integrationEventUpdate = (body: IntegrationEventUpdateRequest, params: RequestParams = {}) =>
        this.request<IntegrationDtoV2, ProblemDetails | ProjectumProblemDetailsTemp>({
            path: `/api/IntegrationEvent`,
            method: 'PUT',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationEvent
     * @name IntegrationEventDetail
     * @request GET:/api/IntegrationEvent/{id}
     * @secure
     * @response `200` `IntegrationDtoV2` Success
     * @response `404` `ProblemDetails` Not Found
     */
    integrationEventDetail = (id: string, params: RequestParams = {}) =>
        this.request<IntegrationDtoV2, ProblemDetails>({
            path: `/api/IntegrationEvent/${id}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationEvent
     * @name IntegrationEventDelete
     * @request DELETE:/api/IntegrationEvent/{id}
     * @secure
     * @response `204` `void` No Content
     * @response `404` `ProblemDetails` Not Found
     * @response `500` `ProjectumProblemDetailsTemp` Server Error
     */
    integrationEventDelete = (id: string, params: RequestParams = {}) =>
        this.request<void, ProblemDetails | ProjectumProblemDetailsTemp>({
            path: `/api/IntegrationEvent/${id}`,
            method: 'DELETE',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name RefreshProjectsFromPwa
     * @request POST:/api/integrations/pwa/RefreshProjectsFromPwa
     * @secure
     * @response `200` `boolean` Success
     */
    refreshProjectsFromPwa = (body: RefreshPwaProjectDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/integrations/pwa/RefreshProjectsFromPwa`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name RefreshProjectRbsFromPwa
     * @request POST:/api/integrations/pwa/refreshprojectsrbs
     * @secure
     * @response `200` `boolean` Success
     */
    refreshProjectRbsFromPwa = (body: RefreshPwaProjectDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/integrations/pwa/refreshprojectsrbs`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name RefreshResourcesFromPwa
     * @request POST:/api/integrations/pwa/refreshresourcesfrompwa
     * @secure
     * @response `200` `boolean` Success
     */
    refreshResourcesFromPwa = (body: RefreshPwaEntityDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/integrations/pwa/refreshresourcesfrompwa`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name RefreshResourceRbsFromPwa
     * @request POST:/api/integrations/pwa/refreshresourcesrbs
     * @secure
     * @response `200` `boolean` Success
     */
    refreshResourceRbsFromPwa = (body: RefreshPwaEntityDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/integrations/pwa/refreshresourcesrbs`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name RunIntegration
     * @request POST:/api/integrations/runintegration
     * @secure
     * @response `200` `void` Success
     */
    runIntegration = (body: SetupIntegrationDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integrations/runintegration`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name SaveIntegration
     * @request GET:/api/integrations/saveintegration
     * @secure
     * @response `200` `boolean` Success
     */
    saveIntegration = (body: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/integrations/saveintegration`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name GetDataverseProjects
     * @request GET:/api/integrations/dataverse/getdata
     * @secure
     * @response `200` `void` Success
     */
    getDataverseProjects = (query: GetDataverseProjectsParams, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integrations/dataverse/getdata`,
            method: 'GET',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name UpdateGanttTask
     * @request POST:/api/integrations/dataverse/updategantttask
     * @secure
     * @response `200` `boolean` Success
     */
    updateGanttTask = (body: GanttDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/integrations/dataverse/updategantttask`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name AadSyncTest
     * @request GET:/api/integrations/aadsynctest
     * @secure
     * @response `200` `void` Success
     */
    aadSyncTest = (query: AadSyncTestParams, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integrations/aadsynctest`,
            method: 'GET',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportCosmosEntityUds
     * @request POST:/api/integrations/dataexport/cosmos/entityIds
     * @secure
     * @response `200` `(Record<string,any>)[]` Success
     */
    dataExportCosmosEntityUds = (body: DataExportQueryDTO, params: RequestParams = {}) =>
        this.request<Record<string, any>[], any>({
            path: `/api/integrations/dataexport/cosmos/entityIds`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportGetEntities
     * @request POST:/api/integrations/dataexport/cosmos/getEntities
     * @secure
     * @response `200` `(any)[]` Success
     */
    dataExportGetEntities = (body: DataExportCollectionDTO, params: RequestParams = {}) =>
        this.request<any[], any>({
            path: `/api/integrations/dataexport/cosmos/getEntities`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportGetDeltaData
     * @request GET:/api/integrations/dataexport/delta/getDelta/{integration}/{page}
     * @secure
     * @response `200` `(Record<string,any>)[]` Success
     */
    dataExportGetDeltaData = (integration: string, page: number, params: RequestParams = {}) =>
        this.request<Record<string, any>[], any>({
            path: `/api/integrations/dataexport/delta/getDelta/${integration}/${page}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportDeltaTimephased
     * @request POST:/api/integrations/dataexport/delta/timephased
     * @secure
     * @response `200` `(DataExportTimephasedPeriodWork)[]` Success
     */
    dataExportDeltaTimephased = (body: DataIntegrationTimephasedtDTO, params: RequestParams = {}) =>
        this.request<DataExportTimephasedPeriodWork[], any>({
            path: `/api/integrations/dataexport/delta/timephased`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportDeltaCapacity
     * @request POST:/api/integrations/dataexport/delta/capacity
     * @secure
     * @response `200` `(DataExportTimephasedPeriodCapacity)[]` Success
     */
    dataExportDeltaCapacity = (body: DataIntegrationTimephasedtDTO, params: RequestParams = {}) =>
        this.request<DataExportTimephasedPeriodCapacity[], any>({
            path: `/api/integrations/dataexport/delta/capacity`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportCacheConstraint
     * @request POST:/api/integrations/dataexport/cacheConstraint
     * @secure
     * @response `200` `DateTimeRange` Success
     */
    dataExportCacheConstraint = (params: RequestParams = {}) =>
        this.request<DateTimeRange, any>({
            path: `/api/integrations/dataexport/cacheConstraint`,
            method: 'POST',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportEnable
     * @request POST:/api/integrations/dataexport/enable
     * @secure
     * @response `200` `void` Success
     */
    dataExportEnable = (body: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integrations/dataexport/enable`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportDisable
     * @request GET:/api/integrations/dataexport/disable
     * @secure
     * @response `200` `void` Success
     */
    dataExportDisable = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integrations/dataexport/disable`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportGetData
     * @request GET:/api/integrations/dataexport/getData/{integration}/{page}
     * @secure
     * @response `200` `(Record<string,any>)[]` Success
     */
    dataExportGetData = (integration: string, page: string, params: RequestParams = {}) =>
        this.request<Record<string, any>[], any>({
            path: `/api/integrations/dataexport/getData/${integration}/${page}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportGetDataRequest
     * @request POST:/api/integrations/dataexport/getData
     * @secure
     * @response `200` `(Record<string,any>)[]` Success
     */
    dataExportGetDataRequest = (body: DataIntegrationRequestDTO, params: RequestParams = {}) =>
        this.request<Record<string, any>[], any>({
            path: `/api/integrations/dataexport/getData`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportGetMetadata
     * @request GET:/api/integrations/dataexport/getMetadata
     * @secure
     * @response `200` `Record<string,any>` Success
     */
    dataExportGetMetadata = (params: RequestParams = {}) =>
        this.request<Record<string, any>, any>({
            path: `/api/integrations/dataexport/getMetadata`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportGetResources
     * @request GET:/api/integrations/dataexport/resources
     * @secure
     * @response `200` `(any)[]` Success
     */
    dataExportGetResources = (params: RequestParams = {}) =>
        this.request<any[], any>({
            path: `/api/integrations/dataexport/resources`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportGetResourcesRequests
     * @request POST:/api/integrations/dataexport/requests
     * @secure
     * @response `200` `(any)[]` Success
     */
    dataExportGetResourcesRequests = (body: DataReferenceIdsDto, params: RequestParams = {}) =>
        this.request<any[], any>({
            path: `/api/integrations/dataexport/requests`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportGetResourcesAllocations
     * @request POST:/api/integrations/dataexport/allocations
     * @secure
     * @response `200` `(any)[]` Success
     */
    dataExportGetResourcesAllocations = (body: DataReferenceIdsDto, params: RequestParams = {}) =>
        this.request<any[], any>({
            path: `/api/integrations/dataexport/allocations`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags integrations
     * @name DataExportGetResourcesCapacity
     * @request POST:/api/integrations/dataexport/capacity
     * @secure
     * @response `200` `(any)[]` Success
     */
    dataExportGetResourcesCapacity = (body: DataReferenceIdsDto, params: RequestParams = {}) =>
        this.request<any[], any>({
            path: `/api/integrations/dataexport/capacity`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags license
     * @name LogLicenseUsage
     * @request GET:/api/license/loglicenseusage
     * @secure
     * @response `200` `boolean` Success
     */
    logLicenseUsage = (query: LogLicenseUsageParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/license/loglicenseusage`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags license
     * @name LogMetrics
     * @request GET:/api/license/logmetrics
     * @secure
     * @response `200` `boolean` Success
     */
    logMetrics = (query: LogMetricsParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/license/logmetrics`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description Calculates and returns the count of billable resources that are active, named and allocated from the start of the current month onwards.
     *
     * @tags license
     * @name GetBillableResources
     * @summary Get the number of billable resources.
     * @request GET:/api/license/billableresources
     * @secure
     * @response `200` `void` Success
     * @response `400` `void` Bad Request
     * @response `500` `ProblemDetails` Server Error
     */
    getBillableResources = (params: RequestParams = {}) =>
        this.request<void, void | ProblemDetails>({
            path: `/api/license/billableresources`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * @description This is used when debugging, making sure that debug levels are reverted in production environments.
     *
     * @tags logging
     * @name UpsertTemporaryLogFilters
     * @summary Upserts specified log settings temporarily for the given timespan.
     * @request POST:/api/logging/debug/temporary
     * @secure
     * @response `200` `void` Success
     * @response `400` `void` Bad Request
     * @response `500` `ProblemDetails` Server Error
     */
    upsertTemporaryLogFilters = (body: UpsertTemporaryLogFiltersDto, params: RequestParams = {}) =>
        this.request<void, void | ProblemDetails>({
            path: `/api/logging/debug/temporary`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags logging
     * @name GetLogFilters
     * @summary Get the logger filters configured.
     * @request GET:/api/logging/filters
     * @secure
     * @response `200` `(LogFilter)[]` Success
     * @response `500` `ProblemDetails` Server Error
     */
    getLogFilters = (params: RequestParams = {}) =>
        this.request<LogFilter[], ProblemDetails>({
            path: `/api/logging/filters`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description This way you can set loglevels for specific namespaces og namespace patterns.
     *
     * @tags logging
     * @name UpsertLogFilter
     * @summary Upserts the logger filter service with the loglevel for the specified namespace pattern.
     * @request PUT:/api/logging/filters/{namespacePattern}
     * @secure
     * @response `200` `void` Success
     * @response `400` `void` Bad Request
     * @response `500` `ProblemDetails` Server Error
     */
    upsertLogFilter = (namespacePattern: string, body: LogFilter, params: RequestParams = {}) =>
        this.request<void, void | ProblemDetails>({
            path: `/api/logging/filters/${namespacePattern}`,
            method: 'PUT',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description This way you can remove loglevels for specific namespaces og namespace patterns.
     *
     * @tags logging
     * @name DeleteLogFilter
     * @summary Deletes the loglevel for the specified namespace pattern in the logger filter service.
     * @request DELETE:/api/logging/filters/{namespacePattern}
     * @secure
     * @response `200` `void` Success
     * @response `204` `void` No Content
     * @response `404` `void` Not Found
     * @response `500` `ProblemDetails` Server Error
     */
    deleteLogFilter = (namespacePattern: string, params: RequestParams = {}) =>
        this.request<void, void | ProblemDetails>({
            path: `/api/logging/filters/${namespacePattern}`,
            method: 'DELETE',
            secure: true,
            ...params,
        });
    /**
     * @description The default loglevel is used, when no configured namespace patterns match.
     *
     * @tags logging
     * @name UpsertDefaultLogLevel
     * @summary Upserts the logger filter service with the default loglevel.
     * @request PUT:/api/logging/filters/default
     * @secure
     * @response `200` `void` Success
     * @response `400` `void` Bad Request
     * @response `500` `ProblemDetails` Server Error
     */
    upsertDefaultLogLevel = (body: UpsertLogLevelDto, params: RequestParams = {}) =>
        this.request<void, void | ProblemDetails>({
            path: `/api/logging/filters/default`,
            method: 'PUT',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description This way you can validate log levels for specific namespaces or namespace patterns.
     *
     * @tags logging
     * @name TestNamespacePattern
     * @summary Tests if a specific namespace is correctly configured in the logger filter service.
     * @request GET:/api/logging/filters/test/{namespacePattern}
     * @secure
     * @response `200` `void` Success
     * @response `404` `void` Not Found
     * @response `500` `ProblemDetails` Server Error
     */
    testNamespacePattern = (namespacePattern: string, params: RequestParams = {}) =>
        this.request<void, void | ProblemDetails>({
            path: `/api/logging/filters/test/${namespacePattern}`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags mail
     * @name RunMailSchedule
     * @request GET:/api/mail/mailscheduleinvoke
     * @secure
     * @response `200` `boolean` Success
     */
    runMailSchedule = (query: RunMailScheduleParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/mail/mailscheduleinvoke`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags mail
     * @name RunScheduledMailTemplate
     * @request POST:/api/mail/runscheduledmailtemplate
     * @secure
     * @response `200` `boolean` Success
     */
    runScheduledMailTemplate = (body: MailTemplate, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/mail/runscheduledmailtemplate`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags mail
     * @name GetMailConfig
     * @request GET:/api/mail/getmailconfig
     * @secure
     * @response `200` `MailServiceConfig` Success
     */
    getMailConfig = (query: GetMailConfigParams, params: RequestParams = {}) =>
        this.request<MailServiceConfig, any>({
            path: `/api/mail/getmailconfig`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags mail
     * @name UpsertMailConfig
     * @request POST:/api/mail/setmailconfig
     * @secure
     * @response `200` `boolean` Success
     */
    upsertMailConfig = (body: MailServiceConfig, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/mail/setmailconfig`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags mail
     * @name SendBasicMail
     * @request POST:/api/mail/sendbasicmail
     * @secure
     * @response `200` `boolean` Success
     */
    sendBasicMail = (body: SendMailOptions, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/mail/sendbasicmail`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags mail
     * @name SendTemplateMail
     * @request POST:/api/mail/sendtemplatemail
     * @secure
     * @response `200` `boolean` Success
     */
    sendTemplateMail = (body: SendTemplateMailOptions, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/mail/sendtemplatemail`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags mail
     * @name ParseMailTemplate
     * @request POST:/api/mail/parsemailtemplate
     * @secure
     * @response `200` `string` Success
     */
    parseMailTemplate = (body: MailTemplateTesterDto, params: RequestParams = {}) =>
        this.request<string, any>({
            path: `/api/mail/parsemailtemplate`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags migration
     * @name GetMigrations
     * @request GET:/api/migration/getmigrations
     * @secure
     * @response `200` `(MigrationDto)[]` Success
     */
    getMigrations = (query: GetMigrationsParams, params: RequestParams = {}) =>
        this.request<MigrationDto[], any>({
            path: `/api/migration/getmigrations`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags migration
     * @name RunMigration
     * @request POST:/api/migration/runmigration
     * @secure
     * @response `200` `boolean` Success
     */
    runMigration = (body: MigrationDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/migration/runmigration`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags msteams
     * @name AddTeamMember
     * @request POST:/api/msteams/addteammember
     * @secure
     * @response `200` `boolean` Success
     */
    addTeamMember = (body: AddTeamMemberDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/msteams/addteammember`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags msteams
     * @name EnableTeamsEvent
     * @request GET:/api/msteams/enableteamsevent
     * @secure
     * @response `200` `boolean` Success
     */
    enableTeamsEvent = (query: EnableTeamsEventParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/msteams/enableteamsevent`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags msteams
     * @name MsTeamsEvent
     * @request POST:/api/msteams/eventteams
     * @secure
     * @response `200` `GraphGroup` Success
     */
    msTeamsEvent = (body: EnsureTeamDto, params: RequestParams = {}) =>
        this.request<GraphGroup, any>({
            path: `/api/msteams/eventteams`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags msteams
     * @name GetTeams
     * @request GET:/api/msteams/getteams
     * @secure
     * @response `200` `(GraphGroup)[]` Success
     */
    getTeams = (query: GetTeamsParams, params: RequestParams = {}) =>
        this.request<GraphGroup[], any>({
            path: `/api/msteams/getteams`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags msteams
     * @name IsMsTeamsIntegrationEnabled
     * @request GET:/api/msteams/isteamsintegrationenabled
     * @secure
     * @response `200` `boolean` Success
     */
    isMsTeamsIntegrationEnabled = (body: boolean, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/msteams/isteamsintegrationenabled`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags msteams
     * @name RemoveTeamMember
     * @request GET:/api/msteams/removeteammember
     * @secure
     * @response `200` `boolean` Success
     */
    removeTeamMember = (query: RemoveTeamMemberParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/msteams/removeteammember`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags notifications
     * @name AddNotification
     * @request POST:/api/notifications/addnotification
     * @secure
     * @response `200` `boolean` Success
     */
    addNotification = (body: NotificationDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/notifications/addnotification`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags panel
     * @name GetResourcesForPanel
     * @request POST:/api/panel/getresourcesforpanel
     * @secure
     * @response `200` `(ResourceListItem)[]` Success
     */
    getResourcesForPanel = (body: GetResourcesForListDto, params: RequestParams = {}) =>
        this.request<ResourceListItem[], any>({
            path: `/api/panel/getresourcesforpanel`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags panels
     * @name ProjectPanelLineManager
     * @summary Gets the list of projects for line managers, used in the project panel
     * @request POST:/api/panels/project/linemanager
     * @secure
     * @response `200` `(ProjectListItem)[]` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    projectPanelLineManager = (body: ProjectPanelLineManagerDto, params: RequestParams = {}) =>
        this.request<ProjectListItem[], ProjectumProblemDetails>({
            path: `/api/panels/project/linemanager`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags permissions
     * @name RefreshAllResourcesPermissionsFromAad
     * @request GET:/api/permissions/refreshallresourcespermisisons
     * @secure
     * @response `200` `boolean` Success
     */
    refreshAllResourcesPermissionsFromAad = (query: RefreshAllResourcesPermissionsFromAadParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/permissions/refreshallresourcespermisisons`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags permissions
     * @name RefreshResourcePermissionFromAad
     * @request POST:/api/permissions/refreshresourcepermisisons
     * @secure
     * @response `200` `boolean` Success
     */
    refreshResourcePermissionFromAad = (body: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/permissions/refreshresourcepermisisons`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description Loads all Entra Groups and updates the cache.
     *
     * @tags permissions
     * @name LoadEntraGroups
     * @summary Load Entra Groups
     * @request POST:/api/permissions/loadEntraGroups
     * @secure
     * @response `200` `(GraphGroup)[]` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    loadEntraGroups = (body: string, params: RequestParams = {}) =>
        this.request<GraphGroup[], ProjectumProblemDetails>({
            path: `/api/permissions/loadEntraGroups`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description Checks which groups a user is in. Returns the group ids, of those.
     *
     * @tags permissions
     * @name IsUserInEntraGroups
     * @summary Is User In Entra Groups
     * @request POST:/api/permissions/isUserInEntraGroups
     * @secure
     * @response `200` `(string)[]` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    isUserInEntraGroups = (body: IsUserInEntraGroupsDto, params: RequestParams = {}) =>
        this.request<string[], ProjectumProblemDetails>({
            path: `/api/permissions/isUserInEntraGroups`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags playground
     * @name GetProjectContractsTimephased
     * @summary Gets all projectcontracts within the boundary, timephased by resolution
     * @request GET:/api/playground/getprojectcontractstimephased/{projectId}
     * @secure
     * @response `200` `(PeriodWorkUnit)[]` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    getProjectContractsTimephased = ({ projectId, ...query }: GetProjectContractsTimephasedParams, params: RequestParams = {}) =>
        this.request<PeriodWorkUnit[], ProjectumProblemDetails>({
            path: `/api/playground/getprojectcontractstimephased/${projectId}`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags playground
     * @name GetAllContractsTimephased
     * @summary Gets all contracts within the boundary, timephased by resolution
     * @request GET:/api/playground/getcontractstimephased
     * @secure
     * @response `200` `(PeriodWorkUnit)[]` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    getAllContractsTimephased = (query: GetAllContractsTimephasedParams, params: RequestParams = {}) =>
        this.request<PeriodWorkUnit[], ProjectumProblemDetails>({
            path: `/api/playground/getcontractstimephased`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name AadSyncEvent
     * @request POST:/api/plugin/aadsyncevent
     * @secure
     * @response `200` `void` Success
     */
    aadSyncEvent = (body: AADSyncEntity, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/plugin/aadsyncevent`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name FireIntegration
     * @request POST:/api/plugin/fireintegration
     * @secure
     * @response `200` `boolean` Success
     */
    fireIntegration = (body: FireIntegrationsDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/plugin/fireintegration`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name GetIntegrations
     * @request GET:/api/plugin/getintegrations
     * @secure
     * @response `200` `(IntegrationProviderDto)[]` Success
     */
    getIntegrations = (query: GetIntegrationsParams, params: RequestParams = {}) =>
        this.request<IntegrationProviderDto[], any>({
            path: `/api/plugin/getintegrations`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name InitializeIntegrations
     * @request GET:/api/plugin/integrationinitialize
     * @secure
     * @response `200` `void` Success
     */
    initializeIntegrations = (query: InitializeIntegrationsParams, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/plugin/integrationinitialize`,
            method: 'GET',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name ProjectOnlineRemoteEvent
     * @request POST:/api/plugin/PORemoteEvent
     * @secure
     * @response `200` `void` Success
     */
    projectOnlineRemoteEvent = (body: RemoteIntegrationDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/plugin/PORemoteEvent`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name ProjectOnlineRemoteEventConnection
     * @request POST:/api/plugin/poremoteeventconnection
     * @secure
     * @response `200` `boolean` Success
     */
    projectOnlineRemoteEventConnection = (body: PORemoteEventConnectionDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/plugin/poremoteeventconnection`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name GetAvailableLocales
     * @request GET:/api/plugin/getavailablelocales
     * @secure
     * @response `200` `(string)[]` Success
     */
    getAvailableLocales = (query: GetAvailableLocalesParams, params: RequestParams = {}) =>
        this.request<string[], any>({
            path: `/api/plugin/getavailablelocales`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name GetLanguageFile
     * @request GET:/api/plugin/getlanguagefile
     * @secure
     * @response `200` `string` Success
     */
    getLanguageFile = (query: GetLanguageFileParams, params: RequestParams = {}) =>
        this.request<string, any>({
            path: `/api/plugin/getlanguagefile`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name GetPlugins
     * @request GET:/api/plugin/getplugins
     * @secure
     * @response `200` `(TPPluginInformation)[]` Success
     */
    getPlugins = (body: string, params: RequestParams = {}) =>
        this.request<TPPluginInformation[], any>({
            path: `/api/plugin/getplugins`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name InvokePlugin
     * @request POST:/api/plugin/plugininvoker
     * @secure
     * @response `200` `void` Success
     */
    invokePlugin = (body: InvokeDefinition, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/plugin/plugininvoker`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name FixInconsistenciesInExportedContractData
     * @request POST:/api/plugin/contractsreportdatainconsistencyfixer
     * @secure
     * @response `200` `void` Success
     */
    fixInconsistenciesInExportedContractData = (body: ContractsReportDataInconsistencyFixerDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/plugin/contractsreportdatainconsistencyfixer`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name PluginByGuid
     * @request GET:/api/plugin/{product}/{pluginId}
     * @secure
     * @response `200` `void` Success
     */
    pluginByGuid = (product: string, pluginId: string, body: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/plugin/${product}/${pluginId}`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name PluginByGuid2
     * @request POST:/api/plugin/{product}/{pluginId}
     * @originalName pluginByGuid
     * @duplicate
     * @secure
     * @response `200` `void` Success
     */
    pluginByGuid2 = ({ product, pluginId, ...query }: PluginByGuid2Params, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/plugin/${product}/${pluginId}`,
            method: 'POST',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name PowerPmmRemoteEvent
     * @request POST:/api/plugin/powerppmremoteevent
     * @secure
     * @response `200` `void` Success
     */
    powerPmmRemoteEvent = (body: PowerPPMIntegrationDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/plugin/powerppmremoteevent`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name PowerPmmSbRemoteEvent
     * @request POST:/api/plugin/powerppmsbremoteevent
     * @secure
     * @response `200` `void` Success
     */
    powerPmmSbRemoteEvent = (body: PowerPPMSBIntegrationDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/plugin/powerppmsbremoteevent`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name PowerPpmRemoteEventConnection
     * @request POST:/api/plugin/powerppmremoteeventconnection
     * @secure
     * @response `200` `boolean` Success
     */
    powerPpmRemoteEventConnection = (body: PowerPPMRemoteEventConnectionDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/plugin/powerppmremoteeventconnection`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags plugin
     * @name FunctionAppRenewSubscription
     * @request GET:/api/plugin/renewsubscriptionexpiration
     * @secure
     * @response `200` `boolean` Success
     */
    functionAppRenewSubscription = (query: FunctionAppRenewSubscriptionParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/plugin/renewsubscriptionexpiration`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description The response is grouped by a contract field value, then by generic resource id
     *
     * @tags powerppm
     * @name GetProjectAllocations
     * @summary Gets the allocations for a project, summed up on generic resources and timephased using the specified resolution
     * @request POST:/api/powerppm/projectallocations
     * @secure
     * @response `200` `PowerPpmHeatmapRow` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    getProjectAllocations = (body: GetProjectAllocationsDto, params: RequestParams = {}) =>
        this.request<PowerPpmHeatmapRow, ProjectumProblemDetails>({
            path: `/api/powerppm/projectallocations`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags project
     * @name GetProjectsForPicker
     * @request POST:/api/project/getprojectsforpickerfield
     * @secure
     * @response `200` `(ProjectMinifiedWithStringVariants)[]` Success
     */
    getProjectsForPicker = (body: ProjectPickerDto, params: RequestParams = {}) =>
        this.request<ProjectMinifiedWithStringVariants[], any>({
            path: `/api/project/getprojectsforpickerfield`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags project
     * @name GetProjectsForPanel
     * @request POST:/api/project/getprojectsforpanel
     * @secure
     * @response `200` `(ProjectListItem)[]` Success
     */
    getProjectsForPanel = (body: GetProjectsForListDto, params: RequestParams = {}) =>
        this.request<ProjectListItem[], any>({
            path: `/api/project/getprojectsforpanel`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags project
     * @name GetProjectsForOverview
     * @request GET:/api/project/getprojectsforoverview
     * @secure
     * @response `200` `(ProjectWithStringVariants)[]` Success
     */
    getProjectsForOverview = (body: GetProjectsForOverviewDto, params: RequestParams = {}) =>
        this.request<ProjectWithStringVariants[], any>({
            path: `/api/project/getprojectsforoverview`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags project
     * @name GetProjectsForOverview2
     * @request POST:/api/project/getprojectsforoverview
     * @originalName getProjectsForOverview
     * @duplicate
     * @secure
     * @response `200` `(ProjectWithStringVariants)[]` Success
     */
    getProjectsForOverview2 = (body: GetProjectsForOverviewDto, params: RequestParams = {}) =>
        this.request<ProjectWithStringVariants[], any>({
            path: `/api/project/getprojectsforoverview`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags project
     * @name ChangeProjectId
     * @request POST:/api/project/projectreplacement
     * @secure
     * @response `200` `boolean` Success
     */
    changeProjectId = (body: ReplaceProjectDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/project/projectreplacement`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags project
     * @name GetProjectsWhereCurrentResourceIsOwner
     * @request GET:/api/project/getrelatedprojects
     * @secure
     * @response `200` `(Project)[]` Success
     */
    getProjectsWhereCurrentResourceIsOwner = (query: GetProjectsWhereCurrentResourceIsOwnerParams, params: RequestParams = {}) =>
        this.request<Project[], any>({
            path: `/api/project/getrelatedprojects`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags project
     * @name MoveContractEligibleProjects
     * @summary Gets a list of projects (minified) that are allowed for the 'Move Contract' feature.
     * @request GET:/api/project/movecontracteligibleprojects
     * @secure
     * @response `200` `(ProjectMinifiedWithStringVariants)[]` Success
     * @response `500` `ProblemDetails` Server Error
     */
    moveContractEligibleProjects = (params: RequestParams = {}) =>
        this.request<ProjectMinifiedWithStringVariants[], ProblemDetails>({
            path: `/api/project/movecontracteligibleprojects`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags project
     * @name SelfAllocatableProjects
     * @summary Gets a list of projects (minified) that are allowed for resources to self allocate to.
     * @request GET:/api/project/selfallocatableprojects
     * @secure
     * @response `200` `(ProjectMinifiedWithStringVariants)[]` Success
     * @response `500` `ProblemDetails` Server Error
     */
    selfAllocatableProjects = (params: RequestParams = {}) =>
        this.request<ProjectMinifiedWithStringVariants[], ProblemDetails>({
            path: `/api/project/selfallocatableprojects`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags pushnotificationannouncements
     * @name GetPushNotificationAnnouncements
     * @summary Gets all the active push notification announcements from blob.
     * @request POST:/api/pushnotificationannouncements/getpushnotificationannouncements
     * @secure
     * @response `200` `(PushNotificationAnnouncementDto)[]` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    getPushNotificationAnnouncements = (body: GetPushNotificationAnnouncementsPayload, params: RequestParams = {}) =>
        this.request<PushNotificationAnnouncementDto[], ProjectumProblemDetails>({
            path: `/api/pushnotificationannouncements/getpushnotificationannouncements`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags report
     * @name CheckForInconsistenciesInExportedContractData
     * @request GET:/api/report/allcontractsreportdatainconsistencychecker
     * @secure
     * @response `200` `(ContractReportDataInconsistencyResult)[]` Success
     */
    checkForInconsistenciesInExportedContractData = (body: boolean, params: RequestParams = {}) =>
        this.request<ContractReportDataInconsistencyResult[], any>({
            path: `/api/report/allcontractsreportdatainconsistencychecker`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags report
     * @name AlignInconsistenciesInExportedContractData
     * @request POST:/api/report/contractsreportdatainconsistencyaligner
     * @secure
     * @response `200` `void` Success
     */
    alignInconsistenciesInExportedContractData = (body: ContractsReportDataInconsistencyAlignerDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/report/contractsreportdatainconsistencyaligner`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags report
     * @name GetAllocationsTimephased
     * @request POST:/api/report/getallocationtimephased
     * @secure
     * @response `200` `void` Success
     */
    getAllocationsTimephased = (body: AllocationTimephasedDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/report/getallocationtimephased`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags report
     * @name GetActivitiesTimephased
     * @request GET:/api/report/getrequestsallocations
     * @secure
     * @response `200` `void` Success
     */
    getActivitiesTimephased = (body: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/report/getrequestsallocations`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags report
     * @name GetRequestsTimephased
     * @request POST:/api/report/getrequesttimephased
     * @secure
     * @response `200` `void` Success
     */
    getRequestsTimephased = (body: RequestTimephasedDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/report/getrequesttimephased`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags report
     * @name CheckForInconsistenciesInExportedProjectContractData
     * @request GET:/api/report/projectcontractsreportdatainconsistencychecker
     * @secure
     * @response `200` `(ContractReportDataInconsistencyResult)[]` Success
     */
    checkForInconsistenciesInExportedProjectContractData = (body: string, params: RequestParams = {}) =>
        this.request<ContractReportDataInconsistencyResult[], any>({
            path: `/api/report/projectcontractsreportdatainconsistencychecker`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags report
     * @name GetResourceCapacityTimephased
     * @request POST:/api/report/resourcecapacitytimephased
     * @secure
     * @response `200` `(ResourceCapacityTimephasedResult)[]` Success
     */
    getResourceCapacityTimephased = (body: ResourceCapacityTimephasedDto, params: RequestParams = {}) =>
        this.request<ResourceCapacityTimephasedResult[], any>({
            path: `/api/report/resourcecapacitytimephased`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags report
     * @name GetPriorityExecutor
     * @request POST:/api/report/getpriorityexecutor
     * @secure
     * @response `200` `void` Success
     */
    getPriorityExecutor = (body: boolean, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/report/getpriorityexecutor`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags report
     * @name GetSqlExportErrorForOverview
     * @request GET:/api/report/getsqlexporterrorforoverview
     * @secure
     * @response `200` `(SqlExportErrorExpanded)[]` Success
     */
    getSqlExportErrorForOverview = (body: string, params: RequestParams = {}) =>
        this.request<SqlExportErrorExpanded[], any>({
            path: `/api/report/getsqlexporterrorforoverview`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags report
     * @name GetSqlExportErrorForOverview2
     * @request POST:/api/report/getsqlexporterrorforoverview
     * @originalName getSqlExportErrorForOverview
     * @duplicate
     * @secure
     * @response `200` `(SqlExportErrorExpanded)[]` Success
     */
    getSqlExportErrorForOverview2 = (body: string, params: RequestParams = {}) =>
        this.request<SqlExportErrorExpanded[], any>({
            path: `/api/report/getsqlexporterrorforoverview`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags report
     * @name RerunSqlExportError
     * @request POST:/api/report/rerunsqlexporterror
     * @secure
     * @response `200` `void` Success
     */
    rerunSqlExportError = (body: RerunSqlExportErrorPayload, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/report/rerunsqlexporterror`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name IsCurrentResourceInOwnerGroup
     * @request POST:/api/resource/iscurrentresourceinownergroup
     * @secure
     * @response `200` `boolean` Success
     */
    isCurrentResourceInOwnerGroup = (body: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/resource/iscurrentresourceinownergroup`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name StartDelegation
     * @request POST:/api/resource/startdelegation
     * @secure
     * @response `200` `boolean` Success
     */
    startDelegation = (body: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/resource/startdelegation`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name StopDelegation
     * @request GET:/api/resource/stopdelegation
     * @secure
     * @response `200` `Resource` Success
     */
    stopDelegation = (query: StopDelegationParams, params: RequestParams = {}) =>
        this.request<Resource, any>({
            path: `/api/resource/stopdelegation`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name MoveAllocations
     * @request POST:/api/resource/moveallallocations
     * @secure
     * @response `200` `boolean` Success
     */
    moveAllocations = (body: MoveAllAllocationsDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/resource/moveallallocations`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name GetNamedResourcesByRbsDeprecated
     * @request POST:/api/resource/getnamedbyrbs
     * @secure
     * @response `200` `(Resource)[]` Success
     */
    getNamedResourcesByRbsDeprecated = (body: string, params: RequestParams = {}) =>
        this.request<Resource[], any>({
            path: `/api/resource/getnamedbyrbs`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name GetResourcesForPicker
     * @request POST:/api/resource/getresourcesforpickerfield
     * @secure
     * @response `200` `(ResourceMinifiedWithStringVariants)[]` Success
     */
    getResourcesForPicker = (body: ResourcePickerDto, params: RequestParams = {}) =>
        this.request<ResourceMinifiedWithStringVariants[], any>({
            path: `/api/resource/getresourcesforpickerfield`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name GetResourcesForOverview
     * @request GET:/api/resource/getresourcesforoverview
     * @secure
     * @response `200` `(ResourceWithStringVariants)[]` Success
     */
    getResourcesForOverview = (body: GetResourcesForOverviewDto, params: RequestParams = {}) =>
        this.request<ResourceWithStringVariants[], any>({
            path: `/api/resource/getresourcesforoverview`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name GetResourcesForOverview2
     * @request POST:/api/resource/getresourcesforoverview
     * @originalName getResourcesForOverview
     * @duplicate
     * @secure
     * @response `200` `(ResourceWithStringVariants)[]` Success
     */
    getResourcesForOverview2 = (body: GetResourcesForOverviewDto, params: RequestParams = {}) =>
        this.request<ResourceWithStringVariants[], any>({
            path: `/api/resource/getresourcesforoverview`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name ToggleFavourtie
     * @request POST:/api/resource/togglefavourite
     * @secure
     * @response `200` `boolean` Success
     */
    toggleFavourtie = (body: UpdateFavouritesDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/resource/togglefavourite`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name ChangeResourceId
     * @request POST:/api/resource/resourcereplacement
     * @secure
     * @response `200` `boolean` Success
     */
    changeResourceId = (body: ReplaceResourceDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/resource/resourcereplacement`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name DeactivateUnusedGenericResource
     * @request GET:/api/resource/deactivateunusedgenericresources
     * @secure
     * @response `200` `boolean` Success
     */
    deactivateUnusedGenericResource = (body: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/resource/deactivateunusedgenericresources`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name ForceDeleteGenericResource
     * @request GET:/api/resource/forcedeletegenericresource
     * @secure
     * @response `200` `boolean` Success
     */
    forceDeleteGenericResource = (body: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/resource/forcedeletegenericresource`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name ResetRelatedGenericResource
     * @request GET:/api/resource/resetrelatedgenericresource
     * @secure
     * @response `200` `boolean` Success
     */
    resetRelatedGenericResource = (body: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/resource/resetrelatedgenericresource`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name UpdateCurrentUserLastSignedIn
     * @request GET:/api/resource/updatecurrentresourcelastsignedin
     * @secure
     * @response `200` `boolean` Success
     */
    updateCurrentUserLastSignedIn = (query: UpdateCurrentUserLastSignedInParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/resource/updatecurrentresourcelastsignedin`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name UpdateCurrentResourceUnitType
     * @request POST:/api/resource/updatecurrentresourceunittype
     * @secure
     * @response `200` `boolean` Success
     */
    updateCurrentResourceUnitType = (body: UnitType, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/resource/updatecurrentresourceunittype`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name ResetResourcesMainWorkProfile
     * @request GET:/api/resource/resetresourcesmainworkprofile
     * @secure
     * @response `200` `boolean` Success
     */
    resetResourcesMainWorkProfile = (query: ResetResourcesMainWorkProfileParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/resource/resetresourcesmainworkprofile`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name GetResourcePeriodAllocations
     * @summary Gets the sums of a resource's allocations, splitted in to periods.
     * @request GET:/api/resource/periodwork/allocations
     * @secure
     * @response `200` `(PeriodWork)[]` Success
     * @response `500` `ProblemDetails` Server Error
     */
    getResourcePeriodAllocations = (query: GetResourcePeriodAllocationsParams, params: RequestParams = {}) =>
        this.request<PeriodWork[], ProblemDetails>({
            path: `/api/resource/periodwork/allocations`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name GetResourcePeriodRequests
     * @summary Gets the sums of a resource's requests, splitted in to periods.
     * @request GET:/api/resource/periodwork/requests
     * @secure
     * @response `200` `(PeriodWork)[]` Success
     * @response `500` `ProblemDetails` Server Error
     */
    getResourcePeriodRequests = (query: GetResourcePeriodRequestsParams, params: RequestParams = {}) =>
        this.request<PeriodWork[], ProblemDetails>({
            path: `/api/resource/periodwork/requests`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name GetResourcePeriodCapacity
     * @summary Gets the sums of a resource's capacity, splitted in to periods.
     * @request GET:/api/resource/periodwork/capacity
     * @secure
     * @response `200` `(PeriodCapacity)[]` Success
     * @response `500` `ProblemDetails` Server Error
     */
    getResourcePeriodCapacity = (query: GetResourcePeriodCapacityParams, params: RequestParams = {}) =>
        this.request<PeriodCapacity[], ProblemDetails>({
            path: `/api/resource/periodwork/capacity`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags resource
     * @name GetNamedResourcesByRbs
     * @summary Gets all named resources within the specified rbs
     * @request GET:/api/resource/getnamedbyrbs/{rbs}
     * @secure
     * @response `200` `(Resource)[]` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    getNamedResourcesByRbs = (rbs: string, params: RequestParams = {}) =>
        this.request<Resource[], ProjectumProblemDetails>({
            path: `/api/resource/getnamedbyrbs/${rbs}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags sanitize
     * @name ActivitiesFteWorkSanitizer
     * @request POST:/api/sanitize/contract/activitiesfteworksanitizer
     * @secure
     * @response `200` `boolean` Success
     */
    activitiesFteWorkSanitizer = (body: ActivitiesFTEWorkSanitizerFunction, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/sanitize/contract/activitiesfteworksanitizer`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags sanitize
     * @name ActivitiesScanner
     * @request POST:/api/sanitize/contract/activitiesscanner
     * @secure
     * @response `200` `ActivitiesScannerResultDto` Success
     */
    activitiesScanner = (body: ActivitiesScannerDto, params: RequestParams = {}) =>
        this.request<ActivitiesScannerResultDto, any>({
            path: `/api/sanitize/contract/activitiesscanner`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags sanitize
     * @name ContractOrphanRemover
     * @request POST:/api/sanitize/contract/contractorphanremover
     * @secure
     * @response `200` `Record<string,string>` Success
     */
    contractOrphanRemover = (body: boolean, params: RequestParams = {}) =>
        this.request<Record<string, string>, any>({
            path: `/api/sanitize/contract/contractorphanremover`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags sanitize
     * @name ContractSanitizer
     * @request POST:/api/sanitize/contract/contractsanitizer
     * @secure
     * @response `200` `(ContractSanitizerResultDto)[]` Success
     */
    contractSanitizer = (body: ContractSanitizerDto, params: RequestParams = {}) =>
        this.request<ContractSanitizerResultDto[], any>({
            path: `/api/sanitize/contract/contractsanitizer`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags sanitize
     * @name GroupContractsByNameSanitizer
     * @request POST:/api/sanitize/contract/groupcontractsbynamesanitizer
     * @secure
     * @response `200` `boolean` Success
     */
    groupContractsByNameSanitizer = (body: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/sanitize/contract/groupcontractsbynamesanitizer`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags sanitize
     * @name DuplicateOriginSanitizer
     * @request POST:/api/sanitize/origin/originsanitizer
     * @secure
     * @response `200` `boolean` Success
     */
    duplicateOriginSanitizer = (query: DuplicateOriginSanitizerParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/sanitize/origin/originsanitizer`,
            method: 'POST',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags sanitize
     * @name RemoveUnusedGenericResources
     * @request POST:/api/sanitize/resource/removeunusedgenericresoruces
     * @secure
     * @response `200` `(Resource)[]` Success
     */
    removeUnusedGenericResources = (body: RemoveUnusedGenericResourcesDto, params: RequestParams = {}) =>
        this.request<Resource[], any>({
            path: `/api/sanitize/resource/removeunusedgenericresoruces`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags sanitize
     * @name RemoveUnusedRbsNodes
     * @request POST:/api/sanitize/rbs/removeunusednodes
     * @secure
     * @response `200` `Rbs` Success
     */
    removeUnusedRbsNodes = (body: RemoveUnusedRbsNodesDto, params: RequestParams = {}) =>
        this.request<Rbs, any>({
            path: `/api/sanitize/rbs/removeunusednodes`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags sanitize
     * @name RemoveWorkForResourceOnAllContracts
     * @request POST:/api/sanitize/resource/removeworkonallactivities
     * @secure
     * @response `200` `string` Success
     */
    removeWorkForResourceOnAllContracts = (body: RemoveWorkOnAllActivitiesDto, params: RequestParams = {}) =>
        this.request<string, any>({
            path: `/api/sanitize/resource/removeworkonallactivities`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags sanitize
     * @name ResourceUsertypeSanitizer
     * @request POST:/api/sanitize/resource/usertypesanitizer
     * @secure
     * @response `200` `boolean` Success
     */
    resourceUsertypeSanitizer = (body: UserTypeSanitizerDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/sanitize/resource/usertypesanitizer`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description This could occur from a legacy concurrency issue.
     *
     * @tags sanitize
     * @name CheckForDuplicateContracts
     * @summary Can check for 'null' or duplicate contracts in the cache
     * @request POST:/api/sanitize/contract/checkforduplicates
     * @secure
     * @response `200` `void` Success
     */
    checkForDuplicateContracts = (body: CheckForDuplicateContractsDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/sanitize/contract/checkforduplicates`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description This endpoint is used for springcleaning of contracts. These empty contracts are not visible in the Team Planner UI, hence the need for this endpoint.
     *
     * @tags sanitize
     * @name RemoveEmptyContracts
     * @summary Removes all contracts that don't have requests and allocations, and is not sticky.
     * @request POST:/api/sanitize/contract/removeemptycontracts
     * @secure
     * @response `200` `void` Success
     */
    removeEmptyContracts = (body: boolean, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/sanitize/contract/removeemptycontracts`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description This will remove all requests on other resources. By design a contract can only have requests on one resource, but a legacy bug made this possible in some cases. This endpoint is used to clean up the mess :)
     *
     * @tags sanitize
     * @name SanitizeContractRequests
     * @summary Ensures that the contract(s) only have requests on a single resource.
     * @request POST:/api/sanitize/contract/sanitizerequests
     * @secure
     * @response `200` `void` Success
     */
    sanitizeContractRequests = (body: SanitizeContractRequestsDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/sanitize/contract/sanitizerequests`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags scenario
     * @name ApproveEntireScenario
     * @request POST:/api/scenario/entirescenarioapproval
     * @secure
     * @response `200` `boolean` Success
     */
    approveEntireScenario = (body: EntireScenarioApprovalDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/scenario/entirescenarioapproval`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags scenario
     * @name GetScenarios
     * @request POST:/api/scenario/getscenarios
     * @secure
     * @response `200` `(GetScenarioResult)[]` Success
     */
    getScenarios = (body: GetScenariosDto, params: RequestParams = {}) =>
        this.request<GetScenarioResult[], any>({
            path: `/api/scenario/getscenarios`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags schedules
     * @name CronValidator
     * @request POST:/api/schedules/cronvalidator
     * @secure
     * @response `200` `(Date)[]` Success
     */
    cronValidator = (body: string, params: RequestParams = {}) =>
        this.request<Date[], any>({
            path: `/api/schedules/cronvalidator`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags schedules
     * @name DeleteScheduleJob
     * @request POST:/api/schedules/deleteschedulejob
     * @secure
     * @response `200` `boolean` Success
     */
    deleteScheduleJob = (body: IScheduleJob, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/schedules/deleteschedulejob`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags schedules
     * @name DisableScheduleWebJob
     * @request GET:/api/schedules/disableschedulewebjob
     * @secure
     * @response `200` `boolean` Success
     */
    disableScheduleWebJob = (query: DisableScheduleWebJobParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/schedules/disableschedulewebjob`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags schedules
     * @name EnableScheduleWebJob
     * @request POST:/api/schedules/enableschedulewebjob
     * @secure
     * @response `200` `boolean` Success
     */
    enableScheduleWebJob = (body: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/schedules/enableschedulewebjob`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags schedules
     * @name GetScheduleJob
     * @request POST:/api/schedules/getschedulejob
     * @secure
     * @response `200` `(IScheduleJob)[]` Success
     */
    getScheduleJob = (body: string, params: RequestParams = {}) =>
        this.request<IScheduleJob[], any>({
            path: `/api/schedules/getschedulejob`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags schedules
     * @name ScheduleJobExecutor
     * @request GET:/api/schedules/schedulejobexecutor
     * @secure
     * @response `200` `boolean` Success
     */
    scheduleJobExecutor = (query: ScheduleJobExecutorParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/schedules/schedulejobexecutor`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags schedules
     * @name UpsertScheduleJob
     * @request POST:/api/schedules/upsertschedulejob
     * @secure
     * @response `200` `void` Success
     */
    upsertScheduleJob = (body: IScheduleJob, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/schedules/upsertschedulejob`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusClientInitializeCreate
     * @request POST:/api/servicebus/client/{configurationKey}/initialize/{configurationName}
     * @secure
     * @response `200` `void` Success
     */
    servicebusClientInitializeCreate = (configurationKey: string, configurationName: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/client/${configurationKey}/initialize/${configurationName}`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusClientStartCreate
     * @request POST:/api/servicebus/client/{configurationKey}/start
     * @secure
     * @response `200` `void` Success
     * @response `404` `ProblemDetails` Not Found
     */
    servicebusClientStartCreate = (configurationKey: string, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/servicebus/client/${configurationKey}/start`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusClientTopicUpdate
     * @request PUT:/api/servicebus/client/{configurationKey}/topic
     * @secure
     * @response `200` `void` Success
     * @response `404` `ProblemDetails` Not Found
     */
    servicebusClientTopicUpdate = (
        { configurationKey, ...query }: ServicebusClientTopicUpdateParams,
        body: PFServiceBusTopicOptions,
        params: RequestParams = {},
    ) =>
        this.request<void, ProblemDetails>({
            path: `/api/servicebus/client/${configurationKey}/topic`,
            method: 'PUT',
            query: query,
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusClientEnableCreate
     * @request POST:/api/servicebus/client/{configurationKey}/enable
     * @secure
     * @response `200` `void` Success
     */
    servicebusClientEnableCreate = (configurationKey: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/client/${configurationKey}/enable`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusClientDisableCreate
     * @request POST:/api/servicebus/client/{configurationKey}/disable
     * @secure
     * @response `200` `void` Success
     */
    servicebusClientDisableCreate = (configurationKey: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/client/${configurationKey}/disable`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusClientRulesDetail
     * @request GET:/api/servicebus/client/{configurationKey}/rules
     * @secure
     * @response `200` `(TopicRuleContainer)[]` Success
     * @response `404` `ProblemDetails` Not Found
     */
    servicebusClientRulesDetail = (configurationKey: string, params: RequestParams = {}) =>
        this.request<TopicRuleContainer[], ProblemDetails>({
            path: `/api/servicebus/client/${configurationKey}/rules`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusClientRulesUpdate
     * @request PUT:/api/servicebus/client/{configurationKey}/rules
     * @secure
     * @response `200` `void` Success
     */
    servicebusClientRulesUpdate = (configurationKey: string, body: SubscriptionRuleCreation, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/client/${configurationKey}/rules`,
            method: 'PUT',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusClientRulesDelete
     * @request DELETE:/api/servicebus/client/{configurationKey}/rules
     * @secure
     * @response `200` `(TopicRuleContainer)[]` Success
     */
    servicebusClientRulesDelete = (configurationKey: string, body: SubscriptionRuleDeletion, params: RequestParams = {}) =>
        this.request<TopicRuleContainer[], any>({
            path: `/api/servicebus/client/${configurationKey}/rules`,
            method: 'DELETE',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusClientList
     * @request GET:/api/servicebus/client
     * @secure
     * @response `200` `(PFServiceBusConfiguration)[]` Success
     */
    servicebusClientList = (params: RequestParams = {}) =>
        this.request<PFServiceBusConfiguration[], any>({
            path: `/api/servicebus/client`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusClientUpdate
     * @request PUT:/api/servicebus/client/{configurationName}
     * @secure
     * @response `200` `(PFServiceBusConfiguration)[]` Success
     */
    servicebusClientUpdate = (configurationName: string, body: ServicebusClientUpdatePayload, params: RequestParams = {}) =>
        this.request<PFServiceBusConfiguration[], any>({
            path: `/api/servicebus/client/${configurationName}`,
            method: 'PUT',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusClientDelete
     * @request DELETE:/api/servicebus/client/{configurationKey}
     * @secure
     * @response `200` `void` Success
     */
    servicebusClientDelete = (configurationKey: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/client/${configurationKey}`,
            method: 'DELETE',
            secure: true,
            ...params,
        });
    /**
     * @description Creates a KeyVault secret containing the Service Bus configuration, based on an already configured Service Bus, using the Service Bus name
     *
     * @tags servicebus
     * @name AddSecret
     * @summary Create secret from Service Bus
     * @request POST:/api/servicebus/configuration/addSecret
     * @secure
     * @response `200` `void` Success
     * @response `500` `void` Server Error
     */
    addSecret = (query: AddSecretParams, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/servicebus/configuration/addSecret`,
            method: 'POST',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name CreateNewConfiguration
     * @request POST:/api/servicebus/configuration/createConfig
     * @secure
     * @response `200` `void` Success
     */
    createNewConfiguration = (query: CreateNewConfigurationParams, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/configuration/createConfig`,
            method: 'POST',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusInitializeCreate
     * @request POST:/api/servicebus/initialize
     * @secure
     * @response `200` `void` Success
     */
    servicebusInitializeCreate = (body: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/initialize`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags servicebus
     * @name ServicebusStartCreate
     * @request POST:/api/servicebus/start
     * @secure
     * @response `200` `void` Success
     */
    servicebusStartCreate = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/start`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags settings
     * @name GetAllSubSettings
     * @request GET:/api/settings/getallsubsettings
     * @secure
     * @response `200` `(SubSettings)[]` Success
     */
    getAllSubSettings = (query: GetAllSubSettingsParams, params: RequestParams = {}) =>
        this.request<SubSettings[], any>({
            path: `/api/settings/getallsubsettings`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags settings
     * @name GetRootSettings
     * @request GET:/api/settings/GetRootSetting
     * @secure
     * @response `200` `RootSettings` Success
     */
    getRootSettings = (query: GetRootSettingsParams, params: RequestParams = {}) =>
        this.request<RootSettings, any>({
            path: `/api/settings/GetRootSetting`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags settings
     * @name GetSettingsForResource
     * @request GET:/api/settings/getsettingsforresource
     * @secure
     * @response `200` `RootSettings` Success
     */
    getSettingsForResource = (body: string, params: RequestParams = {}) =>
        this.request<RootSettings, any>({
            path: `/api/settings/getsettingsforresource`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags settings
     * @name GetSettingsForResource2
     * @request POST:/api/settings/getsettingsforresource
     * @originalName getSettingsForResource
     * @duplicate
     * @secure
     * @response `200` `RootSettings` Success
     */
    getSettingsForResource2 = (body: string, params: RequestParams = {}) =>
        this.request<RootSettings, any>({
            path: `/api/settings/getsettingsforresource`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags settings
     * @name UpdateRootSettings
     * @request POST:/api/settings/updaterootsettings
     * @secure
     * @response `200` `boolean` Success
     */
    updateRootSettings = (body: RootSettings, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/settings/updaterootsettings`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description Requests, Allocations and Capacity - This will always keep within the cache constraint and only merge changes into sql
     *
     * @tags sqlexport
     * @name SqlExportResyncAll
     * @summary Resyncs all for SqlExport
     * @request POST:/api/sqlexport/resync/all
     * @secure
     * @response `200` `void` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    sqlExportResyncAll = (body: SqlExportResyncDto, params: RequestParams = {}) =>
        this.request<void, ProjectumProblemDetails>({
            path: `/api/sqlexport/resync/all`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description This will always keep within the cache constraint and only merge changes into sql
     *
     * @tags sqlexport
     * @name SqlExportResyncRequests
     * @summary Resyncs requests for SqlExport
     * @request POST:/api/sqlexport/resync/requests
     * @secure
     * @response `200` `void` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    sqlExportResyncRequests = (body: SqlExportResyncDto, params: RequestParams = {}) =>
        this.request<void, ProjectumProblemDetails>({
            path: `/api/sqlexport/resync/requests`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description This will always keep within the cache constraint and only merge changes into sql
     *
     * @tags sqlexport
     * @name SqlExportResyncAllocations
     * @summary Resyncs allocations for SqlExport
     * @request POST:/api/sqlexport/resync/allocations
     * @secure
     * @response `200` `void` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    sqlExportResyncAllocations = (body: SqlExportResyncDto, params: RequestParams = {}) =>
        this.request<void, ProjectumProblemDetails>({
            path: `/api/sqlexport/resync/allocations`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description This will always keep within the cache constraint and only merge changes into sql
     *
     * @tags sqlexport
     * @name SqlExportResyncCapacity
     * @summary Resyncs capacity for SqlExport
     * @request POST:/api/sqlexport/resync/capacity
     * @secure
     * @response `200` `void` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    sqlExportResyncCapacity = (body: SqlExportResyncDto, params: RequestParams = {}) =>
        this.request<void, ProjectumProblemDetails>({
            path: `/api/sqlexport/resync/capacity`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags storage
     * @name DeleteStorage
     * @request POST:/api/storage/deletestorage
     * @secure
     * @response `200` `boolean` Success
     */
    deleteStorage = (body: SBMessage, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/storage/deletestorage`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags storage
     * @name UpsertStorage
     * @request POST:/api/storage/upsertstorage
     * @secure
     * @response `200` `boolean` Success
     */
    upsertStorage = (body: SBMessage, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/storage/upsertstorage`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags telemetry
     * @name GetTelemetryExcludes
     * @summary Get the excluded telemetry types configured.
     * @request GET:/api/telemetry/filters
     * @secure
     * @response `200` `(string)[]` Success
     * @response `500` `ProblemDetails` Server Error
     */
    getTelemetryExcludes = (params: RequestParams = {}) =>
        this.request<string[], ProblemDetails>({
            path: `/api/telemetry/filters`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description This way you can exclude telemetry types.
     *
     * @tags telemetry
     * @name AddTelemetryExclude
     * @summary Adds the specified telemetry type name to the excluded list.
     * @request PUT:/api/telemetry/filters/{telemetryType}
     * @secure
     * @response `200` `void` Success
     * @response `400` `void` Bad Request
     * @response `500` `ProblemDetails` Server Error
     */
    addTelemetryExclude = (telemetryType: string, params: RequestParams = {}) =>
        this.request<void, void | ProblemDetails>({
            path: `/api/telemetry/filters/${telemetryType}`,
            method: 'PUT',
            secure: true,
            ...params,
        });
    /**
     * @description This way you can exclude telemetry types.
     *
     * @tags telemetry
     * @name RemoveTelemetryExclude
     * @summary Removes the specified telemetry type name to the excluded list.
     * @request DELETE:/api/telemetry/filters/{telemetryType}
     * @secure
     * @response `200` `void` Success
     * @response `400` `void` Bad Request
     * @response `500` `ProblemDetails` Server Error
     */
    removeTelemetryExclude = (telemetryType: string, params: RequestParams = {}) =>
        this.request<void, void | ProblemDetails>({
            path: `/api/telemetry/filters/${telemetryType}`,
            method: 'DELETE',
            secure: true,
            ...params,
        });
    /**
     * @description The default loglevel is used, when nothing else is configured (nothing else is supported yet).
     *
     * @tags telemetry
     * @name UpsertDefaultTelemetryLogLevel
     * @summary Upserts the telemetry filter service with the default loglevel.
     * @request PUT:/api/telemetry/filters/telemetryDefault
     * @secure
     * @response `200` `void` Success
     * @response `400` `void` Bad Request
     * @response `500` `ProblemDetails` Server Error
     */
    upsertDefaultTelemetryLogLevel = (body: UpsertLogLevelDto, params: RequestParams = {}) =>
        this.request<void, void | ProblemDetails>({
            path: `/api/telemetry/filters/telemetryDefault`,
            method: 'PUT',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags test
     * @name Projectowner
     * @request GET:/api/test/projectowner
     * @secure
     * @response `200` `void` Success
     */
    projectowner = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/test/projectowner`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags test
     * @name Linemanager
     * @request GET:/api/test/linemanager
     * @secure
     * @response `200` `void` Success
     */
    linemanager = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/test/linemanager`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags test
     * @name Superuser
     * @request GET:/api/test/superuser
     * @secure
     * @response `200` `void` Success
     */
    superuser = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/test/superuser`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags test
     * @name Administrator
     * @request GET:/api/test/administrator
     * @secure
     * @response `200` `void` Success
     */
    administrator = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/test/administrator`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags test
     * @name NoAdministrators
     * @request GET:/api/test/no-administrators
     * @secure
     * @response `200` `void` Success
     */
    noAdministrators = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/test/no-administrators`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags theme
     * @name GetCssOverrides
     * @request GET:/api/theme/getcssoverrides
     * @secure
     * @response `200` `string` Success
     */
    getCssOverrides = (query: GetCssOverridesParams, params: RequestParams = {}) =>
        this.request<string, any>({
            path: `/api/theme/getcssoverrides`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags versions
     * @name GetContractChangesForResourceHtml
     * @request POST:/api/versions/contractchanges/forresourcehtml
     * @secure
     * @response `200` `string` Success
     */
    getContractChangesForResourceHtml = (body: GetContractChangesForResourceDto, params: RequestParams = {}) =>
        this.request<string, any>({
            path: `/api/versions/contractchanges/forresourcehtml`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags versions
     * @name GetContractChangesForResource
     * @request POST:/api/versions/contractchanges/forresource
     * @secure
     * @response `200` `ContractNotificationDataWrapper` Success
     */
    getContractChangesForResource = (body: GetContractChangesForResourceDto, params: RequestParams = {}) =>
        this.request<ContractNotificationDataWrapper, any>({
            path: `/api/versions/contractchanges/forresource`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags versions
     * @name ResetMailFlagForVersions
     * @request POST:/api/versions/contractchanges/resetmailflag
     * @secure
     * @response `200` `number` Success
     */
    resetMailFlagForVersions = (body: ResetMailFlagForVersionsDto, params: RequestParams = {}) =>
        this.request<number, any>({
            path: `/api/versions/contractchanges/resetmailflag`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags views
     * @name GetForwardRequestView
     * @request POST:/api/views/getforwardrequestview
     * @secure
     * @response `200` `TopRow` Success
     */
    getForwardRequestView = (body: ForwardRequestViewDto, params: RequestParams = {}) =>
        this.request<TopRow, any>({
            path: `/api/views/getforwardrequestview`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags views
     * @name GetPlannerView
     * @request POST:/api/views/getplannerview
     * @secure
     * @response `200` `TopRow` Success
     */
    getPlannerView = (body: GetLMCapacityViewDto, params: RequestParams = {}) =>
        this.request<TopRow, any>({
            path: `/api/views/getplannerview`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags views
     * @name GetNewResourceRow
     * @request POST:/api/views/getnewresourcerow
     * @secure
     * @response `200` `Row` Success
     */
    getNewResourceRow = (body: GetNewCapacityRowDto, params: RequestParams = {}) =>
        this.request<Row, any>({
            path: `/api/views/getnewresourcerow`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags views
     * @name GetProjectCapacityView
     * @request POST:/api/views/getprojectcapacityview
     * @secure
     * @response `200` `TopRow` Success
     */
    getProjectCapacityView = (body: GetProjectGridDto, params: RequestParams = {}) =>
        this.request<TopRow, any>({
            path: `/api/views/getprojectcapacityview`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags views
     * @name GetResourceCapacityView
     * @request POST:/api/views/getresourcecapacityview
     * @secure
     * @response `200` `TopRow` Success
     */
    getResourceCapacityView = (body: GetFreeCapacityViewDto, params: RequestParams = {}) =>
        this.request<TopRow, any>({
            path: `/api/views/getresourcecapacityview`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags views
     * @name GetCompareScenariosView
     * @request POST:/api/views/getcomparescenariosview
     * @secure
     * @response `200` `TopRow` Success
     */
    getCompareScenariosView = (body: GetVersionedProjectGridDto, params: RequestParams = {}) =>
        this.request<TopRow, any>({
            path: `/api/views/getcomparescenariosview`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags views
     * @name GetDraftProjectCapacityView
     * @request POST:/api/views/getdraftprojectcapacityview
     * @secure
     * @response `200` `TopRow` Success
     */
    getDraftProjectCapacityView = (body: GetDraftProjectCapacityViewDto, params: RequestParams = {}) =>
        this.request<TopRow, any>({
            path: `/api/views/getdraftprojectcapacityview`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name CreateWorkPackageComment
     * @request POST:/api/workpackage/createworkpackagecomment
     * @secure
     * @response `200` `Comment` Success
     */
    createWorkPackageComment = (body: UpsertWorkpackageCommentDto, params: RequestParams = {}) =>
        this.request<Comment, any>({
            path: `/api/workpackage/createworkpackagecomment`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name DeleteWorkpackageComment
     * @request POST:/api/workpackage/deleteworkpackagecomment
     * @secure
     * @response `200` `Comment` Success
     */
    deleteWorkpackageComment = (body: UpsertWorkpackageCommentDto, params: RequestParams = {}) =>
        this.request<Comment, any>({
            path: `/api/workpackage/deleteworkpackagecomment`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name GetWorkpackageView
     * @request POST:/api/workpackage/getworkpackageview
     * @secure
     * @response `200` `TopRow` Success
     */
    getWorkpackageView = (body: GetWorkPackageViewDto, params: RequestParams = {}) =>
        this.request<TopRow, any>({
            path: `/api/workpackage/getworkpackageview`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name SaveWorkPackageCell
     * @request POST:/api/workpackage/saveworkpackagecell
     * @secure
     * @response `200` `boolean` Success
     */
    saveWorkPackageCell = (body: SaveWorkPackageCellDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/workpackage/saveworkpackagecell`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name SaveWorkpackageCells
     * @request POST:/api/workpackage/saveworkpackagecells
     * @secure
     * @response `200` `boolean` Success
     */
    saveWorkpackageCells = (body: SaveWorkpackageCellsPayload, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/workpackage/saveworkpackagecells`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name RemoveWorkpackageActivities
     * @request POST:/api/workpackage/removeworkpackageactivities
     * @secure
     * @response `200` `boolean` Success
     */
    removeWorkpackageActivities = (body: RemoveWorkPackageActivityDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/workpackage/removeworkpackageactivities`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name GetWorkpackageContractComments
     * @request POST:/api/workpackage/getworkpackagecontractscomments
     * @secure
     * @response `200` `Record<string,(Comment)[]>` Success
     */
    getWorkpackageContractComments = (body: string, params: RequestParams = {}) =>
        this.request<Record<string, Comment[]>, any>({
            path: `/api/workpackage/getworkpackagecontractscomments`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name CreateWorkpackage
     * @request POST:/api/workpackage/createworkpackage
     * @secure
     * @response `200` `boolean` Success
     */
    createWorkpackage = (body: WorkPackageDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/workpackage/createworkpackage`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name DeleteWorkpackage
     * @request POST:/api/workpackage/deleteworkpackage
     * @secure
     * @response `200` `boolean` Success
     */
    deleteWorkpackage = (body: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/workpackage/deleteworkpackage`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name EditWorkpackageName
     * @request POST:/api/workpackage/editworkpackagename
     * @secure
     * @response `200` `boolean` Success
     */
    editWorkpackageName = (body: WorkPackageDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/workpackage/editworkpackagename`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name GetAllWorkpackagesFlat
     * @request GET:/api/workpackage/getallworkpackagesflat
     * @secure
     * @response `200` `(WorkPackageSubDto)[]` Success
     */
    getAllWorkpackagesFlat = (query: GetAllWorkpackagesFlatParams, params: RequestParams = {}) =>
        this.request<WorkPackageSubDto[], any>({
            path: `/api/workpackage/getallworkpackagesflat`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name GetAllWorkpackagesTree
     * @request POST:/api/workpackage/getallworkpackagestree
     * @secure
     * @response `200` `(WorkPackageTreeItem)[]` Success
     */
    getAllWorkpackagesTree = (body: WorkPackageSubDto, params: RequestParams = {}) =>
        this.request<WorkPackageTreeItem[], any>({
            path: `/api/workpackage/getallworkpackagestree`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name ImportWorkpackage
     * @request POST:/api/workpackage/importworkpackages
     * @secure
     * @response `200` `boolean` Success
     */
    importWorkpackage = (body: ImportWorkPackageDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/workpackage/importworkpackages`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name UpdateWorkpackageContractGroupName
     * @request POST:/api/workpackage/updateworkpackagecontractgroupname
     * @secure
     * @response `200` `boolean` Success
     */
    updateWorkpackageContractGroupName = (body: UpdateWorkPackageContractDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/workpackage/updateworkpackagecontractgroupname`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name UpdateWorkpackageContractName
     * @request POST:/api/workpackage/updateworkpackagecontractname
     * @secure
     * @response `200` `boolean` Success
     */
    updateWorkpackageContractName = (body: UpdateWorkPackageContractDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/workpackage/updateworkpackagecontractname`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workpackage
     * @name UpdateWorkpackageSubs
     * @request POST:/api/workpackage/updateworkpackagesubs
     * @secure
     * @response `200` `boolean` Success
     */
    updateWorkpackageSubs = (body: WorkPackageSubDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/workpackage/updateworkpackagesubs`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags workprofile
     * @name UpdateBaseCapacityForWorkProfiles
     * @request POST:/api/workprofile/updatebasecapacityworkprofiles
     * @secure
     * @response `200` `string` Success
     */
    updateBaseCapacityForWorkProfiles = (body: string, params: RequestParams = {}) =>
        this.request<string, any>({
            path: `/api/workprofile/updatebasecapacityworkprofiles`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
