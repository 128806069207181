import { PropertyType } from "../../../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyBasicInfo } from "../../../../Utilities/PropertyInfo/BasicInfo";
import { ResourceType } from "../../../ResourceType";
import { DropdownOptionEx } from "../../../../Utilities/DropdownOptionEx";
import { PFPropertyGrouping } from "../../../../Utilities/PropertyInfo/Grouping";
import { PFPropertyRender } from "../../../../Utilities/PropertyInfo/Render";
import { IObjectEditorItemType } from "../../../Main/IObjectEditorItemType";

export class LookupResource implements IObjectEditorItemType {
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:LookupResource.IncludeInactiveResources", 1)
	public IncludeInactive: boolean;
	@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:LookupResource.ResourceTypes", 6)
	@PFPropertyGrouping("Basic")
	@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<ResourceType>(ResourceType) })
	ResourceTypes: Array<ResourceType>;
	// @PFPropertyBasicInfo(PropertyType.Boolean, "OnlyFromOwnRbs", 6)
	// public OnlyFromOwnRbs: boolean;

	constructor() {

	}
}