import { language } from '../../Services/LocalizationService';
import { MessageBarType } from '@fluentui/react';
import { DropdownOptionEx } from '../../Utilities/DropdownOptionEx';
import { Permission } from '../../Services/Permission';
import { UserContext } from '../../Utilities/Context/UserContext';
import { DateEx } from '../../Utilities/DateEx';
import { settings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { EntityType, Resource, ResourceType, UserType } from '../../api/generated/data-contracts';
import { fieldOptionsFactory, IFormSchema } from 'spark-forms';
import { systemFieldsGrouped, tpFieldOptionsFactory } from '../_builders/new/tpFieldOptionsFactory';
import { SettingsSelector } from '../_builders/new/components/SettingsSelector';
import { OriginRender } from '../_builders/new/components/OriginRender';
import { WorkProfileSelector } from '../_builders/new/components/WorkProfileSelector';
import { OriginIdRender } from '../_builders/new/components/OriginIdRender';
import { ResourceSpecificWorkprofileEditor } from '../../views/overviews/resource/components/ResourceSpecificWorkprofileEditor';
import { EntityFieldFilterEditor } from '../_builders/new/components/EntityFieldFilterEditor';
import { StringEx } from '../../Utilities/StringEx';
import EnumEx from '../../Utilities/EnumEx';
import { getcustomPropertiesSchema } from '../../components_new/customPropertiesSchema';
import { validateCustomProperties } from '../customProperty/helpers/customPropertyUtil';
import { originHelper } from '../_builders/new/helpers/origins/originHelper'

export const resourceSchema: IFormSchema<Resource> = {
    groups: {
        basic: { displayName: 'Basic', groupProps: { icon: 'EntitlementPolicy', selected: true } },
		capacity: { displayName: 'Capacity', groupProps: { icon: 'AlignVerticalBottom' } },
        permission: { displayName: 'Permission', groupProps: { icon: 'Permissions' } },
        rbs: { displayName: 'RBS', groupProps: { icon: 'BulletedTreeList' } },
        customProperties: { displayName: 'Custom properties', groupProps: { icon: 'DataManagementSettings' } },
        resourcePool: { displayName: 'Resource pool', groupProps: { icon: 'UserEvent' } },
        specificWorkProfile: { displayName: 'Specific work profile', groupProps: { icon: 'CalendarSettings' } },
        system: { displayName: 'System', groupProps: { icon: 'Settings' } },
    },
    fields: {
        active: tpFieldOptionsFactory.messageBar({
            displayName: 'Active',
            group: 'basic',
            description: language.Resource.ActiveDescription,
            config: {
                options: (item: Resource) => {
                    return {
                        message: item.active ? language.Resource.ActiveState : language.Resource.InActiveState,
                        type: item.active ? MessageBarType.success : MessageBarType.warning,
                        width: '100%',
                    };
                },
            },
            hideLabel: true,
            validation: {
                validationMark: 'required',
                validationRule: async item => (StringEx.isNullOrWhiteSpace(item.name) ? 'The resource must have a name.' : null),
            },
        }),
        name: fieldOptionsFactory.string({ displayName: language.Common.Name, group: 'basic' }),
        email: fieldOptionsFactory.string({ displayName: language.Common.Email, group: 'basic', hide: item => item.resourceType !== ResourceType.Named }), // check render
        resourceType: fieldOptionsFactory.predefinedArray({
            displayName: language.Resource.ResourceType,
            group: 'basic',
            config: { predefinedOptions: { options: DropdownOptionEx.enumToDropdownOptions<ResourceType>(ResourceType) } },
            validation: {
                validationMark: 'required',
                validationRule: async item => (item.resourceType == null ? language.Resource.ResourceTypeIsRequired : null),
            },
        }),
        relatedGenericResourceId: tpFieldOptionsFactory.resource({
            displayName: language.Common.GenericResource,
            group: 'basic',
            description: language.Resource.RelatedToGenericResource,
            disable: () => true,
            hide: item => item.resourceType !== ResourceType.Named,
            config: { nameField: 'name', descriptionField: 'rbs', resourceTypes: [ResourceType.Generic] },
        }),
        workProfileId: fieldOptionsFactory.customComponent({
            displayName: language.Resource.MainWorkProfile,
            group: 'basic',
            hide: item => item.resourceType !== ResourceType.Named && item.resourceType !== ResourceType.Generic,
            config: { component: WorkProfileSelector },
        }),
        earliestAvailable: fieldOptionsFactory.date({
            displayName: language.Resource.EarliestAvailable,
            group: 'basic',
            config: { displayFormat: (date?: Date) => DateEx.asUserLocal(date) },
            hide: item => item.resourceType !== ResourceType.Named && item.resourceType !== ResourceType.WorkProfile,
        }),
        latestAvailable: fieldOptionsFactory.date({
            displayName: language.Resource.LatestAvailable,
            group: 'basic',
            config: { displayFormat: (date?: Date) => DateEx.asUserLocal(date) },
            hide: item => item.resourceType !== ResourceType.Named && item.resourceType !== ResourceType.WorkProfile,
        }),

        manager: tpFieldOptionsFactory.resource({
            displayName: language.Resource.Manager,
            group: 'basic',
            config: { nameField: 'name', descriptionField: 'email', resourceTypes: [ResourceType.Named] },
            hide: (item: Resource) => item.resourceType !== ResourceType.Named,
        }),

		workingPercent: fieldOptionsFactory.number({
            displayName: language.Resource.WorkingPercent,
            group: 'capacity',
			description: language.Resource.WorkingPercentDescription,
            hide: (item: Resource) => item.resourceType !== ResourceType.Named || !settings().enableResourceWorkingPercentAndUtilization,
			validation: {
                validationRule: async item =>
				item.workingPercent >= 0 && item.workingPercent <= 1 ? null : language.Resource.WorkingPercentValidation,
            },
        }),
		utilization: fieldOptionsFactory.number({
            displayName: language.Resource.Utilization,
            group: 'capacity',
			description: language.Resource.UtilizationDescription,
            hide: (item: Resource) => item.resourceType !== ResourceType.Named || !settings().enableResourceWorkingPercentAndUtilization,
			validation: {
                validationRule: async item =>
				item.utilization >= 0 && item.utilization <= 1 ? null : language.Resource.UtilizationValidation,
            },
        }),

        userTypes: fieldOptionsFactory.predefinedArray({
            displayName: language.Resource.UserTypes,
            group: 'permission',
            hide: item => item.resourceType !== ResourceType.Named,
            disable: () => settings().enableADGroupSecurity || settings().permissions?.entraGroup?.enabled || !Permission.HasSomePermissions(UserType.SuperUser),
            config: {
                predefinedOptions: { options: DropdownOptionEx.enumToDropdownOptionsWithoutZeroOption<UserType>(UserType) },
                multiSelect: true,
            },
        }),
        userPrincipalName: fieldOptionsFactory.string({
            displayName: language.Resource.UserPrincipalName,
            group: 'permission',
            hide: item => item.resourceType !== ResourceType.Named,
            disable: item => !originHelper.resourceIsAppOriginAndUnderCreation(item, true),
        }),
        rbs: tpFieldOptionsFactory.rbs({
            displayName: language.Common.Rbs,
            group: 'rbs',
            description: language.Resource.RbsDescription,
            hide: item => item.resourceType === ResourceType.Pool || item.resourceType === ResourceType.WorkProfile,
            disable: item =>
                (item.resourceType === ResourceType.Generic && !!item.created && !settings().allowChangeRbsAndCustomPropertiesOnGenericResource) ||
                (item.id === UserContext.Resource.id && !Permission.IsSuperuser()),
            config: { mode: 'select' },
            validation: {
                validationMark: 'required',
                validationRule: async item =>
                    EnumEx.equalsAny(item.resourceType, ...[ResourceType.Generic, ResourceType.Named]) && StringEx.isNullOrWhiteSpace(item.rbs)
                        ? language.Resource.RbsValidation
                        : null,
            },
        }),
        additionalRBS: tpFieldOptionsFactory.rbs({
            displayName: language.Common.AdditionalRbs,
            group: 'rbs',
            hide: item => item.resourceType !== ResourceType.Named && item.resourceType !== ResourceType.Team,
            disable: item => (item.resourceType === ResourceType.Generic && !!item.created) || (item.id === UserContext.Resource.id && !Permission.IsSuperuser()),
            config: { mode: 'multiSelect' },
        }),
        customProperties: fieldOptionsFactory.formItem({
            displayName: language.Common.CustomPropertiesCapitalized,
            group: 'customProperties',
            description: 'Custom properties',
            config: {
                schema: {
                    schemaProvider: () => getcustomPropertiesSchema('resource'),
                },
            },
            hide: (resource: Resource) =>
                resource.resourceType === ResourceType.Category ||
                resource.resourceType === ResourceType.Team ||
                (resource.resourceType === ResourceType.Pool && settings().enableDynamicResourcePools),
			validation: {
				validationMark: 'none',
				validationRule: item => validateCustomProperties(item, 'resource')
			}
        }),
        matcher: fieldOptionsFactory.customComponent({
            displayName: language.Resource.ResourcePoolMatcher,
            group: 'resourcePool',
            description: language.Resource.ResourcePoolMatcherDescription,
            hide: item => item.resourceType !== ResourceType.Pool || !settings().enableDynamicResourcePools,
            config: {
                component: EntityFieldFilterEditor,
                customProperties: () => settings().resourceCustomProperties,
                entityType: EntityType.Resource,
                schema: () => resourceSchema,
            },
        }),
        excludeFromPools: fieldOptionsFactory.boolean({
            displayName: language.Resource.ExcludedFromPools,
            group: 'resourcePool',
            hide: item => !settings().enableResourcePools || item.resourceType !== ResourceType.Named,
        }),
        enableSpecificWorkprofile: fieldOptionsFactory.boolean({
            displayName: language.Resource.EnableSpecificWorkProfile,
            group: 'specificWorkProfile',
            hide: item => item.resourceType !== ResourceType.Named,
            description: language.Resource.EnableSpecificWorkProfileDescription,
        }),
        specificWorkprofileId: fieldOptionsFactory.customComponent({
            displayName: language.Resource.SpecificWorkProfile,
            group: 'specificWorkProfile',
            hide: item => !item.enableSpecificWorkprofile || item.resourceType !== ResourceType.Named,
            config: { component: ResourceSpecificWorkprofileEditor },
        }),
        azureId: fieldOptionsFactory.string({ displayName: language.Common.AzureId, group: 'system' }),
        originIds: fieldOptionsFactory.customComponent({
            displayName: language.Common.OriginIds,
            group: 'system',
            disable: () => true,
            config: { component: OriginIdRender },
        }),
        origins: fieldOptionsFactory.customComponent({
            displayName: language.Common.Origins,
            group: 'system',
            disable: () => true,
            config: { component: OriginRender },
        }),
        teamProject: fieldOptionsFactory.string({
            displayName: language.Resource.TeamProject,
            group: 'system',
            disable: () => true,
            hide: item => item.resourceType !== ResourceType.Team,
        }),
        settings: fieldOptionsFactory.customComponent({ displayName: language.Common.Settings, group: 'system', config: { component: SettingsSelector } }),

        lastSignin: fieldOptionsFactory.date({
            displayName: language.Resource.LastSignIn,
            group: 'basic',
            config: { displayFormat: (date?: Date) => DateEx.asUserLocal(date) },
            hide: item => item.resourceType !== ResourceType.Named && item.resourceType !== ResourceType.WorkProfile,
        }),

        ...systemFieldsGrouped(),
    },
};