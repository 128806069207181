import { useGridContext } from '../../../../../../../_grid/contexts/gridContext';
import { useHeatmapBackground } from '../../../../hooks/useHeatmapBackground';
import { useUISettings } from '../../../../../../../../../context/network/http/QueryProvider/queries/UISettings';
import { GridCell, GridTotalColumnRow } from '../../../../../../../../../api/generated/data-contracts';
import { useFixedCellValue } from '../../../../../../../_grid/hooks/useFixedCellValue';
import { useRowType } from '../../../../hooks/useRowType';
import { Icon, TooltipHost } from '@fluentui/react';
import { memo, useEffect, useMemo, useState } from 'react';
import { useGridRowContext } from '../../../../../../../_grid/contexts/rowContext';
import { useGetCellValue } from '../../../../../../../_grid/useGrid';
import { isZero } from '../../../../../../../../../helpers/math';

export const ColoredTotalCell = memo(function ColoredTotalCell({
    cell,
    isRowExpanded,
    columnIndex,
	totalColumnRow
}: {
    cell: GridCell;
    isRowExpanded: boolean;
    columnIndex: number;
	totalColumnRow: GridTotalColumnRow;
}) {
	const { totalRequests } = totalColumnRow?.metadata || {};
    const {
        data: {
            settings: {
                projectPlannerSettings: {
                    gridSettings: {
                        //
                        heatMapTextColor,
                        heatMapClassicColors,
                        heatMapColorOffset,
                        heatMapColorSaturation,
                        heatMapInvertSpectrum,
                        heatMapLightness,
                        heatMapOverAllocation,
                        heatMapUnderAllocation,
                        equalAllocationColor,
                        overAllocationColor,
                        underAllocationColor,
                        noActivityColor,
                    },
                },
            },
        },
    } = useUISettings();
    const { isAdminProject } = useRowType();
    const { useGridCellValue } = useGridContext();
    const { subRows } = useGridRowContext();
    const stateValue = useGridCellValue(cell);
    const numberValue = useMemo(() => stateValue.toNumber(), [stateValue]);
    const getCellValue = useGetCellValue();

    const [noActivityColorState, setNoActivityColorState] = useState<string | undefined>();

    useEffect(() => {
        let mounted = true;
        if (numberValue === 0) {
            const columnRows = subRows.map(row => row.columnRows[columnIndex]);
            const run = async () => {
                let isSubrowZero = true;
                for (const columnRow of columnRows) {
                    const cells = columnRow.cells.slice(0, 2).filter(Boolean);
                    for (const cell of cells) {
                        const value = await getCellValue(cell);
                        if (!isZero(value)) {
                            isSubrowZero = false;
                            break;
                        }
                    }
                }
                if (mounted) {
                    if (isSubrowZero) {
                        setNoActivityColorState(noActivityColor);
                    } else {
                        setNoActivityColorState(undefined);
                    }
                }
            };
            run().catch(err => {
                console.error(err);
            });
        }

        return () => {
            mounted = false;
        };
    }, [columnIndex, getCellValue, numberValue, subRows, noActivityColor]);

    const backgroundColor = useHeatmapBackground({
        value: numberValue,
        maxOverAllocationPercent: heatMapOverAllocation,
        maxUnderAllocationPercent: heatMapUnderAllocation,
        colorSaturation: heatMapColorSaturation,
        lightness: heatMapLightness,
        colorOffset: heatMapColorOffset,
        invertSpectrum: heatMapInvertSpectrum,
        classicColors: heatMapClassicColors,
        equalAllocationColor,
        overAllocationColor,
        underAllocationColor,
        noActivityColor: noActivityColorState,
		// totalCapacity
		// totalAllocation,
		totalRequest: totalRequests
    });

    const value = useFixedCellValue(stateValue);

    return (
        <div
            style={{
                //
                backgroundColor: isAdminProject ? 'white' : backgroundColor,
                height: '100%',
                width: '100%',
                ...(isRowExpanded && {
                    width: 80,
                    height: 32,
                }),
                transition: '.3s ease',
                position: 'relative',
                cursor: 'pointer',
            }}
        >
            <span
                style={{
                    position: 'absolute',
                    top: '50%',
                    ...(isRowExpanded
                        ? {
                              transform: 'translate(-100%, -50%)',
                              left: 'calc(100% - 8px)',
                          }
                        : {
                              transform: 'translate(-50%, -50%)',
                              left: '50%',
                          }),
                    transition: '.3s',
                    color: heatMapTextColor,
                    gap: 5,
                    display: 'flex',
                }}
            >
                {isAdminProject && (
                    <TooltipHost
                        styles={{
                            root: {
                                //
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'left',
                                cursor: 'help',
                            },
                        }}
                        content={<>Administrative Project: KPI inapplicable as no requests are permitted. Consider creating a view without admin projects</>}
                    >
                        <Icon iconName="Info" styles={{ root: { fontSize: 18, display: 'block' } }} />
                    </TooltipHost>
                )}
                {value}
            </span>
        </div>
    );
});
