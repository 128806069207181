import { ProjectType } from '../../../../../../Entities/ProjectType';
import { useMemo } from 'react';
import { useGridRowContext } from '../../../../_grid/contexts/rowContext';

export const useRowType = () => {
    const { subRows, rowRelations, row } = useGridRowContext();
    const isProject = row.metadata?.ProjectType !== undefined;
    const isAdminProject = useMemo(() => {
        if (rowRelations.some(row => row.metadata?.ProjectType === ProjectType.Administrative)) {
            return true;
        }
        if (subRows.some(row => row.metadata?.ProjectType === ProjectType.Administrative)) {
            return true;
        }
        if (!isProject) {
            return false;
        }
        if (row.metadata?.ProjectType === ProjectType.Administrative) {
            return true;
        }
        return false;
    }, [isProject, row.metadata?.ProjectType, rowRelations, subRows]);
    const isResource = row.metadata?.ResourceType !== undefined;
    return {
        isProject,
        isAdminProject,
        isResource,
    };
};
