/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useMemo, useLayoutEffect, useEffect } from 'react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Icon } from '@fluentui/react/lib/Icon';
import { Text } from '@fluentui/react/lib/Text';
import { Stack } from '@fluentui/react/lib/Stack';
import { Slider } from '@fluentui/react/lib/Slider';
import { Callout } from '@fluentui/react/lib/Callout';
import { IconButton } from '@fluentui/react/lib/Button';
import { HeaderUtil } from '../../../../Utilities/HeaderUtil';
import { SubHeaders } from './SubHeaders';
import { HeaderRange } from './HeaderRange';
import { HeaderName } from './HeaderName';
import { HeaderRow } from '../../../../Entities/Table/HeaderRow';
import { EventEx } from '../../../../Utilities/EventEx';
import { TableContext } from '../Table';
import { TableStateManager } from '../../../../Services/TableStateManager';
import { useTheme } from '../../../../Themes/themeContext';
import { GridSettings } from '../../../../Utilities/GridSettings';
import { language } from '../../../../Services/LocalizationService';
import { ContextualMenuItemType } from '@fluentui/react';
import { ColumnType } from '../../../../Entities/Table/ColumnType';
import { CustomColumnUtil } from '../../../../Utilities/Grid/CustomColumnUtil';
import { ETooltipContentType, PFTooltipHost } from '../../../Common/PFTooltipHost';
import { Timeout } from '../../../../types/runtimeTypes';

let startX = 0;
let startSize = 0;

const onMouseDown = e => {
    startX = e.clientX;
    startSize = parseInt(document.documentElement.style.getPropertyValue('--gridRowWidth') || '300', 10);
    document.documentElement.addEventListener('mousemove', doDrag, false);
    document.documentElement.addEventListener('mouseup', stopDrag, false);
};

const stopDrag = () => {
    document.documentElement.removeEventListener('mousemove', doDrag, false);
    document.documentElement.removeEventListener('mouseup', stopDrag, false);
};

let _timer: Timeout;
const doDrag = e => {
    const timer = EventEx.delay(
        _timer,
        () => {
            const newX = startSize + e.clientX - startX;
            if (newX < 250) return; // force min width of rows
            document.documentElement.style.setProperty('--gridRowWidth', newX + 'px');
        },
        1,
    );
    _timer = timer;
};
interface IProps {
    headers: Array<HeaderRow>;
    pageTitle: string;
    ExpandCallback: () => void;
    id?: string;
}

export const Header: React.FC<IProps> = props => {
    const tableCtx = useContext(TableContext) as TableStateManager;
    const ExtraItems = tableCtx.Get(_ => _.GridSettingsExtraMenuItems) ?? [];

    const [titleContentOverride, setTitleContentOverride] = useState<JSX.Element>();

    useEffect(() => {
        const override = tableCtx.Get(_ => _.TitleContentOverride) != null ? tableCtx.Get(_ => _.TitleContentOverride)(props.pageTitle) : null;

        setTitleContentOverride(override);
    }, [tableCtx.Get(_ => _.TitleContentOverride)]);

    const gridSettings = [];
    if (tableCtx.Get(_ => _.EnableGridSettings)) {
        gridSettings.push({
            key: 'gridSettings',
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                bottomDivider: true,
                title: language.GridSettings.GridSettings,
                items: [
                    {
                        key: 'showPersona',
                        onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => (
                            <RenderPersonaToggle item={item} dismissMenu={dismissMenu} />
                        ),
                    },
                    {
                        key: 'spacingAmount',
                        onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => (
                            <RenderSpacingAmount item={item} dismissMenu={dismissMenu} />
                        ),
                    },
                    {
                        key: 'expandCollapse',
                        onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => (
                            <RenderExpandCollapseToggle item={item} collapseCallback={props.ExpandCallback} />
                        ),
                    },
                ],
            },
        });
    }

    const menuProps: any = useMemo(
        () => ({
            items: [...gridSettings, ...ExtraItems],
            // fix for hide the section icon
            styles: { root: { '.ms-ContextualMenu-header .ms-ContextualMenu-linkContent i': { display: 'none' } } },
            calloutProps: { calloutMinWidth: 200 },
        }),
        [ExtraItems],
    );

    return (
        <tr>
            <th>
                <div className="tp-capacity-project">
                    {titleContentOverride ?? (
                        <Text className="tp-capacity-project-title">
                            <Stack
                                verticalAlign="center"
                                styles={{ root: { position: 'relative' } }}
                                id={`persona-resource-${props.id}`}
                            >
								<PFTooltipHost key={`tooltiphost-${props.pageTitle}`} contentType={ETooltipContentType.List} content={[{displayName: 'id', displayText: props.id}]} listItemFlexDirection={'row'} enableCopyToClipboard>
                                	<div className="truncate-text">{props.pageTitle}</div>
								</PFTooltipHost>
                            </Stack>
                        </Text>
                    )}
                    <Stack styles={{ root: { height: '100%', padding: '2px 4px' } }}>
                        <Stack.Item styles={{ root: { flexGrow: 1, flexBasis: '50%', position: 'relative' } }}>
                            <div className="tp-grid-rowresize" onMouseDown={onMouseDown}>
                                <TooltipHost content={'Resize resource column'} styles={{ root: { height: '18px' } }}>
                                    <Icon iconName="ImportMirrored" className="tp-grid-rowresize-icon noselect" />
                                </TooltipHost>
                            </div>
                        </Stack.Item>
                        <Stack verticalAlign="end" styles={{ root: { flexGrow: 1, flexBasis: '50%' } }}>
                            {menuProps.items.length > 0 && (
                                <IconButton menuIconProps={{ iconName: 'Settings' }} menuProps={menuProps} styles={{ menuIcon: { fontSize: 16 } }} />
                            )}
                        </Stack>
                    </Stack>
                </div>
            </th>
            {CustomColumnUtil.GenerateCustomColumnsHeader(tableCtx)}
            {props.headers.map((_, idx) => {
                const ColumnHeaderExtraContent = tableCtx.Get(_ => _.ColumnHeaderExtraContent)?.(_);
                const containerStyle = ColumnHeaderExtraContent ? { zIndex: 31 } : {};

                return (
                    <th key={`headers-${idx}`} className={_.Type === ColumnType.Normal ? null : 'tp-table-header-total'} style={containerStyle}>
                        <div className={HeaderUtil.HeaderStyle(_)}>
                            <HeaderRange header={_} />
                            <HeaderName header={_} />
                            <SubHeaders header={_} />
                            {ColumnHeaderExtraContent}
                        </div>
                    </th>
                );
            })}
        </tr>
    );
};

// eslint-disable-next-line react-refresh/only-export-components
export const buttonRootStyles = {
    display: 'flex',
    alignItems: 'center',
    height: 36,
    width: '100%',
    padding: '0 4px',
    cursor: 'pointer',
    userSelect: 'none',
};

const RenderPersonaToggle: React.FC<{ item: any; dismissMenu: any }> = props => {
    const theme = useTheme();
    const tableCtx = useContext(TableContext) as TableStateManager;
    const [hidePersonas, setHidePersonas] = useState<boolean>();

    useLayoutEffect(() => {
        setHidePersonas(tableCtx.Get(_ => _.GridSettingsHidePersonaIcons));
    }, []);

    return (
        <Text
            onClick={() => {
                tableCtx.Get(_ => _.SetGridSettingsHidePersonaIconsCallback)(!hidePersonas);
                tableCtx.Set({ GridSettingsHidePersonaIcons: !hidePersonas });
                setHidePersonas(s => !s);
            }}
            styles={{
                root: {
                    ...buttonRootStyles,
                    '&:hover': {
                        backgroundColor: theme.semanticColors.buttonBackgroundHovered,
                    },
                },
            }}
        >
            <Icon
                iconName="ContactInfo"
                styles={{
                    root: {
                        paddingTop: 2,
                        marginRight: 8,
                        marginLeft: 4,
                        color: theme.semanticColors.menuIcon,
                        fontSize: 16,
                    },
                }}
            />
            {hidePersonas ? language.GridSettings.ShowPersona : language.GridSettings.HidePersona}
        </Text>
    );
};

const RenderSpacingAmount: React.FC<{ item: any; dismissMenu: any }> = props => {
    const theme = useTheme();
    const tableCtx = useContext(TableContext) as TableStateManager;
    const [showCallout, setShowCallout] = useState(false);

    return (
        <>
            <Text
                id="whitespace_amount_button"
                styles={{
                    root: {
                        ...buttonRootStyles,
                        '&:hover': {
                            backgroundColor: theme.semanticColors.buttonBackgroundHovered,
                        },
                    },
                }}
                onClick={() => setShowCallout(s => !s)}
            >
                <Text
                    styles={{
                        root: {
                            width: 16,
                            textAlign: 'center',
                            marginRight: 8,
                            marginLeft: 4,
                            color: theme.semanticColors.menuIcon,
                            fontSize: 14,
                            fontWeight: 600,
                        },
                    }}
                >
                    {tableCtx.Get(_ => _.GridSettingsSpacingAmount) ?? 1}
                </Text>
                {language.GridSettings.WhitespaceAmount}
            </Text>
            {showCallout && (
                <Callout gapSpace={0} target={`#whitespace_amount_button`} setInitialFocus isBeakVisible={false}>
                    <Slider
                        min={0}
                        max={2}
                        step={0.1}
                        defaultValue={tableCtx.Get(_ => _.GridSettingsSpacingAmount) ?? 1}
                        showValue
                        snapToStep
                        onChange={(newValue: number) => GridSettings.SetSpacingAmount(newValue)}
                        onChanged={(e: MouseEvent | TouchEvent | KeyboardEvent, newValue: number) => {
                            tableCtx.Get(_ => _.SetGridSettingsSpacingAmountCallback)(newValue);
                            tableCtx.Set({ GridSettingsSpacingAmount: newValue });
                            setShowCallout(false);
                        }}
                        styles={{
                            root: { width: '100%', minWidth: 200, minHeight: 36, display: 'flex', alignItems: 'center' },
                            container: { flexGrow: 1 },
                            valueLabel: { width: 20 },
                        }}
                    />
                </Callout>
            )}
        </>
    );
};

const RenderExpandCollapseToggle: React.FC<{ item: any; collapseCallback: () => void }> = props => {
    const theme = useTheme();
    const tableCtx = useContext(TableContext) as TableStateManager;
    const [collapsed, setCollapsed] = useState<boolean>();

    useLayoutEffect(() => {
        setCollapsed(tableCtx.Get(_ => _.AllCollapsed));
    }, []);

    return (
        <Text
            onClick={() => {
                props.collapseCallback();
                setCollapsed(s => !s);
            }}
            styles={{
                root: {
                    ...buttonRootStyles,
                    '&:hover': {
                        backgroundColor: theme.semanticColors.buttonBackgroundHovered,
                    },
                },
            }}
        >
            <Icon
                iconName={`${collapsed ? 'ChevronDown' : 'ChevronUp'}`}
                styles={{
                    root: {
                        paddingTop: 2,
                        marginRight: 8,
                        marginLeft: 4,
                        color: theme.semanticColors.menuIcon,
                        fontSize: 16,
                    },
                }}
            />
            {collapsed ? language.GridSettings.ExpandAll : language.GridSettings.CollapseAll}
        </Text>
    );
};
