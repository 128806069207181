import { Dropdown, ICalloutProps, IDropdownOption, IDropdownProps, IRenderFunction, ISelectableOption, Stack, TextField } from '@fluentui/react';
import { disableAllAutoComplete, disableAllSpellchecks, killEventProps, mergeEventHandlers } from '../../helpers/killEvent';
import { FilterDropdownMultiSelectContextProvider, useFilterDropdown } from './FilterDropdownMultiSelectContext';
import { useCallback, useState, useMemo, useLayoutEffect, useRef } from 'react';

//TODO: merge this with the existing singleselect one!! (ewi)

const defaultFilterKey = '@@filter';
const defaultFilterText = 'Filter';

export type FilterDropdownMultiSelectProps<T = any> = {
    options: IDropdownOption<T>[];
    filterFn?: (option: IDropdownOption<T>, filterText: string) => boolean;
    filterKey?: string;
    filterText?: string;
	multiSelect?: boolean;
	// These are just for better type inference
	onChange?: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<T>, index?: number) => void;
	onRenderOption?: IRenderFunction<ISelectableOption<T>>
} & Omit<IDropdownProps, 'options' | 'onChange' | 'onRenderOption'>;

/**
 * A @fluentui/react dropdown that is filterable. It takes the same props, and has the same (but extended)
 * functionality as the Dropdown component from @fluentui/react V8
 * @remarks
 * [Try it here](https://pumspark.z6.web.core.windows.net/stories?story=fluent8--filter-dropdown)
 */
export const FilterDropdownMultiSelect = <T,>({
    options,
    filterFn,
    filterKey: filterKeyProps,
    filterText: filterTextProps,
	multiSelect,
    onChange: onChangeProps,
    onRenderOption: onRenderOptionProps,
    ...dropDownProps
}: FilterDropdownMultiSelectProps<T>) => {
    const [filterInput, setFilterInput] = useState('');
    const filterKey = useMemo(() => filterKeyProps ?? defaultFilterKey, [filterKeyProps]);
    const filterText = useMemo(() => filterTextProps ?? defaultFilterText, [filterTextProps]);
    const onRenderOption = useMemo(() => getOnRenderOptionHandler<T>(filterKey, onRenderOptionProps), [filterKey, onRenderOptionProps]);

	const [selectedKeys, setSelectedKeys] = useState<string[]>([]);


    const onFilterInput = useCallback((rawInput?: string) => {
        setFilterInput(rawInput || '');
    }, []);

    const filteredOptions = useMemo((): IDropdownOption<T>[] => {
        const textInput = filterInput.trim().toLowerCase();
        const filterOption: IDropdownOption<T> = { key: filterKey, text: filterText };
        if (!textInput) {
            return [filterOption, ...options];
        }
        // combine filtered options with selected options
        const selectedOptions = options.filter(option => selectedKeys.includes(option.key as string));
        return [
            filterOption,
            ...selectedOptions,
            ...options.filter(
                option => !selectedKeys.includes(option.key as string) && 
                (filterFn ? filterFn(option, textInput) : option.text.toLowerCase().includes(textInput))
            ),
        ];
    }, [filterFn, filterInput, filterKey, filterText, options, selectedKeys]);

    const onChange = useCallback(
        (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
            if (option?.key === filterKey) {
                return;
            }
			if (multiSelect && option) {
                setSelectedKeys(prevSelectedKeys => {
                    const optionKey = option.key as string;
                    return prevSelectedKeys.includes(optionKey)
                        ? prevSelectedKeys.filter(key => key !== optionKey)
                        : [...prevSelectedKeys, optionKey];
                });
            }
            return onChangeProps?.(event, option, index);
        },
        [filterKey, multiSelect, onChangeProps]
    );

    const calloutProps = useMemo(
        (): ICalloutProps => ({
            styles: {
                root: {
                    '.ms-Dropdown-items > .ms-Dropdown-item:first-of-type': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 100,
                        backgroundColor: 'white',
                    },
					'.ms-Dropdown-items > .ms-Dropdown-item:first-of-type > .ms-Checkbox-label > .ms-Checkbox-checkbox' : {
						display: 'none !important'
					}
                }
            },
            calloutMaxHeight: 370,
            onLayerMounted: () => setFilterInput(''),
            layerProps: {
                onLayerDidMount: () => setFilterInput('')
            },
            onDismiss: () => setFilterInput('')
        }),
        []
    );

    return (
        <FilterDropdownMultiSelectContextProvider value={{ onFilterInput }}>
            <Dropdown
                calloutProps={calloutProps}
                {...dropDownProps} // spread props here to allow overriding calloutProps
                options={filteredOptions}
                onRenderOption={onRenderOption}
                onChange={onChange}
				selectedKeys={selectedKeys}
				multiSelect={multiSelect}
				styles={{root: { backgroundColor: 'red'}}}
            />
        </FilterDropdownMultiSelectContextProvider>
    );
};

const getOnRenderOptionHandler = <T,>(filterKey: string, onRenderOption?: IRenderFunction<ISelectableOption<T>>) => {
    const handler: IRenderFunction<ISelectableOption<T>> = (
        option?: ISelectableOption<T> | undefined,
        defaultRender?: ((props?: ISelectableOption<T> | undefined) => JSX.Element | null) | undefined
    ) => {
        if (onRenderOption) {
            return option?.key === filterKey ? <FilterOption {...option} /> : onRenderOption(option, defaultRender);
        }
        return option?.key === filterKey ? <FilterOption {...option} /> : defaultRender?.(option);
    };
    return handler;
};

const FilterOption = <T,>(props?: ISelectableOption<T>) => {
    const { onFilterInput } = useFilterDropdown();
    const onChange = useCallback((e: any, newVal?: string) => onFilterInput(newVal || ''), [onFilterInput]);
    const ref = useRef<any>(null);

    useLayoutEffect(() => {
        if (ref.current) {
            // This is some ugly ass hack to get the text field to focus on mount
            setTimeout(() => {
                ref.current._textElement.current.focus();
            }, 50);
        }
    }, []);
    return (
        <Stack horizontal {...killEventProps} onInput={noSpellCheckAndAutoComplete} styles={textFieldStyles}>
            <TextField styles={textFieldStyles} placeholder={props?.text ?? defaultFilterText} onChange={onChange} onFocus={noSpellCheckAndAutoComplete} componentRef={ref} />
        </Stack>
    );
};

const textFieldStyles = { root: { width: '100%' } };

const noSpellCheckAndAutoComplete = mergeEventHandlers(disableAllAutoComplete, disableAllSpellchecks);

