import Confetti from 'react-confetti';

export default function ConfettiComponent() {
    const { width, height } = { width: window.innerWidth, height: window.innerHeight };
    return (
        <div className="confetti-container">
            <Confetti width={width} height={height} />
        </div>
    );
}
