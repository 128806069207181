import { Dropdown, IDropdownOption, TextField } from '@fluentui/react';
import { fluentUiValidationMessageElement } from '@wiberg/fluentui-builder';
import { IDynamicPropertyComponentConfig, IFormItem, IPropertyRenderProps } from '@wiberg/formbuilder';
import { PropsWithChildren, ReactElement, useState } from 'react';
import { TimelineResolution } from '../../../../Entities/TimelineResolution';
import { language } from '../../../../Services/LocalizationService';
import { DropdownOptionEx } from '../../../../Utilities/DropdownOptionEx';
import { IPeriodSpan } from '../../../_interfaces/IPeriodSpan';

interface IProps<T extends IFormItem> extends IPropertyRenderProps<T, IDynamicPropertyComponentConfig<T>, IPeriodSpan | undefined> {
    config?: IDynamicPropertyComponentConfig<T>;
}

export const PeriodSpanField = <T extends IFormItem>({
    onChange,
    disabled,
    parent,
    value,
    propertyName,
    config,
    errorMessage,
}: PropsWithChildren<IProps<T>>): ReactElement | null => {
    const [item, setItem] = useState<IPeriodSpan>(value ?? ({} as IPeriodSpan));

    const change = (prop: string, value: any) => {
        const copy = { ...(item ?? {}) } as IPeriodSpan;
        copy[prop] = value;
        setItem(copy);
        onChange(copy);
    };

    const onKeyDown = event => {
        // disable 'e' and decimals in number fields
        if (event.keyCode === 69) event.preventDefault();
        else if (event.keyCode === 110 || event.keyCode === 188 || event.keyCode === 190) event.preventDefault();
    };

    const keyPrefix = config?.componentProps?.key ?? propertyName;

    return (
        <>
            {errorMessage && fluentUiValidationMessageElement(errorMessage)}
            <div className="tp-periodspaneditor">
                <Dropdown
                    label={language.Common.Resolution}
                    key={`${keyPrefix}-resolution`}
                    disabled={disabled}
                    options={DropdownOptionEx.enumToDropdownOptions<TimelineResolution>(TimelineResolution)}
                    defaultSelectedKey={item.resolution ?? TimelineResolution.Month}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => change('Resolution', option.key)}
                    styles={{ root: { width: '100px' } }}
                />
                <TextField
                    label={language.Common.Backwards}
                    key={`${keyPrefix}-backward`}
                    defaultValue={(item.backward || 0) + ''}
                    onChange={(event, val) => change('Backward', +val)}
                    disabled={disabled}
                    type={'number'}
                    onKeyDown={onKeyDown}
                />
                <TextField
                    label={language.Common.Forwards}
                    key={`${keyPrefix}-forward`}
                    defaultValue={(item.forward || 0) + ''}
                    onChange={(event, val) => change('Forward', +val)}
                    disabled={disabled}
                    type={'number'}
                    onKeyDown={onKeyDown}
                />
            </div>
        </>
    );
};
