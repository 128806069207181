import { MessageBarType } from "@fluentui/react";
import { LinkInfo } from "./Fabric/Link";
import { MessageBarButtonInfo } from "./Fabric/MessageBarButtonInfo";
import { guid } from "../helpers/guid";


export enum NotificationStatusType {
    Info = 0,
    Error = 1,
    Blocked = 2,
    SevereWarning = 3,
    Success = 4,
    Warning = 5
}

export class Notification {
	Id: string;
	TimeStamp: Date;
	StatusType: NotificationStatusType;
	Message: string;
	Link?: LinkInfo;
	Buttons? : Array<MessageBarButtonInfo>;
	Expires?: number;
	WriteToConsole: boolean;
	errorInfo?: string;

	constructor() {
		this.Id = guid.newGuid();
		this.TimeStamp = new Date();
	}

	public GetMessageBarType = () : MessageBarType => {
		switch (this.StatusType) {
			case NotificationStatusType.Info:
				return MessageBarType.info;
			case NotificationStatusType.Success:
				return MessageBarType.success;
			case NotificationStatusType.Warning:
				return MessageBarType.warning;
			case NotificationStatusType.SevereWarning:
				return MessageBarType.severeWarning;
			case NotificationStatusType.Error:
				return MessageBarType.error;
			default:
				return MessageBarType.blocked;
		}
	}
}

