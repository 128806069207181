import { useCallback, useMemo } from 'react';
import { UnitType } from '../../../../../../api/generated/data-contracts';
import { useUnitType } from '../../../../../../Utilities/UnitTypeUtil';
import { useUISettings } from '../../../../../../context/network/http/QueryProvider/queries/UISettings';

export const useGetDecimalCellValue = () => {
    const { currentUnitType } = useUnitType();
    const { data } = useUISettings();
    return useCallback(
        (value: string | number) => {
            if (currentUnitType === UnitType.FTE) {
                return Number(value).toFixed(data.settings.gridDecimalsFTE);
            }
            return Number(value).toFixed(data.settings.gridDecimalsHours);
        },
        [currentUnitType, data.settings.gridDecimalsFTE, data.settings.gridDecimalsHours],
    );
};

export const useDecimalCellValue = (value: string | number) => {
    const getDecimals = useGetDecimalCellValue();
    return useMemo(() => getDecimals(value), [getDecimals, value]);
};
