import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { WorkProfile } from '../../../Entities/Main/WorkProfile';
import { EntityType } from '../../../Entities/EntityTypes';
import { DataService } from '../../../Services/DataService';
import { PFSpinner } from '../Spinner';
import { ObjectEditor } from '../ObjectEditor';
import { PFDialog } from '../PFDialog';
import { JsonEx } from '../../../Utilities/JsonEx';
import { validationService } from '../../../Services/ValidationService';
import { language } from '../../../Services/LocalizationService';
import { useStore } from '../../../context/store';

interface IProps extends IDynamicEditorComponentProps {
    Item: string;
    Update: (profile: string) => void;
    Key?: string;
}

const ResourceSpecificWorkprofileEditor: FunctionComponent<IProps> = props => {
    const error = useStore(store => store.addErrorNotification);
    const [profile, setProfile] = useState<WorkProfile>();
    const [loading, setLoading] = useState<boolean>(true);
    const [edit, setEdit] = useState<boolean>(false);

    const _originalProfile = useRef<WorkProfile>();

    useEffect(() => {
        const fetchProfiles = async () => {
            let wp: WorkProfile;
            if (props.Item) {
                try {
                    wp = await DataService.Get<WorkProfile>(EntityType.WorkProfile, props.Item, true);
                } catch {
                    wp = null;
                }
            }
            if (!wp) {
                wp = new WorkProfile();
                wp.ResourceGuid = props.Parent.id;
                wp.Name = props.Parent.Name;
            }
            _originalProfile.current = JsonEx.clone(wp);
            setProfile(wp);
            setLoading(false);
        };
        fetchProfiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.Item]);

    const OnWorkProfileChange = (workProfile: WorkProfile, property: string, value: any): void => {
        const prof = { ...workProfile };
        prof[property] = value;
        setProfile(prof);
    };

    const UpsertWorkprofile = async (workProfile: WorkProfile): Promise<void> => {
        const validated = validationService.validateThis(WorkProfile, workProfile, error);
        if (!validated) return;

        setEdit(false);

        await DataService.Upsert(EntityType.WorkProfile, [workProfile]);
        props.Update(workProfile.id);
    };

    return (
        <div>
            {loading ? (
                <PFSpinner />
            ) : (
                <>
                    <PrimaryButton text={language.ResourceSpecificWorkprofileEditor.WorkProfile} onClick={() => setEdit(true)} />
                    <PFDialog
                        title={language.ResourceSpecificWorkprofileEditor.EditResourceSpecificWorkProfile}
                        maxWidth={1000}
                        showDialog={edit}
                        callback={() => {
                            UpsertWorkprofile(profile);
                        }}
                        dismissCallback={() => {
                            setProfile(JsonEx.clone(_originalProfile.current));
                            setEdit(false);
                        }}
                        buttonDefaultDisabled={props.ReadOnly}
                    >
                        {profile && (
                            <ObjectEditor
                                ItemType={WorkProfile}
                                Item={profile}
                                HiddenProperties={['id', 'Created', 'CreatedBy', 'ModifiedBy', 'Start', 'End', 'Modified', 'ModifiedBy']}
                                DisabledProperties={[]}
                                ReadOnly={props.ReadOnly}
                                ChangesCallback={OnWorkProfileChange}
                            />
                        )}
                    </PFDialog>
                </>
            )}
        </div>
    );
};

export default ResourceSpecificWorkprofileEditor;
