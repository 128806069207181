import { Callout, DirectionalHint } from "@fluentui/react";
import { useQuery } from "react-query";
import { PFSpinner } from "../../../../../../../../../../../Components/Common/Spinner";
import { DateEx } from "../../../../../../../../../../../Utilities/DateEx";
import { ObjectEx } from "../../../../../../../../../../../Utilities/ObjectEx";
import { ApiCalls } from "../../../../../../../../../../../api/api";
import { fromSeconds } from "../../../../../../../../../../../context/network/http/QueryProvider/helpers/queryHelper";
import { ENTITY_CALLOUT_INFO_SWAGGER } from "../../../../../../../../../../../context/network/http/QueryProvider/queryKeys";
import { EntityType } from "../../../../../../../../../../../api/generated/data-contracts";

export const EntityInfoCallout = ({ entityId, entityType, targetId, children }: { entityId: string; entityType: EntityType; children?: React.ReactNode, targetId: string }) => {
	const { data: entityDetails, isLoading: loading } = useQuery(
		[ENTITY_CALLOUT_INFO_SWAGGER, entityId, entityType],
		() => ApiCalls.getEntityDetails({ entityId, entityType: entityType as EntityType }).then(res => res.data),
		{
			staleTime: fromSeconds(10),
		}
	);
	return (
		<Callout
			className="tp-entityinformation-callout"
			role="alertdialog"
			gapSpace={0}
			target={`#${targetId}`}
			setInitialFocus={true}
			isBeakVisible={true}
			styles={{ calloutMain: { padding: 20, minWidth: 300, minHeight: 200 }, root: {borderRadius: 8, overflow: "hidden"},  }}
			directionalHint={DirectionalHint.bottomLeftEdge}
		>
			{loading ? (
				<PFSpinner />
			) : (
				<div>
					<div className="ms-CalloutExample-header">
						<p className="ms-CalloutExample-title">Details</p>
					</div>
					<div className="ms-CalloutExample-inner">
					{children}
						<div className="ms-CalloutExample-content">
							<div className="ms-CalloutExample-subText">
								<table cellPadding={5}>
									<tbody>
										{ObjectEx.keyValuesAsStrings(entityDetails, DateEx.asUserLocal).map(({ key, value }, i) => (
											<tr key={i}>
												<td>
													<b>{key}</b>
												</td>
												<td>{value}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			)}
		</Callout>
	);
};
