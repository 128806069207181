import { atom, useRecoilCallback, useSetRecoilState } from 'recoil';
import { GridCell } from '../../../../api/generated/data-contracts';
import { UpdateCellInfo, useGridContext } from '../contexts/gridContext';
import { useMemo, useLayoutEffect } from 'react';
import { useGridRowContext } from '../contexts/rowContext';
import produce from 'immer';
import { ErrorMessageGetterWithRoundingValue } from './types';
import Big from 'big.js';
import { createCellValueGetter } from '../useGrid';

type PastableCellEntry<Checker extends ErrorMessageGetterWithRoundingValue = ErrorMessageGetterWithRoundingValue> = {
    cell: GridCell;
    onGetUpdateCellErrorMessage?: Checker;
    onError?: (msg: string) => void;
    onCellsUpdated?: () => void;
} & Pick<UpdateCellInfo, 'cellIndex' | 'columnIndex' | 'rowId'>;

type CellIndex = number;
type CellRegistry = Map<CellIndex, PastableCellEntry>;

type ColumnRowIndex = number;
type ColumnRegistry = Map<ColumnRowIndex, CellRegistry>;

type RowIndex = number;
type RowRegistry = Map<RowIndex, ColumnRegistry>;

const pastableCellRegistry = atom<RowRegistry>({
    key: 'pastable-cell-registry',
    default: new Map(),
});

// export const usePeekPastableCells_private = () => useRecoilValue(pastableCellRegistry);

export type usePasteFromExcelProps = PastableCellEntry & {
    /**
     * Gets called with the original event if the pasted data is not in an excel compatible format
     * @param e paste event
     * @returns
     */
    onPasteOriginal?: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export const usePasteFromExcel = (props: usePasteFromExcelProps) => {
    const { cellIndex, columnIndex, onPasteOriginal } = props;
    const { updateCellValue, getRowAncestorTreeToRoot, getRowsMap } = useGridContext();
    const { rowIndex } = useGridRowContext();

    useHandlePastableCellRegistration(props);

    const onPaste = useRecoilCallback(
        ({ snapshot }) =>
            (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                // We need to do this synchronously and cannot use the async navigator methods
                const data = e.clipboardData.getData('text');
                if (isExcelData(data)) {
                    e.preventDefault();
                    e.stopPropagation();

                    // preventDefault() doesn't work properly in an async function.
                    // We return an async IIFE to defer the async operations and let recoil know when we are done with the snapshot.
                    return (async () => {
                        try {
                            const pastableRows = await snapshot.getPromise(pastableCellRegistry);
                            const parsedData = parseExcelData(data);
                            const sortedRowMaps = [...pastableRows]
                                .filter(([rowIdx]) => rowIdx >= rowIndex)
                                .sort(([rowIdxA], [rowIdxB]) => rowIdxA - rowIdxB)
                                .map(tuple => tuple[1]);

                            const alignedCells = sortedRowMaps.map(rowMap => {
                                const sortedColumnMaps = [...rowMap]
                                    .filter(([columnIdx]) => columnIdx >= columnIndex)
                                    .sort(([columnIdxA], [columnIdxB]) => columnIdxA - columnIdxB)
                                    .map(tuple => tuple[1]);

                                const cells = sortedColumnMaps.reduce((acc, cellMap) => {
                                    cellMap.forEach((entry, cellIdx) => {
                                        if (cellIdx >= cellIndex) {
                                            acc.push(entry);
                                        }
                                    });
                                    return acc;
                                }, [] as PastableCellEntry[]);
                                return cells;
                            });
                            const updates: UpdateCellInfo[] = [];
                            const getCellValue = createCellValueGetter(snapshot);
                            const updatedEntries: PastableCellEntry[] = [];
                            const rows = getRowsMap();
                            for (let parsedRowIdx = 0; parsedRowIdx < parsedData.length; parsedRowIdx++) {
                                const pastedRow = parsedData[parsedRowIdx];
                                for (let parsedCellIdx = 0; parsedCellIdx < pastedRow.length; parsedCellIdx++) {
                                    let newValue = pastedRow[parsedCellIdx];
                                    const cellEntry = alignedCells?.[parsedRowIdx]?.[parsedCellIdx];
                                    if (cellEntry) {
                                        const { cell, cellIndex, columnIndex, rowId, onGetUpdateCellErrorMessage, onError } = cellEntry;
                                        if (onGetUpdateCellErrorMessage) {
                                            const row = rows.get(rowId);
                                            const currentCellValue = await getCellValue(cell);
                                            const rowFamilyTree = getRowAncestorTreeToRoot(rowId);
                                            const { errorMessage, changedValue } = await onGetUpdateCellErrorMessage({
                                                cell,
                                                cellIndex,
                                                columnIndex,
                                                newCellValue: newValue,
                                                currentCellValue,
                                                rowFamilyTree,
                                                row,
                                            });
                                            if (errorMessage) {
                                                if (onError) {
                                                    onError(errorMessage);
                                                }
                                                continue;
                                            }
                                            newValue = changedValue;
                                        }
                                        updatedEntries.push(cellEntry);
                                        updates.push({
                                            cell,
                                            cellIndex,
                                            columnIndex,
                                            rowId,
                                            newValue: newValue,
                                        });
                                    }
                                }
                            }

                            updateCellValue(updates).then(() => {
                                updatedEntries.forEach(({ onCellsUpdated }) => {
                                    if (onCellsUpdated) {
                                        onCellsUpdated();
                                    }
                                });
                            });
                        } catch (error) {
                            console.error(error);
                        }
                    })();
                } else if (onPasteOriginal) {
                    onPasteOriginal(e);
                }
            },
        [updateCellValue, rowIndex, columnIndex, cellIndex, getRowsMap, getRowAncestorTreeToRoot, onPasteOriginal],
    );
    return onPaste;
};

const isExcelData = (str: string) => /\d\t/.test(str);
// const parseExcelData = (str: string) => {
//     return (
//         str
//             .split('\n')
//             // filter out empty lines
//             .filter(Boolean)
//             .map(row =>
//                 row
//                     .split('\t')
//                     // coerce to 0 if NaN
//                     .map(value => Number(value) || 0),
//             )
//     );
// };
const parseExcelData = (str: string) => {
    return (
        str
            .split('\n')
            // filter out empty lines
            .filter(Boolean)
            .map(row =>
                row
                    .split('\t')
                    // coerce to 0 if NaN
                    .map(value => new Big((Number(value) || 0).toString())),
            )
    );
};

const useHandlePastableCellRegistration = ({
    cell,
    cellIndex,
    columnIndex,
    rowId,
    onGetUpdateCellErrorMessage,
    onError,
    onCellsUpdated,
}: usePasteFromExcelProps) => {
    const { visible, rowIndex } = useGridRowContext();
    const setCellRegistry = useSetRecoilState(pastableCellRegistry);
    const cellEntry = useMemo((): PastableCellEntry => {
        return { cell, cellIndex, columnIndex, rowId, onGetUpdateCellErrorMessage, onError, onCellsUpdated };
    }, [cell, cellIndex, columnIndex, rowId, onGetUpdateCellErrorMessage, onError, onCellsUpdated]);

    /**
     * We use useLayoutEffect to run all registration specific stuff synchronously
     */

    useLayoutEffect(() => {
        // Deregister at unmount
        return () => {
            setCellRegistry(
                produce(registry => {
                    const row = registry.get(rowIndex);
                    if (!row) {
                        return;
                    }
                    const columnRow = row.get(columnIndex);
                    if (!columnRow) {
                        return;
                    }
                    columnRow.delete(cellIndex);
                    if (!columnRow.size) {
                        row.delete(columnIndex);
                    }
                    if (!row.size) {
                        registry.delete(rowIndex);
                    }
                }),
            );
        };
    }, [cellIndex, columnIndex, rowIndex, setCellRegistry]);

    useLayoutEffect(() => {
        // Register cell when visible
        if (visible) {
            setCellRegistry(
                produce(registry => {
                    let row = registry.get(rowIndex);
                    if (!row) {
                        const newRow = new Map();
                        registry.set(rowIndex, newRow);
                        row = newRow;
                    }
                    let columnRow = row.get(columnIndex);
                    if (!columnRow) {
                        const newColumnRow = new Map();
                        row.set(columnIndex, newColumnRow);
                        columnRow = newColumnRow;
                    }
                    columnRow.set(cellIndex, cellEntry);
                }),
            );
        }
    }, [cellEntry, cellIndex, columnIndex, rowIndex, setCellRegistry, visible]);

    useLayoutEffect(() => {
        // Deregister cell when not visible
        if (!visible) {
            setCellRegistry(
                produce(registry => {
                    const row = registry.get(rowIndex);
                    if (!row) {
                        return;
                    }
                    const columnRow = row.get(columnIndex);
                    if (!columnRow) {
                        return;
                    }
                    columnRow.delete(cellIndex);
                    if (!columnRow.size) {
                        row.delete(columnIndex);
                    }
                    if (!row.size) {
                        registry.delete(rowIndex);
                    }
                }),
            );
        }
    }, [cellIndex, columnIndex, rowIndex, setCellRegistry, visible]);
};
