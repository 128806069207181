import { useUISettings } from "../context/network/http/QueryProvider/queries/UISettings";

export enum EnvironmentState
{
	Running = 1,
	Deployment = 2,
	Demo
}

export function useEnvironmentStateName() {
	return EnvironmentState[useUISettings().data.environmentState];
}