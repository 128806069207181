import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { PFPropertyValidation } from "../../Utilities/PropertyInfo/Validation";
import { TimelineResolution } from "../TimelineResolution";
import { MoveRequestValueType } from "../MoveRequestValueType";
import { OverviewContext } from "../../Utilities/Context/OverviewContext";
import { IObjectEditorItemType } from "../Main/IObjectEditorItemType";
import { DatePickerUtil } from "../../Utilities/DatePickerUtil";

export class MoveRequestDto implements IObjectEditorItemType {
	public ContractId: string;
	public ResourceId: string;
	public ProjectId: string;
	
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Start", 1, "_localization:MoveRequestDto.StartOfRequest")
	@PFPropertyValidation("_localization:MoveRequestDto.StartGreaterThanCurrentDay", dto => MoveRequestDto.ValidateStart(dto), null, () => true)
	@PFPropertyRender(null, { MinDate: dto => MoveRequestDto.GetMinDateBoundary(dto), RestrictedDates: dto => MoveRequestDto.GetRestrictedDates(dto, "first") })
	public FromStart: Date;
	@PFPropertyValidation("_localization:Common.EndGreaterThanStart", dto => dto.FromEnd == null || dto.FromEnd > dto.FromStart)
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.End", 2, "_localization:MoveRequestDto.EndOfRequest")
	@PFPropertyRender(null, { MinDate: dto => MoveRequestDto.GetMinDateBoundary(dto), RestrictedDates: dto => MoveRequestDto.GetRestrictedDates(dto, "last") })
	public FromEnd?: Date;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:MoveRequestDto.MoveTo", 3, "_localization:MoveRequestDto.NewStartDate")
	// @PFPropertyValidation("'Move to' must be specified and greater than today.", dto => MoveRequestDto.ValidateStart(dto), null, true)
	@PFPropertyValidation("_localization:MoveRequestDto.MoveToRequired", dto => dto.ToStart != null)
	@PFPropertyRender(null, { MinDate: dto => MoveRequestDto.GetMinDateBoundary(dto), RestrictedDates: dto => MoveRequestDto.GetRestrictedDates(dto, "first") })
	public ToStart: Date;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:MoveRequestDto.MoveToEnd", 3, "_localization:MoveRequestDto.ShrinkOrExtendSelectedPeriod")
	@PFPropertyValidation("_localization:MoveRequestDto.ToEndGreaterThanToStart", dto => dto.ToEnd == null || dto.ToEnd > dto.ToStart, null)
	@PFPropertyRender(null, { MinDate: dto => MoveRequestDto.GetMinDateBoundary(dto), RestrictedDates: dto => MoveRequestDto.GetRestrictedDates(dto, "last") })
	public ToEnd: Date;
	@PFPropertyBasicInfo(PropertyType.NumberSlider, "_localization:MoveRequestDto.Percentage", 4, "_localization:MoveRequestDto.MoveRequestDescription")
	@PFPropertyRender(null, { Max: 100, Format: (value: number) => `${value}%` })
	public Value: number;
	public ValueType: MoveRequestValueType;

	// auto set
	public Resolution: TimelineResolution;

	constructor() {	
		this.Value = 100;
		this.ValueType = MoveRequestValueType.Percentage;
	}

	public static GetMinDateBoundary = (dto: MoveRequestDto) : Date => {
		return DatePickerUtil.GetMinDateBoundary(dto.Resolution, OverviewContext.Settings.FreezePeriod);
	}

	public static ValidateStart = (dto: MoveRequestDto) : boolean => {
		const start = MoveRequestDto.GetMinDateBoundary(dto);
		return dto.FromStart >= start;
	}

	public static GetRestrictedDates = (dto: MoveRequestDto, type: "first" | "last") : Array<Date> => {
		return DatePickerUtil.GetRestrictedDates(dto.Resolution, OverviewContext.Settings.FreezePeriod, type);
	}
}