import React, { FunctionComponent } from 'react';
import { Label, TooltipHost, Icon } from '@fluentui/react';
 
interface IProps { 
	TooltipText: string;
	LabelText?: string;
	Required?: boolean;
	ExtraClass?: string;
}

interface IPFTooltipProps { 
	Content: string;
}

const PFTooltip: FunctionComponent<IPFTooltipProps> = props => {

    return  <TooltipHost content={ props.Content } calloutProps={{ gapSpace: 0 }} styles={{root:{verticalAlign:"middle"}}}>
				<Icon iconName={ "Info" } />
			</TooltipHost>
}

export const Tooltip: FunctionComponent<IProps> = props => {

    return  <div className={`tp-tooltip-container ${props.ExtraClass}`}>
				<div className="tp-tooltip-text-container">
					{ props.LabelText ? <Label required={props.Required} className={props.TooltipText ? "tp-tooltip-label" : ""} styles={{root:{padding:0}}}>{props.LabelText}</Label> : null }
					{ props.TooltipText ? <div className="tp-tooltip-text"><PFTooltip Content={props.TooltipText} /></div> : null }
				</div>
				{ <div className={props.TooltipText && props.LabelText ? "tp-tooltip-child" : ""}>{props.children}</div> }
			</div>
}

export const TooltipAfter: FunctionComponent<IProps> = props => {

    return  <div className={`tp-tooltip-container ${props.ExtraClass}`}>
				{ <div className={props.TooltipText ? "tp-tooltip-label" : ""}>{props.children}</div> }
				{ props.TooltipText ? <div className="tp-tooltip-text"><PFTooltip Content={props.TooltipText} /></div> : null }
			</div>
}