/* eslint-disable react-refresh/only-export-components */
import { useMemo } from 'react';
import { DefaultValue, atomFamily, selectorFamily, useRecoilState, useRecoilValue } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { ITooltipHostProps, Link, Stack, Text, TooltipHost, Spinner } from '@fluentui/react';
import { capitalize } from '../../../../../../context/network/http/QueryProvider/helpers/queryHelper';
import { GridRow } from '../../../../../../api/generated/data-contracts';
import { formatAsTitle } from '../../../../../../Utilities/StringEx';
import { useUISettings } from '../../../../../../context/network/http/QueryProvider/queries/UISettings';
import { CUSTOM_COLUMN_WIDTH } from '../CONSTANTS';

// We pick from GridRow to keep up with the generated types
export type RowCustomColumn = GridRow['customColumnData'];

// We can't use the generate TypedValue type since our serializer makes date strings into date objects
type TypedValueAbstract = {
    type: string;
    value?: string | Date;
};

const getCustomColumnData = (customColumnData: RowCustomColumn, key: string): TypedValueAbstract => {
    const data = resolveCustomColumnData(customColumnData, key);
    if (!data) {
        return {
            type: 'loading',
            value: 'loading',
        };
    }
    return data as TypedValueAbstract;
};

export const resolveCustomColumnData = <T extends RowCustomColumn>(data: T, key: string) => data?.[key] || data?.[capitalize(key)] || data?.[key.toUpperCase()] || {};

// string
// number
// date
// bool
// url
// rbs
// resourcetype
// projecttype
// stringlist
// resourcelist
export const ProjectCustomColumnCell = ({ option, rowCustomColumns }: { option: CustomColumnOption; rowCustomColumns: RowCustomColumn }) => {
    const { type, value } = useMemo(() => {
        return getCustomColumnData(rowCustomColumns, option.key);
    }, [option.key, rowCustomColumns]);

    const render = useMemo(() => {
        if (!value) {
            return null;
        }
        switch (type) {
            case 'stringlist':
            case 'resource':
            case 'string': {
                return <TextCell>{value as string}</TextCell>;
            }
            case 'url': {
                return (
                    <Link href={value as string} target="_blank">
                        Click here to go to project
                    </Link>
                );
            }
            case 'date': {
                return (value as Date).toDateString();
            }
            case 'loading': {
                return <Spinner />;
            }
            default: {
                return <>{value}</>;
            }
        }
    }, [type, value]);

    return (
        <Stack horizontalAlign="center" tokens={{ maxWidth: CUSTOM_COLUMN_WIDTH, padding: 5 }}>
            {render}
        </Stack>
    );
};

const textStyle: ITooltipHostProps['styles'] = { root: { width: '100%', display: 'inline-grid' } };

const TextCell = ({ children }: { children?: string }) => {
    return (
        <TooltipHost content={children} styles={textStyle}>
            <Text nowrap>{children}</Text>
        </TooltipHost>
    );
};

export type CustomColumnOption = { key: string; text: string; data?: any };

const customColumnsFamily = atomFamily<CustomColumnOption[], CustomColumnOption[]>({
    key: 'custom-columns',
    default: initialColumns => initialColumns,
});

const { persistAtom } = recoilPersist({ key: 'tp-custom-columns' });

const selectedCustomColumnKeys = atomFamily<string[], CustomColumnOption[]>({
    key: 'selected-custom-column-keys',
    default: [],
    effects: [persistAtom],
});

const selectedCustomColumnsFamily = selectorFamily<CustomColumnOption[], CustomColumnOption[]>({
    key: 'selected-custom-columns',
    get:
        initialColumns =>
        ({ get }) => {
            const keys = get(selectedCustomColumnKeys(initialColumns));
            const columns = get(customColumnsFamily(initialColumns));
            return keys.map(key => columns.find(column => column.key === key));
        },
    set:
        initialColumns =>
        ({ set }, newCols) => {
            if (!(newCols instanceof DefaultValue)) {
                set(
                    selectedCustomColumnKeys(initialColumns),
                    newCols.map(col => col.key),
                );
            }
        },
});

const useCustomColumnsSettings = (): CustomColumnOption[] => {
    const { data: uiSettings } = useUISettings();
    const options = useMemo((): CustomColumnOption[] => {
        return (
            uiSettings.settings.projectPlannerProjectFilterOptions
                .reduce((acc, prop) => {
                    if (prop !== 'relatedGenericResourceId') {
                        const lowerKeyProps = prop.toLowerCase();
                        if (lowerKeyProps.startsWith('customproperties.')) {
                            const guid = lowerKeyProps.replace('customproperties.', '');
                            const customProp = uiSettings.settings.projectCustomProperties.find(customProp => {
                                return customProp.id.toLowerCase() === guid;
                            });
                            if (customProp) {
                                acc.push({
                                    key: prop,
                                    text: customProp.displayName,
                                });
                            }
                        } else {
                            acc.push({
                                key: prop,
                                text: formatAsTitle(prop),
                            });
                        }
                    }

                    return acc;
                }, [] as CustomColumnOption[])
        );
    }, [uiSettings.settings.projectCustomProperties, uiSettings.settings.projectPlannerProjectFilterOptions]);

    return options;
};

const selectedCustomColumnCount = selectorFamily<number, CustomColumnOption[]>({
    key: 'selected-custom-column-count',
    get:
        initialColumns =>
        ({ get }) => {
            const keys = get(selectedCustomColumnKeys(initialColumns));
            return keys.length;
        },
});

export const useCustomColumnsValue = () => {
    return useRecoilValue(customColumnsFamily(useCustomColumnsSettings()));
};

export const useSelectedCustomColumnKeysState = () => {
    return useRecoilState(selectedCustomColumnKeys(useCustomColumnsSettings()));
};

export const useSelectedCustomColumnKeysValue = () => {
    return useRecoilValue(selectedCustomColumnKeys(useCustomColumnsSettings()));
};

export const useSelectedColumnsValue = () => {
    return useRecoilValue(selectedCustomColumnsFamily(useCustomColumnsSettings()));
};

export const useSelectedCustomColumnsCount = () => {
    return useRecoilValue(selectedCustomColumnCount(useCustomColumnsSettings()));
};
