/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Callout, FontIcon, IconButton, Text } from '@fluentui/react';
import { PFSpinner } from '../../Common/Spinner';
import { language } from '../../../Services/LocalizationService';
import { IRow } from '../../../Entities/Table/Row';
import { CommentBox } from './components/CommentBox';
import { CommentTextField } from './components/CommentTextField';
import { useTPPermissions } from '../../../hooks/usePermissions';
import { useQuery, useQueryClient } from 'react-query';
import { Comment, UserType } from '../../../api/generated/data-contracts';
import { useUISettings } from '../../../context/network/http/QueryProvider/queries/UISettings';
import { COMMENTS } from '../../../context/network/http/QueryProvider/queryKeys';

interface IProps {
    elementId: string;
    hasComments?: boolean;
    hasUnreadComments?: boolean;
    canDelete?: boolean;
    dontSetAuthor?: boolean;
    newCommentFunction?: (row: IRow) => Comment;
    fetchCallback: (row: IRow) => Promise<Array<Comment>>;
    addCallback: (newComment: Comment) => Promise<Comment>;
    removeCallback: (comment: Comment) => Promise<any>;
    onLoaded?: (comments: Array<Comment>) => void;
    row: IRow;
}

export const Comments: FunctionComponent<IProps> = ({
    addCallback,
    elementId,
    fetchCallback,
    newCommentFunction,
    removeCallback,
    canDelete,
    dontSetAuthor,
    hasComments: hasCommentsProps,
    hasUnreadComments: hasUnreadCommentsProps,
    onLoaded,
    row,
}) => {
    const [calloutVisible, setCalloutVisible] = useState<boolean>(false);

	const [disabled, setDisabled] = useState<boolean>(false);
    const [hasComments, setHasComments] = useState<boolean>(hasCommentsProps);
    const [hasUnreadComments, setHasUnreadComments] = useState<boolean>(hasUnreadCommentsProps);

    const {
        data: comments,
        isSuccess,
        isFetched,
        isLoading,
		refetch: refetchComments,
		
        // isFetching,
    } = useQuery(
        [COMMENTS, row.Properties.ContractId, row.Id],
        async () => {
            const commentsFetched = await fetchCallback(row);
			// contract doesn't exist  (handled by returning null)
			setDisabled(commentsFetched == null);
            return commentsFetched ?? [];
        },
        {
            staleTime: 1000 * 20,
            enabled: calloutVisible,
            refetchInterval: 1000 * 20,
        },
    );
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!calloutVisible) return;
        if (isSuccess && isFetched) {
            onLoaded?.(comments);
        }

        setHasUnreadComments(false);
    }, [calloutVisible, comments, isFetched, isSuccess, onLoaded]);

    const removeComment = useCallback(
        async (comment: Comment) => {
            await removeCallback(comment);
            shouldSmoothScroll.current = false;
            queryClient.setQueryData(['comments', row.Properties.ContractId, row.Id], (comments: Comment[]) =>
                comments.filter(existingCommment => existingCommment.id !== comment.id),
            );
        },
        [queryClient, removeCallback, row.Id],
    );

    // const user = useStore(store => store.user);
    const { data: uiSettings } = useUISettings();
    const resource = uiSettings.resource;
    const addComment = useCallback(
        async (text: string) => {
            try {
                const timestamp = new Date();
                timestamp.setHours(0, 0, 1); // hack to prevent "unread" flag, due to UTC time coming from API
                const comment: Comment = {
                    text,
                    timestamp: timestamp as any,
                    ...(newCommentFunction && newCommentFunction(row)),
                    ...(!dontSetAuthor &&
                        resource && {
                            authorId: resource.id,
                            authorName: resource.name,
                        }),
                };
                if (!hasComments) {
                    setHasComments(true);
                }

                // const newComment = await addCallback(comment);
                // queryClient.setQueryData<Comment[]>(['comments', row.Properties.ContractId, row.Id], comments => [...comments, newComment]);
				await addCallback(comment);
				await refetchComments();
            } catch (error) {
                console.log('addComment ~ error', error);
            }
        },
        [addCallback, dontSetAuthor, hasComments, newCommentFunction, queryClient, resource, row],
    );

    const editComment = useCallback(
        async (text: string, comment: Comment) => {
            try {
                const newComment = await addCallback({ ...comment, text: text });
                queryClient.setQueryData<Comment[]>(['comments', row.Properties.ContractId, row.Id], comments =>
                    comments.map(oldComment => {
                        if (oldComment.id === comment.id) {
                            return newComment;
                        }
                        return oldComment;
                    }),
                );
                shouldSmoothScroll.current = false;
            } catch (error) {
                console.log('addComment ~ error', error);
            }
        },
        [addCallback, queryClient, row.Id],
    );
    const ref = useRef<HTMLDivElement | null>(null);
    const shouldSmoothScroll = useRef(true);

    useLayoutEffect(() => {
        if (!calloutVisible || isLoading) return;

        const timeout = setTimeout(() => {
            if (ref.current) {
                ref.current.scrollTo({
                    top: ref.current.scrollHeight,
                    behavior: 'smooth',
                    // behavior: 'auto',
                });
            }
        }, 300);

        return () => {
            clearTimeout(timeout);
        };
    }, [calloutVisible, isLoading]);

    useLayoutEffect(() => {
        if (!calloutVisible) return;
        if (shouldSmoothScroll.current) {
            const timeout = setTimeout(() => {
                if (ref.current) {
                    ref.current.scrollTo({
                        top: ref.current.scrollHeight,
                        behavior: 'smooth',
                        // behavior: 'auto',
                    });
                }
            }, 300);

            return () => {
                clearTimeout(timeout);
            };
        } else {
            shouldSmoothScroll.current = true;
        }
    }, [calloutVisible, comments]);

    const isSuperUser = useTPPermissions({ userTypes: [UserType.SuperUser] });

    return (
        <>
            <IconButton
                id={elementId}
                iconProps={{ iconName: 'Comment' }}
                className={`tp-comments-button ${hasUnreadComments ? 'unread' : hasComments ? 'read' : ''}`}
                onClick={() => setCalloutVisible(!calloutVisible)}
            />
            {calloutVisible && (
                <Callout
                    id="tp-comment-callout"
                    className="tp-comments-callout"
                    role="alertdialog"
                    gapSpace={0}
                    target={`#${elementId}`}
                    onDismiss={ev => setCalloutVisible(false)}
                    setInitialFocus={true}
                    hidden={!calloutVisible}
                >
                    <div className="tp-comments-container">
                        {isLoading ? (
                            <PFSpinner />
                        ) : (
                            <>
                                <div className="ms-CalloutExample-header">
                                    <p className="ms-CalloutExample-title">{language.Comments.Comments}</p>
                                </div>
								{disabled 
									?
									<div>
										<FontIcon iconName="Warning" />
										<Text styles={{ root: { paddingLeft: 10 } }}>{'Contract does not exist'}</Text>
									</div>
									:
									<>
										<div className="tp-comments-list" ref={ref}>
											{comments.map(comment => {
												return (
													<CommentBox
														key={comment.id}
														comment={comment}
														removeComment={removeComment}
														editComment={editComment}
														canDelete={canDelete}
														isSuperUser={isSuperUser}
													/>
												);
											})}
										</div>
										<CommentTextField addComment={addComment} />
									</>
								}
                            </>
                        )}
                    </div>
                </Callout>
            )}
        </>
    );
};
