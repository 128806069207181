import { useState, useEffect, PropsWithChildren, ReactElement, useCallback } from 'react';
import { NormalPeoplePicker, IBasePickerSuggestionsProps, IPersonaProps, IPersonaStyles, PeoplePickerItemSuggestion, TagPicker, ITag } from '@fluentui/react';
import { LocalStorage } from '../../Utilities/LocalStorage';
import { EventEx } from '../../Utilities/EventEx';
import { ArrayEx } from '../../Utilities/ArrayEx';
import { language } from '../../Services/LocalizationService';
import { useIsMounted } from '@wiberg/formbuilder';
import { Timeout } from '../../types/runtimeTypes';
import { stringVariantsCamelCase } from '../../helpers/stringVariants';

interface IProps<TEntity> {
    item: string | Array<string>;
    update: (value: string | Array<string>) => void;
    parent?: any;
    key?: string;

    pickerType?: 'tag' | 'people';

    pickerOptions?: IPickerOptions<TEntity>;
    pickerOptionsGetter?: () => Promise<IPickerOptions<TEntity>>;
    styles?: any;
    readOnly?: boolean;
}

export interface IPickerItem extends ITag {
    id: string;
    displayName: string;
    description: string;
    [key: string]: any; // extra properties used for search
    // [P in keyof T]?: T[P];
}

export interface IPickerOptions<TEntity> {
    itemParser: (item: TEntity) => IPickerItem;
    entityGetter: (item: any) => Promise<Array<TEntity>>;
    recentItemsKey: string;
    multiSelect?: boolean;
    itemLimit?: number;
    valueSelector?: (item: any) => any;
    placeHolder?: string;
    extraSearchFields?: string[];
    suggestions: IPickerSuggestionsOptions;
}

const personaStyles: Partial<IPersonaStyles> = {
    root: {
        height: 'auto',
    },
    secondaryText: {
        height: 'auto',
        whiteSpace: 'normal',
    },
    primaryText: {
        height: 'auto',
        whiteSpace: 'normal',
    },
};

export interface IPickerSuggestionsOptions {
    suggestionsHeaderText: string;
    noResultsFound: string;
    loadingText: string;
    showRemoveButtons: boolean;
    suggestionsAvailableAlertText: string;
    suggestionsContainerText: string;
}

export const GenericPicker = <TEntity,>({
    item: value,
    pickerType,
    update,
    pickerOptions,
    pickerOptionsGetter,
    parent,
    readOnly,
    key,
    styles,
}: PropsWithChildren<IProps<TEntity>>): ReactElement | null => {
    const [pickerProps, setPickerProps] = useState<IPickerOptions<TEntity>>();
    const [items, setItems] = useState<IPickerItem[]>();
    const [selected, setSelected] = useState<IPickerItem[]>([]);
    const [recentlyUsedIds, setRecentlyUsedIds] = useState<string[]>([]);

    const valueSelector = pickerOptions?.valueSelector ?? ((it: IPickerItem) => it.id);

    const isMounted = useIsMounted();

    useEffect(() => setPickerProps(pickerOptions), [pickerOptions]);
    useEffect(() => {
        if (pickerOptionsGetter == null) return;
        const fetch = async () => {
            const pp = await pickerOptionsGetter();
            setPickerProps(pp);
        };
        fetch();
    }, [pickerOptionsGetter]);

    const setRecentlyUsed = useCallback(
        (recentIds: Array<string>, updateCache?: boolean): void => {
            setRecentlyUsedIds(recentIds);
            if (updateCache) LocalStorage.set(pickerProps.recentItemsKey, recentIds);
        },
        [pickerProps],
    );

    const setSelectedItems = useCallback(
        (selected: string | string[], allItems?: IPickerItem[]) => {
            if (selected == null || pickerProps == null) {
                setSelected([]);
                return;
            }

            allItems ??= items;
            if (allItems == null) return;

            let selectedItems: Array<IPickerItem> = [];
            if (pickerProps.multiSelect) {
                selectedItems = allItems.filter(_ => (selected as Array<string>)?.some(i => i.toLowerCase() === _.id.toLowerCase()));
            } else {
                const item = allItems.find(_ => _.id.toLowerCase() === (selected as string).toLowerCase());
                selectedItems = item ? [item] : [];
            }

            setSelected(selectedItems);
        },
        [items, pickerProps],
    );

    useEffect(() => {
        setSelectedItems(value);
    }, [setSelectedItems, value]);

    useEffect(() => {
        if (pickerProps?.entityGetter == null) return;

        const fetchAndParseEntities = async () => {
            const entities = await pickerProps.entityGetter(parent);
            const allItems = entities?.map(_ => {
                const parsed = pickerProps.itemParser(_);

                if (pickerType === 'tag') {
                    const item = parsed as ITag;
                    item.key = parsed.id;
                    item.name = parsed.displayName;
                } else {
                    // defaults to normal people picker
                    const item = parsed as IPersonaProps;
                    item.id = parsed.id;
                    item.text = parsed.displayName;
                    item.secondaryText = parsed.description;
                }
                return parsed;
            });

            setSelectedItems(value, allItems);

            if (isMounted()) {
                setItems(allItems);
                const recentItems = LocalStorage.get<Array<string>>(pickerProps.recentItemsKey) || [];
                setRecentlyUsed(recentItems);
            }
        };
        fetchAndParseEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parent, pickerProps, pickerProps?.entityGetter, isMounted, pickerType]);

    const filterItemsByText = (filterText: string): IPickerItem[] => {
        const normalizedFilterText = filterText.toLowerCase();
        const searchFields = ['displayName', 'description', ...(pickerOptions?.extraSearchFields ?? [])];

        return items.filter(item =>
            searchFields.some(field => {
                const fieldValue = stringVariantsCamelCase.getAsStringValue(item, field);
                return fieldValue && fieldValue.toLowerCase().includes(normalizedFilterText);
            }),
        );
    };

    let _timer: Timeout;
    const onFilterChanged = (filterText: string, currentPersonas: IPickerItem[], limitResults?: number): PromiseLike<IPickerItem[]> => {
        const item = EventEx.delayAndReturn(
            _timer,
            () => {
                if (filterText) {
                    let filteredPersonas: IPickerItem[] = filterItemsByText(filterText);

                    filteredPersonas = limitResults ? filteredPersonas.splice(0, limitResults) : filteredPersonas;
                    return filteredPersonas;
                } else {
                    return [];
                }
            },
            600,
        );
        _timer = item.timer;
        return item.result;
    };

    const getTextFromItem = (persona: IPickerItem): string => {
        return persona.displayName as string;
    };

    const onItemsChange = (items: IPickerItem[]): void => {
        setSelected(items);
        if (pickerProps.multiSelect) update(items.map(_ => valueSelector(_)));
        else update(items.length === 0 ? null : valueSelector(items[0]));
    };

    const buildSuggestionProps = (): IBasePickerSuggestionsProps => {
        return {
            suggestionsHeaderText: pickerProps.suggestions.suggestionsHeaderText,
            mostRecentlyUsedHeaderText: pickerProps.suggestions.suggestionsHeaderText,
            noResultsFoundText: pickerProps.suggestions.noResultsFound,
            loadingText: pickerProps.suggestions.loadingText,
            showRemoveButtons: pickerProps.suggestions.showRemoveButtons,
            suggestionsAvailableAlertText: pickerProps.suggestions.suggestionsAvailableAlertText,
            suggestionsContainerAriaLabel: pickerProps.suggestions.suggestionsContainerText,
        } as IBasePickerSuggestionsProps;
    };

    const returnMostRecentlyUsed = (currentPersonas: IPickerItem[]): IPickerItem[] | Promise<IPickerItem[]> => {
        const displayIds = recentlyUsedIds.filter(_ => currentPersonas.findIndex(s => s.id === _) < 0);
        const recent = items.filter(_ => displayIds.findIndex(d => d === _.id) >= 0);
        ArrayEx.sortByArray(recent, (item: IPickerItem) => item.id, displayIds.reverse());
        return recent;
    };

    const updateRecentlyUsed = (selectedItem?: IPickerItem): IPickerItem => {
        if (!selectedItem) return null;
        const recent = [...recentlyUsedIds];
        recent.push(selectedItem.id);
        // restrict to 10 recently used items
        if (recent.length > 10) recent.shift();
        setRecentlyUsed(recent, true);
        return selectedItem;
    };

    const removeRecentlyUsedItem = (item: IPickerItem) => {
        const recentItems = LocalStorage.get<Array<string>>(pickerProps.recentItemsKey) || [];
        const index = recentItems.findIndex(_ => _ === item.id);
        if (index < 0) return;
        recentItems.splice(index, 1);
        setRecentlyUsed(recentItems, true);
    };

    const onPeopleRenderSuggestionItem = (personaProps: IPersonaProps, suggestionsProps: IBasePickerSuggestionsProps) => {
        //TODO WRAP IN PFTooltipHost FOR NEXT RELEASE
        return (
            <PeoplePickerItemSuggestion
                personaProps={{ ...personaProps, styles: personaStyles }}
                suggestionsProps={suggestionsProps}
                styles={{ personaWrapper: { width: '100%' } }}
            />
        );
    };

    if (pickerProps == null) return null;

    return (
        <>
            {pickerType === 'tag' ? (
                <TagPicker
                    itemLimit={pickerProps.multiSelect ? pickerProps.itemLimit : 1}
                    onResolveSuggestions={onFilterChanged}
                    onEmptyInputFocus={returnMostRecentlyUsed}
                    inputProps={{ placeholder: pickerOptions?.placeHolder }}
                    onItemSelected={updateRecentlyUsed}
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={buildSuggestionProps()}
                    onRemoveSuggestion={removeRecentlyUsedItem}
                    removeButtonAriaLabel={language.Common.Remove}
                    onChange={onItemsChange}
                    selectedItems={selected}
                    disabled={readOnly || !items}
                    styles={styles ?? null}
                />
            ) : (
                <NormalPeoplePicker
                    itemLimit={pickerProps.multiSelect ? pickerProps.itemLimit : 1}
                    onResolveSuggestions={onFilterChanged}
                    onEmptyInputFocus={returnMostRecentlyUsed}
                    inputProps={{ placeholder: pickerOptions?.placeHolder }}
                    onItemSelected={updateRecentlyUsed}
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={buildSuggestionProps()}
                    className={'ms-PeoplePicker'}
                    onRemoveSuggestion={removeRecentlyUsedItem}
                    removeButtonAriaLabel={language.Common.Remove}
                    onChange={onItemsChange}
                    selectedItems={selected}
                    disabled={readOnly || !items}
                    onRenderSuggestionsItem={onPeopleRenderSuggestionItem}
                    pickerCalloutProps={{ calloutWidth: 0, calloutMaxWidth: 500 }}
                    styles={styles ?? null}
                />
            )}
        </>
    );
};
