import {
    Stack,
    CommandBarButton,
    Text,
    Callout,
    Dialog,
    TextField,
    PrimaryButton,
    DefaultButton,
    ITextProps,
    IButtonStyles,
    IRenderFunction,
    IButtonProps,
    Spinner,
    SpinnerSize,
    AnimationClassNames,
} from '@fluentui/react';
import produce from 'immer';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useIsFetching, useIsMutating, useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import shallow from 'zustand/shallow';
import { ApiCalls } from '../../../../../api/api';
import { GridView, QueryGroup, UIStartupDto, GridViewType } from '../../../../../api/generated/data-contracts';
import { syncUISettings } from '../../../../../context/network/http/QueryProvider/queries/UISettings';
import { UI_SETTINGS_KEY } from '../../../../../context/network/http/QueryProvider/queryKeys';
import { useStore } from '../../../../../context/store';
import { useControlledState } from '../../../../../hooks/useControlledState';
import { useGridUndo } from '../../../_grid/useGrid';
import { fallbackViewId } from '../../fallbackGridView';

export const Toolbar = ({
    currentView,
    viewHasFilterChanges,
    setViewHasFilterChanges,
    views,
    setCurrentViewId,
    setOpenPanel,
    currentViewFilter,
}: {
    currentView: GridView;
    viewHasFilterChanges: boolean;
    setViewHasFilterChanges: (changes: boolean) => void;
    views: GridView[];
    setCurrentViewId: (id: string) => void;
    setOpenPanel: (state: boolean) => void;
    currentViewFilter: QueryGroup;
}) => {
    const { pathname } = useLocation();
    const { defaultViewId, setDefaultViewId } = useStore(
        store => ({
            defaultViewId: store.defaultViews[pathname] || views[0]?.id || fallbackViewId,
            setDefaultViewId: (id: string) => store.setDefaultView(pathname, id),
        }),
        shallow,
    );
    const currentViewId = currentView?.id;
    const [openViewCallout, setOpenViewCallout] = useState(false);
    const [openSaveNewDialog, setOpenSaveNewDialog] = useState(false);
    const [currentEditableView, setCurrentEditableView] = useControlledState(currentView, [currentView]);
    const [openEditCurrentDialog, setOpenEditCurrentDialog] = useState(false);

    const queryClient = useQueryClient();

    const { mutateAsync: upsertView } = useMutation(
        ({ isNew, view }: { isNew?: boolean; view?: Partial<GridView> }) => {
            const id = isNew ? window.crypto.randomUUID() : currentView.id;
            const viewToSend: GridView = {
                ...currentView,
                ...(view || {}),
                // ...(isNew && { type: GridViewType.user }),
                type: GridViewType.User,
                filters: {
                    resources: currentViewFilter,
                },
                id,
            };
            return ApiCalls.upsertResourcePlannerView(viewToSend).then(() => ({ ...viewToSend, isNew }));
        },
        {
            onSuccess: ({ isNew, ...view }) => {
                queryClient.setQueryData<UIStartupDto>([UI_SETTINGS_KEY], uiSettings => {
                    const newSettings = produce(uiSettings, draft => {
                        if (isNew) {
                            draft.settings.gridViews.resourcePlannerViews.push(view);
                        } else {
                            draft.settings.gridViews.resourcePlannerViews = draft.settings.gridViews.resourcePlannerViews.map(oldView => {
                                if (oldView.id === view.id) {
                                    return view;
                                }
                                return oldView;
                            });
                        }
                    });
                    syncUISettings(newSettings);
                    return newSettings;
                });
            },
        },
    );

    const { mutateAsync: deleteView } = useMutation(() => ApiCalls.deleteResourcePlannerView(currentView).then(res => currentView), {
        onSuccess: deletedView => {
            queryClient.setQueryData<UIStartupDto>([UI_SETTINGS_KEY], uiSettings => {
                const newSettings = produce(uiSettings, draft => {
                    draft.settings.gridViews.resourcePlannerViews = draft.settings.gridViews.resourcePlannerViews.filter(view => view.id !== deletedView.id);
                });
                syncUISettings(newSettings);
                return newSettings;
            });
        },
    });

    const onClickListItem = useCallback(
        (viewId: string) => {
            setOpenViewCallout(false);
            setViewHasFilterChanges(false);
            setCurrentViewId(viewId);
        },
        [setCurrentViewId, setViewHasFilterChanges],
    );

    const addWarningNotification = useStore(store => store.addWarningNotification, shallow);

    const {
        redo,
        redoStackCount,
        undo,
        undoStackCount,
        loading,
        //
        purge,
    } = useGridUndo();
    const handleUndo = useCallback(async () => {
        if (undoStackCount) {
            return undo();
        } else {
            addWarningNotification('Nothing to undo');
        }
    }, [addWarningNotification, undo, undoStackCount]);
    const handleRedo = useCallback(async () => {
        if (redoStackCount) {
            return redo();
        } else {
            addWarningNotification('Nothing to redo');
        }
    }, [addWarningNotification, redo, redoStackCount]);
    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if (e.ctrlKey && !loading) {
                const key = e.key.toLowerCase();
                if (key === 'z') {
                    handleUndo();
                } else if (key === 'y') {
                    handleRedo();
                }
            }
        };
        window.addEventListener('keydown', handler);
        return () => {
            window.removeEventListener('keydown', handler);
        };
    }, [loading, handleUndo, handleRedo]);

    useEffect(() => {
        if (viewHasFilterChanges) {
            purge();
        }
    }, [purge, viewHasFilterChanges]);

    return (
        <>
            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 5, padding: 5 }}>
                <Stack>
                    <CommandBarButton
                        id="view-btn"
                        iconProps={{ iconName: 'ChevronDown', styles: { root: { marginRight: 10 } } }}
                        text={currentView.name + (viewHasFilterChanges ? '*' : '')}
                        onClick={() => {
                            setOpenViewCallout(true);
                        }}
                        onRenderText={onRenderText}
                    />
                </Stack>
                <Stack horizontal>
                    <Loader />
                    <CommandBarButton onClick={undo} disabled={!undoStackCount || loading} iconProps={{ iconName: 'Undo' }} text={`Undo (${undoStackCount})`} />
                    <CommandBarButton onClick={redo} disabled={!redoStackCount || loading} iconProps={{ iconName: 'Redo' }} text={`Redo (${redoStackCount})`} />
                    <CommandBarButton onClick={() => setOpenPanel(true)} iconProps={{ iconName: 'Filter' }} text="Edit filter" />
                </Stack>
            </Stack>
            {openViewCallout && (
                <Callout
                    target={'#view-btn'}
                    isBeakVisible={false}
                    shouldDismissOnWindowFocus
                    onDismiss={() => {
                        setOpenViewCallout(false);
                    }}
                >
                    <Stack styles={{ root: { width: 300 } }}>
                        {views.map(view => {
                            return (
                                <ListButton
                                    key={view.id}
                                    currentView={currentView}
                                    onClick={onClickListItem}
                                    view={view}
                                    viewHasFilterChanges={viewHasFilterChanges}
                                    defaultViewId={defaultViewId}
                                    purgeUndoStack={purge}
                                />
                            );
                        })}
                        <div style={{ height: 1, borderTop: '1px solid rgba(0,0,0,0.2)' }} />
                        {currentViewId !== defaultViewId && (
                            <CommandBarButton
                                text={'Set as default view'}
                                iconProps={{ iconName: 'Table' }}
                                onRenderText={onRenderTextFunction}
                                onClick={() => {
                                    setOpenViewCallout(false);
                                    setDefaultViewId(currentViewId);
                                }}
                                disabled={currentViewId === fallbackViewId}
                                styles={listButtonStyles}
                            />
                        )}
                        {viewHasFilterChanges && (
                            <>
                                {currentView.type !== GridViewType.Global && (
                                    <CommandBarButton
                                        text={'Save changes to current view'}
                                        iconProps={{ iconName: 'Save' }}
                                        onRenderText={onRenderTextFunction}
                                        onClick={() => {
                                            setOpenViewCallout(false);
                                            upsertView({ isNew: false }).then(() => {
                                                setViewHasFilterChanges(false);
                                            });
                                        }}
                                        styles={listButtonStyles}
                                    />
                                )}
                                <CommandBarButton
                                    text={'Save as new view'}
                                    iconProps={{ iconName: 'SaveAs' }}
                                    onRenderText={onRenderTextFunction}
                                    onClick={() => {
                                        setOpenViewCallout(false);
                                        setOpenSaveNewDialog(true);
                                    }}
                                    styles={listButtonStyles}
                                />
                            </>
                        )}
                        {currentView.type !== GridViewType.Global && (
                            <CommandBarButton
                                text={'Edit ' + currentView.name}
                                iconProps={{ iconName: 'Edit' }}
                                onRenderText={onRenderTextFunction}
                                onClick={() => {
                                    setOpenViewCallout(false);
                                    setOpenEditCurrentDialog(true);
                                }}
                                styles={listButtonStyles}
                            />
                        )}
                    </Stack>
                </Callout>
            )}
            <Dialog
                hidden={!openSaveNewDialog}
                dialogContentProps={{ title: 'Save as' }}
                onDismiss={() => {
                    setCurrentEditableView(currentView);
                    setOpenSaveNewDialog(false);
                }}
            >
                <Stack>
                    <TextField
                        required
                        label="Name"
                        onChange={(e, val) => {
                            setCurrentEditableView(view => {
                                return {
                                    ...view,
                                    name: String(val),
                                };
                            });
                        }}
                    />
                    <TextField
                        required
                        label="Description"
                        multiline
                        onChange={(e, val) => {
                            setCurrentEditableView(view => {
                                return {
                                    ...view,
                                    description: String(val),
                                };
                            });
                        }}
                    />
                </Stack>
                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10, padding: '10px 0 0 0' }}>
                    <PrimaryButton
                        onClick={() => {
                            upsertView({ isNew: true, view: currentEditableView }).then(({ id }) => {
                                setCurrentViewId(id);
                                setViewHasFilterChanges(false);
                            });
                            setOpenSaveNewDialog(false);
                        }}
                    >
                        Save
                    </PrimaryButton>
                    <DefaultButton
                        onClick={() => {
                            setCurrentEditableView(currentView);
                            setOpenSaveNewDialog(false);
                        }}
                    >
                        Cancel
                    </DefaultButton>
                </Stack>
            </Dialog>
            <Dialog
                hidden={!openEditCurrentDialog}
                dialogContentProps={{ title: 'Edit ' + currentView.name }}
                onDismiss={() => {
                    setCurrentEditableView(currentView);
                    setOpenSaveNewDialog(false);
                }}
            >
                <Stack>
                    <TextField
                        required
                        label="Name"
                        value={currentEditableView.name}
                        onChange={(e, val) => {
                            setCurrentEditableView(view => {
                                return {
                                    ...view,
                                    name: String(val),
                                };
                            });
                        }}
                    />
                    <TextField
                        required
                        label="Description"
                        value={currentEditableView.description}
                        multiline
                        onChange={(e, val) => {
                            setCurrentEditableView(view => {
                                return {
                                    ...view,
                                    description: String(val),
                                };
                            });
                        }}
                    />
                </Stack>
                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10, padding: '10px 0 0 0' }}>
                    {currentView.type !== GridViewType.Global && (
                        <DefaultButton
                            onClick={() => {
                                deleteView().then(() => {
                                    setCurrentViewId(defaultViewId);
                                });
                                setOpenEditCurrentDialog(false);
                            }}
                            styles={{
                                root: { backgroundColor: 'rgb(255, 120, 120)', color: 'white' },
                                rootHovered: { backgroundColor: 'rgb(255, 100, 100)', color: 'white' },
                            }}
                        >
                            Delete
                        </DefaultButton>
                    )}
                    <PrimaryButton
                        onClick={() => {
                            upsertView({ view: currentEditableView }).then(({ id }) => {
                                setCurrentViewId(id);
                            });
                            setOpenEditCurrentDialog(false);
                        }}
                    >
                        Save
                    </PrimaryButton>
                    <DefaultButton
                        onClick={() => {
                            setCurrentEditableView(currentView);
                            setOpenEditCurrentDialog(false);
                        }}
                    >
                        Cancel
                    </DefaultButton>
                </Stack>
            </Dialog>
        </>
    );
};

const Loader = () => {
    const fetching = useIsFetching();
    const mutating = useIsMutating();
    const loading = fetching !== 0 || mutating !== 0;
    return <Spinner size={SpinnerSize.large} className={loading ? AnimationClassNames.fadeIn200 : AnimationClassNames.fadeOut200} />;
};

const ListButton = ({
    currentView,
    view,
    viewHasFilterChanges,
    onClick,
    defaultViewId,
    purgeUndoStack,
}: {
    view: GridView;
    currentView: GridView;
    viewHasFilterChanges: boolean;
    onClick: (viewId: string) => void;
    defaultViewId: string;
    purgeUndoStack: () => void;
}) => {
    const onRenderCommandbarText = useMemo(() => onRenderListTextFunction(view, currentView, viewHasFilterChanges), [currentView, view, viewHasFilterChanges]);
    const handleOnClick = useCallback(() => {
        if (view.id !== currentView.id) {
            onClick(view.id);
            purgeUndoStack();
        }
    }, [view.id, currentView.id, onClick, purgeUndoStack]);
    const iconName = useMemo(() => {
        if (view.type === GridViewType.Global) {
            if (view.id === defaultViewId) {
                return 'GlobeFavorite';
            }
            return 'Globe';
        }
        if (view.id === defaultViewId) {
            return 'ContactHeart';
        }
        return 'Contact';
    }, [defaultViewId, view.id, view.type]);
    const iconProps = useMemo(() => {
        return {
            iconName,
        };
    }, [iconName]);
    // return <CommandBarButton iconProps={iconProps} text={view.name} style={{textDecoration: view.id === currentView.id ? "underline" : "unset"}} onRenderText={onRenderCommandbarText} onClick={handleOnClick} styles={listButtonStyles} />;
    // return <CommandBarButton iconProps={iconProps} text={view.name} style={{fontWeight: view.id === currentView.id ? 600 : 400}} onRenderText={onRenderCommandbarText} onClick={handleOnClick} styles={listButtonStyles} />;
    // return <CommandBarButton iconProps={iconProps} text={view.name} style={{backgroundColor: view.id === currentView.id ? "rgba(0,0,0,0.1)" : "unset"}} onRenderText={onRenderCommandbarText} onClick={handleOnClick} styles={listButtonStyles} />;
    return (
        <CommandBarButton
            iconProps={iconProps}
            text={view.name}
            style={{ ...(view.id === currentView.id && { backgroundColor: 'rgba(0,0,0,0.1)' }) }}
            onRenderText={onRenderCommandbarText}
            onClick={handleOnClick}
            styles={listButtonStyles}
        />
    );
    // return <CommandBarButton iconProps={iconProps} text={view.name + (view.id === currentView.id ? " (current)" : "")} onRenderText={onRenderCommandbarText} onClick={handleOnClick} styles={listButtonStyles} />;
};

const onRenderListTextFunction =
    (view: GridView, currentView: GridView, viewHasFilterChanges: boolean) =>
    ({ text }: { text: string }) => {
        return <ButtonText>{text + (view.id === currentView.id && viewHasFilterChanges ? '*' : '')}</ButtonText>;
    };

const onRenderTextFunction = ({ text }: { text: string }) => {
    return <ButtonText>{text}</ButtonText>;
};

const onRenderText: IRenderFunction<IButtonProps> = ({ text }) => {
    return <Text variant="xxLarge">{text}</Text>;
};

const ButtonText = ({ children }: { children: React.ReactNode }) => <Text styles={commanbarItemStyles}>{children}</Text>;

const commanbarItemStyles: ITextProps['styles'] = { root: { width: '100%', height: '100%', display: 'flex' } };
const listButtonStyles: IButtonStyles = { root: { height: 32 } };
