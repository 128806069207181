import * as React from "react";
import { Text } from "@fluentui/react/lib/Text";
import { HeaderRow } from "../../../../Entities/Table/HeaderRow";
import { useTheme } from '../../../../Themes/themeContext';

export const HeaderRange: React.FunctionComponent<{
	children?: React.ReactNode;
	header: HeaderRow;
}> = ({ header }) => {
	const theme = useTheme();

	return (
		<Text className="tp-table-header-subtopic noselect" styles={{root: { color: theme.semanticColors.bodySubtext }}}>
			{header.Topic}
		</Text>
	)
};
