import { IValidationRule } from '../Entities/Validation/ValidationRule';
import { IValidationResult } from '../Entities/Validation/ValidationResult';
import { PropertyInfoService } from './PropertyInfoService';
import { LocalizationService } from './LocalizationService';

export function validateRule(item: any, validationRule: IValidationRule): IValidationResult {
    const result: IValidationResult = {
        PropertyName: validationRule.Property,
        ErrorMessage: `${LocalizationService.Instance.GetByKey(validationRule.ErrorText)} ${
            validationRule.DescriptionText ? validationRule.DescriptionText(item) : ''
        }`,
        Validated: validationRule.Action(item),
    };
    return result;
}

export function validateItem(item: any, validationRules: Array<IValidationRule>): Array<IValidationResult> {
    return validationRules.map(_ => validateRule(item, _));
}

export function validate(item: any, validationRules: Array<IValidationRule>, notifyError?: (message: string, expires?: number, errorInfo?: string) => void): boolean {
    const errors = validateItem(item, validationRules).filter(_ => !_.Validated);
    if (notifyError) errors.forEach(_ => notifyError(_.ErrorMessage, 5000));
    return errors.length === 0;
}

export function validateThis<T>(type: { new (): T }, item: T, notifyError?: (message: string, expires?: number, errorInfo?: string) => void): boolean {
    const errors = validateItem(item, generateValidationRules(type)).filter(_ => !_.Validated);
    if (notifyError) errors.forEach(_ => notifyError(_.ErrorMessage, 5000));
    return errors.length === 0;
}

export function generateValidationRules<T>(type: { new (): T }): Array<IValidationRule> {
    const properties = PropertyInfoService.GetPFProperties(type, true);
    return properties.filter(_ => _.Validation).map(_ => _.Validation);
}

export const validationService = {
    validateRule,
    validateItem,
    validate,
    validateThis,
    generateValidationRules,
};
