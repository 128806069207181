import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PanelType } from '@fluentui/react/lib/Panel';
import { language } from '../../../../../Services/LocalizationService';
import { useStore } from '../../../../../context/store';
import shallow from 'zustand/shallow';
import { Panel } from '../Panel';
import { ResourcePicker } from './ResourcePicker';
import { getGridTypeFromRoute } from '../../../../../Entities/GridType';
import { useLocation } from 'react-router';
import { RESOURCES_KEY } from '../../../../../context/network/http/QueryProvider/queryKeys';
import { ResourceType } from '../../../../../Entities/ResourceType';
import { useStoreContext } from '../../../../../Services/StateStore';
import EnumEx from '../../../../../Utilities/EnumEx';
import { useUISettings } from '../../../../../context/network/http/QueryProvider/queries/UISettings';
import { useQuery } from 'react-query';
import { ApiCalls } from '../../../../../api/api';
import { ResourceListItem, UserType, GridType } from '../../../../../api/generated/data-contracts';
import { useCheckTPPermissions } from '../../../../../hooks/usePermissions';

export const ResourcePanel: React.FC = () => {
    const { data: uiSettings } = useUISettings();
    const { genericResourceFilter, projectTeamSelected } = useStoreContext().Get(state => ({
        genericResourceFilter: state.GenericResourceFilter,
        projectTeamSelected: state.ProjectTeamSelected,
    }));
    const { showResourcePicker, delegationMode, setUIContext, timelineEnd, timelineStart } = useStore(
        store => ({
            showResourcePicker: store.ui.showResourcePicker,
            delegationMode: store.ui.delegationMode,
            setUIContext: store.setUIContext,
            timelineEnd: store.timeline.end,
            timelineStart: store.timeline.start,
        }),
        shallow,
    );

    const checkPermissions = useCheckTPPermissions();

    const [isDragging, setIsDragging] = useState(false);
    const [requestNamedResources, setRequestNamedResources] = useState(false);
    const [showAll, setShowAll] = useState(false);

    const { pathname } = useLocation();

    const gridType = useMemo(() => {
        if (delegationMode) {
            return GridType.Allocation;
        }
        return getGridTypeFromRoute(pathname);
    }, [delegationMode, pathname]);

    const matchRbs = useMemo(() => {
        return EnumEx.equalsAny(gridType, GridType.Allocation, GridType.ResourcePlanner, GridType.LmCapacityProjects, GridType.ResourceCapacity);
    }, [gridType]);

    const resourceTypes: ResourceType[] = useMemo(() => {
        if (delegationMode) {
            return [ResourceType.Named];
        }

        if (requestNamedResources && gridType === GridType.Request) {
            return [ResourceType.Generic, ResourceType.Team, ResourceType.Pool, ResourceType.Named];
        }

        // is team project
        const teamSelected = projectTeamSelected;

        // request
        if (EnumEx.equalsAny(gridType, GridType.Request, GridType.ResourcePlanner, GridType.WorkPackage)) {
            const types = [ResourceType.Generic];
            if (!teamSelected) {
                types.push(ResourceType.Team);
            }
            if (uiSettings.settings.enableResourcePools) {
                types.push(ResourceType.Pool);
            }
            return types;
        } else if (EnumEx.equalsAny(gridType, GridType.Allocation, GridType.LmCapacityProjects)) {
            // allocation
            const types = [ResourceType.Named, ResourceType.Category];
            if (!teamSelected) {
                types.push(ResourceType.Team);
            }
            return types;
        } else if (gridType === GridType.ResourceCapacity) {
            // resource capacity
            return [ResourceType.Named, ResourceType.Team, ResourceType.Category];
        }
    }, [delegationMode, gridType, projectTeamSelected, requestNamedResources, uiSettings.settings.enableResourcePools]);

    const queryKey = useMemo(
        () =>
            [
                RESOURCES_KEY,
                {
                    gridType,
                    resourceTypes,
                    delegationMode,
                    end: timelineEnd,
                    start: timelineStart,
                    matchRbs,
                    genericResources: uiSettings.settings.showOnlyRelevantResourcesInPicker ? genericResourceFilter : null,
                    selectAll: delegationMode ? true : showAll,
                },
            ] as const,
        [
            delegationMode,
            genericResourceFilter,
            gridType,
            matchRbs,
            resourceTypes,
            showAll,
            timelineEnd,
            timelineStart,
            uiSettings.settings.showOnlyRelevantResourcesInPicker,
        ],
    );

    const canShow = useMemo(() => {
        // check for resourcecapacity not LM
        const isLM = checkPermissions({ userTypes: [UserType.LineManager] });
        if (gridType === GridType.ResourceCapacity && !isLM) {
            return false;
        }

        return Boolean(
            EnumEx.equalsAny(gridType, GridType.Allocation, GridType.Request, GridType.LmCapacityProjects, GridType.ResourceCapacity, GridType.WorkPackage, GridType.ProjectPlanner) &&
                timelineEnd &&
                timelineStart,
        );
    }, [checkPermissions, gridType, timelineEnd, timelineStart]);

    const { data, isFetching, isFetched } = useQuery(
        queryKey,
        async () => {
            try {
                return ApiCalls.getResourcesForPanel({
                    gridType,
                    resourceTypes,
                    delegationMode,
                    end: timelineEnd,
                    start: timelineStart,
                    matchRbs,
                    genericResources: uiSettings.settings.showOnlyRelevantResourcesInPicker ? genericResourceFilter : null,
                    selectAll: delegationMode ? true : showAll,
                }).then(res =>
                    res.data.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    }),
                );
            } catch (error) {
                // The error is already handled before this
                return [];
            }
        },
        {
            placeholderData: useRef([] as ResourceListItem[]).current,
            enabled: canShow && showResourcePicker,
        },
    );

    useEffect(() => {
        if (canShow) {
            const handler = (e: Event) => {
                if (e instanceof KeyboardEvent) {
                    if (!(e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) && e.key.toLowerCase() === 'd') {
                        setUIContext(ui => ({ showResourcePicker: !ui.showResourcePicker }));
                    }
                }
            };
            window.addEventListener('keyup', handler);
            return () => {
                window.removeEventListener('keyup', handler);
            };
        }
    }, [canShow, setUIContext]);

    useEffect(() => {
        if (showResourcePicker && !canShow) {
            setUIContext({ showResourcePicker: false });
        }
    }, [canShow, setUIContext, showResourcePicker]);

    return (
        <Panel
            isOpen={showResourcePicker}
            isLightDismiss={true}
            onDismiss={() => setUIContext({ showResourcePicker: false, delegationMode: false })}
            type={PanelType.smallFixedFar}
            headerText={delegationMode ? language.ResourcePicker.HeaderText.ResourceDelegation : language.ResourcePicker.HeaderText.ResourcePicker}
            className="tp-panel resources"
            isBlocking={false}
            styles={{
                root: {
                    '.ms-Panel-contentInner, .ms-Panel-scrollableContent': { height: '100%', display: 'flex', flexDirection: 'column' },
                    '.ms-Panel-content': { flexGrow: 1, paddingRight: 0, paddingBottom: 0 },
                    opacity: isDragging ? 0.3 : 1,
                    transition: 'opacity .3s',
                },
            }}
        >
            <ResourcePicker
                setIsDragging={setIsDragging}
                data={data}
                isFetching={isFetching}
                queryKey={queryKey}
                requestNamedResources={requestNamedResources}
                setRequestNamedResources={setRequestNamedResources}
                setShowAll={setShowAll}
                showAll={showAll}
                gridType={gridType}
                isFetched={isFetched}
            />
        </Panel>
    );
};
