import { OverflowSet, IOverflowSetItemProps, CommandBarButton, IButtonStyles, TooltipHost, IconButton } from '@fluentui/react';
import { AlignContractStatus } from '../../Entities/AlignContractStatus';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { useState, useEffect } from 'react';
import { Plugin } from '../../Services/PluginInvoker';
import { PluginIDs } from '../../Utilities/PluginIDs';
import { language } from '../../Services/LocalizationService';
import { useStore } from '../../context/store';
import { useCheckTPPermissions } from '../../hooks/usePermissions';
import { UserType } from '../../api/generated/data-contracts';
import { useLocation } from 'react-router';

interface IProps {
    ContractId: string; // guid
    Status: AlignContractStatus;
    Callback: (status: AlignContractStatus) => void;
}

const AlignContractMenu: React.FunctionComponent<IProps> = props => {
    const { error, success } = useStore(store => ({
        error: store.addErrorNotification,
        success: store.addSuccesNotification,
    }));
    const [status, setStatus] = useState<AlignContractStatus>(props.Status);
    const checkPermission = useCheckTPPermissions({ some: true });
    const { pathname } = useLocation();

    useEffect(() => {
        setStatus(props.Status);
    }, [props.Status]);

    const OnRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
        if (item.onRender) {
            return item.onRender(item);
        }
        return (
            <CommandBarButton
                role="menuitem"
                iconProps={{ iconName: item.icon }}
                menuProps={item.subMenuProps}
                text={item.name}
                styles={{ label: { backgroundColor: 'red', color: 'blue' } }}
            />
        );
    };

    const OnRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
        const buttonStyles: Partial<IButtonStyles> = {
            menuIcon: { color: status.Color },
            menuIconHovered: { color: status.Color },
            menuIconExpandedHovered: { color: status.Color },
        };
        return (
            <TooltipHost content={<span style={{ color: status.Color }}>{status.Name}</span>} calloutProps={{ gapSpace: 0, isBeakVisible: true }}>
                <IconButton
                    ariaLabel={language.AlignContractMenu.AlignStatus}
                    role="menuitem"
                    styles={buttonStyles}
                    menuIconProps={{ iconName: 'TaskLogo' }}
                    menuProps={{ items: overflowItems! }}
                />
            </TooltipHost>
        );
    };

    const GetOverFlowItems = (): Array<IOverflowSetItemProps> => {
        const items = [
            {
                key: 'header',
                name: language.AlignContractMenu.ChangeStatus,
                disabled: true,
                style: { color: 'black', fontWeight: 'bold', backgroundColor: '#fafafa' },
            },
        ];

        const statuses = OverviewContext.Settings.AlignContractStatuses.sort((a, b) => (a.Order > b.Order ? 1 : -1)).map((_, idx) => {
            const isRequestPage = pathname.startsWith('/request');
            const isAllocationPage = pathname.startsWith('/allocation');
            const isPM = checkPermission({ userTypes: [UserType.ProjectOwner] });
            const isLM = checkPermission({ userTypes: [UserType.LineManager] });
            const isSU = checkPermission({ userTypes: [UserType.SuperUser] });

            let disableControl: boolean = false;

            if (isPM && isLM && !isSU) {
                if (isRequestPage) {
                    disableControl = !_.EditableBy.some((ut: UserType) => {
                        return ut === UserType.ProjectOwner;
                    });
                } else if (isAllocationPage) {
                    disableControl = !_.EditableBy.some((ut: UserType) => {
                        return ut === UserType.LineManager;
                    });
                }
            }

            const isAllowed = checkPermission({ userTypes: [..._.EditableBy] }) && !disableControl;
            return {
                key: `${_.Id}_header_${idx}`,
                name: _.Name,
                style: { color: _.Color },
                disabled: !isAllowed,
                iconProps: { iconName: isAllowed ? null : 'Lock' },
                onClick: () => {
                    const prevStatus = status;
                    setStatus(_);
                    // update contract
                    if (prevStatus.Id === _.Id) return;
                    props.Callback(_);
                    Plugin.Invoke(PluginIDs.UpdateContractAlignStatus, { ContractId: props.ContractId, NewStatus: _.Id })
                        .then(succ => _.CustomNotification && success(_.CustomNotification))
                        .catch(err => error(language.AlignContractMenu.FailedChangingContractAlignment));
                },
            } as IOverflowSetItemProps;
        });

        items.push(...(statuses as any));
        return items;
    };

    return <OverflowSet items={[]} overflowItems={GetOverFlowItems()} onRenderOverflowButton={OnRenderOverflowButton} onRenderItem={OnRenderItem} />;
};

export default AlignContractMenu;
