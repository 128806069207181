import {
    ComboBox,
    DatePicker,
    DetailsList,
    IColumn,
    IPanelProps,
    SelectionMode,
    Selection,
} from '@fluentui/react';
import { IFormItem } from '@wiberg/formbuilder';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { _CountryOptions, _YearOptions } from '../../../../Entities/Recurrence/CountryAndYearLists';
import { isNullUndefinedOrEmpty } from '../../../../Utilities/StringEx';
import { ApiCalls } from '../../../../api/apiv2';
import { Holiday } from '../../../../api/generated/data-contracts';
import { Divider } from 'antd';
import { PFDialog } from '../../../../Components/Common/PFDialog';
import { devLogger } from '../../../../helpers/devLogger';

type ImportRulesByApiDialogProps = Pick<IPanelProps, 'headerText' | 'className' | 'styles' | 'type'> & {
    openPanel: boolean;
    setOpenPanel: React.Dispatch<React.SetStateAction<boolean>>;
    onClickImport?: (itemsNew: Holiday[]) => Promise<void> | void;
    onDismissPanel?: () => void;
    loading?: boolean;
	onError?: (error: any) => Promise<void> | void;
    /**
     * @param searchInput is a trimmed string of user text
     */
};

export const ImportRulesByApiDialog = <T extends IFormItem>({
    openPanel,
    setOpenPanel,
    onClickImport,
    onDismissPanel,
    loading,
	onError,
    ...rest
}: ImportRulesByApiDialogProps) => {
	const validation = useRef<() => Promise<Record<string, string>>>();
	const setSavingItem = useState(false)[1];
	
    const [countryBoxSelection, setCountryBoxSelection] = useState<string>(null);
    const [yearBoxSelection, setYearBoxSelection] = useState<string>(null);

    const [newHolidayArray, setNewHolidayArray] = useState<Holiday[]>([]);
    const [selectedNewHolidays, setSelectedNewHolidays] = useState<Holiday[]>([]);

    const holidaysSelectionModel = new Selection({
        onSelectionChanged: () => setSelectedNewHolidays(getSelectedNewHolidays()),
        onItemsChanged: () => holidaysSelectionModel.setAllSelected(true),
    });

    const getSelectedNewHolidays = (): Holiday[] => {
        return holidaysSelectionModel.getSelection() as Holiday[];
    };

    const _listColumns = [
        { key: 'name', name: 'Name', fieldName: 'name', minWidth: 150, maxWidth: 150, isResizable: true },
        { key: 'date', name: 'Date', fieldName: 'date', minWidth: 150, maxWidth: 150, isResizable: false },
    ];

    const renderItemColumn = (item: any, index: number, column: IColumn) => {
        if (column.key === 'date') {
            const fieldContDate = new Date(item.date);
            return (
                <DatePicker
                    disabled
                    firstDayOfWeek={1} // Means monday
                    value={fieldContDate}
                    styles={{ textField: { width: 150 } }}
                />
            );
        }
        const fieldContDefaultString = item[column.fieldName as keyof any] as string;
        return <span>{fieldContDefaultString}</span>;
    };

    useEffect(() => {
        if (isNullUndefinedOrEmpty(countryBoxSelection) || isNullUndefinedOrEmpty(yearBoxSelection)) return;
        setNewHolidayArray([]); // Temp fix for never changing keys when changing year, instead of having a useEffect. Good fix is to include a key in the model.
        const fetchHolidays = async () => {
            const output = await ApiCalls.getHolidaysByCountryAndYear({ country: countryBoxSelection, year: yearBoxSelection });
            setNewHolidayArray(output.data);
        };
        fetchHolidays();
    }, [countryBoxSelection, yearBoxSelection]);

	const handleImportItems = useCallback(
        async (preferredItem?: T) => {
            const items = selectedNewHolidays;
            if (onClickImport) {
				let maybePromise: any;
                try {
					// validate
					const validationResult = await validation.current?.();
					if (validationResult && Object.values(validationResult).some(_ => _ != null)) {
						devLogger.log('validationresult', validationResult);
						
						return;
					}

					maybePromise = onClickImport(items);
					// There is no need to start a spinner if there is nothing to wait for
					if (maybePromise instanceof Promise) {
						setSavingItem(true);
						await maybePromise;
					}
                } catch (error) {
					const newError = new Error('Error when saving', { cause: error })
                    if (onError) {
                        onError(newError);
                    }
					throw newError
                } finally {
					if (maybePromise instanceof Promise) {
						setSavingItem(false);
					}
                }
            }
            return items;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onClickImport, onError, selectedNewHolidays],
    );

    return (
        <PFDialog
            title={rest.headerText}
            maxWidth={1500}
            showDialog={openPanel}
            callback={() => {
				handleImportItems();
                setOpenPanel(false);
            }}
            dismissCallback={() => setOpenPanel(false)}
            buttonText="Import"
			okButtonEnabled={selectedNewHolidays.length > 0}
            {...rest}
        >
            <ComboBox
                label="Select country"
                options={_CountryOptions}
                autoComplete={'on'}
                selectedKey={countryBoxSelection}
                onChange={(e, o, i, v) => {
                    setCountryBoxSelection(o.key as string);
                }}
                styles={{ optionsContainer: { width: 200, height: 400 } }}
            />
            <ComboBox
                label="Select year"
                calloutProps={{}}
                options={_YearOptions}
                autoComplete={'on'}
                selectedKey={yearBoxSelection}
                onChange={(e, o, i, v) => {
                    setYearBoxSelection(o.key as string);
                }}
                styles={{ optionsContainer: { width: 100, height: 400 } }}
            />
            <Divider />
            <DetailsList
                compact
                columns={_listColumns}
                onRenderItemColumn={renderItemColumn}
                items={newHolidayArray}
                selectionMode={SelectionMode.multiple}
                selectionPreservedOnEmptyClick={true}
                selection={holidaysSelectionModel}
            />
        </PFDialog>
    );
};
