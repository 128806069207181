import { DropdownOptionEx } from "../../../../Utilities/DropdownOptionEx";
import { PFPropertyBasicInfo } from "../../../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../../../Utilities/PropertyInfo/Render";
import { PFPropertyValidation } from "../../../../Utilities/PropertyInfo/Validation";
import { PeriodSpan } from "../../../PeriodSpan";
import { VersionResolution } from "../../../Versions/VersionResolution";
import { MailSchedule } from "../../Schedule/MailSchedule";
import { MailTemplateDataType } from "../MailTemplateDataType";
import { MailTemplateConfig } from "./MailTemplateConfig";

export class ContractChangesTemplateConfig extends MailTemplateConfig {
	public MailTemplateConfigType: MailTemplateDataType = MailTemplateDataType.ContractChangesNotification;
	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:MailTemplate.VersionResolution", 10, "_localization:MailTemplate.VersionResolutionDescription")
	@PFPropertyRender((item: ContractChangesTemplateConfig, i, col) => VersionResolution[item[col.fieldName]], { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutZeroOption<VersionResolution>(VersionResolution) })
	@PFPropertyValidation("_localization:MailTemplate.TemplateNameValidation", (item: ContractChangesTemplateConfig) => item.VersionResolution != null && item.VersionResolution !== VersionResolution.None)
	public VersionResolution: VersionResolution;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:MailTemplate.DateSpan", 20, "_localization:MailTemplate.DateSpanDescription")
	@PFPropertyRender(null, null, "PeriodSpanEditor")
	public DateSpan: PeriodSpan;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:MailTemplate.Schedule", 30, "_localization:MailTemplate.ScheduleDescription")
	@PFPropertyRender(null, { ItemType: MailSchedule })
	public Schedule: MailSchedule;
}