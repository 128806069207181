import { ArrayEx } from "./ArrayEx";


export const getNestedPropertyValueByString = (object: any, accessor: string) => {
	accessor = accessor.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
	accessor = accessor.replace(/^\./, '');           // strip a leading dot
	const a = accessor.split('.');
	for (let i = 0, n = a.length; i < n; ++i) {
		const k = a[i];
		if (k in object) {
			object = object[k];
		} else {
			return;
		}
	}
	return object;
}

export const setNestedPropertyValueByString = (object: any, accessor: string, value: any) => {
	accessor = accessor.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
	accessor = accessor.replace(/^\./, '');           // strip a leading dot
	const path = accessor.split('.');
	SetDeep(object, path, value, true);
}

/**
 * Dynamically sets a deeply nested value in an object.
 * Optionally "bores" a path to it if its undefined.
 * @function
 * @param {!object} obj  - The object which contains the value you want to change/set.
 * @param {!array} path  - The array representation of path to the value you want to change/set.
 * @param {!mixed} value - The value you want to set it to.
 * @param {boolean} setrecursively - If true, will set value of non-existing path as well.
 */
function SetDeep(obj: any, path: string[], value: any, setrecursively = false) {
	path.reduce((a, b, level) => {
		if (setrecursively && typeof a[b] === "undefined" && level !== path.length - 1) {
			a[b] = {};
			return a[b];
		}

		if (level === path.length - 1) {
			a[b] = value;
			return value;
		}
		return a[b];
	}, obj);
}

export function mergeDeep(...objects: any[]) {
	const isObject = (obj: any) => obj && typeof obj === 'object';
	
	return objects.reduce((prev, obj) => {
		if (obj == null) return prev;
		Object.keys(obj).forEach(key => {
			const pVal = prev[key];
			const oVal = obj[key];
			
			if (Array.isArray(pVal) && Array.isArray(oVal)) {
				prev[key] = pVal.concat(...oVal);
			} else if (isObject(pVal) && isObject(oVal)) {
				prev[key] = mergeDeep(pVal, oVal);
			} else {
				prev[key] = oVal;
			}
		});
	  
	  return prev;
	}, {});
}

export function extract<T>(properties: Record<keyof T, true>) {
	return function <TActual extends T>(value: TActual) {
		const result = {} as T;
		for (const property of Object.keys(properties) as Array<keyof T>) {
			result[property] = value[property];
		}
		return result;
	};
}

export function allNotNull(...objects: any[]): boolean {
	return !objects.some(_ => _ == null);
}

export const anyNotNull = (...objects: any[]) : boolean => {
	return objects.some(_ => _ != null);
}

export function deepCopy<T>(source: T): T {
	return Array.isArray(source)
			? source.map(item => deepCopy(item))
			: source instanceof Date
				? new Date(source.getTime())
				: source && typeof source === 'object'
					? Object.getOwnPropertyNames(source).reduce((o, prop) => {
							Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
							o[prop] = deepCopy(source[prop]);
							return o;
					}, Object.create(Object.getPrototypeOf(source)))
					: source as T;
}

// builds a tree based on an array of path strings
export function buildTrees<T>(paths: any[], nameProp: string = "Name", pathProp: string = "Path", childrenProp: string = "Children", alterItem?: (item: T) => void): T[] {
	const result: T[] = [];
	const level = { result };

	paths.forEach(path => {
		const splits = [...path.split('|')];
		splits.reduce((r, name, i, a) => {
			if (!r[name]) {
				r[name] = { result: [] };
				const pathStr = ArrayEx.joinToString(splits.slice(0, i + 1), "|");
				const obj = {} as T;
				obj[nameProp] = name;
				obj[pathProp] = pathStr;
				obj[childrenProp] = r[name].result;
				if (alterItem)
					alterItem(obj);
				r.result.push(obj);
			}

			return r[name];
		}, level);
	});

	return result;
}

export function alterTreeNode<T>(root: T, itemSelector: (item: T) => boolean, childrenSelector: (item: T) => Array<T>, alter: (item: T) => void, onlyFirst: boolean = false): void {
	// traverse tree without recursion
	const stack: T[] = [];
	stack.push(root);
	while (stack.length > 0) {
		const current = stack.pop();
		if (itemSelector(current)) {
			alter(current);
			if (onlyFirst)
				return;
		}

		childrenSelector(current).forEach(_ => stack.push(_));
	}
	return null;
}

export function treeExpandNested<T>(root: T, itemSelector: (item: T) => boolean, childrenSelector: (item: T) => T[], expandFunc: (item: T) => void): boolean {
	let hasCHildsSelected = itemSelector(root);
	if (hasCHildsSelected)
		expandFunc(root);
	for (const item of childrenSelector(root)) {
		const childSelected = ObjectEx.treeExpandNested(item, itemSelector, childrenSelector, expandFunc);
		const selected = itemSelector(item);
		if (selected || childSelected) {
			hasCHildsSelected = true;
			expandFunc(item);
		}
	}
	return hasCHildsSelected;
}

// NOT TESTED (ewi)
// builds a tree based on an array of items, using a grouping string property the can be seperated
export function buildTreesFromArray<T>(items: Record<string, any>[], stringProperty: string, seperator: string, nameProp: string = "Name", pathProp: string = "Path", childrenProp: string = "Children", alterItem?: (item: T) => void): T[] {
	const result: T[] = [];
	const level = { result };

	items.forEach(item => {
		const splits = [...item[stringProperty].split(seperator)];
		splits.reduce((r, name, i, a) => {
			if (!r[name]) {
				r[name] = { result: [] };
				const pathStr = ArrayEx.joinToString(splits.slice(0, i + 1), "|");
				const obj = {} as T;
				obj[nameProp] = name;
				obj[pathProp] = pathStr;
				obj[childrenProp] = r[name].result;
				if (alterItem)
					alterItem(obj);
				r.result.push(obj);
			}

			return r[name];
		}, level);
	});

	return result;
}

export function keyValuesAsStrings(obj: any, dateFormatter?: (value: Date) => string): Array<{ key: string; value: string; }> {
	return Object.keys(obj).map(key => {
		let value = obj[key];
		value = value instanceof Date && dateFormatter != null ? dateFormatter(value) : value?.toString();
		return { key, value };
	});
}

export const ObjectEx = {
	allNotNull,
	alterTreeNode,
	anyNotNull,
	buildTrees,
	buildTreesFromArray,
	deepCopy,
	extract,
	getNestedPropertyValueByString,
	keyValuesAsStrings,
	mergeDeep,
	setNestedPropertyValueByString,
	treeExpandNested,
	// SetDeep: SetDeep,
}