import { ReactElement, useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { IFieldRenderProps, IFormItem, useFormContext, useValidation, useWatch } from 'spark-forms';
import { FluentValidationMessage } from 'spark-forms-fluent8';
import { ApiCalls } from '../../../../api/api';
import { GetDto, EntityType } from '../../../../api/generated/data-contracts';
import { ShimmerBox } from '../../../../Components/Skeleton/ShimmerBox';
import { useUISettings } from '../../../../context/network/http/QueryProvider/queries/UISettings';
import { useStore } from '../../../../context/store';
import { guid } from '../../../../helpers/guid';
import { IRbsFieldConfig } from './IRbsFieldConfig';
import { SortableTreeEditor } from '../../../../components_new/sortableTreeview/SortableTreeEditor';
import { sortableTreeHelper } from '../../../../components_new/sortableTreeview/sortableTreeHelper';
import { ITreeNode } from '@pum-spark/tree-render';
import { ArrayEx } from '../../../../Utilities/ArrayEx';
import { ITreeNode as ITPTreeNode } from '../../tpComplexBuilder/components/tree/interfaces/ITreeNode';
import { RBS_FIELD } from '../../../../context/network/http/QueryProvider/queryKeys';

export interface IRbsFieldProps<T extends IFormItem> extends IFieldRenderProps<T, IRbsFieldConfig<T>, string | string[] | ITPTreeNode | undefined> {
    config?: IRbsFieldConfig<T>;
}

type LocalValueType = string | string[] | ITreeNode | undefined;

export const RbsField = <T extends IFormItem>({ value, fieldName, config, disabled, path }: IRbsFieldProps<T>): ReactElement | null => {
    const [localValue, setLocalValue] = useState<LocalValueType | ITPTreeNode>(value);

    const { onChange: onChangeForm } = useFormContext();
    const { validationMessage, validate } = useValidation(path);

    useWatch(path, () => setLocalValue(value as LocalValueType), [value]);

    const { data: uiSettings } = useUISettings();
    const setBlockDialog = useStore(store => store.setBlockDialog);

    const isEdit = config.mode === 'edit';

    const { data: rbs } = useQuery([RBS_FIELD, uiSettings.settings.rbsId, config.mode], async () => {
        try {
            if (isEdit) {
                if (rbs != null) return localValue as ITreeNode;
                const tree = (sortableTreeHelper.parseToModernTreeNode(value as ITPTreeNode) ?? {
                    name: 'Root',
                    id: guid.empty,
                    children: [{ id: guid.newGuid(), name: 'Root', children: [] }],
                }) as ITreeNode;
                setLocalValue(tree);
                return null;
            } else {
                const dto = {
                    entity: EntityType.ContentConfiguration,
                    id: uiSettings.settings.rbsId,
                } as GetDto;
                const response = await ApiCalls.getEntity(dto);
                const structure = response.data as any;
                return sortableTreeHelper.parseToModernTreeNode(structure.rbs);
            }
        } catch (err) {
            setBlockDialog(null, {
                title: 'Error',
                subText: typeof err === 'string' ? err : err.message,
            });
        }
    });

    const onDataChange = useCallback(
        (val: Readonly<ITreeNode>) => {
            setLocalValue(val);
            onChangeForm(fieldName, sortableTreeHelper.parseToLegacyTreeNode(val));
            validate();
        },
        [fieldName, onChangeForm, validate],
    );

    const onSelectionChange = useCallback(
        (val: Readonly<string[]> | undefined) => {
            if (config.mode === 'multiSelect') {
                setLocalValue(val as string[]);
                onChangeForm(fieldName, val);
            } else {
                const singleVal = ArrayEx.firstOrNull(val as string[]);
                setLocalValue(singleVal);
                onChangeForm(fieldName, singleVal);
            }
            validate();
        },
        [config.mode, validate, onChangeForm, fieldName],
    );

    if (isEdit ? localValue == null : rbs == null) {
        return <ShimmerBox height={30} />;
    }

    return (
        <>
            <SortableTreeEditor
                data={isEdit ? (localValue as ITreeNode) : rbs}
                mode={config.mode ?? 'select'}
                readOnly={disabled}
                pathDelimiter={config.pathDelimiter || '.'}
                defaultSelectedNodes={isEdit ? undefined : ArrayEx.ensureArray(localValue as string)}
                expandToLevel={config.expandToLevel ?? 2}
                expandToSelected={true}
                disableOptions={config.disableOptions}
                buttonTitle={config.buttonTitle}
                dialogTitle={config.dialogTitle}
                {...config?.componentProps}
                onSelectionChange={isEdit ? undefined : onSelectionChange}
                onDataChange={isEdit ? onDataChange : undefined}
            />
            <FluentValidationMessage message={validationMessage} />
        </>
    );
};
