export class CountDownConfirm {
	private static _instance: CountDownConfirm;
    private _fireMethod: (Title: string, SubText: string, Count: number, Callback: () => void, DismissCallback: () => void) => void = null;
    
    private constructor() { }

    public static get Instance() {
        return this._instance || (this._instance = new this());
	}
	
	public Register = (addMethod: (title: string, subText: string, count: number, callback: () => void, dismissCallback: () => void) => void): void => {
        this._fireMethod = addMethod;
    }

    public Fire = (title: string, subText: string, count: number, callback?: () => void, dismissCallback?: () => void) => {
        if (this._fireMethod == null) {
 console.warn("There has not initialized a countdown confirm component, so page refresh cannot be fired."); return; 
}
        this._fireMethod(title, subText, count, callback, dismissCallback);
    }
}