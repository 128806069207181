import { PropsWithChildren, ReactElement, useState } from 'react';
import { IObjectEditorItemType } from '../../../Entities/Main/IObjectEditorItemType';
import { PropertyInfoService } from '../../../Services/PropertyInfoService';
import { ArrayEx } from '../../../Utilities/ArrayEx';
import { ObjectEditor } from '../ObjectEditor';
import { WizardStep } from './WizardStep';
import { ObjectEditorWizardStep } from './ObjectEditorWizardStep';
import { StepWizard } from './StepWizard';

interface IProps<T extends IObjectEditorItemType> {
	item: T;
	itemType: { new(): T ;};
	stepDefinitions: Array<ObjectEditorWizardStep>;
	customSteps?: Array<{ index: number, step: WizardStep }>;
	completeCallback?: (item: T) => void;
	fixedHeight?: number;
	customNextButton?: (next: () => void) => JSX.Element;
	customPrevButton?: (prev: () => void) => JSX.Element;
}

export const ObjectEditorStepWizard = <T extends IObjectEditorItemType>(props: PropsWithChildren<IProps<T>>) : ReactElement | null => {

	const [item, setItem] = useState<T>(props.item);

	const buildSteps = () : Array<WizardStep> => {
		if (!props.stepDefinitions?.length) return [];
		const allFields = PropertyInfoService.GetPFProperties(props.itemType).map(_ => _.PropertyName);
		const steps = props.stepDefinitions.map(_ => {

			const step = new WizardStep();
			step.Validate = _.validate ? ctx => _.validate(item, ctx) : null;
			step.content = (next, prev, ctx) =>  {

				// hide props based on wizard properties
				const hiddenProps: Array<string> = ctx.properties.hiddenProps;
				if (hiddenProps?.length)
					ArrayEx.removeMultiple(_.Properties, _ => hiddenProps.indexOf(_) >= 0);

				return <>
						<h3>{_.title}</h3>
						<p>{_.description}</p>
						<ObjectEditor
							key={_.title}
							Item={item}
							DisabledProperties={[]}
							HiddenProperties={ArrayEx.difference(allFields, _.Properties)}
							ChangesCallback={(item, prop, value) => setItem(item)}
							ItemType={props.itemType}
						/>
					</>;
			}
			return step;
		});

		// inject custom steps
		if (props.customSteps?.length) {
			props.customSteps.forEach(_ => {
				steps.splice(_.index, 0, _.step);
			})
		}

		return steps;
	}

	return (
		<StepWizard
			cancelCallback={() => setItem(props.item)}
			completeCallback={() => props.completeCallback(item)}
			steps={buildSteps()}
			fixedHeight={props.fixedHeight}
			customNextButton={props.customNextButton}
			customPrevButton={props.customPrevButton}
		/>
	)
}