import { FunctionComponent, useRef, useState, useEffect } from 'react';
import { ObjectEditor } from './ObjectEditor';
import { PrimaryButton, Label } from '@fluentui/react';
import EntityPropertySelector from './DynamicLoaded/EntityPropertySelector';
import CustomPropertyRender from '../../Utilities/CustomPropertyRender';
import { CustomPropertyUtil } from '../../Utilities/CustomPropertyUtil';
import { OriginService } from '../../Services/OriginService';
import { JsonEx } from '../../Utilities/JsonEx';
import { ObjectEx } from '../../Utilities/ObjectEx';
import { PropertyInfoService } from '../../Services/PropertyInfoService';
import { ArrayEx } from '../../Utilities/ArrayEx';
import { Resource } from '../../Entities/Main/Resource';
import { Project } from '../../Entities/Main/Project';
import { language } from '../../Services/LocalizationService';
import { ResourceWithStringVariants } from '../../ListItems/ResourceWithStringVariants';
import { ProjectWithStringVariants } from '../../ListItems/ProjectWithStringVariants';

interface IProps {
    ItemType: { new (): any };
    HiddenProperties: Array<string>;
    Changes: (templateItem: any, properties: Array<string>) => void;
    SelectCallback: (enabled: boolean) => void;
}

// TODO: make usable for ALL types (ewi)
// currently only project and resoruce is allowed
export const BulkEditor: FunctionComponent<IProps> = props => {
    const HiddenProperties = (): Array<string> => {
        // props that doesn't make sense to bulk edit
        const irrelevantResourceProps = ['id', 'AzureId', 'UserPrincipalName', 'Name', 'RelatedGenericResourceId', 'SpecificWorkprofileId', 'TeamProject'];
        const irrelevantProjectProps = ['id', 'Name', 'Url', 'TeamResource'];
        // get dissallowed props
        const disallowedProps =
            TypeAsString(props.ItemType) === 'project'
                ? irrelevantProjectProps.concat(OriginService.GetDisallowedProjectProperties(true))
                : irrelevantResourceProps.concat(OriginService.GetDisallowedResourceProperties(true));
        return disallowedProps;
    };

    const TypeAsString = (ItemType: { new (): any }): 'resource' | 'project' => {
        if (ItemType === Resource || ItemType === ResourceWithStringVariants) return 'resource';
        if (ItemType === Project || ItemType === ProjectWithStringVariants) return 'project';
        return null;
    };

    const [allProps, setAllProps] = useState<Array<string>>([]);
    const [selectedProps, setSelectedProps] = useState<Array<string>>([]);
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const templateItem = useRef(new props.ItemType());
    const hiddenProps = useRef<Array<string>>(HiddenProperties());
    const customProps = useRef(CustomPropertyUtil.GetProperties(TypeAsString(props.ItemType)));

    useEffect(() => {
        // get all fields
        const allFields = PropertyInfoService.GetPFProperties(props.ItemType).map(_ => _.PropertyName);
        setAllProps(allFields);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const SelectedPropertyChanged = (items: Array<string>): void => {
        setSelectedProps([...items]);
        props.Changes(templateItem.current, [...items]);
        setShowEditor(false);
    };

    const ItemChanged = (property: string, value: any) => {
        const clone = JsonEx.clone(templateItem.current);
        ObjectEx.setNestedPropertyValueByString(clone, property, value);
        templateItem.current = clone;
        props.Changes(clone, selectedProps);
    };

    const RenderFields = () => {
        return (
            <ObjectEditor
                ItemType={props.ItemType}
                Item={templateItem.current}
                HiddenProperties={ArrayEx.difference(allProps, selectedProps)}
                DisabledProperties={[]}
                ChangesCallback={(item, prop, val) => ItemChanged(prop, val)}
                NoGrouping
                DisableHiding
                ReadOnly={false}
            />
        );
    };

    const RenderCustomProperties = () => {
        const customProperties = selectedProps.filter(_ => _.startsWith('CustomProperties.'));
        return customProperties.map(_ => {
            const custompropId = _.replace('CustomProperties.', '');
            const customProperty = customProps.current.find(_ => _.Id === custompropId);
            return (
                <CustomPropertyRender
                    key={custompropId}
                    Item={templateItem.current['CustomProperties'][custompropId]}
                    Parent={{ Created: new Date() }}
                    Property={customProperty}
                    Type={TypeAsString(props.ItemType)}
                    Update={value => ItemChanged(_, value)}
                />
            );
        });
    };

    return (
        <>
            <EntityPropertySelector
                PlaceHolder={language.BulkEditor.SelectPropertiesToEdit}
                EntityType={props.ItemType}
                Item={null}
                PropertyTypes={'Both'}
                FilterSystemProperties
                HiddenProperties={hiddenProps.current}
                Update={(items: Array<string>) => SelectedPropertyChanged(items)}
                DisableEmptyEntry
                Styles={{ root: { width: '100%' } }}
                MultiSelect
            />

            <div className="tp-grouping-content">
                <Label styles={{ root: { fontSize: '10px' } }}>{language.BulkEditor.AllSelectedPropertiesWillBeSetOnTheSelectedItems}</Label>
                <PrimaryButton
                    text={language.Common.Select}
                    onClick={e => {
                        setShowEditor(true);
                        props.SelectCallback(true);
                    }}
                    disabled={showEditor || !selectedProps.length}
                />
                {showEditor && hiddenProps.current.length && (
                    <>
                        {RenderFields()}
                        {RenderCustomProperties()}
                    </>
                )}
            </div>
        </>
    );
};
