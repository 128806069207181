import { fluentUiValidationMessageElement } from '@wiberg/fluentui-builder';
import { IFormItem, IPropertyRenderProps } from '@wiberg/formbuilder';
import { PropsWithChildren, ReactElement, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ApiCalls } from '../../../../api/api';
import { Resource, ResourcePickerDto } from '../../../../api/generated/data-contracts';
import { ResourceType } from '../../../../Entities/ResourceType';
import { stringVariants } from '../../../../helpers/stringVariants';
import { language } from '../../../../Services/LocalizationService';
import EnumEx from '../../../../Utilities/EnumEx';
import { GenericPicker, IPickerItem, IPickerOptions, IPickerSuggestionsOptions } from '../../../xxx-components-refactor/GenericPicker';

import { IResourceFieldConfig } from './config/IResourceFieldConfig';

interface IProps<T extends IFormItem> extends IPropertyRenderProps<T, IResourceFieldConfig<T>, string | string[] | undefined> {
    config?: IResourceFieldConfig<T>;
}

export const ResourceField = <T extends IFormItem>({
    onChange,
    disabled,
    parent,
    value,
    config,
    errorMessage,
}: PropsWithChildren<IProps<T>>): ReactElement | null => {
    const { data: resources } = useQuery(['resourceField', { ...config }], async () => {
        const dto = {
            OnlyActive: config.excludeInactive,
            RbsMatch: config.matchOnRbs,
            ResourceTypes: config.resourceTypes != null ? config.resourceTypes : EnumEx.allAsNumber<ResourceType>(ResourceType),
            OnlyGenericResourcesInUse: config.onlyGenericResourcesInUse,
            OnlyPoolResourcesInUse: config.onlyPoolResourcesInUse,
        } as ResourcePickerDto;
        const response = await ApiCalls.getResourcesForPicker(dto);
        return response.data as Resource[];
    });

    const getFieldStringValue = (item: Resource, field: keyof Resource): string => {
        return stringVariants.getAsStringValue(item, field as string);
    };

    const buildOptions = useCallback(
        (): IPickerOptions<Resource> =>
            ({
                entityGetter: resources == null ? null : async () => resources,
                itemParser: (item: Resource) =>
                    ({
                        id: item.id,
                        displayName: getFieldStringValue(item, config.nameField),
                        description: getFieldStringValue(item, config.descriptionField),
                    } as IPickerItem),
                recentItemsKey: 'resourcepicker-recentlyused',
                multiSelect: config.multiSelect,
                suggestions: {
                    loadingText: language.Common.Loading,
                    noResultsFound: language.Common.NoResults,
                    showRemoveButtons: true,
                    suggestionsHeaderText: language.ResourcePicker.RecentlyUsedResources,
                    suggestionsContainerText: language.Common.Resources,
                    suggestionsAvailableAlertText: language.ResourcePicker.RecentlyUsedResources,
                } as IPickerSuggestionsOptions,
            } as IPickerOptions<Resource>),
        [config, resources],
    );

    const pickerOptions = useMemo(buildOptions, [buildOptions]);

    return (
        <>
            <GenericPicker styles={undefined} item={value} update={values => onChange(values)} pickerOptions={pickerOptions} readOnly={disabled} />
            {errorMessage && fluentUiValidationMessageElement(errorMessage)}
        </>
    );
};
