/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, useState, useEffect } from 'react';
import { PFDialog } from '../../../Common/PFDialog';
import { WithStore } from '../../../../Services/StateStore';
import { StoreProps } from '../../../../Services/StoreProps';
import { PFSpinner } from '../../../Common/Spinner';
import { PFErrorBoundary } from '../../../Common/PFErrorBoundary';
import { OverviewContext } from '../../../../Utilities/Context/OverviewContext';
import { RowMenuItemInfo } from '../../../../Entities/Dto/RowMenuItemInfo';
import { Plugin } from '../../../../Services/PluginInvoker';
import { PluginIDs } from '../../../../Utilities/PluginIDs';
import { ContractInfoDto } from '../../../../Entities/Dto/ContractInfoDto';
import { guid } from '../../../../helpers/guid';
import { language } from '../../../../Services/LocalizationService';
import { ObjectEditorStepWizard } from '../../../Common/Wizard/ObjectEditorStepWizard';
import { buildFACCustomSteps, buildFACEditorSteps } from './components/EditorSteps';
import { DistributionResult } from '../../../../Entities/ActivityDistribution/DistributionResult';
import { ActivityDistributionDto } from '../../../../Entities/ActivityDistribution/ActivityDistributionDto';
import { ActivityDistributionDialog } from '../ActivityDistributionDialog';
import AutoDistributionSettingsDialog from '../../../Common/AutoDistributionSettingsDialog';
import { ActivityDistributionConfig } from '../../../../Entities/ActivityDistribution/ActivityDistributionConfig';
import { EntityType } from '../../../../Entities/EntityTypes';
import { Project } from '../../../../Entities/Main/Project';
import { DataService } from '../../../../Services/DataService';
import { useStore } from '../../../../context/store';

interface IProps extends StoreProps {
    rowMenuItemInfo: RowMenuItemInfo;
}

const FindAvailableCapacityDialog: FunctionComponent<IProps> = props => {
    const { timeline, error } = useStore(store => ({ timeline: store.timeline, error: store.addErrorNotification }));
    const [dto, setDto] = useState<ContractInfoDto>();
    const [loading, setLoading] = useState(false);
    const [deliveryResult, setDeliveryResult] = useState<{ distDto: ActivityDistributionDto; result: DistributionResult }>();
    const [showDeliveryResult, setShowDeliveryResult] = useState(false);
    const [distributionConfig, setDistributionConfig] = useState<ActivityDistributionConfig>(null);
    const [showDistributionConfigDialog, setShowDistributionConfigDialog] = useState(false);

    // on rowmenuitem changed
    useEffect(() => {
        if (props.rowMenuItemInfo == null) return;
        const fetch = async () => {
            await getContractInfo(props.rowMenuItemInfo);
            await fetchDistributionConfig();
        };
        fetch();
    }, [props.rowMenuItemInfo]);

    const fetchDistributionConfig = async (): Promise<void> => {
        const project = await DataService.Get<Project>(EntityType.Project, props.rowMenuItemInfo.ProjectId);
        // get project specific settings - fallback to default settings
        const distSettings = project.ActivityDistributionConfig ?? OverviewContext.Settings.DefaultActivityDistributionConfig;
        setDistributionConfig({ ...distSettings });
    };

    const getContractInfo = async (rowInfo: RowMenuItemInfo) => {
        if (rowInfo == null) return;
        setLoading(true);
        let contractInfo = await Plugin.Invoke<ContractInfoDto>(PluginIDs.GetContractInfo, rowInfo.ContractId ?? guid.empty);
        contractInfo ??= new ContractInfoDto(rowInfo.ProjectId);
        contractInfo.Resolution = timeline.resolution;
        setDto(contractInfo);
        contractInfo.ContractId ??= rowInfo.ContractId ?? guid.newGuid();
        contractInfo.ProjectId ??= rowInfo.ProjectId;
        contractInfo.ContractName ??= rowInfo.Properties.ContractName;
        contractInfo.ResourceId ??= rowInfo.Properties.RequestedResourceId;
        contractInfo.UseScenario = true;
        setLoading(false);
    };

    const dtoOnChange = (item: ContractInfoDto, prop, value) => {
        setDto(item);
    };

    const dismiss = () => {
        props.Store.Set({ FindAvailableCapacityDialog: false, FindAvailableCapacityInfo: null });
        setDto(null);
        setDeliveryResult(null);
        setLoading(false);
    };

    const save = async () => {
        await Plugin.Invoke(PluginIDs.UpdateContractInfo, dto);
        if (props.rowMenuItemInfo.Properties.ProposedWorkCallback) props.rowMenuItemInfo.Properties.ProposedWorkCallback(dto.Work);
        dismiss();
    };

    const distribute = async () => {
        setLoading(true);

        const autoDto = new ActivityDistributionDto();
        autoDto.Start = dto.Start;
        autoDto.End = dto.End;
        autoDto.ContractId = dto.ContractId ?? guid.newGuid();
        autoDto.ProjectId = dto.ProjectId;
        autoDto.ResourceId = dto.ResourceId;
        autoDto.ContractName = dto.ContractName;
        autoDto.ScenarioId = dto.Scenario;
        autoDto.ScenarioName = dto.ScenarioName;
        autoDto.OnlyRequests = true;
        autoDto.Resolution = timeline.resolution;
        autoDto.Work = dto.Work;

        await Plugin.Invoke(PluginIDs.AutoCreateActivities, autoDto);

        setLoading(false);
        dismiss();
        OverviewContext.RefreshProjectCapacity();
    };

    const findDeliveryDate = async (): Promise<void> => {
        setLoading(true);
        const distDto = new ActivityDistributionDto();
        distDto.Start = dto.Start;
        distDto.End = dto.End;
        distDto.ContractId = dto.ContractId ?? guid.newGuid();
        distDto.ProjectId = dto.ProjectId;
        distDto.ResourceId = dto.ResourceId;
        distDto.ContractName = dto.ContractName;
        distDto.ScenarioId = dto.Scenario;
        distDto.ScenarioName = dto.ScenarioName;
        distDto.OnlyRequests = true;
        distDto.Resolution = timeline.resolution;
        distDto.Work = dto.Work;
        distDto.DistributionConfig = distributionConfig;
        distDto.PerformDistribution = false;
        distDto.OnlyRequests = false;

        let result: DistributionResult = null;
        try {
            result = await Plugin.Invoke<DistributionResult>(PluginIDs.AutoCreateActivities, distDto);
        } catch (er) {
            result = null;
            console.log(er);
        }
        setDeliveryResult({ distDto, result });
        setShowDeliveryResult(true);
        setLoading(false);
    };

    const autoAllocate = () => {
        setLoading(false);
        setShowDeliveryResult(false);
        dismiss();
        OverviewContext.RefreshProjectCapacity();
    };

    const editDistributionConfig = () => {
        setShowDistributionConfigDialog(true);
    };

    return (
        props.Store.Get(_ => _.FindAvailableCapacityDialog) && (
            <PFDialog
                title={language.Common.FindAvailableCapacity}
                subText={loading ? '' : ''}
                showDialog={props.Store.Get(_ => _.FindAvailableCapacityDialog)}
                maxWidth={600}
                callback={save}
                dismissCallback={dismiss}
                buttonText="Save"
                okButtonEnabled={deliveryResult != null}
                isBlocking={true}
                noCancelButton
                noOkButton
            >
                <div className="tp-findavailablecapacity-dialog-content">
                    {dto == null ? (
                        <PFSpinner CustomClass="justoverridedefault" />
                    ) : (
                        <PFErrorBoundary ComponentName="Find available capacity" Reset={async () => {}}>
                            <>
                                {dto && (
                                    <>
                                        <ObjectEditorStepWizard
                                            item={dto}
                                            itemType={ContractInfoDto}
                                            completeCallback={item => dtoOnChange(item as ContractInfoDto, null, null)}
                                            stepDefinitions={buildFACEditorSteps(error)}
                                            customSteps={buildFACCustomSteps(dto, save, distribute, findDeliveryDate, editDistributionConfig)}
                                        />
                                    </>
                                )}

                                <ActivityDistributionDialog
                                    dismissCallback={() => setShowDeliveryResult(false)}
                                    callback={autoAllocate}
                                    result={deliveryResult?.result}
                                    resolution={timeline.resolution}
                                    show={showDeliveryResult}
                                    dto={deliveryResult?.distDto}
                                    changeSettings={settings => setDistributionConfig(settings)}
                                    redo={findDeliveryDate}
                                />
                                <AutoDistributionSettingsDialog
                                    callback={config => {
                                        setDistributionConfig(config);
                                        setShowDistributionConfigDialog(false);
                                    }}
                                    dismiss={() => setShowDistributionConfigDialog(false)}
                                    show={showDistributionConfigDialog}
                                    distributionConfig={distributionConfig}
                                    projectId={props.rowMenuItemInfo.ProjectId}
                                />
                            </>
                        </PFErrorBoundary>
                    )}
                </div>
            </PFDialog>
        )
    );
};

export default WithStore<IProps>(FindAvailableCapacityDialog);
