import { Dispatch, SetStateAction, useMemo } from 'react';
import { language } from '../../../Services/LocalizationService';
import { MenuItem } from '../../../_components/OverviewCreator/components/OverviewCommandBar/OverviewCommandBar';
import { deepCopy } from '../../../Utilities/ObjectEx';
import { IFormItem } from 'spark-forms';

export const useRuleOverviewMenuItems = <T extends IFormItem>({
    setItems,
    setIsNewlyCreated,
    preNewItem,
    preCopyItem,
	setOpenImportRulesByApiPanel,
}: {
    setItems: Dispatch<SetStateAction<T[]>>;
    setIsNewlyCreated: (isNew: boolean) => void;
    preNewItem?: (item: T) => void;
    preCopyItem?: (item: T) => void;
	setOpenImportRulesByApiPanel: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    return useMemo((): MenuItem<T>[] => {
        return [
            {
                key: 'new',
                text: language.Common.New,
                iconProps: { iconName: 'Add' },
                onClick: (selectedItems, setPanelItem) => {
                    const emptyItem = {} as T;
                    preNewItem?.(emptyItem);
                    setPanelItem(emptyItem);
                    setIsNewlyCreated(true);
                },
            },
            {
                key: 'newImportByHoliday',
                text: 'Import by holidays', // language.Common.New,
                iconProps: { iconName: 'Add' },
                onClick: (selectedItems, setPanelItem) => {
					setOpenImportRulesByApiPanel(true);
                },
            },
            {
                key: 'edit',
                text: language.Common.Edit,
                iconProps: { iconName: 'Edit' },
                onClick: (selectedItems, setPanelItem) => {
                    setPanelItem(selectedItems[0]);
                },
                shouldDisable: selectedItems => selectedItems?.length !== 1,
            },
            {
                key: 'delete',
                text: language.Common.Delete,
                iconProps: { iconName: 'Delete' },
                onClick: selectedItems => {
                    setItems(items => items.filter(item => !selectedItems.some(selectedItem => selectedItem.id === item.id)));
                },
                shouldDisable: selectedItems => !selectedItems?.length,
            },
            {
                key: 'copy',
                text: language.Common.Copy,
                iconProps: { iconName: 'Copy' },
                onClick: (selectedItems, setPanelItem) => {
                    const item = deepCopy(selectedItems[0]);
                    preCopyItem?.(item);
                    setPanelItem(item);
                    setIsNewlyCreated(true);
                },
                shouldDisable: selectedItems => selectedItems?.length !== 1,
            },
        ];
    }, [preCopyItem, preNewItem, setIsNewlyCreated, setItems, setOpenImportRulesByApiPanel]);
};
