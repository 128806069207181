/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useLayoutEffect, useEffect, useState, useCallback } from 'react';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { hasHorizontalOverflow } from '@fluentui/react/lib/Utilities';
import { useGesture } from '@use-gesture/react';
import { useTheme } from '../../../../Themes/themeContext';
import { groupByResource, useDragCtx } from './dragContext';
import DragHighlighterItem from './DragHighlighterItem';
import { EventEx } from '../../../../Utilities/EventEx';
import { IDraggedItem, IFieldsObserver } from './DragTypes';
import { Cell } from '../../../../Entities/Table/Cell';
import { TDragDirection } from './DragTypes';
import { useStore } from '../../../../context/store';
import { Timeout } from '../../../../types/runtimeTypes';

interface IProps {
    bgColor: string;
    DragHighlighterUpdate?: Array<(cells: Array<{ Cell: Cell; ColumnIndex: number; Value: number }>) => void>;
}

const DragHighlighterElement: React.FC<IProps> = props => {
    const theme = useTheme();
    const dragCtx = useDragCtx();
    const scrollbarWidth = useStore(store => store.ui.scrollbarWidth);

    const [fields, setFields] = useState([]);
    const [dragDirection, setDragDirection] = useState<TDragDirection>({ down: true, right: true });
    const [scrollPos, setScrollPos] = useState({ top: 0, left: 0 });
    const startScroll = useRef({ top: 0, left: 0 });
    const containerHasBottomScrollbar = useRef(false);

    const containerTopStyle = dragCtx.isDragging
        ? dragCtx.draggedItem.top - (scrollPos.top - startScroll.current.top)
        : dragCtx.draggedItem.top + dragCtx.inputSize.height - 6 - (scrollPos.top - startScroll.current.top);
    const containerLeftStyle = dragCtx.isDragging
        ? dragCtx.draggedItem.left - (scrollPos.left - startScroll.current.left)
        : dragCtx.draggedItem.left + dragCtx.inputSize.width - 6 - (scrollPos.left - startScroll.current.left);
    const containerBottomStyle = dragCtx.isDragging
        ? dragCtx.draggedItem.bottom + (scrollPos.top - startScroll.current.top)
        : dragCtx.draggedItem.top + dragCtx.inputSize.width - 6 - (scrollPos.top - startScroll.current.top);
    const containerRightStyle = dragCtx.isDragging
        ? dragCtx.draggedItem.right + (scrollPos.left - startScroll.current.left)
        : dragCtx.draggedItem.left + dragCtx.inputSize.height - 6 + (scrollPos.left - startScroll.current.left);

    const containerBorderColor = dragCtx.isDragging ? theme.palette.accent : 'rgba(0,0,0,0)';

    const containerStyle = mergeStyles({
        backgroundColor: props.bgColor,
        border: `1px solid ${containerBorderColor}`,
        borderRadius: theme.effects.roundedCorner2,
        top: dragDirection.down ? containerTopStyle : 'unset',
        left: dragDirection.right ? containerLeftStyle : 'unset',
        bottom: !dragDirection.down ? containerBottomStyle : 'unset',
        right: !dragDirection.right ? containerRightStyle : 'unset',
    });

    const boxStyle = mergeStyles({
        position: 'absolute',
        bottom: dragCtx.isDragging ? '-2px' : '0px',
        right: dragCtx.isDragging ? '-2px' : '0px',
        height: 6,
        width: 6,
        backgroundColor: theme.palette.accent,
        border: `1px solid ${theme.semanticColors.bodyBackground}`,
        cursor: 'cell',
    });

    // TODO: make stable ref
    let _timer: Timeout;
    const onScroll = useCallback(e => {
        const timer = EventEx.delay(
            _timer,
            () => {
                setScrollPos({ top: e.target.scrollTop, left: e.target.scrollLeft });
            },
            1,
        );
        _timer = timer;
    }, []);

    useEffect(() => {
        containerHasBottomScrollbar.current = hasHorizontalOverflow(document.getElementById(dragCtx.dragContainerClass || 'bottom-main'));
    }, []);

    useLayoutEffect(() => {
        const scrollContainer = document.getElementById(dragCtx.dragContainerClass || 'bottom-main');
        scrollContainer.addEventListener('scroll', onScroll);

        setScrollPos({ top: scrollContainer.scrollTop, left: scrollContainer.scrollLeft });
        startScroll.current = { top: scrollContainer.scrollTop, left: scrollContainer.scrollLeft };

        const newFields = [];
        for (const item of dragCtx.inputNodes.values()) {
            // get overlay-fields and their positions
            if (item.CellIndex !== dragCtx.draggedItem.CellIndex) continue;
            if (item.Cell.Properties.ResourceId === dragCtx.draggedItem.Cell.Properties.ResourceId || item.ColumnIndex === dragCtx.draggedItem.ColumnIndex) {
                const fieldPosition = item.getFieldPosition();
                if (fieldPosition == null) continue;
                // window.innerHeight/width is needed to find the real right/bottom position
                newFields.push({
                    ...item,
                    position: {
                        top: fieldPosition.top,
                        left: fieldPosition.left,
                        right: window.innerWidth - fieldPosition.right,
                        bottom: window.innerHeight - fieldPosition.bottom,
                    },
                });
            }
        }
        setFields(newFields);

        return () => {
            scrollContainer.removeEventListener('scroll', onScroll);
            dragCtx.setIsDragging(false);
        };
    }, []);

    useEffect(() => {
        dragCtx.isDragging &&
            document.getElementById(`tp-capacity${dragCtx.elementIdSuffix ? dragCtx.elementIdSuffix : ''}`).classList?.add('is-dragging-noselect');
        console.log('drag', dragCtx.isDragging);
        return () => document.getElementById(`tp-capacity${dragCtx.elementIdSuffix ? dragCtx.elementIdSuffix : ''}`)?.classList.remove('is-dragging-noselect');
    }, [dragCtx.isDragging, dragCtx.elementIdSuffix]);

    const moveHighlighter = useCallback((height: number, width: number) => {
        document
            .getElementById(`drag_highlighter${dragCtx.elementIdSuffix ? dragCtx.elementIdSuffix : ''}`)
            .setAttribute(
                'style',
                `width: ${width <= dragCtx.inputSize.width ? dragCtx.inputSize.width : width}px; height: ${
                    height <= dragCtx.inputSize.height ? dragCtx.inputSize.height : height
                }px`,
            );
    }, []);

    const bind = useGesture(
        {
            onDragStart: () => {
                dragCtx.setIsDragging(true);
            },
            onDragEnd: () => {

				// Fix for missing cells when they are scroll out of view
				if (dragCtx.dragDirection.current === "right" || dragCtx.dragDirection.current === "left") {
					const highestValueCell = [...dragCtx.selectedFields].sort((a, b) => a.ColumnIndex - b.ColumnIndex)?.[dragCtx.selectedFields.length - 1];
					const lowestValueCell = [...dragCtx.selectedFields].sort((a, b) => a.ColumnIndex - b.ColumnIndex)?.[0];
					dragCtx.inputNodes.forEach((item) => {
						const upperIndexCheck = dragCtx.dragDirection.current === "left" ? item.ColumnIndex <= dragCtx.draggedItem.ColumnIndex : item.ColumnIndex >= dragCtx.draggedItem.ColumnIndex;
						const lowerIndexCheck = dragCtx.dragDirection.current === "left" ? item.ColumnIndex >= lowestValueCell.ColumnIndex : item.ColumnIndex <= highestValueCell.ColumnIndex;
						if (
							item.CellIndex === dragCtx.draggedItem.CellIndex &&
							upperIndexCheck &&
							lowerIndexCheck &&
							`${item.Cell.Properties.ContractId}_${item.Cell.Properties.ResourceId}_${item.Cell.Properties.IsRequested ? "requested" : "allocated"}` === `${dragCtx.draggedItem.Cell.Properties.ContractId}_${dragCtx.draggedItem.Cell.Properties.ResourceId}_${dragCtx.draggedItem.Cell.Properties.IsRequested ? "requested" : "allocated"}` &&
							!dragCtx.selectedFields.find(_ => _.Cell.Id === item.Cell.Id) &&
							item.getIsRendered()
						) {
							dragCtx.selectedFields = [...dragCtx.selectedFields, {...item} as IDraggedItem]
						}
					});

				} else if (dragCtx.dragDirection.current === "up" || dragCtx.dragDirection.current === "down") {
					const allCells = mapAllCells(dragCtx.inputNodes);
					const cellsGroupedByResource = groupByResource(allCells, item => `${item.Cell.Properties.ContractId}_${item.Cell.Properties.ResourceId}_${item.Cell.Properties.IsRequested ? "requested" : "allocated"}`);
					const cellsInArray = Object.values(cellsGroupedByResource);
					const sortedSelectedFields = [...dragCtx.selectedFields].sort((a, b) => cellsInArray.findIndex((row: IDraggedItem[]) => row.find(item =>  item.Cell.Id === a.Cell.Id)) - cellsInArray.findIndex((row: IDraggedItem[]) => row.find(item =>  item.Cell.Id === b.Cell.Id)));
					const highestCellIndex = cellsInArray.findIndex((row: IDraggedItem[]) => row.find(item =>  item.Cell.Id === sortedSelectedFields[0].Cell.Id));
					const lowestCellIndex = cellsInArray.findIndex((row: IDraggedItem[]) => row.find(item =>  item.Cell.Id === sortedSelectedFields[sortedSelectedFields.length - 1].Cell.Id));
					const draggedItemIndex = cellsInArray.findIndex((row: IDraggedItem[]) => row.find(item =>  item.Cell.Id === dragCtx.draggedItem.Cell.Id));
					dragCtx.inputNodes.forEach((item) => {
						const currentCellIndex = cellsInArray.findIndex((row: IDraggedItem[]) => row.find(rowItem =>  item.Cell.Id === rowItem.Cell.Id));
						const upperIndexCheck = dragCtx.dragDirection.current === "up" ? highestCellIndex <= currentCellIndex : draggedItemIndex <= currentCellIndex;
						const lowerIndexCheck = dragCtx.dragDirection.current === "up" ? draggedItemIndex >= currentCellIndex : lowestCellIndex >= currentCellIndex;
						if (
							item.CellIndex === dragCtx.draggedItem.CellIndex &&
							item.ColumnIndex === dragCtx.draggedItem.ColumnIndex &&
							upperIndexCheck &&
							lowerIndexCheck &&
							!dragCtx.selectedFields.find(_ => _.Cell.Id === item.Cell.Id) &&
							item.getIsRendered()
						) {
							dragCtx.selectedFields = [...dragCtx.selectedFields, {...item} as IDraggedItem]
						}
					});
				}

                // save new value to fields
                const items = dragCtx.selectedFields.map(_ => {
                    return {
                        Cell: _.Cell,
                        ColumnIndex: _.ColumnIndex,
                        Value: dragCtx.draggedItem.Cell.Value,
                    };
                });
                // call update function specified in the table implementation
                props.DragHighlighterUpdate[dragCtx.draggedItem.CellIndex](items);

                moveHighlighter(8, 8);
                setFields([]);
                dragCtx.setShowDragBox(false);
                dragCtx.setDraggedItem(null);
            },
            onDrag: ({ down, memo, movement: [mx, my] }) => {
                if (!down) return;
                if (!memo)
                    memo = {
                        top: document.getElementById(dragCtx.dragContainerClass || 'bottom-main').scrollTop,
                        left: document.getElementById(dragCtx.dragContainerClass || 'bottom-main').scrollLeft,
                    };

                const disX = mx + (scrollPos.left - memo.left);
                const disY = my + (scrollPos.top - memo.top);

                if (disX >= 0 && !dragDirection.right) setDragDirection((s: any) => ({ ...s, right: true }));
                if (disX < 0 && dragDirection.right) setDragDirection((s: any) => ({ ...s, right: false }));
                if (disY >= 0 && !dragDirection.down) setDragDirection((s: any) => ({ ...s, down: true }));
                if (disY < 0 && dragDirection.down) setDragDirection((s: any) => ({ ...s, down: false }));

                const calcWidthPartRight = dragDirection.right ? 0 : dragCtx.inputSize.width;
                const calcWidthPartDown = dragDirection.down ? 0 : dragCtx.inputSize.height;
                const isDisY = disY < 0 && disY >= -Math.abs(dragCtx.inputSize.height) && !dragDirection.down;
                const isDisX = disX < 0 && disX >= -Math.abs(dragCtx.inputSize.width) && !dragDirection.right;
                const moveHeightY = isDisY
                    ? // cellHeight
                      dragCtx.inputSize.height
                    : // cellHeight + distanceY - direction down ? 0 : cellHeight
                      dragCtx.inputSize.height + Math.abs(disY) - calcWidthPartDown;

                const moveWidthX = isDisX
                    ? // cellWidth
                      dragCtx.inputSize.width
                    : // cellWidth + distanceX - direction right ? 0 : cellWidth
                      dragCtx.inputSize.width + Math.abs(disX) - calcWidthPartRight;

					  const rightOrLeft = dragDirection.right ? "right" : "left";
					  const upOrDown = dragDirection.down ? "down" : "up";
					  dragCtx.dragDirection.current = Math.abs(disX) <= Math.abs(disY) ? upOrDown : rightOrLeft;

                Math.abs(disX) <= Math.abs(disY)
                    ? moveHighlighter(
                          // is distanceY between 0 & cellHeight and direction up
                          moveHeightY,
                          // cellWidth
                          dragCtx.inputSize.width,
                      )
                    : moveHighlighter(
                          // cellHeight
                          dragCtx.inputSize.height,
                          // is distanceX between 0 & cellWidth and direction left
                          moveWidthX,
                      );
                return memo;
            },
        },
        {
            drag: {
                pointer: {
                    capture: false,
                },
                bounds:
                    dragCtx.elementIdSuffix != null
                        ? {
                              // locks drag axis' to left/right only
                              top: 0,
                              bottom: 0,
                          }
                        : {
                              bottom: window.innerHeight - dragCtx.draggedItem.top - 50 - (containerHasBottomScrollbar.current && scrollbarWidth),
                          },
            },
            // 		drag: { bounds: dragCtx.elementIdSuffix != null
            // 		? {
            // 			// locks drag axis' to left/right only
            // 			top: 0,
            // 			bottom: 0,
            // 		} : {
            // 		bottom: window.innerHeight - dragCtx.draggedItem.top - 50 - (containerHasBottomScrollbar.current && scrollbarWidth)
            // 	}
            // }
        },
    );

    return (
        <div {...bind()} id={`drag_highlighter${dragCtx.elementIdSuffix ? dragCtx.elementIdSuffix : ''}`} className={`drag_highlighter ${containerStyle}`}>
            <div id={`drag_highlighter_inner${dragCtx.elementIdSuffix ? dragCtx.elementIdSuffix : ''}`} className="drag_highlighter_inner">
                {dragCtx.isDragging &&
                    fields.length > 0 &&
                    fields.map((field: IFieldsObserver) => <DragHighlighterItem key={field.Cell.Id} dragDirection={dragDirection} {...field} />)}
            </div>
            {dragCtx.isDragging && <div className={boxStyle} />}
        </div>
    );
};

export default DragHighlighterElement;

const mapAllCells = (inputNodes: any) => {
	const newObj = [];
	for (const value of inputNodes.values()) {
		newObj.push(value);
	}
	return newObj;
};