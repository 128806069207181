
import { GridType } from '../api/generated/data-contracts';
export { GridType } from '../api/generated/data-contracts';

// export enum GridType {
//     Request = 1,
//     Allocation = 2,
//     ResourceCapacity = 4,
//     LmCapacityProjects = 5,
//     ResourcePlanner = 6,
//     WorkPackage = 7,
// 	   ProjectPlanner = 8,
//     // CompareScenariosCapacityRequest = 20,
//     // CompareScenariosCapacityAllocation = 21,
// }

// TODO Refactor - We need to rethink this whole GridType concept. 
// It is a relic from a time before proper client routing and has since
// grown and spread into all sorts of things that doesn't really fall into any grid types
export const getGridTypeFromRoute = (rawPath: string) : GridType => {
	// The first char of location.pathname is always "/",
	// start searching for the next "/" from char 1 and onwards
	let endSlashIndex = rawPath.indexOf("/", 1);
	if (endSlashIndex < 0) endSlashIndex = rawPath.length;
	const path = rawPath.slice(1, endSlashIndex);
	
	// // Cut off the first slash "/whatever/"
	// let path = rawPath.slice(rawPath.indexOf("/") + 1);
	// // Cut off the next slash so we end up with the root path;
	// const endIndex = path.indexOf("/") >= 0
	// 					? path.indexOf("/")
	// 					: path.length;
	// path = path.slice(0, endIndex);

    switch (path) {
        case 'request': {
			return GridType.Request;
        }
		case 'projectplanner':
		case 'allocation': {
			return GridType.Allocation
        }
        case 'workpackages': {
            return GridType.WorkPackage;
        }
        case 'resourcecapacity': {
            return GridType.ResourceCapacity;
        }
		case 'planner': {
            return GridType.LmCapacityProjects;
        }
        default: {
            return null;
        }
    }
}


// export const getGridTypeFromRouteParam = (param?: string): null | {number: number, string: string} => {
//     if (!param) {
//         return null;
//     }
//     const number = Number(param);
//     if (Number.isNaN(number)) {
//         return null;
//     }
//     const type = GridType[number];
//     if (!type) {
//         return null;
//     }
//     // return number;
//     return {
// 		number,
// 		string: type
// 	};
// };

// type GridTypeParam = {
// 	gridType: string;
// };

// export const useGridTypeParam = <T>() => {
//     const { gridType, ...rest } = useParams<GridTypeParam>();
//     const { gridTypeVal, gridTypeString } = useMemo(() => {
// 		const enumVals = getGridTypeFromRouteParam(gridType);
// 		if (enumVals) {
// 			return {
// 				gridTypeVal: enumVals.number,
// 				gridTypeString: enumVals.string
// 			}
// 		}
//         return {
// 			gridTypeVal: null,
// 			gridTypeString: null
// 		};
//     }, [gridType]);
// 	return {
// 		...rest, 
// 		gridType: gridTypeVal,
// 		gridTypeString
// 	} as { gridType: null | number, gridTypeString: string | null } & T
// };

