import { useEffect, useState } from 'react';
import WaitingCache from './Components/Common/WaitingCache';
import { EnableLogging } from './Logging/EnableLogging';
import { apiCache } from './helpers/apiCache';
import useEffectOnce from './hooks/useEffectOnce';
import { WaitingHubContext } from './context/network/websocket/WaitingHubContext';
import { devLogger } from './helpers/devLogger';

EnableLogging();

export const CacheLoader: React.FC = ({ children }) => {
    const [isLoadingCache, setIsLoadingCache] = useState<boolean>(true);
    const [go, setGo] = useState(false);

    // hack until react-query takes over
    useEffect(() => {
        apiCache.init(ready => {
            if (!ready === isLoadingCache) return;
            setIsLoadingCache(!ready);
        });
    }, [isLoadingCache]);

    // hack until react-query takes over - TODO: what did I mean? (ewi)
    useEffectOnce(() => {
        fetch(`${window.location.origin}/auth/options`, {
            redirect: 'manual', // handle all redirects - ie. to login.microsoft.com
            method: 'GET',
        }).then(res => {
            const notReady = res.headers.get('tp-host-ready') === 'False';
            devLogger.log('cacheready', !notReady);
            setIsLoadingCache(notReady);
            setGo(true);
        });
    });

    const initialize = async () => {
        // hack until react-query takes over
        window.location.reload();
    };

    if (!go) return null;

    return (
        <>
            {isLoadingCache ? (
                <WaitingHubContext>
                    <WaitingCache DoneLoadingCache={initialize} />
                </WaitingHubContext>
            ) : (
                children
            )}
        </>
    );
};
