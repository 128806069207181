import { QueryClient, QueryClientProvider } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import shallow from 'zustand/shallow';
import { Project, UIStartupDto } from '../../../../api/generated/data-contracts';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import { UserContext } from '../../../../Utilities/Context/UserContext';
import { LocalStorage } from '../../../../Utilities/LocalStorage';
import { useStore } from '../../../store';
import { upperCaseObj } from './helpers/queryHelper';
import { queryCurrentProject } from './queries/project';
import { queryUiSettings } from './queries/UISettings';
import { CURRENT_PROJECT_KEY, UI_SETTINGS_KEY } from './queryKeys';

// eslint-disable-next-line react-refresh/only-export-components
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

(() => {
    const cachedUIStartup = LocalStorage.get<UIStartupDto>(UI_SETTINGS_KEY);
    if (cachedUIStartup) {
        queryClient.prefetchQuery(UI_SETTINGS_KEY, async () => cachedUIStartup);
		UserContext.Initialize(upperCaseObj({ ...cachedUIStartup.user, Resource: cachedUIStartup.resource, IsDelegated: cachedUIStartup.isDelegated, LoginName: cachedUIStartup.loginName }));
		useStore.getState().setUIStartupLoaded(true)
    } else {
        queryClient.prefetchQuery<UIStartupDto>(UI_SETTINGS_KEY, () => ({ user: {}, resource: { userTypes: [] }, settings: {} }));
    }
})();


export const QueryProvider: React.FC = ({ children }) => {
    const { projectId } = useParams<{ projectId: string }>();
	const { setUIStartupLoaded } = useStore(store => ({
		setUIStartupLoaded: store.setUIStartupLoaded
	}), shallow)
	const navigate = useNavigate();

    useEffectOnce(() => {
		try {
            queryClient.prefetchQuery(UI_SETTINGS_KEY, () => queryUiSettings(navigate, true)).then(() => { // Why skip usercheck here? (ewi)
				setUIStartupLoaded(true)
			});

            const project = LocalStorage.get<Project>(CURRENT_PROJECT_KEY);
			// if the persisted project is the same as the current route, prefetch it asap
            const shouldPrefetch = projectId && projectId === project?.id;
            if (shouldPrefetch) {
                // prefetch
                queryClient.prefetchQuery([CURRENT_PROJECT_KEY, projectId], queryCurrentProject);
            }
        } catch (error) {
            console.log('🚀 ~ startPrefetching ~ error', error);
        }
    });

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
