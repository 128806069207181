import { DirectionalHint } from "@fluentui/react";
import React, { FunctionComponent } from "react";
import { language } from "../../Services/LocalizationService";
import { CoachmarkInfo } from "../CoachmarkInfo";
import { PFCoachmark } from "../PFCoachmark";


export const ProjectCapacityCoachmarks: FunctionComponent<any> = React.memo(props => {
	const _projectGroupBy = new CoachmarkInfo("project-capacity-group-by", ".tp-capacity-project-menucontainer", language.Coachmarks.ProjectCapacity.GroupBy, DirectionalHint.bottomCenter)

	return	<>
				<PFCoachmark CoachmarkInfo={_projectGroupBy} />
			</>
})



export const ResourceCapacityCoachmarks: FunctionComponent<any> = React.memo(props => {
	const _requestWorkPackages = new CoachmarkInfo("project-capacity-work-packages", ".tp-capacity-project-menucontainer", language.Coachmarks.ProjectCapacity.RequestWorkPackages, DirectionalHint.rightCenter);

	return	<>
				<PFCoachmark CoachmarkInfo={_requestWorkPackages} />
			</>
})