/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo } from 'react';
import { DebugOverrides } from '../../../../Utilities/DebugOverrides';
import { DynamicProps } from './DynamicProps';
import * as components from './dynamicComponentsMap';

type IProps = DynamicProps<any, any>;

const DynamicComponent: FunctionComponent<IProps> = props => {
    const Comp: any = components[props.PropertyInfo.CustomComponentName] as any;

    return (
        <Comp
            {...props.PropertyInfo.ComponentProps}
            ReadOnly={props.ReadOnly && !DebugOverrides.Enabled} // override readonly, making ALL properties editable
            Item={props.Value}
            Parent={props.Parent}
            Update={value => props.OnChange(props.PropertyInfo.PropertyName, value)}
            key={props.Key}
        />
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    if (prevProps.Value?.ForceRerender) return false; // Major hack for forcing re-render of dynamic components (ewi)
    return prevProps.PropertyInfo.PropertyName === nextProps.PropertyInfo.PropertyName;
};

export default memo(DynamicComponent, comparisonFn);
