/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo } from 'react';
import { Slider } from '@fluentui/react';
import { DynamicProps } from './DynamicProps';

interface IProps extends DynamicProps<number, number> {
    Max: number;
    Format?: (value: number) => string;
    HideValue?: boolean;
    Step?: number;
    OnChangeEvent?: (newValue: number) => void;
}

const DynamicSlider: FunctionComponent<IProps> = props => {
    return (
        <Slider
            key={props.Key}
            disabled={props.ReadOnly}
            max={props.Max}
            step={props.Step}
            snapToStep={props.Step != null}
            defaultValue={props.Value}
            valueFormat={(value: number) => (props.Format ? props.Format(value) : value + '')}
            showValue={!props.HideValue}
            onChanged={(event, value: number) => props.OnChange(props.PropertyInfo.PropertyName, value)}
            onChange={props.OnChangeEvent}
        />
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Value === nextProps.Value;
};

export default memo(DynamicSlider, comparisonFn);
