import ReactDOM from 'react-dom';
import './Styles/index.scss';
import { initializeIcons } from '@uifabric/icons';
import * as DragDropTouch from './DragDropTouch';
import { IdleContext } from './context/IdleContext';
import { ExternalNotificationServiceContext } from './context/network/websocket/ExternalNotificationServiceContext';
import { PFErrorBoundary } from './Components/Common/PFErrorBoundary';
import { QueryProvider } from './context/network/http/QueryProvider/QueryProvider';
import { CacheLoader } from './CacheLoader';
import { AuthProvider } from './context/network/auth/AuthProvider';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './Themes/themeContext';
import { IsDraggingContext } from './context/drag';

DragDropTouch.default.Enable();
initializeIcons(/* optional base url */);
const target = document.getElementById('root');
ReactDOM.render(
    <PFErrorBoundary>
		<BrowserRouter>
			<CacheLoader>
				<AuthProvider>
					<QueryProvider>
						<ThemeProvider>
							<IdleContext>
								<ExternalNotificationServiceContext>
									<IsDraggingContext>
										<App />
									</IsDraggingContext>
								</ExternalNotificationServiceContext>
							</IdleContext>
						</ThemeProvider>
					</QueryProvider>
				</AuthProvider>
			</CacheLoader>
		</BrowserRouter>
    </PFErrorBoundary>,
    target,
);
