import { LogLevel, LogOptions } from 'projectum-logging';
import { ConsoleLogger } from 'projectum-logging/lib/LogProviders/ConsoleLogger';
import { LogProviderOptions } from 'projectum-logging/lib/Models/LogOptions';
import { logging } from 'projectum-logging/lib/Services/LogManager';

export const EnableLogging = () => {
    logging
        .Configure({ Global: { LogLevels: { Default: LogLevel.Error } } } as Partial<LogOptions>)
        .RegisterLogProvider(new ConsoleLogger(), { LogLevels: { Default: LogLevel.Error, SignalRHub: LogLevel.Critical } } as LogProviderOptions);
};
