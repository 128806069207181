/* eslint-disable no-useless-escape */
/** Used for making sure that the date is ALWAYS at the time 00:00
 *  Local time in the browser as 00:00
 *  UTC time sent to the service as 00:00
 */

import { DateEx } from "./DateEx";
import { StringEx } from "./StringEx";

export function stringify(content: any, spacer: number = 2): string {
	return JSON.stringify(content, dateStringifier, spacer);
}

// ensure that all dates are parsed as UTC
const dateStringifier = function(key: string, value: any) : string {

	if (this[key] instanceof Date) {
		return DateEx.specifyUtc(this[key]);
	} else if (typeof(this[key]) === 'string') {
		// https://regex101.com/r/ufFBTA/2
		const a = /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d+|)(?:Z|(?:\+|\-)(?:\d{2}):?(?:\d{2}))/.exec(value);
		if (a == null || a[0].length !== value.length)
			return value;
		return DateEx.specifyUtc(new Date(a[0]));
	}
	
	return value;
}

export function parse<T>(content: string): T {
	return JSON.parse(content, dateParser) as T;
}

export function parseAdvanced<T>(content: string, type: { new(): T; }, cloneAction?: (source: T, target: T) => void): T {
	cloneAction = cloneAction || ((source: T, target: T) => Object.assign(target, source));
	const obj = JSON.parse(content, dateParser) as T;
	const clone = new type() as T;
	cloneAction(obj, clone);
	return clone;
}

export function tryParse<T>(objString: string) {
	if (StringEx.isNullOrWhiteSpace(objString))
		return null;

	try {
		const jsonObj = parse<T>(objString);
		return jsonObj;
	} catch {
		return null;
	}
}

/**
 * @link https://regex101.com/r/ufFBTA/2
 * 
 * In JavaScript and TypeScript, regular expressions are compiled the first time they're used. 
 * If you're using this regex multiple times, make sure you're not re-creating it each time. 
 * Store it in a constant and reuse it.
 * 
 * NOTE: Some operations/methods mutates the regex, clone the regex if such methods are needed:
 * @example
 * new RegExp(dateRegex);
 */
const dateRegex = /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d+|)(?:Z|(?:\+|\-)(?:\d{2}):?(?:\d{2}))/;

export function dateParser(key: any, value: any): any {
	if (typeof value !== 'string')
		return value;
	// don't parse these to dates as they are intended to be strings
	if (key.endsWith("AsString"))
		return value;

	// check for a string version of a date
	const dateMatch = value.match(dateRegex);
	if (dateMatch && dateMatch[0] === value) {
		// The slice is here to remove the timezone offset or UTC identifier
		return new Date(value.slice(0, -1));
	} else {
		return value;
	}
}

export function clone<T>(obj: T): T {
	if (obj === undefined) {
		return undefined;
	}
	return parse<T>(stringify(obj));
}

export function tryStringify(obj: any) {
	if (obj == null)
		return "";

	try {
		const jsonObj = stringify(obj);
		return jsonObj;
	} catch {
		return "";
	}
}

export const JsonEx = {
	clone,
	dateParser,
	dateStringifier,
	parse,
	parseAdvanced,
	stringify,
	tryParse,
	tryStringify,
}