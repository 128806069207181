import { DirectionalHint } from "@fluentui/react";
import React, { FunctionComponent } from "react";
import { language } from "../../Services/LocalizationService";
import { CoachmarkInfo } from "../CoachmarkInfo";
import { PFCoachmark } from "../PFCoachmark";


export const TimelineCoachmarks: FunctionComponent<any> = React.memo(props => {
	const _timelineResolution = new CoachmarkInfo("timeline-resolution", "#timelinemenubutton", language.Coachmarks.Generic.TimelineResolution, DirectionalHint.rightTopEdge);
	const _timelineTable = new CoachmarkInfo("timeline-table", "#tp-timeline-modern-table", language.Coachmarks.Generic.TimelinePeriod, DirectionalHint.bottomCenter);

	return	<>
				<PFCoachmark CoachmarkInfo={_timelineResolution} />
				<PFCoachmark CoachmarkInfo={_timelineTable} />
			</>
})