import { MessageBarType } from '@fluentui/react';
import { fieldOptionsFactory, IFormSchema } from 'spark-forms';
import { Project, ProjectType, ResourceType } from '../../api/generated/data-contracts';
import { getcustomPropertiesSchema } from '../../components_new/customPropertiesSchema';
import { settings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { language } from '../../Services/LocalizationService';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { DropdownOptionEx } from '../../Utilities/DropdownOptionEx';
import { StringEx } from '../../Utilities/StringEx';
import { validateCustomProperties } from '../customProperty/helpers/customPropertyUtil';
import { OriginIdRender } from '../_builders/new/components/OriginIdRender';
import { OriginRender } from '../_builders/new/components/OriginRender';
import { systemFieldsGrouped, tpFieldOptionsFactory } from '../_builders/new/tpFieldOptionsFactory';

export const projectSchema: IFormSchema<Project> = {
    groups: {
        basic: { displayName: 'Basic', groupProps: { icon: 'EntitlementPolicy', selected: true } },
        owners: { displayName: 'Owners', groupProps: { icon: 'Group' } },
        customProperties: { displayName: 'Custom properties', groupProps: { icon: 'DataManagementSettings' } },
        rbs: { displayName: 'RBS', groupProps: { icon: 'BulletedTreeList' } },
        parent: { displayName: 'Parent', groupProps: { icon: 'DependencyAdd' } },
        system: { displayName: 'System', groupProps: { icon: 'Settings' } },
    },
    fields: {
        isActive: tpFieldOptionsFactory.messageBar({
            displayName: 'Active',
            group: 'basic',
            description: language.Project.ActiveDescription,
            config: {
                options: (item: Project) => {
                    return {
                        message: item.isActive ? language.Project.ActiveState : language.Project.InActiveState,
                        type: item.isActive ? MessageBarType.success : MessageBarType.warning,
                        width: '100%',
                    };
                },
            },
            hideLabel: true,
        }),
        name: fieldOptionsFactory.string({
            displayName: language.Common.Name,
            group: 'basic',
            validation: {
                validationRule: async (item: Project) => {
                    const test = StringEx.isNullOrWhiteSpace(item.name) ? language.Project.ProjectValidation : null;
                    return test;
                },
                validationMark: 'required',
            },
        }),
        projectType: fieldOptionsFactory.predefinedArray({
            displayName: language.Project.ProjectType,
            group: 'basic',
            config: { predefinedOptions: { options: DropdownOptionEx.enumToDropdownOptions<ProjectType>(ProjectType) } },
        }),
        start: fieldOptionsFactory.date({
            displayName: language.Project.Start,
            group: 'basic',
            hide: (item: Project) => item.projectType !== ProjectType.Default,
        }),
        end: fieldOptionsFactory.date({
            displayName: language.Project.End,
            group: 'basic',
            hide: (item: Project) => item.projectType !== ProjectType.Default,
        }),
        url: fieldOptionsFactory.string({ displayName: language.Common.Url, group: 'basic' }),
        owner: tpFieldOptionsFactory.resource({
            displayName: language.Common.Owner,
            group: 'owners',
            config: { nameField: 'name', excludeInactive: true, descriptionField: 'email', resourceTypes: [ResourceType.Named] },
            validation: {
                validationRule: async (item: Project) => {
                    const test = StringEx.isNullOrWhiteSpace(item.owner) ? language.Project.OwnerIsRequiredField : null;
                    return test;
                },
                validationMark: 'required',
            },
        }),
        additionalOwners: tpFieldOptionsFactory.resource({
            displayName: language.Common.AdditionalOwners,
            group: 'owners',
            config: { multiSelect: true, nameField: 'name', excludeInactive: true, descriptionField: 'email', resourceTypes: [ResourceType.Named] },
        }),
        additionalOwnersGroup: tpFieldOptionsFactory.aadGroup({
            displayName: language.Common.AdditionalOwnersAADGroup,
            group: 'owners',
            hide: () => !settings().enableProjectOwnerADGroup,
            config: { multiSelect: false, nameField: 'displayName', descriptionField: 'description' },
        }),
        parentId: tpFieldOptionsFactory.project({
            displayName: language.Project.ParentProject,
            group: 'parent',
            description: language.Project.ParentProjectDescription,
            config: {
                nameField: 'name',
                descriptionField: 'owner',
                pickerType: 'tag',
                projectTypes: [ProjectType.Default, ProjectType.Administrative, ProjectType.Team],
            },
            validation: {
                validationRule: async item => {
                    if (settings().forceParentOnAdministrativeProjects && StringEx.isNullOrWhiteSpace(item.parentId)) {
                        return language.Project.AdministrativeProjectMustHaveParentProject;
                    }
                    return null;
                },
            },
        }),
        customProperties: fieldOptionsFactory.formItem({
            displayName: language.Common.CustomPropertiesCapitalized,
            group: 'customProperties',
            description: 'Custom properties',
            config: {
                schema: {
                    schemaProvider: () => getcustomPropertiesSchema('project'),
                },
            },
            validation: {
                validationMark: 'none',
                validationRule: item => validateCustomProperties(item, 'project'),
            },
        }),
        rbs: tpFieldOptionsFactory.rbs({
            displayName: language.Common.Rbs,
            group: 'rbs',
            config: { mode: 'select' },
            hide: (item: Project) => !OverviewContext.Settings.ProjectsAlwaysShowRBS && item.projectType === ProjectType.Default,
        }),
        additionalRBS: tpFieldOptionsFactory.rbs({
            displayName: language.Common.AdditionalRbs,
            group: 'rbs',
            config: { mode: 'multiSelect' },
            hide: (project: Project) => project.projectType === ProjectType.Default,
        }),
        originIds: fieldOptionsFactory.customComponent({
            displayName: language.Common.OriginIds,
            group: 'system',
            config: { component: OriginIdRender },
        }),
        origins: fieldOptionsFactory.customComponent({
            displayName: language.Common.Origins,
            group: 'system',
            disable: () => true,
            config: { component: OriginRender },
        }),
        ...systemFieldsGrouped(),
    },
};
