export default class Locale_Resource {
	ResourceType: string = "Resource type";
	ResourceTypeIsRequired: string = "Resource Type is a required field.";
	RelatedToGenericResource: string = "The resource's related generic resource.";
	MainWorkProfile: string = "Main Work profile";
	EarliestAvailable: string = "Earliest available";
	LatestAvailable: string = "Latest available";

	WorkingPercent: string = "Working percent";
	WorkingPercentDescription: string = "Working percent is a decimal field between 0 and 1";
	WorkingPercentValidation: string = "The number needs to be between 0 and 1";
	Utilization: string = "Utilization";
	UtilizationDescription: string = "Utilization is a decimal field between 0 and 1";
	UtilizationValidation: string = "The number needs to be between 0 and 1";

	UserTypes: string = "User types";
	UserPrincipalName: string = "UserPrincipalName";

	RbsDescription: string = "The resource's main RBS.";
	RbsValidation: string = "The resource is missing an RBS";

	CustomPropertyValidation: string = "All required properties must be set!";

	ResourcePoolMatcher: string = "Resource pool matcher";
	ResourcePoolMatcherDescription: string = "A selector for matching resources with this Pool Resource.";
	ExcludedFromPools: string = "Excluded from pools";

	EnableSpecificWorkProfile: string = "Enable specific work profile";
	EnableSpecificWorkProfileDescription: string = "Weekhours in this specific work profile, will override the weekhours on the base work profile! Rules in this specific work profile and rultes in the base workprofile will be handled according to the general settings of Team Planner.";

	SpecificWorkProfile: string = "Specific work profile";
	
	TeamProject: string = "Team project";
	LastSignIn: string = "Last sign in";

	ActiveDescription: string = "Resources can be activated and deactivated from the 'Additional actions' button in the overview action bar";
	
	ActiveState: string = "Resource is Active";
	InActiveState: string = "Resource is Inactive";

	Manager: string = "Manager";
}