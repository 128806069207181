import { Text, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useMemo } from 'react';
import { SubHeaderRow } from '../../../../../../api/generated/data-contracts';




export const SubHeader = ({ subHeader: { name } }: { subHeader: SubHeaderRow; }) => {
	const id = useId('sub-header');
	const tooltipContent = useMemo(() => (name === 'Allocation' ? 'Allocation on the contract' : 'Unfulfilled request on the contract'), [name]);
	return (
		<TooltipHost content={tooltipContent} styles={{ root: { width: '100%', display: 'flex', justifyContent: 'center' } }}>
			<Text
				id={id}
				styles={{
					root: {
						fontWeight: 600,
						//
						// fontSize: 12,
						// textAlign: 'center',
					},
				}}
			>
				{name}
			</Text>
		</TooltipHost>
	);
};
