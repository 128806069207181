import { guid } from "../helpers/guid";

export class TreeItem {
	public Id: string;
    public FullName: string;
    public Name: string;
	public Children: Array<TreeItem>;
	
	constructor() {
		this.Id = guid.newGuid();
		this.Children = [];
	}
}