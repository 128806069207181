import { memo, useMemo, useRef } from 'react';
import { useForm } from 'spark-forms';
import { FluentLabel, NavIconGrouping, NavIconGroupingContainer } from 'spark-forms-fluent8';
import { Resource } from '../../../../../api/generated/data-contracts';
import { resourceSchema } from '../../../../../forms/resource/resourceSchema';
import { originHelper } from '../../../../../forms/_builders/new/helpers/origins/originHelper';
import { TPBuilder } from '../../../../../forms/_builders/new/TPBuilder';
import { formUnrestricted } from '../../../../../Utilities/DebugOverrides';
import { useOverviewContext } from '../../../../../_components/OverviewCreator/context/OverviewCreatorContext';

export const ResourceForm = memo(() => {
	const { item, setItem, setValidation } = useOverviewContext<Resource>();

	const localItem = useRef(item).current;

	const builderOptions = useMemo(() => ({
		labelComponent: FluentLabel
	}), []);

	const overrides = useMemo(() => {
		return formUnrestricted<Resource>({ disabledProps: originHelper.getDisallowedResourceFields() });
	}, [])

	const schema = useMemo(() => resourceSchema, []);
	
    const { Form, validateItem } = useForm({
		item: localItem,
		onChange: setItem,
		schema,
		builder: TPBuilder,
		builderOptions,
		groupingContainer: NavIconGroupingContainer,
		grouping: NavIconGrouping,
		fieldOverrides: overrides
	});
	
	setValidation(validateItem);

    return <Form />
});
