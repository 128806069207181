import { getFocusStyle } from '@fluentui/react/lib/Styling';
import { IButtonStyles, IButtonProps } from '@fluentui/react/lib/Button';
import { darkColors } from '../colors.default';

export const CommandBarButtonStyles = (props: IButtonProps): Partial<IButtonStyles> => {
	const { theme } = props;
	const { palette } = theme;

	return {
		icon: {
			color: palette.themePrimary,
		},
		iconChecked: {
			color: palette.themePrimary,
		},
		iconDisabled: {
			color: palette.themePrimary,
		},
		iconExpanded: {
			color: palette.themePrimary,
		},
		iconExpandedHovered: {
			color: palette.themePrimary,
		},
		iconPressed: {
			color: palette.themePrimary,
		},
		iconHovered: {
			color: palette.themePrimary,
		},
		menuIcon: {
			color: palette.whiteTranslucent40,
		},
		menuIconHovered: {
			color: palette.neutralLight,
		},
		menuIconExpanded: {
			color: palette.neutralLighter,
		},
		menuIconExpandedHovered: {
			color: palette.neutralLighter,
		},
		menuIconChecked: {
			color: palette.neutralLighter,
		},
		menuIconPressed: {
			color: palette.neutralLighter,
		},
		root: {
			...getFocusStyle(theme, { inset: 2 }),
			backgroundColor: darkColors.bg,
			color: palette.neutralLighter,
		},
		rootHovered: {
			backgroundColor: darkColors.bgHover,
			color: palette.neutralLighter,
		},
		rootExpanded: {
			backgroundColor: darkColors.bgHover,
			color: palette.neutralLighter,
		},
		rootExpandedHovered: {
			backgroundColor: darkColors.bgExpandedHovered,
			color: palette.neutralLighter,
		},
		rootPressed: {
			backgroundColor: darkColors.bgExpandedHovered,
			color: palette.neutralLighter,
		},
		rootChecked: {
			backgroundColor: darkColors.bgActive,
			color: palette.neutralLighter,
		},
		rootCheckedHovered: {
			backgroundColor: darkColors.bgActiveHovered,
			color: palette.neutralLighter,
		},

	};
};