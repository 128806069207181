import { CustomProperty } from "../Entities/ContentConfiguration/CustomProperty";
import { OverviewContext } from "./Context/OverviewContext";

// Used by CustomPropertiesSelector.tsx and BulkEditor.tsx
export class CustomPropertyUtil {
	public static GetProperties = (type: "resource" | "project" | "contract"): Array<CustomProperty> => {
		switch (type) {
			case "resource":
				return OverviewContext.Settings.ResourceCustomProperties;
			case "project":
				return OverviewContext.Settings.ProjectCustomProperties;
			case "contract":
				return OverviewContext.Settings.ContractCustomProperties;
			default:
				return [];
		}
	}
}