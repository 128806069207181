import { settings } from "../../../context/network/http/QueryProvider/queries/UISettings";
import { getDisallowedCustomProperties } from "../../_builders/new/helpers/origins/getDisallowedCustomProperties";

export const customPropertyUtil =  {
	customProperties: (type: "resource" | "project" | "contract") => {
		if (type === "resource") return settings().resourceCustomProperties || [];
		if (type === "project") return settings().projectCustomProperties || [];
		if (type === "contract") return settings().contractCustomProperties || [];
		return [];
	}
}

export const validateCustomProperties = <T extends { customProperties?: any }>(item: T, type: 'project' | 'resource' | 'contract') => {
	const customProperties = customPropertyUtil.customProperties(type);
	const disabledProperties = getDisallowedCustomProperties(type, true);
	
	for (const cp of customProperties) {
		const disabled = disabledProperties.indexOf(cp.id) >= 0;
		if (disabled) {
			continue;
		}
		if (cp.required && item?.customProperties?.[cp.id] == null) {
			return 'Required fields not set';
		}
		return null;
	}
}