import { createContext } from "../../hooks/createContext";


export type FilterDropdownMultiSelectContext = {
    onFilterInput: (str: string) => void;
}

const [useCtx, Provider] = createContext<FilterDropdownMultiSelectContext>();

export const useFilterDropdown = useCtx;

export const FilterDropdownMultiSelectContextProvider = Provider;