import { guid } from './guid';

const isObject = (obj: any) => obj === Object(obj) && !isArray(obj) && typeof obj !== 'function' && !(obj instanceof Date);

const isArray = (arr: any) => Array.isArray(arr);

const pascalize = (str: string) => {
    if (guid.isGuid(str)) {
        return str;
    }
    return str.replace(/^\w/, c => c.toUpperCase());
};

const camelize = (str: string) => str.replace(/^\w/, c => c.toLowerCase());

const changeCaseOnKeys = (obj: any, changer: (str: string) => string, skip?: (str: string) => boolean) => {
    if (obj == null) return null;
    if (isObject(obj)) {
        const n = {};

        Object.keys(obj).forEach(k => {
            if (!skip?.(k)) {
                // const propName = changer(k);
                n[changer(k)] = changeCaseOnKeys(obj[k], changer);
                // Object.defineProperty(n, { [propName]: { configurable: true, writable: true, value: changeCaseOnKeys(obj[k], changer) } });
                // Object.defineProperty(n, propName, { configurable: true, writable: true, value: changeCaseOnKeys(obj[k], changer) });
                // Object.defineProperty()
            }
        });

        return n;
    } else if (isArray(obj)) {
        return obj.map(i => {
            return changeCaseOnKeys(i, changer);
        });
    }

    return obj;
};

const changeArrayValues = (arr: string[], changer: (str: string) => string) => {
    if (!isArray(arr)) return;
    return arr.map(entry => (guid.isGuid(entry) ? entry : changer(entry)));
};

export const changeCase = {
    toCamel: (obj: { [key: string]: any }) => changeCaseOnKeys(obj, camelize),
    toPascal: (obj: { [key: string]: any }, skip?: (str: string) => boolean) => changeCaseOnKeys(obj, pascalize, skip),
    arrayStringsToCamel: (arr: string[]) => changeArrayValues(arr, camelize),
    arrayStringsToPascal: (arr: string[]) => changeArrayValues(arr, pascalize),
    stringToCamel: (str: string) => (str == null ? null : camelize(str)),
    stringToPascal: (str: string) => (str == null ? null : pascalize(str)),
};
