import { UnitType } from "../Entities/UnitType";
import { IExtendedTheme } from "../Themes/Theme.types";
import { OverviewContext } from "./Context/OverviewContext";
import { UserContext } from "./Context/UserContext";

export class CapacityUtil {
	public static GetFreeCapacityColor = (theme: IExtendedTheme, value: number): string => {
		if (value == null) return "";
		value = parseFloat(CapacityUtil.GetValueWithDecimals(value));

		if (value < 0)
			return theme.semanticColors.capacityOverBackground;
		else if (value > 0)
			return theme.semanticColors.capacityUnderBackground;
		else if (value === 0)
			return theme.semanticColors.capacityEqualBackground;
		return "";
	}

	public static GetValueWithDecimals = (value: number) : string => {
		if (value == null) return null;
		switch (UserContext.Resource.PreferredUnitType) {
			case UnitType.FTE:
				return value.toFixed(OverviewContext.Settings.GridDecimalsFTE);
			case UnitType.Hours:
			default:
				return value.toFixed(OverviewContext.Settings.GridDecimalsHours);
		}
	}

}