import 'reflect-metadata';
import { PropertyType } from './PropertyType';
import { PropertyInfoAreas } from './PropertyInfoAreas';

export class PropertyBasicInfo { 
	constructor(public Type: PropertyType, public PropertyName: string, public DisplayName: string, public Order: number, public TooltipText: string) {}
	public static PropertyInfoKey: PropertyInfoAreas = PropertyInfoAreas.BasicInfo;
}

export const PFPropertyBasicInfo = (type: PropertyType, displayName: string = null, order: number = 1000, tooltipText?: string): PropertyDecorator => {
    return (target, property) => {
      const propertyConstructor = target.constructor;
	  const metadata = Reflect.getMetadata(PropertyBasicInfo.PropertyInfoKey, propertyConstructor) || {};
      metadata[property] = new PropertyBasicInfo(type, property as string, displayName || property as string, order, tooltipText);
      Reflect.defineMetadata(PropertyBasicInfo.PropertyInfoKey, metadata, propertyConstructor);
    };
};
