import { guid } from "../helpers/guid";
import { TreeItem } from "../Entities/TreeItem";

export class TreeItemEx {

	public static GetItem = (root: TreeItem, id: string) : TreeItem => {
		if (!id) return null;

		// traverse tree without recursion
		const stack = new Array<TreeItem>();
		stack.push(root);
		while (stack.length > 0) {
			const current = stack.pop();
			if (current.Id === id)
				return current;

			current.Children.forEach(_ => stack.push(_));
		}
		return null;
	}
	
	public static GetItemName = (root: TreeItem, id: string) : string => {
		const rbs = TreeItemEx.GetItem(root, id);
		return rbs ? rbs.Name : "";
	}

	public static UpdateChildFullNames = (rbs: TreeItem, oldName: string, newName: string) : void => {
		TreeItemEx.UpdateChildsRecursive(rbs, (item: TreeItem) => item.FullName = item.FullName.replace(`${oldName}.`, `${newName}.`));
	}

	public static UpdateChildsRecursive = (rbs: TreeItem, action: (item: TreeItem) => void) : void => {
		if (!rbs || !rbs.Children) return;
		rbs.Children.forEach(_ => {
			action(_);
			TreeItemEx.UpdateChildsRecursive(_, action);
		});
	}

	public static CreateRoot = () : TreeItem => {
		const rbs = new TreeItem();
		rbs.Id = guid.empty;
		rbs.Name = "Root";
		rbs.Children = [];
		return rbs;
	}

	public static Count = (rbs: TreeItem) : number => {
		let count: number = 0;

		// traverse tree without recursion
		const stack = new Array<TreeItem>();
		stack.push(rbs);
		while (stack.length > 0) {
			count++;
			const current = stack.pop();
			current.Children.forEach(_ => stack.push(_));
		}
		return count - 1; // substract root
	}
}