import { IconButton, Stack, TextField } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IFieldRenderProps, useFormContext, useWatch } from "spark-forms";
import { IResourceFieldConfig } from "../Fields/IResourceFieldConfig";
import { ResourceField } from "../Fields/ResourceField";

const meTemplate = "[[Me]]";

export const ResourceWithMeFilterField: React.FC<any> = <T,>({
	value,
    disabled,
    options,
    fieldName,
	path
}: IFieldRenderProps<T, IResourceFieldConfig<T>, string | undefined>) => {

	const { onChange } = useFormContext();

    const [localValue, setLocalValue] = useState(value);

	useWatch(path, () => setLocalValue(value), [value]);

	useEffect(() => {
		setLocalValue(value);
	}, [value])
	
    const onLocalChange = useCallback((value: string) => {
        setLocalValue(value);
        onChange(fieldName, value);
    }, [fieldName, onChange]);

	const meUsed = useMemo(() => localValue === meTemplate, [localValue]);

	const useMe = useCallback(() => {
		if (!meUsed)
			onLocalChange(meTemplate);
		else
			onLocalChange(null);
	}, [meUsed, onLocalChange]);

	return 	(
		<Stack horizontal>
			{
				meUsed
					? <TextField value="Me" disabled />
					: <ResourceField
						value={localValue}
						disabled={disabled}
						options={options}
						fieldName={fieldName}
						path={path}
						config={options?.config}
					/>
			}
			<IconButton
				iconProps={
					!meUsed ? 
					{ iconName: 'UserFollowed' } :
					{ iconName: 'Cancel' }
				}
				title={!meUsed ? "Use dynamic 'Me' value" : "Clear dynamic 'Me' value"}
				onClick={useMe}
			/>
		</Stack>
	)
}
