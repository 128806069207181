import { DynamicQuery } from "../Entities/DynamicQueryBuilder/DynamicQuery";
import { Lookup } from "../Entities/ContentConfiguration/Lookup/Lookup";
import { DataService } from "./DataService";
import { EntityType } from "../Entities/EntityTypes";
import { ContentConfigurationType } from "../Entities/ContentConfiguration/ContentConfigurationType";
import { RbsStructure } from "../Entities/ContentConfiguration/RbsStructure";

export class ContentConfigurationService {
	public static GetLookups = async (): Promise<Array<Lookup>> => {
		const query = new DynamicQuery("ContentConfigurationType == @0", [ContentConfigurationType.Lookup]);
		const lookups = await DataService.Query<Lookup>(EntityType.ContentConfiguration, query);
		return lookups;
	}

	public static GetLookup = async (id: string): Promise<Lookup> => {
		const query = new DynamicQuery("ContentConfigurationType == @0", [ContentConfigurationType.Lookup]);
		const lookups = await DataService.Query<Lookup>(EntityType.ContentConfiguration, query);
		const lookup = lookups.find(_ => _.id === id);
		return lookup;
	}

	public static GetRbsStructures = async (): Promise<Array<RbsStructure>> => {
		const query = new DynamicQuery("ContentConfigurationType == @0", [ContentConfigurationType.Rbs]);
		const lookups = await DataService.Query<RbsStructure>(EntityType.ContentConfiguration, query);
		return lookups;
	}
	
	public static GetRbsStructure = async (id: string): Promise<RbsStructure> => {
		const query = new DynamicQuery("ContentConfigurationType == @0", [ContentConfigurationType.Rbs]);
		const lookups = await DataService.Query<RbsStructure>(EntityType.ContentConfiguration, query);
		const lookup = lookups.find(_ => _.id === id);
		return lookup;
	}

	public static GetThemes = async (): Promise<Array<any>> => {
		const query = new DynamicQuery("ContentConfigurationType == @0", [ContentConfigurationType.Theme]);
		const lookups = await DataService.Query<RbsStructure>(EntityType.ContentConfiguration, query);
		return lookups;
	}
}