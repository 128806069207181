import { useState, useEffect } from "react";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { Text, ITextStyles } from "@fluentui/react/lib/Text";
import { useInView } from 'react-intersection-observer';
import { useTheme } from '../../../../Themes/themeContext';
import { useDragCtx } from './dragContext';
import { IDraggedItem, TPosition, TDragDirection }from './DragTypes';

const innerStyles: ITextStyles = { root: { fontWeight: 600 }};

interface IProps extends IDraggedItem {
	position: TPosition;
	dragDirection: TDragDirection;
}

const DragHighlighterItem: React.FC<IProps> = props => {
	const theme = useTheme();
	const dragCtx = useDragCtx();
	const [isVisible, setIsVisible] = useState(false);

	const { ref, inView, entry } = useInView({
		root: document.getElementById(`#drag_highlighter_inner${dragCtx.elementIdSuffix ? dragCtx.elementIdSuffix : ''}`),
		threshold: 0.8,
        rootMargin: '0%'
	});

	useEffect(() => {
		setIsVisible(inView);

		if (inView) {
			dragCtx.selectedFields = [...dragCtx.selectedFields, {...props}  as IDraggedItem];
		} else {
			dragCtx.selectedFields = dragCtx.selectedFields.filter((item: IDraggedItem) => item.Cell.Id !== props.Cell.Id);
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView, entry]);

	const baseStyling = mergeStyles({
		backgroundColor: `${theme.semanticColors.inputBackground}`,
		border: `2px solid ${theme.palette.accent}`,
		borderRadius: theme.effects.roundedCorner2,
		opacity: isVisible ? 1 : 0,
		width: `${dragCtx.inputSize.width}px`,
		height: `${dragCtx.inputSize.height}px`,
		top: props.dragDirection.down ? `${props.position.top - dragCtx.draggedItem.top}px` : "unset",
		left: props.dragDirection.right ? `${props.position.left - dragCtx.draggedItem.left}px` : "unset",
		right: !props.dragDirection.right ? `${props.position.right - dragCtx.draggedItem.right - 2}px` : "unset",
		bottom: !props.dragDirection.down ? `${props.position.bottom - dragCtx.draggedItem.bottom - 2}px` : "unset",
		transform: `translate(${props.dragDirection.right ? -1 : 1}px, ${props.dragDirection.down ? -1 : 1}px)`
	});

	// TODO: fix . to ,
	return (
		<div ref={ref} className={`drag_copy_item ${baseStyling}`}>
			<Text className="drag_copy_item_inner" styles={innerStyles}>
				{dragCtx.draggedItem.Value !== null ? dragCtx.draggedItem.Value : ""}
			</Text>
		</div>
	);
};

export default DragHighlighterItem;
