import { useMemo } from 'react';
import { useTheme } from '../../../../../../Themes/themeContext';

export const useResouceValueBgColor = (value: number) => {
	const theme = useTheme();
	return useMemo(() => {
		if (value < 0) {
			return theme.semanticColors.capacityOverBackground;
		} else if (value === 0) {
			return theme.semanticColors.capacityEqualBackground;
		} else if (value > 0) {
			return theme.semanticColors.capacityUnderBackground;
		}
	}, [theme.semanticColors.capacityEqualBackground, theme.semanticColors.capacityOverBackground, theme.semanticColors.capacityUnderBackground, value]);
};
