import { FunctionComponent, useState, useEffect } from 'react';
import { Dropdown } from '@fluentui/react';
import { DropdownOptionEx } from '../../../Utilities/DropdownOptionEx';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { WorkProfile } from '../../../Entities/Main/WorkProfile';
import { EntityType } from '../../../Entities/EntityTypes';
import { DataService } from '../../../Services/DataService';
import { DynamicQuery } from '../../../Entities/DynamicQueryBuilder/DynamicQuery';
import { Plugin } from '../../../Services/PluginInvoker';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import { Settings } from '../../../Entities/Main/Settings';
 
interface IProps extends IDynamicEditorComponentProps {
	Item: string;
	Update: (profile: string) => void;
	Key?: string;
}

const WorkProfileSelector: FunctionComponent<IProps> = props => {

	const [selectedProfile, setSelectedProfile] = useState<string>(props.Item);
	const [profiles, setProfiles] = useState<Array<WorkProfile>>([]);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
        const fetchProfiles = async () => {
			const query = new DynamicQuery("ResourceGuid == @0", [null]);
			const wps = await DataService.Query<WorkProfile>(EntityType.WorkProfile, query);
			
			// get resource's settings default workprofile
			if (props.Item == null) {
				const settings = await Plugin.Invoke<Settings>(PluginIDs.GetSettings, props.Parent.id);
				setSelectedProfile(settings.WorkProfileId);
			}

			setProfiles(wps);
			setLoading(false);
        };
        fetchProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	return 	(
		<div>
			{ !loading &&
			<Dropdown 
				key={ props.Key }
				disabled={ props.ReadOnly || loading }
				options={ DropdownOptionEx.toDropdownOptionsAddEmptyEntry(profiles, _ => _.id, _ => _.Name, true, null, "Not manually selected") }
				defaultSelectedKey={selectedProfile}
				onChange={ (e, o) => props.Update(o.key as string) }
			/> }
		</div>
	)
}

export default WorkProfileSelector;