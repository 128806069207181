import { useLayoutEffect, useRef, useCallback } from 'react';

/**
 * THIS WILL NOT BE STABLE WHEN CALLED DURING RENDERS!!
 *
 * Inspired by the useEvent react 18 hook RFC proposal.
 *
 * @returns a function with a stable reference that is able to capture state, but with no dependency array.
 * This is useful for e.g. onChange handlers and running in useEffect's
 *
 * @param callback any function
 * @remarks
 * [Video](https://www.youtube.com/watch?v=wJakce71KGM) -
 * [RFC](https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md)
 */
export const useEvent = <T extends (...args: any[]) => any>(cb: T) => {
    const ref = useRef(cb);
    useLayoutEffect(() => {
        ref.current = cb;
    });
    return useCallback((...args: Parameters<typeof cb>): ReturnType<typeof cb> => {
        const { current } = ref;
        return current(...args);
    }, []);
};
