import { PFDialog } from './PFDialog';
import { Confirm } from '../../Utilities/Confirm';
import { FunctionComponent } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { language } from '../../Services/LocalizationService';
import { ConfirmInfo } from './ConfirmDialog';

interface IProps {
    ConfirmInfo?: ConfirmInfo;
}

export const SharedConfirmDialog: FunctionComponent<IProps> = props => {
	//TODO Make defaultConfirmInfo globally available when localization has been remade
    const defaultConfirmInfo: ConfirmInfo = {
		show: false,
        title: '',
        subtext: '',
        confirmAction: () => {},
        dismissAction: () => {},
        okButtonText: language.Common.Ok,
        cancelButtonText: language.Common.Cancel,
        hideCancelButton: true,
    };
	
	const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo>(props.ConfirmInfo ? props.ConfirmInfo : defaultConfirmInfo);
	
	useEffect(() => {
		Confirm.Instance.Register((info: ConfirmInfo) => setConfirmInfo(info));
	}, []);

    return (
        <PFDialog
            title={confirmInfo.title}
            subText={confirmInfo.subtext}
            showDialog={confirmInfo.show}
            maxWidth={500}
            callback={() => {
                confirmInfo.confirmAction();
                setConfirmInfo(defaultConfirmInfo);
            }}
            dismissCallback={() => {
                if (confirmInfo.dismissAction) confirmInfo.dismissAction();
                setConfirmInfo(defaultConfirmInfo);
            }}
            buttonText={confirmInfo.okButtonText}
            cancelButtonText={confirmInfo.cancelButtonText}
            noCancelButton={confirmInfo.hideCancelButton}
        />
    );
};
