export default class Locale_SettingsGroupings {
	Basic_Misc: string = "Basic|Misc";
	Basic_Features: string = "Basic|Features";

	PanelsOverviews_Resource_Panel: string = "Panels and Overviews|Resource|Panel";
	PanelsOverviews_Resource_Overview: string = "Panels and Overviews|Resource|Overview";
	PanelsOverviews_Project_Panel: string = "Panels and Overviews|Project|Panel";
	PanelsOverviews_Project_Overview: string = "Panels and Overviews|Project|Overview";
	PanelsOverviews_Contract_Overview: string = "Panels and Overviews|Contract|Overview";

	GridSettings_Grid : string = "Grid settings|General";
	GridSettings_Timeline : string = "Grid settings|Timeline";
	GridSettings_Actions : string = "Grid settings|Actions options";

	GridSettings_Grids_PlannerView: string = "Grid settings|Grids|Planner view";
	GridSettings_ResourceCapacity: string = "Grid settings|Grids|Resource capacity";

	Entities_Resource_Generic = "Entities|Resource|Generic Resources";
	Entities_Resource_Pools = "Entities|Resource|Pools";
	Entities_Resource_CustomProperties = "Entities|Resource|Custom properties";
	Entities_Resource_Misc = "Entities|Resource|Misc";
	Entities_Project_CustomProperties = "Entities|Project|Custom properties";
	Entities_Project_Misc = "Entities|Project|Misc";
	Entities_Contract_NamingGrouping = "Entities|Contract|Naming and grouping";
	Entities_Contract_CustomProperties = "Entities|Contract|Custom properties";
	Entities_Contract_Misc = "Entities|Contract|Misc";
	Entities_Contract_AlignStatus = "Entities|Contract|Align status";
	Entities_Report = "Entities|Report";
	Entities_WorkPackage = "Entities|Request Template";

	Permissions_Access = "Permissions|Access";
	Permissions_Specific_Misc = "Permissions|Specific|Misc";
	Permissions_Specific_Resources = "Permissions|Specific|Resources";
	Permissions_Specific_Projects = "Permissions|Specific|Projects";
	Permissions_Specific_Contracts = "Permissions|Specific|Contracts";
	Permissions_Specific_Workpackages = "Permissions|Specific|Request Templates";
	Permissions_Specific_Scenarios = "Permissions|Specific|Scenarios";
	Permissions_Origins = "Permissions|Origins";

	VersionScenarios = "Versions and scenarios";
	CapacitySettings = "Capacity settings";
	CacheSettings = "Cache settings";
	SubSettings = "Sub settings";

	Notifications_InApp = "Notifications|In-app";
	Notifications_Mail = "Notifications|Mail";

	LookAndFeel_Theme = "Look and feel|Theme";
	LookAndFeel_Localization = "Look and feel|Localization";
	LookAndFeel_Guide = "Look and feel|Guide";
	LookAndFeel_Navigation = "Look and feel|Navigation";

	System_Info = "System|Info";
	System_Logging = "System|Logging";
	System_Backup = "System|Backup";
	System_Telemitry = "System|Telemitry";

	PreviewExp_Preview_MSTeams = "Preview and experimental|Preview|MS Teams";
	PreviewExp_Preview_ResourcePools = "Preview and experimental|Preview|ResourcePools";
	PreviewExp_Preview_Charts = "Preview and experimental|Preview|Charts";
	PreviewExp_Preview_VersionScenarios_Versioning = "Preview and experimental|Preview|Versions and scenarios|Versioning";
	PreviewExp_Preview_VersionScenarios_Scenarios = "Preview and experimental|Preview|Versions and scenarios|Scenarios";
	PreviewExp_Preview_VersionScenarios_AutoAllocation = "Preview and experimental|Preview|Versions and scenarios|Auto allocation";
	PreviewExp_Exp_Reports = "Preview and experimental|Experimental|Report refresh";
	Preview_Grids = "Preview and experimental|Preview|Grids";
	Preview_Panels = "Preview and experimental|Preview|Panel";
}