import { FunctionComponent } from 'react';
import { IconButton, MessageBar, MessageBarType } from "@fluentui/react";
import { useNavigate } from 'react-router';

export const MovedToModernBanner = (props: {redirect: string}) => {
	const goTo = useNavigate();

	return (
		<MessageBar 
			messageBarType={MessageBarType.warning}
			styles={{root: { maxWidth: '75%' }}}
		>
				Moved to modern settings
				<IconButton 
					title='Go to setting'
					iconProps={{ iconName: 'Share' }}
					onClick={() => goTo(props.redirect)}
				/>
		</MessageBar>
	)
}

const MailServiceConfigurationEditor: FunctionComponent<unknown> = () => {
	return (
		<MovedToModernBanner redirect={'/configuration/notifications/mail'} />
	)
}
export default MailServiceConfigurationEditor;