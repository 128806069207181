import { FunctionComponent, useContext, useState, useEffect } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { TableContext } from './Table';
import { TopRow } from '../../../Entities/Table/TopRow';
import TableCellRow from './TableCellRow';
import { TableStateManager } from '../../../Services/TableStateManager';
import { TooltipHost, Icon } from '@fluentui/react';
import { CustomColumnUtil } from '../../../Utilities/Grid/CustomColumnUtil';

interface IProps {
    Top: TopRow;
    ExpandCallback: () => void;
    Collapsed: boolean;
}

const TableTop: FunctionComponent<IProps> = props => {
    const ctx = useContext(TableContext) as TableStateManager;

    const [collapsed, setCollapsed] = useState<boolean>(props.Collapsed);

    useEffect(() => {
        if (props.Collapsed != null) setCollapsed(props.Collapsed);
    }, [props.Collapsed]);

    return (
        <>
            <tr className="tp-grid-total">
                <td className="tp-grid-total-header">
                    <div className="flex-wrapper">
                        <Text className="tp-grid-total-header-title">
                            {props.ExpandCallback && (
                                <div className="tp-grid-total-expandicon-container">
                                    <TooltipHost content={`${collapsed ? 'Expand' : 'Collapse'} all`}>
                                        <Icon
                                            className="tp-grid-total-expandicon"
                                            iconName={`${collapsed ? 'ChevronDown' : 'ChevronUp'}`}
                                            onClick={event => props.ExpandCallback()}
                                        />
                                    </TooltipHost>
                                </div>
                            )}
                            <div className="truncate-text">{props.Top.Name}</div>
                        </Text>
                        {ctx.Get(_ => _.TopExtraContent) ? ctx.Get(_ => _.TopExtraContent)(props.Top) : null}
                    </div>
                </td>
                {CustomColumnUtil.GenerateCustomColumnCellRows(ctx, props.Top)}
                {props.Top.CellRows.map((_, idx) => {
                    return <TableCellRow key={`${props.Top.Id}-cellrow-${idx}`} KeyPrefix={`${props.Top.Id}`} CellRow={_} Index={idx} ParentRow={props.Top} />;
                })}
            </tr>
            <tr className="row-spacing" />
        </>
    );
};

export default TableTop;
