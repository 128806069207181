import { QueryGroup } from "../DynamicQueryBuilder/QueryGroup";
import { Report } from "./Report";
import { Resource } from "./Resource";
import { Project } from "./Project";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyGrouping } from "../../Utilities/PropertyInfo/Grouping";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { guid } from "../../helpers/guid";
import { PFPropertyValidation } from "../../Utilities/PropertyInfo/Validation";
import { ResourcePickerFields } from "../ResourcePickerFields";
import { ProjectPickerFields } from "../ProjectPickerFields";
import { CustomProperty } from "../ContentConfiguration/CustomProperty";
import { Origin } from "./Origin";
import { TimelineResolution } from "../TimelineResolution";
import { DropdownOptionEx } from "../../Utilities/DropdownOptionEx";
import { UnitType } from "../UnitType";
import { OverlappingRuleDecision } from "../OverlappingRuleDecision";
import { OverlappingRulePreferrer } from "../OverlappingRulePreferrer";
import { language, LocalizationService } from "../../Services/LocalizationService";
import { IObjectEditorItemType } from "./IObjectEditorItemType";
import { AlignContractStatus } from "../AlignContractStatus";
import { FreezePeriod } from "../FreezePeriod";
import { GlimmerConfiguration } from "../GlimmerConfiguration";
import { UserType } from "../UserType";
import { VersionResolution } from "../Versions/VersionResolution";
import { TraverseMode } from "../TraverseMode";
import { CacheContractConstraint } from "../CacheContractConstraint";
import { OverviewContext } from "../../Utilities/Context/OverviewContext";
import { aadGroupSettingValidation, ADGroupMap } from "./ADGroupMap";
import { AvailableSubSettingsProperties } from "../../Utilities/SubSettingsProperties";
import { AvailableUserSettingsProperties } from "../../Utilities/UserSettingsProperties";
import { ContractVersionRetention } from "../Versions/ContractVersionRetention";
import { ScenarioType } from "../Scenario";
import { GridSettings } from "../../Utilities/GridSettings";
import { AutoAlignContractNames } from "../Settings/AutoAlignContractNames";
import { ResourceType } from "../ResourceType";
import { LogSettings } from "./Logging/LogSettings";
import { DateEx } from "../../Utilities/DateEx";
import { PFPropertyFilter, PropertyFilterOptions } from "../../Utilities/PropertyInfo/PropertyFilter";
import { ESettingsLocalization } from "../../Locales/BuildTimeLocalization/Locale_Settings";
import { GridActionOptions } from "../Settings/GridActionOptions";
import { Charts } from "../Charts";
import { TreeMatchDirection } from "../MatchDirection";
import { ActivityDistributionConfig } from "../ActivityDistribution/ActivityDistributionConfig";
import { DynamicQuery, MailTemplate, QueryDto, EntityType as EntityTypeSwag } from "../../api/generated/data-contracts";
import { ContentConfigurationType } from "../ContentConfiguration/ContentConfigurationType";
import { ApiCalls } from "../../api/api";
import { ResourcePanelAddToGrid } from "../Settings/ResourcePanelAddToGrid";
import { ContractItem } from "../contractItem";


const BasicOption: PropertyFilterOptions = { FilterLevel: 2 }
const AdvancedOption: PropertyFilterOptions = { FilterLevel: 1 }

export class Settings implements IObjectEditorItemType {
	public TypeName: string = "BaseSettings";

	// Basic
		// Misc
			@PFPropertyBasicInfo(PropertyType.String, "_localization:Settings.SettingsName", 1)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Misc, 1)
			public Name: string;
			@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.DisplayName", 2, "_localization:Settings.DisplayNameDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Misc)
			public DisplayName: string;
			@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.DefaultWorkProfile", 3)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Misc)
			@PFPropertyRender(undefined, undefined, "WorkProfileSelector")
			public WorkProfileId: string;
			@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.RbsStructure", 4)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Misc, 3)
			@PFPropertyRender(undefined, undefined, "RbsSelector")
			public RbsId: string; //guid
			@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Settings.RBSTraverseMode", 5, "_localization:Settings.RBSTraverseModeDescription")
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Misc)
			@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<TraverseMode>(TraverseMode) })
			public RbsTraverseMode: TraverseMode;
			@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Settings.AdminRbsMatchDirection", 6, "_localization:Settings.AdminRbsMatchDirectionDescription")
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Misc)
			@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<TreeMatchDirection>(TreeMatchDirection) })
			public AdminRbsMatchDirection: TreeMatchDirection;
			@PFPropertyBasicInfo(PropertyType.Number, "_localization:Settings.LightbulbLimitInMonths", 7, "_localization:Settings.LightbulbLimitInMonthsDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Misc)
			public LightbulbLimitInMonths: number;
			@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.GlimmerConfiguration", 8)
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Misc)
			@PFPropertyRender(undefined, { ItemType: GlimmerConfiguration, HiddenProperties: [] })
			public GlimmerConfiguration: GlimmerConfiguration;
		// Features
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableForwardRequest", 10)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features, 5)
			public EnableForwardRequest: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableMoveRequest", 11)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features)
			public EnableMoveRequest: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableMoveProjectRequests", 12)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features)
			public EnableMoveProjectRequests: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableMergeContracts", 13)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features)
			public EnableMergeContracts: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableCreateMatchingRequest", 14)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features)
			public EnableCreateMatchingRequest: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableCreateMatchingAllocation", 15)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features)
			public EnableCreateMatchingAllocation: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableMoveAllAllocations", 16)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features)
			public EnableMoveAllAllocations: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableLmCapacityView", 17, "_localization:Settings.EnableLmCapacityViewDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features)
			public EnableLmCapacityView: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableResourceCapacityView", 19)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features)
			public EnableResourceCapacityView: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableRequestNamedResource", 20)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features)
			public EnableRequestNamedResources: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableFastDelete", 22)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features)
			public EnableFastDelete: boolean;
			// Resource planner
			@PFPropertyBasicInfo(PropertyType.Boolean, "Enable 'Resource Planner' view", 24)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features)
			public EnableResourcePlanner: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "Enable 'Project Planner' view", 25)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Basic_Features)
			public EnableProjectPlanner: boolean;
			
	// Panels and Overviews
		// Resource
			// Panel
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.ShowOnlyRelevantResourcesInPicker", 30, "_localization:Settings.ShowOnlyRelevantResourcesInPickerDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel, 10)
				public ShowOnlyRelevantResourcesInPicker: boolean;
				// @PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.PmResourcePickerGroupBy", 31, "_localization:Settings.PmResourcePickerGroupByDescription")
				// @PFPropertyFilter(BasicOption)
				// @PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel)
				// @PFPropertyRender(undefined, { EntityType: Resource, MultiSelect: false, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
				// public PMResourcePickerGroupBy: string;
				// @PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.LMResourcePickerGroupBy", 32, "_localization:Settings.LMResourcePickerGroupByDescription")
				// @PFPropertyFilter(BasicOption)
				// @PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel)
				// @PFPropertyRender(undefined, { EntityType: Resource, MultiSelect: false, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
				// public LMResourcePickerGroupBy: string;
				
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.PmResourcePickerGroupBy", 31, "_localization:Settings.PmResourcePickerGroupByDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel)
				@PFPropertyRender(undefined, { EntityType: Resource, MultiSelect: true, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
				public ResourcePanelRequestGroupings: string[];
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.LMResourcePickerGroupBy", 32, "_localization:Settings.LMResourcePickerGroupByDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel)
				@PFPropertyRender(undefined, { EntityType: Resource, MultiSelect: true, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
				public ResourcePanelAllocationGroupings: string[];

				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.PMResourcePickerDisplayFields", 33)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel)
				@PFPropertyRender(undefined, { ItemType: ResourcePickerFields })
				public PMResourcePickerDisplayFields: ResourcePickerFields;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.LMResourcePickerDisplayFields", 34)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel)
				@PFPropertyRender(undefined, { ItemType: ResourcePickerFields })
				public LMResourcePickerDisplayFields: ResourcePickerFields;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.AllowSearchAllResources", 35)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel)
				public AllowSearchAllResources: boolean;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableSearchNamedResources", 36)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel)
				public EnableSearchNamedResources: boolean;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ResourceFilterPM", 37, "_localization:Settings.ResourceFilterPMDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel)
				@PFPropertyRender(undefined, { ItemType: Resource, DialogTitleGetter: () => language.Settings.ResourceFilterDialogTitle, ForcedIncludedFields: ["UserTypes"] }, "EntityFilterEditor")
				public ResourceFilterPM: QueryGroup;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ResourceFilterLM", 38, "_localization:Settings.ResourceFilterLMDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel)
				@PFPropertyRender(undefined, { ItemType: Resource, DialogTitleGetter: () => language.Settings.ResourceFilterDialogTitle, ForcedIncludedFields: ["UserTypes"] }, "EntityFilterEditor")
				public ResourceFilterLM: QueryGroup;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.ResourcePanelAddToGrid", 39)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel)
				@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<ResourcePanelAddToGrid>(ResourcePanelAddToGrid) })
				public ResourcePanelAddToGrid: ResourcePanelAddToGrid;
				@PFPropertyBasicInfo(PropertyType.Number, "_localization:Settings.DisableResourcePanelCalculationItemLimit", 40, "_localization:Settings.DisableResourcePanelCalculationItemLimitDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Panel)
				public DisableResourcePanelCalculationItemLimit: number;
			// Overview
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ResourceOverviewProperties", 50, "_localization:Settings.ResourceOverviewPropertiesDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Overview, 15)
				@PFPropertyRender(undefined, { EntityType: Resource, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true }, "EntityPropertySelector")
				public ResourceOverviewProperties: Array<string>;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ResourceFilterOverview", 51, "_localization:Settings.ResourceFilterOverviewDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Overview)
				@PFPropertyRender(undefined, { ItemType: Resource, DialogTitleGetter: () => language.Settings.ResourceFilterDialogTitle, ForcedIncludedFields: ["UserTypes"] }, "EntityFilterEditor")
				public ResourceFilterOverview: QueryGroup;
		// Project
			// Panel
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ProjectPickerDisplayFields", 60)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Project_Panel, 20)
				@PFPropertyRender(undefined, { ItemType: ProjectPickerFields })
				public ProjectPickerDisplayFields: ProjectPickerFields;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.PmProjectPickerGroupBy", 61, "_localization:Settings.PmProjectPickerGroupByDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Project_Panel)
				@PFPropertyRender(undefined, { EntityType: Project, MultiSelect: true, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
				public ProjectPanelRequestGroupings: string[];
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.LMProjectPickerGroupBy", 62, "_localization:Settings.LMProjectPickerGroupByDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Project_Panel)
				@PFPropertyRender(undefined, { EntityType: Project, MultiSelect: true, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
				public ProjectPanelAllocationGroupings: string[];
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.IncludeContractStatusFilterInProjectPanel", 63)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Project_Panel)
				public IncludeContractStatusFilterInProjectPanel: boolean;

				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ProjectFilterPM", 64, "_localization:Settings.ProjectFilterPMDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Project_Panel)
				@PFPropertyRender(undefined, { ItemType: Project, DialogTitleGetter: () => language.Settings.ProjectFilterDialogtitle, ForcedIncludedFields: ["AdditionalOwners"] }, "EntityFilterEditor")
				public ProjectFilterPM: QueryGroup;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ProjectFilterLM", 65, "_localization:Settings.ProjectFilterLMDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Project_Panel)
				@PFPropertyRender(undefined, { ItemType: Project, DialogTitleGetter: () => language.Settings.ProjectFilterDialogtitle, ForcedIncludedFields: ["AdditionalOwners"] }, "EntityFilterEditor")
				public ProjectFilterLM: QueryGroup;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.AllowSearchAllProjects", 66)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Project_Panel)
				public AllowSearchAllProjects: boolean;
				@PFPropertyBasicInfo(PropertyType.Number, "_localization:Settings.DisableProjectPanelCalculationItemLimit", 67, "_localization:Settings.DisableProjectPanelCalculationItemLimitDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Project_Panel)
				public DisableProjectPanelCalculationItemLimit: number;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.HideAlignStatusOnProjectPanel", 68)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Project_Panel)
				public HideAlignStatusOnProjectPanel: boolean;
			// Overview
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ProjectOverviewProperties", 70, "_localization:Settings.ProjectOverviewPropertiesDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Project_Overview, 25)
				@PFPropertyRender(undefined, { EntityType: Project, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true }, "EntityPropertySelector")
				public ProjectOverviewProperties: Array<string>;	
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ProjectFilterOverview", 71, "_localization:Settings.ProjectFilterOverviewDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Project_Overview)
				@PFPropertyRender(undefined, { ItemType: Project, DialogTitleGetter: () => language.Settings.ProjectFilterDialogtitle, ForcedIncludedFields: ["AdditionalOwners"] }, "EntityFilterEditor")
				public ProjectFilterOverview: QueryGroup;
		// TODO Implement this when Settings are reworked
				// // Contract
		// 	// Overview
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ContractOverviewProperties", 72, "_localization:Settings.ContractOverviewPropertiesDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Contract_Overview, 25)
				@PFPropertyRender(undefined, { EntityType: ContractItem, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true }, "EntityPropertySelector")
				public ContractOverviewProperties: Array<string>;


	// Grid settings
		// Grid
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableGridUndo", 80, "_localization:Settings.EnableGridUndoDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grid, 30)
			public EnableGridUndo: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableCopyFromExcel", 81, "_localization:Settings.EnableCopyFromExcelDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grid)
			public EnableCopyFromExcel: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableGridSettings", 82)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grid)
			public EnableGridSettings: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableToggleHideGenericResource", 83, "_localization:Settings.EnableToggleHideGenericResourceDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grid, 30)
			public EnableToggleHideGenericResource: boolean;
			@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ResourcePlannerFilterWhitelist", 86, "_localization:Settings.ResourcePlannerFilterWhitelistDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grid, 32)
			@PFPropertyRender(undefined, { EntityType: Resource, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true  }, "EntityPropertySelector")
			public ResourcePlannerFilterOptions: Array<string>;
			@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ProjectPlannerResourceFilterWhitelist", 87, "_localization:Settings.ProjectPlannerResourceFilterWhitelistDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grid, 34)
			@PFPropertyRender(undefined, { EntityType: Resource, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true  }, "EntityPropertySelector")
			public ProjectPlannerResourceFilterOptions: Array<string>;
			@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ProjectPlannerProjectFilterWhitelist", 88, "_localization:Settings.ProjectPlannerProjectFilterWhitelistDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grid, 34)
			@PFPropertyRender(undefined, { EntityType: Project, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true  }, "EntityPropertySelector")
			public ProjectPlannerProjectFilterOptions: Array<string>;
			@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Settings.GridDecimalsFTE", 89, "_localization:Settings.GridDecimalsFTEDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyRender(undefined, { PredefinedValues: [ {key: 0, text: "0"}, {key: 1, text: "1"}, {key: 2, text: "2"}] })
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grid)
			public GridDecimalsFTE: number;
			@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Settings.GridDecimalsHours", 90, "_localization:Settings.GridDecimalsHoursDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyRender(undefined, { PredefinedValues: [ {key: 0, text: "0"}, {key: 1, text: "1"}, {key: 2, text: "2"}] })
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grid)
			public GridDecimalsHours: number;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.HidePersonaIconsInGrid", 92)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grid)
			public HidePersonaIconsInGrid: boolean;
			@PFPropertyBasicInfo(PropertyType.NumberSlider, "_localization:Settings.GridSpacing", 94)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grid)
			@PFPropertyRender(undefined, { Step: 0.1, Min: 0, Max: 2, OnChangeEvent: (newValue: number) => GridSettings.SetSpacingAmount(newValue) })
			public GridSpacing: number;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.RestrictDirectAllocations", 97, "_localization:Settings.RestrictDirectAllocationsDescription")
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grid)
			public RestrictDirectAllocations: boolean;
		// Grids
			// Planner view
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.AllowPlannerViewGenericResourceSelection", 103)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grids_PlannerView)
				public AllowPlannerViewGenericResourceSelection: boolean;

				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.AllowPlannerRootRbsMatch", 103)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.GridSettings_Grids_PlannerView)
				public AllowPlannerViewRootRbsMatch: boolean;
			// Resource capactiy
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ResourcesCanEditOwnAllocations", 121, "_localization:Settings.ResourcesCanEditOwnAllocationsDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.GridSettings_ResourceCapacity)
				@PFPropertyRender(undefined, { ItemType: Project, DialogTitleGetter: () => language.Settings.ResourcesCanEditOwnAllocationsDialogTitle, ButtonText: "Edit rule", FilterSystemProperties: false }, "EntityFilterEditor")
				public ResourcesCanEditOwnAllocations: QueryGroup;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ResourcesCanAddAllocations", 121, "_localization:Settings.ResourcesCanAddAllocationsDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.GridSettings_ResourceCapacity)
				@PFPropertyRender(undefined, { ItemType: Project, DialogTitleGetter: () => language.Settings.ResourcesCanAddAllocationsDialogTitle, ButtonText: "Edit rule", FilterSystemProperties: false }, "EntityFilterEditor")
				public ResourcesCanAddAllocations: QueryGroup;
				
		// Timeline
			@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedTimelineResolution", 90)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Timeline, 40)
			@PFPropertyRender((item: Settings, i, col) => item[col.fieldName].map(_ => TimelineResolution[_]).join(", "), { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<TimelineResolution>(TimelineResolution) })
			@PFPropertyValidation("_localization:Settings.AllowedTimelineResolutionDescription", (obj: Settings) => obj.SettingsType !== 0 ? true : obj.AllowedTimelineResolutions?.length > 0, undefined, () => true)
			public AllowedTimelineResolutions: Array<TimelineResolution>;
			@PFPropertyBasicInfo(PropertyType.Number, "_localization:Settings.TimelineDayResolutionMaxDaysBackwards", 91, "_localization:Settings.TimelineDayResolutionMaxDaysBackwardsDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Timeline)
			@PFPropertyRender(undefined, undefined, undefined, (settings: Settings) => !OverviewContext.AllowTimelineBackInTime())
			public TimelineDayResolutionMaxDaysBackwards: number;
			@PFPropertyBasicInfo(PropertyType.Number, "_localization:Settings.TimelineDayResolutionMaxDaysForward", 92, "_localization:Settings.TimelineDayResolutionMaxDaysForwardDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Timeline)
			public TimelineDayResolutionMaxDaysForward: number;
		// Actions
			@PFPropertyBasicInfo(PropertyType.Object, "Default behaviours", 95)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.GridSettings_Actions)
			@PFPropertyRender(undefined, { ItemType: GridActionOptions })
			public GridActionOptions: GridActionOptions;



	// Entities
		// Resource
			// Generic resources
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.GenericResourceDefinition", 100, "_localization:Settings.GenericResourceDefinitionDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Resource_Generic, 45)
				@PFPropertyRender(undefined, { EntityType: Resource, DisableEmptyEntry: true, MultiSelect: true, PropertyTypes: "Properties", RemovePrefixFromProperties: true, FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
				public GenericResourceDefinition: Array<string>;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.AutoGenerateGenericResources", 101, "_localization:Settings.AutoGenerateGenericResourcesDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Resource_Generic)
				public AutoGenerateGenericResources: boolean;
				@PFPropertyBasicInfo(PropertyType.String, "_localization:Settings.GenericResourceNamingConvention", 102, "_localization:Settings.GenericResourceNamingConventionDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Resource_Generic)
				@PFPropertyRender(undefined, undefined, undefined, (settings: Settings) => !settings.AutoGenerateGenericResources)
				public GenericResourceNamingConvention: string;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.PlaceholderGenericResource", 104)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Resource_Generic)
				@PFPropertyRender(undefined, { Types: [ResourceType.Generic], ExcludeInactive: true }, "ResourcePicker")
				public PlaceholderGenericResource: string;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.AllowChangeRbsAndCustomPropertiesOnGenericResource", 105)
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Resource_Generic)
				public AllowChangeRbsAndCustomPropertiesOnGenericResource: boolean;
			// Pools
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableResourcePools", 106, "_localization:Settings.EnableResourcePoolsDescription")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Resource_Pools, 47)
				public EnableResourcePools: boolean;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableDynamicResourcePools", 107, "_localization:Settings.EnableDynamicResourcePoolsDescription")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Resource_Pools)
				public EnableDynamicResourcePools: boolean;
			// CustomProperties
				@PFPropertyBasicInfo(PropertyType.ObjectArray, "_localization:Common.CustomProperties", 110, "_localization:Settings.ResourceCustomPropertiesDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Resource_CustomProperties, 50)
				@PFPropertyRender(undefined, { ItemType: CustomProperty, HiddenColumnProperties: [ "Id", "EditableBy", "Group" ], HiddenProperties: [ "Group", "EditableBy" ], ItemIdentifier: (item : CustomProperty) => item.Id, ClonedItemChange: (_: CustomProperty) => {
 _.Id = guid.newGuid(); _.InternalName = `copy_${_.InternalName}`; 
} })
				public ResourceCustomProperties: Array<CustomProperty>;
			// Misc
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ResourceInformationProperties", 120, "_localization:Settings.ResourceInformationPropertiesDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Resource_Misc, 55)
				@PFPropertyRender(undefined, { EntityType: Resource, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true }, "EntityPropertySelector")
				public ResourceInformationProperties: Array<string>;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.AutoInactivateResources", 121, "_localization:Settings.AutoInactivateResourcesDescription")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Resource_Misc)
				@PFPropertyRender(undefined, { ItemType: Resource, DialogTitleGetter: () => language.Settings.AutoInactiveDialogTitle, ButtonText: "Edit rule" }, "EntityFilterEditor")
				public AutoInactivateResources: QueryGroup;
		// Project
			// CustomProperties
				@PFPropertyBasicInfo(PropertyType.ObjectArray, "_localization:Common.CustomPropertiesCapitalized", 130, "_localization:Settings.ProjectCustomPropertiesDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Project_CustomProperties, 60)
				@PFPropertyRender(undefined, { ItemType: CustomProperty, HiddenColumnProperties: [ "Id", "EditableBy", "Group" ], HiddenProperties: [ "Group", "EditableBy" ], ItemIdentifier: (item : CustomProperty) => item.Id, ClonedItemChange: (_: CustomProperty) => {
 _.Id = guid.newGuid(); _.InternalName = `copy_${_.InternalName}`; 
} })
				public ProjectCustomProperties: Array<CustomProperty>;
			// Misc
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ProjectInformationProperties", 140, "_localization:Settings.ProjectInformationPropertiesDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Project_Misc, 65)
				@PFPropertyRender(undefined, { EntityType: Project, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true }, "EntityPropertySelector")
				public ProjectInformationProperties: Array<string>;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.ForceParentOnAdministrativeProjects", 141)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Project_Misc)
				public ForceParentOnAdministrativeProjects: boolean;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.ProjectsAlwaysShowRBS", 142)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Project_Misc)
				public ProjectsAlwaysShowRBS: boolean;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.AutoInactivateProjects", 143, "_localization:Settings.AutoInactivateProjectsDescription")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Project_Misc)
				@PFPropertyRender(undefined, { ItemType: Project, DialogTitleGetter: () => language.Settings.AutoInactiveDialogTitle, ButtonText: "Edit rule" }, "EntityFilterEditor")
				public AutoInactivateProjects: QueryGroup;
		// CONTRACT
			// Naming and grouping
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ContractDefaultName", 150, "_localization:Settings.ContractDefaultNameDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_NamingGrouping, 70)
				@PFPropertyRender(undefined, { EntityType: Resource, PropertyTypes: "Both", DisableEmptyEntry: true, FilterSystemProperties: true, FilterArrayProperties: true, HiddenProperties: ["Email","Active","ResourceType","WorkProfileId","EarliestAvailable","LatestAvailable"] }, "EntityPropertySelector")
				public ContractDefaultName: string;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableDynamicContractNames", 151, "_localization:Settings.EnableDynamicContractNamesDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_NamingGrouping)
				public EnableDynamicContractNames: string;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.AutoAlignContractNames", 152, "_localization:Settings.AutoAlignContractNamesDescription")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_NamingGrouping)
				@PFPropertyRender(undefined, { ItemType: AutoAlignContractNames })
				public AutoAlignContractNames: AutoAlignContractNames;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.AutoGroupContractBy", 153, "_localization:Settings.AutoGroupContractByDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_NamingGrouping)
				@PFPropertyRender(undefined, { EntityType: Resource, MultiSelect: false, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true, HiddenProperties: [ "EnableSpecificWorkprofile", "SpecificWorkprofileId", "PreferredUnitType", "Theme", "Localization", "TeamProject", "Origins" ] }, "EntityPropertySelector")
				public AutoGroupContractBy: string;
			// CustomProperties
				@PFPropertyBasicInfo(PropertyType.ObjectArray, "_localization:Common.CustomProperties", 110, "_localization:Settings.ContractCustomPropertiesDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_CustomProperties)
				@PFPropertyRender(undefined, { ItemType: CustomProperty, HiddenColumnProperties: [ "Id" ], ItemIdentifier: (item : CustomProperty) => item.Id, ClonedItemChange: (_: CustomProperty) => {
 _.Id = guid.newGuid(); _.InternalName = `copy_${_.InternalName}`; 
} })
				public ContractCustomProperties: Array<CustomProperty>;
			// Misc
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.FreezePeriod", 160, "_localization:Settings.FreezePeriodDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_Misc, 75)
				@PFPropertyRender(undefined, { ItemType: FreezePeriod }, "FreezePeriodEditor")
				public FreezePeriod: FreezePeriod;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.SingletonOnRequests", 161, "_localization:Settings.SingletonOnRequestsDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_Misc)
				public SingletonOnRequests: boolean;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.RestrictOverAllocating", 165, "_localization:Settings.RestrictOverAllocatingDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_Misc)
				public RestrictOverAllocating: boolean;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ContractInformationProperties", 50, "_localization:Settings.ContractInformationPropertiesDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_Misc, 15)
				@PFPropertyRender(undefined, { EntityType: Resource, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true }, "EntityPropertySelector")
				public ContractInformationProperties: Array<string>;
				// TODO: not ready yet
				// @PFPropertyBasicInfo(PropertyType.Boolean, "Auto create matching request when allocating a resource directly", 3, "If a LM allocate a resource to a project without answering an existing request, this will auto create a matching request, on the resource's related generic resource.")
				// @PFPropertyGrouping("Contract settings")
				// public AutoCreateRequestWhenDirectAllocating: boolean;
			// Align status
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableAlignContract", 170, "_localization:Settings.EnableAlignContractDescription")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_AlignStatus, 80)
				public EnableAlignContract: boolean;
				@PFPropertyBasicInfo(PropertyType.ObjectArray, "_localization:Settings.AlignContractStatuses", 172, "_localization:Settings.AlignContractStatusesDescription")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_AlignStatus)
				@PFPropertyRender(undefined, { ItemType: AlignContractStatus, ItemIdentifier: (item : CustomProperty) => item.Id, InitialSortFields: [ "Order" ] }, undefined, (obj => !obj.EnableAlignContract))
				public AlignContractStatuses: Array<AlignContractStatus>;
								
				@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Settings.ResetAlignContractOnRequestChange", 176, "_localization:Settings.ResetAlignContractOnRequestChangeDescription")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyRender(null, { PredefinedValuesGetter: (item: Settings) => DropdownOptionEx.toDropdownOptionsAddEmptyEntry(item.AlignContractStatuses, _ => _.Id, _ => _.Name)})
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_AlignStatus)
				public OverrideAlignContractOnRequestChange: string;
								
				@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Settings.ResetAlignContractOnAllocationChange", 179, "_localization:Settings.ResetAlignContractOnAllocationChangeDescription")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Entities_Contract_AlignStatus)
				@PFPropertyRender(null, { PredefinedValuesGetter: (item: Settings) => DropdownOptionEx.toDropdownOptionsAddEmptyEntry(item.AlignContractStatuses, _ => _.Id, _ => _.Name)})
				public OverrideAlignContractOnAllocationChange: string;	

		// Report
			@PFPropertyBasicInfo(PropertyType.ObjectArray, "_localization:Common.Reports", 180)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Entities_Report, 85)
			@PFPropertyRender(undefined, { ItemType: Report, HiddenProperties: [ "id", "Created", "Modified", "CreatedBy", "ModifiedBy" ], ItemIdentifier: (item : Report) => item.Id, ClonedItemChange: (_: Report) => {
 _.Id = guid.newGuid(); _.Name = `copy_${_.Name}`; 
} })
			public Reports: Array<Report>;
			@PFPropertyBasicInfo(PropertyType.String, "_localization:Settings.SQLExportConnectionString", 181, "_localization:Settings.SQLExportConnectionStringDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Entities_Report)
			public SQLExportConnectionString: string;
		// Request Template (PREVIOUSLY: Work Package)
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableWorkPackages", 190)
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping(ESettingsLocalization.Entities_WorkPackage, 90)
			public EnableWorkPackages: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.PrefixContractNamesWhenGroupByWorkpackageRun", 191)
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping(ESettingsLocalization.Entities_WorkPackage, 90)
			public PrefixContractNamesWhenGroupByWorkpackageRun: boolean;


	// Permissions
		// Access
			@PFPropertyBasicInfo(PropertyType.BooleanToggle, "_localization:Settings.EnableADGroupSecurity", 200)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Permissions_Access, 95)
			@PFPropertyRender(undefined, undefined, undefined, (obj: Settings) => (obj as any).Permissions?.EntraGroup?.Enabled || (!obj.EnableADGroupSecurity && obj.ADGroupMaps?.Administrator == null))
			public EnableADGroupSecurity: boolean;
			@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ADGroupMaps", 201)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Permissions_Access)
			@PFPropertyRender(undefined, { ItemType: ADGroupMap }, undefined, (obj: Settings) => !obj.EnableADGroupSecurity || (obj as any).Permissions?.EntraGroup?.Enabled)
			@PFPropertyValidation("_localization:Settings.ADGroupMapsValidation", (obj: Settings) => !obj.EnableADGroupSecurity || aadGroupSettingValidation(obj.ADGroupMaps))
			public ADGroupMaps: ADGroupMap;

			@PFPropertyBasicInfo(PropertyType.Object, "AAD Group Permissions is now Entra Group Permissions", 200)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Permissions_Access, 95)
			@PFPropertyRender(undefined, { redirectPath: 'configuration/permissions/entragroup', settingName: 'AAD Group Permissions' }, 'MovedToModernSettings', (obj: Settings) => !(obj as any).Permissions?.EntraGroup?.Enabled && !(!obj.EnableADGroupSecurity && obj.ADGroupMaps?.Administrator == null))
			public EnableADGroupSecurity_Deprecated: boolean;

			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableProjectOwnerADGroup", 202)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Permissions_Access)
			public EnableProjectOwnerADGroup: boolean;
			@PFPropertyBasicInfo(PropertyType.String, "_localization:Settings.ProjectOwnerADGroupFilter", 203)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Permissions_Access)
			@PFPropertyRender(undefined, undefined, undefined, (obj: Settings) => (obj as any).Permissions?.EntraGroup?.Enabled)
			public ProjectOwnerADGroupFilter: string;
		// Specific
			// Misc
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.RbsLevelsBelowAccessible", 210)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Misc, 100)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.toDropdownOptions([UserType.LineManager, UserType.SuperUser], _ => _, _ => UserType[_].toString()), MultiSelect: true })
				public RbsLevelsBelowAccesibleFor: Array<UserType>;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedToDelegate", 211)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Misc)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None]), MultiSelect: true })
				public AllowedToDelegate: Array<UserType>;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.RbsRestrictedUsers", 213)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Misc)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None, UserType.ProjectOwner, UserType.Administrator]), MultiSelect: true })
				public RbsRestrictedUsers: Array<UserType>;
			// Resources
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.LmCanEditOwnResources", 220)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Resources, 105)
				public LmCanEditOwnResources: boolean;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedToInactivateResources", 221)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Resources)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None, UserType.ProjectOwner]), MultiSelect: true })
				public AllowedToInactivateResources: Array<UserType>;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedToDeleteResources", 222)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Resources)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None, UserType.ProjectOwner]), MultiSelect: true })
				public AllowedToDeleteResources: Array<UserType>;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedToCreateResources", 223)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Resources)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None, UserType.ProjectOwner]), MultiSelect: true })
				public AllowedToCreateResources: Array<UserType>;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedToEditResources", 224)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Resources)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None, UserType.ProjectOwner]), MultiSelect: true })
				public AllowedToEditResources: Array<UserType>;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedToSeeNamedResourcesWithoutRbs", 224)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Resources)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None, UserType.ProjectOwner]), MultiSelect: true })
				public AllowedToSeeNamedResourcesWithoutRbs: Array<UserType>;

			// Projects
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.PmCanEditOwnProjects", 230)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Projects, 110)
				public PmCanEditOwnProjects: boolean;
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.ShownOwnProjectsForLM", 231)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Projects)
				public ShownOwnProjectsForLM: boolean;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedToInactivateProjects", 232)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Projects)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None, UserType.LineManager]), MultiSelect: true })
				public AllowedToInactivateProjects: Array<UserType>;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedToDeleteProjects", 233)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Projects)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None, UserType.LineManager]), MultiSelect: true })
				public AllowedToDeleteProjects: Array<UserType>;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedToCreateProjects", 234)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Projects)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None, UserType.LineManager]), MultiSelect: true })
				public AllowedToCreateProjects: Array<UserType>;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedToEditProjects", 235)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Projects)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None, UserType.LineManager]), MultiSelect: true })
				public AllowedToEditProjects: Array<UserType>;
				@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.LimitAdditionalOwnerPropertyEditing", 237, "_localization:Settings.LimitAdditionalOwnerPropertyEditingDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Projects, 25)
				@PFPropertyRender(undefined, { EntityType: Project, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true }, "EntityPropertySelector")
				public LimitAdditionalOwnerPropertyEditing: Array<string>;

			// Contracts
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.AllowLMsToChangeContractName", 240, "_localization:Settings.AllowLMsToChangeContractNameDescription")
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Contracts, 115)
				public AllowLMsToChangeContractName: boolean;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedToSeeContractOverview", 245)
				@PFPropertyFilter(BasicOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Contracts)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None]), MultiSelect: true })
				public AllowedToSeeContractOverview: Array<UserType>;
				// @PFPropertyBasicInfo(PropertyType.Boolean, "Allow all LM's to allocate outside their RBS", 7)
				// @PFPropertyGrouping("Basic")
				// public AllocateOutsideRBS: boolean;
			// Request Templates (PREVIOUSLY: Work Packages)
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.AllowedToDesignWorkpackages", 250)
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Workpackages, 120)
				@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome<UserType>(UserType, [UserType.Resource, UserType.None]), MultiSelect: true })
				public AllowedToDesignWorkpackages: Array<UserType>;
			// Scenarios
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.UserTypesAllowedToCreateScenarios", 260)
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Scenarios, 125)
				@PFPropertyRender((item: Settings, i, col) => item[col.fieldName].map(_ => UserType[_]).join(", "), { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutZeroOption<UserType>(UserType) })
				public UserTypesAllowedToCreateScenarios: Array<UserType>;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.UserTypesAllowedToApproveScenarios", 261)
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Permissions_Specific_Scenarios)
				@PFPropertyRender((item: Settings, i, col) => item[col.fieldName].map(_ => UserType[_]).join(", "), { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutZeroOption<UserType>(UserType) })
				public UserTypesAllowedToApproveScenarios: Array<UserType>;
		// Origins
			@PFPropertyBasicInfo(PropertyType.ObjectArray, "_localization:Common.Origins", 270)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Permissions_Origins, 130)
			@PFPropertyRender(undefined, { ItemType: Origin, HiddenColumnProperties: [ "Id", "ProjectPropertiesAllowed", "ResourcePropertiesAllowed" ], UnDeletableItemsIds: ["00000000-0000-0000-0000-000000000000", "88fdd1b2-d731-4279-81f3-71daee8c9fb0"], ItemIdentifier: (item : Origin) => item.Id, ClonedItemChange: (_: Origin) => {
 _.Id = guid.newGuid(); _.DisplayName = `copy_${_.DisplayName}`; 
} } )
			public Origins: Array<Origin>;


	// Versions and Scenarios
		@PFPropertyBasicInfo(PropertyType.LabelText, "Find this under the 'Preview' section", 280)
		@PFPropertyFilter(AdvancedOption)
		@PFPropertyGrouping(ESettingsLocalization.VersionScenarios, 135)
		public VersioningLabel: string;


	// Capacity settings
		@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Settings.UnitType", 290, "_localization:Settings.UnitTypeDescription")
		@PFPropertyFilter(BasicOption)
		@PFPropertyGrouping(ESettingsLocalization.CapacitySettings, 140)
		@PFPropertyRender((item: Settings) => UnitType[item.UnitType], { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<UnitType>(UnitType) })
		public UnitType: UnitType;
		@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Settings.ForceUnitTypeOnRequestView", 291, "_localization:Settings.ForceUnitTypeOnRequestViewDescription")
		@PFPropertyFilter(BasicOption)
		@PFPropertyGrouping(ESettingsLocalization.CapacitySettings)
		@PFPropertyRender((item: Settings) => UnitType[item.UnitType], { PredefinedValues: DropdownOptionEx.toDropdownOptionsAddEmptyEntry<UnitType>([UnitType.FTE, UnitType.Hours], _=> (_ as any) as number, _ => UnitType[_].toString()) })
		public ForceUnitTypeOnRequestView: UnitType;
		@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Settings.ForceUnitTypeOnAllocationView", 292, "_localization:Settings.ForceUnitTypeOnAllocationViewDescription")
		@PFPropertyFilter(BasicOption)
		@PFPropertyGrouping(ESettingsLocalization.CapacitySettings)
		@PFPropertyRender((item: Settings) => UnitType[item.UnitType], { PredefinedValues: DropdownOptionEx.toDropdownOptionsAddEmptyEntry<UnitType>([UnitType.FTE, UnitType.Hours], _=> (_ as any) as number, _ => UnitType[_].toString())})
		public ForceUnitTypeOnAllocationView: UnitType;
		@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Settings.OverlappingRulePreferrer", 293, "_localization:Settings.OverlappingRulePreferrerDescription")
		@PFPropertyFilter(BasicOption)
		@PFPropertyGrouping(ESettingsLocalization.CapacitySettings)
		@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<OverlappingRulePreferrer>(OverlappingRulePreferrer) })
		public OverlappingRulePreferrer: OverlappingRulePreferrer;
		@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Settings.OverlappingRuleDecision", 294, "_localization:Settings.OverlappingRuleDecisionDescription")
		@PFPropertyFilter(BasicOption)
		@PFPropertyGrouping(ESettingsLocalization.CapacitySettings)
		@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<OverlappingRuleDecision>(OverlappingRuleDecision) })
		public OverlappingRuleDecision: OverlappingRuleDecision;

	// Cache settings
		@PFPropertyBasicInfo(PropertyType.LabelText, "_localization:Settings.CacheResourceContraintLabel", 300, "_localization:Settings.CacheResourceContraintLabelDescription")
		@PFPropertyFilter(BasicOption)
		@PFPropertyGrouping(ESettingsLocalization.CacheSettings, 145)
		public CacheResourceConstraintLabel: string;
		@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.CacheResourceContraint", 301, "_localization:Settings.CacheResourceContraintDescription")
		@PFPropertyFilter(BasicOption)
		@PFPropertyGrouping(ESettingsLocalization.CacheSettings)
		@PFPropertyRender(undefined, { ItemType: Resource, DialogTitleGetter: () => language.Settings.CacheResourceDialogTitle }, "EntityFilterEditor")
		public CacheResourceConstraint: QueryGroup;
		@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.CacheProjectContraint", 302, "_localization:Settings.CacheProjectContraintDescription")
		@PFPropertyFilter(BasicOption)
		@PFPropertyGrouping(ESettingsLocalization.CacheSettings)
		@PFPropertyRender(undefined, { ItemType: Project, DialogTitleGetter: () => language.Settings.CacheProjectDialogTitle }, "EntityFilterEditor")
		public CacheProjectConstraint: QueryGroup;
		@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.CacheContractConstraint", 303, "_localization:Settings.CacheContractConstraintDescription")
		@PFPropertyFilter(BasicOption)
		@PFPropertyGrouping(ESettingsLocalization.CacheSettings)
		@PFPropertyRender(undefined, undefined, "PeriodSpanEditor")
		public CacheContractConstraint: CacheContractConstraint;

	// Sub Settings
		// TODO NEW FORM
		@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.SubSettingPropertiesAvailable", 310, "_localization:Settings.SubSettingPropertiesAvailableDescription")
		@PFPropertyFilter(AdvancedOption)
		@PFPropertyGrouping(ESettingsLocalization.SubSettings, 150)
		@PFPropertyRender(undefined, { EntityTypeAsString: "Settings", DisableEmptyEntry: true, MultiSelect: true, PropertyTypes: "Fields", OnlyTheseProperties: AvailableSubSettingsProperties, Searchable: true }, "EntityPropertySelector")
		public SubSettingPropertiesAvailable: Array<string>;
		
		// TODO NEW FORM
		@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.UserSettingPropertiesAvailable", 311, "_localization:Settings.UserSettingPropertiesAvailableDescription")
		@PFPropertyFilter(AdvancedOption)
		@PFPropertyGrouping(ESettingsLocalization.SubSettings)
		@PFPropertyRender(undefined, { EntityTypeAsString: "Settings", DisableEmptyEntry: true, MultiSelect: true, PropertyTypes: "Fields", OnlyTheseProperties: AvailableUserSettingsProperties, Searchable: true  }, "EntityPropertySelector")
		public UserSettingPropertiesAvailable: Array<string>;
		@PFPropertyBasicInfo(PropertyType.Object, "_localization:AdminDashboard.SubSettings", 520)
		@PFPropertyFilter(AdvancedOption)
		@PFPropertyGrouping(ESettingsLocalization.SubSettings, 210)
		@PFPropertyRender(undefined, undefined, "SubSettingsAdministration")
		public Subsettings: any; // dummy property to render the SubSettingsAdministration component

	// Notifications
		// In-App
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableGridResolutionMismatchWarning", 320, "_localization:Settings.EnableGridResolutionMismatchWarningDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Notifications_InApp, 155)
			public EnableGridResolutionMismatchWarning: boolean;
			@PFPropertyBasicInfo(PropertyType.Number, "_localization:Settings.AutoHideNotificationsAfter", 156, "_localization:Settings.AutoHideNotificationsAfterDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Notifications_InApp)
			public AutoHideNotificationsAfter: number;
		// Mail
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableMailNotifications", 330)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Notifications_Mail, 160)
			public EnableMailNotifications: boolean;
			@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ConfigureMailService", 332, "_localization:Settings.ConfigureMailServiceDescription")
			@PFPropertyRender(undefined, undefined, "MailServiceConfigurationEditor", (item: Settings) => item.id !== guid.empty)
			@PFPropertyGrouping(ESettingsLocalization.Notifications_Mail)
			public MailServiceConfig: any; // fake prop to render mail service configure dialog
			@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.MailNotifications", 335, "_localization:Settings.MailNotificationsDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.Notifications_Mail)
			@PFPropertyRender(undefined, { PredefinedValuesGetterAsync: async () => {
				const dto = {
					entity: EntityTypeSwag.ContentConfiguration,
					query: { expression: 'ContentConfigurationType == @0', params: [ContentConfigurationType.MailTemplate] } as DynamicQuery,
				} as QueryDto;
				const response = await ApiCalls.queryEntity(dto);
				return DropdownOptionEx.toDropdownOptions<MailTemplate>(response.data, _ => _.id, _ => _.name);
			} })
			public MailNotifications: Array<string>;

	// Look and feel
		// Theme
			@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.DefaultTheme", 340)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.LookAndFeel_Theme, 165)
			@PFPropertyRender(undefined, undefined, "ThemeSelector")
			public DefaultTheme: string; //guid
		// Localization
			@PFPropertyBasicInfo(PropertyType.String, "_localization:Settings.CultureInfo", 350, "_localization:Settings.CultureInfoDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.LookAndFeel_Localization, 170)
			public CultureInfo: string;
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.UseISO8601", 351, "_localization:Settings.UseISO8601Description")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.LookAndFeel_Localization)
			public UseISO8601: boolean;
			@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Settings.Localization", 352, "_localization:Settings.LocalizationDescription")
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.LookAndFeel_Localization)
			@PFPropertyRender(undefined, { PredefinedValuesGetterAsync: () => LocalizationService.GetAvailableLocales() })
			public Localization: string;
			@PFPropertyBasicInfo(PropertyType.String, "_localization:Settings.CustomLocaleStorageUrl", 353, "_localization:Settings.CustomLocaleStorageUrlDescription")
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping(ESettingsLocalization.LookAndFeel_Localization)
			public CustomLocaleStorageUrl: string;
		// Guide
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableCoachmarks", 360)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.LookAndFeel_Guide, 175)
			public EnableCoachmarks: boolean;
		// Navigation
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.ForcePinnedTopbar", 365)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.LookAndFeel_Navigation)
			public ForcePinnedTopbar: boolean;
		// Help section
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableHelpSection", 370)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.LookAndFeel_Guide)
			public EnableHelpSection: boolean;


	// System
		// Info
			@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.Id", 500)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.System_Info, 200)
			public id: string;
			@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Created", 501)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.System_Info)
			@PFPropertyRender(undefined, { DisplayFormat: (date: Date) => DateEx.asUserLocalWithTime(date) }, undefined, undefined, undefined, 120)
			public Created: Date;
			@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Modified", 502)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.System_Info)
			@PFPropertyRender(undefined, { DisplayFormat: (date: Date) => DateEx.asUserLocalWithTime(date) }, undefined, undefined, undefined, 120)
			public Modified: Date;
			@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.CreatedBy", 503)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.System_Info)
			public CreatedBy: string;
			@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.ModifiedBy", 504)
			@PFPropertyFilter(BasicOption)
			@PFPropertyGrouping(ESettingsLocalization.System_Info)
			public ModifiedBy: string;
		// Logging
			@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.LogSettings", 510)
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping(ESettingsLocalization.System_Logging, 205)
			@PFPropertyRender(undefined, { ItemType: LogSettings, HiddenProperties: [] })
			public LogSettings: LogSettings;
		// Settings Backup
			@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.Backups", 520, "_localization:Settings.BackupsDescription")
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping(ESettingsLocalization.System_Backup, 210)
			@PFPropertyRender(undefined, undefined, "SettingsBackups")
			public Backups: any; // dummy property to render the backup component
		//Telemitry
			@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableTelemitry", 530)
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping(ESettingsLocalization.System_Telemitry, 215)
			public EnableTelemitry: boolean;
			// Misc
			@PFPropertyBasicInfo(PropertyType.Boolean, "Enable update of User's last signin", 540)
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping("System|Misc", 220)
			public EnableSetUserLastSignin: boolean;
	// Preview and experimental
		// Preview
			// Grids
			// MS Teams
				@PFPropertyBasicInfo(PropertyType.BooleanToggle, "_localization:Settings.EnableResourceSyncToMSTeams", 1001, "_localization:Settings.EnableResourceSyncToMSTeamsDescription")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_MSTeams)
				public EnableResourceSyncToMSTeams: boolean;
				@PFPropertyBasicInfo(PropertyType.Number, "_localization:Settings.RemoveResourceFromMSTeamsDelay", 1002, "_localization:Settings.RemoveResourceFromMSTeamsDelayDescription")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_MSTeams)
				@PFPropertyRender(undefined, undefined, undefined, (obj: Settings) => !obj.EnableResourceSyncToMSTeams)
				public RemoveResourceFromMSTeamsDelay: number;
			
			// Project panel LM (TEMP)
				@PFPropertyBasicInfo(PropertyType.Boolean, "Enable new Project panel calculation for LM", 1003)
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.Preview_Panels)
				public UseNewProjectPanelForLineManagers: boolean;

			// Charts
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableEmbeddedCharts", 1150, "_localization:Settings.EmbeddedChartsDescription")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_Charts)
				public EnableEmbeddedCharts: boolean;
				@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.EnabledCharts", 1151)
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_Charts)
				@PFPropertyRender((item: Settings, i, col) => item[col.fieldName].map(_ => Charts[_]).join(", "), { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutZeroOption<Charts>(Charts) })
				public EnabledCharts: Array<Charts>;
			// Versioning and scenarios
				// Versioning
					@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableContractVersions", 1201)
					@PFPropertyFilter(AdvancedOption)
					@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_VersionScenarios_Versioning)
					public EnableContractVersions: boolean;
					@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.ContractVersionResolutions", 1202)
					@PFPropertyFilter(AdvancedOption)
					@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_VersionScenarios_Versioning)
					@PFPropertyRender((item: Settings, i, col) => item[col.fieldName].map(_ => VersionResolution[_]).join(", "), { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutZeroOption<VersionResolution>(VersionResolution) })
					public ContractVersionResolutions: Array<VersionResolution>;
					@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ContractVersionRetention", 1203)
					@PFPropertyFilter(AdvancedOption)
					@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_VersionScenarios_Versioning)
					@PFPropertyRender(undefined, { ItemType: ContractVersionRetention })
					public ContractVersionRetention: ContractVersionRetention;
				// Scenarios
					@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableScenarios", 1300)
					@PFPropertyFilter(AdvancedOption)
					@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_VersionScenarios_Scenarios, 1300)
					public EnableScenarios: boolean;
					@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.ScenarioTypesEnabled", 1301)
					@PFPropertyFilter(AdvancedOption)
					@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_VersionScenarios_Scenarios)
					@PFPropertyRender((item: Settings, i, col) => item[col.fieldName].map(_ => ScenarioType[_]).join(", "), { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<ScenarioType>(ScenarioType) })
					public ScenarioTypesEnabled: Array<ScenarioType>;

					// TODO: Move this to permissions, when not a preview anymore (ewi)
					@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Settings.FindAvailableCapacityUsingLiveDatePermissions", 1305)
					@PFPropertyFilter(AdvancedOption)
					@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_VersionScenarios_Scenarios)
					@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.toDropdownOptions([UserType.SuperUser, UserType.ProjectOwner], _ => _, _ => UserType[_].toString()), MultiSelect: true })
					public FindAvailableCapacityUsingLiveDatePermissions: Array<UserType>;

				// Auto Allocation
					@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableFindAvailableCapacity", 1400)
					@PFPropertyFilter(AdvancedOption)
					@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_VersionScenarios_AutoAllocation, 1400)
					public EnableFindAvailableCapacity: boolean;
					@PFPropertyBasicInfo(PropertyType.NumberSlider, "_localization:Settings.MininumFreeCapacityPercentageForAutoAllocation", 1401)
					@PFPropertyFilter(AdvancedOption)
					@PFPropertyRender(undefined, { Max: 100, Format: (value: number) => `${value}%` }, undefined, undefined)
					@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_VersionScenarios_AutoAllocation)
					public MininumFreeCapacityPercentageForAutoAllocation: number;
					@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.DefaultActivityDistributionConfig", 1402)
					@PFPropertyRender(undefined, { HiddenProperties: [ "ResourceConstraints" ]}, "AutoDistributionSettingsEditor")
					@PFPropertyFilter(AdvancedOption)
					@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Preview_VersionScenarios_AutoAllocation)
					public DefaultActivityDistributionConfig: ActivityDistributionConfig;
		// Experimental
			// Report refresh
				@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.EnableProjectExportRefresh", 1550)
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Exp_Reports)
				public EnableProjectExportRefresh: boolean;
			// Report refresh
				@PFPropertyBasicInfo(PropertyType.Boolean, "Distribute activity parts based on capacity", 1560, "When viewing activities on a lower resolution than it spans over, distribution will respect the resource's capacity. This is applicable for SQLExport as well")
				@PFPropertyFilter(AdvancedOption)
				@PFPropertyGrouping(ESettingsLocalization.PreviewExp_Exp_Reports)
				public DistributeActivityPartsBasedOnCapacity: boolean;
			// Overviews
			@PFPropertyBasicInfo(PropertyType.Boolean, "Enable filters on 'Resource Overview", 1580)
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping("Preview and experimental|Experimental|Overviews")
			public EnableResourceOverviewsFilter: boolean;
			@PFPropertyBasicInfo(PropertyType.Boolean, "Enable filters on 'Project Overview", 1590)
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping("Preview and experimental|Experimental|Overviews")
			public EnableProjectOverviewsFilter: boolean;
			// Resource Capacity Working Percent and Utilization
			@PFPropertyBasicInfo(PropertyType.Boolean, "Enable Working percent and Utilization properties on Resource entity", 1590)
			@PFPropertyFilter(AdvancedOption)
			@PFPropertyGrouping("Preview and experimental|Experimental|Resource")
			public EnableResourceWorkingPercentAndUtilization: boolean;

			// // Contract Overview
			// 	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.ContractOverviewProperties", 50, "_localization:Settings.ContractOverviewPropertiesDescription")
			// 	@PFPropertyFilter(BasicOption)
			// 	@PFPropertyGrouping(ESettingsLocalization.PanelsOverviews_Resource_Overview, 15)
			// 	@PFPropertyRender(undefined, { EntityType: Contract, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true }, "EntityPropertySelector")
			// 	public ContractOverviewProperties: Array<string>;


	


	// OTHER
	public Deployment: any;
	public MaintenanceInProgress: boolean;
	public MaintenanceBeginTitle: string;
	public MaintenanceBeginMessage: string;
	public MaintenanceEndTitle: string;
	public MaintenanceEndMessage: string;
	public ChangedProperties: Array<string>;
	public SettingsType: number;
	
	// ONLY FOR SUB SETTINGS (avoided created a new class that inherited from this, as decorators aren't inheritable) (maybe they actually are now? to be determined - ewi)
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Settings.Matchfilter", 1, "_localization:Settings.MatchFilterDescription")
	@PFPropertyGrouping("Sub", 0)
	@PFPropertyRender(undefined, { ItemType: Resource, DialogTitleGetter: () => language.Settings.MatchFilterDialogTitle }, "EntityFilterEditor", (settings: Settings) => settings.SettingsType !== 1)
	public MatchFilter: QueryGroup;
	@PFPropertyBasicInfo(PropertyType.Number, "_localization:Settings.MatchPriority", 2, "_localization:Settings.MatchPriorityDescription")
	@PFPropertyGrouping("Sub")
	public MatchPriority: number;
	public OverrideSubSettingPropertiesAvailable: Array<string>;

}