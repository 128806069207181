import { guid } from "../../helpers/guid";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { IObjectEditorItemType } from "./IObjectEditorItemType";

export class ADGroupMap implements IObjectEditorItemType {
	public Id: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Administrator", 1)
	@PFPropertyRender(null, null, "AadGroupPicker")
	public Administrator: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.SuperUser", 2)
	@PFPropertyRender(null, null, "AadGroupPicker")
	public SuperUser: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.ProjectOwner", 3)
	@PFPropertyRender(null, null, "AadGroupPicker")
	public ProjectOwner: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.LineManager", 4)
	@PFPropertyRender(null, null, "AadGroupPicker")
	public LineManager: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Resource", 5)
	@PFPropertyRender(null, null, "AadGroupPicker")
	public Resource: string;

	constructor() {
		this.Id = this.Id || guid.newGuid();
	}
}

export const aadGroupSettingValidation = (groupMap: ADGroupMap) => {
	if (!guid.isGuidNotEmpty(groupMap.Administrator)) {
		return false;
	}
	if (!guid.isGuidNotEmpty(groupMap.SuperUser)) {
		return false;
	}
	if (!guid.isGuidNotEmpty(groupMap.ProjectOwner)) {
		return false;
	}
	if (!guid.isGuidNotEmpty(groupMap.LineManager)) {
		return false;
	}
	return true;
}