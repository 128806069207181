/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo, useEffect, useState } from 'react';
import { Dropdown, IconButton, TextField, ComboBox } from '@fluentui/react';
import { ReportFilter, ReportFilterCondition, ReportFilterExpression, ReportFilterOperator } from '../../../Entities/ReportFilter';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { IDs } from '../../../Utilities/IDs';
import EnumEx from '../../../Utilities/EnumEx';
import { DropdownOptionEx } from '../../../Utilities/DropdownOptionEx';
import { StringEx } from '../../../Utilities/StringEx';
import { language } from '../../../Services/LocalizationService';

interface IProps extends IDynamicEditorComponentProps {
    Item: ReportFilter;
    Update: (group: ReportFilter) => void;
}

const ReportFilterEditor: FunctionComponent<IProps> = props => {
    const [root, setRoot] = useState<ReportFilter>(props.Item || new ReportFilter());

    useEffect(() => {}, []);

    const Update = (): void => {
        setRoot({ ...root });
        props.Update(root);
    };

    const Clear = (): void => {
        root.Parts.pop();
        setRoot({ ...root });
        props.Update(root);
    };

    const RenderGroup = (filter: ReportFilter, index?: number): JSX.Element => {
        if (!filter.Parts) filter.Parts = new Array<any>();
        const parts = filter.Parts.map((_, i) => {
            if (_.TypeName === 'ReportFilterExpression') return RenderExpression(_, i);
            else return RenderCondition(_, filter, i);
        });

        return (
            <div className="querygroup" key={`group-${index}`}>
                {parts}
                <div style={{ margin: '5px' }}>
                    {RenderMenu(filter)}
                    <IconButton iconProps={{ iconName: 'Delete' }} style={{ top: '5px', fontSize: '20px' }} onClick={e => Clear()} />
                </div>
            </div>
        );
    };

    const RenderCondition = (condition: ReportFilterCondition, group: ReportFilter, index: number): JSX.Element => {
        return (
            <div key={IDs.makeId()}>
                <Dropdown
                    placeholder={language.Common.Condition}
                    className="querycondition"
                    selectedKey={condition}
                    options={EnumEx.allAsNumber<ReportFilterCondition>(ReportFilterCondition).map(e => {
                        return { key: e as number, text: ReportFilterCondition[e].toString() };
                    })}
                    onChange={(e, o) => {
                        group.Parts[index] = ReportFilterCondition[o.text];
                        Update();
                    }}
                />
            </div>
        );
    };

    const RenderExpression = (filter: ReportFilterExpression, idx: number): JSX.Element => {
        const operators = DropdownOptionEx.enumToDropdownOptions<ReportFilterOperator>(ReportFilterOperator);
        const valueOptions = DropdownOptionEx.toDropdownOptions(
            [
                { key: '{ProjectId}', text: 'Project id' },
                { key: '{ResourceId}', text: 'Resource id' },
                { key: '{UserId}', text: "Logged on users' resource id" },
                { key: '{UserEmail}', text: "Logged on users' email" },
            ],
            _ => _.key,
            _ => _.text,
        );
        if (
            filter.Value != null &&
            filter.Value !== '{ProjectId}' &&
            filter.Value !== '{ResourceId}' &&
            filter.Value !== '{UserId}' &&
            filter.Value !== '{UserEmail}'
        )
            valueOptions.push({ key: filter.Value, text: filter.Value });

        return (
            <div className="queryexpression" key={`filter-expr-${idx}`}>
                <TextField
                    placeholder={language.ReportFilterEditor.Property}
                    defaultValue={filter.Property}
                    onChange={(obj, value) => {
                        filter.Property = StringEx.replaceAll(value, ' ', '_x0020_');
                        Update();
                    }}
                />
                <div>
                    <Dropdown
                        placeholder={language.ReportFilterEditor.Operator}
                        options={operators}
                        defaultSelectedKey={filter.Operator || ReportFilterOperator.Equals}
                        onChange={(e, o) => {
                            filter.Operator = ReportFilterOperator[o.text];
                            Update();
                        }}
                    />
                </div>
                <div>
                    <ComboBox
                        placeholder={language.ReportFilterEditor.SelectContextProperty}
                        allowFreeform
                        autoComplete="on"
                        options={valueOptions}
                        selectedKey={filter.Value}
                        onChange={(event, option, idx, value) => {
                            const val =
                                option != null
                                    ? (option.key as string) // options
                                    : value; // freeform

                            filter.Value = val;
                            Update();
                        }}
                    />
                </div>
            </div>
        );
    };

    const RenderMenu = (filter: ReportFilter): JSX.Element => {
        return (
            <div style={{ float: 'left' }}>
                <IconButton
                    title={language.Common.Add}
                    iconProps={{ iconName: 'Add' }}
                    menuProps={{
                        shouldFocusOnMount: true,
                        items: [
                            {
                                key: 'expression',
                                iconProps: { iconName: 'QueryList' },
                                text: language.Common.Expression,
                                disabled: filter.Parts.length > 0 && filter.Parts[filter.Parts.length - 1].TypeName != null,
                                onClick: () => {
                                    filter.Parts.push(new ReportFilterExpression());
                                    Update();
                                },
                            },
                            {
                                key: 'condition',
                                iconProps: { iconName: 'DrillExpand' },
                                text: language.Common.Condition,
                                disabled: filter.Parts.length === 0 || filter.Parts[filter.Parts.length - 1].TypeName == null,
                                onClick: () => {
                                    filter.Parts.push(ReportFilterCondition.And);
                                    Update();
                                },
                            },
                        ],
                    }}
                />
            </div>
        );
    };

    return <div className="tp-reportfilter">{RenderGroup(root, null)}</div>;
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Item === nextProps.Item;
};

export default memo(ReportFilterEditor, comparisonFn);
