import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, PrimaryButton, Text } from '@fluentui/react';
import { useMemo, useState } from 'react';
import shallow from 'zustand/shallow';
import { useStore } from '../../context/store';
import { language } from '../../Services/LocalizationService';
import { useTheme } from '../../Themes/themeContext';
import { buttonRootStyles } from '../Overview/Table/Header/Header';

export const RenderFastDeletionToggle: React.FC = () => {
    const theme = useTheme();
    const [hideDialog, setHideDialog] = useState(false);
    const { fastDeletionEnabled, setFastDeletionEnabled } = useStore(
        store => ({
            fastDeletionEnabled: store.gridSettingsEnableFastDeletion,
            setFastDeletionEnabled: store.setGridSettingsEnableFastDeletionCallback,
        }),
        shallow,
    );

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Warning',
        subText: 'You are about to enable fast deletion. This will delete the item and cannot be undone.',
    };

    const modalProps = useMemo(
        () => ({
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
        }),
        [],
    );

    const _toggleFastDeletionEnabled = () => {
        setFastDeletionEnabled();
    };

    const _toggleDialogVisibility = () => {
        setHideDialog(s => !s);
    };

    const _acceptClickHandler = () => {
        _toggleFastDeletionEnabled();
        _toggleDialogVisibility();
        document.body.click(); // TODO Hack to close the callout when accepted
    };

    return (
        <Text
            onClick={fastDeletionEnabled ? _toggleFastDeletionEnabled : _toggleDialogVisibility}
            styles={{
                root: {
                    ...buttonRootStyles,
                    '&:hover': {
                        backgroundColor: theme.semanticColors.buttonBackgroundHovered,
                    },
                },
            }}
        >
            <Icon
                iconName="RecycleBin"
                styles={{
                    root: {
                        paddingTop: 2,
                        marginRight: 8,
                        marginLeft: 4,
                        color: theme.semanticColors.menuIcon,
                        fontSize: 16,
                    },
                }}
            />
            {fastDeletionEnabled ? language.GridSettings.DisableFastDelete : language.GridSettings.EnableFastDelete}

            {hideDialog ? (
                <Dialog hidden={!hideDialog} onDismiss={_toggleDialogVisibility} dialogContentProps={dialogContentProps} modalProps={modalProps}>
                    <DialogFooter>
                        <PrimaryButton
                            onClick={_acceptClickHandler}
                            text={language.Common.Accept}
                            styles={{ root: { backgroundColor: 'red', borderColor: 'darkred !important' }, rootHovered: { backgroundColor: '#dc2626' } }}
                        />
                        <DefaultButton onClick={_toggleDialogVisibility} text={language.Common.Cancel} styles={{ root: { color: '' } }} />
                    </DialogFooter>
                </Dialog>
            ) : null}
        </Text>
    );
};
