export default class Locale_Upgrade { 
	MajorAndMinorVersionsMustMatch: string = "Major and minor versions of the packages must match!";
	DeployPackages: string = "Deploying packages";
	UpgradeRequestSent: string = "An upgrade request has been sent!";
	FailedDeployingPackages: string = "Failed deploying packages";
	ReleaseCycle: string = "Release cycle";
	SelectCycle: string = "Select a cycle";
	AlwaysUseLatestPackage: string = "Always use latest package from the selected release cycle";
	LoadingPackages: string = "Loading packages...";
	NoReleaseNotes: string = "No release notes";
	ReleaseNotes: string = "Release notes";
	StartupStateAfterUpgrade: string = "Startup state after upgrade";
}