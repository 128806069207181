import { useCallback, useMemo, useRef } from 'react';
import { Resource } from '../../../../../api/generated/data-contracts';
import { useUISettings } from '../../../../../context/network/http/QueryProvider/queries/UISettings';
import { resourceSchema } from '../../../../../forms/resource/resourceSchema';
import { useOverviewContext } from '../../../../../_components/OverviewCreator/context/OverviewCreatorContext';
import { BulkEditorDialog } from '../../../_components/BulkEditorDialog';

export const ResourceBulkEditor = ({
	show, setShow, onSubmit,
}: {
	show: boolean;
	setShow: (state: boolean) => void;
	onSubmit: (items: Resource[]) => void | Promise<void>;
}) => {
	const { selectedItems } = useOverviewContext<Resource>();
	const { data: uiSetting } = useUISettings();
	const dismiss = useCallback(() => setShow(false), [setShow]);
	const template = useMemo(() => ({ resourceType: selectedItems?.[0]?.resourceType, customProperties: {} } as Resource), [selectedItems]);
	const onChange = useCallback(
		async (items: Resource[]) => {
			await onSubmit(items);
			setShow(false);
		},
		[onSubmit, setShow]
	);
	const hiddenProperties = useRef([]).current;
	return (
		<BulkEditorDialog
			items={[...selectedItems]}
			show={show}
			dismiss={dismiss}
			entityType="resource"
			template={template}
			schema={resourceSchema}
			customProperties={uiSetting.settings.resourceCustomProperties}
			hiddenProperties={hiddenProperties}
			onSubmit={onChange} />
	);
};
