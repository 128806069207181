import { CSSProperties, memo, useCallback, useMemo } from 'react';
import { ColumnRowProps } from '../../ColumnRowProps';
import { ColoredTotalCell } from './ColoredTotalCell';
import { useGridRowContext } from '../../../../../../../_grid/contexts/rowContext';
import { Stack } from '@fluentui/react';
import { useScrollToContext } from '../../../../context/ScrollToContext';
import { SubHeader } from './SubHeader';

export const ProjectColumnRow = memo(function ProjectColumnRow({
    columnRow,
    columnIndex,
    expanded,
}: ColumnRowProps & { expanded: boolean }) {
    const { toggleExpanded, rowIndex } = useGridRowContext();
    const render = useMemo(() => {
        return columnRow.cells.map(cell => {
            return <ColoredTotalCell key={cell.id} cell={cell} columnIndex={columnIndex} isRowExpanded={expanded} totalColumnRow={columnRow} />;
        });
    }, [columnIndex, expanded, columnRow]);
    const scrollTo = useScrollToContext();
    const renderColumns = useMemo(() => {
        return (
            <ColumnGridContainer>
                <SubHeader subHeader={{ name: 'Request' }} />
                <SubHeader subHeader={{ name: 'Allocation' }} />
                <SubHeader subHeader={{ name: 'Free' }} />
            </ColumnGridContainer>
        );
    }, []);
    const onClick = useCallback(() => {
        if (!expanded) {
            scrollTo({ rowIndex: rowIndex - 1, align: 'start' });
        }
        toggleExpanded();
    }, [expanded, rowIndex, scrollTo, toggleExpanded]);
    return (
        <Stack onClick={onClick} style={containerStyles}>
            <div style={styles}>{render}</div>
            {expanded && renderColumns}
        </Stack>
    );
});

const containerStyles: CSSProperties = { height: '100%' };
const styles: CSSProperties = { display: 'flex', justifyContent: 'center', position: 'relative', alignItems: 'center', flexGrow: 1 };

const ColumnGridContainer = ({ children }: { children?: React.ReactNode }) => {
    const style = useMemo(() => {
        return {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            padding: '0px 10px',
            gap: 10,
            alignItems: 'center',
        } as CSSProperties;
    }, []);
    return <div style={style}>{children}</div>;
};
