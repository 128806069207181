import { FunctionComponent, useState, useEffect } from 'react';
import { Dropdown } from '@fluentui/react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { DropdownOptionEx } from '../../../Utilities/DropdownOptionEx';
import { ContentConfigurationService } from '../../../Services/ContentConfigurationService';
import { RbsStructure } from '../../../Entities/ContentConfiguration/RbsStructure';
 
interface IProps extends IDynamicEditorComponentProps { 
    Item: string;
    Update: (value: string) => void;
    Key?: string;
}

const RbsSelector: FunctionComponent<IProps> = props => {
    const [rbsStructures, setRbsStructures] = useState<Array<RbsStructure>>();

    useEffect(() => {
		let mounted = true;
        const fetchLookups = async () => {
			const rbsStructureItems = await ContentConfigurationService.GetRbsStructures();
			if (mounted)
				setRbsStructures(rbsStructureItems);
        };
		fetchLookups();
		return () => {
			mounted = false
		};
    }, [])

    const onChange = (item: string): void => {
		props.Update(item);
    };

	return  (
		<>
			{ rbsStructures && 
				<Dropdown
					onChange={ (ev, option, idx) => onChange(option.key as string)}
					options={ DropdownOptionEx.toDropdownOptions(rbsStructures, _ => _.id, _ => _.Name) }
					defaultSelectedKey={ props.Item }
					disabled={props.ReadOnly || !rbsStructures}
				/>
			}
		</>
	)
}

export default RbsSelector;