/* eslint-disable react-refresh/only-export-components */
import { IButtonProps, Icon, TooltipHost } from '@fluentui/react';
import { FunctionComponent } from 'react';
import { useUISettings } from '../context/network/http/QueryProvider/queries/UISettings';
import { EnvironmentState, useEnvironmentStateName } from '../Entities/EnvironmentState';

export function useDisableByEnvironment(disabledOn: EnvironmentState[]): boolean {
    return disabledOn.includes(useUISettings().data.environmentState);
}

export const disabledButtonProps: IButtonProps = { disabled: true, style: { color: 'lightgrey' } };

export interface DisabledPivotItemProps {
    iconName: string;
    headerText: string;
    tooltipText?: string;
}

export const DisabledPivotItem: FunctionComponent<DisabledPivotItemProps> = ({ iconName, headerText, tooltipText }) => {
	const envStateName = useEnvironmentStateName();
    return (
        <span>
            <TooltipHost content={tooltipText || `This feature is not available in ${envStateName} mode`}>
                <span>
                    <Icon iconName={iconName} />
                    <span style={{ marginLeft: 4, verticalAlign: 'top' }}>{headerText}</span>
                </span>
            </TooltipHost>
        </span>
    );
};
