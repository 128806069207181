import { BaseRow, IBaseRow } from "./BaseRow";
import { GroupRow, IGroupRow } from "./GroupRow";

export class GroupContainerRow extends BaseRow {
	public TypeName = "GroupContainerRow";
    public Groups: Array<GroupRow>;

    constructor(public Id: string, public Name: string, public Properties: { [key: string]: any } = null) {    
        super(Id, Name);
        this.Groups = new Array<GroupRow>();
    }
}

export interface IGroupContainerRow extends IBaseRow {
	TypeName: "GroupContainerRow";
	Groups: IGroupRow[];
	Rows: IGroupRow[]
}