import { DefaultButton, IPanelProps, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { IFormItem } from '@wiberg/formbuilder';
import React, { useCallback } from 'react';
import { QueryGroup } from '../../../../../api/generated/data-contracts';
import { EntityPropertyFilter, EntityPropertyFilterProps } from '../../../../../components_new/EntityPropertyFilter';
import { useControlledState } from '../../../../../hooks/useControlledState';

type PropertyFilterReducedProps<T extends IFormItem> = Pick<
    EntityPropertyFilterProps<T>,
    | 'entityType'
    | 'disabled'
    | 'customProperties'
    | 'filterArrayProperties'
    | 'filterSystemProperties'
	| 'onlyTheseProperties'
    | 'schema'
>;

type ViewFilterPanelProps<T extends IFormItem> =
    Pick<IPanelProps, 'headerText' | 'className' | 'styles' | 'type'> &
        PropertyFilterReducedProps<T> & {
            openPanel: boolean;
            setOpenPanel: React.Dispatch<React.SetStateAction<boolean>>;
            onClickSave?: (filter: QueryGroup | null) => void | Promise<void>;
			onDismissPanel?: () => void;
            initialFilterValue?: QueryGroup | null | (() => QueryGroup | null);
            loading?: boolean;
			onChange?: (filter: QueryGroup | null) => void;
        };

export const ViewFilterPanel = <T extends IFormItem>({
    openPanel,
    setOpenPanel,
    initialFilterValue,
    entityType,
    schema,
    customProperties,
    disabled,
    filterArrayProperties,
    filterSystemProperties,
	onlyTheseProperties,
    onClickSave,
	onDismissPanel,
    loading,
	onChange,
    // children,
    ...rest
}: ViewFilterPanelProps<T>) => {
    const [filter, setFilter] = useControlledState<QueryGroup | null>(initialFilterValue, [initialFilterValue]);
    // console.log("🚀 ~ filter:", filter)
    
	const onSave = useCallback(() => {
		if (onClickSave) {
			onClickSave(filter);
		}
    }, [filter, onClickSave]);
	
	const onCancel = useCallback(() => {
		onDismissPanel();
		setFilter(initialFilterValue)
	}, [initialFilterValue, onDismissPanel, setFilter])
    
	const onRenderFooterContent = useCallback(() => {
        return (
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                {onClickSave && <PrimaryButton onClick={onSave}>Apply</PrimaryButton>}
                {onDismissPanel && <DefaultButton onClick={onCancel}>{onClickSave ? 'Cancel' : 'Close'}</DefaultButton>}
                {loading && <Spinner size={SpinnerSize.large} />}
            </Stack>
        );
    }, [loading, onClickSave, onDismissPanel, onSave, onCancel]);
	
	const onChanged = useCallback((filter: QueryGroup | null) => {
		if (onChange) {
			onChange(filter)
		}
		setFilter(filter)
	}, [onChange, setFilter])
    
	return (
        <Panel
            isOpen={openPanel}
            isLightDismiss={true}
            onDismiss={() => setOpenPanel(false)}
            type={PanelType.custom}
			customWidth={"900px"}
            isBlocking={false}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom
            // headerText={panelHeaderText}
            {...rest}
        >
            <EntityPropertyFilter
                item={filter}
                schema={schema}
                customProperties={customProperties}
                onChanged={onChanged}
                filterSystemProperties={filterSystemProperties ?? true}
                filterArrayProperties={filterArrayProperties ?? true}
				onlyTheseProperties={onlyTheseProperties}
                entityType={entityType}
                // entityType={'resource'}
                disabled={disabled}
            />
        </Panel>
    );
};
