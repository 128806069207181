import { Rule } from "../Entities/Main/Rule";

export function rulesOverlap(rules: Array<Rule>): { overlap: boolean; ranges: Array<Rule>; } {
	if (!rules)
		return { overlap: false, ranges: new Array<Rule>() };

	const sorted = rules.sort((previous, current) => {
		if (!previous.Start || !current.Start)
			return 0;
		// get the start date from previous and current
		const previousTime = previous.Start.getTime();
		const currentTime = current.Start.getTime();
		// if the previous is earlier than the current
		if (previousTime < currentTime)
			return -1;
		// if the previous time is the same as the current time
		if (previousTime === currentTime)
			return 0;
		// if the previous time is later than the current time
		return 1;
	});

	const result = sorted.reduce((result: { overlap: boolean; ranges: Array<Rule>; }, current: Rule, idx: number, arr: Array<Rule>) => {
		// get the previous range
		if (idx === 0)
			return result;
		const previous = arr[idx - 1];
		// check for null
		if (!previous.Start || !current.Start)
			return result;
		// check for any overlap
		const previousEnd = previous.End.getTime();
		const currentStart = current.Start.getTime();
		const overlap = (previousEnd >= currentStart);
		// store the result
		if (overlap) {
			// yes, there is overlap
			result.overlap = true;
			// store the specific ranges that overlap
			result.ranges.push(...[previous, current]);
		}
		return result;
		// seed the reduce  
	}, { overlap: false, ranges: new Array<Rule>() });

	// return the final results  
	return result;
}

export const RuleEx = {
	rulesOverlap
}