/* eslint-disable react-refresh/only-export-components */

import React, { useCallback, useMemo, useState } from 'react';
import { IContextualMenuItem, IconButton } from '@fluentui/react';
import { CONTRACT_EXISTS } from '../../context/network/http/QueryProvider/queryKeys';
import { ApiCalls } from '../../api/apiv2';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { useQuery } from 'react-query';
import { WithStore } from '../../Services/StateStore';
import { GridType, ResourceType, Sticky, VersionResolution, WorkType } from '../../api/generated/data-contracts';
import { StoreProps } from '../../Services/StoreProps';
import { RowMenuItemInfo } from '../../Entities/Dto/RowMenuItemInfo';
import { language } from '../../Services/LocalizationService';
import { PluginIDs } from '../../Utilities/PluginIDs';
import { guid } from '../../helpers/guid';
import { ContractInformationDialog } from './ContractInformationDialog';
import { CustomContractPropertiesDialog } from './CustomContractPropertiesDialog';
import { MoveContractDialog } from './MoveContractDialog';
import { Plugin } from '../../Services/PluginInvoker';
import { CountDownConfirm } from '../../Services/CountDownConfirm';
import { DataService } from '../../Services/DataService';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { DebugOverrides } from '../../Utilities/DebugOverrides';
import { useStore } from '../../context/store';
import { EntityType } from '../../Entities/EntityTypes';
import { IBlockContent } from '../../context/contexts/pageBlockContext/pageBlockContext';

export interface IContractRowMenuProps extends StoreProps {
    ContractId: string;
    GridType: GridType;
    IsProjectOwner: boolean;
    AllProperties: { [key: string]: any };
}

const ContractRowMenu: React.FC<IContractRowMenuProps> = ({ AllProperties: allProperties, ContractId: contractId, GridType: gridType, IsProjectOwner: isProjectOwner, Store: store }) => {
    const { data: uiSettings } = useUISettings();
    const [selectedSticky, setSelectedSticky] = useState(allProperties.Sticky);

	const { setBlockDialog } = useStore(store => ({
		setBlockDialog: store.setBlockDialog
	}))

    const {
        data: contractExists,
        refetch: refetchContractExists,
        isStale: isContractExistsStale,
    } = useQuery({
        queryKey: [CONTRACT_EXISTS, contractId],
        queryFn: () => ApiCalls.contractExists(contractId).then(res => res.data),
        staleTime: 5000,
        enabled: false,
    });

    const openDialog = useCallback((content: JSX.Element, options: Omit<IBlockContent, "content">) => {	
        setBlockDialog(content, options);
    }, [setBlockDialog]);

    const menuItems = useMemo(
        () =>
            getMenuItems({
				contractId,
				gridType,
				isProjectOwner,
				allProperties,
				uiSettings,
				contractExists,
				selectedSticky,
				setSelectedSticky,
				store,
				openDialog
            }),
        [contractId, gridType, isProjectOwner, allProperties, uiSettings, contractExists, selectedSticky, store, openDialog],
    );

    return (
        <IconButton
            className="tp-grid-rowmenu-icon"
            iconProps={{ iconName: 'More' }}
            menuProps={{
                items: menuItems,
                onMenuOpened: () => {
                    if (isContractExistsStale) {
                        refetchContractExists();
                    }
                },
            }}
        />
    );
};

export default WithStore<IContractRowMenuProps>(ContractRowMenu);

// generate menuItems for the contract row menu
const getMenuItems = ({
    contractId,
    gridType,
    isProjectOwner,
    allProperties,
    uiSettings,
    contractExists,
    selectedSticky,
    setSelectedSticky,
    store,
	openDialog
}): IContextualMenuItem[] => {

    const handleClickMenuItem = (sticky: Sticky) => {
        setSelectedSticky(sticky);
        allProperties.Sticky = sticky;
        Plugin.InvokeNoResponse(PluginIDs.DeltaUpsert, buildStickyDto(sticky, allProperties));
    };

    const buildStickyMenuItem = (key: string, languageKey: string, sticky: Sticky): IContextualMenuItem => ({
        key,
        text: language.Stickyness[languageKey],
        title: language.Stickyness[`${languageKey}${language.Common.Description}`],
        disabled: selectedSticky === sticky,
        onClick: () => handleClickMenuItem(sticky),
    });

    const menuItems: IContextualMenuItem[] = [];

    if (gridType === GridType.Request || (gridType === GridType.Allocation && isProjectOwner)) {
        if (!allProperties.IsAdmin) {
            // create matching request
            if (uiSettings.settings.enableCreateMatchingRequest) {
                allProperties.MatchType = 0;
                menuItems.push({
                    key: 'matchingrequest',
                    text: language.ContractRowMenu.MenuItemText,
                    title: contractExists ? language.ContractRowMenu.MenuItemTitle : 'Contract does not exist yet',
                    iconProps: { iconName: 'DependencyAdd' },
                    disabled: !contractExists,
                    onClick: () => {
                        const info = new RowMenuItemInfo(contractId, null, gridType , null, allProperties);
                        store.Set({ ShowMatchingActivityDialog: true, MatchingActivityInfo: info });
                    },
                });
            }
        }
        // move contract
        if (uiSettings.settings.enableMoveContract) {
            menuItems.push({
                key: 'movecontract',
                text: language.ContractRowMenu.MoveContractItemText,
                title: contractExists ? language.ContractRowMenu.MoveContractItemTitle : 'Contract does not exist yet',
                iconProps: { iconName: 'Share' },
                disabled: !contractExists,
                onClick: () => {
                    openDialog(<MoveContractDialog ContractId={contractId} />, {
                        title: `Move '${allProperties.ContractName}' to another project`,
                        subText:
                            'Moves all requests and allocations to another project. Metadata will be copied. Historical data will stay in this project and a copy will be created in the target project.',
                        dismissEnable: false,
                        styles: { main: { maxWidth: '500px' } },
                    });
                },
            });
        }
    }

    if (gridType  === GridType.Request) {
        // stickyness
        menuItems.push({
            key: 'sticky',
            text: language.ContractRowMenu.StickyItemText,
            title: contractExists ? language.ContractRowMenu.StickyItemTitle : 'Contract does not exist yet',
            iconProps: { iconName: 'Pinned' },
            disabled: !contractExists,
            subMenuProps: {
                items: [
                    buildStickyMenuItem('stickyoff', 'Off', Sticky.Off),
                    buildStickyMenuItem('stickyalways', 'Always', Sticky.Always),
                    buildStickyMenuItem('stickybefore', 'ActivitiesBefore', Sticky.ActivitiesBefore),
                    buildStickyMenuItem('stickyafter', 'ActivitiesAfter', Sticky.ActivitiesAfter),
                    buildStickyMenuItem('stickybeforeorafter', 'ActivitiesBeforeOrAfter', Sticky.ActivitiesBeforeOrAfter),
                ],
            },
        });

        if (
            OverviewContext.Settings.EnableFindAvailableCapacity &&
            [ResourceType.Generic, ResourceType.Pool, undefined].includes(allProperties.RequestedResourceType)
        ) {
            menuItems.push({
                key: 'magicbutton',
                text: language.Common.FindAvailableCapacity,
                title: contractExists ? language.Common.FindAvailableCapacity : 'Contract does not exist yet',
                iconProps: { iconName: 'Calculator' },
                disabled: !contractExists,
                onClick: () => {
                    const info = new RowMenuItemInfo(contractId, null, gridType , allProperties.ProjectId, allProperties);
                    store.Set({ FindAvailableCapacityDialog: true, FindAvailableCapacityInfo: info });
                },
            });
        }
    }

    if (DebugOverrides.Enabled && [GridType.Request, GridType.Allocation].includes(gridType )) {
        menuItems.push({
            key: 'unrestricted',
            text: 'Unrestricted (use with care)', //TODO LOCALIZATION
            title: 'Unrestricted (use with care)', //TODO LOCALIZATION
            iconProps: { iconName: 'ProtectRestrict' },
            subMenuProps: {
                items: [
                    {
                        key: 'deletecontract',
                        text: 'Delete ENTIRE contract', //TODO LOCALIZATION
                        title: 'Delete ENTIRE contract', //TODO LOCALIZATION
                        iconProps: { iconName: 'Delete' },
                        onClick: () => {
                            CountDownConfirm.Instance.Fire(
                                'Are you sure you want to delete the ENTIRE contract?!!',
                                'All requests and allocations will vanish as well. This CANNOT be undone!',
                                10,
                                async () => {
                                    await DataService.Delete(EntityType.Contract, [{ TypeName: 'Contract', id: contractId }]);
                                    OverviewContext.RefreshProjectCapacity();
                                },
                                () => {},
                            );
                        },
                    },
                    {
                        key: 'deleterequests',
                        text: 'Delete ALL requests', //TODO LOCALIZATION
                        title: 'Delete ALL requests', //TODO LOCALIZATION
                        iconProps: { iconName: 'Delete' },
                        onClick: () => {
                            CountDownConfirm.Instance.Fire(
                                'Are you sure you want to delete ALL the requests on this contract?!!',
                                'This CANNOT be undone!',
                                10,
                                async () => {
                                    await Plugin.InvokeNoResponse(
                                        PluginIDs.RemoveAllActivities,
                                        { ContractId: contractId, WorkType: WorkType.Request },
                                        'Failed removing requests',
                                        true,
                                    );
                                    OverviewContext.RefreshProjectCapacity();
                                },
                                () => {},
                            );
                        },
                    },
                    {
                        key: 'deleteallocations',
                        text: 'Delete ALL allocations', //TODO LOCALIZATION
                        title: 'Delete ALL allocations', //TODO LOCALIZATION
                        iconProps: { iconName: 'Delete' },
                        onClick: () => {
                            CountDownConfirm.Instance.Fire(
                                'Are you sure you want to delete ALL the allocations on this contract?!!',
                                'This CANNOT be undone!',
                                10,
                                async () => {
                                    await Plugin.InvokeNoResponse(
                                        PluginIDs.RemoveAllActivities,
                                        { ContractId: contractId, WorkType: WorkType.Allocation },
                                        'Failed removing allocations',
                                        true,
                                    );
                                    OverviewContext.RefreshProjectCapacity();
                                },
                                () => {},
                            );
                        },
                    },
                    {
                        key: 'resetmailflagday',
                        text: 'Reset mail status Day', //TODO LOCALIZATION
                        title: 'Reset mail status Day', //TODO LOCALIZATION
                        iconProps: { iconName: 'Refresh' },
                        onClick: async () => {
                            await ApiCalls.resetMailFlagForVersions({ contractId: contractId, resolution: VersionResolution.Day });
                        },
                    },
                    {
                        key: 'resetmailflagweek',
                        text: 'Reset mail status Week', //TODO LOCALIZATION
                        title: 'Reset mail status Week', //TODO LOCALIZATION
                        iconProps: { iconName: 'Refresh' },
                        onClick: async () => {
                            await ApiCalls.resetMailFlagForVersions({ contractId: contractId, resolution: VersionResolution.Week });
                        },
                    },
                    {
                        key: 'resetmailflagmonth',
                        text: 'Reset mail status Month', //TODO LOCALIZATION
                        title: 'Reset mail status Month', //TODO LOCALIZATION
                        iconProps: { iconName: 'Refresh' },
                        onClick: async () => {
                            await ApiCalls.resetMailFlagForVersions({ contractId: contractId, resolution: VersionResolution.Month });
                        },
                    },
                ],
            },
        });
    }

    if (uiSettings.settings.contractCustomProperties?.length && gridType  !== GridType.WorkPackage) {
        menuItems.push({
            key: 'contractProperties',
            text: 'Contract Properties', //TODO LOCALIZATION
            title: contractExists ? 'Contract Properties' : 'Contract does not exist yet',
            iconProps: { iconName: 'Info' },
            disabled: !contractExists,
            onClick: () => {
                openDialog(<CustomContractPropertiesDialog AllProperties={allProperties} ContractId={contractId} />, {
                    title: `${allProperties.ContractName} properties`,
                    dismissEnable: false,
                    styles: { main: { maxWidth: '550px' } },
                });
            },
        });
    }
    if (uiSettings.settings.contractInformationProperties?.length) {
        menuItems.push({
            key: 'contractInformation',
            text: 'Contract Information', //TODO Localization
            title: contractExists ? 'Contract Information' : 'Contract does not exist yet',
            iconProps: { iconName: 'Info' },
            disabled: !contractExists,
            onClick: () => {
                openDialog(<ContractInformationDialog AllProperties={allProperties} ContractId={contractId} />, {
                    title: `${allProperties.ContractName} information`,
                    dismissEnable: false,
                    styles: { main: { maxWidth: '500px' } },
                });
            },
        });
    }

    return menuItems;
};

const buildStickyDto = (sticky: Sticky, allProperties: any) => ({
    Origin: guid.empty,
    Entity: EntityType.Contract,
    Items: [
        {
            TypeName: 'Contract',
            Id: guid.newGuid(), // Use actual contract ID
            Stickyness: sticky,
            ProjectId: allProperties.ProjectId,
            ResourceGuid: allProperties.ResourceId,
            Name: allProperties.ContractName,
        },
    ],
    ChangedProperties: ['Id', 'Name', 'ProjectId', 'ResourceGuid', 'Stickyness'],
    Force: guid.newGuid(), // Random or specific force upsert key
});
