import { ProposalState } from "../api/generated/data-contracts";

export enum ScenarioType {
	ProjectScenario = 0,
	PortfolioScenario = 1
}

export class Scenario {
	public TypeName: string = "Scenario";
	public id: string;
	public Name: string;
	public ScenarioType: ScenarioType;
    public State: ProposalState;
    public OwnerId: string;
	public Start: Date;
	public End: Date;
}

export class ProjectScenario extends Scenario {
	ScenarioType = ScenarioType.ProjectScenario;
	ProjectId: string;
	PortfolioScenarioId: string;
}