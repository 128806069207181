/* eslint-disable react-refresh/only-export-components */
import React, { createContext, useContext, useState, useCallback, useRef } from 'react';
import { getInputFocusStyle, IRawStyle } from "@fluentui/react/lib/Styling";
import { useTheme } from '../../../../Themes/themeContext';
import { IDraggedItem, TSizes, IItem, IItemWithValue, TDragDirectionAll } from './DragTypes';
import { CellUpdateDto } from './CellUpdateDto';
import { NumberParser } from '../../../../Utilities/NumberParser';

export interface IDragContext {
	isDragging: boolean;
	setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
	showDragBox: boolean;
	setShowDragBox: React.Dispatch<React.SetStateAction<boolean>>;
	draggedItem: IDraggedItem;
	setDraggedItem: React.Dispatch<React.SetStateAction<IDraggedItem>>
	selectedFields: IDraggedItem[];
	inputNodes: any;
	addInputNode: any;
	// containerSize: TSizes;
	inputSize: TSizes;
	focusStyle: IRawStyle;
	columnCount: number;
	copyFromExcel: (startCell: IItem) => void;
	elementIdSuffix?: string;
	dragContainerClass?: string;
	dragDirection?: React.MutableRefObject<TDragDirectionAll>;
}

const DragContext = createContext<IDragContext | undefined>({
	isDragging: false,
	setIsDragging: () => null,
	showDragBox: false,
	setShowDragBox: () => null,
	draggedItem: {} as IDraggedItem,
	setDraggedItem: () => null,
	inputNodes: [],
	addInputNode: () => null,
	inputSize: { width: 0, height: 0 },
	focusStyle: {},
	selectedFields: [],
	columnCount: 0,
	copyFromExcel: () => null,
	elementIdSuffix: null,
	dragDirection: null,
} as IDragContext);

export const useDragCtx = () => useContext(DragContext) as IDragContext;

interface IProps {
	columnCount: number;
	// resourceId: string;
	// gridType: GridType;
	pasteFromExcel: (cells: Array<CellUpdateDto>, excessiveCells: Array<CellUpdateDto>) => void;
	elementIdSuffix?: string;
	dragContainerClass?: string;
}

export const DragContextProvider: React.FC<IProps> = React.memo((props) => {
	const theme = useTheme();
	const [isDragging, setIsDragging] = useState(false);
	const [showDragBox, setShowDragBox] = useState(false);
	const [draggedItem, setDraggedItem] = useState<IDraggedItem | null>(null);
	const selectedFields = useRef([]);
	const inputSize = useRef({ width: 80, height: 30 });
	const focusStyle = useRef(getInputFocusStyle(theme.palette.accent, 2));
	const [inputNodes] = useState(() => new Map());
	const addInputNode = useCallback((node, i) => node && inputNodes.set(i, node), [inputNodes]);
	const dragDirection = useRef<TDragDirectionAll>(null);

	const copyFromExcel = useCallback((startCell: IItem) => {
		(navigator as any).clipboard.readText()
			.then((text: string) => {
				formatPastedData(inputNodes, text, startCell, props.pasteFromExcel);
			})
			.catch((err: any) => {
				console.error('Failed to read clipboard contents: ', err);
			});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputNodes]);

	return (
		<DragContext.Provider value={{ 
			isDragging, setIsDragging,
			showDragBox, setShowDragBox,
			draggedItem, setDraggedItem,
			inputNodes, addInputNode,
			inputSize: inputSize.current,
			focusStyle: focusStyle.current,
			columnCount: props.columnCount,
			selectedFields: selectedFields.current,
			copyFromExcel,
			elementIdSuffix: props.elementIdSuffix,
			dragContainerClass: props.dragContainerClass,
			dragDirection: dragDirection,
		}}>
			{ props.children }
		</DragContext.Provider>
	);
});

export default DragContext;

const formatPastedData = (inputNodes: any, text: string, startCell: IItem, pasteFromExcel: (cells: Array<CellUpdateDto>, excessiveCells: Array<CellUpdateDto>) => void) => {
	// if not enabled
	if (!pasteFromExcel) return; 
	const filteredCells = filterCells(inputNodes, startCell);

	const cellsGroupedByResource = groupByResource(filteredCells, item => `${item.Cell.Properties.ContractId}_${item.Cell.Properties.ResourceId}_${item.Cell.Properties.IsRequested ? "requested" : "allocated"}`);
	const cells = Object.values(cellsGroupedByResource);

	const { cellsWithValues, leftOverValues } = mapCellsToValues(text, cells as IItem[]);
	// update
	pasteFromExcel(cellsWithValues, leftOverValues);
};

const mapCellsToValues = (text: string, cells: IItem[]) => {

	const numberParser = new NumberParser(navigator.language);

	const splitedByRow = text.split("\n");
	const groupedCellsWithValues = [];
	const leftOverValues = [];
	splitedByRow.map((row, rowIdx) => {
		const rowObj = [];
		const splited = row.split("\t");
		// removes last row that is added because of excel
		if (splited.length === 1 && splited[0] === "") return;

		splited.map((value, columnIdx) => {
			const cell = cells[rowIdx] && cells[rowIdx][columnIdx];
			
			if (cell) {
				rowObj.push({
					Value: numberParser.Parse(value),
					Cell: cell.Cell,
					ColumnIndex: cell.ColumnIndex,
					CellIndex: cell.CellIndex,
				})
			} else {
				leftOverValues.push({ 
					Value: numberParser.Parse(value),
					ValueIndex: columnIdx,
					RowIndex: rowIdx
				});
			}
		});
		groupedCellsWithValues.push(rowObj)
	});

	const cellsWithValues = filterOutEmptyValueAndExtraArrays(groupedCellsWithValues);

	return {cellsWithValues, leftOverValues}
};

const filterOutEmptyValueAndExtraArrays = (groupedCellsWithValues: any[]) => {
	const newArr = [];
	groupedCellsWithValues.map((cellGroup: IItemWithValue[]) => cellGroup.map(cell => cell.Value !== "" && newArr.push(cell)));
	return newArr;
};

export const filterCells = (inputNodes: any, startCell: IItem) => {
	const newObj = [];
	let startCellFound = false;
	for (const [key, value] of inputNodes.entries()) {

		if (key === startCell.Cell.Id)// && value.Cell.Properties.ContractId == startCell.Cell.Properties.ContractId) 
			startCellFound = true;

		if (startCellFound && value.CellIndex === startCell.CellIndex && value.ColumnIndex >= startCell.ColumnIndex && value.getIsRendered()) 
			newObj.push(value);
	}

	return newObj;
};

export const groupByResource = (objectArray: any, getProperty: (obj: IItem) => string) => {
	return objectArray.reduce((acc: IItem[], obj: IItem) => {
		const key = getProperty(obj);
		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push(obj);
		return acc;
	}, {})
};
