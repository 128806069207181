export default class Locale_WorkProfile {
	CapacityStart: string = "Capacity Start";
	CapacityStartDescription: string = "Date from which capacity should be calculated";
	CapacityStartValidation: string = "Capacity start must be set.";
	CapacityEnd: string = "Capacity End";
	CapacityEndDescription: string = "Date to which capacity should be calculated";
	CapacityEndValidation: string = "Capacity end must be set.";
	WeekHours: string = "Week hours";
	WeekHoursValidation: string = "A Work profile must have atleast one day with hours (pt. used for base capacity calculation)";
	Rules: string = "Rules";
	RulesValidation: string = "No rules should overlap each other.";

	//WorkProfileEditor
	WorkProfileDescription: string = "A work profile consists of a work week definition and a set of rules, used to override the work week definition.";

	// Warning
	FTEWarning: string = "IMPORTANT!!! If you're using FTE and this is your default WorkProfile, be aware that changes to the weekhours, will require that you subsequently run the plugin 'Activities FTE Work Sanitizer' to update all activities!!";
}