import 'reflect-metadata';
import { PropertyInfoAreas } from './PropertyInfoAreas';

export class PropertyFilter { 
	constructor(public Options: PropertyFilterOptions) { }
	public static PropertyInfoKey: PropertyInfoAreas = PropertyInfoAreas.Filter;
}

export class PropertyFilterOptions {
	public FilterLevel: number;
}

export const PFPropertyFilter = (options: PropertyFilterOptions): PropertyDecorator => {
    return (target, property) => {
      const propertyConstructor = target.constructor;
	  const metadata = Reflect.getMetadata(PropertyFilter.PropertyInfoKey, propertyConstructor) || {};
      metadata[property] = new PropertyFilter(options);
      Reflect.defineMetadata(PropertyFilter.PropertyInfoKey, metadata, propertyConstructor);
    };
};
