import { guid } from '../../../../../../helpers/guid';
import { ArrayEx } from '../../../../../../Utilities/ArrayEx';
import { ITreeNode } from '../interfaces/ITreeNode';
import { INodeDisabling } from '../components/TreeView';
import { StringEx } from '../../../../../../Utilities/StringEx';

const deleteNode = (tree: ITreeNode, node: ITreeNode, parent: ITreeNode): void => {
    const parentNode = findNode(tree, parent.id);
    const index = parentNode.children.findIndex(_ => _.id === node.id);
    parentNode.children.splice(index, 1);
};

const moveNode = (rootNode: ITreeNode, sourceId: string, sourceParentId: string, targetId: string, delimiter): void => {
    const sourceNode = findNode(rootNode, sourceId);
    const sourceContainerNode = findNode(rootNode, sourceParentId);
    const targetNode = findNode(rootNode, targetId);
    // move node in tree
    targetNode.children.push(sourceNode);
    const index = sourceContainerNode.children.findIndex(_ => _.id === sourceId);
    sourceContainerNode.children.splice(index, 1);
    // rename FullName on all childs
    rebuildChildFullNames(targetNode, delimiter);
};

const addNode = (tree: ITreeNode, parent: ITreeNode, delimiter: string): void => {
    const newItem = {} as ITreeNode;
    newItem.id = guid.newGuid();
    newItem.fullName = StringEx.isNullOrWhiteSpace(parent?.fullName) ? '' : parent.fullName + delimiter;
    newItem.children = [];
    findNode(tree, parent.id).children.push(newItem);
};

const selectAll = (node: ITreeNode, select: boolean): void => {
    node.selected = select;
    node.children.forEach(_ => selectAll(_, select));
};

const disableAll = (node: ITreeNode, disable: boolean, notSelf?: boolean): void => {
    if (!notSelf) {
        //hehe (ewi)
        node.disabled = disable;
    }
    node.children.forEach(_ => disableAll(_, disable));
};

const findNode = (beginNode: ITreeNode, nodeId: string): ITreeNode => {
    // node is the one
    if (beginNode.id === nodeId) {
        return beginNode;
    }
    for (const child of beginNode.children) {
        // child is the one
        if (child.id === nodeId) {
            return child;
        }
        // search children recursively
        const result = findNode(child, nodeId);
        // found
        if (result != null) {
            return result;
        }
    }
    // nothing found
    return null;
};

const renameNode = (node: ITreeNode, newName: string, delimiter: string) => {
    node.name = newName;
    node.fullName ??= newName;
    const splits = node.fullName.split(delimiter);
    splits.pop();
    if (!splits.length) {
        node.fullName = newName;
    } else {
        node.fullName = `${ArrayEx.joinToString(splits, delimiter)}${delimiter}${newName}`;
    }
    rebuildChildFullNames(node, delimiter);
};

const rebuildChildFullNames = (node: ITreeNode, delimiter: string): void => {
    if (StringEx.isNullOrWhiteSpace(delimiter)) {
        return;
    }
    if (!node?.children) {
        return;
    }
    node.children.forEach(_ => {
        if (node.fullName !== undefined) {
            _.fullName = `${node.fullName}${delimiter}${_.name}`;
        }
        rebuildChildFullNames(_, delimiter);
    });
};

const alterNodesInternal = (
    tree: ITreeNode,
    ids: string[] | boolean,
    alter: (node: ITreeNode, found: boolean) => void,
    includeChilds?: boolean,
    forceOnChild?: boolean,
): void => {
    const forceAll = Array.isArray(ids) ? false : !!ids;

    const found = forceAll || forceOnChild || (ids as string[]).findIndex(_ => _ === tree.id) >= 0;
    alter(tree, found);
    tree.children.forEach(_ => alterNodesInternal(_, ids, alter, includeChilds, includeChilds && found));
};

const alterNodes = (tree: ITreeNode, ids: string[] | boolean, alter: (node: ITreeNode, found: boolean) => void, includeChilds?: boolean): void => {
    alterNodesInternal(tree, ids, alter, includeChilds);
};

const setSelected = (tree: ITreeNode, selectedIds: string[], includeChilds?: boolean): void => {
    alterNodes(tree, selectedIds, (node: ITreeNode, found: boolean) => (node.selected = found), includeChilds);
};

const setDisabled = (tree: ITreeNode, selectedIds: string[], includeChilds?: boolean): void => {
    alterNodes(tree, selectedIds, (node: ITreeNode, found: boolean) => (node.disabled = found), includeChilds);
};

const setDisabledWithOptions = (tree: ITreeNode, disableOptions: INodeDisabling) => {
    if (disableOptions == null) {
        return;
    }
    alterNodes(
        tree,
        disableOptions.ids,
        (node: ITreeNode, found: boolean) => (node.disabled = found ? disableOptions.value : !disableOptions.value),
        disableOptions.includeChilds,
    );
};

const clearSelectionOnDisabled = (tree: ITreeNode): void => {
    const alter = (node: ITreeNode, found: boolean) => {
        if (node.disabled) {
            node.selected = false;
        }
    };
    alterNodes(tree, true, alter);
};

const hasChildsSelected = (tree: ITreeNode): boolean => {
    if (!tree?.children?.length) {
        return false;
    }
    let found = false;
    found = tree.children.some(_ => _.selected);
    if (found) {
        return true;
    }
    found = tree.children.some(_ => hasChildsSelected(_));
    return found;
};

const getSelectedIds = (tree: ITreeNode): string[] => {
    return getNodes(tree, _ => _.selected).map(_ => _.id);
};

const getNodesInternal = (tree: ITreeNode, filter: (node: ITreeNode) => boolean, arr: ITreeNode[]): ITreeNode[] => {
    if (!tree?.children?.length) {
        return arr;
    }
    for (const node of tree.children) {
        getNodesInternal(node, filter, arr);
        if (filter(node)) {
            arr.push(node);
        }
    }
    return arr;
};

const getNodes = (tree: ITreeNode, filter: (node: ITreeNode) => boolean): ITreeNode[] => {
    const result = [];
    getNodesInternal(tree, filter, result);
    return result;
};

const getNames = (tree: ITreeNode, ids: string[]): string[] => {
    return getNodes(tree, node => ids.indexOf(node.id) >= 0).map(_ => _.name);
};

const count = (node: ITreeNode): number => {
    if (node == null) {
        return 0;
    }
    let count: number = 0;

    // traverse tree without recursion
    const stack = new Array<ITreeNode>();
    stack.push(node);
    while (stack.length > 0) {
        count++;
        const current = stack.pop();
        if (!current || !current.children) {
            continue;
        }
        current.children.forEach(_ => stack.push(_));
    }
    return count - 1; // substract root
};

const sortChildren = (node: ITreeNode): void => {
    // traverse tree without recursion
    const stack = new Array<ITreeNode>();
    stack.push(node);
    while (stack.length > 0) {
        const current = stack.pop();
        if (!current || !current.children) {
            continue;
        }
        current.children.sort((a, b) => {
            if (a?.name) {
                return b?.name ? a.name.localeCompare(b.name) : -1;
            } else if (b?.name) {
                return a?.name ? b.name.localeCompare(a.name) : 1;
            }
            return 0;
        });
        current.children.forEach(_ => stack.push(_));
    }
};

export const treeHelper = {
    addNode,
    deleteNode,
    moveNode,
    renameNode,
    findNode,
    selectAll,
    disableAll,
    setSelected,
    getSelectedIds,
    setDisabled,
    setDisabledWithOptions,
    getNames,
    clearSelectionOnDisabled,
    hasChildsSelected,
    count,
    sortChildren,
    rebuildChildFullNames,
};
