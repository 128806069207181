import { Icon, Text } from "@fluentui/react";
import { useContext,  useState } from "react";
import { language } from "../../Services/LocalizationService";
import { TableStateManager } from "../../Services/TableStateManager";
import { useTheme } from "../../Themes/themeContext";
import { buttonRootStyles } from "../Overview/Table/Header/Header";
import { TableContext } from "../Overview/Table/Table";

export const RenderHideGenericResourceToggle: React.FC<{item: any, dismissMenu: any}> = () => {
	const theme = useTheme();
	const ctx = useContext(TableContext) as TableStateManager & { ToggleHideGenericResources: () => void };

	const [hideGenericResources, setHideGenericResources] = useState<boolean>(() => ctx.Get(_ => _.GridSettingsHideGenericResources));

	return (
		<Text 
			onClick={() => {
				ctx.Get(_ => _.SetGridSettingsHideGenericResourcesCallback)(!hideGenericResources);
				ctx.ToggleHideGenericResources();

				setHideGenericResources(s => !s);
			}}
			styles={{ 
				root: {
					...buttonRootStyles, 
					"&:hover": {
						backgroundColor: theme.semanticColors.buttonBackgroundHovered,
					},
				}
			}}
		>
			<Icon 
				iconName="ContactInfo" 
				styles={{ root: {
					paddingTop: 2,
					marginRight: 8,
					marginLeft: 4,
					color: theme.semanticColors.menuIcon,
					fontSize: 16,
				}}} 
			/>
			{ hideGenericResources ? language.GridSettings.ShowGenericResources : language.GridSettings.HideGenericresources }
		</Text>
	)	
};