import { createContext } from "../../../../hooks/createContext";
import { ExpandedState } from "../useGrid";
// import { ExpandedState } from "./Grid";

type ExpandedContext = {
    expandedRows: ExpandedState;
    setExpandedRows: React.Dispatch<React.SetStateAction<ExpandedState>>;
}

const [useCtx, Provider] = createContext<ExpandedContext>();

export const useExpandedGridContext = useCtx;

export const ExpandedGridProvider = Provider;