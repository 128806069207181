// Regular expression to match the expected date formats (utc is optional for now, due to some legacy data in TP)
const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z)?$/;

// Helper function to check if a string is date-like
const isDateLike = (str: string): boolean => {
    return iso8601Regex.test(str);
};

export function handleValueType(value: any): string {
    if (value == null) {
        return '';
    }

    // It's a date
    if (value instanceof Date) {
        return value.toDateString();
    } else if (typeof value === 'string' && isDateLike(value)) {
        // it's a date-like string
        const date = new Date(value);
        return date.toDateString();
    } else {
        // default
        return value.toString();
    }
}

export function getPickerPropertyValue<T extends object, U>(item: T, pickerFields: U, key: (fields: U) => string): string {
    let field = key(pickerFields);
    if (field == null) return null;

    let value = '';

    if (field.indexOf('customProperties.') >= 0) {
        // it's a custom property
        field = field.replace('customProperties.', '');
        if (item['customPropertiesAsString'] && Object.hasOwn(item['customPropertiesAsString'], field)) {
            value = item['customPropertiesAsString'][field];
        } else if (item?.['customProperties']?.[field] !== undefined) {
            value = item['customProperties'][field];
        }
    } else if (Object.hasOwn(item, `${field}AsString`)) {
        // it's a standard field
        value = item[`${field}AsString`];
    } else {
        // no AsString variant found, default to field value
        value = item[field];
    }
    // handle types, for now only dates
    return handleValueType(value);
}

export const PanelUtil = {
    getPickerPropertyValue,
};
