
import * as React from 'react';
import { IconButton } from '@fluentui/react';
import { useState } from 'react';

interface IProps {
	Title: string;
	Collapsed?: boolean
}
export const EditorGrouping: React.FunctionComponent<IProps> = props => {
	const [collapsed, setCollapsed] = useState(props.Collapsed);

	return <>
			<div style={{display: "flex"}}>
				<IconButton iconProps={  { iconName: collapsed ? "ChevronRight" : "ChevronDown" } } className="tp-grouping-header-icon" onClick={ () => setCollapsed(!collapsed)} />    
				<h3 className="tp-grouping-header noselect" onClick={ () => setCollapsed(!collapsed)}>
					{props.Title}
				</h3>
			</div>
			<div className={ collapsed ? "tp-grouping-content tp-grouping-collapsible tp-grouping-content-hidden" : "tp-grouping-content" }>
				{props.children}
			</div>
	</>
}
