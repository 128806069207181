
import { IExtendedCustomizations } from './Theme.types';

const applyThemeVars = (customizations: IExtendedCustomizations): void => {
	const { settings: { theme }} = customizations;
	// console.debug('apply theme vars: ', theme);
	if(theme) {
		document.documentElement.style.setProperty('--accentColor', theme.palette.themePrimary);
		document.documentElement.style.setProperty('--topbarColor', theme.palette.neutralDark);
		document.documentElement.style.setProperty('--appBackgroundColor', theme.semanticColors.bodyBackground);
		document.documentElement.style.setProperty('--searchHighligtColor', theme.semanticColors.searchHighligtColor);
		document.documentElement.style.setProperty('--dateSliderColor', theme.palette.themePrimary);
		
		document.documentElement.style.setProperty('--tableBackground', theme.palette.neutralLight);
		// Is the ? var : var needed?
		document.documentElement.style.setProperty('--tableDividerColor', theme.semanticColors.tableDividerColor ? theme.semanticColors.tableDividerColor : theme.semanticColors.variantBorder);
		document.documentElement.style.setProperty('--tableDividerShadow', theme.semanticColors.tableDividerShadow);
		// Is the ? var : var needed?
		document.documentElement.style.setProperty('--tableBorderColorVertical', theme.semanticColors.tableBorderColorVertical ? theme.semanticColors.tableBorderColorVertical : theme.palette.neutralLighterAlt);
		// Is the ? var : var needed?
		document.documentElement.style.setProperty('--tableBorderColorHorizontal', theme.semanticColors.tableBorderColorHorizontal ? theme.semanticColors.tableBorderColorHorizontal : theme.palette.neutralLighterAlt);

		document.documentElement.style.setProperty('--rowBackgroundColor', theme.semanticColors.bodyBackground);
		document.documentElement.style.setProperty('--rowGroupBackground', theme.semanticColors.bodyStandoutBackground);
		document.documentElement.style.setProperty('--rowHoverBackgroundColor', theme.semanticColors.bodyBackgroundHovered);

		document.documentElement.style.setProperty('--overlayButtonBackground', theme.semanticColors.overlayButtonBackground);
		document.documentElement.style.setProperty('--overlayButtonBackgroundPressed', theme.semanticColors.overlayButtonBackgroundPressed);
		document.documentElement.style.setProperty('--overlayButtonText', theme.semanticColors.overlayButtonText);
	}
};

export default applyThemeVars;
