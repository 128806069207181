import { createContext as createCtx, useContext } from 'react';

export function createContext<ContextType>(initialContext?: ContextType) {
    const ctx = createCtx<ContextType | undefined>(initialContext) as React.Context<ContextType>;

    function useCtx() {
        const c = useContext(ctx);
        if (c === undefined) {
            throw new Error("useCtx must be inside a Provider with a value");
		} 
        
        return c;
    }
    
    return [useCtx, ctx.Provider, ctx] as const;
}
