import * as React from 'react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { useEffect } from 'react';

export interface IProgressIndicatorProps {
	title?: string;
	description?: string;
	intervalDelay: number;
	intervalIncrement: number;
	completeCallback?: () => void;
}

export const TPProgressIndicator: React.FunctionComponent<IProgressIndicatorProps> = ({ title, description, intervalDelay, intervalIncrement, completeCallback }) => {
	const [percentComplete, setPercentComplete] = React.useState(0);

	useEffect(() => {
		
		const id = setInterval(() => {
			const update = (intervalIncrement + percentComplete);
			setPercentComplete(update);
		}, intervalDelay);
		if (percentComplete > 1) {
			completeCallback?.();
			clearInterval(id);
			return;
		}

		return () => {
			clearInterval(id);
		};
	});

	return (
		<ProgressIndicator label={title} description={description} percentComplete={percentComplete} />
	);
};
