import { BaseButton, Button, IModalProps } from '@fluentui/react';
import React from 'react';
import { produce } from '../../helpers';
import { PersistGetter, TPContext } from '../../types';
import { BlockingDialog } from './BlockingDialog'


type TDialogButton = {
	text: string;
	type?: "default" | "primary";
	onClick?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement | BaseButton | Button | HTMLSpanElement>;
	shouldDismiss?: boolean;
}

export interface IBlockContent extends Omit<IModalProps, "children"> {
	title?: string;
    subText?: string;
    // content: React.ReactNode;
    dismissEnable?: boolean;
    refreshEnabled?: boolean;
	buttons?: TDialogButton[]
}

export interface IPageBlockContext {
    blockContent: React.ReactNode;
    readonly setBlockDialog: (content: React.ReactNode, props?: Omit<IBlockContent, "content">) => void;
}


export const pageBlockContext: TPContext<IPageBlockContext> = (set, get, api) => {
    return {
		blockContent: null,
		setBlockDialog: (content, props = {} as IBlockContent) => set(produce(store => {
			if (content || props.title || props.subText) {
				// store.blockContent = <BlockingDialog content={content} {...props} />
				store.blockContent = <BlockingDialog {...props}>{content}</BlockingDialog>
			} else {
				store.blockContent = null
			}
		}))
    };
};

export const getPersistBlockContext: PersistGetter = () => {
	return {
		blockContent: null
	}
}

