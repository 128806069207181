import { StringEx } from "./StringEx";
import EnumEx from "./EnumEx";
import { ArrayEx } from "./ArrayEx";
import { IDropdownOption } from "@fluentui/react";

export function toDropdownOptionsAddEmptyEntry<T>(array: T[], keySelector: (obj: T) => string | number, textSelector: (obj: T) => string, sort: boolean = true, emptyEntryKey: string | number = null, emptyEntryValue: string = "Not selected"): IDropdownOption[] {
	return [{ key: emptyEntryKey, text: emptyEntryValue }].concat(toDropdownOptions(array, keySelector, textSelector, sort));
}

export function toDropdownOptions<T>(array: T[], keySelector: (obj: T) => string | number, textSelector: (obj: T) => string, sort: boolean = true): IDropdownOption[] {
	if (array == null)
		return [];
	let items = array.map(_ => ({ key: keySelector(_), text: textSelector(_) }));
	items = sort ? ArrayEx.sort(items, "text") : items;
	return items;
}

export function toDropdownOptionsAddEmptyEntryDisableSome<T>(array: T[], keySelector: (obj: T) => string | number, textSelector: (obj: T) => string, disabled: (string | number)[], sort: boolean = true, emptyEntryKey: string | number = null, emptyEntryValue: string = "Not selected"): IDropdownOption[] {
	let items = [{ key: emptyEntryKey, text: emptyEntryValue }].concat(array.map(_ => {
		const key = keySelector(_);
		return {
			key,
			text: textSelector(_),
			disabled: disabled.some(disable => key === disable)
		};
	}));
	items = sort ? ArrayEx.sort(items, "text") : items;
	return items;
}

export function enumToDropdownOptions<T>(enumType: { [s: number]: string; }): IDropdownOption[] {
	return EnumEx.allAsNumber<T>(enumType).map(_ => ({
		key: (_ as any) as number,
		text: StringEx.splitCamelCase((enumType as any)[_].toString())
	}));
}

export function enumToDropdownOptionsDisableSome<T>(enumType: { [s: number]: string; }, disabled: number[]): IDropdownOption[] {
	return EnumEx.allAsNumber<T>(enumType).map(_ => {
		return {
			key: (_ as any) as number,
			text: StringEx.splitCamelCase((enumType as any)[_].toString()),
			disabled: disabled.some(disable => (_ as any) as number === (disable as number))
		};
	});
}

export function enumToDropdownOptionsWithoutSome<T>(enumType: { [s: number]: string; }, removed: T[]): IDropdownOption[] {
	return EnumEx.allAsNumber<T>(enumType).filter(_ => removed.findIndex(r => r === _) < 0).map(_ => {
		return {
			key: (_ as any) as number,
			text: StringEx.splitCamelCase((enumType as any)[_].toString())
		};
	});
}

export function enumToDropdownOptionsWithoutZeroOption<T>(enumType: { [s: number]: string; }): IDropdownOption[] {
	return EnumEx.allAsNumber<T>(enumType).filter(_ => (_ as any) as number !== 0).map(_ => ({ key: (_ as any) as number, text: StringEx.splitCamelCase((enumType as any)[_].toString()) }));
}

export function enumToDropdownOptionsWithoutZeroOptionAddEmptyEntry<T>(enumType: { [s: number]: string; }, emptyEntryKey: string | number = null, emptyEntryValue: string = "Not selected"): IDropdownOption[] {
	return [{ key: emptyEntryKey, text: emptyEntryValue }]
		.concat(EnumEx.allAsNumber<T>(enumType).filter(_ => (_ as any) as number !== 0).map(_ => ({ key: (_ as any) as number, text: StringEx.splitCamelCase((enumType as any)[_].toString()) })));
}

export function enumToDropdownOptionsAddEmptyEntry<T>(enumType: { [s: number]: string; }, emptyEntryKey: string | number = null, emptyEntryValue: string = "Not selected"): IDropdownOption[] {
	return [{ key: emptyEntryKey, text: emptyEntryValue }]
		.concat(EnumEx.allAsNumber<T>(enumType).map(_ => ({ key: (_ as any) as number, text: StringEx.splitCamelCase((enumType as any)[_]) })));
}

export function enumValuesToDropdownOptions<T>(enumType: { [s: number]: string; }, values: Array<T>): IDropdownOption[] {
	return values.map(_ => ({ key: (_ as any) as number, text: StringEx.splitCamelCase((enumType as any)[_]) }));
}

export const DropdownOptionEx = {
	enumToDropdownOptions,
	enumToDropdownOptionsAddEmptyEntry,
	enumToDropdownOptionsDisableSome,
	enumToDropdownOptionsWithoutSome,
	enumToDropdownOptionsWithoutZeroOption,
	enumToDropdownOptionsWithoutZeroOptionAddEmptyEntry,
	enumValuesToDropdownOptions,
	toDropdownOptions,
	toDropdownOptionsAddEmptyEntry,
	toDropdownOptionsAddEmptyEntryDisableSome,
}