/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, useState, useRef, memo } from 'react';
import { ColorPicker, IColor, Label, Callout, DirectionalHint } from '@fluentui/react';
import { DynamicProps } from './DynamicProps';

type IProps = DynamicProps<string, string>;

const DynamicColorPicker2: FunctionComponent<IProps> = props => {
    const [showDialog, setShowDialog] = useState(false);
    const [value, setValue] = useState<string>(props.Value);
    const elementId = useRef(props.PropertyInfo.PropertyName);

    const Change = (value: string) => {
        setValue(value);
        props.OnChange(props.PropertyInfo.PropertyName, value);
    };

    return (
        <>
            <div style={{ display: 'inline-flex' }}>
                <div
                    id={elementId.current}
                    style={{
                        backgroundColor: value,
                        cursor: 'pointer',
                        width: '20px',
                        height: '20px',
                        marginRight: '10px',
                        marginTop: '5px',
                        border: 'solid 1px black',
                    }}
                    onClick={() => setShowDialog(true)}
                ></div>
                <Label>{props.PropertyInfo.DisplayName}</Label>
            </div>
            {elementId.current && (
                <Callout
                    target={`#${elementId.current}`}
                    hidden={!showDialog}
                    directionalHint={DirectionalHint.leftCenter}
                    onDismiss={event => setShowDialog(false)}
                    setInitialFocus
                    gapSpace={10}
                >
                    <ColorPicker key={props.Key} color={value} onChange={(event, color: IColor) => Change(color.str)} />
                </Callout>
            )}
        </>
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Value === nextProps.Value;
};

export default memo(DynamicColorPicker2, comparisonFn);
