export default class Locale_MoveRequestDto {
	StartOfRequest: string = "This defines the start of the part of the request you want to move.";
	StartGreaterThanCurrentDay: string = "'Start' must be specified and greater than today/start of current period.";
	EndOfRequest: string = "This defines the end of the part of the request you want to move. If left empty it will include the remainder of the request.";
	MoveTo: string = "Move to";
	NewStartDate: string = "The new start date.";
	MoveToRequired: string = "'Move to' is required";
	MoveToEnd: string = "Move to end";
	ShrinkOrExtendSelectedPeriod: string = "Used when you need to shrink or extend the selected period period when moving the request.";
	ToEndGreaterThanToStart: string = "'To end' must greater than 'To start'.";
	Percentage: string = "Percentage";
	MoveRequestDescription: string = "If you need to for instance, only move half of the request, you will specify '50' in this field.";
}