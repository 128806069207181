import { BaseRow, IBaseRow } from "./BaseRow";
import { IRow, Row } from "./Row";

export class GroupRow extends BaseRow {
	public TypeName = "GroupRow";
    public Rows: Array<Row>;

    constructor(public Id: string, public Name: string, public Properties: { [key: string]: any } = null) {    
        super(Id, Name);
        this.Rows = new Array<Row>();
    }
}

export interface IGroupRow extends IBaseRow {
	TypeName: "GroupRow";
	Rows: IRow[];
}