import { FunctionComponent, useEffect, useState } from 'react';
import { language } from '../../Services/LocalizationService';
import { useTheme } from '../../Themes/themeContext';
import { Area, KpiBarMultiple } from './KpiBarMultiple';
 
interface IProps {
	Percentage: number;
	CategoryPercentage: number;
	IsZeroActivityProject: boolean;
}

export const ProjectListItemPercentageBar: FunctionComponent<IProps> = props => {
	const theme = useTheme();
	
	const [areas, setAreas] = useState<Array<Area>>([]);
	
	const ParseCategoryPercentage = (percentage: number) : number => {
		if (percentage === -3) return 0;
		if (percentage === -2) return 0;
		return props.CategoryPercentage;
	}
	
	const GetLeftOverTitle = () : string => {
		if (props.Percentage === -2) return language.ProjectListItemPercentageBar.NotRequestedButAllocated;
		if (props.Percentage === -3) return language.ProjectListItemPercentageBar.NotAllocatedButRequested;
		return language.ProjectListItemPercentageBar.NotAllocated;
	}

	const GetAllocatedTitle = () : string => {
		if (props.IsZeroActivityProject) return language.ProjectListItemPercentageBar.NotRequestedAndNotAllocated;
		return language.ProjectListItemPercentageBar.Allocated;
	}

	const GetAllocatedColor = () : string => {
		if (props.IsZeroActivityProject) return theme.semanticColors.activityZeroBackground;
		if (props.Percentage > 100) return theme.semanticColors.kpiOver;
		return theme.semanticColors.kpiUsed;
	}
	
	useEffect(() => {
		const allocated: Area = { Title: GetAllocatedTitle(), Percentage: props.Percentage === -3 ? 0 : props.Percentage, Color: GetAllocatedColor(), ShowPercentage: !props.IsZeroActivityProject };
		const category: Area = { Title: language.ProjectListItemPercentageBar.Category, Percentage: ParseCategoryPercentage(props.Percentage), Color: theme.semanticColors.kpiCategory, ShowPercentage: true };
		setAreas([allocated, category]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.Percentage, props.CategoryPercentage, props.IsZeroActivityProject]);

	return areas.length &&
			<KpiBarMultiple
				Areas={areas}
				LeftOverTitle={GetLeftOverTitle()}
				LeftOverColor={props.Percentage === -2 ? theme.semanticColors.kpiInfinite : theme.semanticColors.kpiFree}
			/>
}
