import { Spinner, SpinnerSize } from '@fluentui/react';
import cloneDeep from 'lodash/cloneDeep';
import { useState, useEffect, useRef } from 'react';
import { IFormSchema } from 'spark-forms';
import { CustomProperty } from '../../../api/generated/data-contracts';
import { PFDialog } from '../../../Components/Common/PFDialog';
import { merge } from '../../../helpers/merge';
import { language } from '../../../Services/LocalizationService';
import { BulkEditor } from './BulkEditor';

interface IProps<T> {
	show: boolean;
	dismiss: () => void;
	items: T[];
	template?: Partial<T>;
	entityType: "resource" | "project" | "other";
    schema: IFormSchema<T>;
    customProperties: CustomProperty[];
    hiddenProperties: string[];
    onSubmit: (items: T[]) => void | Promise<void>;
}

export const BulkEditorDialog = <T,>(props: React.PropsWithChildren<IProps<T>>): React.ReactElement | null => {

	const items = useRef<T[]>();
	const [changedItems, setChangedItems] = useState<T[]>();
	const [okEnabled, setOkEnabled] = useState<boolean>(false);
	const [updating, setUpdating] = useState(false);

	useEffect(() => {
		items.current = cloneDeep(props.items);
		setChangedItems(props.items);
	}, [props.items])

	const onChange = (templateItem: any): void => {
		const toChange = [...changedItems];
		toChange.forEach(_ => merge(_, templateItem));
		setChangedItems(toChange);
	}

	return <>
				<PFDialog
					buttonText={language.Common.Save}
					minWidth={500}
					maxWidth={800}
					showDialog={ props.show } 
					title={language.Common.BulkEdit}
					subText={updating ? 'Updating items' : language.ListBulkEditor.PropertiesToEdit} 
					callback={ async () => {
						setUpdating(true);
						setOkEnabled(false);
						await props.onSubmit(changedItems);
						setUpdating(false);
					}}
					buttonDefaultDisabled={ false }
					dismissCallback={ () => {
						setChangedItems(null);
						props.dismiss();
					} }
					okButtonEnabled={okEnabled}
				>
					{
						updating
						? <Spinner size={SpinnerSize.large}/>
						: <BulkEditor
							{...props}
							onChange={onChange}
							selectCallback={() => {
								setChangedItems(cloneDeep(items.current));
								setOkEnabled(true);
							}}
						/>
					}
				</PFDialog>
			</>
}
