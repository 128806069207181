import { FreezePeriod } from "../Entities/FreezePeriod";
import { TimelineResolution } from "../Entities/TimelineResolution";
import { DateEx } from "./DateEx";

export function getFirstDayOfPeriod(resolution: TimelineResolution, useISO8601: boolean, date?: Date): Date {
	const today = date || DateEx.todaysDate();
	switch (resolution) {
		case TimelineResolution.Year:
			return DateEx.getFirstDayOfYear(today);
		case TimelineResolution.Quarter:
			return DateEx.getFirstDayOfQuarter(today);
		case TimelineResolution.Month:
			return DateEx.getFirstDayOfMonth(today);
		case TimelineResolution.Week:
			return DateEx.getFirstDayOfWeek(today, useISO8601);
		case TimelineResolution.Day:
			return today;
		default:
			throw new Error("Unknown resolution!");
	}
}

export function addPeriod(resolution: TimelineResolution, periods: number, useISO8601: boolean, date?: Date): Date {
	const today = date || DateEx.todaysDate();
	switch (resolution) {
		case TimelineResolution.Year:{
			const firstDayOfYear = DateEx.getFirstDayOfYear(today);
			return DateEx.addYear(firstDayOfYear, periods);}
		case TimelineResolution.Quarter:{
			const firstDayOfQuarter = DateEx.getFirstDayOfQuarter(today);
			return DateEx.addQuarter(firstDayOfQuarter, periods);}
		case TimelineResolution.Month:{
			const firstDayOfMonth = DateEx.getFirstDayOfMonth(today);
			return DateEx.addMonth(firstDayOfMonth, periods);}
		case TimelineResolution.Week:{
			const firstDayOfWeek = DateEx.getFirstDayOfWeek(today, useISO8601);
			return DateEx.addWeek(firstDayOfWeek, periods);}
		case TimelineResolution.Day:
			return DateEx.addDay(today, periods);
		default:
			throw new Error("Unknown resolution!");
	}
}

export function getStartUsingFreezePeriod(freezePeriod: FreezePeriod, inputResolution: TimelineResolution, useISO8601: boolean): Date {
	const today = DateEx.todaysDate();
	if (freezePeriod == null || freezePeriod.FreezeBeforeTodayOnly)
		return getFirstDayOfPeriod(inputResolution, useISO8601, today);

	let newStart: Date;
	let first: Date;
	switch (freezePeriod.Resolution) {
		case TimelineResolution.Year:
			first = DateEx.getFirstDayOfYear(today);
			newStart = DateEx.addYear(first, freezePeriod.Number);
			break;
		case TimelineResolution.Quarter:
			first = DateEx.getFirstDayOfQuarter(today);
			newStart = DateEx.addQuarter(first, freezePeriod.Number);
			break;
		case TimelineResolution.Month:
			first = DateEx.getFirstDayOfMonth(today);
			newStart = DateEx.addMonth(first, freezePeriod.Number);
			break;
		case TimelineResolution.Week:
			first = DateEx.getFirstDayOfWeek(today);
			newStart = DateEx.addWeek(first, freezePeriod.Number);
			break;
		case TimelineResolution.Day:
			newStart = DateEx.addDay(today, freezePeriod.Number);
			break;
		default:
			throw new Error("Unknown resolution!");
	}

	const inputFirstDate = getFirstDayOfPeriod(inputResolution, useISO8601, newStart);
	if (inputFirstDate >= newStart)
		return inputFirstDate;
	else
		return addPeriod(inputResolution, 1, useISO8601, inputFirstDate);
}

export const ResolutionUtil = {
	getFirstDayOfPeriod,
	addPeriod,
	getStartUsingFreezePeriod
}