import { CompoundButton, Stack } from "@fluentui/react";
import React from "react";
import { ContractInfoDto } from "../../../../../Entities/Dto/ContractInfoDto";
import { DateEx } from "../../../../../Utilities/DateEx";
import { IDs } from "../../../../../Utilities/IDs";
import { ObjectEx } from "../../../../../Utilities/ObjectEx";
import { ObjectEditorWizardStep } from "../../../../Common/Wizard/ObjectEditorWizardStep";
import { WizardStep } from "../../../../Common/Wizard/WizardStep";
import { validateStep1, validateStep2, validateStep3 } from "../helpers/FACValidation";

export const buildFACEditorSteps = (errorHandler: (msg: string) => void) : Array<ObjectEditorWizardStep> => {
	const steps: Array<ObjectEditorWizardStep> = [
		{
			title: "Resource",
			description: "Select a resource",
			Properties: [ "ResourceId", "ContractName" ],
			validate: validateStep1(errorHandler)
		},
		{
			title: "Requirements",
			description: "Select a timeframe and amount of work.",
			Properties: [ "Work", "HasActivitiesMessage", "Start", "EndDescription", "End", "EarliestStart", "LatestEnd" ],
			validate: validateStep2(errorHandler)
		},
		{
			title: "Scenario",
			description: "Should this be included in a scenario",
			Properties: [ "UseScenario", "CreateNewScenario", "Scenario", "ScenarioName" ],
			validate: validateStep3(errorHandler)
		}
	];

	return steps;
}

export const buildFACCustomSteps = (dto: ContractInfoDto, save: () => void, distribute: () => void, findDeliveryDate: () => void, editDistributionConfig: () => void) : Array<{index: number, step: WizardStep}> => {
	const steps: Array<{index: number, step: WizardStep}> = [
		{
			index: 0,
			step: {
				content: (next, prev, ctx) => (
					<div>
						<Stack tokens={ { childrenGap: 10 }}>
							{ dto.ContractName && // called from project (not on a contract, hence hide the metadata option)
								<CompoundButton secondaryText="Only changes and saves the metadata" onClick={() => {
									ctx.setProperties({
										"hiddenProps": [ "EarliestStart", "LatestEnd" ],
										"action": "save"
									});
									ctx.setIgnoreValidation([1, 3]);

									const skips = {} as Record<number, boolean>;
									skips[3] = true;
									skips[5] = true;
									ctx.setSkipSteps(skips);

									ctx.setCompleteButtonText("Save");
									ctx.setCompleteAction(() => save);
									dto.IgnoreEndValidation = false;
									dto.ShouldLockDates = true;
									next();
								}}>
									Edit metadata
								</CompoundButton>
							}
							<CompoundButton secondaryText="Spread out the request evenly over the selected period" onClick={() => {
								ctx.setProperties({
									"hiddenProps": [ "EarliestStart", "LatestEnd" ],
									"action": "distribute"
								});
								ctx.setIgnoreValidation(false);

								const skips = {} as Record<number, boolean>;
								skips[5] = true;
								ctx.setSkipSteps(skips);

								ctx.setCompleteButtonText("Distribute request");
								ctx.setCompleteAction(() => distribute);
								dto.IgnoreEndValidation = false;
								dto.ShouldLockDates = false;
								next();
							}}>
								Distribute request
							</CompoundButton>
							<CompoundButton primary secondaryText="Auto generate both request and allocations" onClick={() => {
								ctx.setProperties({
									"hiddenProps": [],
									"action": "autogen"
								});
								ctx.setIgnoreValidation(false);
								
								ctx.setSkipSteps({});

								dto.IgnoreEndValidation = true;
								dto.ShouldLockDates = false;
								next();
							}}>
								Auto allocate
							</CompoundButton>
						</Stack>
					</div>
				),
				hideNext: true,
				hidePrevious: true,
				hideDots: true
			}
		},
		{
			index: 4,
			step: {
				content: (next, prev, ctx) => (
					<>
						<Stack tokens={{childrenGap: 20}}>
							<table cellPadding={5}>
								<tbody>
									{
										ObjectEx.keyValuesAsStrings(dto, DateEx.asUserLocal).filter(_ => ["ResourceId", "ContractId", "ProjectId", "Resolution", "CreateNewScenario", "ScenarioName", "Scenario", "IgnoreEndValidation"].indexOf(_.key) < 0).map(_ => {
											return (
												<tr key={IDs.makeId()}>
													<td><b>{_.key}</b></td>
													<td>{_.value}</td>
												</tr>
											);
										})
									}
								</tbody>
							</table>
						</Stack>
					</>
				)
			}
		},
		{
			index: 5,
			step: {
				content: (next, prev, ctx) => (
					<>
						<Stack tokens={{childrenGap: 20}}>
							<CompoundButton primary secondaryText="Using default settings" onClick={ findDeliveryDate }>
								Find delivery date
							</CompoundButton>
							<CompoundButton secondaryText="Control distribution specific for this request" onClick={ editDistributionConfig }>
								Edit distribution settings
							</CompoundButton>
						</Stack>
					</>
				),
				hideNext: true
			}
		}
	];
	return steps;
}