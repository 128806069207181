import React from 'react';
import { useState, useEffect } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { useStore } from '../../context/store';

interface IProps {
    placement: EnumPlacement;
    id: string;
    isFirstRender: boolean;
    setIsFirstRender: React.Dispatch<React.SetStateAction<boolean>>;
    onClick?: () => void;
}

// eslint-disable-next-line react-refresh/only-export-components
export enum EnumPlacement {
    left = 'left_sided',
    right = 'right_sided',
}

export const OverlayButton: React.FC<IProps> = ({ id, isFirstRender, placement, setIsFirstRender, children, onClick }) => {
    const { scrollbarWidth, bottomHasScroll } = useStore(store => ({ scrollbarWidth: store.ui.scrollbarWidth, bottomHasScroll: store.ui.bottomHasScroll }));

    const [stayOpen, setStayOpen] = useState(isFirstRender);

    useEffect(() => {
        if (!isFirstRender) return;

        const timer = setTimeout(() => {
            setStayOpen(false);
            // duration before closing
        }, 8000);

        // semi-stupid workaround for second overlay not closing (ewi)
        // don't really quite understand why the previous don't work (ewi)
        const timer2 = setTimeout(() => {
            setIsFirstRender(false);
        }, 10000);

        return () => {
            clearTimeout(timer);
            clearTimeout(timer2);
        };
    }, [isFirstRender, setIsFirstRender]);

    const scrollbarFix = mergeStyles({
        right: `${bottomHasScroll ? scrollbarWidth : 0}px`,
        width: `${bottomHasScroll ? 16 : 14}px`,
        paddingLeft: `${bottomHasScroll ? 8 : 6}px`,
    });

    return (
        <div
            id={id}
            className={`overlay_button ${placement} ${stayOpen ? 'stayOpen' : ''} ${placement === EnumPlacement.right ? scrollbarFix : ''}`}
            onMouseLeave={() => stayOpen && setStayOpen(false)}
        >
            <Text className="overlay_button_inner" variant={'xLarge'} nowrap={true} block={true} onClick={onClick} styles={{ root: { fontWeight: 400 } }}>
                {children}
            </Text>
        </div>
    );
};
