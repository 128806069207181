import Big from "big.js";

/**
 * When set to true, an error will be thrown if a primitive number is passed to the Big constructor,
 * or if valueOf is called, or if toNumber is called on a Big which cannot be converted to a primitive number without a loss of precision.
 */
Big.strict = true;
// Big.RM = Big.roundHalfEven;
Big.RM = Big.roundHalfUp;
// Big.RM = Big.roundUp;

export const bigZero = new Big("0");
export default Big;

export const gtZero = (val: Big) => val.gt(bigZero)
export const gteZero = (val: Big) => val.gte(bigZero)
export const ltZero = (val: Big) => val.lt(bigZero)
export const lteZero = (val: Big) => val.lte(bigZero)
export const isZero = (val: Big) => val.eq(bigZero)

export const isOverAllocatedBigJs = (newValue: Big, maxValue: Big, lastValue: Big): boolean => {
	return (
		// Value is bigger than max allowed
		newValue.gt(maxValue) && 
		// Only allow downsizing allocation
		newValue.gt(lastValue) && 
		// Allow 0
		// newValue !== 0
		!newValue.eq(bigZero)
	)
};
