import { ContentConfigurationType } from '../../Entities/ContentConfiguration/ContentConfigurationType';
import { propertyOptionsFactory } from '@wiberg/formbuilder';
import { language } from '../../Services/LocalizationService';
import { DateEx } from '../../Utilities/DateEx';
import { unrestrictedMode } from '../../Utilities/DebugOverrides';
import { merge } from '../../helpers/merge';
import { IQueryExpression } from '../_interfaces/IQueryExpression';
import { guid } from '../../helpers/guid';
import { IRule } from '../workprofile/rule/IRule';
import { IRecurrenceRule } from '../workprofile/rule/recurrence/IRecurrenceRule';
import { Project, QueryGroup, Resource, TPEntity } from '../../api/generated/data-contracts';
import { IWorkProfile } from '../workprofile/IWorkProfile';
import { IWorkWeek } from '../workprofile/interfaces/IWorkWeek';
import { IRbs } from '../_interfaces/IRbs';

export const enum TPEntityTypes {
    Lookup = 'Lookup',
    Resource = 'Resource',
    Project = 'Project',
    Contract = 'Contract',
    BaseSettings = 'BaseSettings',
    WorkProfile = 'WorkProfile',
    ContentConfiguration = 'ContentConfiguration',
}

const tpEntityDefaults = (type: TPEntityTypes) => ({ id: guid.newGuid(), typeName: type.toString() });

export const tpEntityFactory = {
    contentConfiguration: <T,>(type: ContentConfigurationType, partial?: Partial<T>): T => {
        return {
            ...partial,
            ...tpEntityDefaults(TPEntityTypes.ContentConfiguration),
            contentConfigurationType: type,
        } as unknown as T;
    },
    queryGroup: (): QueryGroup => {
        return {
            typeName: 'QueryGroup',
            parts: [],
        } as QueryGroup;
    },
    queryExpression: (): IQueryExpression => {
        return {
            typeName: 'QueryExpression',
        } as IQueryExpression;
    },
	entity: <T,>(entity: TPEntityTypes, partial?: Partial<T>): T => {
        return {
			...partial,
            ...tpEntityDefaults(entity),
		} as unknown as T
    },
	workweek: () : IWorkWeek => {
		return {
			monday: 0,
			tuesday: 0,
			wednesday: 0,
			thursday: 0,
			friday: 0,
			saturday: 0,
			sunday: 0
		}
	},
	rbsRoot: (ownerId?: string) : IRbs => {
		return {
			name: "Root",
			id: guid.empty,
			fullName: "",
			children: []
		}
	}
};

export const tpEntityAddDefaults = {
    contentConfiguration: <T,>(item: T, type: ContentConfigurationType, partial?: Partial<T>): void => {
        merge(item, tpEntityDefaults(TPEntityTypes.ContentConfiguration), { contentConfigurationType: type }, partial);
    },
    workProfileRule: (item: IRule, partial?: Partial<IRule>): void => {
        merge(item, { id: guid.newGuid(), RecurrenceRule: {} as IRecurrenceRule }, partial);
    },
	workProfile: (item: IWorkProfile, partial?: Partial<IWorkProfile>): void => {
        merge(item, tpEntityDefaults(TPEntityTypes.WorkProfile), partial);
    },
    resource: (item: Resource, partial?: Partial<Resource>): void => {
        merge(item, tpEntityDefaults(TPEntityTypes.Resource), {active: true, userPrincipalName: undefined} as Partial<Resource>, partial);
    },
    project: (item: Project, partial?: Partial<Resource>): void => {
        merge(item, tpEntityDefaults(TPEntityTypes.Project), {isActive: true}, partial);
    }
};

export const systemFields = () => ({
    id: propertyOptionsFactory.string({ displayName: language.Common.Id, disable: () => !unrestrictedMode }),
    created: propertyOptionsFactory.date({
        displayName: language.Common.Created,
        disable: () => !unrestrictedMode,
        config: { displayFormat: (date?: Date) => DateEx.asUserLocalWithTime(date) },
		...dateFieldListRender<TPEntity>("created")
        // listItemOptions: { customValueRender: item => <>{DateEx.asUserLocalWithTime(item.created)}</> },
    }),
    modified: propertyOptionsFactory.date({
        displayName: language.Common.Modified,
        disable: () => !unrestrictedMode,
        config: { displayFormat: (date?: Date) => DateEx.asUserLocalWithTime(date) },
		...dateFieldListRender<TPEntity>("modified")
        // listItemOptions: { customValueRender: item => <>{DateEx.asUserLocalWithTime(item.modified)}</> },
    }),
    createdBy: propertyOptionsFactory.string({ displayName: language.Common.CreatedBy, disable: () => !unrestrictedMode }),
    modifiedBy: propertyOptionsFactory.string({ displayName: language.Common.ModifiedBy, disable: () => !unrestrictedMode }),
});

export const systemFieldsGrouped = () => ({
    id: propertyOptionsFactory.string({ displayName: language.Common.Id, disable: () => !unrestrictedMode, group: 'system' }),
    created: propertyOptionsFactory.date({
        displayName: language.Common.Created,
        disable: () => !unrestrictedMode,
        group: 'system',
        config: { displayFormat: (date?: Date) => DateEx.asUserLocalWithTime(date) },
		...dateFieldListRender<TPEntity>("created")
        // listItemOptions: { customValueRender: item => <>{DateEx.asUserLocalWithTime(item.created)}</>, minWidth: 120 },
    }),
    modified: propertyOptionsFactory.date({
        displayName: language.Common.Modified,
        disable: () => !unrestrictedMode,
        group: 'system',
        config: { displayFormat: (date?: Date) => DateEx.asUserLocalWithTime(date) },
		...dateFieldListRender<TPEntity>("modified")
        // listItemOptions: { customValueRender: item => <>{DateEx.asUserLocalWithTime(item.modified)}</>, minWidth: 120 },
    }),
    createdBy: propertyOptionsFactory.string({ displayName: language.Common.CreatedBy, disable: () => !unrestrictedMode, group: 'system' }),
    modifiedBy: propertyOptionsFactory.string({ displayName: language.Common.ModifiedBy, disable: () => !unrestrictedMode, group: 'system' }),
});


export const dateFieldListRender = <T,>(dateProp: keyof T, withTime?: boolean) => {
	const func = withTime
					? DateEx.asUserLocalWithTime
					: DateEx.asUserLocal;
	return {
		listItemOptions: { customValueRender: (item) => func(item[dateProp] as Date), minWidth: 120, isResizable: true }
	}
}