import { language } from "../../../Services/LocalizationService";
import { DropdownOptionEx } from "../../../Utilities/DropdownOptionEx";
import { PFPropertyBasicInfo } from "../../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyFilter } from "../../../Utilities/PropertyInfo/PropertyFilter";
import { PropertyType } from "../../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../../Utilities/PropertyInfo/Render";
import { LogSeverityLevel } from "../../LogSeverityLevel";

export class LogSettings {
	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:LogSettings.SeverityLevel", 1)
	@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<LogSeverityLevel>(LogSeverityLevel)})
	@PFPropertyFilter({FilterLevel: 1})
	public SeverityLevel: LogSeverityLevel;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:LogSettings.EnablePluginLogging", 2)
	@PFPropertyFilter({FilterLevel: 1})
	public EnablePluginLogging: boolean;
	@PFPropertyBasicInfo(PropertyType.SimpleArray, "_localization:LogSettings.ExcludeCategoryNames", 3)
	@PFPropertyRender(null, { ColumnTitleFunction: () => language.LogSettings.ExcludeCategoryColumnTitle, ValueType: "text" })
	@PFPropertyFilter({FilterLevel: 1})
	public ExcludeCategoryNames: Array<string>;
}