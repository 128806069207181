import { IconButton, Label, Stack, StackItem, TextField, Toggle } from '@fluentui/react';
import { FunctionComponent, useState, useEffect } from 'react';
import { ProposalState } from '../../api/generated/data-contracts';
import { DynamicQuery } from '../../Entities/DynamicQueryBuilder/DynamicQuery';
import { EntityType } from '../../Entities/EntityTypes';
import { ProjectScenario, Scenario, ScenarioType } from '../../Entities/Scenario';
import { DataService } from '../../Services/DataService';
import { language } from '../../Services/LocalizationService';
import { Plugin } from '../../Services/PluginInvoker';
import { Confirm } from '../../Utilities/Confirm';
import { UserContext } from '../../Utilities/Context/UserContext';
import { DropdownOptionEx } from '../../Utilities/DropdownOptionEx';
import { PluginIDs } from '../../Utilities/PluginIDs';
import { StringEx } from '../../Utilities/StringEx';
import { PFDialog } from './PFDialog';
import PFDropdown from './PFDropdown';

export class DraftDialogDto {
    DraftId: string;
    DraftName: string;
}

interface IProps {
    Show: boolean;
    Callback: (dto: DraftDialogDto) => Promise<void>;
    DismissCallback: () => void;
    ProjectId: string;
}

const ProjectDraftDialog: FunctionComponent<IProps> = props => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [dto, setDto] = useState<DraftDialogDto>(null);
    const [createNew, setCreateNew] = useState<boolean>(false);
    const [drafts, setDrafts] = useState<Array<Scenario>>();

    useEffect(() => {
        setShowDialog(props.Show);
        if (!props.Show) return;
        const fetch = async () => {
            const query = new DynamicQuery('ScenarioType == @0 AND State == @1 AND OwnerId == @2', [
                ScenarioType.ProjectScenario,
                ProposalState.Draft,
                UserContext.Resource.id,
            ]);
            let scenarios = (await DataService.Query<ProjectScenario>(EntityType.Scenario, query)) ?? [];
            scenarios = scenarios.filter(_ => _.ProjectId === props.ProjectId);
            setDrafts(scenarios);
        };
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.Show]);

    const onChange = (id: string, name: string) => {
        const clone = dto != null ? { ...dto } : new DraftDialogDto();
        clone.DraftId = id;
        clone.DraftName = name;
        setDto(clone);
    };

    const dismiss = () => {
        setCreateNew(false);
        setDto(null);
    };

    const removeFromList = (draftId: string) => {
        const clone = [...drafts];
        const index = clone.findIndex(_ => _.id === draftId);
        clone.splice(index, 1);
        // fake select next item
        if (clone.length > 0) {
            const idx = index >= clone.length ? clone.length - 1 : index;
            const newSelection = new DraftDialogDto();
            newSelection.DraftId = clone[idx].id;
            newSelection.DraftName = clone[idx].Name;
            setDto(newSelection);
        } else {
            setDto(null);
        }
        setDrafts(clone);
    };

    const onDelete = () => {
        // confirm dialog
        Confirm.Instance.Show({
            show: true,
            title: language.Common.AreYouSure,
            subtext: language.ProjectDraftDialog.AreYouSureYouWantToDelete,
            confirmAction: async () => {
                const item = { TypeName: 'Scenario', id: dto.DraftId, ScenarioType: 0 } as Scenario;
                await DataService.Delete(EntityType.Scenario, [item]);
                removeFromList(dto.DraftId);
            },
            //TODO ACTUAL DEFAULT DISMISS?
            dismissAction: () => {},
            okButtonText: language.Common.Ok,
            cancelButtonText: language.Common.Cancel,
            hideCancelButton: true,
        });
    };

    const onPropose = () => {
        // confirm dialog
        Confirm.Instance.Show({
            show: true,
            title: language.Common.AreYouSure,
            subtext: language.ProjectDraftDialog.AreYouSureYouWantToPropose,
            confirmAction: async () => {
                // set state to proposed
                await Plugin.InvokeNoResponse(PluginIDs.ResetScenarioState, { ScenarioId: dto.DraftId, State: ProposalState.Proposed });
                removeFromList(dto.DraftId);
            },
            //TODO ACTUAL DEFAULT DISMISS?
            dismissAction: () => {},
            okButtonText: language.Common.Ok,
            cancelButtonText: language.Common.Cancel,
            hideCancelButton: true,
        });
    };

    return (
        <PFDialog
            title={language.ProjectDraftDialog.Drafts}
            showDialog={showDialog}
            className="tp-draftdialog"
            callback={() => {
                props.Callback(dto);
                dismiss();
            }}
            dismissCallback={() => {
                props.DismissCallback();
                dismiss();
            }}
            buttonDefaultDisabled
            okButtonEnabled={!StringEx.isNullOrWhiteSpace(dto?.DraftName)}
            cancelButtonText={language.Common.Close}
            buttonText={language.ProjectDraftDialog.OpenDraft}
        >
            {!createNew && (
                <>
                    <Label>{language.ProjectDraftDialog.SelectDraftToWorkWith}</Label>
                    <Stack styles={{ root: { flexFlow: 'row', paddingRight: '5px' } }}>
                        <StackItem styles={{ root: { flexGrow: 1 } }}>
                            <PFDropdown
                                SelectedKey={dto?.DraftId}
                                Options={DropdownOptionEx.toDropdownOptions(
                                    drafts,
                                    _ => _.id,
                                    _ => _.Name,
                                )}
                                OnChange={(event, items, option) => onChange(option.key as string, option.text)}
                            />
                        </StackItem>

                        <IconButton
                            iconProps={{ iconName: 'DocumentApproval' }}
                            onClick={onPropose}
                            disabled={dto?.DraftId == null}
                            title={language.ProjectDraftDialog.ReadyForApproval}
                        />
                        <IconButton
                            iconProps={{ iconName: 'RemoveFromShoppingList' }}
                            onClick={onDelete}
                            disabled={dto?.DraftId == null}
                            title={language.ProjectDraftDialog.DeleteDraft}
                        />
                    </Stack>
                </>
            )}

            {createNew && (
                <>
                    <Label>{language.ProjectDraftDialog.CreateNewDraft}</Label>
                    <TextField placeholder={language.ProjectDraftDialog.NewDraftName} onChange={(obj, value) => onChange(null, value)} />
                </>
            )}
            <Toggle
                onChange={(obj, value) => {
                    setDto(null);
                    setCreateNew(value);
                }}
                offText={language.ProjectDraftDialog.OrCreateNewDraft}
                styles={{ container: { paddingTop: '10px' } }}
            />
        </PFDialog>
    );
};

export default ProjectDraftDialog;
