import { DateResolution } from "../../api/generated/data-contracts";
import { produce } from "../helpers";
import { TPContext } from "../types";


export interface ITimeline {
	end: Date,
	start: Date,
	resolution: DateResolution
}

export interface ITimelineContext {
	timeline: ITimeline;
	readonly setTimeline: (timeline: Partial<ITimeline> | ((timeline: ITimeline) => Partial<ITimeline>)) => void;
	readonly setTimelineStart: (start: Date) => void;
	readonly setTimelineEnd: (end: Date) => void;
	readonly setTimelineResolution: (res: DateResolution) => void;
}

export const timelineContext: TPContext<ITimelineContext> = (set, get, api) => {
	return {
		timeline: {
			end: null,
			start: null,
			resolution: null
		},
		setTimeline: (timeline) => set(produce(store => {
			if (typeof timeline === "function") {
				store.timeline = {...store.timeline, ...timeline(store.timeline)}
			} else {
				if (timeline.start) {
					store.timeline.start = new Date(timeline.start);
				}
				if (timeline.end) {
					store.timeline.end = new Date(timeline.end);
				}
				if (timeline.resolution != null) {
					store.timeline.resolution = timeline.resolution;
				}
			}
		})),
		setTimelineEnd: (end) => set(produce(store => {
			store.timeline.end = end
		})),
		setTimelineStart: (start) => set(produce(store => {
			store.timeline.start = start
		})),
		setTimelineResolution: (res) => set(produce(store => {
			store.timeline.resolution = res
		})),
	}
}