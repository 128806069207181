import { MsalProvider } from '@azure/msal-react';
import React, { Suspense } from 'react';
import { getSuspendableMSALInstance, getSuspendableTeamsAuthInstance } from '../../../api/authConfig';
import { LoadingSpinner } from '../../../Components/Common/LoadingSpinner';
import { PFErrorBoundary } from '../../../Components/Common/PFErrorBoundary';

interface Props {
    onSuccess?: () => any;
}

const isTeams =
    window.location.ancestorOrigins !== undefined &&
    window.location.ancestorOrigins.length >= 1 &&
    window.location.ancestorOrigins[0] === 'https://teams.microsoft.com';

const SuspendableMsalProvider: React.FC<Props> = ({ children, onSuccess }) => {
	if (!isTeams) {
		const instance = getSuspendableMSALInstance(onSuccess);
		return <MsalProvider instance={instance}>{children}</MsalProvider>;
	} else {
		const teamsTokenAquiredMsalInstance = getSuspendableTeamsAuthInstance(onSuccess);
		// No MSAL provider for teams, so we just use the token aquired status from teams
		if (teamsTokenAquiredMsalInstance) {
			return <MsalProvider instance={teamsTokenAquiredMsalInstance}>{children}</MsalProvider>;
		}
		// If teams token is not aquired, we try msal again
		const instance = getSuspendableMSALInstance(onSuccess);
		return <MsalProvider instance={instance}>{children}</MsalProvider>;
	}
};

export const AuthProvider: React.FC<Props> = ({ children, onSuccess }) => {
    return (
        <PFErrorBoundary>
            <Suspense fallback={<LoadingSpinner visible message='Authenticating...' />}>
                <SuspendableMsalProvider onSuccess={onSuccess}>{children}</SuspendableMsalProvider>
            </Suspense>
        </PFErrorBoundary>
    );
};

