import { useState } from 'react';
import { Callout, CommandBarButton, DirectionalHint } from '@fluentui/react';
import { DraggableList } from './components/DraggableList/DraggableList';
import { useCustomColumnsValue } from '../../context/customColumnContext';

const id = 'project-planner-custom-column-filter';
export const CustomColumnFilter = () => {
    const [showFilterCallout, setShowFilterCallout] = useState(false);
    const columns = useCustomColumnsValue();
    if (!columns.length) {
        return null;
    }
    return (
        <>
            <CommandBarButton
                //
                onClick={() => setShowFilterCallout(s => !s)}
                iconProps={{ iconName: 'TripleColumnEdit' }}
                text="Add columns"
                id={id}
            />
            <Callout
                hidden={!showFilterCallout}
                target={`#${id}`}
                directionalHint={DirectionalHint.bottomLeftEdge}
                isBeakVisible={false}
                onDismiss={() => setShowFilterCallout(false)}
                doNotLayer
                styles={{ root: { overflow: 'hidden' } }}
            >
                <DraggableList handle useDragOverlay={true} />
            </Callout>
        </>
    );
};
