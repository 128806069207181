/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AADSyncEntity {
    entity?: EntityType;
}

/** @format int32 */
export enum ActiveFilter {
    Active = 0,
    Inactive = 1,
    Both = 2,
}

/** @format int32 */
export enum ActivitiesFTEWorkSanitizerFunction {
    ResetHours = 1,
    ResetFTE = 2,
}

export interface ActivitiesScannerDto {
    checkForUtcFormat?: boolean;
    checkForZeroWork?: boolean;
    checkForDecimalMin?: boolean;
    checkForInvalidDateRange?: boolean;
    checkForOverlapping?: boolean;
    checkWorkHoursImplemented?: boolean;
    checkForWeekendWorkHours?: boolean;
    checkForContractStartEnd?: boolean;
    returnOnlyIds?: boolean;
}

export interface ActivitiesScannerResultDto {
    results?: ActivitiesScannerResultItemDto[];
    affectedContractIds?: string[];
}

export interface ActivitiesScannerResultItemDto {
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    contractId?: string;
    contractName?: string;
    requestsMarkedAsIssue?: ContractActivity[];
    allocationsMarkedAsIssue?: ContractActivity[];
}

export interface ActivityDistributionConfig {
    distributionTechnique?: DistributionTechnique;
    resourceDistribution?: ResourceDistribution;
    /** @format double */
    allowedEvenlyDistributedVariation?: number;
}

export interface ActivityDistributionDto {
    resolution?: DateResolution;
    /** @format uuid */
    contractId?: string;
    contractName?: string;
    /** @format uuid */
    resourceId?: string;
    resourceName?: string;
    /** @format uuid */
    projectId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format date-time */
    earliestStart?: Date;
    /** @format date-time */
    latestEnd?: Date;
    /** @format double */
    work?: number;
    /** @format uuid */
    scenarioId?: string;
    scenarioName?: string;
    performDistribution?: boolean;
    onlyRequests?: boolean;
    distributionConfig?: ActivityDistributionConfig;
}

export interface ActivityGroup {
    /** @format uuid */
    resourceId?: string;
    resourceName?: string;
    /** @format double */
    workHours?: number;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

/** @format int32 */
export enum ActivityHandlingType {
    Requests = 0,
    Allocations = 1,
    Both = 2,
}

export interface ActivityImportConfiguration {
    /**
     * Contract naming rule
     * The definition used to name created contracts.
     */
    contractNamingRule?: string;
    importType: ImportType;
    dateHandling: ImportDateHandling;
    contractIdGenerator: ContractIdGeneratorType;
    /**
     * Skip sanitize activities
     * If it should skip sanitizing imported activities. Use only for a trusted data set.
     */
    skipSanitizeActivities: boolean;
    /**
     * Origin
     * Which origin should the import use. This is used for tracking changes, and when using a Full Load import.
     * @format uuid
     */
    origin: string;
}

/** @format int32 */
export enum ActivitySourceAction {
    Unknown = 0,
    CellEdit = 1,
    ForwardRequest = 2,
    MoveRequest = 3,
    RemoveRequest = 4,
    RemoveAllocations = 5,
    MergeContract = 6,
    CreateMatchingRequest = 7,
    RemoveActivities = 8,
    WorkPackage = 9,
    ActivityIntegration = 10,
    ResourceManagerMigrator = 11,
    ProjectEngagementsMigrator = 12,
    GenericResourceChanged = 13,
    CreateMatchingAllocation = 14,
    MoveAllAllocations = 15,
    PublishedScenario = 17,
    AutoCreated = 18,
    UserCreated = 19,
    AutoDistribute = 20,
    SQLImportV2 = 21,
    MoveAllocation = 22,
    Sanitizer = 100,
}

export interface AddActivityDto {
    entity?: EntityType;
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
    contractName?: string;
    contractGroupName?: string;
    resourceName?: string;
    workPackage?: PowerPpmWorkPackage;
    /** @format double */
    work?: number;
    type?: GridType;
    resolution?: DateResolution;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface AddTeamMemberDto {
    /** @format uuid */
    teamId?: string;
    members?: string[];
}

export interface AddVersionedActivityDto {
    entity?: EntityType;
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
    contractName?: string;
    contractGroupName?: string;
    resourceName?: string;
    workPackage?: PowerPpmWorkPackage;
    /** @format double */
    work?: number;
    type?: GridType;
    resolution?: DateResolution;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format uuid */
    versionedContractId?: string;
    /** @format uuid */
    scenarioId?: string;
    state?: ProposalState;
}

/** @format int32 */
export enum AddressFamily {
    Unspecified = 0,
    Unix = 1,
    InterNetwork = 2,
    ImpLink = 3,
    Pup = 4,
    Chaos = 5,
    NS = 6,
    Ipx = 7,
    Iso = 8,
    Osi = 9,
    Ecma = 10,
    DataKit = 11,
    Ccitt = 12,
    Sna = 13,
    DecNet = 14,
    DataLink = 15,
    Lat = 16,
    HyperChannel = 17,
    AppleTalk = 18,
    NetBios = 19,
    VoiceView = 21,
    FireFox = 22,
    Banyan = 23,
    Atm = 24,
    InterNetworkV6 = 25,
    Cluster = 26,
    Ieee12844 = 28,
    Irda = 29,
    NetworkDesigners = 65536,
    Max = 65537,
    Packet = -1,
}

export interface AlignContractStatus {
    /** @format uuid */
    id?: string;
    name?: string;
    color?: string;
    /** @format int32 */
    order?: number;
    editableBy?: UserType[];
    customNotification?: string;
}

export interface Allocation {
    /** @format uuid */
    id?: string;
    typeName?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    name?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format double */
    work?: number;
    /** @format double */
    workFTE?: number;
    /** @format double */
    workHours?: number;
    /** @format uuid */
    resourceId?: string;
    resolution?: DateResolution;
    unitType?: UnitType;
    sourceAction?: ActivitySourceAction;
    /** @format double */
    unitTypeWork?: number;
    state?: ProposalState;
}

export interface AllocationTimephasedDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface AllowEditOfCurrentPeriod {
    year?: boolean;
    quarter?: boolean;
    month?: boolean;
    week?: boolean;
}

export interface Announcement {
    /** @format uuid */
    id?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    name?: string;
    typeName?: string;
    contentConfigurationType?: ContentConfigurationType;
    content?: string;
    active?: boolean;
    recipientFilter?: QueryGroup;
    /** @format int32 */
    order?: number;
}

export interface AppPackage {
    cycle?: UpgradeCycle;
    name?: string;
    path?: string;
    url?: string;
    /** @format date-time */
    lastModified?: Date;
}

export interface AppPackageDto {
    cycle?: UpgradeCycle;
    name?: string;
    path?: string;
    url?: string;
    lastModified?: string;
}

export interface AppPackageGroup {
    controllerName?: string;
    package?: AppPackage;
}

export interface ApplyClause {
    transformations?: TransformationNode[];
}

export interface ApplyQueryOption {
    context?: ODataQueryContext;
    resultClrType?: Type;
    applyClause?: ApplyClause;
    rawValue?: string;
}

export interface AsnEncodedData {
    oid?: Oid;
    /** @format byte */
    rawData?: string;
}

export interface Assembly {
    definedTypes?: TypeInfo[];
    exportedTypes?: Type[];
    codeBase?: string;
    entryPoint?: MethodInfo;
    fullName?: string;
    imageRuntimeVersion?: string;
    isDynamic?: boolean;
    location?: string;
    reflectionOnly?: boolean;
    isCollectible?: boolean;
    isFullyTrusted?: boolean;
    customAttributes?: CustomAttributeData[];
    escapedCodeBase?: string;
    manifestModule?: Module;
    modules?: Module[];
    globalAssemblyCache?: boolean;
    /** @format int64 */
    hostContext?: number;
    securityRuleSet?: SecurityRuleSet;
}

export interface AsymmetricAlgorithm {
    /** @format int32 */
    keySize?: number;
    legalKeySizes?: KeySizes[];
    signatureAlgorithm?: string;
    keyExchangeAlgorithm?: string;
}

export interface AuthOptionsBase {
    instance?: string;
    tenantId?: string;
    clientId?: string;
}

export interface AutoAlignContractNames {
    withGenericResource?: boolean;
    onlyForNameMatch?: boolean;
}

export interface BaseRow {
    typeName?: string;
    /** @format uuid */
    id?: string;
    name?: string;
    properties?: Record<string, any>;
    cellRows?: CellRow[];
}

export interface BlobFile {
    name?: string;
    url?: string;
    path?: string;
    /** @format date-time */
    lastModified?: Date;
    cycle?: UpgradeCycle;
}

export interface ByteReadOnlyMemory {
    /** @format int32 */
    length?: number;
    isEmpty?: boolean;
    span?: ByteReadOnlySpan;
}

export interface ByteReadOnlySpan {
    /** @format int32 */
    length?: number;
    isEmpty?: boolean;
}

export interface CacheContractConstraint {
    resolution?: DateResolution;
    /** @format int32 */
    backward?: number;
    /** @format int32 */
    forward?: number;
}

/** @format int32 */
export enum CallingConventions {
    Standard = 1,
    VarArgs = 2,
    Any = 3,
    HasThis = 32,
    ExplicitThis = 64,
}

export interface CancellationToken {
    isCancellationRequested?: boolean;
    canBeCanceled?: boolean;
    waitHandle?: WaitHandle;
}

export interface CategoryResourceSettings {
    alwaysShowMatchingCategoryResourcesInViews?: boolean;
    categoryResourcesMatchingMode?: RbsMatchingMode;
}

export interface Cell {
    /** @format uuid */
    id?: string;
    /** @format uuid */
    parentId?: string;
    type?: CellType;
    /** @format double */
    value?: number;
    disabled?: boolean;
    properties?: Record<string, any>;
}

export interface CellRow {
    /** @format uuid */
    id?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    cells?: Cell[];
    properties?: Record<string, any>;
}

/** @format int32 */
export enum CellType {
    Top = 1,
    Group = 2,
    Row = 3,
    Container = 4,
}

export interface ChangeContractGroupNameDto {
    contractIds?: string[];
    newName?: string;
}

export interface ChangeContractNameDto {
    /** @format uuid */
    contractId?: string;
    name?: string;
}

/** @format int32 */
export enum ChartType {
    None = 0,
    RequestFulfillment = 1,
    ResourceCapacity = 2,
}

export interface CheckForDuplicateContractsDto {
    shouldDelete?: boolean;
    keepOption?: KeepOption;
}

export interface Claim {
    issuer?: string;
    originalIssuer?: string;
    properties?: Record<string, string>;
    subject?: ClaimsIdentity;
    type?: string;
    value?: string;
    valueType?: string;
}

export interface ClaimsIdentity {
    authenticationType?: string;
    isAuthenticated?: boolean;
    actor?: ClaimsIdentity;
    bootstrapContext?: any;
    claims?: Claim[];
    label?: string;
    name?: string;
    nameClaimType?: string;
    roleClaimType?: string;
}

export interface ClaimsPrincipal {
    claims?: Claim[];
    identities?: ClaimsIdentity[];
    identity?: IIdentity;
}

export interface ColumnDto {
    displayName?: string;
    logicalName?: string;
    attributeType?: string;
}

/** @format int32 */
export enum ColumnType {
    Normal = 0,
    Total = 1,
    Custom = 2,
}

export interface Comment {
    /** @format uuid */
    id?: string;
    /** @format uuid */
    resourceId?: string;
    text?: string;
    /** @format uuid */
    authorId?: string;
    authorName?: string;
    authorRole?: UserType;
    /** @format date-time */
    timestamp?: Date;
    isPMComment?: boolean;
}

export interface ComputeClause {
    computedItems?: ComputeExpression[];
}

export interface ComputeExpression {
    expression?: SingleValueNode;
    alias?: string;
    typeReference?: IEdmTypeReference;
}

export interface ComputeQueryOption {
    context?: ODataQueryContext;
    resultClrType?: Type;
    computeClause?: ComputeClause;
    rawValue?: string;
}

export interface ConnectionInfo {
    id?: string;
    remoteIpAddress?: IPAddress;
    /** @format int32 */
    remotePort?: number;
    localIpAddress?: IPAddress;
    /** @format int32 */
    localPort?: number;
    clientCertificate?: X509Certificate2;
}

export interface ConstructorInfo {
    name?: string;
    declaringType?: Type;
    reflectedType?: Type;
    module?: Module;
    customAttributes?: CustomAttributeData[];
    isCollectible?: boolean;
    /** @format int32 */
    metadataToken?: number;
    attributes?: MethodAttributes;
    methodImplementationFlags?: MethodImplAttributes;
    callingConvention?: CallingConventions;
    isAbstract?: boolean;
    isConstructor?: boolean;
    isFinal?: boolean;
    isHideBySig?: boolean;
    isSpecialName?: boolean;
    isStatic?: boolean;
    isVirtual?: boolean;
    isAssembly?: boolean;
    isFamily?: boolean;
    isFamilyAndAssembly?: boolean;
    isFamilyOrAssembly?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    isConstructedGenericMethod?: boolean;
    isGenericMethod?: boolean;
    isGenericMethodDefinition?: boolean;
    containsGenericParameters?: boolean;
    methodHandle?: RuntimeMethodHandle;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    memberType?: MemberTypes;
}

/** @format int32 */
export enum ContentConfigurationType {
    Unknown = 0,
    Rbs = 1,
    Lookup = 2,
    Theme = 4,
    MailTemplate = 5,
    WorkPackage = 6,
    Announcement = 7,
    HelpSection = 8,
}

export interface Contract {
    typeName?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    /** @format uuid */
    relationId?: string;
    name?: string;
    groupName?: string;
    origins?: string[];
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    resourceGuid?: string;
    comments?: Comment[];
    requests?: Request[];
    allocations?: Allocation[];
    activities?: ContractActivity[];
    /** @format uuid */
    id?: string;
    /** @format uuid */
    alignStatus?: string;
    sourceAction?: ContractSourceAction;
    /** @format uuid */
    sourceId?: string;
    sourceProperties?: Record<string, any>;
    /** @format uuid */
    sourceRunId?: string;
    originIds?: Record<string, string>;
    /** @format uuid */
    manager?: string;
    workPackage?: PowerPpmWorkPackage;
    cacheConstraintInfo?: ContractCacheConstraintInfo;
    stickyness?: Sticky;
    constraints?: ContractConstraints;
    /** @format double */
    proposedWork?: number;
    customProperties?: Record<string, any>;
}

export interface ContractActivity {
    /** @format uuid */
    id?: string;
    typeName?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    name?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format double */
    work?: number;
    /** @format double */
    workFTE?: number;
    /** @format double */
    workHours?: number;
    /** @format uuid */
    resourceId?: string;
    resolution?: DateResolution;
    unitType?: UnitType;
    sourceAction?: ActivitySourceAction;
    /** @format double */
    unitTypeWork?: number;
    state?: ProposalState;
}

/** @format int32 */
export enum ContractCacheConstraintInfo {
    HasRequests = 1,
    HasAllocations = 2,
}

export interface ContractChange {
    contractName?: string;
    work?: WorkChange;
    resourceChanges?: ResourceChange[];
}

export interface ContractConstraints {
    /** @format date-time */
    earliestStart?: Date;
    /** @format date-time */
    latestEnd?: Date;
    predecessors?: string[];
    successors?: string[];
}

/** @format int32 */
export enum ContractIdGeneratorType {
    SuppliedId = 0,
    GenericResource = 1,
    ResourceProperty = 2,
    SuppliedMetadata = 3,
}

export interface ContractInfoDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    resourceId?: string;
    contractName?: string;
    resourceName?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format double */
    work?: number;
    /** @format date-time */
    earliestStart?: Date;
    /** @format date-time */
    latestEnd?: Date;
    predecessors?: string[];
    successors?: string[];
    hasActvities?: boolean;
}

export interface ContractNotificationDataWrapper {
    recipientName?: string;
    start?: string;
    end?: string;
    pmChanges?: ProjectChange[];
    lmChanges?: ProjectChange[];
    resourceChanges?: ProjectChange[];
    isEmpty?: boolean;
    /** @format int32 */
    changesCount?: number;
}

export interface ContractPickerDto {
    /** @format uuid */
    projectId?: string;
}

export interface ContractReportDataInconsistencyResult {
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    contractId?: string;
    contractName?: string;
    verified?: boolean;
    /** @format double */
    requestDiff?: number;
    /** @format double */
    allocationDiff?: number;
}

export interface ContractResolutionCheckDto {
    /** @format uuid */
    contractId?: string;
    resolution?: DateResolution;
    checkRequests?: boolean;
    checkAllocations?: boolean;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface ContractSanitizerDto {
    /** @format uuid */
    contractId?: string;
    contractIds?: string[];
    contractIdsAsString?: string;
    performChanges?: boolean;
    sanitizePeriods?: boolean;
    showBeforeAndAfter?: boolean;
}

export interface ContractSanitizerResultDto {
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    contractId?: string;
    contractName?: string;
    requestsMarkedForUpdate?: ContractActivity[];
    requestsMarkedForDelete?: ContractActivity[];
    allocationsMarkedForUpdate?: ContractActivity[];
    allocationsMarkedForDelete?: ContractActivity[];
    contractBefore?: Contract;
    contractAfter?: Contract;
}

/** @format int32 */
export enum ContractSourceAction {
    Unknown = 0,
    CellEdit = 1,
    ForwardRequest = 2,
    MoveRequest = 3,
    MergeContract = 6,
    CreateMatchingRequest = 7,
    RemoveActivities = 8,
    WorkPackage = 9,
    ActivityIntegration = 10,
    ResourceManagerMigrator = 11,
    ProjectEngagementsMigrator = 12,
    GenericResourceChanged = 13,
    PublishedScenario = 17,
    AutoCreated = 18,
    UserCreated = 19,
    AutoDistribute = 20,
    SQLImportV2 = 21,
    MoveContract = 23,
    SqlImportActivities = 24,
}

export interface ContractVersionRetention {
    /** @format int32 */
    day?: number;
    /** @format int32 */
    week?: number;
    /** @format int32 */
    month?: number;
    allIsZero?: boolean;
}

export interface ContractWithStringVariants {
    typeName?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    /** @format uuid */
    relationId?: string;
    name?: string;
    groupName?: string;
    origins?: string[];
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    resourceGuid?: string;
    comments?: Comment[];
    requests?: Request[];
    allocations?: Allocation[];
    activities?: ContractActivity[];
    /** @format uuid */
    id?: string;
    /** @format uuid */
    alignStatus?: string;
    sourceAction?: ContractSourceAction;
    /** @format uuid */
    sourceId?: string;
    sourceProperties?: Record<string, any>;
    /** @format uuid */
    sourceRunId?: string;
    originIds?: Record<string, string>;
    /** @format uuid */
    manager?: string;
    workPackage?: PowerPpmWorkPackage;
    cacheConstraintInfo?: ContractCacheConstraintInfo;
    stickyness?: Sticky;
    constraints?: ContractConstraints;
    /** @format double */
    proposedWork?: number;
    customProperties?: Record<string, any>;
    nameAsString?: string;
    projectIdAsString?: string;
    resourceGuidAsString?: string;
    rbsAsString?: string;
    proposedWorkAsString?: string;
    requestsAsString?: string;
    allocationsAsString?: string;
    alignStatusAsString?: string;
    stickynessAsString?: string;
    sourceActionAsString?: string;
    customPropertiesAsString?: Record<string, string>;
    originsAsString?: string;
}

export interface ContractsReportDataInconsistencyAlignerDto {
    /** @format uuid */
    projectId?: string;
    enableMaintenanceMode?: boolean;
    refreshCapacityForAffectedResources?: boolean;
}

export interface ContractsReportDataInconsistencyFixerDto {
    contractsList?: ContractReportDataInconsistencyResult[];
    contractJson?: string;
    refreshCapacityForAffectedResources?: boolean;
}

export interface CountQueryOption {
    context?: ODataQueryContext;
    rawValue?: string;
    value?: boolean;
    validator?: CountQueryValidator;
}

export type CountQueryValidator = object;

export type CryptoProviderCache = object;

export interface CryptoProviderFactory {
    cryptoProviderCache?: CryptoProviderCache;
    customCryptoProvider?: ICryptoProvider;
    /** @default true */
    cacheSignatureProviders?: boolean;
    /** @format int32 */
    signatureProviderObjectPoolCacheSize?: number;
}

export interface Custom403Setting {
    custom403Enabled?: boolean;
    custom403Html?: string;
}

export interface CustomAttributeData {
    attributeType?: Type;
    constructor?: ConstructorInfo;
    constructorArguments?: CustomAttributeTypedArgument[];
    namedArguments?: CustomAttributeNamedArgument[];
}

export interface CustomAttributeNamedArgument {
    memberInfo?: MemberInfo;
    typedValue?: CustomAttributeTypedArgument;
    memberName?: string;
    isField?: boolean;
}

export interface CustomAttributeTypedArgument {
    argumentType?: Type;
    value?: any;
}

export interface CustomProperty {
    /** @format uuid */
    id?: string;
    internalName?: string;
    displayName?: string;
    required?: boolean;
    type?: CustomPropertyType;
    /** @format uuid */
    lookup?: string;
    group?: string;
}

/** @format int32 */
export enum CustomPropertyType {
    String = 0,
    Number = 1,
    Boolean = 2,
    Date = 3,
    Choice = 4,
    ChoiceMultiselect = 5,
    StringMultiline = 6,
    Url = 7,
}

export interface CustomPropertyWithPermissions {
    /** @format uuid */
    id?: string;
    internalName?: string;
    displayName?: string;
    required?: boolean;
    type?: CustomPropertyType;
    /** @format uuid */
    lookup?: string;
    group?: string;
    editableBy?: UserType[];
}

export interface DataExportCollectionDTO {
    entity?: string;
    ids?: string[];
}

export interface DataExportQueryDTO {
    entity?: string;
}

export interface DataExportTimephasedPeriodCapacity {
    /** @format uuid */
    resourceId?: string;
    /** @format double */
    baseCapacity?: number;
    periodCapacity?: PeriodCapacity[];
}

export interface DataExportTimephasedPeriodWork {
    /** @format uuid */
    contractId?: string;
    periodWork?: PeriodWork[];
}

export interface DataIntegrationRequestDTO {
    integration?: string;
    ids?: string[];
}

export interface DataIntegrationTimephasedtDTO {
    integration?: string;
    ids?: string[];
}

export interface DataReferenceIdsDto {
    ids?: string[];
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface DataverseEnvironment {
    /** @format uuid */
    id?: string;
    friendlyName?: string;
    url?: string;
}

/** @format int32 */
export enum DateResolution {
    Year = 0,
    Month = 1,
    Week = 2,
    Day = 3,
    Quarter = 4,
}

export interface DateTimeRange {
    /** @format date-time */
    min?: Date;
    /** @format date-time */
    max?: Date;
}

export interface DefaultQuerySettings {
    enableExpand?: boolean;
    enableSelect?: boolean;
    enableCount?: boolean;
    enableOrderBy?: boolean;
    enableFilter?: boolean;
    /** @format int32 */
    maxTop?: number;
    enableSkipToken?: boolean;
}

export interface DeleteCascadeDto {
    entity?: EntityType;
    items?: TPEntity[];
}

export interface DeleteCommentsDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    commentId?: string;
}

export interface DeleteDangerousDto {
    /** @format uuid */
    id?: string;
    collection?: string;
    partitionKey?: string;
}

export interface DeleteDto {
    entity?: EntityType;
    items?: TPEntity[];
    force?: boolean;
}

export interface DeltaUpsertDto {
    entity?: EntityType;
    items?: TPEntity[];
    /** @format uuid */
    origin?: string;
    changedProperties?: string[];
}

export interface DistributionResult {
    success?: boolean;
    message?: string;
    /** @format double */
    target?: number;
    /** @format double */
    allocatedSum?: number;
    result?: DistributionResultType;
    resources?: ResourceDistributionResult[];
    dateSpan?: DateTimeRange;
    /** @format int32 */
    periodsCount?: number;
    allocations?: Allocation[];
}

/** @format int32 */
export enum DistributionResultType {
    Success = 1,
    NotPerfect = 2,
    Failed = 4,
    RemovedTooSmall = 8,
    UsedPeriodEvenlyVariation = 16,
}

/** @format int32 */
export enum DistributionTechnique {
    ShortAndFat = 0,
    Evenly = 1,
}

export interface DynamicQuery {
    expression?: string;
    params?: any[];
}

export interface ETag {
    isWellFormed?: boolean;
    entityType?: Type;
    isAny?: boolean;
    isIfNoneMatch?: boolean;
}

/** @format int32 */
export enum EdmContainerElementKind {
    None = 0,
    EntitySet = 1,
    ActionImport = 2,
    FunctionImport = 3,
    Singleton = 4,
}

/** @format int32 */
export enum EdmExpressionKind {
    None = 0,
    BinaryConstant = 1,
    BooleanConstant = 2,
    DateTimeOffsetConstant = 3,
    DecimalConstant = 4,
    FloatingConstant = 5,
    GuidConstant = 6,
    IntegerConstant = 7,
    StringConstant = 8,
    DurationConstant = 9,
    Null = 10,
    Record = 11,
    Collection = 12,
    Path = 13,
    If = 14,
    Cast = 15,
    IsType = 16,
    FunctionApplication = 17,
    LabeledExpressionReference = 18,
    Labeled = 19,
    PropertyPath = 20,
    NavigationPropertyPath = 21,
    DateConstant = 22,
    TimeOfDayConstant = 23,
    EnumMember = 24,
    AnnotationPath = 25,
}

/** @format int32 */
export enum EdmOnDeleteAction {
    None = 0,
    Cascade = 1,
}

/** @format int32 */
export enum EdmPropertyKind {
    None = 0,
    Structural = 1,
    Navigation = 2,
}

export interface EdmReferentialConstraintPropertyPair {
    dependentProperty?: IEdmStructuralProperty;
    principalProperty?: IEdmStructuralProperty;
}

/** @format int32 */
export enum EdmSchemaElementKind {
    None = 0,
    TypeDefinition = 1,
    Term = 2,
    Action = 3,
    EntityContainer = 4,
    Function = 5,
}

/** @format int32 */
export enum EdmTypeKind {
    None = 0,
    Primitive = 1,
    Entity = 2,
    Complex = 3,
    Collection = 4,
    EntityReference = 5,
    Enum = 6,
    TypeDefinition = 7,
    Untyped = 8,
    Path = 9,
}

export interface EncryptingCredentials {
    alg?: string;
    enc?: string;
    keyExchangePublicKey?: SecurityKey;
    cryptoProviderFactory?: CryptoProviderFactory;
    setDefaultCtyClaim?: boolean;
    key?: SecurityKey;
}

export interface EnsureTeamDto {
    /** @format uuid */
    id?: string;
    /** @format uuid */
    origin?: string;
}

export interface EntireScenarioApprovalDto {
    /** @format uuid */
    scenarioId?: string;
    approvalType?: ScenarioApprovalType;
    state?: ProposalState;
}

export interface EntityDto {
    entity?: EntityType;
}

/** @format int32 */
export enum EntityType {
    Project = 1,
    Resource = 2,
    Contract = 3,
    ContentConfiguration = 9,
    Settings = 10,
    Rule = 11,
    WorkProfile = 12,
    Schedule = 15,
    Integration = 20,
    ContractVersion = 50,
    Scenario = 51,
    Log = 2048,
    DatabaseTable = 4000,
}

export interface EntraGroupMappings {
    administrator?: string[];
    superUser?: string[];
    projectOwner?: string[];
    lineManager?: string[];
    resource?: string[];
}

export interface EntraGroupPermissionSettings {
    enabled?: boolean;
    filter?: string;
    roleToGroupMappings?: EntraGroupMappings;
}

export interface EnvironmentOptions {
    instance?: string;
    tenantId?: string;
    clientId?: string;
    hostFQDN?: string;
}

/** @format int32 */
export enum EnvironmentState {
    Running = 1,
    Deployment = 2,
    Demo = 3,
}

/** @format int32 */
export enum EventAttributes {
    None = 0,
    SpecialName = 512,
    RTSpecialName = 1024,
}

export interface EventInfo {
    name?: string;
    declaringType?: Type;
    reflectedType?: Type;
    module?: Module;
    customAttributes?: CustomAttributeData[];
    isCollectible?: boolean;
    /** @format int32 */
    metadataToken?: number;
    memberType?: MemberTypes;
    attributes?: EventAttributes;
    isSpecialName?: boolean;
    addMethod?: MethodInfo;
    removeMethod?: MethodInfo;
    raiseMethod?: MethodInfo;
    isMulticast?: boolean;
    eventHandlerType?: Type;
}

/** @format int32 */
export enum EventType {
    Project = 0,
    Resource = 1,
    Gantt = 2,
    AzureSync = 3,
}

/** @format int32 */
export enum ExcecutionState {
    Unknown = 0,
    Success = 1,
    Failed = 2,
}

/** @format int32 */
export enum FieldAttributes {
    PrivateScope = 0,
    Private = 1,
    FamANDAssem = 2,
    Assembly = 3,
    Family = 4,
    FamORAssem = 5,
    Public = 6,
    FieldAccessMask = 7,
    Static = 16,
    InitOnly = 32,
    Literal = 64,
    NotSerialized = 128,
    HasFieldRVA = 256,
    SpecialName = 512,
    RTSpecialName = 1024,
    HasFieldMarshal = 4096,
    PinvokeImpl = 8192,
    HasDefault = 32768,
    ReservedMask = 38144,
}

export interface FieldInfo {
    name?: string;
    declaringType?: Type;
    reflectedType?: Type;
    module?: Module;
    customAttributes?: CustomAttributeData[];
    isCollectible?: boolean;
    /** @format int32 */
    metadataToken?: number;
    memberType?: MemberTypes;
    attributes?: FieldAttributes;
    fieldType?: Type;
    isInitOnly?: boolean;
    isLiteral?: boolean;
    isNotSerialized?: boolean;
    isPinvokeImpl?: boolean;
    isSpecialName?: boolean;
    isStatic?: boolean;
    isAssembly?: boolean;
    isFamily?: boolean;
    isFamilyAndAssembly?: boolean;
    isFamilyOrAssembly?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    fieldHandle?: RuntimeFieldHandle;
}

export interface FilterClause {
    expression?: SingleValueNode;
    rangeVariable?: RangeVariable;
    itemType?: IEdmTypeReference;
}

export interface FilterQueryOption {
    context?: ODataQueryContext;
    validator?: FilterQueryValidator;
    compute?: ComputeQueryOption;
    filterClause?: FilterClause;
    rawValue?: string;
}

export type FilterQueryValidator = object;

/** @format int32 */
export enum FilterType {
    Simple = 0,
    Advanced = 1,
}

export interface FireIntegrationsDto {
    /** @format uuid */
    lockId?: string;
    /** @format date-time */
    timeStamp?: Date;
    dto?: EntityDto;
    pluginName?: string;
    logProperties?: Record<string, string>;
    sendNotificationToUser?: boolean;
    notificationText?: string;
    currentUser?: string;
}

export interface ForwardFullRequestDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    targetResourceId?: string;
    resolution?: DateResolution;
}

export interface ForwardRequestDto {
    row?: TopRow;
    resolution?: DateResolution;
}

export interface ForwardRequestViewDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    fromResourceId?: string;
    /** @format uuid */
    toResourceId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    dateResolution?: DateResolution;
}

export interface FreezePeriod {
    resolution?: DateResolution;
    /** @format int32 */
    number?: number;
    freezeBeforeTodayOnly?: boolean;
}

export interface GanttDto {
    /** @format uuid */
    ganttTaskId?: string;
    /** @format date-time */
    startDate?: Date;
    /** @format date-time */
    endDate?: Date;
    isTPActivity?: boolean;
}

/** @format int32 */
export enum GenericParameterAttributes {
    None = 0,
    Covariant = 1,
    Contravariant = 2,
    VarianceMask = 3,
    ReferenceTypeConstraint = 4,
    NotNullableValueTypeConstraint = 8,
    DefaultConstructorConstraint = 16,
    SpecialConstraintMask = 28,
}

export interface GetActivitiesGroupedResult {
    /** @format uuid */
    contractId?: string;
    contractName?: string;
    request?: ActivityGroup;
    allocations?: ActivityGroup[];
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface GetContractChangesForResourceDto {
    /** @format uuid */
    templateId?: string;
    /** @format date-time */
    fakeToday?: Date;
}

export interface GetDraftProjectCapacityViewDto {
    /** @format uuid */
    projectId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    dateResolution?: DateResolution;
    gridType?: GridType;
    groupBy?: string;
    commentsRead?: Record<string, Record<string, Date>>;
    /** @format uuid */
    scenarioId?: string;
    scenarioName?: string;
}

export interface GetDto {
    entity?: EntityType;
    /** @format uuid */
    id?: string;
}

export interface GetEntityDetailsDto {
    /** @format uuid */
    entityId?: string;
    entityType?: EntityType;
    overrideSelectedProperties?: string[];
}

export interface GetFreeCapacityViewDto {
    /** @format uuid */
    resourceId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    dateResolution?: DateResolution;
    readOnly?: boolean;
}

export interface GetGetCommentsDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
}

export interface GetLMCapacityViewDto {
    projectIds?: string[];
    resourceIds?: string[];
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    dateResolution?: DateResolution;
}

export interface GetMultipleDto {
    entity?: EntityType;
    ids?: string[];
}

export interface GetNewCapacityRowDto {
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    dateResolution?: DateResolution;
    gridType?: GridType;
    groupBy?: string;
    /** @format uuid */
    versionedContractId?: string;
    /** @format uuid */
    scenarioId?: string;
}

export interface GetProjectAllocationsDto {
    /**
     * Project id
     * The id of the project
     * @format uuid
     */
    projectId: string;
    resolution: DateResolution;
    /**
     * Start
     * The start of the date range used
     * @format date-time
     */
    start: Date;
    /**
     * Start
     * The end of the date range used
     * @format date-time
     */
    end: Date;
}

export interface GetProjectGridDto {
    /** @format uuid */
    projectId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    dateResolution?: DateResolution;
    gridType?: GridType;
    groupBy?: string;
    commentsRead?: Record<string, Record<string, Date>>;
}

export interface GetProjectsForListDto {
    selectAll?: boolean;
    gridType?: GridType;
    resolution?: DateResolution;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface GetProjectsForOverviewDto {
    onlyActive?: boolean;
    filter?: QueryGroup;
}

export interface GetResourceAllocationsDto {
    /**
     * Resource
     * If only needed for a single resource. If this isn't filled out, all resources will be handed.
     * @format uuid
     */
    resourceId?: string;
    unitType: UnitType;
    resolution: DateResolution;
    /**
     * Start
     * When to get allocations from
     * @format date-time
     */
    start: Date;
    /**
     * End
     * When to get allocations to
     * @format date-time
     */
    end: Date;
}

export interface GetResourcesForListDto {
    selectAll?: boolean;
    resourceTypes?: ResourceType[];
    delegationMode?: boolean;
    genericResources?: string[];
    matchRbs?: boolean;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    gridType?: GridType;
}

export interface GetResourcesForOverviewDto {
    activeFilter?: ActiveFilter;
    filter?: QueryGroup;
}

export interface GetScenarioResult {
    scenario?: Scenario;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface GetScenariosDto {
    scenarioType?: ScenarioType;
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    ownerId?: string;
    states?: ProposalState[];
    /** @format uuid */
    portfolioScenarioId?: string;
    gridType?: GridType;
    filterOnRbs?: boolean;
}

export interface GetVersionedProjectGridDto {
    /** @format uuid */
    projectId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    dateResolution?: DateResolution;
    gridType?: GridType;
    groupBy?: string;
    commentsRead?: Record<string, Record<string, Date>>;
    scenarioIds?: string[];
}

export interface GetWorkPackageViewDto {
    /** @format uuid */
    workPackageId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    groupBy?: string;
}

export interface GlimmerConfiguration {
    userTypes?: UserType[];
    gridTypes?: GridType[];
}

export interface GlobalPlannerGridSettings {
    heatMapClassicColors?: boolean;
    /** @format int32 */
    heatMapOverAllocation?: number;
    /** @format int32 */
    heatMapUnderAllocation?: number;
    /** @format int32 */
    heatMapColorSaturation?: number;
    /** @format int32 */
    heatMapLightness?: number;
    /** @format int32 */
    heatMapColorOffset?: number;
    heatMapInvertSpectrum?: boolean;
    heatMapTextColor?: string;
    overAllocationColor?: string;
    equalAllocationColor?: string;
    underAllocationColor?: string;
}

export interface GlobalPlannerSettings {
    gridSettings?: GlobalPlannerGridSettings;
}

export interface GraphGroup {
    type?: string;
    /** @format uuid */
    id?: string;
    description?: string;
    displayName?: string;
    groupTypes?: string[];
    email?: string;
    visibility?: string;
    resourceProvisioningOptions?: string[];
    members?: GraphUser[];
    owners?: GraphUser[];
}

export interface GraphGroupMinified {
    /** @format uuid */
    id?: string;
    description?: string;
    displayName?: string;
}

export interface GraphUser {
    type?: string;
    displayName?: string;
    givenName?: string;
    jobTitle?: string;
    email?: string;
    mobilePhone?: string;
    officeLocation?: string;
    preferredLanguage?: string;
    surname?: string;
    userPrincipalName?: string;
    accountEnabled?: boolean;
    /** @format uuid */
    id?: string;
}

export interface GridActionOptions {
    matchRequestDefaultTicked?: boolean;
    createMatchingRequestDefaultTicked?: boolean;
    forwardRequestFullDefaultTicked?: boolean;
}

export interface GridCell {
    /** @format uuid */
    id: string;
    /** @format double */
    value?: number;
    disabled?: boolean;
}

export interface GridColumnRow {
    /** @format uuid */
    id: string;
    /** @format date-time */
    start: Date;
    /** @format date-time */
    end: Date;
    cells: GridCell[];
    metadata?: Record<string, any>;
}

export interface GridData {
    /** @format uuid */
    id: string;
    name?: string;
    metadata?: Record<string, any>;
    rows: GridRow[];
    headers: GridHeaderRow[];
}

export interface GridHeaderRow {
    /** @format uuid */
    id: string;
    topic?: string;
    /** @minLength 1 */
    name: string;
    /** @format date-time */
    start: Date;
    /** @format date-time */
    end: Date;
    subHeaderRows?: GridSubHeaderRow[];
}

export interface GridRow {
    /** @format uuid */
    id: string;
    /** @minLength 1 */
    name: string;
    /** @format uuid */
    parentRowId?: string;
    metadata?: Record<string, any>;
    identity?: GridRowIdentity;
    columnRows: GridColumnRow[];
    totalColumnRows?: GridTotalColumnRow[];
    customColumnData?: Record<string, TypedValue>;
}

export interface GridRowIdentity {
    /** @format uuid */
    id: string;
    entityType?: EntityType;
}

export interface GridSubHeaderRow {
    /** @minLength 1 */
    name: string;
}

export interface GridTotalColumnRow {
    /** @format uuid */
    id: string;
    cells: GridCell[];
    metadata?: Record<string, any>;
}

/** @format int32 */
export enum GridType {
    Request = 1,
    Allocation = 2,
    ResourceCapacity = 4,
    LmCapacityProjects = 5,
    ResourcePlanner = 6,
    WorkPackage = 7,
    ProjectPlanner = 8,
}

export interface GridView {
    /** @format uuid */
    id: string;
    /** @minLength 1 */
    name: string;
    description?: string;
    filters: GridViewFilters;
    type?: GridViewType;
    filterType: FilterType;
}

export interface GridViewContainer {
    resourcePlannerViews?: GridView[];
    projectPlannerViews?: GridView[];
}

export interface GridViewFilters {
    resources?: QueryGroup;
    projects?: QueryGroup;
    contracts?: QueryGroup;
}

/** @format int32 */
export enum GridViewType {
    Global = 0,
    User = 1,
}

/** @format int32 */
export enum HandleNullPropagationOption {
    Default = 0,
    True = 1,
    False = 2,
}

export interface HeaderRow {
    topic?: string;
    name?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    isFrozen?: boolean;
    type?: ColumnType;
    /** @format uuid */
    scenarioId?: string;
    subHeaderRows?: SubHeaderRow[];
}

export interface Holiday {
    name?: string;
    /** @format date-time */
    date?: Date;
    type?: string;
    country?: string;
}

export interface HostString {
    value?: string;
    hasValue?: boolean;
    host?: string;
    /** @format int32 */
    port?: number;
}

export interface HttpContext {
    features?: TypeObjectKeyValuePair[];
    request?: HttpRequest;
    response?: HttpResponse;
    connection?: ConnectionInfo;
    webSockets?: WebSocketManager;
    user?: ClaimsPrincipal;
    items?: Record<string, any>;
    requestServices?: IServiceProvider;
    requestAborted?: CancellationToken;
    traceIdentifier?: string;
    session?: ISession;
}

export interface HttpRequest {
    httpContext?: HttpContext;
    method?: string;
    scheme?: string;
    isHttps?: boolean;
    host?: HostString;
    pathBase?: PathString;
    path?: PathString;
    queryString?: QueryString;
    query?: StringStringValuesKeyValuePair[];
    protocol?: string;
    headers?: Record<string, string[]>;
    cookies?: StringStringKeyValuePair[];
    /** @format int64 */
    contentLength?: number;
    contentType?: string;
    body?: Stream;
    bodyReader?: PipeReader;
    hasFormContentType?: boolean;
    form?: StringStringValuesKeyValuePair[];
    routeValues?: Record<string, any>;
}

export interface HttpResponse {
    httpContext?: HttpContext;
    /** @format int32 */
    statusCode?: number;
    headers?: Record<string, string[]>;
    body?: Stream;
    bodyWriter?: PipeWriter;
    /** @format int64 */
    contentLength?: number;
    contentType?: string;
    cookies?: IResponseCookies;
    hasStarted?: boolean;
}

export type ICryptoProvider = object;

export type ICustomAttributeProvider = object;

export type IEdmDirectValueAnnotationsManager = object;

export interface IEdmEntityContainer {
    elements?: IEdmEntityContainerElement[];
    schemaElementKind?: EdmSchemaElementKind;
    namespace?: string;
    name?: string;
}

export interface IEdmEntityContainerElement {
    containerElementKind?: EdmContainerElementKind;
    container?: IEdmEntityContainer;
    name?: string;
}

export interface IEdmExpression {
    expressionKind?: EdmExpressionKind;
}

export interface IEdmModel {
    schemaElements?: IEdmSchemaElement[];
    vocabularyAnnotations?: IEdmVocabularyAnnotation[];
    referencedModels?: IEdmModel[];
    declaredNamespaces?: string[];
    directValueAnnotationsManager?: IEdmDirectValueAnnotationsManager;
    entityContainer?: IEdmEntityContainer;
}

export interface IEdmNavigationProperty {
    partner?: IEdmNavigationProperty;
    onDelete?: EdmOnDeleteAction;
    containsTarget?: boolean;
    referentialConstraint?: IEdmReferentialConstraint;
    propertyKind?: EdmPropertyKind;
    type?: IEdmTypeReference;
    declaringType?: IEdmStructuredType;
    name?: string;
}

export interface IEdmNavigationPropertyBinding {
    navigationProperty?: IEdmNavigationProperty;
    target?: IEdmNavigationSource;
    path?: IEdmPathExpression;
}

export interface IEdmNavigationSource {
    navigationPropertyBindings?: IEdmNavigationPropertyBinding[];
    path?: IEdmPathExpression;
    type?: IEdmType;
    name?: string;
}

export interface IEdmPathExpression {
    pathSegments?: string[];
    path?: string;
    expressionKind?: EdmExpressionKind;
}

export interface IEdmProperty {
    propertyKind?: EdmPropertyKind;
    type?: IEdmTypeReference;
    declaringType?: IEdmStructuredType;
    name?: string;
}

export interface IEdmReferentialConstraint {
    propertyPairs?: EdmReferentialConstraintPropertyPair[];
}

export interface IEdmSchemaElement {
    schemaElementKind?: EdmSchemaElementKind;
    namespace?: string;
    name?: string;
}

export interface IEdmStructuralProperty {
    defaultValueString?: string;
    propertyKind?: EdmPropertyKind;
    type?: IEdmTypeReference;
    declaringType?: IEdmStructuredType;
    name?: string;
}

export interface IEdmStructuredType {
    isAbstract?: boolean;
    isOpen?: boolean;
    baseType?: IEdmStructuredType;
    declaredProperties?: IEdmProperty[];
    typeKind?: EdmTypeKind;
}

export interface IEdmTerm {
    type?: IEdmTypeReference;
    appliesTo?: string;
    defaultValue?: string;
    schemaElementKind?: EdmSchemaElementKind;
    namespace?: string;
    name?: string;
}

export interface IEdmType {
    typeKind?: EdmTypeKind;
}

export interface IEdmTypeReference {
    isNullable?: boolean;
    definition?: IEdmType;
}

export type IEdmVocabularyAnnotatable = object;

export interface IEdmVocabularyAnnotation {
    qualifier?: string;
    term?: IEdmTerm;
    target?: IEdmVocabularyAnnotatable;
    value?: IEdmExpression;
}

export interface IIdentity {
    name?: string;
    authenticationType?: string;
    isAuthenticated?: boolean;
}

export interface IPAddress {
    addressFamily?: AddressFamily;
    /** @format int64 */
    scopeId?: number;
    isIPv6Multicast?: boolean;
    isIPv6LinkLocal?: boolean;
    isIPv6SiteLocal?: boolean;
    isIPv6Teredo?: boolean;
    isIPv6UniqueLocal?: boolean;
    isIPv4MappedToIPv6?: boolean;
    /** @format int64 */
    address?: number;
}

export type IResponseCookies = object;

export interface IScheduleJob {
    /** @format uuid */
    id?: string;
    cronExpression?: string;
    /** @format uuid */
    pluginId?: string;
    pluginName?: string;
    body?: any;
    properties?: Record<string, any>;
    /** @format date-time */
    lastOccurrence?: Date;
    /** @format date-time */
    nextOccurrence?: Date;
    lastRuns?: ScheduleRun[];
}

export type IServiceProvider = object;

export interface ISession {
    isAvailable?: boolean;
    id?: string;
    keys?: string[];
}

export type ITPEntityWithStringVariants = object;

export interface ImportActivitesDto {
    importConfig: ActivityImportConfiguration;
    activityType: WorkType;
    /**
     * SQL connectionstring
     * The connection string for the SQL database
     * @minLength 1
     */
    connectionString: string;
    /**
     * SQL view name
     * The SQL view containing the import items
     * @minLength 1
     */
    sqlView: string;
}

/** @format int32 */
export enum ImportDateHandling {
    RespectFreezePeriod = 0,
    DatesFromData = 1,
    CacheDates = 2,
}

/** @format int32 */
export enum ImportType {
    DeltaLoad = 0,
    FullLoad = 1,
}

export interface ImportWorkPackageDto {
    /** @format uuid */
    projectId?: string;
    workPackageIds?: string[];
    resolution?: DateResolution;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export type IntPtr = object;

export interface IntegrationDto {
    name?: string;
    description?: string;
    activated?: any;
    schema?: string;
    configuration?: any;
}

export interface IntegrationDtoV2 {
    /** @format uuid */
    id?: string;
    type?: IntegrationType;
    name?: string;
    /** @format uuid */
    originId?: string;
    configuration?: Record<string, any>;
    subscribedEvents?: EventType[];
}

export interface IntegrationEventCreateRequest {
    /** @format uuid */
    id?: string;
    name?: string;
    type?: IntegrationType;
    /** @format uuid */
    originId?: string;
    subscribedEvents?: EventType[];
    configuration?: Record<string, any>;
}

/** @format int32 */
export enum IntegrationEventType {
    Upsert = 0,
    Delete = 1,
    Insert = 2,
}

export interface IntegrationEventUpdateRequest {
    /** @format uuid */
    id?: string;
    name?: string;
    /** @format uuid */
    originId?: string;
    subscribedEvents?: EventType[];
    configuration?: Record<string, any>;
}

export interface IntegrationProviderDto {
    name?: string;
    description?: string;
    integrations?: IntegrationDto[];
}

/** @format int32 */
export enum IntegrationType {
    Dataverse = 0,
    AzureADGroupSync = 1,
}

export interface InvokeDefinition {
    /** @format uuid */
    id?: string;
    typeName?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    /** @format uuid */
    pluginId?: string;
    body?: string;
    plugins?: string[];
}

export interface IsContractFullfilledDto {
    /** @format uuid */
    contractId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format uuid */
    status?: string;
}

export interface IsUserInEntraGroupsDto {
    /** @format uuid */
    resourceId?: string;
    groupIds?: string[];
}

export interface JwtSecurityToken {
    actor?: string;
    audiences?: string[];
    claims?: Claim[];
    encodedHeader?: string;
    encodedPayload?: string;
    header?: Record<string, any>;
    id?: string;
    issuer?: string;
    payload?: Record<string, any>;
    innerToken?: JwtSecurityToken;
    rawAuthenticationTag?: string;
    rawCiphertext?: string;
    rawData?: string;
    rawEncryptedKey?: string;
    rawInitializationVector?: string;
    rawHeader?: string;
    rawPayload?: string;
    rawSignature?: string;
    securityKey?: SecurityKey;
    signatureAlgorithm?: string;
    signingCredentials?: SigningCredentials;
    encryptingCredentials?: EncryptingCredentials;
    signingKey?: SecurityKey;
    subject?: string;
    /** @format date-time */
    validFrom?: Date;
    /** @format date-time */
    validTo?: Date;
    /** @format date-time */
    issuedAt?: Date;
}

/** @format int32 */
export enum KeepOption {
    Oldest = 0,
    Newest = 1,
}

export interface KeySizes {
    /** @format int32 */
    minSize?: number;
    /** @format int32 */
    maxSize?: number;
    /** @format int32 */
    skipSize?: number;
}

/** @format int32 */
export enum LayoutKind {
    Sequential = 0,
    Explicit = 2,
    Auto = 3,
}

export interface LineManagerStatusData {
    resourceAllocationSummary?: ResourceAllocationSummary;
    projectPendingRequests?: ProjectPendingRequests[];
    lineManagerStatusTotals?: LineManagerStatusTotals;
    periods?: DateTimeRange[];
}

export interface LineManagerStatusTotals {
    /** @format double */
    pendingRequests?: number;
    /** @format double */
    overallocatedRequests?: number;
    /** @format int32 */
    underallocated?: number;
    /** @format int32 */
    overallocated?: number;
}

export interface LogConfiguration {
    defaultLogLevel?: LogLevel;
    defaultTelemetryLogLevel?: LogLevel;
    logFilters?: LogFilter[];
    excludedTelemetryTypes?: string[];
}

export interface LogFilter {
    namespacePattern?: string;
    logLevel?: LogLevel;
}

/** @format int32 */
export enum LogItemType {
    Default = 0,
    SqlExportError = 1,
}

/** @format int32 */
export enum LogLevel {
    Trace = 0,
    Debug = 1,
    Information = 2,
    Warning = 3,
    Error = 4,
    Critical = 5,
    None = 6,
}

export interface LogSettings {
    severityLevel?: SeverityLevel;
    enablePluginLogging?: boolean;
    excludeCategoryNames?: string[];
}

export interface MailRecipient {
    email?: string;
    name?: string;
}

export interface MailServiceConfig {
    service?: MailServiceIdentifier;
    smtpServer?: string;
    userName?: string;
    password?: string;
    /** @format int32 */
    port?: number;
    enableSsl?: boolean;
    senderEmail?: string;
    senderName?: string;
    apiKey?: string;
    /** @format int32 */
    maxRequestsPrSecond?: number;
    debugLoginName?: string;
    resilienceSettings?: ResilienceSettings;
}

/** @format int32 */
export enum MailServiceIdentifier {
    None = 0,
    Debug = 1,
    Graph = 2,
    Smtp = 4,
    SendGrid = 8,
}

export interface MailTemplate {
    /** @format uuid */
    id?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    name?: string;
    typeName?: string;
    contentConfigurationType?: ContentConfigurationType;
    template?: string;
    subject?: string;
    templateType?: MailTemplateType;
    templateDataType?: MailTemplateDataType;
    config?: MailTemplateConfig;
}

export interface MailTemplateConfig {
    mailTemplateConfigType?: MailTemplateDataType;
}

/** @format int32 */
export enum MailTemplateDataType {
    Unknown = 0,
    ContractChangesNotification = 1,
    OnCommentReceived = 2,
    LineManagerStatus = 3,
}

export interface MailTemplateTesterDto {
    template?: string;
    dataType?: MailTemplateDataType;
}

/** @format int32 */
export enum MailTemplateType {
    Explicit = 0,
    Reference = 1,
}

export interface MaintenanceDto {
    beginTitle?: string;
    beginMessage?: string;
    endTitle?: string;
    endMessage?: string;
    enabled?: boolean;
    dontPersistMessages?: boolean;
}

export interface MatchingActivityDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    resolution?: DateResolution;
    fullAllocation?: boolean;
}

export interface MemberInfo {
    memberType?: MemberTypes;
    name?: string;
    declaringType?: Type;
    reflectedType?: Type;
    module?: Module;
    customAttributes?: CustomAttributeData[];
    isCollectible?: boolean;
    /** @format int32 */
    metadataToken?: number;
}

/** @format int32 */
export enum MemberTypes {
    Constructor = 1,
    Event = 2,
    Field = 4,
    Method = 8,
    Property = 16,
    TypeInfo = 32,
    Custom = 64,
    NestedType = 128,
    All = 191,
}

export interface MergeContractsDto {
    /** @format uuid */
    sourceContractId?: string;
    /** @format uuid */
    targetContractId?: string;
}

/** @format int32 */
export enum MethodAttributes {
    PrivateScope = 0,
    ReuseSlot = 1,
    Private = 2,
    FamANDAssem = 3,
    Assembly = 4,
    Family = 5,
    FamORAssem = 6,
    Public = 7,
    MemberAccessMask = 8,
    UnmanagedExport = 16,
    Static = 32,
    Final = 64,
    Virtual = 128,
    HideBySig = 256,
    NewSlot = 512,
    VtableLayoutMask = 1024,
    CheckAccessOnOverride = 2048,
    Abstract = 4096,
    SpecialName = 8192,
    RTSpecialName = 16384,
    PinvokeImpl = 32768,
    HasSecurity = 53248,
}

export interface MethodBase {
    memberType?: MemberTypes;
    name?: string;
    declaringType?: Type;
    reflectedType?: Type;
    module?: Module;
    customAttributes?: CustomAttributeData[];
    isCollectible?: boolean;
    /** @format int32 */
    metadataToken?: number;
    attributes?: MethodAttributes;
    methodImplementationFlags?: MethodImplAttributes;
    callingConvention?: CallingConventions;
    isAbstract?: boolean;
    isConstructor?: boolean;
    isFinal?: boolean;
    isHideBySig?: boolean;
    isSpecialName?: boolean;
    isStatic?: boolean;
    isVirtual?: boolean;
    isAssembly?: boolean;
    isFamily?: boolean;
    isFamilyAndAssembly?: boolean;
    isFamilyOrAssembly?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    isConstructedGenericMethod?: boolean;
    isGenericMethod?: boolean;
    isGenericMethodDefinition?: boolean;
    containsGenericParameters?: boolean;
    methodHandle?: RuntimeMethodHandle;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
}

/** @format int32 */
export enum MethodImplAttributes {
    IL = 0,
    Managed = 1,
    Native = 2,
    OPTIL = 3,
    CodeTypeMask = 4,
    Runtime = 8,
    ManagedMask = 16,
    Unmanaged = 32,
    NoInlining = 64,
    ForwardRef = 128,
    Synchronized = 256,
    NoOptimization = 512,
    PreserveSig = 4096,
    AggressiveInlining = 65535,
}

export interface MethodInfo {
    name?: string;
    declaringType?: Type;
    reflectedType?: Type;
    module?: Module;
    customAttributes?: CustomAttributeData[];
    isCollectible?: boolean;
    /** @format int32 */
    metadataToken?: number;
    attributes?: MethodAttributes;
    methodImplementationFlags?: MethodImplAttributes;
    callingConvention?: CallingConventions;
    isAbstract?: boolean;
    isConstructor?: boolean;
    isFinal?: boolean;
    isHideBySig?: boolean;
    isSpecialName?: boolean;
    isStatic?: boolean;
    isVirtual?: boolean;
    isAssembly?: boolean;
    isFamily?: boolean;
    isFamilyAndAssembly?: boolean;
    isFamilyOrAssembly?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    isConstructedGenericMethod?: boolean;
    isGenericMethod?: boolean;
    isGenericMethodDefinition?: boolean;
    containsGenericParameters?: boolean;
    methodHandle?: RuntimeMethodHandle;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    memberType?: MemberTypes;
    returnParameter?: ParameterInfo;
    returnType?: Type;
    returnTypeCustomAttributes?: ICustomAttributeProvider;
}

export interface MigrationDto {
    name?: string;
    description?: string;
    entityType?: MigrationEntityType;
    clear?: boolean;
    schema?: string;
    configuration?: any;
}

/** @format int32 */
export enum MigrationEntityType {
    Project = 1,
    Resource = 2,
    Contract = 3,
    ResourceManager = 4,
    ContentConfiguration = 5,
}

export interface Module {
    assembly?: Assembly;
    fullyQualifiedName?: string;
    name?: string;
    /** @format int32 */
    mdStreamVersion?: number;
    /** @format uuid */
    moduleVersionId?: string;
    scopeName?: string;
    moduleHandle?: ModuleHandle;
    customAttributes?: CustomAttributeData[];
    /** @format int32 */
    metadataToken?: number;
}

export interface ModuleHandle {
    /** @format int32 */
    mdStreamVersion?: number;
}

export interface MonitoringAndAnalyticsConfig {
    enableFrontendLogging?: boolean;
    enableRUM?: boolean;
}

export interface MoveAllAllocationsDto {
    /** @format uuid */
    sourceResourceId?: string;
    /** @format uuid */
    targetResourceId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface MoveAllocationDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    sourceResourceId?: string;
    /** @format uuid */
    targetResourceId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    resolution?: DateResolution;
}

export interface MoveContractDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    projectId?: string;
    includeCustomProperties?: string[];
}

export interface MoveRequestDto {
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
    /** @format date-time */
    fromStart?: Date;
    /** @format date-time */
    fromEnd?: Date;
    /** @format date-time */
    toStart?: Date;
    /** @format date-time */
    toEnd?: Date;
    /** @format int32 */
    value?: number;
    valueType?: MoveRequestValueType;
    resolution?: DateResolution;
}

/** @format int32 */
export enum MoveRequestValueType {
    Hours = 1,
    Percentage = 2,
}

export interface NotificationDto {
    t?: string;
    m?: string;
    is?: boolean;
    ib?: boolean;
    ad?: boolean;
    f?: boolean;
    ty?: NotificationType;
    eu?: boolean;
    u?: string[];
    /** @format date-time */
    ts?: Date;
    c?: boolean;
}

/** @format int32 */
export enum NotificationType {
    Info = 0,
    Error = 1,
    Blocked = 2,
    SevereWarning = 3,
    Success = 4,
    Warning = 5,
}

export interface ODataPathSegment {
    edmType?: IEdmType;
    identifier?: string;
}

export interface ODataQueryContext {
    defaultQuerySettings?: DefaultQuerySettings;
    model?: IEdmModel;
    elementType?: IEdmType;
    navigationSource?: IEdmNavigationSource;
    elementClrType?: Type;
    path?: ODataPathSegment[];
    requestContainer?: IServiceProvider;
}

export type ODataQueryValidator = object;

export interface ODataRawQueryOptions {
    filter?: string;
    apply?: string;
    compute?: string;
    search?: string;
    orderBy?: string;
    top?: string;
    skip?: string;
    select?: string;
    expand?: string;
    count?: string;
    format?: string;
    skipToken?: string;
    deltaToken?: string;
}

export interface Oid {
    value?: string;
    friendlyName?: string;
}

export interface OrderByClause {
    thenBy?: OrderByClause;
    expression?: SingleValueNode;
    direction?: OrderByDirection;
    rangeVariable?: RangeVariable;
    itemType?: IEdmTypeReference;
}

/** @format int32 */
export enum OrderByDirection {
    Ascending = 0,
    Descending = 1,
}

export interface OrderByNode {
    direction?: OrderByDirection;
}

export interface OrderByQueryOption {
    context?: ODataQueryContext;
    orderByNodes?: OrderByNode[];
    rawValue?: string;
    validator?: OrderByQueryValidator;
    compute?: ComputeQueryOption;
    orderByClause?: OrderByClause;
}

export type OrderByQueryValidator = object;

export interface Origin {
    /** @format uuid */
    id?: string;
    displayName?: string;
    projectIdentifierFieldMapping?: StringStringTuple;
    projectPropertiesAllowed?: string[];
    resourceIdentifierFieldMapping?: StringStringTuple;
    resourcePropertiesAllowed?: string[];
}

/** @format int32 */
export enum OverlappingRuleDecision {
    SmallestWorkWin = 0,
    BiggestWorkWin = 1,
}

/** @format int32 */
export enum OverlappingRulePreferrer {
    None = 0,
    MainWorkProfile = 1,
    SpecificWorkProfile = 2,
}

export interface PFServiceBusConfiguration {
    configurationKey?: string;
    enabled?: boolean;
    connectionString?: string;
    topics?: PFServiceBusTopicOptions[];
    queues?: PFServiceBusQueueOptions[];
}

export interface PFServiceBusConnection {
    path?: string;
    processorOptions?: ServiceBusProcessorOptions;
}

export interface PFServiceBusQueueOptions {
    path?: string;
    processorOptions?: ServiceBusProcessorOptions;
    queueName?: string;
}

export interface PFServiceBusTopicOptions {
    topicName?: string;
    subscriptions?: PFServiceBusConnection[];
}

export interface PORemoteEventConnectionDto {
    username?: string;
    password?: string;
    pwaUrl?: string;
}

/** @format int32 */
export enum ParameterAttributes {
    None = 0,
    In = 1,
    Out = 2,
    Lcid = 4,
    Retval = 8,
    Optional = 16,
    HasDefault = 4096,
    HasFieldMarshal = 8192,
    Reserved3 = 16384,
    Reserved4 = 32768,
    ReservedMask = 61440,
}

export interface ParameterInfo {
    attributes?: ParameterAttributes;
    member?: MemberInfo;
    name?: string;
    parameterType?: Type;
    /** @format int32 */
    position?: number;
    isIn?: boolean;
    isLcid?: boolean;
    isOptional?: boolean;
    isOut?: boolean;
    isRetval?: boolean;
    defaultValue?: any;
    rawDefaultValue?: any;
    hasDefaultValue?: boolean;
    customAttributes?: CustomAttributeData[];
    /** @format int32 */
    metadataToken?: number;
}

export interface PathString {
    value?: string;
    hasValue?: boolean;
}

export interface PeriodCapacity {
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format double */
    capacity?: number;
    /** @format double */
    freeCapacity?: number;
}

export interface PeriodWork {
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format double */
    work?: number;
}

export interface PeriodWorkUnit {
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format double */
    workHours?: number;
    /** @format double */
    workFTE?: number;
    /** @format uuid */
    resourceId?: string;
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    projectId?: string;
}

export interface PermissionSettings {
    entraGroup?: EntraGroupPermissionSettings;
}

export interface PickerFields {
    title?: string;
    secondary?: string;
    tertiary?: string;
    quarternary?: string;
    iconOverride?: string;
}

export type PipeReader = object;

export interface PipeWriter {
    canGetUnflushedBytes?: boolean;
    /** @format int64 */
    unflushedBytes?: number;
}

export interface PluginInformation {
    name?: string;
    id?: string;
    version?: string;
    assembly?: string;
}

export interface PowerPPMIntegrationDto {
    entity?: EntityType;
    isCustom?: boolean;
    eventType?: IntegrationEventType;
    json?: string;
}

export interface PowerPPMRemoteEventConnectionDto {
    envUrl?: string;
}

export interface PowerPPMSBIntegrationDto {
    entity?: EntityType;
    isCustom?: boolean;
    eventType?: IntegrationEventType;
    json?: string;
}

export interface PowerPpmHeatmapRow {
    type?: PowerPpmHeatmapRowType;
    /**
     * Row id
     * The id of the row (generated based on a hash of metadata)
     * @format uuid
     */
    rowId?: string;
    /**
     * Id
     * The id of the related type (WorkPackage, RequestedResource, AllocatedResource)
     * @format uuid
     */
    id?: string;
    /**
     * Name
     * The id of the related type (WorkPackage, RequestedResource, AllocatedResource)
     */
    name?: string;
    /**
     * Parent row id
     * The id of the parent row. Matches RowId
     * @format uuid
     */
    parentRow?: string;
    /**
     * Allocations
     * The resource allocations (Key is 'start date' value is 'work')
     */
    allocations?: Record<string, number>;
    metadata?: any;
}

/** @format int32 */
export enum PowerPpmHeatmapRowType {
    WorkPackage = 0,
    RequestedResource = 1,
    AllocatedResource = 2,
}

export interface PowerPpmWorkPackage {
    /** @format uuid */
    id?: string;
    name?: string;
}

export interface ProblemDetails {
    type?: string;
    title?: string;
    /** @format int32 */
    status?: number;
    detail?: string;
    instance?: string;
    [key: string]: any;
}

export interface Project {
    typeName?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    origins?: string[];
    /** @format uuid */
    parentId?: string;
    name?: string;
    isActive?: boolean;
    /** @format uuid */
    owner?: string;
    additionalOwners?: string[];
    /** @format uuid */
    additionalOwnersGroup?: string;
    customProperties?: Record<string, any>;
    url?: string;
    /** @format uuid */
    rbs?: string;
    additionalRBS?: string[];
    /** @format uuid */
    id?: string;
    /** @format uuid */
    teamResource?: string;
    projectType?: ProjectType;
    originIds?: Record<string, string>;
    activityDistributionConfig?: ActivityDistributionConfig;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface ProjectChange {
    /** @format uuid */
    projectId?: string;
    projectName?: string;
    projectUrl?: string;
    projectOwner?: string;
    contracts?: ContractChange[];
}

export interface ProjectETag {
    isWellFormed?: boolean;
    entityType?: Type;
    isAny?: boolean;
    isIfNoneMatch?: boolean;
}

export interface ProjectListItem {
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    origins?: string[];
    /** @format uuid */
    parentId?: string;
    name?: string;
    isActive?: boolean;
    /** @format uuid */
    owner?: string;
    additionalOwners?: string[];
    /** @format uuid */
    additionalOwnersGroup?: string;
    customProperties?: Record<string, any>;
    url?: string;
    /** @format uuid */
    rbs?: string;
    additionalRBS?: string[];
    /** @format uuid */
    id?: string;
    /** @format uuid */
    teamResource?: string;
    projectType?: ProjectType;
    originIds?: Record<string, string>;
    activityDistributionConfig?: ActivityDistributionConfig;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    typeName?: string;
    originsAsString?: string;
    parentIdAsString?: string;
    isActiveAsString?: string;
    ownerAsString?: string;
    additionalOwnersAsString?: string;
    additionalOwnersGroupAsString?: string;
    customPropertiesAsString?: Record<string, string>;
    rbsAsString?: string;
    teamResourceAsString?: string;
    projectTypeAsString?: string;
    autoAllocationConstraintsAsString?: string;
    nameAsString?: string;
    urlAsString?: string;
    additionalRBSAsString?: string;
    startAsString?: string;
    endAsString?: string;
    favourite?: boolean;
    /** @format int32 */
    requestsFulfilled?: number;
    /** @format int32 */
    requestsFulfilledWithCategory?: number;
    /** @format date-time */
    firstUnfullfilled?: Date;
    /** @format date-time */
    lastModifiedByOthers?: Date;
    alignStatus?: AlignContractStatus;
    contractStatuses?: string[];
    scenariosAvailable?: boolean;
}

export interface ProjectMinifiedWithStringVariants {
    /** @format uuid */
    id?: string;
    name?: string;
    isActive?: boolean;
    /** @format uuid */
    owner?: string;
    /** @format uuid */
    rbs?: string;
    projectType?: ProjectType;
    customProperties?: Record<string, any>;
    ownerName?: string;
    isActiveAsString?: string;
    ownerAsString?: string;
    customPropertiesAsString?: Record<string, string>;
    rbsAsString?: string;
    projectTypeAsString?: string;
}

export interface ProjectODataQueryOptions {
    request?: HttpRequest;
    context?: ODataQueryContext;
    rawValues?: ODataRawQueryOptions;
    selectExpand?: SelectExpandQueryOption;
    apply?: ApplyQueryOption;
    compute?: ComputeQueryOption;
    filter?: FilterQueryOption;
    search?: SearchQueryOption;
    orderBy?: OrderByQueryOption;
    skip?: SkipQueryOption;
    skipToken?: SkipTokenQueryOption;
    top?: TopQueryOption;
    count?: CountQueryOption;
    validator?: ODataQueryValidator;
    ifMatch?: ProjectETag;
    ifNoneMatch?: ProjectETag;
}

export interface ProjectPanelLineManagerDto {
    selectAll?: boolean;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface ProjectPendingRequests {
    project?: Project;
    url?: string;
    owner?: string;
    unfulfilledRequests?: Record<string, number>;
    overallocatedRequests?: Record<string, number>;
    /** @format double */
    unfulfilledRequestsTotal?: number;
    /** @format double */
    overallocatedRequestsTotal?: number;
}

export interface ProjectPickerDto {
    projectTypes?: ProjectType[];
    onlyActive?: boolean;
    expandFields?: string[];
}

export interface ProjectPlannerGridDto {
    /** @format date-time */
    start: Date;
    /** @format date-time */
    end: Date;
    dateResolution: DateResolution;
    projectFilter?: QueryGroup;
    resourceFilter?: QueryGroup;
    customColumns?: string[];
}

export interface ProjectPlannerGridSettings {
    heatMapClassicColors?: boolean;
    /** @format int32 */
    heatMapOverAllocation?: number;
    /** @format int32 */
    heatMapUnderAllocation?: number;
    /** @format int32 */
    heatMapColorSaturation?: number;
    /** @format int32 */
    heatMapLightness?: number;
    /** @format int32 */
    heatMapColorOffset?: number;
    heatMapInvertSpectrum?: boolean;
    heatMapTextColor?: string;
    overAllocationColor?: string;
    equalAllocationColor?: string;
    underAllocationColor?: string;
    noActivityColor?: string;
}

export interface ProjectPlannerSettings {
    gridSettings?: ProjectPlannerGridSettings;
    /** @format int32 */
    maxReturnedProjects?: number;
}

/** @format int32 */
export enum ProjectType {
    Default = 0,
    Administrative = 1,
    Team = 2,
}

export interface ProjectWithStringVariants {
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    origins?: string[];
    /** @format uuid */
    parentId?: string;
    name?: string;
    isActive?: boolean;
    /** @format uuid */
    owner?: string;
    additionalOwners?: string[];
    /** @format uuid */
    additionalOwnersGroup?: string;
    customProperties?: Record<string, any>;
    url?: string;
    /** @format uuid */
    rbs?: string;
    additionalRBS?: string[];
    /** @format uuid */
    id?: string;
    /** @format uuid */
    teamResource?: string;
    projectType?: ProjectType;
    originIds?: Record<string, string>;
    activityDistributionConfig?: ActivityDistributionConfig;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    typeName?: string;
    originsAsString?: string;
    parentIdAsString?: string;
    isActiveAsString?: string;
    ownerAsString?: string;
    additionalOwnersAsString?: string;
    additionalOwnersGroupAsString?: string;
    customPropertiesAsString?: Record<string, string>;
    rbsAsString?: string;
    teamResourceAsString?: string;
    projectTypeAsString?: string;
    autoAllocationConstraintsAsString?: string;
    nameAsString?: string;
    urlAsString?: string;
    additionalRBSAsString?: string;
    startAsString?: string;
    endAsString?: string;
}

export interface ProjectumProblemDetails {
    type?: string;
    title?: string;
    /** @format int32 */
    status?: number;
    detail?: string;
    instance?: string;
    stackTrace?: string;
    correlationId?: string;
    [key: string]: any;
}

export interface ProjectumProblemDetailsTemp {
    type?: string;
    title?: string;
    /** @format int32 */
    status?: number;
    detail?: string;
    instance?: string;
    stackTrace?: string;
    correlationId?: string;
    [key: string]: any;
}

/** @format int32 */
export enum PropertyAttributes {
    None = 0,
    SpecialName = 512,
    RTSpecialName = 1024,
    HasDefault = 4096,
    Reserved2 = 8192,
    Reserved3 = 16384,
    Reserved4 = 32768,
    ReservedMask = 62464,
}

export interface PropertyInfo {
    name?: string;
    declaringType?: Type;
    reflectedType?: Type;
    module?: Module;
    customAttributes?: CustomAttributeData[];
    isCollectible?: boolean;
    /** @format int32 */
    metadataToken?: number;
    memberType?: MemberTypes;
    propertyType?: Type;
    attributes?: PropertyAttributes;
    isSpecialName?: boolean;
    canRead?: boolean;
    canWrite?: boolean;
    getMethod?: MethodInfo;
    setMethod?: MethodInfo;
}

/** @format int32 */
export enum ProposalState {
    Published = 0,
    Draft = 1,
    Proposed = 2,
    Rejected = 3,
    Promoted = 4,
    PromotedWithChanges = 5,
    PartlyPromoted = 6,
    ProposedChanged = 7,
    Unknown = 99,
}

export interface PublicKey {
    encodedKeyValue?: AsnEncodedData;
    encodedParameters?: AsnEncodedData;
    key?: AsymmetricAlgorithm;
    oid?: Oid;
}

export interface PublishProfile {
    profileName?: string;
    publishMethod?: string;
    publishUrl?: string;
    username?: string;
    password?: string;
}

export interface PushNotificationAnnouncementDto {
    /** @format uuid */
    id?: string;
    name?: string;
    markdownBlobPath?: string;
    markdown?: string;
    active?: boolean;
    /** @format int32 */
    order?: number;
    /** @format date-time */
    startDate?: Date;
    /** @format date-time */
    endDate?: Date;
    filterUserTypes?: UserType[];
    filterVersions?: string[];
    filterUrls?: string[];
}

export interface QueryDto {
    entity?: EntityType;
    query?: DynamicQuery;
}

export interface QueryGroup {
    typeName?: string;
    parts?: any[];
}

/** @format int32 */
export enum QueryNodeKind {
    None = 0,
    Constant = 1,
    Convert = 2,
    NonResourceRangeVariableReference = 3,
    BinaryOperator = 4,
    UnaryOperator = 5,
    SingleValuePropertyAccess = 6,
    CollectionPropertyAccess = 7,
    SingleValueFunctionCall = 8,
    Any = 9,
    CollectionNavigationNode = 10,
    SingleNavigationNode = 11,
    SingleValueOpenPropertyAccess = 12,
    SingleResourceCast = 13,
    All = 14,
    CollectionResourceCast = 15,
    ResourceRangeVariableReference = 16,
    SingleResourceFunctionCall = 17,
    CollectionFunctionCall = 18,
    CollectionResourceFunctionCall = 19,
    NamedFunctionParameter = 20,
    ParameterAlias = 21,
    EntitySet = 22,
    KeyLookup = 23,
    SearchTerm = 24,
    CollectionOpenPropertyAccess = 25,
    CollectionComplexNode = 26,
    SingleComplexNode = 27,
    Count = 28,
    SingleValueCast = 29,
    CollectionPropertyNode = 30,
    AggregatedCollectionPropertyNode = 31,
    In = 32,
    CollectionConstant = 33,
}

export interface QueryString {
    value?: string;
    hasValue?: boolean;
}

export interface RangeVariable {
    name?: string;
    typeReference?: IEdmTypeReference;
    /** @format int32 */
    kind?: number;
}

export interface Rbs {
    /** @format uuid */
    id?: string;
    fullName?: string;
    name?: string;
    /** @format int32 */
    sortOrder?: number;
    children?: Rbs[];
}

/** @format int32 */
export enum RbsMatchingMode {
    Direct = 0,
    DirectAndBelow = 1,
    DirectAndUpwards = 2,
}

export interface RefreshPwaEntityDto {
    /** @format uri */
    pwaUrl?: string;
    username?: string;
    password?: string;
    additionalRbsField?: string;
    pwaOriginName?: string;
    /** @format uuid */
    referenceGuid?: string;
    useInternalCredentials?: boolean;
    resilienceSettings?: ResilienceSettings;
}

export interface RefreshPwaProjectDto {
    /** @format uri */
    pwaUrl?: string;
    domain?: string;
    username?: string;
    password?: string;
    projectRbsField?: string;
}

export interface RemoteIntegrationDto {
    entity?: EntityType;
    eventType?: IntegrationEventType;
    json?: string;
}

export interface RemoveActivityDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
    resolution?: DateResolution;
    /** @format date-time */
    start?: Date;
    gridType?: GridType;
}

/** @format int32 */
export enum RemoveUnusedGenericResourceAction {
    Get = 0,
    Deactivate = 1,
    Delete = 2,
}

export interface RemoveUnusedGenericResourcesDto {
    action?: RemoveUnusedGenericResourceAction;
    onlyUnRequested?: boolean;
}

/** @format int32 */
export enum RemoveUnusedRbsNodesAction {
    Get = 0,
    Delete = 1,
}

export interface RemoveUnusedRbsNodesDto {
    action?: RemoveUnusedRbsNodesAction;
    selector?: RemoveUnusedRbsNodesSelect;
}

/** @format int32 */
export enum RemoveUnusedRbsNodesSelect {
    MainAndAdditional = 0,
    OnlyMain = 1,
}

export interface RemoveWorkOnAllActivitiesDto {
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format uuid */
    resourceId?: string;
    /** @format uuid */
    secretKey?: string;
}

export interface RemoveWorkPackageActivityDto {
    /** @format uuid */
    workPackageId?: string;
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
    /** @format date-time */
    start?: Date;
}

export interface ReplaceProjectDto {
    /** @format uuid */
    sourceProjectId?: string;
    /** @format uuid */
    sourceProjectOriginId?: string;
    /** @format uuid */
    targetProjectId?: string;
    /** @format uuid */
    targetProjectOriginId?: string;
}

export interface ReplaceResourceDto {
    /** @format uuid */
    sourceResourceId?: string;
    /** @format uuid */
    sourceResourceOriginId?: string;
    /** @format uuid */
    targetResourceId?: string;
    /** @format uuid */
    targetResourceOriginId?: string;
}

export interface Report {
    /** @format uuid */
    id?: string;
    name?: string;
    url?: string;
    openInNewWindow?: boolean;
    userTypes?: UserType[];
    /** @format int32 */
    view?: number;
    filter?: ReportFilter;
}

export interface ReportFilter {
    parts?: any[];
}

export interface Request {
    /** @format uuid */
    id?: string;
    typeName?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    name?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format double */
    work?: number;
    /** @format double */
    workFTE?: number;
    /** @format double */
    workHours?: number;
    /** @format uuid */
    resourceId?: string;
    resolution?: DateResolution;
    unitType?: UnitType;
    sourceAction?: ActivitySourceAction;
    /** @format double */
    unitTypeWork?: number;
    state?: ProposalState;
}

export interface RequestTimephasedDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface ResetContractNamesDto {
    /**
     * Projects
     * Reset contract names on these project id's
     */
    projects?: string[];
    /**
     * Apply to ALL contracts (DANGEROUS)
     * Enabling this will reset names on ALL contracts. (use with caution)
     */
    applyToAllContracts_Dangerous?: boolean;
}

export interface ResetMailFlagForVersionsDto {
    /** @format uuid */
    contractId?: string;
    resolution?: VersionResolution;
}

export interface ResilienceSettings {
    /** @format int32 */
    maxRetries?: number;
    /** @format int32 */
    rateLimitPerMinute?: number;
    /** @format int32 */
    maxParallelization?: number;
}

export interface Resource {
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    typeName?: string;
    origins?: string[];
    /** @format uuid */
    parentId?: string;
    name?: string;
    email?: string;
    /** @format uuid */
    company?: string;
    /** @format uuid */
    settings?: string;
    /** @format uuid */
    rbs?: string;
    active?: boolean;
    /** @format uuid */
    delegatedResourceId?: string;
    customProperties?: Record<string, any>;
    userTypes?: UserType[];
    resourceType?: ResourceType;
    /** @format uuid */
    relatedGenericResourceId?: string;
    /** @format date-time */
    earliestAvailable?: Date;
    /** @format date-time */
    latestAvailable?: Date;
    /** @format double */
    workingPercent?: number;
    /** @format double */
    utilization?: number;
    additionalRBS?: string[];
    favoriteProjects?: string[];
    favoriteResources?: string[];
    /** @format uuid */
    workProfileId?: string;
    enableSpecificWorkprofile?: boolean;
    /** @format uuid */
    specificWorkprofileId?: string;
    preferredUnitType?: UnitType;
    matcher?: QueryGroup;
    excludeFromPools?: boolean;
    /** @format uuid */
    manager?: string;
    /** @format uuid */
    theme?: string;
    /** @format uuid */
    id?: string;
    /** @format uuid */
    azureId?: string;
    /** @format uuid */
    teamProject?: string;
    originIds?: Record<string, string>;
    /** @format date-time */
    lastSignin?: Date;
    userPrincipalName?: string;
}

export interface ResourceAllocationSummary {
    underallocatedResources?: Record<string, Record<string, number>>;
    overallocatedResources?: Record<string, Record<string, number>>;
}

export interface ResourceCapacityTimephasedDto {
    /** @format uuid */
    resourceId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface ResourceCapacityTimephasedResult {
    /** @format uuid */
    resourceId?: string;
    /** @format date-time */
    date?: Date;
    /** @format double */
    capacity?: number;
    /** @format double */
    freeCapacity?: number;
    /** @format double */
    capacityHours?: number;
    /** @format double */
    freeCapacityHours?: number;
    /** @format double */
    capacityFTE?: number;
    /** @format double */
    freeCapacityFTE?: number;
}

export interface ResourceChange {
    resourceName?: string;
    resourceType?: string;
    work?: WorkChange;
    commentsChanged?: boolean;
}

export interface ResourceConstraints {
    /** @format uuid */
    resourceId?: string;
    resourceName?: string;
    /** @format int32 */
    priority?: number;
    /** @format double */
    minimumFreeCapacity?: number;
    /** @format double */
    minimumUtilization?: number;
    /** @format double */
    maximumUtilization?: number;
    /** @format double */
    maximumTotalHours?: number;
    exclude?: boolean;
}

export interface ResourceContractAllocation {
    /** @format uuid */
    resourceId?: string;
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    projectId?: string;
    allocations?: SimpleActivity[];
}

export interface ResourceDistribution {
    resourceTechnique?: ResourceTechnique;
    prefer?: QueryGroup;
    restrict?: QueryGroup;
    resourceOrderTypes?: ResourceOrderTypes[];
    resourceConstraints?: ResourceConstraints[];
    /** @format int32 */
    maxNumberOfResources?: number;
}

export interface ResourceDistributionResult {
    /** @format uuid */
    resourceId?: string;
    resourceName?: string;
    /** @format double */
    allocationSum?: number;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

/** @format int32 */
export enum ResourceFutureActivitiesDeletionScope {
    Api = 0,
    Integrations = 1,
    Both = 2,
    Never = 10,
}

export interface ResourceListItem {
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    origins?: string[];
    /** @format uuid */
    parentId?: string;
    name?: string;
    email?: string;
    /** @format uuid */
    company?: string;
    /** @format uuid */
    settings?: string;
    /** @format uuid */
    rbs?: string;
    active?: boolean;
    /** @format uuid */
    delegatedResourceId?: string;
    customProperties?: Record<string, any>;
    userTypes?: UserType[];
    resourceType?: ResourceType;
    /** @format uuid */
    relatedGenericResourceId?: string;
    /** @format date-time */
    earliestAvailable?: Date;
    /** @format date-time */
    latestAvailable?: Date;
    /** @format double */
    workingPercent?: number;
    /** @format double */
    utilization?: number;
    additionalRBS?: string[];
    favoriteProjects?: string[];
    favoriteResources?: string[];
    /** @format uuid */
    workProfileId?: string;
    enableSpecificWorkprofile?: boolean;
    /** @format uuid */
    specificWorkprofileId?: string;
    preferredUnitType?: UnitType;
    matcher?: QueryGroup;
    excludeFromPools?: boolean;
    /** @format uuid */
    manager?: string;
    /** @format uuid */
    theme?: string;
    /** @format uuid */
    id?: string;
    /** @format uuid */
    azureId?: string;
    /** @format uuid */
    teamProject?: string;
    originIds?: Record<string, string>;
    /** @format date-time */
    lastSignin?: Date;
    userPrincipalName?: string;
    typeName?: string;
    originsAsString?: string;
    parentIdAsString?: string;
    settingsAsString?: string;
    rbsAsString?: string;
    activeAsString?: string;
    customPropertiesAsString?: Record<string, string>;
    userTypesAsString?: string;
    resourceTypeAsString?: string;
    relatedGenericResourceIdAsString?: string;
    additionalRBSAsString?: string;
    workProfileIdAsString?: string;
    enableSpecificWorkprofileAsString?: string;
    teamProjectAsString?: string;
    matcherAsString?: string;
    excludeFromPoolsAsString?: string;
    managerAsString?: string;
    nameAsString?: string;
    emailAsString?: string;
    userPrincipalNameAsString?: string;
    lastSigninAsString?: string;
    earliestAvailableAsString?: string;
    latestAvailableAsString?: string;
    workingPercentAsString?: string;
    utilizationAsString?: string;
    favourite?: boolean;
    /** @format int32 */
    availability?: number;
}

export interface ResourceMinifiedWithStringVariants {
    /** @format uuid */
    id?: string;
    name?: string;
    active?: boolean;
    email?: string;
    /** @format uuid */
    rbs?: string;
    resourceType?: ResourceType;
    customProperties?: Record<string, any>;
    rbsAsString?: string;
    activeAsString?: string;
    resourceTypeAsString?: string;
    customPropertiesAsString?: Record<string, string>;
}

/** @format int32 */
export enum ResourceOrderTypes {
    Priority = 0,
    Prefer = 1,
    MostFreeCapacity = 2,
    AllocatedToProject = 4,
    PreviousAllocatedToProject = 8,
    FutureAllocatedToProject = 16,
}

/** @format int32 */
export enum ResourcePanelAddToGrid {
    DragDrop = 1,
    PlusSign = 2,
    DoubleClick = 4,
}

export interface ResourcePickerDto {
    resourceTypes?: ResourceType[];
    rbsMatch?: boolean;
    onlyActive?: boolean;
    onlyGenericResourcesInUse?: boolean;
    onlyPoolResourcesInUse?: boolean;
}

export interface ResourcePlannerGridDto {
    /** @format date-time */
    start: Date;
    /** @format date-time */
    end: Date;
    dateResolution: DateResolution;
    filter?: QueryGroup;
    customColumns?: string[];
}

export interface ResourcePlannerGridSettings {
    heatMapClassicColors?: boolean;
    /** @format int32 */
    heatMapOverAllocation?: number;
    /** @format int32 */
    heatMapUnderAllocation?: number;
    /** @format int32 */
    heatMapColorSaturation?: number;
    /** @format int32 */
    heatMapLightness?: number;
    /** @format int32 */
    heatMapColorOffset?: number;
    heatMapInvertSpectrum?: boolean;
    heatMapTextColor?: string;
    overAllocationColor?: string;
    equalAllocationColor?: string;
    underAllocationColor?: string;
}

export interface ResourcePlannerSettings {
    gridSettings?: ResourcePlannerGridSettings;
    /** @format int32 */
    maxReturnedResources?: number;
}

/** @format int32 */
export enum ResourceTechnique {
    FillFromTop = 0,
    SpreadOut = 1,
    SpreadOutPreferredFirst = 2,
}

/** @format int32 */
export enum ResourceType {
    Unknown = 0,
    Named = 1,
    Generic = 2,
    Category = 3,
    Team = 4,
    WorkProfile = 5,
    Pool = 6,
}

export interface ResourceWithStringVariants {
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    origins?: string[];
    /** @format uuid */
    parentId?: string;
    name?: string;
    email?: string;
    /** @format uuid */
    company?: string;
    /** @format uuid */
    settings?: string;
    /** @format uuid */
    rbs?: string;
    active?: boolean;
    /** @format uuid */
    delegatedResourceId?: string;
    customProperties?: Record<string, any>;
    userTypes?: UserType[];
    resourceType?: ResourceType;
    /** @format uuid */
    relatedGenericResourceId?: string;
    /** @format date-time */
    earliestAvailable?: Date;
    /** @format date-time */
    latestAvailable?: Date;
    /** @format double */
    workingPercent?: number;
    /** @format double */
    utilization?: number;
    additionalRBS?: string[];
    favoriteProjects?: string[];
    favoriteResources?: string[];
    /** @format uuid */
    workProfileId?: string;
    enableSpecificWorkprofile?: boolean;
    /** @format uuid */
    specificWorkprofileId?: string;
    preferredUnitType?: UnitType;
    matcher?: QueryGroup;
    excludeFromPools?: boolean;
    /** @format uuid */
    manager?: string;
    /** @format uuid */
    theme?: string;
    /** @format uuid */
    id?: string;
    /** @format uuid */
    azureId?: string;
    /** @format uuid */
    teamProject?: string;
    originIds?: Record<string, string>;
    /** @format date-time */
    lastSignin?: Date;
    userPrincipalName?: string;
    typeName?: string;
    originsAsString?: string;
    parentIdAsString?: string;
    settingsAsString?: string;
    rbsAsString?: string;
    activeAsString?: string;
    customPropertiesAsString?: Record<string, string>;
    userTypesAsString?: string;
    resourceTypeAsString?: string;
    relatedGenericResourceIdAsString?: string;
    additionalRBSAsString?: string;
    workProfileIdAsString?: string;
    enableSpecificWorkprofileAsString?: string;
    teamProjectAsString?: string;
    matcherAsString?: string;
    excludeFromPoolsAsString?: string;
    managerAsString?: string;
    nameAsString?: string;
    emailAsString?: string;
    userPrincipalNameAsString?: string;
    lastSigninAsString?: string;
    earliestAvailableAsString?: string;
    latestAvailableAsString?: string;
    workingPercentAsString?: string;
    utilizationAsString?: string;
}

export interface RootSettings {
    /** @format uuid */
    id?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    mergedWith?: string;
    name?: string;
    /** @format uuid */
    workProfileId?: string;
    allowSearchAllProjects?: boolean;
    allowSearchAllResources?: boolean;
    allowChangeRbsAndCustomPropertiesOnGenericResource?: boolean;
    allowedToDelegate?: UserType[];
    rbsRestrictedUsers?: UserType[];
    rbsLevelsBelowAccesibleFor?: UserType[];
    lmCanEditOwnResources?: boolean;
    pmCanEditOwnProjects?: boolean;
    shownOwnProjectsForLM?: boolean;
    glimmerConfiguration?: GlimmerConfiguration;
    allocateOutsideRBS?: boolean;
    adminRbsMatchDirection?: TreeMatchDirection;
    /** @format int32 */
    lightbulbLimitInMonths?: number;
    enableToggleHideGenericResource?: boolean;
    /** @format int32 */
    gridDecimalsFTE?: number;
    /** @format int32 */
    gridDecimalsHours?: number;
    hidePersonaIconsInGrid?: boolean;
    /** @format double */
    gridSpacing?: number;
    gridActionOptions?: GridActionOptions;
    enableAlignContract?: boolean;
    enableForwardRequest?: boolean;
    enableMoveRequest?: boolean;
    enableMoveProjectRequests?: boolean;
    enableCreateMatchingRequest?: boolean;
    enableCreateMatchingAllocation?: boolean;
    enableMoveAllAllocations?: boolean;
    enableMergeContracts?: boolean;
    enableLmCapacityView?: boolean;
    enableGridUndo?: boolean;
    enableCopyFromExcel?: boolean;
    enableRequestNamedResources?: boolean;
    enableFastDelete?: boolean;
    enableSearchNamedResources?: boolean;
    enableContractVersions?: boolean;
    enableMailNotifications?: boolean;
    enableWorkPackages?: boolean;
    enableResourcePools?: boolean;
    enableDynamicResourcePools?: boolean;
    enableCoachmarks?: boolean;
    enableTelemitry?: boolean;
    enableScenarios?: boolean;
    enableResourceCapacityView?: boolean;
    enableFindAvailableCapacity?: boolean;
    enableGridSettings?: boolean;
    enableEmbeddedCharts?: boolean;
    forcePinnedTopbar?: boolean;
    unitType?: UnitType;
    forceUnitTypeOnAllocationView?: UnitType;
    forceUnitTypeOnRequestView?: UnitType;
    resourceFilter?: QueryGroup;
    resourceFilterPM?: QueryGroup;
    resourceFilterLM?: QueryGroup;
    resourceFilterOverview?: QueryGroup;
    resourcePickerGroupBy?: string;
    pmResourcePickerGroupBy?: string;
    lmResourcePickerGroupBy?: string;
    resourcePanelRequestGroupings?: string[];
    resourcePanelAllocationGroupings?: string[];
    projectPanelRequestGroupings?: string[];
    projectPanelAllocationGroupings?: string[];
    hideAlignStatusOnProjectPanel?: boolean;
    resourcePanelAddToGrid?: ResourcePanelAddToGrid[];
    resourcePickerDisplayFields?: PickerFields;
    pmResourcePickerDisplayFields?: PickerFields;
    lmResourcePickerDisplayFields?: PickerFields;
    showOnlyRelevantResourcesInPicker?: boolean;
    resourceInformationProperties?: string[];
    contractInformationProperties?: string[];
    allowedToInactivateResources?: UserType[];
    allowedToSeeContractOverview?: UserType[];
    allowedToDeleteResources?: UserType[];
    allowedToCreateResources?: UserType[];
    allowedToEditResources?: UserType[];
    allowedToSeeNamedResourcesWithoutRbs?: UserType[];
    limitAdditionalOwnerPropertyEditing?: string[];
    resourceOverviewProperties?: string[];
    genericResourceDefinition?: string[];
    autoGenerateGenericResources?: boolean;
    genericResourceNamingConvention?: string;
    allowPlannerViewGenericResourceSelection?: boolean;
    allowPlannerViewRootRbsMatch?: boolean;
    /** @format int32 */
    disableResourcePanelCalculationItemLimit?: number;
    /** @format int32 */
    disableProjectPanelCalculationItemLimit?: number;
    includeContractStatusFilterInProjectPanel?: boolean;
    /** @format uuid */
    placeholderGenericResource?: string;
    defaultActivityDistributionConfig?: ActivityDistributionConfig;
    /** @format int32 */
    mininumFreeCapacityPercentageForAutoAllocation?: number;
    projectPickerDisplayFields?: PickerFields;
    allowedToInactivateProjects?: UserType[];
    allowedToDeleteProjects?: UserType[];
    allowedToCreateProjects?: UserType[];
    allowedToEditProjects?: UserType[];
    projectInformationProperties?: string[];
    projectFilterPM?: QueryGroup;
    projectFilterLM?: QueryGroup;
    projectFilterOverview?: QueryGroup;
    projectsAlwaysShowRBS?: boolean;
    projectOverviewProperties?: string[];
    forceParentOnAdministrativeProjects?: boolean;
    contractDefaultName?: string;
    allowLMsToChangeContractName?: boolean;
    currentPeriodEdit?: AllowEditOfCurrentPeriod;
    freezePeriod?: FreezePeriod;
    autoGroupContractBy?: string;
    autoCreateRequestWhenDirectAllocating?: boolean;
    singletonOnRequests?: boolean;
    enableDynamicContractNames?: boolean;
    restrictOverAllocating?: boolean;
    restrictDirectAllocations?: boolean;
    contractOverviewProperties?: string[];
    scenarioTypesEnabled?: ScenarioType[];
    userTypesAllowedToCreateScenarios?: UserType[];
    userTypesAllowedToApproveScenarios?: UserType[];
    findAvailableCapacityUsingLiveDatePermissions?: UserType[];
    enableGridResolutionMismatchWarning?: boolean;
    /** @format int32 */
    autoHideNotificationsAfter?: number;
    mailNotifications?: string[];
    timelineAllowBackInTime?: boolean;
    allowEditHistoricalData?: boolean;
    /** @format int32 */
    timelineMaxMonthsBackwards?: number;
    /** @format int32 */
    timelineMaxMonthsForward?: number;
    /** @format int32 */
    timelineDayResolutionMaxDaysBackwards?: number;
    /** @format int32 */
    timelineDayResolutionMaxDaysForward?: number;
    allowedToDesignWorkpackages?: UserType[];
    prefixContractNamesWhenGroupByWorkpackageRun?: boolean;
    localization?: string;
    /** @format uuid */
    defaultTheme?: string;
    enableProjectExportRefresh?: boolean;
    startupTasksRun?: string[];
    distributeActivityPartsBasedOnCapacity?: boolean;
    enableResourcePlanner?: boolean;
    resourcePlannerFilterOptions?: string[];
    enableProjectPlanner?: boolean;
    projectPlannerResourceFilterOptions?: string[];
    projectPlannerProjectFilterOptions?: string[];
    gridViews?: GridViewContainer;
    globalPlannerSettings?: GlobalPlannerSettings;
    resourcePlannerSettings?: ResourcePlannerSettings;
    projectPlannerSettings?: ProjectPlannerSettings;
    resourcesCanEditOwnAllocations?: QueryGroup;
    resourcesCanAddAllocations?: QueryGroup;
    custom403Setting?: Custom403Setting;
    overviewExportToExcelEnabled?: boolean;
    enableMoveContract?: boolean;
    moveContractTargetProjectFilter?: QueryGroup;
    projectSelectorDescriptionField?: string;
    projectSelectorExtraSearchFields?: string[];
    typeName?: string;
    settingsType?: SettingsType;
    activeMatchFilter?: boolean;
    applicationMonitoringAndAnalytics?: MonitoringAndAnalyticsConfig;
    displayName?: string;
    /** @format uuid */
    rbsId?: string;
    logLevel?: SeverityLevel;
    logSettings?: LogSettings;
    logConfiguration?: LogConfiguration;
    /** @format date-time */
    nextLicenseLog?: Date;
    rbsTraverseMode?: TraverseMode;
    enableSetUserLastSignin?: boolean;
    overlappingRulePreferrer?: OverlappingRulePreferrer;
    overlappingRuleDecision?: OverlappingRuleDecision;
    resourceCustomProperties?: CustomProperty[];
    autoAlignContractNames?: AutoAlignContractNames;
    autoInactivateResources?: QueryGroup;
    projectCustomProperties?: CustomProperty[];
    autoInactivateProjects?: QueryGroup;
    /** @format int32 */
    contractDurationInMonths?: number;
    resetAlignContractOnAllocationChange?: boolean;
    /** @format uuid */
    overrideAlignContractOnAllocationChange?: string;
    resetAlignContractOnRequestChange?: boolean;
    /** @format uuid */
    overrideAlignContractOnRequestChange?: string;
    alignContractStatuses?: AlignContractStatus[];
    contractCustomProperties?: CustomPropertyWithPermissions[];
    contractVersionResolutions?: VersionResolution[];
    contractVersionRetention?: ContractVersionRetention;
    reports?: Report[];
    sqlExportConnectionString?: string;
    publishingProfiles?: PublishProfile[];
    enableADGroupSecurity?: boolean;
    adGroupMaps?: Record<string, string>;
    enableProjectOwnerADGroup?: boolean;
    projectOwnerADGroupFilter?: string;
    allowedTimelineResolutions?: DateResolution[];
    /** @format int32 */
    removeResourceFromMSTeamsDelay?: number;
    enableResourceSyncToMSTeams?: boolean;
    origins?: Origin[];
    customLocaleStorageUrl?: string;
    cultureInfo?: string;
    useISO8601?: boolean;
    maintenanceInProgress?: boolean;
    maintenanceBeginTitle?: string;
    maintenanceBeginMessage?: string;
    maintenanceEndTitle?: string;
    maintenanceEndMessage?: string;
    cacheResourceConstraint?: QueryGroup;
    cacheProjectConstraint?: QueryGroup;
    cacheContractConstraint?: CacheContractConstraint;
    enabledCharts?: ChartType[];
    subSettingPropertiesAvailable?: string[];
    userSettingPropertiesAvailable?: string[];
    enableHelpSection?: boolean;
    enableResourceOverviewsFilter?: boolean;
    enableProjectOverviewsFilter?: boolean;
    useNewProjectPanelForLineManagers?: boolean;
    categoryResourceSettings?: CategoryResourceSettings;
    permissions?: PermissionSettings;
    enablePushNotificationAnnouncements?: boolean;
    enableResourceWorkingPercentAndUtilization?: boolean;
    deletionScopeUponResourceInactivation?: ResourceFutureActivitiesDeletionScope;
}

export interface Row {
    typeName?: string;
    /** @format uuid */
    id?: string;
    name?: string;
    properties?: Record<string, any>;
    cellRows?: CellRow[];
}

export interface RowCalculationRule {
    target?: any;
    method?: MethodInfo;
}

export type RuleAction = object;

export type RuleFilter = object;

export interface RuleProperties {
    filter?: RuleFilter;
    action?: RuleAction;
    name?: string;
}

export interface RuntimeFieldHandle {
    value?: IntPtr;
}

export interface RuntimeMethodHandle {
    value?: IntPtr;
}

export interface RuntimeTypeHandle {
    value?: IntPtr;
}

export interface SBMessage {
    parameters?: Record<string, any>;
    /** @format uuid */
    pluginId?: string;
    cacheName?: string;
    storageContainer?: string;
    ids?: string[];
}

export interface SafeWaitHandle {
    isClosed?: boolean;
    isInvalid?: boolean;
}

export interface SanitizeContractRequestsDto {
    /**
     * Contracts
     * Sanitize requests on these contracts id's
     */
    contracts?: string[];
    /**
     * Apply to ALL contracts (DANGEROUS)
     * Enabling this will sanitize requests on ALL contracts. (use with caution)
     */
    applyToAllContracts_Dangerous?: boolean;
    /**
     * Only check (don't fix)
     * Enabling this will make the sanitizer only return the contracts with issues, not fix them
     */
    checkOnly?: boolean;
}

export interface SaveWorkPackageCellDto {
    /** @format uuid */
    workPackageId?: string;
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
    contractName?: string;
    contractGroupName?: string;
    resourceName?: string;
    /** @format double */
    work?: number;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface Scenario {
    /** @format uuid */
    id?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    typeName?: string;
    name?: string;
    scenarioType?: ScenarioType;
    state?: ProposalState;
    /** @format uuid */
    ownerId?: string;
}

/** @format int32 */
export enum ScenarioApprovalType {
    None = 0,
    Request = 1,
    Allocation = 2,
    Both = 4,
}

/** @format int32 */
export enum ScenarioType {
    ProjectScenario = 0,
    PortfolioScenario = 1,
}

export interface ScheduleRun {
    /** @format date-time */
    timeStamp?: Date;
    state?: ExcecutionState;
    /** @format double */
    duration?: number;
}

export interface SearchClause {
    expression?: SingleValueNode;
}

export interface SearchQueryOption {
    context?: ODataQueryContext;
    resultClrType?: Type;
    searchClause?: SearchClause;
    rawValue?: string;
}

export interface SecurityKey {
    /** @format int32 */
    keySize?: number;
    keyId?: string;
    cryptoProviderFactory?: CryptoProviderFactory;
}

/** @format int32 */
export enum SecurityRuleSet {
    None = 0,
    Level1 = 1,
    Level2 = 2,
}

export interface SelectExpandClause {
    selectedItems?: SelectItem[];
    allSelected?: boolean;
}

export interface SelectExpandQueryOption {
    context?: ODataQueryContext;
    rawSelect?: string;
    rawExpand?: string;
    compute?: ComputeQueryOption;
    validator?: SelectExpandQueryValidator;
    selectExpandClause?: SelectExpandClause;
    /** @format int32 */
    levelsMaxLiteralExpansionDepth?: number;
}

export type SelectExpandQueryValidator = object;

export type SelectItem = object;

export interface SendMailOptions {
    to?: MailRecipient[];
    subject?: string;
    body?: string;
    /** @format uuid */
    relatedResource?: string;
}

export interface SendTemplateMailOptions {
    to?: MailRecipient[];
    subject?: string;
    body?: string;
    templateType?: MailTemplateType;
    template?: string;
    data?: any;
    /** @format uuid */
    relatedResource?: string;
}

export interface ServiceBusProcessorOptions {
    /** @format int32 */
    prefetchCount?: number;
    receiveMode?: ServiceBusReceiveMode;
    autoCompleteMessages?: boolean;
    /**
     * @format timespan
     * @example "00:00:00"
     */
    maxAutoLockRenewalDuration?: string;
    /** @format int32 */
    maxConcurrentCalls?: number;
    subQueue?: SubQueue;
}

/** @format int32 */
export enum ServiceBusReceiveMode {
    PeekLock = 0,
    ReceiveAndDelete = 1,
}

export interface ServiceEndpointDTO {
    /** @format int32 */
    connectionmode?: number;
    /** @format int32 */
    authtype?: number;
    name?: string;
    /** @format int32 */
    contract?: number;
    /** @format int32 */
    messageformat?: number;
    namespaceaddress?: string;
    solutionnamespace?: string;
    serviceendpointid?: string;
    saskey?: string;
    /** @format int32 */
    userclaim?: number;
    /** @format int32 */
    namespaceformat?: number;
    saskeyname?: string;
    path?: string;
}

export interface ServiceEndpointStepDTO {
    sdkmessageprocessingstepid?: string;
    configuration?: string;
    'eventhandler_serviceendpoint@odata.bind'?: string;
    name?: string;
    /** @format int32 */
    mode?: number;
    /** @format int32 */
    rank?: number;
    'sdkmessageid@odata.bind'?: string;
    'sdkmessagefilterid@odata.bind'?: string;
    /** @format int32 */
    stage?: number;
    asyncautodelete?: boolean;
}

export interface SetContractNamesDto {
    genericResources?: Record<string, string>;
    onlyContractsWithNameAsGenRes?: boolean;
}

/** @format int32 */
export enum SettingsType {
    Root = 0,
    Sub = 1,
    User = 2,
    Backup = 99,
}

/** @format int32 */
export enum SetupIntegrationActionDto {
    Activate = 1,
    Deactivate = 2,
    Activated = 3,
}

export interface SetupIntegrationDto {
    providerName?: string;
    integrationName?: string;
    action?: SetupIntegrationActionDto;
}

/** @format int32 */
export enum SeverityLevel {
    Verbose = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4,
}

export interface SigningCredentials {
    algorithm?: string;
    digest?: string;
    cryptoProviderFactory?: CryptoProviderFactory;
    key?: SecurityKey;
    kid?: string;
}

export interface SimpleActivity {
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format double */
    work?: number;
}

export interface SingleValueNode {
    typeReference?: IEdmTypeReference;
    kind?: QueryNodeKind;
}

export interface SkipQueryOption {
    context?: ODataQueryContext;
    rawValue?: string;
    /** @format int32 */
    value?: number;
    validator?: SkipQueryValidator;
}

export type SkipQueryValidator = object;

export type SkipTokenHandler = object;

export interface SkipTokenQueryOption {
    rawValue?: string;
    context?: ODataQueryContext;
    validator?: SkipTokenQueryValidator;
    handler?: SkipTokenHandler;
}

export type SkipTokenQueryValidator = object;

export interface SqlExportError {
    /** @format uuid */
    id?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    name?: string;
    /** @format date-time */
    timeStamp?: Date;
    typeName?: string;
    logType?: LogItemType;
    entityType?: EntityType;
    /** @format uuid */
    entityId?: string;
    /** @format uuid */
    relationId?: string;
    dtoName?: string;
    dto?: string;
    message?: string;
    stackTrace?: string;
}

export interface SqlExportErrorExpanded {
    /** @format uuid */
    id?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    name?: string;
    /** @format date-time */
    timeStamp?: Date;
    typeName?: string;
    logType?: LogItemType;
    entityType?: EntityType;
    /** @format uuid */
    entityId?: string;
    /** @format uuid */
    relationId?: string;
    dtoName?: string;
    dto?: string;
    message?: string;
    stackTrace?: string;
    type?: string;
    entityName?: string;
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    resourceId?: string;
    projectName?: string;
    resourceName?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
}

export interface SqlExportResyncDto {
    /**
     * Start
     * The start date of the boundary, which the re-sync will keep within (if not set then start of cacheconstraint is used)
     * @format date-time
     */
    start?: Date;
    /**
     * End
     * The end date of the boundary, which the re-sync will keep within (if not set then end of cacheconstraint is used)
     * @format date-time
     */
    end?: Date;
    /**
     * Respect freeze period
     * If checked, the boundary will start at the freeze period end
     */
    respectFreezePeriod?: boolean;
    /**
     * Skip merge of data
     * If checked, the timephased data will only be synced to a temp table. Then it can be investigated and/or merged in manually.
     */
    skipMerge?: boolean;
}

/** @format int32 */
export enum Sticky {
    Off = 0,
    Always = 1,
    ActivitiesBefore = 2,
    ActivitiesAfter = 3,
    ActivitiesBeforeOrAfter = 4,
}

export interface Stream {
    canRead?: boolean;
    canWrite?: boolean;
    canSeek?: boolean;
    canTimeout?: boolean;
    /** @format int64 */
    length?: number;
    /** @format int64 */
    position?: number;
    /** @format int32 */
    readTimeout?: number;
    /** @format int32 */
    writeTimeout?: number;
}

export interface StringStringKeyValuePair {
    key?: string;
    value?: string;
}

export interface StringStringTuple {
    item1?: string;
    item2?: string;
}

export type StringStringValueTuple = object;

export interface StringStringValuesKeyValuePair {
    key?: string;
    value?: string[];
}

export interface StructLayoutAttribute {
    typeId?: any;
    value?: LayoutKind;
}

export interface SubHeaderRow {
    name?: string;
}

/** @format int32 */
export enum SubQueue {
    None = 0,
    DeadLetter = 1,
    TransferDeadLetter = 2,
}

export interface SubSettings {
    /** @format uuid */
    id?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    mergedWith?: string;
    name?: string;
    /** @format uuid */
    workProfileId?: string;
    allowSearchAllProjects?: boolean;
    allowSearchAllResources?: boolean;
    allowChangeRbsAndCustomPropertiesOnGenericResource?: boolean;
    allowedToDelegate?: UserType[];
    rbsRestrictedUsers?: UserType[];
    rbsLevelsBelowAccesibleFor?: UserType[];
    lmCanEditOwnResources?: boolean;
    pmCanEditOwnProjects?: boolean;
    shownOwnProjectsForLM?: boolean;
    glimmerConfiguration?: GlimmerConfiguration;
    allocateOutsideRBS?: boolean;
    adminRbsMatchDirection?: TreeMatchDirection;
    /** @format int32 */
    lightbulbLimitInMonths?: number;
    enableToggleHideGenericResource?: boolean;
    /** @format int32 */
    gridDecimalsFTE?: number;
    /** @format int32 */
    gridDecimalsHours?: number;
    hidePersonaIconsInGrid?: boolean;
    /** @format double */
    gridSpacing?: number;
    gridActionOptions?: GridActionOptions;
    enableAlignContract?: boolean;
    enableForwardRequest?: boolean;
    enableMoveRequest?: boolean;
    enableMoveProjectRequests?: boolean;
    enableCreateMatchingRequest?: boolean;
    enableCreateMatchingAllocation?: boolean;
    enableMoveAllAllocations?: boolean;
    enableMergeContracts?: boolean;
    enableLmCapacityView?: boolean;
    enableGridUndo?: boolean;
    enableCopyFromExcel?: boolean;
    enableRequestNamedResources?: boolean;
    enableFastDelete?: boolean;
    enableSearchNamedResources?: boolean;
    enableContractVersions?: boolean;
    enableMailNotifications?: boolean;
    enableWorkPackages?: boolean;
    enableResourcePools?: boolean;
    enableDynamicResourcePools?: boolean;
    enableCoachmarks?: boolean;
    enableTelemitry?: boolean;
    enableScenarios?: boolean;
    enableResourceCapacityView?: boolean;
    enableFindAvailableCapacity?: boolean;
    enableGridSettings?: boolean;
    enableEmbeddedCharts?: boolean;
    forcePinnedTopbar?: boolean;
    unitType?: UnitType;
    forceUnitTypeOnAllocationView?: UnitType;
    forceUnitTypeOnRequestView?: UnitType;
    resourceFilter?: QueryGroup;
    resourceFilterPM?: QueryGroup;
    resourceFilterLM?: QueryGroup;
    resourceFilterOverview?: QueryGroup;
    resourcePickerGroupBy?: string;
    pmResourcePickerGroupBy?: string;
    lmResourcePickerGroupBy?: string;
    resourcePanelRequestGroupings?: string[];
    resourcePanelAllocationGroupings?: string[];
    projectPanelRequestGroupings?: string[];
    projectPanelAllocationGroupings?: string[];
    hideAlignStatusOnProjectPanel?: boolean;
    resourcePanelAddToGrid?: ResourcePanelAddToGrid[];
    resourcePickerDisplayFields?: PickerFields;
    pmResourcePickerDisplayFields?: PickerFields;
    lmResourcePickerDisplayFields?: PickerFields;
    showOnlyRelevantResourcesInPicker?: boolean;
    resourceInformationProperties?: string[];
    contractInformationProperties?: string[];
    allowedToInactivateResources?: UserType[];
    allowedToSeeContractOverview?: UserType[];
    allowedToDeleteResources?: UserType[];
    allowedToCreateResources?: UserType[];
    allowedToEditResources?: UserType[];
    allowedToSeeNamedResourcesWithoutRbs?: UserType[];
    limitAdditionalOwnerPropertyEditing?: string[];
    resourceOverviewProperties?: string[];
    genericResourceDefinition?: string[];
    autoGenerateGenericResources?: boolean;
    genericResourceNamingConvention?: string;
    allowPlannerViewGenericResourceSelection?: boolean;
    allowPlannerViewRootRbsMatch?: boolean;
    /** @format int32 */
    disableResourcePanelCalculationItemLimit?: number;
    /** @format int32 */
    disableProjectPanelCalculationItemLimit?: number;
    includeContractStatusFilterInProjectPanel?: boolean;
    /** @format uuid */
    placeholderGenericResource?: string;
    defaultActivityDistributionConfig?: ActivityDistributionConfig;
    /** @format int32 */
    mininumFreeCapacityPercentageForAutoAllocation?: number;
    projectPickerDisplayFields?: PickerFields;
    allowedToInactivateProjects?: UserType[];
    allowedToDeleteProjects?: UserType[];
    allowedToCreateProjects?: UserType[];
    allowedToEditProjects?: UserType[];
    projectInformationProperties?: string[];
    projectFilterPM?: QueryGroup;
    projectFilterLM?: QueryGroup;
    projectFilterOverview?: QueryGroup;
    projectsAlwaysShowRBS?: boolean;
    projectOverviewProperties?: string[];
    forceParentOnAdministrativeProjects?: boolean;
    contractDefaultName?: string;
    allowLMsToChangeContractName?: boolean;
    currentPeriodEdit?: AllowEditOfCurrentPeriod;
    freezePeriod?: FreezePeriod;
    autoGroupContractBy?: string;
    autoCreateRequestWhenDirectAllocating?: boolean;
    singletonOnRequests?: boolean;
    enableDynamicContractNames?: boolean;
    restrictOverAllocating?: boolean;
    restrictDirectAllocations?: boolean;
    contractOverviewProperties?: string[];
    scenarioTypesEnabled?: ScenarioType[];
    userTypesAllowedToCreateScenarios?: UserType[];
    userTypesAllowedToApproveScenarios?: UserType[];
    findAvailableCapacityUsingLiveDatePermissions?: UserType[];
    enableGridResolutionMismatchWarning?: boolean;
    /** @format int32 */
    autoHideNotificationsAfter?: number;
    mailNotifications?: string[];
    timelineAllowBackInTime?: boolean;
    allowEditHistoricalData?: boolean;
    /** @format int32 */
    timelineMaxMonthsBackwards?: number;
    /** @format int32 */
    timelineMaxMonthsForward?: number;
    /** @format int32 */
    timelineDayResolutionMaxDaysBackwards?: number;
    /** @format int32 */
    timelineDayResolutionMaxDaysForward?: number;
    allowedToDesignWorkpackages?: UserType[];
    prefixContractNamesWhenGroupByWorkpackageRun?: boolean;
    localization?: string;
    /** @format uuid */
    defaultTheme?: string;
    enableProjectExportRefresh?: boolean;
    startupTasksRun?: string[];
    distributeActivityPartsBasedOnCapacity?: boolean;
    enableResourcePlanner?: boolean;
    resourcePlannerFilterOptions?: string[];
    enableProjectPlanner?: boolean;
    projectPlannerResourceFilterOptions?: string[];
    projectPlannerProjectFilterOptions?: string[];
    gridViews?: GridViewContainer;
    globalPlannerSettings?: GlobalPlannerSettings;
    resourcePlannerSettings?: ResourcePlannerSettings;
    projectPlannerSettings?: ProjectPlannerSettings;
    resourcesCanEditOwnAllocations?: QueryGroup;
    resourcesCanAddAllocations?: QueryGroup;
    custom403Setting?: Custom403Setting;
    overviewExportToExcelEnabled?: boolean;
    enableMoveContract?: boolean;
    moveContractTargetProjectFilter?: QueryGroup;
    projectSelectorDescriptionField?: string;
    projectSelectorExtraSearchFields?: string[];
    typeName?: string;
    settingsType?: SettingsType;
    /** @format int32 */
    matchPriority?: number;
    matchFilter?: QueryGroup;
    activeMatchFilter?: boolean;
    overrideSubSettingPropertiesAvailable?: string[];
}

export interface SubscriptionRuleCreation {
    topic?: string;
    subscription?: string;
    ruleName?: string;
    filters?: Record<string, any>;
}

export interface SubscriptionRuleDeletion {
    topic?: string;
    subscription?: string;
    ruleName?: string;
}

export interface TPApplicationInfo {
    /** @format date-time */
    clientSecretExpirationDate?: Date;
}

export interface TPEntity {
    /** @format uuid */
    id?: string;
    typeName?: string;
    /** @format date-time */
    created?: Date;
    /** @format date-time */
    modified?: Date;
    createdBy?: string;
    modifiedBy?: string;
}

export interface TPEntityMinified {
    /** @format uuid */
    id?: string;
    name?: string;
}

export interface TPPluginInformation {
    information?: PluginInformation;
    endpoint?: string;
    requestSchema?: string;
    responseSchema?: string;
}

export interface TPVersion {
    cycle?: string;
    app?: string;
}

export interface TableDto {
    displayName?: string;
    logicalName?: string;
}

export interface TopQueryOption {
    context?: ODataQueryContext;
    rawValue?: string;
    /** @format int32 */
    value?: number;
    validator?: TopQueryValidator;
}

export type TopQueryValidator = object;

export interface TopRow {
    typeName?: string;
    /** @format uuid */
    id?: string;
    name?: string;
    properties?: Record<string, any>;
    cellRows?: CellRow[];
    headers?: HeaderRow[];
    groups?: BaseRow[];
}

export interface TopicRuleContainer {
    subscriptions?: TopicSubscription[];
}

export interface TopicSubscription {
    name?: string;
    rules?: RuleProperties[];
}

export interface TransformationNode {
    kind?: TransformationNodeKind;
}

/** @format int32 */
export enum TransformationNodeKind {
    Aggregate = 0,
    GroupBy = 1,
    Filter = 2,
    Compute = 3,
    Expand = 4,
}

/** @format int32 */
export enum TraverseMode {
    BreadthFirst = 0,
    DepthFirst = 1,
}

/** @format int32 */
export enum TreeMatchDirection {
    Up = 0,
    Down = 1,
}

export interface Type {
    name?: string;
    customAttributes?: CustomAttributeData[];
    isCollectible?: boolean;
    /** @format int32 */
    metadataToken?: number;
    isInterface?: boolean;
    memberType?: MemberTypes;
    namespace?: string;
    assemblyQualifiedName?: string;
    fullName?: string;
    assembly?: Assembly;
    module?: Module;
    isNested?: boolean;
    declaringType?: Type;
    declaringMethod?: MethodBase;
    reflectedType?: Type;
    underlyingSystemType?: Type;
    isTypeDefinition?: boolean;
    isArray?: boolean;
    isByRef?: boolean;
    isPointer?: boolean;
    isConstructedGenericType?: boolean;
    isGenericParameter?: boolean;
    isGenericTypeParameter?: boolean;
    isGenericMethodParameter?: boolean;
    isGenericType?: boolean;
    isGenericTypeDefinition?: boolean;
    isSZArray?: boolean;
    isVariableBoundArray?: boolean;
    isByRefLike?: boolean;
    isFunctionPointer?: boolean;
    isUnmanagedFunctionPointer?: boolean;
    hasElementType?: boolean;
    genericTypeArguments?: Type[];
    /** @format int32 */
    genericParameterPosition?: number;
    genericParameterAttributes?: GenericParameterAttributes;
    attributes?: TypeAttributes;
    isAbstract?: boolean;
    isImport?: boolean;
    isSealed?: boolean;
    isSpecialName?: boolean;
    isClass?: boolean;
    isNestedAssembly?: boolean;
    isNestedFamANDAssem?: boolean;
    isNestedFamily?: boolean;
    isNestedFamORAssem?: boolean;
    isNestedPrivate?: boolean;
    isNestedPublic?: boolean;
    isNotPublic?: boolean;
    isPublic?: boolean;
    isAutoLayout?: boolean;
    isExplicitLayout?: boolean;
    isLayoutSequential?: boolean;
    isAnsiClass?: boolean;
    isAutoClass?: boolean;
    isUnicodeClass?: boolean;
    isCOMObject?: boolean;
    isContextful?: boolean;
    isEnum?: boolean;
    isMarshalByRef?: boolean;
    isPrimitive?: boolean;
    isValueType?: boolean;
    isSignatureType?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    structLayoutAttribute?: StructLayoutAttribute;
    typeInitializer?: ConstructorInfo;
    typeHandle?: RuntimeTypeHandle;
    /** @format uuid */
    guid?: string;
    baseType?: Type;
    isSerializable?: boolean;
    containsGenericParameters?: boolean;
    isVisible?: boolean;
}

/** @format int32 */
export enum TypeAttributes {
    NotPublic = 0,
    AutoLayout = 1,
    AnsiClass = 2,
    Class = 3,
    Public = 4,
    NestedPublic = 5,
    NestedPrivate = 6,
    NestedFamily = 7,
    NestedAssembly = 8,
    NestedFamANDAssem = 16,
    VisibilityMask = 24,
    NestedFamORAssem = 32,
    SequentialLayout = 128,
    ExplicitLayout = 256,
    LayoutMask = 1024,
    Interface = 2048,
    ClassSemanticsMask = 4096,
    Abstract = 8192,
    Sealed = 16384,
    SpecialName = 65536,
    RTSpecialName = 131072,
    Import = 196608,
    Serializable = 262144,
    WindowsRuntime = 264192,
    UnicodeClass = 1048576,
    AutoClass = 12582912,
}

export interface TypeInfo {
    name?: string;
    customAttributes?: CustomAttributeData[];
    isCollectible?: boolean;
    /** @format int32 */
    metadataToken?: number;
    isInterface?: boolean;
    memberType?: MemberTypes;
    namespace?: string;
    assemblyQualifiedName?: string;
    fullName?: string;
    assembly?: Assembly;
    module?: Module;
    isNested?: boolean;
    declaringType?: Type;
    declaringMethod?: MethodBase;
    reflectedType?: Type;
    underlyingSystemType?: Type;
    isTypeDefinition?: boolean;
    isArray?: boolean;
    isByRef?: boolean;
    isPointer?: boolean;
    isConstructedGenericType?: boolean;
    isGenericParameter?: boolean;
    isGenericTypeParameter?: boolean;
    isGenericMethodParameter?: boolean;
    isGenericType?: boolean;
    isGenericTypeDefinition?: boolean;
    isSZArray?: boolean;
    isVariableBoundArray?: boolean;
    isByRefLike?: boolean;
    isFunctionPointer?: boolean;
    isUnmanagedFunctionPointer?: boolean;
    hasElementType?: boolean;
    genericTypeArguments?: Type[];
    /** @format int32 */
    genericParameterPosition?: number;
    genericParameterAttributes?: GenericParameterAttributes;
    attributes?: TypeAttributes;
    isAbstract?: boolean;
    isImport?: boolean;
    isSealed?: boolean;
    isSpecialName?: boolean;
    isClass?: boolean;
    isNestedAssembly?: boolean;
    isNestedFamANDAssem?: boolean;
    isNestedFamily?: boolean;
    isNestedFamORAssem?: boolean;
    isNestedPrivate?: boolean;
    isNestedPublic?: boolean;
    isNotPublic?: boolean;
    isPublic?: boolean;
    isAutoLayout?: boolean;
    isExplicitLayout?: boolean;
    isLayoutSequential?: boolean;
    isAnsiClass?: boolean;
    isAutoClass?: boolean;
    isUnicodeClass?: boolean;
    isCOMObject?: boolean;
    isContextful?: boolean;
    isEnum?: boolean;
    isMarshalByRef?: boolean;
    isPrimitive?: boolean;
    isValueType?: boolean;
    isSignatureType?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    structLayoutAttribute?: StructLayoutAttribute;
    typeInitializer?: ConstructorInfo;
    typeHandle?: RuntimeTypeHandle;
    /** @format uuid */
    guid?: string;
    baseType?: Type;
    isSerializable?: boolean;
    containsGenericParameters?: boolean;
    isVisible?: boolean;
    genericTypeParameters?: Type[];
    declaredConstructors?: ConstructorInfo[];
    declaredEvents?: EventInfo[];
    declaredFields?: FieldInfo[];
    declaredMembers?: MemberInfo[];
    declaredMethods?: MethodInfo[];
    declaredNestedTypes?: TypeInfo[];
    declaredProperties?: PropertyInfo[];
    implementedInterfaces?: Type[];
}

export interface TypeObjectKeyValuePair {
    key?: Type;
    value?: any;
}

export interface TypedValue {
    value?: string;
    type?: string;
}

export interface UIStartupDto {
    user?: UIUser;
    isDelegated?: boolean;
    resource?: Resource;
    loginName?: string;
    version?: TPVersion;
    applicationInfo?: TPApplicationInfo;
    tabTitle?: string;
    environmentState?: EnvironmentState;
    settings?: RootSettings;
    theme?: any;
    locale?: string;
    appServiceArchitecture?: string;
}

export interface UIUser {
    principalName?: string;
    securityToken?: JwtSecurityToken;
    tokenExpires?: string;
}

export interface UnfilfilledContractsDto {
    unitType?: UnitType;
    /** @format uuid */
    rbs?: string;
    /** @format uuid */
    status?: string;
    /** @format uuid */
    contractId?: string;
    resolution?: DateResolution;
}

export interface UnfulfilledContract {
    /** @format uuid */
    id?: string;
    name?: string;
    /** @format uuid */
    projectId?: string;
    projectName?: string;
    /** @format uuid */
    resourceId?: string;
    resourceName?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    requests?: SimpleActivity[];
    /** @format uuid */
    contractStatus?: string;
    /** @format uuid */
    rbs?: string;
}

/** @format int32 */
export enum UnitType {
    Hours = 0,
    FTE = 1,
    BothHoursAsPrimary = 2,
    BothFteAsPrimary = 3,
}

export interface UpdateConfigurationsDto {
    changes?: Record<string, string>;
}

export interface UpdateContractAlignStatusDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    newStatus?: string;
}

export interface UpdateContractCustomPropertiesDto {
    /** @format uuid */
    id?: string;
    customProperties?: Record<string, any>;
}

export interface UpdateContractGroupDto {
    /** @format uuid */
    contractId?: string;
    newName?: string;
}

export interface UpdateFavouritesDto {
    /** @format uuid */
    favouriteProject?: string;
    /** @format uuid */
    favouriteResource?: string;
}

export interface UpdateWorkPackageContractDto {
    /** @format uuid */
    workPackageId?: string;
    contractIds?: string[];
    name?: string;
}

export interface UpgradeController {
    name?: string;
    host?: UpgradeHost;
}

/** @format int32 */
export enum UpgradeCycle {
    Production = 0,
    ReleaseCandidate = 1,
    Beta = 2,
    Alpha = 3,
}

export interface UpgradeDto {
    releaseCycle?: UpgradeCycle;
    latest?: boolean;
    functionPackage?: AppPackageDto;
    apiPackage?: AppPackageDto;
    uiPackage?: AppPackageDto;
    startupMode?: EnvironmentState;
}

export interface UpgradeHost {
    name?: string;
    url?: string;
}

export interface UpgradePackage {
    controllerName?: string;
    package?: AppPackage;
    releaseCycle?: UpgradeCycle;
}

export interface UpgradePackageGroup {
    controllerName?: string;
    packages?: AppPackage[];
    releaseCycle?: UpgradeCycle;
}

export interface UpsertCommentsDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    projectId?: string;
    /** @format uuid */
    resourceId?: string;
    contractName?: string;
    comment?: Comment;
}

export interface UpsertDto {
    entity?: EntityType;
    items?: TPEntity[];
}

export interface UpsertLogLevelDto {
    logLevel?: LogLevel;
}

export interface UpsertTemporaryLogFiltersDto {
    /**
     * @format timespan
     * @example "00:00:00"
     */
    revertAfter?: string;
    defaultLogLevel?: LogLevel;
    logFilters?: LogFilter[];
    defaultTelemetryLogLevel?: LogLevel;
    excludedTelemetryTypes?: string[];
}

export interface UpsertWorkpackageCommentDto {
    /** @format uuid */
    workPackageId?: string;
    /** @format uuid */
    contractId?: string;
    comment?: Comment;
}

/** @format int32 */
export enum UserType {
    ProjectOwner = 1,
    LineManager = 2,
    SuperUser = 3,
    Administrator = 4,
    Resource = 5,
}

export interface UserTypeSanitizerDto {
    lineManager?: string;
    projectOwner?: string;
    resource?: string;
    superUser?: string;
}

/** @format int32 */
export enum VersionResolution {
    None = 0,
    Day = 1,
    Week = 2,
    Month = 3,
}

export interface VersionedAllocationTimephasedDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format uuid */
    versionedContractId?: string;
}

export interface VersionedRequestTimephasedDto {
    /** @format uuid */
    contractId?: string;
    /** @format uuid */
    resourceId?: string;
    /** @format date-time */
    start?: Date;
    /** @format date-time */
    end?: Date;
    /** @format uuid */
    versionedContractId?: string;
}

export interface WaitHandle {
    handle?: IntPtr;
    safeWaitHandle?: SafeWaitHandle;
}

export interface WebSocketManager {
    isWebSocketRequest?: boolean;
    webSocketRequestedProtocols?: string[];
}

export interface WorkChange {
    from?: string;
    to?: string;
    change?: string;
}

export interface WorkPackageDto {
    /** @format uuid */
    id?: string;
    name?: string;
}

export interface WorkPackageSubDto {
    /** @format uuid */
    id?: string;
    name?: string;
    subWorkPackages?: string[];
}

export interface WorkPackageTreeItem {
    /** @format uuid */
    id?: string;
    name?: string;
    children?: WorkPackageTreeItem[];
    selected?: boolean;
    disabled?: boolean;
}

/** @format int32 */
export enum WorkType {
    Request = 1,
    Allocation = 2,
}

export interface X500DistinguishedName {
    oid?: Oid;
    /** @format byte */
    rawData?: string;
    name?: string;
}

export interface X509Certificate2 {
    handle?: IntPtr;
    issuer?: string;
    subject?: string;
    serialNumberBytes?: ByteReadOnlyMemory;
    archived?: boolean;
    extensions?: X509Extension[];
    friendlyName?: string;
    hasPrivateKey?: boolean;
    privateKey?: AsymmetricAlgorithm;
    issuerName?: X500DistinguishedName;
    /** @format date-time */
    notAfter?: Date;
    /** @format date-time */
    notBefore?: Date;
    publicKey?: PublicKey;
    /** @format byte */
    rawData?: string;
    rawDataMemory?: ByteReadOnlyMemory;
    serialNumber?: string;
    signatureAlgorithm?: Oid;
    subjectName?: X500DistinguishedName;
    thumbprint?: string;
    /** @format int32 */
    version?: number;
}

export interface X509Extension {
    oid?: Oid;
    /** @format byte */
    rawData?: string;
    critical?: boolean;
}

export interface RefreshCacheParams {
    notused?: string;
}

export interface RefreshCacheObjectParams {
    cacheFullName?: string;
}

export interface ChartResourceCapacityParams {
    /** @format uuid */
    ResourceId?: string;
    /** @format date-time */
    Start?: Date;
    /** @format date-time */
    End?: Date;
    DateResolution?: any;
}

export interface EnsureApplicationAppKeyParams {
    notused?: string;
}

export interface IsFunctionAppRunningParams {
    notused?: string;
}

export interface GetPrincipalParams {
    notused?: string;
}

export interface ReloadAppServiceConfigurationParams {
    notused?: string;
}

export type GetCommentsForContractPayload = string[];

export type UpdateContractsCustomPropertiesPayload = UpdateContractCustomPropertiesDto[];

export type AddActivitiesPayload = AddActivityDto[];

export interface GetContractPeriodAllocationsParams {
    /** @format uuid */
    ContractId: string;
    UnitType: any;
    Resolution: any;
    /** @format date-time */
    Start: Date;
    /** @format date-time */
    End: Date;
}

export interface GetContractPeriodRequestsParams {
    /** @format uuid */
    ContractId: string;
    UnitType: any;
    Resolution: any;
    /** @format date-time */
    Start: Date;
    /** @format date-time */
    End: Date;
}

export interface RemoveFutureAllocationsParams {
    /** @format uuid */
    ContractId?: string;
    /** @format uuid */
    ResourceId?: string;
    Resolution?: any;
}

export interface DataverseEnvironmentsTablesListParams {
    environmentUrl?: string;
}

export interface DataverseEnvironmentsTablesColumnsListParams {
    environmentUrl?: string;
    tableName?: string;
}

export interface DataverseEnvironmentsTablesColumnsOptionsListParams {
    environmentUrl?: string;
    tableName?: string;
    columnName?: string;
}

export interface GetDemoDataParams {
    notused?: string;
}

export interface AddFirstUserParams {
    notused?: string;
}

export interface RestartFunctionAppParams {
    notused?: string;
}

export interface GetCacheTypesParams {
    notused?: string;
}

export type UpgradePackagesPayload = UpgradePackage[];

export interface GetProjectsParams {
    opts?: any;
    'TimeZone.Id'?: string;
    'TimeZone.HasIanaId'?: boolean;
    'TimeZone.DisplayName'?: string;
    'TimeZone.StandardName'?: string;
    'TimeZone.DaylightName'?: string;
    /** @format timespan */
    'TimeZone.BaseUtcOffset'?: string;
    'TimeZone.SupportsDaylightSavingTime'?: boolean;
    EnsureStableOrdering?: boolean;
    HandleNullPropagation?: any;
    EnableConstantParameterization?: boolean;
    EnableCorrelatedSubqueryBuffering?: boolean;
    /** @format int32 */
    PageSize?: number;
    HandleReferenceNavigationPropertyExpandFilter?: boolean;
}

export interface GetResourceParams {
    /** @format uuid */
    key?: string;
}

export interface GetHolidaysByCountryAndYearParams {
    country?: string;
    year?: string;
}

export interface GetDataverseProjectsParams {
    notused?: string;
}

export interface AadSyncTestParams {
    notUsed?: string;
}

export interface LogLicenseUsageParams {
    notused?: string;
}

export interface LogMetricsParams {
    notused?: string;
}

export interface RunMailScheduleParams {
    notused?: string;
}

export interface GetMailConfigParams {
    notused?: string;
}

export interface GetMigrationsParams {
    notused?: string;
}

export interface EnableTeamsEventParams {
    notUsed?: string;
}

export interface GetTeamsParams {
    notUsed?: string;
}

export interface RemoveTeamMemberParams {
    notUsed?: string;
}

export interface RefreshAllResourcesPermissionsFromAadParams {
    notUsed?: string;
}

export interface GetProjectContractsTimephasedParams {
    /** @format date-time */
    BoundaryStart?: Date;
    /** @format date-time */
    BoundaryEnd?: Date;
    Resolution?: any;
    HandlingType?: any;
    SkipFteCalculation?: boolean;
    UseParallel?: boolean;
    /** @format uuid */
    projectId: string;
}

export interface GetAllContractsTimephasedParams {
    /** @format date-time */
    BoundaryStart?: Date;
    /** @format date-time */
    BoundaryEnd?: Date;
    Resolution?: any;
    HandlingType?: any;
    SkipFteCalculation?: boolean;
    UseParallel?: boolean;
}

export interface GetIntegrationsParams {
    notUsed?: string;
}

export interface InitializeIntegrationsParams {
    notUsed?: string;
}

export interface GetAvailableLocalesParams {
    notused?: string;
}

export interface GetLanguageFileParams {
    notused?: string;
}

export interface PluginByGuid2Params {
    dto?: any;
    product: string;
    /** @format uuid */
    pluginId: string;
}

export interface FunctionAppRenewSubscriptionParams {
    functionUrl?: string;
}

export interface GetProjectsWhereCurrentResourceIsOwnerParams {
    notused?: string;
}

export type GetPushNotificationAnnouncementsPayload = string[];

export type RerunSqlExportErrorPayload = SqlExportError[];

export interface StopDelegationParams {
    notused?: string;
}

export interface UpdateCurrentUserLastSignedInParams {
    notused?: string;
}

export interface ResetResourcesMainWorkProfileParams {
    notused?: string;
}

export interface GetResourcePeriodAllocationsParams {
    /** @format uuid */
    ResourceId: string;
    UnitType: any;
    Resolution: any;
    /** @format date-time */
    Start: Date;
    /** @format date-time */
    End: Date;
}

export interface GetResourcePeriodRequestsParams {
    /** @format uuid */
    ResourceId: string;
    UnitType: any;
    Resolution: any;
    /** @format date-time */
    Start: Date;
    /** @format date-time */
    End: Date;
}

export interface GetResourcePeriodCapacityParams {
    /** @format uuid */
    ResourceId: string;
    UnitType: any;
    Resolution: any;
    /** @format date-time */
    Start: Date;
    /** @format date-time */
    End: Date;
}

export interface DuplicateOriginSanitizerParams {
    notused?: string;
}

export interface DisableScheduleWebJobParams {
    notUsed?: string;
}

export interface ScheduleJobExecutorParams {
    notUsed?: string;
}

export interface ServicebusClientTopicUpdateParams {
    configurationKey: string;
}

export type ServicebusClientUpdatePayload = PFServiceBusConfiguration[];

export interface AddSecretParams {
    serviceBusName?: string;
    secretName?: string;
}

export interface CreateNewConfigurationParams {
    rootSAS?: string;
    secretName?: string;
}

export interface GetAllSubSettingsParams {
    notused?: string;
}

export interface GetRootSettingsParams {
    notused?: string;
}

export interface GetCssOverridesParams {
    notUsed?: string;
}

export type SaveWorkpackageCellsPayload = SaveWorkPackageCellDto[];

export interface GetAllWorkpackagesFlatParams {
    notused?: string;
}
