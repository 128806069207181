import React, { FunctionComponent, useRef, useState } from 'react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { FreezePeriod } from '../../../Entities/FreezePeriod';
import { Checkbox, Dropdown, IDropdownOption, Label, TextField } from '@fluentui/react';
import { DropdownOptionEx } from '../../../Utilities/DropdownOptionEx';
import { TimelineResolution } from '../../../Entities/TimelineResolution';
import { language } from '../../../Services/LocalizationService';

interface IProps extends IDynamicEditorComponentProps {
    Item: FreezePeriod;
    Update: (freeze: FreezePeriod) => void;
    Key?: string;
}

const FreezePeriodEditor: FunctionComponent<IProps> = props => {
    const item = useRef<FreezePeriod>({ ...(props.Item || new FreezePeriod()) });
    const [todayOnly, setTodayOnly] = useState<boolean>(item.current.FreezeBeforeTodayOnly);

    const OnChange = (prop: string, value: any) => {
        const copy = { ...item.current } || new FreezePeriod();
        copy[prop] = value;
        item.current = copy;
        props.Update(copy);
    };

    const OnKeyDown = event => {
        // disable 'e' and decimals in number fields
        if (event.keyCode === 69) event.preventDefault();
        else if (event.keyCode === 110 || event.keyCode === 188 || event.keyCode === 190) event.preventDefault();
    };

    return (
        <div className="tp-freezeperiod">
            {!todayOnly && (
                <>
                    <Dropdown
                        label={language.Common.Resolution}
                        key={`${props.Key}-resolution`}
                        disabled={props.ReadOnly}
                        options={DropdownOptionEx.enumToDropdownOptions<TimelineResolution>(TimelineResolution)}
                        defaultSelectedKey={item.current.Resolution ?? TimelineResolution.Month}
                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => OnChange('Resolution', option.key)}
                        styles={{ root: { width: '100px' } }}
                    />
                    <TextField
                        label={language.FreezePeriodEditor.Number}
                        key={`${props.Key}-number`}
                        defaultValue={(item.current.Number || 0) + ''}
                        onChange={(event, value) => OnChange('Number', +value)}
                        disabled={props.ReadOnly}
                        type={'number'}
                        onKeyDown={OnKeyDown}
                    />
                </>
            )}
            <div>
                <Label>{language.FreezePeriodEditor.FreezePrevious}</Label>
                <Checkbox
                    key={`${props.Key}-todayonly`}
                    defaultChecked={item.current.FreezeBeforeTodayOnly}
                    onChange={(event, checked) => {
                        OnChange('FreezeBeforeTodayOnly', checked);
                        setTodayOnly(checked);
                    }}
                    disabled={props.ReadOnly}
                />
            </div>
        </div>
    );
};

export default FreezePeriodEditor;
