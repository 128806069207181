import { memo, useMemo, CSSProperties } from 'react';
import { ContractColumnRow } from './ContractColumnRow';
import { ColumnRowProps } from '../../ColumnRowProps';
import { ROW_HEIGHT, BORDER, COLUMN_WIDTH } from '../../../../CONSTANTS';
import { useGridRowContext } from '../../../../../../../_grid/contexts/rowContext';
import { GridColumnRow } from '../../../../../../../../../api/generated/data-contracts';

export const ContractColumnContainer = memo(function ContractColumnContainer({
    columnIndex,
    columnRow,
    rowId,
    isAdminProject,
    // index,
    start,
}: ColumnRowProps & { start: number; columnRow: GridColumnRow }) {
    const { toggleExpanded } = useGridRowContext();
    const style = useMemo(() => {
        return {
            width: `${COLUMN_WIDTH}px`,
            position: 'absolute',
            transform: `translateX(${start}px)`,
            top: 0,
            left: 0,
            height: `${ROW_HEIGHT}px`,
            boxSizing: 'border-box',
            borderBottom: BORDER,
            borderRight: BORDER,
        } as CSSProperties;
    }, [start]);
    return (
        <div style={style} onClick={() => toggleExpanded()}>
            <ContractColumnRow columnRow={columnRow} />
        </div>
    );
});
