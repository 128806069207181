import { useCallback, useState } from 'react';
import { ICustomComponentConfig, IFieldRenderProps, IFormItem, useFormContext, useValidation, useWatch } from 'spark-forms';
import { getNestedEntityProperty } from '../../../views/overviews/_components/helpers/sortHelper';
import { OverviewCreator } from '../../../_components/OverviewCreator/OverviewCreator';
import { FormItemFieldForm } from '../../_builders/new/list/FormItemFieldForm';
import { ImportRulesByApiDialog } from '../../_builders/new/components/ImportRulesByApiDialog';
import { Holiday } from '../../../api/generated/data-contracts';
import { guid } from '../../../helpers/guid';
import { useRuleOverviewMenuItems } from '../hooks/useRuleOverviewMenuItems';
import { DateEx } from '../../../Utilities/DateEx';
import { ruleSchema } from '../rule/ruleSchema';
import { FluentValidationMessage } from 'spark-forms-fluent8';

export const RulesOverview: React.FC<any> = <T extends IFormItem>({
    value: listItems,
    fieldName,
    disabled,
    path,
    options,
}: IFieldRenderProps<T, ICustomComponentConfig<T>, T[] | undefined>) => {
    const [items, setItems] = useState<T[]>(listItems ?? []);

    const [isNewlyCreated, setIsNewlyCreated] = useState(false);

    const [openImportRulesByApiPanel, setOpenImportRulesByApiPanel] = useState(false);

    const { onChange: onChangeForm } = useFormContext();

    useWatch(path, () => setItems(listItems), [listItems]);

	const { validate, validationMessage } = useValidation(path);

    const onClosePanel = useCallback(() => {
        setIsNewlyCreated(false);
    }, []);

    // fake setstateaction
    const _setItems = useCallback(
        (action: T[] | ((prevState: T[]) => T[])) => {
            setItems(action);
            let changed = [];
            if (Array.isArray(action)) {
                changed = action;
            } else {
                changed = action(items);
            }
            onChangeForm(fieldName, changed);
			validate();
        },
        [fieldName, items, onChangeForm, validate],
    );

    const onClickSave = useCallback(
        async (item: T) => {
            if (isNewlyCreated) {
                _setItems([item, ...items]);
                setIsNewlyCreated(false);
            } else {
                _setItems(items.map(wp => (wp.id === item.id ? item : wp)));
            }
        },
        [_setItems, isNewlyCreated, items],
    );

    const onClickImport = useCallback(
        async (itemsNew: Holiday[]) => {
            const wpRulesNew = itemsNew.map((v, i, a) => {
                return {
                    id: guid.newGuid(),
                    name: v.name,
                    start: v.date,
                    end: v.date,
                    work: 0,
                    recurring: false,
                } as any;
            });
            _setItems([...wpRulesNew, ...items]);
        },
        [_setItems, items],
    );

    const onGetPanelHeaderText = useCallback(
        (item: T) => {
            if (isNewlyCreated) {
                return `Creating new item${item?.name ? `: ${item?.name}` : ''}`;
            }
            return `Edit: ${item?.name}`;
        },
        [isNewlyCreated],
    );

    const menuItems = useRuleOverviewMenuItems({
        setItems: _setItems,
        setIsNewlyCreated,
        preNewItem: options.config?.preNewItem,
        preCopyItem: options.config?.preCopyItem,
        setOpenImportRulesByApiPanel,
    });

    const onSearchItems = useCallback(
        (items: T[], searchInput: string) => {
            const columnNames = columns.map(col => col.fieldName);
            // always enable search by id
            columnNames.push('id');
            const text = searchInput.toLowerCase();
            return items.filter(item => {
                for (const key of columnNames) {
                    const value = getNestedEntityProperty(item, key as any);
                    if (typeof value === 'string' && value.toLowerCase().includes(text)) {
                        return true;
                    }
                }
                return false;
            });
        },
        [],
    );

    return (
		<>
			<FluentValidationMessage message={validationMessage} />
			<div style={{ marginTop: '0px', height: 'calc(100vh - 575px)' }}>
			<OverviewCreator
				items={items}
				columns={columns}
				loading={false}
				onClickSave={onClickSave}
				onClosePanel={onClosePanel}
				menuItems={disabled ? undefined : menuItems}
				searchFilter={onSearchItems}
				searchDebounceTimout={300}
				panelContent={<FormItemFieldForm schema={ruleSchema} />}
				onGetPanelHeaderText={onGetPanelHeaderText}
				listContainerClassName={'workprofile-rules__list-container'}
			/>
			</div>
			<ImportRulesByApiDialog
				headerText={'Import rules by holidays'}
				onClickImport={onClickImport}
				openPanel={openImportRulesByApiPanel}
				setOpenPanel={setOpenImportRulesByApiPanel}
				onDismissPanel={() => {
					setOpenImportRulesByApiPanel(false);
				}}
			/>
		</>
    );
};

const columns = [
	{
		fieldName: 'name',
		name: 'Name',
		key: 'name',
		minWidth: 200,
		maxWidth: 200,
		isResizable: true
	},
	{
		fieldName: 'recurring',
		name: 'Recurring',
		key: 'recurring',
		minWidth: 100,
		maxWidth: 100,
		isResizable: true
	},
	{
		fieldName: 'start',
		name: 'Start',
		key: 'start',
		minWidth: 100,
		maxWidth: 180,
		isResizable: true,
		onRender: item => DateEx.asUserLocal(item.start)
	},
	{
		fieldName: 'end',
		name: 'End',
		key: 'end',
		minWidth: 200,
		maxWidth: 180,
		isResizable: true,
		onRender: item => DateEx.asUserLocal(item.end)
	}
];