/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo } from 'react';
import { TextField, IconButton } from '@fluentui/react';
import { DynamicProps } from './DynamicProps';

interface IProps extends DynamicProps<string, string> {
    Type?: string;
}

const OnKeyDown = (e, type: string) => {
    // hack to disable 'e' in number fields
    // for some reason this is allowed in the fabric component
    if (type === 'number' && e.keyCode === 69) e.preventDefault();
};

const DynamicTextFieldCopyValue: FunctionComponent<IProps> = props => {
    return (
        <>
            <div className="tp-dynamic-textfieldcopyvalue-container">
                <TextField
                    className="tp-dynamic-textfieldcopyvalue-textfield"
                    key={props.Key}
                    defaultValue={props.Value != null ? props.Value : ''}
                    onKeyDown={event => OnKeyDown(event, props.Type)}
                    onChange={(obj, value) => props.OnChange(props.PropertyInfo.PropertyName, value)}
                    disabled={props.ReadOnly}
                    type={props.Type || 'text'}
                />
                <IconButton iconProps={{ iconName: 'Copy', onClick: e => (navigator as any).clipboard.writeText(props.Value) }} />
            </div>
        </>
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Value === nextProps.Value;
};

export default memo(DynamicTextFieldCopyValue, comparisonFn);
