import { PickerFields, ProjectListItem, RootSettings } from '../../../../../api/generated/data-contracts';
import { language } from '../../../../../Services/LocalizationService';
import { ObjectEx } from '../../../../../Utilities/ObjectEx';
import { functionThatReturnsTrue } from '../../../../../helpers/functionPrimitives';
import { TFilter } from '../types';
import { capitalize } from '../../../../../context/network/http/QueryProvider/helpers/queryHelper';

export const makeProjectFilterDefaults = (groupings: string[]): TFilter[] => {
    if (!groupings.length) {
        return [{ filterFn: functionThatReturnsTrue, type: 'button', grouping: "all" }];
    }
    return groupings.map(grouping => {
        if (grouping === 'rbs') {
            return { filterFn: functionThatReturnsTrue, type: 'rbs', grouping };
        }
        return { filterFn: functionThatReturnsTrue, type: 'button', grouping };
    });
};

export const getPickerFields = (settings: RootSettings): PickerFields => {
    return settings.projectPickerDisplayFields;
};

export const resolveProjectFieldName = (field: string, settings: RootSettings): string | undefined => {
    if (language.Project[field]) {
        return language.Project[field];
    }
    // TODO remove this when serialization is stable
    const pascalFieldName = capitalize(field);
	const lowerCaseField = field.toLowerCase();
    if (language.Project[pascalFieldName]) {
        return language.Project[pascalFieldName];
    } else if (lowerCaseField.startsWith('customproperties')) {
        const customGuid = field.slice('customproperties'.length + 1);
        return settings.projectCustomProperties.find(_ => _.id === customGuid)?.displayName;
    } else if (lowerCaseField) {
		const title = field.split(".")[1];
		return title
	}
};

export const GetOrderByAsString = (orderBy: string): string => {
	// as the EntityPropertySelector has custom properties in the format 'custompropertie|guid|'
	// we need to change it to 'customproperties.guid' for the stringVariants.ts (ewi)
	const match = orderBy.match(/customProperties\|(.*?)\|/);
	if (match?.length > 1) {
		orderBy = `customProperties.${match[1]}`;
	}
	return orderBy;
};

export const SortFilteredProjects = (orderBy: string, orderByDescending: boolean, items: ProjectListItem[]): ProjectListItem[] => {
    return SortByPropertyValue(items, '-favourite', `${orderByDescending ? '-' : ''}${orderBy}`, 'name');
};

const isNullOrWhiteSpace = (value: any) => value == null || value === '';
const valueGetter = ObjectEx.getNestedPropertyValueByString;
// TODO: do something about this (should work for now in most cases) (ewi)
const getFieldSorter =
    <T>(fields: string[]) =>
    (a: T, b: T) => {
        return fields
            .map(field => {
                let direction = 1;
                if (field[0] === '-') {
                    direction = -1;
                    field = field.substring(1);
                }
                const aVal = valueGetter(a, field);
                const bVal = valueGetter(b, field);

                if (isNullOrWhiteSpace(aVal) && isNullOrWhiteSpace(bVal)) {
                    return 0;
                }

                const comparer = typeof aVal === 'string' && typeof bVal === 'string' ? (c, d) => c.localeCompare(d, 'en') > 0 : (c, d) => c > d;

                return isNullOrWhiteSpace(aVal) || comparer(aVal, bVal) ? direction : isNullOrWhiteSpace(bVal) || comparer(bVal, aVal) ? -direction : 0;
            })
            .reduce((p, n) => (p ? p : n), 0);
    };

const SortByPropertyValue = <T>(array: T[], ...params: string[]): T[] => {
    return [...array].sort(getFieldSorter(params));
};
