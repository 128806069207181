import { Resource } from "../../../../../api/generated/data-contracts";
import { settings } from "../../../../../context/network/http/QueryProvider/queries/UISettings";
import { refactorHelper } from "../../../../../helpers/refactorHelper";
import { ArrayEx } from "../../../../../Utilities/ArrayEx";
import { projectSchema } from "../../../../project/projectSchema";
import { resourceSchema } from "../../../../resource/resourceSchema";
import { getDisallowedCustomProperties } from "./getDisallowedCustomProperties";
import { getAppOrigin } from "./getOrigins";

const getDisallowedProjectFields = (includeCustomPropertyPath?: boolean) : string[] => {
	const props = getDisallowedFields("project", includeCustomPropertyPath);
	return props;
}

const getDisallowedResourceFields = (includeCustomPropertyPath?: boolean) : string[] => {
	const props = getDisallowedFields("resource", includeCustomPropertyPath);
	if (settings().allowChangeRbsAndCustomPropertiesOnGenericResource) {
		const genResDefinitionLocks = settings().genericResourceDefinition;
		genResDefinitionLocks.forEach(lock => {
			ArrayEx.pushIfNotExists(props, lock, _ => _ === lock);
		})
	}
	return props;
}

const getDisallowedFields = (type: "project" | "resource" | "contract", includeCustomPropertyPath?: boolean) : string[] => {

	let customPropIds: string[];
	let allowed: string[];
	let allFields: string[];

	const appOrigin = getAppOrigin();
	allowed =  refactorHelper.getOriginProperties(appOrigin, type);

	if (type === "resource") {
		customPropIds = settings().resourceCustomProperties.map(_ => _.id);
		allFields = Object.keys(resourceSchema.fields);
	} else if (type === "project") {
		customPropIds = settings().projectCustomProperties.map(_ => _.id);
		allFields = Object.keys(projectSchema.fields);
	} else return [];


	if (!allowed) return allFields;

	if (includeCustomPropertyPath) {
		customPropIds = customPropIds.map(_ => `customProperties.${_}`)
	}

	// combine fields and custom props
	const allProps = allFields.concat(customPropIds);

	// extract custom properties
	let hasCustomProperties = false;
	if (!allowed) return allProps;
	// add whole custom property path as well to support both ways of using them
	allowed = allowed.map(_ => {
		if (_.indexOf("customProperties.") >= 0) {
			hasCustomProperties = true;
			if (!includeCustomPropertyPath)
			_ = _.replace("customProperties.", "");
		}
		return _;
	});

	// add custom props to allow list - all deny is handled by the individual custom property
	if (hasCustomProperties)
		allowed.push("customProperties");

	// get difference
	return ArrayEx.difference(allProps, allowed);
}

const resourceHasOrigin = (resource: Resource, originId: string) : boolean => {
	if (resource?.originIds === null || resource?.originIds === undefined) return false;
	return Object.values(resource?.originIds).includes(originId);
}

const resourceIsAppOrigin = (resource: Resource, allowNullOriginIdsAsAppOrigin: boolean = false) : boolean => {
	if (allowNullOriginIdsAsAppOrigin) return (resource?.originIds === null || resource?.originIds === undefined) || Object.values(resource?.originIds).includes(getAppOrigin().id);

	if (resource?.originIds === null || resource?.originIds === undefined) return false;
	return Object.values(resource?.originIds).includes(getAppOrigin().id);
}

const resourceIsAppOriginAndUnderCreation = (resource: Resource, allowNullOriginIdsAsAppOrigin: boolean = false) : boolean => {
	if (allowNullOriginIdsAsAppOrigin) return (resource?.created === null || resource?.created === undefined) && ((resource?.originIds === null || resource?.originIds === undefined) || Object.values(resource?.originIds).includes(getAppOrigin().id));

	if (resource?.originIds === null || resource?.originIds === undefined) return false;
	return (resource?.created === undefined || resource?.created === null) && Object.values(resource?.originIds).includes(getAppOrigin().id);
}

export const originHelper = {
	getDisallowedProjectFields,
	getDisallowedResourceFields,
	getDisallowedFields,
	getDisallowedCustomProperties,
	resourceHasOrigin,
	resourceIsAppOrigin,
	resourceIsAppOriginAndUnderCreation,
}