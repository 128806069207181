import { HubConnectionState } from '@microsoft/signalr';
import { useEffect } from 'react';
import shallow from 'zustand/shallow';
import { NotificationDtoCompressed } from '../../../Entities/Dto/NotificationDto';
import { Notification } from '../../../Entities/Notification';
import { devLogger } from '../../../helpers/devLogger';
import { createContext } from '../../../hooks/createContext';
import { useSignalR, useSignalRReturns } from '../../../hooks/useSignalR';
import { HubLogger } from '../../../Logging/HubLogger';
import { useIdleContext } from '../../IdleContext';
import { useStore } from '../../store';
import { useUISettings } from '../http/QueryProvider/queries/UISettings';

export type TExternalNotificationContext = useSignalRReturns<NotificationDtoCompressed>;

const [useCtx, Provider] = createContext<TExternalNotificationContext>();

const logger = new HubLogger();

// eslint-disable-next-line react-refresh/only-export-components
export const useExternalNotificationContext = useCtx;

export const ExternalNotificationServiceContext: React.FC = ({ children }) => {
    const { isIdle } = useIdleContext();
    const { data: uiSettings } = useUISettings();
    const userPrincipalName = uiSettings?.user?.principalName;
    const { setBlockDialog, addNotification } = useStore(
        store => ({
            setBlockDialog: store.setBlockDialog,
            setNotifications: store.setNotifications,
            addNotification: store.addNotification,
        }),
        shallow,
    );
    const signalR = useSignalR<NotificationDtoCompressed>({
        eventName: 'Send',
        nextRetryDelayInMilliseconds: (retryContext, destroyConnection, makeConnection) => {
            // don't reconnect if idle
            // if (isIdle) return null;
            // retries.current++;
            // If we've been reconnecting for less than 3 times so far,
            // wait between 0 and 5 seconds before the next reconnect attempt.
            if (retryContext.previousRetryCount < 3) return Math.random() * 5000;

            // attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000), // exponential back-off

            // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
            return null;
        },
        onMessage: dto => {
            // if the notification is blocking
            // show refresh dialog with notification text
            if (dto.ib || dto.f) {
                setBlockDialog(null, {
                    title: dto.t,
                    subText: dto.m,
                    dismissEnable: dto.ad,
                    refreshEnabled: dto.f,
                });
            } else {
                // else show regular notification
                const notification = new Notification();
                notification.Message = dto.m;
                notification.StatusType = dto.ty;
                notification.WriteToConsole = dto.c;
                addNotification(notification);
            }
        },
        url: url => `${url}/hubs/notifications?user=${userPrincipalName}`,
        logger,
        connectOnMount: false,
    });

    const { makeConnection, shutDownConnection, getConnection } = signalR;

    useEffect(() => {
        if (userPrincipalName) {
            const connectionState = getConnection()?.state;
            devLogger.log('ExternalNotificationService', connectionState);
            if (isIdle && connectionState === HubConnectionState.Connected) {
                shutDownConnection();
            } else if (!isIdle && (connectionState === HubConnectionState.Disconnected || !connectionState)) {
                makeConnection()
                    .then(async connection => {
                        devLogger.log('ExternalNotificationService', connection.state);
                        if (connection.state === HubConnectionState.Disconnected) {
                            await connection.start();
                            devLogger.log('ExternalNotificationService', connection.state);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
            return () => {
                shutDownConnection();
            };
        }
    }, [getConnection, isIdle, makeConnection, shutDownConnection, userPrincipalName]);

    return <Provider value={signalR}>{children}</Provider>;
};
