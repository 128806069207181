import {
    DetailsListLayoutMode,
    IColumn,
    Label,
    ScrollablePane,
    SearchBox,
    Selection,
    SelectionMode,
    ShimmeredDetailsList,
    Spinner,
    SpinnerSize,
    Stack,
} from '@fluentui/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useStore } from '../../context/store';
import { OverviewContextProvider } from './context/OverviewCreatorContext';
import { useControlledState } from '../../hooks/useControlledState';
import { OverviewPanel } from './components/OverviewPanel/OverviewPanel';
import { MenuItem, OverviewCommandBar } from './components/OverviewCommandBar/OverviewCommandBar';
import { OverviewSearchContextProvider } from './context/OverviewCreatorSearchContext';
import { useDebouncedEventHandler } from '../../hooks/useDebouncedHandler';
import { isNullOrUndefined } from '../../helpers/isNullOrUndefined';
import { AnyObject } from '../../types/types';
import { useEvent } from '../../hooks/useEvent';
import { devLogger } from '../../helpers/devLogger';

// export type TOverviewColumns<T extends { [key: string]: any }[] | { [key: string]: any }> = (IColumn & {
//     fieldName: T extends { [key: string]: any }[] ? keyof T[0] : keyof T;
// })[];

export type OverviewCreatorProps<T extends AnyObject> = {
    items: T[];
    columns: IColumn[];
    onClickSave?: (item: T) => Promise<void> | void;
	saveOptions?: { keepPanelOpen: boolean, saveButtonText?: string };
    panelContent?: React.ReactNode;
    onClosePanel?: () => void;
    menuItems?: MenuItem<T>[];
    onGetSearchPlaceholderText?: (items: T[]) => string;
    onGetPanelHeaderText?: (item: T) => string;
    children?: React.ReactNode;
    loading?: boolean;
    onError?: (error: any) => Promise<void> | void;
    /**
     * @param searchInput is a trimmed string of user text
     */
    searchFilter?: (items: T[], searchInput: string) => T[];
    onColumnSort?: (a: T, b: T, propKey: string, isSortedDescending?: boolean) => number;
    searchDebounceTimout?: number;
	onShouldCancelItemInvocation?: (item?: T, index?: number, ev?: Event) => boolean;
	listContainerClassName?: string;
	selectionMode?: SelectionMode;
};

export const OverviewCreator = <T extends AnyObject>({
    items,
    onGetSearchPlaceholderText,
    menuItems,
    onGetPanelHeaderText: onGetPanelTitle,
    children,
    onClickSave,
	saveOptions,
    onClosePanel,
    loading,
    onError,
    columns: propsColumns,
    searchFilter,
    /**
     * Defaults to 0 YOLO
     */
    searchDebounceTimout = 0,
    panelContent,
    onColumnSort = defaultOverviewSort,
	onShouldCancelItemInvocation,
	listContainerClassName,
	selectionMode = SelectionMode.multiple
}: OverviewCreatorProps<T>) => {
    const [savingItem, setSavingItem] = useState(false);
    const topbarAlwaysOpen = useStore(store => store.ui.topbarAlwaysOpen);
    const [panelItem, setPanelItem] = useState<T | null>(null);
    const [selectedItems, setSelectedItems] = useState<T[]>([]);
	// const [currentViewItems, setCurrentViewItems] = useState<T[]>([]);
    const [searchInput, setSearchInput] = useState('');
    const [currentSort, setCurrentSort] = useState<{ columnKey: string; sortFn: (a: T, b: T) => number; isSortedDescending: boolean } | null>(null);
	
	const validation = useRef<() => Promise<Record<string, string>>>();
	const [keepPanelOpen, setKeepPanelOpen] = useState(false);

    // This whole selection thing is terrible :/
    const selection = useRef(
        new Selection({
            onSelectionChanged: () => {
                setSelectedItems(selection.getSelection() as T[]);
            },
        }),
    ).current;

    const filteredItems = useMemo(() => {
        if (searchFilter) {
            return searchFilter(items, searchInput);
        }
        return items;
    }, [searchFilter, items, searchInput]);

    const sortedItems = useMemo(() => {
        if (currentSort) {
            return [...filteredItems].sort(currentSort.sortFn);
        }
        return filteredItems;
    }, [currentSort, filteredItems]);

    const onColumnClick = useCallback(
        (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
            const columnKey = column.key;
            // Toggle the sorted column
            const isSortedDescending = !column.isSortedDescending;
            setCurrentSort({
                columnKey,
                isSortedDescending,
                sortFn: (a, b) => {
                    return onColumnSort(a, b, columnKey, isSortedDescending);
                },
            });
        },
        [onColumnSort],
    );

    const [columns, setColumns] = useControlledState<IColumn[]>(() => propsColumns, [propsColumns]);

    const listColumns: IColumn[] = useMemo(() => {
        return columns.map(col => {
            if (col.key === currentSort?.columnKey) {
                return {
                    ...col,
                    onColumnClick,
                    isSortedDescending: currentSort.isSortedDescending,
                    isSorted: true,
                };
            }
            return {
                ...col,
                onColumnClick,
                isSorted: false,
            };
        });
    }, [columns, currentSort, onColumnClick]);

    const searchPlaceHolder = useMemo(() => {
        if (onGetSearchPlaceholderText) {
            return onGetSearchPlaceholderText(items);
        }
        return 'Search..';
    }, [onGetSearchPlaceholderText, items]);

    const onDismissPanel = useCallback(() => {
		if (keepPanelOpen) {
			// ev.preventDefault();
			return;
		}
		setKeepPanelOpen(false);
        setPanelItem(null);
        if (onClosePanel) {
            onClosePanel();
        }
    }, [keepPanelOpen, onClosePanel]);

    const onItemInvoked = useEvent((item?: T, index?: number, ev?: Event) => {
		if (onShouldCancelItemInvocation && onShouldCancelItemInvocation(item, index, ev)) {
			return;
		}
		// Support empty strings and other falsy values
        if (!isNullOrUndefined(item)) {
            setPanelItem(item);
        }
    });

    const handleSaveItem = useCallback(
        async (preferredItem?: T) => {
            const item = preferredItem ?? panelItem;
            if (onClickSave) {
				let maybePromise: any;
                try {
					// validate
					const validationResult = await validation.current?.();
					if (validationResult && Object.values(validationResult).some(_ => _ != null)) {
						devLogger.log('validationresult', validationResult);
						
						return;
					}

					maybePromise = onClickSave(item);
					// There is no need to start a spinner if there is nothing to wait for
					if (maybePromise instanceof Promise) {
						setSavingItem(true);
						await maybePromise;
					}

					if (!keepPanelOpen) {
						setPanelItem(null);
					}
                } catch (error) {
					const newError = new Error('Error when saving', { cause: error })
                    if (onError) {
                        onError(newError);
                    }
					throw newError
                } finally {
					if (maybePromise instanceof Promise) {
						setSavingItem(false);
					}
                }
            }
            return item;
        },
        [keepPanelOpen, onClickSave, onError, panelItem],
    );

    const onSearch = useDebouncedEventHandler((e: any, newValue: string) => {
        setSearchInput(newValue);
    }, searchDebounceTimout);

    const onClearSearch = useCallback(() => {
        setSearchInput('');
    }, []);

    const columnReorderOptions = useMemo(() => {
        return {
            handleColumnReorder: (draggedIndex: number, targetIndex: number) => {
                setColumns(columns => {
                    const draggedItems = columns[draggedIndex];

                    const newColumns: IColumn[] = [...columns];
                    newColumns.splice(draggedIndex, 1);

                    newColumns.splice(targetIndex, 0, draggedItems);
                    return newColumns;
                });
            },
        };
    }, [setColumns]);

    return (
        <OverviewContextProvider
            value={{
                item: panelItem,
                selectedItems,
                dismissPanel: onDismissPanel,
                items,
                saveItem: handleSaveItem,
                loading,
                savingItem,
                setItem: setPanelItem,
				setValidation: validationFunc => validation.current = validationFunc
            }}
        >
            {/* Search input is intentionally put in its own context to reduce rerenders when the text changes */}
            <OverviewSearchContextProvider value={{ searchText: searchInput }}>
                <div className={`tp-entity-overview ${topbarAlwaysOpen ? 'topbar-stay' : ''}`}>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        {searchFilter && (
                            <>
								<SearchBox styles={{ root: { width: 500, marginLeft: 14 } }} placeholder={searchPlaceHolder} onChange={onSearch} onClear={onClearSearch} />
								{
									searchInput && <Label>Results: {filteredItems?.length}</Label>
								}
							</>
                        )}
                        {loading && <Spinner size={SpinnerSize.large} />}
                    </Stack>
                    {Boolean(menuItems?.length) && (
                        <OverviewCommandBar
                            //
                            menuItems={menuItems}
                            selectedItems={selectedItems}
                            setPanelContext={setPanelItem}
							filteredItems={sortedItems}
							columns={columns}
                        />
                    )}
                    <Stack className={`tp-entity-overview__list-container ${listContainerClassName}`}>
                        <ScrollablePane>
                            <ShimmeredDetailsList
                                //
                                items={sortedItems}
                                onItemInvoked={onItemInvoked}
                                enableShimmer={!items.length && loading}
                                columns={listColumns}
                                selection={selection}
                                selectionMode={selectionMode}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                selectionPreservedOnEmptyClick
                                columnReorderOptions={columnReorderOptions}
                            />
                        </ScrollablePane>
                    </Stack>
                    {panelContent && (
                        <OverviewPanel
                            handleSaveItem={handleSaveItem}
                            onDismissPanel={onDismissPanel}
                            panelItem={panelItem}
                            savingItem={savingItem}
                            onGetPanelTitle={onGetPanelTitle}
							showSaveButtom={Boolean(onClickSave)}
							keepOpen={ saveOptions?.keepPanelOpen 
								? (keepOpen: boolean) => setKeepPanelOpen(keepOpen) 
								: undefined}
							saveButtonText={saveOptions?.saveButtonText}
                        >
                            {panelContent}
                        </OverviewPanel>
                    )}
                    {children}
                </div>
            </OverviewSearchContextProvider>
        </OverviewContextProvider>
    );
};

function defaultOverviewSort<T>(a: T, b: T, columnKey: string, isSortedDescending?: boolean): number {
    const key = columnKey as keyof T;
    return (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1;
}
