import { IconButton, Stack, TextField } from "@fluentui/react";
import { DynamicDateField } from "@wiberg/fluentui-builder";
import { IDynamicPropertyComponentConfig, IFormItem, IPropertyRenderProps } from "@wiberg/formbuilder";
import { PropsWithChildren, ReactElement, useCallback, useMemo, useState } from "react";

interface IProps<T extends IFormItem> extends IPropertyRenderProps<T, IDynamicPropertyComponentConfig<T>, Date | string | undefined> { 
    config?: IDynamicPropertyComponentConfig<T>;
}

const todayTemplate = "[[Today]]";

export const DateWithTodayFilterField = <T extends IFormItem>(props: PropsWithChildren<IProps<T>>) : ReactElement | null => {

	const [item, setItem] = useState(props.value);

	const change = useCallback((value: any) => {		
		setItem(value);
		props.onChange(value);
	}, [props]);

	const todayUsed = useMemo(() => item === todayTemplate, [item]);

	const useToday = useCallback(() => {
		if (!todayUsed)
			change(todayTemplate);
		else
			change(null);
	}, [todayUsed, change]);

	return 	(
		<Stack horizontal>
			{
				todayUsed
					? <TextField value="Today" disabled />
					: <DynamicDateField
						{...props as any}
						value={item}
						onChange={change}
					/>
			}
			<IconButton
                iconProps={
					!todayUsed ? 
					{ iconName: 'GotoToday' } :
					{ iconName: 'Cancel' }
				}
				title={!todayUsed ? "Use dynamic 'Today' value" : "Clear dynamic 'Today' value"}
				onClick={useToday}
            />
		</Stack>
	)
}
