// export enum ProposalState
// {
// 	Published = 0,              // the real activity (only used on the real contract itself)
// 	Draft = 1,                  // a user draft for myself
// 	Proposed = 2,               // most likely from the AI, but could come from a user as well
// 	Rejected = 3,               // not to use - hidden for all but owner (maybe activities is removed when coming from AI)
// 	Promoted = 4,               // this was pushed to real data (published)
// 	PromotedWithChanges = 5,    // the proposal has been modified and was pushed to real data (published)
// 	PartlyPromoted = 6,         // the proposal has been partly promoted
// 	ChangesToProposed = 7,      // a proposed value has been changed by the approver
// 	Unknown = 99
// }

import { ProposalState } from "../../api/generated/data-contracts"

export const activeProposalStates = [
	ProposalState.Draft,
	ProposalState.Proposed,
	ProposalState.PartlyPromoted,
	ProposalState.ProposedChanged,
	ProposalState.Unknown
]

export const activeProposalStatesWithoutDraft = [
	ProposalState.Proposed,
	ProposalState.PartlyPromoted,
	ProposalState.ProposedChanged,
	ProposalState.Unknown
]