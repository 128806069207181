export default class Locale_ProjectCapacity { 
	Project = {
		Select: "Please select a project. You cannot request on the latest selected project.",
		OnMergeContract: "You can only merge contracts, with the same requested resource",
	}
	ConfirmDto = {
		Merge: "Merge",
		OnMergeContractFail: "Failed merging contracts",
		ConfirmDtoTitle: "Are you sure you want to merge these contracts?",
		ConfirmDtoSubtext: "This cannot be undone.. It affects the entire contract",
	}
	OnCellUpdate = {
		DifferentTimelineResolution: "The request you are editing/allocating on has a different time resolution ([[timelineresolution]]), than the one currently selected!",
		Switch: "Switch",
	}
	MenuItems = {
		Workpackage: {
			Text: 'Add request templates',
			Title: 'Add request templates to project'
		},
		Scenario: {
			Text: 'Scenarios',
			Title: 'Scenarios'
		},
		AutoDistribution: {
			Text: 'Auto distribution',
			Title: 'Auto distribution'
		},
		MoveProject: {
			Text: 'Move project requests in time',
			Title: 'Move project requests in time'
		},
		RefreshExport: {
			Text: 'Resync SQL export',
			Title: 'Resync SQL export'
		}
	}
	GroupExtraContent = {
		DropHere: 'Drop here',
		MergeOnto: 'Merge onto'
	}
	GroupExtraTextContent: string = "The contract has unseen changes";
	GroupContainerExtraContent = {
		AddToGroup: 'Add to group',
		MoveOutOfGroup: 'Move out of group',
	}
	WorkPackageImportDialog = {
		SuccesfullyAddedWorkpackages: "Succesfully added request templates",
		FailedAddingWorkpackages: "Failed adding request templates"
	}
	RemoveResource: string = `Successfully removed future activities for:`;
	ChangeContractName: string = "Failed changing contract name! The contract does not really exists until work has been added.";
	ChangeContractContainerName: string = "Failed changing contract group name!";
	DropHere: string = 'Drop here';
	NewGroup: string = 'New group';
	GoToProjectAtOrigin: string = 'Go to project at origin';
	CachedSelectedDatesAreInvalid: string = "Cached selected dates are invalid. Please try to re-select using the timeline.";
	Default: string = "Default";
	RequestTemplate: string = "Request Template"
	RequestTemplateRun: string = "Request Template Run"
	WorkWithDrafts: string = "Work with drafts";
	CompareScenarios: string = "Compare scenarios";
	AddedByWorkPackage: string = "Added by request template";
	FromChild: string = "from child";
}