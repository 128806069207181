import { ICustomizations } from "@fluentui/react/lib/Utilities";
import { createTheme } from '@fluentui/react/lib/Styling';
import { IExtendedSemanticColors, IExtendedTheme } from '../Theme.types';
import { palette, extendedSemanticColors } from './colors.default';

export const defaultTheme: IExtendedTheme = createTheme({
	palette: palette,
	semanticColors: extendedSemanticColors as IExtendedSemanticColors,
});

export const defaultScopedSettings = {};

const defaultCustomizations: ICustomizations = {
	settings: { theme: defaultTheme as IExtendedTheme },
	scopedSettings: defaultScopedSettings,
};

// addVariants(defaultCustomizations.settings.theme);

export default defaultCustomizations;