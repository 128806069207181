import { PickerFields, RootSettings } from '../../../../../api/generated/data-contracts';
import { GridType } from '../../../../../Entities/GridType';
import { language } from '../../../../../Services/LocalizationService';
import { functionThatReturnsTrue } from '../../../../../helpers/functionPrimitives';
import { TFilter } from '../types';
import { capitalize } from '../../../../../context/network/http/QueryProvider/helpers/queryHelper';

export const makeResourceFilterDefaults = (groupings: string[]): TFilter[] => {
    if (!groupings.length) {
        return [{ filterFn: functionThatReturnsTrue, type: 'button', grouping: 'all' }];
    }
    return groupings.map(grouping => {
        if (grouping === 'rbs') {
            return { filterFn: functionThatReturnsTrue, type: 'rbs', grouping };
        }
        return { filterFn: functionThatReturnsTrue, type: 'button', grouping };
    });
};

export const getFieldsGroupBy = (settings: RootSettings, gridType: GridType): string => {
    switch (gridType) {
        case GridType.Request:
        case GridType.WorkPackage:
            return settings.pmResourcePickerGroupBy;
        case GridType.Allocation:
        case GridType.ResourcePlanner:
        case GridType.ResourceCapacity:
        default:
            return settings.lmResourcePickerGroupBy;
    }
};

export const getPickerFields = (settings: RootSettings, gridType: GridType): PickerFields => {
    switch (gridType) {
        case GridType.Request:
        case GridType.WorkPackage:
            return settings.pmResourcePickerDisplayFields;
        case GridType.Allocation:
        case GridType.ResourcePlanner:
        case GridType.ResourceCapacity:
        default:
            return settings.lmResourcePickerDisplayFields;
    }
};

export const resolveResourceFieldName = (field: string, settings: RootSettings): string | undefined => {
	if (field === "rbs") {
		return "RBS";
	}
    if (language.Resource[field]) {
        return language.Resource[field];
    }
    // TODO remove this when serialization is stable
    const pascalFieldName = capitalize(field);
    if (language.Resource[pascalFieldName]) {
        return language.Resource[pascalFieldName];
    } else if (field.toLowerCase().startsWith('customproperties')) {
        const customGuid = field.slice('customproperties'.length + 1);
        return settings.resourceCustomProperties.find(_ => {
            return _.id === customGuid;
        })?.displayName;
    }
};
