import { useQuery } from 'react-query';
import { CONTRACT_CUSTOMPROPS } from '../../context/network/http/QueryProvider/queryKeys';
import { EntityType } from '../../Entities/EntityTypes';
import { Plugin } from '../../Services/PluginInvoker';
import { PluginIDs } from '../../Utilities/PluginIDs';
import { PFSpinner } from '../Common/Spinner';
import { ObjectEx } from '../../Utilities/ObjectEx';
import { DateEx } from '../../Utilities/DateEx';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { StringEx } from '../../Utilities/StringEx';

export const ContractInformationDialog = ({ AllProperties, ContractId }: { AllProperties: any; ContractId: string }) => {
	const { data: uiSettings } = useUISettings();
    const { data, isFetching } = useQuery([CONTRACT_CUSTOMPROPS, AllProperties.ResourceId], () =>
        Plugin.Invoke<Record<string, any>>(PluginIDs.GetEntityDetails, {
            EntityId: AllProperties.ResourceId,
            EntityType: EntityType.Contract,
            OverrideSelectedProperties: uiSettings.settings.contractInformationProperties.forEach((key) => StringEx.formatAsTitle(key)),
        })
    );

    return (
        <div>
            {isFetching ? (
                <PFSpinner />
            ) : (
                <div>
                    <div className="ms-CalloutExample-header">
                        <p className="ms-CalloutExample-title">Details</p>
                    </div>
                    <div className="ms-CalloutExample-inner">
                        <div className="ms-CalloutExample-content">
                            <div className="ms-CalloutExample-subText">
                                <table cellPadding={5}>
                                    <tbody>
										<tr key={`contractinfo_contractid`}>
                                                <td>
                                                    <b>Contract Id</b>
                                                </td>
                                                <td>{ContractId}</td>
                                            </tr>
                                        {ObjectEx.keyValuesAsStrings(data, DateEx.asUserLocal).map(_ => (
                                            <tr key={`contractinfo_${_.key}`}>
                                                <td>
                                                    <b>{_.key === 'Id' ? 'ResourceId' : _.key}</b>
                                                </td>
                                                <td>{_.value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
