import { useEffect, useRef, useState } from 'react';
import { IconButton, Label, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { PFDialog } from './PFDialog';
import { DataService } from '../../Services/DataService';
import { EntityType } from '../../Entities/EntityTypes';
import { Settings } from '../../Entities/Main/Settings';
import { PropertyInfoService } from '../../Services/PropertyInfoService';
import { ArrayEx } from '../../Utilities/ArrayEx';
import { ObjectEx } from '../../Utilities/ObjectEx';
import { ObjectEditor } from './ObjectEditor';
import { language } from '../../Services/LocalizationService';
import { UserSettingsService } from '../../Services/Settings/UserSettingsService';
import { refactorHelper } from '../../helpers/refactorHelper';
import { useQueryClient } from 'react-query';
import { UI_SETTINGS_KEY } from '../../context/network/http/QueryProvider/queryKeys';

export const UserSettings = () => {
    const queryClient = useQueryClient();

    const rootSetting = useRef<Settings>();

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [userSettings, setUserSettings] = useState<Settings>(null);
    const [notConfigured, setNotConfigured] = useState<boolean>(false);

    const usedProps = useRef<Array<string>>([]);

    useEffect(() => {
        if (!showDialog) return;
        if (!OverviewContext.Settings.UserSettingPropertiesAvailable?.length) {
            setNotConfigured(true);
            setLoading(false);
            return;
        }
        const fetchData = async () => {
            await LoadData();
        };
        SetHiddenProps();
        fetchData();
    }, [showDialog]);

    const SetHiddenProps = () => {
        // edit props
        const editProps = ArrayEx.difference(
            PropertyInfoService.GetPFProperties(Settings, false).map(_ => _.PropertyName),
            [...OverviewContext.Settings.UserSettingPropertiesAvailable],
        );
        usedProps.current = editProps;
    };

    const LoadData = async () => {
        setLoading(true);
        const result = await UserSettingsService.GetUserSettings();
        rootSetting.current = result.Root;
        setUserSettings(result.User);
        setLoading(false);
    };

    const SaveUserSettings = async (): Promise<void> => {
        await DataService.Upsert<Settings>(EntityType.Settings, [userSettings]);
        OverviewContext.Settings = refactorHelper.parseSettingsToLegacy(userSettings);
        queryClient.refetchQueries([UI_SETTINGS_KEY]);
    };

    const changeUserSettingsProperty = (item, property, value) => {
        const clone = { ...userSettings };
        clone[property] = value;
        setUserSettings(clone);
    };

    const ResetPropertyValueButton = (propName: string, readonly: boolean, setState?: (value: any) => void): JSX.Element => {
        if (readonly) return null;
        return (
            <div className="tp-subsettings-reset">
                <TooltipHost content={language.SubSettingsAdministration.ResetValue}>
                    <IconButton
                        iconProps={{ iconName: 'Refresh' }}
                        onClick={event => {
                            const clone = ObjectEx.deepCopy(rootSetting.current);
                            const value = clone[propName];
                            if (setState) setState(value);
                        }}
                    />
                </TooltipHost>
            </div>
        );
    };

    return (
        <>
            <IconButton id="usersettingsbutton" iconProps={{ iconName: 'PlayerSettings' }} onClick={e => setShowDialog(!showDialog)} />
            <PFDialog
                showDialog={showDialog}
                title={language.UserSettings.UserSettings}
                callback={() => {
                    if (!notConfigured) SaveUserSettings();
                    setShowDialog(false);
                }}
                dismissCallback={() => {
                    setShowDialog(false);
                }}
                maxWidth={500}
            >
                {loading ? (
                    <Spinner size={SpinnerSize.large} label={language.Common.LoadingDot} />
                ) : (
                    <>
                        {notConfigured ? (
                            <Label>{language.UserSettings.AdminDoesNotAllowPersonalSettings}</Label>
                        ) : (
                            <ObjectEditor
                                Item={userSettings}
                                ItemType={Settings}
                                HiddenProperties={usedProps.current}
                                DisabledProperties={['id', 'Created', 'Modified', 'CreatedBy', 'ModifiedBy']}
                                ChangesCallback={changeUserSettingsProperty}
                                PropertyExtraContent={ResetPropertyValueButton}
                            />
                        )}
                    </>
                )}
            </PFDialog>
        </>
    );
};
