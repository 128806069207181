export enum ESettingsLocalization {
	Basic_Misc = "_localization:Settings.Groupings.Basic_Misc",
	Basic_Features = "_localization:Settings.Groupings.Basic_Features",
	PanelsOverviews_Resource_Panel = "_localization:Settings.Groupings.PanelsOverviews_Resource_Panel",
	PanelsOverviews_Resource_Overview = "_localization:Settings.Groupings.PanelsOverviews_Resource_Overview",
	PanelsOverviews_Project_Panel = "_localization:Settings.Groupings.PanelsOverviews_Project_Panel",
	PanelsOverviews_Project_Overview = "_localization:Settings.Groupings.PanelsOverviews_Project_Overview",
	PanelsOverviews_Contract_Overview = "_localization:Settings.Groupings.PanelsOverviews_Contract_Overview",
	GridSettings_Grid = "_localization:Settings.Groupings.GridSettings_Grid",
	GridSettings_Timeline = "_localization:Settings.Groupings.GridSettings_Timeline",
	GridSettings_Actions = "_localization:Settings.Groupings.GridSettings_Actions",
	GridSettings_Grids_PlannerView = "_localization:Settings.Groupings.GridSettings_Grids_PlannerView",
	GridSettings_ResourceCapacity = "_localization:Settings.Groupings.GridSettings_ResourceCapacity",

	Entities_Resource_Generic = "_localization:Settings.Groupings.Entities_Resource_Generic",
	Entities_Resource_Pools = "_localization:Settings.Groupings.Entities_Resource_Pools",
	Entities_Resource_CustomProperties = "_localization:Settings.Groupings.Entities_Resource_CustomProperties",
	Entities_Resource_Misc = "_localization:Settings.Groupings.Entities_Resource_Misc",

	Entities_Project_CustomProperties = "_localization:Settings.Groupings.Entities_Project_CustomProperties",
	Entities_Project_Misc = "_localization:Settings.Groupings.Entities_Project_Misc",
	Entities_Contract_NamingGrouping = "_localization:Settings.Groupings.Entities_Contract_NamingGrouping",
	Entities_Contract_CustomProperties = "_localization:Settings.Groupings.Entities_Contract_CustomProperties",
	Entities_Contract_Misc = "_localization:Settings.Groupings.Entities_Contract_Misc",
	Entities_Contract_AlignStatus = "_localization:Settings.Groupings.Entities_Contract_AlignStatus",
	Entities_Report = "_localization:Settings.Groupings.Entities_Report",
	Entities_WorkPackage = "_localization:Settings.Groupings.Entities_WorkPackage",
	Permissions_Access = "_localization:Settings.Groupings.Permissions_Access",
	Permissions_Specific_Misc = "_localization:Settings.Groupings.Permissions_Specific_Misc",
	Permissions_Specific_Resources = "_localization:Settings.Groupings.Permissions_Specific_Resources",
	Permissions_Specific_Projects = "_localization:Settings.Groupings.Permissions_Specific_Projects",
	Permissions_Specific_Contracts = "_localization:Settings.Groupings.Permissions_Specific_Contracts",
	Permissions_Specific_Workpackages = "_localization:Settings.Groupings.Permissions_Specific_Workpackages",
	Permissions_Specific_Scenarios = "_localization:Settings.Groupings.Permissions_Specific_Scenarios",
	Permissions_Origins = "_localization:Settings.Groupings.Permissions_Origins",

	VersionScenarios = "_localization:Settings.Groupings.VersionScenarios",
	CapacitySettings = "_localization:Settings.Groupings.CapacitySettings",
	CacheSettings = "_localization:Settings.Groupings.CacheSettings",
	SubSettings = "_localization:Settings.Groupings.SubSettings",

	Notifications_InApp = "_localization:Settings.Groupings.Notifications_InApp",
	Notifications_Mail = "_localization:Settings.Groupings.Notifications_Mail",

	LookAndFeel_Theme = "_localization:Settings.Groupings.LookAndFeel_Theme",
	LookAndFeel_Localization = "_localization:Settings.Groupings.LookAndFeel_Localization",
	LookAndFeel_Guide = "_localization:Settings.Groupings.LookAndFeel_Guide",
	LookAndFeel_Navigation = "_localization:Settings.Groupings.LookAndFeel_Navigation",
	System_Info = "_localization:Settings.Groupings.System_Info",
	System_Logging = "_localization:Settings.Groupings.System_Logging",
	System_Backup = "_localization:Settings.Groupings.System_Backup",
	System_Telemitry = "_localization:Settings.Groupings.System_Telemitry",

	PreviewExp_Preview_MSTeams = "_localization:Settings.Groupings.PreviewExp_Preview_MSTeams",
	PreviewExp_Preview_ResourcePools = "_localization:Settings.Groupings.PreviewExp_Preview_ResourcePools",
	PreviewExp_Preview_Charts = "_localization:Settings.Groupings.PreviewExp_Preview_Charts",
	PreviewExp_Preview_VersionScenarios_Versioning = "_localization:Settings.Groupings.PreviewExp_Preview_VersionScenarios_Versioning",
	PreviewExp_Preview_VersionScenarios_Scenarios = "_localization:Settings.Groupings.PreviewExp_Preview_VersionScenarios_Scenarios",
	PreviewExp_Preview_VersionScenarios_AutoAllocation = "_localization:Settings.Groupings.PreviewExp_Preview_VersionScenarios_AutoAllocation",
	PreviewExp_Exp_Reports = "_localization:Settings.Groupings.PreviewExp_Exp_Reports",
	Preview_Grids = "_localization:Settings.Groupings.Preview_Grids",
	Preview_Panels = "_localization:Settings.Groupings.Preview_Panels",
}
