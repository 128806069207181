import { CSSProperties, useCallback, useMemo } from 'react';
import { useGridRowContext } from '../../../../../../../../_grid/contexts/rowContext';
import { AnimationClassNames, CommandBarButton, IButtonStyles, IContextualMenuProps, IIconProps, ILayerProps, IStackProps, Icon, Stack } from '@fluentui/react';
import { useNavigate } from 'react-router';
import { buildRoute } from '../../../../../../../../../../helpers/routeHelpers';
import { ResourceCoinIcon } from './components/ResourceCoinIcon';

export const ResourceHeader = ({ expanded, nestingLevel }: { expanded: boolean; nestingLevel: number }) => {
    const { row, canExpand, toggleExpanded } = useGridRowContext();
    const navigate = useNavigate();

    const handleGotoEntityClick = useCallback(() => {
        navigate(buildRoute('resourcecapacity', row.identity?.id));
    }, [navigate, row.identity?.id]);

    const menuProps = useMemo((): IContextualMenuProps => {
        return {
            items: [
                {
                    key: 'project-link',
                    text: 'Go to Resource',
                    iconProps: { iconName: 'UserGauge' },
                    onClick: handleGotoEntityClick,
                },
            ],
            calloutProps: {
                dismissOnTargetClick: true,
                layerProps: fadeInCalloutLayerStyles,
            },
        };
    }, [handleGotoEntityClick]);
    const toggleRowExpanded = useCallback(() => toggleExpanded(), [toggleExpanded]);
	const titleContainer = useMemo(
        () => ({
            paddingLeft: nestingLevel * 10,
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            transition: 'background-color .3s',
            gap: 8,
        }),
        [nestingLevel],
    );

    return (
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" styles={containerStyles}>
            <div
                style={titleContainer}
            >
                {canExpand && (
                    <Icon
                        // styles={{ root: { transform: 'scale(0.7)', cursor: 'pointer' } }}
                        styles={expandIconStyles}
                        iconName={expanded ? 'ChevronDown' : 'ChevronRight'}
                        onClick={toggleRowExpanded}
                    />
                )}
                <div style={coinContainerStyles} onDoubleClick={handleGotoEntityClick}>
                    <ResourceCoinIcon row={row} />
                </div>
            </div>
            <div>
                <CommandBarButton
                    menuProps={menuProps}
                    menuIconProps={{ iconName: 'More' }}
                    styles={moreButtonStyles}
                />
            </div>
        </Stack>
    );
};
const fadeInCalloutLayerStyles: ILayerProps = {
    styles: {
        content: {
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
        },
    },
    className: AnimationClassNames.fadeIn100,
};
const moreButtonStyles: IButtonStyles = {
    flexContainer: { height: 24 },
    menuIcon: { fontSize: 16 },
    root: { backgroundColor: 'transparent' }
};

const containerStyles: IStackProps['styles'] = { root: { height: '100%', overflow: 'hidden' } };
const expandIconStyles: IIconProps['styles'] = { root: { transform: 'scale(0.7)', cursor: 'pointer' } };
const coinContainerStyles: CSSProperties = {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
};
