import { merge } from '@fluentui/react/lib/Utilities';
import { ITheme, createTheme, IPalette } from '@fluentui/react/lib/Styling';
import { defaultScopedSettings } from './defaultTheme/theme.default';
import { defaultTopbarScopedSettings } from './defaultTheme/topbarTheme.default';

import { extendedSemanticColors as defaultSemanticColors, palette as defaultPalette } from './defaultTheme/colors.default';

export const createCustomizations = (props: any) => {
    let newTheme: any = {};
    let newTopbarTheme: any = {};
    if (props && props.theme) {
        const base = createTheme({ ...props.theme, palette: merge(defaultPalette, props.theme.palette ? props.theme.palette : {}) });
        let mergedSemantic: any = {};
        if (props.theme.semanticColors) {
            mergedSemantic = { ...defaultSemanticColors, ...props.theme.semanticColors };
        } else {
            mergedSemantic = defaultSemanticColors;
        }
        newTheme = { ...base, semanticColors: { ...base.semanticColors, ...mergedSemantic } };
    } else {
        const base = createTheme({ palette: defaultPalette });
        newTheme = { ...base, semanticColors: { ...base.semanticColors, ...defaultSemanticColors } };
    }

    if (props && props.topbarTheme && props.useAdvanced) {
        const base = createTheme({ ...props.topbarTheme, palette: merge(defaultPalette, props.topbarTheme.palette ? props.topbarTheme.palette : {}) });
        let mergedSemantic: any = {};
        if (props.topbarTheme.semanticColors) {
            mergedSemantic = { ...defaultSemanticColors, ...props.topbarTheme.semanticColors };
        } else {
            mergedSemantic = defaultSemanticColors;
        }
        newTopbarTheme = { ...base, semanticColors: { ...base.semanticColors, ...mergedSemantic } };
    } else {
        const base = createTheme({ palette: defaultPalette });
        newTopbarTheme = { ...base, semanticColors: { ...base.semanticColors, ...defaultSemanticColors } };
    }

    return {
        customization: {
            settings: {
                theme: newTheme,
            },
            scopedSettings:
                props && props.themeCustomizations && props.useAdvanced ? { ...defaultScopedSettings, ...props.themeCustomizations } : defaultScopedSettings,
        },
        topbarCustomization: {
            settings: {
                theme: newTopbarTheme,
            },
            scopedSettings:
                props && props.topbarCustomizations && props.useAdvanced
                    ? { ...defaultTopbarScopedSettings, ...props.topbarCustomizations }
                    : defaultTopbarScopedSettings,
        },
    };
};

const DarkDefaultPalette: Partial<IPalette> = {
    themeDarker: '#82c7ff',
    themeDark: '#6cb8f6',
    themeDarkAlt: '#3aa0f3',
    themePrimary: '#2899f5',
    themeSecondary: '#0078d4',
    themeTertiary: '#235a85',
    themeLight: '#004c87',
    themeLighter: '#043862',
    themeLighterAlt: '#092c47',
    black: '#ffffff',
    neutralDark: '#faf9f8',
    neutralPrimary: '#f3f2f1',
    neutralPrimaryAlt: '#c8c6c4',
    neutralSecondary: '#a19f9d',
    neutralSecondaryAlt: '#979693',
    neutralTertiary: '#797775',
    neutralTertiaryAlt: '#484644',
    neutralQuaternary: '#3b3a39',
    neutralQuaternaryAlt: '#323130',
    neutralLight: '#292827',
    neutralLighter: '#252423',
    neutralLighterAlt: '#201f1e',
    white: '#201F1E',
    redDark: '#F1707B',
};

export const DarkTheme: ITheme = createTheme({
    palette: DarkDefaultPalette,
    semanticColors: {
        buttonText: DarkDefaultPalette.black,
        buttonTextPressed: DarkDefaultPalette.neutralDark,
        buttonTextHovered: DarkDefaultPalette.neutralPrimary,
        bodySubtext: DarkDefaultPalette.white,
        disabledBackground: DarkDefaultPalette.neutralQuaternaryAlt,
        inputBackgroundChecked: DarkDefaultPalette.themePrimary,
        menuBackground: DarkDefaultPalette.neutralLighter,
        menuItemBackgroundHovered: DarkDefaultPalette.neutralQuaternaryAlt,
        menuItemBackgroundPressed: DarkDefaultPalette.neutralQuaternary,
        menuDivider: DarkDefaultPalette.neutralTertiaryAlt,
        menuIcon: DarkDefaultPalette.themeDarkAlt,
        menuHeader: DarkDefaultPalette.black,
        menuItemText: DarkDefaultPalette.neutralPrimary,
        menuItemTextHovered: DarkDefaultPalette.neutralDark,
    },
});
