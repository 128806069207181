export const Downgrade: React.FC = () => {
	//TODO PREFERABLY GETS CONTENT FROM BACKEND INSTEAD OF ROUTER 
    return (
        <>
            <div id="downgrade-pdf" style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100vh', overflow: 'hidden' }}>
                <object
                    style={{ height: '100%', width: '100%' }}
                    data="https://projectumcdnstorage.blob.core.windows.net/teamplannerpackages/guides/How_to_Downgrade_Team_Planner_Authentication.pdf"
                    aria-label="guide"
                ></object>
            </div>
        </>
    );
};
