import { MessageBar } from "@fluentui/react";
import { IFormItem, IPropertyRenderProps } from "@wiberg/formbuilder";
import { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { IDynamicMessageBarFieldConfig } from "./config/IDynamicMessageBarFieldConfig";

interface IProps<T extends IFormItem> extends IPropertyRenderProps<T, IDynamicMessageBarFieldConfig<T>, string | undefined> { 
    config?: IDynamicMessageBarFieldConfig<T>;
}

export const MessageBarField = <T extends IFormItem>(props: PropsWithChildren<IProps<T>>) : ReactElement | null => {

	const [options, setOptions] = useState(props.config?.options != null ? props.config.options(props.parent) : null);

	useEffect(() => {
		if (!props.config?.options) return;
		setOptions(props.config.options(props.parent));
	}, [props.config, props.parent])

	if (options == null) return null;

	return 	(
		<MessageBar
			messageBarType={options.type}
			styles={{root: { width: options.width }}}
		>
			{ options.message }
		</MessageBar>
	)
}
