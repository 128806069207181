export class StateManager<TState> {

	public constructor(protected Parent: any) { }
		
	public Get = <T>(getter: (state: TState) => T) : T => {
		return getter(this.Parent.state);
	}

	public Set = (obj: Partial<TState>, callback?: (...args: any) => void) : void => {
		this.Parent.setState(obj, callback);
	}

	public SetAsync = (obj: Partial<TState>) : Promise<void> => {
		const state = {...this.Parent.state}
		Object.assign(state, obj);
		return new Promise((resolve, reject) => {
			this.Parent.setState(state, resolve);		
		});
	}
}