/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useContext, useState, useEffect } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Text } from '@fluentui/react/lib/Text';
import { TableContext } from './Table';
import TableCellRow from './TableCellRow';
import { GroupRow } from '../../../Entities/Table/GroupRow';
import TableRow from './TableRow';
import { TableStateManager } from '../../../Services/TableStateManager';
import { Icon } from '@fluentui/react';
import { LocalStorage } from '../../../Utilities/LocalStorage';
import { GroupContainerRow } from '../../../Entities/Table/GroupContainerRow';
import { CustomColumnUtil } from '../../../Utilities/Grid/CustomColumnUtil';
import { useStore } from '../../../context/store';
import { useLocation } from 'react-router';

interface IProps {
    Group: GroupRow;
    Container?: GroupContainerRow;
    Collapsed?: boolean;
	disabled?: boolean;
}

const TableGroup: FunctionComponent<IProps> = props => {
    const error = useStore(store => store.addErrorNotification);
    const ctx = useContext(TableContext) as TableStateManager;

	const { pathname } = useLocation();

    const [editMode, setEditMode] = useState(false);
    const [previousName, setPreviousName] = useState(props.Group.Name);
    const [collapsed, setCollapsed] = useState<boolean>(
        props.Collapsed || LocalStorage.getProperty<boolean>('tp-grid-collapsed', `${ctx.Get(_ => _.Model.Id)}-${props.Group.Id}`) || false,
    );
	
	const hideGenericResources = (pathname.startsWith('/allocation') || pathname.startsWith('/planner')) && ctx.Get(_ => _.GridSettingsHideGenericResources);

    const [rowsLength] = useState(props.Group.Rows?.length);

    useEffect(() => {
        if (props.Collapsed != null) setCollapsed(props.Collapsed);
    }, [props.Collapsed]);

    useEffect(() => {
        ctx.RefreshTabIndex();
    }, [collapsed]);

    useEffect(() => {
        if (props.Group.Rows.length !== rowsLength) setCollapsed(false);
    }, [props.Group.Rows.length]);

    const EditEnabled = (): boolean => {
        const checker = ctx.Get(_ => _.GroupEditEnabled);
        return checker == null ? false : checker(props.Group);
    };

    return (ctx.Get(_ => _.RowSort)(props.Group)) &&(
        <>	
            {!props.Container && <tr className="row-spacing" />}
            <tr key={`${props.Group.Id}-tr`} className={`tp-grid-group${props.Container ? ' in-container' : ''}`}>
                <td className={`tp-grid-group-header noselect ${ctx.Get(_ => _.GroupClass) != null ? ctx.Get(_ => _.GroupClass)(props.Group) : ''}`}>
                    {(editMode && !props.disabled) ? (
                        <TextField
                            key={`${props.Group.Id}-textfield`}
                            className="groupname-edit"
                            autoFocus
                            defaultValue={props.Group.Name}
                            onKeyUp={e => {
                                if (e.keyCode === 13) e.currentTarget.blur();
                            }}
                            onChange={(obj, value) => (props.Group.Name = value)}
                            onBlur={e => {
                                if (props.Group.Name == null || props.Group.Name === '') {
                                    error('Contract name cannot be empty');
                                    setEditMode(false);
                                    return;
                                }
                                if (!props.Group.Id || props.Group.Name === previousName) {
                                    setEditMode(false);
                                    return;
                                }
                                ctx.Get(_ => _.GroupEdit)(props.Group, previousName, props.Group.Name);
                                setPreviousName(props.Group.Name);
                                setEditMode(false);
                            }}
                        />
                    ) : (
                        <div
                            id={`gtg_${props.Group.Id}`}
                            className="flex-overflow-container noselect"
                            draggable={ctx.Get(_ => _.GroupDraggable)(props.Group)}
                            onDragStart={e => (ctx.Get(_ => _.GroupDragStart) ? ctx.Get(_ => _.GroupDragStart)(props.Group, props.Container, e) : () => {})}
                            onDragEnd={e => (ctx.Get(_ => _.GroupDragEnd) ? ctx.Get(_ => _.GroupDragEnd)(props.Group, e) : () => {})}
                            onClick={e => (ctx.Get(_ => _.GroupClick) ? ctx.Get(_ => _.GroupClick)(props.Group) : () => {})}
                        >
                            {ctx.Get(_ => _.AllowGroupCollapse) && (
                                <div className="tp-grid-group-expandicon-container">
                                    <Icon
                                        className="tp-grid-group-expandicon"
                                        iconName={`${collapsed ? 'ChevronDown' : 'ChevronUp'}`}
                                        onClick={event => {
                                            setCollapsed(!collapsed);
                                            LocalStorage.setProperty('tp-grid-collapsed', `${ctx.Get(_ => _.Model.Id)}-${props.Group.Id}`, !collapsed);
                                        }}
                                    />
                                </div>
                            )}
                            {ctx.Get(_ => _.GroupExtraTextContent) ? ctx.Get(_ => _.GroupExtraTextContent)(props.Group) : null}
                            <Text className="tp-grid-group-header-title" onClick={e => (EditEnabled() ? setEditMode(true) : () => {})}>
                                <div className="truncate-text">
                                    {props.Group.Name}
                                </div>
                            </Text>
							{ ctx.Get(_ => _.GroupExtraContent) ? ctx.Get(_ => _.GroupExtraContent)(props.Group) : null }
                        </div>
                    )}
                </td>
                {CustomColumnUtil.GenerateCustomColumnCellRows(ctx, props.Group)}
                {props.Group.CellRows.map((_, idx) => (
                    <TableCellRow key={`${props.Group.Id}-cellrow-${idx}`} KeyPrefix={`${props.Group.Id}`} CellRow={_} Index={idx} ParentRow={props.Group} />
                ))}
            </tr>
            {!collapsed &&
                ctx
                    .Get(_ => _.RowSort)(props.Group)
					.filter(_ => {
						if (!hideGenericResources) return true;
						return !_.Properties?.IsRequested;
					})
                    .map(
                        (_, idx) => <TableRow key={`${_.Id}_${_.Properties?.IsRequested}`} Group={props.Group} Row={_} InContainer={props.Container != null} />
                    )}
        </>
    );
};

export default TableGroup;
