import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { language } from '../../../../../Services/LocalizationService';
import { useStore } from '../../../../../context/store';
import shallow from 'zustand/shallow';
import { Panel } from '../Panel';
import { ProjectPicker } from './ProjectPicker';
import { useLocation, useMatch } from 'react-router';
import { getRouteFromEnum, MainView } from '../../../../../Entities/MainView';
import { IconButton, IPanelHeaderRenderer, IPanelProps, PanelType } from '@fluentui/react';
import { PROJECTS_KEY } from '../../../../../context/network/http/QueryProvider/queryKeys';
// import { DateEx } from '../../../../../Utilities/DateEx';
import { getGridTypeFromRoute, GridType } from '../../../../../Entities/GridType';
import { useQuery } from 'react-query';
import { ApiCalls } from '../../../../../api/api';
import { DateResolution, ProjectListItem } from '../../../../../api/generated/data-contracts';
import EnumEx from '../../../../../Utilities/EnumEx';
import { useUISettings } from '../../../../../context/network/http/QueryProvider/queries/UISettings';

export const ProjectPanel: React.FC = () => {
    const { setUIContext, showProjectPicker, timelineEnd, timelineStart, timelineResolution } = useStore(
        store => ({
            showProjectPicker: store.ui.showProjectPicker,
            setUIContext: store.setUIContext,
            timelineEnd: store.timeline.end,
            timelineStart: store.timeline.start,
            timelineResolution: store.timeline.resolution,
        }),
        shallow,
    );
    const { pathname } = useLocation();
	const { data: uiSettings } = useUISettings();

    const gridType = useMemo(() => {
        return getGridTypeFromRoute(pathname);
    }, [pathname]);
    const canShow = useMemo(() => {
        const _canShow: boolean = Boolean(
            EnumEx.equalsAny(gridType, GridType.Allocation, GridType.Request, GridType.LmCapacityProjects) &&
                timelineEnd &&
                timelineStart &&
                Object.values(DateResolution).includes(timelineResolution),
        );
        return _canShow;
    }, [gridType, timelineEnd, timelineResolution, timelineStart]);

    useEffect(() => {
        if (showProjectPicker && !canShow) {
            setUIContext({ showProjectPicker: false });
        }
    }, [canShow, setUIContext, showProjectPicker]);

    useEffect(() => {
        if (canShow) {
            const handler = (e: KeyboardEvent) => {
                if (!(e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) && e.key.toLowerCase() === 'a') {
                    setUIContext(ui => ({ showProjectPicker: !ui.showProjectPicker }));
                }
            };
            window.addEventListener('keyup', handler);
            return () => {
                window.removeEventListener('keyup', handler);
            };
        }
    }, [canShow, setUIContext]);
    const isCurrentlyOnPlanner = useMatch(getRouteFromEnum(MainView.LmCapacity));
    const [panelSide, setPanelSide] = useState(PanelType.smallFixedNear);
    const toggleSide = useCallback(
        () => setPanelSide(panelSide => (panelSide === PanelType.smallFixedNear ? PanelType.smallFixedFar : PanelType.smallFixedNear)),
        [],
    );

    const [isDragging, setIsDragging] = useState(false);
    const [showAll, setShowAll] = useState(false);

    const PanelHeader = useCallback(
        (props?: IPanelProps, defaultRender?: IPanelHeaderRenderer) => {
            return (
                <>
                    {/* {Store.Get(_ => _.MainView == MainView.LmCapacity) && ( */}
                    {isCurrentlyOnPlanner && (
                        <IconButton
                            title={language.ProjectPicker.HeaderContent}
                            iconProps={{ iconName: 'Switch' }}
                            styles={{ root: { right: '45px', position: 'absolute' } }}
                            onClick={toggleSide}
                        />
                    )}
                    {defaultRender?.(props)}
                </>
            );
        },
        [isCurrentlyOnPlanner, toggleSide],
    );

    const queryKey = useMemo(
        () => [
            PROJECTS_KEY,
            {
                gridType,
                // end: DateEx.specifyUtc(timelineEnd),
                // start: DateEx.specifyUtc(timelineStart),
                end: timelineEnd,
                start: timelineStart,
                selectAll: showAll,
                timelineResolution,
            },
        ],
        [gridType, showAll, timelineEnd, timelineResolution, timelineStart],
    );

    const { data, isFetching, isFetched } = useQuery(
        queryKey,
        async () => {
            try {
				// new optimized endpoint (for now only for Allocaiton view)
				if ((gridType === GridType.Allocation || gridType === GridType.LmCapacityProjects) && uiSettings.settings.useNewProjectPanelForLineManagers) {
					return await ApiCalls.projectPanelLineManager({
						selectAll: showAll,
						start: timelineStart,
						end: timelineEnd
					}).then(res => res.data.map(project => ({ ...project, customPropertiesAsString: { ...project.customPropertiesAsString } })));
				} 
				// old endpoint
				return await ApiCalls.getProjectsForPanel({
					gridType,
					// end: DateEx.specifyUtc(timelineEnd) as any, //TODO TYPECASTING TO ANY AS WE SEND STRINGS TO API
					// start: DateEx.specifyUtc(timelineStart) as any, //TODO TYPECASTING TO ANY AS WE SEND STRINGS TO API
					end: timelineEnd,
					start: timelineStart,
					selectAll: showAll,
					resolution: timelineResolution,
					// TODO: This is not right
				}).then(res => res.data.map(project => ({ ...project, customPropertiesAsString: { ...project.customPropertiesAsString } })));
            } catch (error) {
                // The error is already handled before this
                return [];
            }
            // }).then(res => {
            //     const data = res.data.flatMap((re, i1) =>
            //         [...res.data, ...res.data.map((r, i) => ({ ...r, id: r.id + i }))].map((res, i2) => ({ ...res, id: res.id + i1 + i2 })),
            //     );
            //     // data.length = Math.floor(data.length / 2);
            //     console.log("🚀 ~ data.length", data.length)
            //     return data;
            // }),
        },
        {
            placeholderData: useRef([] as ProjectListItem[]).current,
            enabled: canShow && showProjectPicker,
            // enabled: canShow,
        },
    );

    return (
        <Panel
            isOpen={showProjectPicker}
            isLightDismiss={true}
            onDismiss={() => setUIContext({ showProjectPicker: false })}
            headerText={language.ProjectPicker.BodyHeaderText}
            closeButtonAriaLabel={language.ProjectPicker.CloseButtonAreaLabel}
            className="tp-panel projects"
            styles={{
                root: {
                    '.ms-Panel-contentInner, .ms-Panel-scrollableContent': { height: '100%', display: 'flex', flexDirection: 'column' },
                    '.ms-Panel-content': { flexGrow: 1, paddingRight: 0, paddingBottom: 0 },
                    // opacity: 0.3,
                    // pointerEvents: "none",
                    opacity: isDragging ? 0.3 : 1,
                    transition: 'opacity .3s',
                },
            }}
            isBlocking={!isCurrentlyOnPlanner}
            onRenderHeader={PanelHeader}
            type={isCurrentlyOnPlanner ? panelSide : PanelType.smallFixedNear}
        >
            <ProjectPicker
                setIsDragging={setIsDragging}
                data={data}
                gridType={gridType}
                isFetching={isFetching}
                queryKey={queryKey}
                setShowAll={setShowAll}
                showAll={showAll}
                isFetched={isFetched}
            />
        </Panel>
    );
};
