import shallow from 'zustand/shallow';
import { DateResolution } from '../api/generated/data-contracts';
import { useStore } from '../context/store';
import {
	getDifferenceInDays,
	getDifferenceInMonths,
	getDifferenceInQuarters,
	getDifferenceInWeeks,
	getDifferenceInYears
} from '../Utilities/DateEx';

export const useGetGridColumnsNum = () => {
	return useStore(store => {
		const { start, end, resolution } = store.timeline;
		switch (resolution) {
			case DateResolution.Day:
				return getDifferenceInDays(start, end);
			case DateResolution.Month:
				return getDifferenceInMonths(start, end);
			case DateResolution.Week:
				return getDifferenceInWeeks(start, end);
			case DateResolution.Quarter:
				return getDifferenceInQuarters(start, end);
			case DateResolution.Year:
			default:
				return getDifferenceInYears(start, end);
		}
	}, shallow);
};
