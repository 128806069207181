import React from 'react';
import classNames from 'classnames';

import styles from './Wrapper.module.scss';

interface Props {
    children: React.ReactNode;
    center?: boolean;
	vertical?: boolean;
    style?: React.CSSProperties;
	fadeIn?: boolean
}

export function Wrapper({ children, center, style, vertical, fadeIn }: Props) {
    return (
        <div className={classNames(styles.Wrapper, center && styles.center, vertical && styles.vertical, fadeIn && styles.fadeIn)} style={style}>
            {children}
        </div>
    );
}
