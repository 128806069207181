import { TreeViewItem } from "../Entities/TreeViewItem"
import { ITreeNode } from "../forms/_builders/tpComplexBuilder/components/tree/interfaces/ITreeNode"
import { changeCase } from "../helpers/changeKeysCase"

const parseToTreeView = (node: ITreeNode) : TreeViewItem => {
	return changeCase.toPascal(node) as TreeViewItem;
}

const parseToTreeNode = (node: TreeViewItem) : ITreeNode => {
	return changeCase.toCamel(node) as ITreeNode;
}

export const treeViewRefactor = {
	parseToTreeNode,
	parseToTreeView
}