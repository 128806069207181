import { CommandBar, SelectionMode, ICommandBarItemProps, Panel, PanelType, PrimaryButton, ShimmeredDetailsList, IContextualMenuItem } from '@fluentui/react';
import { Selection, IColumn, DetailsListLayoutMode, ConstrainMode, IDragDropEvents, IDragDropContext } from '@fluentui/react/lib/DetailsList';
import { IDs } from '../../Utilities/IDs';
import { PFDialog } from './PFDialog';
import { ObjectEditor } from './ObjectEditor';
import { ArrayEx } from '../../Utilities/ArrayEx';
import { JsonEx } from '../../Utilities/JsonEx';
import { ValidationRule } from '../../Entities/Validation/ValidationRule';
import { PropertyInfoService } from '../../Services/PropertyInfoService';
import { PropertyType } from '../../Utilities/PropertyInfo/PropertyType';
import { PropertyInfo } from '../../Utilities/PropertyInfo/PropertyInfo';
import { validationService } from '../../Services/ValidationService';
import { ConfirmDialog, ConfirmInfo } from './ConfirmDialog';
import { Confirm } from '../../Utilities/Confirm';
import { Searcher } from './Searcher';
import { ListBulkEditor } from './ListBulkEditor';
import { IObjectEditorItemType } from '../../Entities/Main/IObjectEditorItemType';
import { DateEx } from '../../Utilities/DateEx';
import { DebugOverrides } from '../../Utilities/DebugOverrides';
import { language } from '../../Services/LocalizationService';
import { ObjectEditorExtraMenuItem } from './FormBuilder/ObjectEditorExtraMenuItem';
import { useStore } from '../../context/store';
import { Component } from 'react';

interface IProps<T extends IObjectEditorItemType> {
    ItemType: { new (): T };
    Items: Array<T>;
    PageTitle?: string;
    HiddenColumnProperties: Array<string>;
    HiddenProperties: Array<string>;
    DisabledProperties: Array<string>;
    EnrichNewItem?: (item: T) => void;
    ItemIdentifier: (item: T) => any;
    ClonedItemChange: (item: T) => void;
    ChangesCallback?: (items: Array<T>, item: T) => void;
    InitialSortFields?: Array<string>;
    UserSortEnabled?: boolean;
    ReadOnly?: boolean;
    SearchEnabled?: boolean;
    SearchFields?: Array<string>;
    SearchPlaceHolder?: string;
    DialogType?: ItemDialogType;
    DialogTitle?: string;
    DialogDescription?: string;
    Shimmer?: boolean;
    ShimmerLines?: number;
    NewItemMenuItems?: Array<{ Title: string; Action: (item: T) => T; Disabled?: boolean }>;
    AdditionalMenuItems?: Array<ObjectEditorExtraMenuItem<T>>;
    HideMenu?: boolean;
    ListClassName?: string;
    MultiSelect?: boolean;
    BulkEditCallback?: (allItems: Array<T>, changedItems: Array<T>) => void;
    BulkDeleteCallback?: (allItems: Array<T>, deletedItems: Array<T>) => void;
    OverrideValidationRules?: boolean;
    ItemsEditable?: (items: Array<T>) => boolean;
    ItemsDeletable?: (items: Array<T>) => boolean;
    UseStringVariants?: Record<string, string>;
    CustomColumns?: Array<IColumn>;
    ColumnOrder?: Array<string>;
    ListWrapperClass?: string;
    PropertyExtraContent?: (object: T, propName: string, readonly: boolean, update?: (value: any) => void) => JSX.Element;
    ExtraDialogFooterContent?: (item: T) => JSX.Element;
    DraggableRowsConfig?: {
        onDrop: (dragItem: T, dropItem: T, reorderedItems: Array<T>) => void;
        onDrag: (dragItem: T) => void;
        restrictedRows?: (item: T) => boolean;
    };
}

interface IState<T> {
    Items: Array<T>;
    FilteredItems: Array<T>;
    ShowDialog: boolean;
    ShowBulkEditDialog: boolean;
    SelectedItem: T;
    SelectedItems: Array<T>;
    EditMode: boolean;
    Columns: Array<IColumn>;
    UnsavedChanges: boolean;
    Shimmer: boolean;
    Commands: Array<ICommandBarItemProps>;
    LocalConfirmInfo: ConfirmInfo;
    FormReadOnly: boolean;
}

export enum ItemDialogType {
    Dialog,
    Panel,
}

export default class ObjectListEditor<T extends IObjectEditorItemType> extends Component<IProps<T>, IState<T>> {
    constructor(props) {
        super(props);

        const properties = PropertyInfoService.GetPFProperties(props.ItemType, false, this.props.HiddenColumnProperties);

        this.state = {
            ShowDialog: false,
            ShowBulkEditDialog: false,
            Items: [],
            FilteredItems: [],
            SelectedItem: this.CreateNewItem(),
            SelectedItems: [],
            EditMode: false,
            Columns: this.GenerateColumns(properties),
            UnsavedChanges: false,
            Shimmer: props.Shimmer,
            Commands: [],
            LocalConfirmInfo: null,
            FormReadOnly: props.ReadOnly,
        };

        // TODO: refactor - ewi
        this._selection = new Selection({
            onSelectionChanged: () => {
                const selected = this._selection.getSelectedIndices();
                if (!selected) return;
                const commands = this.state.Commands;

                if (props.ReadOnly) {
                    const item = selected.length > 0 ? this.state.FilteredItems[selected[0]] : null;
                    commands[0].disabled = item == null;
                    this.SelectedItemOriginal = JsonEx.clone<T>(item);
                    this.setState({ SelectedItem: { ...(item as any) }, Commands: commands, SelectedItems: [] });
                    return;
                }
                const items = ArrayEx.multiSlice<T>(this.state.FilteredItems, ...selected);
                const deleteDisabled = this.props.ItemsDeletable != null && !this.props.ItemsDeletable(items);
                const editDisabled = this.EditEnabled(items);

                // none selected
                if (selected.length === 0) {
                    commands[1] = this.EditCommand;
                    commands[1].disabled = true;
                    commands[2].disabled = true;
                    commands[3].disabled = true;
                    if (this.props.BulkEditCallback) commands[4].disabled = true;
                    this.SelectedItemsOriginal = [];

                    if (this.props.AdditionalMenuItems) {
                        const index = this.props.BulkEditCallback ? 5 : 4;
                        commands[index].subMenuProps.items.forEach(_ => (_.disabled = _.forceDisabled || _.selectionNeeded));
                    }

                    this.setState({ SelectedItems: [], Commands: commands, SelectedItem: null });
                } else if (selected.length > 1) {
                    // multi select
                    commands[1] = this.EditCommand;
                    commands[1].disabled = true;
                    commands[2].disabled = DebugOverrides.Enabled ? false : !this.props.MultiSelect || deleteDisabled;
                    commands[2].onClick = this.BulkDelete;
                    commands[3].disabled = true;
                    if (this.props.BulkEditCallback) commands[4].disabled = DebugOverrides.Enabled ? false : !this.props.BulkEditCallback || editDisabled;

                    if (this.props.AdditionalMenuItems) {
                        const index = this.props.BulkEditCallback ? 5 : 4;
                        commands[index].subMenuProps.items.forEach(_ => (_.disabled = _.forceDisabled || (!_.multiSelect && _.selectionNeeded)));
                    }

                    this.SelectedItemsOriginal = [...items];
                    this.setState({ SelectedItems: items, Commands: commands, SelectedItem: null });
                } else {
                    // single select
                    const item = items[0];
                    commands[1] = editDisabled ? this.ViewCommand : this.EditCommand;

                    // refresh commandbar buttons state
                    commands[1].disabled = false;
                    commands[2].disabled = DebugOverrides.Enabled ? false : deleteDisabled;
                    commands[2].onClick = this.RemoveItem;
                    commands[3].disabled = item == null || (DebugOverrides.Enabled ? false : editDisabled);
                    if (this.props.BulkEditCallback) commands[4].disabled = true;

                    if (this.props.AdditionalMenuItems) {
                        const index = this.props.BulkEditCallback ? 5 : 4;
                        commands[index].subMenuProps.items.forEach(_ => (_.disabled = _.forceDisabled));
                    }

                    this.SelectedItemOriginal = JsonEx.clone<T>(item);
                    this.setState({ SelectedItem: { ...(item as any) }, Commands: commands, SelectedItems: [] });
                }
            },
        });
        this.ValidationRules = this.GenerateValidationRules();
    }

    private _selection: Selection;
    private ValidationRules: Array<ValidationRule>;

    private _draggedItem: T;
    private _draggedIndex: number;

    private SelectedItemOriginal: T = new this.props.ItemType() as T;
    private SelectedItemsOriginal: Array<T> = [];

    private EditEnabled = (items): boolean => !DebugOverrides.Enabled && this.props.ItemsEditable != null && !this.props.ItemsEditable(items);

    private ViewCommand = {
        key: 'view',
        name: language.Common.View,
        disabled: true,
        onClick: e => {
            e.preventDefault();
            this.setState({ ShowDialog: true, EditMode: true, FormReadOnly: true });
        },
        iconProps: { iconName: 'Zoom' },
    };
    private EditCommand = {
        key: 'edit',
        name: language.Common.Edit,
        disabled: true,
        onClick: e => {
            e.preventDefault();
            this.setState({ ShowDialog: true, EditMode: true, FormReadOnly: false });
        },
        iconProps: { iconName: 'Edit' },
    };

    async componentWillReceiveProps(nextProps: IProps<T>) {
        if ((!nextProps.Items || nextProps.Items === this.props.Items) && this.props.Shimmer === nextProps.Shimmer) return;
        const sorted = this.RefreshColumnSort([...nextProps.Items]); // TODO: is this still used?? - ewi
        this.setState({ Items: [...sorted], FilteredItems: [...sorted], Shimmer: nextProps.Shimmer });
    }

    componentDidCatch(error, info) {
        console.log(error, info);
    }

    private CreateNewItem = (): T => {
        const item = new this.props.ItemType() as T;
        if (this.props.EnrichNewItem) this.props.EnrichNewItem(item);
        return item;
    };

    private GenerateNewButton = (): ICommandBarItemProps => {
        const newButton = { key: 'new', name: language.Common.New, iconProps: { iconName: 'Add' } } as ICommandBarItemProps;

        if (!this.props.NewItemMenuItems)
            newButton.onClick = e => {
                e.preventDefault();
                this.setState({ ShowDialog: true, EditMode: false, SelectedItem: this.CreateNewItem() });
            };
        else {
            newButton.subMenuProps = { items: [] };
            this.props.NewItemMenuItems.forEach(_ => {
                const menuItem = {
                    key: _.Title,
                    text: _.Title,
                    disabled: _.Disabled,
                    onClick: (e, item) => {
                        e.preventDefault();
                        this.setState({ ShowDialog: true, EditMode: false, SelectedItem: _.Action(this.CreateNewItem() as T) });
                    },
                } as IContextualMenuItem;
                newButton.subMenuProps.items.push(menuItem);
            });
        }

        return newButton;
    };

    private GenerateExtraButton = (): ICommandBarItemProps => {
        if (!this.props.AdditionalMenuItems) return;

        const extraButton = {
            key: 'addactions',
            name: language.ObjectListEditor.AdditionalActions,
            iconProps: { iconName: 'SetAction' },
        } as ICommandBarItemProps;

        extraButton.subMenuProps = { items: [] };
        this.props.AdditionalMenuItems.forEach(_ => {
            const menuItem = {
                key: _.Title,
                text: _.Title,
                multiSelect: _.MultiSelect,
                forceDisabled: _.disabled,
                title: _.tooltip,
                disabled: _.disabled || _.SelectionNeeded,
                selectionNeeded: _.SelectionNeeded,
                onClick: (e, item) => _.Action(this.state.SelectedItem ? [this.state.SelectedItem] : this.state.SelectedItems) as any,
                subMenuProps: _.subMenuProps?.length
                    ? {
                          items: _.subMenuProps.map(sub => {
                              return {
                                  key: sub.Title,
                                  text: sub.Title,
                                  disabled: false,
                                  onClick: (e, item) => sub.Action(this.state.SelectedItem ? [this.state.SelectedItem] : this.state.SelectedItems) as any,
                              };
                          }),
                      }
                    : null,
            } as IContextualMenuItem;
            extraButton.subMenuProps.items.push(menuItem);
        });

        return extraButton;
    };

    async componentDidMount() {
        let commands: Array<ICommandBarItemProps>;
        if (this.props.ReadOnly) {
            commands = [this.ViewCommand];
        } else {
            commands = [
                this.GenerateNewButton(),
                this.EditCommand,
                {
                    key: 'remove',
                    name: language.Common.Remove,
                    disabled: true,
                    onClick: e => {
                        e.preventDefault();
                        this.RemoveItem();
                    },
                    iconProps: { iconName: 'Delete' },
                },
                {
                    key: 'clone',
                    name: language.ObjectListEditor.CopyToNew,
                    disabled: true,
                    onClick: e => {
                        e.preventDefault();
                        this.CloneItem();
                    },
                    iconProps: { iconName: 'Copy' },
                },
            ];
            if (this.props.BulkEditCallback)
                commands.push({
                    key: 'bulkEdit',
                    name: language.Common.BulkEdit,
                    disabled: true,
                    onClick: e => {
                        e.preventDefault();
                        this.setState({ ShowBulkEditDialog: true });
                    },
                    iconProps: { iconName: 'TripleColumnEdit' },
                });
            if (this.props.AdditionalMenuItems) {
                commands.push(this.GenerateExtraButton());
            }
        }

        let items = this.props.Items ? [...this.props.Items] : [];
        if (this.props.InitialSortFields && this.props.InitialSortFields.length) items = ArrayEx.sort(items, ...this.props.InitialSortFields);
        this.setState({ Items: items, FilteredItems: items, Commands: commands });
    }

    private GenerateValidationRules = (): Array<ValidationRule> => {
        const properties = PropertyInfoService.GetPFProperties(this.props.ItemType, false);
        return properties.filter(_ => _.Validation).map(_ => _.Validation);
    };

    private GenerateColumns = (properties: Array<PropertyInfo>): Array<IColumn> => {
        const columns = properties.map((propertyInfo, idx) => {
            const key =
                this.props.UseStringVariants && Object.hasOwn(this.props.UseStringVariants, propertyInfo.PropertyName)
                    ? this.props.UseStringVariants[propertyInfo.PropertyName]
                    : propertyInfo.PropertyName;
            const column = {
                key: key,
                name: propertyInfo.DisplayName,
                fieldName: propertyInfo.PropertyName,
                isResizable: true,
                onRender: this.GetColumnRender(propertyInfo),
                minWidth: 200,
            } as IColumn;
            if (this.props.UserSortEnabled) {
                column.onColumnClick = (ev, col) => this.OnColumnClick(ev, col, propertyInfo.Type === PropertyType.Date);
                column.data = propertyInfo.Type !== PropertyType.Date ? 'string' : 'number'; //TODO: do right ;)
            }
            if (propertyInfo.ListColumnWidth) column.minWidth = propertyInfo.ListColumnWidth;
            return column;
        });

        if (this.props.CustomColumns)
            columns.push(
                ...this.props.CustomColumns.map(column => {
                    if (this.props.UserSortEnabled) column.onColumnClick = (ev, col) => this.OnColumnClick(ev, col, (column as any).isDate); // '(column as any).isDate' hack to support custom column of type date in objectlisteditor
                    return column;
                }),
            );

        if (this.props.ColumnOrder) ArrayEx.sortByArray(columns, item => item.key, this.props.ColumnOrder);

        return columns;
    };

    private OnColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn, isDate: boolean): void => {
        const { Columns, FilteredItems } = this.state;
        const newColumns: IColumn[] = Columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = null;
            }
        });

        // stupid hack - ewi
        const newItems = ArrayEx.sortEmptyOrNullLast(FilteredItems, this.GetDisplayField(currColumn.fieldName!), currColumn.isSortedDescending, isDate); // yes that ascending/descending looks suspicious, but it's by design #fuckfluentui
        this.setState({ Columns: newColumns, FilteredItems: newItems });
    };

    // TODO: is this still used?? - ewi
    private RefreshColumnSort = (items: Array<T>): Array<T> => {
        const { Columns } = this.state;
        const currColumn: IColumn = Columns.find(_ => _.isSorted);
        if (currColumn == null) {
            if (this.props.InitialSortFields && this.props.InitialSortFields.length) {
                const sortFields = this.props.InitialSortFields.map(_ => this.GetDisplayField(_));
                return ArrayEx.sort(items, ...sortFields);
            }
            return items;
        }

        // is date? -- stupid hack - ewi
        const newItems = ArrayEx.sortEmptyOrNullLast(
            items,
            this.GetDisplayField(currColumn.fieldName!),
            currColumn.isSortedDescending,
            (currColumn as any).isDate,
        ); // yes that ascending/descending looks suspicious, but it's by design #fuckfluentui
        return newItems;
    };

    private GetDisplayField = (fieldName: string): string => {
        if (this.props.UseStringVariants) {
            const displayField = this.props.UseStringVariants[fieldName];
            if (displayField) return displayField;
        }

        return fieldName;
    };

    private GetColumnRender = (propertyInfo: PropertyInfo): ((item?: any, index?: number, column?: IColumn) => any) => {
        if (this.props.UseStringVariants) {
            const stringValueProperty = this.props.UseStringVariants[propertyInfo.PropertyName];
            if (stringValueProperty) return _ => <>{_[stringValueProperty]}</>;
        }
        if (propertyInfo.ListColumnRender) return propertyInfo.ListColumnRender;
        switch (propertyInfo.Type) {
            case PropertyType.Boolean:
                return _ => <>{_[propertyInfo.PropertyName] ? 'true' : 'false'}</>;
            case PropertyType.Date:
                return _ => (_[propertyInfo.PropertyName] ? <>{DateEx.asUserLocal(new Date(_[propertyInfo.PropertyName]))}</> : null);
            case PropertyType.Object:
                return _ => <>No render specified</>;
            case PropertyType.ObjectArray:
                return _ => <>{ArrayEx.joinToString(_[propertyInfo.PropertyName] as Array<any>, '|')}</>;
            case PropertyType.SimpleArray:
            case PropertyType.PredefinedArray:
                return _ => <>{_[propertyInfo.PropertyName]}</>;
            case PropertyType.PredefinedArrayMulti:
                return _ => <>{ArrayEx.joinToString(_[propertyInfo.PropertyName] as Array<any>, '|')}</>;
            case PropertyType.Dictionary:
                return item => (
                    <>
                        {ArrayEx.joinToString(
                            Object.keys(item[propertyInfo.PropertyName] || {}).map(_ => item[propertyInfo.PropertyName][_]),
                            '|',
                        )}
                    </>
                );
            case PropertyType.Json:
                return item => <>{JsonEx.tryStringify(item[propertyInfo.PropertyName])}</>;
            default:
                return null;
        }
    };

    private BulkEdit = (changedItems: Array<T>) => {
        const items = [...this.state.Items];

        changedItems.forEach(_ => {
            const index = this.state.Items.findIndex(item => this.props.ItemIdentifier(item) === this.props.ItemIdentifier(_));
            items[index] = _;
        });

        this.setState({ Items: items, FilteredItems: items, ShowBulkEditDialog: false, UnsavedChanges: false, LocalConfirmInfo: null }, () => {
            if (this.props.BulkEditCallback) this.props.BulkEditCallback(items, this.state.SelectedItems);
        });
    };

    private BulkDelete = () => {
        const confirmDto = {
            show: true,
            title: language.Common.ConfirmDeletionOfItems,
            subtext: language.Common.CannotBeUndone,
            confirmAction: () => {
                const items = [...this.state.Items];

                const shouldRemove = (item: T) => this.state.SelectedItems.some(_ => this.props.ItemIdentifier(item) === this.props.ItemIdentifier(_));

                //remove items
                const removed = ArrayEx.removeMultiple(items, shouldRemove);

                this.setState(
                    { Items: items, FilteredItems: items, ShowBulkEditDialog: false, UnsavedChanges: false, LocalConfirmInfo: null, SelectedItems: [] },
                    () => {
                        this._selection.setAllSelected(false);
                        if (this.props.BulkDeleteCallback) this.props.BulkDeleteCallback(items, removed);
                        else this.props.ChangesCallback(items, null);
                    },
                );
            },
            dismissAction: () => this.setState({ LocalConfirmInfo: null }),
            okButtonText: language.Common.Ok,
            cancelButtonText: language.Common.Cancel,
            hideCancelButton: true,
        };

        this.setState({ LocalConfirmInfo: confirmDto });
    };

    private NewItem = (): void =>
        this.ItemAction((items, i) => {
            items.push(this.state.SelectedItem);
            return this.state.SelectedItem;
        }, true);

    private EditItem = (): void =>
        this.ItemAction((items, i) => {
            items[i] = this.state.SelectedItem;
            return this.state.SelectedItem;
        }, true);

    private RemoveItem = (): void => {
        // let deleteDisabled = this.props.UnDeletableItemsIds && this.props.UnDeletableItemsIds.some(_ => this.props.ItemIdentifier(this.state.SelectedItem) == _);
        // let confirmDto: ConfirmInfo;

        // if (deleteDisabled)
        // 	confirmDto = new ConfirmInfo(true,
        // 		"This item cannot be deleted!", "The app depends on this item and you are therefore not allowed to remove it..",
        // 		() => this.setState({LocalConfirmInfo: null}),
        // 		() => this.setState({LocalConfirmInfo: null}));
        // else
        const confirmDto = {
            show: true,
            title: language.Common.ConfirmDeletionOfItems,
            subtext: language.Common.CannotBeUndone,
            confirmAction: () => {
                this.ItemAction((items, i) => {
                    items.splice(i, 1);
                    return this.state.SelectedItem;
                });
                this._selection.setAllSelected(false);
            },

            dismissAction: () => this.setState({ LocalConfirmInfo: null }),
            okButtonText: language.Common.Ok,
            cancelButtonText: language.Common.Cancel,
        };

        this.setState({ LocalConfirmInfo: { ...confirmDto } });
    };

    private CloneItem = (): void =>
        this.ItemAction((items, i) => {
            const clone = JsonEx.clone<T>(this.state.SelectedItem);
            this.props.ClonedItemChange(clone);
            items.push(clone);
            return clone;
        });

    private ItemAction = (action: (t: Array<T>, index: number) => T, validate?: boolean): void => {
        if (validate) {
            // let validated = validationService.validate(this.state.SelectedItem, this.ValidationRules, true);
            // TODO Re-enable notification handling
            const validated = validationService.validate(this.state.SelectedItem, this.ValidationRules, useStore.getState().addErrorNotification);
            // let validated = validationService.validate(this.state.SelectedItem, this.ValidationRules, true);
            // continue if validation should be overridden (notifications still happen)
            if (!validated && !this.props.OverrideValidationRules) return;
        }

        const index = this.state.Items.findIndex(_ => this.props.ItemIdentifier(_) === this.props.ItemIdentifier(this.state.SelectedItem));
        const items = [...this.state.Items];
        const item = action(items, index);

        const filteredIndex = this.state.FilteredItems.findIndex(_ => this.props.ItemIdentifier(_) === this.props.ItemIdentifier(this.state.SelectedItem));
        const filteredItems = [...this.state.FilteredItems];
        action(filteredItems, filteredIndex);

        // update original fallback object
        this.SelectedItemOriginal = item;

        this.setState({ Items: items, FilteredItems: filteredItems, ShowDialog: false, UnsavedChanges: false, LocalConfirmInfo: null }, () => {
            if (this.props.ChangesCallback) this.props.ChangesCallback(items, item);
        });
    };

    private DismissPanel = (): void => {
        if (this.state.UnsavedChanges)
            Confirm.Instance.Show({
                show: true,
                title: language.Common.YouHaveUnsavedChanges,
                subtext: language.Common.ConfirmDiscardChanges,
                confirmAction: this.Dismiss,
                dismissAction: () => this.setState({ LocalConfirmInfo: null }),
                okButtonText: 'Discard changes',
                cancelButtonText: language.Common.Cancel,
            });
        else this.Dismiss();
    };

    private Dismiss = (): void => {
        if (this.SelectedItemOriginal) {
            const idx = this.state.FilteredItems.findIndex(_ => this.props.ItemIdentifier(_) === this.props.ItemIdentifier(this.SelectedItemOriginal));
            if (idx >= 0) this.state.FilteredItems[idx] = JsonEx.clone<T>(this.SelectedItemOriginal);
        }
        this.setState({
            ShowDialog: false,
            UnsavedChanges: false,
            FilteredItems: this.state.FilteredItems,
            SelectedItem: JsonEx.clone<T>(this.SelectedItemOriginal),
            LocalConfirmInfo: null,
            Shimmer: false,
        });
    };

    private SetChanges = (): void => {
        if (!this.state.UnsavedChanges) this.setState({ UnsavedChanges: true });
    };

    private OnItemInvoked(item: any, index: number | undefined): void {
        this.setState({ ShowDialog: true, EditMode: true, FormReadOnly: DebugOverrides.Enabled ? false : this.EditEnabled([item]) });
    }

    private GetDragDropEvents(): IDragDropEvents {
        return {
            canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) =>
                this.props.DraggableRowsConfig.restrictedRows == null || !this.props.DraggableRowsConfig.restrictedRows(dropContext.data),
            canDrag: (item?: any) => this.props.DraggableRowsConfig.restrictedRows == null || !this.props.DraggableRowsConfig.restrictedRows(item),
            onDragEnter: (item?: any, event?: DragEvent) => null,
            onDragLeave: (item?: any, event?: DragEvent) => null,

            onDrop: (item?: any, event?: DragEvent) => {
                if (this._draggedItem) {
                    const reorderedItems = this.InsertBeforeItem(item);
                    this.props.DraggableRowsConfig.onDrop(this._draggedItem, item, reorderedItems);
                }
            },
            onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                this._draggedItem = item;
                this._draggedIndex = itemIndex!;
                this.props.DraggableRowsConfig.onDrag(this._draggedItem);
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                this._draggedItem = undefined;
                this._draggedIndex = -1;
            },
        };
    }

    private InsertBeforeItem(item: T): Array<T> {
        const draggedItems = this._selection.isIndexSelected(this._draggedIndex) ? (this._selection.getSelection() as T[]) : [this._draggedItem!];

        const insertIndex = this.state.FilteredItems.indexOf(item);
        const items = this.state.FilteredItems.filter(itm => draggedItems.indexOf(itm) === -1);

        items.splice(insertIndex, 0, ...draggedItems);

        // clear previous selection indexes
        const prevSelectedIndexes = this._selection.getSelectedIndices();
        prevSelectedIndexes.forEach(prev => {
            this._selection.setIndexSelected(prev, false, false);
        });

        // set new selection indexes
        const newIndexes = [...Array(prevSelectedIndexes.length)].map((v, i) => insertIndex + i);
        newIndexes.forEach(prev => {
            this._selection.setIndexSelected(prev, true, false);
        });

        this.setState({ FilteredItems: items });

        return items;
    }

    render() {
        return (
            <>
                {this.state.ShowDialog && this.props.DialogType !== ItemDialogType.Panel ? (
                    <PFDialog
                        buttonText={language.Common.Ok}
                        key={IDs.makeId()}
                        minWidth={600}
                        maxWidth={800}
                        showDialog={this.state.ShowDialog}
                        title={this.props.DialogTitle}
                        subText={this.props.DialogDescription}
                        callback={this.state.EditMode ? this.EditItem : this.NewItem}
                        buttonDefaultDisabled={false}
                        dismissCallback={this.Dismiss}
                        extraDialogFooterContent={this.props.ExtraDialogFooterContent ? this.props.ExtraDialogFooterContent(this.state.SelectedItem) : null}
                    >
                        {
                            <ObjectEditor
                                ItemType={this.props.ItemType}
                                Item={this.state.SelectedItem}
                                HiddenProperties={this.props.HiddenProperties}
                                DisabledProperties={this.props.DisabledProperties}
                                ReadOnly={this.props.ReadOnly || this.state.FormReadOnly}
                                PropertyExtraContent={
                                    this.props.PropertyExtraContent
                                        ? (propName, update) => this.props.PropertyExtraContent(this.state.SelectedItem, propName, update)
                                        : null
                                }
                            />
                        }
                    </PFDialog>
                ) : (
                    <Panel
                        isOpen={this.state.ShowDialog}
                        onDismiss={this.DismissPanel}
                        isLightDismiss={false}
                        type={PanelType.smallFixedFar}
                        className="tp-panel tp-panel-editor"
                        headerText={this.props.DialogTitle}
                        layerProps={{}}
                        overlayProps={{}}
                        focusTrapZoneProps={{}}
                        isBlocking={true}
                        onOuterClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                        }}
                    >
                        <div className="save-button">
                            <PrimaryButton
                                text={language.Common.Save}
                                onClick={this.state.EditMode ? this.EditItem : this.NewItem}
                                disabled={this.props.ReadOnly || this.state.FormReadOnly}
                            />
                        </div>
                        <ObjectEditor
                            ItemType={this.props.ItemType}
                            Item={this.state.SelectedItem}
                            HiddenProperties={this.props.HiddenProperties}
                            DisabledProperties={this.props.DisabledProperties}
                            ChangesCallback={this.SetChanges}
                            ReadOnly={this.props.ReadOnly || this.state.FormReadOnly}
                            PropertyExtraContent={
                                this.props.PropertyExtraContent
                                    ? (propName, update) => this.props.PropertyExtraContent(this.state.SelectedItem, propName, update)
                                    : null
                            }
                        />
                    </Panel>
                )}

                <div className="tp-overview-toolbar">
                    {this.props.PageTitle && <h2>{this.props.PageTitle}</h2>}
                    {this.props.SearchEnabled && (
                        <Searcher
                            Items={this.state.Items}
                            OnSearch={(filteredItems: Array<T>, searchText: string) => {
                                this.setState({ FilteredItems: filteredItems, SelectedItems: [] });
                                this._selection.setAllSelected(false);
                            }}
                            ClassName="tp-searcher"
                            Delay={600}
                            Disabled={!this.state.Items || this.state.Items.length === 0}
                            SearchPlaceHolder={this.props.SearchPlaceHolder}
                            EnableTextHighlight={true}
                            SearchFields={this.props.SearchFields}
                            HighLightElements={() =>
                                Array.from(document.querySelectorAll('.ms-List .ms-DetailsRow-cell:not(.ms-DetailsRow-cellCheck)')).filter(
                                    _ => this.props.SearchFields.findIndex(sf => sf === _.attributes['data-automation-key'].value) >= 0,
                                )
                            }
                            FilterOnItemsChange
                        />
                    )}
                    {!this.props.HideMenu && <CommandBar items={this.state.Commands} styles={{ root: { padding: 0 } }} />}
                </div>
                <div className={this.props.ListWrapperClass}>
                    <ShimmeredDetailsList
                        className={this.props.ListClassName != null ? this.props.ListClassName : 'tp-objectlisteditor-list'}
                        columns={this.state.Columns}
                        items={this.state.FilteredItems}
                        selectionMode={this.props.MultiSelect && !this.props.ReadOnly ? SelectionMode.multiple : SelectionMode.single}
                        selectionPreservedOnEmptyClick={true}
                        selection={this._selection}
                        enableShimmer={this.state.Shimmer}
                        shimmerLines={this.props.ShimmerLines || 20}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        constrainMode={ConstrainMode.unconstrained}
                        setKey={'itemId'}
                        getKey={(item: T) => (item != null ? this.props.ItemIdentifier(item) : IDs.makeId())}
                        onItemInvoked={this.OnItemInvoked.bind(this)}
                        dragDropEvents={this.props.DraggableRowsConfig == null ? null : this.GetDragDropEvents()}
                    />
                    <ConfirmDialog ConfirmInfo={this.state.LocalConfirmInfo} />
                    {this.state.ShowBulkEditDialog && ( // TODO: hardcoded properties!!
                        <ListBulkEditor
                            ItemType={this.props.ItemType as any}
                            Items={this.state.SelectedItems}
                            HiddenProperties={this.props.DisabledProperties.concat([
                                'id',
                                'Origins',
                                'RelatedGenericResourceId',
                                'Email',
                                'EnableSpecificWorkprofile',
                                'SpecificWorkprofileId',
                            ])}
                            Update={this.BulkEdit}
                            Dismiss={() => this.setState({ ShowBulkEditDialog: false, SelectedItems: this.SelectedItemsOriginal })}
                        />
                    )}
                </div>
            </>
        );
    }
}
