import { IColumn } from "@fluentui/react";
import { IFormSchema } from "spark-forms";
import { CustomProperty, CustomPropertyType, ITPEntityWithStringVariants } from "../../../api/generated/data-contracts";
import { refactorHelper } from "../../../helpers/refactorHelper";
import { DateEx } from "../../../Utilities/DateEx";
import { ListColumnEnhanced } from "../../../_components/OverviewCreator/types/ListColumnEnhanced";
import { HighlightComponent, highlightWrapper } from "../_components/HighlightComponent";
import { handleValueType } from "../../../Utilities/PanelUtil";

export const generateOverviewColums = <T,>(type: 'resource' | 'project' | 'contract', schema: IFormSchema<T>, extraOptions?: { [field: string]: Partial<ListColumnEnhanced<ITPEntityWithStringVariants, any>> }) => {
	const fields = refactorHelper.getOverviewProperties(type);
	const columns: IColumn[] = [];
	const customProperties = refactorHelper.getCustomProperties(type);

	fields.forEach(field => {
		let column: IColumn;
		if (field.startsWith('customProperties.')){
			column = generateCustomColumn(field, customProperties);
		} else {
			column = generateFieldcolumn(field, schema, extraOptions);
		}
		if (column) {
			columns.push(column);
		}
	})
	return columns;
}

export const generateSchemaColums = <T,>(schema: IFormSchema<T>, extraOptions?: { [field: string]: Partial<ListColumnEnhanced<ITPEntityWithStringVariants, any>> }) => {
	const columns: IColumn[] = [];

	Object.keys(schema.fields).forEach(key => {
		const column = generateFieldcolumn(key, schema, extraOptions);
		if (column) {
			columns.push(column);
		}
	})
	return columns;
}

const generateFieldcolumn = <T,>(fieldName: string, schema: IFormSchema<T>, extraOptions?: { [field: string]: Partial<ListColumnEnhanced<ITPEntityWithStringVariants, any>> }) => {
		const schemaFields = schema.fields[fieldName];
		if (schemaFields == null) {
			return null;
		}
		const extra = extraOptions[fieldName] ?? {};
		return {
			key: fieldName,
			fieldName: fieldName,
			name: schemaFields.displayName,
			isResizable: true,
			minWidth: 200,
			...extra,
			...wrapOnRender(fieldName, extra)
		} as IColumn;
}

const fieldValueParser = (item: ITPEntityWithStringVariants, fieldName: string) => {
	let val = null;
	if (Object.hasOwn(item, `${fieldName}AsString`)) {
		val = item[`${fieldName}AsString`];
	} else {
		val = item[fieldName];
	}
	return handleValueType(val);
}

const wrapOnRender = (fieldName: any, extra: Partial<ListColumnEnhanced<ITPEntityWithStringVariants, any>>) => {
	if (extra?.onRender) {
		if (extra.data?.noHighlight) {
			return { onRender: extra?.onRender };
		}
		return { onRender: highlightWrapper(extra.onRender as any) };
	} else {
		if (extra.data?.noHighlight) {
			return { onRender: item => fieldValueParser(item, fieldName) };
		}
		return { onRender: item => <HighlightComponent text={fieldValueParser(item, fieldName)} /> };
	}
}

const generateCustomColumn = (fieldName: string, customProperties: CustomProperty[]) => {
	const nameIndex = fieldName.indexOf('.');
	const strippedName = fieldName.substring(nameIndex + 1);
	const cp = customProperties.find(_ => _.id === strippedName);
	if (cp == null) {
		return;
	}
	const column: IColumn = {
		key: `customProperties.${cp.id}`,
		fieldName: `customProperties.${cp.id}`,
		name: cp.displayName,
		isResizable: true,
		minWidth: 200,
		onRender: highlightWrapper(item => {
			if (cp.type === CustomPropertyType.Date) {
			return (
				item.customProperties?.[strippedName] &&
				DateEx.asUserLocal(new Date(item.customProperties[strippedName]))
				);
			}
			if (cp.type === CustomPropertyType.Url){
				if(item?.customProperties?.[strippedName] != null){
					return <a href={item?.customProperties?.[strippedName]}>{item?.customProperties?.[strippedName]}</a>
				}
			}
			return item.customPropertiesAsString?.[strippedName];
		}),
	};
	return column;
}