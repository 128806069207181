import { TreeItem } from "../../../TreeItem";
import { TreeMatchDirection } from "../../../MatchDirection";
import { TreeItemEx } from "../../../../Utilities/TreeItemEx";

export class LookupTree {
	public Tree: TreeItem;
	public MatchDirection: TreeMatchDirection;
	public Delimiter: string = ".";

	constructor() {
		this.MatchDirection = TreeMatchDirection.Down;
		this.Tree = TreeItemEx.CreateRoot();
	}
}