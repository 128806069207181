import { getScrollbarWidth } from '@fluentui/react';
import { includeKeys, produce } from '../helpers';
import { PersistGetter, TPContext } from '../types';

interface UI {
    topbarAlwaysOpen: boolean;
    topbarMenuItemOpen: boolean;
    topbarStayOpen: boolean;
    showOverlayButtons: boolean;
    bottomHasScroll: boolean;
    scrollToAddedResource: string;
	forcePinnedTopbar: boolean;
    // environmentState: EnvironmentState;
    scrollbarWidth: number;
    showNotifications: boolean;
    showResourcePicker: boolean;
    showProjectPicker: boolean;
    delegationMode: boolean;
    showCurrentUserInfo: boolean;
    doCelebration: boolean;
    gridHasChanged: boolean;
}

export interface IUIContext {
    ui: UI;
    readonly setUIContext: (ui: Partial<UI> | ((ui: Partial<UI>) => Partial<UI>)) => void;
}

const getDefaults = () => {
    const defaults: UI = {
        topbarAlwaysOpen: true,
        topbarMenuItemOpen: false,
        topbarStayOpen: false,
		forcePinnedTopbar: false,
        bottomHasScroll: false,
        showOverlayButtons: true,
        scrollToAddedResource: '',
        scrollbarWidth: getScrollbarWidth(),
        showNotifications: false,
        showResourcePicker: false,
        showProjectPicker: false,
        delegationMode: false,
        showCurrentUserInfo: false,
        doCelebration: false,
        gridHasChanged: false,
    };
    return defaults;
};

export const uiContext: TPContext<IUIContext> = (set, get, api) => {
    return {
        ui: getDefaults(),
        setUIContext: ui =>
            set(
                produce(store => {
                    if (typeof ui === 'function') {
                        store.ui = { ...store.ui, ...ui(store.ui) };
                    } else {
                        store.ui = { ...store.ui, ...ui };
                    }
                }),
            ),
    };
};

export const getPersistUIContext: PersistGetter = store => {
    try {
        return {
            ui: {
                ...(store.ui
                    ? {
                          //
                          ...getDefaults(),
                          ...includeKeys(store.ui, ['topbarAlwaysOpen']),
                      }
                    : getDefaults()),
            },
        };
    } catch (error) {
        console.error('getPersisUIContext ~ error', error);
        return {
            ui: getDefaults(),
        };
    }
};
