import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { ResourceType } from '../../../../../../../../Entities/ResourceType';
import { GridType } from '../../../../../../../../Entities/GridType';
import { language } from '../../../../../../../../Services/LocalizationService';
import { ProjectType, RootSettings, UserType } from '../../../../../../../../api/generated/data-contracts';
import { IconButton, Spinner, Stack, PersonaCoin, Text, SpinnerSize, Icon } from '@fluentui/react';
import { PanelUtil } from '../../../../../../../../Utilities/PanelUtil';
import { ListItemPercentageBar } from '../../../../../../../../Components/Common/ListItemPercentageBar';
import { JsonEx } from '../../../../../../../../Utilities/JsonEx';
import { useMutation } from 'react-query';
import { ApiCalls } from '../../../../../../../../api/api';
import { OverviewContext } from '../../../../../../../../Utilities/Context/OverviewContext';
import { useStore } from '../../../../../../../../context/store';
import { EntityType } from '../../../../../../../../Entities/EntityTypes';
import { NavigateFunction, useMatch, useParams } from 'react-router';
import EnumEx from '../../../../../../../../Utilities/EnumEx';
import { changeCase } from '../../../../../../../../helpers/changeKeysCase';
import Highlighter from 'react-highlight-words';
import { ResourceListItemWithMetadata } from '../../../../types';
import { getPickerFields } from '../../../helpers';
import { ResourcePanelAddToGrid } from '../../../../../../../../Entities/Settings/ResourcePanelAddToGrid';
import { resourceAddToGridEnabled } from '../../../../../../../../Utilities/resourceAddToGridEnabled';
import { Timeout } from '../../../../../../../../types/runtimeTypes';
import { useCurrentProject } from '../../../../../../../../context/network/http/QueryProvider/queries/project';

const GetPickerFields = (settings: RootSettings, gridType: GridType) => {
    switch (gridType) {
        case GridType.Allocation:
        case GridType.ResourcePlanner:
        case GridType.ResourceCapacity:
            return settings.lmResourcePickerDisplayFields;
        case GridType.Request:
        case GridType.WorkPackage:
            return settings.pmResourcePickerDisplayFields;
        default:
            return settings.lmResourcePickerDisplayFields;
    }
};

const highlightStyle = { background: 'yellow', padding: 0 };

export const ListItem = memo(function ListItem({
    item,
    gridType,
    settings,
    showAll,
    delegationMode,
    updateItem,
    navigate,
    pathname,
    setIsDragging,
    searchText,
}: {
    showAll: boolean;
    delegationMode: boolean;
    item: ResourceListItemWithMetadata;
    settings: RootSettings;
    gridType: GridType;
    updateItem: (resource: ResourceListItemWithMetadata) => void;
    navigate: NavigateFunction;
    pathname: string;
    setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
    searchText: string;
}) {
    const { projectId: projectIdParams } = useParams<{ projectId: string }>();
    const { data: project } = useCurrentProject(projectIdParams);
	const isOnProjectPlanner = useMatch("/projectplanner")

    const initials = useMemo(() => {
        const fieldMap = GetPickerFields(settings, gridType);
        const initials = (item.name || '').match(/\b\w/g);
        const text = PanelUtil.getPickerPropertyValue(item, fieldMap, _ => _.iconOverride);
        if (fieldMap.iconOverride) {
            if (text === undefined) {
                return ' ';
            }
            return text;
        }
        return ((initials?.shift() ?? '') + (initials?.pop() ?? '')).toUpperCase();
    }, [gridType, item, settings]);

    const { secondaryText, tertiaryText, text } = useMemo(() => {
        const pickerFields = getPickerFields(settings, gridType);

        const text = PanelUtil.getPickerPropertyValue(item, pickerFields, _ => _.title);
        const secondaryText = PanelUtil.getPickerPropertyValue(item, pickerFields, _ => _.secondary);
        const tertiaryText = PanelUtil.getPickerPropertyValue(item, pickerFields, _ => _.tertiary);
        return {
            text,
            secondaryText,
            tertiaryText,
        };
    }, [gridType, item, settings]);

    // TODO make this right once serialization is normalized and stable
    const serializedItem: any = useMemo(() => ({ ...changeCase.toPascal(item), Id: undefined, id: item.id, __meta: undefined }), [item]);

    const dragProps = useMemo(() => {
        return {
            onDragStart: (event: React.DragEvent<HTMLElement>) => {
                event.dataTransfer.setData('resourcelistitem', JsonEx.stringify(serializedItem));
                event.dataTransfer.effectAllowed = 'all';
                let query: string;
                switch (gridType) {
					case GridType.Allocation:
                        query =
                            settings.restrictDirectAllocations && project?.projectType === ProjectType.Default
                                ? '.tp-grid-group'
                                : isOnProjectPlanner ? '.project-planner' :  '.tp-grid-group, .tp-grid-top';
                        break;
                    case GridType.WorkPackage:
						query = '.tp-grid-top';
                        break;
                    case GridType.Request:
                        query = '.tp-grid-group, .tp-grid-top';
                        break;
                    case GridType.LmCapacityProjects:
                        query = settings.restrictDirectAllocations
                            ? '.tp-grid-group, .tp-lmcapacity-resourcedrop'
                            : '.tp-grid-group, .tp-grid-top, .tp-lmcapacity-resourcedrop';
                        break;
                    default:
                        query = null;
                }
                if (!query) {
                    return;
                }
                Array.from(document.querySelectorAll<HTMLDivElement>(`.tp-list-capacity-dropzone-overlay${query}`), _ => (_.style.display = 'block'));
                setIsDragging(true);
            },
            onDragEnd: () => {
                Array.from(document.querySelectorAll<HTMLDivElement>('.tp-list-capacity-dropzone-overlay'), _ => (_.style.display = 'none'));
                setIsDragging(false);
            },
            draggable: gridType !== GridType.ResourceCapacity && resourceAddToGridEnabled(ResourcePanelAddToGrid.DragDrop),
        };
    }, [gridType, isOnProjectPlanner, project?.projectType, serializedItem, setIsDragging, settings.restrictDirectAllocations]);

    const { mutate: handleToggleFavourite, isLoading: isLoadingToggleFavourite } = useMutation(
        () => ApiCalls.toggleFavourtie({ favouriteResource: item.id }).then(res => res.data),
        {
            onSuccess: () => {
                updateItem({ ...item, favourite: !item.favourite });
            },
        },
    );

    const { mutate: handleAddResource, isLoading: isLoadingAddingResource } = useMutation(async () => {
        // OverviewContext.AddResource() can be both sync and async at different times.
        // react-query MUST have a promise to work with, hence the async wrapper function
        if (OverviewContext.AddResource) {
            await OverviewContext.AddResource(serializedItem);
        }
    });

    const { entityCalloutInfo, setEntityCalloutInfo } = useStore(store => ({
        setEntityCalloutInfo: store.setEntityCalloutInfo,
        entityCalloutInfo: store.entityCalloutInfo,
    }));

    const onClickCallout = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
            if (entityCalloutInfo) {
                setEntityCalloutInfo(null);
            } else {
                setEntityCalloutInfo({
                    Id: item.id,
                    EntityType: EntityType.Resource,
                    Target: `#detailicon-${item.id}`,
                });
            }
        },
        [entityCalloutInfo, item.id, setEntityCalloutInfo],
    );

    const percentTooltipText = useMemo(() => language.Resources.ItemAvailability.replace('[[availability]]', `${item.availability}`), [item.availability]);

    const clickTimer = useRef<Timeout>();

    const handlePersonaClick = useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            if (e.detail === 1) {
                clickTimer.current = setTimeout(() => {
                    if (delegationMode) {
                        return ApiCalls.startDelegation(item.id).finally(() => {
                            return window.location.reload();
                        });
                    }
                    if (pathname.startsWith('/request') || pathname.startsWith('/planner')) {
                        return;
                    }
                    const resourceId = item.id;
                    if (pathname.startsWith('/allocation')) {
                        navigate(`/resourcecapacity/${resourceId}`);
                    }
                    if (pathname.startsWith('/resourcecapacity')) {
                        navigate(`/resourcecapacity/${resourceId}`);
                    }
                }, 300);
            }
            // This is a doubleclick
            if (e.detail === 2) {
                if (!EnumEx.equalsAny(gridType, GridType.Allocation, GridType.Request) || !resourceAddToGridEnabled(ResourcePanelAddToGrid.DoubleClick)) {
                    clearTimeout(clickTimer.current);
                    clickTimer.current = null;
                    return;
                }
                if (delegationMode) {
                    return;
                }
                clearTimeout(clickTimer.current);
                clickTimer.current = null;
                handleAddResource();
            }
        },
        [delegationMode, gridType, handleAddResource, item.id, navigate, pathname],
    );

    const [wrap, setWrap] = useState(true);
    const coinSize = 65;

    return (
        <Stack data-panelitemid={item.id} className="tp-list-item noselect" {...dragProps} onClick={handlePersonaClick}>
            <Stack id={`rr_${item.id}`} className="tp-list-item-resource-container" horizontal horizontalAlign="space-between">
                <Stack horizontal tokens={{ childrenGap: 10 }} grow={1}>
                    <Stack
                        //
                        tokens={{ padding: '5px 0 0 5px' }}
                    >
                        <Stack
                            styles={{ root: { position: 'relative' } }}
                            id={`persona-resource-${item.id}`}
                            onMouseEnter={() => {
                                setEntityCalloutInfo({
                                    Id: item.id,
                                    EntityType: EntityType.Resource,
                                    Target: `#persona-resource-${item.id}`,
                                });
                            }}
                            onMouseLeave={() => {
                                setEntityCalloutInfo(null);
                            }}
                        >
                            <PersonaCoin text={item.name} imageInitials={initials} coinSize={coinSize} />
                            {(isLoadingAddingResource || isLoadingToggleFavourite) && (
                                <Spinner
                                    styles={{
                                        root: { height: coinSize, width: coinSize, transform: 'scale(2.6)', position: 'absolute', top: -1, left: -1 },
                                    }}
                                    size={SpinnerSize.large}
                                />
                            )}
                            <ResourceTypeIcon resourceType={item.resourceTypeAsString} />
                        </Stack>
                    </Stack>
                    <Stack grow={1}>
                        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ padding: '0 5px 0 0', childrenGap: 10 }}>
                            <div style={{ display: 'inline-grid', cursor: 'pointer' }} onClick={() => setWrap(w => !w)}>
                                <Text className="tp-list-item-resource-name" variant="large" nowrap={wrap} title={text}>
                                    {text ? <Highlighter searchWords={[searchText]} textToHighlight={text} highlightStyle={highlightStyle} /> : <>&nbsp;</>}
                                </Text>
                            </div>
                            {item.__meta && (
                                <Text variant="small" style={{ textAlign: 'right' }}>
                                    <Highlighter searchWords={[searchText]} textToHighlight={item.__meta} highlightStyle={highlightStyle} autoEscape />
                                </Text>
                            )}
                        </Stack>
                        <Text variant="medium">
                            {secondaryText ? ( // when delegating, the usertypes makes more sense to show (ewi)
                                <Highlighter
                                    searchWords={[searchText]}
                                    textToHighlight={delegationMode ? EnumEx.asJoinedString(UserType, item.userTypes) : secondaryText}
                                    highlightStyle={highlightStyle}
                                />
                            ) : (
                                <>&nbsp;</>
                            )}
                        </Text>
                        <Text variant="medium">
                            {tertiaryText ? (
                                <Highlighter searchWords={[searchText]} textToHighlight={tertiaryText} highlightStyle={highlightStyle} />
                            ) : (
                                <>&nbsp;</>
                            )}
                        </Text>
                    </Stack>
                </Stack>
                <Stack className="tp-list-item-resource-icon-container">
                    <IconButton id={`detailicon-${item.id}`} iconProps={{ iconName: 'Info' }} className="tp-resource-details-icon" onClick={onClickCallout} />
                    {gridType !== GridType.ResourceCapacity &&
                        !delegationMode &&
                        item.resourceType !== ResourceType.Category &&
						!isOnProjectPlanner &&
                        resourceAddToGridEnabled(ResourcePanelAddToGrid.PlusSign) && (
                            <IconButton
                                iconProps={{ iconName: 'CirclePlus' }}
                                className="tp-list-item-resource-add"
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleAddResource();
                                }}
                                disabled={isLoadingAddingResource}
                            />
                        )}
                    {!delegationMode && (
                        <IconButton
                            id={`favouriteicon-${item.id}`}
                            iconProps={{ iconName: item.favourite ? 'UnPin' : 'Pinned' }}
                            className="tp-resources-favourite-icon"
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleToggleFavourite();
                            }}
                            disabled={isLoadingToggleFavourite}
                        />
                    )}
                </Stack>
            </Stack>
            {!showAll && !delegationMode && item.resourceType === ResourceType.Named && item.availability > -1 && (
                <ListItemPercentageBar Percentage={item.availability} TooltipText={percentTooltipText} />
            )}
        </Stack>
    );
});

const ResourceTypeIcon = ({ resourceType }: { resourceType: string }) => {
    const iconName = useMemo(() => {
        switch (resourceType) {
            case 'Named': {
                return 'Contact';
            }
            case 'Generic': {
                return 'InternalInvestigation';
            }
            case 'Category': {
                return 'Transition';
            }
            case 'Team': {
                return 'Teamwork';
            }
            case 'Pool': {
                return 'SearchNearby';
            }
            case 'WorkProfile': {
                return 'AccountManagement';
            }
            case 'Unknown':
            default: {
                return 'BlockContact';
            }
        }
    }, [resourceType]);
    return (
        <Stack
            styles={{
                root: {
                    position: 'absolute',
                    right: 0,
                    bottom: -15,
                    backgroundColor: '#ececec',
                    width: 33,
                    height: 33,
                    borderRadius: '50%',
                    border: '1px solid #fff',
                },
            }}
            horizontalAlign="center"
            verticalAlign="center"
        >
            <Icon iconName={iconName} styles={{ root: { fontSize: 20 } }} />
        </Stack>
    );
};
