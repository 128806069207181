import { Icon, IIconProps, IPersonaCoinProps, IStackProps, ITextProps, PersonaCoin, Stack, Text } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useMemo, useState } from 'react';
import { GridRow } from '../../../../../../../../api/generated/data-contracts';
import { ProjectType } from '../../../../../../../../Entities/ProjectType';
import { EntityInfoCallout } from './EntityInfoCallout';

const ProjectTypeIcon = ({ projectType, coinSize }: { projectType: ProjectType; coinSize: number }) => {
    const iconName = useMemo(() => {
        switch (projectType) {
            case ProjectType.Administrative: {
                return 'CalendarSettings';
            }
            case ProjectType.Team: {
                return 'Teamwork';
            }
            case ProjectType.Default:
            default: {
                return 'IssueTracking';
            }
        }
    }, [projectType]);
    const { iconStyles, stackStyles } = useMemo(() => {
        return {
            stackStyles: {
                root: {
                    position: 'absolute',
                    right: 0,
                    bottom: '-20%',
                    width: '50%',
                    height: '50%',
                    backgroundColor: '#ececec',
                    // borderRadius: '50%',
                    border: '1px solid #fff',
                    fontSize: coinSize,
                    userSelect: 'none',
                    cursor: 'help',
                },
            } as IStackProps['styles'],
            iconStyles: { root: { fontSize: '30%' } } as IIconProps['styles'],
        };
    }, [coinSize]);
    return (
        <Stack styles={stackStyles} horizontalAlign="center" verticalAlign="center">
            <Icon iconName={iconName} styles={iconStyles} />
        </Stack>
    );
};


const CoinIcon = ({ row, coinSize = 28 }: { row: GridRow; coinSize?: number }) => {
    const { coinStyles, stackStyles } = useMemo(() => {
        return {
            stackStyles: { root: { position: 'relative', height: coinSize, width: coinSize, fontSize: coinSize / 4 } } as IStackProps['styles'],
            coinStyles: {
                initials: { fontSize: '1.1em' },
                coin: {
                    fontSize: '100%',
                    userSelect: 'none',
                    cursor: 'help',
                    //
                    // '.ms-Persona-initials': { borderRadius: 0 },
                },
            } as IPersonaCoinProps['styles'],
        };
    }, [coinSize]);
	// const { data: uiSettings } = useUISettings()
	const initials = useMemo(() => {
		// const fieldMap = uiSettings.settings.projectPickerDisplayFields;
		const initials = (row.name || '').match(/\b\w/g);
		// const text = PanelUtil.getPickerPropertyValue(row, fieldMap, _ => _.iconOverride);
		// if (fieldMap.iconOverride) {
		// 	if (fieldMap.iconOverride === "projectType") {
		// 		// return getProjectText(row.projectType)
		// 		return getProjectText(row.metadata.projectType)
		// 	}
		// 	if (text === undefined) {
		// 		return " "
		// 	}
		// 	return text
		// }
		return ((initials?.shift() ?? '') + (initials?.pop() ?? '')).toUpperCase();
	// }, [row, uiSettings.settings.projectPickerDisplayFields]);
	}, [row]);
    return (
        <>
            <Stack styles={stackStyles}>
                {/* <PersonaCoin text={row.name} imageInitials={getProjectText(row.metadata?.ProjectType)} styles={coinStyles} coinSize={coinSize} /> */}
                <PersonaCoin text={row.name} imageInitials={initials} styles={coinStyles} coinSize={coinSize} />
                <ProjectTypeIcon projectType={row.metadata?.ProjectType} coinSize={coinSize} />
            </Stack>
        </>
    );
};

export const ProjectCoinIcon = ({ row }: { row: GridRow }) => {
    const [showInfo, setShowInfo] = useState(false);
    const id = useId('project-coin' + row.id);
    const { stackStyles, textStyles } = useMemo(() => {
        return {
            stackStyles: { root: { gap: 10, marginBottom: 20 } } as IStackProps['styles'],
            textStyles: { root: { fontWeight: 700, fontSize: 18 } } as ITextProps['styles'],
        };
    }, []);
    return (
        <>
            <Stack horizontal onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)} id={id}>
                <CoinIcon row={row} />
            </Stack>
            <Text className="title">{row.name}</Text>
            {showInfo && (
                <EntityInfoCallout entityId={row.identity!.id} entityType={row.identity!.entityType} targetId={id}>
                    <Stack horizontal verticalAlign="center" styles={stackStyles}>
                        <CoinIcon row={row} coinSize={50} />
                        <Text styles={textStyles}>{row.name}</Text>
                    </Stack>
                </EntityInfoCallout>
            )}
        </>
    );
};
