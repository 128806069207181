export const AvailableUserSettingsProperties = [
	"WorkProfileId",
	"AllowSearchAllProjects",
	"AllowSearchAllResources",
	"RbsLevelsBelowAccesibleFor",
	"LmCanEditOwnResources",
	"PmCanEditOwnProjects",
	"ShownOwnProjectsForLM",
	"GlimmerConfiguration",
	"AllocateOutsideRBS",
	"GridDecimalsFTE",
	"GridDecimalsHours",
	"EnableAlignContract",
	"EnableForwardRequest",
	"EnableMoveRequest",
	"EnableCreateMatchingRequest",
	"EnableCreateMatchingAllocation",
	"EnableMoveAllAllocations",
	"EnableMergeContracts",
	"EnableLmCapacityView",
	"EnableGridUndo",
	"EnableCopyFromExcel",
	"EnableCoachmarks",
	"EnableRequestNamedResources",
	"EnableSearchNamedResources",
	"EnableContractVersions",
	"EnableMailNotifications",
	"EnableWorkPackages",
	"ResourceFilterPM",
	"ResourceFilterLM",
	"ResourceFilterOverview",
	"PMResourcePickerGroupBy",
	"LMResourcePickerGroupBy",
	"PMResourcePickerDisplayFields",
	"LMResourcePickerDisplayFields",
	"ShowOnlyRelevantResourcesInPicker",
	"ResourceInformationProperties",
	"AllowedToInactivateResources",
	"AllowedToDeleteResources",
	"ResourceOverviewProperties",
	"ProjectPickerDisplayFields",
	"AllowedToInactivateProjects",
	"ProjectInformationProperties",
	"ProjectFilterPM",
	"ProjectFilterLM",
	"ProjectFilterOverview",
	"ProjectsAlwaysShowRBS",
	"ProjectOverviewProperties",
	"ContractDefaultName",
	"AllowLMsToChangeContractName",
	"FreezePeriod",
	"AutoGroupContractBy",
	"AutoCreateRequestWhenDirectAllocating",
	"SingletonOnRequests",
	"EnableDynamicContractNames",
	"EnableGridResolutionMismatchWarning",
	"MailNotifications",
	"TimelineDayResolutionMaxDaysBackwards",
	"TimelineDayResolutionMaxDaysForward",
	"Localization",
	"GenericResourceDefinition",
	"AutoGenerateGenericResources",
	"GenericResourceNamingConvention",
	"LightbulbLimitInMonths",
	"ContractChangeNotificationDateSpan",
	"GridSpacing",
	"HidePersonaIconsInGrid",
	"DefaultTheme",
	"EnableProjectExportRefresh",
	"EnableHelpSection",
	"EnableProjectPlanner",
	"EnableResourcePlanner",
	"EnableFastDelete"
];