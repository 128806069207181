import { JsonEx } from "./JsonEx";

export function set(key: string, value: any): void {
	localStorage.setItem(key, JsonEx.stringify(value));
}

export function get<T>(key: string): T {
	const value = localStorage.getItem(key);
	if (value == null || value === "undefined")
		return null;
	return JsonEx.parse<T>(value);
}

export function setProperty(key: string, propertyKey: string, value: any): void {
	const cache = get<Date>(key) || {};
	cache[propertyKey] = value;
	set(key, cache);
}

export function getProperty<T>(key: string, propertyKey: string): T {
	const cache = get<any>(key) || {};
	return cache[propertyKey];
}

export const LocalStorage = {
	set,
	get,
	getProperty,
	setProperty
}

