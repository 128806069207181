import { PFPropertyBasicInfo } from "../../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../../Utilities/PropertyInfo/Render";
import { PFPropertyValidation } from "../../../Utilities/PropertyInfo/Validation";

export class MailSchedule {
	@PFPropertyBasicInfo(PropertyType.Cron, "_localization:MailTemplate.CronExpression", 10, "_localization:MailTemplate.CronExpressionDescription")
	@PFPropertyValidation("_localization:MailTemplate.CronExpressionValidation", (obj: any) => new RegExp(/^((((\d+,)+\d+|(\d+(\/|-|#)\d+)|\d+L?|\*(\/\d+)?|L(-\d+)?|\?|[A-Z]{3}(-[A-Z]{3})?) ?){5,7})$|(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)/).test(obj.CronExpression), null, () => true)
	public CronExpression: string;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:MailTemplate.LastRun", 20, "_localization:MailTemplate.LastRunDescription")
	@PFPropertyRender(null, null, null, null, () => true)
    public LastRun: Date;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:MailTemplate.NextRun", 30, "_localization:MailTemplate.NextRunDescription")
    public NextRun: Date;
}