import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import { useId } from '@fluentui/react-hooks';
import { useStore } from '../../../../../../../context/store';
import { defaultNumberParser } from '../../../../../../../Utilities/NumberParser';
import { useGridContext } from '../../../../../_grid/contexts/gridContext';
import { useControlledState } from '../../../../../../../hooks/useControlledState';
import { useDragGridCell } from '../../../../../_grid/hooks/useDragGridCell';
import { useTheme } from '../../../../../../../Themes/themeContext';
import { TextFieldWithForwardRef } from '../TextField';
import { useAnimationProp } from '../../../../../../../hooks/useAnimationProp';
import { usePasteFromExcel } from '../../../../../_grid/hooks/usePasteFromExcel';
import { ErrorMessage } from './components/ErrorMessage';
import { useCheckOverAllocationResourcePlanner } from './hooks/useCheckOverAllocationResoucePlanner';
import { useAnimationListener } from './hooks/useAnimationListener';
import { killEvent } from '../../../../../../../helpers/killEvent';
import Big from 'big.js';
import { GridCell } from '../../../../../../../api/generated/data-contracts';
import { useUISettings } from '../../../../../../../context/network/http/QueryProvider/queries/UISettings';
import { useUnitType } from '../../../../../../../Utilities/UnitTypeUtil';
import { UnitType } from '../../../../../../../Entities/UnitType';
import { bigZero } from '../../../../../../../helpers/math';
import { useFixedCellValue } from '../../../../../_grid/hooks/useFixedCellValue';
import { useGridRowContext } from '../../../../../_grid/contexts/rowContext';

const big100000 = new Big('100000');

type CellProps = {
    cell: GridCell;
    rowId: string;
    columnIndex: number;
    cellIndex: number;
    // rowIndex: number;
};

export const Cell = ({ cell, cellIndex, columnIndex, rowId }: CellProps) => {
	const { row } = useGridRowContext()
    const { updateCellValue, readonly, useGridCellValue, getRowAncestorTreeToRoot } = useGridContext();
    const [errorMessage, setErrorMessage] = useState('');
    const isWritable = cellIndex !== 0 && !readonly;
    const warning = useStore(store => store.addWarningNotification);
    const storeValue = useGridCellValue(cell);
    const [value, setValue] = useControlledState(() => storeValue.toString(), [storeValue]);
    const successHighlight = useAnimationListener(storeValue, 'update-success');
    const localChangeHighlight = useAnimationListener(value, 'update-local', 500);
    const [updateErrorHighlight, showUpdateErrorHighlight] = useAnimationProp('update-error', 1000);
    const fixedValue = useFixedCellValue(storeValue);
    const ref = useRef<HTMLInputElement | null>(null);
    const [isFocused, setIsFocused] = useState(false);
    const theme = useTheme();

    const onGetUpdateCellErrorMessage = useCheckOverAllocationResourcePlanner();

    const rowFamilyTree = useMemo(() => {
        return getRowAncestorTreeToRoot(rowId);
    }, [getRowAncestorTreeToRoot, rowId]);

    const onError = useCallback(
        (msg: string) => {
            setErrorMessage(msg);
            showUpdateErrorHighlight();
        },
        [showUpdateErrorHighlight],
    );

	const [bindDragHandler, dragState] = useDragGridCell({
        cell,
        inputRef: ref.current,
        setLocalCellValue: setValue,
        columnIndex,
        cellIndex,
        rowId,
        onGetUpdateCellErrorMessage,
        onError,
    });

    const onBlurHandler = useCallback(
        async (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
            setIsFocused(false);
			if (dragState.current) {
				// We let useDragGridCell handle the updating when dragging
				return;
			}
			const parsedNumber = defaultNumberParser.Parse(value);
			if (Number.isNaN(parsedNumber)) {
				return setErrorMessage("Not a valid number")
			}
            const newValue = new Big(parsedNumber.toString());
            if (newValue.gte(bigZero) && !newValue.eq(storeValue)) {
                if (newValue.gt(big100000)) {
                    const message = 'The entered value is too high and has not been saved! Please change it to a value below 100000'; //TODO LOCALIZATION
                    warning(message);
                    onError(message);
                    e.stopPropagation();
                    e.preventDefault();
                    return;
                }
                const { errorMessage, changedValue } = await onGetUpdateCellErrorMessage({
                    cell,
                    cellIndex,
                    columnIndex,
                    currentCellValue: storeValue,
                    newCellValue: newValue,
                    rowFamilyTree,
					row
                });
                if (errorMessage) {
                    onError(errorMessage);
                    return;
                }
                updateCellValue({ cell, cellIndex, columnIndex, newValue: changedValue, rowId }).catch(error => {
                    console.error(error);
                    setErrorMessage(error.message);
                });
                setErrorMessage('');
            }
        },
        [dragState, value, storeValue, onGetUpdateCellErrorMessage, cell, cellIndex, columnIndex, rowFamilyTree, row, updateCellValue, rowId, warning, onError],
    );

    const onChangeHandler = useCallback(
        (_: any, newValue?: string) => {
            if (newValue !== undefined) {
                setValue(newValue);
                setErrorMessage('');
            }
        },
        [setValue],
    );

    const onFocusHandler = useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        setIsFocused(true);
    }, []);

    useEffect(() => {
        if (isFocused) {
            ref.current?.select();
        }
    }, [isFocused]);

    const onPaste = usePasteFromExcel({
        cell,
        cellIndex,
        columnIndex,
        rowId,
        onGetUpdateCellErrorMessage,
        onError,
    });

    const dragHandleStyle = useMemo((): React.CSSProperties => {
        return {
            position: 'absolute',
            bottom: '-1px',
            right: '-1px',
            height: 8,
            width: 8,
            backgroundColor: theme.palette.accent,
            border: `1px solid ${theme.semanticColors.bodyBackground}`,
            touchAction: 'none',
            display: isFocused ? 'block' : 'none',
            cursor: 'cell',
        };
    }, [isFocused, theme.palette.accent, theme.semanticColors.bodyBackground]);

    const tooltipId = useId('cell-tooltip');
    const { data } = useUISettings();
    const { currentUnitType } = useUnitType();

    const textValue = useMemo(() => {
        if (isFocused) {
            return value;
        }
        if (currentUnitType === UnitType.FTE) {
            return Number(value).toFixed(data.settings.gridDecimalsFTE);
        }
        return Number(value).toFixed(data.settings.gridDecimalsHours);
    }, [currentUnitType, data.settings.gridDecimalsFTE, data.settings.gridDecimalsHours, isFocused, value]);
    return (
        <div className="tp-capacity-cell">
            {isWritable ? (
                <>
                    <TextFieldWithForwardRef
                        ref={ref}
                        className="tp-capacity-input"
                        value={textValue}
                        readOnly={readonly}
                        disabled={readonly || cell.disabled}
                        onChange={onChangeHandler}
                        onFocus={onFocusHandler}
                        style={{
                            //
                            ...(errorMessage && { border: '1px solid red' }),
                            ...(!isFocused && localChangeHighlight),
                            ...successHighlight,
                            // error takes precedence
                            ...updateErrorHighlight,
                        }}
                        onBlur={onBlurHandler}
                        onPaste={onPaste}
                        id={tooltipId}
                        // We kill the drag event because it fucks with row dragging
                        onDragStart={killEvent}
                    />
                    <div {...bindDragHandler(value)} style={dragHandleStyle} />
                    <ErrorMessage errorMessage={errorMessage} targetId={tooltipId} />
                </>
            ) : (
                <div className="tp-capacity-readonly">{fixedValue}</div>
            )}
        </div>
    );
};
