import { TreeItem } from "./TreeItem";

export class TreeViewItem extends TreeItem {
	public Selected?: boolean;
	public Disabled?: boolean;
	public DefaultExpanded?: boolean;
	public Children: Array<TreeViewItem>;

    constructor() {
		super();
    }
}