import { guid } from "../helpers/guid";
import { PFPropertyBasicInfo } from "../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../Utilities/PropertyInfo/Render";
import { DropdownOptionEx } from "../Utilities/DropdownOptionEx";
import { UserType } from "../api/generated/data-contracts";

export class AlignContractStatus {
	public Id: string; // guid
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.Name", 1)
	public Name: string;
	@PFPropertyBasicInfo(PropertyType.Color, "_localization:Common.Color", 2)
	@PFPropertyRender(null, null, null, null, null, 120)
	public Color: string;
	@PFPropertyBasicInfo(PropertyType.Number, "_localization:Common.Order", 3)
	@PFPropertyRender(null, null, null, null, null, 120)
	public Order: number;
	@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:CustomProperty.EditableBy", 8)
	@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome(UserType, [ UserType.Administrator ]), MultiSelect: true })
	public EditableBy: Array<UserType>;
	@PFPropertyBasicInfo(PropertyType.String, "Custom Notification", 10)
	@PFPropertyRender(undefined, { Multiline: true, Resizable: true})
	public CustomNotification: string; 

	constructor() {
		this.Id = guid.newGuid();
	}
}