import { QueryGroup } from './createQueryGroup';
import { QueryCondition } from '../../../../../../Entities/DynamicQueryBuilder/QueryCondition';
import { QueryOperator } from '../../../../../../Entities/DynamicQueryBuilder/QueryOperator';

// NOSONAR
export const isAdvancedQuery = (queryGroup?: QueryGroup) => {
	if (queryGroup?.parts) {
		for (const part of queryGroup.parts) {
			if (typeof part === 'number' && part !== QueryCondition.Or) {
				return true;
			}
			if (typeof part !== 'number') {
				if (part.property !== 'id') {
					return true;
				}
				if (part.typeName === 'QueryGroup') {
					return true;
				}
				if (part.operator !== QueryOperator.Equals) {
					return true;
				}
			}
		}
	}
	return false;
};
