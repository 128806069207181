import { DropdownOptionEx } from "../../Utilities/DropdownOptionEx";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyFilter } from "../../Utilities/PropertyInfo/PropertyFilter";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { QueryGroup } from "../DynamicQueryBuilder/QueryGroup";
import { Resource } from "../Main/Resource";
import { ResourceTechnique, ResourceOrderTypes } from "./Enums";
import { ResourceConstraints } from "./ResourceConstraints";

export class ResourceDistribution {
	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:AutoDistributionConfig.ResourceTechnique", 1, "_localization:AutoDistributionConfig.ResourceTechniqueDescription")
	@PFPropertyRender((item: ResourceDistribution, i, col) => ResourceTechnique[item[col.fieldName]], { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<ResourceTechnique>(ResourceTechnique) })
	ResourceTechnique: ResourceTechnique;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:AutoDistributionConfig.Prefer", 5, "_localization:AutoDistributionConfig.PreferDescription")
	@PFPropertyRender(null, { ItemType: Resource, DialogTitleGetter: () => "Prefer" }, "EntityFilterEditor")
	@PFPropertyFilter({ FilterLevel: 1 })
	Prefer: QueryGroup;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:AutoDistributionConfig.Restrict", 10, "_localization:AutoDistributionConfig.RestrictDescription")
	@PFPropertyRender(null, { ItemType: Resource, DialogTitleGetter: () => "Restrict" }, "EntityFilterEditor")
	@PFPropertyFilter({ FilterLevel: 1 })
	Restrict: QueryGroup;
	@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:AutoDistributionConfig.ResourceOrderTypes", 15, "_localization:AutoDistributionConfig.ResourceOrderTypesDescription")
	@PFPropertyRender((item: ResourceDistribution, i, col) => ResourceOrderTypes[item[col.fieldName]], { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<ResourceOrderTypes>(ResourceOrderTypes) })
	ResourceOrderTypes: Array<ResourceOrderTypes>;
	@PFPropertyBasicInfo(PropertyType.ObjectArray, "_localization:AutoDistributionConfig.ResourceConstraints", 20, "_localization:AutoDistributionConfig.ResourceConstraintsDescription")
	@PFPropertyRender(null, { ItemType: ResourceConstraints, ItemIdentifier: (item: ResourceConstraints) => item.ResourceId })
	@PFPropertyFilter({ FilterLevel: 1 })
	ResourceConstraints: Array<ResourceConstraints>;
	@PFPropertyBasicInfo(PropertyType.Number, "_localization:AutoDistributionConfig.MaxNumberOfResources", 25, "_localization:AutoDistributionConfig.MaxNumberOfResourcesDescription")
	MaxNumberOfResources: number;
}