import { Project } from "../../../../api/generated/data-contracts";
import { EntityType } from "../../../../Entities/EntityTypes";
import { guid } from "../../../../helpers/guid";
import { language } from "../../../../Services/LocalizationService";
import { Plugin } from "../../../../Services/PluginInvoker";
import { PluginIDs } from "../../../../Utilities/PluginIDs";
import { IOverviewActivation, overviewActivation } from "../../_components/helpers/overviewActivation";

export const showInactivateConfirm = (resources: Project[], active: boolean, callback: () => void, indicateLoading: (value: boolean) => void) => {
	overviewActivation<Project>({
		languageObj: language.ProjectOverview,
		items: resources,
		active: active,
		showInactiveCountdown: false,
		callback: callback,
		setActiveState: setActiveStateOnProjects,
		indicateLoading: indicateLoading
	}).activation()
};

const setActiveStateOnProjects = async (options: IOverviewActivation<Project>) => {
	options.indicateLoading(true);
	const dto = {
		Origin: guid.empty,
		Entity: EntityType.Project,
		Items: options.items.map(_ => {
			return {
				TypeName: "Project",
				Id: _.id,
				IsActive: options.active
			}
		}),
		ChangedProperties: [ "IsActive" ],
		Force: "2A02C94C-D08A-4F54-B215-FB3A3076E68C" // force upsert key on delta plugin
	};

	await Plugin.InvokeNoResponse(PluginIDs.DeltaUpsert, dto);
	options.callback();
	options.indicateLoading(false);
};