import { GridCell, GridColumnRow } from '../../../../api/generated/data-contracts';
import { forEachAsync } from '../../../../helpers/forEachAsync';

export const forEachGridCellInColumnRows = async (
    columnRow: GridColumnRow[],
    cb: (cell: GridCell, cellIndex: number, columnIndex: number, columnRow: GridColumnRow) => void | Promise<void>,
) =>
    await forEachAsync(columnRow, async (columnRow, columnRowIndex) => {
        await forEachAsync(columnRow.cells, async (cell, cellIndex) => {
            await cb(cell, cellIndex, columnRowIndex, columnRow);
        });
    });
