export default class Locale_MailTemplate {
	// Mail template
	Name: string = "Name";
	NameDescription: string = "The name of the template";
	Template: string = "Template";
	TemplateDescription: string = "The template";
	TemplateReference: string = "Template";
	TemplateReferenceDescription: string = "Type in the 'Id' of the template (from the 3rd party service)";
	MailTemplateType: string = "Mail template type";
	MailTemplateTypeDescription: string = "If using a 3rd party email service, use the 'Reference'. Otherwise use the 'Explicit'";
	MailTemplateDataType: string = "Mail template data type";
	MailTemplateDataTypeDescription: string = "The type of template used";
	Subject: string = "Subject";
	SubjectDescription: string = "The subject in the emails send out using this template";
	Config: string = "Template configuration";
	ConfigDescription: string = "The specific configuration needed by the template data type (varies from data type to data type)";

	// validation
	TemplateNameValidation: string = "Name must be specified";
	TemplateSubjectValidation: string = "The subject must be specified";
	TemplateDataTypeValidation: string = "Data type is not selected";
	TemplateValidation: string = "The template is null";
	ContractChangesTemplateConfigValidation: string = "Resolution must be specifed";
	
	// Contract changes notification
	VersionResolution: string = "Resolution";
	VersionResolutionDescription: string = "Which resolution should we use";
	DateSpan: string = "Date span";
	DateSpanDescription: string = "The date span from which to search for changes";
	NoConfig: string = "No config needed for this template data type";

	// On comment received
	Rules: string = "Rules";
	HasPreviouslyCommented: string = "Previous commenter's";
	RbsMatch: string = "Rbs match";
	AdditionalRbsMatch: string = "Additional Rbs match";
	OnlyMatchRbsDirectly: string = "Only match Rbs directly";
	IsProjectOwner: string = "Project owner";
	IsAdditionalProjectOwner: string = "Additional project owner";

	RulesDescription: string = "The rules for who the notifications should be send out to";
	HasPreviouslyCommentedDescription: string = "Notifies all previous commenter's";
	RbsMatchDescription: string = "Notifies all Line Managers that matches on Rbs";
	AdditionalRbsMatchDescription: string = "Notifies all Line Managers that matches on Rbs and Additional Rbs (requires 'Rbs match' to be enabled as well)";
	OnlyMatchRbsDirectlyDescription: string = "Restricts the Rbs matching to direct matching. (requires 'Rbs match' to be enabled as well)";
	IsProjectOwnerDescription: string = "Notifies the project owner";
	IsAdditionalProjectOwnerDescription: string = "Notifies all the additional project owners";

	// Mail Schedule
	Schedule: string = "Schedule";
	ScheduleDescription: string = "Configure when this mail template should run";
	NextRun: string = "Next run";
	NextRunDescription: string = "When the this template will be executed next. If this is not filled out, the schedule will not run!";
	LastRun: string = "Last run";
	LastRunDescription: string = "When this template has been executed";
	CronExpression: string = "The schedule rule (Timezone is UTC)";
	CronExpressionDescription: string = "When the schedule should run (Timezone is UTC)";
	CronExpressionValidationDescription: string = "The schedule rule is malformed!";
	CronExpressionValidation: string = "A schedule must be specified";
	ResolutionValidation: string = "A resolution must be set";
	DateSpanValidation: string = "The dates doesn't span anything :)";
	NewCommentsCountsAsChange: string = "Comments counts as change";
	NewCommentsCountsAsChangeDescription: string = "Even if no changes to request/allocation include it as a change if there are new comments";
}