import { useNavigate } from 'react-router-dom';
import { Stack, Text } from '@fluentui/react';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { useGetDefaultRoute } from '../../router/hooks/useGetDefaultRoute';
import { TPProgressIndicator } from '../../components_new/ProgressIndicator';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';

export const AccessDenied = ({
    disableRedirect = false,
    customHTML: customHTMLProps,
    useCustom: useCustomProps,
}: {
    disableRedirect?: boolean;
    useCustom?: boolean;
    customHTML?: string;
}) => {
    const { data: uiSettings } = useUISettings({ skibUserCheck: true });
    const defaultUserRoute = useGetDefaultRoute();
    const goTo = useNavigate();
    const useCustom = useCustomProps ?? uiSettings?.settings?.custom403Setting?.custom403Enabled ?? false;
    const customHTML = customHTMLProps ?? uiSettings?.settings?.custom403Setting?.custom403Html ?? '';

    if (useCustom) {
        return (
            <div style={{ height: '100%', width: '100%' }}>
                <Stack horizontalAlign="center">
                    <ReactMarkdown className="markdown-body" remarkPlugins={[remarkGfm as any]} rehypePlugins={[rehypeRaw as any]} children={customHTML} />
                    {!uiSettings?.resource?.userTypes?.length ? (
                        <Text variant="xLarge">Cannot find any permission for your user</Text>
                    ) : (
                        !disableRedirect && (
                            <Stack tokens={{ padding: '30px 0 0 0' }}>
                                <Text variant="xLarge">You don't have sufficient permissions to be here.</Text>
                                <TPProgressIndicator
                                    description="Redirecting you to your default page. If you keep seeing this message, please contact an administrator"
                                    intervalDelay={360}
                                    intervalIncrement={0.04}
                                    completeCallback={() => goTo(defaultUserRoute)}
                                />
                            </Stack>
                        )
                    )}
                </Stack>
            </div>
        );
    }
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div className="video-container" style={{ width: '800px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                <img src="/images/access_denied.gif" alt="access denied" />
            </div>
            <Stack horizontalAlign="center">
                {!uiSettings?.resource?.userTypes?.length ? (
                    <Text variant="xLarge">Cannot find any permission for your user</Text>
                ) : (
                    !disableRedirect && (
                        <Stack>
                            <Text variant="xLarge">You don't have sufficient permissions to be here.</Text>
                            <TPProgressIndicator
                                description="Redirecting you to your default page. If you keep seeing this message, please contact an administrator"
                                intervalDelay={360}
                                intervalIncrement={0.04}
                                completeCallback={() => goTo(defaultUserRoute)}
                            />
                        </Stack>
                    )
                )}
            </Stack>
        </div>
    );
};
