import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Text, Dropdown, IDropdownOption } from '@fluentui/react';
import { RecursiveTree } from '../../../helpers/buildRbsTree';
import { useUISettings } from '../../../../../../../context/network/http/QueryProvider/queries/UISettings';
import { resolveResourceFieldName } from '../../../resource/helpers';
import { findIndexInMap, mapMapToArray } from '../../../../../_grid/helpers/map';

type TRbsTreeProps = {
    rbsTree: RecursiveTree;
    onChange: (rbsPart: string) => void;
    title?: string;
    parentKey?: string;
    currentPath: string;
};

export const RbsList: React.FC<TRbsTreeProps> = ({ rbsTree, onChange, parentKey, currentPath }) => {
    const [selectedKey, setSelectedKey] = useState('');
    const { data: uiSettings } = useUISettings();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [hasChosen, setHasChosen] = useState(false);
    const options = useMemo(() => {
        return [
            {
                key: 'deselect',
                text: 'Deselect',
            },
            ...mapMapToArray(rbsTree, (value, index, key) => {
                return {
                    key,
                    text: key,
                    data: {
                        index,
                    },
                };
            }),
        ] as IDropdownOption<any>[];
    }, [rbsTree]);

    const childPath = useMemo(() => {
        const nextDot = currentPath.indexOf('.');
        if (nextDot > -1) {
            return currentPath.slice(currentPath.indexOf('.') + 1);
        }
        return '';
    }, [currentPath]);

	// const dropdownOnChange = useCallback((e: any, option: IDropdownOption<any>) => {
	// 	if (option) {
	// 		const keyString = String(option.key);
	// 		if (keyString === 'deselect') {
	// 			onChange(parentKey || '');
	// 			setSelectedIndex(0);
	// 			setHasChosen(false);
	// 			setSelectedKey('');
	// 		} else {
	// 			onChange(parentKey ? parentKey + '.' + keyString : keyString);
	// 			setSelectedIndex(option.data.index);
	// 			setHasChosen(true);
	// 			setSelectedKey(keyString);
	// 		}
	// 	}
	// }, [onChange, parentKey])

    const render = useMemo(() => {
        return mapMapToArray(rbsTree, (branch, i, key) => {
            if (i !== selectedIndex) {
                return null;
            }
            return (
                <React.Fragment key={key}>
                    {parentKey && (
                        <Text styles={textStyles} variant="xxLarge">
                            .
                        </Text>
                    )}
                    <Dropdown
                        placeholder={'choose'}
                        options={options}
                        onChange={(e, option) => {
                            if (option) {
                                const keyString = String(option.key);
                                if (keyString === 'deselect') {
                                    onChange(parentKey || '');
                                    setSelectedIndex(0);
                                    setHasChosen(false);
                                    setSelectedKey('');
                                } else {
                                    onChange(parentKey ? parentKey + '.' + keyString : keyString);
                                    setSelectedIndex(option.data.index);
                                    setHasChosen(true);
                                    setSelectedKey(keyString);
                                }
                            }
                        }}
						// onChange={dropdownOnChange}
                        selectedKey={selectedKey}
                        calloutProps={calloutProps}
                    />
                    {hasChosen && (
                        <RbsList
                            parentKey={parentKey ? parentKey + '.' + selectedKey : selectedKey}
                            title={key}
                            onChange={onChange}
                            rbsTree={branch}
                            currentPath={childPath}
                        />
                    )}
                </React.Fragment>
            );
        });
        // }).filter(Boolean);
    }, [childPath, hasChosen, onChange, options, parentKey, rbsTree, selectedIndex, selectedKey]);

    const hasCalledOnMount = useRef(false);

    const hasCalledOnLeaf = useRef(false);

    useLayoutEffect(() => {
        const defaultPathTitle = 'All ' + (resolveResourceFieldName('rbs', uiSettings.settings) || 'RBS');
        if (currentPath && currentPath !== defaultPathTitle && !hasCalledOnMount.current && !hasCalledOnLeaf.current) {
            const selectedKey = currentPath.includes('.') ? currentPath.slice(0, currentPath.indexOf('.')) : currentPath;
            // const currentIndex = mapMap(rbsTree, (value, index, key) => {
            // 	if (selectedKey === key) {
            // 		return index;
            // 	}
            // }).filter(index => index !== undefined)[0];
            const currentIndex = findIndexInMap(rbsTree, (value, index, key) => selectedKey === key);
            if (selectedKey && currentIndex !== -1) {
                setSelectedKey(selectedKey);
                setSelectedIndex(currentIndex);
                setHasChosen(true);
                onChange(parentKey ? parentKey + '.' + currentPath : currentPath);
            }
            hasCalledOnMount.current = true;
        }
    }, [currentPath, onChange, parentKey, rbsTree, uiSettings.settings]);

    // useLayoutEffect(() => {
    //     if (render.length === 1 && !hasCalledOnLeaf.current && !hasCalledOnMount.current) {
    //         // const keyString = String(options[rbsTree.size > 1 ? 1 : 0].key);
    //         const keyString = String(options[1].key);
    //         setSelectedKey(keyString);
    //         setHasChosen(true);
    //         onChange(parentKey ? parentKey + '.' + keyString : keyString);
    //         setSelectedIndex(0);
    //         hasCalledOnLeaf.current = true;
    //     }
    // }, [onChange, options, parentKey, render.length, rbsTree.size]);

    return <>{render}</>;
};

const textStyles = { root: { lineHeight: '80%', display: 'flex', justifyContent: 'center' } };

const calloutProps = {
    styles: {
        root: {
            width: 'min-content',
        },
    },
};
