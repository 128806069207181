import { LocalStorage } from "./LocalStorage"
import { DateEx } from "./DateEx";

// export class ProjectChangesUtil {

// 	private static ProjectKey = "tp-projectchanges";
// 	private static CheckFromDateKey =  "tp-projectchanges-begin";
	
// 	private static CheckFromDate: Date;

// 	public static Initialize = () : void => {
// 		var begin: Date = LocalStorage.get<Date>(ProjectChangesUtil.CheckFromDateKey);
// 		if (!begin) {
// 			begin = new Date();
// 			LocalStorage.set(ProjectChangesUtil.CheckFromDateKey, begin);
// 		}
// 		ProjectChangesUtil.CheckFromDate = begin;
// 	}

// 	public static SetSeenChangesProject = (projectId: string, seenContracts?: boolean) : void => {
// 		var cache = LocalStorage.get<Date>(ProjectChangesUtil.ProjectKey) || {};
// 		var existing = cache[projectId] || { };
// 		cache[projectId] = { seen: new Date(), contracts: seenContracts == null ? !!existing.contracts : true }
// 		LocalStorage.set(ProjectChangesUtil.ProjectKey, cache);
// 	}
 
// 	public static HasSeenChangesProject = (projectId: string, modified: Date, seenContracts?: boolean) : boolean => {
// 		if (modified == null || modified < ProjectChangesUtil.CheckFromDate) return true;
// 		var cache = LocalStorage.get<Date>(ProjectChangesUtil.ProjectKey) || {};
// 		var project = cache[projectId];
// 		if (project == null) {
// 			if (seenContracts) {
// 				setTimeout(() => {
// 					ProjectChangesUtil.SetSeenChangesProject(projectId, seenContracts);
// 				}, 10000);
// 			}
// 			return false;
// 		}
// 		// All dates coming from our API are UTC, but not converted
// 		var seenChanges = project.seen > new Date(DateEx.specifyUtc(modified)) && (!seenContracts || project.contracts);
// 		if (!seenChanges && seenContracts) {
// 			setTimeout(() => {
// 				ProjectChangesUtil.SetSeenChangesProject(projectId, seenContracts);
// 			}, 10000);
// 		}
// 		return seenChanges;
// 	}
// }

const ProjectKey = "tp-projectchanges";
const CheckFromDateKey =  "tp-projectchanges-begin";
let CheckFromDate: Date;


export function initialize(): void {
	let begin: Date = LocalStorage.get<Date>(CheckFromDateKey);
	if (!begin) {
		begin = new Date();
		LocalStorage.set(CheckFromDateKey, begin);
	}
	CheckFromDate = begin;
}

export function setSeenChangesProject(projectId: string, seenContracts?: boolean): void {
	const cache = LocalStorage.get<Date>(ProjectKey) || {};
	const existing = cache[projectId] || {};
	cache[projectId] = { seen: new Date(), contracts: seenContracts == null ? Boolean(existing.contracts) : true };
	LocalStorage.set(ProjectKey, cache);
}

export function hasSeenChangesProject(projectId: string, modified: Date, seenContracts?: boolean): boolean {
	if (modified == null || modified < CheckFromDate)
		return true;
	const cache = LocalStorage.get<Date>(ProjectKey) || {};
	const project = cache[projectId];
	if (project == null) {
		if (seenContracts) {
			setTimeout(() => {
				setSeenChangesProject(projectId, seenContracts);
			}, 10000);
		}
		return false;
	}
	// All dates coming from our API are UTC, but not converted
	const seenChanges = project.seen > new Date(DateEx.specifyUtc(modified)) && (!seenContracts || project.contracts);
	if (!seenChanges && seenContracts) {
		setTimeout(() => {
			setSeenChangesProject(projectId, seenContracts);
		}, 10000);
	}
	return seenChanges;
}

export const ProjectChangesUtil = {
	initialize,
	setSeenChangesProject,
	hasSeenChangesProject,
}