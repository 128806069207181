import { IPropertyTypes, propertyTypes } from "@wiberg/formbuilder";

export interface ITpPropertyTypes extends IPropertyTypes {
    // simple
	cron: string,
    color: string
	numberSlider: string;
	messageBar: string;
	textfieldCopyvalue: string;
	multilineString: string;
	hyperlink: string;
	// complex
	tree: string;
	rbs: string;
	resource: string;
	project: string;
	entityProperty: string;
	aadGroup: string;
	periodSpan: string;
	dateWithTodayFilter: string; 
}

export const tpPropertyTypes: ITpPropertyTypes = {
    // default
	...propertyTypes,

	// simple
    cron: "cron",
    color: "color",
	numberSlider: "numberSlider",
	messageBar: "messageBar",
	textfieldCopyvalue: "textfieldCopyvalue",
	multilineString: "multilineString",
	hyperlink: "hyperlink",

	// complex
	tree: "tree",
	rbs: "rbs",
	resource: "resource",
	project: "project",
	entityProperty: "entityProperty",
	aadGroup: "aadGroup",
	periodSpan: "periodSpan",
	dateWithTodayFilter: "dateWithTodayFilter"
}