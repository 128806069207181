export default class Locale_Project {
	ProjectValidation: string = "The project must have a name.";
	ProjectType: string = "Project type";
	OwnerIsRequiredField: string = "Owner is a required field.";
	CustomPropertiesValidation: string = "All required properties must be set!";
	ParentProject: string = "Parent project";
	ParentProjectDescription: string = "Have no effect in the application and is only usable in reporting or the like."
	AdministrativeProjectMustHaveParentProject: string = "Administrative projects must have a parent project selected.";
	TeamResource: string = "Team resource";

	ActiveDescription: string = "Projects can be activated and deactivated from the 'Additional actions' button in the overview action bar";

	ActiveState: string = "Project is Active";
	InActiveState: string = "Project is Inactive";

	Start: string = "Start";
	End: string = "End";
}