import { Configuration, EventType, PopupRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import * as microsoftTeams from '@microsoft/teams-js';
import { ApiCalls } from './apiv2';

interface AuthConfig {
    clientId: string;
    instance: string;
    tenantId: string;
}

let loginRequest: PopupRequest;
let teamsLoginRequest: {
    resources: string[];
    silent: boolean;
};

let msalConfig: Configuration;

export let msalInstance: PublicClientApplication;

let initStatus: 'ready' | 'pending' | 'failed' | 'success' = 'ready';

let authEndpoint = 'auth/options';

const isTeams =
    window.location.ancestorOrigins !== undefined &&
    window.location.ancestorOrigins.length >= 1 &&
    window.location.ancestorOrigins[0] === 'https://teams.microsoft.com';

export const setAuthEndpoint = (endpoint: string) => (authEndpoint = endpoint);

let authConfigPromise = Promise.resolve();

const getAuthConfig = (): Promise<AuthConfig> => {
    return fetch(window.location.origin + '/' + authEndpoint, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        cache: 'force-cache',
    }).then(res => {
        if (!res.ok) {
            throw new Error('failed to get auth config');
        }
        return res.json();
    });
};

let authError: any;

const SetInitializedMSALInstance = async (clientId?: string, instance?: string, tenantId?: string): Promise<void> => {
    loginRequest = {
        // Add here scopes for id token to be used at MS Identity Platform endpoints.
        scopes: [clientId + '/.default'],
    } as RedirectRequest;
    msalConfig = {
        auth: {
            clientId: clientId,
            // authority: authConfig.instance + '/' + authConfig.tenantId,
            authority: (!instance.endsWith('/') ? instance + '/' : instance) + tenantId,
            // authority: "https://login.microsoftonline.com" + "/" +  authConfig.tenantId,
            redirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: true,
            secureCookies: true,
        },
    };
    msalInstance = new PublicClientApplication(msalConfig);

    msalInstance.addEventCallback(event => {
        if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as any).account) {
            const account = (event.payload as any).account;
            msalInstance.setActiveAccount(account);
        }
        if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
            msalInstance.loginRedirect(loginRequest);
        }
    });

    // initialize msal, and await it - This is required for any other msal calls to work
    await msalInstance.initialize();

    // cached account
    await msalInstance.handleRedirectPromise();
};

const RejectTeamsAuth = (error: any) => {
    initStatus = 'failed';
    console.error(error);
    authError = error;
    return {
        error,
        retry: () => {
            initStatus = 'ready';
            msalInstance = null;
        },
    };
};

/**
 * This function might look strange at first look.
 * To conform to react suspense functionality we must throw a promise
 * in order to trigger the suspense. React will then keep calling the
 * function (indirectly from within a rendering component) until it
 * no longer throws a promise and then the suspense will end.
 */
export const getSuspendableMSALInstance = (
    onSuccess?: (msalInstance: PublicClientApplication) => any,
    onError?: (errorInfo: { error: any; retry: () => void }) => any,
) => {
    if (msalInstance && initStatus === 'success') {
        return msalInstance;
    }
    if (initStatus === 'ready') {
        initStatus = 'pending';

        authConfigPromise = getAuthConfig()
            .then(async (authConfig: AuthConfig) => {
                // console.log("🚀 ~ getSuspendableMSALInstance ~ authConfig", authConfig);
                // LocalStorage.set("tp-authconfig", authConfig);

                await SetInitializedMSALInstance(authConfig.clientId, authConfig.instance, authConfig.tenantId);

                const accounts = msalInstance.getAllAccounts();

                if (accounts.length > 0) {
                    msalInstance.setActiveAccount(accounts[0]);
                } else {
                    // login
                    try {
                        await msalInstance.loginRedirect(loginRequest);
                    } catch (e) {
                        if (e.errorCode === 'redirect_in_iframe') {
                            await msalInstance.ssoSilent(loginRequest);
                            return window.location.reload();
                        }
                    }
                }
                initStatus = 'success';
                try {
                    if (onSuccess) {
                        onSuccess(msalInstance);
                    }
                } catch (error) {}
            })
            .catch(error => {
                initStatus = 'failed';
                console.error(error);
                authError = error;
                if (onError) {
                    onError({
                        error,
                        retry: () => {
                            initStatus = 'ready';
                            msalInstance = null;
                        },
                    });
                }
                // throw error;
            });
        // throwing a promise will trigger react suspense
        throw authConfigPromise;
    } else if (initStatus === 'pending') {
        // react will keep trying to run this function until it no longer throws a promise thus ending the suspense
        throw authConfigPromise;
    } else if (initStatus === 'failed') {
        throw new Error('Authentication failed', { cause: authError });
    }
};

export const getSuspendableTeamsAuthInstance = (
    onSuccess?: (msalInstance: PublicClientApplication) => any,
    onError?: (errorInfo: { error: any; retry: () => void }) => any,
) => {
    if (msalInstance && initStatus === 'success') {
        return msalInstance;
    }
    if (initStatus === 'ready') {
        initStatus = 'pending';

        authConfigPromise = ApiCalls.getEnvironmentOptions()
            .then(async res => {
                const envOptions = res.data;
                teamsLoginRequest = {
                    resources: [`api://${envOptions.hostFQDN}/${envOptions.clientId}/access_as_user`],
                    silent: true,
                };

                await SetInitializedMSALInstance(envOptions.clientId, envOptions.instance, envOptions.tenantId);

                microsoftTeams.app
                    .initialize()
                    .then(() => {
                        microsoftTeams.authentication
                            .getAuthToken(teamsLoginRequest)
                            .then(() => {
                                initStatus = 'success';
                                // teamsToken = token;
                                try {
                                    if (onSuccess) {
                                        onSuccess(msalInstance);
                                    }
                                } catch (error) {}
                            })
                            .catch(error => {
                                if (onError) {
                                    onError(RejectTeamsAuth(error));
                                    // throw error;
                                }
                            });
                    })
                    .catch(error => {
                        if (onError) {
                            onError(RejectTeamsAuth(error));
                            // throw error;
                        }
                    });
            })
            .catch(error => {
                if (onError) {
                    onError(RejectTeamsAuth(error));
                    // throw error;
                }
            });
        // throwing a promise will trigger react suspense
        throw authConfigPromise;
    } else if (initStatus === 'pending') {
        // react will keep trying to run this function until it no longer throws a promise thus ending the suspense
        throw authConfigPromise;
    } else if (initStatus === 'failed') {
        throw new Error('Authentication failed', { cause: authError });
    }
};

export const authHeaders = Object.freeze({
    current: {
        Authorization: '',
    },
});

export const getAuthHeaders = async () => {
    if (initStatus === 'failed') {
        throw new Error('Authentication failed');
    }

    if (initStatus !== 'success') return;

    if (isTeams) {
        try {
            const authToken = await microsoftTeams.authentication.getAuthToken(teamsLoginRequest);
            const headers = {
                Authorization: `Bearer ${authToken}`,
            };

            authHeaders.current.Authorization = headers.Authorization;

            return headers;
        } catch (error) {
            console.error(error);
        }
    }

    const account = msalInstance.getActiveAccount();

    if (!account) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
    });

    const headers = {
        Authorization: `Bearer ${response.accessToken}`,
    };

    authHeaders.current.Authorization = headers.Authorization;

    return headers;
};
