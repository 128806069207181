import { ReportFilterExpression, ReportFilterOperator, ReportFilterCondition } from "../Entities/ReportFilter";
import { StringEx } from "./StringEx";
import { UserContext } from "./Context/UserContext";

const upperCaseFirstLetter = (str: string) => str[0].toUpperCase() + str.slice(1);
const upperCaseObj = <T,>(obj: T) => Object.fromEntries(Object.entries(obj).map(([key, val]) => [upperCaseFirstLetter(key), val]));


export function buildUrl(report_: any, projectId?: string, resourceId?: string): string {
	const report = upperCaseObj(report_)
	// if no filters return the plain url
	if (report.Filter == null || report.Filter.Parts == null || !report.Filter.Parts.length)
		return report.Url;
	// build filter url
	let baseFilterUrl = `${report.Url}&filter=`;
	report.Filter.Parts.forEach(_ => {
		// filter
		if ((_ as any).TypeName === "ReportFilterExpression")
			baseFilterUrl += oDataFilterStringify(_ as ReportFilterExpression);


		// condition
		else
			baseFilterUrl += ` ${GetConditionValue((_ as ReportFilterCondition))} `;
	});

	if (projectId)
		baseFilterUrl = StringEx.replaceAll(baseFilterUrl, "{ProjectId}", projectId);
	if (resourceId)
		baseFilterUrl = StringEx.replaceAll(baseFilterUrl, "{ResourceId}", resourceId);
	baseFilterUrl = StringEx.replaceAll(baseFilterUrl, "{UserId}", UserContext.Resource.id);
	baseFilterUrl = StringEx.replaceAll(baseFilterUrl, "{UserEmail}", UserContext.LoginName);

	return baseFilterUrl;
}

export function oDataFilterStringify(filter: ReportFilterExpression): string {
	return `${filter.Property} ${GetOperatorValue(filter.Operator)} '${filter.Value}'`;
}

function GetOperatorValue(operator: ReportFilterOperator): string {
	switch (operator) {
		case ReportFilterOperator.Equals:
			return "eq";
		case ReportFilterOperator.NotEquals:
			return "ne";
		default:
			break;
	}
}

function GetConditionValue(condition: ReportFilterCondition): string {
	switch (condition) {
		case ReportFilterCondition.And:
			return "and";
		case ReportFilterCondition.Or:
			return "or";
		default:
			break;
	}
}

export const ReportEx = {
	buildUrl,
	oDataFilterStringify
}