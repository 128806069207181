import { Stack } from '@fluentui/react';
import { useCallback, useMemo, useState, memo, useRef } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router';
import shallow from 'zustand/shallow';
import { ApiCalls } from '../../../api/api';
import { DateResolution, GridData, GridRow, MoveAllocationDto, QueryGroup } from '../../../api/generated/data-contracts';
import { useUISettings } from '../../../context/network/http/QueryProvider/queries/UISettings';
import { useStore } from '../../../context/store';
import { useControlledState } from '../../../hooks/useControlledState';
import { useUnitType } from '../../../Utilities/UnitTypeUtil';
import { RowsMap } from '../_grid/grid-types';
import { ViewFilterPanel } from './components/ViewFilterPanel/ViewFilterPanel';
import { Toolbar } from './components/Toolbar/Toolbar';
import { ResourcePlannerGrid } from './components/Grid/components/ResourcePlannerGrid';
import { RecoilRoot } from 'recoil';
import { OnMoveRowInfo, OnUpdateCellInfo, RowDeleteInfo, RowUpdateInfo, UpdateRowTreeInfo } from '../_grid/useGrid';
import { resourceSchema } from '../../../forms/resource/resourceSchema';
import { GridSkeleton } from '../../../_components/Skeleton/GridSkeleton';
import { forEachAsync } from '../../../helpers/forEachAsync';
import { forEachGridCellInColumnRows } from '../_grid/helpers/forEachGridCell';
import { reduceMapAsync } from '../_grid/helpers/mapAsync';
import { useResourcePlannerData } from './hooks/useResourcePlannerData';
import { fallbackView, fallbackViewId } from './fallbackGridView';

export const ResourcePlanner = () => {
    return (
        <RecoilRoot>
            <ResourcePlannerInner />
        </RecoilRoot>
    );
};


export const ResourcePlannerInner = memo(() => {
    const { pathname } = useLocation();
    const { currentUnitType } = useUnitType();
    const { data: uiSettings } = useUISettings();
    const views = useMemo(() => uiSettings.settings.gridViews.resourcePlannerViews || [], [uiSettings.settings.gridViews.resourcePlannerViews]);

    const { timelineStart, timelineEnd, timelineResolution, defaultViewId } = useStore(store => {
        const defaultViewId = store.defaultViews[pathname] || views[0]?.id || fallbackViewId;
        return {
            timelineEnd: store.timeline.end,
            timelineStart: store.timeline.start,
            timelineResolution: store.timeline.resolution,
            defaultViewId,
        };
    }, shallow);
    const [currentViewId, setCurrentViewId] = useState(defaultViewId || '');
    const currentView = useMemo(() => views.find(view => view.id === currentViewId) || views[0] || fallbackView, [currentViewId, views]);
    const [currentViewFilter, setCurrentViewFilter] = useControlledState<QueryGroup | null>(() => currentView?.filters?.resources || null, [currentView]);
    const [viewHasFilterChanges, setViewHasFilterChanges] = useState(false);
    const gridRef = useRef<{ getUpdatedData: () => Promise<GridData> }>(null);

    const gridData = useResourcePlannerData({ currentUnitType, currentViewFilter, gridRef });

    const { mutateAsync } = useMutation((updates: OnUpdateCellInfo[]) =>
        ApiCalls.addActivities(
            updates.map(({ newValue, relations, row, cell, columnIndex }) => {
                const [project, resource] = relations;
                const contractId = row.identity.id;
                const resourceId = resource.identity.id;
                const projectId = project.identity.id;
                const start = row.columnRows[columnIndex].start;
                const end = row.columnRows[columnIndex].end;
                return {
                    projectId,
                    contractId,
                    resourceId,
                    work: newValue.toNumber(),
                    type: 2,
                    resolution: timelineResolution as DateResolution,
                    start,
                    end,
                };
            }),
        ).then(res => res.data),
    );

    const onUpdateCell = useCallback(
        async (updates: OnUpdateCellInfo[]) => {
            await mutateAsync(updates);
        },
        [mutateAsync],
    );

    const { mutateAsync: moveRow } = useMutation(
        ({ contractId, sourceResourceId, targetResourceId }: Pick<MoveAllocationDto, 'contractId' | 'sourceResourceId' | 'targetResourceId'>) =>
            ApiCalls.moveAllocation({ end: timelineEnd, start: timelineStart, resolution: timelineResolution as DateResolution, contractId, sourceResourceId, targetResourceId }),
    );

    const onMoveRow = useCallback(
        ({ rowAncestors, rowToMove, newRowAncestors }: OnMoveRowInfo) => {
            const newResource = newRowAncestors[1];
            const oldResource = rowAncestors[1];
            const sourceResourceId = oldResource.identity.id;
            const targetResourceId = newResource.identity.id;
            const contractId = rowToMove.identity.id;
            moveRow({ contractId, sourceResourceId, targetResourceId });
        },
        [moveRow],
    );

    const onClickSaveFilter = useCallback(
        (filter: QueryGroup | null) => {
            setCurrentViewFilter(filter);
            setViewHasFilterChanges(true);
            setOpenPanel(false);
        },
        [setCurrentViewFilter],
    );
    const [openPanel, setOpenPanel] = useState(false);

    return (
        <>
            <Stack styles={{ root: { width: '100%', height: '100%' } }}>
                <Toolbar
                    currentView={currentView}
                    setCurrentViewId={setCurrentViewId}
                    setOpenPanel={setOpenPanel}
                    setViewHasFilterChanges={setViewHasFilterChanges}
                    viewHasFilterChanges={viewHasFilterChanges}
                    views={views}
                    currentViewFilter={currentViewFilter}
                />
                {gridData ? (
                    <ResourcePlannerGrid
                        ref={gridRef}
                        data={gridData}
                        onUpdateCells={onUpdateCell}
                        onUpdateCellTree={onUpdateCellTree}
                        onUpdateCellTreeWhenDeletingRow={onUpdateCellTreeWhenDeletingRow}
                        onShouldDeleteRow={onShouldDeleteRow}
                        onUpdateRowTree={onUpdateRowTree}
                        onMoveRow={onMoveRow}
                    />
                ) : (
                    <GridSkeleton />
                )}
            </Stack>
            <ViewFilterPanel
                schema={resourceSchema}
                entityType={'resource'}
                customProperties={uiSettings.settings.resourceCustomProperties}
                onlyTheseProperties={uiSettings.settings.resourcePlannerFilterOptions}
                initialFilterValue={currentViewFilter}
                headerText={'Filter editor'}
                onClickSave={onClickSaveFilter}
                openPanel={openPanel}
                setOpenPanel={setOpenPanel}
                onDismissPanel={() => {
                    setOpenPanel(false);
                }}
            />
        </>
    );
});

const onShouldDeleteRow = (row: GridRow, rows: RowsMap) => Boolean(row.parentRowId);

const onUpdateCellTree = ({ columnIndex, diff, row, totalRows, setCellValue }: RowUpdateInfo) => {
    const leftCell = row.columnRows[columnIndex].cells[0];
    setCellValue(leftCell, value => value.minus(diff));
    for (const totalRow of totalRows) {
        const totalCell = totalRow.columnRows[columnIndex].cells[0];
        setCellValue(totalCell, value => value.minus(diff));
    }
};

const onUpdateCellTreeWhenDeletingRow = async ({ row, totalRows, getCellValue, setCellValue }: RowDeleteInfo) => {
    // Update total column of all ancestor rows
    await forEachAsync(totalRows, async totalRow => {
        if (totalRow.totalColumnRows) {
            await forEachAsync(totalRow.totalColumnRows, async (totalColumnRow, totalColumnRowIndex) => {
                await forEachAsync(totalColumnRow.cells, async (totalCell, totalCellIndex) => {
                    if (row.totalColumnRows?.[totalColumnRowIndex]?.cells?.[totalCellIndex]) {
                        const rowCell = row.totalColumnRows?.[totalColumnRowIndex]?.cells?.[totalCellIndex];
                        if (rowCell) {
                            const rowCellValue = await getCellValue(rowCell);
                            setCellValue(totalCell, value => value.minus(rowCellValue));
                        }
                    }
                });
            });
        }
    });
};

const onUpdateRowTree = async ({
    rowToMove,
    rowAncestors,
    rowsMap,
    newParentIndex,
    newParentId,
    getCellValue,
    setCellValue,
    getSubrowFamily: getSubRowFamily,
    abortMove,
}: UpdateRowTreeInfo) => {
    const [originalProjectRow, originalResourceRow] = rowAncestors;
    if (newParentId === originalResourceRow?.id) {
        abortMove();
        console.warn('Cannot move contract to original resource');
        return rowsMap;
    }
    // Clean up original resource row
    await forEachGridCellInColumnRows(originalResourceRow.columnRows, async (cell, cellIndex, columnIndes, columnRow) => {
        const movedCellValue = await getCellValue(rowToMove.columnRows[columnIndes].cells[1]);
        setCellValue(cell, existingCellValue => existingCellValue.plus(movedCellValue));
    });
    const newResourceRow = rowsMap.get(newParentId);
    const newParentSubrows = getSubRowFamily(newParentId);
    const existingProjectRow = newParentSubrows.find(row => row.identity?.id === originalProjectRow.identity?.id);

    // Recalc new resource row
    await forEachGridCellInColumnRows(newResourceRow.columnRows, async (cell, cellIndex, columnIndes, columnRow) => {
        const movedCellValue = await getCellValue(rowToMove.columnRows[columnIndes].cells[1]);
        setCellValue(cell, existingCellValue => existingCellValue.minus(movedCellValue));
    });
    if (existingProjectRow) {
        // Recalc the new project row
        await forEachGridCellInColumnRows(existingProjectRow.columnRows, async (cell, cellIndex, columnIndes, columnRow) => {
            const movedCellValue = await getCellValue(rowToMove.columnRows[columnIndes].cells[1]);
            setCellValue(cell, existingCellValue => existingCellValue.plus(movedCellValue));
        });

        const existingContractRow = newParentSubrows.find(row => row.identity?.id === rowToMove.identity?.id);
        if (existingContractRow) {
            // The moved row is replacing the original contract row. We need to do this so the moveRow function is
            // able to capture state before and after the update (used for undo/redo), and the moveRow function
            // only knows the original moved contract id.

            await forEachGridCellInColumnRows(rowToMove.columnRows, async (cell, cellIndex, columnIndes, columnRow) => {
                const movedCellValue = await getCellValue(existingContractRow.columnRows[columnIndes].cells[1]);
                setCellValue(cell, existingCellValue => existingCellValue.plus(movedCellValue));
            });

            return reduceMapAsync(
                rowsMap,
                async (rows, row, i) => {
                    if (row.id !== rowToMove.id && row.id !== originalProjectRow.id) {
                        if (existingContractRow.identity?.id === row.identity?.id) {
                            // replace the original contract with the moved contract.
                            rows.set(rowToMove.id, { ...rowToMove, parentRowId: row.parentRowId });
                        } else {
                            rows.set(row.id, row);
                        }
                    }
                    return rows;
                },
                new Map() as RowsMap,
            );
        }
    }
    return reduceMapAsync(
        rowsMap,
        async (rows, row, i) => {
            if (row.id !== rowToMove.id && row.id !== originalProjectRow.id) {
                rows.set(row.id, row);
                if (i === newParentIndex) {
                    rows.set(originalProjectRow.id, { ...originalProjectRow, parentRowId: newParentId });
                    rows.set(rowToMove.id, { ...rowToMove, parentRowId: originalProjectRow.id });
                }
            }
            return rows;
        },
        new Map() as RowsMap,
    );
};
