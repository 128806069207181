import { Origin } from "../../../../../api/generated/data-contracts";
import { settings } from "../../../../../context/network/http/QueryProvider/queries/UISettings";
import { guid } from "../../../../../helpers/guid";
import { refactorHelper } from "../../../../../helpers/refactorHelper";
import { ArrayEx } from "../../../../../Utilities/ArrayEx";

const getAppOrigin = () : Origin => {
	return settings().origins.find(_ => _.id === guid.empty);
}

export const getDisallowedCustomProperties = (type: "project" | "resource" | "contract", includeCustomPropertyPath?: boolean) : string[] => {

	let customPropIds: string[];
	let allowed: string[];

	const appOrigin = getAppOrigin();
	allowed =  refactorHelper.getOriginProperties(appOrigin, type);

	if (type === "resource") {
		customPropIds = settings().resourceCustomProperties.map(_ => _.id);
	} else if (type === "project") {
		customPropIds = settings().projectCustomProperties.map(_ => _.id);
	} else return [];

	if (includeCustomPropertyPath) {
		customPropIds = customPropIds.map(_ => `customProperties.${_}`)
	}

	if (!allowed) return customPropIds;
	// extract custom properties
	let hasCustomProperties = false;
	// add whole custom property path as well to support both ways of using them
	allowed = allowed.map(_ => {
		if (_.indexOf("customProperties.") >= 0) {
			hasCustomProperties = true;
			if (!includeCustomPropertyPath)
			_ = _.replace("customProperties.", "");
		}
		return _;
	});

	// add custom props to allow list - all deny is handled by the individual custom property
	if (hasCustomProperties)
		allowed.push("customProperties");

	// get difference
	return ArrayEx.difference(customPropIds, allowed);
}