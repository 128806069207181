import React, { CSSProperties } from 'react';
import { FunctionComponent } from 'react';
import { EnvironmentState } from '../../Entities/EnvironmentState';
import { language } from '../../Services/LocalizationService';
// import { useUi } from '../../Utilities/Context/UiContext';
// import { useStore } from '../../context/store';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';

const watermarkContainerStyles: CSSProperties = {
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	padding: 5,
	zIndex: 10000,
	transform: 'rotate(0deg)',
	pointerEvents: 'none'
};

const watermarkFlexContainerStyles: CSSProperties = {
	flexDirection: 'row',
	display: 'flex',
	justifyContent: 'space-evenly'
};

const textStyles: CSSProperties = {
	color: 'rgba(255, 0, 0, 0.02)',
	padding: 5,
	textAlign: 'center',
	transform: 'rotate(-45deg)',
	fontSize: '13rem'
};

const DemoModeViewer: FunctionComponent<any> = () => {
    // const ctx = useUi();
	
	// const environmentState = useStore(store => store.ui.environmentState)
	const environmentState = useUISettings().data.environmentState;
	// const [environmentState, setEnvironmentState] = useState<EnvironmentState>(null);
	const environmentDemoName: string = EnvironmentState[EnvironmentState.Demo];

	// useEffect(()=>{
	// 	if(environmentState == null){
	// 		setEnvironmentState(ctx.environmentState);
	// 	}
	// }, [ctx.environmentState])


    const watermarkText = language.Common.Demo;

	return environmentState && environmentState.toString() === environmentDemoName ? (
        <div style={watermarkContainerStyles}>
            <div style={watermarkFlexContainerStyles}>
                <div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                </div>
                <div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                </div>
                <div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                    <div style={textStyles}>{watermarkText}</div>
                </div>
            </div>
        </div>
    ) : null;
};

export default DemoModeViewer;
