import { ProposalState } from "../api/generated/data-contracts";
import { AddActivityDto } from "../Entities/Dto/AddActivityDto";
import { AddVersionedActivityDto } from "../Entities/Dto/Versions/AddVersionedActivityDto";
import { guid } from "../helpers/guid";
import { PluginIDs } from "../Utilities/PluginIDs";
// import { NotificationService } from "./NotificationService";
import { Plugin } from "./PluginInvoker";
import { ProjectCapacityService } from "./ProjectCapacityService";

export class VersionedProjectCapacityService {
	public static saveCell = async (versionedId: string, dto: AddActivityDto, scenarioId: string, state: ProposalState, cellId: string, errorHandler: (msg: string) => void, ignoreContainerName?: boolean) : Promise<string> => {
			if (ignoreContainerName) dto.ContractGroupName = null;
			const versionedDto = dto  as AddVersionedActivityDto;
			versionedDto.VersionedContractId = versionedId ?? guid.newGuid();
			versionedDto.ScenarioId = scenarioId;
			versionedDto.State = state;
			VersionedProjectCapacityService.upsertCell(versionedDto, cellId, errorHandler);
			return dto.ContractId;
	}

	public static upsertCell = (dto: AddVersionedActivityDto, cellId: string, errorHandler: (msg: string) => void, ignoreContainerName?: boolean) : Promise<void> => {
		if (ignoreContainerName) dto.ContractGroupName = null;
		return Plugin.Invoke<any>(PluginIDs.AddVersionedActivity, dto)
			.then(_ => {
				ProjectCapacityService.HighlightCell([cellId], true, "highlightdone");
			})
			.catch(_ => {
				// NotificationService.Instance.Error("Failed saving cell");
				errorHandler("Failed saving cell");
				ProjectCapacityService.HighlightCell([cellId], true, "highlighterror");
			});
	}
}