import { Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import { language } from "../../Services/LocalizationService";

export const LoadingSpinner: React.StatelessComponent<{children?: React.ReactNode, visible: boolean, message?: string, className?: string}> = ({children, visible, message, className}) =>
(visible ? 
    (<div>
        <div className="pm-spinner-loadspinner">
			<Spinner size={ SpinnerSize.large } label={ (message == null ? language.Common.LoadingDot : message) } className={`tp-spinner ${className}`} />
        </div>
    </div>)
    : (<div>{children}</div>));