import 'reflect-metadata';
import { ArrayEx } from '../../Utilities/ArrayEx';
import { EditorHandler } from '../../Utilities/EditorHandler';
import { EditorGrouping } from './EditorGrouping';
import { DefaultButton, IDropdownOption, INavLink, INavLinkGroup, INavStyles, Label, Nav, Pivot, PivotItem, ScrollablePane, Stack } from '@fluentui/react';
import { PropertyInfoService } from '../../Services/PropertyInfoService';
import { PropertyInfo } from '../../Utilities/PropertyInfo/PropertyInfo';
import { PropertyGrouping } from '../../Utilities/PropertyInfo/Grouping';
import { IObjectEditorItemType } from '../../Entities/Main/IObjectEditorItemType';
import { IDs } from '../../Utilities/IDs';
import { ObjectEx } from '../../Utilities/ObjectEx';
import { RootSettingsEditorCoachmarks } from '../../userGuidance/areas/RootSettingsEditorCoachmarks';
import { Component } from 'react';

interface IProps<T extends IObjectEditorItemType> {
    ItemType: { new (): T };
    Item: T;
    HiddenProperties: Array<string>;
    DisabledProperties: Array<string>;
    ChangesCallback?: (item: T, property: string, value: any) => void;
    ReadOnly?: boolean;
    Hide?: boolean;
    KeyPrefix?: string;
    NoGrouping?: boolean;
    DisableHiding?: boolean;
    GroupType?: GroupType;
    DefaultSelectedArea?: string;
    PropertyExtraContent?: (propName: string, readonly: boolean, update?: (value: any) => void) => JSX.Element;
    PropertyFilterLevel?: number;
    OnHeaderClick?: React.MouseEventHandler<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement>;
    // OnHeaderClick? :(ev: any) => void;
}

interface IState<T> {
    Item: T;
    Properties: Array<PropertyInfo>;
    GroupedProperties: { [key: string]: PropertyInfo[] };
    SortedGroupKeys: Array<string>;
    ReadOnly: boolean;
    NavLinks: INavLink[];
    SelectedNavItemKey: string;
    HeaderProps: any;
}

const navStyles: Partial<INavStyles> = {
    root: {
        width: '300px',
        height: 'calc(100vh - 44px - 76px)',
        boxSizing: 'border-box',
        // border: '1px solid #eee',
        overflowY: 'auto',
    },
};

export enum GroupType {
    Default = 0,
    Tabs = 1,
    Nav = 2,
}
export class ObjectEditor<T extends IObjectEditorItemType> extends Component<IProps<T>, IState<T>> {
    constructor(props) {
        super(props);
        this.state = {
            Item: this.props.Item,
            Properties: [],
            GroupedProperties: {},
            SortedGroupKeys: [],
            ReadOnly: props.ReadOnly,
            NavLinks: [],
            SelectedNavItemKey: this.props.DefaultSelectedArea,
            HeaderProps: [],
        };
    }

    async componentWillReceiveProps(nextProps: IProps<T>) {
        // if (!nextProps.Item) return; // will commenting out this, give trouble - ewi
        const item = nextProps.Item != null ? nextProps.Item : (new this.props.ItemType() as T);

        // handle change of propertyfilter level
        let partialState: Pick<IState<T>, 'Properties' | 'GroupedProperties' | 'SortedGroupKeys' | 'NavLinks'> = {} as any;
        if (nextProps.PropertyFilterLevel !== this.props.PropertyFilterLevel) {
            const propsState = this.RefreshProperties(item, nextProps.PropertyFilterLevel);
            const NavLinks =
                this.props.GroupType === GroupType.Nav
                    ? this.buildNavLinks(propsState.Properties, nextProps.DefaultSelectedArea ?? this.state.SelectedNavItemKey)
                    : ({} as any);
            partialState = { ...propsState, ...{ NavLinks } };
        }

        if (nextProps.DefaultSelectedArea !== this.props.DefaultSelectedArea) {
            this.setState({
                HeaderProps: this.getHeaderGroupings(
                    this.state.Properties.filter(_ => {
                        if (!_.Grouping!) return;
                        return _.Grouping.Name.startsWith(nextProps.DefaultSelectedArea);
                    }),
                ),
            });
        }

        // 	// ObjectEx.AlterTreeNode(this.state.NavLinks[2], item => item.key == 'Grid settings', item => item.links, item => item.isExpanded = true);
        // 	ObjectEx.TreeExpandNested(this.state.NavLinks[2], item => item.key == nextProps.DefaultSelectedArea, item => item.links, item => item.isExpanded = true);
        // 	partialState.NavLinks = [... this.state.NavLinks]
        // };

        if (nextProps.DefaultSelectedArea != null && nextProps.DefaultSelectedArea !== this.props.DefaultSelectedArea)
            (partialState as any).SelectedNavItemKey = nextProps.DefaultSelectedArea;

        this.setState({
            Item: { ...(item as object) } as T,
            ReadOnly: nextProps.ReadOnly,
            ...partialState,
        });
    }

    async componentDidMount() {
        // if (!nextProps.Item) return; // will commenting out this, give trouble - ewi
        const item = this.props.Item != null ? this.props.Item : (new this.props.ItemType() as T);
        const partialState = this.RefreshProperties(item, this.props.PropertyFilterLevel);
        this.setState({ Item: { ...(item as object) } as T, ReadOnly: this.props.ReadOnly, ...partialState });
    }

    async componentDidUpdate(prevProps: Readonly<IProps<T>>, prevState: Readonly<IState<T>>) {
        if (!this.props.Item) return;

        const prevStateProps = prevState.Properties.length !== this.state.Properties.length;
        if (
            (prevStateProps && (this.state.SelectedNavItemKey ?? this.props.DefaultSelectedArea != null)) ||
            this.props.DefaultSelectedArea !== prevProps.DefaultSelectedArea
        ) {
            this.setState({
                NavLinks: this.buildNavLinks(this.state.Properties, this.props.DefaultSelectedArea ?? this.state.SelectedNavItemKey),
            });
        }
        // Header Groupings
        if (prevStateProps && prevState.SelectedNavItemKey !== this.state.SelectedNavItemKey) {
            this.setState({
                HeaderProps: this.getHeaderGroupings(
                    this.state.Properties.filter(_ => {
                        if (!_.Grouping!) return;
                        return _.Grouping.Name.startsWith(prevProps.DefaultSelectedArea);
                    }),
                ),
            });
        }
    }

    // this is still used!! - ewi
    private OnItemChange = (prop: string, value: any) => {
        const item = this.props.Item || this.state.Item;
        item[prop] = value;

        this.FirePropertyInfoOnchange(item, prop);

        const partialState = this.RefreshProperties(item, this.props.PropertyFilterLevel);
        this.setState({ Item: { ...(item as object) } as T, ...partialState }, () =>
            this.props.ChangesCallback ? this.props.ChangesCallback(item, prop, value) : () => {},
        );
    };

    private FirePropertyInfoOnchange = (item: T, prop: string): void => {
        const propertyInfo = this.state.Properties.find(_ => _.PropertyName === prop);
        const onChangeAction = propertyInfo.OnChange;
        if (!onChangeAction) return;
        onChangeAction(item);
    };

    // this is used for hiding properties based on property values
    private RefreshProperties = (
        item: T,
        propertyFilterLevel: number = 0,
    ): Pick<IState<T>, 'Properties' | 'GroupedProperties' | 'SortedGroupKeys' | 'HeaderProps'> => {
        let properties = PropertyInfoService.GetPFProperties(this.props.ItemType, false, this.props.HiddenProperties, propertyFilterLevel);

        // hide props
        if (!this.props.DisableHiding) properties = properties.filter(_ => !_.Hide || !_.Hide(item));

        // grouping
        const grouped = ArrayEx.groupByNestedProperty(properties, 'Grouping.Name');
        const sortedGroupKeys = Object.keys(grouped).sort((a, b) => {
            if (!grouped[a][0].Grouping) return -1;
            if (!grouped[b][0].Grouping) return 1;
            return grouped[a][0].Grouping.Order > grouped[b][0].Grouping.Order ? 1 : -1;
        });

        // Header Groupings
        const headerProps = this.getHeaderGroupings(
            properties.filter(_ => {
                if (!_.Grouping!) return;
                return _.Grouping.Name.startsWith(this.state.SelectedNavItemKey ?? this.props.DefaultSelectedArea);
            }),
        );

        return {
            Properties: properties,
            GroupedProperties: grouped,
            SortedGroupKeys: sortedGroupKeys,
            HeaderProps: headerProps,
        };
    };

    // is still used!!
    private OnChangeMultiSelect = (propertyKey: string, option: IDropdownOption): void => {
        const item = this.state.Item[propertyKey] || [];
        if (option.selected) item.push(option.key);
        else {
            const currIndex = item.indexOf(option.key);
            if (currIndex > -1) {
                item.splice(currIndex, 1);
            }
        }
        this.OnItemChange(propertyKey, item);
    };

    private GenerateHeaderId = (segments: string[], ix: number): string => {
        const segmentId = [];
        segments.forEach((segment: string, idx: number) => {
            if (idx > ix) return;
            segmentId.push(segment);
        });
        return segmentId.join('|');
    };

    render() {
        if (!this.state.Item) return null;
        return (
            <div className={this.props.GroupType === 2 ? 'tp-object-editor-tabs disabled' : 'tp-object-editor'}>
                {this.state.SortedGroupKeys.length < 1 || this.props.NoGrouping ? (
                    EditorHandler.RenderPFProperties(
                        this.state.Properties,
                        this,
                        this.DynamicKeyPrefix + this.props.KeyPrefix,
                        this.state.ReadOnly,
                        null,
                        this.props.PropertyFilterLevel,
                    )
                ) : this.props.GroupType === 2 ? (
                    <>
                        <div className={'tp-object-editor-nav-wrapper'}>
                            <div className={'tp-object-editor-nav-menu'}>
                                <Nav
                                    onLinkClick={this.linkClickHandler}
                                    groups={[{ links: this.state.NavLinks } as INavLinkGroup]}
                                    styles={navStyles}
                                    selectedKey={this.state.SelectedNavItemKey}
                                />
                                <RootSettingsEditorCoachmarks />
                            </div>
                            <div className={'tp-object-editor-nav-content'}>
                                <Stack tokens={{ childrenGap: 8 }}>
                                    {Object.keys(this.state.HeaderProps).map(key => {
                                        const headers: string[] = key.split('|');
                                        return (
                                            <div key={key}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {headers.map((segment: string, ix: number) => {
                                                        const id = this.GenerateHeaderId(headers, ix);
                                                        return (
                                                            <div key={id} style={{ display: 'flex' }}>
                                                                {ix === 0 ? null : (
                                                                    <Label style={{ color: 'darkgray', paddingRight: 4, paddingLeft: 4 }}>{'>'}</Label>
                                                                )}
                                                                {segment ===
                                                                this.state.SelectedNavItemKey.split('|')[
                                                                    this.state.SelectedNavItemKey.split('|').length - 1
                                                                ] ? (
                                                                    <DefaultButton
                                                                        onClick={this.props.OnHeaderClick}
                                                                        style={{ border: 'none', padding: 0, minWidth: 'auto' }}
                                                                    >
                                                                        <Label id={id} style={{ cursor: 'pointer', fontSize: 16 }}>
                                                                            {segment}
                                                                        </Label>
                                                                    </DefaultButton>
                                                                ) : (
                                                                    <DefaultButton
                                                                        onClick={this.props.OnHeaderClick}
                                                                        style={{ border: 'none', padding: 0, minWidth: 'auto' }}
                                                                    >
                                                                        <Label id={id} style={{ color: 'darkgray', cursor: 'pointer', fontSize: 16 }}>
                                                                            {segment}
                                                                        </Label>
                                                                    </DefaultButton>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className={'tp-object-editor-property-list'} style={{ paddingLeft: 30 }}>
                                                    {EditorHandler.RenderPFProperties(
                                                        this.state.HeaderProps[key],
                                                        this,
                                                        this.DynamicKeyPrefix + this.props.KeyPrefix,
                                                        this.state.ReadOnly,
                                                        this.ExtraContent(),
                                                        this.props.PropertyFilterLevel,
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Stack>
                            </div>
                        </div>
                    </>
                ) : this.props.GroupType === 1 ? (
                    <Pivot className={'tp-objectlisteditor-tab'} styles={{ root: { paddingLeft: 16, paddingRight: 16 } }}>
                        {this.state.SortedGroupKeys.map(_ =>
                            this.RenderTabGrouping(this.state.GroupedProperties[_][0].Grouping, this.state.GroupedProperties[_]),
                        )}
                    </Pivot>
                ) : (
                    this.state.SortedGroupKeys.map(_ => {
                        return this.RenderGrouping(this.state.GroupedProperties[_][0].Grouping, this.state.GroupedProperties[_]);
                    })
                )}
            </div>
        );
    }

    private getHeaderGroupings = (properties: Array<PropertyInfo>) => {
        const groupNames: any = [];
        const groups = properties.reduce((groups, item: PropertyInfo) => {
            const group = groups[item.Grouping.Name] || [];
            group.push(item);
            groupNames.push(item.Grouping.Name);
            groups[item.Grouping.Name] = group;
            return groups;
        }, {} as { [key: string]: PropertyInfo[] });
        return groups;
    };

    private linkClickHandler = (event: React.MouseEvent<HTMLElement, MouseEvent>, item: INavLink) => {
        //Collapse is a grouping with children is clicked on while expanded
        if (this.state.SelectedNavItemKey === item.key && item.isExpanded === true) return;

        //Allows clicking on group parent header while current object is its child
        if (!(item.links.length > 0) || item.isExpanded === true) {
            event.preventDefault();
        }

        //Avoid changing state if same grouping header is clicked
        if (this.state.SelectedNavItemKey !== item.key)
            this.setState({
                SelectedNavItemKey: item.key,
                HeaderProps: this.getHeaderGroupings(this.state.Properties.filter(_ => _.Grouping.Name.startsWith(item.key))),
            });
    };

    private buildNavLinks = (properties: Array<PropertyInfo>, initialSelectedKey: string): INavLink[] => {
        const subGroups = ArrayEx.distinct(
            properties.map(_ => (_.Grouping?.Name ? _.Grouping.Name : '')),
            _ => _,
        );

        // expand initial selected groups
        const alterItem = (item: INavLink) => (item.isExpanded = initialSelectedKey.startsWith(item.key));

        const groups = ObjectEx.buildTrees<INavLink>(subGroups, 'name', 'key', 'links', alterItem);
        return groups;
    };

    // used to force re-render when setting property values from the extracontent
    private DynamicKeyPrefix: string;
    private ExtraContent = (): ((propname: string, readonly: boolean) => JSX.Element) => {
        return this.props.PropertyExtraContent != null
            ? (propName, readonly) =>
                  this.props.PropertyExtraContent(propName, readonly, value => {
                      this.OnItemChange(propName, value);
                      this.DynamicKeyPrefix = IDs.makeId();
                  })
            : null;
    };

    private RenderGrouping = (grouping: PropertyGrouping, properties: Array<PropertyInfo>): JSX.Element => {
        // if (!grouping) grouping = new PropertyGrouping("Misc", 1000);
        if (!grouping)
            return EditorHandler.RenderPFProperties(
                properties,
                this,
                this.DynamicKeyPrefix + this.props.KeyPrefix,
                this.state.ReadOnly,
                this.ExtraContent(),
                this.props.PropertyFilterLevel,
            );
        return (
            <EditorGrouping
                key={`tp-objecteditor-${grouping.Name.replace(/\s/g, '')}`}
                Title={this.props.GroupType === GroupType.Nav ? grouping.Name : this.GetGroupingName(grouping.Name)}
                Collapsed={grouping.DefaultCollapsed}
                children={EditorHandler.RenderPFProperties(
                    properties,
                    this,
                    this.DynamicKeyPrefix + this.props.KeyPrefix,
                    this.state.ReadOnly,
                    this.ExtraContent(),
                    this.props.PropertyFilterLevel,
                )}
            />
        );
    };

    private GetGroupingName = (groupingName: string): string => {
        const rootName: string[] = groupingName.split('|');
        return rootName[0];
    };

    private RenderTabGrouping = (grouping: PropertyGrouping, properties: Array<PropertyInfo>): JSX.Element => {
        if (!grouping)
            return EditorHandler.RenderPFProperties(
                properties,
                this,
                this.DynamicKeyPrefix + this.props.KeyPrefix,
                this.state.ReadOnly,
                this.ExtraContent(),
                this.props.PropertyFilterLevel,
            );
        return (
            <PivotItem
                className={'tp-grouping-content'}
                key={`tp-objecteditor-${grouping.Name.replace(/\s/g, '')}`}
                headerText={grouping.Name.split('|')[0]}
                children={
                    <ScrollablePane styles={{ contentContainer: { padding: '0 16px 16px' } }}>
                        <Stack tokens={{ childrenGap: 8 }}>
                            {EditorHandler.RenderPFProperties(
                                properties,
                                this,
                                this.DynamicKeyPrefix + this.props.KeyPrefix,
                                this.state.ReadOnly,
                                this.ExtraContent(),
                                this.props.PropertyFilterLevel,
                            )}
                        </Stack>
                    </ScrollablePane>
                }
            />
        );
    };
}
