import { FunctionComponent, useRef } from 'react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import {  Dropdown, IDropdownOption,  TextField } from '@fluentui/react';
import { DropdownOptionEx } from '../../../Utilities/DropdownOptionEx';
import { TimelineResolution } from '../../../Entities/TimelineResolution';
import { PeriodSpan } from '../../../Entities/PeriodSpan';
import { language } from '../../../Services/LocalizationService';
 
interface IProps extends IDynamicEditorComponentProps {
	Item: PeriodSpan;
	Update: (freeze: PeriodSpan) => void;
	Key?: string;
}

const PeriodSpanEditor: FunctionComponent<IProps> = props => {

	const item = useRef<PeriodSpan>({...props.Item || new PeriodSpan()});

	const OnChange = (prop: string, value: any) => {		
		const copy = {...item.current} || new PeriodSpan();
		copy[prop] = value;
		item.current = copy;
		props.Update(copy);
	}

	const OnKeyDown = (event) => {
		// disable 'e' and decimals in number fields
		if (event.keyCode === 69) event.preventDefault();
		else if (event.keyCode === 110 || event.keyCode === 188 || event.keyCode === 190) event.preventDefault();
	}
	
	return 	(
		<div className="tp-periodspaneditor">
			<Dropdown
				label={language.Common.Resolution}
				key={ `${props.Key}-resolution` }
				disabled={ props.ReadOnly }
				options={ DropdownOptionEx.enumToDropdownOptions<TimelineResolution>(TimelineResolution) }
				defaultSelectedKey={ item.current.Resolution ?? TimelineResolution.Month }
				onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => OnChange("Resolution", option.key) }
				styles={{root: {width: "100px"}}}
			/>
			<TextField 
				label={language.Common.Backwards}
				key={ `${props.Key}-backward` }
				defaultValue={ (item.current.Backward || 0) + "" }
				onChange={(event, value) => OnChange("Backward", +value)}
				disabled={ props.ReadOnly }
				type={ "number" }
				onKeyDown={OnKeyDown}
			/>
			<TextField 
				label={language.Common.Forwards}
				key={ `${props.Key}-forward` }
				defaultValue={ (item.current.Forward || 0) + "" }
				onChange={(event, value) => OnChange("Forward", +value)}
				disabled={ props.ReadOnly }
				type={ "number" }
				onKeyDown={OnKeyDown}
			/>
		</div>
	)
}

export default PeriodSpanEditor;