export const string = {

	/** Lowercase entire string, remove whitespaces and uppercase on first char */
	formatAsTitle: (text: string) : string => {
		if (!text) return null;
		return text.toLowerCase().replace(/^\w/, c => c.toUpperCase());
	},

	replaceAll: (str: string, find: string, replace: string) : string => {
		return str.replace(new RegExp(find, 'g'), replace);
	},

	isAllUpperCase: (str: string): boolean => {
		return str === str.toUpperCase();
	},

	isNullOrWhiteSpace: (str: string): boolean => {
		return str == null || !str.replace(/\s/g, '').length;
	},

	splitCamelCase: (str: string) : string => {
		// do not split abbrevations
		if (string.isAllUpperCase(str)) return str;
		return str.split(/(?=[A-Z])/).join(' ');
	},

	toLowercaseSafe: (str: string) : string => {
		if (str == null) return null;
		return str.toLocaleLowerCase();
	},
	empty: "",
	lowerCaseFirstLetter: (str: string) : string => {
		if (str == null) return null;
		return str.charAt(0).toLocaleLowerCase() + str.slice(1);
	}
}