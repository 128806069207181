import { language } from '../../Services/LocalizationService';
import { IWorkProfile } from './IWorkProfile';
import { WorkWeekEditor } from './_components/WorkWeekEditor';
import { fieldOptionsFactory, IFormSchema } from 'spark-forms';
import { ruleUtil } from '../helpers/ruleUtil';
import { RulesOverview } from './_components/RulesOverview';

export const workProfileSchema: IFormSchema<IWorkProfile> = {
    fields: {
        id: fieldOptionsFactory.string({
            displayName: language.Common.Id,
            disable: item => true,
        }),
        name: fieldOptionsFactory.string({
            displayName: language.Common.Name,
            hide: item => item.resourceGuid != null,
        }),
        start: fieldOptionsFactory.date({
            displayName: language.WorkProfile.CapacityStart,
            description: language.WorkProfile.CapacityStartDescription,
            hide: item => item.resourceGuid != null,
            validation: {
                validationRule: async item => {
                    if (item.start != null || item.resourceGuid != null) {
                        return undefined;
                    }
                    return language.WorkProfile.CapacityStartValidation;
                },
            },
        }),
        end: fieldOptionsFactory.date({
            displayName: language.WorkProfile.CapacityEnd,
            description: language.WorkProfile.CapacityEndDescription,
            hide: item => item.resourceGuid != null,
            validation: {
                validationRule: async item => {
                    if (item.end != null || item.resourceGuid != null) {
                        return undefined;
                    }
                    return language.WorkProfile.CapacityEndValidation;
                },
            },
        }),
        weekHours: fieldOptionsFactory.customComponent({
            displayName: language.WorkProfile.WeekHours,
            config: { component: WorkWeekEditor },
        }),
        rules: fieldOptionsFactory.customComponent({
            displayName: language.WorkProfile.Rules,
            config: {
                component: RulesOverview,
            },
            validation: {
                validationRule: async item => {
                    if (ruleUtil.rulesOverlap(item.rules).overlap) {
                        return language.WorkProfile.RulesValidation;
                    }
                    return null;
                },
            },
        }),
    },
};
