import create from 'zustand';
import { persist } from 'zustand/middleware';
import { JsonEx } from '../Utilities/JsonEx';
import { entityCalloutContext, IEntityCalloutContext } from './contexts/entityCalloutContext';
import { getPersistNotificationContext, INotificationContext, notificationContext } from './contexts/notificationContext';
import { getPersistBlockContext, IPageBlockContext, pageBlockContext } from './contexts/pageBlockContext/pageBlockContext';
import { ITimelineContext, timelineContext } from './contexts/timelineContext';
import { getPersistUIContext, IUIContext, uiContext } from './contexts/uiContext';
import { getStorage } from './storage';
import { excludeKeys, produce } from './helpers'
import { getPersistGridContext, gridContext, IGridContext } from './contexts/gridContext';
import { viewContext, ViewContext } from './contexts/viewContext';
import { devLogger } from '../helpers/devLogger';

export interface IStore extends IUIContext, ITimelineContext, IEntityCalloutContext, IPageBlockContext, INotificationContext, IGridContext, ViewContext {
    hasHydrated: boolean;
	uiStartUpLoaded: boolean;
	uiStartUpRefreshed: boolean;
	readonly setUIStartupLoaded: (started: boolean) => void;
	readonly setUIStartupRefreshed: (refreshed: boolean) => void;
    readonly setStore: (updater: (store: IStore) => void) => void;
}

const setHydrated = () => useStore.setState({ hasHydrated: true });

export const useStore = create<IStore>(
    persist<IStore>(
        (set, get, api) => {
            return {
                ...uiContext(set, get, api),
                ...timelineContext(set, get, api),
                ...entityCalloutContext(set, get, api),
                ...pageBlockContext(set, get, api),
                ...notificationContext(set, get, api),
				...gridContext(set, get, api),
				...viewContext(set, get, api),
                hasHydrated: false,
				uiStartUpLoaded: false,
				setUIStartupLoaded: startup => {
					set(produce(store => {
						if (store.uiStartUpLoaded !== startup) {
							store.uiStartUpLoaded = startup
						}
					}))
				},
                setStore: storeOrUpdater => set(produce(storeOrUpdater)),
				uiStartUpRefreshed: false,
				setUIStartupRefreshed: refreshed => {
					set(produce(store => {
						if (store.uiStartUpRefreshed !== refreshed) {
							store.uiStartUpRefreshed = refreshed
						}
					}))
				},
            };
        },
        {
            name: 'tp-settings',
            onRehydrateStorage: state => {
                devLogger.log('hydration started');
                return (state, error) => {
                    devLogger.log('hydration done');
                    if (error) {
                        console.error('hydration error', error);
                    }
                    setHydrated();
                };
            },

            deserialize: JsonEx.parse,
            serialize: store => JsonEx.stringify({...store, state: excludeKeys(store.state, ["blockContent"])}),

            getStorage: getStorage(getPersistUIContext, getPersistNotificationContext, getPersistBlockContext, getPersistGridContext),
        },
    ),
);
