import { useMemo, useEffect } from 'react';
import { getColorFromString, useTheme } from '@fluentui/react';
import { useDragStateReset, useDragStateValue } from '../hooks/useGridDragHandler';

export const DragBox = () => {
    const { left, top, height, visible, width } = useDragStateValue();
    const resetDragBoxState = useDragStateReset();

    useEffect(() => {
        // RESET THAT BOX BY ANY MEANS IF APP IS NOT IN FOCUS
        window.addEventListener('blur', resetDragBoxState);
        window.addEventListener('focus', resetDragBoxState);
        document.addEventListener('visibilitychange', resetDragBoxState);
        return () => {
            window.removeEventListener('blur', resetDragBoxState);
            window.removeEventListener('focus', resetDragBoxState);
            document.removeEventListener('visibilitychange', resetDragBoxState);
        };
    }, [resetDragBoxState]);

    const theme = useTheme();
    const backgroundColor = useMemo(() => {
        const { r, g, b } = getColorFromString(theme.palette.accent);
        return `rgba(${r},${g},${b},0.2)`;
    }, [theme.palette.accent]);

    return visible ? (
        <div
            style={{
                position: 'fixed',
                height,
                width,
                top,
                left,
                backgroundColor,
                zIndex: 9999999999,
            }}
        />
    ) : null;
};
