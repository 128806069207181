import React, { forwardRef } from 'react';
import classNames from 'classnames';

import styles from './List.module.scss';
import { Wrapper } from '../Wrapper/Wrapper';

export interface Props {
    children: React.ReactNode;
    columns?: number;
    style?: React.CSSProperties;
    horizontal?: boolean;
    title?: string;
}

export const List = forwardRef<HTMLUListElement, Props>(({ children, columns = 1, horizontal, style, title }: Props, ref) => {
    return (
        <Wrapper vertical>
            {title && (
                <div className={styles.title}>
                    <span>{title}</span>
                </div>
            )}
            <Wrapper style={{ overflowY: 'auto', maxHeight: 500, marginBottom: 10 }}>
                <ul
                    ref={ref}
                    style={
                        {
                            ...style,
                            '--columns': columns,
                        } as React.CSSProperties
                    }
                    className={classNames(styles.List, horizontal && styles.horizontal)}
                >
                    {children}
                </ul>
            </Wrapper>
        </Wrapper>
    );
});
