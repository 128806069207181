import { GridColumnRow } from '../../../../../../api/generated/data-contracts';
import { Cell } from './Cell/Cell';

export type ColumnRowProps = {
    columnRow: GridColumnRow;
    columnIndex: number;
    rowId: string;
    isAdminProject?: boolean;
};

export const ColumnRow = ({ columnRow, columnIndex, rowId, isAdminProject }: ColumnRowProps) => {
	return (
		<td className="tp-capacity-cell-container">
			<div style={{ display: 'flex', padding: '0 10px', gap: 10, justifyContent: 'center' }}>
				{columnRow.cells.map((cell, i) => {
					if (isAdminProject && i === 0) {
						return <div key={cell.id} className="tp-capacity-cell"></div>;
					}
					return <Cell key={cell.id} cell={cell} rowId={rowId} columnIndex={columnIndex} cellIndex={i} />;
				})}
			</div>
		</td>
	);
};
