import React, { FunctionComponent, useState } from 'react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import RichTextEditor from 'react-rte';

interface IProps extends IDynamicEditorComponentProps {
    Item: string;
    Update: (_: any) => void;
    Key?: string;
    textTypeOverride?: string;
    readOnly?: boolean;
}

export const PFRichTextEditor: FunctionComponent<IProps> = props => {
    const [_value, _setValue] = useState<any>(RichTextEditor.createValueFromString(props.Item, props.textTypeOverride || 'html'));

    const _onChange = value => {
        _setValue(value);
        if (props.Update) {
            props.Update(value.toString(props.textTypeOverride || 'html'));
        }
    };

    return (
        <RichTextEditor value={_value} onChange={_onChange} key={props.Key ?? 'tp-announcement-editor-rich-text-editor'} readOnly={props.readOnly || false} />
    );
};

export default PFRichTextEditor;

