import React, { FunctionComponent, useState } from 'react';
import { ObjectEditor } from '../ObjectEditor';
import { PrimaryButton } from '@fluentui/react';
import { PFDialog } from '../PFDialog';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { ActivityDistributionConfig } from '../../../Entities/ActivityDistribution/ActivityDistributionConfig';
import { language } from '../../../Services/LocalizationService';
 
interface IProps extends IDynamicEditorComponentProps { 
    Item: ActivityDistributionConfig;
    Update: (value: ActivityDistributionConfig) => void;
    Key?: string;

	HiddenProperties: Array<string>;
}

const AutoDistributionSettingsEditor: FunctionComponent<IProps> = props => {

	const [showDialog, setShowDialog] = useState(false);
	const [item, setItem] = useState({...props.Item});
	const [prevItem, setPrevItem] = useState({...props.Item});

	const onChange = (changedItem: ActivityDistributionConfig) => {		
		setItem({...changedItem});
	}

	return 	(
		<>
			<PrimaryButton text={ language.AutoDistributionConfig.SettingsButtonText } onClick={() => setShowDialog(true)} />

			<PFDialog
				title={ language.AutoDistributionConfig.SettingsDialogTitle } // language.AutoDistributionSettingsDialog.Title
				showDialog={showDialog}
				className="tp-autodistsettings"
				callback={ () => {
					setPrevItem({...item});
					props.Update(item);
					setShowDialog(false);
				}}
				dismissCallback={ () => {
					setItem({...prevItem});
					setShowDialog(false);
				}}
			>
					<ObjectEditor
						Item={item}
						ItemType={ActivityDistributionConfig}
						DisabledProperties={[]}
						HiddenProperties={props.HiddenProperties ?? []}
						ChangesCallback={onChange}
					/>
			</PFDialog>
		</>
	)
}

export default AutoDistributionSettingsEditor;