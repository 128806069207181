export class ValidationRule {
	constructor(public Property: string, public Action: (obj: any) => boolean, public ErrorText: string, public DescriptionText?: (obj: any) => string, public IsRequired?: (parent: any) => boolean, public Error?: boolean) {}
}

export interface IValidationRule {
	Property: string;
	Action: (obj: any) => boolean;
	DescriptionText?: (obj: any) => string;
	IsRequired?: (parent: any) => boolean;
	Error?: boolean;
	ErrorText: string;
}