import { IStore } from './store';
import immerProduce from 'immer';
import { PersistGetter, RecursivePartial, RecursiveReadonly } from './types';
import { WritableDraft } from 'immer/dist/internal.js';

/**
 * This is effectively the exact same as using produce from immer directly but without the need for providing a correct type.
 * This only exists because Typescript
 * @param cb callback function for the store state
 */
// We take a generic type but doesn't use it. This is to keep 1:1 api with the original immer produce
export const produce =
// eslint-disable-next-line @typescript-eslint/no-unused-vars
    <T = IStore>(cb: (store: WritableDraft<IStore>) => IStore | void) =>
    (store: IStore) =>
        immerProduce(store, cb);

export const excludeKeys = <T>(obj: T, keys: (keyof T)[]) => {
    return Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key as any))) as Partial<T>;
};

export const includeKeys = <T>(obj: T, keys: (keyof T)[]) => {
    return Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key as any))) as Partial<T>;
};

export const mergeMergers =
    (...mergers: ((persistedState: RecursiveReadonly<RecursivePartial<IStore>>, currentState: IStore) => IStore)[]) =>
    (persistedState: RecursiveReadonly<RecursivePartial<IStore>>, currentState: IStore) =>
        mergers.reduce((currentState, merger) => {
            try {
                return merger(persistedState, currentState);
            } catch (error) {
                console.error('mergeMergers ~ error', merger.name, error);
                return currentState;
            }
        }, currentState);

export const runPersistGetters = async (
    state: RecursivePartial<IStore>,
    ...getters: PersistGetter[]
) => {
    return await getters.reduce(async (currentState: RecursivePartial<IStore> | Promise<RecursivePartial<IStore>>, getter) => {
		try {
			const state = await currentState;
			const newState = await getter(state);
			return {
				...state,
				...newState,
				hasHydrated: false, // insist that we are not done hydrating
			}
		} catch (error) {
            console.error("runPersistGetters ~ error", getter.name, error)
			return currentState
		}
    }, state);
};
