export class UrlEx {
	public static GetUrlParams(): URLSearchParams {
		return new URLSearchParams(window.location.search);
	}

	public static GetUrlParam(name: string): string {
		return new URLSearchParams(window.location.search).get(name);
	}
	public static GetUrlParamHashCompatibility(name: string): string {
		// The last get param will be get a "/" at the end
		// when we use hash routing, we cut that off.
		let getParam = UrlEx.GetUrlParam(name);
		if (getParam && getParam.endsWith("/")) {
			getParam = getParam.slice(0, getParam.length - 1)
		}
		return getParam;
	}
}