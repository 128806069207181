// export class StringEx {

// 	/** Lowercase entire string and uppercase on first char */
// 	public static FormatAsTitle = (text: string) : string => {
// 		if (!text) return null;
// 		return text.toLowerCase().replace(/^\w/, c => c.toUpperCase());
// 	}

// 	public static ReplaceAll = (str: string, find: string, replace: string) : string => {
// 		return str.replace(new RegExp(find, 'g'), replace);
// 	}

// 	public static IsAllUpperCase = (str: string): boolean => {
// 		return str === str.toUpperCase();
// 	}

// 	public static IsNullOrWhiteSpace = (str: string): boolean => {
// 		return str == null || !str.replace(/\s/g, '').length;
// 	}

// 	public static SplitCamelCase = (str: string) : string => {
// 		// do not split abbrevations
// 		if (StringEx.IsAllUpperCase(str)) return str;
// 		return str.split(/(?=[A-Z])/).join(' ');
// 	}

// 	public static ToLowercaseSafe = (str: string) : string => {
// 		if (str == null) return null;
// 		return str.toLocaleLowerCase();
// 	}
// }


/** Lowercase entire string and uppercase on first char 
 * @description consider using "text-transform: capitalize" to handle it with accelerated CSS
*/
export const formatAsTitle = (text: string) : string => {
	if (!text) return null;
	return text.toLowerCase().replace(/^\w/, c => c.toUpperCase());
}

export const replaceAll = (str: string, find: string, replace: string) : string => {
	return str.replace(new RegExp(find, 'g'), replace);
}

export const isAllUpperCase = (str: string): boolean => {
	return str === str.toUpperCase();
}

export const isNullOrWhiteSpace = (str: string): boolean => {
	return str == null || !str.replace(/\s/g, '').length;
}

export const isNullUndefinedOrEmpty = (str: any) => {
	return (str ?? "") === ""
}

export const splitCamelCase = (str: string) : string => {
	// do not split abbrevations
	if (isAllUpperCase(str)) return str;
	return str.split(/(?=[A-Z])/).join(' ');
}

export const toLowercaseSafe = (str: string) : string => {
	if (str == null) return null;
	return str.toLocaleLowerCase();
}

export const StringEx = {
	formatAsTitle,
	replaceAll,
	isAllUpperCase,
	isNullOrWhiteSpace,
	splitCamelCase,
	toLowercaseSafe
}