import { createContext, Fragment, FunctionComponent, useState } from "react";
import { ArrayEx } from "../../../../../../Utilities/ArrayEx";
import { ITreeNode } from "../interfaces/ITreeNode";
import { treeHelper } from "../helpers/treeHelper";
import { TreeViewNode } from "./TreeViewNode";
import useEffectOnce from "../../../../../../hooks/useEffectOnce";

export interface IProps {
	tree: ITreeNode;
	type: "select" | "manage";
	selected?: string | Array<string>;
	disable?: INodeDisabling;
	multiSelect?: boolean;
	readOnly?: boolean;
	delimiter: string;
	selectionChanged?: (item: ITreeNode, checked: boolean) => void;
	defaultExpandedDepth?: number;
	autoSelectAndDisableChilds?: boolean;
	autoExpandSelected?: boolean;
	hideRootNode?: boolean;
	selectedIncludeChilds?: boolean;
}

export interface INodeDisabling {
	ids: Array<string>;
	value: boolean;
	includeChilds?: boolean;
	clearSelected?: boolean;
}

export const TreeContext = createContext({
	rootNodeId: "",
	hideRoot: false
});

export const TreeView: FunctionComponent<IProps> = ({ tree, type, selected, disable, delimiter, readOnly, multiSelect, autoSelectAndDisableChilds, defaultExpandedDepth, autoExpandSelected, selectedIncludeChilds, hideRootNode, selectionChanged }) => {

	const [treeNode, setTreeNode] = useState<ITreeNode>();

	useEffectOnce(() => {
		const clone = {...tree};
		if (type === "select") {
			treeHelper.setSelected(clone, ArrayEx.ensureArray(selected), selectedIncludeChilds);
			treeHelper.setDisabledWithOptions(clone, disable);
		}

		// sort children
		treeHelper.sortChildren(clone);

		setTreeNode(clone);
	})
	
	const onSelectionChange = (node: ITreeNode, checked: boolean) : void => {
		const clone = {...treeNode};
		if (!multiSelect) {
			treeHelper.selectAll(clone, false);
		}

		if (disable?.clearSelected) {
			treeHelper.clearSelectionOnDisabled(clone);
		}
		
		const changedNode = treeHelper.findNode(clone, node.id);
		changedNode.selected = checked;

		if (autoSelectAndDisableChilds) {
			changedNode.children.forEach(_ => treeHelper.selectAll(changedNode, checked));
			changedNode.children.forEach(_ => treeHelper.disableAll(changedNode, checked, true));
		}

		setTreeNode(clone);
		selectionChanged?.(changedNode, checked);
	}
	
	const onAdd = (parent: ITreeNode) : void => {
		const clone = {...treeNode};
		treeHelper.addNode(clone, parent, delimiter ?? ".");
		setTreeNode(clone);
	}

	const onDelete = (node: ITreeNode, parent: ITreeNode) : void => {
		const clone = {...treeNode};
		treeHelper.deleteNode(clone, node, parent);
		setTreeNode(clone);
	}

	const onRename = (node: ITreeNode, newName: string) : void => {
		const clone = {...treeNode};
		treeHelper.renameNode(treeHelper.findNode(clone, node.id), newName, delimiter ?? ".");
		setTreeNode(clone);
	}

	const onMove = (sourceId: string, sourceParentId: string, targetId: string) : boolean => {
		const clone = {...treeNode};
		
		//guard for adding parent to child
		const sourceNode = treeHelper.findNode(clone, sourceId);
		const invalid = treeHelper.findNode(sourceNode, targetId);
		if (invalid) {
			const element = document.querySelector(`.tp-treeview input[data-nodeid="${targetId}"]`) as HTMLElement;
			if (!element) return false;
			element.classList.add("tp-treeview-droperror-highlight");
			setTimeout(() => {
				element.classList.remove("tp-treeview-droperror-highlight");
			}, 1000);
			return false;
		}

		treeHelper.moveNode(clone, sourceId, sourceParentId, targetId, delimiter ?? ".");
		treeHelper.sortChildren(clone);
		setTreeNode(clone);
		return true;
	}

	const collapsed = (node: ITreeNode, depth: number) : boolean => {
		if (autoExpandSelected) {
			if (treeHelper.hasChildsSelected(node))
				return false;
		}
		if (defaultExpandedDepth != null)
			return depth >= defaultExpandedDepth;
		else
			return depth >= 1;
	}

	const createTree = (treeNode: ITreeNode, parent?: ITreeNode, depth: number = 0) =>
		<TreeViewNode
			node={treeNode}
			type={type}
			delimiter={delimiter ?? "."}
			readOnly={readOnly}
			parent={parent}
			defaultCollapsed={collapsed(treeNode, depth)}
			onSelectCallback={onSelectionChange}
			onAddCallback={onAdd}
			onDeleteCallback={onDelete}
			onMoveCallback={onMove}
			onRenameCallback={onRename}
			depth={depth}
		>
			{treeNode?.children?.map((branch: ITreeNode) => {
				return <Fragment key={branch.id}>{createTree(branch, treeNode, depth + 1)}</Fragment>
			})}
		</TreeViewNode>

	if (treeNode == null) return null;

	return (
		<div className="tp-treeview">
			<TreeContext.Provider value={{ hideRoot: hideRootNode, rootNodeId: treeNode.id }}>
				{createTree(treeNode)}
			</TreeContext.Provider>
		</div>
	)
}