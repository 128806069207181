import { useEffect, useMemo } from 'react';
import { useDragBoxValue, useSetIntersectingCellsState, useGridCellDragRegistryValue, useResetDragBoxState, useSetCurrentScrollTarget, TriggerMargins, useDragTriggers } from '../hooks/useDragGridCell';
import { filterMap } from '../helpers/map';
import { useTheme } from '../../../../Themes/themeContext';
import { getColorFromString } from '@fluentui/react';
// import { filterMapAsync } from '../helpers/mapAsync';

const isInterSecting = (top: number, left: number, height: number, width: number, element: HTMLElement) => {
    const right = left + width;
    const bottom = top + height;
    const { bottom: cellBottom, top: cellTop, left: cellLeft, right: cellRight } = element.getBoundingClientRect();
    // check vertical
    if (bottom > cellTop && top < cellBottom) {
        // check horizontal
        if (right > cellLeft && left < cellRight) {
            return true;
        }
    }
    return false;
};

export const DragBox = ({ scrollRef, triggerMargins }: { scrollRef?: React.MutableRefObject<HTMLElement>, triggerMargins?: Partial<TriggerMargins> }) => {
	const setScrollTarget = useSetCurrentScrollTarget();
    const { left, top, height, visible, width, currentValue: currentDraggingCellValue } = useDragBoxValue();
	const resetDragBoxState = useResetDragBoxState()
    const dragCellReg = useGridCellDragRegistryValue();
	const setIntersectingCells = useSetIntersectingCellsState()
	const containingElement = scrollRef?.current || document.body;
	useEffect(() => {
		setIntersectingCells(filterMap(dragCellReg, ({ inputRef, restoreStoreValue, setLocalCellValue }) => {
			const intersecting = isInterSecting(top, left, height, width, inputRef);
			if (!intersecting) { 
				restoreStoreValue()
            } else {
				setLocalCellValue(String(currentDraggingCellValue));
            }
			return intersecting
		}))
	}, [currentDraggingCellValue, dragCellReg, height, left, setIntersectingCells, top, width]);

	useEffect(() => {
		setScrollTarget(containingElement);
		return () => {
			setScrollTarget(null);
		}
	}, [containingElement, setScrollTarget])

	useEffect(() => {
		// RESET THAT BOX BY ANY MEANS IF APP IS NOT IN FOCUS
		window.addEventListener("blur", resetDragBoxState);
		window.addEventListener("focus", resetDragBoxState);
		document.addEventListener("visibilitychange", resetDragBoxState);
		return () => {
			window.removeEventListener("blur", resetDragBoxState);
			window.removeEventListener("focus", resetDragBoxState)
			document.removeEventListener("visibilitychange", resetDragBoxState);
		}
	}, [resetDragBoxState])

	useDragTriggers(triggerMargins)

	const theme = useTheme();
	const backgroundColor = useMemo(() => {
		const { r, g, b } = getColorFromString(theme.palette.accent);
		return `rgba(${r},${g},${b},0.2)`
	}, [theme.palette.accent])

    return visible ? (
        <div
            style={{
                position: 'fixed',
                height,
                width,
                top,
                left,
                backgroundColor,
                zIndex: 999999999,
            }}
        />
    ) : null;
};
