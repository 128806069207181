import { ConfirmInfo } from '../Components/Common/ConfirmDialog';


// TODO We should refactor this to some react context since it is directly related to react rendering
export class Confirm {
	private static _instance: Confirm;
    private _showMethod: (info: ConfirmInfo) => void = null;
    
    private constructor() { }

    public static get Instance() {
        return this._instance || (this._instance = new this());
	}
	
	public Register = (_showMethod: (info: ConfirmInfo) => void): void => {
        this._showMethod = _showMethod;
    }

    public SetMessage = (info: ConfirmInfo) => {
        if (this._showMethod == null) {
 console.warn("There has not initialized a confirm component, so no confirm dialog is shown."); return; 
}
        this._showMethod(info);
    }

    public Show = (info: ConfirmInfo): void => {
        this.SetMessage(info);
	}
}