import {
    IFormItem,
    AtLeast,
    IFormItemPropertyOptions,
    IDynamicTextFieldConfig,
    propertyOptionsFactory,
    IDynamicPropertyComponentConfig,
} from '@wiberg/formbuilder';
import { IAadGroupFieldConfig } from './tpComplexBuilder/components/config/IAadGroupFieldConfig';
import { IProjectFieldConfig } from './tpComplexBuilder/components/config/IProjectFieldConfig';
import { IRbsFieldConfig } from './tpComplexBuilder/components/config/IRbsFieldConfig';
import { IResourceFieldConfig } from './tpComplexBuilder/components/config/IResourceFieldConfig';
import { IDynamicCronFieldConfig } from './tpSimpleBuilder/components/config/IDynamicCronFieldConfig';
import { IDynamicMessageBarFieldConfig } from './tpSimpleBuilder/components/config/IDynamicMessageBarFieldConfig';
import { IDynamicNumberSliderFieldConfig } from './tpSimpleBuilder/components/config/IDynamicNumberSliderFieldConfig';

const tpPropOptionsFactory = {
    cron: <T extends IFormItem>(
        options: AtLeast<IFormItemPropertyOptions<T, IDynamicCronFieldConfig<T>>, 'displayName'>,
    ): Partial<IFormItemPropertyOptions<T, IDynamicCronFieldConfig<T>>> => {
        options.propertyType = 'cron' as any;
        return options;
    },

    color: <T extends IFormItem>(
        options: AtLeast<IFormItemPropertyOptions<T, IDynamicPropertyComponentConfig<T>>, 'displayName'>,
    ): Partial<IFormItemPropertyOptions<T, IDynamicPropertyComponentConfig<T>>> => {
        options.propertyType = 'color' as any;
        return options;
    },

    numberSlider: <T extends IFormItem>(
        options: AtLeast<IFormItemPropertyOptions<T, IDynamicNumberSliderFieldConfig<T>>, 'displayName'>,
    ): Partial<IFormItemPropertyOptions<T, IDynamicNumberSliderFieldConfig<T>>> => {
        options.propertyType = 'numberSlider' as any;
        return options;
    },

    messageBar: <T extends IFormItem>(
        options: AtLeast<IFormItemPropertyOptions<T, IDynamicMessageBarFieldConfig<T>>, 'displayName'>,
    ): Partial<IFormItemPropertyOptions<T, IDynamicMessageBarFieldConfig<T>>> => {
        options.propertyType = 'messageBar' as any;
        return options;
    },

    textfieldCopyvalue: <T extends IFormItem>(
        options: AtLeast<IFormItemPropertyOptions<T, IDynamicTextFieldConfig<T>>, 'displayName'>,
    ): Partial<IFormItemPropertyOptions<T, IDynamicTextFieldConfig<T>>> => {
        options.propertyType = 'textfieldCopyvalue' as any;
        return options;
    },

    rbs: <T extends IFormItem>(
        options: AtLeast<IFormItemPropertyOptions<T, IRbsFieldConfig<T>>, 'displayName'>,
    ): Partial<IFormItemPropertyOptions<T, IRbsFieldConfig<T>>> => {
        options.propertyType = 'rbs' as any;
        return options;
    },

    tree: <T extends IFormItem>(
        options: AtLeast<IFormItemPropertyOptions<T, IRbsFieldConfig<T>>, 'displayName'>,
    ): Partial<IFormItemPropertyOptions<T, IRbsFieldConfig<T>>> => {
        options.propertyType = 'tree' as any;
        return options;
    },

    resource: <T extends IFormItem>(
        options: AtLeast<IFormItemPropertyOptions<T, AtLeast<IResourceFieldConfig<T>, 'nameField' | 'descriptionField' | 'resourceTypes'>>, 'displayName'>,
    ): Partial<IFormItemPropertyOptions<T, IResourceFieldConfig<T>>> => {
        options.propertyType = 'resource' as any;
        return options;
    },

    project: <T extends IFormItem>(
        options: AtLeast<IFormItemPropertyOptions<T, AtLeast<IProjectFieldConfig<T>, 'nameField' | 'descriptionField' | 'projectTypes'>>, 'displayName'>,
    ): Partial<IFormItemPropertyOptions<T, IProjectFieldConfig<T>>> => {
        options.propertyType = 'project' as any;
        return options;
    },

    aadGroup: <T extends IFormItem>(
        options: AtLeast<IFormItemPropertyOptions<T, IAadGroupFieldConfig<T>>, 'displayName'>,
    ): Partial<IFormItemPropertyOptions<T, IAadGroupFieldConfig<T>>> => {
        options.propertyType = 'aadGroup' as any;
        return options;
    },

    periodSpan: <T extends IFormItem>(
        options: AtLeast<IFormItemPropertyOptions<T, IDynamicPropertyComponentConfig<T>>, 'displayName'>,
    ): Partial<IFormItemPropertyOptions<T, IDynamicPropertyComponentConfig<T>>> => {
        options.propertyType = 'periodSpan' as any;
        return options;
    },
};

export const tpPropertyOptionsFactory = { ...propertyOptionsFactory, ...tpPropOptionsFactory };
