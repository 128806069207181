import { Project } from "../Entities/Main/Project";

export class ProjectWithStringVariants extends Project {
	public OriginsAsString: string = "";
	public ParentIdAsString: string = "";
	public IsActiveAsString: string = "";
	public OwnerAsString: string = "";
	public AdditionalOwnersAsString: string = "";
	public AdditionalOwnersGroupAsString: string = "";
	public CustomPropertiesAsString: Record<string, string> = {};
	public RBSAsString: string = "";
	public TeamResourceAsString: string = "";
	public ProjectTypeAsString: string = "";
}