import { Icon, IIconProps, IPersonaCoinProps, IStackProps, ITextProps, PersonaCoin, Stack, Text } from '@fluentui/react';
import { useMemo, useState } from 'react';
import { GridRow } from '../../../../../../../../api/generated/data-contracts';
import { ResourceType } from '../../../../../../../../Entities/ResourceType';
import { useId } from '@fluentui/react-hooks';
import { EntityInfoCallout } from './EntityInfoCallout';

export const ResourceTypeIcon = ({ resourceType, coinSize }: { resourceType: ResourceType; coinSize: number }) => {
    const iconName = useMemo(() => {
        switch (resourceType) {
            case ResourceType.Named: {
                return 'Contact';
            }
            case ResourceType.Generic: {
                return 'InternalInvestigation';
            }
            case ResourceType.Category: {
                return 'Transition';
            }
            case ResourceType.Team: {
                return 'Teamwork';
            }
            case ResourceType.Pool: {
                return 'SearchNearby';
            }
            case ResourceType.WorkProfile: {
                return 'AccountManagement';
            }
            case ResourceType.Unknown:
            default: {
                return 'BlockContact';
            }
        }
    }, [resourceType]);
    const { iconStyles, stackStyles } = useMemo(() => {
        return {
            stackStyles: {
                root: {
                    position: 'absolute',
                    right: 0,
                    bottom: '-20%',
                    width: '50%',
                    height: '50%',
                    backgroundColor: '#ececec',
                    borderRadius: '50%',
                    border: '1px solid #fff',
                    fontSize: coinSize,
                    userSelect: 'none',
                    cursor: 'help',
                },
            } as IStackProps['styles'],
            iconStyles: { root: { fontSize: '30%' } } as IIconProps['styles'],
        };
    }, [coinSize]);
    return (
        <Stack styles={stackStyles} horizontalAlign="center" verticalAlign="center">
            <Icon iconName={iconName} styles={iconStyles} />
        </Stack>
    );
};
const CoinIcon = ({ row, coinSize = 28 }: { row: GridRow; coinSize?: number }) => {
    const initials = useMemo(() => {
        if (row.name == null) {
            return '';
        }
        let initials = row.name.match(/\b\w/g) || ([] as any);
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        return initials;
    }, [row.name]);
    const { coinStyles, stackStyles } = useMemo(() => {
        return {
            stackStyles: { root: { position: 'relative', height: coinSize, width: coinSize, fontSize: coinSize / 4 } } as IStackProps['styles'],
            coinStyles: { initials: { fontSize: '1.5em' }, coin: { fontSize: '100%', userSelect: 'none', cursor: 'help' } } as IPersonaCoinProps['styles'],
        };
    }, [coinSize]);
    return (
        <>
            <Stack styles={stackStyles}>
                <PersonaCoin
                    //
                    text={row.name}
                    imageInitials={initials}
                    styles={coinStyles}
                    coinSize={coinSize}
                />
                <ResourceTypeIcon resourceType={row.metadata?.ResourceType} coinSize={coinSize} />
            </Stack>
        </>
    );
};

export const ResourceCoinIcon = ({ row }: { row: GridRow }) => {
    const [showInfo, setShowInfo] = useState(false);
    const id = useId('resource-coin' + row.id);
    const { stackStyles, textStyles } = useMemo(() => {
        return {
            stackStyles: { root: { gap: 10, marginBottom: 20 } } as IStackProps['styles'],
            textStyles: { root: { fontWeight: 700, fontSize: 18 } } as ITextProps['styles'],
        };
    }, []);
    return (
        <>
            <Stack horizontal onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)} id={id}>
                <CoinIcon row={row} />
            </Stack>
            <Text className="title">{row.name}</Text>
            {showInfo && (
                <EntityInfoCallout entityId={row.identity!.id} entityType={row.identity!.entityType} targetId={id}>
                    <Stack horizontal verticalAlign="center" styles={stackStyles}>
                        <CoinIcon row={row} coinSize={50} />
                        <Text styles={textStyles}>{row.name}</Text>
                    </Stack>
                </EntityInfoCallout>
            )}
        </>
    );
};
