export default class Locale_AdminDashboard { 
	SubSettings: string = "Sub Settings";
	Endpoints: string = "Endpoints";
	Plugins: string = "Plugins";
	Schedules: string = "Schedules";
	Reboot: string = "Reboot";
	Log: string = "Log";
	Maintenance: string = "Maintenance";
	Themes: string = "Themes";
	Documentation: string = "Documentation";
	VideoGuides: string = "Video guides";
	Logs: string = "Logs";
}