/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { UIStartupDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Startup<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags getuistartup
     * @name GetUiStartup
     * @request GET:/startup/getuistartup
     * @secure
     * @response `200` `UIStartupDto` Success
     */
    getUiStartup = (params: RequestParams = {}) =>
        this.request<UIStartupDto, any>({
            path: `/startup/getuistartup`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
}
