import React, { memo, useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { GridType } from '../../../../../../../Entities/GridType';
import { useState } from 'react';
import { RootSettings } from '../../../../../../../api/generated/data-contracts';
import { Stack, Text, FontIcon } from '@fluentui/react';
import { ListItem } from './components/ListItem';
import { useLocation, useNavigate } from 'react-router';
import { ResourceListItemWithMetadata } from '../../../types';

export const ResultList = memo(
    ({
        items,
        delegationMode,
        gridType,
        settings,
        showAll,
        updateItem,
        itemSize = 50,
        loadingMargin = 100,
        setIsDragging,
        searchText,
    }: {
        items: ResourceListItemWithMetadata[];
        showAll: boolean;
        delegationMode: boolean;
        settings: RootSettings;
        gridType: GridType;
        itemSize?: number;
        loadingMargin?: number;
        updateItem: (resource: ResourceListItemWithMetadata) => void;
        setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
        searchText: string;
    }) => {
        const { favorites, restItems } = useMemo(() => {
            return items.reduce(
                (acc, item) => {
                    if (item.favourite) {
                        acc.favorites.push(item);
                    } else {
                        acc.restItems.push(item);
                    }
                    return acc;
                },
                { favorites: [], restItems: [] } as { favorites: ResourceListItemWithMetadata[]; restItems: ResourceListItemWithMetadata[] },
            );
        }, [items]);
        const scrollContainerRef = useRef<HTMLDivElement>(null);
        const [cutSize, setCutSize] = useState(itemSize);
        const loadedItems = useMemo(() => {
            if (cutSize >= restItems.length) {
                return restItems;
            }
            return restItems.slice(0, cutSize);
        }, [cutSize, restItems]);

        const [hasScrolled, setHasScrolled] = useState(false);

        const onScroll = useCallback(
            (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
                const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
                if (scrollTop >= scrollHeight - clientHeight - loadingMargin) {
                    setCutSize(c => c + itemSize);
                }
                if (scrollTop >= 5) {
                    setHasScrolled(true);
                } else {
                    setHasScrolled(false);
                }
            },
            [itemSize, loadingMargin],
        );

        useLayoutEffect(() => {
            setCutSize(itemSize);
            const scrollContainer = scrollContainerRef.current;
            if (scrollContainer) {
                scrollContainer.scrollTo({
                    top: 0,
                });
            }
        }, [itemSize, items]);

        const { pathname } = useLocation();
        const navigate = useNavigate();

        return (
            <Stack className="tp-list-resource-list-container" grow={1}>
                <div
                    style={{
                        boxShadow: hasScrolled ? '0px 0px 8px rgba(0,0,0,0.5)' : 'none',
                        backgroundColor: hasScrolled ? '#ebebeb' : 'transparent',
                    }}
                    className="tp-list-resource-scroll-shadow"
                />
                <div onScroll={onScroll} ref={scrollContainerRef} className="tp-list-scrollable-container">
                    {Boolean(favorites.length) && (
                        <Stack
                            className="tp-list-resource-favorite-list"
                            styles={{
                                root: {
                                    marginBottom: 10,
                                    borderRadius: 5,
                                },
                            }}
                        >
                            <Text variant="large" styles={{ root: { marginBottom: 10, marginLeft: 10 } }}>
                                Favorite resources ({favorites.length})
                            </Text>
                            <Stack>
                                {favorites.map(item => {
                                    return (
                                        <ListItem
                                            key={item.id}
                                            item={item}
                                            delegationMode={delegationMode}
                                            gridType={gridType}
                                            settings={settings}
                                            showAll={showAll}
                                            updateItem={updateItem}
                                            pathname={pathname}
                                            navigate={navigate}
                                            setIsDragging={setIsDragging}
                                            searchText={searchText}
                                        />
                                    );
                                })}
                            </Stack>
                        </Stack>
                    )}
                    <Stack className="tp-list-resource-list">
                        {loadedItems.map(item => {
                            return (
                                <ListItem
                                    key={item.id}
                                    item={item}
                                    delegationMode={delegationMode}
                                    gridType={gridType}
                                    settings={settings}
                                    showAll={showAll}
                                    updateItem={updateItem}
                                    pathname={pathname}
                                    navigate={navigate}
                                    setIsDragging={setIsDragging}
                                    searchText={searchText}
                                />
                            );
                        })}
                    </Stack>
                </div>
                <div
                    className="tp-list-resource-goto-top-btn"
                    style={{
                        transform: hasScrolled ? 'scale(1)' : 'scale(0)',
                    }}
                    onClick={() => {
                        if (scrollContainerRef.current) {
                            scrollContainerRef.current.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        }
                    }}
                >
                    <FontIcon iconName="ChevronUp" />
                </div>
            </Stack>
        );
    },
);
