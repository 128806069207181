/* eslint-disable react-refresh/only-export-components */
import * as React from 'react';
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { ICustomizations } from '@fluentui/react';
import defaultCustomizations from './defaultTheme/theme.default';
import defaultTopbarCustomizations from './defaultTheme/topbarTheme.default';
import applyThemeVars from './applyThemeVars';
import { IExtendedTheme, IExtendedCustomizations } from './Theme.types';
import { createCustomizations } from './CreateCustomization';
import { useUISettings } from '../context/network/http/QueryProvider/queries/UISettings';
import { devLogger } from '../helpers/devLogger';

export interface IThemeContext {
	customizations: Partial<ICustomizations>;
	topbarCustomizations: Partial<ICustomizations>;
	setCustomizations: React.Dispatch<any>;
	setTopbarCustomizations: React.Dispatch<any>;
	setTheme: (theme: any) => void;
	setDefaultTheme: () => void;
	// scheme?: string;
}

export interface IThemesObj {
	theme?: Partial<IExtendedTheme>;
	customizations?: Partial<IExtendedCustomizations>;
	topbarTheme?: Partial<IExtendedTheme>;
	topbarCustomizations?: Partial<IExtendedCustomizations>;
	// TODO: hack putting this here - needs to be changed - ewi
	useAdvanced?: boolean;
	CssOverrides?: string;
}


export const ThemeContext = createContext<IThemeContext>(undefined);

export const useTheme = () => useContext(ThemeContext).customizations.settings.theme as IExtendedTheme;
export const useCustomizations = () => useContext(ThemeContext).customizations as ICustomizations;
export const useTopbarCustomizations = () => useContext(ThemeContext).topbarCustomizations as ICustomizations;

export const ThemeProvider: React.FC = props => {
	const { data: uiSettings } = useUISettings()
	const [customizations, setCustomizations] = useState(defaultCustomizations);
	const [topbarCustomizations, setTopbarCustomizations] = useState(defaultTopbarCustomizations);

	const setDefaultTheme = useCallback(() => {
		setCustomizations(defaultCustomizations);
		setTopbarCustomizations(defaultTopbarCustomizations);
	}, []);
	
	const setTheme = useCallback((themesObj: any) => {
		const newCustomizations = createCustomizations(themesObj);
		setCustomizations(newCustomizations.customization);
		setTopbarCustomizations(newCustomizations.topbarCustomization);
		devLogger.debug('Setting new theme: ', newCustomizations);
	}, [])
	
	useEffect(() => {
		applyThemeVars(customizations);
	}, [customizations]);

	return (
		<ThemeContext.Provider value={{ 
			customizations, 
			topbarCustomizations, 
			setCustomizations, 
			setTopbarCustomizations, 
			setTheme, 
			setDefaultTheme
		}}>
			{ props.children }
			{uiSettings?.theme?.CssOverrides && (
				<style>{uiSettings.theme.CssOverrides}</style>
			)}
		</ThemeContext.Provider>
	);
};
