import * as React from "react";
import { Text } from "@fluentui/react/lib/Text";
import { HeaderRow } from "../../../../Entities/Table/HeaderRow";
import { useTheme } from '../../../../Themes/themeContext';

export const SubHeaders: React.FunctionComponent<{
	children?: React.ReactNode;
	header: HeaderRow;
}> = ({ header }) => {
	const theme = useTheme();

	return (
		<div className="tp-table-header-subs">
			{header.SubHeaderRows.map((_, idx) => (
				<Text key={`headersubrow-${idx}`} className="tp-table-header-subs-item" styles={{root: { color: theme.semanticColors.disabledBodyText }}}>
					{_.Name}
				</Text>
			))}
		</div>
	)
}
