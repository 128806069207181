import { guid } from "../../helpers/guid";

export class WorkPackageDto {
	public Id: string; //guid
	public Name: string;
	public SubWorkPackages: Array<string>;

	constructor() {
		this.Id = guid.newGuid();
		this.SubWorkPackages = new Array<string>();
	}
}