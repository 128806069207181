type CallbackWithAccumulator<Key, Type, Acc, CBReturnType = Acc> = (acc: Acc, item: Type, index: number, key: Key, map: Map<Key, Type> | ReadOnlyMap<Map<Key, Type>>) => Promise<CBReturnType> | CBReturnType;
type Callback<Key, Type, CBReturnType> = (item: Type, index: number, key: Key, map: Map<Key, Type> | ReadOnlyMap<Map<Key, Type>>) => Promise<CBReturnType> | CBReturnType;
type ReadOnlyMap<ImmutableMap extends Map<Key, Item>, Key = any, Item = any> = Omit<ImmutableMap, "set" | "delete" | "clear">;

export async function reduceMapAsync<K, T, Acc, Cb extends CallbackWithAccumulator<K, T, Acc>>(map: Map<K, T> | ReadOnlyMap<Map<K, T>>, cb: Cb, acc?: Acc) {
    let index = 0;
    let currentValue = acc;
	for (const [key, value] of map.entries()) {
		currentValue = await cb(currentValue, value, index++, key, map);
	}
    return currentValue;
}

export function filterMapAsync<K, T, Cb extends Callback<K, T, boolean>>(map: Map<K, T>, cb: Cb) {
    return reduceMapAsync(
        map,
        async (acc, item, index, key, map) => {
            if (await cb(item, index, key, map)) {
                acc.set(key, item);
            }
            return acc;
        },
        new Map() as Map<K, T>,
    );
}


