import React, { useCallback, useState } from 'react';
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton, Label, Spinner, SpinnerSize } from '@fluentui/react';
import { ApiCalls } from '../../../../api/api';
import { FluentValidationMessage } from 'spark-forms-fluent8';
import { DateResolution } from '../../../../api/generated/data-contracts';
import { useMutation } from 'react-query';
import { WorkpackageImportForm } from './WorkpackageImportForm';

interface PackageDialogProps {
    isOpen: boolean;
	options: { projectId: string, resolution: DateResolution, defaultStartDate?: Date };
    onDismiss: () => void;
	onSuccess?: (formData: ImportFormData) => void;
	onError?: (formData: ImportFormData) => void;
}

type ImportFormData = {
	selectedPackages: string[];
	startDate: Date | null;
	endDate: Date | null;
}

export const WorkpackageImportDialog: React.FC<PackageDialogProps> = ({ isOpen, onDismiss, onSuccess, onError, options }) => {
	
	const [isFormValid, setIsFormValid] = useState(false);

	const [formData, setFormData] = useState<ImportFormData>({
        selectedPackages: [],
        startDate: options.defaultStartDate,
        endDate: null
    });

	const handleFormFieldChange = useCallback((fieldName: string, value: any) => {
		const clone = {...formData, [fieldName]: value }
		setFormData(clone);
		// validation
		const isValid = clone.selectedPackages.length && clone.startDate && (!clone.endDate || clone.startDate < clone.endDate);
        setIsFormValid(isValid);
    }, [formData]);

	const {
        mutate: importWorkpackages,
        isLoading,
    } = useMutation(
        () =>
            ApiCalls.importWorkpackage({
                projectId: options.projectId,
                start: formData.startDate,
                end: formData.endDate,
                resolution: options.resolution,
                workPackageIds: formData.selectedPackages,
            }),
        {
            onSuccess: result => {
                if (result) {
					onSuccess({...formData});
					onDismiss();
                } else {
                    onError({...formData});
                }
            },
            onError: () => {
                onError({...formData});
            }
        },
    );

    return (
		<Dialog 
			hidden={!isOpen} 
			onDismiss={onDismiss}
			minWidth={'600px'}
			modalProps={{
				isBlocking: true,
			}}
			styles={{main:{height: '340px'}}}
		>
			{
				isLoading
					? <Spinner size={SpinnerSize.large} />
					: <>
						<Label>Templates</Label>
						<WorkpackageImportForm onFormFieldChange={handleFormFieldChange} defaultStartDate={ options.defaultStartDate } />
						<FluentValidationMessage message={isFormValid ? undefined : 'You must select at least one template and start must be smaller than end'} />
					</>
			}
			<DialogFooter>
				<PrimaryButton text="Import" onClick={ () => importWorkpackages() } disabled={!isFormValid || isLoading} />
				<DefaultButton text="Cancel" onClick={ onDismiss } disabled={isLoading} />
			</DialogFooter>
		</Dialog>
	);
}
