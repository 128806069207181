import { useCallback, useContext, useEffect, useState } from 'react';
import { getGridTypeFromRoute, GridType } from '../../Entities/GridType';
import { DataService } from '../../Services/DataService';
import { EntityType } from '../../Entities/EntityTypes';
import { DragAndDrop } from '../../Utilities/DragAndDrop';
import { NotificationBar } from '../Common/NotificationBar';
import { SharedConfirmDialog } from '../Common/SharedConfirmDialog';
import { UserDetails } from '../Common/UserDetails';
import { Navbar } from '../Navbar/Navbar';
import { language } from '../../Services/LocalizationService';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { Customizer } from '@fluentui/react/lib/Utilities';
import { ThemeContext } from '../../Themes/themeContext';
import { ThemeContainer } from '../../Entities/ContentConfiguration/ThemeContainer';
import { guid } from '../../helpers/guid';
import { ProjectChangesUtil } from '../../Utilities/ProjectChangesUtil';
import { NotificationStatusType } from '../../Entities/Notification';
import { MessageBar, MessageBarType } from '@fluentui/react';
import AnnouncementViewer from '../Common/AnnouncementViewer';
import Confetti from '../Common/Confetti';
import DemoModeViewer from '../Common/DemoModeViewer';
import { Main } from './Main/Main';
import { useStore } from '../../context/store';
import shallow from 'zustand/shallow';
import { ResourcePanel } from '../../views/capacityViews/_components/panels/resource/ResourcePanel';
import { ProjectPanel } from '../../views/capacityViews/_components/panels/project/ProjectPanel';
import { useLocation } from 'react-router';
import { useExternalNotificationContext } from '../../context/network/websocket/ExternalNotificationServiceContext';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { useCheckTPPermissions } from '../../hooks/usePermissions';
import { UserType } from '../../api/generated/data-contracts';
import PushNotificationAnnouncementViewer from '../Common/PushNotificationAnnouncementViewer';
import useClientSecretExpirationDialog from '../../hooks/useClientSecretExpirationDialog';

export interface IOverviewState {
    Loading: boolean;
    MaintenanceMode: boolean;
}

// hack: FUCK fluentui (ewi)
const CustomizerWrapper = Customizer as any;

export const Overview = () => {
    const { data: uiSettings } = useUISettings();
    const { addHandler, removeHandler } = useExternalNotificationContext();
    const { pathname } = useLocation();
    const gridType = getGridTypeFromRoute(pathname);
    const gridTypeString = GridType[gridType];
    const { topbarAlwaysOpen, showNotifications, setUIContext, showCurrentUserInfo, doCelebration, setBlockDialog, blockContent, forcePinnedTopbar } = useStore(
        store => ({
            topbarAlwaysOpen: store.ui.topbarAlwaysOpen,
            showNotifications: store.ui.showNotifications,
            setUIContext: store.setUIContext,
            forcePinnedTopbar: store.ui.forcePinnedTopbar,
            showCurrentUserInfo: store.ui.showCurrentUserInfo,
            doCelebration: store.ui.doCelebration,
            blockContent: store.blockContent,
            setBlockDialog: store.setBlockDialog,
        }),
        shallow,
    );
    const [maintenanceMode, setMaintenanceMode] = useState(false);
    const [showingPushNotificationAnnouncements, setShowingPushNotificationAnnouncements] = useState(uiSettings.settings.enablePushNotificationAnnouncements); // Default to be true, if push notification announcements are enabled
    const { setTheme, customizations, topbarCustomizations } = useContext(ThemeContext);

    const checkPermission = useCheckTPPermissions();
	useClientSecretExpirationDialog();

    useEffect(() => {
        // check for maintenance in progress

        if (uiSettings.settings.maintenanceInProgress) {
            // only superusers and admins are allowed in
            if (!checkPermission({ userTypes: [UserType.Administrator, UserType.SuperUser], some: true })) {
                setBlockDialog(null, {
                    title: uiSettings.settings.maintenanceBeginTitle,
                    subText: uiSettings.settings.maintenanceBeginMessage,
                    dismissEnable: false,
                    isDarkOverlay: true,
                    isBlocking: true,
                    refreshEnabled: true,
                });
            } else if (!maintenanceMode) {
                // display maintenance banner
                setMaintenanceMode(true);
            }
        } else if (maintenanceMode) setMaintenanceMode(false);
    }, [
        checkPermission,
        maintenanceMode,
        setBlockDialog,
        uiSettings.settings.maintenanceBeginMessage,
        uiSettings.settings.maintenanceBeginTitle,
        uiSettings.settings.maintenanceInProgress,
    ]);

    useEffect(() => {
        const OnNotificationReceived = dto => {
            if (dto.t !== 'Celebration') return;
            setUIContext({ doCelebration: true });
        };
        addHandler(OnNotificationReceived);
        return () => {
            removeHandler(OnNotificationReceived);
        };
    }, [addHandler, removeHandler, setUIContext]);

    useEffect(() => {
        const init = async () => {
            // initialize project changes feature
            ProjectChangesUtil.initialize();
            // load theme
            const selectedTheme = uiSettings.settings.defaultTheme;
            if (guid.isGuidNotEmpty(selectedTheme)) {
                const theme = await DataService.Get<ThemeContainer>(EntityType.ContentConfiguration, selectedTheme);
                if (theme && theme.Theme) {
                    setTheme(theme.Theme);
                }
            }
        };
        init();
    }, [setTheme, setUIContext, uiSettings.settings.cultureInfo, uiSettings.settings.defaultTheme]);

    const UpdateNotificationIconColor = useCallback((any: boolean, maxStatus: NotificationStatusType): void => {
        const notifyIcon = document.querySelector(".tp-topbar-menu button[aria-label='notifications']");
        if (notifyIcon == null) return;

        notifyIcon.classList.remove('notify-info', 'notify-success', 'notify-warning', 'notify-severewarning', 'notify-error');
        if (any) {
            switch (maxStatus) {
                case NotificationStatusType.Info:
                    notifyIcon.classList.add('notify-info');
                    break;
                case NotificationStatusType.Success:
                    notifyIcon.classList.add('notify-success');
                    break;
                case NotificationStatusType.Warning:
                    notifyIcon.classList.add('notify-warning');
                    break;
                case NotificationStatusType.SevereWarning:
                    notifyIcon.classList.add('notify-severewarning');
                    break;
                case NotificationStatusType.Error:
                    notifyIcon.classList.add('notify-error');
                    break;
                default:
                    break;
            }
        }
    }, []);

    const handlePushNotificationAnnouncementsToShowChange = useCallback((val: boolean): void => {
        setShowingPushNotificationAnnouncements(val);
    }, []);

    return (
        <>
            <CustomizerWrapper {...topbarCustomizations}>
                {maintenanceMode && <MessageBar messageBarType={MessageBarType.warning}>{language?.Overview?.MaintenanteModeActive}</MessageBar>}
                <Navbar />
                <NotificationBar
                    show={showNotifications}
                    dismissCallback={() => setUIContext({ showNotifications: false })}
                    notificationsAvailableCallback={UpdateNotificationIconColor}
                    autoHideAfter={uiSettings.settings.autoHideNotificationsAfter}
                />
            </CustomizerWrapper>
            <CustomizerWrapper {...customizations}>
                <Fabric applyTheme id="tp-app" className={`tp-wrapper ${topbarAlwaysOpen || forcePinnedTopbar ? 'spacing-topbar' : 'hiding-topbar'}`}>
                    <ResourcePanel />
                    <ProjectPanel />
                    <div id="tp-layout-container" className="tp-layout-container">
                        <div className="tp-layout-pane middle">
                            <Main />
                        </div>
                        <div
                            id="tp-list-resources-dropzone-overlay"
                            onDrop={() => (DragAndDrop.RemoveDraggedResource = true)}
                            onDragEnter={e => e.preventDefault()}
                            onDragOver={e => e.preventDefault()}
                        >
                            <div id="tp-list-resources-dropzone-text">
                                {language?.Overview?.ResourceDropZone?.replace('[[gridtype]]', gridTypeString ? gridTypeString.toLowerCase() : 'activities')}
                            </div>
                        </div>
                    </div>

                    <SharedConfirmDialog />
                    <UserDetails Visible={showCurrentUserInfo} />
                    {!showingPushNotificationAnnouncements && <AnnouncementViewer />}
                    {uiSettings.settings.enablePushNotificationAnnouncements && (
                        <PushNotificationAnnouncementViewer AnnouncementsToShowCallback={handlePushNotificationAnnouncementsToShowChange} />
                    )}
                    <DemoModeViewer />
                    {blockContent}
                </Fabric>
            </CustomizerWrapper>
            {doCelebration && <Confetti />}
        </>
    );
};
