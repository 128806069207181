export enum ContentConfigurationType {
	Unknown = 0,
	Rbs = 1,
	Lookup = 2,
	// WorkProfile = 3,
	Theme = 4,
	MailTemplate = 5,
	WorkPackage = 6,
	Announcement = 7,
	HelpSection = 8
}