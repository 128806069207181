export default class Locale_TopBar {
	Pin: string = "Pin";
	UnPin: string = "UnPin";
	Project: string = "Project";
	Request: string = "Request";
	Allocation: string = "Allocation";
	ResourceCapacity: string = "Resource Capacity";
	MyCapacity: string = "My Capacity";
	Delegation: string = "Delegation";
	StopDelegation: string = "Stop Delegation";
	ResourceOverview: string = "Resource Overview";
	ProjectOverview: string = "Project Overview";
	ContractOverview: string = "Contract Overview";
	ContentSettings: string = "Content Settings";
	AdminDashboard: string = "Admin Dashboard";
	Planner: string = "Planner View";
	ProjectPlanner: string = "Project Planner";
	ResourcePlanner: string = "Resource Planner"
	WorkPackages: string = "Request Templates";
	MoreCommands: string = 'More commands';
	CompareScenarios: string = "Compare";
	Draft: string = "Draft";
}