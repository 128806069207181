import { GridRow } from '../../../../../../api/generated/data-contracts';

export const getRowType = (row: GridRow) => {
	if (row.metadata?.ProjectType !== undefined) {
		return 'project';
	}
	if (row.metadata?.ResourceType !== undefined) {
		return 'resource';
	}
	return 'contract';
};
