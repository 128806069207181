import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, DropdownMenuItemType, IconButton, IDropdownOption } from '@fluentui/react';
import { fieldTypes, Form, FormProvider, IFieldConfig, IFieldTypes, IFormItem, IFormItemFieldOptions, IFormSchema } from 'spark-forms';
import { CustomProperty, QueryGroup } from '../api/generated/data-contracts';
import { DropdownOptionEx } from '../Utilities/DropdownOptionEx';
import { ArrayEx } from '../Utilities/ArrayEx';
import { language } from '../Services/LocalizationService';
import { tpEntityFactory } from '../forms/common/tpEntityFactory';
import { QueryCondition } from '../Entities/DynamicQueryBuilder/QueryCondition';
import EnumEx from '../Utilities/EnumEx';
import { QueryOperator } from '../Entities/DynamicQueryBuilder/QueryOperator';
import { ITpFieldTypes, tpFieldTypes } from '../forms/_builders/new/tpFieldTypes';
import { IQueryExpression } from '../forms/_interfaces/IQueryExpression';
import { changeCase } from '../helpers/changeKeysCase';
import { CustomPropertyRender } from '../forms/customProperty/_components/CustomPropertyRender';
import { TPBuilder } from '../forms/_builders/new/TPBuilder';
import { FluentLabel } from 'spark-forms-fluent8';
import { IField, schemaHelper } from '../forms/_builders/new/helpers/schemaHelper';
import { clone } from '../Utilities/JsonEx';

export interface EntityPropertyFilterProps<T extends IFormItem> {
    item: QueryGroup;
    schema: IFormSchema<T>;
    onChanged: (group: QueryGroup) => void;
    customProperties?: CustomProperty[];
    hiddenProperties?: string[];
    onlyTheseProperties?: string[];
    entityType: 'resource' | 'project' | 'contract' | 'other';
    filterSystemProperties?: boolean;
    filterArrayProperties?: boolean;
    forcedIncludedFields?: string[];
    disabled?: boolean;
    setRootOnUpdate?: boolean;
    setRootOnClear?: boolean;
}


export const EntityPropertyFilter = <T extends IFormItem>({
    item,
    schema,
    onChanged,
    filterSystemProperties,
    filterArrayProperties,
    forcedIncludedFields,
    hiddenProperties,
    onlyTheseProperties,
    customProperties,
    disabled,
    entityType,
	setRootOnClear = true,
	setRootOnUpdate = true
}: React.PropsWithChildren<EntityPropertyFilterProps<T>>): React.ReactElement | null => {
    const [root, setRoot] = useState<QueryGroup>(() => changeCase.toCamel({ ...item }) ?? tpEntityFactory.queryGroup());

    const [propertyOptions, setPropertyOptions] = useState<IDropdownOption[]>();

    const fields = useMemo(
        () => schemaHelper.getFields<T>(schema, { filterArrayProperties, forcedIncludedFields, filterSystemProperties, hiddenProperties, onlyTheseProperties }),
        [filterArrayProperties, forcedIncludedFields, filterSystemProperties, hiddenProperties, onlyTheseProperties, schema],
    );

    const properties = useMemo(
        () => schemaHelper.getCustomProperties<T>(customProperties, { filterArrayProperties, filterSystemProperties, hiddenProperties, onlyTheseProperties }),
        [customProperties, filterArrayProperties, filterSystemProperties, hiddenProperties, onlyTheseProperties],
    );

    useEffect(() => {
        const fieldHeader = [{ key: 'fieldsHeader', text: language.Common.Fields, itemType: DropdownMenuItemType.Header } as IDropdownOption];
        const propertiesHeader = [{ key: 'propertiesHeader', text: language.Common.Properties, itemType: DropdownMenuItemType.Header } as IDropdownOption];

        const fieldOptions = DropdownOptionEx.toDropdownOptions(
            fields,
            _ => _.internalName,
            _ => _.displayName,
        );
        const customOptions = DropdownOptionEx.toDropdownOptions(
            properties,
            _ => `customProperties|${_.internalName}|`,
            _ => _.displayName,
        );

        const propOptions = ArrayEx.isNullOrEmpty(customProperties)
            ? fieldHeader.concat(fieldOptions)
            : fieldHeader.concat(fieldOptions).concat(propertiesHeader).concat(customOptions);
        setPropertyOptions(propOptions);
    }, [customProperties, fields, properties]);

    const Update = (): void => {
		if (setRootOnUpdate) {
			setRoot({ ...root });
		}
        onChanged(clone(root));
    };

    const Clear = (): void => {
		if (setRootOnClear) {
			setRoot(tpEntityFactory.queryGroup());
		}
        onChanged(tpEntityFactory.queryGroup());
    };

    const RenderGroup = (group: QueryGroup, parent?: QueryGroup, index?: number): JSX.Element => {
        group.parts ??= group.parts = [];
        const parts = group.parts.map((_, i) => {
            if (_.typeName === 'QueryExpression') {
                return RenderExpression(_, i, group);
            } else if (_.typeName === 'QueryGroup') {
                return RenderGroup(_, group, i);
            } else {
                return RenderCondition(_, group, i);
            }
        });

        return (
            <div className="querygroup" key={`group-${index}`}>
                {parts}
                <div style={{ margin: '7px' }}>
                    {RenderMenu(group)}
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        disabled={disabled}
                        style={{ fontSize: '20px' }}
                        onClick={e => RemoveGroup(parent, index)}
                        ariaLabel={language.EntityPropertyFilter.Delete.Grouping}
                        title={language.EntityPropertyFilter.Delete.Grouping}
                    />
                </div>
            </div>
        );
    };

    const GetProperty = (key: string): IField<T> => {
        const defaultItem = {} as IField<T>;
        if (key == null) {
            return defaultItem;
        }
        let fieldItem = fields.find(_ => _.internalName.toLowerCase() === key.toLowerCase());
        fieldItem ??= properties?.find(_ => `customProperties|${_.internalName}|` === key || `CustomProperties|${_.internalName}|` === key);
        fieldItem ??= defaultItem;
        return fieldItem;
    };

    const RenderCondition = (condition: QueryCondition, group: QueryGroup, index: number): JSX.Element => {
        return (
            <div key={`querycondition-${index}`}>
                <Dropdown
                    placeholder={language.Common.Condition}
                    className="querycondition"
                    selectedKey={condition}
                    options={DropdownOptionEx.enumToDropdownOptions(QueryCondition)}
                    onChange={(e, option) => {
                        group.parts[index] = QueryCondition[option.text];
                        Update();
                    }}
                />
            </div>
        );
    };

    const RemoveGroup = (parent: QueryGroup, index: number) => {
        if (parent == null) {
            return;
        }
        // if removing last part, remove pre operator
        if (index === parent.parts.length - 1) {
            index--;
        }
        // remove expression and related operator
        if (parent) {
            parent.parts.splice(index, 2);
            Update();
        } else Clear();
    };

    const RemoveExpression = (group: QueryGroup, index: number) => {
        // if removing last part, remove pre operator
        if (index === group.parts.length - 1) index--;
        // remove expression and related operator
        if (group) {
            group.parts.splice(index, 2);
            Update();
        } else Clear();
    };

    const GetDefaultSelectedKey = (expr: IQueryExpression, isField: boolean): string => {
        if (expr.property) {
            if (isField) return changeCase.stringToCamel(expr.property);
            else {
                const match = expr.property.match(/[cC]ustomProperties\|(.*?)\|/i);
                if (match?.length) return changeCase.stringToCamel(match[0]);
            }
        }
        return null;
    };

    const RenderExpression = (expr: IQueryExpression, idx: number, group: QueryGroup): JSX.Element => {
        const field = GetProperty(expr.property);
        const isField = field.type === 'field';

        const disableOperator = (op: number) => {
            return (field.lookup && op > 1) || (field.internalName === 'rbs' && op > 1 && op < 5) || ((field.internalName === 'userTypes' ||field.internalName === 'additionalOwners') && op !== 4);
            // TODO: what about resource, rbs, etc. (ewi)
            // they should also disable all operators but Equals and NotEquals (ewi)2
        };
        const operators = EnumEx.allAsNumber<QueryOperator>(QueryOperator).map(e => {
            return { key: e as number, text: QueryOperator[e].toString(), disabled: disableOperator(e as number) };
        });

        // hard coded quick fix due to the new rendering of active state on projects and resources (TODO: different) (ewi)
        if (field.internalName === 'active' || field.internalName === 'isActive') {
            field.options ??= {} as IFormItemFieldOptions<any, IFieldConfig<any>>;
            field.options.fieldType = fieldTypes.boolean as keyof IFieldTypes;
            field.displayName = 'active';
        }

        // date fields should have the ability to select "today" template for dynamic filtering
        if (field.options?.fieldType === 'date') {
            field.options.fieldType = tpFieldTypes.dateWithTodayFilter as any;
        }

		// resource fields should have the ability to select "me" template for dynamic filtering
		if (field.options?.fieldType === 'resource' as keyof ITpFieldTypes) {
			field.options.fieldType = tpFieldTypes.resourceWithMeFilter as any;
		}

		// rbs fields should have the ability to select "myrbs" template for dynamic filtering
		if (field.options?.fieldType === 'rbs' as keyof ITpFieldTypes) {
			field.options.fieldType = tpFieldTypes.rbsWithMyRbsFilter as any;
		}

        // create a fake item and option, used to render the single property
        const formFieldOptions: IFormItemFieldOptions<any, IFieldConfig<any>> = {
            config: {
                multiSelect: false,
            },
            ...field.options,
            hideLabel: true,
            disable: () => false,
            hide: () => false,
        };

        if (field.options?.fieldType === ('predefinedArray' as keyof ITpFieldTypes)) {
            formFieldOptions.config.multiSelect = false;
        }

        const schema: IFormSchema<any> = {
            fields: {
                [field.internalName]: formFieldOptions,
            },
        };

        const fakeItem = {};
        fakeItem[field.internalName] = expr.value;

        return (
            <div className="queryexpression" key={`filter-expr-${idx}`}>
                <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    style={{ fontSize: '20px', width: 'unset !important', display: `${disabled ? 'none' : 'unset'}` }}
                    onClick={e => RemoveExpression(group, idx)}
                    ariaLabel={language.EntityPropertyFilter.Delete.Expression}
                    title={language.EntityPropertyFilter.Delete.Expression}
                />
                <Dropdown
                    disabled={disabled}
                    placeholder={language.EntityPropertyFilter.SelectPropertyPlaceholder}
                    options={propertyOptions}
                    defaultSelectedKey={GetDefaultSelectedKey(expr, isField)}
                    onChange={(event, option, idx) => {
                        expr.property = option.key as string;
                        expr.value = null;
                        Update();
                    }}
                />
                <div>
                    <Dropdown
                        disabled={disabled}
                        placeholder="Operator"
                        options={operators}
                        defaultSelectedKey={expr.operator}
                        onChange={(e, o) => {
                            expr.operator = QueryOperator[o.text];
                            Update();
                        }}
                    />
                </div>
                {field && (
                    <div>
                        {isField ? (
                            <FormProvider
                                onStateChange={(_item, _field, value) => {
                                    expr.value = value;
                                    Update();
                                }}
                                builder={TPBuilder}
                                builderOptions={{ labelComponent: FluentLabel }}
                                item={fakeItem ?? {}}
                                schema={schema}
                                watch={undefined} // deliberate to prevent watch getting mixed with nested formcontexts
                            >
                                <Form />
                            </FormProvider>
                        ) : (
                            <CustomPropertyRender
                                disabled={disabled}
                                value={expr.value}
                                onChange={(value: any) => {
                                    expr.value = value;
                                    Update();
                                }}
                                hideLabel
                                property={customProperties.find(_ => _.id === field.internalName)}
                                entityType={entityType}
                                parent={null}
                                propertyName={null}
                                onBlur={null}
                                options={null}
                                noValidation
                                overrideDisabledProps
                            />
                        )}
                    </div>
                )}
            </div>
        );
    };

    const RenderMenu = (group: QueryGroup): JSX.Element => {
        return (
            <div style={{ float: 'left' }}>
                <IconButton
                    disabled={disabled}
                    title={language.Common.Add}
                    iconProps={{ iconName: 'Add' }}
                    menuProps={{
                        shouldFocusOnMount: true,
                        items: [
                            {
                                key: 'group',
                                iconProps: { iconName: 'RowsGroup' },
                                text: language.EntityPropertyFilter.Group,
                                disabled: group.parts.length > 0 && group.parts[group.parts.length - 1].typeName != null,
                                onClick: () => {
                                    group.parts.push(tpEntityFactory.queryGroup());
                                    Update();
                                },
                            },
                            {
                                key: 'expression',
                                iconProps: { iconName: 'QueryList' },
                                text: language.Common.Expression,
                                disabled: group.parts.length > 0 && group.parts[group.parts.length - 1].typeName != null,
                                onClick: () => {
                                    group.parts.push(tpEntityFactory.queryExpression());
                                    Update();
                                },
                            },
                            {
                                key: 'condition',
                                iconProps: { iconName: 'DrillExpand' },
                                text: language.Common.Condition,
                                disabled: group.parts.length === 0 || group.parts[group.parts.length - 1].typeName == null,
                                onClick: () => {
                                    group.parts.push(QueryCondition.And);
                                    Update();
                                },
                            },
                        ],
                    }}
                />
            </div>
        );
    };

    return <div className="tp-property-filter">{fields && RenderGroup(root, null)}</div>;
};
