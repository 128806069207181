import { Settings } from "../../Entities/Main/Settings";
import { GroupRow } from "../../Entities/Table/GroupRow";
import { TimelineResolution } from "../../Entities/TimelineResolution";
import { Lookup } from "../../Entities/ContentConfiguration/Lookup/Lookup";
import { ResourcePanelItem } from "../../ListItems/ResourcePanelItem";
import { CacheLikeGetter } from "../CacheLikeGetter";
import { ContentConfigurationService } from "../../Services/ContentConfigurationService";
import { ProjectPanelItem } from "../../ListItems/ProjectPanelItem";

export class OverviewContext {
	constructor() { }
	// find a fancy way for controlling add resource/project in panels
	public static AddResource: (resource: ResourcePanelItem, row?: GroupRow) => Promise<void> | void;
	public static AddProject: (project: ProjectPanelItem, row?: GroupRow) => void;
	// move to context
	public static Settings: Settings;
	// do this through route instead - still keep the querystring 'id' thingy
	public static SingleProjectView: boolean; 
	// move to projectcontext
	public static UserIsPmForSelectedProject: boolean;
	// find a fancy way for refreshing grids from outside
	// thought context?
	public static RefreshProjectCapacity: (groupBy?: string) => void;
	public static RefreshResourceCapacity: () => void;
	public static RefreshLmCapacity: () => void;
	public static RefreshWorkPackages: (groupBy?: string) => void;
	// handle this through timeline context
	public static ChangeResolution: (resolution: TimelineResolution) => void;
	// move to settings context
	public static AllowTimelineBackInTime = () : boolean => OverviewContext.Settings.CacheContractConstraint?.Backward > 0;

	// find a fancy way for refreshing grids from outside
	// thought context?
	public static RefreshCurrentCapacityView = (groupBy?: string) : void => {
		if (OverviewContext.RefreshProjectCapacity) OverviewContext.RefreshProjectCapacity(groupBy);
		if (OverviewContext.RefreshResourceCapacity) OverviewContext.RefreshResourceCapacity();
		if (OverviewContext.RefreshLmCapacity) OverviewContext.RefreshLmCapacity();
		if (OverviewContext.RefreshWorkPackages) OverviewContext.RefreshWorkPackages(groupBy);
	}

	// REMOVE when form replaced
	private static Lookups: Array<Lookup>;
	public static GetLookups = async () : Promise<Array<Lookup>> => {
		return await CacheLikeGetter<Array<Lookup>>(() => OverviewContext.Lookups, val => OverviewContext.Lookups = val, ContentConfigurationService.GetLookups);
	}
	
	// REMOVE when form replaced
	public static SetLookups = (lookups: Array<Lookup>): void => {
		OverviewContext.Lookups = lookups;
	}
}