export const stringVariants = {
    generateAsStringFields: (properties: Array<string>) => {
        return properties
            .filter(_ => _ != null)
            .map(_ => {
                if (_.indexOf('CustomProperties.') >= 0) return _.replace('CustomProperties.', 'CustomPropertiesAsString.');
                return `${_}AsString`;
            });
    },

    getAsStringValue<T extends object>(item: T, field: string): string {
        if (field == null) {
            return null;
        }

        if (field.indexOf('CustomProperties.') >= 0) {
            field = field.replace('CustomProperties.', '');
            if (item['CustomPropertiesAsString'] != null && Object.hasOwn(item['CustomPropertiesAsString'], field)) {
                return item['CustomPropertiesAsString'][field];
            } else {
                return null;
            }
        } else if (item[`${field}AsString`]) {
            return item[`${field}AsString`];
        }
        return item[field];
    },
};

export const stringVariantsCamelCase = {
    generateAsStringFields: (properties: Array<string>) => {
        return properties
            .filter(_ => _ != null)
            .map(_ => {
                if (_.indexOf('customProperties.') >= 0) return _.replace('customProperties.', 'customPropertiesAsString.');
                return `${_}AsString`;
            });
    },

    getAsStringValue<T extends object>(item: T, property: string): string | null | undefined {
        if (!property) return null;

        if (property.startsWith('customProperties.')) {
            const key = property.replace('customProperties.', '');
            return item['customPropertiesAsString']?.[key];
        } else {
            const propertyAsString = `${property}AsString`;
            if (Object.hasOwn(item, propertyAsString)) {
                return item[propertyAsString];
            }
        }

        return item[property];
    },
};

