import { useMemo, useEffect } from 'react';
import { useUISettings } from '../context/network/http/QueryProvider/queries/UISettings';
import { UserType } from '../api/generated/data-contracts';
import { DateEx } from '../Utilities/DateEx';
import { useStore } from '../context/store';

const useClientSecretExpirationDialog = () => {
	const { data: uiSettings } = useUISettings();

	const { setBlockDialog } = useStore(
        store => ({
            setBlockDialog: store.setBlockDialog,
        }),
    );

    const daysUntilExpiration = useMemo(() => {
        const { userTypes } = uiSettings.resource;
        const { clientSecretExpirationDate } = uiSettings.applicationInfo ?? {};

        if (
            userTypes.indexOf(UserType.Administrator) > -1 &&
            clientSecretExpirationDate != null
        ) {
            return DateEx.getDifferenceInDays(DateEx.todaysDate(), clientSecretExpirationDate);
        }
        return null;
    }, [uiSettings.resource, uiSettings.applicationInfo]);

    useEffect(() => {
        if (daysUntilExpiration !== null) {
            if (daysUntilExpiration <= 0) {
                setBlockDialog(null, {
                    title: `URGENT: Client Secret Expired`,
                    subText: `The client secret expired ${Math.abs(daysUntilExpiration)} day(s) ago. Immediate renewal is required to maintain security and system functionality.`,
                    dismissEnable: true,
                    refreshEnabled: false,
                    isBlocking: false,
                });
            } else if (daysUntilExpiration <= 10) {
                setBlockDialog(null, {
                    title: `CRITICAL: Client Secret Nearly Expired`,
                    subText: `The client secret will expire in ${daysUntilExpiration} day(s). Immediate action is required to renew the client secret and avoid potential disruptions.`,
                    dismissEnable: true,
                    refreshEnabled: false,
                    isBlocking: false,
                });
            } else if (daysUntilExpiration <= 30) {
                setBlockDialog(null, {
                    title: 'Notice: Client Secret Expiration Approaching',
                    subText: `The client secret is set to expire in ${daysUntilExpiration} day(s). Please ensure renewal is completed to avoid any service interruptions.`,
                    dismissEnable: true,
                    refreshEnabled: false,
                    isBlocking: false,
                });
            }
        }
    }, [daysUntilExpiration, setBlockDialog]);
};

export default useClientSecretExpirationDialog;
