import { isNumeric } from '../../../../Utilities/NumberEx';

export type SortOrder = 'desc' | 'asc';
export const sortString = (a: string, b: string) => {
    if (isNumeric(a) && isNumeric(b)) {
        return sortNumber(Number(a), Number(b));
    }
    return a.localeCompare(b);
};
export const sortNumber = (a: number, b: number) => a - b;
export const sortDate = (a: Date, b: Date) => new Date(a).getTime() - new Date(b).getTime();
export const sortBool = (a: boolean, b: boolean) => Number(a) - Number(b);

export const getSorter = (type?: string): ((a: any, b: any) => number) => {
    switch (type) {
        case 'number':
            return sortNumber;
        case 'date':
            return sortDate;
        case 'bool':
            return sortBool;
        case 'url':
        case 'RBS':
        case 'resourcetype':
        case 'projecttype':
        case 'stringlist':
        case 'resourcelist':
        case 'string':
        default:
            return sortString;
    }
};

export const sortRowProperty = (a: any, b: any, sortOrder: SortOrder, type?: string) => {
    return withFallbackSort(withSortOrder(getSorter(type)))(a, b, sortOrder);
};

// type RowCustomProperty = 'number' | 'date' | 'bool' | 'url' | 'RBS' | 'resourcetype' | 'projecttype' | 'stringlist' | 'resourcelist' | 'string'

function withSortOrder<T>(sorter: (a: T, b: T) => number) {
    return (a: T, b: T, sortOrder: SortOrder) => {
        return sorter(a, b) * (sortOrder === 'asc' ? 1 : -1);
    };
}

function withFallbackSort<T>(sorter: (a: T, b: T, sortOrder: SortOrder) => number) {
    return (a: T, b: T, sortOrder: SortOrder) => {
        if ((a as any) === b) {
            // identical? return 0
            return 0;
        } else if (a === null || a === '') {
            // a is null? last
            return sortOrder === 'desc' ? 1 : -1;
        } else if (b === null || b === '') {
            // b is null? last
            return sortOrder === 'desc' ? -1 : 1;
        }
        return sorter(a, b, sortOrder);
    };
}
