import { Resource } from "../Entities/Main/Resource";

export class ResourceWithStringVariants extends Resource {
	public OriginsAsString: string = "";
	public ActiveAsString: string = "";
	public CustomPropertiesAsString: Record<string, string> = {};
	public RBSAsString: string = "";
	public SettingsAsString: string = "";
	public UserTypesAsString: string = "";
	public ResourceTypeAsString: string = "";
	public RelatedGenericResourceIdAsString: string = "";
	public AdditionalRBSAsString: string = "";
	public WorkProfileIdAsString: string = "";
	public EnableSpecificWorkprofileAsString: string = "";
	public SpecificWorkprofileIdAsString: string = "";
	public TeamProjectAsString: string = "";
	public ManagerAsString: string = "";
}