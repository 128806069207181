/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo, useEffect, useState } from 'react';
import { DynamicProps } from './DynamicProps';
import { MessageBar, MessageBarType } from '@fluentui/react';

interface IProps extends DynamicProps<boolean, boolean> {
    Options: (parent) => { message: string; type: MessageBarType; width: string };
}

const DynamicMessageBar: FunctionComponent<IProps> = props => {
    const [options, setOptions] = useState(props.Options != null ? props.Options(props.Parent) : null);

    useEffect(() => {
        if (!props.Options) return;
        setOptions(props.Options(props.Parent));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.Options]);

    if (options == null) return null;

    return (
        <MessageBar key={props.Key} messageBarType={options.type} styles={{ root: { width: options.width } }}>
            {options.message}
        </MessageBar>
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Value === nextProps.Value;
};

export default memo(DynamicMessageBar, comparisonFn);
