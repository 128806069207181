import { useState, useEffect, useRef } from 'react';
import { Plugin } from '../../../Services/PluginInvoker';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import { TopRow } from '../../../Entities/Table/TopRow';
import Table from '../../../Components/Overview/Table/Table';
import { ActionButton, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles } from '@fluentui/react';
import { Cell } from '../../../Entities/Table/Cell';
import { AddActivityDto, WorkType } from '../../../Entities/Dto/AddActivityDto';
import { CellRow } from '../../../Entities/Table/CellRow';
import { OverviewContext } from '../../../Utilities/Context/OverviewContext';
import { CellEx } from '../../../Utilities/CellEx';
import { PFSpinner } from '../../../Components/Common/Spinner';
import { ProjectCapacityService } from '../../../Services/ProjectCapacityService';
import { GroupRow } from '../../../Entities/Table/GroupRow';
import { UnitType } from '../../../Entities/UnitType';
import { useTheme } from '../../../Themes/themeContext';
import { EntityInformationCallout } from '../../../Components/Common/EntityInformationCallout';
import { EntityType } from '../../../Entities/EntityTypes';
import { EventEx } from '../../../Utilities/EventEx';
import { MessageBarButtonInfo } from '../../../Entities/Fabric/MessageBarButtonInfo';
import { Notification, NotificationStatusType } from '../../../Entities/Notification';
import { UnitTypeUtil, useUnitType } from '../../../Utilities/UnitTypeUtil';
import { CellUpdateDto } from '../../../Components/Overview/Table/DragCopy/CellUpdateDto';
import { UndoItem } from '../../../Entities/UndoItem';
import { language } from '../../../Services/LocalizationService';
import { Permission } from '../../../Services/Permission';
import { NumberEx } from '../../../Utilities/NumberEx';
import { UserSettingsService } from '../../../Services/Settings/UserSettingsService';
import { IDs } from '../../../Utilities/IDs';
import { Resource } from '../../../Entities/Main/Resource';
import { DataService } from '../../../Services/DataService';
import { ResourceType } from '../../../Entities/ResourceType';
import { Text } from '@fluentui/react/lib/Text';
import { useNavigate, useParams } from 'react-router';
import { useStore } from '../../../context/store';
import { ITimeline } from '../../../context/contexts/timelineContext';
import shallow from 'zustand/shallow';
import { IEntityInfoCallout } from '../../../context/contexts/entityCalloutContext';
import { useUISettings } from '../../../context/network/http/QueryProvider/queries/UISettings';
import { useStateRef } from '@wiberg/formbuilder';
import { Timeout } from '../../../types/runtimeTypes';
import { CellType, DateResolution, GridType, UserType } from '../../../api/generated/data-contracts';
import { isOverAllocated, roundAllocation } from '../helpers';
import { useCheckTPPermissions } from '../../../hooks/usePermissions';
import { ApiCalls } from '../../../api/api';
import { guid } from '../../../helpers/guid';
import { deepCopy } from '../../../Utilities/ObjectEx';
import { Row } from '../../../Entities/Table/Row';
import { upperCaseObj } from '../../../context/network/http/QueryProvider/helpers/queryHelper';
import { GroupContainerRow } from '../../../Entities/Table/GroupContainerRow';
import { useResourceCapacityProject } from './useResourceCapacityProject';


const ResourceCapacity = () => {
    const { data: uiSettings } = useUISettings();
    const { resourceId } = useParams<{ resourceId: string }>();
    const { currentUnitType } = useUnitType();
    const { timeline, setEntityCalloutInfo, setUIContext, warning, info, error, addNotification } = useStore(
        store => ({
            timeline: store.timeline,
            setEntityCalloutInfo: store.setEntityCalloutInfo,
            setUIContext: store.setUIContext,
            error: store.addErrorNotification,
            info: store.addInfoNotification,
            warning: store.addWarningNotification,
            addNotification: store.addNotification,
        }),
        shallow,
    );

    const setBlockDialog = useStore(store => store.setBlockDialog);

    const addProject = useResourceCapacityProject();
    const [view, _setView, viewRef] = useStateRef<TopRow>();
    const [loading, setLoading] = useState(true);
    const ignoreResolutionMismatch = useRef(false);

    const checkPermissions = useCheckTPPermissions();

    // previous is needed because updates come from outside and inside
    const prevTimeline = useRef<ITimeline>();

    const [localResourceId, setLocalResourceId] = useState<string>();
    // previous is needed because updates come from outside and inside
    const prevResourceId = useRef<string>();
    const [resource, setResource] = useState<Resource>();

    // details dialog
    const [detailDto, setDetailDto] = useState<IEntityInfoCallout>(null);

    const navigate = useNavigate();

    const theme = useTheme();

    const setView = (view: TopRow) => {
        // if (!isMounted.current) return;
        if (view != null) view.ChangeKey = IDs.makeId();
        _setView(view);
    };

    useEffect(() => {
        if (viewRef.current === undefined) return;
        ForceRefreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUnitType]);

    useEffect(() => {
        OverviewContext.RefreshResourceCapacity = ForceRefreshData;
        // willunmount
        return () => (OverviewContext.RefreshResourceCapacity = null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    // 	if (start == null || end == null) return;
    // 	setTimeline({Start: start, End: end, Resolution: resolution});
    // }, [start, end, resolution]);

    useEffect(() => {
        let usedResourceId = resourceId;
        if (resourceId == null) {
            if (checkPermissions({ userTypes: [UserType.LineManager], not: true })) {
                usedResourceId = uiSettings?.resource?.id;
            } else return;
        }
        const fetch = async () => {
            const res = await DataService.Get<Resource>(EntityType.Resource, usedResourceId);
            setResource(res);
            setLocalResourceId(usedResourceId);
        };
        fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourceId]);

    useEffect(() => {
        const getView = async () => {
            await LoadCapacity();
        };
        getView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeline, localResourceId]);

    const LoadCapacity = async (force: boolean = false) => {
        // running.begin();
        // seperate handling of force is necessary as calls to this comes from outside this context
        if (force) {
            // if (!running.endShouldContinue()) return;
            setLoading(true);
            // const top = await GetCapacity(resourceId, start, end, resolution);
            const top = await GetCapacity(localResourceId, timeline.start, timeline.end, timeline.resolution);
            // if (!isMounted.current) return;
            setView(top);
            setLoading(false);
            return;
        }

        // don't refresh data if timeline or resource hasn't changed
        if (localResourceId == null || timeline == null) {
            // running.end();
            return;
        }
        if (
            prevResourceId.current != null &&
            prevResourceId.current === localResourceId &&
            prevTimeline.current != null &&
            timeline != null &&
            timeline.start === prevTimeline.current.start &&
            timeline.end === prevTimeline.current.end &&
            timeline.resolution === prevTimeline.current.resolution
        ) {
            // running.end();
            return;
        }

        prevTimeline.current = { ...timeline };
        prevResourceId.current = localResourceId;

        // if (!running.endShouldContinue()) return;
        setLoading(true);
        const top = await GetCapacity(localResourceId, timeline.start, timeline.end, timeline.resolution);
        // if (!isMounted.current) return;
        setView(top);
        setLoading(false);
    };

    const GetCapacity = async (resourceId: string, start: Date, end: Date, resolution: DateResolution): Promise<TopRow> => {
        if (!resourceId || resourceId === '') return null;
        const readOnly = Permission.HasSomePermissions(UserType.LineManager, UserType.SuperUser) ? false : true; // if not LM or SU act as 'My Capacity' and readonly
        return await Plugin.Invoke<TopRow>(
            PluginIDs.GetResourceCapacityView,
            { ResourceId: resourceId, Start: start, End: end, DateResolution: resolution, ReadOnly: readOnly },
            'Failed getting resource capacity',
            true,
        );
    };

    const ForceRefreshData = async () => {
        await LoadCapacity(true);
    };

    const OnCellUpdate = (columnIndex: number, idx: number, cell: Cell, newValue: number, isUndo?: boolean): TopRow => {
        const flattenedGroups = ProjectCapacityService.GroupsFlattened(viewRef.current);

        const group = flattenedGroups.find(_ => _.Id === cell.Properties.ProjectId);
        const row = group.Rows.find(_ => _.Id === cell.Properties.ContractId);
        const cellRow = row.CellRows[columnIndex];

        // handle allocation on different resolution than request
        if (
            OverviewContext.Settings.EnableGridResolutionMismatchWarning &&
            !ignoreResolutionMismatch.current &&
            cellRow.Properties.RequestResolution &&
            (cellRow.Properties.RequestResolution.length > 1 ||
                (cellRow.Properties.RequestResolution.length === 1 && cellRow.Properties.RequestResolution[0] !== timeline.resolution))
        ) {
            // NotificationService.Instance.Warning(language.ResourceCapacity.DifferentTimeResolution.replace('[[differenttimeresolution]]', TimelineResolution[cellRow.Properties.RequestResolution[0]].toString()));
            warning(
                language.ResourceCapacity.DifferentTimeResolution.replace(
                    '[[differenttimeresolution]]',
                    DateResolution[cellRow.Properties.RequestResolution[0]].toString(),
                ),
            );

            const notification = new Notification();
            notification.Message = language.CapacityViews.OnCellUpdate.NotificationMessage;
            notification.StatusType = NotificationStatusType.SevereWarning;
            notification.Buttons = [
                new MessageBarButtonInfo(language.CapacityViews.OnCellUpdate.Disable, () => (ignoreResolutionMismatch.current = true)),
                new MessageBarButtonInfo('Switch', () => OverviewContext.ChangeResolution(cellRow.Properties.RequestResolution[0])),
            ];
            // NotificationService.Instance.AddNotification(notification);
            addNotification(notification);
            return viewRef.current;
        }

        // store action to be used in 'undo' feature
        // if (!isUndo && OverviewContext.Settings.EnableGridUndo)
        if (!isUndo && uiSettings.settings.enableGridUndo)
            SaveActions(timeline.resolution, WorkType.Allocation, idx, [{ Cell: cell, ColumnIndex: columnIndex, Value: cell.Value }]);

        const lastValue = cellRow.Cells[idx].Value;
        if (uiSettings.settings?.restrictOverAllocating) {
            // freeCap doesn't exist on category resources, thus infinity capacity
            const freeCap = view.Groups[0]?.CellRows[columnIndex].Cells[0]?.Value ?? Infinity;
            const maxValue = lastValue + freeCap;
            roundAllocation(newValue, maxValue, (roundedNew: number, roundedMax: number) => {
                if (roundedNew === roundedMax) {
                    newValue = maxValue;
                }
            });
            if (isOverAllocated(newValue, maxValue, lastValue)) {
                error(language.CapacityViews.OverAllocation);
                Undo();
                // Skip this cell update
                return viewRef.current;
            }
        }

        const diff = newValue - lastValue;

        // update cell value
        CellEx.Update(row.CellRows[columnIndex].Cells[idx], newValue);

        // update total rows
        UpdateTotals(group, columnIndex, idx, diff);
        // update total column
        if (UnitTypeUtil.getCurrentUnitType() === UnitType.Hours) {
            UpdateTotals(group, 0, idx, diff);
            CellEx.Update(row.CellRows[0].Cells[idx], diff, true);
        }

        // save cell
        SaveCell(timeline.resolution, cell, cellRow);
        return view;
    };

    const UpdateTotals = (group: GroupRow, columnIndex: number, idx: number, diff: number): void => {
        // update totals
        CellEx.Update(group.CellRows[columnIndex].Cells[idx], diff, true);
        // if resource is a 'Category Resource' don't update free capacity, as it's not there
        if (resource.ResourceType === ResourceType.Category) {
            CellEx.Update(view.Groups[0].CellRows[columnIndex].Cells[idx], diff, true);
        } else {
            CellEx.Update(view.Groups[0].CellRows[columnIndex].Cells[idx], -diff, true);
            CellEx.Update(view.Groups[1].CellRows[columnIndex].Cells[idx], diff, true);
        }
    };

    const SaveCell = (resolution: DateResolution, cell: Cell, parent: CellRow, newValue?: number): void => {
        const dto = new AddActivityDto();
        dto.Start = parent.Start;
        dto.End = parent.End;
        dto.Type = WorkType.Allocation;
        dto.Work = NumberEx.getValueOrDefault(newValue ? newValue : cell.Value);
        dto.ContractId = parent.Properties.ContractId;
        dto.ResourceId = parent.Properties.ResourceId;
        dto.ProjectId = parent.Properties.ProjectId;
        dto.ResourceName = parent.Properties.ResourceName;
        dto.Resolution = resolution as any;

        Plugin.Invoke<any>(PluginIDs.SaveProjectCapacityCell, dto)
            .then(_ => {
                console.info(language.ResourceCapacity.SaveCell.Success);
                // ctx.Set({GridHasChanges: true});
                setUIContext({ gridHasChanged: true });
                HighlightCell([cell.Id], true, 'highlightdone');
            })
            .catch(_ => {
                // NotificationService.Instance.Error(language.ResourceCapacity.SaveCell.Fail);
                error(language.ResourceCapacity.SaveCell.Fail);
                HighlightCell([cell.Id], true, 'highlighterror');
                // ctx.Set({GridHasChanges: true});
                setUIContext({ gridHasChanged: true });
            });
    };

    const HighlightCell = (cellIds: Array<string>, removeBorder?: boolean, highlightClass: string = 'highlight') => {
        if (cellIds == null) return;
        cellIds.forEach(_ => {
            const element = document.querySelector(`.tp-capacity-cell-container input[data-cellid="${_}"]`) as HTMLElement;
            if (!element) return;
            element.classList.add(highlightClass);
            setTimeout(() => {
                element.classList.remove(highlightClass);
            }, 1000);

            if (removeBorder) element.style.border = 'unset';
        });
    };

    const GetFreeCapacityColor = (value: number): string => {
        if (value == null) return '';
        value = parseFloat(value.toFixed(ProjectCapacityService.GetGridDecimals()));

        if (value < 0) return theme.semanticColors.capacityOverBackground;
        else if (value > 0) return theme.semanticColors.capacityUnderBackground;
        else if (value === 0) return theme.semanticColors.capacityEqualBackground;
        return '';
    };

    const buildSaveCell = (resolution: DateResolution, type: WorkType, cell: Cell, parent: CellRow, resourceName: string, newValue: number): AddActivityDto => {
        const dto = new AddActivityDto();
        dto.Start = parent.Start;
        dto.End = parent.End;
        dto.Type = type;
        dto.Work = NumberEx.getValueOrDefault(newValue ? newValue : cell.Value);
        dto.ContractId = cell.Properties.ContractId;
        dto.ResourceId = cell.Properties.ResourceId;
        dto.ProjectId = cell.Properties.ProjectId;
        dto.ResourceName = resourceName;
        dto.Resolution = resolution as any;
        return dto;
    };

    const DragUpdates = () => {
        const updates = [
            (cells: Array<CellUpdateDto>) => {
                const updatedModel = OnMultipleCellsUpdate(view, timeline.resolution, WorkType.Allocation, 0, cells);
                setView({ ...updatedModel });
            },
        ];
        return updates;
    };

    const OnMultipleCellsUpdate = (
        view: TopRow,
        resolution: DateResolution,
        workType: WorkType,
        idx: number,
        cells: Array<CellUpdateDto>,
        isUndo?: boolean,
    ): TopRow => {
        // store actions to be used in 'undo' feature
        if (!isUndo && OverviewContext.Settings.EnableGridUndo)
            SaveActions(
                resolution,
                workType,
                idx,
                cells.map(_ => {
                    return { Cell: _.Cell, ColumnIndex: _.ColumnIndex, Value: _.Cell.Value };
                }),
            );

        let newValue = cells[cells.length - 1].Value;
        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);

        const dtos = cells.reduce((acc, cell) => {
            const group = flattenedGroups.find(_ => _.Id === cell.Cell.Properties.ProjectId);
            const contract = group.Rows.find(_ => _.Id === cell.Cell.Properties.ContractId);
            const cellRow = contract.CellRows[cell.ColumnIndex];

            const lastValue = cellRow.Cells[idx].Value;
            if (uiSettings.settings?.restrictOverAllocating) {
                // freeCap doesn't exist on category resources, thus infinity capacity
                const freeCap = view.Groups[0]?.CellRows[cell.ColumnIndex].Cells[0]?.Value ?? Infinity;
                const maxValue = lastValue + freeCap;
                roundAllocation(newValue, maxValue, (roundedNew, roundedMax) => {
                    if (roundedNew === roundedMax) {
                        newValue = maxValue;
                    }
                });
                if (isOverAllocated(newValue, maxValue, lastValue)) {
                    error(language.CapacityViews.OverAllocation);
                    Undo();
                    // Skip this cell update
                    return acc;
                }
            }

            const diff = newValue - lastValue;

            CellEx.Update(contract.CellRows[cell.ColumnIndex].Cells[idx], newValue);
            // update total rows
            UpdateTotals(group, cell.ColumnIndex, idx, diff);
            // update total column
            if (UnitTypeUtil.getCurrentUnitType() === UnitType.Hours) {
                UpdateTotals(group, 0, idx, diff);
                CellEx.Update(contract.CellRows[0].Cells[idx], diff, true);
            }
            const dto = buildSaveCell(resolution, workType, cell.Cell, cellRow, contract.Name, newValue);
            acc.push(dto);
            return acc;
        }, [] as AddActivityDto[]);

        ProjectCapacityService.SaveCells(
            dtos,
            cells.map(_ => _.Cell.Id),
            error,
        );
        setUIContext({ gridHasChanged: true });
        return view;
    };

    const savedActions = useRef<Array<UndoItem>>([]);

    const SaveActions = (resolution: DateResolution, workType: WorkType, idx: number, cells: Array<CellUpdateDto>) => {
        const item = new UndoItem(resolution as any, workType, idx, cells);
        savedActions.current.push(item);
    };

    const Undo = () => {
        if (!OverviewContext.Settings.EnableGridUndo) return;
        const item = savedActions.current.pop();
        if (item == null) {
            // NotificationService.Instance.Info(language.CapacityViews.Undo.NothingToUndo, 2000);
            info(language.CapacityViews.Undo.NothingToUndo, 2000);
            return;
        }
        const updatedModel: TopRow = OnMultipleCellsUpdate(viewRef.current, item.resolution as any, item.workType, item.idx, item.cells, true);
        // highlight undo'ed items
        ProjectCapacityService.HighlightCell(
            item.cells.map(_ => _.Cell.Id),
            true,
        );
        // update view
        setView({ ...updatedModel });
        // notify
        info(language.CapacityViews.Undo.UndidPreviousCellEdit, 2000);
    };

    const ExcelCopyUpdate = (cells: Array<CellUpdateDto>, excessiveCells: Array<CellUpdateDto>) => {
        const updatedModel = OnMultipleCellsUpdate(viewRef.current, timeline.resolution, WorkType.Allocation, 0, cells);
        setView({ ...updatedModel });
        // highlight copied items
        ProjectCapacityService.HighlightCell(
            cells.map(_ => _.Cell.Id),
            true,
        );
        if (excessiveCells.length) {
            warning(language.CapacityViews.ExcelCopyUpdate);
        }
    };

    let _projtimer: Timeout;
    const OnProjectHover = (projectId: string): void => {
        const timer = EventEx.delay(
            _projtimer,
            () => {
                setEntityCalloutInfo({
                    Id: projectId,
                    EntityType: EntityType.Project,
                    Target: `#resource-capacity-group-hover-${projectId}`,
                });
            },
            800,
        );
        _projtimer = timer;
    };

    const OnProjectHoverLeave = (): void => {
        clearTimeout(_projtimer);
        _projtimer = null;
        setEntityCalloutInfo(null);
    };

    let _restimer: Timeout;
    const OnResourceHover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (localResourceId == null) return;
        _restimer = EventEx.delay(
            _restimer,
            () => {
                setDetailDto({
                    Id: localResourceId,
                    EntityType: EntityType.Resource,
                    Target: '.resource-capacity-top-hover',
                });
                // hack to prevent element losing event when re-rendering
                setTimeout(() => {
                    (document.querySelector('.resource-capacity-top-hover') as HTMLElement).addEventListener('mouseleave', OnResourceHoverLeave as any);
                }, 10);
            },
            1000,
        );
    };

    const OnResourceHoverLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        clearTimeout(_restimer);
        _restimer = null;
        setDetailDto(null);
        (document.querySelector('.resource-capacity-top-hover') as HTMLElement).removeEventListener('mouseleave', OnResourceHoverLeave as any);
    };

    const addRow = async (projectId: string, projectName: string) => {
        const contractId = guid.newGuid();
        const response = await ApiCalls.getNewResourceRow({
            projectId: projectId,
            gridType: GridType.ResourceCapacity,
            resourceId: resource.id,
            dateResolution: timeline.resolution,
            start: timeline.start,
            end: timeline.end,
            contractId: contractId,
        });
        // fixing legacy crap (ewi)
        const newRow = upperCaseObj(response.data);
        newRow.Id = contractId;
        newRow.Properties.ContractName = resource.Name;

        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);
        let group = flattenedGroups.find(_ => _.Id === projectId);
        if (!group) {
            group = new GroupRow(projectId, projectName);
            group.CellRows = deepCopy(view.CellRows);
            group.Properties = { ProjectId: projectId };
            group.CellRows.forEach(_ =>
                _.Cells.forEach(_ => {
                    CellEx.Update(_, 0);
                    _.Type = CellType.Group;
                    _.Properties = { ProjectId: projectId };
                }),
            );
            (viewRef.current.Groups[1] as GroupContainerRow).Groups.push(group);
        }
        group.Rows.push(newRow as Row);
        _setView({ ...viewRef.current });
    };

    return (
        <>
            {view &&
                (loading ? (
                    <PFSpinner />
                ) : (
                    <>
                        <div
                            onKeyDown={e => {
                                if (e.keyCode === 90 && e.ctrlKey) Undo();
                            }}
                            tabIndex={-1}
                            style={{ outline: 'unset' }}
                        >
                            <Table
                                Name={view.Properties.ResourceName}
                                Model={view}
                                ContainerClassName="resource-capacity"
                                CellUpdates={[
                                    (cell: Cell, columnIndex: number, value: number, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                        const updatedModel = OnCellUpdate(columnIndex, 0, cell, value);
                                        setView({ ...updatedModel });
                                    },
                                ]}
                                TitleContentOverride={(title: string) => (
                                    <>
                                        <div className="resource-capacity-top-hover" onMouseEnter={OnResourceHover} onMouseLeave={OnResourceHoverLeave}>
                                            <Text className="tp-capacity-project-title">
                                                <div className="truncate-text">{title}</div>
                                            </Text>
                                            {resource?.id === uiSettings.resource.id && !!uiSettings.settings.resourcesCanAddAllocations?.parts.length && (
                                                <ActionButton
                                                    iconProps={{ iconName: 'Add' }}
                                                    className="tp-capacity-project-addbutton"
                                                    onClick={() =>
                                                        addProject((projectId: string, projectName: string) => {
                                                            // add row
                                                            addRow(projectId, projectName);
                                                            // close modal
                                                            setBlockDialog(null);
                                                        })
                                                    }
                                                >
                                                    {language.ResourceCapacity.AddMyselfToProjectButton}
                                                </ActionButton>
                                            )}
                                        </div>
                                    </>
                                )}
                                TopCellStyles={[
                                    (cell: Cell) => {
                                        return { root: { paddingRight: '10px' } } as IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
                                    },
                                ]}
                                GroupCellStyles={[
                                    (cell: Cell) => {
                                        return { root: { paddingRight: '10px' } } as IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
                                    },
                                ]}
                                GroupContainerCellStyles={[
                                    (cell: Cell) => {
                                        return {
                                            field: { backgroundColor: cell.Properties.FreeCap ? `${GetFreeCapacityColor(cell.Value)} !important` : '' },
                                            root: { paddingRight: '10px' },
                                        } as IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
                                    },
                                ]}
                                RowCellStyles={[
                                    (cell: Cell) => {
                                        return { root: { paddingRight: '10px', cursor: 'unset' } } as IStyleFunctionOrObject<
                                            ITextFieldStyleProps,
                                            ITextFieldStyles
                                        >;
                                    },
                                ]}
                                GroupExtraContent={(group: GroupRow) => (
                                    <>
                                        <div
                                            id={`resource-capacity-group-hover-${group.Id}`}
                                            className="resource-capacity-group-hover"
                                            onMouseEnter={e => OnProjectHover(group.Id)}
                                            onMouseLeave={OnProjectHoverLeave}
                                        ></div>
                                    </>
                                )}
                                GroupClick={(row: GroupRow) => {
                                    if (row.Properties.CanGoToProject) {
                                        OnProjectHoverLeave();
                                        navigate(`/allocation/${row.Id}`);
                                    }
                                }}
                                GroupClass={(group: GroupRow) => (group.Properties.CanGoToProject ? '' : 'header-click-disabled')}
                                GridCellDecimals={ProjectCapacityService.GetGridDecimals()}
                                DragHighlighterUpdate={DragUpdates()}
                                PasteFromExcel={OverviewContext.Settings.EnableCopyFromExcel && ExcelCopyUpdate}
                                GridSettingsSpacingAmount={OverviewContext.Settings.GridSpacing}
                                GridSettingsHidePersonaIcons={OverviewContext.Settings.HidePersonaIconsInGrid}
                                SetGridSettingsHidePersonaIconsCallback={value =>
                                    UserSettingsService.UpdateUserSettingsPartial({ HidePersonaIconsInGrid: value }, null, true)
                                }
                                SetGridSettingsSpacingAmountCallback={value =>
                                    UserSettingsService.UpdateUserSettingsPartial({ GridSpacing: value }, null, true)
                                }
                                EnableGridSettings={OverviewContext.Settings.EnableGridSettings}
                            />
                        </div>
                        <EntityInformationCallout Dto={detailDto} />
                    </>
                ))}
        </>
    );
};

export default ResourceCapacity;
