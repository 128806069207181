import { JsonEx } from '../Utilities/JsonEx';
import { runPersistGetters } from './helpers';
import { IStore } from './store';
import { RecursivePartial } from './types';

type StorageItem = RecursivePartial<{ state: Partial<IStore>; version: number }>;

/**
 * 
 * @param getters get run in the order they are declared, thus making it possible to use state from a previous
 * @returns a StateStorage object
 */
export const getStorage =
    (
        ...getters: (
            | ((store: RecursivePartial<IStore>) => Promise<RecursivePartial<IStore>>)
            | ((store: RecursivePartial<IStore>) => RecursivePartial<IStore>)
        )[]
    ) =>
    () => {
        return {
            getItem: async (item: string) => {
                try {
                    const localStorageItem = window.localStorage.getItem(item);
                    const storageVal = localStorageItem
                        ? JsonEx.parse<StorageItem>(localStorageItem)
                        : ({ state: {} } as RecursivePartial<StorageItem>);

                    const storedState = {
                        state: {
                            //
                            ...storageVal.state,
                            ...(await runPersistGetters(storageVal.state, ...getters)),
                        },
                    };

                    return JsonEx.stringify(storedState);
                } catch (error) {
                    console.log('Local storage hydration error', error);
					return window.localStorage.getItem(item)
                }
            },
            setItem: (item: string, value: string) => {
                window.localStorage.setItem(item, value);
            },
            removeItem: (name: string) => {
                window.localStorage.removeItem(name);
            },
        };
    };
