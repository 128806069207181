import React, { FunctionComponent, useEffect, useState } from 'react';
import { TextField, Checkbox, DatePicker, DayOfWeek, Dropdown, IDropdownOption, Label } from '@fluentui/react';
import { CustomPropertyType } from '../Entities/ContentConfiguration/CustomPropertyType';
import { OverviewContext } from './Context/OverviewContext';
import { LookupType } from '../Entities/ContentConfiguration/Lookup/LookupType';
import { DropdownOptionEx } from './DropdownOptionEx';
import { CustomProperty } from '../Entities/ContentConfiguration/CustomProperty';
import { OriginService } from '../Services/OriginService';
import { LookupProject } from '../Entities/ContentConfiguration/Lookup/LookupValueTypes/LookupProject';
import { LookupResource } from '../Entities/ContentConfiguration/Lookup/LookupValueTypes/LookupResource';
import { LookupRbs } from '../Entities/ContentConfiguration/Lookup/LookupValueTypes/LookupRbs';
import { ContentConfigurationService } from '../Services/ContentConfigurationService';
import { ResourceType } from '../Entities/ResourceType';
import { ArrayEx } from './ArrayEx';
import ProjectPicker from '../Components/Common/DynamicLoaded/ProjectPicker';
import ResourcePicker from '../Components/Common/DynamicLoaded/ResourcePicker';
import { TreeEditor } from '../forms/_builders/tpComplexBuilder/components/tree/components/TreeEditor';
import { ITreeNode } from '../forms/_builders/tpComplexBuilder/components/tree/interfaces/ITreeNode';
import { treeViewRefactor } from './TreeViewRefactor';
import ResourcePickerWithMeFilter from '../Components/Common/DynamicLoaded/ResourcePickerWithMeFilter';

interface IProps {
    Item: any;
    Property: CustomProperty;
    Update: (value: any) => void;
    Key?: string;
    Type: 'resource' | 'project' | 'contract';
    Parent?: any;
    ReadOnly?: boolean;
    Required?: boolean;
    HideLabel?: boolean;
    OverrideDisabledProps?: boolean;
}

// used by BulkEditor.tsx:5:34 CustomPropertiesSelector.tsx:4:34 EntityPropertyFilter.tsx:18:34
export const CustomPropertyRender: FunctionComponent<IProps> = props => {
    const [element, setElement] = useState<JSX.Element>(null);

    useEffect(() => {
        const getElement = async () => {
            const el = await GetElement();
            setElement(el);
        };
        getElement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.Item]);

    const OnChange = (value: any) => {
        props.Update(value);
    };

    const OnChangeMultiSelect = (option: IDropdownOption): void => {
        const copy = props.Item != null ? props.Item : [];
        if (option.selected) copy.push(option.key);
        else {
            const currIndex = copy.indexOf(option.key);
            if (currIndex > -1) {
                copy.splice(currIndex, 1);
            }
        }
        OnChange(copy);
    };

    const IsLocked = (fieldId: string): boolean => {
        if (props.OverrideDisabledProps) return false;

        let disallowedProps = [];
        let genResDefinitionLocks = [];

        switch (props.Type) {
            case 'project':
                disallowedProps = OriginService.GetDisallowedProjectProperties();
                break;
            case 'resource':
                disallowedProps = OriginService.GetDisallowedResourceProperties();
                genResDefinitionLocks = LockedGenericResourceUpdateProperties();
                break;
            case 'contract':
            default:
                break;
        }

        const locks = ArrayEx.mergeDistinct(genResDefinitionLocks, disallowedProps, _ => _);

        if (!locks || locks.length === 0) return false;
        return locks.some(_ => _.includes(fieldId));
    };

    const LockedGenericResourceUpdateProperties = (): Array<string> => {
        // guards (only generic resource updates, needs to restrict the custom properties used in the gen res definition)
        if (props.Type !== 'resource') return null;
        if (props.Parent.ResourceType !== ResourceType.Generic) return null;
        if (!props.Parent.Created) return null;
        // allowed by setting to edit gen res custom props
        if (OverviewContext.Settings.AllowChangeRbsAndCustomPropertiesOnGenericResource) return null;
        return OverviewContext.Settings.GenericResourceDefinition;
    };

    const GetElement = async (): Promise<JSX.Element> => {
        if (!props.Property) return null;
        const readOnly = props.ReadOnly || IsLocked(props.Property.Id);
        if (props.Property.Type === CustomPropertyType.String) {
            return (
                <TextField
                    key={`customproperties-${props.Property.InternalName}`}
                    label={props.HideLabel ? null : props.Property.DisplayName}
                    type={'text'}
                    disabled={readOnly}
                    required={props.Property.Required && !props.HideLabel}
                    defaultValue={props.Item as string}
                    onChange={(e, value) => OnChange(value)}
                />
            );
        } else if (props.Property.Type === CustomPropertyType.Number) {
            return (
                <TextField
                    key={`customproperties-${props.Property.InternalName}`}
                    label={props.HideLabel ? null : props.Property.DisplayName}
                    type={'number'}
                    disabled={readOnly}
                    onKeyDown={event => {
                        if (event.keyCode === 69) event.preventDefault();
                    }} // hack to disable 'e' in number fields
                    required={props.Property.Required && !props.HideLabel}
                    defaultValue={props.Item as string}
                    onChange={(e, value) => OnChange(+value)}
                />
            );
        } else if (props.Property.Type === CustomPropertyType.Boolean) {
            return (
                <Checkbox
                    key={`customproperties-${props.Property.InternalName}`}
                    label={props.HideLabel ? null : props.Property.DisplayName}
                    onChange={(obj, value) => OnChange(value)}
                    defaultChecked={props.Item as boolean}
                    disabled={readOnly}
                />
            );
        } else if (props.Property.Type === CustomPropertyType.Date) {
            return (
                <DatePicker
                    key={`customproperties-${props.Property.InternalName}`}
                    label={props.HideLabel ? null : props.Property.DisplayName}
                    firstDayOfWeek={DayOfWeek.Monday}
                    placeholder="Select a date..."
                    showWeekNumbers={true}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay={true}
                    allowTextInput={true}
                    value={props.Item as Date}
                    onSelectDate={date => OnChange(date)}
                    disabled={readOnly}
                    isRequired={props.Property.Required && !props.HideLabel}
                />
            );
        } else if (props.Property.Type === CustomPropertyType.Choice) {
            // TODO: combine multi and singleselect
            const lookup = (await OverviewContext.GetLookups()).find(l => l.id === props.Property.Lookup);
            if (!lookup) return null;

            if (lookup.LookupType === LookupType.Number || lookup.LookupType === LookupType.String) {
                return (
                    <Dropdown
                        key={`customproperties-${props.Property.InternalName}`}
                        label={props.HideLabel ? null : props.Property.DisplayName}
                        disabled={readOnly}
                        required={props.Property.Required && !props.HideLabel}
                        options={DropdownOptionEx.toDropdownOptionsAddEmptyEntry(
                            lookup.Options,
                            _ => _.Id,
                            _ => (_.Value ? _.Value.toString() : null),
                        )}
                        defaultSelectedKey={props.Item as string}
                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => OnChange(option.key)}
                    />
                );
            } else if (lookup.LookupType === LookupType.Tree) {
                return (
                    <>
                        {!props.HideLabel && <Label>{props.Property.DisplayName}</Label>}
                        <TreeEditor
                            required={props.Property.Required && !props.HideLabel}
                            readOnly={readOnly}
                            multiSelect={false}
                            type={'select'}
                            tree={treeViewRefactor.parseToTreeNode(lookup.Options[0].Value.Tree)}
                            selected={props.Item}
                            onChanged={value => (typeof value === 'object' ? OnChange(treeViewRefactor.parseToTreeView(value as ITreeNode)) : OnChange(value))}
                            dialogTitle="Select"
                            buttonTitle="Select"
                            defaultExpandedDepth={lookup.Options[0].Value.DefaultExpandedDepth}
                        />
                    </>
                );
            } else if (lookup.LookupType === LookupType.Project) {
                const projLookup = lookup.Options[0].Value as LookupProject;
                return (
                    <>
                        {!props.HideLabel && <Label>{props.Property.DisplayName}</Label>}
                        <ProjectPicker
                            Required={props.Property.Required && !props.HideLabel}
                            ReadOnly={readOnly}
                            IncludeInActive={projLookup.IncludeInactive}
                            Item={props.Item}
                            Update={value => OnChange(value)}
                            MultiSelect={false}
                        />
                    </>
                );
            } else if (lookup.LookupType === LookupType.Resource) {
                const resLookup = lookup.Options[0].Value as LookupResource;
                return (
                    <>
                        {!props.HideLabel && <Label>{props.Property.DisplayName}</Label>}
                        <ResourcePickerWithMeFilter
                            Required={props.Property.Required && !props.HideLabel}
                            ReadOnly={readOnly}
                            ExcludeInactive={!resLookup.IncludeInactive}
                            Types={resLookup.ResourceTypes}
                            Item={props.Item}
                            Update={value => OnChange(value)}
                            MultiSelect={false}
                        />
                    </>
                );
            } else if (lookup.LookupType === LookupType.Rbs) {
                const rbsLookup = lookup.Options[0].Value as LookupRbs;
                const rbs = await ContentConfigurationService.GetRbsStructure(rbsLookup.RbsId);
                return (
                    <>
                        {!props.HideLabel && <Label>{props.Property.DisplayName}</Label>}
                        <TreeEditor
                            required={props.Property.Required && !props.HideLabel}
                            readOnly={readOnly}
                            type="select"
                            multiSelect={false}
                            tree={treeViewRefactor.parseToTreeNode(rbs.Rbs)}
                            selected={props.Item}
                            onChanged={value => (typeof value === 'object' ? OnChange(treeViewRefactor.parseToTreeView(value as ITreeNode)) : OnChange(value))}
                        />
                    </>
                );
            } else return <></>;
        } else if (props.Property.Type === CustomPropertyType.ChoiceMultiselect) {
            const lookup = (await OverviewContext.GetLookups()).find(l => l.id === props.Property.Lookup);
            if (!lookup) return null;

            if (lookup.LookupType === LookupType.Number || lookup.LookupType === LookupType.String) {
                return (
                    <Dropdown
                        key={`customproperties-${props.Property.InternalName}`}
                        label={props.HideLabel ? null : props.Property.DisplayName}
                        multiSelect
                        multiSelectDelimiter=" | "
                        disabled={props.ReadOnly || IsLocked(props.Property.Id)}
                        required={props.Property.Required && !props.HideLabel}
                        options={DropdownOptionEx.toDropdownOptions(
                            lookup.Options,
                            _ => _.Id,
                            _ => _.Value.toString(),
                        )}
                        defaultSelectedKeys={props.Item as Array<string>}
                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => OnChangeMultiSelect(option)}
                    />
                );
            } else if (lookup.LookupType === LookupType.Tree) {
                return (
                    <>
                        {!props.HideLabel && <Label>{props.Property.DisplayName}</Label>}
                        <TreeEditor
                            required={props.Property.Required && !props.HideLabel}
                            readOnly={readOnly}
                            multiSelect={true}
                            type={'select'}
                            tree={treeViewRefactor.parseToTreeNode(lookup.Options[0].Value.Tree)}
                            selected={props.Item}
                            onChanged={value => (typeof value === 'object' ? OnChange(treeViewRefactor.parseToTreeView(value as ITreeNode)) : OnChange(value))}
                            dialogTitle="Select"
                            buttonTitle="Select"
                        />
                    </>
                );
            } else if (lookup.LookupType === LookupType.Project) {
                const projLookup = lookup.Options[0].Value as LookupProject;
                return (
                    <>
                        {!props.HideLabel && <Label>{props.Property.DisplayName}</Label>}
                        <ProjectPicker
                            Required={props.Property.Required && !props.HideLabel}
                            ReadOnly={readOnly}
                            IncludeInActive={projLookup.IncludeInactive}
                            Item={props.Item}
                            Update={value => OnChange(value)}
                            MultiSelect={true}
                        />
                    </>
                );
            } else if (lookup.LookupType === LookupType.Resource) {
                const resLookup = lookup.Options[0].Value as LookupResource;
                return (
                    <>
                        {!props.HideLabel && <Label>{props.Property.DisplayName}</Label>}
                        <ResourcePicker
                            Required={props.Property.Required && !props.HideLabel}
                            ReadOnly={readOnly}
                            Types={resLookup.ResourceTypes}
                            Item={props.Item}
                            Update={value => OnChange(value)}
                            MultiSelect={true}
                        />
                    </>
                );
            } else if (lookup.LookupType === LookupType.Rbs) {
                const rbsLookup = lookup.Options[0].Value as LookupRbs;
                const rbs = await ContentConfigurationService.GetRbsStructure(rbsLookup.RbsId);
                return (
                    <>
                        {!props.HideLabel && <Label>{props.Property.DisplayName}</Label>}
                        <TreeEditor
                            required={props.Property.Required && !props.HideLabel}
                            readOnly={readOnly}
                            type="select"
                            multiSelect={true}
                            tree={treeViewRefactor.parseToTreeNode(rbs?.Rbs)}
                            selected={props.Item}
                            onChanged={value =>
                                typeof value === 'object' && !Array.isArray(value)
                                    ? OnChange(treeViewRefactor.parseToTreeView(value as ITreeNode))
                                    : OnChange(value)
                            }
                        />
                    </>
                );
            } else return <></>;
        }
    };

    return element;
};

export default CustomPropertyRender;
