import { DateEx } from "./DateEx";
import { HeaderRow } from "../Entities/Table/HeaderRow";

export class HeaderUtil {
	public static HeaderStyle = (header: HeaderRow) : string => {
		return `tp-table-header noselect ${HeaderUtil.GetClass(header)}`
	}

	private static GetClass = (header: HeaderRow) : string => {
		// temp hack for defining total header column
		if (header.Start == null || header.Start as any === "0001-01-01T00:00:00") return "";
		if (DateEx.equals(header.Start, header.End) && DateEx.isWeekend(header.Start)) return "weekend";
		return "";
	}
}