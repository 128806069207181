import { useDroppable, useDraggable } from '@dnd-kit/core';
import { Stack, IconButton, TextField, Checkbox, ITextFieldProps } from '@fluentui/react';
import { ITreeNode, useTreeContext } from '@pum-spark/tree-render';
import { useCallback, useMemo, useState } from 'react';
import { guid } from '../../helpers/guid';
import { useFilterTextContext } from './FilterTextContext';
import { useTheme } from '../../Themes/themeContext';

export const SortableTreeNode = ({ node }: { node: ITreeNode; isRoot?: boolean }) => {
    const { updateNode, toggleSelect, mode, toggleExpanded, isExpanded, isNodeSelected, removeNode, addNode, pathDelimiter, isNodeDisabled } = useTreeContext();

    const [showCommands, setShowCommands] = useState(false);

    const expanded = isExpanded(node);
    const selected = isNodeSelected(node);
    const disabled = isNodeDisabled(node);

    const {
        attributes,
        listeners,
        setNodeRef: dragRef,
        transform,
        isDragging,
    } = useDraggable({
        id: node.id,
    });

    const dragStyle = useMemo(
        () =>
            transform
                ? {
                      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
                  }
                : undefined,
        [transform],
    );

    const { isOver, setNodeRef: dropRef } = useDroppable({
        id: node.id,
    });

    const dropStyle = useMemo(
        () => ({
            backgroundColor: isOver ? 'lightgrey' : undefined,
        }),
        [isOver],
    );

    const onMouseEnter = useCallback(() => {
        setShowCommands(true);
    }, []);

    const onMouseLeave = useCallback(() => {
        setShowCommands(false);
    }, []);

    const filterText = useFilterTextContext();

    const theme = useTheme();
    const inputStyles = useMemo((): ITextFieldProps['styles'] => {
        const matchesText = filterText && node.name.toLowerCase().includes(filterText.toLowerCase());
        let border = 'unset';
        let backgroundColor = 'white';
        if (matchesText) {
            backgroundColor = theme.palette.yellowLight;
        } else if (disabled) {
            backgroundColor = 'rgb(243, 242, 241)';
        }
        if (mode !== 'edit' || disabled) {
            border = 'solid 1px';
        }
        return {
            root: { border, width: '250px' },
            field: { backgroundColor, color: 'unset' },
        };
    }, [disabled, filterText, mode, node.name, theme.palette.yellowLight]);

    return useMemo(() => {
        return (
            <div
                ref={dropRef}
                style={{
                    ...dropStyle,
                    opacity: isDragging ? 0.5 : 1,
                    // width: '315px',
                    paddingBottom: '2px',
                    paddingTop: '2px',
                    paddingLeft: !node.children?.length ? '32px' : 'unset',
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <Stack horizontal>
                    {!!node.children?.length && (
                        <IconButton
                            iconProps={{ iconName: expanded ? 'ChevronDown' : 'ChevronRight' }}
                            className="tp-grouping-header-icon"
                            onClick={() => toggleExpanded(node)}
                        />
                    )}
                    {mode !== 'edit' && (
                        <Checkbox disabled={disabled} checked={selected} onChange={() => toggleSelect(node)} styles={{ root: { paddingTop: '6px' } }} />
                    )}
                    <div>
                        <TextField
                            defaultValue={node.name}
                            disabled={mode !== 'edit' || disabled}
                            styles={inputStyles}
                            onChange={(ev, value) => {
                                if (value !== undefined) {
                                    updateNode({ ...node, name: value });
                                }
                            }}
                            title={node.path}
                        />
                    </div>
                    {mode === 'edit' && !disabled && (
                        <div style={{ visibility: showCommands ? 'visible' : 'collapse' }}>
                            <Stack horizontal>
                                <div ref={dragRef} {...attributes} {...listeners} style={dragStyle}>
                                    <IconButton iconProps={{ iconName: 'Move' }} title="Drag to move" style={{ cursor: 'grab' }} />
                                </div>
                                <IconButton iconProps={{ iconName: 'Delete' }} title="Delete node" onClick={() => removeNode(node)} />
                                <IconButton
                                    iconProps={{ iconName: 'Add' }}
                                    title="Add new node"
                                    onClick={() =>
                                        addNode(
                                            {
                                                id: guid.newGuid(),
                                                name: `New node ${(node.children?.length ?? 0) + 1}`,
                                                path: `${node.path}${pathDelimiter}New node ${(node.children?.length ?? 0) + 1}`,
                                            } as ITreeNode,
                                            node,
                                        )
                                    }
                                />
                            </Stack>
                        </div>
                    )}
                </Stack>
            </div>
        );
    }, [
        addNode,
        attributes,
        disabled,
        dragRef,
        dragStyle,
        dropRef,
        dropStyle,
        expanded,
        pathDelimiter,
        isDragging,
        listeners,
        mode,
        node,
        onMouseEnter,
        onMouseLeave,
        removeNode,
        selected,
        showCommands,
        toggleExpanded,
        toggleSelect,
        updateNode,
        inputStyles,
    ]);
};
