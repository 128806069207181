export default class Locale_MailServiceConfig {
	Service: string = "Mail service";
	GraphWarning: string = "Remember to add the 'Send Mail' application permission to the Azure AAD App Registration!!";
	SmtpServer: string = "Smtp server";
	UserName: string = "Username";
	Password: string = "Password";
	Port: string = "Port";
	ApiKey: string = "Api key";
	MaxRequestsPrSecond: string = "Max request pr. second";
	SenderEmail: string = "Sender email";
	SenderName: string = "Sender name";
	DebugLoginName: string = "Debug login name";

	ServiceDescription: string = "Choose the service to use for email notifications";
	GraphWarningDescription: string = "You need an Azure Global Admin to do this";
	SmtpServerDescription: string = "The url of the smtp server (or the MX record, if using 'Direct Send')";
	UserNameDescription: string = "The smtp user";
	PasswordDescription: string = "The password for the smtp user";
	PortDescription: string = "The port for the smtp";
	ApiKeyDescription: string = "The Api Key for the 3. party email service";
	MaxRequestsPrSecondDescription: string = "This can be set to prevent throttling issues (defaults to 5)";
	SenderEmailDescription: string = "The email address used for sending out notification emails";
	SenderNameDescription: string = "The name used for sending out notification emails";
	DebugLoginNameDescription: string = "The login name of the user that will receive the debug 'mails' written to browser console";

	ButtonText: string = "Configure mail service";
	DialogTitle: string = "Configure mail service";
	DialogSaveButtonText: string = "Save";
}