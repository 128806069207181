import { ReactElement } from 'react';
import { IFormItem, IFieldConfig, IFieldRenderProps } from 'spark-forms';
import { FluentBuilder } from 'spark-forms-fluent8';
import { ITpFieldTypes, tpFieldTypes } from './tpFieldTypes';
import { RbsField } from './Fields/RbsField';
import { ResourceField } from './Fields/ResourceField';
import { ProjectField } from './Fields/ProjectField';
import { JsonField } from './Fields/JsonField';
import { MessagebarField } from './Fields/MessagebarField';
import { DateWithTodayFilterField } from './components/DateWithTodayFilterField';
import { AadGroupField } from './Fields/AadGroupField';
import { RichTextField } from './Fields/RichTextField';
// import { TreeField } from './Fields/TreeField';
import { TreeField } from './Fields/TreeFieldNew';
import { HyperlinkField } from './Fields/HyperlinkField';
import { ResourceWithMeFilterField } from './components/ResourceWithMeFilterField';
import { PredefinedArrayFilterField } from './components/PredefinedArrayFilterField';
import { RbsWithMyRbsFilterField } from './Fields/RbsWithMyRbsFilterField';

export const TPBuilder = <T extends IFormItem, C extends IFieldConfig<T>>({
    disabled,
    options,
    path,
    fieldName,
    value,
    validationMark
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
IFieldRenderProps<T, C, any>): ReactElement | null => {

    const props = {
        disabled,
        options,
        path,
        fieldName,
        value,
        validationMark,
        ...options
    };

	// extending the default propertypes that ships with the formbuilder
	const fieldType: ITpFieldTypes = tpFieldTypes;

    switch (options.fieldType) {
        case fieldType.rbs:
            return <RbsField {...props} />;
		case fieldType.rbsWithMyRbsFilter:
			return <RbsWithMyRbsFilterField {...props} />;
		case fieldType.resource:
			return <ResourceField {...props} />;
		case fieldType.resourceWithMeFilter:
			return <ResourceWithMeFilterField {...props} />;
		case fieldType.project:
            return <ProjectField {...props} />;
		case fieldType.json:
            return <JsonField {...props} />;
		case fieldType.messageBar:
			return <MessagebarField {...props} />;
		case fieldType.dateWithTodayFilter:
			return <DateWithTodayFilterField {...props} />;
		case fieldType.aadGroup:
			return <AadGroupField {...props} />;
		case fieldType.richText:
			return <RichTextField {...props} />;
		case fieldType.tree:
			return <TreeField {...props} />;
		case fieldType.hyperlink:
			return <HyperlinkField {...props} />;
		case fieldType.predefinedArrayFilter:
			return <PredefinedArrayFilterField {...props} />
        default: {
            return <FluentBuilder {...props} />;
        }
    }
};
