import { FunctionComponent, useEffect, useState } from "react";
import { CountDownDialog } from "../../../Components/Common/CountDownDialog";
import { OverviewContext } from "../../../Utilities/Context/OverviewContext";
import { MoveAllActivitiesDialog } from "./_components/moveActivities/MoveAllActivitiesDialog";

interface IProps {
	moveActivitiesInfo: IMoveResourceActivitiesInfo;
}

export interface IMoveResourceActivitiesInfo {
	resourceId: string;
	type: "request" | "allocation" | "both";
}

export const ResourceOverviewExtra: FunctionComponent<IProps> = props => {

	const [moveActivitiesInfo, setMoveActivitiesInfo] = useState<IMoveResourceActivitiesInfo>(props.moveActivitiesInfo);

	useEffect(() => {
		setMoveActivitiesInfo(props.moveActivitiesInfo);
	}, [props.moveActivitiesInfo])
	
	return (
		<>
			{
				OverviewContext.Settings.EnableMoveAllAllocations && (
				<MoveAllActivitiesDialog
					info={moveActivitiesInfo}
					clear={() => setMoveActivitiesInfo(null)}
				/>
			)}
			<CountDownDialog />
		</>
	)
}