import { IColumn } from "@fluentui/react";
import { ResourceWithStringVariants } from "../../../api/generated/data-contracts";
import { resourceSchema } from "../../../forms/resource/resourceSchema";
import { ArrayEx } from "../../../Utilities/ArrayEx";
import { ListColumnEnhanced } from "../../../_components/OverviewCreator/types/ListColumnEnhanced";
import { generateOverviewColums } from "../_helpers/generateOverviewColums";

export const generateResourceOverviewColumns = () : IColumn[] => {
	return generateOverviewColums('resource', resourceSchema, extraColumnOptions);
}

const extraColumnOptions: { [field: string] : Partial<ListColumnEnhanced<ResourceWithStringVariants>> } = {
	active: {
		minWidth: 100,
		onRender: item => item.active?.toString()
	},
	originIds: {
		onRender: item => ArrayEx.joinToString(Object.values(item.originIds), ",")
	}
}