import { Icon, Text } from '@fluentui/react';
import { useTheme } from '../../../../../../Themes/themeContext';
import { GridHeaderRow } from '../../../../../../api/generated/data-contracts';
import { atom, useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { useGridContext } from '../../../../_grid/contexts/gridContext';
import { createCellValueGetter } from '../../../../_grid/useGrid';

type HeaderProps = { header: GridHeaderRow; isCustom?: boolean; index: number };
export const Header = ({ header, isCustom, index }: HeaderProps) => {
    const { name } = header;
    const id = name;
    const theme = useTheme();
    const { setRootRows } = useGridContext();
    const onClick = useRecoilCallback(
        ({ snapshot, set }) =>
            async () => {
                const currentSortedValue = await snapshot.getPromise(currentSortedByColumn);
                let order: SortOrder = 'asc';
                if (currentSortedValue?.id === id) {
                    order = currentSortedValue.order === 'desc' ? 'asc' : 'desc';
                }
                await setRootRows(async rows => {
                    const getCellValue = createCellValueGetter(snapshot);
                    const columnValues = await Promise.all(
                        rows.map(async row => {
                            const cell = row.columnRows[index].cells[0];
                            const value = await getCellValue(cell);
                            return {
                                value,
                                row,
                            };
                        }),
                    );
                    columnValues.sort((a, b) => {
                        if (order === 'asc') {
                            return a.value.minus(b.value).toNumber();
                        }
                        return b.value.minus(a.value).toNumber();
                    });
                    return columnValues.map(({ row }) => row);
                });
                set(currentSortedByColumn, { id, isCustom, order });
            },
        [id, index, isCustom, setRootRows],
    );
    return (
        <th className="tp-table-header-total" onClick={onClick}>
            <Text className="tp-table-header-topic noselect" styles={{ root: { color: theme.semanticColors.bodyText } }}>
                {name}
                <ColumnSortIcon id={id} />
            </Text>
        </th>
    );
};

export type SortOrder = 'desc' | 'asc';
export type CurrentSortedByColumnState = { id: string; order: SortOrder; isCustom: boolean } | null;

const currentSortedByColumn = atom<CurrentSortedByColumnState>({
    key: 'resource-planner-column-sort',
    default: null,
});

// eslint-disable-next-line react-refresh/only-export-components
export const useSortedByColumnState = () => useRecoilState(currentSortedByColumn);

const ColumnSortIcon = ({ id }: { id: string }) => {
    const currentColumn = useRecoilValue(currentSortedByColumn);
    const currentColumnId = currentColumn?.id;
    if (!currentColumn || currentColumnId !== id) {
        return null;
    }
    return <Icon iconName={currentColumn.order === 'asc' ? 'SortUp' : 'SortDown'} />;
};
