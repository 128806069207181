import { AnimationClassNames, CommandBarButton, IButtonStyles, Icon, IContextualMenuProps, ILayerProps, Stack, Text } from '@fluentui/react';
import { ColoredTotalColumnRow } from "../ColoredTotalColumnRow";
import { TotalColumnRow } from "../TotalColumnRow";
import { SubHeader } from "../SubHeader";
import { ColumnRow } from "../ColumnRow";
import { ProjectType } from '../../../../../../../Entities/ProjectType';
import { ProjectCoinIcon } from './components/ProjectCoinIcon';
import { ResourceCoinIcon } from './components/ResourceCoinIcon';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { buildRoute } from '../../../../../../../helpers/routeHelpers';
// import { RowWrapperProps } from '../../../../../_grid/components/RowWrapper';
import { GridRow } from '../../../../../../../api/generated/data-contracts';
import { useIsDragging } from '../../../../../../../context/drag';
import { useGridContext } from '../../../../../_grid/contexts/gridContext';
import { preventDefault } from '../../../../../../../helpers/killEvent';
import { useUISettings } from '../../../../../../../context/network/http/QueryProvider/queries/UISettings';
import { ExpandedState } from '../../../../../_grid/useGrid';

type RowWrapperProps = {
    row: GridRow;
	rowIndex: number;
    setExpandedRows: React.Dispatch<React.SetStateAction<ExpandedState>>;
    visible: boolean;
    expanded: boolean;
    getRowAncestorTreeToRoot: (rowId: string) => GridRow[];
    getSubRows: (rowId: string) => GridRow[];
    // rowIndex: number;
    rowComponent: (props: RowProps) => JSX.Element;
    nestingLevel: number;
	maxGridDepth: number;
};

type RowProps = Omit<RowWrapperProps, 'rowComponent' | 'rowIndex'> & {
    toggleExpanded: (toggle?: boolean) => void;
    canExpand: boolean;
    nestingLevel: number;
    // ancestorTree: RowWithRelations[];
    subRows: GridRow[];
    rowRelations: GridRow[];
};

export const Row = ({
    row,
    setExpandedRows,
    visible,
    getRowAncestorTreeToRoot,
    getSubRows,
    expanded,
    nestingLevel,
    canExpand,
    subRows,
    toggleExpanded,
    rowRelations,
    maxGridDepth,
}: RowProps) => {
    const isProject = row.metadata?.ProjectType !== undefined;
    const isAdminProject = useMemo(() => {
        if (rowRelations.some(row => row.metadata?.ProjectType === ProjectType.Administrative)) {
            return true;
        }
        if (subRows.some(row => row.metadata?.ProjectType === ProjectType.Administrative)) {
            return true;
        }
        if (!isProject) {
            return false;
        }
        if (row.metadata?.ProjectType === ProjectType.Administrative) {
            return true;
        }
        return false;
    }, [isProject, row.metadata?.ProjectType, rowRelations, subRows]);
    const isResource = row.metadata?.ResourceType !== undefined;

	if (isResource) {
        return (
            <ResourceRow
                row={row}
                setExpandedRows={setExpandedRows}
                visible={visible}
                getRowAncestorTreeToRoot={getRowAncestorTreeToRoot}
                getSubRows={getSubRows}
                expanded={expanded}
                nestingLevel={nestingLevel}
                canExpand={canExpand}
                subRows={subRows}
                toggleExpanded={toggleExpanded}
                rowRelations={rowRelations}
                maxGridDepth={maxGridDepth}
                // isAdminProject={isAdminProject}
            />
        );
    }

    if (isProject) {
        return (
            <ProjectRow
                row={row}
                setExpandedRows={setExpandedRows}
                visible={visible}
                getRowAncestorTreeToRoot={getRowAncestorTreeToRoot}
                getSubRows={getSubRows}
                expanded={expanded}
                nestingLevel={nestingLevel}
                canExpand={canExpand}
                subRows={subRows}
                toggleExpanded={toggleExpanded}
                rowRelations={rowRelations}
                maxGridDepth={maxGridDepth}
                isAdminProject={isAdminProject}
            />
        );
    }

    return (
        <ContractRow
            row={row}
            setExpandedRows={setExpandedRows}
            visible={visible}
            getRowAncestorTreeToRoot={getRowAncestorTreeToRoot}
            getSubRows={getSubRows}
            expanded={expanded}
            nestingLevel={nestingLevel}
            canExpand={canExpand}
            subRows={subRows}
            toggleExpanded={toggleExpanded}
            rowRelations={rowRelations}
            maxGridDepth={maxGridDepth}
            isAdminProject={isAdminProject}
        />
    );
};

const ResourceRow = ({ visible, row, canExpand, nestingLevel, expanded, toggleExpanded }: RowProps ) => {
    const navigate = useNavigate();

    const handleGotoEntityClick = useCallback(() => {
        navigate(buildRoute('resourcecapacity', row.identity?.id));
    }, [navigate, row.identity?.id]);

    const menuProps = useMemo((): IContextualMenuProps => {
        return {
            items: [
                {
                    key: 'project-link',
                    text: 'Go to Resource',
                    iconProps: { iconName: 'UserGauge' },
                    onClick: handleGotoEntityClick,
                },
            ],
            calloutProps: {
                dismissOnTargetClick: true,
                layerProps: fadeInCalloutLayerStyles,
            },
        };
    }, [handleGotoEntityClick]);
    const { data: uiSettings } = useUISettings();
    const { dropProps, isDraggingOver } = useDropRow(row.id, { onDrop: () => toggleExpanded(true), enabled: !uiSettings.settings.restrictOverAllocating });
    const { isDragging } = useIsDragging();
    return (
        <>
            <tr
                className={'tp-grid__row--resource'}
                {...(expanded && { style: { height: 75 } })}
                // {...(!visible && { style: { display: 'none', height: 0 } })}
                {...dropProps}
                // style={{
                // 	...(isDraggingOver && {
                // 		borderBottom: isDraggingOver ? "3px solid grey" : "none",
                // 		boxSizing: "border-box"

                // 	})
                // }}
            >
                <td className="header">
                    <div
                        style={{
                            paddingLeft: nestingLevel * 10,
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                            position: 'relative',
                            // overflow: 'hidden',
                        }}
                    >
                        <div className="expandicon-container">
                            {canExpand && <Icon className="expandicon" iconName={expanded ? 'ChevronDown' : 'ChevronRight'} onClick={() => toggleExpanded()} />}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                            }}
                            onDoubleClick={handleGotoEntityClick}
                        >
                            <ResourceCoinIcon row={row} />
                        </div>
                        {isDragging && (
                            <div className="dropable-zone">
                                <span className="drop-text">
                                    Drop here <Icon iconName="Download" />
                                </span>
                            </div>
                        )}
                    </div>
                </td>
                <td className="header">
                    <CommandBarButton
                        menuProps={menuProps}
                        menuIconProps={{ iconName: 'More' }}
                        styles={{ ...moreButtonStyles, root: { backgroundColor: 'transparent' } }}
                    />
                </td>
                {row.columnRows.map((columnRow, i) => {
                    return (
                        <ColoredTotalColumnRow key={columnRow.id} totalColumnRow={columnRow} expanded={expanded} isSubTotal={false}>
                            {expanded && (
                                // TODO use the header rows of headers here
                                <Stack
                                    horizontal
                                    horizontalAlign="space-around"
                                    className={AnimationClassNames.fadeIn500}
                                    styles={{ root: { width: '100%', padding: '0 10px', display: 'flex', gap: '10px' } }}
                                >
                                    <SubHeader subHeader={{ name: 'Unfulfilled' }} />
                                    <SubHeader subHeader={{ name: 'Allocation' }} />
                                </Stack>
                            )}
                        </ColoredTotalColumnRow>
                    );
                })}
            </tr>
            {/* {isDraggingOver && <tr style={{height: 3,}} />} */}
            {isDraggingOver && (
                <tr>
                    <td style={{ backgroundColor: '#000', border: '1px solid black', height: 3 }} />
                    <td style={{ backgroundColor: '#000', border: '1px solid black', height: 3 }} />
                    {row.columnRows.map((_, i) => {
                        return <td key={i} style={{ backgroundColor: '#000', border: '1px solid black', height: 3 }} />;
                    })}
                </tr>
            )}
        </>
    );
};


const ProjectRow = ({ visible, row, canExpand, nestingLevel, expanded, toggleExpanded, isAdminProject }: RowProps & { isAdminProject: boolean }) => {
    const navigate = useNavigate();
    const handleGotoEntityClick = useCallback(() => {
        navigate(buildRoute('allocation', row.identity?.id));
    }, [navigate, row.identity?.id]);

    const menuProps = useMemo((): IContextualMenuProps => {
        return {
            items: [
                {
                    key: 'project-link',
                    text: 'Go to Project',
                    iconProps: { iconName: 'MiniExpand' },
                    onClick: handleGotoEntityClick,
                },
            ],
            calloutProps: {
                dismissOnTargetClick: true,
                layerProps: fadeInCalloutLayerStyles,
            },
        };
    }, [handleGotoEntityClick]);
    return (
        <>
            <tr className={'tp-grid__row--project ' + AnimationClassNames.fadeIn400} {...(!visible && { style: { display: 'none', height: 0 } })}>
                <td className="header">
                    <div
                        style={{
                            paddingLeft: nestingLevel * 10,
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                            transition: 'background-color .3s',
                        }}
                    >
                        <div className="expandicon-container">
                            {canExpand && <Icon className="expandicon" iconName={expanded ? 'ChevronDown' : 'ChevronRight'} onClick={() => toggleExpanded()} />}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                            }}
                            onDoubleClick={handleGotoEntityClick}
                        >
                            <ProjectCoinIcon row={row} />
                        </div>
                    </div>
                </td>
                <td className="header">
                    <CommandBarButton
                        menuProps={menuProps}
                        menuIconProps={{ iconName: 'More' }}
                        styles={{ ...moreButtonStyles, root: { backgroundColor: 'transparent' } }}
                    />
                </td>
                {row.columnRows.map((columnRow, i) => {
                    return (
                        <TotalColumnRow key={columnRow.id} totalColumnRow={columnRow} expanded={expanded} isSubTotal={true} isAdminProject={isAdminProject} />
                    );
                })}
            </tr>
        </>
    );
};


const ContractRow = ({ visible, row, canExpand, nestingLevel, expanded, toggleExpanded, isAdminProject }: RowProps & { isAdminProject: boolean }) => {
    const { data: uiSettings } = useUISettings();
    const dragProps = useDragRow(row.id, !uiSettings.settings?.restrictOverAllocating);
    return (
        <>
            <tr draggable={!uiSettings.settings?.restrictOverAllocating} className={'tp-grid__row'} {...(!visible && { style: { display: 'none', height: 0 } })} {...dragProps}>
                <td className="header">
                    <div
                        style={{
                            paddingLeft: nestingLevel * 10 + 20,
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                            transition: 'background-color .3s',
                        }}
                    >
                        <Text className="title">{row.name}</Text>
                    </div>
                </td>
                <td className="header"></td>
                {row.columnRows.map((columnRow, i) => {
                    return <ColumnRow key={columnRow.id} columnRow={columnRow} columnIndex={i} rowId={row.id} isAdminProject={isAdminProject} />;
                })}
            </tr>
        </>
    );
};

const useDragRow = (thisRowId: string, enabled = true) => {
    const { setIsDragging } = useIsDragging();

    return useMemo((): React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> => {
        if (!enabled) {
            return {};
        }
        return {
            onDragStart: e => {
                e.dataTransfer.setData('row', thisRowId);
                e.dataTransfer.effectAllowed = 'all';
                setIsDragging(true);
            },
            onDragEnd: e => {
                setIsDragging(false);
            },
        };
    }, [enabled, setIsDragging, thisRowId]);
};

const useDropRow = (thisRowId: string, { enabled = true, onDrop }: { enabled?: boolean; onDrop?: () => void } = {}) => {
    const { moveRow } = useGridContext();
    const [isDraggingOver, setIsDragginOver] = useState(false);
    const [loading, setLoading] = useState(false);
    const dropProps = useMemo((): React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> => {
        if (!enabled) {
            return {};
        }
        return {
            onDrop: async e => {
                setIsDragginOver(false);
                const rowId = e.dataTransfer.getData('row');
                if (rowId) {
                    setLoading(true);
                    try {
                        await moveRow(rowId, thisRowId);
                    } catch (error) {
                        console.error('🚀 ~ dropProps ~ error', new Error('Error moving row', { cause: error }));
                    }
                    setLoading(false);
                }
                if (onDrop) {
                    onDrop();
                }
            },

            // An element with the preventDefault combination on onDragEnter and onDragOver makes it a dropable target.
            // The drag and drop api is retarded https://quirksmode.org/blog/archives/2009/09/the_html5_drag.html
            onDragEnter: e => {
                // console.log("🚀 ~ onDragEnter ~ e", e)
                preventDefault(e);
                setIsDragginOver(true);
            },
            onDragLeave: e => {
                setIsDragginOver(false);
            },
            onDragOver: e => {
                setIsDragginOver(true);
                // console.log("🚀 ~ onDragOver ~ e", e)
                preventDefault(e);
            },
        };
    }, [moveRow, thisRowId, enabled, onDrop]);

    return {
        dropProps,
        isDraggingOver,
        loading,
    };
};

const moreButtonStyles: IButtonStyles = {
    flexContainer: { height: 24 },
    menuIcon: { fontSize: 16 },
    root: {
        position: 'absolute !important',
        right: 8,
        padding: 0,
        backgroundColor: '#fff',
    } as any, // Hack to use important....
};

const fadeInCalloutLayerStyles: ILayerProps = {
    styles: {
        content: {
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
        },
    },
    className: AnimationClassNames.fadeIn100,
};
