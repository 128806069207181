export default class Locale_ContractInfoDto {
	RecentlyUsedContracts: string = "Recently used contracts";
	SuggestedContracts: string = "Suggested contracts";
	RecentlyUsedScenarios: string = "Recently used scenarios";
	SuggestedScenarios: string = "Suggested scenarios";
	ContractName: string = "Contract name (Will default to resource name if left empty)";
	StartGreaterThanFreezePeriod: string = "'Start' must be specified and greater than the freezeperiod settings.";
	EarliestStartGreaterThanFreezePeriod: string = "'EarliestStart' must be greater than the freezeperiod settings.";
	LatestEndGreaterThanEarliestStart: string = "'LatestEnd' must greater than EarliestStart.";
	IncludeInScenario: string = "Include in scenario";
	ExistingOrNewScenario: string = "Existing or new scenario";
	CreateNewScenario: string = "Create new scenario";
	SelectExistingScenario: string = "Select existing scenario";
	NewScenarioName: string = "New scenario name";
	EndExtraDescription: string = "If end is left empty the ditribution will default to 'as soon as possible'";
	Work: string = "Amount of workhours needed";
	HasActivitiesMessage: string = "Start and end are disabled as this contract has existing requests/allocations, that then define the start/end"
}