import { useGridRowContext } from '../../../../_grid/contexts/rowContext';
import { TotalColumnProps } from './TotalColumnRow';
import { ColoredTotalCell } from "./ColoredTotalCell";

export const ColoredTotalColumnRow = ({ totalColumnRow, children, expanded, isSubTotal }: TotalColumnProps) => {
	// console.log("🚀 ~ TotalColumnRow ~ isAdminProject", isAdminProject)
	const { toggleExpanded, canExpand } = useGridRowContext();

	return (
		<td className="tp-table-header-total" onClick={() => toggleExpanded()}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100%',
					width: '100%',
					flexDirection: 'column',
					// padding: '10px 0',
					// padding: '0',
					overflow: 'hidden',
					// height: "100%",
					padding: '.5px',
				}}
			>
				<div
					style={{
						//
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: 0,
						// gap: 10,
						// ...(isSubTotal && { gap: 10, padding: 10 }),
						...(isSubTotal && { gap: 10 }),
						width: '100%',
						height: '100%',
						...(canExpand && { cursor: 'pointer' }),
					}}
				>
					{totalColumnRow.cells.map(cell => {
						// totalColumnRow.metadata
						return (
							<ColoredTotalCell
								key={cell.id}
								// componentName="TotalCell"
								cell={cell}
								isRowExpanded={expanded}
								totalColumnRow={totalColumnRow} />
						);
					})}
				</div>
				{children}
			</div>
		</td>
	);
};
