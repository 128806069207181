import { JsonEx } from '../Utilities/JsonEx';
import { GroupRow, IGroupRow } from '../Entities/Table/GroupRow';
import { CellRow, ICellRow } from '../Entities/Table/CellRow';
import { guid } from '../helpers/guid';
import { ITopRow, TopRow } from '../Entities/Table/TopRow';
import { Plugin } from './PluginInvoker';
import { PluginIDs } from '../Utilities/PluginIDs';
import { TimelineResolution } from '../Entities/TimelineResolution';
import { IRow, Row } from '../Entities/Table/Row';
import { DragAndDrop } from '../Utilities/DragAndDrop';
import { GridType } from '../Entities/GridType';
import { CellEx } from '../Utilities/CellEx';
import { CellType } from '../Entities/Table/CellType';
import { GroupContainerRow, IGroupContainerRow } from '../Entities/Table/GroupContainerRow';
import { WorkType, AddActivityDto, IAddActivityDto } from '../Entities/Dto/AddActivityDto';
import { Cell, ICell } from '../Entities/Table/Cell';
import { OverviewContext } from '../Utilities/Context/OverviewContext';
import { UnitType } from '../Entities/UnitType';
import { DateEx } from '../Utilities/DateEx';
import { ResourceType } from '../Entities/ResourceType';
import { EntityType } from '../Entities/EntityTypes';
import { StringEx } from '../Utilities/StringEx';
import { UnitTypeUtil } from '../Utilities/UnitTypeUtil';
import { NumberEx } from '../Utilities/NumberEx';
import { IExtendedTheme } from '../Themes/Theme.types';
import { BaseRow, IBaseRow } from '../Entities/Table/BaseRow';
import { UserContext } from '../Utilities/Context/UserContext';
import { ColumnType } from '../Entities/Table/ColumnType';
import { language } from './LocalizationService';
import { LocalStorage } from '../Utilities/LocalStorage';
import EnumEx from '../Utilities/EnumEx';
import { DateResolution, ProjectType } from '../api/generated/data-contracts';

export class ProjectCapacityService {
    //
    public static AddResource = async (
        view: TopRow,
        projectId: string,
        resourceId: string,
        groupName: string,
        groupId: string,
        start: Date,
        end: Date,
        resolution: TimelineResolution,
        gridType: GridType,
        infoHandler: (msg: string) => void,
        relatedGenericResourceId?: string,
        projectType?: ProjectType,
        useAutoGrouping = false,
        groupBy?: string,
        alterNewRow?: (row: Row) => void,
    ): Promise<TopRow> => {
        if (!resourceId || !groupName || !view) return;

        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);

        // if only allowed to request a resource one time on a given project
        if (gridType === GridType.Request && OverviewContext.Settings.SingletonOnRequests) {
            const groupWithGenRes = flattenedGroups.find(g => g.Rows.some(r => r.Properties.ResourceType === ResourceType.Generic && r.Id === resourceId));
            if (groupWithGenRes != null) {
                infoHandler(language.Common.ResourceAlreadyAssignedToProject);
                return view;
            }
        }

        let newGroup = false;
        let group: GroupRow;

        // specific group
        if (groupId) {
            group = flattenedGroups.find(_ => _.Id === groupId);
            // a specific group has been chosen and the resource is already added to that contract
            if (ProjectCapacityService.ResourceAlreadyAssigned(group, resourceId, gridType)) {
                infoHandler(language.Common.ResourceAlreadyAssignedToContract);
                return view;
            }
        } else {
            // no group selected - dynamic group
            // use the resource it self to locate a suitable contract
            if (OverviewContext.Settings.EnableRequestNamedResources) {
                group = group || flattenedGroups.find(g => g.Properties.RequestedResourceId === resourceId);
            }
            if (ProjectCapacityService.ResourceAlreadyAssigned(group, resourceId, gridType)) {
                group = null;
            }
            // use the resoruce's generic resoruce to locate a suitable contract
            group =
                group ||
                flattenedGroups.find(g =>
                    g.Rows.some(r => r.Properties.ResourceType === ResourceType.Generic && relatedGenericResourceId && r.Id === relatedGenericResourceId),
                );
            if (ProjectCapacityService.ResourceAlreadyAssigned(group, resourceId, gridType)) {
                group = null;
            }
            // use the contract name to locate a suitable contract
            if (gridType === GridType.Allocation) {
                group = group || flattenedGroups.find(_ => _.Name === groupName);
            }
            if (ProjectCapacityService.ResourceAlreadyAssigned(group, resourceId, gridType)) {
                group = null;
            }
        }

        if (!group) {
            if (gridType === GridType.Allocation || gridType === GridType.LmCapacityProjects) {
                if (OverviewContext.Settings.RestrictDirectAllocations && projectType === ProjectType.Default) {
                    infoHandler("Direct allocations is restricted and couldn't find a suitable contract for this resource."); // TODO: language
                    return view;
                }
            }
            newGroup = true;
            group = new GroupRow(guid.newGuid(), groupName);
            group.CellRows = JsonEx.clone(view.CellRows) as CellRow[];
            group.Properties = { ProjectId: projectId, ResourceId: resourceId, RequestedResourceId: gridType === GridType.Request ? resourceId : null };
            group.CellRows.forEach(_ =>
                _.Cells.forEach(_ => {
                    CellEx.Update(_, 0);
                    _.Type = CellType.Group;
                    _.Properties = { ContractId: group.Id, ProjectId: projectId };
                }),
            );

            if (!groupBy) {
                // handle contract autogrouping
                if (useAutoGrouping && !StringEx.isNullOrWhiteSpace(OverviewContext.Settings.AutoGroupContractBy)) {
                    const entity = await Plugin.Invoke<Record<string, string>>(PluginIDs.GetEntityDetails, {
                        EntityId: resourceId,
                        EntityType: EntityType.Resource,
                        OverrideSelectedProperties: [OverviewContext.Settings.AutoGroupContractBy],
                    });
                    const containerName = entity[OverviewContext.Settings.AutoGroupContractBy.replace('CustomProperties.', '')];
                    if (StringEx.isNullOrWhiteSpace(containerName)) view.Groups.push(group);
                    else {
                        const container = new GroupContainerRow(guid.newGuid(), containerName, {});
                        container.CellRows = JsonEx.clone(view.CellRows) as CellRow[];
                        container.CellRows.forEach(_ =>
                            _.Cells.forEach(_ => {
                                CellEx.Update(_, 0);
                                _.Type = CellType.Group;
                                _.Properties = { ContractId: group.Id, ProjectId: projectId };
                            }),
                        );
                        group.Properties.ContainerId = container.Id;
                        container.Groups.push(group);
                        view.Groups.push(container);
                    }
                } else view.Groups.push(group);
            }
        }

        LocalStorage.setProperty('tp-grid-collapsed', `${view.Id}-${group.Id}`, false);

        if (group.Properties.RequestedResourceId !== resourceId && gridType === GridType.Request && group.Properties.HasRequests) {
            infoHandler(language.Common.RequestedResourceAlreadyAssignedToContract);
            return view;
        }

        if (!group.Rows.find(_ => _.Id === resourceId) || group.Properties.RequestedResourceId === resourceId) {
            const resourceRow = await Plugin.Invoke<Row>(PluginIDs.GetNewResourceRow, {
                ProjectId: projectId,
                ContractId: group.Id,
                ResourceId: resourceId,
                Start: start,
                End: end,
                DateResolution: resolution,
                GridType: gridType,
                GroupBy: groupBy,
            });
            if (alterNewRow) alterNewRow(resourceRow);
            // serious hacky shit! Don't care about this stupid grid anymore! gogogo for the new grid! (ewi)
            // we need to find the id of a contract outside the grid - this is what we do, because we're rogue retarded cowboys!
            if (gridType === GridType.Request && OverviewContext.Settings.SingletonOnRequests) {
                group.Id = resourceRow.Properties.ContractId;
                group.CellRows.forEach(_ => _.Cells.forEach(_ => (_.Properties = { ContractId: group.Id, ProjectId: projectId })));
            }
            resourceRow.Properties.IsNew = true;
            group.Properties.RequestedResourceType = resourceRow.Properties.RequestedResourceType;
            if (resourceRow) group.Rows.push(resourceRow);
            // when dynamic groupby is enabled
            if (groupBy && newGroup) {
                if (resourceRow.Properties.ContainerName) {
                    const container = view.Groups.find(
                        _ => _.TypeName === 'GroupContainerRow' && _.Name === resourceRow.Properties.ContainerName,
                    ) as GroupContainerRow;
                    if (container) container.Groups.push(group);
                    else {
                        const container = new GroupContainerRow(guid.newGuid(), resourceRow.Properties.ContainerName);
                        group.Properties.ContainerId = container.Id;
                        container.Groups.push(group);
                        container.CellRows = JsonEx.clone(group.CellRows) as CellRow[];
                        view.Groups.push(container);
                    }
                } else view.Groups.push(group);
            }
        }
        return view;
    };

    // rf
    private static ResourceAlreadyAssigned = (group: GroupRow, resourceId: string, gridType: GridType): boolean => {
        if (group == null) return false;
        // allowed add of allocation resource if it is also the requested one (these have been splitted into two rows now)
        const isRequestedResource = group.Properties.RequestedResourceId === resourceId;
        const resourceAlreadyAssigned =
            isRequestedResource && group.Properties.HasRequests && EnumEx.equalsAny(gridType, GridType.Allocation, GridType.LmCapacityProjects)
                ? group.Rows.filter(_ => _.Id === resourceId).length >= 2
                : group.Rows.some(_ => _.Id === resourceId);
        return resourceAlreadyAssigned;
    };

    // rf
    public static AddResourceToContainer = async (
        view: TopRow,
        projectId: string,
        containerId: string,
        resourceId: string,
        groupName: string,
        start: Date,
        end: Date,
        resolution: TimelineResolution,
        gridType: GridType,
        alterNewRow?: (row: Row) => void,
    ): Promise<TopRow> => {
        const group = new GroupRow(guid.newGuid(), groupName);
        group.CellRows = JsonEx.clone(view.CellRows) as CellRow[];
        group.Properties = { ProjectId: projectId, ContainerId: containerId };
        group.CellRows.forEach(_ =>
            _.Cells.forEach(_ => {
                CellEx.Update(_, 0);
                _.Type = CellType.Group;
                _.Properties = { ContractId: group.Id };
            }),
        );

        const container = view.Groups.find(_ => _.Id === containerId) as GroupContainerRow;
        container.Groups.push(group);

        const resourceRow = await Plugin.Invoke<Row>(PluginIDs.GetNewResourceRow, {
            ProjectId: projectId,
            ContractId: group.Id,
            ResourceId: resourceId,
            Start: start,
            End: end,
            DateResolution: resolution,
            GridType: gridType,
        });
        if (alterNewRow) alterNewRow(resourceRow);
        resourceRow.Properties.IsNew = true;
        group.Rows.push(resourceRow);

        return view;
    };

    // rf
    public static OnResourceRemove = async (
        view: TopRow,
        gridType: GridType,
        rowResource: Row,
        rowGroup: GroupRow,
        resolution: TimelineResolution,
        removeActivitiesFunc: (gridType: GridType, contractId: string, resourceId: string, start: Date, resolution: TimelineResolution) => Promise<void>,
    ): Promise<TopRow> => {
        if (!DragAndDrop.RemoveDraggedResource) return null;
        DragAndDrop.RemoveDraggedResource = false;

        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);
        const group = flattenedGroups.find(_ => _.Id === rowGroup.Id);
        const resource = group.Rows.find(_ => _.Id === rowResource.Id);

        // update totals
        for (let rIdx = 0; rIdx < resource.CellRows.length; rIdx++) {
            const cellRow = resource.CellRows[rIdx];
            for (let cIdx = 0; cIdx < cellRow.Cells.length; cIdx++) {
                // skip free capacity total
                if (cIdx >= 2) continue;
                if (group.CellRows[rIdx].Cells.length - 1 < cIdx) continue;
                const cell = cellRow.Cells[cIdx];
                CellEx.Update(group.CellRows[rIdx].Cells[cIdx], -cell.Value, true);
                CellEx.Update(view.CellRows[rIdx].Cells[cIdx], -cell.Value, true);
                // container total
                if (group.Properties.ContainerId === guid.empty) continue;
                const container = view.Groups.find(_ => _.Id === group.Properties.ContainerId);
                if (container) CellEx.Update(container.CellRows[rIdx].Cells[cIdx], -cell.Value, true);
            }
        }

        // remove resource
        const index = group.Rows.findIndex(_ => _.Id === rowResource.Id);
        if (index > -1) group.Rows.splice(index, 1);

        // remove possible empty group
        if (group.Rows.length <= 0 && !group.Properties.Sticky) {
            ProjectCapacityService.RemoveGroup(view, group);

            // remove possible empty container
            const container = view.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow;
            if (container && container.Groups.length <= 0) {
                ProjectCapacityService.RemoveGroup(view, container);
            }
        }

        // guard for when total column is enabled
        const firstHeader = view.Headers.find(_ => _.Type === ColumnType.Normal);
        await removeActivitiesFunc(gridType, group.Id, rowResource.Id, firstHeader.Start, resolution);
        return view;
    };

    public static OnContractRemove = async (
        view: TopRow,
        rowGroup: GroupRow,
        resolution: DateResolution,
        removeActivitiesFunc: (contractId: string, start: Date, resolution: DateResolution) => Promise<void>,
        removeGroup = true,
    ): Promise<TopRow> => {
        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);
        const group = flattenedGroups.find(_ => _.Id === rowGroup.Id);

        await Promise.all(
            group.Rows.map(async (row: Row) => {
                // update totals
                for (let rIdx = 0; rIdx < row.CellRows.length; rIdx++) {
                    const cellRow = row.CellRows[rIdx];
                    for (let cIdx = 0; cIdx < cellRow.Cells.length; cIdx++) {
                        // skip free capacity total
                        if (cIdx >= 2) continue;
                        if (group.CellRows[rIdx].Cells.length - 1 < cIdx) continue;
                        const cell = cellRow.Cells[cIdx];
                        CellEx.Update(group.CellRows[rIdx].Cells[cIdx], -cell.Value, true);
                        CellEx.Update(view.CellRows[rIdx].Cells[cIdx], -cell.Value, true);
                        // container total
                        if (group.Properties.ContainerId === guid.empty) continue;
                        const container = view.Groups.find(_ => _.Id === group.Properties.ContainerId);
                        if (container) CellEx.Update(container.CellRows[rIdx].Cells[cIdx], -cell.Value, true);
                    }
                }

                // remove resource
                const index = group.Rows.findIndex(_ => _.Id === row.Id);
                if (index > -1) group.Rows.splice(index, 1);

                // remove possible empty group
                if (removeGroup) {
                    ProjectCapacityService.RemoveGroup(view, group);
                }

                // remove possible empty container
                const container = view.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow;
                if (container && container.Groups.length <= 0 && removeGroup) {
                    ProjectCapacityService.RemoveGroup(view, group);
                }

                // guard for when total column is enabled
                const firstHeader = view.Headers.find(_ => _.Type === ColumnType.Normal);
                return await removeActivitiesFunc(group.Properties['ContractId'], firstHeader.Start, resolution);
            }),
        );
        return view;
    };

    public static OnGroupRemove = async (
        view: TopRow,
        rowGroup: GroupContainerRow,
        resolution: DateResolution,
        removeActivitiesFunc: (contractId: string, start: Date, resolution: DateResolution) => Promise<void>,
    ): Promise<TopRow> => {
        let currentView = view;
        for (const group of rowGroup.Groups) {
            const newView = await ProjectCapacityService.OnContractRemove(currentView, group, resolution, removeActivitiesFunc, false);
            currentView = newView;
        }
        rowGroup.Groups.forEach(g => ProjectCapacityService.RemoveGroup(currentView, g));
        currentView.Groups = currentView.Groups.filter(currentGroup => currentGroup.Id !== rowGroup.Id);
        return currentView;
    };

    // rf
    public static RemoveActivities = async (gridType: GridType, contractId: string, resourceId: string, start: Date, resolution: TimelineResolution) => {
        await Plugin.InvokeNoResponse(
            PluginIDs.RemoveActivities,
            { GridType: gridType, ContractId: contractId, ResourceId: resourceId, Start: start, Resolution: resolution },
            'Failed removing activities',
            true,
        );
    };

    public static RemoveContractActivities = async (contractId: string, start: Date, resolution: TimelineResolution) => {
        await Plugin.InvokeNoResponse(
            PluginIDs.RemoveContractActivities,
            { ContractId: contractId, Start: start, Resolution: resolution },
            'Failed removing activities',
            true,
        );
    };

    // rf
    public static GroupsFlattened = (view: TopRow): Array<GroupRow> => {
        const groups = new Array<GroupRow>();
        view.Groups.forEach(_ => {
            if (_.TypeName === 'GroupRow') groups.push(_);
            else if (_.TypeName === 'GroupContainerRow') groups.push(...(_ as GroupContainerRow).Groups);
        });
        return groups;
    };

    // rf
    public static RemoveGroup = (view: TopRow, group: BaseRow): void => {
        view.Groups.forEach((_, idx) => {
            if (_.Id === group.Id) {
                view.Groups.splice(idx, 1);
                return;
            } else if (_.TypeName === 'GroupContainerRow') {
                const container = _ as GroupContainerRow;
                container.Groups.forEach((_, index) => {
                    if (_.Id === group.Id) {
                        container.Groups.splice(index, 1);
                        return;
                    }
                });
            }
        });
    };

    // rf
    public static FindGroup = (view: TopRow, groupId: string): GroupRow => {
        let group: GroupRow;
        view.Groups.forEach(_ => {
            if (group) return;
            if (_.Id === groupId) {
                group = _;
                return;
            } else if (_.TypeName === 'GroupContainerRow') {
                (_ as GroupContainerRow).Groups.forEach(g => {
                    if (g.Id === groupId) {
                        group = g;
                        return;
                    }
                });
            }
        });
        return group;
    };

    // rf
    public static UpdateCellDomValue = (
        view: TopRow,
        flattenedGroups: Array<GroupRow>,
        container: GroupContainerRow,
        group: GroupRow,
        row: Row,
        cellRow: CellRow,
        workType: WorkType,
        columnIndex: number,
        idx: number,
        newValue: number,
        dontUpdateTotalRow?: boolean,
        dontUpdateTotalColumn?: boolean,
    ): void => {
        const oldValue = cellRow.Cells[idx].Value;
        const diff = newValue - oldValue;

        // update cell value
        CellEx.Update(row.CellRows[columnIndex].Cells[idx], newValue);

        // if category resource has allocations (unset for other types)
        const categoryHasAllocations = row.Properties.ResourceType !== ResourceType.Category ? null : newValue > 0;

        // update total rows
        ProjectCapacityService.UpdateTotals(view, group, columnIndex, idx, diff, workType, container, categoryHasAllocations, dontUpdateTotalRow);
        // update total column - only present when using hours
        if (!dontUpdateTotalColumn && UnitTypeUtil.getCurrentUnitType() === UnitType.Hours) {
            ProjectCapacityService.UpdateTotals(view, group, 0, idx, diff, workType, container, categoryHasAllocations, dontUpdateTotalRow);
            CellEx.Update(row.CellRows[0].Cells[idx], diff, true);
        }

        // update all other freecapacity cells for this resource (when resource is allocated at multiple contracts to the same project)
        if (
            workType === WorkType.Allocation &&
            row.Properties.ResourceType !== ResourceType.Category &&
            !row.Properties.IsRequested &&
            row.Name !== 'Unknown resource'
        ) {
            // update free capacity for edited cell
            CellEx.Update(cellRow.Cells[idx + 1], -diff, true);
            // update free capacity for resource's other free capacity cells
            flattenedGroups.forEach(g => {
                if (g.Id === group.Id) return;
                g.Rows.forEach(r => {
                    if (r.Id !== row.Id) return;
                    const cr = r.CellRows.find(_ => DateEx.equals(_.Start, cellRow.Start));
                    if (!cr || cr.Properties.IsRequested) return;
                    CellEx.Update(cr.Cells[idx + 1], -diff, true);
                });
            });
        }
    };

    // rf
    public static UpdateTotals = (
        view: TopRow,
        group: GroupRow,
        columnIndex: number,
        idx: number,
        diff: number,
        workType: WorkType,
        container: GroupContainerRow,
        categoryHasAllocations: boolean,
        dontUpdateTotalRow?: boolean,
    ): void => {
        const updateTotals = (row: BaseRow) => {
            const cell = row.CellRows[columnIndex].Cells[idx];
            const adjescantCell = row.CellRows[columnIndex].Cells[adjescantCellIndex];
            // update view totals
            CellEx.Update(cell, diff, true);
            if (adjescantCell == null) return;
            adjescantCell.ReRender = true;
            // handle category allocations
            if (categoryHasAllocations != null) {
                cell.Properties.ContainsCategory = categoryHasAllocations;
                adjescantCell.Properties.ContainsCategory = categoryHasAllocations;
            }
        };

        const adjescantCellIndex = workType === WorkType.Request ? 1 : 0;

        // update view total if shown
        if (!dontUpdateTotalRow && view.CellRows.length > 0) updateTotals(view);

        // update container totals
        if (container) updateTotals(container);

        // update group totals
        updateTotals(group);
    };

    // rf
    public static GetRowAndCellRow = (view: TopRow, cell: Cell, columnIndex: number): [GroupContainerRow, GroupRow, Row, CellRow] => {
        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);
        let container: GroupContainerRow;
        let group: GroupRow;
        let row: BaseRow;
        let cellRow: CellRow;
        for (const g of flattenedGroups) {
            for (const r of g.Rows) {
                if (r.Id !== cell.Properties.ResourceId || r.Properties.ContractId !== cell.Properties.ContractId) continue;
                row = r;
                cellRow = r.CellRows[columnIndex];
                group = g;
                if (g.Properties.ContainerId) container = flattenedGroups.find(_ => _.Id === g.Properties.ContainerId) as GroupContainerRow;
                break;
            }
            if (row != null) break;
        }

        return [container, group, row, cellRow] as const;
    };

    // rf
    public static ForceRefreshCells = (topRow: TopRow) => {
        topRow.Groups.forEach(group => {
            if (group.TypeName === 'GroupRow') {
                group.Rows.forEach(row => {
                    row.CellRows.forEach(cr => {
                        cr.Cells.forEach(cell => {
                            cell.ReRender = true;
                        });
                    });
                });
            } else if (group.TypeName === 'GroupContainerRow') {
                (group as GroupContainerRow).Groups.forEach(g => {
                    g.Rows.forEach(row => {
                        row.CellRows.forEach(cr => {
                            cr.Cells.forEach(cell => {
                                cell.ReRender = true;
                            });
                        });
                    });
                });
            }
        });
    };

    // rf
    public static BuildSaveCellDto = (
        resolution: TimelineResolution,
        type: WorkType,
        cell: Cell,
        parent: CellRow,
        groupName: string,
        containerName: string,
    ): AddActivityDto => {
        const dto = new AddActivityDto();
        dto.Start = parent.Start;
        dto.End = parent.End;
        dto.Type = type;
        dto.Work = NumberEx.getValueOrDefault(cell.Value);
        dto.ContractId = parent.Properties.ContractId;
        dto.ContractGroupName = containerName;
        dto.ResourceId = parent.Properties.ResourceId;
        dto.ProjectId = parent.Properties.ProjectId;
        dto.ResourceName = parent.Properties.ResourceName;
        dto.ContractName = groupName;
        dto.Resolution = resolution;
        return dto;
    };

    // rf
    public static SaveCell = (dto: AddActivityDto, cellId: string, errorHandler: (msg: string) => void, ignoreContainerName?: boolean): void => {
        if (ignoreContainerName) dto.ContractGroupName = null;
        Plugin.Invoke<any>(PluginIDs.SaveProjectCapacityCell, dto)
            .then(_ => {
                ProjectCapacityService.HighlightCell([cellId], true, 'highlightdone');
            })
            .catch(_ => {
                // NotificationService.Instance.Error(language.Common.FailedSavingCells);
                errorHandler(language.Common.FailedSavingCells);
                ProjectCapacityService.HighlightCell([cellId], true, 'highlighterror');
            });
    };

    // rf
    public static SaveCells = (
        dtos: Array<AddActivityDto>,
        cellIds: Array<string>,
        errorHandler: (msg: string) => void,
        ignoreContainerName?: boolean,
    ): void => {
        if (ignoreContainerName) dtos.forEach(_ => (_.ContractGroupName = null));
        // TODO remove this terrible fix ASAP - MIK
        // This happens because there is a memory leak somewhere (possibly dragContext),
        // that causes the update to include old values (and duplicates thereof) from other grids.
        const nonDuplicateMap = new Map<string, AddActivityDto>();
        // let updateValue = 0; // removing this as it is used by the excel copy/paste feature as well (ewi)
        dtos.forEach((dto, i) => {
            // if(!i) {
            // 	updateValue = dto.Work; // removing this as it is used by the excel copy/paste feature as well (ewi)
            // }
            const cellId = dto.Start.toISOString() + dto.End.toISOString() + dto.ContractId + dto.ResourceId;
            if (!nonDuplicateMap.has(cellId)) {
                nonDuplicateMap.set(cellId, { ...dto, Work: dto.Work });
            }
        });
        Plugin.Invoke<any>(PluginIDs.SaveProjectCapacityCells, [...nonDuplicateMap.values()])
            .then(_ => {
                ProjectCapacityService.HighlightCell(cellIds, true, 'highlightdone');
            })
            .catch(_ => {
                // NotificationService.Instance.Error(language.Common.FailedSavingCells);
                errorHandler(language.Common.FailedSavingCells);
                ProjectCapacityService.HighlightCell(cellIds, true, 'highlighterror');
            });
    };

    // rf
    public static HighlightCell = (cellIds: Array<string>, removeBorder?: boolean, highlightClass: string = 'highlight', includeDisabledCells?: boolean) => {
        if (cellIds == null) return;
        cellIds.forEach(_ => {
            const element = document.querySelector(
                `.tp-capacity-cell-container input[data-cellid="${_}"] ${includeDisabledCells ? `, div[data-cellid="${_}"]` : ''}`,
            ) as HTMLElement;
            if (!element) return;
            element.classList.add(highlightClass);
            setTimeout(() => {
                element.classList.remove(highlightClass);
            }, 1000);

            if (removeBorder) element.style.border = 'unset';
        });
    };

    // rf
    public static GetGridDecimals = (): number => {
        const unittype = UnitTypeUtil.getCurrentUnitType();
        switch (unittype) {
            case UnitType.FTE:
                return OverviewContext.Settings.GridDecimalsFTE;
            case UnitType.Hours:
                return OverviewContext.Settings.GridDecimalsHours;
            default:
                return OverviewContext.Settings.GridDecimalsHours;
        }
    };

    // rf
    public static GetActivityColor = (view: TopRow, cell: Cell, index: number, columnIndex: number, cellType: CellType, theme: IExtendedTheme): string => {
        if (cell == null) return '';

        let otherCell: Cell;
        switch (cellType) {
            case CellType.Top:
                if (!view || !view.CellRows[columnIndex]) return;
                otherCell = view.CellRows[columnIndex].Cells[index === 0 ? 1 : 0];
                break;
            case CellType.Group: {
                let group = view.Groups.find(_ => _.Id === cell.Properties.ContractId);
                group = group || ProjectCapacityService.FindGroup(view, cell.Properties.ContractId);
                if (!group || !group.CellRows[columnIndex]) return;
                otherCell = group.CellRows[columnIndex].Cells[index === 0 ? 1 : 0];
                break;
            }
            case CellType.Row:
                return;
            case CellType.Container: {
                const container = view.Groups.find(_ => _.Id === cell.Properties.ContainerId);
                if (!container || !container.CellRows[columnIndex]) return;
                otherCell = container.CellRows[columnIndex].Cells[index === 0 ? 1 : 0];
                break;
            }
        }

        // define values to compare
        const decimals = ProjectCapacityService.GetGridDecimals();
        const request = NumberEx.round(index === 0 ? cell.Value : otherCell?.Value, decimals);
        const allocation = NumberEx.round(index === 1 ? cell.Value : otherCell?.Value, decimals);

        if (request < allocation) return theme.semanticColors.activityOverBackground;
        else if (request > allocation) return theme.semanticColors.activityUnderBackground;
        else if (request === allocation)
            if (cell.Properties.ContainsCategory && allocation > 0) return theme.semanticColors.activityEqualContainsCategoryBackground;
            else if (request === 0 && allocation === 0) {
                return theme.semanticColors.activityZeroBackground;
            } else {
                return theme.semanticColors.activityEqualBackground;
            }
        return '';
    };
}

// This is private ¯\_(ツ)_/¯
const resourceAlreadyAssigned = (group: IGroupRow, resourceId: string, gridType: GridType): boolean => {
    if (group == null) return false;
    // allowed add of allocation resource if it is also the requested one (these have been splitted into two rows now)
    const isRequestedResource = group.Properties.RequestedResourceId === resourceId;
    const resourceAlreadyAssigned =
        isRequestedResource && EnumEx.equalsAny(gridType, GridType.Allocation, GridType.LmCapacityProjects)
            ? group.Rows.filter(_ => _.Id === resourceId).length >= 2
            : group.Rows.some(_ => _.Id === resourceId);
    return resourceAlreadyAssigned;
};

export async function addResourceToContainer(
    view: ITopRow,
    projectId: string,
    containerId: string,
    resourceId: string,
    groupName: string,
    start: Date,
    end: Date,
    resolution: TimelineResolution,
    gridType: GridType,
    alterNewRow?: (row: Row) => void,
): Promise<ITopRow> {
    // const group = new GroupRow(guid.newGuid(), groupName);
    const group: IGroupRow = {
        Id: guid.newGuid(),
        Name: groupName,
        CellRows: JsonEx.clone(view.CellRows) as ICellRow[],
        Properties: { ContainerId: containerId },
        TypeName: 'GroupRow',
        Rows: [],
    };
    // group.CellRows = JsonEx.Clone(view.CellRows) as CellRow[];
    // group.Properties = { ContainerId: containerId };
    group.CellRows.forEach(_ =>
        _.Cells.forEach(_ => {
            CellEx.Update(_, 0);
            _.Type = CellType.Group;
            _.Properties = { ContractId: group.Id };
        }),
    );

    const container = view.Groups.find(_ => _.Id === containerId) as IGroupContainerRow;
    container.Groups.push(group);

    const resourceRow = await Plugin.Invoke<Row>(PluginIDs.GetNewResourceRow, {
        ProjectId: projectId,
        ContractId: group.Id,
        ResourceId: resourceId,
        Start: start,
        End: end,
        DateResolution: resolution,
        GridType: gridType,
    });
    if (alterNewRow) {
        alterNewRow(resourceRow);
    }
    resourceRow.Properties.IsNew = true;
    group.Rows.push(resourceRow);

    return view;
}

export async function onResourceRemove(
    view: ITopRow,
    gridType: GridType,
    rowResource: IRow,
    rowGroup: IGroupRow,
    resolution: TimelineResolution,
    removeActivitiesFunc: (gridType: GridType, contractId: string, resourceId: string, start: Date, resolution: TimelineResolution) => Promise<void>,
): Promise<ITopRow> {
    if (!DragAndDrop.RemoveDraggedResource) return null;
    DragAndDrop.RemoveDraggedResource = false;

    const flattenedGroups = groupsFlattened(view);
    const group = flattenedGroups.find(_ => _.Id === rowGroup.Id);
    const resource = group.Rows.find(_ => _.Id === rowResource.Id);

    // update totals
    for (let rIdx = 0; rIdx < resource.CellRows.length; rIdx++) {
        const cellRow = resource.CellRows[rIdx];
        for (let cIdx = 0; cIdx < cellRow.Cells.length; cIdx++) {
            // skip free capacity total
            if (cIdx >= 2) continue;
            if (group.CellRows[rIdx].Cells.length - 1 < cIdx) continue;
            const cell = cellRow.Cells[cIdx];
            CellEx.Update(group.CellRows[rIdx].Cells[cIdx], -cell.Value, true);
            CellEx.Update(view.CellRows[rIdx].Cells[cIdx], -cell.Value, true);
            // container total
            if (group.Properties.ContainerId === guid.empty) continue;
            const container = view.Groups.find(_ => _.Id === group.Properties.ContainerId);
            if (container) CellEx.Update(container.CellRows[rIdx].Cells[cIdx], -cell.Value, true);
        }
    }

    // remove resource
    const index = group.Rows.findIndex(_ => _.Id === rowResource.Id);
    if (index > -1) group.Rows.splice(index, 1);

    // remove possible empty group
    if (group.Rows.length <= 0 && !group.Properties.Sticky) {
        removeGroup(view, group);

        // remove possible empty container
        const container = view.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow;
        if (container && container.Groups.length <= 0) {
            removeGroup(view, container);
        }
    }

    // guard for when total column is enabled
    const firstHeader = view.Headers.find(_ => _.Type === ColumnType.Normal);
    await removeActivitiesFunc(gridType, group.Id, rowResource.Id, firstHeader.Start, resolution);
    // await ProjectCapacityService.RemoveActivities(gridType, group.Id, rowResource.Id, firstHeader.Start, resolution);
    return view;
}

export async function removeActivities(gridType: GridType, contractId: string, resourceId: string, start: Date, resolution: TimelineResolution) {
    await Plugin.InvokeNoResponse(
        PluginIDs.RemoveActivities,
        { GridType: gridType, ContractId: contractId, ResourceId: resourceId, Start: start, Resolution: resolution },
        'Failed removing activities',
        true,
    );
}

export function removeGroup(view: ITopRow, group: IBaseRow): void {
    view.Groups.forEach((_, idx) => {
        if (_.Id === group.Id) {
            view.Groups.splice(idx, 1);
            return;
        } else if (_.TypeName === 'GroupContainerRow') {
            const container = _ as GroupContainerRow;
            container.Groups.forEach((_, index) => {
                if (_.Id === group.Id) {
                    container.Groups.splice(index, 1);
                    return;
                }
            });
        }
    });
}

export function updateCellDomValue(
    view: ITopRow,
    flattenedGroups: IGroupRow[],
    container: IGroupContainerRow,
    group: IGroupRow,
    row: IRow,
    cellRow: ICellRow,
    workType: WorkType,
    columnIndex: number,
    idx: number,
    newValue: number,
    dontUpdateTotalRow?: boolean,
    dontUpdateTotalColumn?: boolean,
): void {
    const oldValue = cellRow.Cells[idx].Value;
    const diff = newValue - oldValue;

    // update cell value
    CellEx.Update(row.CellRows[columnIndex].Cells[idx], newValue);

    // if category resource has allocations (unset for other types)
    const categoryHasAllocations = row.Properties.ResourceType !== ResourceType.Category ? null : newValue > 0;

    // update total rows
    updateTotals(view, group, columnIndex, idx, diff, workType, container, categoryHasAllocations, dontUpdateTotalRow);
    // update total column - only present when using hours
    if (!dontUpdateTotalColumn && UnitTypeUtil.getCurrentUnitType() === UnitType.Hours) {
        updateTotals(view, group, 0, idx, diff, workType, container, categoryHasAllocations, dontUpdateTotalRow);
        CellEx.Update(row.CellRows[0].Cells[idx], diff, true);
    }

    // update all other freecapacity cells for this resource (when resource is allocated at multiple contracts to the same project)
    if (
        workType === WorkType.Allocation &&
        row.Properties.ResourceType !== ResourceType.Category &&
        !row.Properties.IsRequested &&
        row.Name !== 'Unknown resource'
    ) {
        // update free capacity for edited cell
        CellEx.Update(cellRow.Cells[idx + 1], -diff, true);
        // update free capacity for resource's other free capacity cells
        flattenedGroups.forEach(g => {
            if (g.Id === group.Id) return;
            g.Rows.forEach(r => {
                if (r.Id !== row.Id) return;
                const cr = r.CellRows.find(_ => DateEx.equals(_.Start, cellRow.Start));
                if (!cr || cr.Properties.IsRequested) return;
                CellEx.Update(cr.Cells[idx + 1], -diff, true);
            });
        });
    }
}

export function updateTotals(
    view: ITopRow,
    group: IGroupRow,
    columnIndex: number,
    idx: number,
    diff: number,
    workType: WorkType,
    container: IGroupContainerRow,
    categoryHasAllocations: boolean,
    dontUpdateTotalRow?: boolean,
): void {
    const adjescantCellIndex = workType === WorkType.Request ? 1 : 0;

    const _updateTotals = (row: IBaseRow) => {
        const cell = row.CellRows[columnIndex].Cells[idx];
        const adjescantCell = row.CellRows[columnIndex].Cells[adjescantCellIndex];
        // update view totals
        CellEx.Update(cell, diff, true);
        if (adjescantCell == null) return;
        adjescantCell.ReRender = true;
        // handle category allocations
        if (categoryHasAllocations != null) {
            cell.Properties.ContainsCategory = categoryHasAllocations;
            adjescantCell.Properties.ContainsCategory = categoryHasAllocations;
        }
    };

    // update view total if shown
    if (!dontUpdateTotalRow && view.CellRows.length > 0) {
        _updateTotals(view);
    }

    // update container totals
    if (container) {
        _updateTotals(container);
    }

    // update group totals
    _updateTotals(group);
}

export function getRowAndCellRow(view: ITopRow, cell: ICell, columnIndex: number): [IGroupContainerRow, IGroupRow, IRow, ICellRow] {
    const flattenedGroups = groupsFlattened(view);
    let container: IGroupContainerRow;
    let group: IGroupRow;
    let row: IBaseRow;
    let cellRow: ICellRow;
    for (const g of flattenedGroups) {
        for (const r of g.Rows) {
            if (r.Id !== cell.Properties.ResourceId || r.Properties.ContractId !== cell.Properties.ContractId) continue;
            row = r;
            cellRow = r.CellRows[columnIndex];
            group = g;
            if (g.Properties.ContainerId) container = flattenedGroups.find(_ => _.Id === g.Properties.ContainerId) as unknown as IGroupContainerRow;
            break;
        }
        if (row != null) break;
    }

    return [container, group, row, cellRow];
}

export function forceRefreshCells(topRow: ITopRow) {
    topRow.Groups.forEach(group => {
        if (group.TypeName === 'GroupRow') {
            (group as IGroupRow).Rows.forEach(row => {
                row.CellRows.forEach(cr => {
                    cr.Cells.forEach(cell => {
                        cell.ReRender = true;
                    });
                });
            });
        } else if (group.TypeName === 'GroupContainerRow') {
            (group as IGroupContainerRow).Groups.forEach(g => {
                g.Rows.forEach(row => {
                    row.CellRows.forEach(cr => {
                        cr.Cells.forEach(cell => {
                            cell.ReRender = true;
                        });
                    });
                });
            });
        }
    });
}

export function buildSaveCellDto(
    resolution: TimelineResolution,
    type: WorkType,
    cell: Cell,
    parent: CellRow,
    groupName: string,
    containerName: string,
): IAddActivityDto {
    return {
        Start: parent.Start,
        End: parent.End,
        Type: type,
        Work: NumberEx.getValueOrDefault(cell.Value),
        ContractId: parent.Properties.ContractId,
        ContractGroupName: containerName,
        ResourceId: parent.Properties.ResourceId,
        ProjectId: parent.Properties.ProjectId,
        ResourceName: parent.Properties.ResourceName,
        ContractName: groupName,
        Resolution: resolution,
    };
}

export function saveCell(dto: AddActivityDto, cellId: string, errorHandler: (msg: string) => void, ignoreContainerName?: boolean): void {
    if (ignoreContainerName) dto.ContractGroupName = null;
    Plugin.Invoke<any>(PluginIDs.SaveProjectCapacityCell, dto)
        .then(_ => {
            highlightCell([cellId], true, 'highlightdone');
        })
        .catch(_ => {
            // NotificationService.Instance.Error(language.Common.FailedSavingCells);
            errorHandler(language.Common.FailedSavingCells);
            highlightCell([cellId], true, 'highlighterror');
        });
}

export function saveCells(dtos: IAddActivityDto[], cellIds: string[], errorHandler: (msg: string) => void, ignoreContainerName?: boolean): void {
    if (ignoreContainerName) dtos.forEach(_ => (_.ContractGroupName = null));
    Plugin.Invoke<any>(PluginIDs.SaveProjectCapacityCells, dtos)
        .then(_ => {
            highlightCell(cellIds, true, 'highlightdone');
        })
        .catch(_ => {
            // NotificationService.Instance.Error(language.Common.FailedSavingCells);
            errorHandler(language.Common.FailedSavingCells);
            highlightCell(cellIds, true, 'highlighterror');
        });
}

export function highlightCell(cellIds: string[], removeBorder?: boolean, highlightClass: string = 'highlight', includeDisabledCells?: boolean) {
    if (cellIds == null) return;
    cellIds.forEach(_ => {
        const element = document.querySelector(
            `.tp-capacity-cell-container input[data-cellid="${_}"] ${includeDisabledCells ? `, div[data-cellid="${_}"]` : ''}`,
        ) as HTMLElement;
        if (!element) return;
        element.classList.add(highlightClass);
        setTimeout(() => {
            element.classList.remove(highlightClass);
        }, 1000);

        if (removeBorder) element.style.border = 'unset';
    });
}

export function findGroup(view: ITopRow, groupId: string): IGroupRow {
    let group: IGroupRow;
    view.Groups.forEach(_ => {
        if (group) return;
        if (_.Id === groupId) {
            group = { ..._, Rows: [], TypeName: 'GroupRow' };
            return;
        } else if (_.TypeName === 'GroupContainerRow') {
            (_ as IGroupContainerRow).Groups.forEach(g => {
                if (g.Id === groupId) {
                    group = { ...g, Rows: [] };
                    return;
                }
            });
        }
    });
    return group;
}

export function getActivityColor(view: ITopRow, cell: ICell, index: number, columnIndex: number, cellType: CellType, theme: IExtendedTheme): string {
    if (cell == null) return '';

    let otherCell: Cell;
    switch (cellType) {
        case CellType.Top: {
            if (!view || !view.CellRows[columnIndex]) return;
            otherCell = view.CellRows[columnIndex].Cells[index === 0 ? 1 : 0];
            break;
        }
        case CellType.Group: {
            let group = view.Groups.find(_ => _.Id === cell.Properties.ContractId);
            group = group || findGroup(view, cell.Properties.ContractId);
            if (!group || !group.CellRows[columnIndex]) return;
            otherCell = group.CellRows[columnIndex].Cells[index === 0 ? 1 : 0];
            break;
        }
        case CellType.Row:
            return;
        case CellType.Container: {
            const container = view.Groups.find(_ => _.Id === cell.Properties.ContainerId);
            if (!container || !container.CellRows[columnIndex]) return;
            otherCell = container.CellRows[columnIndex].Cells[index === 0 ? 1 : 0];
            break;
        }
    }

    // define values to compare
    const decimals =
        (UserContext.Resource.PreferredUnitType === UnitType.FTE ? OverviewContext.Settings.GridDecimalsFTE : OverviewContext.Settings.GridDecimalsHours) ?? 2;
    const request = NumberEx.round(index === 0 ? cell.Value : otherCell?.Value, decimals);
    const allocation = NumberEx.round(index === 1 ? cell.Value : otherCell?.Value, decimals);

    if (request < allocation) return theme.semanticColors.activityOverBackground;
    else if (request > allocation) return theme.semanticColors.activityUnderBackground;
    else if (request === allocation)
        if (cell.Properties.ContainsCategory && allocation > 0) return theme.semanticColors.activityEqualContainsCategoryBackground;
        else return theme.semanticColors.activityEqualBackground;
    return '';
}

export function getGridDecimals(): number {
    const unittype = UnitTypeUtil.getCurrentUnitType();
    switch (unittype) {
        case UnitType.FTE:
            return OverviewContext.Settings.GridDecimalsFTE;
        case UnitType.Hours:
            return OverviewContext.Settings.GridDecimalsHours;
        default:
            return OverviewContext.Settings.GridDecimalsHours;
    }
}

export function groupsFlattened(view: ITopRow): IGroupRow[] {
    const groups: IGroupRow[] = [];
    view.Groups.forEach(_ => {
        if (_.TypeName === 'GroupRow') groups.push(_ as IGroupRow);
        else if (_.TypeName === 'GroupContainerRow') groups.push(...(_ as unknown as IGroupContainerRow).Groups);
    });
    return groups;
}

export async function addResource(
    view: ITopRow,
    projectId: string,
    resourceId: string,
    groupName: string,
    groupId: string,
    start: Date,
    end: Date,
    resolution: TimelineResolution,
    gridType: GridType,
    infoHandler: (msg: string) => void,
    relatedGenericResourceId?: string,
    useAutoGrouping = false,
    groupBy?: string,
    alterNewRow?: (row: IRow) => void,
): Promise<ITopRow> {
    if (!resourceId || !groupName || !view) return;

    const flattenedGroups = groupsFlattened(view);

    // if only allowed to request a resource on time on a given project
    if (gridType === GridType.Request && OverviewContext.Settings.SingletonOnRequests) {
        const groupWithGenRes = flattenedGroups.find(g => g.Rows.some(r => r.Properties.ResourceType === ResourceType.Generic && r.Id === resourceId));
        if (groupWithGenRes != null) {
            // NotificationService.Instance.Info(language.Common.ResourceAlreadyAssignedToProject);
            infoHandler(language.Common.ResourceAlreadyAssignedToProject);
            return view;
        }
    }

    let newGroup = false;
    let group: IGroupRow;
    // should always null out group when adding resources for request!! (ewi)
    // only one generic resource allowed pr. contract - this will force creation of a new contract if contract already have a generic resource
    // if ((gridType == GridType.Request || gridType == GridType.WorkPackage)) // && group && group.Rows.some(_ => _.Properties.ResourceType == ResourceType.Generic))
    // 	group = null;
    // specific group
    if (groupId) {
        group = flattenedGroups.find(_ => _.Id === groupId);
        // a specific group has been chosen and the resource is already added to that contract
        if (resourceAlreadyAssigned(group, resourceId, gridType)) {
            infoHandler(language.Common.ResourceAlreadyAssignedToContract);
            return view;
        }
    } else {
        // no group selected - dynamic group
        // use the resource it self to locate a suitable contract
        if (OverviewContext.Settings.EnableRequestNamedResources) group = group || flattenedGroups.find(g => g.Properties.RequestedResourceId === resourceId);
        if (resourceAlreadyAssigned(group, resourceId, gridType)) group = null;
        // use the resoruce's generic resoruce to locate a suitable contract
        group =
            group ||
            flattenedGroups.find(g =>
                g.Rows.some(r => r.Properties.ResourceType === ResourceType.Generic && relatedGenericResourceId && r.Id === relatedGenericResourceId),
            );
        if (resourceAlreadyAssigned(group, resourceId, gridType)) group = null;
        // use the contract name to locate a suitable contract
        group = group || flattenedGroups.find(_ => _.Name === groupName);
        if (resourceAlreadyAssigned(group, resourceId, gridType)) group = null;
    }

    if (!group) {
        newGroup = true;
        group = {
            CellRows: JsonEx.clone(view.CellRows) as ICellRow[],
            Properties: { ResourceId: resourceId, RequestedResourceId: gridType === GridType.Request ? resourceId : null },
            Id: guid.newGuid(),
            Name: groupName,
            Rows: [],
            TypeName: 'GroupRow',
        };
        group.CellRows.forEach(_ =>
            _.Cells.forEach(_ => {
                CellEx.Update(_, 0);
                _.Type = CellType.Group;
                _.Properties = { ContractId: group.Id, ProjectId: projectId };
            }),
        );

        if (!groupBy) {
            // handle contract autogrouping
            if (useAutoGrouping && !StringEx.isNullOrWhiteSpace(OverviewContext.Settings.AutoGroupContractBy)) {
                const entity = await Plugin.Invoke<Record<string, string>>(PluginIDs.GetEntityDetails, {
                    EntityId: resourceId,
                    EntityType: EntityType.Resource,
                    OverrideSelectedProperties: [OverviewContext.Settings.AutoGroupContractBy],
                });
                const containerName = entity[OverviewContext.Settings.AutoGroupContractBy.replace('CustomProperties.', '')];
                if (StringEx.isNullOrWhiteSpace(containerName)) view.Groups.push(group);
                else {
                    const id = guid.newGuid();
                    const container: IGroupContainerRow = {
                        Id: id,
                        CellRows: JsonEx.clone(view.CellRows) as ICellRow[],
                        Groups: [group],
                        Name: containerName,
                        TypeName: 'GroupContainerRow',
                        Properties: {
                            ContainerId: id,
                        },
                        Rows: [],
                    };
                    container.CellRows = JsonEx.clone(view.CellRows) as CellRow[];
                    container.CellRows.forEach(_ =>
                        _.Cells.forEach(_ => {
                            CellEx.Update(_, 0);
                            _.Type = CellType.Group;
                            _.Properties = { ContractId: group.Id, ProjectId: projectId };
                        }),
                    );
                    group.Properties.ContainerId = container.Id;
                    view.Groups.push(container);
                }
            } else view.Groups.push(group);
        }
    }

    LocalStorage.setProperty('tp-grid-collapsed', `${view.Id}-${group.Id}`, false);

    if (group.Properties.RequestedResourceId !== resourceId && gridType === GridType.Request && group.Properties.HasRequests) {
        infoHandler(language.Common.RequestedResourceAlreadyAssignedToContract);
        return view;
    }

    if (!group.Rows.find(_ => _.Id === resourceId) || group.Properties.RequestedResourceId === resourceId) {
        const resourceRow = await Plugin.Invoke<IRow>(PluginIDs.GetNewResourceRow, {
            ProjectId: projectId,
            ContractId: group.Id,
            ResourceId: resourceId,
            Start: start,
            End: end,
            DateResolution: resolution,
            GridType: gridType,
            GroupBy: groupBy,
        });
        if (alterNewRow) alterNewRow(resourceRow);
        resourceRow.Properties.IsNew = true;
        group.Properties.RequestedResourceType = resourceRow.Properties.RequestedResourceType;
        if (resourceRow) group.Rows.push(resourceRow);
        // when dynamic groupby is enabled
        if (groupBy && newGroup) {
            if (resourceRow.Properties.ContainerName) {
                const container = view.Groups.find(
                    _ => _.TypeName === 'GroupContainerRow' && _.Name === resourceRow.Properties.ContainerName,
                ) as IGroupContainerRow;
                if (container) {
                    container.Groups.push(group);
                } else {
                    const id = guid.newGuid();
                    const container: IGroupContainerRow = {
                        TypeName: 'GroupContainerRow',
                        Groups: [group],
                        Id: id,
                        Name: resourceRow.Properties.ContainerName,
                        CellRows: JsonEx.clone(group.CellRows) as CellRow[],
                        Properties: {
                            ContainerId: id,
                        },
                        Rows: [],
                    };
                    view.Groups.push(container);
                }
            } else view.Groups.push(group);
        }
    }
    return view;
}
