/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useState, useEffect, useContext, useRef } from 'react';
import { hasVerticalOverflow } from '@fluentui/react/lib/Utilities';
import { Plugin } from '../../../../Services/PluginInvoker';
import { PluginIDs } from '../../../../Utilities/PluginIDs';
import { TimelineResolution } from '../../../../Entities/TimelineResolution';
import { TopRow } from '../../../../Entities/Table/TopRow';
import Table from '../../../../Components/Overview/Table/Table';
import { Cell } from '../../../../Entities/Table/Cell';
import { WorkType } from '../../../../Entities/Dto/AddActivityDto';
import { OverviewContext } from '../../../../Utilities/Context/OverviewContext';
import { GridType } from '../../../../Entities/GridType';
import { EntityType } from '../../../../Entities/EntityTypes';
import { ResourceEx } from '../../../../Utilities/ResourceEx';
import { Row } from '../../../../Entities/Table/Row';
import { CellType } from '../../../../Entities/Table/CellType';
import { GroupRow } from '../../../../Entities/Table/GroupRow';
import { ProjectCapacityService } from '../../../../Services/ProjectCapacityService';
import { PFSpinner } from '../../../../Components/Common/Spinner';
import { Notification, NotificationStatusType } from '../../../../Entities/Notification';
import { MessageBarButtonInfo } from '../../../../Entities/Fabric/MessageBarButtonInfo';
import { Link, IContextualMenuItem, Icon } from '@fluentui/react';
import { GroupContainerRow } from '../../../../Entities/Table/GroupContainerRow';
import { guid } from '../../../../helpers/guid';
import { StoreContext } from '../../../../Services/StateStore';
import { useTheme } from '../../../../Themes/themeContext';
import { ProjectType } from '../../../../Entities/ProjectType';
import { EntityInformationCallout } from '../../../../Components/Common/EntityInformationCallout';
import { EventEx } from '../../../../Utilities/EventEx';
import { Text } from '@fluentui/react/lib/Text';
import { ResourceType } from '../../../../Entities/ResourceType';
import { CapacityUtil } from '../../../../Utilities/CapacityUtil';
import { language } from '../../../../Services/LocalizationService';
import CompareScenariosDialog from '../../../../Components/Common/ProjectVersionsDialog';
import { CellRow } from '../../../../Entities/Table/CellRow';
import { ArrayEx } from '../../../../Utilities/ArrayEx';
import { activeProposalStatesWithoutDraft } from '../../../../Entities/Versions/ProposalState';
import { HeaderRow } from '../../../../Entities/Table/HeaderRow';
import { IDs } from '../../../../Utilities/IDs';
import { AddVersionedActivityDto } from '../../../../Entities/Dto/Versions/AddVersionedActivityDto';
import { BaseRow } from '../../../../Entities/Table/BaseRow';
import { UserSettingsService } from '../../../../Services/Settings/UserSettingsService';
import { ResolutionUtil } from '../../../../Utilities/ResolutionUtil';
import { NumberParser } from '../../../../Utilities/NumberParser';
import { DateEx } from '../../../../Utilities/DateEx';
import { GridSettings } from '../../../../Utilities/GridSettings';
import { CellEx } from '../../../../Utilities/CellEx';
import { VersionedProjectCapacityService } from '../../../../Services/VersionedProjectCapacityService';
import { useNavigate, useParams } from 'react-router';
import { useStore } from '../../../../context/store';
import shallow from 'zustand/shallow';
import { IEntityInfoCallout } from '../../../../context/contexts/entityCalloutContext';
import { useUISettings } from '../../../../context/network/http/QueryProvider/queries/UISettings';
import { useCurrentProject } from '../../../../context/network/http/QueryProvider/queries/project';
import { useCheckTPPermissions } from '../../../../hooks/usePermissions';
import { upperCaseObj } from '../../../../context/network/http/QueryProvider/helpers/queryHelper';
import { Timeout } from '../../../../types/runtimeTypes';
import { UserType, ProposalState } from '../../../../api/generated/data-contracts';

const numberParser = new NumberParser(navigator.language);

interface IProps {
    gridType: GridType;
}

export const CompareScenarios: FunctionComponent<IProps> = ({ gridType }) => {
    const { projectId, scenarioIds } = useParams<{ projectId: string; scenarioIds: string }>();
    const { data: uiSettings } = useUISettings();
    const { data: project } = useCurrentProject(projectId);
    const checkPermissions = useCheckTPPermissions();
    const goTo = useNavigate();
    const { setUIContext, setTimelineInStore, timeline, addNotification, error, info, warning } = useStore(
        store => ({
            setUIContext: store.setUIContext,
            setTimelineInStore: store.setTimeline,
            timeline: store.timeline,
            error: store.addErrorNotification,
            info: store.addInfoNotification,
            warning: store.addWarningNotification,
            addNotification: store.addNotification,
        }),
        shallow,
    );

    const [versionIds, setVersionIds] = useState(scenarioIds?.split('|') ?? []);

    const [view, _setView] = useState<TopRow>();
    const viewRef = useRef<TopRow>(view);
    const [loading, _setLoading] = useState(true);
    const adminRef = useRef<boolean>(false);
    const [name, setName] = useState<string>('');
    const ignoreResolutionMismatch = useRef(false);
    const [detailDto, setDetailDto] = useState<IEntityInfoCallout>(null);
    const versionIdsRef = useRef<Array<string>>(versionIds);

    const [showVersionsDialog, setShowVersionsDialog] = useState<boolean>(false);

    const setView = (view: TopRow) => {
        if (view != null) view.ChangeKey = IDs.makeId();
        viewRef.current = view; // keep updated
        _setView(view);
    };

    const setLoading = (loadingg: boolean) => {
        _setLoading(loadingg);
    };

    const setAdminProject = (isAdmin: boolean) => {
        adminRef.current = isAdmin; // keep updated
    };

    const _setVersionIds = async (versions: Array<string>) => {
        // same same
        versionIdsRef.current = versions;
        setVersionIds(versions);
        setLoading(true);
        if (!versionIdsRef.current?.length) {
            goTo(-1);
        }
        await LoadCapacity(false, versions);
    };

    const ctx = useContext(StoreContext);
    const theme = useTheme();

    useEffect(() => {
        if (loading) {
            setUIContext({ bottomHasScroll: false });
        } else {
            setUIContext({ bottomHasScroll: hasVerticalOverflow(document.getElementById('bottom-main')) });
        }
    }, [loading]);

    useEffect(() => {
        setLoading(true);
        const load = async () => await LoadCapacity(false, versionIdsRef.current);
        load();
    }, [timeline]);

    useEffect(() => {
        if (ArrayEx.simpleCompare(versionIds, versionIdsRef.current)) return;
        _setVersionIds(versionIds);
        OverviewContext.RefreshProjectCapacity = ForceRefreshData;
    }, [gridType, versionIds]);

    const ForceRefreshData = async () => {
        setLoading(true);
        await LoadCapacity(true, versionIdsRef.current);
    };

    //TODO: refactor - looks like crap! - ewi
    const LoadCapacity = async (isRefresh?: boolean, scenarioIds?: Array<string>): Promise<void> => {
        if (!projectId) {
            setLoading(false);
            return;
        }
        if (!project) {
            setView(null);
            setLoading(false);
            return;
        }

        // check if user has permission to request on the selected project
        if (gridType === GridType.Request) {
            OverviewContext.UserIsPmForSelectedProject = await ResourceEx.projectMatch(project, uiSettings.resource);
            if (
                (!OverviewContext.UserIsPmForSelectedProject && !checkPermissions({ userTypes: [UserType.SuperUser] })) ||
                project.projectType === ProjectType.Administrative
            ) {
                info(language.ProjectCapacity.Project.Select);
                setName(project.name);
                setView(null);
                setLoading(false);
                return;
            }
        }

        // get capacity grid
        const model =
            !timeline?.start || !timeline?.end
                ? null
                : await GetCapacity(projectId, timeline.start, timeline.end, timeline.resolution as any, gridType, scenarioIds);
        if (!model) {
            setView(null);
            setLoading(false);
            return;
        }

        if (project.projectType === ProjectType.Team) ctx.Set({ ProjectTeamSelected: true });
        else ctx.Set({ ProjectTeamSelected: false });

        setName(project.name);
        setView(model);
        setAdminProject(project.projectType === ProjectType.Administrative);
        setLoading(false);
    };

    const GetCapacity = async (
        projectId: string,
        start: Date,
        end: Date,
        resolution: TimelineResolution,
        gridType: GridType,
        scenarioIds?: Array<string>,
    ): Promise<TopRow> => {
        if (!projectId) return null;
        return await Plugin.Invoke<TopRow>(
            PluginIDs.GetVersionedProjectCapacityView,
            { ScenarioIds: scenarioIds, ProjectId: project.id, Start: start, End: end, DateResolution: resolution, GridType: gridType },
            'Failed getting grid data',
            true,
        );
    };

    const GetActivictyColor = (cell: Cell, index: number, columnIndex: number, cellType: CellType): string => {
        if (adminRef.current) return theme.semanticColors.activityEqualBackground;
        return ProjectCapacityService.GetActivityColor(view, cell, index, columnIndex, cellType, theme);
    };

    // TODO: refactor so theres only one way of updating cell, using an array - same in API - ewi
    const OnCellUpdate = async (
        view: TopRow,
        adminProject: boolean,
        resolution: TimelineResolution,
        workType: WorkType,
        columnIndex: number,
        idx: number,
        cell: Cell,
        newValue: number,
        isUndo?: boolean,
    ): Promise<TopRow> => {
        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);

        const group = flattenedGroups.find(_ => _.Id === cell.Properties.ContractId);
        // check on 'IsRequested' as well due to the fact that we now can have two resourcerows with the same resource (when a resource is both requested and allocated)
        const resource = group.Rows.find(_ => _.Id === cell.Properties.ResourceId && _.Properties.IsRequested === cell.Properties.IsRequested);
        const cellRow = resource.CellRows[columnIndex];

        // handle allocation on different resolution than request
        if (
            OverviewContext.Settings.EnableGridResolutionMismatchWarning &&
            !adminProject &&
            !ignoreResolutionMismatch.current &&
            cellRow.Properties.ResolutionMismatch
        ) {
            ProjectCapacityService.HighlightCell([cell.Id], true, 'highlighterror'); // TODO: this only works when stepping in debug, WHY GOD WHY?? - ewi

            warning(
                language.ProjectCapacity.OnCellUpdate.DifferentTimelineResolution.replace(
                    '[[timelineresolution]]',
                    TimelineResolution[cellRow.Properties.RequestResolution].toString(),
                ),
            );

            const notification = new Notification();
            notification.Message = language.CapacityViews.OnCellUpdate.NotificationMessage;
            notification.StatusType = NotificationStatusType.SevereWarning;
            notification.Buttons = [
                new MessageBarButtonInfo(language.CapacityViews.OnCellUpdate.Disable, () => (ignoreResolutionMismatch.current = true)),
                new MessageBarButtonInfo(language.ProjectCapacity.OnCellUpdate.Switch, () =>
                    OverviewContext.ChangeResolution(cellRow.Properties.RequestResolution),
                ),
            ];
            addNotification(notification);

            return view;
        }

        // get container if any
        const container =
            group.Properties.ContainerId !== guid.empty ? (view.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow) : null;

        ProjectCapacityService.UpdateCellDomValue(
            view,
            flattenedGroups,
            container,
            group,
            resource,
            cellRow,
            workType,
            columnIndex,
            idx,
            newValue,
            false,
            true,
        );

        // save cell
        const dto = ProjectCapacityService.BuildSaveCellDto(resolution, workType, cell, cellRow, group.Name, container?.Name);
        const versionedContractId = await VersionedProjectCapacityService.saveCell(
            cellRow.Properties.VersionedId,
            dto,
            cellRow.Properties.ScenarioId,
            ProposalState.ProposedChanged,
            cell.Id,
            error,
        );

        // set versionedContractId on all cellrows and their cells
        resource.CellRows.forEach(cr => {
            cr.Properties.VersionedId = versionedContractId;
            cr.Cells.forEach(c => (c.Properties.VersionedId = versionedContractId));
        });

        cell.Properties.ConfirmedAnimation = false;
        cell.Properties.ProposalStates = [ProposalState.ProposedChanged];
        return view;
    };

    const TopCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 0, colIdx, CellType.Top)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return {
                    field: { backgroundColor: `${GetActivictyColor(cell, 1, colIdx, CellType.Top)} !important` },
                    root: { paddingRight: gridType === GridType.Allocation && !adminRef.current ? null : '10px' },
                };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' } };
            },
        ];
        if (adminRef.current) styles.splice(0, 1);
        return styles;
    };

    const GroupCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 0, colIdx, CellType.Group)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return {
                    field: { backgroundColor: `${GetActivictyColor(cell, 1, colIdx, CellType.Group)} !important` },
                    root: {
                        paddingRight: gridType === GridType.Allocation && !adminRef.current ? null : '10px',
                        cursor: gridType === GridType.Request ? 'pointer' : '',
                    },
                };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' } };
            },
        ];
        if (adminRef.current) styles.splice(0, 1);
        return styles;
    };

    const GroupContainerCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 0, colIdx, CellType.Container)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return {
                    field: { backgroundColor: `${GetActivictyColor(cell, 1, colIdx, CellType.Container)} !important` },
                    root: {
                        paddingRight: gridType === GridType.Allocation && !adminRef.current ? null : '10px',
                        cursor: gridType === GridType.Request ? 'pointer' : '',
                    },
                };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' } };
            },
        ];
        if (adminRef.current) styles.splice(0, 1);
        return styles;
    };

    const RowCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return !cell.Properties.GrandTotal
                    ? null
                    : { root: { backgroundColor: document.documentElement.style.getPropertyValue('--rowHoverBackgroundColor') } };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: gridType === GridType.Allocation && !adminRef.current ? null : '10px' } };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' }, field: { backgroundColor: `${CapacityUtil.GetFreeCapacityColor(theme, cell.Value)} !important` } };
            },
        ];
        if (adminRef.current) styles.splice(0, 1);
        return styles;
    };

    const CellUpdates = () => {
        const updates = [
            async (cell: Cell, columnIndex: number, value: number, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const updatedModel = await OnCellUpdate(view, adminRef.current, timeline.resolution as any, WorkType.Request, columnIndex, 0, cell, value);
                setView({ ...updatedModel });
            },
            async (cell: Cell, columnIndex: number, value: number, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const updatedModel = await OnCellUpdate(
                    view,
                    adminRef.current,
                    timeline.resolution as any,
                    WorkType.Allocation,
                    columnIndex,
                    adminRef.current ? 0 : 1,
                    cell,
                    value,
                );
                setView({ ...updatedModel });
            },
        ];
        if (adminRef.current) updates.splice(0, 1);
        return updates;
    };

    // TODO: make this a stable ref
    let _timer: Timeout;
    const OnProjectHover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        _timer = EventEx.delay(
            _timer,
            () => {
                setDetailDto({
                    Id: project.id,
                    EntityType: EntityType.Project,
                    Target: '.project-capacity-top-hover',
                    // Properties: { Url: project?.Url }
                    Properties: { Url: project?.url },
                });

                // hack to prevent element losing event when re-rendering
                setTimeout(() => {
                    (document.querySelector('.project-capacity-top-hover') as HTMLElement).addEventListener('mouseleave', OnProjectHoverLeave as any);
                }, 10);
            },
            1000,
        );
    };

    const OnProjectHoverLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        clearTimeout(_timer);
        _timer = null;
        setDetailDto(null);
        (document.querySelector('.project-capacity-top-hover') as HTMLElement).removeEventListener('mouseleave', OnProjectHoverLeave as any);
    };

    const RowSort = (group: GroupRow): Array<Row> => {
        group.Rows.sort((a, b) => {
            // if resourcetype is the same, sort by name
            if (a.Properties.ResourceType === b.Properties.ResourceType && a.Properties.IsRequested === b.Properties.IsRequested)
                return a.Name.localeCompare(b.Name);
            // generics is first
            if (a.Properties.IsRequested) return -1;
            // then requested that is also allocated
            if (a.Id === group.Properties.RequestedResourceId && !b.Properties.IsRequested) return -1;
            // then named
            if (a.Properties.ResourceType === ResourceType.Named && !b.Properties.IsRequested && b.Id !== group.Properties.RequestedResourceId) return -1;
            // last category and teams
            else return 1;
        });
        return group.Rows;
    };

    const GetExtraMenuItems = (): Array<IContextualMenuItem> => {
        const menuItems: Array<IContextualMenuItem> = [];

        menuItems.push({
            key: 'scenario',
            text: language.ProjectCapacity.MenuItems.Scenario.Text,
            title: language.ProjectCapacity.MenuItems.Scenario.Title,
            iconProps: { iconName: 'MultiSelect' },
            subMenuProps: {
                items: [
                    {
                        key: 'versions',
                        text: language.ProjectCapacity.CompareScenarios,
                        title: language.ProjectCapacity.CompareScenarios,
                        iconProps: { iconName: 'Compare' },
                        onClick: (ev, item) => setShowVersionsDialog(true),
                    },
                ],
            },
        });

        return menuItems;
    };

    const HeaderExtraContent = (headerRow: HeaderRow): JSX.Element => {
        if (headerRow.ScenarioId == null) return null;
        const cellIndex = gridType === GridType.Request ? 0 : 1;

        return (
            <div className="header-compare-menu" style={{ boxShadow: theme.effects.elevation8 }}>
                <div className="header-compare-menu-content">
                    <div className="compare-menu-button compare-menu-button-confirm">
                        <Icon iconName="BoxCheckmarkSolid" onClick={e => RespondToVersionedColumn(ProposalState.Promoted, headerRow, cellIndex)} />
                    </div>
                    <div className="compare-menu-button compare-menu-button-cancel">
                        <Icon
                            iconName="BoxMultiplySolid"
                            onClick={e => {
                                RespondToVersionedColumn(ProposalState.Rejected, headerRow, cellIndex);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const GetOriginalCell = (cellIndex: number, cellRow: CellRow, row: BaseRow): [CellRow, Cell, number] => {
        const cellRowIndex = row.CellRows.indexOf(cellRow);
        let originalCellRowIndex = cellRowIndex;
        while (originalCellRowIndex > 0) {
            originalCellRowIndex--;
            const cr = row.CellRows[originalCellRowIndex];
            if (cr.Properties.IsVersioned) continue;
            return [cr, cr.Cells[cellIndex], originalCellRowIndex];
        }
        return null;
    };

    const GetApproveAnimationCellCount = (row: Row, cellRow: CellRow): number => {
        // set versioned columns, used for animations
        const index = row.CellRows.findIndex(_ => _.Id === cellRow.Id);
        const colCount = (index % (versionIdsRef.current.length + 1)) * cellRow.Cells.length;
        return colCount;
    };

    const RespondToVersionedColumn = async (state: ProposalState, header: HeaderRow, cellIndex: number) => {
        // update original cell
        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);
        flattenedGroups.forEach(group => {
            group.Rows.forEach(row => {
                // TODO: match should support multiple columns (ewi)
                const cellRow = row.CellRows.find(
                    cr =>
                        cr.Properties.ScenarioId === header.ScenarioId &&
                        new Date(cr.Start).getTime() === new Date(header.Start).getTime() &&
                        !cr.Properties.ColumnIsFrozen &&
                        !cr.Cells[cellIndex].Disabled,
                );

                if (cellRow != null) {
                    const originalCell = GetOriginalCell(cellIndex, cellRow, row)[1];
                    if (cellRow.Cells[cellIndex].Value === originalCell.Value) return;
                    const colCount = GetApproveAnimationCellCount(row, cellRow);

                    if (state === ProposalState.Promoted) {
                        // // set versioned columns, used for animations
                        GridSettings.SetVersionedAnimationCellCount(colCount);

                        cellRow.Cells[cellIndex].Properties.ConfirmedAnimation = true;
                        cellRow.Cells[cellIndex].ReRender = true;
                    } else {
                        RespondToVersionedCell(
                            state,
                            cellRow.Cells[cellIndex],
                            row.CellRows.findIndex(_ => _.Id === cellRow.Id),
                            cellIndex,
                        );
                    }
                }
            });
        });

        //update view
        setView({ ...view });
    };

    const RespondToVersionedCell = async (state: ProposalState, cell: Cell, columnIndex: number, cellIndex: number) => {
        const [container, group, row, cellRow] = ProjectCapacityService.GetRowAndCellRow(view, cell, columnIndex);

        const workType = gridType === GridType.Request ? WorkType.Request : WorkType.Allocation;
        const dto = ProjectCapacityService.BuildSaveCellDto(
            timeline.resolution as any,
            workType,
            cell,
            cellRow,
            cellRow.Properties.ContractName,
            null,
        ) as AddVersionedActivityDto;
        dto.VersionedContractId = cell.Properties.VersionedId ?? guid.newGuid();
        dto.ScenarioId = cell.Properties.ScenarioId;
        dto.State = state;

        await VersionedProjectCapacityService.upsertCell(dto, cell.Id, error);

        // state guard
        if (state !== ProposalState.Promoted && state !== ProposalState.Rejected) return;

        // update 'dom' elements
        cell.Properties.ProposalStates = [state];
        cell.Properties.VersionedId = dto.VersionedContractId;
        cell.Properties.ScenarioId = dto.ScenarioId;
        cell.Properties.IsVersioned = true;

        cell.Properties.ConfirmedAnimation = false;
        const [originalCellRow, originalCell, originalCellRowIndex] = GetOriginalCell(cellIndex, cellRow, row);

        // get worktype
        const cellWorkType = cellIndex === 0 ? WorkType.Request : WorkType.Allocation;
        // if category resource has allocations (unset for other types)
        const categoryHasAllocations = row.Properties.ResourceType !== ResourceType.Category ? null : cell.Value > 0;

        let diff = 0;
        if (state === ProposalState.Promoted) {
            diff = cell.Value - originalCell.Value;
            originalCell.Value = cell.Value;
            // update original totals
            ProjectCapacityService.UpdateTotals(view, group, originalCellRowIndex, cellIndex, diff, cellWorkType, container, categoryHasAllocations, false);
            // update free cap on original
            if (workType === WorkType.Allocation) CellEx.Update(originalCellRow.Cells[cellIndex + 1], -diff, true);
        } else if (state === ProposalState.Rejected) {
            diff = originalCell.Value - cell.Value;
            cell.Value = originalCell.Value;
            // update original totals
            ProjectCapacityService.UpdateTotals(view, group, originalCellRowIndex + 1, cellIndex, diff, cellWorkType, container, categoryHasAllocations, false);
            // update free cap on original
            if (workType === WorkType.Allocation) CellEx.Update(cellRow.Cells[cellIndex + 1], -diff, true);
        }

        cell.ReRender = true;

        //update view
        setView({ ...view });
    };

    const CellExtraContent = (cell: Cell, columnIndex: number, cellIndex: number): JSX.Element => {
        if (cell.Disabled) return;

        // render if different from original value
        // prettier-ignore
        const [ /*container*/, /*group*/, row, cellRow ] = ProjectCapacityService.GetRowAndCellRow(view, cell, columnIndex);
        const originalCell = GetOriginalCell(cellIndex, cellRow, row)[1];

        if (cell.Value === originalCell.Value) return;

        // set versioned columns, used for animations
        const colCount = GetApproveAnimationCellCount(row, cellRow);

        return (
            <CellCompareMenuOptions
                cell={cell}
                columnIndex={columnIndex}
                cellIndex={cellIndex}
                cellTotalIndex={colCount}
                respondToVersionedCell={RespondToVersionedCell}
                resetAnimation={() => (cell.Properties.ConfirmedAnimation = false)}
            />
        );
    };

    return (
        <>
            {loading ? (
                <PFSpinner />
            ) : (
                view && (
                    <div>
                        <Table
                            Name={name}
                            Model={view}
                            ContainerClassName={`project-capacity-versioned versioned-columns${versionIdsRef.current.length}`}
                            CellUpdates={CellUpdates()}
                            TopCellStyles={TopCellStyles()}
                            GroupCellStyles={GroupCellStyles()}
                            GroupContainerCellStyles={GroupContainerCellStyles()}
                            RowCellStyles={RowCellStyles()}
                            TitleContentOverride={(title: string) => (
                                <>
                                    <div className="project-capacity-top-hover" onMouseEnter={OnProjectHover} onMouseLeave={OnProjectHoverLeave}>
                                        <Text className="tp-capacity-project-title">
                                            <div className="truncate-text">{title}</div>
                                        </Text>
                                    </div>
                                </>
                            )}
                            GridSettingsExtraMenuItems={GetExtraMenuItems()}
                            GroupSort={(groups: Array<GroupRow>) => groups.sort((a, b) => a.Name.localeCompare(b.Name))}
                            RowSort={RowSort}
                            RowClassName={(row: Row) => `${row.Properties.IsRequested ? 'tp-capacity-resource-generic' : ''}`}
                            AllowContainerCollapse
                            AllowGroupCollapse
                            GridCellDecimals={ProjectCapacityService.GetGridDecimals()}
                            CellExtraContent={CellExtraContent}
                            ColumnHeaderExtraContent={(headerRow: HeaderRow) => HeaderExtraContent(headerRow)}
                            GridSettingsSpacingAmount={OverviewContext.Settings.GridSpacing}
                            GridSettingsHidePersonaIcons={OverviewContext.Settings.HidePersonaIconsInGrid}
                            SetGridSettingsHidePersonaIconsCallback={value =>
                                UserSettingsService.UpdateUserSettingsPartial({ HidePersonaIconsInGrid: value }, null, true)
                            }
                            SetGridSettingsSpacingAmountCallback={value => UserSettingsService.UpdateUserSettingsPartial({ GridSpacing: value }, null, true)}
                            EnableGridSettings={OverviewContext.Settings.EnableGridSettings}
                        />
                        {/* Cannot use the callout in main, due to state vs. onmouseleave fuckups!!
							So placing one specific for this view here! #theonlyway? - ewi */}
                        <EntityInformationCallout Dto={detailDto}>
                            {detailDto && detailDto.Properties.Url && (
                                <Link className="ms-CalloutExample-link" href={detailDto.Properties.Url} target="_blank">
                                    {language.ProjectCapacity.GoToProjectAtOrigin}
                                </Link>
                            )}
                        </EntityInformationCallout>
                        <CompareScenariosDialog
                            show={showVersionsDialog}
                            projectId={projectId}
                            states={activeProposalStatesWithoutDraft}
                            versionIds={versionIdsRef.current}
                            gridType={gridType}
                            callback={(newVersionIds, start, end) => {
                                if (start != null && end != null) {
                                    const freezeDate = ResolutionUtil.getStartUsingFreezePeriod(
                                        upperCaseObj(uiSettings.settings.freezePeriod),
                                        timeline.resolution as any,
                                        uiSettings.settings.useISO8601,
                                    );
                                    const minStart = freezeDate > start ? freezeDate : start;
                                    // don't refresh dates if null or min
                                    if (DateEx.isNullOrMin(minStart) || DateEx.isNullOrMin(end)) setVersionIds(newVersionIds);
                                    else setVersionIds(newVersionIds);
                                    setTimelineInStore({ start: minStart, end });
                                } else {
                                    setVersionIds(newVersionIds);
                                    _setVersionIds(newVersionIds); // auto refreshes grid
                                }
                                setShowVersionsDialog(false);
                            }}
                            dismissCallback={() => setShowVersionsDialog(false)}
                        />
                    </div>
                )
            )}
            {/* TODO: add coachmarks!!  */}
        </>
    );
};

interface ICellCompareMenuOptionsProps {
    cell: Cell;
    columnIndex: any;
    cellIndex: any;
    respondToVersionedCell: any;
    cellTotalIndex: number;
    resetAnimation: () => void;
}

// TODO: what about error handling? (ewi)
const CellCompareMenuOptions: React.FC<ICellCompareMenuOptionsProps> = props => {
    const theme = useTheme();

    const [isConfirmed, setIsConfirmed] = useState(false);
    const isAnimating = useRef(false);
    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        setIsConfirmed(props.cell.Properties.ConfirmedAnimation);
    }, [props.cell.Properties.ConfirmedAnimation]);

    useEffect(() => {
        if (!isConfirmed) {
            props.resetAnimation();
            return;
        }

        isAnimating.current = true;

        setTimeout(() => {
            if (isMounted.current) {
                props.respondToVersionedCell(ProposalState.Promoted, props.cell, props.columnIndex, props.cellIndex);
                isAnimating.current = false;
            }
        }, 1000);

        return () => {
            if (isConfirmed && isAnimating.current) {
                props.respondToVersionedCell(ProposalState.Promoted, props.cell, props.columnIndex, props.cellIndex);
            }
        };
    }, [isConfirmed]);

    return (
        <>
            <div className="cell-compare-menu" style={{ boxShadow: theme.effects.elevation8 }}>
                <div className="cell-compare-menu-content">
                    <div className="compare-menu-button compare-menu-button-confirm">
                        <Icon
                            iconName="BoxCheckmarkSolid"
                            onClick={e => {
                                GridSettings.SetVersionedAnimationCellCount(props.cellTotalIndex);
                                setIsConfirmed(true);
                            }}
                        />
                    </div>
                    <div className="compare-menu-button compare-menu-button-cancel">
                        <Icon
                            iconName="BoxMultiplySolid"
                            onClick={e => props.respondToVersionedCell(ProposalState.Rejected, props.cell, props.columnIndex, props.cellIndex)}
                        />
                    </div>
                </div>
            </div>
            <div className={`confirm-overlay ${isConfirmed ? 'is-confirmed-overlay' : ''}`}>
                {numberParser.ParseToString(props.cell.Value, ProjectCapacityService.GetGridDecimals())}
            </div>
        </>
    );
};
