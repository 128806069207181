import { Stack, Text } from '@fluentui/react';
import { useEffect, useMemo, useState } from 'react';
import { ShimmerBox } from './ShimmerBox';

const FIRST_COLUMN_WIDTH = 300;
const SECOND_COLUMN_WIDTH = 190;
const COLUMN_WIDTH = 270;

export const GridSkeleton = ({ maxCols = 5, offset, minCols = 1 }: { maxCols?: number; minCols?: number; offset?: number }) => {
    const cols = useMaxColsOnScreen({ max: maxCols, offset, min: minCols });
    return (
        <Stack styles={{ root: { height: '100%', cursor: "wait" } }}>
            <Stack className="testing-test" styles={{ root: { backgroundColor: 'var(--tableBackground)', paddingBottom: 15 } }}>
                <HeaderRow cols={cols} />
                <Row cols={cols} />

                <RowSpacer />
                <Row cols={cols} backgroundColor={'var(--rowGroupBackground)!important'} />
                <Row cols={cols} />

                <RowSpacer />
                <Row cols={cols} backgroundColor={'var(--rowGroupBackground)!important'} />
                <Row cols={cols} />
                <Row cols={cols} />

                <RowSpacer />
                <Row cols={cols} backgroundColor={'var(--rowGroupBackground)!important'} />
                <Row cols={cols} />
                <Row cols={cols} />
                <Row cols={cols} />

                <RowSpacer />
                <Row cols={cols} backgroundColor={'var(--rowGroupBackground)!important'} />
                <Row cols={cols} />
            </Stack>
        </Stack>
    );
};

const HeaderRow = ({ cols }: { cols: number }) => {
    return (
        <Stack horizontal styles={{ root: { flexWrap: 'no-wrap', height: 80 } }}>
            <Stack
                styles={{
                    root: {
                        minWidth: FIRST_COLUMN_WIDTH,
                        borderRight: '1px solid var(--tableBorderColorVertical)',
                        borderBottom: '1px solid var(--tableBorderColorVertical)',
                        backgroundColor: '#fff',
                    },
                }}
                verticalAlign="center"
                horizontal
            >
                <Text styles={{ root: { fontSize: 16, fontWeight: 600, paddingLeft: 30, marginRight: 10 } }}>
                    <i>Loading..</i>
                </Text>
            </Stack>
            <Stack
                styles={{
                    root: {
                        minWidth: SECOND_COLUMN_WIDTH,
                        backgroundColor: 'var(--rowGroupBackground)!important',
                        borderRight: '1px solid var(--tableBorderColorVertical)',
                        borderBottom: '1px solid var(--tableBorderColorVertical)',
                        paddingBottom: 5,
                        paddingTop: 5,
                    },
                }}
                horizontalAlign="center"
            >
                <Stack verticalAlign="center" styles={{ root: { height: '80%' } }}>
                    <Text styles={{ root: { fontSize: 16, fontWeight: 600 } }}>Total</Text>
                </Stack>
                <Stack
                    horizontal
                    horizontalAlign="space-around"
                    styles={{ root: { height: '20%', width: '100%', color: 'rgba(0,0,0,0.5)', span: { color: 'inherit' } } }}
                >
                    <ShimmerBox width={30} height={13} />
                    <ShimmerBox width={30} height={13} />
                </Stack>
            </Stack>
            {Array.from({ length: cols }, (_, i) => {
                return (
                    <Stack
                        styles={{
                            root: {
                                minWidth: COLUMN_WIDTH,
                                borderRight: '1px solid var(--tableBorderColorVertical)',
                                borderBottom: '1px solid var(--tableBorderColorVertical)',
                                backgroundColor: '#fff',
                                paddingTop: 5,
                            },
                        }}
                        horizontalAlign="center"
                        verticalAlign="space-around"
                        key={i}
                    >
                        <ShimmerBox width={50} height={13} />
                        <ShimmerBox width={80} height={18} />
                        <Stack
                            horizontal
                            horizontalAlign="space-around"
                            styles={{
                                root: { height: '20%', width: '100%', color: 'rgba(0,0,0,0.5)', span: { color: 'inherit' }, paddingLeft: 10, paddingRight: 10 },
                            }}
                        >
                            <ShimmerBox width={30} height={13} />
                            <ShimmerBox width={30} height={13} />
                            <ShimmerBox width={30} height={13} />
                        </Stack>
                    </Stack>
                );
            })}
        </Stack>
    );
};

const Row = ({ cols, backgroundColor = '#fff' }: { cols: number; backgroundColor?: string }) => {
    return (
        <Stack horizontal styles={{ root: { flexWrap: 'no-wrap', height: 45 } }}>
            <Stack
                styles={{
                    root: {
                        minWidth: FIRST_COLUMN_WIDTH,
                        borderRight: '1px solid var(--tableBorderColorVertical)',
                        borderBottom: '1px solid var(--tableBorderColorVertical)',
                        backgroundColor,
                    },
                }}
                verticalAlign="center"
            >
                <Stack styles={{ root: { width: '70%', paddingLeft: 30 } }}>
                    <ShimmerBox width={80} height={25} />
                </Stack>
            </Stack>
            <Stack
                styles={{
                    root: {
                        minWidth: SECOND_COLUMN_WIDTH,
                        backgroundColor: 'var(--rowGroupBackground)!important',
                        borderRight: '1px solid var(--tableBorderColorVertical)',
                        borderBottom: '1px solid var(--tableBorderColorVertical)',
                    },
                }}
                horizontalAlign="center"
                verticalAlign="center"
            >
                <Stack horizontal horizontalAlign="space-around" styles={{ root: { width: '100%', paddingLeft: 10, paddingRight: 10 } }}>
                    <ShimmerBox width={75} height={30} />
                    <ShimmerBox width={75} height={30} />
                </Stack>
            </Stack>
            {Array.from({ length: cols }, (_, i) => {
                return (
                    <Stack
                        styles={{
                            root: {
                                minWidth: COLUMN_WIDTH,
                                borderRight: '1px solid var(--tableBorderColorVertical)',
                                borderBottom: '1px solid var(--tableBorderColorVertical)',
                                backgroundColor,
                            },
                        }}
                        horizontalAlign="center"
                        verticalAlign="space-around"
                        key={i}
                    >
                        <Stack horizontal horizontalAlign="space-around" styles={{ root: { width: '100%', paddingLeft: 10, paddingRight: 10 } }}>
                            <ShimmerBox width={75} height={30} />
                            <ShimmerBox width={75} height={30} />
                            <ShimmerBox width={75} height={30} />
                        </Stack>
                    </Stack>
                );
            })}
        </Stack>
    );
};

const RowSpacer = () => {
    return <Stack styles={{ root: { height: 10 } }} />;
};

type IuseMaxColsOnScreen = {
    offset?: number;
    max?: number;
    min?: number;
}


const useMaxColsOnScreen = ({ offset = FIRST_COLUMN_WIDTH + SECOND_COLUMN_WIDTH, max = Infinity, min = -Infinity }: IuseMaxColsOnScreen = {}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth - offset);
    useEffect(() => {
        const handler = () => {
            setWindowWidth(window.innerWidth - offset);
        };
        window.addEventListener('resize', handler);
        return () => {
            window.removeEventListener('resize', handler);
        };
    }, [offset]);
    const cols = useMemo(() => {
        const cols = Math.floor(windowWidth / COLUMN_WIDTH);
        if (cols <= max && cols >= min) {
            return cols;
        }
        if (cols <= min) {
            return min;
        }
        return max;
    }, [max, min, windowWidth]);
    return cols;
};
