 export enum ResourceOrderTypes {
	Priority = 0,
	Prefer = 1,
	MostFreeCapacity = 2,
	AllocatedToProject = 4,
	PreviousAllocatedToProject = 8,
	FutureAllocatedToProject = 16
}

export enum DistributionTechnique {
	ShortAndFat = 0,
	Evenly = 1
}

export enum ResourceTechnique {
	FillFromTop = 0,
	SpreadOut = 1,
	SpreadOutPreferredFirst = 2
}

export enum DistributionResultType {
	Success = 1 << 0,
	NotPerfect = 1 << 1,
	Failed = 1 << 2,
	RemovedTooSmall = 1 << 3,
	UsedPeriodEvenlyVariation = 1 << 4
}