import {
	Checkbox,
	DefaultButton,
	Label,
	Panel,
	PanelType,
	PrimaryButton, Spinner,
	SpinnerSize,
	Stack
} from '@fluentui/react';
import { memo, useCallback, useMemo } from 'react';

type OverviewPanelProps<T extends { [key: string]: any; }> = {
	handleSaveItem: (item?: T) => any;
	onDismissPanel: () => void;
	// panelContext: TPanelContext<T>;
	panelItem: T;
	savingItem: boolean;
	children: React.ReactNode;
	onGetPanelTitle?: (item: T) => string;
	showSaveButtom: boolean;
	saveButtonText?: string;
	keepOpen?: (keep: boolean) => void;
};
export const OverviewPanel = memo(
	<T extends { [key: string]: any; }>({ handleSaveItem, onDismissPanel, panelItem: panelContext, savingItem, children, onGetPanelTitle, showSaveButtom, saveButtonText, keepOpen }: OverviewPanelProps<T>) => {
		const onClickSave = useCallback(() => void handleSaveItem(), [handleSaveItem]);
		const onRenderFooterContent = useCallback(() => {
			return (
				<Stack horizontal tokens={{ childrenGap: 10 }}>
					{showSaveButtom && <PrimaryButton onClick={onClickSave} disabled={savingItem}>{saveButtonText ?? 'Save'}</PrimaryButton>}
					<DefaultButton onClick={onDismissPanel} disabled={savingItem}>Cancel</DefaultButton>
					{keepOpen && <><Checkbox onChange={(_, checked) => keepOpen(checked)} /><Label>Keep panel open</Label></>}
					{savingItem && <Spinner size={SpinnerSize.large} />}
				</Stack>
			);
		}, [keepOpen, onClickSave, onDismissPanel, saveButtonText, savingItem, showSaveButtom]);

		const headerText = useMemo(() => {
			if (onGetPanelTitle) {
				return onGetPanelTitle(panelContext);
			}
		}, [onGetPanelTitle, panelContext]);

		return (
			<Panel
				//
				headerText={headerText}
				isOpen={Boolean(panelContext)}
				onDismiss={onDismissPanel}
				onRenderFooterContent={onRenderFooterContent}
				isFooterAtBottom
				type={PanelType.medium}
			>
				{children}
			</Panel>
		);
	}
);
