import React from 'react';
import DynamicTextField from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicTextField';
import DynamicComponent from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicComponent';
import DynamicDatePicker from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicDatePicker';
import DynamicCheckbox from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicCheckbox';
import DynamicDictionary from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicDictionary';
import DynamicDropdown from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicDropdown';
import DynamicSimpleArray from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicSimpleArray';
import DynamicObjectArray from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicObjectArray';
import { PropertyType } from './PropertyInfo/PropertyType';
import { PropertyInfo } from './PropertyInfo/PropertyInfo';
import { TooltipAfter, Tooltip } from '../Components/Common/Tooltip';
import DynamicSlider from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicSlider';
import DynamicObject from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicObject';
import DynamicTextFieldCopyValue from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicTextFieldCopyValue';
import DynamicToggle from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicToggle';
import { FirstWeekOfYear } from '@fluentui/react';
import { DateEx } from './DateEx';
import DynamicColorPicker2 from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicColorPicker2';
import { DebugOverrides } from './DebugOverrides';
import DynamicCron from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicCron';
import DynamicJson from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicJson';
import DynamicMessageBar from '../Components/Common/DynamicLoaded/DynamicPropertyComponents/DynamicMessageBar';

// eslint-disable-next-line react-refresh/only-export-components
const KeyGen = (propertyInfo: PropertyInfo, idx: number, keyPrefix: string): string =>
    `tp-objecteditor-${propertyInfo.Grouping ? propertyInfo.Grouping.Name.replace(/\s/g, '') : 'root'}-${propertyInfo.PropertyName}-${
        keyPrefix ? keyPrefix + '-' : ''
    }`;

export class EditorHandler {
    public static RenderPFProperties = (
        properties: Array<PropertyInfo>,
        parentComponent: any,
        keyPrefix: string = '',
        forceReadOnly: boolean = false,
        propertyExtraContent?: (propName: string, readonly: boolean) => JSX.Element,
        propertyFilterLevel?: number,
    ): any => {
        return properties
            .sort((a, b) => (a.Order >= b.Order ? 1 : -1))
            .map((propertyInfo, idx) => {
                if (!propertyInfo) return null;

                const props = {
                    Parent: parentComponent.state.Item,
                    Value: parentComponent.state.Item[propertyInfo.PropertyName],
                    Key: KeyGen(propertyInfo, idx, keyPrefix),
                    OnChange: (obj, value) => parentComponent.OnItemChange(propertyInfo.PropertyName, value),
                    ReadOnly:
                        forceReadOnly ||
                        parentComponent.props.ReadOnly ||
                        (parentComponent.props.DisabledProperties && parentComponent.props.DisabledProperties.indexOf(propertyInfo.PropertyName) >= 0) ||
                        (propertyInfo.Disable && propertyInfo.Disable(parentComponent.state.Item)),
                    PropertyInfo: propertyInfo,
                    Required:
                        propertyInfo.Validation != null &&
                        propertyInfo.Validation.IsRequired != null &&
                        propertyInfo.Validation.IsRequired(parentComponent.state.Item) &&
                        !parentComponent.props.ReadOnly,
                    PropertyFilterLevel: propertyFilterLevel,
                };

                // override readonly, making ALL properties editable
                if (DebugOverrides.Enabled) props.ReadOnly = false;

                const renderExtraContent = propertyExtraContent;

                return propertyInfo.Type === PropertyType.Boolean ||
                    propertyInfo.Type === PropertyType.Color ||
                    propertyInfo.Type === PropertyType.MessageBar ? (
                    <TooltipAfter key={`${props.Key}-tooltip`} TooltipText={propertyInfo.TooltipText} ExtraClass={renderExtraContent ? 'sticky' : ''}>
                        {renderExtraContent && propertyExtraContent(propertyInfo.PropertyName, props.ReadOnly)}
                        {EditorHandler.GetComponent(propertyInfo, props, parentComponent)}
                    </TooltipAfter>
                ) : (
                    <Tooltip
                        key={`${props.Key}-tooltip`}
                        LabelText={propertyInfo.DisplayName}
                        TooltipText={propertyInfo.TooltipText}
                        Required={props.Required}
                        ExtraClass={renderExtraContent ? 'sticky' : ''}
                    >
                        {renderExtraContent && propertyExtraContent(propertyInfo.PropertyName, props.ReadOnly)}
                        {EditorHandler.GetComponent(propertyInfo, props, parentComponent)}
                    </Tooltip>
                );
            });
    };

    public static DateFieldFirstDayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 1;
    public static DateFieldFirstWeekOfYear: FirstWeekOfYear = FirstWeekOfYear.FirstFourDayWeek;

    public static GetComponent = (propertyInfo: PropertyInfo, props: any, parentComponent: any): JSX.Element => {
        switch (propertyInfo.Type) {
            case PropertyType.String:
                return <DynamicTextField {...props} {...propertyInfo.ComponentProps} />;
            case PropertyType.StringCopyValue:
                return <DynamicTextFieldCopyValue {...props} {...propertyInfo.ComponentProps} />;
            case PropertyType.Date: {
                // TODO: move out of here!!
                const firstDayOfWeek = DateEx.firstDayNumberOfWeek();
                const firstDayOfYear = DateEx.firstDayOfYear();

                let firstWeekRule: FirstWeekOfYear;
                switch (firstDayOfYear) {
                    case 1:
                        firstWeekRule = FirstWeekOfYear.FirstDay;
                        break;
                    case 4:
                        firstWeekRule = FirstWeekOfYear.FirstFourDayWeek;
                        break;
                    case 6:
                        firstWeekRule = FirstWeekOfYear.FirstFullWeek;
                        break;
                    default:
                        firstWeekRule = FirstWeekOfYear.FirstFourDayWeek;
                }
                return <DynamicDatePicker {...props} FirstDayOfWeek={firstDayOfWeek} FirstWeekOfYear={firstWeekRule} {...propertyInfo.ComponentProps} />;
            }
            case PropertyType.Number:
                return <DynamicTextField {...props} {...propertyInfo.ComponentProps} Type="number" />;
            case PropertyType.Boolean:
                return <DynamicCheckbox {...props} {...propertyInfo.ComponentProps} />;
            case PropertyType.BooleanToggle:
                return <DynamicToggle {...props} {...propertyInfo.ComponentProps} />;
            case PropertyType.Dictionary:
                return <DynamicDictionary {...props} {...propertyInfo.ComponentProps} />;
            case PropertyType.PredefinedArray:
                return <DynamicDropdown {...props} {...propertyInfo.ComponentProps} />;
            case PropertyType.PredefinedArrayMulti:
                return (
                    <DynamicDropdown
                        {...props}
                        {...propertyInfo.ComponentProps}
                        Multiselect
                        OnChange={(obj, option) => parentComponent.OnChangeMultiSelect(propertyInfo.PropertyName, option)}
                    />
                );
            case PropertyType.SimpleArray:
                return <DynamicSimpleArray {...props} {...propertyInfo.ComponentProps} />;
            case PropertyType.ObjectArray:
                return <DynamicObjectArray {...props} {...propertyInfo.ComponentProps} />;
            case PropertyType.Object:
                return propertyInfo.CustomComponentName ? (
                    <DynamicComponent {...props} {...propertyInfo.ComponentProps} />
                ) : (
                    <DynamicObject {...props} {...propertyInfo.ComponentProps} />
                );
            case PropertyType.Color:
                return <DynamicColorPicker2 {...props} {...propertyInfo.ComponentProps} />;
            case PropertyType.NumberSlider:
                return <DynamicSlider {...props} {...propertyInfo.ComponentProps} />;
            case PropertyType.Cron:
                return <DynamicCron {...props} {...propertyInfo.ComponentProps} />;
            case PropertyType.Json:
                return <DynamicJson {...props} {...propertyInfo.ComponentProps} />;
            case PropertyType.MessageBar:
                return <DynamicMessageBar {...props} {...propertyInfo.ComponentProps} />;
            default:
                return null;
        }
    };
}
