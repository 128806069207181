/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { Selection, IColumn, SelectionMode, DetailsList } from '@fluentui/react/lib/DetailsList';
import { ProjectScenario, Scenario, ScenarioType } from '../../Entities/Scenario';
import { PFDialog } from './PFDialog';
import { Label, IconButton } from '@fluentui/react';
import { DateEx } from '../../Utilities/DateEx';
import { Plugin } from '../../Services/PluginInvoker';
import { PluginIDs } from '../../Utilities/PluginIDs';
import { GridType } from '../../Entities/GridType';
import { Confirm } from '../../Utilities/Confirm';
import { language } from '../../Services/LocalizationService';
import { ScenarioApprovalType } from '../../Entities/Versions/ScenarioApprovalType';
import { DataService } from '../../Services/DataService';
import { EntityType } from '../../Entities/EntityTypes';
import { UserContext } from '../../Utilities/Context/UserContext';
import { Permission } from '../../Services/Permission';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { ProposalState } from '../../api/generated/data-contracts';

interface IProps {
    show: boolean;
    callback: (versionIds: Array<string>, start: Date, end: Date) => void;
    dismissCallback: () => void;
    projectId: string;
    versionIds: Array<string>;
    gridType: GridType;
    states: Array<ProposalState>;
    onlyMine?: boolean;
}

const CompareScenariosDialog: FunctionComponent<IProps> = props => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [selectedVersionIds, setSelectedVersionIds] = useState<Array<string>>([]);
    const [allProjectScenarios, setAllProjectScenarios] = useState<Array<ProjectScenario>>([]);
    const selection = useMemo(
        () =>
            new Selection<ProjectScenario>({
                selectionMode: SelectionMode.multiple,
                getKey: _ => _.id,
                items: allProjectScenarios ?? [],
                onSelectionChanged: () => {
                    const selectedIds = selection.getSelection().map(_ => (_ as ProjectScenario).id);
                    setSelectedVersionIds(selectedIds);
                },
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        setShowDialog(props.show);
        if (!props.show) return;

        const fetch = async () => {
            const result = await Plugin.Invoke<Array<{ Start: Date; End: Date; Scenario: ProjectScenario }>>(PluginIDs.GetScenarios, {
                ScenarioType: ScenarioType.ProjectScenario,
                ProjectId: props.projectId,
                FilterOnRbs: props.gridType === GridType.Allocation,
                GridType: props.gridType,
                States: props.states,
                OwnerId: props.onlyMine ? UserContext.Resource.id : null,
            });
            const scenarios = result.map(_ => {
                _.Scenario.Start = _.Start;
                _.Scenario.End = _.End;
                return _.Scenario;
            });
            setAllProjectScenarios(scenarios);
        };
        fetch();
    }, [props.show]);

    useEffect(() => {
        props.versionIds.forEach(_ => selection.setKeySelected(_, true, false));
    }, [allProjectScenarios]);

    useEffect(() => {
        setSelectedVersionIds(props.versionIds);
    }, [props.versionIds]);

    const getApprovalType = () => {
        // superusers can approve the entirety
        if (Permission.IsSuperuser()) return ScenarioApprovalType.Both;

        if (props.gridType === GridType.Request) return ScenarioApprovalType.Request;
        if (props.gridType === GridType.Allocation) return ScenarioApprovalType.Allocation;
    };

    const OnApprove = (item: Scenario) => {
        // confirm dialog
        Confirm.Instance.Show({
            show: true,
            title: language.Common.AreYouSure,
            subtext: language.ProjectVersionsDialog.AreYouSureYouWantToMode.replace('[[mode]]', 'APPROVE'),
            confirmAction: async () => {
                // approve
                await Plugin.Invoke(PluginIDs.EntireScenarioApproval, {
                    ScenarioId: item.id,
                    ApprovalType: getApprovalType(),
                    State: ProposalState.Promoted,
                });
                const clone = [...allProjectScenarios];
                const itemClone = clone.find(_ => _.id === item.id);
                itemClone.State = ProposalState.Promoted;
                setAllProjectScenarios(clone);

                OverviewContext.RefreshProjectCapacity();
            },
            //TODO DEFAULT DISMISS ACTION?
            dismissAction: () => {},
            okButtonText: language.Common.Ok,
            cancelButtonText: language.Common.Cancel,
            hideCancelButton: true,
        });
    };

    const OnReject = (item: Scenario) => {
        // confirm dialog
        Confirm.Instance.Show({
            show: true,
            title: language.Common.AreYouSure,
            subtext: language.ProjectVersionsDialog.AreYouSureYouWantToMode.replace('[[mode]]', 'REJECT'),
            confirmAction: async () => {
                // approve
                await Plugin.Invoke(PluginIDs.EntireScenarioApproval, {
                    ScenarioId: item.id,
                    ApprovalType: getApprovalType(),
                    State: ProposalState.Rejected,
                });
                const clone = [...allProjectScenarios];
                const itemClone = clone.find(_ => _.id === item.id);
                itemClone.State = ProposalState.Rejected;
                setAllProjectScenarios(clone);
            },
            //TODO DEFAULT DISMISS ACTION?
            dismissAction: () => {},
            okButtonText: language.Common.Ok,
            cancelButtonText: language.Common.Cancel,
            hideCancelButton: true,
        });
    };

    const OnDelete = (item: Scenario) => {
        // confirm dialog
        Confirm.Instance.Show({
            show: true,
            title: language.Common.AreYouSure,
            subtext: language.ProjectVersionsDialog.AreYouSureYouWantToMode.replace('[[mode]]', 'DELETE'),
            confirmAction: async () => {
                // delete
                await DataService.Delete(EntityType.Scenario, [item]);
                const clone = [...allProjectScenarios];
                const index = clone.findIndex(_ => _.id === item.id);
                clone.splice(index, 1);
                setAllProjectScenarios(clone);
            },
            //TODO DEFAULT DISMISS ACTION?
            dismissAction: () => {},
            okButtonText: language.Common.Ok,
            cancelButtonText: language.Common.Cancel,
            hideCancelButton: true,
        });
    };

	const Dismiss = () => {};

    return (
        <PFDialog
            title={language.Common.Scenarios}
            showDialog={showDialog}
            className="tp-versiondialog"
            callback={() => {
                const selectedVersions = allProjectScenarios.filter(_ => selectedVersionIds.indexOf(_.id) >= 0);
                const startDates = selectedVersions.map(_ => new Date(_.Start)) as any[];
                const endDates = selectedVersions.map(_ => new Date(_.End)) as any[];
                const start = startDates?.length ? new Date(Math.min(...startDates)) : null;
                const end = endDates?.length ? new Date(Math.max(...endDates)) : null;
                props.callback(selectedVersionIds, start, end);
                Dismiss();
            }}
            dismissCallback={() => {
                props.dismissCallback();
                Dismiss();
            }}
            minWidth={1000}
            cancelButtonText="Close"
            buttonDefaultDisabled={true}
            okButtonEnabled={selectedVersionIds.length !== 0}
        >
            <>
                <Label>{language.ProjectVersionsDialog.SelectVersionsToCompare}</Label>
                <DetailsList
                    items={allProjectScenarios ?? []}
                    columns={[
                        { key: 'name', name: language.Common.Version, fieldName: 'Name' } as IColumn,
                        { key: 'state', name: language.Common.State, fieldName: 'State', onRender: _ => ProposalState[_.State] } as IColumn,
                        {
                            key: 'start',
                            name: language.Common.Start,
                            fieldName: 'Start',
                            minWidth: 180,
                            onRender: _ => (DateEx.isNullOrMin(_.Start) ? '' : DateEx.asUserLocalWithTime(_.Start)),
                        } as IColumn,
                        {
                            key: 'End',
                            name: language.Common.End,
                            fieldName: 'End',
                            minWidth: 180,
                            onRender: _ => (DateEx.isNullOrMin(_.End) ? '' : DateEx.asUserLocalWithTime(_.End)),
                        } as IColumn,
                        {
                            key: 'approve',
                            name: language.Common.Approval,
                            onRender: (_: Scenario) => (
                                <>
                                    <IconButton iconProps={{ iconName: 'DocumentApproval' }} onClick={event => OnApprove(_)} title="Approve" />
                                    <IconButton iconProps={{ iconName: 'RemoveFromShoppingList' }} onClick={event => OnReject(_)} title="Reject" />
                                </>
                            ),
                        } as IColumn,
                        {
                            key: 'delete',
                            name: language.Common.Delete,
                            onRender: (_: Scenario) => (
                                <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    disabled={_.OwnerId !== UserContext.Resource.id && !Permission.IsSuperuser()}
                                    onClick={event => OnDelete(_)}
                                    title="Delete"
                                />
                            ),
                        } as IColumn,
                    ]}
                    selection={selection as any}
                    listProps={{}}
                    selectionPreservedOnEmptyClick
                />
            </>
        </PFDialog>
    );
};


export default CompareScenariosDialog;
