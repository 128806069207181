import { IconButton, Link, Stack, TextField } from '@fluentui/react';
import { ReactElement, useCallback, useState } from 'react';
import { IFieldConfig, IFieldRenderProps, IFormItem, useFormContext, useWatch } from 'spark-forms';
import { isFullUrl } from '../../../../Utilities/Url';

export interface IHyperlinkFieldProps<T extends IFormItem> extends IFieldRenderProps<T, IFieldConfig<T>, string | undefined> {
    config?: IFieldConfig<T>;
}

export const HyperlinkField = <T extends IFormItem>({ value, fieldName, config, disabled, path }: IHyperlinkFieldProps<T>): ReactElement | null => {
    const [localValue, setLocalValue] = useState<string>(() =>
        value == null ? '' : value,
    );
    const { onChange: onChangeForm } = useFormContext();

    useWatch(path, () => setLocalValue(value), [value]);

    const onChange = useCallback(
        (val: any | undefined) => {
            setLocalValue(val);
            onChangeForm(fieldName, val?.toString());
        },
        [fieldName, onChangeForm],
    );

    return (
        <>
            <HyperlinkTextEditor value={localValue} disabled={disabled} onChange={onChange} {...config?.componentProps?.key} />
        </>
    );
};

export interface IHyperlinkTextEditorProps<T extends IFormItem> {
    value: string;
    disabled: boolean;
    onChange: (value: any | undefined) => void;
    config?: IFieldConfig<T>;
}

export const HyperlinkTextEditor = <T extends IFormItem>({ value, disabled, onChange, config }: IHyperlinkTextEditorProps<T>): ReactElement | null => {
    const [isEditing, setIsEditing] = useState<boolean>(disabled ? false : value.length > 0 ? false : true);
    const [error, setError] = useState<string>('');
    const [_value, _setValue] = useState<string>(value.length > 0 ? value : '');

    const _onChange = useCallback(
        (ev: any, val: any | undefined) => {
            _setValue(val);
        },
        [_setValue],
    );

    const onConfirmChanges = useCallback(() => {
        if (isFullUrl(_value)) {
            setError('');
            setIsEditing(false);
            onChange(_value);
        } else if(_value.length === 0) {
			onChange(_value);
            setIsEditing(false);
		} else {
            setError('This is not a url'); // TODO LOCALIZATION
        }
    }, [_value, onChange]);

    return (
        <Stack horizontal>
            {isEditing ? (
                <>
                    <TextField defaultValue={_value} styles={{ root: { width: '100%' } }} onChange={_onChange} errorMessage={error} />
                    <IconButton iconProps={{ iconName: 'Checkmark' }} onClick={() => onConfirmChanges()} />
                </>
            ) : (
                <>
                    <div style={{ width: '100%', lineHeight: '32px', backgroundColor: disabled ? 'rgb(243, 242, 241)' : undefined }}>
                        <Link target={'_blank'} href={_value}>
                            {_value}
                        </Link>
                    </div>
                    <IconButton iconProps={{ iconName: 'edit' }} onClick={() => setIsEditing(true)} disabled={disabled}/>
                </>
            )}
        </Stack>
    );
};
