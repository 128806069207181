import { StringEx } from './StringEx';

// "It's not possible to ensure the parameter is an enum, because enumerations in TS don't inherit from a common ancestor or interface"
// Hence the '{ [s: number]: string }' is my best bet :)
export function all<T>(enumType: { [s: number]: string }): T[] {
    return Object.keys(enumType)
        .filter(ct => typeof enumType[ct] === 'string' && ct !== 'Unknown')
        .map(it => enumType[it] as T);
}

export function allAsNumber<T>(enumType: { [s: number]: string }): T[] {
    return Object.keys(enumType)
        .filter(ct => typeof enumType[ct] !== 'string' && ct !== 'Unknown')
        .map(it => enumType[it] as T);
}

export function allAsString(enumType: { [s: number]: string }): string[] {
    return Object.keys(enumType)
        .map(key => enumType[key])
        .filter(value => typeof value === 'string') as string[];
}

export function equalsAny<T>(obj: T, ...enumValues: T[]): boolean {
    if (!Array.isArray(enumValues)) return obj === enumValues;
    return enumValues.some(x => {
        return obj === x;
    });
}

export function asString<T>(enumType: { [s: number]: string }, values: T[]): string[] {
    if (values == null) return [];
    return values.map(key => enumType[key as any as number]).filter(value => typeof value === 'string') as string[];
}

export function asJoinedString<T>(enumType: { [s: number]: string }, values: T[]): string {
    if (values == null) return '';
    const stringValues = values.map(key => enumType[key as any as number]).filter(value => typeof value === 'string') as string[];
    return stringValues.join(', ');
}

export function asDisplayString(enumType: { [s: number]: string }): string[] {
    const values = Object.keys(enumType)
        .map(key => enumType[key])
        .filter(value => typeof value === 'string');
    values.forEach(_ => {
        if (StringEx.isAllUpperCase(_)) return;
        _ = _.split(/(?=[A-Z])/).join(' ');
    });
    return values;
}

export function getFlagValues<TEnum>(enumType: { [s: number]: string }, value: TEnum): TEnum[] {
    const enumValues: TEnum[] = [];
    let i = 0;
    let perm: number;
    while (enumType[(perm = 1 << i++)]) {
        if ((value as any) & perm) enumValues.push(perm as any as TEnum);
    }
    return enumValues;
}

export const EnumEx = {
    all,
    allAsNumber,
    allAsString,
    asDisplayString,
    asString,
    equalsAny,
    getFlagValues,
    asJoinedString,
};

export default EnumEx;
