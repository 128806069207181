import { ICell } from "../Entities/Table/Cell";

// export class CellEx {
	
// 	public static Update = (cell: Cell, newValue: number, increment?: boolean) => {
// 		if (increment)
// 			cell.Value += newValue;
// 		else
// 			cell.Value = newValue;
// 		cell.ReRender = true;
// 	}
// }

export function Update(cell: ICell, newValue: number, increment?: boolean) {
	if (increment) {
		cell.Value += newValue;
	} else {
		cell.Value = newValue;
	}
	cell.ReRender = true;
}

export const CellEx = {
	Update
}

