import { useMemo, useState, useCallback, useRef, useLayoutEffect } from 'react';
import { ImmutableRowsMap, InternalGridData, RowsMap } from './grid-types';
import { UpdateCellInfo } from './contexts/gridContext';
import produce, { enableMapSet } from 'immer';
import { useControlledState } from '../../../hooks/useControlledState';
import { filterMapMap, findIndexInMap, findMap, mapMapToArray, reduceMap, someMap } from './helpers/map';
import { GridRow, GridData, GridCell } from '../../../api/generated/data-contracts';
import { useRecoilCallback, Snapshot, RecoilState, useRecoilState, useRecoilValue, atomFamily, atom, selector, MutableSnapshot } from 'recoil';
import Big from 'big.js';
import { forEachAsync } from '../../../helpers/forEachAsync';
import { useEvent } from '../../../hooks/useEvent';
import { reduceMapAsync } from './helpers/mapAsync';

enableMapSet();

type CellValueGetter = (cell: GridCell) => Promise<Big>;
type CellValueSetter = (cell: GridCell, valOrUpdater: Big | ((currentValue: Big) => Big)) => void;

export type RowUpdateInfo = UpdateCellInfo & {
    diff: Big;
    row: Readonly<GridRow>;
    getCellValue: CellValueGetter;
    setCellValue: CellValueSetter;
    totalRows: readonly Readonly<GridRow>[];
    rows: ImmutableRowsMap;
    getRowRelationsToRoot: (rowId: string) => readonly Readonly<GridRow>[];
};

type RowUpdater = (info: RowUpdateInfo) => Promise<void> | void;

export type RowTreeUpdater = (moveInfo: UpdateRowTreeInfo) => Promise<RowsMap | ImmutableRowsMap> | RowsMap | ImmutableRowsMap;

export type RowDeleteInfo = {
    row: GridRow;
    rowsObject: Readonly<RowsMap>;
    totalRows: GridRow[];
    getCellValue: CellValueGetter;
    setCellValue: CellValueSetter;
};

export type AddRowInfo = {
    ancestors: GridRow[];
    rows: RowsMap;
    parentRow?: GridRow;
    abort: (reason?: string) => void;
    /**
     * Useful for lazy loading metadata and update the row later.
     * This function can only be executed once, and throws on further attempts
     */
    updateRows: (...updateRows: GridRow[]) => Promise<void>;
};

type RowsGetter = (rowId: string) => Readonly<GridRow>[];

export type UpdateRowTreeInfo = {
    getSubrows: RowsGetter;
    getSubrowFamily: RowsGetter;
    getRowAncestors: RowsGetter;
    findRow: (cb: (row: Readonly<GridRow>) => boolean) => Readonly<GridRow>;
    findRowIndex: (cb: (row: Readonly<GridRow>) => boolean) => number;
    getRowIndex: (rowId: string) => number;
    getCellValue: CellValueGetter;
    setCellValue: CellValueSetter;
    abortMove: () => void;
    rowsMap: ImmutableRowsMap;
    rowAncestors: Readonly<GridRow>[];
    subrows: Readonly<GridRow>[];
    subrowFamily: Readonly<GridRow>[];
    rowToMove: Readonly<GridRow>;
    newParentIndex: number | null;
    newParentId: string | null;
};

export type OnMoveRowInfo = {
    rowToMove: GridRow;
    rowAncestors: GridRow[];
    subrows: GridRow[];
    subrowFamily: GridRow[];

    newRowAncestors: GridRow[];
    newSubrows: GridRow[];
    newSubrowFamily: GridRow[];
};

export type ExpandedState = {
    [key: string]: boolean;
};

export type VisibleRow = {
    row: Readonly<GridRow>;
    visible: boolean;
    nestingLevel: number;
    expanded: boolean;
    subrows: GridRow[];
    rowRelations: GridRow[];
};

const cleanRows = (rows: GridRow[]) => {
    const rootRows = rows.filter(row => !row.parentRowId);
    const getFamilySubRowsOfRow = (rowId: string) => {
        const subRowFamily: GridRow[] = [];
        const recurse = (rowId: string) => {
            const subRows = rows.filter(row => {
                return row.parentRowId === rowId;
            });
            subRows.forEach(subRow => {
                subRowFamily.push(subRow);
                recurse(subRow.id);
            });
        };
        recurse(rowId);
        return subRowFamily;
    };
    return rootRows.reduce((acc, row) => {
        acc.push(row, ...getFamilySubRowsOfRow(row.id));
        return acc;
    }, [] as GridRow[]);
};

const prepareGridData = (data: GridData, clean?: boolean): InternalGridData => {
    return {
        ...data,
        originalRows: data.rows,
        rows: new Map((clean ? cleanRows(data.rows) : data.rows).map(row => [row.id, row])),
    };
};

const getRowRelationsToRoot = (rowId: string, rows: RowsMap) => {
    const tree: GridRow[] = [];
    let currentAncestor = rows.get(rowId);
    while (currentAncestor) {
        currentAncestor = rows.get(currentAncestor.parentRowId);
        if (currentAncestor) {
            tree.push(currentAncestor);
        }
    }
    return tree;
};

const getFamilySubRowsOfRow = (rowId: string, rows: RowsMap) => {
    const subRowFamily: GridRow[] = [];
    const recurse = (rowId: string) => {
        const subRows = filterMapMap(rows, row => {
            if (row.parentRowId === rowId) {
                return row;
            }
        });
        subRows.forEach(subRow => {
            subRowFamily.push(subRow);
            recurse(subRow.id);
        });
    };
    recurse(rowId);
    return subRowFamily;
};

const getSubRowsOfRow = (rowId: string, rows: RowsMap) => {
    const subRows: GridRow[] = [];
    rows.forEach(row => {
        if (row.parentRowId === rowId) {
            subRows.push(row);
        }
    });
    return subRows;
};

const cleanUpParentRows = async (
    rowId: string,
    rows: RowsMap,
    ancestors: GridRow[],
    onShouldDeleteRow?: (row: GridRow, rows: RowsMap) => Promise<boolean> | boolean,
) => {
    for (const ancestor of ancestors) {
        const siblingRows = getSubRowsOfRow(ancestor.id, rows).filter(an => an.id !== rowId);
        if (siblingRows.length === 0) {
            if (onShouldDeleteRow) {
                if (!(await onShouldDeleteRow(rows.get(ancestor.id), rows))) {
                    return;
                }
            }
            rows.delete(ancestor.id);
        }
    }
};

const getCellAtom = atomFamily<Big, Readonly<GridCell>>({
    key: 'gridCell',
    default: cell => {
        const { value } = cell;
        return new Big((value || 0).toString());
    },
});

export const createCellValueGetter = (snapshot: Snapshot | MutableSnapshot) => (cell: GridCell) => snapshot.getPromise(getCellAtom(cell));
export const createCellValueSetter =
    (set: (recoilVal: RecoilState<Big>, valOrUpdater: Big | ((currVal: Big) => Big)) => void) =>
    (cell: GridCell, valOrUpdater: ((currentValue: Big) => Big) | Big) =>
        set(getCellAtom(cell), valOrUpdater);

export const createCellValueSetGet = (snapshot: MutableSnapshot) => {
    return {
        setCellValue: createCellValueSetter(snapshot.set),
        getCellValue: createCellValueGetter(snapshot),
    };
};

export const useGridCellState = (cell: GridCell) => useRecoilState(getCellAtom(cell));
export const useGridCellValue = (cell: GridCell) => useRecoilValue(getCellAtom(cell));

export const useGetCellValue = () =>
    useRecoilCallback(
        ({ snapshot }) =>
            async (cell: GridCell) => {
                const getter = createCellValueGetter(snapshot);
                return getter(cell);
            },
        [],
    );

export type OnUpdateCellInfo = UpdateCellInfo & {
    relations: GridRow[];
    row: GridRow;
};

export type UseGridProps<AddRowMetadata = any> = {
    data: GridData;
    onUpdateCells?: (infos: OnUpdateCellInfo[]) => Promise<void> | void;
    onUpdateCellTree?: RowUpdater;
    onUpdateCellTreeWhenDeletingRow?: (info: RowDeleteInfo) => Promise<void> | void;
    onDeleteRow?: (row: GridRow, updatedTotalRows: GridRow[]) => Promise<void> | void;
    onShouldDeleteRow?: (row: GridRow, rows: RowsMap) => Promise<boolean> | boolean;
    onAddRow?: (addRowInfo: AddRowInfo, metadata: AddRowMetadata, placeLast?: boolean) => Promise<GridRow | GridRow[] | void> | GridRow | GridRow[] | void;
    onUpdateRowTree?: RowTreeUpdater;
    onMoveRow?: (info: OnMoveRowInfo) => Promise<void> | void;
    autoUpdateTotals?: boolean;
    readonly?: boolean;
    /**
     * Tries to clean up rows that are out of order
     * defaults to false
     */
    cleanRows?: boolean;
};

export const useGrid = <AddRowMetadata = any>({
    data: incomingData,
    onUpdateCells,
    onUpdateCellTree,
    onUpdateCellTreeWhenDeletingRow,
    onDeleteRow,
    onShouldDeleteRow,
    onAddRow,
    autoUpdateTotals = true,
    readonly = false,
    onUpdateRowTree,
    onMoveRow,
    cleanRows: cleanRowsProps,
}: UseGridProps<AddRowMetadata>) => {
    const [data, setData] = useControlledState(() => prepareGridData(incomingData, cleanRowsProps), [incomingData, cleanRowsProps]);
    const { name, headers, rows, metadata, id } = data;

    const { addAction, untable_quietlySpliceUndoStack } = useGridActionHistory();
    // const skipNextExpandedStateUpdate = useRef(false);

    const setRootRows = useCallback(
        async (cb: (rootRows: GridRow[]) => GridRow[] | Promise<GridRow[]>) => {
            const allRows = [...rows.values()];
            const rootRows = allRows.filter(row => !row.parentRowId);
            const newRootRows = await cb(rootRows);
            const newRows = newRootRows.flatMap(row => [row, ...getFamilySubRowsOfRow(row.id, rows)]);
            setData(data => ({ ...data, rows: new Map(newRows.map(row => [row.id, row])) }));
        },
        [rows, setData],
    );

    const [expandedRows, setExpandedRows] = useRecoilState(gridExpandedRows(incomingData.rows));

    const getRowAncestorTreeToRoot = useCallback(
        (rowId: string) => {
            return getRowRelationsToRoot(rowId, rows);
        },
        [rows],
    );

    const getSubRows = useCallback(
        (rowId: string) => {
            return getFamilySubRowsOfRow(rowId, rows);
        },
        [rows],
    );

    const updateCellValue = useRecoilCallback(
        ({ snapshot, gotoSnapshot }) =>
            async (updatesOrUpdate: UpdateCellInfo | UpdateCellInfo[], shouldCallHandler = true) => {
                if (readonly) {
                    throw new Error('Grid is set to readonly');
                }
                const snapshotBeforeUpdate = snapshot.map(snap => snap);
                const releaseSnapshotBeforeUpdate = snapshotBeforeUpdate.retain();
                const updates = Array.isArray(updatesOrUpdate) ? updatesOrUpdate : [updatesOrUpdate];
                let err: any = null;
                try {
                    const undoUpdateInfos: OnUpdateCellInfo[] = [];
                    const updateInfos: OnUpdateCellInfo[] = [];
                    let releaseUpdatedSnapshot: () => void;
                    const updatedSnapshot = await snapshot.asyncMap(async snapshot => {
                        releaseUpdatedSnapshot = snapshot.retain();
                        const { getCellValue, setCellValue } = createCellValueSetGet(snapshot);
                        for (const { cell, cellIndex, columnIndex, newValue, rowId } of updates) {
                            const totalRows = getRowRelationsToRoot(rowId, rows);
                            const currentCellValue = await getCellValue(cell);
                            const diff = newValue.minus(currentCellValue);

                            const row = rows.get(rowId);

                            if (onUpdateCellTree) {
                                await onUpdateCellTree({
                                    cell,
                                    cellIndex,
                                    columnIndex,
                                    diff,
                                    newValue,
                                    row,
                                    rowId,
                                    totalRows,
                                    getCellValue,
                                    setCellValue,
                                    rows,
                                    getRowRelationsToRoot: rowId => getRowRelationsToRoot(rowId, rows),
                                });
                            }
                            setCellValue(cell, newValue);
                            if (autoUpdateTotals) {
                                for (const totalRow of totalRows) {
                                    const totalCell = rows.get(totalRow.id)?.columnRows?.[columnIndex]?.cells?.[cellIndex];
                                    if (totalCell) {
                                        setCellValue(totalCell, value => value.plus(diff));
                                    }
                                }
                            }

                            const info: OnUpdateCellInfo = {
                                cell,
                                cellIndex,
                                columnIndex,
                                newValue,
                                rowId,
                                relations: totalRows,
                                row,
                            };

                            updateInfos.push(info);
                            undoUpdateInfos.push({
                                ...info,
                                newValue: currentCellValue,
                            });
                        }
                    });
                    gotoSnapshot(updatedSnapshot);
                    addAction(
                        async () => {
                            gotoSnapshot(snapshotBeforeUpdate);
                            if (onUpdateCells && shouldCallHandler) {
                                await onUpdateCells(undoUpdateInfos);
                            }
                        },
                        async () => {
                            gotoSnapshot(updatedSnapshot);
                            if (onUpdateCells && shouldCallHandler) {
                                await onUpdateCells(updateInfos);
                            }
                        },
                        () => {
                            releaseUpdatedSnapshot();
                            releaseSnapshotBeforeUpdate();
                        },
                    );
                    if (onUpdateCells && shouldCallHandler) {
                        err = await runVoidCallbackAsync(async () => {
                            await onUpdateCells(updateInfos);
                        });
                    }
                } catch (error) {
                    err = error;
                }
                if (err) {
                    throw err;
                }
            },
        [addAction, autoUpdateTotals, onUpdateCellTree, onUpdateCells, readonly, rows],
    );

    const updateMultipleCells = updateCellValue;

    const removeRow = useRecoilCallback(
        ({ snapshot, gotoSnapshot }) =>
            async (rowId: string) => {
                if (readonly) {
                    throw new Error('Grid is set to readonly');
                }
                if (!onDeleteRow) {
                    throw new Error('A callback to removing rows was not provided');
                }
                const snapshotBeforeUpdate = snapshot.map(snap => snap);
                const releaseSnapshotBeforeUpdate = snapshotBeforeUpdate.retain();
                const currentData = data;
                const currentRows = currentData.rows;
                const deletedRow = currentRows.get(rowId);
                const totalRows = getRowRelationsToRoot(rowId, currentRows);
                let err: any = null;
                try {
                    if (onShouldDeleteRow) {
                        if (!(await onShouldDeleteRow(deletedRow, currentRows))) {
                            return;
                        }
                    }
                    let releaseUpdatedSnapshot: () => void;
                    const updatedSnapshot = await snapshot.asyncMap(async snapshot => {
                        releaseUpdatedSnapshot = snapshot.retain();
                        const { getCellValue, setCellValue } = createCellValueSetGet(snapshot);
                        const row = currentRows.get(rowId);
                        if (autoUpdateTotals) {
                            // subtract totals
                            await forEachAsync(totalRows, async totalRow => {
                                await forEachAsync(totalRow.columnRows, async (columnRow, columnIndex) => {
                                    await forEachAsync(columnRow.cells, async (totalCell, totalCellIndex) => {
                                        const rowCell = row.columnRows[columnIndex]?.cells[totalCellIndex];
                                        if (rowCell) {
                                            const rowCellValue = await getCellValue(rowCell);
                                            setCellValue(totalCell, value => value.minus(rowCellValue));
                                        }
                                    });
                                });
                            });
                        }
                        if (onUpdateCellTreeWhenDeletingRow) {
                            await onUpdateCellTreeWhenDeletingRow({ row, rowsObject: currentRows, totalRows, setCellValue, getCellValue });
                        }
                    });

                    const newData = await produce(currentData, async data => {
                        const currentRows = data.rows;
                        const totalRows = getRowRelationsToRoot(rowId, currentRows);
                        await cleanUpParentRows(rowId, currentRows, totalRows, onShouldDeleteRow);
                        currentRows.delete(rowId);
                    });

                    setData(newData);
                    gotoSnapshot(updatedSnapshot);

                    addAction(
                        async () => {
                            gotoSnapshot(snapshotBeforeUpdate);
                            setData(currentData);
                        },
                        async () => {
                            gotoSnapshot(updatedSnapshot);
                            setData(currentData);
                        },
                        () => {
                            releaseSnapshotBeforeUpdate();
                            releaseUpdatedSnapshot();
                        },
                    );
                    try {
                        await onDeleteRow(deletedRow, totalRows);
                    } catch (error) {
                        setData(currentData);
                        gotoSnapshot(updatedSnapshot);
                        throw error;
                    }
                } catch (error) {
                    err = error;
                }

                if (err) {
                    throw err;
                }
            },
        [addAction, autoUpdateTotals, data, onDeleteRow, onShouldDeleteRow, onUpdateCellTreeWhenDeletingRow, readonly, setData],
    );

    const addRow = useRecoilCallback(
        ({ gotoSnapshot, snapshot }) =>
            async (parentRowId: string, metaData: AddRowMetadata, placeLast = true) => {
                if (!onAddRow) {
                    throw new Error('A callback to adding row was not provided');
                }
                const currentData = data;
                const currentRows = currentData.rows;
                const parentRow = currentRows.get(parentRowId);
                const ancestors = getRowRelationsToRoot(parentRowId, currentRows);

                const snapshotBeforeUpdate = snapshot.map(snap => snap);
                const releaseSnapshotBeforeUpdate = snapshotBeforeUpdate.retain();
                let updated = false;

                /**
                 * This is designed to be called later, useful for e.g lazy loading metadata
                 */
                const updateRows = async (...rows: GridRow[]) => {
                    if (updated) {
                        throw new Error('ILLIGAL FUNCTION CALL: updateRows can only be called 1 time');
                    }
                    updated = true;
                    if (abortAddOperation) {
                        return;
                    }
                    const updatedData = await produce(newData, async data => {
                        const currentRows = data.rows;
                        data.rows = await reduceMapAsync(
                            currentRows,
                            async (_rows, _row, index, key) => {
                                const updatedRow = rows.find(row => _row.id === row.id);
                                if (updatedRow) {
                                    _rows.set(updatedRow.id, updatedRow);
                                } else {
                                    _rows.set(key, _row);
                                }
                                return _rows;
                            },
                            new Map() as Map<string, GridRow>,
                        );
                    });
                    // skipNextExpandedStateUpdate.current = true;
                    // const snapshotBeforeUpdate = snapshot.map(snap => snap);
                    // const releaseSnapshotBeforeUpdate = snapshotBeforeUpdate.retain();
                    untable_quietlySpliceUndoStack(actionIndex, 1, [
                        async () => {
                            gotoSnapshot(snapshotBeforeUpdate);
                            setData(currentData);
                        },
                        async () => {
                            gotoSnapshot(snapshotBeforeUpdate);
                            setData(updatedData);
                        },
                        () => {
                            releaseSnapshotBeforeUpdate();
                        },
                    ]);
                    setData(updatedData);
                };

                let abortAddOperation: string | boolean = false;
                const abort = (reason?: string) => {
                    abortAddOperation = reason || true;
                };
                const newRow = await onAddRow({ ancestors, rows: currentRows, parentRow, abort, updateRows }, metaData);
                if (abortAddOperation || !newRow) {
                    console.log('Add row was aborted early ' + (typeof abortAddOperation === 'string' ? abortAddOperation : ''));
                    return {
                        newRows: Array.from(currentRows.values()),
                        parentRow,
                    };
                }
                const newRows = Array.isArray(newRow) ? newRow : [newRow];
                const newData = produce(currentData, data => {
                    let insertAfter = false;
                    const rows = data.rows;
                    data.rows = reduceMap(
                        rows,
                        placeLast
                            ? (acc, row, index, key) => {
                                  if (row.id === parentRowId) {
                                      insertAfter = true;
                                  }
                                  if (insertAfter && row.id !== parentRowId && row.parentRowId !== parentRowId) {
                                      newRows.forEach(row => {
                                          acc.set(row.id, row);
                                      });
                                      insertAfter = false;
                                  }
                                  acc.set(key, row);
                                  return acc;
                              }
                            : (acc, row, index, key) => {
                                  acc.set(key, row);
                                  if (row.id === parentRowId) {
                                      newRows.forEach(row => {
                                          acc.set(row.id, row);
                                      });
                                  }
                                  return acc;
                              },
                        new Map() as Map<string, GridRow>,
                    );
                });
                // skipNextExpandedStateUpdate.current = true;
                setData(newData);
                const actionIndex = await addAction(
                    async () => {
                        gotoSnapshot(snapshotBeforeUpdate);
                        setData(currentData);
                    },
                    async () => {
                        gotoSnapshot(snapshotBeforeUpdate);
                        setData(newData);
                    },
                    () => {
                        releaseSnapshotBeforeUpdate();
                    },
                );
                return {
                    newRows,
                    parentRow,
                };
            },
        [addAction, data, onAddRow, setData, untable_quietlySpliceUndoStack],
    );

    const moveRow = useRecoilCallback(
        ({ gotoSnapshot, snapshot }) =>
            async (rowId: string, newParentId: string | null = null) => {
                // Prepare state for update
                const snapshotBeforeUpdate = snapshot.map(snap => snap);
                const releaseSnapshotBeforeUpdate = snapshotBeforeUpdate.retain();
                const currentData = data;
                const currentRows = data.rows;

                // Initialize helpers
                const { getRowIndex, findRow, findRowIndex, getRowAncestors, getSubrowFamily, getSubrows } = createRowHelpers(currentRows);

                // Collect and prepare data for update
                const newParentIndex = !newParentId ? null : getRowIndex(newParentId);
                const rowToMove = currentRows.get(rowId);
                const rowAncestors = getRowRelationsToRoot(rowId, currentRows);
                const subrows = getSubRowsOfRow(rowId, currentRows);
                const subrowFamily = getFamilySubRowsOfRow(rowId, currentRows);

                let error: any = null;
                let isMoveAborted = false;
                const abortMove = () => (isMoveAborted = true);
                try {
                    let newRows: RowsMap;
                    let releaseUpdatedSnapshot: () => void;
                    const updatedSnapshot = await snapshot.asyncMap(async snapshot => {
                        releaseUpdatedSnapshot = snapshot.retain();
                        const { getCellValue, setCellValue } = createCellValueSetGet(snapshot);
                        if (onUpdateRowTree) {
                            newRows = (await onUpdateRowTree({
                                rowsMap: currentRows,
                                rowAncestors,
                                subrowFamily,
                                subrows,
                                rowToMove,
                                newParentIndex,
                                newParentId,
                                getRowIndex,
                                findRowIndex,
                                getRowAncestors,
                                getSubrowFamily,
                                getSubrows,
                                getCellValue,
                                setCellValue,
                                abortMove,
                                findRow,
                            })) as RowsMap;
                        } else {
                            newRows = reduceMap(
                                currentRows,
                                (rows, row, i) => {
                                    if (row.id !== rowId) {
                                        rows.set(row.id, row);
                                        if (i === newParentIndex) {
                                            rows.set(rowToMove.id, { ...rowToMove, parentRowId: newParentId });
                                        }
                                    }
                                    return rows;
                                },
                                new Map() as RowsMap,
                            );
                        }
                    });
                    const cleanUp = () => {
                        releaseSnapshotBeforeUpdate();
                        releaseUpdatedSnapshot();
                    };
                    if (isMoveAborted) {
                        cleanUp();
                        return;
                    }
                    const newRowAncestors = getRowRelationsToRoot(rowId, newRows);
                    const newSubrowFamily = getFamilySubRowsOfRow(rowId, newRows);
                    const newSubrows = getSubRowsOfRow(rowId, newRows);

                    const onMoveRowInfo: OnMoveRowInfo = {
                        // rowToMove: updatedRowToMove,
                        rowToMove,
                        rowAncestors,
                        newRowAncestors,

                        subrowFamily,
                        newSubrowFamily,

                        subrows,
                        newSubrows,
                    };

                    const undoOnMoveRowInfo: OnMoveRowInfo = {
                        rowToMove,
                        rowAncestors: newRowAncestors,
                        newRowAncestors: rowAncestors,

                        subrowFamily: newSubrowFamily,
                        newSubrowFamily: subrowFamily,

                        subrows: newSubrows,
                        newSubrows: subrows,
                    };

                    const newData: InternalGridData = {
                        ...data,
                        rows: newRows,
                    };
                    setData(newData);
                    gotoSnapshot(updatedSnapshot);
                    addAction(
                        async () => {
                            setData(currentData);
                            gotoSnapshot(snapshotBeforeUpdate);
                            if (onMoveRow) {
                                await onMoveRow(undoOnMoveRowInfo);
                            }
                        },
                        async () => {
                            setData(newData);
                            gotoSnapshot(updatedSnapshot);
                            if (onMoveRow) {
                                await onMoveRow(onMoveRowInfo);
                            }
                        },
                        cleanUp,
                    );
                    if (onMoveRow) {
                        error = await runVoidCallbackAsync(async () => {
                            await onMoveRow(onMoveRowInfo);
                        });
                    }
                } catch (err) {
                    error = err;
                }
                if (error) {
                    throw error;
                }
            },
        [addAction, data, onMoveRow, onUpdateRowTree, setData],
    );

    const allClosed = useMemo(() => {
        return !someMap(rows, row => expandedRows[row.id]);
    }, [expandedRows, rows]);

    // TODO remove this when trimming the api
    const rowsRelations = useMemo(() => mapMapToArray(rows, row => getRowRelationsToRoot(row.id, rows)), [rows]);
    const maxGridDepth = useMemo(() => Math.max(...rowsRelations.map(tree => tree.length)), [rowsRelations]);

    // We always render all rows and let the individual row decide how to
    // handle its visibility. This is more performant because the render tree is static
    const visibleRows = useMemo(() => {
        return mapMapToArray(rows, (row, i): VisibleRow => {
            const subrowsOfRow = getSubRowsOfRow(row.id, rows);
            const rowRelations = rowsRelations[i];
            // Root rows has no parent row id
            const isRootRow = !row.parentRowId;
            // The row is visible if the parent row is expanded, or is a root row
            const visible = isRootRow || Boolean(expandedRows[row.parentRowId]);
            // root rows with no subrows cannot expand
            const expanded = isRootRow && !subrowsOfRow.length ? false : expandedRows[row.id];

            return {
                row,
                visible,
                nestingLevel: rowRelations.length,
                expanded,
                subrows: subrowsOfRow,
                rowRelations,
            };
        });
    }, [expandedRows, rows, rowsRelations]);

    const getUpdatedData = useRecoilCallback(
        ({ snapshot }) =>
            async (): Promise<GridData> => {
                const getCellValue = createCellValueGetter(snapshot);
                const updatedData = await produce(data, async draft => {
                    await forEachAsync(draft.originalRows, async row => {
                        if (row.columnRows) {
                            await forEachAsync(row.columnRows, async columnRow => {
                                if (columnRow.cells) {
                                    await forEachAsync(columnRow.cells, async cell => {
                                        if (cell !== null) {
                                            const currentValue = await getCellValue(cell);
                                            cell.value = currentValue.toNumber();
                                        }
                                    });
                                }
                            });
                        }
                        if (row.totalColumnRows) {
                            await forEachAsync(row.totalColumnRows, async totalColumnRow => {
                                if (totalColumnRow.cells) {
                                    await forEachAsync(totalColumnRow.cells, async totalCell => {
                                        if (totalCell !== null) {
                                            const currentValue = await getCellValue(totalCell);
                                            totalCell.value = currentValue.toNumber();
                                        }
                                    });
                                }
                            });
                        }
                    });
                });
                return {
                    id: updatedData.id,
                    headers: updatedData.headers,
                    rows: updatedData.originalRows,
                    metadata: updatedData.metadata,
                    name: updatedData.name,
                };
            },
        [data],
    );

    const getRowsMap = useEvent(() => rows as ImmutableRowsMap);

    const gridMethods = useMemo(() => {
        return {
            addRow,
            removeRow,
            /**
             * @deprecated call updateCellValue with an array of updates
             * @description call updateCellValue with an array of updates
             */
            updateMultipleCells,
            updateCellValue,
            getSubRows,
            getRowAncestorTreeToRoot,
            useGridCellState,
            useGridCellValue,
            getUpdatedData,
            moveRow,
            getRowsMap,
            setRootRows,
        };
    }, [addRow, getRowAncestorTreeToRoot, getSubRows, removeRow, updateCellValue, updateMultipleCells, getUpdatedData, moveRow, getRowsMap, setRootRows]);

    const staticGridData = useMemo(() => {
        return {
            name,
            id,
            metadata,
            headers,
        };
    }, [headers, id, metadata, name]);

    const gridData = useMemo(() => {
        return {
            maxGridDepth,
            allClosed,
            visibleRows,
            rowsRelations,
        };
    }, [allClosed, maxGridDepth, rowsRelations, visibleRows]);

    return useMemo(() => {
        return {
            staticGridData,
            gridData,
            gridMethods,
            expandedRows,
            setExpandedRows,
        };
    }, [expandedRows, gridData, gridMethods, setExpandedRows, staticGridData]);
};

const createRowHelpers = (currentRows: RowsMap) => {
    return {
        getRowIndex: (rowId: string) => findIndexInMap(currentRows, row => row.id === rowId),
        findRowIndex: (cb: (row: GridRow) => boolean) => findIndexInMap(currentRows, cb),
        findRow: (cb: (row: GridRow) => boolean) => findMap(currentRows, cb),
        getSubrowFamily: (rowId: string) => getFamilySubRowsOfRow(rowId, currentRows),
        getSubrows: (rowId: string) => getSubRowsOfRow(rowId, currentRows),
        getRowAncestors: (rowId: string) => getRowRelationsToRoot(rowId, currentRows),
    };
};

type CleanUp = () => void;
type UndoFn = () => Promise<void>;
type RedoFn = () => Promise<void>;
type UndoPoint = [UndoFn, RedoFn, CleanUp];

const runVoidCallbackAsync = async (cb: () => Promise<void> | void) => {
    try {
        await cb();
    } catch (error) {
        return error;
    }
};

const pastUndoStackAtom = atom({
    key: 'undostack',
    default: [] as UndoPoint[],
});

const futureRedoStackAtom = atom({
    key: 'redostack',
    default: [] as UndoPoint[],
});

const isInPastAtom = atom({
    key: 'isInPast',
    default: false,
});

const undoStackCountSelector = selector({
    key: 'undostackCount',
    get: ({ get }) => get(pastUndoStackAtom).length,
});

const redoStackCountSelector = selector({
    key: 'redostackCount',
    get: ({ get }) => get(futureRedoStackAtom).length,
});

export const useGridUndo = (maxStackSize = 20) => {
    const redoStackCount = useRecoilValue(redoStackCountSelector);
    const undoStackCount = useRecoilValue(undoStackCountSelector);
    const { purgeStacks } = useGridActionHistory(maxStackSize);
    const [loading, setLoading] = useState(false);
    const undo = useRecoilCallback(
        ({ snapshot, set }) =>
            async () => {
                const past = [...(await snapshot.getPromise(pastUndoStackAtom))];
                const future = [...(await snapshot.getPromise(futureRedoStackAtom))];
                const undoPoint = past.pop();
                if (undoPoint) {
                    const undoFn = undoPoint[0];
                    const err = await runVoidCallbackAsync(undoFn);
                    set(pastUndoStackAtom, past);
                    set(futureRedoStackAtom, [...future, undoPoint]);
                    set(isInPastAtom, true);
                    if (err) {
                        throw err;
                    }
                }
            },
        [],
    );
    const redo = useRecoilCallback(
        ({ snapshot, set }) =>
            async () => {
                const past = [...(await snapshot.getPromise(pastUndoStackAtom))];
                const future = [...(await snapshot.getPromise(futureRedoStackAtom))];
                const redoPoint = future.pop();
                if (redoPoint) {
                    const redoFn = redoPoint[1];
                    const err = await runVoidCallbackAsync(redoFn);
                    set(futureRedoStackAtom, future);
                    set(pastUndoStackAtom, [...resizeStackImmutable(past, maxStackSize - 1), redoPoint]);
                    if (err) {
                        throw err;
                    }
                }
            },
        [maxStackSize],
    );
    const shadowUndo = useCallback(() => {
        setLoading(true);
        return undo().finally(() => {
            setLoading(false);
        });
    }, [undo]);

    const shadowRedo = useCallback(() => {
        setLoading(true);
        return redo().finally(() => {
            setLoading(false);
        });
    }, [redo]);

    // We do this shadowing to prevent the undo/redo UI from flickering.
    // This happens because the undo state is part of the recoil state itself.
    // We keep providing the most recent value until the state has settled after
    // the undo/redo action and network calls are done.
    const lastRedoStackCount = useRef(redoStackCount);
    const shadowRedoStackCount = useMemo(() => {
        if (loading) {
            return lastRedoStackCount.current;
        }
        lastRedoStackCount.current = redoStackCount;
        return redoStackCount;
    }, [loading, redoStackCount]);

    const lastUndoStackCount = useRef(undoStackCount);
    const shadowUndoStackCount = useMemo(() => {
        if (loading) {
            return lastUndoStackCount.current;
        }
        lastUndoStackCount.current = undoStackCount;
        return undoStackCount;
    }, [loading, undoStackCount]);

    return {
        undo: shadowUndo,
        redo: shadowRedo,
        purge: purgeStacks,
        loading,
        redoStackCount: shadowRedoStackCount,
        undoStackCount: shadowUndoStackCount,
    };
};

const useGridActionHistory = (maxStackSize = 20) => {
    const addAction = useRecoilCallback(
        ({ snapshot, set }) =>
            async (...undoPoint: UndoPoint) => {
                const past = await snapshot.getPromise(pastUndoStackAtom);
                const newPastStack = resizeStackImmutable(past, maxStackSize - 1);
                const isInPast = await snapshot.getPromise(isInPastAtom);
                set(pastUndoStackAtom, [...newPastStack, undoPoint]);
                if (isInPast) {
                    const future = [...(await snapshot.getPromise(futureRedoStackAtom))];
                    // The current future timeline is no longer valid, nuke it
                    set(futureRedoStackAtom, resizeStackImmutable(future));
                    set(isInPastAtom, false);
                }
                return newPastStack.length;
            },
        [maxStackSize],
    );

    const purgeStacks = useRecoilCallback(
        ({ set }) =>
            async () => {
                set(pastUndoStackAtom, resizeStackImmutable);
                set(futureRedoStackAtom, resizeStackImmutable);
            },
        [],
    );

    const untable_quietlySpliceUndoStack = useRecoilCallback(
        ({ set }) =>
            async (start: number, deleteCount: number, ...actions: UndoPoint[]) => {
                set(pastUndoStackAtom, stack => {
                    return stack.toSpliced(start, deleteCount, ...actions);
                });
            },
        [],
    );
    const untable_quietlySpliceRedoStack = useRecoilCallback(
        ({ set }) =>
            async (start: number, deleteCount: number, ...actions: UndoPoint[]) => {
                set(futureRedoStackAtom, stack => stack.toSpliced(start, deleteCount, ...actions));
            },
        [],
    );

    useLayoutEffect(() => {
        const purge = purgeStacks;
        return () => {
            purge();
        };
    }, [purgeStacks]);

    return {
        addAction,
        purgeStacks,
        /**
         * EASY TO MAKE MEMORY LEAKS IF CLEANUP ISN'T HANDLED PROPERLY. THIS DOES *_NOT_* RUN CLEANUPS, use with caution.
         * This is only for internal grid state use.
         * Acts like Array.prototype.splice
         */
        untable_quietlySpliceUndoStack,
        /**
         * EASY TO MAKE MEMORY LEAKS IF CLEANUP ISN'T HANDLED PROPERLY. THIS DOES *_NOT_* RUN CLEANUPS, use with caution.
         * This is only for internal grid state use.
         * Acts like Array.prototype.splice
         */
        untable_quietlySpliceRedoStack,
    };
};

const resizeStackImmutable = (stack: UndoPoint[], newSize = 0) => {
    const newStack = [...stack];
    if (newSize < 0 || newSize > newStack.length || newStack.length === 0) {
        return newStack;
    }
    while (newStack.length > newSize) {
        const cleanUp = newStack.pop()[2];
        cleanUp();
    }
    return newStack;
};

type Indexify<T extends object> = T & { [index: number | string]: any };

const gridExpandedRows = atomFamily<ExpandedState, Indexify<GridRow>[]>({
    key: 'expendedState',
    default: rows => ({
        ...rows.reduce((acc, row) => {
            acc[row.id] = false;
            return acc;
        }, {} as ExpandedState),
    }),
});
