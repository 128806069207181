import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { IconButton, ITag, Stack, TagPicker, TooltipHost } from '@fluentui/react';
import { ApiCalls } from '../../../../../api/api';
import { ProjectType } from '../../../../../api/generated/data-contracts';
import { PROJECT_PICKERFIELD } from '../../../../../context/network/http/QueryProvider/queryKeys';
import EnumEx from '../../../../../Utilities/EnumEx';
import { useDebouncedEventHandlerAsync } from '../../../../../hooks/useDebouncedHandler';
import { language } from '../../../../../Services/LocalizationService';
import { QueryGroup, createQueryGroup } from './helpers/createQueryGroup';

export const ProjectPicker = ({ onFilterChange, queryGroup }: { onFilterChange: (queryGroup: QueryGroup) => void; queryGroup?: QueryGroup | null }) => {
    const { data } = useQuery([PROJECT_PICKERFIELD], () =>
        ApiCalls.getProjectsForPicker({ onlyActive: true, projectTypes: EnumEx.all<ProjectType>(ProjectType) }).then(res =>
            res.data.sort((a, b) => a.name?.localeCompare(b.name)),
        ),
    );
	
    const [selectedProjectTags, setSelectedProjectTags] = useState<ITag[]>(() => {
        if (data && queryGroup?.parts) {
            return queryGroup.parts.reduce((acc, part) => {
                if (typeof part !== 'number') {
                    const projectId = part.value;
					const project = data.find(project => project.id === projectId);
					if (project) {
						acc.push({
							name: project.name!,
							key: project.id!
						})
					}
                }
                return acc;
            }, [] as ITag[]);
        }
        return [];
    });

    const onResolveSuggestions = useDebouncedEventHandlerAsync<(filter: string, selectedItems?: ITag[]) => Promise<ITag[]>>(
        async (filterText, selectedItems) => {
            const text = filterText.toLowerCase();
            return data
                ?.sort(resource => {
                    if (resource.name?.toLowerCase().startsWith(text)) {
                        return -1;
                    }
                    return 0;
                })
                .filter(project => {
                    const fitsFilter = project.name?.toLowerCase().includes(text);
                    if (fitsFilter && selectedItems && selectedItems.some(selectedItem => selectedItem.key === project.id)) {
                        return false;
                    }
                    return fitsFilter;
                })
                .map(({ name, id }) => {
                    return {
                        key: id,
                        name,
                    };
                });
        },
        300,
    );

    const onChange = useCallback(
        (items?: ITag[]) => {
            if (items) {
                onFilterChange(createQueryGroup(data.filter(project => items.some(item => item.key === project.id))));
                setSelectedProjectTags(items)
            }
        },
        [data, onFilterChange],
    );

    return (
        <Stack horizontal>
            <TagPicker
                styles={{ root: { width: '100%' } }}
                disabled={!data}
                onResolveSuggestions={onResolveSuggestions}
                onChange={onChange}
                selectedItems={selectedProjectTags}
            />
            <TooltipHost styles={{ root: { paddingBottom: 4 } }} content={language.LMCapacity.ClearSelection.Project}>
                <IconButton styles={{ icon: { fontSize: 24 } }} iconProps={{ iconName: 'ClearFormattingEraser' }} onClick={() => onChange([])} />
            </TooltipHost>
        </Stack>
    );
};
