import { FunctionComponent, useEffect } from 'react';
import { useState } from 'react';
import { language } from '../../Services/LocalizationService';
import { PFDialog } from './PFDialog';

export interface ConfirmInfo {
    show: boolean;
    title: string;
    subtext: string;
    confirmAction: () => void;
    dismissAction: () => void;
    okButtonText: string;
    cancelButtonText: string;
    hideCancelButton?: boolean;
}

interface IProps {
    ConfirmInfo: ConfirmInfo;
}

export const ConfirmDialog: FunctionComponent<IProps> = props => {
    const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo>(props.ConfirmInfo);

    useEffect(() => {
        setConfirmInfo(props.ConfirmInfo);
    }, [props.ConfirmInfo]);

    //TODO Make defaultConfirmInfo globally available when localization has been remade
    const defaultConfirmInfo: ConfirmInfo = {
        show: false,
        title: '',
        subtext: '',
        confirmAction: () => {},
        dismissAction: () => {},
        okButtonText: language.Common.Ok,
        cancelButtonText: language.Common.Cancel,
        hideCancelButton: true,
    };

    if (!confirmInfo?.show) return null;
    return (
        <PFDialog
            title={confirmInfo.title}
            subText={confirmInfo.subtext}
            showDialog={confirmInfo.show}
            maxWidth={500}
            callback={() => {
                confirmInfo.confirmAction();
                setConfirmInfo(defaultConfirmInfo);
            }}
            dismissCallback={() => {
                confirmInfo.dismissAction();
                setConfirmInfo(defaultConfirmInfo);
            }}
            buttonText={confirmInfo.okButtonText}
            cancelButtonText={confirmInfo.cancelButtonText}
            noCancelButton={confirmInfo.hideCancelButton}
        />
    );
};
