import { createContext } from '../../../hooks/createContext';

// import { createContext } from '../../../hooks/createContext';
export type OverviewCreatorSearchContext = {
	searchText: string
};
const [useCtx, Provider] = createContext<OverviewCreatorSearchContext>();

// eslint-disable-next-line react-refresh/only-export-components
export const useOverviewSearchContext = useCtx;
export const OverviewSearchContextProvider = Provider;