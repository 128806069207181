import { memo, useMemo, forwardRef, useImperativeHandle, useRef, useCallback, StrictMode } from 'react';
import { AllRowsClosedProvider } from '../../../_grid/contexts/allRowsClosedContext';
import { ExpandedGridProvider } from '../../../_grid/contexts/expandedContext';
import { GridContext, GridContextProvider } from '../../../_grid/contexts/gridContext';
import { useGrid, UseGridProps } from '../../../_grid/useGrid';
import { GridData } from '../../../../../api/generated/data-contracts';
import { defaultRangeExtractor, useVirtualizer } from '@tanstack/react-virtual';
import {
    ROW_HEIGHT,
    ROW_HEADER_WIDTH,
    COLUMN_WIDTH,
    ROW_STICKY_COUNT,
    ROW_EXPANDED_HEIGHT,
    COLUMN_STICKY_COUNT,
    TOP_HEADERS_HEIGHT,
    CUSTOM_COLUMN_WIDTH,
} from './CONSTANTS';
import { RowContainer } from './components/RowContainer/RowContainer';
import { HeaderContainer } from './components/HeaderContainer';
import { useEvent } from '../../../../../hooks/useEvent';
import { ScrollToContextProvider, ScrollToHandler } from './context/ScrollToContext';
import { useDebouncedEventHandler } from '../../../../../hooks/useDebouncedHandler';
import { GridLayoutContextProvider } from './context/LayoutContext';
import { useSelectedCustomColumnsCount } from './context/customColumnContext';
import { useCustomColumnRange } from './hooks/useCustomColumnRange';
import { getRowType } from './helpers/getRowType';
import { useLocalStorage } from '../../../../../hooks/useLocalStorage';
import { DragContextProvider } from './context/dragContext';
import { useGridDragHandler } from './hooks/useGridDragHandler';
import { DragBox } from './components/DragBox';
import { AddRowData } from './AddRowData';
export type ProjectPlannerGridProps = UseGridProps<AddRowData>;
export type ProjectPlannerHandle = { getUpdatedData: () => Promise<GridData> };

export const ProjectPlannerGrid = memo(
    forwardRef<ProjectPlannerHandle, ProjectPlannerGridProps>(function ProjectPlannerGrid(gridProps, ref) {
        const {
            gridData: { visibleRows, maxGridDepth, allClosed },
            gridMethods: {
                getRowAncestorTreeToRoot,
                getSubRows,
                updateCellValue,
                removeRow,
                addRow,
                updateMultipleCells,
                useGridCellState,
                useGridCellValue,
                getUpdatedData,
                moveRow,
                getRowsMap,
                setRootRows: setRows,
            },
            staticGridData: { headers, name, metadata, id },
            expandedRows,
            setExpandedRows,
        } = useGrid<AddRowData>(gridProps);

        const context = useMemo((): GridContext<AddRowData> => {
            return {
                name,
                headers,
                properties: metadata,
                id,
                updateCellValue,
                getRowAncestorTreeToRoot,
                removeRow,
                addRow,
                readonly: gridProps.readonly,
                updateMultipleCells,
                maxGridDepth,
                useGridCellState,
                useGridCellValue,
                moveRow,
                getRowsMap,
                setRootRows: setRows,
            };
        }, [
            name,
            headers,
            metadata,
            id,
            updateCellValue,
            getRowAncestorTreeToRoot,
            removeRow,
            addRow,
            gridProps.readonly,
            updateMultipleCells,
            maxGridDepth,
            useGridCellState,
            useGridCellValue,
            moveRow,
            getRowsMap,
            setRows,
        ]);

        const expandedContext = useMemo(() => {
            return {
                expandedRows,
                setExpandedRows,
            };
        }, [expandedRows, setExpandedRows]);

        useImperativeHandle(
            ref,
            () => {
                return {
                    getUpdatedData,
                };
            },
            [getUpdatedData],
        );

        const scrollRef = useRef<HTMLDivElement>(null);

        const renderableRows = useMemo(() => {
            return visibleRows.filter(({ visible }) => visible);
        }, [visibleRows]);

        const activeStickyIndexRef = useRef(0);

        const stickyIndexes = useMemo(() => [0], []);

        const pendingCellIndexScroll = useRef<{ dir: 'x' | 'y'; index: number } | null>(null);

        const [rowHeaderSize, _setRowHeaderSize] = useLocalStorage('row-header-width', ROW_HEADER_WIDTH);
        const customColumnCount = useSelectedCustomColumnsCount();
        const { range } = useCustomColumnRange();
        const columnVirtualizer = useVirtualizer({
            horizontal: true,
            count: (visibleRows?.[0]?.row?.columnRows?.length || 0) + COLUMN_STICKY_COUNT + customColumnCount,
            getScrollElement: () => scrollRef.current,
            overscan: 5,
            estimateSize: useCallback(
                i => {
                    // First columns is the row headers
                    if (i === 0) {
                        return rowHeaderSize;
                    }
                    if (range.includes(i)) {
                        return CUSTOM_COLUMN_WIDTH;
                    }
                    return COLUMN_WIDTH;
                },
                [range, rowHeaderSize],
            ),
            rangeExtractor: useCallback(
                range => {
                    activeStickyIndexRef.current = [...stickyIndexes].reverse().find(index => range.startIndex >= index);

                    const next = new Set([activeStickyIndexRef.current, ...defaultRangeExtractor(range)]);

                    return [...next].sort((a, b) => a - b);
                },
                [stickyIndexes],
            ),
            onChange: useEvent((instance, sync) => {
                if (!sync) {
                    if (pendingCellIndexScroll.current !== null) {
                        const { dir, index } = pendingCellIndexScroll.current;
                        if (dir === 'y') {
                            rowVirtualizer.scrollToIndex(index, { align: 'center', behavior: 'smooth' });
                            pendingCellIndexScroll.current = null;
                        }
                    }
                }
            }),
        });

        const rowVirtualizer = useVirtualizer({
            count: renderableRows.length + ROW_STICKY_COUNT,
            getScrollElement: () => scrollRef.current,
            overscan: 5,
            estimateSize: useCallback(
                (index: number) => {
                    if (index === 0) {
                        return TOP_HEADERS_HEIGHT;
                    }
                    const { expanded, row } = renderableRows[index - ROW_STICKY_COUNT];
                    if (expanded) {
                        const rowType = getRowType(row);
                        switch (rowType) {
                            case 'project':
                                return ROW_EXPANDED_HEIGHT;

                            case 'resource':
                            case 'contract':
                            default:
                                return ROW_HEIGHT;
                        }
                    }
                    return ROW_HEIGHT;
                },
                [renderableRows],
            ),
            rangeExtractor: useCallback(
                range => {
                    activeStickyIndexRef.current = [...stickyIndexes].reverse().find(index => range.startIndex >= index);

                    const next = new Set([activeStickyIndexRef.current, ...defaultRangeExtractor(range)]);

                    return [...next].sort((a, b) => a - b);
                },
                [stickyIndexes],
            ),
            onChange: useEvent((instance, sync) => {
                if (!sync) {
                    if (pendingCellIndexScroll.current !== null) {
                        const { dir, index } = pendingCellIndexScroll.current;
                        if (dir === 'x') {
                            columnVirtualizer.scrollToIndex(index, { align: 'center', behavior: 'smooth' });
                            pendingCellIndexScroll.current = null;
                        }
                    }
                }
            }),
        });

        const setRowHeaderSize = useDebouncedEventHandler<React.Dispatch<React.SetStateAction<number>>>(
            () => {
                columnVirtualizer.measure();
            },
            arg => {
                _setRowHeaderSize(arg);
            },
            20,
        );

        const layoutValue = useMemo(() => ({ rowHeaderSize, setRowHeaderSize }), [rowHeaderSize, setRowHeaderSize]);

        const scrollTo = useDebouncedEventHandler<ScrollToHandler>(({ x, y, rowIndex, columnIndex, behavior = 'smooth', align = 'center' }) => {
			const scrollToIndex = (columnIndex?: number, rowIndex?: number) => {
				if (columnIndex !== undefined && rowIndex !== undefined) {
					rowVirtualizer.scrollToIndex(rowIndex, { align, behavior });
					pendingCellIndexScroll.current = { dir: 'x', index: columnIndex };
				}
				if (columnIndex !== undefined) {
					columnVirtualizer.scrollToIndex(columnIndex, { align, behavior });
				}
				if (rowIndex !== undefined) {
					rowVirtualizer.scrollToIndex(rowIndex, { align, behavior });
				}
			}
			if (x !== undefined && y !== undefined) {
				const colIndex = columnVirtualizer.getVirtualItemForOffset(x).index;
				const rowIndex = rowVirtualizer.getVirtualItemForOffset(y).index;
				scrollToIndex(colIndex, rowIndex);
				return;
			}
            if (x !== undefined) {
				const colIndex = columnVirtualizer.getVirtualItemForOffset(x).index;
				scrollToIndex(colIndex, undefined);
				return;
            }
            if (y !== undefined) {
				const rowIndex = rowVirtualizer.getVirtualItemForOffset(x).index;
				scrollToIndex(undefined, rowIndex);
				return;
            }
			scrollToIndex(columnIndex, rowIndex)

        }, 20);

        const rows = rowVirtualizer.getVirtualItems();
        const columns = columnVirtualizer.getVirtualItems();
        const totalWidth = columnVirtualizer.getTotalSize();
        const totalHeight = rowVirtualizer.getTotalSize();

        const render = useMemo(() => {
            return rows.map(({ index, start, key }) => {
                if (!index) {
                    return <HeaderContainer key={key} headers={headers} start={start} totalWidth={totalWidth} columns={columns} />;
                }
                const rowIndex = index - ROW_STICKY_COUNT;
                const { row, visible, nestingLevel, expanded, rowRelations } = renderableRows[rowIndex];
                return (
                    <RowContainer
                        key={key}
                        row={row}
                        setExpandedRows={setExpandedRows}
                        visible={visible}
                        expanded={expanded}
                        getRowAncestorTreeToRoot={getRowAncestorTreeToRoot}
                        getSubRows={getSubRows}
                        nestingLevel={nestingLevel}
                        maxGridDepth={maxGridDepth}
                        rowIndex={rowIndex}
                        rowRelations={rowRelations}
                        index={rowIndex}
                        start={start}
                        columns={columns}
                        totalWidth={totalWidth}
                    />
                );
            });
        }, [columns, getRowAncestorTreeToRoot, getSubRows, headers, maxGridDepth, renderableRows, rows, setExpandedRows, totalWidth]);

        const innerScrollContainerStyles = useMemo(
            (): React.CSSProperties => ({
                position: 'relative',
                width: '100%',
                height: `${totalHeight}px`,
            }),
            [totalHeight],
        );

		
        const dragContext = useGridDragHandler({
            columnVirtualizer,
            renderableRows,
            rowVirtualizer,
            scrollRef,
            updateCellValue,
        });

        return (
            <StrictMode>
                <ScrollToContextProvider value={scrollTo}>
                    <DragContextProvider value={dragContext}>
                        <GridLayoutContextProvider value={layoutValue}>
                            <GridContextProvider value={context}>
                                <ExpandedGridProvider value={expandedContext}>
                                    <AllRowsClosedProvider value={allClosed}>
                                        <div ref={scrollRef} style={scrollContainerStyle}>
                                            <div style={innerScrollContainerStyles}>{render}</div>
                                        </div>
                                        <DragBox/>
                                    </AllRowsClosedProvider>
                                </ExpandedGridProvider>
                            </GridContextProvider>
                        </GridLayoutContextProvider>
                    </DragContextProvider>
                </ScrollToContextProvider>
            </StrictMode>
        );
    }),
);

const scrollContainerStyle: React.CSSProperties = {
    height: `100%`,
    width: `100%`,
    overflow: 'auto',
    maxWidth: '100%',
};
