import { guid } from "../../helpers/guid";
import { CustomPropertyType } from "./CustomPropertyType";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { DropdownOptionEx } from "../../Utilities/DropdownOptionEx";
import EnumEx from "../../Utilities/EnumEx";
import { UserType } from "../UserType";
import { StringEx } from "../../Utilities/StringEx";

export class CustomProperty {
	@PFPropertyBasicInfo(PropertyType.StringCopyValue, "_localization:Common.Id", 1)
	@PFPropertyRender(null, null, null, null, () => true)
	public Id: string;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:CustomProperty.InternalName", 2, "Only letters! No spaces or special characters! It is highly advisable NOT to change this name ever, as it may be used in integrations!")
	// @PFPropertyRender(null, null, null, null, item => item.InternalName != null)
	// @PFPropertyValidation("Only letters allowed in 'Internal name'", (item: CustomProperty) => !(new RegExp(/[^a-zA-Z]+/, 'g').test(item.InternalName)))
	public InternalName: string;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.DisplayName", 3)
	public DisplayName: string;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Common.Required", 4)
	public Required: boolean;
	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Common.Type", 5)
	@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsAddEmptyEntry<CustomPropertyType>(CustomPropertyType) }, null, null, item => item.Type != null)
	public Type: CustomPropertyType;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Lookup", 6)
	@PFPropertyRender(null, null, "LookupSelector", (item: CustomProperty) => !EnumEx.equalsAny<CustomPropertyType>(item.Type, CustomPropertyType.Choice, CustomPropertyType.ChoiceMultiselect), item => !StringEx.isNullOrWhiteSpace(item.Lookup))
	public Lookup: string; // guid
	@PFPropertyBasicInfo(PropertyType.String, "_localization:CustomProperty.Group", 7)
	public Group: string;
	@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:CustomProperty.EditableBy", 8)
	@PFPropertyRender(undefined, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutSome(UserType, [ UserType.Administrator, UserType.None ]), MultiSelect: true })
	public EditableBy: Array<UserType>;
	
	constructor() {
		this.Id = guid.newGuid();
	}
}