import { guid } from "../../../../../helpers/guid";

// TODO: TO DIE (kill it with fire)

export const typeOf = (obj: any): string => Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()
export const capitalize = (str: string) => str ? str[0].toUpperCase() + str.slice(1) : str;

export const upperCaseObj = (obj: any) => {
	const type = typeOf(obj);
	switch (type) {
		case "object": {
			if (obj === null) {
				return obj;
			}
			if (obj instanceof Date) {
				return obj;
			}
			return Object.fromEntries(
				Object.entries(obj).reduce((acc, [key, val]) => {
					if (key.toLowerCase() === "id") {
						// Special case for id. Backend doesn't care
						acc.push(["id", val], ["Id", val]);
					} else if (!guid.isGuidNotEmpty(key)) {
						acc.push([capitalize(key), upperCaseObj(val)]);
					} else {
						acc.push([key, upperCaseObj(val)])
					}
					return acc
				}, [] as [string, any][]),
			)
		}
		case 'array': {
			return (obj as any[]).map(upperCaseObj)
		}
		default: {
			return obj
		}
	}
};



// helper functions to easily get a human readable cache time

export const fromSeconds = (sec: number) => sec * 1000;

export const fromMinutes = (min: number) => fromSeconds(min) * 60;

export const fromHours = (hour: number) => fromMinutes(hour) * 60;

export const fromDays = (day: number) => fromHours(day) * 24;
