import { memo, useMemo } from 'react';
import { IFormSchema, useForm, Watch } from 'spark-forms';
import { FluentLabel } from 'spark-forms-fluent8';
import { TPBuilder } from '../../../../forms/_builders/new/TPBuilder';
import { useOverviewContext } from '../../../../_components/OverviewCreator/context/OverviewCreatorContext';

export interface IFormItemFieldFormProps {
	schema: IFormSchema<any>;
	watch?: Watch<any>;
}

export const FormItemFieldForm = memo((props: IFormItemFieldFormProps) => {
	const { item, setItem, setValidation } = useOverviewContext<any>();

	const options = useMemo(() => {
		return {
			item,
			onChange: setItem,
			schema: props.schema,
			builder: TPBuilder,
			builderOptions: {
				labelComponent: FluentLabel
			},
			watch: props.watch
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    const { Form, validateItem } = useForm(options);

	setValidation(validateItem);

    return <Form />
});
