import {
	CommandBar, ICommandBarItemProps,
	IContextualMenuItem
} from '@fluentui/react';
import { memo, useMemo } from 'react';
// import { MenuItem } from '../../OverviewCreator';

type MenuitemExtras<T> = {
    onClick?: (
        // setPanelContext: React.Dispatch<React.SetStateAction<TPanelContext<T> | null>>,
        selectedItems: T[],
        setPanelContext: React.Dispatch<React.SetStateAction<T | null>>,
		filteredItems: T[],
		columns: T[],
        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        item?: IContextualMenuItem,
    ) => boolean | void;
    shouldDisable?: (selectedItems: T[]) => boolean;
    subMenuProps?: Omit<ICommandBarItemProps['subMenuProps'], 'items'> & {
        items?: MenuItem<T>[];
    };
};

export type MenuItem<T> = Omit<ICommandBarItemProps, 'onClick'> & MenuitemExtras<T>;

type OverviewCommandBarProps<T extends { [key: string]: any; }> = {
	menuItems: MenuItem<T>[];
	selectedItems: T[];
	setPanelContext: React.Dispatch<React.SetStateAction<T | null>>;
	filteredItems: T[];
	columns: T[];
};
export const OverviewCommandBar = memo(<T extends { [key: string]: any; }>({ menuItems, selectedItems, setPanelContext, filteredItems, columns }: OverviewCommandBarProps<T>) => {
	const wrappedMenuItems = useMemo(() => {
		if (!menuItems || !menuItems.length) {
			return;
		}
		const menuItemMapper = (menuItem: MenuItem<T>) => {
			return {
				...menuItem,
				// Wrap the onClick handler to allow menuItems to set the panel and access selectedItems
				...(menuItem.onClick && {
					onClick: (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
						return menuItem?.onClick?.(selectedItems, setPanelContext, filteredItems, columns, ev, item);
					},
				}),
				...((menuItem as unknown as ICommandBarItemProps).subMenuProps && {
					subMenuProps: {
						...(menuItem as unknown as ICommandBarItemProps).subMenuProps,
						...((menuItem as unknown as ICommandBarItemProps).subMenuProps.items && {
							items: (menuItem as unknown as ICommandBarItemProps).subMenuProps.items.map(menuItemMapper as any),
						}),
					},
				}),
				...(menuItem.shouldDisable && { disabled: menuItem.shouldDisable(selectedItems) }),
			} as ICommandBarItemProps;
		};
		return menuItems.map(menuItemMapper);
	}, [menuItems, selectedItems, setPanelContext, filteredItems, columns]);
	return <CommandBar items={wrappedMenuItems} />;
});
