/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useContext, useState, useEffect } from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import { Text } from "@fluentui/react/lib/Text";
import { TableContext } from "./Table";
import TableCellRow from "./TableCellRow";
import { TableStateManager } from "../../../Services/TableStateManager";
import TableGroup from "./TableGroup";
import { GroupContainerRow } from "../../../Entities/Table/GroupContainerRow";
import { Icon } from "@fluentui/react";
import { LocalStorage } from "../../../Utilities/LocalStorage";
import { guid } from "../../../helpers/guid";
import { EventEx } from "../../../Utilities/EventEx";
import { CustomColumnUtil } from "../../../Utilities/Grid/CustomColumnUtil";
import { useStore } from "../../../context/store";

interface IProps {
	Container: GroupContainerRow;
	Collapsed?: boolean;
	disabled?: boolean;
}

const TableGroupContainer: FunctionComponent<IProps> = props => {
	const error = useStore(store => store.addErrorNotification)
	const ctx = useContext(TableContext) as TableStateManager;

	const [editMode, setEditMode] = useState(false);
	const [previousName, setPreviousName] = useState(props.Container.Name);
	const [collapsed, setCollapsed] = useState<boolean>(props.Collapsed || LocalStorage.getProperty<boolean>("tp-grid-collapsed", `${ctx.Get(_ => _.Model.Id)}-${props.Container.Name}`) || false);

	const [groupsLength] = useState(props.Container.Groups?.length);

	useEffect(() => {
		if (props.Collapsed != null)
			setCollapsed(props.Collapsed);
	}, [props.Collapsed])

	useEffect(() => {
		ctx.RefreshTabIndex();
	}, [collapsed])

	const EditEnabled = () : boolean => {
		const checker = ctx.Get(_ => _.GroupContainerEditEnabled);
		return checker == null ? false : checker(props.Container);
	}

	useEffect(() => {
		if (props.Container.Groups.length !== groupsLength)
			setCollapsed(false);
	}, [props.Container.Groups.length])

	return (
		<>
			<tr className="row-spacing" />
			<tr id={`container-${props.Container.Name}`} key={`group-container-${props.Container.Name}-tr`} className="tp-grid-groupd">
				<td className={`tp-grid-groupd-header noselect ${ctx.Get(_ => _.GroupContainerClass) != null ? ctx.Get(_ => _.GroupContainerClass)(props.Container) : ""}`}>
					{(editMode && !props.disabled) ? (
						<TextField
							key={`${props.Container.Name}-groupd-header-textfield`}
							className="containername-edit"
							autoFocus
							defaultValue={props.Container.Name}
							onKeyUp={e => {
								if (e.keyCode === 13) e.currentTarget.blur();
							}}
							onChange={(obj, value) => (props.Container.Name = value)}
							onBlur={e => {
								if (props.Container.Name == null || props.Container.Name === "") {
									error("Group name cannot be empty");
									setEditMode(false);
									return;
								}
								if (!props.Container.Id || props.Container.Name === previousName) {
									setEditMode(false);
									return;
								}
								ctx.Get(_ => _.GroupContainerEdit)(
									props.Container,
									previousName,
									props.Container.Groups.map(_ => _.Id)
								);
								setPreviousName(props.Container.Name);
								setEditMode(false);
							}}
						/>
					) : (
						<div 
							id={ props.Container.Id === guid.empty ? null : `gtc_${props.Container.Id}`} 
							className="flex-overflow-container noselect" 
							onClick={e => ctx.Get(_ => _.GroupContainerClick) ? ctx.Get(_ => _.GroupContainerClick)(props.Container) : () => {} }
						>
							{ctx.Get(_ => _.GroupContainerExtraContent)
								? ctx.Get(_ => _.GroupContainerExtraContent)(
										props.Container.Name,
										props.Container
								)
								: null}
							{ (ctx.Get(_ => _.AllowContainerCollapse) && props.Container.Id !== guid.empty) &&
								<div className="tp-grid-group-expandicon-container">
									<Icon className="tp-grid-container-expandicon" iconName={`${collapsed ? "ChevronDown" : "ChevronUp"}`} onClick={(event) => {
										setCollapsed(!collapsed);
										LocalStorage.setProperty("tp-grid-collapsed", `${ctx.Get(_ => _.Model.Id)}-${props.Container.Name}`, !collapsed);
										EventEx.preventAndStop(event);
									}} />
								</div>
							}
							<Text className={`tp-grid-groupd-header-title ${props.Container.Id === guid.empty ? "containernoid" : "" }`}
								onClick={e => EditEnabled()
									? setEditMode(true)
									: () => {}
								}
							>
							<span className="truncate-text">{props.Container.Name}</span>
							{ctx.Get(_ => _.GroupContainerSuffixContent)
								? ctx.Get(_ => _.GroupContainerSuffixContent)(
									props.Container.Name,
									props.Container
								)
								: null
							}
							</Text>
						</div>
					)}
				</td>
				{ CustomColumnUtil.GenerateCustomColumnCellRows(ctx, props.Container) }
				{props.Container.CellRows.map((_, idx) => (
					<TableCellRow
						key={`${props.Container.Id}-cellrow-${idx}`}
						KeyPrefix={`${props.Container.Id}`}
						CellRow={_}
						Index={idx}
						ParentRow={props.Container}
					/>
				))}
			</tr>
			{
				(!collapsed) &&
				ctx.Get(_ => _.GroupSort)(props.Container.Groups)
					.map((_, idx) => (
						<TableGroup key={_.Id} Group={_} Container={props.Container} />
					))
			}
		</>
	);
};

export default TableGroupContainer;
