import { DropdownOptionEx } from "../Utilities/DropdownOptionEx";
import { PFPropertyBasicInfo } from "../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyFilter } from "../Utilities/PropertyInfo/PropertyFilter";
import { PropertyType } from "../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../Utilities/PropertyInfo/Render";
import { GridType } from "./GridType";
import { IObjectEditorItemType } from "./Main/IObjectEditorItemType";
import { UserType } from "./UserType";

export class GlimmerConfiguration implements IObjectEditorItemType {
	@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Common.Roles", 2)
	@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<UserType>(UserType) })
	@PFPropertyFilter({FilterLevel: 1})
	public UserTypes: Array<UserType>;
	@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Common.Views", 3)
	@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.enumValuesToDropdownOptions<GridType>(GridType, [1, 2]) })
	@PFPropertyFilter({FilterLevel: 1})
	public GridTypes: Array<GridType>;

	constructor() {
		this.UserTypes = [];
		this.GridTypes = [];
	}
}
