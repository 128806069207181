
export function round(value, precision = 0) {
    if (typeof value !== 'number' || typeof precision !== 'number') {
        throw new TypeError('Value and precision must be numbers.');
    }

    // Adjust for floating point errors before rounding
    const factor = Math.pow(10, precision);
    // Add a small correction factor before rounding
    const correction = 1 / (factor * 10);
    const tempNumber = value * factor + correction;
    const roundedTempNumber = Math.round(tempNumber);

    return roundedTempNumber / factor;
}

export function getValueOrDefault(num: number, defaultValue: number = 0): number {
	if (num == null || isNaN(num))
		return defaultValue;
	return num;
}

export function isNumeric(str: string | null | undefined): boolean {
	// Check for null or undefined
	if (str === null || str === undefined) return false;
	const num = parseFloat(str);
	return !isNaN(num) && num.toString() === str.trim();
}

export const NumberEx = {
	round,
	getValueOrDefault
} 