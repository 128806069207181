import { ColumnType } from './ColumnType';
import { SubHeaderRow } from './SubHeaderRow';

export class HeaderRow {
    constructor(
        public Topic: string,
        public Name: string,
        public Type: ColumnType,
        public Start: Date,
        public End: Date,
        public SubHeaderRows: Array<SubHeaderRow>,
        public ScenarioId?: string,
    ) {}
}

export interface IHeaderRow {
    Topic: string;
    Name: string;
    Type: ColumnType;
    Start: Date;
    End: Date;
    SubHeaderRows: Array<SubHeaderRow>;
    ScenarioId?: string;
}
