import React, { FunctionComponent } from 'react';
import { ProgressIndicator, TooltipHost, IStyleFunctionOrObject, IProgressIndicatorStyleProps, IProgressIndicatorStyles } from '@fluentui/react';
 
interface IProps {
	TooltipText: string;
	Percentage: number;
	Styles: IStyleFunctionOrObject<IProgressIndicatorStyleProps, IProgressIndicatorStyles>;
}

export const KpiBar: FunctionComponent<IProps> = props => {

    return 	<TooltipHost content={ props.TooltipText } calloutProps={{ gapSpace: -8 }}>
				<ProgressIndicator 
					percentComplete={props.Percentage / 100} 
					styles={ props.Styles }
				/>
			</TooltipHost>
}
