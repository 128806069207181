import React, { FunctionComponent, useContext, useRef, useEffect } from 'react';
import { TableContext } from './Table';
import TableCellRow from './TableCellRow';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { Row } from '../../../Entities/Table/Row';
import { GroupRow } from '../../../Entities/Table/GroupRow';
import { TableStateManager } from '../../../Services/TableStateManager';
import { ResourceType } from '../../../Entities/ResourceType';
import { CustomColumnUtil } from '../../../Utilities/Grid/CustomColumnUtil';
import { useStore } from '../../../context/store';
import shallow from 'zustand/shallow';

const getRowClassName = (isCategory: boolean | undefined, IsGeneric: boolean | undefined) => {
    if (isCategory) return 'tp-is-category-row';
    else if (IsGeneric) return 'tp-is-generic-row';
    else return 'tp-is-named-row';
};

interface IProps {
    Group: GroupRow;
    Row: Row;
    InContainer?: boolean;
}

const TableRow: FunctionComponent<IProps> = props => {
    const ctx = useContext(TableContext) as TableStateManager;
    const containerRef = useRef(null);
    const { scrollToAddedResource, setUIContext } = useStore(
        store => ({ setUIContext: store.setUIContext, scrollToAddedResource: store.ui.scrollToAddedResource }),
        shallow,
    );

    useEffect(() => {
        // if (props.Row.Id == uiCtx.scrollToAddedResource && containerRef.current) {
        if (props.Row.Id === scrollToAddedResource && containerRef.current) {
            // uiCtx.setScrollToAddedResource("");
            setUIContext({ scrollToAddedResource: '' });
            containerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <tr
            id={`gtr_${props.Group.Id}_${props.Row.Id}_${props.Row.Properties.IsRequested}`}
            ref={containerRef}
            key={`${props.Row.Id}`}
            className={`tp-grid-row${props.InContainer ? ' in-container' : ''} ${getRowClassName(
                props.Row.Properties.ResourceType === ResourceType.Category,
                props.Row.Properties.ResourceType === ResourceType.Generic,
            )}`}
        >
            <td className={`tp-grid-row-header noselect${props.Row.Properties.IsNew ? ' shake' : ''}`}>
                <div className="flex-container-between">
                    <Persona
                        text={props.Row.Name}
                        className={`${ctx.Get(_ => _.RowClassName)(props.Row)} ${
                            ctx.Get(_ => _.RowDraggable)(props.Row) || ctx.Get(_ => _.RowClick) ? 'tp-capacity-resource-draggable' : ''
                        }`}
                        imageInitials={(() => {
                            if (props.Row.Name == null) return '';
                            let initials = props.Row.Name.match(/\b\w/g) || ([] as any);
                            initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
                            return initials;
                        })()}
                        size={PersonaSize.size28}
                        hidePersonaDetails={false}
                        coinSize={28}
                        draggable={ctx.Get(_ => _.RowDraggable)(props.Row)}
                        onDragStart={e => (ctx.Get(_ => _.RowDragStart) ? ctx.Get(_ => _.RowDragStart)(props.Row, props.Group, e as any) : () => {})}
                        onDragEnd={e => (ctx.Get(_ => _.RowDragEnd) ? ctx.Get(_ => _.RowDragEnd)(props.Row, props.Group, e as any) : () => {})}
                        onClick={e => (ctx.Get(_ => _.RowClick) ? ctx.Get(_ => _.RowClick)(props.Row) : () => {})}
                        styles={{ root: { '.ms-Persona-coin': { display: ctx.Get(_ => _.GridSettingsHidePersonaIcons) ? 'none' : 'inline-block' } } }}
                        {...ctx.Get(_ => _.RowPersonaProps)}
                    />
                    {ctx.Get(_ => _.RowExtraContent) && ctx.Get(_ => _.RowExtraContent)(props.Row, props.Group)}
                </div>
            </td>
            {CustomColumnUtil.GenerateCustomColumnCellRows(ctx, props.Row)}
            {props.Row.CellRows.map((_, idx) => {
                return <TableCellRow key={`${props.Row.Id}-cellrow-${idx}`} KeyPrefix={`${props.Row.Id}`} CellRow={_} Index={idx} ParentRow={props.Row} />;
            })}
        </tr>
    );
};

export default TableRow;
