import { TooltipHost, Icon, Link as FluentLink } from '@fluentui/react';
import { CSSProperties, memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { GridColumnRow, GridCell, UnitType } from '../../../../../../../../../api/generated/data-contracts';
import { buildRoute } from '../../../../../../../../../helpers/routeHelpers';
import { useGridContext } from '../../../../../../../_grid/contexts/gridContext';
import { useGridRowContext } from '../../../../../../../_grid/contexts/rowContext';
import { useFixedCellValue } from '../../../../../../../_grid/hooks/useFixedCellValue';
import { useContractValueBgColor } from '../../../../hooks/useContractValueBgColor';
import { CELL_HEIGHT, CELL_WIDTH } from '../../../../CONSTANTS';
import { AnyObject } from '../../../../../../../../../types/types';
import { useUnitType } from '../../../../../../../../../Utilities/UnitTypeUtil';

type ColumnRowProps = {
    columnRow: GridColumnRow;
};

export const ContractColumnRow = memo(({ columnRow }: ColumnRowProps) => {
    const render = useMemo(() => {
        return columnRow.cells.map((cell, i, cells) => {
            if (i === 1) {
                return <ContractAllocationCell key={cell.id} cell={cell} contractCells={cells} metadata={columnRow.metadata} cellIndex={i} />;
            }
            return <ContractRequestCell key={cell.id} cell={cell} contractCells={cells} cellIndex={i} />;
        });
    }, [columnRow.cells, columnRow.metadata]);
    return <ColumnGridContainer>{render}</ColumnGridContainer>;
});

const ColumnGridContainer = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const style = useMemo(() => {
        return {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            padding: '0px 10px',
            gap: 10,
            alignItems: 'center',
            height: '100%',
        } as CSSProperties;
    }, []);
    return <div style={style} {...props} />;
};

const ContractRequestCell = ({ cell, contractCells }: { cell: GridCell; contractCells: GridCell[]; cellIndex: number }) => {
    const { useGridCellValue } = useGridContext();
    const allocationCell = contractCells[1];
    const allocationCellValue = useGridCellValue(allocationCell);
    const storeValue = useGridCellValue(cell);
    const backgroundColor = useContractValueBgColor(storeValue.toNumber(), allocationCellValue.toNumber());
    const value = useFixedCellValue(storeValue);

    return (
        <div
            style={{
                paddingRight: 8,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'end',
                backgroundColor,
                height: CELL_HEIGHT,
                width: CELL_WIDTH,
            }}
        >
            {value}
        </div>
    );
};

const ContractAllocationCell = ({
    cell,
    contractCells,
    cellIndex,
    metadata,
}: {
    cell: GridCell;
    contractCells: GridCell[];
    metadata?: AnyObject;
    cellIndex: number;
}) => {
    const { useGridCellValue } = useGridContext();
    const requestCell = contractCells[0];
    const requestCellValue = useGridCellValue(requestCell);
    const storeValue = useGridCellValue(cell);
    const backgroundColor = useContractValueBgColor(requestCellValue.toNumber(), storeValue.toNumber());
    const value = useFixedCellValue(storeValue);

    const renderAllocationWarning = useMemo(() => {
        if (metadata?.AllocatedOnOthers && cellIndex === 1 && metadata?.AllocatedOnOthers !== '0') {
            return <AllocationWarning metadata={metadata} />;
        }
        return null;
    }, [cellIndex, metadata]);

    return (
        <div
            style={{
                paddingRight: 8,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'end',
                backgroundColor,
                height: CELL_HEIGHT,
                width: CELL_WIDTH,
            }}
        >
            {renderAllocationWarning} {value}
        </div>
    );
};

const AllocationWarning = ({ metadata }: { metadata?: AnyObject }) => {
    const { rowRelations } = useGridRowContext();
    const link = useMemo(() => {
        // Contract rows is the middle level, project is first
        const row = rowRelations[0];
        return buildRoute('allocation', row.identity?.id);
    }, [rowRelations]);

    const valueNumeric = useMemo(() => {
        const text = (metadata?.AllocatedOnOthers as string) || '';
        // The backend responds likes this: "25,4"
        return Number(text.replace(',', '.'));
    }, [metadata?.AllocatedOnOthers]);

    const valueFixed = useFixedCellValue(valueNumeric);

	const {currentUnitType} = useUnitType()

    const text = useMemo(() => {
        return `There are ${valueFixed} ${currentUnitType === UnitType.FTE ? "FTE" : "FTE"} allocated outside your area`;
    }, [currentUnitType, valueFixed]);
    return (
        <TooltipHost
            styles={{
                root: {
                    //
                    flexGrow: 1,
                    paddingLeft: 5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    cursor: 'help',
                },
            }}
            content={
                <>
                    {text}
                    <Link to={link}>
                        <FluentLink styles={{ root: { display: 'inline' } }}>Click here to go to the allocation</FluentLink>
                    </Link>
                </>
            }
        >
            <Icon iconName="Info" styles={{ root: { fontSize: 18, display: 'block' } }} />
        </TooltipHost>
    );
};
