import { PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react';
import {
    IFormItem,
    IDynamicPropertyComponentConfig,
    IPropertyRenderProps,
    IDynamicComponentConfig,
    IFormItemPropertyOptions,
    IPropertyTypes,
    IItemRenderProps,
    formbuilder,
    IDynamicPredefinedArrayFieldConfig,
    IValidationResult,
} from '@wiberg/formbuilder';
import { ITpPropertyTypes, tpPropertyTypes } from '../../_builders/tpPropertyTypes';
import { CustomPropertyType } from '../../../Entities/ContentConfiguration/CustomPropertyType';
import { ILookup } from '../../lookup/ILookup';
import { ResourceType } from '../../../Entities/ResourceType';
import { ArrayEx } from '../../../Utilities/ArrayEx';
import { LookupType } from '../../../Entities/ContentConfiguration/Lookup/LookupType';
import { DropdownOptionEx } from '../../../Utilities/DropdownOptionEx';
import { IProjectFieldConfig } from '../../_builders/tpComplexBuilder/components/config/IProjectFieldConfig';
import { IResourceFieldConfig } from '../../_builders/tpComplexBuilder/components/config/IResourceFieldConfig';
import { ITreeFieldConfig } from '../../_builders/tpComplexBuilder/components/config/ITreeFieldConfig';
import { ITreeNode } from '../../_builders/tpComplexBuilder/components/tree/interfaces/ITreeNode';
import { CustomProperty, CustomPropertyWithPermissions, DynamicQuery, QueryDto, EntityType } from '../../../api/generated/data-contracts';
import { settings } from '../../../context/network/http/QueryProvider/queries/UISettings';
import { useQuery } from 'react-query';
import { ContentConfigurationType } from '../../../Entities/ContentConfiguration/ContentConfigurationType';
import { ApiCalls } from '../../../api/api';
import { changeCase } from '../../../helpers/changeKeysCase';
import { IRbsStructure } from '../../_interfaces/IRbsStructure';
import { useCheckTPPermissions } from '../../../hooks/usePermissions';
import { Label } from '@fluentui/react';
import { ShimmerBox } from '../../../Components/Skeleton/ShimmerBox';
import { originHelper } from '../../_builders/new/helpers/origins/originHelper';

interface IProps<T extends IFormItem, C extends IDynamicPropertyComponentConfig<T>> extends IPropertyRenderProps<T, C, any | undefined> {
    config?: IDynamicComponentConfig<T>;
    value: any;
    parent: any;
    disabled: boolean;

    noValidation?: boolean;
    hideLabel?: boolean;
    overrideDisabledProps?: boolean;
    entityType: 'resource' | 'project' | 'contract' | 'other';
    property: CustomProperty;
}

export const CustomPropertyRender = <T extends IFormItem, C extends IDynamicPropertyComponentConfig<T>>({
    value,
    parent,
    config,
    disabled,
    onChange,
    noValidation,
    hideLabel,
    overrideDisabledProps,
    entityType,
    property,
}: PropsWithChildren<IProps<T, C>>): ReactElement | null => {
    const checkPerms = useCheckTPPermissions();
    const [element, setElement] = useState<JSX.Element>(null);
    // const [lookups, setLookups] = useState<ILookup[]>();
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const { data: lookups } = useQuery(['lookupsrender'], async () => {
        const dto = {
            entity: EntityType.ContentConfiguration,
            query: { expression: 'ContentConfigurationType == @0', params: [ContentConfigurationType.Lookup] } as DynamicQuery,
        } as QueryDto;
        const response = await ApiCalls.queryEntity(dto);
        return changeCase.toCamel(response.data as ILookup[]); // why the fuck do I need to do this (ewi)
    });

    const { data: rbsStructures } = useQuery(['lookupsrenderrbs'], async () => {
        const dto = {
            entity: EntityType.ContentConfiguration,
            query: { expression: 'ContentConfigurationType == @0', params: [ContentConfigurationType.Rbs] } as DynamicQuery,
        } as QueryDto;
        const response = await ApiCalls.queryEntity(dto);
        return response.data as IRbsStructure[];
    });

    const lockedGenericResourceUpdateProperties = useCallback((): string[] => {
        // guards (only generic resource updates, needs to restrict the custom properties used in the gen res definition)
        if (entityType !== 'resource') return null;
        if (parent?.ResourceType !== ResourceType.Generic) return null;
        if (!parent?.Created) return null;
        // allowed by setting to edit gen res custom props
        if (settings().allowChangeRbsAndCustomPropertiesOnGenericResource) return null;
        return settings().genericResourceDefinition;
    }, [parent?.Created, parent?.ResourceType, entityType]);

    const isLocked = useCallback(
        async (fieldId: string): Promise<boolean> => {
            if (overrideDisabledProps) return false;

            let disallowedProps = [];
            let genResDefinitionLocks = [];

            switch (entityType) {
                case 'resource':
                    disallowedProps = await originHelper.getDisallowedResourceFields(true);
                    genResDefinitionLocks = lockedGenericResourceUpdateProperties();
                    break;
                case 'project':
                    disallowedProps = await originHelper.getDisallowedProjectFields(true);
                    break;
                case 'contract':
                default:
                    return false;
            }

            const locks = ArrayEx.mergeDistinct(genResDefinitionLocks, disallowedProps, _ => _);

            if (!locks || locks.length === 0) return false;
            return locks.some(_ => _.includes(fieldId));
        },
        [entityType, lockedGenericResourceUpdateProperties, overrideDisabledProps],
    );

    const lookupTypeMapping = useCallback(
        async (lookup: ILookup, multiSelect?: boolean): Promise<{ key: keyof ITpPropertyTypes; config: any }> => {
            switch (lookup.lookupType) {
                case LookupType.Number:
                    return {
                        key: 'predefinedArray',
                        config: {
                            multiSelect: multiSelect,
                            predefinedOptions: {
                                options: DropdownOptionEx.toDropdownOptionsAddEmptyEntry(
                                    lookup.options,
                                    _ => _.id,
                                    _ => _.value?.toString(),
                                ),
                            },
                        } as IDynamicPredefinedArrayFieldConfig<any>,
                    };
                case LookupType.String:
                    return {
                        key: 'predefinedArray',
                        config: {
                            multiSelect: multiSelect,
                            predefinedOptions: {
                                options: DropdownOptionEx.toDropdownOptionsAddEmptyEntry(
                                    lookup.options,
                                    _ => _.id,
                                    _ => _.value?.toString(),
                                ),
                            },
                        } as IDynamicPredefinedArrayFieldConfig<any>,
                    };
                case LookupType.Project:
                    return {
                        key: 'project',
                        config: {
                            multiSelect: multiSelect,
                            nameField: 'name',
                            descriptionField: 'owner',
                            excludeInactive: !lookup.options[0].value.includeInactive,
                        } as IProjectFieldConfig<any>,
                    };
                case LookupType.Resource:
                    return {
                        key: 'resource',
                        config: {
                            multiSelect: multiSelect,
                            nameField: 'name',
                            descriptionField: 'rbs',
                            excludeInactive: !lookup.options[0].value.includeInactive,
                        } as IResourceFieldConfig<any>,
                    };
                case LookupType.Rbs: {
                    const rbsId = lookup.options[0].value.rbsId;
                    const getter = () => rbsStructures?.find(_ => _.id === rbsId)?.rbs;
                    return { key: 'tree', config: { multiSelect: multiSelect, type: 'select', treeGetter: getter } as ITreeFieldConfig<any> };
                }
                case LookupType.Tree: {
                    const tree = lookup.options[0].value.tree as ITreeNode;
                    return {
                        key: 'tree',
                        config: {
                            multiSelect: multiSelect,
                            type: 'select',
                            defaultExpandedDepth: lookup.options[0].value.defaultExpandedDepth,
                            treeGetter: () => tree,
                        } as ITreeFieldConfig<any>,
                    };
                }
                default:
                    break;
            }
        },
        [rbsStructures],
    );

    const typeMapping = useCallback(
        async (customPropType: CustomPropertyType): Promise<{ key: keyof ITpPropertyTypes; config?: any }> => {
            switch (customPropType) {
                case CustomPropertyType.Number:
                    return { key: 'number', config: { type: 'number' } };
                case CustomPropertyType.String:
                    return { key: 'string' };
                case CustomPropertyType.StringMultiline:
                    return { key: 'multilineString', config: { multiline: true, autoAdjustHeight: true, resizable: true } };
                case CustomPropertyType.Boolean:
                    return { key: 'boolean' };
                case CustomPropertyType.Date:
                    return { key: 'date' };
                case CustomPropertyType.Choice: {
                    const lookup = lookups?.find(_ => _.id === property.lookup);
                    return await lookupTypeMapping(lookup);
                }
                case CustomPropertyType.ChoiceMultiselect: {
                    const lookup2 = lookups?.find(_ => _.id === property.lookup);
                    return await lookupTypeMapping(lookup2, true);
                }
                case CustomPropertyType.Url:
                    return { key: 'hyperlink' };
                default:
                    break;
            }
        },
        [lookupTypeMapping, lookups, property?.lookup],
    );

    const allowedCheck = useCallback(
        (property: CustomPropertyWithPermissions): boolean => {
            if (property.editableBy?.length) {
                return checkPerms({ userTypes: property.editableBy, some: true });
            }
            return true;
        },
        [checkPerms],
    );

    // const createElement = useCallback(async (property: CustomProperty) : Promise<JSX.Element | null> => {
    const createElement = useCallback(
        async (property: CustomProperty): Promise<JSX.Element | null> => {
            if (property == null) return null;

            const options = await typeMapping(property.type);
            const allowed = allowedCheck(property as CustomPropertyWithPermissions);

            // create a fake item and option, used to render the single property
            const fakeItem = {};
            fakeItem[property.id] = localValue;
            const locked = await isLocked(property.id);
            const fakePropertyOptions = {} as { [name: string]: IFormItemPropertyOptions<any, IDynamicPropertyComponentConfig<any>> };

            fakePropertyOptions[property.id] = {
                hideLabel: hideLabel,
                disable: () => disabled || locked || !allowed,
                displayName: property.displayName,
                propertyType: tpPropertyTypes[options.key] as keyof IPropertyTypes,
                config: options.config,
            };

            // fake validation
            const validationResults: IValidationResult = {};
            validationResults[property.id] = {
                test: {
                    success: fakeItem[property.id] != null && fakeItem[property.id] !== '',
                    message: 'Required',
                },
            };

            const propertyRenderProps: IItemRenderProps<any> = {
                item: fakeItem,
                schema: { properties: fakePropertyOptions },
                onChange: (prop: string, val: string) => onChange(val),
                onBlur: (prop: string) => {},
                validationResults: property.required && allowed && !noValidation ? validationResults : {},
            };

            const render = formbuilder.formItemRender.properties(propertyRenderProps);
            return render?.length ? render[0] : null;
        },
        [disabled, hideLabel, isLocked, localValue, noValidation, onChange, typeMapping, allowedCheck],
    );

    useEffect(() => {
        if (lookups == null) return;
        const getElement = async () => {
            const el = await createElement(property);
            setElement(el);
        };
        getElement();
    }, [createElement, lookups, property, localValue]);

    if (!rbsStructures) {
        return (
            <>
                <Label>{property?.displayName}</Label>
                <ShimmerBox height={30} />
            </>
        );
    }

    return element;
};
