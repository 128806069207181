import { Text, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useMemo } from 'react';

export const SubHeader = ({ subHeader: { name } }: { subHeader: { name: string } }) => {
    const id = useId('sub-header');
    const tooltipContent = useMemo(() => {
        switch (name) {
            case 'Allocation':
                return 'Allocation on the contract';
            case 'Free':
                return 'Free capacity';
			case 'Request':
			default:
				return 'Request on the contract'
        }
    }, [name]);
    return (
        <TooltipHost content={tooltipContent} styles={{ root: { width: '100%', display: 'flex', justifyContent: 'center' } }}>
            <Text
                id={id}
                styles={{
                    root: {
                        fontWeight: 600,
                        //
                        // fontSize: 12,
                        // textAlign: 'center',
                    },
                }}
            >
                {name}
            </Text>
        </TooltipHost>
    );
};
