import { useMemo } from 'react';
import { useTheme } from '../../../../../../Themes/themeContext';
import { useGetDecimalCellValue } from './useGetDecimalCellValue';

export const useContractValueBgColor = (requestValue: number, allocationValue: number) => {
	const theme = useTheme();
	const getDecimals = useGetDecimalCellValue();
	const requestDecimalValue = useMemo(() => Number(getDecimals(requestValue)), [getDecimals, requestValue]);
	const allocationDecimalValue = useMemo(() => Number(getDecimals(allocationValue)), [getDecimals, allocationValue]);
	return useMemo(() => {
		if (allocationDecimalValue === 0 && requestDecimalValue === 0) {
			return theme.semanticColors.activityZeroBackground;
		}
		if (allocationDecimalValue < requestDecimalValue) {
			return theme.semanticColors.activityUnderBackground;
		}
		if (allocationDecimalValue > requestDecimalValue) {
			return theme.semanticColors.activityOverBackground;
		}
		if (allocationDecimalValue === requestDecimalValue) {
			return theme.semanticColors.activityEqualBackground;
		}
	}, [
		allocationDecimalValue,
		requestDecimalValue,
		theme.semanticColors.activityZeroBackground,
		theme.semanticColors.activityUnderBackground,
		theme.semanticColors.activityOverBackground,
		theme.semanticColors.activityEqualBackground,
	]);
};
