/* eslint-disable react-refresh/only-export-components */
import React, { ErrorInfo } from 'react';
import { IconButton, Callout } from '@fluentui/react';
import { logging } from 'projectum-logging/lib/Services/LogManager';
import { language } from '../../Services/LocalizationService';
import Locale_PFErrorBoundary from '../../Locales/Locale_PFErrorBoundary';

const logger = logging.GetLogger('ErrorBoundary');

const localLocale = new Locale_PFErrorBoundary();

function resolveLocaleSafe(key: keyof Locale_PFErrorBoundary) {
    let string = '';
    try {
        string = language.PFErrorBoundary[key];
    } catch (error) {
		try {
            string = localLocale[key];
        } catch (error) {}
    }
    // Last resort to show something meaningful
    return string || key;
}

interface IProps {
	ComponentName?: string;
	Reset?: () => Promise<void>;
}

interface IState {
	HasError: boolean;
	Error: any;
	ShowDetails: boolean;
}

// TODO: reset error count when success - ewi
export class PFErrorBoundary extends React.Component<IProps, IState> {
    private RefreshFailed: boolean = false;
    private ResetFailed: boolean = false;
    private Component: string;

    constructor(props: Readonly<IProps>) {
        super(props);
        this.state = { HasError: false, ShowDetails: false, Error: null };
        this.Component = this.props.ComponentName || 'component';

        this.handleDetailsClick = this.handleDetailsClick.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleReloadPageClick = this.handleReloadPageClick.bind(this);
        this.handleDismissDetailsClick = this.handleDismissDetailsClick.bind(this);
        this.handleRefreshFromPropsClick = this.handleRefreshFromPropsClick.bind(this);
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        // log the error to all registered log providers ('dev console' and 'app insights')
        logger.Critical(error.message, { StackTrace: info.componentStack });
        // Display fallback UI
        this.setState({ HasError: true, Error: { message: error.message, stack: info.componentStack } });
    }

    static getDerivedStateFromError() {
        return { HasError: true };
    }

    handleDetailsClick() {
        this.setState({ ShowDetails: true });
    }

    handleRefreshClick() {
        this.RefreshFailed = true;
        this.setState({ HasError: false });
    }

    handleRefreshFromPropsClick() {
        this.props.Reset().then(_ => {
            this.RefreshFailed = false;
            this.ResetFailed = true;
            this.setState({ HasError: false });
        });
    }

    handleReloadPageClick() {
        window.location.reload();
    }

    handleDismissDetailsClick() {
        this.setState({ ShowDetails: false });
    }

    render() {
        if (this.state.HasError) {
            // You can render any custom fallback UI
            return (
                <>
                    <div className="tp-errorboundary-container">
                        <RenderAction
                            iconName="Error"
                            text={resolveLocaleSafe('SomethingWentWrong').replace('[[component]]', this.Component)}
                            onClick={this.handleDetailsClick}
                            iconElementId="tperroricon"
                        />
                        {!this.RefreshFailed && !this.ResetFailed && (
                            <RenderAction
                                iconName="Refresh"
                                text={resolveLocaleSafe('TryRefreshingThisComponent').replace('[[component]]', this.Component)}
                                onClick={this.handleRefreshClick}
                            />
                        )}
                        {this.props.Reset && this.RefreshFailed && (
                            <RenderAction
                                iconName="RevToggleKey"
                                text={resolveLocaleSafe('RefreshingWasNotEnough').replace('[[component]]', this.Component)}
                                onClick={this.handleRefreshFromPropsClick}
                            />
                        )}
                        {(this.ResetFailed || (!this.props.Reset && this.RefreshFailed)) && (
                            <RenderAction
                                iconName="Sync"
                                text={resolveLocaleSafe('ResetOrRefreshWasNotEnough').replace('[[component]]', this.Component)}
                                onClick={this.handleReloadPageClick}
                            />
                        )}
                    </div>
                    <Callout
                        target={`#tperroricon`}
                        hidden={!this.state.ShowDetails}
                        onDismiss={this.handleDismissDetailsClick}
                        onClick={this.handleDismissDetailsClick}
                        className="tp-errorboundary-callout"
                    >
                        <div>
                            <p>
                                <b>{this.state?.Error?.message}</b>
                            </p>
                        </div>
                        <div>
                            <p style={{ whiteSpace: 'pre-wrap' }}>{this.state?.Error?.stack}</p>
                        </div>
                    </Callout>
                </>
            );
        }
        return <>{this.props.children}</>;
    }
}

type RenderActionProps = {
    iconName: string;
    text: string;
    onClick: (event: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement, MouseEvent>) => void;
    iconElementId?: string;
};

const RenderAction = ({ iconName, text, onClick, iconElementId }: RenderActionProps) => {
    return (
        <div className="tp-errorboundary-subcontainer">
            <IconButton id={iconElementId} iconProps={{ iconName: iconName }} className="tp-errorboundary-icon" onClick={onClick} />
            <p>{text}</p>
        </div>
    );
};
