import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { Resource } from "./Resource";
import { Project } from "./Project";
import { guid } from "../../helpers/guid";
import { Tuple } from "../Tuple";
import { IObjectEditorItemType } from "./IObjectEditorItemType";

export class Origin implements IObjectEditorItemType {	
	@PFPropertyBasicInfo(PropertyType.StringCopyValue, "_localization:Origin.OriginId", 1)
	@PFPropertyRender(null, null, null, null, item => true)
	public Id: string;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.DisplayName", 2)
	@PFPropertyRender(null, null, null, null, (item: Origin) => item.Id === guid.empty || item.Id === "88fdd1b2-d731-4279-81f3-71daee8c9fb0")
	public DisplayName: string;

	// PROJECTS
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Origin.ProjectIdentifierFieldMapping", 3, "_localization:Origin.ProjectIdentifierFieldMapping")
	@PFPropertyRender((item: Origin, i, col) => item.ProjectIdentifierFieldMapping != null ? `${item.ProjectIdentifierFieldMapping.Item1} - ${item.ProjectIdentifierFieldMapping.Item2}` : "N/A", { EntityType: Project }, "PropertyMapping", (item: Origin) => item.Id === guid.empty || item.Id === "88fdd1b2-d731-4279-81f3-71daee8c9fb0")
	public ProjectIdentifierFieldMapping: Tuple;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Origin.ProjectPeopertiesAllowedForEdit", 4, "_localization:Origin.ProjectPeopertiesAllowedForEditDescription")
	@PFPropertyRender(null, { EntityType: Project, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true }, "EntityPropertySelector", (item: Origin) => item.Id === "88fdd1b2-d731-4279-81f3-71daee8c9fb0")
	public ProjectPropertiesAllowed: Array<string>;

	// RESOURCES
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Origin.ResourceIdentifierFieldMapping", 5, "_localization:Origin.ResourceIdentifierFieldMappingDescription")
	@PFPropertyRender((item: Origin, i, col) => item.ResourceIdentifierFieldMapping != null ? `${item.ResourceIdentifierFieldMapping.Item1} - ${item.ResourceIdentifierFieldMapping.Item2}` : "N/A", { EntityType: Resource }, "PropertyMapping", (item: Origin) => item.Id === guid.empty || item.Id === "88fdd1b2-d731-4279-81f3-71daee8c9fb0")
	public ResourceIdentifierFieldMapping: Tuple;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Origin.ResourcePeopertiesAllowedForEdit", 6, "_localization:Origin.ResourcePeopertiesAllowedForEditDescription")
	@PFPropertyRender(null, { EntityType: Resource, PropertyTypes: "Both", MultiSelect: true, DisableEmptyEntry: true }, "EntityPropertySelector", (item: Origin) => item.Id === "88fdd1b2-d731-4279-81f3-71daee8c9fb0")
	public ResourcePropertiesAllowed: Array<string>;

	constructor() {
		this.Id = guid.newGuid();
		this.ProjectIdentifierFieldMapping = new Tuple();
		this.ResourceIdentifierFieldMapping = new Tuple();
	}
}