import { ValidationOverride } from "@wiberg/formbuilder";
import { IFieldOverrides, IFormItem } from "spark-forms";
import { LogSeverityLevel } from "../Entities/LogSeverityLevel";
import { LogDto } from "../Logging/LogDto";
import { Permission } from "../Services/Permission";

// this file is used 10 places

export class DebugOverrides {
	public static Enabled = false;
}

export let unrestrictedMode = false;

export const EnableUnrestrictedMode = (value: boolean) => {
	// silent exit
	if (!Permission.IsAdmin()) return;

	if (value) {
		console.warn("####################################      WARNING     #####################################");
		console.warn("####################################      WARNING     #####################################");
		console.warn("					  This feature should not be used in production");
		console.warn("###########################################################################################");
		console.warn("	IMPORTANT! Using the unrestricted mode may cause critical errors! Use with caution..");
		console.warn("					       Logging enabling of unrestricted mode");
		console.warn("###########################################################################################");
		
		// log whenever enabled
		const dto = new LogDto();
		dto.Level = LogSeverityLevel.Critical;
		dto.Message = "UNRESTRICTED MODE ENABLED!";
		// LogService.Log(dto); // plugin not there anymore!! (ewi)
		// TODO: add logging (on critical level) (ewi)
	}
	
	DebugOverrides.Enabled = value;
	unrestrictedMode = value;
}

window["unrestricted_mode"] = EnableUnrestrictedMode;

export const formUnrestrictedOld = (disabledProperties?: string[]) => {
	return { 
		validationOverride: unrestrictedMode ? ValidationOverride.Continue : ValidationOverride.None,
		propertyOverrides: unrestrictedMode ? { disabledProps: false } : (disabledProperties != null ? { disabledProps: disabledProperties } : undefined)
	}
}

export const formUnrestricted = <T extends IFormItem>(partial?: IFieldOverrides<T>) : IFieldOverrides<T> => {
	return { 
		disabledProps: unrestrictedMode ? false : partial.disabledProps,
		hiddenProps: partial?.hiddenProps
	}
}