import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { IconButton, Stack, TooltipHost } from '@fluentui/react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { ResourceType } from '../../../Entities/ResourceType';
import { Plugin } from '../../../Services/PluginInvoker';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import { language } from '../../../Services/LocalizationService';
import { GenericPicker, IPickerOptions, IPickerSuggestionsOptions } from '../../../forms/xxx-components-refactor/GenericPicker';
import { config } from 'react-transition-group';
import EnumEx from '../../../Utilities/EnumEx';
import { stringVariants } from '../../../helpers/stringVariants';
import { Resource } from '../../../Entities/Main/Resource';
import { ResourceWithStringVariants } from '../../../ListItems/ResourceWithStringVariants';
 
interface PickerProperties {
	PrimaryTextField?: string;
	SecondaryTextField?: string;
	TertiaryTextField?: string;
}

interface IProps extends IDynamicEditorComponentProps { 
    Item: string | Array<string>;
    Update: (value: string | Array<string>) => void;
    Key?: string;

	MultiSelect?: boolean;
	RoleSearchEnabled?: boolean;
	RbsMatch?: boolean;
	Types: Array<ResourceType>;
	ExcludeInactive?: boolean;
	PlaceHolder?: string;
	ClearSelection?: (typeToClear: string) => void;
	OnlyGenericResourcesInUse?: boolean;
	OnlyPoolResourcesInUse?: boolean;

	PickerProperties?: PickerProperties;
	PropertySelector?: (item: Resource) => any;
}

const ResourcePicker: FunctionComponent<IProps> = ({Item, Update, ClearSelection, PropertySelector, ExcludeInactive, MultiSelect, Types, OnlyGenericResourcesInUse, OnlyPoolResourcesInUse, PickerProperties, RbsMatch, RoleSearchEnabled, Parent, PlaceHolder, ReadOnly, Required, Key}) => {
	
	const [item, setItem] = useState(Item);

	useEffect(() => {
		setItem(Item);
	}, [Item])
	
	const getResources = useCallback(async () : Promise<Resource[]> => {
		const options = { OnlyActive: ExcludeInactive, RbsMatch: RbsMatch, ResourceTypes: Types != null ? Types : EnumEx.all<ResourceType>(ResourceType), OnlyGenericResourcesInUse: OnlyGenericResourcesInUse, OnlyPoolResourcesInUse: OnlyPoolResourcesInUse };
		return await Plugin.Invoke<Array<ResourceWithStringVariants>>(PluginIDs.GetResourcesForPickerField, options);
	}, [ExcludeInactive, OnlyGenericResourcesInUse, OnlyPoolResourcesInUse, RbsMatch, Types])

	const getFieldStringValue = (item: Resource, field: string) : string => {
		return stringVariants.getAsStringValue(item, field as string);
	}

	const buildOptions = useCallback((): IPickerOptions<Resource> => (
		{
			entityGetter: getResources,
			itemParser: (item: Resource) => (
				{
					id: item.id,
					displayName: item.Name,
					description: getFieldStringValue(item, PickerProperties?.SecondaryTextField ?? "Email"),
					Email: item.Email // for hack use in 'NotificationSender' (ewi)
				} as any //as IPickerItem
			),
			valueSelector: PropertySelector,
			recentItemsKey: 'resourcepicker-recentlyused',
			multiSelect: MultiSelect,
			suggestions: {
				loadingText: language.Common.Loading,
				noResultsFound: language.Common.NoResults,
				showRemoveButtons: true,
				suggestionsHeaderText: language.ResourcePicker.RecentlyUsedResources,
				suggestionsContainerText: language.Common.Resources,
				suggestionsAvailableAlertText: language.ResourcePicker.RecentlyUsedResources,
			} as IPickerSuggestionsOptions,
			placeHolder: PlaceHolder
		} as IPickerOptions<Resource>
	// eslint-disable-next-line react-hooks/exhaustive-deps
	), [config, getResources])
	
	const pickerOptions = useState(() => buildOptions())[0];

	const onItemsChange = (items: string | string[]): void => {
        if (MultiSelect)
            Update(items);
        else
            Update(items as string);
	};

	const clear = () => {
		setItem(null);
		ClearSelection('resource');
	}

	return (
		<>
			<Stack horizontal verticalAlign={'start'}>
				<GenericPicker
					styles={{root:{flexGrow:1}}}
					// styles={{root:{flexGrow:1, padding: '1px', margin: '1px'}}}
					item={item}
					update={(values) => onItemsChange(values)}
					pickerOptions={ pickerOptions }
					readOnly={ReadOnly}
				/>
				{ClearSelection && 
					<TooltipHost styles={{root:{paddingBottom:4}}} content={language.LMCapacity.ClearSelection.Project}>
						<IconButton styles={{icon:{fontSize: 24}}} iconProps={ { iconName: "ClearFormattingEraser" } } onClick={ clear } disabled={ReadOnly} />
					</TooltipHost>
				}
            </Stack>
		</>
	)
}

export default ResourcePicker;