import { FormBuilder, FormRef, ValidationResult } from '@wiberg/formbuilder';
import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { PFDialog } from '../../../../../Components/Common/PFDialog';
import { PFErrorBoundary } from '../../../../../Components/Common/PFErrorBoundary';
import { PFSpinner } from '../../../../../Components/Common/Spinner';
import { string } from '../../../../../forms/helpers/string';
import { guid } from '../../../../../helpers/guid';
import { language } from '../../../../../Services/LocalizationService';
import { Plugin } from '../../../../../Services/PluginInvoker';
import { PluginIDs } from '../../../../../Utilities/PluginIDs';
import { IMoveResourceActivitiesInfo } from '../../ResourceOverviewExtra';
import { moveAllActivitiesSchema } from './moveAllActivitiesSchema';

interface IProps {
	info: IMoveResourceActivitiesInfo;
	clear: () => void;
}

export interface IMoveAllActivitiesDto {
	sourceResourceId: string;
	targetResourceId: string;
	type: "request" | "allocation" | "both";
}

export const MoveAllActivitiesDialog: FunctionComponent<IProps> = ({ info, clear }) => {

	const formRef = useRef<FormRef<IMoveAllActivitiesDto>>();
	const [loading, setLoading] = useState(false);
	const [dto, setDto] = useState<IMoveAllActivitiesDto>();
	const [success, setSuccess] = useState<boolean>();
	const [showDialog, setShowDialog] = useState(false);

	useEffect(() => {
		const clone = {} as IMoveAllActivitiesDto;
		clone.sourceResourceId = info?.resourceId;
		clone.type = info?.type;
		setDto(clone);
		if (guid.isGuidNotEmpty(info?.resourceId))
			setShowDialog(true);
	}, [info?.resourceId, info?.type])

	const moveAllAllocations = async () : Promise<void> => {
		
		const validationResult = await formRef.current.validateItem();
		if (validationResult === ValidationResult.Failed) return;
		
		setLoading(true);

		try {
			// requests are not ready in backend yet #gofundme (ewi)
			// if (info.type == 'allocation')
			await Plugin.InvokeNoResponse(PluginIDs.MoveAllAllocations, dto, language.MoveAllAllocationsDialog.FailedMovingAllocations);
		} catch(error) {
			setSuccess(false);
			setLoading(false);
			console.log(error);
			return;
		}

		setSuccess(true);
		setLoading(false);
	}

	const onChange = (item: IMoveAllActivitiesDto, prop: string, value: any) => {
		setDto(item);
	}

	const dismiss = () => {
		setDto(null);
		setShowDialog(false);
		setSuccess(null);
		clear();
	}

	const subText = (): string => {
		if (loading) return string.empty;
		if (success == null) return language.MoveAllAllocationsDialog.SubText;
		if (success) return language.MoveAllAllocationsDialog.SubTextSuccess;
		if (!success) return language.MoveAllAllocationsDialog.FailedMovingAllocations;
	}

	return <PFDialog
				title={language.MoveAllAllocationsDialog.DialogTitle}
				subText={ subText() }
				showDialog={ showDialog }
				maxWidth={600}
				callback={ success ? dismiss : moveAllAllocations }
				dismissCallback={ dismiss }
				okButtonEnabled={!loading}
			>
				<div className="tp-moverequest-dialog-content">
					{ loading
						? 	<PFSpinner CustomClass="justoverridedefault" />
						: !success && <PFErrorBoundary ComponentName="move allocations dialog" Reset={ async () => setDto(null) }>
								<FormBuilder
									item={dto}
									schemaConfig={{ schemaProvider: moveAllActivitiesSchema }}
									singleItemProps={{ onPropertyChange: onChange }}
									formRef={formRef}
								/>
							</PFErrorBoundary>
					}
				</div>
			</PFDialog>
}
