import { fluentUiValidationMessageElement } from "@wiberg/fluentui-builder";
import { IFormItem, IPropertyRenderProps } from "@wiberg/formbuilder";
import { PropsWithChildren, ReactElement, useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { ApiCalls } from "../../../../api/api";
import { Project, ProjectPickerDto } from "../../../../api/generated/data-contracts";
import { ProjectType } from "../../../../Entities/ProjectType";
import { stringVariants } from "../../../../helpers/stringVariants";
import { language } from "../../../../Services/LocalizationService";
import EnumEx from "../../../../Utilities/EnumEx";
import { GenericPicker, IPickerItem, IPickerOptions, IPickerSuggestionsOptions } from "../../../xxx-components-refactor/GenericPicker";
import { IProjectFieldConfig } from "./config/IProjectFieldConfig";
import { PROJECT_FIELD } from "../../../../context/network/http/QueryProvider/queryKeys";

interface IProps<T extends IFormItem> extends IPropertyRenderProps<T, IProjectFieldConfig<T>, string | string[] | undefined> { 
    config?: IProjectFieldConfig<T>;
}

export const ProjectField = <T extends IFormItem>({onChange, disabled, parent, value, config, errorMessage}: PropsWithChildren<IProps<T>>) : ReactElement | null => {

	const {
        data: projects,
    } = useQuery(
        [PROJECT_FIELD, config.excludeInactive, config.projectTypes],
        async () => {
			const dto = {
                onlyActive: config.excludeInactive,
				projectTypes: config.projectTypes != null ? config.projectTypes : EnumEx.allAsNumber<ProjectType>(ProjectType),
            } as ProjectPickerDto;
			const response = await ApiCalls.getProjectsForPicker(dto);
			return response.data as Project[];
        }
    );

	const getFieldStringValue = (item: Project, field: keyof Project) : string => {
		return stringVariants.getAsStringValue(item, field as string);
	}

	const buildOptions = useCallback((): IPickerOptions<Project> => (
		{
			entityGetter: async () => projects,
			itemParser: (item: Project) => (
				{
					id: item.id,
					displayName: getFieldStringValue(item, config.nameField),
					description: getFieldStringValue(item, config.descriptionField)
				} as IPickerItem
			),
			recentItemsKey: 'projectpicker-recentlyused',
			multiSelect: config.multiSelect,
			suggestions: {
				loadingText: language.Common.Loading,
				noResultsFound: language.Common.NoResults,
				showRemoveButtons: true,
				suggestionsHeaderText: language.ProjectPicker.RecentlyUsedProjects,
				suggestionsContainerText: language.Common.Resources,
				suggestionsAvailableAlertText: language.ProjectPicker.RecentlyUsedProjects
			} as IPickerSuggestionsOptions
		} as IPickerOptions<Project>
	), [config.descriptionField, config.multiSelect, config.nameField, projects])
	
	const pickerOptions = useMemo(buildOptions, [buildOptions]);

	return (
		<>
			<GenericPicker
				styles={undefined}
				item={value}
				update={(values) => onChange(values)}
				pickerOptions={ pickerOptions }
				readOnly={disabled}
				pickerType={config.pickerType}
			/>
			{ errorMessage && fluentUiValidationMessageElement(errorMessage) }
		</>
	)
}
