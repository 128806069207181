import { produce } from '../helpers';
import { PersistGetter, TPContext } from '../types';

export type IGridContext = {
    gridSettingsEnableFastDeletion: boolean;
    readonly setGridSettingsEnableFastDeletionCallback: (show?: boolean) => void;
};

export const gridContext: TPContext<IGridContext> = (set, get, api) => {
    return {
        gridSettingsEnableFastDeletion: false,
        setGridSettingsEnableFastDeletionCallback: show =>
            set(
                produce(store => {
                    store.gridSettingsEnableFastDeletion = show ?? !store.gridSettingsEnableFastDeletion;
                }),
            ),
    };
};

export const getPersistGridContext: PersistGetter = store => {
    return { gridSettingsEnableFastDeletion: false };
};
