import { Panel as FluentPanel, IPanelProps } from '@fluentui/react/lib/Panel';
import { PFErrorBoundary } from '../../../../Components/Common/PFErrorBoundary';

export const Panel: React.FC<IPanelProps> = ({ children, ...props }) => {
    return (
        <FluentPanel {...props}>
            <PFErrorBoundary>{children}</PFErrorBoundary>
        </FluentPanel>
    );
};
