import { useState, useCallback, useMemo } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { IFieldConfig, IFieldRenderProps, useForm, useFormContext, useValidation, useWatch } from 'spark-forms';
import { FluentLabel, FluentValidationMessage } from 'spark-forms-fluent8';
import { TPBuilder } from '../../../../forms/_builders/new/TPBuilder';
import { useQuery } from 'react-query';
import { ApiCalls } from '../../../../api/api';
import { EntityType, UpsertDto } from '../../../../api/generated/data-contracts';
import { PFDialog } from '../../../../Components/Common/PFDialog';
import { IWorkProfile } from '../../../../forms/workprofile/IWorkProfile';
import { language } from '../../../../Services/LocalizationService';
import { tpEntityFactory, TPEntityTypes } from '../../../../forms/common/tpEntityFactory';
import { workProfileSchema } from '../../../../forms/workprofile/workProfileSchema';
import { ShimmerBox } from '../../../../Components/Skeleton/ShimmerBox';
export const ResourceSpecificWorkprofileEditor: React.FC<any> = <T,>({
	value,
	disabled,
	fieldName,
	path
}: IFieldRenderProps<T, IFieldConfig<T>, string | undefined>) => {
	
	const { onChange, item } = useFormContext();
	const { validationMessage } = useValidation(path);

	const [profile, setProfile] = useState<IWorkProfile>();
	const [edit, setEdit] = useState<boolean>(false);
	const [localValue, setLocalValue] = useState<string>(value);

    useWatch(path, () => setLocalValue(value), [value]);

	
	const {
		data: prof,
		isFetching,
		refetch
    } = useQuery(
        ['resourcespecificworkprofile', { id: item.id, wpId: localValue }],
        async () => {
			let wp = null;
			if (localValue) {
				const response = await ApiCalls.getEntity({ entity: EntityType.WorkProfile, id: localValue });
				wp = response.data;
			}
			if (!wp) {
				wp = tpEntityFactory.entity<IWorkProfile>(TPEntityTypes.WorkProfile, {
					resourceGuid: item.id,
					rules: [],
					weekHours: tpEntityFactory.workweek(),
				});
				wp.id = item.id; // set id to resource id
				wp.name = item.name; // set name to resource name
			}
			setProfile(wp);
			return wp as IWorkProfile;
        }
    );

	const options = useMemo(() => {
		return {
			item: prof,
			onChange: setProfile,
			schema: workProfileSchema,
			builder: TPBuilder,
			builderOptions: {
				labelComponent: FluentLabel
			}
		}
	}, [prof])

    const { Form, validateItem } = useForm(options);

	const upsert = useCallback(async () => {
		const validated = await validateItem();
		if (!validated) {
			return;
		}

		const dto = { entity: EntityType.WorkProfile, items: [profile] } as UpsertDto;
		await ApiCalls.upsertEntity(dto);

		onChange(fieldName, profile.id);
		setLocalValue(profile.id);

		setEdit(false);

		refetch();
	},[fieldName, onChange, profile, refetch, validateItem])

	if (isFetching) {
		return <ShimmerBox height={30} />;
	}

	return 	(
		<div>
			<PrimaryButton text={language.ResourceSpecificWorkprofileEditor.WorkProfile} onClick={ () => setEdit(true) } />
			<PFDialog
				title={ language.ResourceSpecificWorkprofileEditor.EditResourceSpecificWorkProfile }
				maxWidth={ 1000 }
				showDialog={ edit }
				callback={ upsert }
				dismissCallback={ () => {
					refetch();
					setEdit(false);
				}}
				buttonDefaultDisabled={disabled}
			>
				{
					prof &&
					<Form />
				}
			</PFDialog>
			<FluentValidationMessage message={validationMessage} />
		</div>
	)
}
