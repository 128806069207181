/* eslint-disable react-refresh/only-export-components */
import * as React from 'react';
import { StoreProps } from './StoreProps';
import { TPStateManager } from './TPStateManager';
import { RowMenuItemInfo } from '../Entities/Dto/RowMenuItemInfo';

export interface IStoreState {
	GenericResourceFilter: Array<string>;
	ShowForwardRequestDialog: boolean;
	ForwardRequestInfo: RowMenuItemInfo;
	ShowMoveRequestDialog: boolean;
	MoveRequestInfo: RowMenuItemInfo;
	ShowMatchingActivityDialog: boolean;
	MatchingActivityInfo: RowMenuItemInfo;
	ProjectTeamSelected: boolean;
	FindAvailableCapacityDialog: boolean;
	FindAvailableCapacityInfo: RowMenuItemInfo;
}

export const StoreContext = React.createContext<TPStateManager>({} as any);
export const useStoreContext = () => React.useContext(StoreContext);

const CreateStore = WrappedComponent => {
	return class extends React.Component<any, IStoreState> {

		private StateManager = new TPStateManager(this);

		constructor(props) {
			super(props);
			this.state = {
				GenericResourceFilter: null,
				
				/** Move these down to where needed */
				ShowForwardRequestDialog: false,
				ForwardRequestInfo: null,
				ShowMoveRequestDialog: false,
				MoveRequestInfo: null,
				ShowMatchingActivityDialog: false,
				MatchingActivityInfo: null,
				ProjectTeamSelected: false,
				FindAvailableCapacityDialog: false,
				FindAvailableCapacityInfo: null,
			}
		}

		render() {
			return (
				<StoreContext.Provider value={{...this.StateManager as any}}>
					<WrappedComponent {...this.props} />
				</StoreContext.Provider>
			)
		}
	}
}

const WithStore = <TProps extends StoreProps>(WrappedComponent) => {
	return class extends React.Component<TProps> {
		render() {
			return (
				<StoreContext.Consumer>
					{context => <WrappedComponent<TProps> Store={context} {...this.props} />}
				</StoreContext.Consumer>
			)
		}
	}
}

export { CreateStore, WithStore }