import moment from 'moment';
import { OverviewContext } from './Context/OverviewContext';

// please, please, please switch away from moment ASAP!! (ewi)
import 'moment/min/locales.min';

export function setLocale(locale: string): void {
	moment.locale(locale);
}

export function firstDayNumberOfWeek(): number {
	return moment.localeData().firstDayOfWeek();
}

export function lastDayNumberOfWeek(): number {
	const firstDayOfWeek = moment.localeData().firstDayOfWeek();
	if (firstDayOfWeek === 0)
		return 6;
	else
		return firstDayOfWeek - 1;
}

export function firstDayOfYear(): number {
	return moment.localeData().firstDayOfYear();
}

export function specifyUtc(date: Date): string {
	if (date == null) return null;
	const offset = date.getTimezoneOffset();
    const myDate = Date.parse(date.toString()) - (offset * 60 * 1000);
    const dateAsISO = new Date(myDate).toISOString();
    return dateAsISO;

	// return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())).toISOString();
}

export function getDateOfISOWeek(week: number, year: number): Date {
	const simple = new Date(year, 0, 1 + (week - 1) * 7);
	const dow = simple.getDay();
	const ISOweekStart = simple;
	if (dow <= 4)
		ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);

	else
		ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
	return ISOweekStart;
}

export function getStartDateOfWeek(week: number, year: number): Date {
	const firstDayOfWeek = moment.localeData().firstDayOfWeek();
	return moment().day(firstDayOfWeek).week(week).year(year).toDate();
}

export function getQuarter(date: Date): { quarter: number; year: number; } {
	const quarter = moment(date).quarter();
	return { quarter: quarter, year: date.getFullYear() };
}

export const getWeekNumber = (date: Date, locale: string, useISOweek: boolean) : { week: string, year: string } => {

	let week = moment(date).week();

	let firstWeekDay = getFirstDayOfWeek(date);
	let endWeekDay = lastDayOfWeek(date);
	
	// can't be more wrong in regards to every standard in the whole world.. but so be it!
	if (locale === "en-US" && useISOweek) {
		// iso has mondays as first day of week
		const pushedDate = date.getDay() === 0 ? addDay(date) : date;
		week = moment(pushedDate).isoWeek();
		// push first and last as well
		firstWeekDay = addDay(firstWeekDay);
		endWeekDay = addDay(endWeekDay);
	} else if (locale === "en-US" && !useISOweek) {
		// handle shitty US crap - not good
		if (firstWeekDay.getFullYear() !== endWeekDay.getFullYear()) {
			return { week: String(week), year: `${endWeekDay.getFullYear()}` };
		}
	}
	// first week of year rule (ISO 8601)
	if (useISOweek && firstWeekDay.getFullYear() !== endWeekDay.getFullYear()) {
		const weekYear = endWeekDay.getDate() >= 4
			? endWeekDay.getFullYear()
			: firstWeekDay.getFullYear();

		return { week: String(week), year: weekYear+"" };
	}

	return { week: String(week), year: firstWeekDay.getFullYear()+"" };
}

export function addYear(date: Date, count: number = 1): Date {
	const clone = new Date(date);
	return new Date(clone.setFullYear(clone.getFullYear() + count));
}

export function addQuarter(date: Date, count: number = 1): Date {
	const clone = new Date(date);
	return moment(clone).add(count, 'Q').toDate();
}

export function addMonth(date: Date, count: number = 1): Date {
	const clone = new Date(date);
	return new Date(clone.setMonth(clone.getMonth() + count));
}

export function addWeek(date: Date, count: number = 1): Date {
	const clone = new Date(date);
	return new Date(clone.setDate(clone.getDate() + (7 * count)));
}

export function addDay(date: Date, count: number = 1): Date {
	const clone = new Date(date);
	return new Date(clone.setDate(clone.getDate() + count));
}

export function addSecond(date: Date, count: number = 1): Date {
	const clone = new Date(date);
	return new Date(clone.setSeconds(clone.getSeconds() + count));
}

export function getDaysCount(start: Date, end: Date): number {
	if (!start || !end)
		return -1;
	return Math.floor((+end - +start) / 86400000);
}

export function daysInMonth(month: number, year: number): number {
	return new Date(year, month, 0).getDate();
}

export function isWeekend(date: Date): boolean {
	if (date == null)
		return false;
	return date.getDay() === 6 || date.getDay() === 0;
}

export function equals(d1: Date, d2: Date): boolean {
	if (d1 == null || d2 == null)
		return false;
	return new Date(d1).getTime() === new Date(d2).getTime();
}

export function todaysDate(): Date {
	const today = new Date();
	return new Date(today.getFullYear(), today.getMonth(), today.getDate());
}

export function dateOnly(date: Date): Date {
	const d = new Date(date);
	return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

export function firstWeekDay(): number {
	return moment.localeData().firstDayOfWeek();
}

export function lastWeekDay(): number {
	const firstDay = moment.localeData().firstDayOfWeek();
	return firstDay === 0 ? 6 : firstDay - 1;
}

export function getFirstDayOfWeek(date?: Date, useISO8601?: boolean): Date {
	const firstDay = moment(date ?? new Date()).startOf(useISO8601 ? 'isoWeek' : 'week').toDate();
	return dateOnly(firstDay);
}

export function getFirstDayOfQuarter(date?: Date): Date {
	const firstDay = moment(date ?? new Date()).startOf('quarter').toDate();
	return dateOnly(firstDay);
}

export function lastDayOfQuarter(date?: Date): Date {
	const lastDay = moment(date ?? new Date()).endOf('quarter').toDate();
	return dateOnly(lastDay);
}

export function getFirstDayOfMonth(date?: Date): Date {
	const today = todaysDate();
	date = date || today;
	return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getFirstDayOfYear(date?: Date): Date {
	const today = todaysDate();
	date = date || today;
	return new Date(date.getFullYear(), 0, 1);
}

export function firstDayOfNextWeek(date?: Date): Date {
	const firstDayOfWeek = moment.localeData().firstDayOfWeek();

	const today = todaysDate();
	date = date ? dateOnly(date) : today;
	const day = date.getDay(), diff = date.getDate() - day + (day === 0 ? -6 : firstDayOfWeek) + 7;
	return new Date(date.setDate(diff));
}

export function lastDayOfWeek(date?: Date, useISO8601?: boolean): Date {
	const lastDay = moment(date).endOf(useISO8601 ? 'isoWeek' : 'week').toDate();
	return dateOnly(lastDay);
}

export function firstDayOfNextMonth(date?: Date): Date {
	const today = todaysDate();
	date = date ? dateOnly(date) : today;
	date.setMonth(date.getMonth() + 1);
	date.setDate(1);
	return date;
}

export function lastDayOfMonth(date?: Date): Date {
	const today = todaysDate();
	date = date ? dateOnly(date) : today;
	return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function firstDayOfNextYear(date?: Date): Date {
	const today = todaysDate();
	date = date ? dateOnly(date) : today;
	date.setFullYear(date.getFullYear() + 1);
	date.setMonth(0);
	date.setDate(1);
	return date;
}

export function lastDayOfYear(date?: Date): Date {
	const today = todaysDate();
	date = date ? dateOnly(date) : today;
	return new Date(date.getFullYear(), 11, 31);
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
export function getDifferenceInDays(d1: Date, d2: Date): number {
	// Discard the time and time-zone information.
	const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
	const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());

	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function getDifferenceInMonths(d1: Date, d2: Date) {
	const date1 = moment(new Date(d1));
	const date2 = moment(new Date(d2));
	const diff = date2.diff(date1, "months", true);
	return Math.ceil(diff)
}

export function getDifferenceInQuarters(d1: Date, d2: Date) {
	const date1 = moment(new Date(d1));
	const date2 = moment(new Date(d2));
	const diff = date2.diff(date1, "quarters", true);
	return Math.ceil(diff)
}

export function getDifferenceInYears(d1: Date, d2: Date) {
	const date1 = moment(new Date(d1));
	const date2 = moment(new Date(d2));
	const diff = date2.diff(date1, "years", true);
	return Math.ceil(diff)
}

export function getDifferenceInWeeks(d1: Date, d2: Date) {
	const date1 = moment(new Date(d1));
	const date2 = moment(new Date(d2));
	const diff = date2.diff(date1, "weeks", true);
	return Math.ceil(diff)
}

export function formatDate(date: Date): string {
	const momentObj = moment(date);
	return momentObj.format('ddd Do MMM YYYY');
}

export function formatDateWithTimeSimple(date: Date): string {
	const today = todaysDate();
	const dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
	let dateString: string;
	if (today.getTime() === dateDate.getTime())
		dateString = "Today";

	else
		dateString = formatDate(date);

	const hours = `0${date.getHours()}`.slice(-2);
	const minutes = `0${date.getMinutes()}`.slice(-2);
	const seconds = `0${date.getSeconds()}`.slice(-2);
	const timeString = `${hours}:${minutes}:${seconds}`;
	const fullString = `${dateString} - ${timeString}`;
	return fullString;
}

export function asUserLocalWithTime(utcDate: Date, locale?: string): string {
	if (utcDate == null) return "";
	const localDate = (moment(utcDate).add(Number(moment().utcOffset()), 'm')) as moment.Moment;
	// TODO: move out from here
	locale ??= OverviewContext.Settings.Localization;
	localDate.locale(locale);
	return localDate.format('YYYY-MM-DD HH:mm:ss ZZ');
}

export function asUserLocalWithTimeNoZ(utcDate: Date, locale?: string): string {
	if (utcDate == null) return "";
	const localDate = (moment(utcDate).add(Number(moment().utcOffset()), 'm')) as moment.Moment;
	// TODO: move out from here
	locale ??= OverviewContext.Settings.Localization;
	localDate.locale(locale);
	return localDate.format('YYYY-MM-DD HH:mm:ss');
}

export function asUserLocalWithTimePretty(utcDate: Date, locale?: string): string {
	if (utcDate == null) return "";
	const localDate = (moment(utcDate).add(Number(moment().utcOffset()), 'm')) as moment.Moment;
	// TODO: move out from here
	locale ??= OverviewContext.Settings.Localization;
	localDate.locale(locale);
	return localDate.format('ddd Do MMM YYYY HH:mm');
}

export function asUserLocal(utcDate: Date, locale?: string): string {
	if (utcDate == null) return "";
	const localDate = (moment(utcDate).add(Number(moment().utcOffset()), 'm')) as moment.Moment;
	// TODO: move out from here
	locale ??= OverviewContext.Settings.Localization;
	localDate.locale(locale);
	const formattedLocalDate = localDate.format('ddd Do MMM YYYY');
	if(!utcDate || formattedLocalDate === "Mon 1st Jan 0001") return ""; //TODO EWI, THIS WAS CHANGED TO AVOID 'Invalid Date'
	return localDate.format('ddd Do MMM YYYY');
}

export function tryParse(obj: any): Date {
	if (obj == null)
		return null;
	const date = moment(obj);
	if (date == null)
		return null;
	return date.toDate();
}

export function getDatesBetween(start: Date, end: Date, interval: "years" | "months" | "weeks" | "days", total: number): Array<Date> {
	const dateArray = [];
	let currentDate = moment({ ...start });
	const momentEnd = moment(end);

	while (currentDate < momentEnd) {
		dateArray.push(currentDate);
		currentDate = currentDate.add(total, interval);
	}

	return dateArray;
}

export function toUtcString(date: Date): string {
	return moment.utc(moment(date).utc()).format();
}

export function fixJsonAutoParse(date: Date): Date {
	// hack to prevent the json stringyfier to set utc incorrectly
	const inverseOffset = moment(date).utcOffset() * -1;
	const alteredDate = moment().utcOffset(inverseOffset);
	// another hack - fuck dates and javascript
	(alteredDate as any)._offset = 0;
	const etst = new Date(alteredDate.local().format());
	return etst;
}

export function isNullOrMin(date: Date): boolean {
	return date == null || date as any === "0001-01-01T00:00:00" || new Date(date).getFullYear() <= 1;
}

export function isValidISODate(value: string): boolean {
    // Attempt strict parsing with UTC designation ("Z") first
    if (moment(value, moment.ISO_8601, true).isValid()) {
        return true;
    }
    return false;
}

export const DateEx = {
	addDay,
	addMonth,
	addQuarter,
	addSecond,
	addWeek,
	addYear,
	asUserLocal,
	asUserLocalWithTime,
	asUserLocalWithTimeNoZ,
	asUserLocalWithTimePretty,
	dateOnly,
	daysInMonth,
	equals,
	firstDayNumberOfWeek,
	firstDayOfNextMonth,
	firstDayOfNextWeek,
	firstDayOfNextYear,
	firstDayOfYear,
	firstWeekDay,
	fixJsonAutoParse,
	formatDate,
	formatDateWithTimeSimple,
	getDateOfISOWeek,
	getDatesBetween,
	getDaysCount,
	getDifferenceInDays,
	getDifferenceInMonths,
	getDifferenceInQuarters,
	getDifferenceInWeeks,
	getDifferenceInYears,
	getFirstDayOfMonth,
	getFirstDayOfQuarter,
	getFirstDayOfWeek,
	getFirstDayOfYear,
	lastDayOfQuarter,
	getQuarter,
	getStartDateOfWeek,
	getWeekNumber,
	isNullOrMin,
	isWeekend,
	lastDayNumberOfWeek,
	lastDayOfMonth,
	lastDayOfWeek,
	lastDayOfYear,
	lastWeekDay,
	setLocale,
	specifyUtc,
	toUtcString,
	todaysDate,
	tryParse,
	isValidISODate
}