export default class Locale_Stickyness {
	Off = "Off";
	OffDescription = "Disable stickyness";
	Always = "Always";
	AlwaysDescription = "Always show contract";
	ActivitiesBefore = "Activities before";
	ActivitiesBeforeDescription = "Show contract if there are any activities before selected timespan";
	ActivitiesAfter = "Activities after";
	ActivitiesAfterDescription = "Show contract if there are any activities after selected timespan";
	ActivitiesBeforeOrAfter = "Activities before or after";
	ActivitiesBeforeOrAfterDescription = "Show contract if there are any activities before or after selected timespan";
}