import { Resource } from "../../Entities/Main/Resource";

export class UserContext {
	// public static PrincipalName: string;
	// public static SecurityToken: object;
	// public static TokenExpires: Date;

	public static LoginName: string;
	public static Resource: Resource;
	public static IsDelegated: boolean;

	public static Initialize = (user: any): void => {
		// UserContext.PrincipalName = user.PrincipalName;
		// UserContext.SecurityToken = user.SecurityToken;
		// UserContext.TokenExpires = user.TokenExpires;

		UserContext.LoginName = user.LoginName;
		UserContext.Resource = user.Resource;
		// UserContext.IsDelegated = Boolean(JSON.parse(user.IsDelegated.toLowerCase()));
		UserContext.IsDelegated = user.IsDelegated;

		window["User"] = user;
	}
}