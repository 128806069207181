import { lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { Overview } from '../Components/Overview/Overview';
import { ProjectOverview } from '../views/overviews/project/ProjectOverview';
import ResourceCapacity from '../views/capacityViews/resourceCapacity/ResourceCapacity';
import { flattenRoutes, RouteObjectWithName } from '../helpers/routeHelpers';
import { NotFound } from './components/NotFound';
import { DefaultRoute } from './components/DefaultRoute';
import { RequireAuth } from './components/RequireAuth';
import { RequestView } from '../views/capacityViews/projectCapacity/regular/Request';
import { AllocationView } from '../views/capacityViews/projectCapacity/regular/Allocation';
import { DraftAllocationView } from '../views/capacityViews/projectCapacity/drafts/DraftAllocation';
import { DraftRequestView } from '../views/capacityViews/projectCapacity/drafts/DraftRequest';
import { CompareRequestView } from '../views/capacityViews/projectCapacity/compare/DraftRequest';
import { CompareAllocationView } from '../views/capacityViews/projectCapacity/compare/CompareAllocation';
import WorkPackages from '../views/capacityViews/workpackages/WorkPackages';
import { ReportViewer } from '../Components/Common/ReportViewer';
import { Playground } from './components/Playground/Playground';
import { ResourceOverview } from '../views/overviews/resource/ResourceOverview';
import { AccessDenied } from '../Components/Common/AccessDenied';
import { AccessCheck } from './components/AccessCheck';
import { RequireEnvironment } from './components/RequireEnvironment';
import { EnvironmentState } from '../Entities/EnvironmentState';
import { Downgrade } from '../views/adminDashboard/areas/system/areas/upgrade/downgrade-pdf';
import { ResourcePlanner } from '../views/capacityViews/resourceplanner/ResourcePlanner';
import { RequireFeatureFlag } from './components/RequireFeatureFlag';
import { LazyLoadPageSuspender } from './components/LazyLoadPageSuspender';
import { UserType } from '../api/generated/data-contracts';
import { ProjectPlanner } from '../views/capacityViews/projectplanner/ProjectPlanner';
import PlannerView from '../views/capacityViews/planner/PlannerView';

/**
 * Lazy loaded routes
 */
const HelpView = lazy(() => import('../views/help/HelpView'));
const AdminDashboard = lazy(() => import('../views/adminDashboard/AdminDashboard'));
const ContentSettings = lazy(() => import('../views/contentSettings/ContentSettings'));
const ContractChanges = lazy(() => import('../views/contractChanges/ContractChanges'));
const LinemanagerStatus = lazy(() => import('../views/linemanagerStatus/LinemanagerStatus'));
const ContractOverview = lazy(() => import('../views/overviews/contract/ContractOverview'));
const Configuration = lazy(() => import('../views/Configuration/Configuration'))

const routes: RouteObjectWithName[] = [
    {
        path: '/',
        element: <Overview />,
        children: [
            { index: true, element: <></> },
            {
                path: 'request/*',
                name: 'Request',
                element: <RequireAuth userTypes={[UserType.ProjectOwner, UserType.SuperUser]} some />,
                children: [
                    { index: true, element: <RequestView /> },
                    { path: ':projectId', element: <RequestView /> },
                    { path: 'draft/:projectId/:draftId', element: <DraftRequestView />, name: 'Draft view' } as RouteObjectWithName,
                    { path: 'compare/:projectId/:scenarioIds', element: <CompareRequestView />, name: 'Compare' },
                ],
            } as RouteObjectWithName,
            {
                path: 'allocation/*',
                name: 'Allocation',
                element: <RequireAuth userTypes={[UserType.LineManager, UserType.SuperUser]} some />,
                children: [
                    { index: true, element: <AllocationView /> },
                    { path: ':projectId', element: <AllocationView /> },
                    { path: 'draft/:projectId/:draftId', element: <DraftAllocationView />, name: 'Draft view' } as RouteObjectWithName,
                    { path: 'compare/:projectId/:scenarioIds', element: <CompareAllocationView />, name: 'Compare' },
                ],
            },
			{
                path: 'planner/',
                name: 'Planner View',
                element: <RequireAuth userTypes={[UserType.LineManager, UserType.SuperUser]} some />,
                // children: [{ index: true, element: <PlannerView /> }],
                children: [{ index: true, element: <PlannerView /> }],
            },
            {
                path: 'projectplanner/',
                name: 'Project Planner',
                element: <RequireAuth userTypes={[UserType.LineManager, UserType.SuperUser]} some />,
                // children: [{ index: true, element: <PlannerView /> }],
                children: [{ index: true, element: <ProjectPlanner /> }],
            },
            {
                path: 'resourceplanner/',
                name: 'Resource Planner',
                element: (
                    <RequireAuth userTypes={[UserType.SuperUser, UserType.LineManager]} some>
                        <RequireFeatureFlag checker={settings => Boolean(settings.enableResourcePlanner)} />
                    </RequireAuth>
                ),
                children: [{ index: true, element: <ResourcePlanner /> }],
            },
            {
                path: 'resourcecapacity/*',
                name: 'Resource capacity',
                element: <RequireAuth userTypes={[UserType.LineManager, UserType.Resource]} some />,
                children: [
                    { index: true, element: <ResourceCapacity /> },
                    { path: ':resourceId', element: <ResourceCapacity /> },
                ],
            },
            {
                path: 'resourceoverview',
                name: 'Resource overview',
                element: <RequireAuth userTypes={[UserType.LineManager, UserType.SuperUser]} some />,
                children: [{ index: true, element: <ResourceOverview /> }],
            },
            {
                path: 'projectoverview',
                name: 'Project overview',
                element: <RequireAuth userTypes={[UserType.ProjectOwner, UserType.SuperUser]} some />,
                children: [{ index: true, element: <ProjectOverview /> }],
            },
            {
                path: 'workpackages',
                name: 'Workpackages',
                element: <RequireAuth userTypes={[UserType.ProjectOwner, UserType.SuperUser]} some />,
                children: [{ index: true, element: <WorkPackages /> }],
            },
            {
                path: 'contentsettings/*',
                name: 'Content settings',
                element: <RequireAuth userTypes={[UserType.SuperUser]} />,
                children: [
                    {
                        index: true,
                        element: (
                            <LazyLoadPageSuspender label="Loading Content Settings">
                                <ContentSettings />
                            </LazyLoadPageSuspender>
                        ),
                    },
                    {
                        path: ':pivotItem',
                        element: (
                            <LazyLoadPageSuspender label="Loading Content Settings">
                                <ContentSettings />
                            </LazyLoadPageSuspender>
                        ),
                    },
                ],
            },
            {
                path: 'admindashboard/*',
                name: 'Admin dashboard',
                element: <RequireAuth userTypes={[UserType.Administrator]} />,
                children: [
                    {
                        index: true,
                        element: (
                            <LazyLoadPageSuspender label="Loading Admin Dashboard">
                                <AdminDashboard />
                            </LazyLoadPageSuspender>
                        ),
                    },
                    {
                        path: ':pivotItem',
                        element: <RequireEnvironment disallowedOn={['integrations', 'migrations']} param={'pivotItem'} envStates={[EnvironmentState.Demo]} />,
                        children: [
                            {
                                index: true,
                                element: (
                                    <LazyLoadPageSuspender label="Loading Admin Dashboard">
                                        <AdminDashboard />
                                    </LazyLoadPageSuspender>
                                ),
                            },
                        ],
                    },
                    {
                        path: ':pivotItem/:menuItem',
                        element: <RequireEnvironment disallowedOn={['upgrade']} param={'menuItem'} envStates={[EnvironmentState.Demo]} />,
                        children: [
                            {
                                index: true,
                                element: (
                                    <LazyLoadPageSuspender label="Loading Admin Dashboard">
                                        <AdminDashboard />
                                    </LazyLoadPageSuspender>
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'configuration/*',
                name: 'Configuration',
                element: (
                    <RequireAuth userTypes={[UserType.Administrator]}>
                        <Configuration />
                    </RequireAuth>
                ),
            },
            {
                // Report viewer is responsible for handling report viewing permissions
                // since the permissions is attached to the report itself
                path: 'reportviewer/:reportId',
                name: 'Report view',
                element: <ReportViewer />,
            },
            {
                path: 'contractoverview',
                element: 
					<LazyLoadPageSuspender label="Loading Contract overview">
						<ContractOverview />
					</LazyLoadPageSuspender>,
            },
            {
                path: 'contractchanges',
                element: (
                    <LazyLoadPageSuspender label="Loading Contract Changes">
                        <ContractChanges />
                    </LazyLoadPageSuspender>
                ),
            },
			{
                path: 'linemanagerstatus',
                element: (
                    <LazyLoadPageSuspender label="Loading Linemanager Status">
						<RequireAuth userTypes={[UserType.LineManager]}>
							<LinemanagerStatus />
						</RequireAuth>
                    </LazyLoadPageSuspender>
                ),
            },
            {
                path: 'help',
                element: (
                    <LazyLoadPageSuspender label="Loading Help section">
                        <HelpView />
                    </LazyLoadPageSuspender>
                ),
            },
            { path: 'playground/*', element: <Playground /> },
            { path: 'ui/downgrade-pdf.html', element: <Downgrade /> }, //TODO Quick workaround
            { path: ':notfound/*', element: <NotFound /> },
        ],
    },
    { path: 'accessdenied', element: <AccessDenied /> },
];

// use for navbar report dropdown (ewi)
// eslint-disable-next-line react-refresh/only-export-components
export const viewsAndPaths = flattenRoutes(routes).reduce((filteredRoutes, route) => {
    if (route.path.includes('*') && !route.path.endsWith('*')) {
        return filteredRoutes;
    }
    if (route.path === '/') {
        return filteredRoutes;
    }
    if (route.path.includes(':notfound')) {
        return filteredRoutes;
    }
    let path = route.path;
    const lastSlash = path.lastIndexOf('/');
    // Handle /* or /:param
    if (lastSlash > 0) {
        path = path.slice(0, lastSlash);
    }
    filteredRoutes.push({
        path,
        name: (route.routesMeta[route.routesMeta.length - 1] as any).route.name,
    });
    return filteredRoutes;
}, [] as { path: string; name?: string }[]);

export const Router = () => {
    const route = useRoutes(routes as RouteObject[]);
	// <AccessCheck /> needs to be the last rendered before the route, otherwise it won't take precedence due to reacts render order
    return (
        <>
            <DefaultRoute />
            <AccessCheck />
            {route}
        </>
    );
};
