import { CSSProperties, useCallback, useEffect, useState } from "react";

/**
 * 
 * @param animationName the name of the keyframes to use for the animation
 * @param duration how long the animation should last in miliseconds
 * @param leading if the animation should run on mount
 * @returns 
 */
export const useAnimationProp = (animationName: string, duration: number, leading = false) => {
    const [animation, setAnimation] = useState<CSSProperties | null>(
        leading ? { animation: `${animationName} ${duration}ms ease both` } : null
    );
    const [count, setCount] = useState(0);
    useEffect(() => {
        if (animation) {
            const timer = setTimeout(() => {
                setAnimation(null);
            }, duration);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [duration, count, animation]);
    const reset = useCallback(() => {
        setAnimation({ animation: `${animationName} ${duration}ms ease both` });
        setCount(c => c++);
    }, [animationName, duration]);

    return [animation, reset] as const;
};
