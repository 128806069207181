import { ReactElement, useMemo } from 'react';
import { MessageBar } from '@fluentui/react';
import { IFieldRenderProps, IFormItem, useFormContext } from 'spark-forms';
import { IMessageBarFieldConfig } from './IMessageBarFieldConfig';

export interface IMessagebarFieldProps<T extends IFormItem> extends IFieldRenderProps<T, IMessageBarFieldConfig<T>, string | object | undefined> {
    config?: IMessageBarFieldConfig<T>;
}

export const MessagebarField = <T extends IFormItem>({ config }: IMessagebarFieldProps<T>): ReactElement | null => {
    const { item } = useFormContext();

    const options = useMemo(() => config.options(item), [config, item]);

    if (options == null) {
        return;
    }

    return (
        <MessageBar messageBarType={options.type} styles={{ root: { width: options.width } }}>
            {options.message}
        </MessageBar>
    );
};
