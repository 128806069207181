import { CoachmarkLocaleItem } from "./Locale_CoachmarkItem";

export default class Locale_Coachmarks {
	public ProjectCapacity = {
		GroupBy: new CoachmarkLocaleItem("Project", "_projectGroupBy"),
		RequestWorkPackages: new CoachmarkLocaleItem("Work Package", "_requestWorkPackages"),
	}
	public WorkPackages = {
		CreateWorkPackage: new CoachmarkLocaleItem("Project", `_createWorkPackage`),
		PickWorkPackage: new CoachmarkLocaleItem('PickWorkPackage', `_pickWorkPackage`),
		IncludeWorkPackage: new CoachmarkLocaleItem('IncludeWorkPackage', `_includeWorkPackage`),
		TotalWorkMenu: new CoachmarkLocaleItem('TotalWorkMenu', `_totalRowMenu`),
		WorkPackageTimeline: new CoachmarkLocaleItem('WorkPackageTimeline', `_workPackageTimeline`),
	}
	public ContentSettings = {
		LookupEditor: new CoachmarkLocaleItem('Lookup Editor', `Define Lookup fields. These are used to EG. show 'Role' on resources.`),
		RBSEditor: new CoachmarkLocaleItem('RBS Editor', `RBS' define your organitazions structure.`),
		WorkProfileEditor: new CoachmarkLocaleItem('Work Profile Editor', `A 'Work Profile' defines a group of resources' work week. These are used to calculate a resources' capacity.`),
		AnnouncementEditor: new CoachmarkLocaleItem('Announcement Editor', `Create platform-wide announcements that are shown upon opening up Team Planner 3. These will by default be shown once per user. 'Content' can be plain text and HTML.`),
		MailTemplateEditor: new CoachmarkLocaleItem('Mail Template Editor', `_mailTemplateEditor`),
	}
	public LMCapacity = {
		Project: new CoachmarkLocaleItem('Projects', `Add projects from the 'Resource Panel' by dragging them to the dropzone.`),
		Resource: new CoachmarkLocaleItem('Resources', `Include resources from the 'Resource Panel' by dragging them to the dropzone.`),
		Settings: new CoachmarkLocaleItem('Actions', `In the settings you can set 'Auto Refresh' when selecting or de-selecting resources or projects. You can force refresh the grid or clear all selections.`),
		View: new CoachmarkLocaleItem('Views', `Manage your own personalized views here.`),
		Drop: new CoachmarkLocaleItem('Include resources and projects', `Search for resources and projects to include, or drop them here when dragging them from the panels.`),

	}
	public Generic = {
		DelegationPanel: new CoachmarkLocaleItem('Delegation Panel', `'Delegation' allows you to perform actions as other users. Among these are creating, updating and deleting entities such as 'Resources' and 'Projects'. `),
		PinButton: new CoachmarkLocaleItem('Pin to Navigation bar', 'Pin to keep the top navigation bar expanded.'),
		TimelineResolution: new CoachmarkLocaleItem('Timeline Resolution', `Pick resolution of the 'Timeline'. This adjusts capacity data accordingly.`),
		TimelinePeriod: new CoachmarkLocaleItem('Timeline Period', `Left-Click or Click and drag on months/weeks to select the period to show below. Click on a year to select its underlying period.`),
		ChartPanel: new CoachmarkLocaleItem('Chart Panel', `Select a screen size to expand/collapse the embedded 'Chart Panel'.`),
	}
	public AdminDashboard = {
		SettingsToggle: new CoachmarkLocaleItem('Settings Toggle', 'Toggle between simple and advanced settings.'),
		NavGroupings: new CoachmarkLocaleItem('Groupings',`Select a grouping to display all its children or a subgroup to display specific settings.`),
	}
	public DraftProjectCapacity = {
		WorkingWithDrafts: new CoachmarkLocaleItem("You are working with a draft!", "This draft is only available to you! When you're done, you can make it available for approval in the 'Work with drafts' dialog. Here you can also delete your draft.")
	}
}