
export class GridSettings {
	public static SetSpacingAmount = (amount: number) : void => {
		document.documentElement.style.setProperty("--spacingAmount", `${amount}`);
	};

	public static SetVersionedAnimationCellCount = (amount: number) : void => {
		document.documentElement.style.setProperty("--versionAnimationCells", `${amount}`);
	};

	public static SetVersionedAnimationCellRowCount = (amount: number) : void => {
		document.documentElement.style.setProperty("--versionAnimationCellRowCells", `${amount}`);
	};
}