import { ProjectListItem, ResourceListItem } from '../../../../../api/generated/data-contracts';

export type RecursiveTree = Map<string, RecursiveTree>;

export const buildRbsTree = (resources: (ResourceListItem | ProjectListItem)[]) => {
	const root: RecursiveTree = new Map();
	resources.forEach(res => {
		if (res.rbsAsString) {
			const rbs = res.rbsAsString;
			const parts = rbs.split('.');
			parts.reduce((branch, part, i) => {
				const isStart = !i;
				if (isStart) {
					if (root.has(part)) {
						// If the tree has this branch from the trunk, use it to build from in the next iteration
						return root.get(part);
					}
					// Add a branch to the trunk of the tree
					root.set(part, branch);
					// Use the newly sprouted branch in the next iteration
					return branch;
				}
				if (branch.has(part)) {
					// If the current branch has this branch as a sub branch, add branches from there on the next iteration
					return branch.get(part);
				}
				const subBranch = new Map();
				// Add another a sub branch from the current branch
				branch.set(part, subBranch);
				// Use the newly sprouted branch in the next iteration
				return subBranch;
			}, new Map() as RecursiveTree);
		}
	});
	return root;
};
