export default class Locale_AutoDistributionConfig {
	// dialog
	SettingsDialogTitle: string = "Default activity distribution settings";
	ProjectSettingsDialogTitle: string = "Project specific activity distribution settings";
	SettingsButtonText: string = "Edit";

	// distribution config
	DistributionTechnique: string = "Distribution technique";
	ResourceDistribution: string = "Resource distribution";
	AllowedEvenlyDistributedVariation: string = "Allowed variation for evenly distribution";

	DistributionTechniqueDescription: string = "How the activities will be distributed";
	AllowedEvenlyDistributedVariationDescription: string = "By default, using evenly distribution, will striclty make sure that it's perfectly evenly spread out across periods. If an evenly distributed request cannot be fulfilled, this setting will start another try using this varians, making the activities less evenly distributed";

	// resource distribution
	ResourceTechnique: string = "Resource technique";
	Prefer: string = "Prefer resources";
	Restrict: string = "Restrict to resources";
	ResourceOrderTypes: string = "Resource order";
	ResourceConstraints: string = "Resource constraints";
	MaxNumberOfResources: string = "Max number of resources to use";
	MaxHoursTotal: string = "Maximum hours to use in total";

	ResourceTechniqueDescription: string = "How activities should be spread on the selected resources";
	PreferDescription: string = "A filter for preferring some resources over others. This can be used in the sorting mechanisms and in the resource technique 'Spread Out Preferred First'.";
	RestrictDescription: string = "A filter for restricting which resources to use. Only resources that match this filter are used.";
	ResourceOrderTypesDescription: string = "How resources are ordered. Select multiple ordering methods, that will be run in the order selected.";
	ResourceConstraintsDescription: string = "Resource constraints";
	MaxNumberOfResourcesDescription: string = "Restrict the request to not use more resources than specified here.";

	// resource constraints
	Resource: string = "Resource";
	Priority: string = "Priority";
	MinimumFreeCapacity: string = "Minimum free capacity percentage";
	MinimumUtilization: string = "Minimum utilization percentage";
	MaximumUtilization: string = "Maximum utilization percentage";
	Exclude: string = "Exclude";

	ResourceDescription: string = "The resource";
	PriorityDescription: string = "The priority of the resource. Used when ordered resources by priority.";
	MinimumFreeCapacityDescription: string = "If a resource has less free capacity in a period than specified here, it will be discarded for that period.";
	MinimumUtilizationDescription: string = "If an allocation for a resource in a period is smaller than this, it will be removed an replaced on another resource, if possible";
	MaximumUtilizationDescription: string = "A resource cannot be allocated more than this in a period.";
	ExcludeDescription: string = "Exclude this resource from the distribution.";
	AdvancedSettings: string = "Advanced settings";
	SimpleSettings: string = "Showing basic settings";
}