import { FunctionComponent } from 'react';
import { TooltipHost } from '@fluentui/react';

export interface Area {
    Title: string;
    Percentage: number;
    Color: string;
    ShowPercentage: boolean;
}

interface IProps {
    Areas: Array<Area>;
    LeftOverColor?: string;
    LeftOverTitle?: string;
}

export const KpiBarMultiple: FunctionComponent<IProps> = props => {
    const GetAreas = () => {
        const areas = [...props.Areas];
        let sum: number = 0;
        props.Areas.forEach(a => (sum += a.Percentage));
        if (sum !== 100) areas.push({ Title: props.LeftOverTitle, Percentage: 100 - sum, Color: props.LeftOverColor, ShowPercentage: sum > 0 } as Area);
        return areas;
    };

    return (
        <div className="kpi-container">
            {GetAreas().map((_, idx) => {
                return (
                    <TooltipHost
                        key={`${_.Title}_${idx}_tip`}
                        hostClassName="kpi-tooltip"
                        content={_.ShowPercentage ? `${_.Title}: ${_.Percentage}%` : _.Title}
                        calloutProps={{ gapSpace: 0 }}
                        styles={{ root: { width: `${_.Percentage}%`, display: 'flex' } }}
                    >
                        <div id={`#test${idx}`} key={`${_.Title}_${idx}`} className="kpi-bar" style={{ width: `${100}%`, backgroundColor: `${_.Color}` }}></div>
                    </TooltipHost>
                );
            })}
        </div>
    );
};
