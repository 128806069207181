import { ReactElement, useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { IFieldRenderProps, IFormItem, useFormContext, useValidation, useWatch } from "spark-forms";
import { FluentValidationMessage } from "spark-forms-fluent8";
import { ApiCalls } from "../../../../api/api";
import { language } from "../../../../Services/LocalizationService";
import { JsonEx } from "../../../../Utilities/JsonEx";
import { IPickerOptions, IPickerSuggestionsOptions, GenericPicker, IPickerItem } from "../../../xxx-components-refactor/GenericPicker";
import { IAadGroup } from "../../tpComplexBuilder/components/AadGroupField";
import { IAadGroupFieldConfig } from "./IAadGroupFieldConfig";

export interface IFluentTextFieldProps<T extends IFormItem> extends IFieldRenderProps<T, IAadGroupFieldConfig<T>, string | string[] | undefined> {
    config?: IAadGroupFieldConfig<T>;
}

export const AadGroupField = <T extends IFormItem>({ value, fieldName, config, disabled, path }: IFluentTextFieldProps<T>): ReactElement | null => {
    const [localValue, setLocalValue] = useState<string | string[]>(value);

    const { onChange: onChangeForm } = useFormContext();
    const { validate, validationMessage } = useValidation(path);

    useWatch(path, () => setLocalValue(JsonEx.tryStringify(value)), [value]);

    const onChange = useCallback(
        (val: string | string[] | undefined) => {
			validate();
            setLocalValue(val);
            onChangeForm(fieldName, val);
        },
        [validate, onChangeForm, fieldName]
    );
	const {
        data: groups,
    } = useQuery(
        ['aadGroupField'],
        async () => {
			const response = await ApiCalls.queryAadGroups(null);
			return response.data as IAadGroup[];
        }
    );

	const buildOptions = useCallback((): IPickerOptions<IAadGroup> => (
		{
			entityGetter: async () => groups,
			itemParser: (item: IAadGroup) => (
				{
					id: item.id,
					displayName: item.displayName,
					description: item.description
				} as IPickerItem
			),
			recentItemsKey: 'aadgrouppicker-recentlyused',
			multiSelect: config.multiSelect,
			suggestions: {
				loadingText: language.Common.Loading,
				noResultsFound: language.Common.NoResults,
				showRemoveButtons: true,
				suggestionsHeaderText: language.ResourcePicker.RecentlyUsedResources,
				suggestionsContainerText: language.Common.Resources,
				suggestionsAvailableAlertText: language.ResourcePicker.RecentlyUsedResources,
			} as IPickerSuggestionsOptions
		} as IPickerOptions<IAadGroup>
	), [config.multiSelect, groups])
	
	const pickerOptions = useMemo(buildOptions, [buildOptions]);

	return (
		<>
			<GenericPicker
				styles={undefined}
				item={localValue}
				update={(values) => onChange(values)}
				pickerOptions={ pickerOptions }
				readOnly={disabled}
			/>
			<FluentValidationMessage message={validationMessage} />
		</>
	)
}
