import { useState, useMemo } from 'react';
import { createContext } from '../hooks/createContext';

type DragContext = {
    isDragging: boolean;
    setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
};
const [useCtx, Provider] = createContext<DragContext>();

// eslint-disable-next-line react-refresh/only-export-components
export const useIsDragging = useCtx;

export const IsDraggingContext = ({ children }: { children: React.ReactNode }) => {
    const [isDragging, setIsDragging] = useState(false);
    const context = useMemo(() => ({ isDragging, setIsDragging }), [isDragging]);
    return <Provider value={context}>{children}</Provider>;
};
