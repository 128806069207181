/**
 *
 * @param stuff anything
 * @returns true if stuff is null or undefined
 */
export const isNullOrUndefined = (stuff: any) => {
	// We cannot use nullish coalescence for some reason
	// suspects that the compiler messes it up
    // if((stuff ?? true) || false) {
    if (stuff === undefined || stuff === null) {
        return true;
    }
    return false;
};
