import { PropertyType } from "../Utilities/PropertyInfo/PropertyType";
import { PFPropertyBasicInfo } from "../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyRender } from "../Utilities/PropertyInfo/Render";
import { Project } from "./Main/Project";

export class ProjectPickerFields {
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:PickerFields.ItemTitle", 1)
	@PFPropertyRender(null, { EntityType: Project, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
	public Title: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:PickerFields.ItemSecondaryText", 2)
	@PFPropertyRender(null, { EntityType: Project, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
	public Secondary: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:PickerFields.ItemTertiaryText", 3)
	@PFPropertyRender(null, { EntityType: Project, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
	public Tertiary: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:PickerFields.ItemQuarternaryText", 4)
	@PFPropertyRender(null, { EntityType: Project, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
	public Quarternary: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:PickerFields.ItemIconText", 5)
	@PFPropertyRender(null, { EntityType: Project, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
	public IconOverride: string;
}