import { useState, useCallback } from 'react';
import { Dropdown } from '@fluentui/react';
import { useUISettings } from '../../../../context/network/http/QueryProvider/queries/UISettings';
import { useQuery } from 'react-query';
import { ApiCalls } from '../../../../api/api';
import { EntityType, QueryDto } from '../../../../api/generated/data-contracts';
import { DropdownOptionEx } from '../../../../Utilities/DropdownOptionEx';
import { IWorkProfile } from '../../../workprofile/IWorkProfile';
import { IFieldRenderProps, IFieldConfig, useFormContext } from 'spark-forms';

export const WorkProfileSelector: React.FC<any> = <T,>({
	value,
	disabled,
	fieldName
}: IFieldRenderProps<T, IFieldConfig<T>, string | undefined>) => {
	
	const { onChange } = useFormContext();
	const { data: uiSettings } = useUISettings();
	const [localValue, setLocalValue] = useState<string>(value ?? uiSettings.settings.workProfileId);

	const {
        data: profiles,
		isLoading
    } = useQuery(
        ['workprofilefield'],
        async () => {
			const dto = {
				entity: EntityType.WorkProfile,
				query: { expression: 'ResourceGuid == @0', params: [null] },
			} as QueryDto;
			const response = await ApiCalls.queryEntity(dto);
			return response.data as IWorkProfile[];
        }
    );

	const onLocalChange = useCallback(value => {
        setLocalValue(value);
        onChange(fieldName, value);
    }, [fieldName, onChange]);

	return 	(
		<div>
			<Dropdown 
				disabled={ disabled || isLoading }
				options={ DropdownOptionEx.toDropdownOptionsAddEmptyEntry(profiles, _ => _.id, _ => _.name, true, null, "Not manually selected") }
				selectedKey={localValue}
				onChange={ (_ev, option) => onLocalChange(option.key) }
			/>
		</div>
	)
}
