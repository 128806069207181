import React, { FunctionComponent, useEffect, useState } from 'react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { PeriodSpan } from '../../../Entities/PeriodSpan';
import { DataService } from '../../../Services/DataService';
import { DynamicQuery } from '../../../Entities/DynamicQueryBuilder/DynamicQuery';
import { Settings } from '../../../Entities/Main/Settings';
import { EntityType } from '../../../Entities/EntityTypes';
import { PFList } from '../PFList';
import { DateEx } from '../../../Utilities/DateEx';
import { ArrayEx } from '../../../Utilities/ArrayEx';
import { OverviewContext } from '../../../Utilities/Context/OverviewContext';
// import { PageBlocker } from '../../../Services/PageBlocker';
import { guid } from '../../../helpers/guid';
import { Confirm } from '../../../Utilities/Confirm';
import { language } from '../../../Services/LocalizationService';
import { useStore } from '../../../context/store';
import { refactorHelper } from '../../../helpers/refactorHelper';

interface IProps extends IDynamicEditorComponentProps {
    Item: PeriodSpan;
    Update: (freeze: PeriodSpan) => void;
    Key?: string;
}

const SettingsBackups: FunctionComponent<IProps> = props => {
    const [backups, setBackups] = useState([]);
	const setBlockDialog = useStore(store => store.setBlockDialog)

    useEffect(() => {
        const fetch = async () => {
            // load all settings backups
            const query = new DynamicQuery('SettingsType == @0', [99]);
            let backupSettings = await DataService.Query<Settings>(EntityType.Settings, query);
            backupSettings = ArrayEx.sortEmptyOrNullLast(backupSettings, 'Modified', false, true);
            setBackups(backupSettings);
        };
        fetch();
    }, []);

    const Restore = async (settings: Settings) => {
        if (settings == null) return;

        // confirm dialog
        Confirm.Instance.Show({
            show: true,
            title: language.Common.AreYouSure,
            subtext: language.SettingsBackup.ConfirmRestorationToBackupSettings,
            confirmAction: async () => {
                // set props to convert to root settings
                settings.SettingsType = 0;
                settings.id = guid.empty;
                settings.Modified = DateEx.toUtcString(new Date()) as any;
                // upsert
                await DataService.Upsert<Settings>(EntityType.Settings, [settings], true, { IsPureUpsert: true }).then(_ => {
                    // OverviewContext.Settings = settings;
					OverviewContext.Settings = refactorHelper.parseSettingsToLegacy(settings);
                    // PageBlocker.Instance.Fire(language.SettingsBackup.SettingsRestored, language.SettingsBackup.PleaseRefreshPage, true, false, true);
					setBlockDialog(null, {
						title: language.SettingsBackup.SettingsRestored, 
						subText: language.SettingsBackup.PleaseRefreshPage, 
						isBlocking: true, 
						dismissEnable: false, 
						refreshEnabled: true
					})
                });
            },
            //TODO DEFAULT DISMISS ACTION?
            dismissAction: () => {},
            okButtonText: language.Common.Ok,
            cancelButtonText: language.Common.Cancel,
            hideCancelButton: true,
        });
    };

    return (
        <>
            <PFList
                DialogTitle={''}
                ReadOnly={true}
                Columns={[
                    {
                        key: 'Created',
                        name: language.Common.Created,
                        fieldName: 'Created',
                        minWidth: 200,
                        maxWidth: 250,
                        valueType: 'text',
                        onRender: (item?: Settings, index?: number, column?: any) => DateEx.asUserLocalWithTime(item.Modified),
                    },
                    {
                        key: 'BackupInitiator',
                        name: language.Common.ModifiedBy,
                        fieldName: 'BackupInitiator',
                        minWidth: 200,
                        maxWidth: 250,
                        valueType: 'text',
                        onRender: (item?: Settings, index?: number, column?: any) => (item as any).BackupInitiator ?? 'Unkown',
                    },
                    {
                        key: 'ChangedProperties',
                        name: language.SettingsBackup.ChangedProperties,
                        fieldName: 'ChangedProperties',
                        minWidth: 500,
                        isResizable: true,
                        valueType: 'text',
                        onRender: (item?: Settings, index?: number, column?: any) =>
                            ArrayEx.joinToStringIgnore(item.ChangedProperties, ', ', null, 'Modified', 'ModifiedBy', 'NextLicenseLog'),
                    },
                ]}
                ItemIdentifier={(item: any) => item.Key}
                Items={backups}
                ItemType={Date} // just for show (ewi)
                SelectedItemDefaultAsNull
                FixedItems
                ExtraButtons={[{ title: language.SettingsBackup.RestoreSelectedBackup, iconName: 'DataManagementSettings', onClick: item => Restore(item) }]}
                MaxWidth="75%"
            />
        </>
    );
};

export default SettingsBackups;
