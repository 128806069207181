import { Origin } from "../Entities/Main/Origin";
import { OverviewContext } from "../Utilities/Context/OverviewContext";
import { guid } from "../helpers/guid";
import { PropertyInfoService } from "./PropertyInfoService";
import { Project } from "../Entities/Main/Project";
import { ArrayEx } from "../Utilities/ArrayEx";
import { Resource } from "../Entities/Main/Resource";
import { language } from "./LocalizationService";

export class OriginService {
	
	public static GetOrigin = (id: string) : Origin => {
		if (id == null) return null;
		return OverviewContext.Settings.Origins.find(_ => _.Id === id);
	}

	public static GetOrigins = (ids: Array<string>) : Array<Origin> => {
		if (ids == null) return [];
		return OverviewContext.Settings.Origins.filter(_ => ids.some(i => i === _.Id));
	}

	public static GetAppOrigin = () : Origin => {
		return OverviewContext.Settings.Origins.find(_ => _.Id === guid.empty);
	}

	public static GetDisallowedProjectProperties = (includeCustomPropertyPath?: boolean) : Array<string> => {
		return OriginService.GetDisallowedProperties<Project>(Project, includeCustomPropertyPath);
	}

	public static GetDisallowedResourceProperties = (includeCustomPropertyPath?: boolean) : Array<string> => {
		return OriginService.GetDisallowedProperties<Resource>(Resource, includeCustomPropertyPath);
	}

	public static GetDisallowedProperties = <T>(type: new() => T, includeCustomPropertyPath?: boolean) : Array<string> => {

		let entityType: { new(): any; };
		let customPropIds: Array<string>;
		let allowed: Array<string>;
		if (type.name === Resource.name) {
			entityType = Resource;
			customPropIds = OverviewContext.Settings.ResourceCustomProperties.map(_ => _.Id);
			allowed = OriginService.GetAppOrigin().ResourcePropertiesAllowed;
		} else if (type.name === Project.name) {
			entityType = Project;
			customPropIds = OverviewContext.Settings.ProjectCustomProperties.map(_ => _.Id);
			allowed = OriginService.GetAppOrigin().ProjectPropertiesAllowed;
		} else throw Error(language.Common.TypeNotSupported);

		// get all fields
		const allFields = PropertyInfoService.GetPFProperties(entityType, true).map(_ => _.PropertyName);

		if (!allowed) return allFields;

		if (includeCustomPropertyPath) {
			customPropIds = customPropIds.map(_ => `CustomProperties.${_}`)
		}

		// combine fields and custom props
		const allProps = allFields.concat(customPropIds);

		// extract custom properties
		let hasCustomProperties = false;
		if (!allowed) return allProps;
		// add whole custom property path as well to support both ways of using them
		allowed = allowed.map(_ => {
			if (_.indexOf("CustomProperties.") >= 0) {
				hasCustomProperties = true;
				if (!includeCustomPropertyPath)
				_ = _.replace("CustomProperties.", "");
			}
			return _;
		});

		// add custom props to allow list - all deny is handled by the individual custom property
		if (hasCustomProperties)
			allowed.push("CustomProperties");

		// get difference
		return ArrayEx.difference(allProps, allowed);
	}

	public static GetAllowedProperties = <T>(type: new() => T) : Array<string> => {

		let allowed: Array<string>;
		if (type.name === Resource.name) {
			allowed = OriginService.GetAppOrigin().ResourcePropertiesAllowed;
		} else if (type.name === Project.name) {
			allowed = OriginService.GetAppOrigin().ProjectPropertiesAllowed;
		} else throw Error(language.Common.TypeNotSupported);

		if (!allowed) return [];

		// extract custom properties
		let hasCustomProperties = false;
		allowed = allowed.map(_ => {
			if (_.indexOf("CustomProperties.") >= 0) {
				hasCustomProperties = true;
				_ = _.replace("CustomProperties.", "");
			}
			return _;
		});

		// add custom props to allow list - all deny is handled by the individual custom property
		if (hasCustomProperties)
			allowed.push("CustomProperties");

		// get difference
		return allowed;
	}
}