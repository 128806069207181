import { useMemo, useState } from 'react';
import { ResourceListItem, RootSettings } from '../../../../../../api/generated/data-contracts';
import { TFilterListButton, TFilterListRbs } from '../../components/FilterList/FilterList';
import { resolveResourceFieldName } from '../helpers';
import { functionThatReturnsTrue } from '../../../../../../helpers/functionPrimitives';
import { BreadcrumbGroupTitle, TFilter } from '../../types';
import { useEvent } from '../../../../../../hooks/useEvent';

type TuseResourceCategoriesProps = {
    data: ResourceListItem[];
    settings: RootSettings;
    filterLevel: number;
    panelGroupings: string[];
    filters: TFilter[];
    breadcrumbTitles: BreadcrumbGroupTitle[];
    updateFilterState: (grouping: string, filterFn: (resource: ResourceListItem) => boolean, breadcrumbText: string, filterDirection?: number) => void;
};

export const useCurrentResourceCategory = ({
    data,
    settings,
    filterLevel,
    panelGroupings,
    filters,
    breadcrumbTitles,
    updateFilterState,
}: TuseResourceCategoriesProps) => {
    const [currentRBSPath, setCurrentRBSPath] = useState(() => {
        const existingFilterIndex = filters.findIndex(filter => filter.type === 'rbs');
        if (existingFilterIndex !== -1) {
            return breadcrumbTitles[existingFilterIndex].title;
        }
        return '';
    });

    const resetCurrentRBSPath = useEvent(() => {
        setCurrentRBSPath(() => {
            const existingFilterIndex = filters.findIndex(filter => filter.type === 'rbs');
            if (existingFilterIndex !== -1) {
                return breadcrumbTitles[existingFilterIndex].title;
            }
            return '';
        });
    });

    const categories = useMemo(() => {
        return panelGroupings.map(grouping => {
            const customPropName = 'customproperties';
            const isCustom = grouping.toLowerCase().startsWith(customPropName);
            const allTitle = `All ${resolveResourceFieldName(grouping, settings) || grouping}`;
            if (grouping === 'rbs') {
                return [
                    {
                        key: `all-${grouping}`,
                        title: 'All',
                        type: 'button',
                        onClick: () => {
                            updateFilterState(grouping, functionThatReturnsTrue, allTitle);
                            setCurrentRBSPath('');
                        },
                    },
                    {
                        type: 'rbs',
                        title: 'rbs',
                        key: 'rbs',
                        currentPath: currentRBSPath,
                        onChange: (newRbsPath: string) => {
                            setCurrentRBSPath(newRbsPath);
                            updateFilterState(grouping, newRbsPath ? res => res.rbsAsString?.startsWith(newRbsPath) : functionThatReturnsTrue, newRbsPath, 0);
                        },
                    } as TFilterListRbs,
                ] as (TFilterListRbs | TFilterListButton)[];
            }
            return data.reduce(
                (acc, resource) => {
                    if (isCustom && resource.customPropertiesAsString) {
                        const customPropGuid = grouping.slice(customPropName.length + 1);
                        const title = resource.customPropertiesAsString[customPropGuid];
                        if (acc.some(existing => existing.title === title) || !title) {
                            return acc;
                        }
                        acc.push({
                            type: 'button',
                            title,
                            key: title,
                            onClick: () => {
                                updateFilterState(grouping, res => res.customPropertiesAsString?.[customPropGuid] === title, title);
                            },
                        });
                    } else {
                        const title = resource[grouping + 'AsString'];
                        if (!title || acc.some(existing => existing.title === title)) {
                            return acc;
                        }
                        acc.push({
                            type: 'button',
                            title,
                            key: title,
                            onClick: () => {
                                updateFilterState(grouping, res => res?.[grouping + 'AsString'] === title, title);
                            },
                        });
                    }

                    return acc;
                },
                [
                    {
                        key: `all-${grouping}`,
                        title: 'All',
                        type: 'button',
                        onClick: () => {
                            updateFilterState(grouping, functionThatReturnsTrue, allTitle);
                        },
                    },
                ] as TFilterListButton[],
            );
        });
    }, [panelGroupings, settings, data, currentRBSPath, updateFilterState]);

    const currentCategory = useMemo(() => {
        return categories[filterLevel - 1] || [];
    }, [categories, filterLevel]);

    const sortedCategories = useMemo(() => {
        return [...currentCategory].sort((a, b) => {
            if (a.title === 'All' || b.title === 'All') {
                return 1;
            }
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        });
    }, [currentCategory]);

    return { currentCategory: sortedCategories, resetCurrentRBSPath };
};
