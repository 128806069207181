import { ICustomizations } from "@fluentui/react/lib/Utilities";
import { createTheme } from '@fluentui/react/lib/Styling';
import { CommandBarStyles } from './styles/CommandBar.styles';
import { CommandBarButtonStyles } from './styles/CommandBarButton.styles';
import { IExtendedSemanticColors } from '../Theme.types';

import { palette, extendedSemanticColors } from './colors.default';

export const defaultTopbarScopedSettings = {
	CommandBar: {
		styles: CommandBarStyles
	},
	CommandBarButton: {
		styles: CommandBarButtonStyles
	}
};

export const topbarCustomizations: ICustomizations = {
	settings: {
		theme: createTheme({
			palette: palette,
			semanticColors: extendedSemanticColors as IExtendedSemanticColors,
			disableGlobalClassNames: true,
		})
	},
	scopedSettings: defaultTopbarScopedSettings
};


export default topbarCustomizations;


