import { FunctionComponent, useCallback } from 'react';
import { Callout } from '@fluentui/react';
import { IDs } from '../../Utilities/IDs';
import { Plugin } from '../../Services/PluginInvoker';
import { PluginIDs } from '../../Utilities/PluginIDs';
import { PFSpinner } from './Spinner';
import { ObjectEx } from '../../Utilities/ObjectEx';
import { DateEx } from '../../Utilities/DateEx';
import { useStore } from '../../context/store';
import { IEntityInfoCallout } from '../../context/contexts/entityCalloutContext';
import { useQuery } from 'react-query';
import { ENTITY_CALLOUT_INFO } from '../../context/network/http/QueryProvider/queryKeys';
import { fromSeconds } from '../../context/network/http/QueryProvider/helpers/queryHelper';

type IProps = {
    Dto: IEntityInfoCallout;
}

export const EntityInformationCallout: FunctionComponent<IProps> = ({ Dto, children }) => {
    const setEntityCalloutInfo = useStore(store => store.setEntityCalloutInfo);
	
    const { data: entityDetails, isLoading: loading } = useQuery(
        [ENTITY_CALLOUT_INFO, Dto],
        () => Plugin.Invoke<Record<string, string>>(PluginIDs.GetEntityDetails, { EntityId: Dto.Id, EntityType: Dto.EntityType }),
        {
            enabled: Boolean(Dto),
			staleTime: fromSeconds(10)
        },
    );

    const onCalloutDissmiss = useCallback(() => {
        setEntityCalloutInfo(null);
    }, [setEntityCalloutInfo]);

    if (!Dto) {
        return null;
    }
    return (
        <Callout
            className="tp-entityinformation-callout"
            role="alertdialog"
            gapSpace={0}
            target={Dto.Target}
            onDismiss={onCalloutDissmiss}
            setInitialFocus={true}
            isBeakVisible={true}
            styles={{ calloutMain: { padding: 20, minWidth: 300, minHeight: 200 } }}
        >
            {loading ? (
                <PFSpinner />
            ) : (
                <div
                    // onClick={() => {
                    //     setEntityCalloutInfo(null);
                    // }}
                >
                    <div className="ms-CalloutExample-header">
                        <p className="ms-CalloutExample-title">Details</p>
                    </div>
                    <div className="ms-CalloutExample-inner">
                        <div className="ms-CalloutExample-content">
                            <div className="ms-CalloutExample-subText">
                                <table cellPadding={5}>
                                    <tbody>
                                        {ObjectEx.keyValuesAsStrings(entityDetails, DateEx.asUserLocal).map(_ => (
                                            <tr key={IDs.makeId()}>
                                                <td>
                                                    <b>{_.key}</b>
                                                </td>
                                                <td>{_.value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="ms-CalloutExample-actions">{children}</div>
                    </div>
                </div>
            )}
        </Callout>
    );
};
