export default class Locale_ResourceRowMenu { 
	ForwardRequest = {
		text: 'ForwardRequest',
		title: 'Forwards part of a / whole request, to antoher resource',
	}
	MoveRequest = {
		text: 'Move request in time',
		title: 'Moves the request in time, allowing stretch/expand',
	}
	MatchRequest = {
		text: 'Match request',
		title: "Matches the request, respecting the resource's free capacity",
	}
	RemoveFutureAllocations = {
		text: 'Remove future allocations',
		title: "Removes allocations after the resources Latest Available if set, otherwise from today's date",
		dialogTitle: 'Remove future allocations',
		dialogSubText: "Removes allocations after the resources Latest Available if set, otherwise from today's date. It will though, respect the configuration for how far back in time you're allowed to edit allocations.",
		success: 'Successfully removed future allocations',
		error: 'Failed removing future allocations'
	}
}