import { IFieldConfig, IPredefinedArrayFieldConfig } from 'spark-forms';
import { ApiCalls } from '../api/api';
import { ContentConfigurationType, CustomProperty, CustomPropertyType, EntityType, QueryDto } from '../api/generated/data-contracts';
import { queryClient } from '../context/network/http/QueryProvider/QueryProvider';
import { LookupType } from '../Entities/ContentConfiguration/Lookup/LookupType';
import { ILookup } from '../forms/lookup/ILookup';
import { IProjectFieldConfig } from '../forms/_builders/new/Fields/IProjectFieldConfig';
import { IResourceFieldConfig } from '../forms/_builders/new/Fields/IResourceFieldConfig';
import { ITpFieldTypes } from '../forms/_builders/new/tpFieldTypes';
import { ITreeFieldConfig } from '../forms/_builders/tpComplexBuilder/components/config/ITreeFieldConfig';
import { ITreeNode } from '../forms/_builders/tpComplexBuilder/components/tree/interfaces/ITreeNode';
import { IRbsStructure } from '../forms/_interfaces/IRbsStructure';
import { changeCase } from '../helpers/changeKeysCase';
import { DropdownOptionEx } from '../Utilities/DropdownOptionEx';

export const customPropertyParser = async (customProperty: CustomProperty) => {
    const getLookups = async () => {
        const data = await queryClient.fetchQuery(
            'lookupsselector',
            async () => {
                const dto = {
                    entity: EntityType.ContentConfiguration,
                    query: { expression: 'ContentConfigurationType == @0', params: [ContentConfigurationType.Lookup] },
                } as QueryDto;
                const response = await ApiCalls.queryEntity(dto);
                return changeCase.toCamel(response.data as ILookup[]); // why the fuck do I need to do this (ewi)
            },
            { staleTime: 30000 },
        );
        return data;
    };

    const getRbsStructures = async () => {
        const data = await queryClient.fetchQuery(
            'lookupsrbs',
            async () => {
                const dto = {
                    entity: EntityType.ContentConfiguration,
                    query: { expression: 'ContentConfigurationType == @0', params: [ContentConfigurationType.Rbs] },
                } as QueryDto;
                const response = await ApiCalls.queryEntity(dto);
                return response.data as IRbsStructure[];
            },
            { staleTime: 30000 },
        );
        return data;
    };

    const lookupTypeMapping = async (lookup: ILookup, multiSelect?: boolean): Promise<{ key: keyof ITpFieldTypes; config: IFieldConfig<any> }> => {
        switch (lookup?.lookupType) {
            case LookupType.Number:
                return {
                    key: 'predefinedArray',
                    config: {
                        multiSelect: multiSelect,
                        predefinedOptions: {
                            options: DropdownOptionEx.toDropdownOptionsAddEmptyEntry(
                                lookup.options,
                                _ => _.id,
                                _ => _.value?.toString(),
                            ),
                        },
                    } as IPredefinedArrayFieldConfig<any>,
                };
            case LookupType.String:
                return {
                    key: 'predefinedArrayFilter',
                    config: {
                        multiSelect: multiSelect,
                        predefinedOptions: {
                            options: DropdownOptionEx.toDropdownOptionsAddEmptyEntry(
                                lookup.options,
                                _ => _.id,
                                _ => _.value?.toString(),
                            ),
                        },
                    } as IPredefinedArrayFieldConfig<any>,
                };
            case LookupType.Project:
                return {
                    key: 'project',
                    config: {
                        multiSelect: multiSelect,
                        nameField: 'name',
                        descriptionField: 'owner',
                        excludeInactive: !lookup.options[0].value.includeInactive,
                    } as IProjectFieldConfig<any>,
                };
            case LookupType.Resource:
                return {
                    key: 'resource',
                    config: {
                        multiSelect: multiSelect,
                        nameField: 'name',
                        descriptionField: 'rbs',
                        excludeInactive: !lookup.options[0].value.includeInactive,
                    } as IResourceFieldConfig<any>,
                };
            case LookupType.Rbs: {
                const rbsId = lookup.options[0].value.rbsId;
                const getter = async () => (await getRbsStructures()).find(_ => _.id === rbsId)?.rbs;
                return { key: 'tree', config: { multiSelect: multiSelect, type: 'select', treeGetter: getter } as ITreeFieldConfig<any> };
            }
            case LookupType.Tree: {
                const tree = lookup.options[0].value.tree as ITreeNode;
                return {
                    key: 'tree',
                    config: {
                        multiSelect: multiSelect,
                        type: 'select',
                        defaultExpandedDepth: lookup.options[0].value.defaultExpandedDepth,
                        treeGetter: () => tree,
                    } as ITreeFieldConfig<any>,
                };
            }
            default:
                return null;
        }
    };

    const typeMapping = async (customProp: CustomProperty): Promise<{ key: keyof ITpFieldTypes; config?: IFieldConfig<any> }> => {
        switch (customProp.type) {
            case CustomPropertyType.Number:
                return { key: 'number', config: { type: 'number' } };
            case CustomPropertyType.String:
                return { key: 'string' };
            case CustomPropertyType.StringMultiline:
                return { key: 'string', config: { multiline: true, autoAdjustHeight: true, resizable: true } };
            case CustomPropertyType.Boolean:
                return { key: 'boolean' };
            case CustomPropertyType.Date:
                return { key: 'date' };
            case CustomPropertyType.Choice: {
                const lookup = (await getLookups())?.find(_ => _.id === customProp.lookup);
                return await lookupTypeMapping(lookup, false);
            }
            case CustomPropertyType.ChoiceMultiselect: {
                const lookup2 = (await getLookups())?.find(_ => _.id === customProp.lookup);
                return await lookupTypeMapping(lookup2, true);
            }
            case CustomPropertyType.Url:
                return { key: 'hyperlink' };
            default:
                return null;
        }
    };

    return await typeMapping(customProperty);
};
