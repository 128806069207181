import {
	DateResolution, GridCell,
	GridColumnRow, GridTotalColumnRow
} from '../../../../api/generated/data-contracts';
import { ITimeline } from '../../../../context/contexts/timelineContext';
import { DateEx } from '../../../../Utilities/DateEx';

const makeCell = (gridCell?: GridCell): GridCell => {
	return {
		...gridCell,
		disabled: false,
		id: crypto.randomUUID(),
		value: 0,
	};
};

const getAdder = (resolution: DateResolution) => {
	switch (resolution) {
		case DateResolution.Day:
			return DateEx.addDay;
		case DateResolution.Month:
			return DateEx.addMonth;
		case DateResolution.Quarter:
			return DateEx.addQuarter;
		case DateResolution.Week:
			return DateEx.addWeek;
		case DateResolution.Year:
			return DateEx.addYear;
		default: {
			throw new Error(`Unknown timeline resolution: ${resolution}}`);
		}
	}
};

const getLastDayOfPeriod = (resolution: DateResolution) => {
	switch (resolution) {
		case DateResolution.Day:
			// noop
			return (date: Date) => date;
		case DateResolution.Month:
			return DateEx.lastDayOfMonth;
		case DateResolution.Quarter:
			return DateEx.lastDayOfQuarter;
		case DateResolution.Week:
			return DateEx.lastDayOfWeek;
		case DateResolution.Year:
			return DateEx.lastDayOfYear;
		default: {
			throw new Error(`Unknown timeline resolution: ${resolution}}`);
		}
	}
};

export function makeColumnRows({ start, end, resolution }: ITimeline, useISO8601: boolean): GridColumnRow[] {
	const periods: GridColumnRow[] = [];
	let currentStartDate = start;

	while (currentStartDate <= end) {
		let currentEndDate: Date = getLastDayOfPeriod(resolution)(currentStartDate, useISO8601);

		// Make sure the period does not exceed the overall endDate
		if (currentEndDate > end) {
			currentEndDate = end;
		}

		periods.push({
			cells: [null, makeCell()],
			id: crypto.randomUUID(),
			start: new Date(currentStartDate),
			end: new Date(currentEndDate),
		});

		// Prepare the next period's start date
		currentStartDate = getAdder(resolution)(currentStartDate);
	}

	return periods;
}

export function makeContractColumnRows({ start, end, resolution }: ITimeline, useISO8601: boolean): GridTotalColumnRow[] {
	const periods: GridColumnRow[] = [];
	let currentStartDate = start;

	while (currentStartDate <= end) {
		let currentEndDate: Date = getLastDayOfPeriod(resolution)(currentStartDate, useISO8601);

		// Make sure the period does not exceed the overall endDate
		if (currentEndDate > end) {
			currentEndDate = end;
		}

		periods.push({
			cells: [makeCell(), makeCell()],
			id: crypto.randomUUID(),
			start: new Date(currentStartDate),
			end: new Date(currentEndDate),
		});

		// Prepare the next period's start date
		currentStartDate = getAdder(resolution)(currentStartDate);
	}

	return periods;
}
