import { useEffect, useState } from 'react';

// Hook
export function useLocalStorage<T>(key: string, initialValue: T | (() => T)) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        const value = initialValue instanceof Function ? initialValue() : initialValue;
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            if (item !== null) {
                return JSON.parse(item) as T;
            }
            window.localStorage.setItem(key, JSON.stringify(value));
            return value;
        } catch (error) {
			// report localStorage error and use initial value
            console.error(error);
            return value;
        }
    });
	
    useEffect(() => {
		window.localStorage.setItem(key, JSON.stringify(storedValue));
    }, [key, storedValue]);

    return [storedValue, setStoredValue] as const;
}
