import { Localization } from '../Locales/Localization';
import { Plugin } from './PluginInvoker';
import { PluginIDs } from '../Utilities/PluginIDs';
import { ObjectEx } from '../Utilities/ObjectEx';
import { IPFPropertyLocalizationService } from './IPFPropertyLocalizationService';

export class LocalizationService implements IPFPropertyLocalizationService {
    private static _instance: LocalizationService;

    private constructor() {}

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public static GetAvailableLocales = async (): Promise<Array<string>> => {
        return Plugin.Invoke<Array<string>>(PluginIDs.GetAvailableLocales);
    };

    public Initialize = async (usedLocale: string): Promise<void> => {
        let currentLocale: Localization;
        // 'en'us localization is 'hardcoded' into team planner (no need to fetch language file)
        if (usedLocale === 'en-us') {
            currentLocale = null;
        } else {
            const locale = await Plugin.Invoke<string>(PluginIDs.GetLanguageFile);
            currentLocale = locale != null && locale !== '' ? (JSON.parse(locale) as Localization) : null;
        }
        // merge with default - to highlight missing key/value pairs
        const def = new Localization();
        const merged = currentLocale != null ? ObjectEx.mergeDeep(def, currentLocale) : def;
        language = merged;
    };

    public GetByKey = (keyPath: string): string => {
        if (keyPath == null || !keyPath.startsWith('_localization:')) return keyPath;
        const propertyPath = keyPath.replace('_localization:', '');
        const value = ObjectEx.getNestedPropertyValueByString(language, propertyPath);
        if (value) return value;
        else return keyPath;
    };

    public GetAPIMessageByKey = (keyPath: string): string => {
        if (keyPath == null || !keyPath.startsWith('_localization:')) return keyPath;
        let propertyPath = keyPath.replace('_localization:', '');
        // replace seperators
        propertyPath = propertyPath.replace(/[_:]/g, '.');
        const value = ObjectEx.getNestedPropertyValueByString(language.APIMessages, propertyPath);
        if (value) return value;
        else return keyPath;
    };
}

// instantiate here, due to fuckups when being used prior to init (ewi)
export let language: Localization = new Localization();
