import { GridRow } from "../../../../api/generated/data-contracts";
import { createContext } from "../../../../hooks/createContext";

export type RowContext = {
    row: GridRow;
    // parentRowContext?: RowContext;
    // getRootRow: () => RowWithRelations;
    // getRowFrom: (depth: number) => RowWithRelations;
    // toggleExpanded: (toggle?: boolean) => void;
	// getFlatRowRelations: () => RowWithRelations[];
	// buildRowPath: () => RowPath;
	toggleExpanded: (toggle?: boolean) => void;
	getRowAncestorTreeToRoot: (rowId: string) => GridRow[];
	nestingLevel: number;
	// ancestorTree: RowWithRelations[];
	subRows: GridRow[];
	rowRelations: GridRow[];
	canExpand: boolean;
	visible: boolean;
	rowIndex: number
};

const [useCtx, Provider] = createContext<RowContext>();

export const useGridRowContext = useCtx;

export const GridRowContext = Provider;