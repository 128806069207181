import { useCallback, useMemo, useRef } from 'react';
import { Project } from '../../../../../api/generated/data-contracts';
import { useUISettings } from '../../../../../context/network/http/QueryProvider/queries/UISettings';
import { projectSchema } from '../../../../../forms/project/projectSchema';
import { useOverviewContext } from '../../../../../_components/OverviewCreator/context/OverviewCreatorContext';
import { BulkEditorDialog } from '../../../_components/BulkEditorDialog';

export const ProjectBulkEditor = ({
	show, setShow, onSubmit,
}: {
	show: boolean;
	setShow: (state: boolean) => void;
	originProperties: string[];
	onSubmit: (items: Project[]) => void | Promise<void>;
}) => {
	const { selectedItems } = useOverviewContext<Project>();
	const { data: uiSetting } = useUISettings();
	const dismiss = useCallback(() => setShow(false), [setShow]);
	const template = useMemo(() => ({ projectType: selectedItems?.[0]?.projectType, customProperties: {} } as Project), [selectedItems]);
	const onChange = useCallback(
		(items: Project[]) => {
			setShow(false);
			onSubmit(items);
		},
		[onSubmit, setShow]
	);
	const hiddenProperties = useRef([]).current;
	return (
		<BulkEditorDialog
			items={[...selectedItems]}
			show={show}
			dismiss={dismiss}
			entityType="project"
			template={template}
			schema={projectSchema}
			customProperties={uiSetting.settings.projectCustomProperties}
			hiddenProperties={hiddenProperties}
			onSubmit={onChange} />
	);
};
