import { GridCell, GridTotalColumnRow } from '../../../../../../api/generated/data-contracts';
import { useGridContext } from '../../../../_grid/contexts/gridContext';
import { useFixedCellValue } from '../../../../_grid/hooks/useFixedCellValue';

type SubTotalCellProps = {
    cell: GridCell;
    isRowExpanded: boolean;
    totalColumnRow: GridTotalColumnRow;
    totalCapacity?: number;
};

export function SubTotalCell({ cell }: SubTotalCellProps) {
    const { useGridCellValue } = useGridContext();
    const stateValue = useGridCellValue(cell);
    const value = useFixedCellValue(stateValue);
    return (
        <div
            style={{
                // width: '100%',
                width: 80,
                // height: '100%',
                display: 'flex',
                justifyContent: 'end',
                // paddingRight: 12,
                paddingRight: 8,
            }}
        >
            <span>{value}</span>
        </div>
    );
}
