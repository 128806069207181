import { JsonEx } from "../Utilities/JsonEx";
import { Url } from "../Utilities/Url";
// import { NotificationService } from "./NotificationService";
import { TPError } from "../Entities/TPError";
// import { PageBlocker } from "./PageBlocker";
import { language, LocalizationService } from "./LocalizationService";
import { apiCache } from "../helpers/apiCache";
import { getAuthHeaders } from "../api/authConfig";
import { useStore } from "../context/store";

export class Plugin {
    public static Invoke = async <TResult>(pluginId: string, body?: any, errorText?: string, disableRethrow?: boolean, fallbackValue?: any): Promise<TResult> => {		
		try {
			const url = `${Url.getUrl()}/api/plugin/teamplanner/${pluginId}`;
			const authHeaders = await getAuthHeaders();
			authHeaders["Content-Type"] = "application/json;charset=UTF-8";
			const response = await Plugin.Fetch(url, "POST", authHeaders, body != null ? JsonEx.stringify(body) : null);
			const responseText = await response.text();
			const error = Plugin.Error(responseText, pluginId);
			if (error) throw error;
			const obj = JsonEx.parse<TResult>(responseText) as TResult;
			return obj;
		} catch (err) {
			Plugin.HandleError(err, errorText, disableRethrow, fallbackValue);
		}
    }

	public static CancelableInvokation = <TResult>(pluginId: string, body?: any, errorText?: string, disableRethrow?: boolean, fallbackValue?: any) => {
		const url = `${Url.getUrl()}/api/plugin/teamplanner/${pluginId}`;
		const controller = new AbortController();
		const cancel = () => {
			controller.abort();
		}

		const promise = Plugin.CancelableFetch(url, "POST", {} as any, !body ? null : JsonEx.stringify(body), controller.signal)
			.then(res => {
				return res.text();
			})
			.then(text => {
				const error = Plugin.Error(text, pluginId);
				if (error) {
					throw error;
				}
				return JsonEx.parse<TResult>(text);
			})
			.catch(err => {
				Plugin.HandleError(err, errorText, disableRethrow, fallbackValue);
			})
			
		return [promise, cancel, controller] as [Promise<TResult>, () => void, AbortController];
	}

    public static InvokeNoResponse = async (pluginId: string, body?: any, errorText?: string, disableRethrow?: boolean): Promise<void> => {
		try {
    		const url = `${Url.getUrl()}/api/plugin/teamplanner/${pluginId}`;
			const authHeaders = await getAuthHeaders();
			authHeaders["Content-Type"] = "application/json;charset=UTF-8";
			const response = await Plugin.Fetch(url, "POST", authHeaders, body != null ? JsonEx.stringify(body) : null);
			const responseText = await response.text();
			const error = Plugin.Error(responseText, pluginId);
			if (error) throw error;
		} catch (err) {
			Plugin.HandleError(err, errorText, disableRethrow);
		}
	}

	// TODO Re-enable notification service
	private static HandleError = (err: any, errorText?: string, disableRethrow?: boolean, fallbackValue?: any): void => {
		const isTPException = err instanceof TPError;
		const error = isTPException ? err : TPError.New("ClientError", err.message, err.stack);
		console.error(error.AsString());
		// handle default values yourself
		if (disableRethrow) {
			if (errorText)
				useStore.getState().addErrorNotification(errorText)
				// 	NotificationService.Instance.Error(errorText, error.AsString());
			if (isTPException)
				useStore.getState().addErrorNotification(LocalizationService.Instance.GetAPIMessageByKey(error.Message))
				// NotificationService.Instance.Error(LocalizationService.Instance.GetAPIMessageByKey(error.Message), error.AsString());
			return fallbackValue;
		}
		// notify TP error message
		// if (isTPException)
		// 	NotificationService.Instance.Error(LocalizationService.Instance.GetAPIMessageByKey(error.Message), error.AsString());
		
		// throw error to be catched by caller
		throw error;
	}
	
	private static Error = (response: string, pluginId: string) : TPError => {
		let resp = null;
		try {
			resp = JsonEx.parse(response) as any;
		} catch { }
		if (!resp || resp.TypeName !== "TPException" || resp.Status === "Initializing") return null;
		const error = JsonEx.parseAdvanced<TPError>(response, TPError);
		return error;
	}

	public static Fetch = async (url: string, method: string, headers: Record<string, string>, body: string, signal?: AbortSignal):  Promise<Response> => {
        return fetch(url, {
			redirect: "manual", // handle all redirects - ie. to login.microsoft.com
			body,
			headers,
			method,
			signal
		}).then((res) => {
			if (res.headers.get("tp-host-ready") === "False") {
				console.log("cache NOT ready");
				apiCache.setReady(false);
				return;
			} else apiCache.setReady(true);

			if (res.type === "opaqueredirect") {
				// we assume that when a redirect happen from a call to a plugin, it is when the user is not authenticated any more - ewi
				// PageBlocker.Instance.Fire(language.PluginInvoker.AuthenticationExpired, language.PluginInvoker.AuthenticationExpiredDescription, true, false, true);
				useStore.getState().setBlockDialog(null, {
                    title: language.PluginInvoker.AuthenticationExpired,
                    subText: language.PluginInvoker.AuthenticationExpiredDescription,
					dismissEnable: false,
					refreshEnabled: true
                });
				// window.location.href = res.url; // force redirect despite of cors
			} else return res;
		});
	}

	public static CancelableFetch = async (url: string, method: string, headers: Record<string, string>, body: string, signal?: AbortSignal):  Promise<Response> => {
        return cancelableFetch(url, signal, {
			redirect: "manual", // handle all redirects - ie. to login.microsoft.com
			body,
			headers,
			method,
		}).then((res) => {
			if (res.type === "opaqueredirect") {
				// we assume that when a redirect happen from a call to a plugin, it is when the user is not authenticated any more - ewi
				// PageBlocker.Instance.Fire(language.PluginInvoker.AuthenticationExpired, language.PluginInvoker.AuthenticationExpiredDescription, true, false, true);
				useStore.getState().setBlockDialog(null, {
                    title: language.PluginInvoker.AuthenticationExpired,
                    subText: language.PluginInvoker.AuthenticationExpiredDescription,
					dismissEnable: false,
					refreshEnabled: true
                });
				// window.location.href = res.url; // force redirect despite of cors
			} else return res;
		});
	}
}

/**
 * This implementation will stop the .then() chain and swallow user cancel error on cancellation
 */
const cancelableFetch = (url: string, signal: AbortSignal, config: RequestInit = {}): Promise<Response> => {
	let resolve: (res: Response) => void;
	let reject: (error: any) => void;
	
	fetch(url, { ...config, signal })
		.then(res => {
			if (!signal.aborted) {
				resolve(res);
			}
		})
		.catch(err => {
			if (!signal.aborted) {
				reject(err);
			}
		})

	return new Promise((res, rej) => {
		resolve = res;
		reject = rej;
	})
}