import { ProjectListItem, ResourceListItem } from '../../../../../api/generated/data-contracts';

// export const generateAsStringGetters = (properties: string[], settings: RootSettings, type: 'project' | 'resource') => {
export const generateAsStringGetters = (properties: string[]) => {
	const customPrefix = 'customproperties';
    return properties.map(propertyName => {
        if (propertyName.toLowerCase().startsWith(customPrefix)) {
			const guid = propertyName.slice(customPrefix.length + 1);
            return (item: ResourceListItem | ProjectListItem): string => {
                const val =
                    item?.customPropertiesAsString?.[
                        // type === 'project' ? resolveProjectFieldName(propertyName, settings) : resolveResourceFieldName(propertyName, settings)
						guid
                    ];
                return val ? String(val) : '';
            };
        }
        return (item: ResourceListItem | ProjectListItem): string => {
            const val = item?.[`${propertyName}AsString`] || item?.[`${propertyName}`];
            return val ? String(val) : '';
        };
    });
};
