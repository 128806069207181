import React, { useMemo } from 'react';
import { TextField } from '@fluentui/react';
import { IFieldRenderProps, IFieldConfig } from 'spark-forms';
import { getOrigins } from '../helpers/origins/getOrigins';

export const OriginIdRender: React.FC<any> = <T,>({
	value,
	disabled,
}: IFieldRenderProps<T, IFieldConfig<T>, T[] | undefined>) => {
	
	const originMaps = useMemo(() => {
		const valueSafe = value ?? {};
		const origins = getOrigins(Object.keys(valueSafe));
		const resourceArray = Object.keys(valueSafe ?? {}).map((key)=>[key, valueSafe[key]])
		const originMap = [];
		resourceArray.forEach((resource) => {
			origins.forEach((origin) => {
				if(origin.id === resource[0]) {
					const originArrEntry = {};
					originArrEntry['displayName'] = origin.displayName;
					originArrEntry['value'] = resource[1];
					originMap.push(originArrEntry)
				}
			})
		})
		if (!originMap.length) {
			return [{ displayName: 'empty', value: '' }]
		}
		return originMap;
	}, [value])

	return  (
		<>
			{ originMaps?.map((origin, index) => 
				<TextField
					readOnly
					key={ `originids-${index}` }
					value={`${origin.displayName} | ${origin.value}`}
					disabled={ disabled }
					styles={{root:{paddingBottom:2}}}
				/>
			) 
			}
		</>
	)
}
