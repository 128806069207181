import { IComboBoxOption } from "@fluentui/react";

export const _CountryOptions = [
	{key: "AF", text: "Afghanistan"},
	{key: "AL", text: "Albania"},
	{key: "DZ", text: "Algeria"},
	{key: "AS", text: "American Samoa"},
	{key: "AD", text: "Andorra"},
	{key: "AO", text: "Angola"},
	{key: "AI", text: "Anguilla"},
	{key: "AQ", text: "Antarctica"},
	{key: "AG", text: "Antigua and Barbuda"},
	{key: "AR", text: "Argentina"},
	{key: "AM", text: "Armenia"},
	{key: "AW", text: "Aruba"},
	{key: "AU", text: "Australia"},
	{key: "AT", text: "Austria"},
	{key: "AZ", text: "Azerbaijan"},
	{key: "BS", text: "Bahamas"},
	{key: "BH", text: "Bahrain"},
	{key: "BD", text: "Bangladesh"},
	{key: "BB", text: "Barbados"},
	{key: "BY", text: "Belarus"},
	{key: "BE", text: "Belgium"},
	{key: "BZ", text: "Belize"},
	{key: "BJ", text: "Benin"},
	{key: "BM", text: "Bermuda"},
	{key: "BT", text: "Bhutan"},
	{key: "BO", text: "Bolivia"},
	{key: "BQ", text: "Bonaire, Sint Eustatius and Saba"},
	{key: "BA", text: "Bosnia and Herzegovina"},
	{key: "BW", text: "Botswana"},
	{key: "BV", text: "Bouvet Island"},
	{key: "BR", text: "Brazil"},
	{key: "IO", text: "British Indian Ocean Territory"},
	{key: "BN", text: "Brunei Darussalam"},
	{key: "BG", text: "Bulgaria"},
	{key: "BF", text: "Burkina Faso"},
	{key: "BI", text: "Burundi"},
	{key: "CV", text: "Cabo Verde"},
	{key: "KH", text: "Cambodia"},
	{key: "CM", text: "Cameroon"},
	{key: "CA", text: "Canada"},
	{key: "KY", text: "Cayman Islands"},
	{key: "CF", text: "Central African Republic"},
	{key: "TD", text: "Chad"},
	{key: "CL", text: "Chile"},
	{key: "CN", text: "China"},
	{key: "CX", text: "Christmas Island"},
	{key: "CC", text: "Cocos (Keeling) Islands"},
	{key: "CO", text: "Colombia"},
	{key: "KM", text: "Comoros"},
	{key: "CG", text: "Congo"},
	{key: "CD", text: "Congo, Democratic Republic"},
	{key: "CK", text: "Cook Islands"},
	{key: "CR", text: "Costa Rica"},
	{key: "HR", text: "Croatia"},
	{key: "CU", text: "Cuba"},
	{key: "CW", text: "Curaçao"},
	{key: "CY", text: "Cyprus"},
	{key: "CZ", text: "Czech Republic"},
	{key: "CI", text: "Côte d'Ivoire"},
	{key: "DK", text: "Denmark"},
	{key: "DJ", text: "Djibouti"},
	{key: "DM", text: "Dominica"},
	{key: "DO", text: "Dominican Republic"},
	{key: "EC", text: "Ecuador"},
	{key: "EG", text: "Egypt"},
	{key: "SV", text: "El Salvador"},
	{key: "GQ", text: "Equatorial Guinea"},
	{key: "ER", text: "Eritrea"},
	{key: "EE", text: "Estonia"},
	{key: "SZ", text: "Eswatini"},
	{key: "ET", text: "Ethiopia"},
	{key: "FK", text: "Falkland Islands (Malvinas)"},
	{key: "FO", text: "Faroe Islands"},
	{key: "FJ", text: "Fiji"},
	{key: "FI", text: "Finland"},
	{key: "FR", text: "France"},
	{key: "GF", text: "French Guiana"},
	{key: "PF", text: "French Polynesia"},
	{key: "TF", text: "French Southern Territories"},
	{key: "GA", text: "Gabon"},
	{key: "GM", text: "Gambia"},
	{key: "GE", text: "Georgia"},
	{key: "DE", text: "Germany"},
	{key: "GH", text: "Ghana"},
	{key: "GI", text: "Gibraltar"},
	{key: "GR", text: "Greece"},
	{key: "GL", text: "Greenland"},
	{key: "GD", text: "Grenada"},
	{key: "GP", text: "Guadeloupe"},
	{key: "GU", text: "Guam"},
	{key: "GT", text: "Guatemala"},
	{key: "GG", text: "Guernsey"},
	{key: "GN", text: "Guinea"},
	{key: "GW", text: "Guinea-Bissau"},
	{key: "GY", text: "Guyana"},
	{key: "HT", text: "Haiti"},
	{key: "HM", text: "Heard Island and McDonald Islands"},
	{key: "VA", text: "Holy See (Vatican City State)"},
	{key: "HN", text: "Honduras"},
	{key: "HK", text: "Hong Kong"},
	{key: "HU", text: "Hungary"},
	{key: "IS", text: "Iceland"},
	{key: "IN", text: "India"},
	{key: "ID", text: "Indonesia"},
	{key: "IR", text: "Iran, Islamic Republic of"},
	{key: "IQ", text: "Iraq"},
	{key: "IE", text: "Ireland"},
	{key: "IM", text: "Isle of Man"},
	{key: "IL", text: "Israel"},
	{key: "IT", text: "Italy"},
	{key: "JM", text: "Jamaica"},
	{key: "JP", text: "Japan"},
	{key: "JE", text: "Jersey"},
	{key: "JO", text: "Jordan"},
	{key: "KZ", text: "Kazakhstan"},
	{key: "KE", text: "Kenya"},
	{key: "KI", text: "Kiribati"},
	{key: "KP", text: "Korea (Democratic People's Republic of)"},
	{key: "KR", text: "Korea, Republic of"},
	{key: "KW", text: "Kuwait"},
	{key: "KG", text: "Kyrgyzstan"},
	{key: "LA", text: "Lao People's Democratic Republic"},
	{key: "LV", text: "Latvia"},
	{key: "LB", text: "Lebanon"},
	{key: "LS", text: "Lesotho"},
	{key: "LR", text: "Liberia"},
	{key: "LY", text: "Libya"},
	{key: "LI", text: "Liechtenstein"},
	{key: "LT", text: "Lithuania"},
	{key: "LU", text: "Luxembourg"},
	{key: "MO", text: "Macao"},
	{key: "MG", text: "Madagascar"},
	{key: "MW", text: "Malawi"},
	{key: "MY", text: "Malaysia"},
	{key: "MV", text: "Maldives"},
	{key: "ML", text: "Mali"},
	{key: "MT", text: "Malta"},
	{key: "MH", text: "Marshall Islands"},
	{key: "MQ", text: "Martinique"},
	{key: "MR", text: "Mauritania"},
	{key: "MU", text: "Mauritius"},
	{key: "YT", text: "Mayotte"},
	{key: "MX", text: "Mexico"},
	{key: "FM", text: "Micronesia, Federated States of"},
	{key: "MD", text: "Moldova, Republic of"},
	{key: "MC", text: "Monaco"},
	{key: "MN", text: "Mongolia"},
	{key: "ME", text: "Montenegro"},
	{key: "MS", text: "Montserrat"},
	{key: "MA", text: "Morocco"},
	{key: "MZ", text: "Mozambique"},
	{key: "MM", text: "Myanmar"},
	{key: "NA", text: "Namibia"},
	{key: "NR", text: "Nauru"},
	{key: "NP", text: "Nepal"},
	{key: "NL", text: "Netherlands"},
	{key: "NC", text: "New Caledonia"},
	{key: "NZ", text: "New Zealand"},
	{key: "NI", text: "Nicaragua"},
	{key: "NE", text: "Niger"},
	{key: "NG", text: "Nigeria"},
	{key: "NU", text: "Niue"},
	{key: "NF", text: "Norfolk Island"},
	{key: "MK", text: "North Macedonia"},
	{key: "MP", text: "Northern Mariana Islands"},
	{key: "NO", text: "Norway"},
	{key: "OM", text: "Oman"},
	{key: "PK", text: "Pakistan"},
	{key: "PW", text: "Palau"},
	{key: "PS", text: "Palestine, State of"},
	{key: "PA", text: "Panama"},
	{key: "PG", text: "Papua New Guinea"},
	{key: "PY", text: "Paraguay"},
	{key: "PE", text: "Peru"},
	{key: "PH", text: "Philippines"},
	{key: "PN", text: "Pitcairn"},
	{key: "PL", text: "Poland"},
	{key: "PT", text: "Portugal"},
	{key: "PR", text: "Puerto Rico"},
	{key: "QA", text: "Qatar"},
	{key: "RO", text: "Romania"},
	{key: "RU", text: "Russian Federation"},
	{key: "RW", text: "Rwanda"},
	{key: "RE", text: "Réunion"},
	{key: "BL", text: "Saint Barthélemy"},
	{key: "SH", text: "Saint Helena, Ascension and Tristan da Cunha"},
	{key: "KN", text: "Saint Kitts and Nevis"},
	{key: "LC", text: "Saint Lucia"},
	{key: "MF", text: "Saint Martin"},
	{key: "PM", text: "Saint Pierre and Miquelon"},
	{key: "VC", text: "Saint Vincent and the Grenadines"},
	{key: "WS", text: "Samoa"},
	{key: "SM", text: "San Marino"},
	{key: "ST", text: "Sao Tome and Principe"},
	{key: "SA", text: "Saudi Arabia"},
	{key: "SN", text: "Senegal"},
	{key: "RS", text: "Serbia"},
	{key: "SC", text: "Seychelles"},
	{key: "SL", text: "Sierra Leone"},
	{key: "SG", text: "Singapore"},
	{key: "SX", text: "Sint Maarten, Dutch part"},
	{key: "SK", text: "Slovakia"},
	{key: "SI", text: "Slovenia"},
	{key: "SB", text: "Solomon Islands"},
	{key: "SO", text: "Somalia"},
	{key: "ZA", text: "South Africa"},
	{key: "GS", text: "South Georgia and the South Sandwich Islands"},
	{key: "SS", text: "South Sudan"},
	{key: "ES", text: "Spain"},
	{key: "LK", text: "Sri Lanka"},
	{key: "SD", text: "Sudan"},
	{key: "SR", text: "Suriname"},
	{key: "SJ", text: "Svalbard and Jan Mayen"},
	{key: "SE", text: "Sweden"},
	{key: "CH", text: "Switzerland"},
	{key: "SY", text: "Syrian Arab Republic"},
	{key: "TW", text: "Taiwan, Province of China"},
	{key: "TJ", text: "Tajikistan"},
	{key: "TZ", text: "Tanzania, United Republic of"},
	{key: "TH", text: "Thailand"},
	{key: "TL", text: "Timor-Leste"},
	{key: "TG", text: "Togo"},
	{key: "TK", text: "Tokelau"},
	{key: "TO", text: "Tonga"},
	{key: "TT", text: "Trinidad and Tobago"},
	{key: "TN", text: "Tunisia"},
	{key: "TM", text: "Turkmenistan"},
	{key: "TC", text: "Turks and Caicos Islands"},
	{key: "TV", text: "Tuvalu"},
	{key: "TR", text: "Türkiye"},
	{key: "UG", text: "Uganda"},
	{key: "UA", text: "Ukraine"},
	{key: "AE", text: "United Arab Emirates"},
	{key: "GB", text: "United Kingdom"},
	{key: "UM", text: "United States Minor Outlying Islands"},
	{key: "US", text: "United States of America"},
	{key: "UY", text: "Uruguay"},
	{key: "UZ", text: "Uzbekistan"},
	{key: "VU", text: "Vanuatu"},
	{key: "VE", text: "Venezuela, Bolivarian Republic of"},
	{key: "VN", text: "Vietnam"},
	{key: "VG", text: "Virgin Islands, British"},
	{key: "VI", text: "Virgin Islands, U.S."},
	{key: "WF", text: "Wallis and Futuna"},
	{key: "EH", text: "Western Sahara"},
	{key: "YE", text: "Yemen"},
	{key: "ZM", text: "Zambia"},
	{key: "ZW", text: "Zimbabwe"},
	{key: "AX", text: "Åland Islands"}
]  as IComboBoxOption[];
  

export const _YearOptions = [
    { key: "2020", text: "2020" },
    { key: "2021", text: "2021" },
    { key: "2022", text: "2022" },
    { key: "2023", text: "2023" },
    { key: "2024", text: "2024" },
    { key: "2025", text: "2025" },
    { key: "2026", text: "2026" },
    { key: "2027", text: "2027" },
    { key: "2028", text: "2028" },
    { key: "2029", text: "2029" },
    { key: "2030", text: "2030" },
    { key: "2031", text: "2031" },
    { key: "2032", text: "2032" },
    { key: "2033", text: "2033" },
    { key: "2034", text: "2034" },
    { key: "2035", text: "2035" },
    { key: "2036", text: "2036" },
    { key: "2037", text: "2037" },
    { key: "2038", text: "2038" },
    { key: "2039", text: "2039" },
    { key: "2040", text: "2040" },
] as IComboBoxOption[];