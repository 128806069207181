export default class Locale_APIMessages {

	// SHARED

	Common = {
		ContractNotFound: "Contract not found",
		DatesIncompatibleWithResolution: "The selected dates are not compatible with the selected resolution!",
		StartDatesBeforeCurrentPeriod: "Start dates cannot be earlier than the beginning of the current period!",
		StartEndNotDefined: "Start and End must be defined!",
		ResourceNotDefined: "Resource must be specified!",
		IncompatibleDates: "Incompatible dates selected"
	}


	// FILES

	TPUser = {
		GetResource: {
			Unauthorized: "We did not find any resources which matches your LoginName."
		}
	}

	ContractService = {
		MergeContracts: {
			NoSourceContract: "Could not identify source contract! Could be that you're trying to merge a newly added empty contract, which is not supported.",
			NoTargetContract: "Could not identify target contract! Could be that you're trying to merge a newly added empty contract, which is not supported.",
			NoMatch: "Contracts do not match correctly to perform a merge."
		},
		CreateMatchingRequest: {
			NoGenerics: "Resources hasn't got a related generic resource",
			ExisitingRequest: "The contract already has a corresponding request on a different generic resource.",
			ExisitingRequestOutsideRange: "The contract has requests outside the cache constraint scope.",
			NoAllocations: "The contract has no allocations within the selected date range."
		},
		Upsert: {
			TooManyActivities: "The selected contract has too many requests and allocations. Your changes have not been saved! Please create a new contract and do continue your work on that."
		},
		AddRequest: {
			DifferentGenericResource: "There's already a different generic resource assigned to this contract."
		}
	}

	WorkpackageService = {
		AddSubPackages: {
			SubpackagesReferenceError: "Cannot add this template, as it is referenced by a parent to the current template"
		}
	}

	ResourceService = {
		VerifyDeletion: {
			DeleteGenericInUse: "Generic resources in use, cannot be deleted"
		},
		Authorization: {
			ForbiddenAccess: "You are not authorized to view other resources.",
			RbsMismatch: "This resource is not within your RBS.",
			NotTeamOwner: "You are not the owner of this team."
		}
	}

	ProjectService = {
		Authorization: {
			ForbiddenAccess: "You are not an owner of this project.",
			RbsMismatch: "This project is not within your RBS."
		}
	}

	ContractActivityEx = {
		Resize: {
			NoResolution: "Resolution must be set when moving in time"
		}
	}

	ActivityMover = {
		Constructor: {
			NoResolution: "Resolution must be set when moving in time"
		}
	}

	FreezePeriodUtil = {
		HandleDate: {
			DateWithinFreezePeriod: "Date is within the configured frezeperiod!"
		}
	}

	ResourceGenericResourceChanged = {
		TryUpsert: {
			FailedToMove: "Failed to move existing allocations to new generic resource."
		}
	}

	ActivityDistributor = {
		Constructor : {
			NoPossibleResources: "No possible resources for the given combination of generic/pool resource and restriction filter!"
		}
	}

	


	// PLUGINS

	Plugins = {
		StartDelegation: {
			NoSigninPermissions: "Resource selected for delegation has no sign-in permissions and your delegation request has been cancelled."
		},
		FindDeliveryDates: {
			GenericResourceNotUsed: "Generic resource is not used by any named resources!"
		},
		ForwardRequest: {
			InvalidInputTooMuch: "The forward request input is not valid! You're trying to forward more than available.",
			InvalidInputFreezePeriod: "The forward request input is not valid! You're trying to forward during the freeze period."
		},
		MergeContracts: {
			DtoNull: "No data was sent with the merge request",
			SourceNotFound: "Source contract could not be found!",
			TargetNotFound: "Target contract could not be found!",
			SelfMerge: "A contract cannot be merged with itself :)",
		},
		GetNewCapacityRow : {
			ContractHasRequestOnADifferentResource: "Contract already have requests on another resource!"
		}
	}
}