import { useEffect } from 'react';
import { useAnimationProp } from '../../../../../../../../hooks/useAnimationProp';
import { usePrevious } from '../../../../../../../../hooks/usePrevious';

export const useAnimationListener = (valueToListenTo: any, keyframesName: string, animationDuration = 1000) => {
	const lastValue = usePrevious(valueToListenTo);
	const [animation, startAnimation] = useAnimationProp(keyframesName, animationDuration);
	useEffect(() => {
		if (valueToListenTo !== lastValue) {
			startAnimation();
		}
	}, [lastValue, startAnimation, valueToListenTo]);
	return animation;
};
