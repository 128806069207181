const isObject = (obj) => {
	return obj === Object(obj);
}

const mergeValues = (target: any, source: any) => {
	if (isObject(target) && isObject(source)) {
		return mergeObjects(target, source)
	}
	if (Array.isArray(target) && Array.isArray(source)) {
		return mergeArrays(target, source)
	}
	if (source === undefined) {
		return target
	}
	return source
}

const mergeObjects = (target: object, source: object) => {
	Object.keys(source).forEach((key) => {
		const sourceValue = source[key]
		const targetValue = target[key]
		target[key] = mergeValues(targetValue, sourceValue)
	});

	return target
}
const mergeArrays = (target: any[], source: any[]) => {
	source.forEach((value, index) => {
		target[index] = mergeValues(target[index], value)
	})

	return target
}

/**
 * Merges from soure to target
 * @param target Object
 * @param sources Object
 * @returns target merged with source
 */
export const merge = <T,>(target: T, ...sources: object[]) : T => {
	sources.forEach((source) => {
		return mergeValues(target, source)
	})
	return target;
}