import 'reflect-metadata';
import { IconButton, PrimaryButton, TextField } from '@fluentui/react';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ITreeNode } from '../interfaces/ITreeNode';
import { JsonEx } from '../../../../../../Utilities/JsonEx';
import { ArrayEx } from '../../../../../../Utilities/ArrayEx';
import { treeHelper } from '../helpers/treeHelper';
import { TreeView } from './TreeView';
import { PFDialog } from '../../../../../../Components/Common/PFDialog';

interface IProps {
    tree: ITreeNode;
    selected?: string | Array<string>;
    onChanged: (item: string | Array<string> | ITreeNode) => void;
    type: 'select' | 'manage';
    multiSelect?: boolean;
    dialogTitle?: string;
    buttonTitle?: string;
    delimiter?: string;
    readOnly?: boolean;
    defaultExpandedDepth?: number;
    autoSelectAndDisableChilds?: boolean;
    autoExpandSelected?: boolean;
    required?: boolean;
}

export const TreeEditor: FunctionComponent<IProps> = ({
    tree,
    selected,
    onChanged: update,
    type,
    autoExpandSelected,
    autoSelectAndDisableChilds,
    buttonTitle,
    defaultExpandedDepth,
    delimiter,
    dialogTitle,
    multiSelect,
    readOnly,
    required,
}) => {
    const [item, setItem] = useState<string | Array<string>>(selected ?? (multiSelect ? null : []));
    const [treeNode, setTreeNode] = useState<ITreeNode>(tree);
    const [editMode, setEditMode] = useState<boolean>(false);

    const originalTreeStruture = useRef(JsonEx.clone(tree));

    useEffect(() => {
        setItem(selected);
    }, [selected]);

    return (
        <div>
            {type === 'select' ? (
                <div style={{ display: 'flex' }}>
                    <IconButton iconProps={{ iconName: readOnly ? 'Zoom' : 'Edit' }} onClick={() => setEditMode(true)} />
                    <TextField
                        styles={{ root: { width: '100%' } }}
                        disabled={readOnly}
                        readOnly
                        value={ArrayEx.joinToString(treeHelper.getNames(treeNode, ArrayEx.ensureArray(selected)), ' | ')}
                    />
                </div>
            ) : (
                <PrimaryButton required={required && !readOnly} text={buttonTitle || (readOnly ? 'View' : 'Edit')} onClick={() => setEditMode(true)} />
            )}
            {editMode && (
                <PFDialog
                    title={dialogTitle}
                    maxWidth={1500}
                    showDialog={editMode}
                    callback={() => {
                        if (type === 'select') {
                            const items = treeHelper.getSelectedIds(treeNode);
                            const item = multiSelect ? items : ArrayEx.firstOrNull(items);
                            setEditMode(false);
                            setItem(item);
                            update(item);
                        } else {
                            const clone = treeNode;
                            setEditMode(false);
                            setTreeNode(clone);
                            originalTreeStruture.current = JsonEx.clone(clone);
                            update(clone);
                        }
                    }}
                    dismissCallback={() => {
                        setEditMode(false);
                        setTreeNode(JsonEx.clone(originalTreeStruture.current));
                    }}
                    buttonDefaultDisabled={readOnly}
                >
                    <TreeView
                        delimiter={delimiter || '.'}
                        readOnly={readOnly}
                        selected={item as string | Array<string>}
                        tree={treeNode}
                        type={type}
                        multiSelect={multiSelect}
                        defaultExpandedDepth={defaultExpandedDepth ?? 2}
                        autoSelectAndDisableChilds={autoSelectAndDisableChilds}
                        autoExpandSelected
                        hideRootNode
                    />
                </PFDialog>
            )}
        </div>
    );
};
