import { WorkType } from "../Entities/Dto/AddActivityDto";
import { devLogger } from "../helpers/devLogger";
import { OverviewContext } from "./Context/OverviewContext";

export const shouldResetAlignStatus = (workType: WorkType) => {
	const enableAlignContract = OverviewContext.Settings.EnableAlignContract;
	const alignContractStatuses = OverviewContext.Settings.AlignContractStatuses;
	const overrideAlignContractOnAllocationChange = OverviewContext.Settings.OverrideAlignContractOnAllocationChange;
	const overrideAlignContractOnRequestChange = OverviewContext.Settings.OverrideAlignContractOnRequestChange;

	if ((enableAlignContract && alignContractStatuses && alignContractStatuses.length) 
		&& ((overrideAlignContractOnAllocationChange && overrideAlignContractOnAllocationChange.length > 0 && workType === WorkType.Allocation) 
		|| ((overrideAlignContractOnRequestChange && overrideAlignContractOnRequestChange.length > 0) && workType === WorkType.Request))) {
			return true;
	} else {
		return false;
	}
}

export const resetAlignStatus = (workType: WorkType) => {
	let selectedAlignStatusId: string;
	switch(workType){
		case WorkType.Allocation:
			selectedAlignStatusId = OverviewContext.Settings.OverrideAlignContractOnAllocationChange;
			break;
			case WorkType.Request:
			selectedAlignStatusId = OverviewContext.Settings.OverrideAlignContractOnRequestChange;
			break;
		}
	const resetTo = OverviewContext.Settings.AlignContractStatuses.find((status) => status.Id === selectedAlignStatusId).Id;
	devLogger.log('resetting to', resetTo)
	return resetTo;
}