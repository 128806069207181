import { useMemo } from 'react';
import { UnitType } from '../../../../api/generated/data-contracts';
import { useUISettings } from '../../../../context/network/http/QueryProvider/queries/UISettings';
import { useUnitType } from '../../../../Utilities/UnitTypeUtil';
import Big from 'big.js';


export const useFixedCellValue = (value: number | Big) => {
	const { data } = useUISettings();
    const { currentUnitType } = useUnitType();
	return useMemo(() => {
		return currentUnitType === UnitType.FTE ? value.toFixed(data.settings.gridDecimalsFTE) : value.toFixed(data.settings.gridDecimalsHours);
	}, [currentUnitType, data.settings.gridDecimalsFTE, data.settings.gridDecimalsHours, value]);
};


