import { DateEx } from './DateEx';

export function flatten<T>(arrays: Array<any>): Array<T> {
    return arrays.reduce((flat, toFlatten) => {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
}

export function equalByProperty(first: Array<any>, second: Array<any>, ...key: Array<string>): boolean {
    if (first == null && second == null) return true;
    if (first == null && second != null) return false;
    if (first != null && second == null) return false;

    const hasDiff = first.some(obj => {
        return second.some(obj2 => {
            key.forEach(_ => {
                const different = obj[_] !== obj2[_];
                if (different) return true;
            });
            return false;
        });
    });
    return !hasDiff;
}

export function merge<T>(first: T[], second: T[]): T[] {
    if (first == null && second == null) return [];
    if (first == null && second != null) return [...second];
    if (first != null && second == null) return [...first];
    return [...first, ...second];
}

export function mergeDistinct<T>(first: T[], second: T[], selector: (item: T) => any): T[] {
    if (first == null && second == null) return [];
    if (first == null && second != null) return [...second];
    if (first != null && second == null) return [...first];

    const combined = [...first, ...second];
    return distinct(combined, selector);
}

export function groupBy<T>(array: T[], property: string, fallbackProperty?: string): { [key: string]: T[] } {
    return array.reduce((groups, item) => {
        const val = item[property] || item[fallbackProperty];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
    }, {});
}

const getNestedProperty = (nestedObj: any, pathArr: string[]) =>
    pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);

export function groupByNestedPropertyWithFallback<T>(array: T[], fieldExpr: string, fallbackProperty?: string): { [key: string]: T[] } {
    const path = fieldExpr.split('.');
    const fallBackPath = fallbackProperty.split('.');
    return array.reduce((groups, item) => {
        const val = getNestedProperty(item, path) || getNestedProperty(item, fallBackPath);
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
    }, {});
}

export function groupByNestedProperty<T>(array: T[], fieldExpr: string, fallbackGroup: string = 'Basic'): { [key: string]: T[] } {
    const path = fieldExpr.split('.');
    const getNestedProperty = (nestedObj: any, pathArr: string[]) =>
        pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : fallbackGroup), nestedObj);
    return array.reduce((groups, item) => {
        const val = getNestedProperty(item, path);
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
    }, {});
}

export function sortByNestedProperty<T>(array: T[], fieldExpr: string, order: 'asc' | 'desc'): T[] {
    const path = fieldExpr.split('.');
    return array.sort((a, b) => (getNestedProperty(a, path) > getNestedProperty(b, path) ? (order === 'asc' ? 1 : -1) : order === 'asc' ? -1 : 1));
}

export function sortByNestedProperties<T>(array: T[], ...params: string[]): T[] {
    const fieldSorter = (fields: string[]) => (a: any, b: any) =>
        fields
            .map(o => {
                let dir = 1;
                if (o[0] === '-') {
                    dir = -1;
                    o = o.substring(1);
                }
                return getNestedProperty(a, o.split('.')) > getNestedProperty(b, o.split('.')) || getNestedProperty(a, o.split('.')) == null
                    ? dir
                    : getNestedProperty(a, o.split('.')) < getNestedProperty(b, o.split('.')) || getNestedProperty(b, o.split('.')) == null
                    ? -dir
                    : 0;
            })
            .reduce((p, n) => (p ? p : n), 0);

    const sorted = array.sort(fieldSorter(params));
    return sorted;
}

export function sort(array: any[], ...params: string[]): any[] {
    const fieldSorter = (fields: string[]) => (a: any, b: any) =>
        fields
            .map(o => {
                let dir = 1;
                if (o[0] === '-') {
                    dir = -1;
                    o = o.substring(1);
                }
                return a[o] === '' ? 1 : a[o] > b[o] ? dir : b[o] === '' ? -1 : a[o] < b[o] ? -dir : 0;
            })
            .reduce((p, n) => (p ? p : n), 0);

    const sorted = array.sort(fieldSorter(params));
    return sorted;
}

export function sortEmptyOrNullLast(array: any[], fieldExpr: string, ascending: boolean = true, isDate: boolean = false): any[] {
    const path = fieldExpr.split('.');
    // const getNestedProperty = (nestedObj, pathArr) => pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    const multiplier = ascending ? 1 : -1;

    const sorter = (a: any, b: any) => {
        const valueA = getNestedProperty(a, path);
        const valueB = getNestedProperty(b, path);

        if (valueA === valueB)
            // identical? return 0
            return 0;
        else if (valueA == null || valueA === '')
            // a is null? last
            return 1;
        else if (valueB == null || valueB === '')
            // b is null? last
            return -1;
        else if (Array.isArray(valueA) || Array.isArray(valueB)) return (valueA.length - valueB.length) * multiplier;
        else {
            if (isDate) {
                const firstDate = DateEx.tryParse(valueA);
                const secondDate = DateEx.tryParse(valueB);
                return firstDate.getTime() > secondDate.getTime() ? 1 * multiplier : -1 * multiplier;
            } else if (Object.hasOwn(valueA,'localeCompare')) {
                return valueA.localeCompare(valueB) * multiplier; // compare, negate if descending
            } else {
                return (valueA > valueB ? 1 : -1) * multiplier;
            }
        }
    };

    return array.sort(sorter);
}

// mutates array
export function sortByArray<T>(array: T[], selector: (item: T) => string, sortArray: any[]): void {
    array.sort((a, b) => sortArray.indexOf(selector(a)) - sortArray.indexOf(selector(b)));
}

/**
 * Pushes an item to an array - overwrites existing if any.
 *
 * @param array - the array
 * @param obj - the item to upsert
 * @param selector - the action used identify the item
 * @returns Returns the replaced item, if any.
 */
export function pushOrReplace<T>(array: T[], obj: T, selector: <J>(T: J) => boolean): T {
    const index = array.findIndex(e => selector(e));
    let replaced: T;
    if (index === -1) array.push(obj);
    else {
        replaced = { ...(array[index] as any) } as T; // cast due to error: 'Spread types may only be created from object types'
        array[index] = obj;
    }
    return replaced;
}

export function pushOrReplaceMultiple<T>(arrayTo: T[], arrayFrom: T[], selector: <J>(T: J) => boolean) {
    arrayFrom.forEach(_ => {
        const index = arrayTo.findIndex(e => selector(e) === selector(_));
        if (index === -1) arrayTo.push(_);
        else arrayTo[index] = _;
    });
}

export function Replace<T>(array: T[], obj: T, selector: <J>(T: J) => boolean) {
    const index = array.findIndex(e => selector(e));
    if (index === -1) {
    } else array[index] = obj;
}

// mutates array
// returns removed
export function removeMultiple<T>(array: Array<T>, predicate: (item: T) => boolean): Array<T> {
    const removed = [];
    for (let i = array.length - 1; i >= 0; i--) {
        const shouldRemove = predicate(array[i]);
        if (shouldRemove) {
            const rem = array.splice(i, 1);
            removed.push(...rem);
        }
    }
    return removed;
}

export function pushIfNotExists<T>(array: T[], obj: T, selector: <J>(T: J) => boolean) {
    const index = array.findIndex(e => selector(e));
    if (index === -1) array.push(obj);
    else return;
}

export function unshiftIfNotExists<T>(array: T[], obj: T, selector: <J>(T: J) => boolean) {
    const index = array.findIndex(e => selector(e));
    if (index === -1) array.unshift(obj);
    else return;
}

// only simple types
export function simpleCompare(first: any[], second: any[], ignoreLength: boolean = false): boolean {
    if ((!first && second) || (first && !second)) return false;
    if (!first && !second) return true;
    if (!ignoreLength && first.length !== second.length) return false;
    first = [...first];
    second = [...second];
    const secondSorted = second.sort();
    if (ignoreLength && second.length > first.length) second.slice(first.length);
    return first.sort().every((value, index) => {
        return value === secondSorted[index];
    });
}

export function simpleCompareNoSort(first: any[], second: Array<any>, ignoreLength: boolean = false): boolean {
    if ((!first && second) || (first && !second)) return false;
    if (!ignoreLength && first.length !== second.length) return false;
    first = [...first];
    second = [...second];
    if (ignoreLength && second.length > first.length) second.slice(first.length);
    return first.every((value, index) => {
        return value === second[index];
    });
}

export function multiSplice<T>(arr: T[], ...indexes: number[]): void {
    indexes.sort((a, b) => {
        return a - b;
    });
    for (let i = 0; i < arr.length; i++) {
        const index = indexes[i] - i;
        arr.splice(index, 1);
    }
}

export function multiSlice<T>(arr: T[], ...indexes: number[]): T[] {
    const sliced = [];
    indexes.forEach(_ => {
        sliced.push(arr[_]);
    });
    return sliced;
}

/**
 *
 * @param array
 * @param selector
 * @deprecated
 */
export const distinct = distinctStringsBySelector;

export function distinctStringsBySelector<T>(array: T[], selector: (item: T) => any): T[] {
    return [...new Set(array.map(item => selector(item)))];
}

export function distinctObjectsBySelector<T>(array: T[], selector: (item: any) => string): T[] {
    return [...new Map(array.map(item => [selector(item), item])).values()];
}

export function distinctObject<T>(array: T[], key: string): T[] {
    // TODO: make better
    return array.reduce((prev, curr) => (prev.find(a => a[key] === curr[key]) ? prev : prev.push(curr) && prev), []);
}

export function difference<T>(array1: T[], array2: T[]): T[] {
    if (!array2) return array1;
    return array1.filter(i => array2.indexOf(i) < 0);
}

export function differenceBy<T>(array1: T[], array2: T[], selector: (item: T) => any): T[] {
    if (!array2) return array1;
    return array1.filter(i => array2.findIndex(_ => selector(i) === selector(_)) < 0);
}

export function same<T>(array1: T[], array2: T[]): T[] {
    return array1.filter(i => array2.indexOf(i) > 0);
}

export function lastElement<T>(array: T[]): T {
    return array[array.length - 1];
}

export function joinToString(array: any[], seperator: string, property?: string): string {
    if (!array) return '';
    return array.map(_ => (property ? _[property] : _)).join(seperator);
}

export function objectJoinToString<T>(array: T[], seperator: string, stringFunc: (item: T) => string): string {
    if (!array) return '';
    return array.map(_ => stringFunc(_)).join(seperator);
}

export function joinToStringIgnore(array: any[], seperator: string, property?: string, ...ignores: string[]): string {
    if (!array) return '';
    let clone = [...array];
    if (ignores) {
        clone = clone.filter(_ => !ignores.some(i => i === _));
    }
    return clone.map(_ => (property ? _[property] : _)).join(seperator);
}

export function firstOrNull<T>(arr: T[]): T {
    if (!Array.isArray(arr)) return null;
    if (!arr || !arr.length) return null;
    return arr[0];
}

export function isNullOrEmpty(arr: any[]): boolean {
    return !arr || !arr.length;
}

export function toggleItem<T>(array: T[], item: T): void {
    const index = array.indexOf(item);
    if (index < 0) array.push(item);
    else array.splice(index, 1);
}

export function any<T>(source: any[], ...args: T[]): boolean {
    if (source == null) return false;
    return source.some(_ => args.indexOf(_) > -1);
}

export function ensureArray<T>(object: T[] | T): T[] {
    if (object == null) return [];
    if (Array.isArray(object)) return object as T[];
    else return [object];
}

export const replace = <T>(array: T[], obj: T, selector: <J>(T: J) => boolean) => {
    const index = array.findIndex(e => selector(e));
    if (index === -1) {
    } else array[index] = obj;
};

/**
 *
 * @param item The item to insert
 * @param index index to insert the item at
 * @param array The array to work with
 * @returns A new copy of the array with the item inserted at the given index
 */
export function insertItemInArray<T>(array: T[], index: number, item: T) {
    if (index > array.length - 1 || index < 0) {
        return array;
    }
    return array.map((item, i) => {
        if (i === index) {
            return item;
        }
        return item;
    });
    // return [...array.slice(0, index), item, ...array.slice(index + 1)];
}

export const ArrayEx = {
    flatten,
    any,
    difference,
    differenceBy,
    distinct,
    distinctObject,
    ensureArray,
    equalByProperty,
    firstOrNull,
    groupBy,
    groupByNestedProperty,
    groupByNestedPropertyWithFallback,
    isNullOrEmpty,
    joinToString,
    joinToStringIgnore,
    lastElement,
    merge,
    mergeDistinct,
    multiSlice,
    multiSplice,
    objectJoinToString,
    pushIfNotExists,
    pushOrReplace,
    pushOrReplaceMultiple,
    removeMultiple,
    replace,
    same,
    simpleCompare,
    simpleCompareNoSort,
    sort,
    sortByArray,
    sortByNestedProperties,
    sortByNestedProperty,
    sortEmptyOrNullLast,
    toggleItem,
    unshiftIfNotExists,
    insertItemInArray,
};
