import { DropdownOptionEx } from "../../Utilities/DropdownOptionEx";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyFilter, PropertyFilterOptions } from "../../Utilities/PropertyInfo/PropertyFilter";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { DistributionTechnique } from "./Enums";
import { ResourceDistribution } from "./ResourceDistribution";

export const ActivityDistributionBasicOption: PropertyFilterOptions = { FilterLevel: 2 }
export const ActivityDistributionAdvancedOption: PropertyFilterOptions = { FilterLevel: 1 }

export class ActivityDistributionConfig {
	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:AutoDistributionConfig.DistributionTechnique", 1, "_localization:AutoDistributionConfig.DistributionTechniqueDescription")
	@PFPropertyRender((item: ActivityDistributionConfig, i, col) => DistributionTechnique[item[col.fieldName]], { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<DistributionTechnique>(DistributionTechnique) })
	DistributionTechnique: DistributionTechnique;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:AutoDistributionConfig.ResourceDistribution", 5)
	@PFPropertyRender(null, { ItemType: ResourceDistribution})
	@PFPropertyFilter({ FilterLevel: 2 })
	ResourceDistribution: ResourceDistribution;
	@PFPropertyBasicInfo(PropertyType.NumberSlider, "_localization:AutoDistributionConfig.AllowedEvenlyDistributedVariation", 10, "_localization:AutoDistributionConfig.AllowedEvenlyDistributedVariationDescription")
	@PFPropertyRender(null, { Min: 0, Max: 100, Format: (value: number) => `${value}%` })
	@PFPropertyFilter({ FilterLevel: 1 })
	AllowedEvenlyDistributedVariation: number;
}