import { useCallback, useLayoutEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import shallow from 'zustand/shallow';
import { ApiCalls } from '../../../../api/api';
import { GridData, QueryGroup, UnitType } from '../../../../api/generated/data-contracts';
import { RESOURCE_PLANNER } from '../../../../context/network/http/QueryProvider/queryKeys';
import { useStore } from '../../../../context/store';
import { useMemoQueryKey } from '../../../../context/network/http/QueryProvider/queries/useMemoQueryKey';
import { usePrevious } from '../../../../hooks/usePrevious';

export const useResourcePlannerData = ({
    currentUnitType,
    currentViewFilter,
    gridRef,
}: {
    currentViewFilter: QueryGroup | null;
    currentUnitType: UnitType | UnitType;
    gridRef: React.MutableRefObject<{
        getUpdatedData: () => Promise<GridData>;
    }>;
}) => {
    const setBlockDialog = useStore(store => store.setBlockDialog);

    const { timelineStart, timelineEnd, timelineResolution } = useStore(store => {
        return {
            timelineEnd: store.timeline.end,
            timelineStart: store.timeline.start,
            timelineResolution: store.timeline.resolution,
        };
    }, shallow);
    const queryKey = useMemoQueryKey(RESOURCE_PLANNER, timelineStart, timelineEnd, timelineResolution, currentViewFilter, currentUnitType);
    const { data: gridData } = useQuery(queryKey, () =>
        ApiCalls.getResourcePlannerGrid({ dateResolution: timelineResolution, end: timelineEnd, start: timelineStart, filter: currentViewFilter })
            .then(res => res.data)
            .catch(error => {
                // can we do something better? by typing errors in the backend? or using business codes? (ewi)
                if (error?.title?.startsWith('MaxReturnedResources') || error?.detail?.startsWith('MaxReturnedResources')) {
                    setBlockDialog(null, {
                        title: `You're asking for too much :)`,
                        subText:
                            'You apparently have a lot of resources. Try narrowing the result down by using the filters. And set a new default view if needed.',
                    });
                    return { rows: [], headers: [], metadata: {} } as GridData;
                } else {
                    setBlockDialog(null, {
                        title: 'An unexpected error has occured',
                        subText: `Status: ${error?.title ?? 'unknown'}, Trace: ${error?.traceId ?? 'unknown'}`,
                    });
                    return { rows: [], headers: [], metadata: {} } as GridData;
                }
            }),
    );
    const prevQueryKey = usePrevious(queryKey);
    const queryClient = useQueryClient();
    const updateQueryCache = useCallback(
        (qKey: any[]) => (data: GridData) => {
            queryClient.setQueryData<GridData>(qKey, () => data);
        },
        [queryClient],
    );

    useLayoutEffect(() => {
        const ref = gridRef.current;
        if (prevQueryKey !== queryKey && ref) {
            ref.getUpdatedData().then(updateQueryCache(prevQueryKey));
            return () => void ref.getUpdatedData().then(updateQueryCache(prevQueryKey));
        }
    }, [gridRef, prevQueryKey, queryKey, updateQueryCache]);

    return gridData;
};
