import { RecurrenceFrequency } from "./RecurrenceFrequency";
import { Ordinal } from "./Ordinal";
import { Month } from "./Month";
import { DayOfWeek } from "./DayOfWeek";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { DropdownOptionEx } from "../../Utilities/DropdownOptionEx";
import { Day } from "./Day";
import { PFPropertyValidation } from "../../Utilities/PropertyInfo/Validation";
import { IObjectEditorItemType } from "../Main/IObjectEditorItemType";

const ValidateDay = (rule: RecurrenceRule) : boolean => {
	if (rule.Frequency === RecurrenceFrequency.Year) {
		if (rule.Day == null) return true;
		if ((!rule.Months || !rule.Months.length) && !rule.OrdinalWeek) 
			return false;
	}
	return true;
}

export class RecurrenceRule implements IObjectEditorItemType {
	
	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Common.Frequency", 1, "_localization:RecurrenceRule.FrequencyDescription")
	@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsDisableSome<RecurrenceFrequency>(RecurrenceFrequency, [1]) })
	public Frequency: RecurrenceFrequency;
	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:RecurrenceRule.Skip", 2, "_localization:RecurrenceRule.SkipDescription")
	@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.toDropdownOptions(Array.from({length: 10}, (v, k) => k+1), _ => _, _ => _+"") })
	public Skip: number;
	@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:RecurrenceRule.Months", 3, "_localization:RecurrenceRule.MonthsDescription")
	@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<Month>(Month) }, null, (obj: RecurrenceRule) => obj.Frequency !== RecurrenceFrequency.Year)
	public Months: Array<Month>;
	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:RecurrenceRule.OrdinalWeek", 4, "_localization:RecurrenceRule.OrdinalWeekDescription")
	@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsAddEmptyEntry<Ordinal>(Ordinal, null, "Not used") }, null, (obj: RecurrenceRule) => (obj.Frequency !== RecurrenceFrequency.Year && obj.Frequency !== RecurrenceFrequency.Month) || obj.Day != null)
	public OrdinalWeek: Ordinal;
	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:RecurrenceRule.Day", 5, "_localization:RecurrenceRule.DayDescription")
	@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.toDropdownOptionsAddEmptyEntry(Array.from({length: 31}, (v, k) => k+1), _ => _, _ => _+"", null, null, "Not used") }, null, (obj: RecurrenceRule) => obj.DaysOfWeek.length > 0 || (obj.Frequency !== RecurrenceFrequency.Year && obj.Frequency !== RecurrenceFrequency.Month) || obj.DayOfWeek != null)
	@PFPropertyValidation("_localization:RecurrenceRule.DayValidation", (item: RecurrenceRule) => ValidateDay(item))
	public Day: number;
	@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:RecurrenceRule.DaysOfWeek", 6, "_localization:RecurrenceRule.DaysOfWeekDescription")
	@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<Day>(Day) }, null, (obj: RecurrenceRule) => obj.Frequency !== RecurrenceFrequency.Week)
	public DaysOfWeek: Array<Day>;
	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:RecurrenceRule.DayOfWeek", 7, "_localization:RecurrenceRule.DayOfWeekValidation")
	@PFPropertyRender(null, { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsAddEmptyEntry<DayOfWeek>(DayOfWeek, null, "Not used") }, null, (obj: RecurrenceRule) => obj.Frequency === RecurrenceFrequency.Week || obj.Day != null)
	@PFPropertyValidation("_localization:RecurrenceRule.DayOfWeekValidation", (item: RecurrenceRule) => item.DayOfWeek == null ? true : item.OrdinalWeek != null)
	public DayOfWeek: DayOfWeek;

	@PFPropertyBasicInfo(PropertyType.Date, "_localization:RecurrenceRule.ActiveFrom", 7, "_localization:RecurrenceRule.ActiveFromDescription")
	@PFPropertyValidation("_localization:RecurrenceRule.ActiveFromValidation", (item: RecurrenceRule) => item.ActiveFrom != null, null, () => true)
	public ActiveFrom: Date;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:RecurrenceRule.ActiveTo", 7, "_localization:RecurrenceRule.ActiveToDescription")
	public ActiveTo?: Date;

	constructor() {
		this.DaysOfWeek = [];
		this.Months = [];
		this.Frequency = RecurrenceFrequency.Year;
		this.Skip = 1;
	}
}