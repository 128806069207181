/**
 * Borrowed from https://www.trysmudford.com/blog/linear-interpolation-functions/
 * Great read btw
 */

/**
 * Linear interpolate in a range
 * @param x start of range
 * @param y end of range
 * @param a value to interpolate
 * @returns an interpolated value of a in the range
 */
export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;

/**
 * Inverse linear interpolate in a range
 * @param x start of range
 * @param y end of range
 * @param a value to interpolate
 * @returns an interpolated value of a in the range
 */
export const invlerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x));

/**
 * If a is bigger than max, max is returned
 * If a is smaller than min, min is returned
 */
export const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));

/**
 *
 * @param x1
 * @param y1
 * @param x2
 * @param y2
 * @param a
 * @returns
 */
export const range = (x1: number, y1: number, x2: number, y2: number, a: number) => lerp(x2, y2, invlerp(x1, y1, a));
