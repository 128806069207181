import { Shimmer, ShimmerElementType } from '@fluentui/react';

type ShimmerBoxProps = { width?: number | string; height?: number; type?: ShimmerElementType }

export const ShimmerBox: React.FC<ShimmerBoxProps> = ({ width = 100, height = 16, type = ShimmerElementType.line }) => {
	return <Shimmer shimmerElements={getShimmerElementRow(width, height, type)} />;
};

const getShimmerElementRow = (width: string | number, height: number, type: ShimmerElementType) => {
	return [{ type, height, width }];
};
