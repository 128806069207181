import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { createContext } from 'react';
import { WizardStep } from './WizardStep';

interface IProps {
    completeCallback?: () => void;
    cancelCallback?: () => void;
    changeCallback?: (currentIndex, newIndex) => void;
    steps: Array<WizardStep>;
    fixedHeight?: number;
    properties?: Record<string, any>;
    customNextButton?: (next: () => void) => JSX.Element;
    customPrevButton?: (prev: () => void) => JSX.Element;
}

export type ValidationDirection = 'prev' | 'next' | 'both';

export interface IWizardContext {
    setCompleteButtonText: Dispatch<SetStateAction<string>>;
    currentIndex: number;
    setCurrentIndex: (index: number) => void;
    properties: Record<string, any>;
    setProperties: Dispatch<SetStateAction<Record<string, any>>>;
    setPropertyValue: (key: string, value: any) => void;
    getPropertyValue: (key: string) => any;
    ignoreValidation: boolean | Array<number>;
    setIgnoreValidation: Dispatch<SetStateAction<boolean | Array<number>>>;
    setValidationDirection: Dispatch<SetStateAction<ValidationDirection>>;
    skipSteps: Record<number, boolean>;
    setSkipStep: (index: number, value: boolean) => void;
    setSkipSteps: (steps: Record<number, boolean>) => void;
    setHideDots: Dispatch<SetStateAction<boolean>>;
    setCompleteAction: Dispatch<SetStateAction<() => void>>;
}

export const StepWizard: FunctionComponent<IProps> = props => {
    const WizardContext = createContext<IWizardContext>(null);

    const [steps, setSteps] = useState<Array<WizardStep>>(props.steps);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [properties, setProperties] = useState(props.properties ?? {});
    const [ignoreValidation, setIgnoreValidation] = useState<boolean | Array<number>>(false);
    const [validationDirection, setValidationDirection] = useState<ValidationDirection>('next');
    const [skipSteps, _setSkipSteps] = useState<Record<number, boolean>>({});
    const [hideDots, setHideDots] = useState(false);
    const [completeButtonText, setCompleteButtonText] = useState('Complete');
    const [completeAction, setCompleteAction] = useState<() => void>(() => props.completeCallback);

    useEffect(() => {
        setSteps(props.steps);
    }, [props.steps]);

    const setPropertyValue = (key: string, value: any): void => {
        const clone = { ...(properties ?? {}) };
        clone[key] = value;
        setProperties(clone);
    };

    const getPropertyValue = (key: string): any => {
        return properties[key];
    };

    const setSkipStep = (key: number, value: boolean): void => {
        const clone = { ...(skipSteps  ?? {}) };
        clone[key] = value;
        _setSkipSteps(clone);
    };

    const setSkipSteps = (steps: Record<number, boolean>): void => {
        _setSkipSteps(steps);
    };

    const shouldSkip = (index: number, backwards: boolean = false): number => {
        if (skipSteps[index]) {
            if (!backwards) index += 1;
            else index -= 1;
            shouldSkip(index);
        }
        return index;
    };

    const next = (skip: number = 1) => {
        let nextIndex = currentIndex + skip;
        nextIndex = shouldSkip(nextIndex);
        if (nextIndex >= steps.length) {
            if (completeAction) completeAction();
            return;
        }
        changeCurrent(nextIndex);
    };

    const prev = () => {
        let nextIndex = currentIndex - 1;
        nextIndex = shouldSkip(nextIndex, true);
        if (currentIndex <= 0) return;
        changeCurrent(nextIndex);
    };

    const context: IWizardContext = {
        currentIndex,
        setCurrentIndex,
        properties,
        setProperties,
        setPropertyValue,
        getPropertyValue,
        ignoreValidation,
        setIgnoreValidation,
        setValidationDirection,
        skipSteps,
        setSkipStep,
        setSkipSteps,
        setHideDots,
        setCompleteButtonText,
        setCompleteAction,
    };

    const isLastStep = (index: number): boolean => {
        const isRestOfStepsSkipped = () =>
            steps
                .map((_, idx) => idx)
                .slice(index + 1)
                .every(_ => skipSteps[_]);
        return index >= steps.length - 1 || isRestOfStepsSkipped();
    };

    const shouldValidate = (newIndex: number): boolean => {
        switch (validationDirection) {
            case 'prev':
                return newIndex < currentIndex;
            case 'next':
                return newIndex > currentIndex;
            case 'both':
                return true;
        }
    };

    const validate = (newIndex: number): boolean => {
        if (!shouldValidate(newIndex)) return true;

        const asc = currentIndex < newIndex;
        let low = asc ? currentIndex : newIndex;
        low++;
        const high = asc ? newIndex : currentIndex;

        const stepsBetween: Array<number> = [currentIndex];
        while (low < high) {
            stepsBetween.push(low++);
        }

        let validated = true;
        for (const idx of stepsBetween) {
            const currentStep = steps[idx];
            // ignore can be a boolean for 'all' or an array of specific step indexes
            const ignore = Array.isArray(ignoreValidation) ? (ignoreValidation as Array<number>).findIndex(_ => _ === idx) >= 0 : ignoreValidation;
            if (currentStep.Validate && !ignore) {
                const stepValid = currentStep.Validate(context);
                if (!stepValid) validated = false;
            }
        }

        return validated;
    };

    const changeCurrent = (newIndex: number) => {
        if (!validate(newIndex)) return;

        if (props.changeCallback) props.changeCallback(currentIndex, newIndex);
        setCurrentIndex(newIndex);
    };

    return (
        <WizardContext.Provider value={context}>
            <div className="tp-stepwizard-container" style={props.fixedHeight ? { height: props.fixedHeight } : null}>
                <div className="tp-stepwizard-dotcontainer" style={hideDots || steps[currentIndex].hideDots ? { display: 'none' } : null}>
                    {steps.map((_, index) => {
                        if (steps[index].hideDots || skipSteps[index]) return null;
                        return (
                            <div
                                key={`tp-stepwizard-dot-${index}`}
                                className={`tp-stepwizard-dot ${index === currentIndex ? 'active' : ''}`}
                                onClick={() => changeCurrent(index)}
                            />
                        );
                    })}
                </div>
                <div className="tp-stepwizard-content">
                    <div className="tp-stepwizard-item">{steps[currentIndex].content(next, prev, context)}</div>
                </div>
                <div className="tp-stepwizard-buttons">
                    {currentIndex > 0 && !steps[currentIndex].hidePrevious && (
                        <div className="tp-stepwizard-buttons-previous">
                            {props.customPrevButton ? props.customPrevButton(prev) : <DefaultButton text="Previous" onClick={prev} />}
                        </div>
                    )}
                    {!steps[currentIndex].hideNext && (
                        <div className="tp-stepwizard-buttons-next">
                            {props.customNextButton ? (
                                props.customNextButton(next)
                            ) : (
                                <PrimaryButton text={isLastStep(currentIndex) ? completeButtonText : 'Next'} onClick={() => next()} />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </WizardContext.Provider>
    );
};
