import { SetterOrUpdater } from 'recoil';
import type Big from 'big.js';
import { GridCell, GridHeaderRow, GridRow } from '../../../../api/generated/data-contracts';
import { createContext } from '../../../../hooks/createContext';
import { ImmutableRowsMap } from '../grid-types';

export type UpdateCellInfo = {
    cell: Readonly<GridCell>;
    newValue: Big;
    rowId: string;
    columnIndex: number;
    cellIndex: number;
};

export type GridContext<AddRowMetadata = any> = {
    name: string;
    headers: GridHeaderRow[];
    properties: Record<string, any>;
    id: string;
    updateCellValue: (info: UpdateCellInfo | UpdateCellInfo[]) => Promise<void>;
    getRowAncestorTreeToRoot: (rowId: string) => GridRow[];
    removeRow: (rowId: string) => Promise<void>;
    addRow: (rowId: string, metadata: AddRowMetadata, placeLast?: boolean) => Promise<{newRows: GridRow[], parentRow: GridRow}>;
    readonly: boolean;
    updateMultipleCells: (batch: UpdateCellInfo[]) => Promise<void>;
    maxGridDepth: number;
	useGridCellState: (cell: GridCell) => readonly [Big, SetterOrUpdater<Big>];
	useGridCellValue: (cell: GridCell) => Big;
	moveRow: (rowId: string, newParentId: string) => Promise<void>
	getRowsMap: () => ImmutableRowsMap;
	setRootRows: (cb: (rootRows: GridRow[]) => GridRow[] | Promise<GridRow[]>) => Promise<void>
};

const [useCtx, Provider] = createContext<GridContext>();

export const useGridContext = <AddRowMetadata = any>() => useCtx() as GridContext<AddRowMetadata>;
export const GridContextProvider = Provider;
