import { useStore } from '../context/store';
import { TPError2 } from '../Entities/TPError';
import { language, LocalizationService } from '../Services/LocalizationService';
import { JsonEx } from '../Utilities/JsonEx';
import { getAuthHeaders } from './authConfig';
import { Api } from './generated/Api';
import { ContentType, HttpClient } from './generated/http-client';
import { Startup } from './generated/Startup';

/**
 * This instance contains methods to make calls to the projectum-teamplanner-next backend
 */
export const ApiCalls = (() => {
    const nativeApi = new Api({});
    (nativeApi as any).contentFormatters = {
        ...(nativeApi as any),
        [ContentType.Json]: (input: any) => (input !== null && (typeof input === 'object' || typeof input === 'string') ? JsonEx.stringify(input) : input),
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob ? property : typeof property === 'object' && property !== null ? JsonEx.stringify(property) : `${property}`,
                );
                return formData;
            }, new FormData()),
    };
    const httpClientKeys = Object.keys(new HttpClient());
    const wrappedApi = Object.keys(nativeApi).reduce((wrap, key: keyof Api) => {
        if (typeof nativeApi[key] === 'function') {
            if (key === 'request') {
                wrap[key] = nativeApi[key].bind(nativeApi);
            }
            if (!httpClientKeys.includes(key)) {
                // We bind the method to the original api instance to make sure we have the right "this" context
                // because "this" is weird and confusing
                wrap[key] = wrapCall((nativeApi[key] as any).bind(nativeApi), key);
            }
        }
        return wrap;
    }, {} as Omit<Api, keyof HttpClient> & Pick<HttpClient, 'request'>);
    return wrappedApi;
})();

export const StartupCalls = (() => {
    const nativeApi = new Startup({});
    (nativeApi as any).contentFormatters = {
        ...(nativeApi as any),
        [ContentType.Json]: (input: any) => (input !== null && (typeof input === 'object' || typeof input === 'string') ? JsonEx.stringify(input) : input),
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob ? property : typeof property === 'object' && property !== null ? JsonEx.stringify(property) : `${property}`,
                );
                return formData;
            }, new FormData()),
    };
    const httpClientKeys = Object.keys(new HttpClient());
    const wrappedApi = Object.keys(nativeApi).reduce((wrap, key: keyof Api) => {
        if (typeof nativeApi[key] === 'function') {
            if (key === 'request') {
                wrap[key] = nativeApi[key].bind(nativeApi);
            }
            if (!httpClientKeys.includes(key)) {
                // We bind the method to the original api instance to make sure we have the right "this" context
                // because "this" is weird and confusing
                wrap[key] = wrapCall((nativeApi[key] as any).bind(nativeApi), key);
            }
        }
        return wrap;
    }, {} as Omit<Startup, keyof HttpClient> & Pick<HttpClient, 'request'>);
    return wrappedApi;
})();

type ReturnPromiseType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R> ? R : any;

export function wrapCall<CallbackFunction extends (...args: any[]) => Promise<any>>(apiCall: CallbackFunction, methodName: string) {
    // the api calls takes either multiple params where 1 is optional, or takes 1 optional param
    return async (...args: Parameters<CallbackFunction>): Promise<ReturnPromiseType<CallbackFunction>> => {
        try {
            const headers = await getAuthHeaders();
            const callArgs: any[] = [];
            let dontSerialize = false;

            if (apiCall.length) {
                // If the length is above zero, consider the last arg as params
                const params = args[apiCall.length] || {};
                if (params.format) {
                    // We let the implementation control the response format
                    // and otherwise fallback to text so we can do our
                    // own parsing and handle i.e dates how we like it later
                    dontSerialize = true;
                }
                for (let index = 0; index < apiCall.length; index++) {
                    const arg = args[index];
                    callArgs.push(arg);
                }
                callArgs.push({
                    ...params,
                    headers: { ...(params.headers || {}), ...headers },
                    format: params.format || 'text',
                });
            } else {
                // If the length is zero then we got a call with 1 optional param
                const params = args[0] || {};
                if (params.format) {
                    dontSerialize = true;
                }
                callArgs.push({
                    ...params,
                    headers: { ...(params.headers || {}), ...headers },
                    format: params.format || 'text',
                });
            }

            const response = await apiCall(...callArgs);
            const error = parseError(response.data);

            if (error) {
                throw error;
            }

            if (response.error) {
                throw response.error;
            }

            if (response.type === 'opaqueredirect') {
                // we assume that when a redirect happen from a call to a plugin, it is when the user is not authenticated any more - ewi
                useStore.getState().setBlockDialog(null, {
                    title: language.PluginInvoker.AuthenticationExpired,
                    subText: language.PluginInvoker.AuthenticationExpiredDescription,
                });
                // window.location.href = res.url; // force redirect despite of cors
            }

            if (dontSerialize) {
                return response;
            }

            return {
                ...response,
                data: JsonEx.tryParse(response.data),
            };
        } catch (error) {
            console.error('api ~ error', methodName, error);
			const errString = error?.error;
			if (typeof errString === "string") {
				const err = JSON.parse(errString)
				if (err.detail || err.traceId) {
					// Support standard error messages from backend
					throw err
				}
			}
            handleError(error, methodName);
        }
    };
}

function parseError(resp: any): TPError2 {
    if (!resp || resp.TypeName !== 'TPException') return null;
    const error = JsonEx.parseAdvanced<TPError2>(resp, TPError2);
    return error;
}

function handleError(err: any, errorText?: string, disableRethrow?: boolean, fallbackValue?: any): void {
	const isTPException = err instanceof TPError2;
    const error = isTPException ? err : TPError2.New(err, err.message, err.stack);
    console.error(errorText, error.AsString());
    const { addErrorNotification } = useStore.getState();
    // handle default values yourself
    if (disableRethrow) {
        if (errorText) {
            addErrorNotification(errorText, undefined, error.Message);
            // addErrorNotification(errorText, 5, error.Message)
        }
        if (isTPException) {
            addErrorNotification(LocalizationService.Instance.GetAPIMessageByKey(error.Message));
        }
        return fallbackValue;
    }

    if (isTPException) {
        addErrorNotification(LocalizationService.Instance.GetAPIMessageByKey(error.Message));
    }
    // throw error to be catched by caller
    throw error;
}

export function callWrapCall<CallbackFunction extends (...args: any[]) => Promise<any>>(
    apiCall: CallbackFunction,
    errorText: string,
    ...args: Parameters<CallbackFunction>
): Promise<ReturnPromiseType<CallbackFunction>> {
    return wrapCall(apiCall, errorText)(...args);
}

export function resolveDirectly<Cb extends (...args: any[]) => Promise<{ data: any }>>(fn: Cb) {
    return async (...args: Parameters<Cb>): Promise<ReturnPromiseType<Cb>['data']> => {
        return fn(...args)
            .then(res => res.data)
            .catch(res => {
                throw res.error;
            });
    };
}
