import { UserContext } from "../Utilities/Context/UserContext";
import { UserType } from "../Entities/UserType";

export class Permission {
	public static IsSuperuser(): boolean {
		return UserContext.Resource && UserContext.Resource.UserTypes && UserContext.Resource.UserTypes.indexOf(UserType.SuperUser) > -1;
	}

	public static IsLM(): boolean {
		return UserContext.Resource && UserContext.Resource.UserTypes && UserContext.Resource.UserTypes.indexOf(UserType.LineManager) > -1;
	}

	public static IsPM(): boolean {
		return UserContext.Resource && UserContext.Resource.UserTypes && UserContext.Resource.UserTypes.indexOf(UserType.ProjectOwner) > -1;
	}

	public static IsAdmin = (): boolean => {
		return UserContext.Resource && UserContext.Resource.UserTypes && UserContext.Resource.UserTypes.indexOf(UserType.Administrator) > -1;
	}

	public static IsResource(): boolean {
		return UserContext.Resource && UserContext.Resource.UserTypes && UserContext.Resource.UserTypes.indexOf(UserType.Resource) > -1;
	}

	public static HasSomePermissions = (...userTypes: Array<UserType>) : boolean => {
		if (userTypes == null) return false;
		return UserContext.Resource && UserContext.Resource.UserTypes && userTypes.some(_ =>  UserContext.Resource.UserTypes.indexOf(_) > -1);
	}

	public static HasAllPermissions = (...userTypes: Array<UserType>) : boolean => {
		if (userTypes == null) return false;
		return UserContext.Resource && UserContext.Resource.UserTypes && userTypes.every(_ =>  UserContext.Resource.UserTypes.indexOf(_) > -1);
	}
}
