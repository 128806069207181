import { AtLeast, fieldOptionsFactory, IFieldConfig, IFormItem, IFormItemFieldOptions } from "spark-forms";
import { language } from "../../../Services/LocalizationService";
import { DateEx } from "../../../Utilities/DateEx";
import { unrestrictedMode } from "../../../Utilities/DebugOverrides";
import { IAadGroupFieldConfig } from "./Fields/IAadGroupFieldConfig";
import { IMessageBarFieldConfig } from "./Fields/IMessageBarFieldConfig";
import { IProjectFieldConfig } from "./Fields/IProjectFieldConfig";
import { IRbsFieldConfig } from "./Fields/IRbsFieldConfig";
import { IResourceFieldConfig } from "./Fields/IResourceFieldConfig";


window["t"] = () => console.log(unrestrictedMode)

export const tpFieldOptionsFactory = {

    cron: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, IFieldConfig<T>>, "displayName">) : Partial<IFormItemFieldOptions<T, IFieldConfig<T>>> => {
        options.fieldType = "cron" as any;
        return options;
    },

    color: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, IFieldConfig<T>>, "displayName">) : Partial<IFormItemFieldOptions<T, IFieldConfig<T>>> => {
        options.fieldType = "color" as any;
        return options;
    },

    // numberSlider: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, IDynamicNumberSliderFieldConfig<T>>, "displayName">) : Partial<IFormItemFieldOptions<T, IDynamicNumberSliderFieldConfig<T>>> => {
    //     options.fieldType = "numberSlider" as any;
    //     return options;
    // },

    messageBar: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, IMessageBarFieldConfig<T>>, "displayName">) : IFormItemFieldOptions<T, IMessageBarFieldConfig<T>> => {
        options.fieldType = "messageBar" as any;
        return options as any;
    },

    // textfieldCopyvalue: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, IDynamicTextFieldConfig<T>>, "displayName">) : Partial<IFormItemFieldOptions<T, IDynamicTextFieldConfig<T>>> => {
    //     options.fieldType = "textfieldCopyvalue" as any;
    //     return options;
    // },

    rbs: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, IRbsFieldConfig<T>>, "displayName">) : IFormItemFieldOptions<T, IRbsFieldConfig<T>> => {
        options.fieldType = "rbs" as any;
        return options as any;
    },

	tree: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, IRbsFieldConfig<T>>, "displayName">) : IFormItemFieldOptions<T, IRbsFieldConfig<T>> => {
        options.fieldType = "tree" as any;
        return options as any;
    },

    resource: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, AtLeast<IResourceFieldConfig<T>, "nameField" | "descriptionField" | "resourceTypes">>, "displayName">) : IFormItemFieldOptions<T, IResourceFieldConfig<T>> => {
        options.fieldType = "resource" as any;
        return options as any;
    },

    project: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, AtLeast<IProjectFieldConfig<T>, "nameField" | "descriptionField" | "projectTypes">>, "displayName">) : IFormItemFieldOptions<T, IProjectFieldConfig<T>> => {
        options.fieldType = "project" as any;
        return options as any;
    },

    // // entityProperty: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, IDynamicArrayFieldConfig<T>>, "displayName">) : Partial<IFormItemFieldOptions<T, IDynamicArrayFieldConfig<T>>> => {
    // //     options.fieldType = "array";
    // //     return options;
    // // },

	aadGroup: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, IAadGroupFieldConfig<T>>, "displayName">) : IFormItemFieldOptions<T, IAadGroupFieldConfig<T>> => {
        options.fieldType = "aadGroup" as any;
        return options as any;
    },

	richText: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, IFieldConfig<T>>, "displayName">) : IFormItemFieldOptions<T, IFieldConfig<T>> => {
        options.fieldType = "richText" as any;
        return options as any;
    },

	// periodSpan: <T extends IFormItem>(options: AtLeast<IFormItemFieldOptions<T, IDynamicPropertyComponentConfig<T>>, "displayName">) : Partial<IFormItemFieldOptions<T, IDynamicPropertyComponentConfig<T>>> => {
    //     options.fieldType = "periodSpan" as any;
    //     return options;
    // }
}


export const systemFieldsGrouped = () => ({
    id: fieldOptionsFactory.string({ displayName: language.Common.Id, disable: () => !unrestrictedMode, group: 'system' }),
    created: fieldOptionsFactory.date({
        displayName: language.Common.Created,
        disable: () => !unrestrictedMode,
        group: 'system',
        config: { displayFormat: (date?: Date) => DateEx.asUserLocalWithTime(date) },
    }),
    modified: fieldOptionsFactory.date({
        displayName: language.Common.Modified,
        disable: () => !unrestrictedMode,
        group: 'system',
        config: { displayFormat: (date?: Date) => DateEx.asUserLocalWithTime(date) },
    }),
    createdBy: fieldOptionsFactory.string({ displayName: language.Common.CreatedBy, disable: () => !unrestrictedMode, group: 'system' }),
    modifiedBy: fieldOptionsFactory.string({ displayName: language.Common.ModifiedBy, disable: () => !unrestrictedMode, group: 'system' }),
});

export const dateFieldColumnRender = <T,>(field: keyof T, withTime?: boolean) => {
	const func = withTime
					? DateEx.asUserLocalWithTime
					: DateEx.asUserLocal;
	return { 
		onRender: item => func(item[field] as Date), minWidth: 120, isResizable: true
	}
}