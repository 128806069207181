import { FunctionComponent } from 'react';
import { KpiBar } from './KpiBar';
import { useTheme } from '../../Themes/themeContext';
import { IStyleFunctionOrObject, IProgressIndicatorStyleProps, IProgressIndicatorStyles, mergeStyleSets } from '@fluentui/react';
 
interface IProps {
	Percentage: number;
	TooltipText: string;
	Styles?: IStyleFunctionOrObject<IProgressIndicatorStyleProps, IProgressIndicatorStyles>;
}


export const ListItemPercentageBar: FunctionComponent<IProps> = props => {
	const theme = useTheme();

	return 	<KpiBar 
				Percentage={props.Percentage}
				TooltipText={props.TooltipText} 
				Styles={
					mergeStyleSets(
						props.Styles, 
						{ 
							root: { marginLeft: "64px", marginRight: 8 }, 
							itemProgress: { paddingBottom: 4 },
							progressTrack: { backgroundColor: props.Percentage === -2 ? theme.semanticColors.kpiInfinite : theme.semanticColors.kpiFree }, 
							progressBar: { backgroundColor: props.Percentage >= 101 ? theme.semanticColors.kpiOver : theme.semanticColors.kpiUsed } 
						}
					)
				}
			/>
}
