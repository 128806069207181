import { FunctionComponent, useState, useEffect } from 'react';
import { TextField } from '@fluentui/react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { OriginService } from '../../../Services/OriginService';
import { Origin } from '../../../Entities/Main/Origin';
 
interface IProps extends IDynamicEditorComponentProps { 
    Item: Array<string>;
    Key?: string;
}

const OriginRender: FunctionComponent<IProps> = props => {
    const [origins, setOrigins] = useState<Array<Origin>>();

    useEffect(() => {
		let mounted = true;
		const origins = OriginService.GetOrigins(props.Item);
		if (mounted)
			setOrigins(origins);

		return () => {
			mounted = false
		};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	return  (
		<>
			{ origins && 
				<TextField
					key={ props.Key }
					value={ origins.map(_ => _.DisplayName).join(" | ") }
					disabled={ props.ReadOnly }
				/>
			}
		</>
	)
}

export default OriginRender;