import { MessageBar } from '@fluentui/react/lib/MessageBar';
import { Link, MessageBarButton } from '@fluentui/react';
import { Notification } from '../../Entities/Notification';
import { DateEx } from '../../Utilities/DateEx';
import { ThemeContext } from '../../Themes/themeContext';
import { language } from '../../Services/LocalizationService';
import { FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import { useContext } from 'react';
import { MessageBarButtonInfo } from '../../Entities/Fabric/MessageBarButtonInfo';

interface IProps {
    notification: Notification;
    dismiss: (notification: Notification) => void;
}

export const NotificationItem: FunctionComponent<IProps> = ({ notification, dismiss }) => {
    const themeCtx = useContext(ThemeContext);
    const [expireCountdown, setExpireCountdown] = useState(notification.Expires * 1000);
    const [pauseCountdown, setPauseCountdown] = useState(false);
    const [buttons, setButtons] = useState(notification.Buttons || []);

    useEffect(() => {
        if (notification.errorInfo) {
            setButtons(btns => [...btns, new MessageBarButtonInfo('Copy info', () => navigator.clipboard.writeText(notification.errorInfo))]);
        }
    }, [notification.errorInfo]);

    useLayoutEffect(() => {
        if (notification.Expires !== undefined && !pauseCountdown) {
            if (expireCountdown <= 0) {
                dismiss(notification);
            } else {
                const currentTimer = setTimeout(() => {
                    setExpireCountdown(expireCountdown - 1000);
                }, 1000);
                return () => {
                    clearTimeout(currentTimer);
                };
            }
        }
    }, [dismiss, expireCountdown, notification, pauseCountdown]);

    return (
        <div
            className="tp-notification-item"
            onMouseEnter={() => setPauseCountdown(true)}
            onMouseLeave={() => {
                setPauseCountdown(false);
                // Reset countdown
                setExpireCountdown(notification.Expires * 1000);
            }}
        >
            <MessageBar
                onDismiss={e => dismiss(notification)}
                dismissButtonAriaLabel={language.Common.Close}
                messageBarType={notification.GetMessageBarType()}
                styles={{
                    dismissal: { selectors: { i: { color: themeCtx.customizations.settings.theme.semanticColors.bodyText, fontSize: '13px !important' } } },
                    innerText: { width: '100%', display: 'flex', selectors: { '& > span': { width: '100%', display: 'flex', flexDirection: 'column' } } },
                }}
                actions={
                    Boolean(buttons.length) && (
                        <div>
                            {buttons.map((_, i) => {
                                return (
                                    <MessageBarButton
                                        key={_.Title + i}
                                        onClick={e => {
                                            _.action(e);
                                            dismiss(notification);
                                        }}
                                    >
                                        {_.Title}
                                    </MessageBarButton>
                                );
                            })}
                        </div>
                    )
                }
            >
                <div className="tp-notification-item-innercontent">
                    <span className="tp-notification-item-message">{notification.Message}</span>
                    <span className="tp-notification-item-timestamp">{DateEx.formatDateWithTimeSimple(notification.TimeStamp)}</span>
                </div>
                {notification.Link && (
                    <Link href={notification.Link.Url} target="_blank">
                        {notification.Link.Title}
                    </Link>
                )}
            </MessageBar>
        </div>
    );
};
