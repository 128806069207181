import { DirectionalHint } from "@fluentui/react";
import React, { FunctionComponent } from "react";
import { language } from "../../Services/LocalizationService";
import { CoachmarkInfo } from "../CoachmarkInfo";
import { PFCoachmark } from "../PFCoachmark";

export const LMCapacityCoachmarks: FunctionComponent<any> = React.memo(props => {
	const _project = new CoachmarkInfo("plannerview-project", "#project-overlay-button", language.Coachmarks.LMCapacity.Project, DirectionalHint.rightCenter);
	const _resource = new CoachmarkInfo("plannerview-resource", "#resource-overlay-button", language.Coachmarks.LMCapacity.Resource, DirectionalHint.leftCenter);
	const _settings = new CoachmarkInfo("plannerview-settings", ".lmcapacity-settings-buttons-top", language.Coachmarks.LMCapacity.Settings, DirectionalHint.rightCenter);
	const _view = new CoachmarkInfo("plannerview-views", ".lmcapacity-settings-dropdowns", language.Coachmarks.LMCapacity.View, DirectionalHint.bottomCenter);
	const _drop = new CoachmarkInfo("plannerview-dropzone", ".tp-lmcapacity-pickercontainer", language.Coachmarks.LMCapacity.Drop, DirectionalHint.bottomRightEdge);
	
	return	<>
				<PFCoachmark CoachmarkInfo={_project} />
				<PFCoachmark CoachmarkInfo={_resource} />
				<PFCoachmark CoachmarkInfo={_settings} />
				<PFCoachmark CoachmarkInfo={_view} />
				<PFCoachmark CoachmarkInfo={_drop} />
			</>
})