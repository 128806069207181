import 'reflect-metadata';
import { Dialog, DialogFooter, IconButton, PrimaryButton, TextField, DialogContent } from '@fluentui/react';
import { FunctionComponent, useState } from 'react';
import { language } from '../../Services/LocalizationService';
import { ArrayEx } from '../../Utilities/ArrayEx';
import { SortableTreeView } from './SortableTreeView';
import { TreeViewProps } from './TreeViewProps';
import { sortableTreeHelper } from './sortableTreeHelper';
import { IDisableOptions } from '@pum-spark/tree-render';

export type SortableTreeEditorProps = TreeViewProps & {
    required?: boolean;
    readOnly?: boolean;
    buttonTitle?: string;
    dialogTitle?: string;
    autoSelectAndDisableChilds?: boolean; // TODO: support for this in tree-render in spark
};

const defaultDisableOptions = { nodeIds: [], type: 'disable' } as IDisableOptions;

export const SortableTreeEditor: FunctionComponent<SortableTreeEditorProps> = props => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const { data, required, readOnly, mode, defaultSelectedNodes, buttonTitle, dialogTitle } = props;

    const toggleDialog = () => setShowDialog(prev => !prev);

    const disableOptions = readOnly ? defaultDisableOptions : undefined; // TODO: support for this in the field

    return (
        <>
            {mode !== 'edit' ? (
                <div style={{ display: 'flex' }}>
                    <IconButton iconProps={{ iconName: readOnly ? 'Zoom' : 'Edit' }} onClick={toggleDialog} />
                    <TextField
                        styles={{ root: { width: '100%' } }}
                        disabled={readOnly}
                        readOnly
                        value={ArrayEx.joinToString(sortableTreeHelper.getNodeNames(data, ArrayEx.ensureArray(defaultSelectedNodes)), ' | ')}
                    />
                </div>
            ) : (
                <PrimaryButton required={required && !readOnly} text={buttonTitle || (readOnly ? 'View' : 'Edit')} onClick={toggleDialog} />
            )}
            {showDialog && (
                <Dialog
                    hidden={!showDialog}
                    onDismiss={toggleDialog}
                    // dialogContentProps={{ title: dialogTitle, styles: { inner: { padding: '0px 24px 24px 0px' } } }}
                    dialogContentProps={{ title: dialogTitle }}
                    styles={{ main: { maxWidth: '1200px !important', padding: 0 } }}
                >
                    <SortableTreeView {...props} disableOptions={disableOptions ?? props.disableOptions} sortNodes ignoreRootNode />
                    <DialogContent
                        styles={{
                            //
                            inner: { padding: '0 !important' },
                        }}
                    ></DialogContent>
                    <DialogFooter>
                        <PrimaryButton onClick={toggleDialog} text={language.Common.Ok} />
                    </DialogFooter>
                </Dialog>
            )}
        </>
    );
};
