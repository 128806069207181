import { memo, useMemo, CSSProperties } from 'react';
import { ResourceColumnRow } from './ResourceColumnRow';
import { ColumnRowProps } from '../../ColumnRowProps';
import { ROW_HEIGHT, COLUMN_WIDTH, BORDER } from '../../../../CONSTANTS';

export const ResourceColumnContainer = memo(function ResourceColumnContainer({
    columnIndex,
    columnRow,
    rowId,
    isAdminProject,
    start,
}: ColumnRowProps & { start: number }) {
    const style = useMemo(() => {
        return {
            width: `${COLUMN_WIDTH}px`,
            position: 'absolute',
            transform: `translateX(${start}px)`,
            top: 0,
            left: 0,
            height: `${ROW_HEIGHT}px`,
            boxSizing: 'border-box',
            borderBottom: BORDER,
            borderRight: BORDER,
        } as CSSProperties;
    }, [start]);
    return (
        <div style={style}>
            <ResourceColumnRow columnRow={columnRow} columnIndex={columnIndex} rowId={rowId} isAdminProject={isAdminProject} />
        </div>
    );
});
