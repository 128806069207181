import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@fluentui/react';
import { language } from '../../../../Services/LocalizationService';


export const CommentTextField: React.FC<{
	value?: string;
	addComment: (str: string) => Promise<any>;
	autoAdjustHeight?: boolean;
	onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
}> = ({ value, addComment, autoAdjustHeight, onBlur }) => {

	const [newCommentText, setNewCommentText] = useState<string>(value || '');
	const [disabled, setDisabled] = useState(false);
	const textFieldRef = useRef<any>(null);
	
	useEffect(() => {
		if (!disabled) {
			if (textFieldRef.current) {
				textFieldRef.current.focus();
			}
		}
	}, [disabled]);

	const handleTextFieldInputChange = (event: any): void => {
		setNewCommentText(event.target.value);
	};

	const enterKeyPressed = useRef(false);
	const textFieldKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		enterKeyPressed.current = e.keyCode === 13 && !e.shiftKey;
		if (enterKeyPressed.current) {
			e.preventDefault();
			setNewCommentText(text => text + '\n');
			return;
		}
	};

	const textFieldKeyUp = async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		// ENTER
		if (enterKeyPressed.current && newCommentText) {
			const text = newCommentText.trim(); // remove all whitespace at beginning and end


			// Don't attempt to save empty comments
			if (!text)
				return;
			setDisabled(true);
			try {
				const exit = await addComment(text);
				if (exit) {
					return;
				}
			} catch (error) {
				console.log('textField enter ~ error', error);
			}
			setNewCommentText('');
			setDisabled(false);
		}
	};
	return (
		<TextField
			disabled={disabled}
			placeholder={language.Comments.HitEnterToSave}
			autoFocus={true}
			value={newCommentText}
			onChange={handleTextFieldInputChange}
			multiline
			rows={4}
			minLength={1}
			onKeyDown={textFieldKeyDown}
			onKeyUp={textFieldKeyUp}
			autoAdjustHeight={autoAdjustHeight}
			onBlur={onBlur}
			componentRef={(stuff: any) => {
				if (stuff?._textElement?.current) {
					textFieldRef.current = stuff._textElement.current;
				}
			}} />
	);
};
