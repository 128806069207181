import { TimelineResolution } from "../TimelineResolution";

export class AddActivityDto {
    ProjectId: string;
	ContractId: string;
	ContractName: string;
	ContractGroupName: string;
    ResourceId: string;
    ResourceName: string;
	Work: number;
	Resolution: TimelineResolution;
    Type: WorkType;
    Start: Date;
    End: Date;
}

export interface IAddActivityDto {
	ProjectId: string;
	ContractId: string;
	ContractName: string;
	ContractGroupName: string;
    ResourceId: string;
    ResourceName: string;
	Work: number;
	Resolution: TimelineResolution;
    Type: WorkType;
    Start: Date;
    End: Date;
}

export enum WorkType {
    Request = 1,
	Allocation = 2
}