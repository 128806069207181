import { FunctionComponent, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

interface IProps {
	Markdown: string;
}

export const MarkdownViewer: FunctionComponent<IProps> = props => {

	const [markdown, setMarkdown] = useState<string>(props.Markdown);

	useEffect(() => () => {
		setMarkdown(props.Markdown);
	}, [props.Markdown])

	return <ReactMarkdown className="markdown-body" remarkPlugins={[remarkGfm as any]} rehypePlugins={[rehypeRaw as any]} children={markdown} /> //TODO: Types for plugins
}