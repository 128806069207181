import { Stack, Icon, Text, IColumn, TextField } from '@fluentui/react';
import { FunctionComponent, useState, useEffect } from 'react';
import { ActivityDistributionConfig } from '../../../../../../Entities/ActivityDistribution/ActivityDistributionConfig';
import { DistributionResult } from '../../../../../../Entities/ActivityDistribution/DistributionResult';
import { ResourceOrderTypes } from '../../../../../../Entities/ActivityDistribution/Enums';
import { ResourceConstraints } from '../../../../../../Entities/ActivityDistribution/ResourceConstraints';
import { ResourceDistribution } from '../../../../../../Entities/ActivityDistribution/ResourceDistribution';
import { ResourceDistributionResult } from '../../../../../../Entities/ActivityDistribution/ResourceDistributionResult';
import { EntityType } from '../../../../../../Entities/EntityTypes';
import { Resource } from '../../../../../../Entities/Main/Resource';
import { DataService } from '../../../../../../Services/DataService';
import { language } from '../../../../../../Services/LocalizationService';
import { IExtendedTheme } from '../../../../../../Themes/Theme.types';
import { ArrayEx } from '../../../../../../Utilities/ArrayEx';
import { DateEx } from '../../../../../../Utilities/DateEx';
import { IDs } from '../../../../../../Utilities/IDs';
import { StringEx } from '../../../../../../Utilities/StringEx';
import ObjectListEditor from '../../../../../Common/ObjectListEditor';
import { DistributionFail } from './DistributionFail';
import { DistributionSuccess } from './DistributionSuccess';

interface IProps {
    theme: IExtendedTheme;
    result: DistributionResult;
    config: ActivityDistributionConfig;
    changeSettings: (config: ActivityDistributionConfig) => void;
}

export const DistributionResultComponent: FunctionComponent<IProps> = props => {
    const [result, setResult] = useState(props.result);
    const [resources, setResources] = useState(props.result?.Resources);

    useEffect(() => {
        setResult(props.result);
        if (props.result.Resources == null) return;
        const combined = mergeResources(resources, props.result.Resources);
        setResources(combined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.result]);

    const setMaxAllocation = (resourceId: string, max: number | null): void => {
        const clone = { ...props.config };
        clone.ResourceDistribution ??= new ResourceDistribution();
        clone.ResourceDistribution.ResourceConstraints ??= [];

        const constraint = clone.ResourceDistribution.ResourceConstraints?.find(rc => rc.ResourceId === resourceId);
        if (constraint == null) clone.ResourceDistribution.ResourceConstraints.push({ ResourceId: resourceId, MaximumTotalHours: max } as ResourceConstraints);
        else constraint.MaximumTotalHours = max;

        props.changeSettings(clone);
    };

    const getMaxAllocation = (resourceId: string): number => {
        const constraint = props.config.ResourceDistribution.ResourceConstraints?.find(rc => rc.ResourceId === resourceId);
        return constraint?.MaximumTotalHours;
    };

    const resetPriority = async (resourceIds: Array<string>): Promise<void> => {
        const clone = { ...props.config };
        clone.ResourceDistribution ??= new ResourceDistribution();
        clone.ResourceDistribution.ResourceConstraints ??= [];

        clone.ResourceDistribution.ResourceOrderTypes = [ResourceOrderTypes.Priority];

        for (const { index, value } of resourceIds.map((value, index) => ({ index, value }))) {
            const constraint = clone.ResourceDistribution.ResourceConstraints?.find(rc => rc.ResourceId === value);
            if (constraint == null) {
                const resource = await DataService.Get<Resource>(EntityType.Resource, value);
                clone.ResourceDistribution.ResourceConstraints.push({
                    ResourceId: value,
                    ResourceName: resource.Name,
                    Priority: index + 1,
                } as ResourceConstraints);
            } else constraint.Priority = index + 1;
        }

        props.changeSettings(clone);
    };

    const mergeResources = (existingRes: Array<ResourceDistributionResult>, newRes: Array<ResourceDistributionResult>): Array<ResourceDistributionResult> => {
        const result: Array<ResourceDistributionResult> = [];
        existingRes.forEach(existing => {
            let clone = { ...existing };
            const match = newRes?.find(_ => _.ResourceId === existing.ResourceId);
            if (match == null) {
                clone.AllocationSum = 0;
                clone.Start = null;
                clone.End = null;
            } else {
                clone = { ...match };
            }

            result.push(clone);
        });

        const compareByPriority = (item1: ResourceDistributionResult, item2: ResourceDistributionResult) => {
            const getPriority = (item: ResourceDistributionResult) =>
                props.config.ResourceDistribution.ResourceConstraints?.find(_ => _.ResourceId === item.ResourceId)?.Priority ?? 1000;

            const priority1 = getPriority(item1);
            const priority2 = getPriority(item2);

            if (priority1 < priority2) {
                return -1;
            }
            if (priority1 > priority2) {
                return 1;
            }
            return 0;
        };

        // add new resources
        result.push(...ArrayEx.differenceBy(newRes, existingRes, _ => _.ResourceId));
        // sort resources
        result.sort(compareByPriority);
        return result;
    };

    return (
        <Stack tokens={{ childrenGap: 24 }}>
            {result.Success ? <DistributionSuccess theme={props.theme} result={result} /> : <DistributionFail theme={props.theme} result={result} />}
            <Stack horizontalAlign="center" tokens={{ childrenGap: 16 }}>
                {result.DateSpan && (
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                        <Text variant="mediumPlus">{`${DateEx.asUserLocal(result.DateSpan.Min)}`}</Text>
                        <Icon iconName="SkypeArrow" styles={{ root: { transform: 'rotate(-180deg)' } }} />
                        <Text variant="mediumPlus">{`${DateEx.asUserLocal(result.DateSpan.Max)}`}</Text>
                    </Stack>
                )}
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                    <ObjectListEditor
                        ItemType={ResourceDistributionResult}
                        Items={resources}
                        HiddenColumnProperties={[]}
                        HiddenProperties={[]}
                        DisabledProperties={[]}
                        ItemIdentifier={item => item.ResourceId}
                        ReadOnly
                        MultiSelect={true}
                        DraggableRowsConfig={{
                            onDrop: (item, dropItem, items) => resetPriority(items.map(_ => _.ResourceId)),
                            onDrag: item => console.log(item),
                        }}
                        CustomColumns={[
                            {
                                key: IDs.makeId(),
                                name: 'Max hours',
                                fieldName: 'MaxHours',
                                maxWidth: 120,
                                onRender: (item: ResourceDistributionResult, idx: number, column: IColumn) => {
                                    return (
                                        <TextField
                                            key={IDs.makeId()}
                                            defaultValue={getMaxAllocation(item.ResourceId) + ''}
                                            onBlur={e =>
                                                setMaxAllocation(item.ResourceId, StringEx.isNullOrWhiteSpace(e.target.value) ? null : +e.target.value)
                                            }
                                            draggable={false}
                                            onFocus={event => event.target.select()}
                                            type={'number'}
                                            tabIndex={idx}
                                        />
                                    );
                                },
                                minWidth: 150,
                            },
                        ]}
                        ClonedItemChange={null}
                        HideMenu
                    />
                </Stack>
            </Stack>
            {result.Success && (
                <Stack
                    horizontal
                    horizontalAlign="center"
                    verticalAlign="center"
                    tokens={{ childrenGap: 4 }}
                    styles={{
                        root: {
                            padding: '8px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            backgroundColor: props.theme.semanticColors.warningBackground,
                            borderRadius: 2,
                            width: 'fit-content',
                        },
                    }}
                >
                    <Icon
                        iconName="Warning"
                        styles={{
                            root: { color: props.theme.semanticColors.warningIcon, fontSize: 17 },
                        }}
                    />

                    <Text styles={{ root: { color: props.theme.semanticColors.warningIcon, fontSize: 13 } }}>
                        {language.AutoAllocationDialog.AutoAllocating.AutoAllocatingOnAn}
                        <b>{language.AutoAllocationDialog.AutoAllocating.Existing}</b>
                        {language.AutoAllocationDialog.AutoAllocating.ContractWillAlso}
                        <b>{language.AutoAllocationDialog.AutoAllocating.ClearAll}</b>
                        {language.AutoAllocationDialog.AutoAllocating.ExistingActivities}
                    </Text>
                </Stack>
            )}
        </Stack>
    );
};
