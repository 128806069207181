
import { Plugin } from '../Services/PluginInvoker';
import { PluginIDs } from '../Utilities/PluginIDs';
import { DynamicQuery } from "../Entities/DynamicQueryBuilder/DynamicQuery";
import { EntityType } from "../Entities/EntityTypes";
import { language } from './LocalizationService';

export class DataService {

	/**
	 * Query
	 */
	public static Query = async <T>(entityType: EntityType, query?: DynamicQuery, enableRethrow?: boolean): Promise<Array<T>> => {
		const dto = { Entity: entityType, Query: query };
        return await Plugin.Invoke<Array<T>>(PluginIDs.Query, dto, `${language.DataService.FailedQuerying} ${EntityType[entityType].toString()}`, !enableRethrow, []);
	}

	/**
	 * Query Minified
	 */
	public static QueryMinified = async <T>(entityType: EntityType, query?: DynamicQuery, enableRethrow?: boolean): Promise<Array<T>> => {
		const dto = { Entity: entityType, Query: query };
        return await Plugin.Invoke<Array<T>>(PluginIDs.QueryMinified, dto, `${language.DataService.FailedQuerying} ${EntityType[entityType].toString()}`, !enableRethrow, []);
	}

	/**
	 * Query Expanded
	 */
	public static QueryExpanded = async <T>(entityType: EntityType, query?: DynamicQuery, enableRethrow?: boolean): Promise<Array<T>> => {
		const dto = { Entity: entityType, Query: query };
        return await Plugin.Invoke<Array<T>>(PluginIDs.QueryExpanded, dto, `${language.DataService.FailedQuerying} ${EntityType[entityType].toString()}`, !enableRethrow, []);
	}

	/**
	 * Get Expanded
	 */
	public static GetExpanded = async <T>(entityType: EntityType, id: string, enableRethrow?: boolean): Promise<T> => {
		const dto = { Entity: entityType, Id: id };
        return await Plugin.Invoke<T>(PluginIDs.GetExpanded, dto, `${language.DataService.FailedGetting} ${EntityType[entityType].toString()}`, !enableRethrow);
	}

	/**
	 * Get Expanded
	 */
	public static GetExpandedMultiple = async <T>(entityType: EntityType, ids: Array<string>, enableRethrow?: boolean): Promise<Array<T>> => {
		const dto = { Entity: entityType, Ids: ids };
        return await Plugin.Invoke<Array<T>>(PluginIDs.GetExpandedMultiple, dto, `${language.DataService.FailedGetting} ${EntityType[entityType].toString()}`, !enableRethrow);
	}

	/**
	 * Get
	 */
	public static Get = async <T>(entityType: EntityType, id: string, enableRethrow?: boolean): Promise<T> => {
		const dto = { Entity: entityType, Id: id };
        return await Plugin.Invoke<T>(PluginIDs.Get, dto, `${language.DataService.FailedGetting} ${EntityType[entityType].toString()}`, !enableRethrow);
	}
	
	public static CancelableGet = <T>(entityType: EntityType, id: string, enableRethrow?: boolean) => {
		const dto = { Entity: entityType, Id: id };
        return Plugin.CancelableInvokation<T>(PluginIDs.Get, dto, `${language.DataService.FailedGetting} ${EntityType[entityType].toString()}`, !enableRethrow);
	}

	/**
	 * Get
	 */
	public static GetSilent = async <T>(entityType: EntityType, id: string): Promise<T> => {
		const dto = { Entity: entityType, Id: id };
        return await Plugin.Invoke<T>(PluginIDs.Get, dto, `${language.DataService.FailedGetting} ${EntityType[entityType].toString()}`, false, null);
	}

	/**
	 * Upsert
	 */
	public static Upsert = async <T>(entityType: EntityType, items: Array<T>, enableRethrow?: boolean, dtoProps?: { [key: string]: any }): Promise<Array<T>> => {
		const dto = { Entity: entityType, Items: items, ...dtoProps };
        return await Plugin.Invoke<Array<T>>(PluginIDs.Upsert, dto, `${language.DataService.FailedUpserting} ${EntityType[entityType].toString()}`, !enableRethrow);
	}
	// public static CancelableUpsert = <T>(entityType: EntityType, items: Array<T>, enableRethrow?: boolean, dtoProps?: { [key: string]: any }) => {
	// 	const dto = { Entity: entityType, Items: items, ...dtoProps };
    //     return Plugin.CancelableInvokation<Array<T>>(PluginIDs.Upsert, dto, `${language.DataService.FailedUpserting} ${EntityType[entityType].toString()}`, !enableRethrow);
	// }

	/**
	 * Delete
	 */
	public static Delete = async <T>(entityType: EntityType, items: Array<T>, enableRethrow?: boolean): Promise<boolean> => {
		const dto = { Entity: entityType, Items: items };
        return await Plugin.Invoke<boolean>(PluginIDs.Delete, dto, `${language.DataService.FailedDeleting} ${EntityType[entityType].toString()}`, !enableRethrow);
	}
}