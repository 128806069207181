/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo } from 'react';
import { Toggle } from '@fluentui/react';
import { DynamicProps } from './DynamicProps';
import { language, LocalizationService } from '../../../../Services/LocalizationService';

interface IProps extends DynamicProps<boolean, boolean> {
    OnText: string;
    OffText: string;
}

const DynamicToggle: FunctionComponent<IProps> = props => {
    return (
        <Toggle
            onText={LocalizationService.Instance.GetByKey(props.OnText) || language.Common.Enable}
            offText={LocalizationService.Instance.GetByKey(props.OffText) || language.Common.Disabled}
            key={props.Key}
            onChange={(obj, value) => props.OnChange(props.PropertyInfo.PropertyName, value)}
            defaultChecked={props.Value}
            disabled={props.ReadOnly}
        />
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Value === nextProps.Value;
};

export default memo(DynamicToggle, comparisonFn);
