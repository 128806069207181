import { Rule } from "./Rule";
import { WorkWeek } from "./WorkWeek";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { guid } from "../../helpers/guid";
import { PFPropertyValidation } from "../../Utilities/PropertyInfo/Validation";
import { RuleEx } from "../../Utilities/RuleEx";
import { IObjectEditorItemType } from "./IObjectEditorItemType";
import { PFPropertyGrouping } from "../../Utilities/PropertyInfo/Grouping";
import { language } from "../../Services/LocalizationService";
import { OverviewContext } from "../../Utilities/Context/OverviewContext";

export class WorkProfile implements IObjectEditorItemType {
	public TypeName: string = "WorkProfile";
	
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.Name", 1)
	@PFPropertyRender(null, null, null, (item: WorkProfile) => item.ResourceGuid != null)
	public Name: string;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:WorkProfile.CapacityStart", 2, "_localization:WorkProfile.CapacityStartDescription")
	@PFPropertyRender(null, null, null, (item: WorkProfile) => item.ResourceGuid != null)
	@PFPropertyValidation("_localization:WorkProfile.CapacityStartValidation", (item: WorkProfile) => item.Start != null || item.ResourceGuid != null, null, () => true)
	public Start: Date;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:WorkProfile.CapacityEnd", 2, "_localization:WorkProfile.CapacityEndDescription")
	@PFPropertyRender(null, null, null, (item: WorkProfile) => item.ResourceGuid != null)
	@PFPropertyValidation("_localization:WorkProfile.CapacityEndValidation", (item: WorkProfile) => item.End != null || item.ResourceGuid != null, null, () => true)
	public End: Date;

	@PFPropertyBasicInfo(PropertyType.LabelText, "_localization:WorkProfile.FTEWarning", 3)
	@PFPropertyRender(null, null, null, (obj: WorkProfile) => OverviewContext.Settings.UnitType <= 0) // will this even work? we can't know fore sure if this is the 'root' settings (ewi) for now this will do
	public FTEWarning: string; // fake prop
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:WorkProfile.WeekHours", 4)
	@PFPropertyRender((obj: WorkProfile, idx, column) => obj.WeekHours ? `${language.Common.Total}: ${WorkWeek.Total(obj.WeekHours)}` : "", null, "WorkWeekEditor")
	// @PFPropertyValidation("_localization:WorkProfile.WeekHoursValidation", (wp: WorkProfile) => WorkWeek.Validate(wp.WeekHours))
	public WeekHours: WorkWeek;
	@PFPropertyBasicInfo(PropertyType.ObjectArray, "_localization:WorkProfile.Rules", 5)
	@PFPropertyRender((obj: WorkProfile, idx, column) => `${language.Common.Total}: ${obj.Rules ? obj.Rules.length : 0}`, { ItemType: Rule, HiddenProperties: [ "id" ], HiddenColumnProperties: [ "Start", "End"], ItemIdentifier: (item : Rule) => item.Id, ClonedItemChange: (_: Rule) => {
 _.Id = guid.newGuid(); _.Name = `copy_${_.Name}` 
} })
	@PFPropertyValidation("_localization:WorkProfile.RulesValidation", (obj: WorkProfile) => !RuleEx.rulesOverlap(obj.Rules).overlap)
	public Rules: Array<Rule>;

	// SYSTEM
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.Id", 1)
	@PFPropertyGrouping("System", 100)
    public id: string;
    @PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Created", 2)
	@PFPropertyGrouping("System")
    public Created: Date;
    @PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Modified", 3)
	@PFPropertyGrouping("System")
    public Modified: Date;
    @PFPropertyBasicInfo(PropertyType.String, "_localization:Common.CreatedBy", 4)
	@PFPropertyGrouping("System")
    public CreatedBy: string;
    @PFPropertyBasicInfo(PropertyType.String, "_localization:Common.ModifiedBy", 5)
	@PFPropertyGrouping("System")
	public ModifiedBy: string;

	public ResourceGuid: string;

	constructor() {
		this.id = guid.newGuid();
		this.Rules = [];
		this.WeekHours = new WorkWeek();
	}
}