import { TimelineResolution } from "./TimelineResolution";

export class FreezePeriod {
	public Resolution: TimelineResolution;
	public Number: number; // number of periods to freeze, including current period
	public FreezeBeforeTodayOnly: boolean; // this should disable/hide the 'Forward' and 'Backward' properties

	constructor() {
		this.Resolution = TimelineResolution.Month;
		this.Number = 0;
	}
}
