export default class Locale_Resources { 
	FailedGettingNamedResources: string = "Failed getting named resources for use in search";
	Favorite = {
		Resources: "Favorite Resources",
		Categories: "Favorite Categories",
	}
	// SearchAllResources: string = "Search all resources";
	SearchAllResources: string = "View all resources across organisation";
	RequestBy: string = "Request named resources";
	SearchResources: string = "Search Resources..";
	SearchByNamed: string = "Search by named resource";
	ItemAvailability: string = `Allocated: [[availability]]%`;
	Teams: string = "Teams";
	Pools: string = "Pools";
	Categories: string = "Categories";
}