import { EntityType } from "../../Entities/EntityTypes";
import { produce } from "../helpers";
import { TPContext } from "../types";



export interface IEntityInfoCallout {
	Id: string,
	EntityType: EntityType,
	Target: string,
	Properties?: Record<string, string>
}

export interface IEntityCalloutContext {
	entityCalloutInfo: (IEntityInfoCallout & { Properties: Record<string, string> }) | null;
	readonly setEntityCalloutInfo: (info: IEntityInfoCallout | null) => void
}

export const entityCalloutContext: TPContext<IEntityCalloutContext> = (set, get, api) => {
	return {
		entityCalloutInfo: null,
		setEntityCalloutInfo: (info) => set(produce(store => {
			if(!info) {
				store.entityCalloutInfo = null
			} else {
				store.entityCalloutInfo = {...info, Properties: info.Properties || {}}
			}
		}))
	}
}