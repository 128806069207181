import { FunctionComponent, useState, useEffect } from 'react';
import { NormalPeoplePicker, IBasePickerSuggestionsProps, IPersonaProps, PeoplePickerItemSuggestion } from '@fluentui/react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { EventEx } from '../../../Utilities/EventEx';
import { LocalStorage } from '../../../Utilities/LocalStorage';
import { ArrayEx } from '../../../Utilities/ArrayEx';
import { Plugin } from '../../../Services/PluginInvoker';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import { language } from '../../../Services/LocalizationService';
import { PFTooltipHost, ETooltipContentType } from '../PFTooltipHost';
import { Timeout } from '../../../types/runtimeTypes';

interface IProps extends IDynamicEditorComponentProps {
    Item: string | Array<string>;
    Update: (value: string | Array<string>) => void;
    Key?: string;

    MultiSelect?: boolean;
    Filter?: string;
}

interface AadGroupItem extends IPersonaProps {
    Id: string;
    DisplayName: string;
    Description: string;
}

const AadGroupPicker: FunctionComponent<IProps> = props => {
    const [groups, setGroups] = useState<Array<AadGroupItem>>();
    const [selected, setSelected] = useState(new Array<AadGroupItem>());
    const [recentlyUsedIds, setRecentlyUsedIds] = useState<Array<string>>([]);

    useEffect(() => {
        let mounted = true;
        const fetchGroups = async () => {
            const groups = await Plugin.Invoke<Array<AadGroupItem>>(PluginIDs.GetAllAadGroups, props.Filter);

            const allItems = AadGroupsToItems(groups);
            if (props.Item) {
                let items: Array<AadGroupItem> = [];
                if (props.MultiSelect) {
                    items = allItems.filter(_ => (props.Item as Array<string>).some(i => i.toLowerCase() === _.Id.toLowerCase()));
                } else {
                    const item = allItems.find(_ => _.Id.toLowerCase() === (props.Item as string).toLowerCase());
                    items = item ? [item] : [];
                }

                let defaultSelected: Array<AadGroupItem>;
                if (items.length > 0) defaultSelected = items;

                if (mounted) {
                    if (props.Item) setSelected(defaultSelected);
                }
            }
            if (mounted) {
                setGroups(allItems);
                const recentResources = LocalStorage.get<Array<string>>('aadgrouppicker-recentlyused') || [];
                SetRecentlyUsedGroups(recentResources, allItems);
            }
        };
        fetchGroups();
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const AadGroupsToItems = (resources: Array<AadGroupItem>): Array<AadGroupItem> => {
        return resources.map(_ => {
            return {
                Id: _.Id,
                DisplayName: _.DisplayName,
                Description: _.Description,
                text: _.DisplayName,
                secondaryText: _.Description,
            } as AadGroupItem;
        });
    };

    const filterItemsByText = (filterText: string): AadGroupItem[] => {
        return groups.filter(
            item =>
                (item.DisplayName && item.DisplayName.search(new RegExp(filterText, 'i')) > -1) ||
                (item.Id && item.Id.search(new RegExp(filterText, 'i')) > -1) ||
                (item.Description && item.Description.search(new RegExp(filterText, 'i')) > -1),
        );
    };

    let _timer: Timeout;
    const onFilterChanged = (filterText: string, currentPersonas: AadGroupItem[], limitResults?: number): PromiseLike<AadGroupItem[]> => {
        const item = EventEx.delayAndReturn(
            _timer,
            () => {
                if (filterText) {
                    let filteredPersonas: AadGroupItem[] = filterItemsByText(filterText);

                    filteredPersonas = limitResults ? filteredPersonas.splice(0, limitResults) : filteredPersonas;
                    return filteredPersonas;
                } else {
                    return [];
                }
            },
            600,
        );
        _timer = item.timer;
        return item.result;
    };

    const getTextFromItem = (persona: AadGroupItem): string => {
        return persona.DisplayName as string;
    };

    const onItemsChange = (items: AadGroupItem[]): void => {
        setSelected(items);
        if (props.MultiSelect) props.Update(items.map(_ => _.Id));
        else props.Update(items.length === 0 ? null : items[0].Id);
    };

    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: language.AadGroupPicker.SuggestedGroup,
        mostRecentlyUsedHeaderText: language.AadGroupPicker.SuggestedGroup,
        noResultsFoundText: language.AadGroupPicker.NoResultsFound,
        loadingText: language.Common.LoadingDot,
        showRemoveButtons: false,
        suggestionsAvailableAlertText: language.AadGroupPicker.AadGroupPickerSuggestionsAvailable,
        suggestionsContainerAriaLabel: language.AadGroupPicker.Suggested,
    };

    const returnMostRecentlyUsed = (currentPersonas: AadGroupItem[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
        const displayIds = recentlyUsedIds.filter(_ => currentPersonas.findIndex(s => s.Id === _) < 0);
        const recent = groups.filter(_ => displayIds.findIndex(d => d === _.Id) >= 0);
        ArrayEx.sortByArray(recent, (item: AadGroupItem) => item.Id, displayIds.reverse());
        return recent;
    };

    const updateRecentlyUsed = (selectedItem?: AadGroupItem): AadGroupItem => {
        if (!selectedItem) return null;
        const recent = [...recentlyUsedIds];
        recent.push(selectedItem.Id);
        // restrict to 10 recently used resources
        if (recent.length > 10) recent.shift();
        SetRecentlyUsedGroups(recent, groups, true);
        return selectedItem;
    };

    const SetRecentlyUsedGroups = (recentIds: Array<string>, allResources: Array<AadGroupItem>, updateCache?: boolean): void => {
        setRecentlyUsedIds(recentIds);
        if (updateCache) LocalStorage.set('aadgrouppicker-recentlyused', recentIds);
    };

    const onRenderSuggestionsItem = (personaProps, suggestionsProps) => (
        <PFTooltipHost
            contentType={ETooltipContentType.List}
            content={[
                { displayName: 'Group', displayText: personaProps.text },
                { displayName: 'Description', displayText: personaProps.secondaryText },
                { displayName: 'Id', displayText: personaProps.Id },
            ]}
            listItemFlexDirection={'column'}
        >
            <PeoplePickerItemSuggestion personaProps={personaProps} suggestionsProps={suggestionsProps} styles={{ personaWrapper: { width: '100%' } }} />
        </PFTooltipHost>
    );

    return (
        <>
            <NormalPeoplePicker
                itemLimit={props.MultiSelect ? undefined : 1}
                onResolveSuggestions={onFilterChanged}
                onEmptyInputFocus={returnMostRecentlyUsed}
                onItemSelected={updateRecentlyUsed}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={suggestionProps}
                className={'ms-PeoplePicker'}
                onRenderSuggestionsItem={onRenderSuggestionsItem}
                pickerCalloutProps={{ calloutWidth: 0, calloutMaxWidth: 500 }}
                onChange={onItemsChange}
                selectedItems={selected}
                disabled={props.ReadOnly || !groups}
            />
        </>
    );
};

export default AadGroupPicker;
