import { useCallback } from 'react';
import { useStore } from '../../../context/store';
import { ResourceCapacityProjectDialog } from './ResourceCapacityProjectDialog';

export const useResourceCapacityProject = () => {
    const setBlockDialog = useStore(store => store.setBlockDialog);

    const addProject = useCallback(
        (confirmCallback: (projectId: string, projectName: string) => void) => {
            setBlockDialog(<ResourceCapacityProjectDialog selectCallback={confirmCallback} />, {
                title: 'Add project',
                subText: 'Choose a project to allocate yourself to',
                isBlocking: false,
                dismissEnable: false,
                styles: {
                    scrollableContent: {
                        overflowY: 'hidden',
                        height: '70vh',
                        width: '60vw',
                    },
                },
            });
        },
        [setBlockDialog],
    );

    return addProject;
};
