import { CSSProperties, useMemo } from 'react';
import { ROW_HEIGHT, ROW_EXPANDED_HEIGHT, BORDER } from '../../../CONSTANTS';
import { useRowType } from '../../../hooks/useRowType';
import { useGridRowContext } from '../../../../../../_grid/contexts/rowContext';
import { ProjectHeader } from './components/ProjectHeader/ProjectHeader';
import { ContractHeader } from './components/ContractHeader';
import { ResourceHeader } from './components/ResourceHeader/ResourceHeader';
import { useGridLayoutContext } from '../../../context/LayoutContext';
import { Icon } from '@fluentui/react';

export const RowHeader = ({ expanded, dropable }: { expanded: boolean; dropable?: boolean }) => {
    const { isProject, isResource } = useRowType();
    const { nestingLevel } = useGridRowContext();
    const { rowHeaderSize } = useGridLayoutContext();
    const style = useMemo(() => {
        return {
            //
            ...rowHeaderStyles,
            paddingLeft: 10 * nestingLevel + 10,
            ...(expanded && isProject && { height: ROW_EXPANDED_HEIGHT }),
            width: `${rowHeaderSize}px`,
        } as CSSProperties;
    }, [expanded, isProject, nestingLevel, rowHeaderSize]);

    const render = useMemo(() => {
        if (isProject) {
            return <ProjectHeader expanded={expanded} nestingLevel={nestingLevel} />;
        }
        if (isResource) {
            return <ResourceHeader expanded={expanded} nestingLevel={nestingLevel} />;
        }
        return <ContractHeader expanded={expanded} nestingLevel={nestingLevel} />;
    }, [expanded, isProject, nestingLevel, isResource]);
	
    return (
        <div style={style}>
            {render}
            {dropable && (
                <div className="tp-list-capacity-dropzone-overlay project-planner">
                    <span className="drop-text">
                        Drop here <Icon iconName="Download" />
                    </span>
                </div>
            )}
        </div>
    );
};

const rowHeaderStyles: CSSProperties = {
    backgroundColor: 'inherit',
    borderBottom: BORDER,
    borderRight: BORDER,
    boxSizing: 'border-box',
    zIndex: 1,
    height: ROW_HEIGHT,
    position: 'sticky',
    left: 0,
};



