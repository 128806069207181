import { atom, useRecoilState } from 'recoil';
import { SortOrder } from '../../../../../_grid/helpers/sortRowProperty';



export type CurrentSortedByColumnState = { id: string; order: SortOrder; isCustom: boolean; } | null;
export const currentSortedByColumn = atom<CurrentSortedByColumnState>({
	key: 'project-planner-column-sort',
	default: null,
});

export const useSortedByColumnState = () => useRecoilState(currentSortedByColumn);
