import React, { useContext } from "react";
import { CellRow } from "../../../Entities/Table/CellRow";
import TableCell from "./TableCell";
import { useDragCtx } from './DragCopy/dragContext';
import { TableContext } from "./Table";
import { TableStateManager } from "../../../Services/TableStateManager";
import { BaseRow } from "../../../Entities/Table/BaseRow";

interface IProps {
	CellRow: CellRow;
	Index: number;
	KeyPrefix: string;
	ParentRow: BaseRow;
}

const TableCellRow: React.FC<IProps> = props => {
	const dragCtx = useDragCtx();
	const ctx = useContext(TableContext) as TableStateManager;

	return (
		<td className={`tp-capacity-cell-container${props.CellRow.Properties.GrandTotal ? " tp-grid-grandtotal" : ""}`}>
			<div className="tp-capacity-cell-group">
				{props.CellRow.Cells.map((_, idx) => (
					<TableCell
						key={`${props.KeyPrefix}-cell-${idx}`}
						Cell={_}
						ColumnIndex={props.Index}
						Index={idx}
						ref={(n) => !_.Disabled && dragCtx.addInputNode(n, _.Id)}
					/>
				))}
				{ctx.Get(_ => _.CellRowExtraContent) ? ctx.Get(_ => _.CellRowExtraContent)(props.CellRow, props.ParentRow) : null}
			</div>
		</td>
	);
};

export default TableCellRow;
