export default class Locale_ProjectRowMenu { 
	GroupBy = {
		ContractDefined: 'Uses the grouping, that is defined on the contract',
		ResourceRBS: "Groups by the contract's resource's RBS",
		WorkPackage: "Groups by the contract's work package",
		OriginatedRequestTemplate: 'Groups by originated request template',
		OriginatedRequestTemplateRun: 'Groups by originated request template run',
		AlignStatus: "Align status"
	}
	CustomProperties: string = 'Custom Properties';
	ResourceCustomProperty: string = `Groups by custom property: '[[displayname]] of the contract's resource`
	GroupContractsText: string = 'Group contracts by';
	GroupContractsTitle: string = 'Groups the contracts by the selected property';
}