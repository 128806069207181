import { guid } from "../../../../helpers/guid";
import { PFPropertyBasicInfo } from "../../../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../../../Utilities/PropertyInfo/Render";

export class LookupValue {
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.Value", 1) // these decorators are only used for the string type so far
	public Value: any;
	@PFPropertyBasicInfo(PropertyType.StringCopyValue, "_localization:Common.Id", 2)
	@PFPropertyRender(null,null,null,null, obj => true)
	public Id: string;

	constructor(value?: any) {
		this.Id = guid.newGuid();
		this.Value = value;
	}
}

// test - ewi
export class LookupValueNumber {
	@PFPropertyBasicInfo(PropertyType.Number, "_localization:Common.Value", 1)
	public Value: any;
	@PFPropertyBasicInfo(PropertyType.StringCopyValue, "_localization:Common.Id", 2)
	@PFPropertyRender(null,null,null,null, obj => true)
	public Id: string;

	constructor(value?: any) {
		this.Id = guid.newGuid();
		this.Value = value;
	}
}