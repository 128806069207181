import React, { FunctionComponent, useState, useEffect } from 'react';
import { PFDialog } from '../../Common/PFDialog';
import { PFSpinner } from '../../Common/Spinner';
import { PFErrorBoundary } from '../../Common/PFErrorBoundary';
import { Plugin } from '../../../Services/PluginInvoker';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import { OverviewContext } from '../../../Utilities/Context/OverviewContext';
import { TimelineResolution } from '../../../Entities/TimelineResolution';
import { useTheme } from '../../../Themes/themeContext';
import { language } from '../../../Services/LocalizationService';
import { StringEx } from '../../../Utilities/StringEx';
import { Confirm } from '../../../Utilities/Confirm';
import { DefaultButton } from '@fluentui/react';
import { DistributionResult } from '../../../Entities/ActivityDistribution/DistributionResult';
import { ActivityDistributionDto } from '../../../Entities/ActivityDistribution/ActivityDistributionDto';
import { ActivityDistributionConfig } from '../../../Entities/ActivityDistribution/ActivityDistributionConfig';
import { DistributionResultComponent } from './FindAvailableCapacity/components/DistributionResult/DistributionResultComponent';

interface IProps {
    result: DistributionResult;
    dto: ActivityDistributionDto;
    resolution: TimelineResolution;
    dismissCallback: () => void;
    callback: () => void;
    show: boolean;
    changeSettings: (config: ActivityDistributionConfig) => void;
    redo?: () => void;
}

export const ActivityDistributionDialog: FunctionComponent<IProps> = props => {
    const theme = useTheme();

    const [result, setResult] = useState<DistributionResult>();
    const [dto, setDto] = useState<ActivityDistributionDto>();
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(props.show);

    // on show changed
    useEffect(() => setShowDialog(props.show), [props.show]);

    // on result changed
    useEffect(() => setResult(props.result), [props.result]);

    // on info changed
    useEffect(() => setDto(props.dto), [props.dto]);

    const Dismiss = () => {
        setShowDialog(false);
        props.dismissCallback();
    };

    const autoAllocate = async () => {
        setLoading(true);

        dto.OnlyRequests = false;
        dto.PerformDistribution = true;
        await Plugin.Invoke(PluginIDs.AutoCreateActivities, dto);

        const action = async () => {
            setLoading(false);
            props.callback();
            OverviewContext.RefreshProjectCapacity();
        };

        // show status dialog
        const usesScenario = !(StringEx.isNullOrWhiteSpace(props.dto.ScenarioId) && StringEx.isNullOrWhiteSpace(props.dto.ScenarioName));
        Confirm.Instance.Show({
            show: true,
            title: language.AutoAllocationDialog.Success,
            subtext: usesScenario ? language.AutoAllocationDialog.AddedVersionToSelectedScenario : language.AutoAllocationDialog.ContractUpdatedWithChanges,
            confirmAction: action,
            dismissAction: action,
            okButtonText: null,
            cancelButtonText: null,
        });
    };

    return (
        <PFDialog
            showDialog={showDialog}
            maxWidth={1000}
            minWidth={575}
            callback={autoAllocate}
            dismissCallback={Dismiss}
            buttonText={language.AutoAllocationDialog.AutoAllocate}
            okButtonEnabled={result?.Success}
            extraStyles={{
                root: {
                    '.ms-Dialog-inner': { paddingTop: '24px !important' },
                    '.ms-Dialog-header': { display: 'none' },
                },
            }}
            extraDialogFooterContent={<DefaultButton text={'Try again'} onClick={props.redo} />}
        >
            <div className="tp-autoallocate-dialog-content">
                {loading || result == null ? (
                    <PFSpinner CustomClass="justoverridedefault" />
                ) : (
                    <PFErrorBoundary ComponentName="AutoAllocationDialog" Reset={async () => Dismiss()}>
                        <>
                            <DistributionResultComponent
                                theme={theme}
                                result={result}
                                config={dto?.DistributionConfig}
                                changeSettings={settings => props.changeSettings(settings)}
                            />
                        </>
                    </PFErrorBoundary>
                )}
            </div>
        </PFDialog>
    );
};
