import { ReactElement, useCallback, useState } from 'react';
import { TextField } from '@fluentui/react';
import React from 'react';
import { IFieldRenderProps, IFormItem, ITextFieldConfig, useFormContext, useValidation, useWatch } from 'spark-forms';
import { JsonEx } from '../../../../Utilities/JsonEx';

export interface IFluentTextFieldProps<T extends IFormItem> extends IFieldRenderProps<T, ITextFieldConfig<T>, string | object | undefined> {
    config?: ITextFieldConfig<T>;
}

export const JsonField = <T extends IFormItem>({ value, fieldName, config, disabled, path }: IFluentTextFieldProps<T>): ReactElement | null => {
    const [localValue, setLocalValue] = useState<string | undefined>(JsonEx.tryStringify(value));
    const [localError, setLocalError] = useState<string>();

    const { onChange: onChangeForm } = useFormContext();
    const { validate, validationMessage } = useValidation(path);

    useWatch(path, () => setLocalValue(JsonEx.tryStringify(value)), [value]);

    const onChange = useCallback(
        (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) => {
            const jsonObj = JsonEx.tryParse<any>(val);
            setLocalValue(val);
            onChangeForm(fieldName, jsonObj);
        },
        [onChangeForm, setLocalValue, fieldName],
    );

    const onBlur = useCallback(async () => {
        await validate();
        const jsonParseError = isJsonString(localValue) ? undefined : 'Not correct json syntax';
        setLocalError(jsonParseError);
    }, [localValue, validate]);

    return (
        <TextField
            // key={path}
            value={(localValue ?? '') as string}
            onChange={onChange}
            disabled={disabled}
            type={'text'}
            multiline={config?.multiline ?? true}
            resizable={config?.resizable ?? true}
            onBlur={onBlur}
            errorMessage={localError ?? validationMessage}
            canRevealPassword
            placeholder={config?.placeHolder}
            {...config?.componentProps}
        />
    );
};

const isJsonString = str => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};
