import { PFPropertyBasicInfo } from '../../Utilities/PropertyInfo/BasicInfo';
import { PropertyType } from '../../Utilities/PropertyInfo/PropertyType';
import { PFPropertyGrouping } from '../../Utilities/PropertyInfo/Grouping';

export class CustomThemeProps {
	@PFPropertyBasicInfo(PropertyType.Color, null, 1)
	@PFPropertyGrouping("Custom settings", 1, true)
	public activityOverBackground = "#FFEAD4";
	@PFPropertyBasicInfo(PropertyType.Color, null, 2)
	@PFPropertyGrouping("Custom settings")
	public activityEqualBackground = "#E5F2E5";
	@PFPropertyBasicInfo(PropertyType.Color, null, 3)
	@PFPropertyGrouping("Custom settings")
	public activityEqualContainsCategoryBackground = "#f8edff";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public activityZeroBackground = "#E5F2E5";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public activityUnderBackground = "#FFE5E5";
	// Capacity cell backgrounds
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public capacityOverBackground = "#FFEAD4";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public capacityEqualBackground = "#E5F2E5";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public capacityUnderBackground =  "#ECF4FF";
	// Overlay / navigation buttons
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public overlayButtonBackground = "hsl(15, 3%, 23%)"//; darkColors.bg;
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public overlayButtonBackgroundPressed = "hsl(20, 3%7%)"; //darkColors.bgExpandedHovered;
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public overlayButtonText = "#f4f4f6"; //palette.neutralLighter;
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public searchHighligtColor = "#ffff00";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public tableBorderColorVertical = "#ebeeef";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public tableBorderColorHorizontal = "#ebeeef";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public tableDividerColor = "#c7ced1";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public tableDividerShadow = "1px 0px 3px #c7ced1";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public kpiInfinite = "#000000";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public kpiFree = "#ff0000";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public kpiOver = "#ffa500";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public kpiUsed = "#008000";
	@PFPropertyBasicInfo(PropertyType.Color, null)
	@PFPropertyGrouping("Custom settings")
	public kpiCategory = "#a62af5"
}