import { Origin } from "../../../../../api/generated/data-contracts";
import { settings } from "../../../../../context/network/http/QueryProvider/queries/UISettings";
import { guid } from "../../../../../helpers/guid";

export const getOrigins = (ids: string[]) : Origin[] => {
	if (!ids?.length) return [];
	return settings().origins.filter(_ => ids.some(i => i === _.id));
}

export const getAppOrigin = () : Origin => {
	return settings().origins.find(_ => _.id === guid.empty);
}
