
import { PropertyType } from "../Utilities/PropertyInfo/PropertyType";
import { PFPropertyBasicInfo } from "../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyRender } from "../Utilities/PropertyInfo/Render";
import { Resource } from "./Main/Resource";
import { PFPropertyFilter } from "../Utilities/PropertyInfo/PropertyFilter";

export class ResourcePickerFields {
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:PickerFields.ItemTitle", 1)
	@PFPropertyRender(null, { EntityType: Resource, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
	@PFPropertyFilter({FilterLevel: 2})
	public Title: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:PickerFields.ItemSecondaryText", 2)
	@PFPropertyRender(null, { EntityType: Resource, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
	@PFPropertyFilter({FilterLevel: 2})
	public Secondary: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:PickerFields.ItemTertiaryText", 3)
	@PFPropertyRender(null, { EntityType: Resource, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
	@PFPropertyFilter({FilterLevel: 2})
	public Tertiary: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:PickerFields.ItemIconText", 6)
	@PFPropertyRender(null, { EntityType: Resource, PropertyTypes: "Both", FilterSystemProperties: true, FilterArrayProperties: true }, "EntityPropertySelector")
	@PFPropertyFilter({FilterLevel: 2})
	public IconOverride: string;
}