import { BaseRow } from "../../../Entities/Table/BaseRow";
import { HeaderRow } from "../../../Entities/Table/HeaderRow";

export class TableCustomColumn {
	public Id: string;
	public Header: HeaderRow;
	public TopInfo: TableCustomColumnInfo;
	public ContainerInfo: TableCustomColumnInfo;
	public GroupInfo: TableCustomColumnInfo;
	public RowInfo: TableCustomColumnInfo;
}

export class TableCustomColumnInfo {
	constructor(public ValueGetter: (row: BaseRow) => number, public Disabled: boolean, public Properties?: (row: BaseRow) => { [key: string]: any }) { }
}