import { DndContext } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { SortableTreeNode } from './SortableTreeNode';
import { ITreeNode, ITreeProps, Tree, TreeContextProps, getFlattenedTree } from '@pum-spark/tree-render';
import { objectUtil } from '../../forms/helpers/object';
import { guid } from '../../helpers/guid';
import { ScrollablePane, Stack, TextField } from '@fluentui/react';
import { FilterContextProvider } from './FilterTextContext';
import { useTheme } from '../../Themes/themeContext';
import { useDebouncedEventHandler } from '../../hooks/useDebouncedHandler';

const ChildContainer = ({ children }) => <div style={{ marginBottom: '0.2rem', paddingLeft: children?.length ? '1.5rem' : '3.5rem' }}>{children}</div>;

const CustomTreeContainer = ({ moveNode, treeData, children }: PropsWithChildren<TreeContextProps>) => {
    const flattenedItems = useMemo(() => {
        const flattenedTree = getFlattenedTree(treeData);
        return flattenedTree;
    }, [treeData]);

    const handleDragEnd = useCallback(
        event => {
            const { active, over } = event;

            if (!active || !over) return;

            const draggableId = active.id;
            const droppableId = over.id;

            if (!draggableId || !droppableId) return;

            moveNode(draggableId, droppableId);
        },
        [moveNode],
    );

    return (
        <DndContext onDragEnd={handleDragEnd}>
            <SortableContext items={flattenedItems.map(({ id }) => id)} strategy={verticalListSortingStrategy}>
                {children}
            </SortableContext>
        </DndContext>
    );
};

type FlatTreeNode = Omit<ITreeNode, 'children'> & { parentId: string };

const nodeToFlatNode = ({ id, name, path }: ITreeNode, parentId: string | null = null): FlatTreeNode => {
    return {
        id,
        name,
        path,
        parentId,
    };
};

const getChildNodes = (currentNodeId: string, nodes: FlatTreeNode[]) => nodes.filter(node => node.parentId === currentNodeId);

const flatNodeToNode = ({ id, name, path }: FlatTreeNode, data: FlatTreeNode[]): ITreeNode => {
    return {
        id,
        name,
        path,
        children: getChildNodes(id, data).map(node => flatNodeToNode(node, data)),
    };
};

const flattenNodes = (data: ITreeNode) => {
    const nodes: FlatTreeNode[] = [];
    const recurse = (node: ITreeNode) => {
        node.children?.forEach(childNode => {
            nodes.push(nodeToFlatNode(childNode, node.id));
            recurse(childNode);
        });
    };
    recurse(data);
    return nodes;
};

const getNodeAncesters = (nodeId: string, data: FlatTreeNode[]) => {
    const nodes: FlatTreeNode[] = [];
    let currentNode: FlatTreeNode = data.find(node => node.id === nodeId);
    while (currentNode) {
        currentNode = data.find(node => node.id === currentNode.parentId);
        if (currentNode) {
            nodes.push(currentNode);
        }
    }
    return nodes;
};

export const SortableTreeView = ({
    data,
    mode,
    ignoreRootNode,
    defaultSelectedNodes,
    expandToLevel,
    expandToSelected,
    onDataChange,
    onSelectionChange,
    pathDelimiter,
    sortNodes,
    disableOptions,
}: Omit<ITreeProps, 'nodeComponent' | 'childContainer' | 'treeContainer'>) => {
    const [filterText, setFilterText] = useState('');
    const [filterTextDebounced, setFilterTextDebounced] = useState('');
    const [dataClone] = useState(() => objectUtil.deepClone(data));

    const rootNode = useMemo(() => {
        if (!filterTextDebounced) {
            return dataClone;
        }
        const flatNodes = flattenNodes(dataClone);
        const filteredNodes = flatNodes.filter(node => node.name.toLowerCase().includes(filterTextDebounced.toLowerCase().trim()));
        // Rebuild hierarchy THIS WILL CONTAIN DUBLICATES
        const filteredRowsWithFamilies = filteredNodes.flatMap(node => [
            //
            ...getNodeAncesters(node.id, flatNodes),
            node,
            ...getChildNodes(node.id, flatNodes),
        ]);
        // Filter out dublicates
        const cleanRows = [...new Set(filteredRowsWithFamilies)];
        return flatNodeToNode({ id: guid.empty, name: 'Root', parentId: null, path: null }, cleanRows);
    }, [dataClone, filterTextDebounced]);

    const onFilterTextChange = useDebouncedEventHandler(
        (event: any, newValue?: string) => {
			setFilterTextDebounced(String(newValue));
		},
        (event, newValue) => {
			setFilterText(String(newValue));
		},
		500
    );

    const theme = useTheme();

    return (
        <FilterContextProvider value={filterTextDebounced}>
            <div style={{ height: '80vh', width: '50vw', maxWidth: 800, position: 'relative' }}>
                <TextField
                    styles={{ root: { maxWidth: 400, backgroundColor: theme.semanticColors.bodyBackground, position: 'relative' } }}
                    value={filterText}
                    onChange={onFilterTextChange}
                    placeholder="Filter.."
                />
                <div style={{ position: 'relative', height: '100%' }}>
                    <ScrollablePane>
                        <Stack styles={{ root: { paddingTop: 10 } }}>
                            <Tree
                                // We force a remount because the internal state of <Tree /> is heavily memoized.
                                // This is a very unoptimized solution
                                key={filterTextDebounced || '0'}
                                data={rootNode}
                                mode={mode}
                                nodeComponent={SortableTreeNode}
                                childContainer={ChildContainer}
                                treeContainer={CustomTreeContainer}
                                ignoreRootNode={ignoreRootNode}
                                defaultSelectedNodes={defaultSelectedNodes}
                                expandToSelected={expandToSelected}
                                // Expand the tree to let users see the result of filtering
                                expandToLevel={filterText ? Infinity : expandToLevel}
                                onDataChange={onDataChange}
                                onSelectionChange={onSelectionChange}
                                pathDelimiter={pathDelimiter}
                                sortNodes={sortNodes}
                                disableOptions={disableOptions}
                            />
                        </Stack>
                    </ScrollablePane>
                </div>
            </div>
        </FilterContextProvider>
    );
};
