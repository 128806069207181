import { IFormSchema, IFormItemFieldOptions } from "spark-forms";
import { customPropertyUtil } from "../forms/customProperty/helpers/customPropertyUtil";
import { getDisallowedCustomProperties } from "../forms/_builders/new/helpers/origins/getDisallowedCustomProperties";
import { customPropertyParser } from "./customPropertyParser";
import { CustomProperty } from "../api/generated/data-contracts";

export const getcustomPropertiesSchema = async (type: 'resource' | 'project' | 'contract', forceDisabledState: (cp: CustomProperty) => boolean = null) => {

	const customProperties = customPropertyUtil.customProperties(type);
	const disabledProperties = getDisallowedCustomProperties(type);
	// TODO lock genres setting
	
	const formSchema = { fields: {}, groups: {} } as IFormSchema<any>;
	for (const cp of customProperties) {
		const mapping = await customPropertyParser(cp);
		if (mapping == null) continue;
		const disabled = forceDisabledState?.(cp) ?? disabledProperties.indexOf(cp.id) >= 0;

		const groupKey = cp.group?.replace(' ', '');
		if (cp.group) {
			formSchema.groups[groupKey] = { displayName: cp.group };
		}
		const fieldOptions = {
			displayName: cp.displayName,
			fieldType: mapping.key as any,
			config: mapping.config,
			group: groupKey,
			disable: () => disabled,
			validation: {
				validationMark: cp.required ? 'required' : undefined,
				validationRule: async item => !disabled && cp.required && item?.[cp.id] == null ? 'Required' : null
			}
		} as IFormItemFieldOptions<any, any>;
		formSchema.fields[cp.id] = fieldOptions;
	}
	return formSchema;
}
