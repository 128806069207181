import { memo, useRef, useMemo } from "react";
import { useForm } from "spark-forms";
import { FluentLabel, NavIconGroupingContainer, NavIconGrouping } from "spark-forms-fluent8";
import { Project } from "../../../../../api/generated/data-contracts";
import { projectSchema } from "../../../../../forms/project/projectSchema";
import { originHelper } from "../../../../../forms/_builders/new/helpers/origins/originHelper";
import { TPBuilder } from "../../../../../forms/_builders/new/TPBuilder";
import { formUnrestricted } from "../../../../../Utilities/DebugOverrides";
import { useOverviewContext } from "../../../../../_components/OverviewCreator/context/OverviewCreatorContext";
import { useGetAdditionalOwnerRestrictions } from "../../../../../hooks/useGetRestrictedProperties";

export const ProjectForm = memo(() => {
	const { item, setItem, setValidation } = useOverviewContext<Project>();
	const localItem = useRef(item).current;
	const { restrictedProperties } = useGetAdditionalOwnerRestrictions(localItem);
	
	const builderOptions = useMemo(() => ({
		labelComponent: FluentLabel
	}), []);

	const overrides = useMemo(() => {
		return formUnrestricted<Project>({ disabledProps: [...restrictedProperties, ...originHelper.getDisallowedProjectFields()] });
	}, [restrictedProperties])
	
	const schema = useMemo(() => projectSchema, [])
	
    const { Form, validateItem } = useForm({
		item: localItem,
		onChange: setItem,
		schema,
		builder: TPBuilder,
		builderOptions,
		groupingContainer: NavIconGroupingContainer,
		grouping: NavIconGrouping,
		fieldOverrides: overrides
	});

	setValidation(validateItem);

    return <Form />
});
