export default class Locale_ProjectDraftDialog {
	Drafts: string = "Drafts";
	SelectDraftToWorkWith: string = "Select a draft to work with";
	CreateNewDraft: string = "Create a new draft";
	NewDraftName: string = "New draft name";
	OrCreateNewDraft: string = "Or create a new draft";
	OpenDraft: string = "Open draft";

	AreYouSureYouWantToDelete: string = "Are you sure you want to DELETE the entire draft? This cannot be undone!";
	AreYouSureYouWantToPropose: string = "Are you sure you want to make the draft available for approval? This cannot be undone!";
	ReadyForApproval: string = "Ready for approval";
	DeleteDraft: string = "Delete draft";
}