/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo } from 'react';
import { DatePicker, DayOfWeek, FirstWeekOfYear } from '@fluentui/react';
import { DynamicProps } from './DynamicProps';
import { language } from '../../../../Services/LocalizationService';

interface IProps extends DynamicProps<Date, Date> {
    FirstDayOfWeek?: DayOfWeek;
    FirstWeekOfYear?: FirstWeekOfYear;
    DisplayFormat?: (date: Date) => string;
    MinDate?: (parent: any) => Date;
    MaxDate?: (parent: any) => Date;
    RestrictedDates?: (parent: any) => Array<Date>;
}

const DynamicDatePicker: FunctionComponent<IProps> = props => {
    return (
        <div key={props.Key}>
            <DatePicker
                placeholder={props.ReadOnly ? '' : language.DynamicComponents.SelectADate}
                firstDayOfWeek={props.FirstDayOfWeek == null ? DayOfWeek.Monday : props.FirstDayOfWeek}
                showWeekNumbers={true}
                firstWeekOfYear={props.FirstWeekOfYear == null ? props.FirstWeekOfYear : FirstWeekOfYear.FirstFourDayWeek}
                showMonthPickerAsOverlay={true}
                isRequired={false}
                allowTextInput={true}
                formatDate={props.DisplayFormat}
                value={props.Value != null && (props.Value as any) !== '0001-01-01T00:00:00' ? new Date(props.Value) : null}
                onSelectDate={date => props.OnChange(props.PropertyInfo.PropertyName, date)}
                disabled={props.ReadOnly}
                minDate={props.MinDate ? props.MinDate(props.Parent) : null}
                maxDate={props.MaxDate ? props.MaxDate(props.Parent) : null}
                calendarProps={{ showGoToToday: true, strings: null, restrictedDates: props.RestrictedDates ? props.RestrictedDates(props.Parent) : null }}
            />
        </div>
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Value === nextProps.Value;
};

export default memo(DynamicDatePicker, comparisonFn);
