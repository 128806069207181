import { CSSProperties, useCallback, useMemo } from 'react';
import { useGridRowContext } from '../../../../../../../../_grid/contexts/rowContext';
import {
    AnimationClassNames,
    CommandBarButton,
    IButtonStyles,
    IContextualMenuProps,
    IIconProps,
    ILayerProps,
    IStackProps,
    Icon,
    Stack,
    TooltipHost,
} from '@fluentui/react';
import { ProjectCoinIcon } from './components/ProjectCoinIcon';
import { useNavigate } from 'react-router';
import { buildRoute } from '../../../../../../../../../../helpers/routeHelpers';
import { ResourceType } from '../../../../../../../../../../api/generated/data-contracts';
import { useTheme } from '../../../../../../../../../../Themes/themeContext';

export const ProjectHeader = ({ expanded, nestingLevel }: { expanded: boolean; nestingLevel: number }) => {
    const { row, canExpand, toggleExpanded, subRows } = useGridRowContext();
    const navigate = useNavigate();
    const handleGotoEntityClick = useCallback(() => {
        navigate(buildRoute('allocation', row.identity?.id));
    }, [navigate, row.identity?.id]);

    const includesCategoryResource = useMemo(() => subRows.length && subRows.some(row => row.metadata?.ResourceType === ResourceType.Category), [subRows]);
    const menuProps = useMemo((): IContextualMenuProps => {
        return {
            items: [
                {
                    key: 'project-link',
                    text: 'Go to Project',
                    iconProps: { iconName: 'MiniExpand' },
                    onClick: handleGotoEntityClick,
                },
            ],
            calloutProps: {
                dismissOnTargetClick: true,
                layerProps: fadeInCalloutLayerStyles,
            },
        };
    }, [handleGotoEntityClick]);

    const titleContainer = useMemo(
        () => ({
            paddingLeft: nestingLevel * 10,
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            transition: 'background-color .3s',
            gap: 8,
        }),
        [nestingLevel],
    );
    const toggleRowExpanded = useCallback(() => toggleExpanded(), [toggleExpanded]);
    const theme = useTheme();
    const categoryResourceIconStyles = useMemo(
        (): IIconProps['styles'] => ({ root: { color: theme.semanticColors.kpiCategory, fontSize: 16, cursor: 'help' } }),
        [theme.semanticColors.kpiCategory],
    );
    return (
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" styles={containerStyles}>
            <div style={titleContainer}>
                {canExpand && <Icon styles={expandIconStyles} iconName={expanded ? 'ChevronDown' : 'ChevronRight'} onClick={toggleRowExpanded} />}
                <div style={coinContainerStyles} onDoubleClick={handleGotoEntityClick}>
                    <ProjectCoinIcon row={row} />
                </div>
            </div>
            <div>
                {includesCategoryResource && (
                    <TooltipHost content="Category Resource is allocated to this project">
                        <Icon iconName="Transition" styles={categoryResourceIconStyles} />
                    </TooltipHost>
                )}
                <CommandBarButton menuProps={menuProps} menuIconProps={{ iconName: 'More' }} styles={moreButtonStyles} />
            </div>
        </Stack>
    );
};
const fadeInCalloutLayerStyles: ILayerProps = {
    styles: {
        content: {
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
        },
    },
    className: AnimationClassNames.fadeIn100,
};
const moreButtonStyles: IButtonStyles = {
    flexContainer: { height: 24 },
    menuIcon: { fontSize: 16 },
    root: { backgroundColor: 'transparent' },
};
const containerStyles: IStackProps['styles'] = { root: { height: '100%', overflow: 'hidden' } };
const coinContainerStyles: CSSProperties = {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
};
const expandIconStyles: IIconProps['styles'] = { root: { transform: 'scale(0.7)', cursor: 'pointer' } };
