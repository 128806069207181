import 'reflect-metadata';
import { PropertyBasicInfo } from "../Utilities/PropertyInfo/BasicInfo";
import { PropertyInfo } from '../Utilities/PropertyInfo/PropertyInfo';
import { PropertyGrouping } from '../Utilities/PropertyInfo/Grouping';
import { PropertyInfoAreas } from '../Utilities/PropertyInfo/PropertyInfoAreas';
import { IPFPropertyLocalizationService } from './IPFPropertyLocalizationService';
import { PropertyFilter } from '../Utilities/PropertyInfo/PropertyFilter';

export class PropertyInfoService {

	public static UseLocalization = (localizationService: IPFPropertyLocalizationService) => PropertyInfoService.LocalizationService = localizationService;
	private static LocalizationService: IPFPropertyLocalizationService;

	public static GetPFProperties = <T>(type: { new(): T ;}, filterSystemProps?: boolean, hiddenProps: Array<string> = [], filterLevel: number = 0) : Array<PropertyInfo> => {
		const defaultDisabledProps = [ "id", "Created", "Modified", "CreatedBy", "ModifiedBy", "TypeName" ];

		const metadata = Reflect.getMetadata(PropertyBasicInfo.PropertyInfoKey, type);
		const filterInfo = Reflect.getMetadata(PropertyFilter.PropertyInfoKey, type);
		return Object.keys(metadata)
						.filter(_ => !(hiddenProps.indexOf(_) >= 0 || (filterSystemProps && defaultDisabledProps.indexOf(_) > 0) )
								&& (filterLevel === 0 || filterInfo == null || filterInfo[_]?.Options.FilterLevel == null || filterInfo[_]?.Options.FilterLevel >= filterLevel))
							.map(_ => PropertyInfoService.CreatePropertyInfo(type, _));
	}

	public static CreatePropertyInfo = <T>(type: { new(): T ;}, propertyName: string) => {
		const metadataKeys = Reflect.getMetadataKeys(type);

		const basicInfo = PropertyInfoService.GetSpecificMetadata(metadataKeys, PropertyInfoAreas.BasicInfo, type, propertyName);
		const grouping = PropertyInfoService.GetSpecificMetadata(metadataKeys, PropertyInfoAreas.Grouping, type, propertyName);
		const render = PropertyInfoService.GetSpecificMetadata(metadataKeys, PropertyInfoAreas.Render, type, propertyName);
		const validation = PropertyInfoService.GetSpecificMetadata(metadataKeys, PropertyInfoAreas.Validation, type, propertyName);
		const changes = PropertyInfoService.GetSpecificMetadata(metadataKeys, PropertyInfoAreas.Change, type, propertyName);

		const propertyInfo = new PropertyInfo(
			basicInfo.Type, 
			basicInfo.PropertyName, 
			PropertyInfoService.LocalizationService != null ? PropertyInfoService.LocalizationService.GetByKey(basicInfo.DisplayName) : basicInfo.DisplayName, 
			basicInfo.Order, 
			grouping.Name ? new PropertyGrouping(PropertyInfoService.LocalizationService.GetByKey(grouping.Name), grouping.Order, grouping.DefaultCollapsed): null,
			render.ListColumnRender,
			render.CustomComponentName,
			render.ComponentProps,
			render.Hide,
			render.Disable,
			validation.ValidationRule,
			changes.Action,
			PropertyInfoService.LocalizationService.GetByKey(basicInfo.TooltipText),
			render.ListColumnWidth
		);

		return propertyInfo;
	}

	private static GetSpecificMetadata = (allMetadataKeys: Array<string>, metadataKey: string, type: { new() ;}, property: string) => {
		if (allMetadataKeys.indexOf(metadataKey) < 0) return {};
		const metadata = Reflect.getMetadata(metadataKey, type);
		if (!metadata) return {};
		return metadata[property] || {};
	}
}