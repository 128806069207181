/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo, useEffect, useState } from 'react';
import { Dropdown, DropdownMenuItemType, IconButton } from '@fluentui/react';
import { PropertyInfoService } from '../../Services/PropertyInfoService';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { DropdownOptionEx } from '../../Utilities/DropdownOptionEx';
import { DropdownOption } from '../../Entities/Fabric/DropdownOption';
import { Resource } from '../../Entities/Main/Resource';
import { Project } from '../../Entities/Main/Project';
import { CustomProperty } from '../../Entities/ContentConfiguration/CustomProperty';
import { QueryExpression } from '../../Entities/DynamicQueryBuilder/QueryExpression';
import { QueryGroup } from '../../Entities/DynamicQueryBuilder/QueryGroup';
import { PropertyInfo } from '../../Utilities/PropertyInfo/PropertyInfo';
import { QueryCondition } from '../../Entities/DynamicQueryBuilder/QueryCondition';
import EnumEx from '../../Utilities/EnumEx';
import { IDs } from '../../Utilities/IDs';
import { EditorHandler } from '../../Utilities/EditorHandler';
import { QueryOperator } from '../../Entities/DynamicQueryBuilder/QueryOperator';
import CustomPropertyRender from '../../Utilities/CustomPropertyRender';
import { language } from '../../Services/LocalizationService';
import { PropertyType } from '../../Utilities/PropertyInfo/PropertyType';

interface IProps {
    Item: QueryGroup;
    EntityType: { new (): any };
    Update: (group: QueryGroup) => void;
    PropertyTypes: 'Fields' | 'Properties' | 'Both';
    FilterSystemProperties?: boolean;
    FilterArrayProperties?: boolean;
	ForcedIncludedFields?: string[];
    HiddenProperties?: Array<string>;
    RemovePrefixFromProperties?: boolean;
    Readonly?: boolean;
}

const EntityPropertyFilter: FunctionComponent<IProps> = props => {
    const [fields, setFields] = useState<Array<PropertyInfo>>();
    const [properties, setProperties] = useState<Array<CustomProperty>>();
    const [propertyOptions, setPropertyOptions] = useState<Array<DropdownOption>>();
    const [root, setRoot] = useState<QueryGroup>(props.Item || new QueryGroup());

    useEffect(() => {
		const itemProperties = getItemProperties(PropertyInfoService.GetPFProperties(props.EntityType, false), props.FilterSystemProperties, props.FilterArrayProperties, props.HiddenProperties, props.ForcedIncludedFields);

        let additionalProps: Array<CustomProperty>;
        switch (props.EntityType) {
            case Resource:
                additionalProps = OverviewContext.Settings.ResourceCustomProperties;
                break;
            case Project:
                additionalProps = OverviewContext.Settings.ProjectCustomProperties;
                break;
            default:
                additionalProps = [];
                break;
        }

        const fieldHeader = [{ key: 'fieldsHeader', text: language.Common.Fields, itemType: DropdownMenuItemType.Header } as DropdownOption];
        const propertiesHeader = [{ key: 'propertiesHeader', text: language.Common.Properties, itemType: DropdownMenuItemType.Header } as DropdownOption];

        const fields = DropdownOptionEx.toDropdownOptions(
            itemProperties,
            _ => _.PropertyName,
            _ => _.DisplayName,
        );
        const properties = DropdownOptionEx.toDropdownOptions(
            additionalProps,
            _ => `CustomProperties|${_.Id}|`,
            _ => _.DisplayName,
        );

        switch (props.PropertyTypes) {
            case 'Fields':
                setPropertyOptions(fieldHeader.concat(fields));
                break;
            case 'Properties':
                setPropertyOptions(propertiesHeader.concat(properties));
                break;
            case 'Both':
                setPropertyOptions(fieldHeader.concat(fields).concat(propertiesHeader).concat(properties));
                break;
            default:
                break;
        }

        setFields(itemProperties);
        setProperties(additionalProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Update = (): void => {
        setRoot({ ...root });
        props.Update({ ...root });
    };

    const Clear = (): void => {
        setRoot(new QueryGroup());
        props.Update(new QueryGroup());
    };

    const RenderGroup = (group: QueryGroup, parent?: QueryGroup, index?: number): JSX.Element => {
        if (!group.Parts) group.Parts = new Array<any>();
        const parts = group.Parts.map((_, i) => {
            if (_.TypeName === 'QueryExpression') return RenderExpression(_, i, group);
            else if (_.TypeName === 'QueryGroup') return RenderGroup(_, group, i);
            else return RenderCondition(_, group, i);
        });

        return (
            <div className="querygroup" key={`group-${index}`}>
                {parts}
                <div style={{ margin: '5px' }}>
                    {RenderMenu(group)}
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        disabled={props.Readonly}
                        style={{ fontSize: '20px' }}
                        onClick={e => RemoveGroup(parent, index)}
                        ariaLabel={language.EntityPropertyFilter.Delete.Grouping}
                        title={language.EntityPropertyFilter.Delete.Grouping}
                    />
                </div>
            </div>
        );
    };

    const GetProperty = (key: string): PropertyInfo | CustomProperty => {
        const fieldItem = fields.find(_ => _.PropertyName === key);
        const propertyItem = properties.find(_ => `CustomProperties|${_.Id}|` === key);
        if (fieldItem) return fieldItem;
        if (propertyItem) return propertyItem;
        return null;
    };

    const RenderCondition = (condition: QueryCondition, group: QueryGroup, index: number): JSX.Element => {
        return (
            <div key={IDs.makeId()}>
                <Dropdown
                    placeholder={language.Common.Condition}
                    className="querycondition"
                    selectedKey={condition}
                    options={EnumEx.allAsNumber<QueryCondition>(QueryCondition).map(e => {
                        return { key: e as number, text: QueryCondition[e].toString() };
                    })}
                    onChange={(e, o) => {
                        group.Parts[index] = QueryCondition[o.text];
                        Update();
                    }}
                />
            </div>
        );
    };

    const RemoveGroup = (parent: QueryGroup, index: number) => {
        if (parent == null) return;
        // if removing last part, remove pre operator
        if (index === parent.Parts.length - 1) index--;
        // remove expression and related operator
        if (parent) {
            parent.Parts.splice(index, 2);
            Update();
        } else Clear();
    };

    const RemoveExpression = (group: QueryGroup, index: number) => {
        // if removing last part, remove pre operator
        if (index === group.Parts.length - 1) index--;
        // remove expression and related operator
        if (group) {
            group.Parts.splice(index, 2);
            Update();
        } else Clear();
    };

    const GetDefaultSelectedKey = (expr: QueryExpression, isField: boolean): string => {
        if (expr.Property) {
            if (isField) return expr.Property;
            else {
                const match = expr.Property.match(/CustomProperties\|(.*?)\|/);
                if (match.length) return match[0];
            }
        }
        return null;
    };

    const RenderExpression = (expr: QueryExpression, idx: number, group: QueryGroup): JSX.Element => {
        const prop = GetProperty(expr.Property);
        const isField = prop ? Object.hasOwn(prop, 'PropertyName') : false;

        const disableOperator = (op: number) => {
            return (
                (!isField && prop && (prop as CustomProperty).Lookup && op > 1) ||
                (isField && (prop as PropertyInfo).PropertyName === 'RBS' && op > 1 && op < 5) ||
				(isField && (prop as PropertyInfo).PropertyName === "UserTypes" && op !== 4) ||
				(isField && (prop as PropertyInfo).PropertyName === "AdditionalOwners" && op !== 4)
            );
            // TODO: what about resource, rbs, etc.
            // they should also disable all operators but Equals and NotEquals
        };
        const operators = EnumEx.allAsNumber<QueryOperator>(QueryOperator).map(e => {
            return { key: e as number, text: QueryOperator[e].toString(), disabled: disableOperator(e as number) };
        });

        // hard coded quick fix due to the new rendering of active state on projects and resources (TODO: different) (ewi)
        if (isField && ((prop as PropertyInfo).PropertyName === 'Active' || (prop as PropertyInfo).PropertyName === 'IsActive')) {
            prop.Type = PropertyType.Boolean;
        }

        // date fields should have the ability to select "today" template for dynamic filtering
        if (prop?.Type === PropertyType.Date) {
            prop.Type = PropertyType.Object;
            prop.CustomComponentName = 'DatePickerWithTodayFilter';
        }
		
		// resource fields should have the ability to select "me" template for dynamic filtering
		if (isField && ((prop as PropertyInfo).CustomComponentName === 'ResourcePicker')) {
			prop.Type = PropertyType.Object;
			(prop as PropertyInfo).CustomComponentName = 'ResourcePickerWithMeFilter';
		}

		// usertypes and additionalowners should be a dropdown, but not a multiselect. The component prop is overridden in the dropdown, so the best way for this old crap is to override the type
		if (isField && ((prop as PropertyInfo)?.Type === PropertyType.PredefinedArrayMulti && ((prop as PropertyInfo).PropertyName === "UserTypes") || (prop as PropertyInfo).PropertyName === "AdditionalOwners")) {
			(prop as PropertyInfo).Type = PropertyType.PredefinedArray;
        }

        return (
            <div className="queryexpression" key={`filter-expr-${idx}`}>
                <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    style={{ fontSize: '20px', width: 'unset !important', display: `${props.Readonly ? 'none' : 'unset'}` }}
                    onClick={e => RemoveExpression(group, idx)}
                    ariaLabel={language.EntityPropertyFilter.Delete.Expression}
                    title={language.EntityPropertyFilter.Delete.Expression}
                />
                <Dropdown
                    disabled={props.Readonly}
                    placeholder={language.EntityPropertyFilter.SelectPropertyPlaceholder}
                    options={propertyOptions}
                    defaultSelectedKey={GetDefaultSelectedKey(expr, isField)}
                    onChange={(event, option, idx) => {
                        expr.Property = option.key as string;
                        expr.Value = null;
                        Update();
                    }}
                />
                <div>
                    <Dropdown
                        disabled={props.Readonly}
                        placeholder="Operator"
                        options={operators}
                        defaultSelectedKey={expr.Operator}
                        onChange={(e, o) => {
                            expr.Operator = QueryOperator[o.text];
                            Update();
                        }}
                    />
                </div>
                {prop && (
                    <div>
                        {isField ? (
                            EditorHandler.GetComponent(
                                prop as PropertyInfo,
                                {
                                    ReadOnly: props.Readonly,
                                    Value: expr.Value,
                                    OnChange: (obj, value) => {
                                        expr.Value = value;
                                        Update();
                                    },
                                    PropertyInfo: prop,
                                    HideLabel: true,
                                    Key: `field-${(prop as PropertyInfo).PropertyName}-${idx}`,
                                },
                                null,
                            )
                        ) : (
                            <CustomPropertyRender
                                ReadOnly={props.Readonly}
                                Item={expr.Value}
                                Property={prop as CustomProperty}
                                Type={props.EntityType.toString() as any}
                                Update={value => {
                                    expr.Value = value;
                                    Update();
                                }}
                                HideLabel
                                Key={`field-${(prop as CustomProperty).InternalName}-${idx}`}
                                OverrideDisabledProps={true}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    };

    const RenderMenu = (group: QueryGroup): JSX.Element => {
        return (
            <div style={{ float: 'left' }}>
                <IconButton
                    disabled={props.Readonly}
                    title={language.Common.Add}
                    iconProps={{ iconName: 'Add' }}
                    menuProps={{
                        shouldFocusOnMount: true,
                        items: [
                            {
                                key: 'group',
                                iconProps: { iconName: 'RowsGroup' },
                                text: language.EntityPropertyFilter.Group,
                                disabled: group.Parts.length > 0 && group.Parts[group.Parts.length - 1].TypeName != null,
                                onClick: () => {
                                    group.Parts.push(new QueryGroup());
                                    Update();
                                },
                            },
                            {
                                key: 'expression',
                                iconProps: { iconName: 'QueryList' },
                                text: language.Common.Expression,
                                disabled: group.Parts.length > 0 && group.Parts[group.Parts.length - 1].TypeName != null,
                                onClick: () => {
                                    group.Parts.push(new QueryExpression());
                                    Update();
                                },
                            },
                            {
                                key: 'condition',
                                iconProps: { iconName: 'DrillExpand' },
                                text: language.Common.Condition,
                                disabled: group.Parts.length === 0 || group.Parts[group.Parts.length - 1].TypeName == null,
                                onClick: () => {
                                    group.Parts.push(QueryCondition.And);
                                    Update();
                                },
                            },
                        ],
                    }}
                />
            </div>
        );
    };

    return <div className="tp-property-filter">{fields && RenderGroup(root, null)}</div>;
};

const getItemProperties = (itemPropertyInfoArray: PropertyInfo[], filterSystemProperties?: boolean, filterArrayProperties?: boolean, hiddenProperties?: Array<string>, forcedIncludedFields?: string[]): PropertyInfo[] => {
	// Apply filters as usual
	const systemProps = ['id', 'Created', 'Modified', 'CreatedBy', 'ModifiedBy', 'OriginIds'];
	const arrayProps = ['Rules', 'AdditionalRBS', 'UserTypes', 'AdditionalOwners'];

	const resultArray = itemPropertyInfoArray.filter(
		_ => _.PropertyName !== 'CustomProperties' &&
		(!filterSystemProperties || systemProps.indexOf(_.PropertyName) < 0) &&
		(!filterArrayProperties || arrayProps.indexOf(_.PropertyName) < 0) &&
		(!hiddenProperties || hiddenProperties.indexOf(_.PropertyName) < 0),
	);

	// Add forced included fields
	if (forcedIncludedFields?.length > 0) {
		forcedIncludedFields.forEach(field => {
			if (resultArray.find(_ => _?.PropertyName?.toString() === field) === undefined) {
				const fieldToForce = itemPropertyInfoArray.find(_ => _.PropertyName === field);
				resultArray.push(fieldToForce);
			}
		});
	}

	return resultArray;
}

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Item === nextProps.Item;
};

export default memo(EntityPropertyFilter, comparisonFn);
