import { IconButton, TooltipHost } from '@fluentui/react';
import { msalInstance } from '../../api/authConfig';
import { language } from '../../Services/LocalizationService';

export const LogoutButton = () => {
    const handleLogout = () => {
        try {
            // Initiate the logout by redirecting the user to the Azure AD logout endpoint
            msalInstance.logoutRedirect();
        } catch (error) {
            console.log('Logout error:', error);
        }
    };

    return (
        <TooltipHost content={language.UserDetails.SignOut} id="signoutbutton" calloutProps={{ gapSpace: 0 }}>
            <IconButton id="signoutbutton" iconProps={{ iconName: 'SignOut' }} onClick={handleLogout} />
        </TooltipHost>
    );
};
