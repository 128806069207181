import { useCallback, useMemo } from 'react';
import { CommandBar, ICommandBarItemProps, Stack } from '@fluentui/react';
import { language } from '../../Services/LocalizationService';
import { useTheme } from '../../Themes/themeContext';
import { UnitTypeToggler } from '../Common/UnitTypeToggler';
import { useNavbarItems } from './hooks/useNavbarItems';
import { useStore } from '../../context/store';
import shallow from 'zustand/shallow';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';

export const Navbar = () => {
    const theme = useTheme();

    const { data: uiSettings } = useUISettings();

    const { alwaysOpen, setUIContext, topbarStayOpen, topbarMenuItemOpen, showNotifications, showCurrentUserInfo } = useStore(
        store => ({
            alwaysOpen: uiSettings.settings.forcePinnedTopbar ? true : store.ui.topbarAlwaysOpen,
            // alwaysOpen: store.ui.topbarAlwaysOpen,
            setUIContext: store.setUIContext,
            topbarStayOpen: store.ui.topbarStayOpen,
            topbarMenuItemOpen: store.ui.topbarMenuItemOpen,
            showNotifications: store.ui.showNotifications,
            showCurrentUserInfo: store.ui.showCurrentUserInfo,
        }),
        shallow,
    );

    const { navbarFarItems, navbarItems } = useNavbarItems();

    const toggleTopbarAlwaysOpen = useCallback(() => {
        setUIContext(uiCtx => ({ topbarAlwaysOpen: !uiCtx.topbarAlwaysOpen }));
    }, [setUIContext]);

    const rightNavbarFaritems = useMemo((): ICommandBarItemProps[] => {
        return [
            !uiSettings.settings.forcePinnedTopbar && {
                key: 'pinnedtopbar',
                name: alwaysOpen ? language.Topbar.UnPin : language.Topbar.Pin,
                iconProps: { iconName: alwaysOpen ? 'Unpin' : 'Pinned' },
                iconOnly: true,
                onClick: toggleTopbarAlwaysOpen,
                buttonStyles: { root: { zIndex: 1 } },
            },
            {
                key: 'notifications',
                name: language.Common.Notifications,
                ariaLabel: 'notifications',
                iconProps: { iconName: 'MailAlert' },
                iconOnly: true,
                onClick: () => setUIContext({ showNotifications: !showNotifications }),
            },
            { key: 'unittype', name: 'unittype', ariaLabel: 'unittype', commandBarButtonAs: UnitTypeToggler },
        ].filter(Boolean);

    }, [alwaysOpen, setUIContext, showNotifications, toggleTopbarAlwaysOpen, uiSettings.settings.forcePinnedTopbar]);

    return (
        <>
            {!alwaysOpen && <div className="tp-topbar-overlay-button" />}
            <div
                className={`tp-topbar${alwaysOpen ? '' : ' hiding-topbar'}${
                    (!alwaysOpen && topbarStayOpen) ||
                    (!alwaysOpen && topbarMenuItemOpen) ||
                    (!alwaysOpen && showNotifications) ||
                    (!alwaysOpen && showCurrentUserInfo)
                        ? ' stay-open'
                        : ''
                }`}
            >
                <Stack
                    horizontal
                    styles={{ root: { boxShadow: alwaysOpen ? theme.effects.elevation8 : theme.effects.elevation16, maxWidth: '100%' } }}
                    className="tp-topbar-menu"
                >
                    <Stack.Item styles={{ root: { flexBasis: '50%', flexShrink: 0, flexGrow: 0 } }}>
                        <CommandBar
                            items={navbarItems}
                            overflowButtonProps={{
                                ariaLabel: language.Topbar.MoreCommands,
                                onAfterMenuDismiss: () => {
                                    if (!alwaysOpen) {
                                        setUIContext({ topbarStayOpen: false, topbarMenuItemOpen: false });
                                    } else {
                                        setUIContext({ topbarMenuItemOpen: false });
                                    }
                                },
                                menuProps: {
                                    items: [],
                                    onMenuOpened: () => setUIContext({ topbarStayOpen: false }),
                                },
                            }}
                            styles={{
                                root: {
                                    '.is-checked:after': {
                                        content: "''",
                                        position: 'absolute',
                                        bottom: 4,
                                        left: 4,
                                        width: 'calc(100% - 8px)',
                                        borderRadius: 4,
                                        height: 3,
                                        backgroundColor: theme.palette.themeSecondary,
                                    },
                                },
                            }}
                        />
                    </Stack.Item>

                    <Stack horizontalAlign="end" styles={{ root: { flexBasis: '50%', flexShrink: 0, flexGrow: 0 } }}>
                        <Stack.Item className="tp-topbar-menu-right" styles={{ root: { width: '100%', height: '100%', marginLeft: 'auto' } }}>
                            <CommandBar
                                items={navbarFarItems}
                                farItems={rightNavbarFaritems}
                                id="topbar-right"
                                overflowButtonProps={{
                                    ariaLabel: language.Topbar.MoreCommands,
                                    id: 'topbar-more-commands-right',
                                    onAfterMenuDismiss: () => {
                                        if (!alwaysOpen) {
                                            setUIContext({ topbarStayOpen: false, topbarMenuItemOpen: false });
                                        } else {
                                            setUIContext({ topbarMenuItemOpen: false });
                                        }
                                    },
                                    menuProps: {
                                        items: [],
                                        onMenuOpened: () => setUIContext({ topbarStayOpen: true }),
                                    },
                                }}
                                styles={{
                                    root: {
                                        flexDirection: 'row-reverse',
                                        selectors: {
                                            '.ms-OverflowSet': {
                                                flexDirection: 'row-reverse',
                                            },
                                            '.ms-OverflowSet-item': {
                                                flexShrink: 1,
                                            },
                                            '.ms-CommandBar-primaryCommand': {
                                                flexGrow: 0,
                                            },
                                        },
                                        '.is-checked:after': {
                                            content: "''",
                                            position: 'absolute',
                                            bottom: 4,
                                            left: 4,
                                            width: 'calc(100% - 8px)',
                                            borderRadius: 4,
                                            height: 3,
                                            backgroundColor: theme.palette.themeSecondary,
                                        },
                                    },
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </div>
        </>
    );
};
