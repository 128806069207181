import { Icon, Text } from '@fluentui/react';
import { useTheme } from '../../../../../../../Themes/themeContext';
import { useGridContext } from '../../../../../_grid/contexts/gridContext';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { createCellValueGetter } from '../../../../../_grid/useGrid';
import { SortOrder, sortRowProperty } from '../../../../../_grid/helpers/sortRowProperty';
import { currentSortedByColumn } from './useSortedByColumnState';
import { resolveCustomColumnData } from '../../context/customColumnContext';

// string
// number
// date
// bool
// url
// rbs
// resourcetype
// projecttype
// stringlist
// resourcelist



export const TableHeader = ({ children, index, isCustom, id }: { children: string; index: number; isCustom?: boolean; id: string }) => {
	const theme = useTheme();
    const { setRootRows } = useGridContext();
    const onClick = useRecoilCallback(
        ({ snapshot, set }) =>
            async () => {
                const currentSortedValue = await snapshot.getPromise(currentSortedByColumn);
                let order: SortOrder = 'asc';
                if (currentSortedValue?.id === id) {
                    order = currentSortedValue.order === 'desc' ? 'asc' : 'desc';
                }
                await setRootRows(async rows => {
                    if (isCustom) {
                        return [...rows].sort((a, b) => {
                            const { value: valueA = "", type } = resolveCustomColumnData(a.customColumnData, id);
                            const { value: valueB = "" } = resolveCustomColumnData(b.customColumnData, id);
                            return sortRowProperty(valueA, valueB, order, type);
                        });
                    }
                    const getCellValue = createCellValueGetter(snapshot);
                    const columnValues = await Promise.all(
                        rows.map(async row => {
                            const cell = row.columnRows[index].cells[0];
                            const value = await getCellValue(cell);
                            return {
                                value,
                                row,
                            };
                        }),
                    );
                    columnValues.sort((a, b) => {
                        if (order === 'asc') {
							return a.value.minus(b.value).toNumber();
						}
                        return b.value.minus(a.value).toNumber();
                    });
                    return columnValues.map(({ row }) => row);
                });
                set(currentSortedByColumn, { id, isCustom, order });
            },
        [id, index, isCustom, setRootRows],
    );
    return (
        <div onClick={onClick} style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%", cursor: "pointer", userSelect: "none"}}>
            <Text styles={{ root: { color: theme.semanticColors.bodyText, fontSize: 15, fontWeight: 600 } }}>
                {children}
                <ColumnSortIcon id={id} />
            </Text>
        </div>
    );
};


const ColumnSortIcon = ({ id }: { id: string }) => {
    const currentColumn = useRecoilValue(currentSortedByColumn);
    const currentColumnId = currentColumn?.id;
    if (!currentColumn || currentColumnId !== id) {
        return null;
    }
    return <Icon iconName={currentColumn.order === 'asc' ? 'SortUp' : 'SortDown'} />;
};
