import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { IconButton, IPersonaProps, NormalPeoplePicker, Stack, TooltipHost } from '@fluentui/react';
import { ApiCalls } from '../../../../../api/api';
import { ResourceType, UserType } from '../../../../../api/generated/data-contracts';
import { RESOURCE_PICKERFIELD } from '../../../../../context/network/http/QueryProvider/queryKeys';
// import EnumEx from '../../../../../Utilities/EnumEx';
import { useDebouncedEventHandlerAsync } from '../../../../../hooks/useDebouncedHandler';
import { useUISettings } from '../../../../../context/network/http/QueryProvider/queries/UISettings';
import { useTPPermissions } from '../../../../../hooks/usePermissions';
import { language } from '../../../../../Services/LocalizationService';
import { QueryGroup, createQueryGroup } from './helpers/createQueryGroup';

export const ResourcePicker = ({ onFilterChange, queryGroup }: { onFilterChange: (queryGroup: QueryGroup) => void; queryGroup: QueryGroup }) => {
    const isNotSuperUser = useTPPermissions({ userTypes: [UserType.SuperUser], not: true });
    const { data: uiSettings } = useUISettings();
    const rbsMatch = !(isNotSuperUser || uiSettings.settings.allowSearchAllResources);
    const { data } = useQuery([RESOURCE_PICKERFIELD, rbsMatch], () =>
        // ApiCalls.getResourcesForPicker({ onlyActive: true, resourceTypes: EnumEx.all<ResourceType>(ResourceType), rbsMatch }).then(res =>
        ApiCalls.getResourcesForPicker({
            onlyActive: true,
            resourceTypes: [
                //
                ResourceType.Category,
                ResourceType.Generic,
                ResourceType.Named,
                ResourceType.Pool,
                ResourceType.Team,
            ],
            rbsMatch,
        }).then(res => res.data.sort((a, b) => a.name?.localeCompare(b.name))),
    );

    const [selectedResourceTags, setSelectedResourceTags] = useState<IPersonaProps[]>(() => {
        if (data && queryGroup?.parts) {
            return queryGroup.parts.reduce((acc, part) => {
                if (typeof part !== 'number') {
                    const projectId = part.value;
                    const project = data.find(project => project.id === projectId);
                    if (project) {
                        acc.push({
                            key: project.id,
                            text: project.name,
                        });
                    }
                }
                return acc;
            }, [] as IPersonaProps[]);
        }
        return [];
    });

    const onResolveSuggestions = useDebouncedEventHandlerAsync<(filter: string, selectedItems?: IPersonaProps[]) => Promise<IPersonaProps[]>>(
        async (filterText, selectedItems) => {
            const text = filterText.toLowerCase();

            return data
                ?.sort(resource => {
                    if (resource.name?.toLowerCase().startsWith(text)) {
                        return -1;
                    }
                    return 0;
                })
                .filter(resource => {
                    const fitsFilter = resource.name?.toLowerCase().includes(text);
                    if (fitsFilter && selectedItems && selectedItems.some(selectedItem => selectedItem.key === resource.id)) {
                        return false;
                    }
                    return fitsFilter;
                })
                .map(({ name, id }) => {
                    return {
                        key: id,
                        name,
                        text: name,
                    };
                });
        },
        300,
    );

    const onChange = useCallback(
        (items?: IPersonaProps[]) => {
            // sync up selection to real projects
            if (items) {
                onFilterChange(createQueryGroup(data.filter(project => items.some(item => item.key === project.id))));
                setSelectedResourceTags(items);
            }
        },
        [data, onFilterChange, setSelectedResourceTags],
    );

    return (
        <>
            <Stack horizontal>
                <NormalPeoplePicker
                    styles={{ root: { width: '100%' } }}
                    disabled={!data}
                    onResolveSuggestions={onResolveSuggestions}
                    onChange={onChange}
                    selectedItems={selectedResourceTags}
                />
                <TooltipHost styles={{ root: { paddingBottom: 4 } }} content={language.LMCapacity.ClearSelection.Resource}>
                    <IconButton styles={{ icon: { fontSize: 24 } }} iconProps={{ iconName: 'ClearFormattingEraser' }} onClick={() => onChange([])} />
                </TooltipHost>
            </Stack>
        </>
    );
};
