import { Coachmark, TeachingBubbleContent } from "@fluentui/react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { language } from "../Services/LocalizationService";
import { OverviewContext } from "../Utilities/Context/OverviewContext";
import { LocalStorage } from "../Utilities/LocalStorage";
import { CoachmarkInfo } from "./CoachmarkInfo";

interface IProps {
	CoachmarkInfo: CoachmarkInfo;
}

const CoachmarkRootKey: string = "tp-guide";

export const PFCoachmark: FunctionComponent<IProps> = props => {
	const [show, setShow] = useState<boolean>(false);

	useEffect(() => {
		if (props.CoachmarkInfo == null) return;
		const guides = LocalStorage.get<{ [key: string]: boolean }>(CoachmarkRootKey);
		if (guides == null) {
			setShow(true);
			return;
		}
		const guide = guides[props.CoachmarkInfo.LocalStorageKey];
		const domElementExists = !!document.querySelector(props.CoachmarkInfo.Target);
		if (!domElementExists) setShow(false);
		else setShow(!guide);
	},  [props.CoachmarkInfo])

	return 	<>
				{ show && OverviewContext.Settings.EnableCoachmarks &&
					<Coachmark
						target={props.CoachmarkInfo.Target}
						positioningContainerProps={{directionalHint: props.CoachmarkInfo.DirectionalHint}}
					>
						<TeachingBubbleContent
							headline={props.CoachmarkInfo.LocaleItem.Headline}
							hasCloseButton
							closeButtonAriaLabel={language.Common.Close}
							onDismiss={() => {
								setShow(false);
								LocalStorage.setProperty(CoachmarkRootKey, props.CoachmarkInfo.LocalStorageKey, true);
							}}
						>
							{ props.CoachmarkInfo.LocaleItem.Text }
						</TeachingBubbleContent>
					</Coachmark>
				}
			</>
}