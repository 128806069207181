let _ready = false;
let _callBack: (ready: boolean) => void = null;

const setReady = (ready: boolean) => {
	_ready = ready;
	_callBack?.(ready);
}

const init = (callback: (ready: boolean) => void) => _callBack = callback;

export const apiCache = {  ready: _ready, setReady, init }