export default class Locale_CapacityViews { 
	OnCellUpdate = {
		NotificationMessage: "Your changes haven't been persisted! Do you want to disable these checks, during your current session or would you like to switch to the correct view?",
		Disable: 'Disable'
	}
	Undo = {
		NothingToUndo: 'Nothing to undo',
		UndidPreviousCellEdit: 'Undid previous cell edit',
	}
	ExcelCopyUpdate: string = "The copied data is larger than the available area in the grid! Affected cells have been updated properly and excessive data has been discarded.";
	NameLabel: string = 'Name';
	OverAllocation: string = "Over allocation is not allowed"
}