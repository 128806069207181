import { Stack, Icon, Text } from '@fluentui/react';
import { FunctionComponent } from 'react';
import { DistributionResult } from '../../../../../../Entities/ActivityDistribution/DistributionResult';
import { DistributionResultType } from '../../../../../../Entities/ActivityDistribution/Enums';
import { language } from '../../../../../../Services/LocalizationService';
import { IExtendedTheme } from '../../../../../../Themes/Theme.types';
import { ArrayEx } from '../../../../../../Utilities/ArrayEx';
import EnumEx from '../../../../../../Utilities/EnumEx';

interface IProps {
    theme: IExtendedTheme;
    result: DistributionResult;
}

export const DistributionSuccess: FunctionComponent<IProps> = props => {
    const getResultTypes = (resultType: DistributionResultType): Array<DistributionResultType> => {
        const results = EnumEx.getFlagValues<DistributionResultType>(DistributionResultType, resultType);
        return results;
    };

    return (
        <>
            <Stack horizontalAlign="center" styles={{ root: { width: '100%' } }}>
                <Icon
                    iconName="SkypeCheck"
                    styles={{ root: { color: props.theme.semanticColors.successIcon, fontSize: 26, cursor: 'help' } }}
                    title={ArrayEx.objectJoinToString(getResultTypes(props.result.Result), ', ', _ => DistributionResultType[_])}
                />
                <Text variant="xLarge" styles={{ root: { color: props.theme.semanticColors.successIcon } }}>
                    {language.Common.Success}
                </Text>
            </Stack>
        </>
    );
};
