import { ITextProps, Text } from '@fluentui/react';
import { CSSProperties } from 'react';

export const RowTitle = ({ children }: { children?: React.ReactNode }) => {
    return (
        <div style={containerStyle}>
            <Text styles={textStyle}>{children}</Text>
        </div>
    );
};


const containerStyle: CSSProperties = {
	display: 'grid'
}

const textStyle: ITextProps["styles"] = {
	root: { fontWeight: 700, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' } 
}