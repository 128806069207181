import 'reflect-metadata';
import { PropertyInfoAreas } from './PropertyInfoAreas';

export class PropertyGrouping { 
	constructor(public Name: string, public Order: number = 1000, public DefaultCollapsed: boolean = true) { } 
	public static PropertyInfoKey: PropertyInfoAreas = PropertyInfoAreas.Grouping;
}

export const PFPropertyGrouping = (name: string, order?: number, collapsed?: boolean): PropertyDecorator => {
    return (target, property) => {
      const propertyConstructor = target.constructor;
	  const metadata = Reflect.getMetadata(PropertyGrouping.PropertyInfoKey, propertyConstructor) || {};
      metadata[property] = new PropertyGrouping(name, order, collapsed);
      Reflect.defineMetadata(PropertyGrouping.PropertyInfoKey, metadata, propertyConstructor);
    };
};
