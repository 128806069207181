export default class Locale_RecurrenceRule {
	FrequencyDescription: string = "The frequency of the rule.";
	Skip: string = "Every";
	SkipDescription: string = "How many periods to skip the selected frequency.";
	Months: string = "Months";
	MonthsDescription: string = "The months this rule should occur.";
	OrdinalWeek: string = "On weeks";
	OrdinalWeekDescription: string = "The week this rule should occur.";
	Day: string = "On date";
	DayDescription: string = "The date this rule should occur.";
	DayValidation: string = "You haven't set either 'Months' or 'Ordinal Week'";
	DaysOfWeek: string = "On weekdays";
	DaysOfWeekDescription: string = "The weekdays this rule should occour.";
	DayOfWeek: string = "On weekday";
	DayOfWeekDescription: string = "The weekday this rule should occour.";
	DayOfWeekValidation: string = "You must select an ordinal week, when using weekday";
	ActiveFrom: string = "Active from";
	ActiveFromDescription: string = "The start date of the period the rule should be effective.";
	ActiveFromValidation: string = "The recurring rule must have an Active From date";
	ActiveTo: string = "Active to";
	ActiveToDescription: string = "The end date of the period the rule should be effective. If not selected the rule runs forever.";
}