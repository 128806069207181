export default class Locale_Common {
	Owner: string = "Owner";
	AdditionalOwners: string = "Additional Owners";
	AdditionalOwnersAADGroup: string = "Additional owners - AAD group";
	Resources: string = "Resources";
	
	ConfirmDeletionOfItem: string = "Are you sure you want to delete this item?";
	ConfirmDeletionOfItems: string = "Are you sure you want to delete these items?";
	CannotBeUndone: string = "This cannot be undone..";
	SuccessDeletionOfItem: string = "Successfully deleted item"
	SuccessDeletionOfItems: string = "Successfully deleted items"

	YouHaveUnsavedChanges: string = "You have unsaved changes";
	SettingsHaveUnsavedChanges: string = "Settings has unsaved changes";
	ConfirmDiscardChanges: string = "Do you want to discard these changes?";
	DiscardChanges: string = "Discard changes";
	PleaseWait: string = "Please wait (it will take about 1-2 minutes before done)...";
	RefreshPage: string = "You need to refresh the page. It can take up to two minutes before the app is restarted..";
	FailedSavingCells: string = "Failed saving cells";
	NothingToUndo: string = "Nothing to undo";
	UndidPreviousCellEdit: string = "Undid previous cell edit";
	EndGreaterThanStart: string = "'End' must greater than start.";
	ResourceAlreadyAssignedToProject: string = "Resource is already assigned to this project.";
	ResourceAlreadyAssignedToContract: string = "Resource is already assigned to this contract.";
	RequestedResourceAlreadyAssignedToContract: string = "A generic resource is already assigned to this contract.";


	Administrator: string = "Administrator";
	SuperUser: string = "Super User";
	ProjectOwner: string = "Project Owner";
	LineManager: string = "Line Manager";
	Resource: string = "Resource";
	System: string = "System";

	Created: string = "Created";
	CreatedBy: string = "CreatedBy";
	Modified: string = "Modified";
	ModifiedBy: string = "Modified By";

	Day: string = "Day";
	Week: string = "Week";
	Month: string = "Month";
	Year: string = "Year";
	Quarter: string = "Quarter";

	Charts: string = "Charts (Preview)";
	Select: string = "Select";
	Ok: string = "Ok";
	Save: string = "Save";
	Search: string = "Search";
	Edit: string = "Edit";
	New: string = "New";
	Remove: string = "Remove";
	Version: string = "Version";
	State: string = "State";
	Start: string = "Start";
	End: string = "End";
	Approval: string = "Approval";
	Delete: string = "Delete";
	Copy: string = "Copy";
	RbsStructure: string = "RBS structure";
	Rbs: string = "RBS";
	Lookups: string = "Lookups";
	WorkProfile: string = "Work Profile";
	Add: string = "Add";
	Import: string = "Import";
	Frequency: string = "Frequency";
	Resolution: string = "Resolution";
	Backwards: string = "Backwards";
	Forwards: string = "Forwards";
	Condition: string = "Condition";
	Expression: string = "Expression";
	Unknown: string = "Unknown";
	Theme: string = "Theme";
	Key: string = "Key";
	Value: string = "Value";
	Close: string = "Close";
	Name: string = "Name";
	Description: string = "Description";
	On: string = "On";
	Off: string = "Off";
	Yes: string = "Yes";
	No: string = "No";
	Saving: string = "Saving..";
	PlannerView: string = "Planner view";
	Id: string = "Id";
	Endpoint: string = "Endpoint";
	Active: string = "Active";
	FunctionsApp: string = "FUNCTIONS APP";
	Api: string = "API";
	Packages: string = "Packages";
	Client: string = "Client";
	Upgrade: string = "Upgrade";
	Notification: string = "Notification";
	Endpoints: string = "Endpoints (Preview)";
	Plugins: string = "Plugins";
	Maintenance: string = "Maintenance";
	Log: string = "Log";
	Reboot: string = "Reboot";
	Color: string = "Color";
	Order: string = "Order";
	Roles: string = "Roles";
	Views: string = "Views";
	Required: string = "Required";
	Type: string = "Type";
	Lookup: string = "Lookup";
	Options: string = "Options";
	NoResults: string = "No results";
	Loading: string = "Loading";
	LoadingDot: string = "Loading...";
	Scenarios: string = "Scenarios";
	Scenario: string = "Scenario";
	Contracts: string = "Contracts";
	Projects: string = "Projects";
	Project: string = "Project";
	Work: string = "Work";
	GenericResource: string = "Generic resource";
	Url: string = "Url";
	AdditionalRbs: string = "Additional RBS";
	View: string = "View";
	Filter: string = "Filter";
	Email: string = "Email";
	IsActive: string = "Is active";
	Settings: string = "Settings";
	Localization: string = "Localization (PREVIEW)";
	AzureId: string = "AzureId";
	OriginIds: string = "Origin Id's";
	Origins: string = "Origins";
	Configuration: string = "Configuration";
	Total: string = "Total";
	Enable: string = "Enable";
	Enabled: string = "Enabled";
	Disabled: string = "Disabled";
	TypeNotSupported: string = "Type not supported";
	UpdatingPleaseWait: string = `Updating... please wait`;
	Reports: string = "Reports";
	Refreshview: string = "Refresh view";
	Notifications: string = "Notifications";
	NoResultsFound: string = "No results found";
	LatestEnd: string = "Latest end";
	EarliestStart: string = "Earliest start";
	Integrations: string = "Integrations";
	FindAvailableCapacity: string = "Find Available Capacity";
	FindAvailableCapacitySettings: string = "Project distribution settings";
	Fields: string = "Fields";
	FailedGettingTeamplannerIntegrations: string = "Failed getting teamplanner integrations";
	FailedGettingResources: string = "Failed getting resources";
	CustomPropertiesCapitalized: string = "Custom Properties";
	CustomProperties: string = "Custom properties";
	ConfirmCountCascadingTitle: string = "Are you really sure?!";
	ConfirmCascadingOkButton: string = "Delete ALL related data";
	ClearAllSelections: string = "Clear all selections";
	BulkEdit: string = "Bulk edit";
	AutoRefreshOnSelection: string = "Auto refresh on selection";
	AreYouSure: string = "Are you sure?";
	Properties: string = "Properties";
	DisplayName: string = "Display name";
	Migrations: string = "Migrations";
	SearchResults: string = "Search Results";
	Demo: string = "Demo";
	Success: string = "Success";
	Cancel: string = "Cancel";
	Accept: string = "Accept";
	MissingRequiredProperties: string = "Missing required fields"
	Revert: string = "Revert";
	WorkPackage: string = "Work Package";

	MonthNamesLong = {
		January: "January",
		February: "February",
		March: "March",
		April: "April",
		May: "May",
		June: "June",
		July: "July",
		August: "August",
		September: "September",
		October: "October",
		November: "November",
		December: "December",
	};
	MonthNamesShort = {
		January: "Jan",
		February: "Feb",
		March: "Mar",
		April: "Apr",
		May: "May",
		June: "Jun",
		July: "Jul",
		August: "Aug",
		September: "Sep",
		October: "Oct",
		November: "Nov",
		December: "Dec",
	}
}
