import 'reflect-metadata';
import { PropertyInfoAreas } from './PropertyInfoAreas';

export class PropertyOnChange { 
	constructor(public Action?: (obj: any) => void) {}
	public static PropertyInfoKey: PropertyInfoAreas = PropertyInfoAreas.Change;
}

export const PFPropertyOnChange = (action?: (obj: any) => void): PropertyDecorator => {
    return (target, property) => {
      const propertyConstructor = target.constructor;
	  const metadata = Reflect.getMetadata(PropertyOnChange.PropertyInfoKey, propertyConstructor) || {};
      metadata[property] = new PropertyOnChange(action);
      Reflect.defineMetadata(PropertyOnChange.PropertyInfoKey, metadata, propertyConstructor);
    };
};
