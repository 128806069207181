import { useQuery } from 'react-query';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { StartupCalls } from '../../../../../api/api';
import { RootSettings, UIStartupDto } from '../../../../../api/generated/data-contracts';
import { refactorHelper } from '../../../../../helpers/refactorHelper';
import { OverviewContext } from '../../../../../Utilities/Context/OverviewContext';
import { UserContext } from '../../../../../Utilities/Context/UserContext';
import { LocalStorage } from '../../../../../Utilities/LocalStorage';
import { useStore } from '../../../../store';
import { upperCaseObj } from '../helpers/queryHelper';
import { UI_SETTINGS_KEY } from '../queryKeys';
import { queryClient } from '../QueryProvider';

export const syncUISettings = (uiStartup: UIStartupDto) => {
    UserContext.Initialize(
        upperCaseObj({ ...uiStartup.user, Resource: uiStartup.resource, IsDelegated: uiStartup.isDelegated, LoginName: uiStartup.loginName }),
    );
    OverviewContext.Settings = refactorHelper.parseSettingsToLegacy(uiStartup.settings);
    document.title = uiStartup.tabTitle;
    LocalStorage.set(UI_SETTINGS_KEY, uiStartup);
};

/**
 *
 * @returns cached settings, if any
 */
export const settings = (): RootSettings | undefined => {
    const data = queryClient.getQueryData<UIStartupDto>(UI_SETTINGS_KEY);
    return data?.settings;
};

export const queryUiSettings = (navigate: NavigateFunction, skibUserCheck?: boolean): Promise<UIStartupDto | null> => {
    return StartupCalls.getUiStartup({})
        .then(res => {
            const data = res.data;
            syncUISettings(data);
            useStore.getState().setUIStartupRefreshed(true); // this is weird but true (ewi)
            if ((!data?.resource || !data?.resource?.userTypes?.length) && !skibUserCheck) {
                throw new Error("User is missing or hasn't got any permissions");
            }
            return data;
        })
        .catch(error => {
            console.error(error);
            navigate('/accessdenied', { replace: true });
            return null;
        });
};

export const useUISettings = ({ skibUserCheck }: { skibUserCheck?: boolean } = {}) => {
    const navigate = useNavigate();
    return useQuery(UI_SETTINGS_KEY, () => queryUiSettings(navigate, skibUserCheck), {
        //
        cacheTime: Infinity,
        staleTime: Infinity,
    });
};
