import { Notification, NotificationStatusType } from '../../Entities/Notification';
import { devLogger } from '../../helpers/devLogger';
import { produce } from '../helpers';
import { IStore } from '../store';
import { PersistGetter, TPContext } from '../types';

export interface INotificationContext {
    notifications: Notification[];
    setNotifications: (updater: (notifications: Notification[]) => Notification[]) => void;
    clearNotifications: () => void;
    addNotification: (notification: Notification) => void;
    addErrorNotification: (message: string, expires?: number, errorInfo?: string) => void;
    addWarningNotification: (message: string, expires?: number) => void;
    addSevereWarningNotification: (message: string, expires?: number) => void;
    addInfoNotification: (message: string, expires?: number) => void;
    addSuccesNotification: (message: string, expires?: number) => void;
}

export const notificationContext: TPContext<INotificationContext> = (set, get, api) => {
    return {
        notifications: [],
        setNotifications: updater =>
            set(
                produce(store => {
                    store.notifications = updater(store.notifications);
                }),
            ),
        clearNotifications: () =>
            set(
                produce(store => {
                    store.notifications = [];
                }),
            ),
        addNotification: notification =>
            set(
                produce(store => {
                    if (notification.WriteToConsole === true) {
                        devLogger.info('Notification: ' + notification.Message);
                    } else {
                        store.notifications.push(notification);
                    }
                }),
            ),
        addErrorNotification: (message, expires, errorInfo) => set(createNotification(message, NotificationStatusType.Error, expires, errorInfo)),
        addWarningNotification: (message, expires) => set(createNotification(message, NotificationStatusType.Warning, expires)),
        addSevereWarningNotification: (message, expires) => set(createNotification(message, NotificationStatusType.SevereWarning, expires)),
        addInfoNotification: (message, expires) => set(createNotification(message, NotificationStatusType.Info, expires)),
        addSuccesNotification: (message, expires) => set(createNotification(message, NotificationStatusType.Success, expires)),
    };
};

function createNotification(message: string, status: NotificationStatusType, expires?: number, errorInfo?: string) {
    const notification = new Notification();
    notification.Expires = expires;
    notification.StatusType = status;
    notification.Message = message;
    notification.errorInfo = errorInfo;

    if (notification.WriteToConsole === true) {
        devLogger.info('Notification: ' + notification.Message);
    } else {
        return (store: IStore) => void store.addNotification(notification);
    }
}

export const getPersistNotificationContext: PersistGetter = () => {
    return {
        // Persisted notifications doesn't make sense
        notifications: [],
    };
};
