import { useRecoilCallback } from 'recoil';
import { useStore } from '../../../../../../../../context/store';
import { useUISettings } from '../../../../../../../../context/network/http/QueryProvider/queries/UISettings';
import { language } from '../../../../../../../../Services/LocalizationService';
import { ErrorMessageGetterWithRoundingValue } from '../../../../../../_grid/hooks/types';
import { isOverAllocatedBigJs } from '../../../../../../../../helpers/math';
import { createCellValueGetter } from '../../../../../../_grid/useGrid';

export const useCheckOverAllocationResourcePlanner = () => {
	const makeErrorNotification = useStore(store => store.addErrorNotification);
	const { data: uiSettings } = useUISettings();
	return useRecoilCallback(
		({ snapshot }): ErrorMessageGetterWithRoundingValue =>
			async ({ cell, columnIndex, currentCellValue, newCellValue, rowFamilyTree }) => {
				// let changedValue = newCellValue;
				let errorMessage = undefined;
				if (!uiSettings.settings?.restrictOverAllocating) {
					return { errorMessage, changedValue: newCellValue };
				}
				// Allow the user to gradually write down the value 
				if (newCellValue.lt(currentCellValue)) {
					return { errorMessage, changedValue: newCellValue };
				}
				
				const rootRow = rowFamilyTree[rowFamilyTree.length - 1];
				const totalCell = rootRow.columnRows[columnIndex].cells[0];
				const getCellValue = createCellValueGetter(snapshot);
				const freeCap = await getCellValue(totalCell);
				const maxValue = currentCellValue.plus(freeCap);

				if (isOverAllocatedBigJs(newCellValue, maxValue, currentCellValue)) {
					errorMessage = language.CapacityViews.OverAllocation;
					makeErrorNotification(errorMessage);
				}
				return { errorMessage, changedValue: newCellValue };
			},
		[uiSettings.settings?.restrictOverAllocating, makeErrorNotification]
	);
};
