import { useState, useCallback } from 'react';
import { Dropdown } from '@fluentui/react';
import { useQuery } from 'react-query';
import { ApiCalls } from '../../../../api/api';
import { EntityType, QueryDto, RootSettings } from '../../../../api/generated/data-contracts';
import { DropdownOptionEx } from '../../../../Utilities/DropdownOptionEx';
import { IFieldRenderProps, IFieldConfig, useFormContext } from 'spark-forms';
import { guid } from '../../../../helpers/guid';

export const SettingsSelector: React.FC<any> = <T,>({
	value,
	disabled,
	fieldName
}: IFieldRenderProps<T, IFieldConfig<T>, string | undefined>) => {
	
	const { onChange } = useFormContext();
	const [localValue, setLocalValue] = useState<string>(value ?? guid.empty);

	const {
        data: settings,
		isLoading
    } = useQuery(
        ['settingsfield'],
        async () => {
			const dto = {
				entity: EntityType.Settings,
				query: { expression: 'SettingsType < @0 AND ActiveMatchFilter == @1', params: [2, false] },
			} as QueryDto;
			const response = await ApiCalls.queryEntity(dto);
			return response.data as RootSettings[];
        }
    );

	const onLocalChange = useCallback((v, value: any) => {
        setLocalValue(value);
        onChange(fieldName, value);
    }, [fieldName, onChange]);

	return 	(
		<div>
			<Dropdown 
				disabled={ disabled || isLoading }
				options={ DropdownOptionEx.toDropdownOptions(settings, _ => _.id, _ => _.name) }
				defaultSelectedKey={localValue}
				onChange={ onLocalChange }
			/>
		</div>
	)
}
