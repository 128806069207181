/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect, useCallback, memo } from 'react';
import { TimelineResolution } from '../../../Entities/TimelineResolution';
import { getGridTypeFromRoute, GridType } from '../../../Entities/GridType';
import { TimeLineCell } from './TimeLineCell';
import { TimeLineHeaderCell } from './TimeLineHeaderCell';
import { Callout, ChoiceGroup, IChoiceGroupOption, IconButton, TooltipHost } from '@fluentui/react';
import { TimelineUtil } from '../../../Utilities/TimelineUtil';
import { DateEx } from '../../../Utilities/DateEx';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { DateCell } from './DateCell';
import { OverviewContext } from '../../../Utilities/Context/OverviewContext';
import { language } from '../../../Services/LocalizationService';
import { UnitType } from '../../../Entities/UnitType';
import { useUnitType } from '../../../Utilities/UnitTypeUtil';
import { useStore } from '../../../context/store';
import { useLocation } from 'react-router';
import { useUISettings } from '../../../context/network/http/QueryProvider/queries/UISettings';
import { DateResolution } from '../../../api/generated/data-contracts';

class SelectedIndexes {
    constructor(public Start: number, public End: number) {}
}

// eslint-disable-next-line react-refresh/only-export-components
const TimelineModern = () => {
    const { data: uiSettings } = useUISettings();
    const { pathname } = useLocation();
    const gridType = getGridTypeFromRoute(pathname);

    const { timeline, setTimeline } = useStore(store => ({
        timeline: store.timeline,
        setTimeline: store.setTimeline,
    }));

    const { currentUnitType } = useUnitType();

    // this will make sure that when we cache timeresolution and then remove one from settings, we make sure it switches to the first allowed
    const getPossibleResolution = useCallback(
        (resolution: TimelineResolution): TimelineResolution => {
            const found = uiSettings.settings.allowedTimelineResolutions.indexOf(resolution as number) >= 0;
            if (found) {
                return resolution;
            } else {
                return uiSettings.settings.allowedTimelineResolutions[0] as unknown as TimelineResolution;
            }
        },
        [uiSettings.settings.allowedTimelineResolutions],
    );

    // const [resolution, setResolution] = useState<TimelineResolution>(timeline.resolution ?? (LocalStorage.Get<TimelineResolution>("tp-timeline-resolution") ?? TimelineResolution.Month));
    const [resolution, setResolution] = useState<TimelineResolution>(getPossibleResolution(timeline.resolution));
    const [showResolutionSelector, setShowResolutionSelector] = useState<boolean>(false);

    const [headerCell, setHeaderCell] = useState<{ [key: string]: number }>();
    const [cells, setCells] = useState<Array<DateCell>>();

    const [selectedIndexes, setSelectedIndexes] = useState<SelectedIndexes>();

    const [resolutionOptions, setResolutionOptions] = useState<Array<IChoiceGroupOption>>();

    const _isDragging = useRef<boolean>(false);
    const _isMoving = useRef<boolean>(false);
    const _isMovingRange = useRef<boolean>(false);
    const _movingRangeIndex = useRef<number>();

    const _scrollTimestamp = useRef<number>(0);
    const _moveTimestamp = useRef<number>();

    const _startBoundary = useRef<number>();
    const _endBoundary = useRef<number>();

    const _cellCount = useRef<number>();
    const _viewIndex = useRef<number>(0);

    const _offSet = useRef<number>(0);

    const CellWidth: number = 65;

    const SetResolution = useCallback((resolution: TimelineResolution): void => {
        setResolution(resolution);
        setShowResolutionSelector(false);
        // LocalStorage.Set("tp-timeline-resolution", resolution);
    }, []);

    useEffect(() => {
        OverviewContext.ChangeResolution = resolution => SetResolution(resolution);
    }, [SetResolution]);

    // did mount
    useEffect(() => {
        Initialze();
        const data = LoadData(null, null);
        const end = GetDefaultColumnCount(gridType);
        UpdateSelection(0, end, data, OverviewContext.AllowTimelineBackInTime() ? _offSet.current : 0);
    }, []);

    const ClearSelected = useCallback((cellArr: Array<DateCell>) => {
        cellArr.forEach(_ => (_.Selected = false));
    }, []);

    // refresh selection
    // useEffect(() => SelectRange(), [selectedIndexes]);

    // refresh selection
    const lastSelectedIndexes = useRef<SelectedIndexes | null>(null);
    useEffect(() => {
        // only run this effect if selectedIndexes has changes since last time
        if (lastSelectedIndexes.current !== selectedIndexes) {
            if (!selectedIndexes?.End || !selectedIndexes?.Start) if (!cells || selectedIndexes.Start < 0 || selectedIndexes.End < 0) return;
            cells.forEach(_ => (_.Selected = false));

            const allCells = [...cells];
            ClearSelected(allCells);

            const selectedCells =
                selectedIndexes.End < selectedIndexes.Start
                    ? cells.filter((el, idx) => idx <= selectedIndexes.Start && idx >= selectedIndexes.End) //reverse select
                    : cells.slice(selectedIndexes.Start, selectedIndexes.End + 1); // normal select

            allCells.filter(_ => selectedCells.some(sc => sc.Index === _.Index)).forEach(_ => (_.Selected = true));
            setCells(allCells);
            lastSelectedIndexes.current = selectedIndexes;
        }
    }, [ClearSelected, cells, selectedIndexes]);

    // refresh resolution
    useEffect(() => {
        SetCellWidth();
        HandleResolutionChange();
    }, [resolution]);

    // did mount
    useEffect(() => {
        if (!timeline.start || !cells) return;
        const startCellIdx = cells.findIndex(_ => _.Start <= timeline.start && _.End >= timeline.start);
        if (startCellIdx < 0) return;
        const endCellIdx = cells.findIndex(_ => _.Start <= timeline.end && _.End >= timeline.end);
        const endIdx =
            endCellIdx >= 0 ? endCellIdx : startCellIdx !== selectedIndexes.Start ? GetDefaultColumnCount(gridType) + startCellIdx : selectedIndexes.End;

        _offSet.current = startCellIdx;

        document.documentElement.style.setProperty('--timelineOffSet', _offSet.current + '');
        UpdateSelection(startCellIdx, endIdx, null, null, true);
    }, [timeline.start, timeline.end]);

    const UpdateSelection = (startIndex: number, endIndex: number, data?: Array<DateCell>, push?: number, skipCallback?: boolean): void => {
        if (push != null && startIndex === 0) {
            startIndex += push;
            endIndex += push;
            document.documentElement.style.setProperty('--timelineOffSet', _offSet.current + '');
        }
        // update ranges
        SetRangeIndexes(startIndex, endIndex);

        if (skipCallback) return;
        // update parent
        TimelineChanged(startIndex, endIndex, data);
    };

    const SetupResolutionOptions = (): void => {
        const resOptions = [];
        // var allowedResolutions = OverviewContext.Settings.AllowedTimelineResolutions;
        const allowedResolutions = uiSettings.settings.allowedTimelineResolutions || [];
        if (allowedResolutions.indexOf(DateResolution.Year) >= 0)
            resOptions.push({
                key: 'Year',
                text: language.Common.Year,
                iconProps: { iconName: 'CalendarAgenda' },
                onClick: () => SetResolution(TimelineResolution.Year),
            });
        if (allowedResolutions.indexOf(DateResolution.Quarter) >= 0)
            resOptions.push({
                key: 'Quarter',
                text: language.Common.Quarter,
                iconProps: { iconName: 'CalendarWorkWeek' },
                onClick: () => SetResolution(TimelineResolution.Quarter),
            });
        if (allowedResolutions.indexOf(DateResolution.Month) >= 0)
            resOptions.push({
                key: 'Month',
                text: language.Common.Month,
                iconProps: { iconName: 'Calendar' },
                onClick: () => SetResolution(TimelineResolution.Month),
            });
        if (allowedResolutions.indexOf(DateResolution.Week) >= 0)
            resOptions.push({
                key: 'Week',
                text: language.Common.Week,
                iconProps: { iconName: 'CalendarWeek' },
                onClick: () => SetResolution(TimelineResolution.Week),
            });
        if (allowedResolutions.indexOf(DateResolution.Day) >= 0)
            resOptions.push({
                key: 'Day',
                text: language.Common.Day,
                iconProps: { iconName: 'CalendarDay' },
                onClick: () => SetResolution(TimelineResolution.Day),
            });
        setResolutionOptions(resOptions);
    };

    const TimelineChanged = (startIndex: number, endIndex: number, data?: Array<DateCell>) => {
        const allCells = data || cells;
        // guard
        if (startIndex < 0) startIndex = 0;
        if (allCells != null && endIndex > allCells.length - 1) endIndex = allCells.length - 1;
        // get dates
        const start = allCells[startIndex].Start;
        const end = allCells[endIndex < allCells.length ? endIndex : allCells.length - 1].End;
        // update
        // props.OnTimeLineChanged(start, end, resolution);
        setTimeline({ start, end, resolution });
    };

    const SetRangeIndexes = (start: number, end: number) => {
        setSelectedIndexes(new SelectedIndexes(start, end));
    };

    const Initialze = () => {
        SetCellWidth();
        SetupResolutionOptions();
    };

    const SetCellWidth = useCallback((): void => {
        let cellWidth = CellWidth;
        // set cellwidth
        switch (resolution) {
            case TimelineResolution.Day:
                cellWidth = 45;
                break;
            case TimelineResolution.Week:
                cellWidth = 55;
                break;
            case TimelineResolution.Month:
                cellWidth = 65;
                break;
            case TimelineResolution.Quarter:
                cellWidth = 55;
                break;
            case TimelineResolution.Year:
                cellWidth = 75;
                break;
            default:
                break;
        }
        // set cellWidth
        // document.documentElement.style.setProperty('--timelineCellWidth', cellWidth+"")
        document.documentElement.style.setProperty('--timelineCellWidth', String(cellWidth));
        // set boundaries
        _startBoundary.current = 0;
        const cellCount = Math.round(window.screen.width / CellWidth);
        _cellCount.current = cellCount;
        _endBoundary.current = cellCount;
    }, [resolution]);

    const LoadData = (start?: Date, end?: Date): Array<DateCell> => {
        // get date range for selector
        // const dates = TimelineUtil.getTimelineHistoricalStartEnd(resolution, OverviewContext.Settings);
        const dates = TimelineUtil.getTimelineHistoricalStartEnd(resolution, uiSettings.settings);

        const cellss = TimelineUtil.generateDates(dates.start, dates.end, resolution);
        setHeaderCell(TimelineUtil.generateHeaders(cellss, resolution, uiSettings.settings));
        setCells(cellss);
        if (_cellCount.current > cellss.length) {
            _cellCount.current = cellss.length;
            _endBoundary.current = cellss.length;
        }
        if (OverviewContext.AllowTimelineBackInTime()) SetOffset(cellss);
        return cellss;
    };

    const SetOffset = (cells: Array<DateCell>): void => {
        const today = DateEx.todaysDate();
        const todayCell = cells.find(_ => _.Start <= today && _.End >= today);
        if (todayCell == null) return;
        _offSet.current = todayCell.Index;

        if (OverviewContext.AllowTimelineBackInTime()) {
            _startBoundary.current += _offSet.current;
            _endBoundary.current += _offSet.current;
            _viewIndex.current = _offSet.current;
        }
    };

    const ResetView = (forceSelection: boolean = false): void => {
        const today = DateEx.todaysDate();
        const todayCell = cells.find(_ => _.Start <= today && _.End >= today);
        if (todayCell == null) return;
        _offSet.current = todayCell.Index;

        if (OverviewContext.AllowTimelineBackInTime()) {
            _startBoundary.current += _offSet.current;
            _endBoundary.current += _offSet.current;
            _viewIndex.current = _offSet.current;
        }

        document.documentElement.style.setProperty('--timelineOffSet', _offSet.current + '');
        UpdateSelection(todayCell.Index, todayCell.Index + (forceSelection ? GetDefaultColumnCount(gridType) : selectedIndexes.End - selectedIndexes.Start));
    };

    const HandleResolutionChange = (): void => {
        // get date range for selector
        // const dates = TimelineUtil.getTimelineHistoricalStartEnd(resolution, OverviewContext.Settings);
        const dates = TimelineUtil.getTimelineHistoricalStartEnd(resolution, uiSettings.settings);

        const cellss = TimelineUtil.generateDates(dates.start, dates.end, resolution);
        setHeaderCell(TimelineUtil.generateHeaders(cellss, resolution, uiSettings.settings));
        setCells(cellss);
        const today = DateEx.todaysDate();
        const todayCell = cellss.find(_ => _.Start <= today && _.End >= today);
        if (todayCell == null) return;

        UpdateSelection(todayCell.Index, todayCell.Index + GetDefaultColumnCount(gridType), cellss, null);
    };

    const PushSelectedRange = elementIndex => {
        const diff = elementIndex - selectedIndexes.Start;
        let newEnd = selectedIndexes.End + diff;
        if (newEnd > cells.length - 1) newEnd = cells.length - 1;
        // update
        UpdateSelection(elementIndex, newEnd);
    };

    const MouseDown = event => {
        // right click
        if (IsRightClick(event)) {
            const elementIndex = GetDateCellIndex(event.currentTarget.id);
            PushSelectedRange(elementIndex);

            event.preventDefault();
            event.stopPropagation();
            return false;
        }

        _isDragging.current = true;
        // handle move
        const index = GetSelectedDateCellIndex(event.currentTarget.id);
        if (index >= 0 && !event.ctrlKey) {
            _isDragging.current = false;
            _isMovingRange.current = true;
            _movingRangeIndex.current = index;
            return;
        }

        const clickedIndex = GetDateCellIndex(event.currentTarget.id);
        SetRangeIndexes(clickedIndex, clickedIndex);
    };

    const MouseMove = (event: any) => {
        if (_moveTimestamp.current + 50 > event.timeStamp) return;
        _moveTimestamp.current = event.timeStamp;
        Move(GetDateCellIndex(event.currentTarget.id));
    };

    const Scroll = (event: React.WheelEvent<any>) => {
        // event.persist()
        if (_scrollTimestamp.current + 50 > event.timeStamp) return;
        _scrollTimestamp.current = event.timeStamp;

        const offSet = +document.documentElement.style.getPropertyValue('--timelineOffSet');
        let newOffSet = offSet + (event.deltaY > 0 ? 3 : -3);

        if (newOffSet < 0) {
            newOffSet = 0;
        }
        // if (OverviewContext.AllowTimelineBackInTime() && newOffSet >= cells.length - _cellCount.current + 3) {
        // 	newOffSet = cells.length - _cellCount.current + 5;
        // }
        _viewIndex.current = newOffSet;

        document.documentElement.style.setProperty('--timelineOffSet', newOffSet + '');
    };

    const Move = (elementIndex: number) => {
        let newStart: number;
        let newEnd: number;

        // update drag select index
        if (_isDragging.current) {
            _isMoving.current = true;
            newEnd = elementIndex;
            // set selected indexes
            SetRangeIndexes(newStart >= 0 ? newStart : selectedIndexes.Start, newEnd >= 0 ? newEnd : selectedIndexes.End);
        }
        // update selected indexes when dragging range
        if (_isMovingRange.current) {
            _isMoving.current = true;

            newStart = selectedIndexes.Start + elementIndex - _movingRangeIndex.current;
            newEnd = selectedIndexes.End + elementIndex - _movingRangeIndex.current;
            _movingRangeIndex.current = elementIndex;
            // set selected indexes
            SetRangeIndexes(newStart, newEnd >= 0 ? newEnd : selectedIndexes.End);
        }
        if (_viewIndex.current >= 0 && newStart < _viewIndex.current && newStart >= 0) {
            const moveCount = 2;

            _viewIndex.current -= moveCount;

            const offSet = +document.documentElement.style.getPropertyValue('--timelineOffSet');
            document.documentElement.style.setProperty('--timelineOffSet', offSet - moveCount + '');
        }
        if (newEnd > _viewIndex.current + _cellCount.current - 5 && newEnd <= cells.length) {
            const moveCount = 2;

            _viewIndex.current += moveCount;

            if (newEnd + moveCount <= cells.length) {
                const offSet = +document.documentElement.style.getPropertyValue('--timelineOffSet');
                document.documentElement.style.setProperty('--timelineOffSet', offSet + moveCount + '');
            }
        }

        if (typeof window.event.preventDefault !== 'undefined') window.event.preventDefault();
    };

    const IsRightClick = (event: any): boolean => {
        if (event.which) return event.which === 3;
        else if (event.button) return event.button === 2;
        return false;
    };

    const MouseUp = event => {
        if (!_isDragging.current && !_isMovingRange.current) return false;

        // single click
        if (!_isMoving.current) {
            SetRangeIndexes(selectedIndexes.Start, selectedIndexes.Start);
        }

        _isDragging.current = false;
        _isMoving.current = false;
        _isMovingRange.current = false;

        // reverse select - swap start and end
        if (selectedIndexes.End < selectedIndexes.Start) {
            const oldStart = selectedIndexes.Start;
            // update
            UpdateSelection(selectedIndexes.End, oldStart);
            return;
        }

        document.documentElement.onselectstart = function () {
            return true;
        };

        // update
        TimelineChanged(selectedIndexes.Start, selectedIndexes.End);
    };

    const GetSelectedDateCellIndex = (id: string): number => {
        const cellId = +id.replace('tp-timeline-cell-', '');
        return cells[cellId].Selected ? cellId : -1;
    };

    const GetDateCellIndex = (id: string): number => {
        return +id.replace('tp-timeline-cell-', '');
    };

    const GetCellCount = (headerIdx: number): number => {
        let count = 0;
        Object.keys(headerCell).forEach((key, idx) => {
            if (idx >= headerIdx) return;
            count += headerCell[key];
        });
        return count;
    };

    /* window width minus resource column divided by column width */
    const GetDefaultColumnCount = (gridType?: GridType): number => {
        gridType = gridType ? gridType : gridType;

        let columnWidth = getColumnWidth(3);
        switch (gridType) {
            case GridType.Allocation:
                // columnWidth = 270;
                columnWidth = getColumnWidth(3);
                break;
            case GridType.Request:
                // columnWidth = 190;
                columnWidth = getColumnWidth(2);
                break;
            case GridType.ResourceCapacity:
                // columnWidth = 95;
                columnWidth = getColumnWidth(1);
                break;
            case GridType.LmCapacityProjects:
                // columnWidth = 270;
                columnWidth = getColumnWidth(3);
                break;
            case GridType.ResourcePlanner:
                // columnWidth = 270;
                columnWidth = getColumnWidth(3);
                break;
            default:
                break;
        }

        let columnCount =
            Math.trunc((window.innerWidth - parseInt(getComputedStyle(document.documentElement).getPropertyValue('--gridRowWidth')) - 10) / columnWidth) - 1;
        if (currentUnitType !== UnitType.FTE) columnCount--;
        return columnCount;
    };

    const HeaderClick = (index: number, key: string) => {
        let cellCount = GetCellCount(index);
        let offSet =
            OverviewContext.AllowTimelineBackInTime() && cellCount + _cellCount.current > cells.length - 1 ? cells.length - _cellCount.current + 5 : cellCount;

        _viewIndex.current = offSet - 1;
        const newEnd = GetCellCount(index + 1) - 1;
        if (resolution === TimelineResolution.Week) {
            if (cells[cellCount].Start.getDate() <= 7 && cells[cellCount].Start.getDate() >= 0) {
                cellCount -= 1;
                offSet -= 1;
            }
        }
        document.documentElement.style.setProperty('--timelineOffSet', offSet + '');

        // update
        UpdateSelection(cellCount, newEnd);
    };

    return (
        <div className="top-wrapper" onMouseUp={MouseUp} onWheel={Scroll}>
            {cells && (
                <>
                    <div className="timeline-buttons">
                        <TooltipHost content={language.Timeline.ChangeResolution}>
                            <IconButton id="timelinemenubutton" iconProps={{ iconName: 'CalendarSettings' }} onClick={e => setShowResolutionSelector(true)} />
                        </TooltipHost>
                        <TooltipHost content={language.Timeline.ResetView}>
                            <IconButton id="timelineresetbutton" iconProps={{ iconName: 'Refresh' }} onClick={e => ResetView(true)} />
                        </TooltipHost>
                    </div>
                    <TransitionGroup>
                        <CSSTransition key={resolution} timeout={500} classNames="messageout">
                            <table className={`tp-timeline-modern-table tp-timeline-modern-animation`}>
                                <tbody>
                                    <tr>
                                        {Object.keys(headerCell).map((key, idx) => (
                                            <td colSpan={headerCell[key]} onClick={e => HeaderClick(idx, key)} key={`tp-timeline-header-${key}-${idx}`}>
                                                <TimeLineHeaderCell
                                                    Title={key}
                                                    spanOnlyOne={true} //headerCell[key] == 1 ? true : false} // this was never good :) - ewi
                                                    Offset={
                                                        _viewIndex.current > GetCellCount(idx) && _viewIndex.current < GetCellCount(idx + 1)
                                                            ? (_viewIndex.current - GetCellCount(idx)) * (CellWidth + 2)
                                                            : 30
                                                    }
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        {cells.map((_, i) => {
                                            return (
                                                <td style={{ left: `${0 + i * CellWidth}px` }} key={`tp-timeline-header-${_}-${i}`}>
                                                    <TimeLineCell
                                                        CellTitle={TimelineUtil.cellTitle(_.Start, _.End, resolution, uiSettings.settings)}
                                                        Selected={_.Selected}
                                                        MouseDown={MouseDown}
                                                        MouseMove={MouseMove}
                                                        Index={i}
                                                    />
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </CSSTransition>
                    </TransitionGroup>

                    <div id="timelinemenu">
                        <Callout
                            role="dialog"
                            gapSpace={0}
                            target="#timelinemenubutton"
                            onDismiss={() => setShowResolutionSelector(false)}
                            setInitialFocus={true}
                            hidden={!showResolutionSelector}
                        >
                            <div className="tp-timeline-classic-settings">
                                <ChoiceGroup
                                    label={language.Timeline.PickResolution}
                                    defaultSelectedKey={TimelineResolution[resolution].toString()}
                                    options={resolutionOptions}
                                />
                            </div>
                        </Callout>
                    </div>
                </>
            )}
        </div>
    );
};

// eslint-disable-next-line react-refresh/only-export-components
export default memo(TimelineModern);

const getColumnWidth = (cellCount: number) => {
    const spacingAmount = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--spacingAmount'));
    const cellWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--cellWidth'));
    // cellCount * cellWidth + margin * 2 + container padding * 2 + 17, just some extra i guess
    return cellCount * (cellWidth + 2 * spacingAmount * 2) + 2 * (16 * spacingAmount) + 17;
};
