import { DateEx } from "../../../../Utilities/DateEx";
import { isNumeric } from "../../../../Utilities/NumberEx";

export const getNestedProperty = (nestedObj: any, pathArr: string[]) => pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);

export const parseEntityKey = <T extends object>(obj: T, propPath: string) => {
	const path = propPath.split(".");

	if (path.length === 1) {
		path[0] = Object.hasOwn(obj, `${path[0]}AsString`) ? `${path[0]}AsString` : path[0]; //`${path[0]}AsString`;
	}
	if (path.length > 1) {
		// we have an AsString equivilant
		if (obj['customPropertiesAsString'] && Object.hasOwn(obj['customPropertiesAsString'], path[1])) {
			path[0] = path[0].replace("customProperties", "customPropertiesAsString");
		}
		// if not it will default to the raw value (is there any issues with this?) (ewi)
	}
	return path;
}


export const getNestedEntityProperty = <T extends object>(entity: T, key: string) => getNestedProperty(entity, parseEntityKey(entity, key))

const tryParseDate = (obj: any, defaultDate: Date | null = null) => {
	if (Date.parse(obj)) {
		return new Date(obj);
	} else {
		return defaultDate;
	}
}

// let's try to support special cases and dates (ewi)
export const sortOverview = <T extends object>(a: T, b: T, columnKey: string, isSortedDescending?: boolean, specialCases?: (value) => void): number => {
	let valueA = getNestedProperty(a, parseEntityKey(a, columnKey));
	let valueB = getNestedProperty(b, parseEntityKey(b, columnKey));

	// special cases - hardcoded (ewi)
	if (specialCases) {
		valueA = specialCases(valueA);
		valueB = specialCases(valueB);
	}

    if (valueA === valueB) { // identical? return 0
		return 0;
	} else if (valueA == null || valueA === '') { // a is null? last 
		return isSortedDescending ? 1 : -1;
	} else if (valueB == null || valueB === '') { // b is null? last
		return isSortedDescending ? -1 : 1;
	}

	// numbers
	if (isNumeric(valueA) || isNumeric(valueB)) {
		const numA = parseFloat(valueA);
		const numB = parseFloat(valueB);
		return (isSortedDescending ? numA < numB : numA > numB) ? 1 : -1;
	}

	// dates
	if (typeof valueA === 'string' || typeof valueB === 'string') {
		// the 'string' is in fact a serialized date
		if (DateEx.isValidISODate(valueA) || DateEx.isValidISODate(valueB)) {
			valueA = tryParseDate(valueA, new Date(0));
			valueB = tryParseDate(valueB, new Date(0));
		} else {
			return valueA?.localeCompare(valueB) * (isSortedDescending ? -1 : 1); // compare, negate if descending
		}
	}

	if (valueA instanceof Date || valueB instanceof Date) {
		return (isSortedDescending ? valueB.getTime() - valueA.getTime() : valueA.getTime() - valueB.getTime());
	}


	return (isSortedDescending ? valueA < valueB : valueA > valueB) ? 1 : -1;
}