export enum EntityType {
	Project = 1,
	Resource = 2,
	Contract = 3,
	ContentConfiguration = 9,
	Settings = 10,
	Rule = 11,
	WorkProfile = 12,
	Schedule = 15,
	Integration = 20,
	ResourceManager = 25,
	ContractVersion = 50,
	Scenario = 51,
	Changeset = 1024,
	Log = 2048
}