import { useMemo } from 'react';
import { clamp, lerp, range } from '../../../../_grid/helpers/colors';
import { addPercent } from '../../../../resourceplanner/components/Grid/hooks/useHeatmapBackground';

export const useHeatmapBackground = ({
    // totalCapacity,
    classicColors,
    maxOverAllocationPercent,
    maxUnderAllocationPercent,
    // totalAllocation,
    totalRequest,
    value,
    colorSaturation,
    lightness,
    colorOffset = 0,
    invertSpectrum,
    equalAllocationColor,
    overAllocationColor,
    underAllocationColor,
    noActivityColor,
}: CalcBackgroundColorProps & {
    // value: number;
    // totalCapacity: number;
    // maxOverAllocationPercent: number;
    // maxUnderAllocationPercent: number;
    // colorSaturation?: number;
    // lightness?: number;
    // colorOffset?: number;
    // invertSpectrum?: boolean;
    classicColors: boolean;
    equalAllocationColor: string;
    overAllocationColor: string;
    underAllocationColor: string;
    noActivityColor?: string | undefined | void;
}) => {
    return useMemo(() => {
        if (value === 0 && noActivityColor) {
            return noActivityColor;
            //
        }
        if (classicColors) {
            if (value < 0) {
                return underAllocationColor;
            } else if (value === 0) {
                return equalAllocationColor;
            } else if (value > 0) {
                return overAllocationColor;
            }
        }
        return calcBackgroundColor({
            maxOverAllocationPercent,
            maxUnderAllocationPercent,
            // totalAllocation: totalCapacity,
            // totalAllocation,
            totalRequest,
            value,
            colorOffset,
            colorSaturation,
            invertSpectrum,
            lightness,
        });
    }, [
        classicColors,
        colorOffset,
        colorSaturation,
        equalAllocationColor,
        invertSpectrum,
        lightness,
        maxOverAllocationPercent,
        maxUnderAllocationPercent,
        noActivityColor,
        overAllocationColor,
        underAllocationColor,
        value,
        // totalAllocation,
        totalRequest,
    ]);
};

const getHSLString = (deg: number, saturation = 90, lightness = 75) => `hsl(${deg.toFixed(0)}deg, ${saturation}%, ${lightness}%)`;
const calcDeg = (percent: number, invert?: boolean) => (invert ? clamp(lerp(0, 120, percent), 0, 120) : clamp(lerp(120, 0, percent), 0, 120));

export type CalcBackgroundColorProps = {
    value: number;
    // totalAllocation: number;
    totalRequest: number;
    maxOverAllocationPercent: number;
    maxUnderAllocationPercent: number;
    colorSaturation?: number;
    lightness?: number;
    colorOffset?: number;
    invertSpectrum?: boolean;
};
export function calcBackgroundColor({
    maxOverAllocationPercent,
    maxUnderAllocationPercent,
    // totalAllocation,
    totalRequest,
    value,
    colorSaturation,
    lightness,
    colorOffset = 0,
    invertSpectrum,
}: CalcBackgroundColorProps) {
    // 0deg = red
    // 60deg = yellow
    // 120deg = green

    // Over allocation
    if (value < 0) {
        const val = Math.abs(value);
        const maxOverAllocation = addPercent(totalRequest, maxOverAllocationPercent);
        const ranged =  rangedWithCaps(0, maxOverAllocation, 0, 1, val, totalRequest) || 0; // coercing to 0 if NaN;
        const deg = calcDeg(ranged, invertSpectrum) || 0;
        return getHSLString(deg + (colorOffset || 0), colorSaturation, lightness);
    }
    const val = value;
    const maxUnderAllocation = addPercent(totalRequest, maxUnderAllocationPercent);
    const ranged = rangedWithCaps(0, maxUnderAllocation, 0, 1, val, totalRequest) || 0;
    const deg = calcDeg(ranged, invertSpectrum) || 0;
    return getHSLString(deg + (colorOffset || 0), colorSaturation, lightness);
}

const rangedWithCaps = (x1: number, y1: number, x2: number, y2: number, value: number, totalRequest: number) => {
	if (value === 0 && totalRequest > 0) return 0
	if (value > 0 && totalRequest === 0) return 1
	return range(x1, y1, x2, y2, value);
}
