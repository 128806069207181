import { useUISettings } from '../context/network/http/QueryProvider/queries/UISettings';
import { Project } from '../api/generated/data-contracts';
import { string } from '../forms/helpers/string';
import { useMemo } from 'react';

interface AdditionalOwnerRestrictions {
    restrictedProperties: string[];
}

export const useGetAdditionalOwnerRestrictions = (project: Project): AdditionalOwnerRestrictions => {
    const { data: uiSettings } = useUISettings();

    const restrictions: AdditionalOwnerRestrictions = useMemo(() => {
        // guard for config null
        if (uiSettings.settings.limitAdditionalOwnerPropertyEditing == null) {
            return { restrictedProperties: [] };
        }
        const currentResourceId = uiSettings.resource.id;
        // if not owner and additionalowner
        if (project?.owner !== currentResourceId && project?.additionalOwners?.some(additionalOwnerId => additionalOwnerId === currentResourceId)) {
            return { restrictedProperties: uiSettings.settings.limitAdditionalOwnerPropertyEditing.map((prop: string) => string.lowerCaseFirstLetter(prop)) };
        }
        return { restrictedProperties: [] };
    }, [project, uiSettings]);

    return restrictions;
}