import { TextField, DetailsList, CommandBar, SelectionMode, DatePicker, DayOfWeek, ColorPicker, IColor, IColumn } from '@fluentui/react';
import { Selection } from '@fluentui/react/lib/DetailsList';
import { IDs } from '../../Utilities/IDs';
import { PFDialog } from './PFDialog';
import { IPFListColumn } from '../../Entities/IPFListColumn';
import { ArrayEx } from '../../Utilities/ArrayEx';
import { language } from '../../Services/LocalizationService';
import { Component } from 'react';

interface IProps<T> {
    Columns: Array<IPFListColumn>;
    ItemType: { new (): T };
    EnrichNewItem?: (item: T) => void;
    ItemIdentifier: (item: T) => any;
    Items: Array<T>;
    ChangesCallback?: (items: Array<T>) => void;
    ReadOnly?: boolean;
    FixedItems?: boolean;
    DisabledColumns?: Array<string>;
    DialogTitle: string;
    ExtraButtons?: Array<{ title: string; iconName: string; onClick: (item: T) => void }>;
    SelectedItemDefaultAsNull?: boolean;
    MaxWidth?: string;
}

interface IState<T> {
    Items: Array<T>;
    ShowDialog: boolean;
    SelectedItem: T;
    EditMode: boolean;
    Columns: Array<IColumn>;
}

export class PFList<T> extends Component<IProps<T>, IState<T>> {
    constructor(props) {
        super(props);
        this.state = {
            ShowDialog: false,
            Items: [],
            SelectedItem: this.ResetSelectedItem(),
            EditMode: false,
            Columns: this.props.Columns,
        };

        this._selection = new Selection();

        if (this.props.FixedItems)
            this._commands = [
                {
                    key: 'edit',
                    name: language.Common.Edit,
                    onClick: e => {
                        e.preventDefault();
                        this.setState({ ShowDialog: true, EditMode: true });
                    },
                    iconProps: { iconName: 'Edit' },
                },
            ];

        if (this.props.ReadOnly) this._commands = [];

        if (this.props.ExtraButtons) {
            this.props.ExtraButtons.forEach(_ => {
                this._commands.push({
                    key: _.title,
                    name: _.title,
                    onClick: e => {
                        e.preventDefault();
                        _.onClick(this.state.SelectedItem);
                    },
                    iconProps: { iconName: _.iconName },
                });
            });
        }
    }

    private _selection: Selection;

    // used in parent components
    public SetItems = (items: Array<T>): void => {
        this.setState({ Items: [...items] });
    };

    async componentWillReceiveProps(nextProps: IProps<T>) {
        if (!nextProps.Items) return;
        this.setState({ Items: [...nextProps.Items] });
    }

    async componentDidMount() {
        if (!this.props.Items) return;
        this.setState({ Items: [...this.props.Items] || [] });
    }

    private OnItemChange = (prop: string, value: any) => {
        const item = this.state.SelectedItem;
        item[prop] = value;
    };

    private _commands = [
        {
            key: 'new',
            name: language.Common.New,
            onClick: e => {
                e.preventDefault();
                this.setState({ ShowDialog: true, EditMode: false, SelectedItem: this.ResetSelectedItem() });
            },
            iconProps: { iconName: 'Add' },
        },
        {
            key: 'edit',
            name: language.Common.Edit,
            onClick: e => {
                e.preventDefault();
                this.setState({ ShowDialog: true, EditMode: true });
            },
            iconProps: { iconName: 'Edit' },
        },
        {
            key: 'remove',
            name: language.Common.Remove,
            onClick: e => {
                e.preventDefault();
                this.RemoveItem();
            },
            iconProps: { iconName: 'Delete' },
        },
    ];

    private NewItem = (): void =>
        this.ItemAction((items, i) => {
            if (this.props.EnrichNewItem) this.props.EnrichNewItem(this.state.SelectedItem);
            items.push(this.state.SelectedItem);
        });

    private EditItem = (): void => this.ItemAction((items, i) => (items[i] = this.state.SelectedItem));

    private RemoveItem = (): void => this.ItemAction((items, i) => items.splice(i, 1));

    private ItemAction = (action: (t: Array<T>, index: number) => any): void => {
        this._selection.setAllSelected(false);

        const index = this.state.Items.findIndex(_ => this.props.ItemIdentifier(_) === this.props.ItemIdentifier(this.state.SelectedItem));
        const items = [...this.state.Items];
        action(items, index);

        const propsIndex = this.props.Items.findIndex(_ => this.props.ItemIdentifier(_) === this.props.ItemIdentifier(this.state.SelectedItem));
        action(this.props.Items, propsIndex);

        this.setState({ Items: items, SelectedItem: this.ResetSelectedItem(), ShowDialog: false }, () => {
            if (this.props.ChangesCallback) this.props.ChangesCallback(items);
        });
    };

    private OnColumnClick = async (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => {
        const newColumns: IColumn[] = [...this.state.Columns];
        const currColumn: IColumn = newColumns.find(currCol => column.key === currCol.key);

        newColumns.forEach((col: IColumn) => {
            if (col === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                col.isSorted = false;
                col.isSortedDescending = null;
            }
        });

        const newItems = ArrayEx.sort([...this.state.Items], column.isSortedDescending ? `-${column.fieldName}` : `${column.fieldName}`);
        this.setState({ Columns: newColumns, Items: newItems });
    };

    private ResetSelectedItem = (): T => {
        return this.props.SelectedItemDefaultAsNull ? null : (new this.props.ItemType() as T);
    };

    render() {
        return (
            <div>
                <div style={{ maxWidth: this.props.MaxWidth ?? '600px' }}>
                    <PFDialog
                        buttonText={language.Common.Save}
                        className="tp-dialog"
                        showDialog={this.state.ShowDialog}
                        title={this.props.DialogTitle}
                        callback={this.state.EditMode ? this.EditItem : this.NewItem}
                        buttonDefaultDisabled={false}
                        dismissCallback={() => {
                            this._selection.setAllSelected(false);
                            this.setState({ ShowDialog: false, SelectedItem: this.ResetSelectedItem() });
                        }}
                    >
                        {this.props.Columns.map((_, idx) => {
                            switch (_.valueType) {
                                case 'text':
                                    return (
                                        <TextField
                                            key={IDs.makeId()}
                                            label={_.name}
                                            defaultValue={this.state.SelectedItem ? this.state.SelectedItem[_.fieldName] : ''}
                                            onChange={(obj, value) => this.OnItemChange(_.fieldName, value)}
                                            disabled={this.props.DisabledColumns && this.props.DisabledColumns.findIndex(dc => dc === _.key) >= 0}
                                            autoFocus={idx === 0}
                                        />
                                    );
                                case 'date':
                                    return (
                                        <DatePicker
                                            key={IDs.makeId()}
                                            label={_.name}
                                            firstDayOfWeek={DayOfWeek.Monday}
                                            placeholder={language.PFList.SelectDate}
                                            showWeekNumbers={true}
                                            firstWeekOfYear={1}
                                            showMonthPickerAsOverlay={true}
                                            isRequired={false}
                                            allowTextInput={false}
                                            value={this.state.SelectedItem ? this.state.SelectedItem[_.fieldName] : null}
                                            onSelectDate={date => this.OnItemChange(_.fieldName, date)}
                                            disabled={this.props.DisabledColumns && this.props.DisabledColumns.findIndex(dc => dc === _.key) >= 0}
                                        />
                                    );
                                case 'number':
                                    return (
                                        <TextField
                                            key={IDs.makeId()}
                                            label={_.name}
                                            type="number"
                                            onChange={(obj, value) => this.OnItemChange(_.fieldName, +value)}
                                            defaultValue={this.state.SelectedItem ? this.state.SelectedItem[_.fieldName] : 0}
                                            disabled={this.props.DisabledColumns && this.props.DisabledColumns.findIndex(dc => dc === _.key) >= 0}
                                            autoFocus={idx === 0}
                                        />
                                    );
                                case 'color':
                                    return (
                                        <ColorPicker
                                            key={IDs.makeId()}
                                            color={this.state.SelectedItem ? this.state.SelectedItem[_.fieldName] : null}
                                            alphaSliderHidden={true}
                                            onChange={(event, color: IColor) => this.OnItemChange(_.fieldName, color.str)}
                                        />
                                    );
                                //TODO: support enums - ewi
                                case 'enum':
                                    return <p>{language.PFList.EnumsNotSupported}</p>;
                            }
                        })}
                    </PFDialog>
                    {<CommandBar items={this._commands} />}
                    <DetailsList
                        columns={this.state.Columns}
                        items={this.state.Items}
                        selectionMode={SelectionMode.single}
                        selectionPreservedOnEmptyClick={false}
                        selection={this._selection}
                        onActiveItemChanged={item => this.setState({ SelectedItem: { ...item } })}
                        onColumnHeaderClick={(ev, column) => this.OnColumnClick(ev, column)}
                    />
                </div>
            </div>
        );
    }
}
