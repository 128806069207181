import { DirectionalHint } from "@fluentui/react";
import React, { FunctionComponent } from "react";
import { language } from "../../Services/LocalizationService";
import { CoachmarkInfo } from "../CoachmarkInfo";
import { PFCoachmark } from "../PFCoachmark";



export const RootSettingsEditorCoachmarks: FunctionComponent<any> = React.memo(props => {	
	const _navGroupings  = new CoachmarkInfo("rootsettings-groupings", ".tp-object-editor-nav-menu", language.Coachmarks.AdminDashboard.NavGroupings, DirectionalHint.rightCenter);

	return	<>
				<PFCoachmark CoachmarkInfo={_navGroupings} />
			</>
})