import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";

export class ResourceDistributionResult {
	public ResourceId: string
	@PFPropertyBasicInfo(PropertyType.String, "Resource")
	@PFPropertyRender(null,null,null,null,null,200)
	public ResourceName: string
	@PFPropertyBasicInfo(PropertyType.Date, "Start")
	@PFPropertyRender(null,null,null,null,null,150)
	public Start: Date;
	@PFPropertyBasicInfo(PropertyType.Date, "End")
	@PFPropertyRender(null,null,null,null,null,150)
	public End: Date;
	@PFPropertyBasicInfo(PropertyType.Number, "Allocated")
	@PFPropertyRender(null,null,null,null,null,100)
	public AllocationSum: number;
}
