import { guid } from "../../helpers/guid";
import { CellType } from "./CellType";
export class Cell {
	public Value: number;
	public Disabled: boolean;
	public Id: string;
	public ParentId: string;
	public Type: CellType;
	public Properties: { [key: string]: any }
	// only used in ui
	public ReRender: boolean;

	constructor() {
		this.Id = guid.newGuid();
	}
}

export const makeCell = (cellInfo: Omit<ICell, "Id">): ICell => {
	return {
		...cellInfo,
		Id: guid.newGuid(),
	}
}

export interface ICell {
	Value: number;
	Disabled: boolean;
	Id: string;
	ParentId: string;
	Type: CellType;
	Properties: {
		[key: string]: any
	}
	ReRender: boolean;
}