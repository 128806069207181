import { useLayoutEffect, useRef } from "react";
import { Timeout } from "../types/runtimeTypes";
import { useEvent } from "./useEvent"

export function useDebouncedEventHandlerAsync<Cb extends (...args: any[]) => any>(handler: Cb, timeout?: number): (...args: Parameters<Cb>) => Promise<Awaited<ReturnType<Cb>>>;
export function useDebouncedEventHandlerAsync<Cb extends (...args: any[]) => any, CbImmediately extends (...args: Parameters<Cb>) => void | Promise<void>>(handler: Cb, immediately?: CbImmediately, timeout?: number): (...args: Parameters<Cb>) => Promise<Awaited<ReturnType<Cb>>>;
export function useDebouncedEventHandlerAsync<Cb extends (...args: any[]) => any, CbImmediately extends (...args: Parameters<Cb>) => void | Promise<void>>(handler: Cb, timeoutOrImmediately?: CbImmediately, timeout?: number): (...args: Parameters<Cb>) => Promise<Awaited<ReturnType<Cb>>> {
    const timeoutToUse = (typeof timeoutOrImmediately === 'number' ? timeoutOrImmediately : timeout) ?? 200;
    const immediately = typeof timeoutOrImmediately === 'function' && timeoutOrImmediately;
    const timer = useRef<Timeout | null>(null);
    const debouncedHandler = useEvent((...args: Parameters<Cb>) => new Promise<Awaited<ReturnType<Cb>>>((res, rej) => {
        if (timer.current !== null) {
            clearTimeout(timer.current);
        }
        if (immediately) {
            immediately(...args);
        }
        timer.current = setTimeout(async () => {
			try {
				res(await handler(...args));
			} catch (error) {
				rej(error)
			}
        }, timeoutToUse);
    }));
    useLayoutEffect(() => {
        return () => {
            if (timer.current !== null) {
                clearTimeout(timer.current);
            }
        };
    }, []);
    return debouncedHandler;
}


export function useDebouncedEventHandler<T extends (...args: any[]) => void | Promise<void>>(handler: T, timeout?: number): (...args: Parameters<T>) => void;
export function useDebouncedEventHandler<T extends (...args: any[]) => void | Promise<void>>(handler: T, immediately?: T, timeout?: number): (...args: Parameters<T>) => void;
export function useDebouncedEventHandler<T extends (...args: any[]) => void | Promise<void>>(handler: T, timeoutOrImmediately?: T | number, timeout?: number): (...args: Parameters<T>) => void {
    const timeoutToUse = (typeof timeoutOrImmediately === 'number' ? timeoutOrImmediately : timeout) ?? 200;
    const immediately = typeof timeoutOrImmediately === 'function' && timeoutOrImmediately;
    const timer = useRef<Timeout | null>(null);
    const debouncedHandler = useEvent((...args: Parameters<T>) => {
        if (timer.current !== null) {
            clearTimeout(timer.current);
        }
        if (immediately) {
            immediately(...args);
        }
        timer.current = setTimeout(() => {
            handler(...args);
        }, timeoutToUse);
    });
    useLayoutEffect(() => {
        return () => {
            if (timer.current !== null) {
                clearTimeout(timer.current);
            }
        };
    }, []);
    return debouncedHandler;
}
