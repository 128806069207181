export class FluentUIThemeProps {
	public themePrimary = '#0078d4';
	public themeLighterAlt = '#eff6fc';
	public themeLighter = '#deecf9';
	public themeLight = '#c7e0f4';
	public themeTertiary = '#71afe5';
	public themeSecondary = '#2b88d8';
	public themeDarkAlt = '#106ebe';
	public themeDark = '#005a9e';
	public themeDarker = '#004578';
	public neutralLighterAlt = '#faf9f8';
	public neutralLighter = '#f3f2f1';
	public neutralLight = '#edebe9';
	public neutralQuaternaryAlt = '#e1dfdd';
	public neutralQuaternary = '#d0d0d0';
	public neutralTertiaryAlt = '#c8c6c4';
	public neutralTertiary = '#a19f9d';
	public neutralSecondary = '#605e5c';
	public neutralPrimaryAlt = '#3b3a39';
	public neutralPrimary = '#323130';
	public neutralDark = '#201f1e';
	public black = '#000000';
	public white = '#ffffff';
}