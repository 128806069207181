import React, { useEffect, useState } from 'react';
import { createContext } from '../hooks/createContext';
import { Timeout } from '../types/runtimeTypes';

export interface IIdleContext {
    isIdle: boolean;
}

const [useCtx, Provider] = createContext<IIdleContext>();

// eslint-disable-next-line react-refresh/only-export-components
export const useIdleContext = useCtx;

export interface IIdleContextProps {
    timeout?: number;
    idleChangeHandler?: ((idle: boolean) => void) | ((idle: boolean) => void)[];
}

export const IdleContext: React.FC<IIdleContextProps> = ({ children, timeout = 1000 * 60 * 5 }) => {
    const [isIdle, setIsIdle] = useState(false);
    useEffect(() => {
        let _timeout: Timeout;
        const handler = () => {
            clearTimeout(_timeout);
            setIsIdle(false);
            _timeout = setTimeout(() => {
                setIsIdle(true);
            }, timeout);
        };
        window.addEventListener('mousemove', handler);
        window.addEventListener('keypress', handler);
        return () => {
            window.removeEventListener('mousemove', handler);
            window.removeEventListener('keypress', handler);
            clearTimeout(_timeout);
        };
    }, [timeout]);
    return <Provider value={{ isIdle }}>{children}</Provider>;
};
