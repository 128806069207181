import { Shimmer, ShimmerElementType, Stack, Text } from '@fluentui/react';
import { useMemo } from 'react';
import { ShimmerBox } from './ShimmerBox';
import { useGetGridColumnsNum } from '../../hooks/useGetGridColumnsNum';

// const FIRST_COLUMN_WIDTH = 300;
const SECOND_COLUMN_WIDTH = 190;
// const COLUMN_WIDTH = 270;
const COLUMN_WIDTH = 198;

export const GridSkeleton = ({ rows = 12, columnWidth = COLUMN_WIDTH, squareRowHeader }: { rows?: number, columnWidth?: number, squareRowHeader?: boolean }) => {
    const cols = useGetGridColumnsNum()

    return (
        <Stack styles={{ root: { height: '100%', cursor: 'wait' } }}>
            <Stack className="testing-test" styles={{ root: { backgroundColor: 'var(--tableBackground)', paddingBottom: 15 } }}>
                <HeaderRow cols={cols} columnWidth={columnWidth} />
                {Array.from({ length: rows }, (_, i) => {
                    return <Row key={i} squareRowHeader={squareRowHeader} cols={cols} backgroundColor={'var(--rowGroupBackground)!important'} columnWidth={columnWidth} />;
                })}
            </Stack>
        </Stack>
    );
};

const HeaderRow = ({ cols, showTotalColumn, columnWidth }: { cols: number; showTotalColumn?: boolean, columnWidth: number }) => {
    return (
        <Stack horizontal styles={{ root: { flexWrap: 'no-wrap', height: 88 } }}>
            <Stack
                styles={{
                    root: {
                        // minWidth: 'var(--gridRowWidth)',
                        minWidth: 'calc(var(--gridRowWidth) + 50px)',
                        borderRight: '1px solid var(--tableBorderColorVertical)',
                        borderBottom: '1px solid var(--tableBorderColorVertical)',
                        backgroundColor: '#fff',
                        gap: 5,
                        padding: 10,
                    },
                }}
                verticalAlign="end"
                horizontal
            >
                <ShimmerBox height={32} width={32} />
                <ShimmerBox height={32} width={32} />
            </Stack>
            {showTotalColumn && (
                <Stack
                    styles={{
                        root: {
                            minWidth: SECOND_COLUMN_WIDTH,
                            backgroundColor: 'var(--rowGroupBackground)!important',
                            borderRight: '1px solid var(--tableBorderColorVertical)',
                            borderBottom: '1px solid var(--tableBorderColorVertical)',
                            paddingBottom: 5,
                            paddingTop: 5,
                        },
                    }}
                    horizontalAlign="center"
                >
                    <Stack verticalAlign="center" styles={{ root: { height: '80%' } }}>
                        <Text styles={{ root: { fontSize: 16, fontWeight: 600 } }}>Total</Text>
                    </Stack>
                    <Stack
                        horizontal
                        horizontalAlign="space-around"
                        styles={{ root: { height: '20%', width: '100%', color: 'rgba(0,0,0,0.5)', span: { color: 'inherit' } } }}
                    >
                        <ShimmerBox width={30} height={13} />
                        <ShimmerBox width={30} height={13} />
                    </Stack>
                </Stack>
            )}
            {Array.from({ length: cols }, (_, i) => {
                return (
                    <Stack
                        styles={{
                            root: {
                                minWidth: columnWidth,
                                borderRight: '1px solid var(--tableBorderColorVertical)',
                                borderBottom: '1px solid var(--tableBorderColorVertical)',
                                backgroundColor: 'var(--rowGroupBackground)!important',
                                paddingTop: 5,
                            },
                        }}
                        horizontalAlign="center"
                        verticalAlign="space-around"
                        key={i}
                    >
                        <ShimmerBox width={80} height={22} />
                    </Stack>
                );
            })}
        </Stack>
    );
};

const Row = ({ cols, backgroundColor = '#fff', showTotalColumn, columnWidth, squareRowHeader }: { cols: number; backgroundColor?: string; showTotalColumn?: boolean, columnWidth: number, squareRowHeader?: boolean }) => {
    const cellWidth = useMemo(() => getRandomInt(50, 200), []);
    return (
        <Stack horizontal styles={{ root: { flexWrap: 'no-wrap', height: 45 } }}>
            <Stack
                styles={{
                    root: {
                        // minWidth: 'var(--gridRowWidth)',
                        minWidth: 'calc(var(--gridRowWidth) + 50px)',
                        borderRight: '1px solid var(--tableBorderColorVertical)',
                        borderBottom: '1px solid var(--tableBorderColorVertical)',
                        backgroundColor,
                    },
                }}
                verticalAlign="center"
            >
                <Stack styles={{ root: { paddingLeft: 20, gap: 10 } }} horizontal verticalAlign="center">
                    <PersonaShimmer square={squareRowHeader} />
                    <ShimmerBox width={cellWidth} height={25} />
                </Stack>
            </Stack>
            {showTotalColumn && (
                <Stack
                    styles={{
                        root: {
                            minWidth: SECOND_COLUMN_WIDTH,
                            backgroundColor: 'var(--rowGroupBackground)!important',
                            borderRight: '1px solid var(--tableBorderColorVertical)',
                            borderBottom: '1px solid var(--tableBorderColorVertical)',
                        },
                    }}
                    verticalAlign="center"
                >
                    <Stack horizontal horizontalAlign="space-around" styles={{ root: { width: '100%', paddingLeft: 10, paddingRight: 10 } }}>
                        <ShimmerBox width={75} height={30} />
                        <ShimmerBox width={75} height={30} />
                    </Stack>
                </Stack>
            )}
            {Array.from({ length: cols }, (_, i) => {
                return (
                    <Stack
                        styles={{
                            root: {
                                minWidth: columnWidth,
                                borderRight: '1px solid var(--tableBorderColorVertical)',
                                borderBottom: '1px solid var(--tableBorderColorVertical)',
                                backgroundColor,
                            },
                        }}
                        horizontalAlign="center"
                        verticalAlign="space-around"
                        key={i}
                    >
                        <ShimmerBox width={columnWidth - 5} height={40} />
                    </Stack>
                );
            })}
        </Stack>
    );
};

function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

const PersonaShimmer = ({square}: {square?: boolean}) => {
    return (
        <div style={{ height: 30, width: 30, borderRadius: square ? 'none' : '50%', overflow: 'hidden' }}>
            <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 30, width: 30 }]} />;
        </div>
    );
};


