import React, { useMemo } from 'react';
import { TextField } from '@fluentui/react';
import { IFieldConfig, IFieldRenderProps } from 'spark-forms';
import { useUISettings } from '../../../../context/network/http/QueryProvider/queries/UISettings';

export const OriginRender: React.FC<any> = <T,>({
	value,
	disabled,
}: IFieldRenderProps<T, IFieldConfig<T>, T[] | undefined>) => {
	
	const { data: uiSettings } = useUISettings();

	const origins = useMemo(() => uiSettings.settings.origins?.filter(_ => value?.some(i => i === _.id)), [uiSettings.settings?.origins, value])

	return  (
		<>
			{ origins && 
				<TextField
					value={ origins.map(_ => _.displayName).join(" | ") }
					disabled={ disabled }
					onChange={() => {}}
				/>
			}
		</>
	)
}
