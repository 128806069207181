import { Slider } from "@fluentui/react";
import { IFormItem, IPropertyRenderProps } from "@wiberg/formbuilder";
import { PropsWithChildren, ReactElement } from "react";
import { IDynamicNumberSliderFieldConfig } from "./config/IDynamicNumberSliderFieldConfig";

interface IProps<T extends IFormItem> extends IPropertyRenderProps<T, IDynamicNumberSliderFieldConfig<T>, number | undefined> { 
    config?: IDynamicNumberSliderFieldConfig<T>;
}

export const NumberSliderField = <T extends IFormItem>(props: PropsWithChildren<IProps<T>>) : ReactElement | null => {

	return  <Slider 
				key={ props.key }
				disabled={ props.disabled }
				min={props.config?.min}
				max={props.config?.max}
				step={props.config?.step}
				snapToStep={props.config?.step != null}
				defaultValue={props.value}
				valueFormat={(value: number) => props.config?.format ? props.config.format(value) : value+"" } 
				showValue={!props.config?.hideValue}
				onChanged={ (event, value: number) => props.onChange(value) }
				onChange={props.config?.onChangeEvent}
			/>
}
