/**
 * COLUMN SIZES
 */
export const COLUMN_WIDTH = 300;
export const COLUMN_STICKY_COUNT = 1;
export const CUSTOM_COLUMN_WIDTH = 200

/**
 * ROW SIZES
 */
export const ROW_HEADER_WIDTH = 400;
export const ROW_HEIGHT = 50;
export const ROW_EXPANDED_HEIGHT = 75;
export const ROW_STICKY_COUNT = 1;
export const TOP_HEADERS_HEIGHT = 90; 

/**
 * CELL SIZES
 */
export const CELL_WIDTH = 80;
export const CELL_HEIGHT = 30;

/**
 * COLORS
 */
export const ROW_BG_HOVER = "var(--rowHoverBackgroundColor)";
export const ROOT_ROW_BG = "var(--tableBackground)";
export const ROW_BG = "var(--rowGroupBackground)";

/**
 * BORDERS
 */
export const BORDER = "1.5px solid var(--tableBorderColorVertical)";

