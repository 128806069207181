import 'reflect-metadata';
import { IconButton, PrimaryButton, TextField } from '@fluentui/react';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { JsonEx } from '../../../../../../Utilities/JsonEx';
import { ArrayEx } from '../../../../../../Utilities/ArrayEx';
import { treeHelper } from '../helpers/treeHelper';
import { PFDialog } from '../../../../../../Components/Common/PFDialog';
import { SortableTreeView } from '../../../../../../components_new/sortableTreeview/SortableTreeView';
import { ITreeNode } from '@pum-spark/tree-render';
import { sortableTreeHelper } from '../../../../../../components_new/sortableTreeview/sortableTreeHelper';

interface IProps {
    tree: ITreeNode;
    selected?: string | Array<string>;
    onChanged: (item: string | Array<string> | ITreeNode) => void;
    type: 'select' | 'manage';
    multiSelect?: boolean;
    dialogTitle?: string;
    buttonTitle?: string;
    delimiter?: string;
    readOnly?: boolean;
    defaultExpandedDepth?: number;
    autoSelectAndDisableChilds?: boolean;
    autoExpandSelected?: boolean;
    required?: boolean;
}

export const TreeEditor: FunctionComponent<IProps> = ({
    tree,
    selected,
    onChanged: update,
    type,
    autoExpandSelected,
    autoSelectAndDisableChilds,
    buttonTitle,
    defaultExpandedDepth,
    delimiter,
    dialogTitle,
    multiSelect,
    readOnly,
    required,
}) => {
    const [item, setItem] = useState<string[] | null>(() => {
		if (selected) {
			return Array.isArray(selected) ? selected : [selected]
		}
		return multiSelect ? null : []
	});
    const [treeNode, setTreeNode] = useState<ITreeNode>(tree);
    const [editMode, setEditMode] = useState<boolean>(false);

    const originalTreeStruture = useRef(JsonEx.clone(tree));

    useEffect(() => {
		if (selected != null) {
			setItem(Array.isArray(selected) ? selected : [selected]);
		}
    }, [selected]);

    return (
        <div>
            {type === 'select' ? (
                <div style={{ display: 'flex' }}>
                    <IconButton iconProps={{ iconName: readOnly ? 'Zoom' : 'Edit' }} onClick={() => setEditMode(true)} />
                    <TextField
                        styles={{ root: { width: '100%' } }}
                        disabled={readOnly}
                        readOnly
                        value={ArrayEx.joinToString(treeHelper.getNames(sortableTreeHelper.parseToLegacyTreeNode(treeNode), ArrayEx.ensureArray(selected)), ' | ')}
                    />
                </div>
            ) : (
                <PrimaryButton required={required && !readOnly} text={buttonTitle || (readOnly ? 'View' : 'Edit')} onClick={() => setEditMode(true)} />
            )}
            {editMode && (
                <PFDialog
                    title={dialogTitle}
                    maxWidth={1500}
                    showDialog={editMode}
                    callback={() => {
                        if (type === 'select') {
                            setEditMode(false);
                            update(item);
                        } else {
                            const clone = treeNode;
                            setEditMode(false);
                            setTreeNode(clone);
                            originalTreeStruture.current = JsonEx.clone(clone);
                            update(clone);
                        }
                    }}
                    dismissCallback={() => {
                        setEditMode(false);
                        setTreeNode(JsonEx.clone(originalTreeStruture.current));
                    }}
                    buttonDefaultDisabled={readOnly}
                >
					<SortableTreeView 
						data={treeNode}
						mode={multiSelect ? 'multiSelect' : 'select'}
						ignoreRootNode
						onSelectionChange={setItem as (selectedNodes: Readonly<string[]>) => void}
						defaultSelectedNodes={item}
						expandToLevel={defaultExpandedDepth}
						pathDelimiter={delimiter}
						expandToSelected={autoExpandSelected}
					/>
                </PFDialog>
            )}
        </div>
    );
};
