
// TODO: remove this when OverviewContext gets refactored




export const CacheLikeGetter = async <TPropValue>(valueGetter: () => TPropValue, valueSetter: (val: TPropValue) => void, dataGetter: () => Promise<TPropValue>) : Promise<TPropValue> => {
	const value = valueGetter();
	// already set, return item
	if (value !== undefined) return value;
	// fetch data
	const data = await dataGetter();
	// set value
	valueSetter(data);
	return data;
}