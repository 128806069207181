export default class Locale_LookupEditor { 
	LookupTitle = {
		String: "String lookup",
		Number: "Number lookup",
		Tree: "Tree lookup",
		Resource: 'Resource lookup (in preview)',
		Project: 'Project lookup (in preview)',
		Rbs: 'Rbs (in preview)'
	}
	Lookup: string = "lookup";
}