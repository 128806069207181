export const UI_SETTINGS_KEY = 'tp-ui-settings';

export const CURRENT_PROJECT_KEY = 'tp-current-project';

export const RESOURCES_KEY = 'resources';

export const PROJECTS_KEY = 'projects';

export const CONTRACT_CUSTOMPROPS = 'contract-customprops';

export const RESOURCE_OVERVIEW = 'resource-overview';

export const RESOURCE_PICKERFIELD = 'RESOURCE_PICKERFIELD';

export const PROJECT_OVERVIEW = 'project-overview';

export const PROJECT_PICKERFIELD = 'PROJECT_PICKERFIELD';

export const CONTRACT_OVERVIEW = 'contract-overview';

export const ROOT_SETTINGS = 'root-settings';

export const ENDPOINT_OVERVIEW = 'endpoint-overview';

export const RBS_OVERVIEW = 'rbs-overview';

export const WORKPROFILE_OVERVIEW = 'workprofile-overview';

export const LOOKUP_OVERVIEW = 'lookup-overview';

export const ANNOUNCEMENT_OVERVIEW = 'announcement-overview';

export const SQLEXPORTERROR_OVERVIEW = 'sqlexporterror-overview';

export const HELP_SECTION = 'helpsection';

export const RESOURCE_PLANNER = 'RESOURCE_PLANNER';
export const PROJECT_PLANNER = 'PROJECT_PLANNER';

export const ENTITY_CALLOUT_INFO = 'ENTITY_CALLOUT_INFO';

export const ENTITY_CALLOUT_INFO_SWAGGER = 'ENTITY_CALLOUT_INFO_SWAGGER';

// export const RESOURCE_PLANNER_VIEW_FILTER = "RESOURCE_PLANNER_VIEW_FILTER";

export const UPGRADE_LEGACY_APP_PACKAGES = 'UPGRADE_LEGACY_APP_PACKAGES';

export const UPGRADE_LEGACY_UI_PACKAGES = 'UPGRADE_LEGACY_UI_PACKAGES';

export const UPGRADE_LEGACY_FUNCTION_PACKAGES = 'UPGRADE_LEGACY_FUNCTION_PACKAGES';

export const UPGRADE_LEGACY_RELEASE_NOTES = 'UPGRADE_LEGACY_RELEASE_NOTES';

export const RBSSTRUCTURE_SELECTOR = 'RBSSTRUCTURE_SELECTOR';

export const INTEGRATIONS_SETTINGS = 'INTEGRATIONS_SETTINGS';

export const INTEGRATION_DATAVERSE_ENV_LIST = 'INTEGRATION_DATAVERSE_ENV_LIST';

export const DATAVERSE_ENVIRONMENTS_TABLES = 'DATAVERSE_ENVIRONMENTS_TABLES';

export const DATAVERSE_PROJECT_COLUMNS = 'DATAVERSE_PROJECT_COLUMNS';

export const DATAVERSE_RESOURCE_COLUMNS = 'DATAVERSE_RESOURCE_COLUMNS';

export const DATAVERSE_GANTT_COLUMNS = 'DATAVERSE_GANTT_COLUMNS';

export const RESOURCE_CAPACITY_PROJECTS = 'RESOURCE_CAPACITY_PROJECTS';

// export const DATAVERSE_GANTT_COLUMNS = "DATAVERSE_GANTT_COLUMNS";

export const WORKPACKAGE_IMPORT = 'WORKPACKAGE_IMPORT';

export const DATAVERSE_OPTIONS = 'DATAVERSE_OPTIONS';

export const RESOURCEPLAN_STATE_TRIGGER = 'RESOURCEPLAN_STATE_TRIGGER';

export const MOVE_CONTRACT_PROJECTS = 'MOVE_CONTRACT_PROJECTS';

export const MAIL_CONFIGURATION = 'MAIL_CONFIGURATION';

export const PROJECT_FIELD = 'PROJECT_FIELD';

export const AAD_GROUP_FIELD = 'AAD_GROUP_FIELD';

export const RBS_FIELD = 'RBS_FIELD';

export const COMMENTS = 'COMMENTS';

export const ENTRA_GROUPS = 'ENTRA_GROUPS';

export const ENTRA_USER_GROUP_CHECK = 'ENTRA_USER_GROUP_CHECK';

export const CONTRACT_EXISTS = 'CONTRACT_EXISTS';