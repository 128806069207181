/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { PFList } from '../../PFList';
import { KeyValue } from '../../../../Entities/KeyValue';
import { IDs } from '../../../../Utilities/IDs';
import { DynamicProps } from './DynamicProps';
import { IColumn } from '@fluentui/react';

interface IProps extends DynamicProps<Array<string | number>, object> {
    ColumnTitle?: string;
    ColumnTitleFunction?: () => string;
    ValueType?: 'number' | 'boolean' | 'text' | 'date' | 'color' | 'enum';
    OnRender?: (item?: any, index?: number, column?: IColumn) => any;
}

const DynamicSimpleArray: FunctionComponent<IProps> = props => {
    const [items, setItems] = useState<Array<{ Key: string; Value: any }>>();
    const [hasLoadedLocalization, setHasLoadedLocalization] = useState<boolean>(false);
    const [columnTitle, setColumnTitle] = useState<string>('');

    //TODO: items mangler types
    useEffect(() => {
        const itemvalue = props.Value || [];
        const itemsArr = Object.keys(itemvalue).map(key => {
            return { Value: itemvalue[key] };
        });
        const items = itemsArr.map(_ => {
            return { Key: IDs.makeId(), Value: _.Value };
        });
        setItems(items);
    }, []);

    useEffect(() => {
        if (hasLoadedLocalization) return;
        const GetColumnTitle = () => {
            if (props.ColumnTitle) {
                setHasLoadedLocalization(true);
                setColumnTitle(props.ColumnTitle);
            } else if (props.ColumnTitleFunction) {
                setColumnTitle(props.ColumnTitleFunction());
                setHasLoadedLocalization(true);
            } else return 'Value';
        };
        GetColumnTitle();
    }, []);

    return (
        <div key={props.Key}>
            <div>
                {columnTitle && (
                    <PFList
                        DialogTitle={props.PropertyInfo.DisplayName}
                        ReadOnly={props.ReadOnly}
                        Columns={[
                            {
                                key: 'Value',
                                name: columnTitle,
                                fieldName: 'Value',
                                minWidth: 200,
                                valueType: props.ValueType ?? 'text',
                                onRender: props.OnRender,
                            },
                        ]}
                        ItemIdentifier={(item: any) => item.Key}
                        Items={items}
                        ItemType={KeyValue}
                        ChangesCallback={(items: Array<KeyValue>) =>
                            props.OnChange(
                                props.PropertyInfo.PropertyName,
                                items.map(_ => _.Value),
                            )
                        }
                    />
                )}
            </div>
        </div>
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Value === nextProps.Value;
};

export default memo(DynamicSimpleArray, comparisonFn);
