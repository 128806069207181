export default class Locale_Timeline {
	ChangeResolution: string = "Change Resolution";
	ResetView: string = "Reset View";
	PickResolution: string = "Pick Resolution";
	
	Jan: string = "Jan";
	Feb: string = "Feb";
	Mar: string = "Mar";
	Apr: string = "Apr";
	May: string = "May";
	Jun: string = "Jun";
	Jul: string = "Jul";
	Aug: string = "Aug";
	Sep: string = "Sep";
	Oct: string = "Oct";
	Nov: string = "Nov";
	Dec: string = "Dec";

	Q1: string = "Q1";
	Q2: string = "Q2";
	Q3: string = "Q3";
	Q4: string = "Q4";
}