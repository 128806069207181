import { useState, useEffect, useRef } from 'react';
import ObjectListEditor, { ItemDialogType } from '../ObjectListEditor';
import { DataService } from '../../../Services/DataService';
import { EntityType } from '../../../Entities/EntityTypes';
import { guid } from '../../../helpers/guid';
import { UserContext } from '../../../Utilities/Context/UserContext';
import { PFSpinner } from '../Spinner';
import { Plugin } from '../../../Services/PluginInvoker';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import { Settings } from '../../../Entities/Main/Settings';
import { IDs } from '../../../Utilities/IDs';
import { PropertyInfoService } from '../../../Services/PropertyInfoService';
import { ArrayEx } from '../../../Utilities/ArrayEx';
import { QueryGroup } from '../../../Entities/DynamicQueryBuilder/QueryGroup';
import { OverviewContext } from '../../../Utilities/Context/OverviewContext';
import { IconButton, Label, TooltipHost } from '@fluentui/react';
import { ObjectEx } from '../../../Utilities/ObjectEx';
import { language } from '../../../Services/LocalizationService';
import { useStore } from '../../../context/store';

const SubSettingsAdministration = () => {
    const success = useStore(store => store.addSuccesNotification);
    const rootSetting = useRef<Settings>();
    const [allSubSettings, setAllSubSettings] = useState<Array<Settings>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [notConfigured, setNotConfigured] = useState<boolean>(false);

    const hiddenColumnProps = useRef<Array<string>>([]);
    const usedProps = useRef<Array<string>>([]);

    useEffect(() => {
        if (!OverviewContext.Settings.SubSettingPropertiesAvailable?.length) {
            setNotConfigured(true);
            setLoading(false);
            return;
        }
        const fetchData = async () => {
            await LoadData();
        };
        fetchData();
        SetHiddenProps();
    }, []);

    const SetHiddenProps = () => {
        // column props
        const columnProps = ArrayEx.difference(
            PropertyInfoService.GetPFProperties(Settings, true).map(_ => _.PropertyName),
            ['Name', 'MatchPriority'],
        );
        hiddenColumnProps.current = columnProps;

        // edit props
        const editProps = ArrayEx.difference(
            PropertyInfoService.GetPFProperties(Settings, true).map(_ => _.PropertyName),
            [...OverviewContext.Settings.SubSettingPropertiesAvailable, 'MatchFilter', 'MatchPriority', 'Name'],
        );
        usedProps.current = editProps;
    };

    const LoadData = async () => {
        // root
        rootSetting.current = await Plugin.Invoke<Settings>(PluginIDs.GetRootSettings);
        // subs
        const allSubSettings = await Plugin.Invoke<Array<Settings>>(PluginIDs.GetAllSubSettings);
        setAllSubSettings(allSubSettings);
        setLoading(false);
    };

    const Save = async (listItems: Array<Settings>, sub: Settings) => {
        setLoading(true);
        const deleted = !listItems.find(_ => sub.id === _.id);
        if (deleted) {
            await RemoveSubSettings(sub);
            await LoadData();
            success(language.SubSettingsAdministration.SubSettings.Deleted);
        } else {
            sub.Created = null;
            sub.CreatedBy = null;
            await SaveSubSettings(sub);
            await LoadData();
            success(language.SubSettingsAdministration.SubSettings.Saved);
        }

        setLoading(false);
    };

    const SaveSubSettings = async (sub): Promise<void> => {
        await DataService.Upsert<Settings>(EntityType.Settings, [sub]);
    };

    const RemoveSubSettings = async (sub): Promise<boolean> => {
        return await DataService.Delete<Settings>(EntityType.Settings, [sub]);
    };

    const ResetPropertyValueButton = (object: Settings, propName: string, readonly: boolean, setState?: (value: any) => void): JSX.Element => {
        if (readonly) return null;
        return (
            <div className="tp-subsettings-reset">
                <TooltipHost content={language.SubSettingsAdministration.ResetValue}>
                    <IconButton
                        iconProps={{ iconName: 'Refresh' }}
                        onClick={event => {
                            const clone = ObjectEx.deepCopy(rootSetting.current);
                            const value = clone[propName];
                            if (setState) setState(value);
                        }}
                    />
                </TooltipHost>
            </div>
        );
    };

    return (
        <>
            {loading ? (
                <PFSpinner />
            ) : (
                <>
                    <Label styles={{ root: { color: 'red' } }}>
                        Subsettings can affect performance in some areas. Especially in larger environments with 10k+ resources.
                    </Label>
                    {notConfigured ? (
                        <Label>Subsettings are not configured yet by your administrator</Label>
                    ) : (
                        <ObjectListEditor
                            ItemIdentifier={(item: Settings) => item.id}
                            ItemType={Settings}
                            HiddenColumnProperties={hiddenColumnProps.current}
                            HiddenProperties={usedProps.current}
                            DisabledProperties={['id', 'Created', 'Modified', 'CreatedBy', 'ModifiedBy']}
                            Items={allSubSettings}
                            UserSortEnabled={true}
                            DialogTitle={language.SubSettingsAdministration.DialogTitle}
                            DialogType={ItemDialogType.Panel}
                            ChangesCallback={(items: Array<Settings>, item: Settings) => Save(items, item)}
                            EnrichNewItem={(sub: Settings) => {
                                const clone = ObjectEx.deepCopy(rootSetting.current);
                                Object.keys(rootSetting.current).forEach(_ => (sub[_] = clone[_]));
                                (sub as any).SettingsType = 1;
                                sub.Name = IDs.makeId();
                                sub.id = guid.newGuid();
                                sub.MatchFilter = new QueryGroup();
                                sub.OverrideSubSettingPropertiesAvailable = [];
                            }}
                            ClonedItemChange={(_: Settings) => {
                                _.Name = `copy_${_.Name}`;
                                _.id = guid.newGuid();
                                const modified = new Date();
                                _.Created = modified;
                                _.Modified = modified;
                                _.CreatedBy = UserContext.LoginName;
                                _.ModifiedBy = UserContext.LoginName;
                            }}
                            PropertyExtraContent={ResetPropertyValueButton}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default SubSettingsAdministration;
