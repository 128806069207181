import Locale_TopBar from './Locale_Topbar';
import Locale_Timeline from './Locale_Timeline';
import Locale_Common from './Locale_Common';
import Locale_Coachmarks from './Coachmarks/Locale_Coachmarks';
import Locale_ProjectOverview from './Locale_ProjectOverview';
import Locale_ContentSettings from './Locale_ContentSettings';
import Locale_ProjectCapacity from './Locale_ProjectCapacity';
import Locale_Overview from './Locale_Overview';
import Locale_MoveRequestDialog from './Locale_MoveRequestDialog';
import Locale_MoveAllAllocationsDialog from './Locale_MoveAllAllocationsDialog';
import Locale_MatchingActivityDialog from './Locale_MatchingActivityDialog';
import Locale_LMCapacity from './Locale_LMCapacity';
import Locale_CapacityViews from './Locale_CapacityViews';
import Locale_ForwardRequestDialog from './Locale_ForwardRequestDialog';
import Locale_ContractRowMenu from './Locale_ContractRowMenu';
import Locale_ProjectRowMenu from './Locale_ProjectRowMenu';
import Locale_Projects from './Locale_Projects';
import Locale_ResourceCapacity from './Locale_ResourceCapacity';
import Locale_ResourceOverview from './Locale_ResourceOverview';
import Locale_ResourceRowMenu from './Locale_ResourceRowMenu';
import Locale_Resources from './Locale_Resources';
import Locale_ResourceSettings from './Locale_ResourceSettings';
import Locale_RootSettingsEditor from './Locale_RootSettingsEditor';
import Locale_SubSettingsAdministration from './Locale_SubSettingsAdministration';
import Locale_Main from './Locale_Main';
import Locale_Comments from './Locale_Comments';
import Locale_GridSettings from './Locale_GridSettings';
import Locale_Stickyness from './Locale_Stickyness';
import Locale_ProjectPicker from './Locale_ProjectPicker';
import Locale_ResourceEditor from './Locale_ResourceEditor';
import Locale_ResourcePicker from './Locale_ResourcePicker';
import Locale_SettingsEditor from './Locale_SettingsEditor';
import Locale_AnnouncementEditor from './Locale_AnnouncementEditor';
import Locale_AnnouncementViewer from './Locale_AnnouncementViewer';
import Locale_BlockingDialog from './Locale_BlockingDialog';
import Locale_BulkEditor from './Locale_BulkEditor';
import Locale_CountDownDialog from './Locale_CountDownDialog';
import Locale_EntityPropertyFilter from './Locale_EntityPropertyFilter';
import Locale_ListBulkEditor from './Locale_ListBulkEditor';
import Locale_LookupEditor from './Locale_LookupEditor';
import Locale_MailTemplates from './Locale_MailTemplates';
import Locale_Maintenance from './Locale_Maintenance';
import Locale_NotificationSender from './Locale_NotificationSender';
import Locale_ObjectListEditor from './Locale_ObjectListEditor';
import Locale_OverviewActiveToggler from './Locale_OverviewActiveToggler';
import Locale_PFErrorBoundary from './Locale_PFErrorBoundary';
import Locale_PFList from './Locale_PFList';
import Locale_ProjectDraftDialog from './Locale_ProjectDraftDialog';
import Locale_ProjectListItemPercentageBar from './Locale_ProjectListItemPercentageBar';
import Locale_ProjectVersionsDialog from './Locale_ProjectVersionsDialog';
import Locale_ThemeArea from './Locale_ThemeArea';
import Locale_UnitTypeToggler from './Locale_UnitTypeToggler';
import Locale_UserDetails from './Locale_UserDetails';
import Locale_UserSettings from './Locale_UserSettings';
import Locale_WorkPackageImportDialog from './Locale_WorkPackageImportDialog';
import Locale_WorkPackageSelector from './Locale_WorkPackageSelector';
import Locale_WorkPackageEditor from './Locale_WorkPackageEditor';
import Locale_AadGroupPicker from './Locale_AadGroupPicker';
import Locale_EntityPropertySelector from './Locale_EntityPropertySelector';
import Locale_FreezePeriodEditor from './Locale_FreezePeriodEditor';
import Locale_LookupOptionsEditor from './Locale_LookupOptionsEditor';
import Locale_PropertyMapping from './Locale_PropertyMapping';
import Locale_ReportFilterEditor from './Locale_ReportFilterEditor';
import Locale_ResourceSpecificWorkprofileEditor from './Locale_ResourceSpecificWorkprofileEditor';
import Locale_SettingsBackup from './Locale_SettingsBackup';
import Locale_ThemeDesigner from './Locale_ThemeDesigner';
import Locale_DynamicComponents from './Locale_DynamicComponents';
import Locale_Integrations from './Locale_Integrations';
import Locale_Migrations from './Locale_Migrations';
import Locale_AdminDashboard from './Locale_AdminDashboard';
import Locale_AlignContractMenu from './Locale_AlignContractMenu';
import Locale_Schedules from './Locale_Schedules';
import Locale_AutoAllocationDialog from './Locale_AutoAllocationDialog';
import Locale_FindAvailableCapacityDialog from './Locale_FindAvailableCapacityDialog';
import Locale_OverviewDefaultOptions from './Locale_OverviewDefaultOptions';
import Locale_Reboot from './Locale_Reboot';
import Locale_Upgrade from './Locale_Upgrade';
import Locale_WorkPackages from './Locale_WorkPackages';
import Locale_ContractVersionRetention from './Locale_ContractVersionRetention';
import Locale_ContractConstraints from './Locale_ContractContraints';
import Locale_PickerFields from './Locale_ProjectPickerFields';
import Locale_CustomProperty from './Locale_CustomProperty';
import Locale_LookupProject from './Locale_LookupProject';
import Locale_LookupResource from './Locale_LookupResource';
import Locale_ContractInfoDto from './Locale_ContractInfoDto';
import Locale_MatchingActivityDto from './Locale_MatchingActivityDto';
import Locale_MoveAllAllocationsDto from './Locale_MoveAllAllocationsDto';
import Locale_MoveRequestDto from './Locale_MoveRequestDto';
import Locale_WorkPackageImportDto from './Locale_WorkPackageImportDto';
import Locale_MailTemplate from './Locale_MailTemplate';
import Locale_Announcements from './Locale_Announcements';
import Locale_Origin from './Locale_Origin';
import Locale_Project from './Locale_Project';
import Locale_Report from './Locale_Report';
import Locale_Resource from './Locale_Resource';
import Locale_Rule from './Locale_Rule';
import Locale_ThemeContainer from './Locale_ThemeContainer';
import Locale_Settings from './Locale_Settings';
import Locale_WorkProfile from './Locale_WorkProfile';
import Locale_LogSettings from './Locale_LogSettings';
import Locale_ScheduleJob from './Locale_ScheduleJob';
import Locale_ScheduleRun from './Locale_ScheduleRun';
import Locale_RecurrenceRule from './Locale_RecurrenceRule';
import Locale_AutoAlignContractNames from './Locale_AutoAlignContractNames';
import Locale_ProjectListItem from './Locale_ProjectListItem';
import Locale_DataService from './Locale_DataService';
import Locale_PluginInvoker from './Locale_PluginInvoker';
import Locale_APIMessages from './Locale_APIErrors';
import Locale_ChartPanel from './Locale_ChartPanel';
import Locale_MailServiceConfig from './Locale_MailServiceConfig';
import Locale_AutoDistributionConfig from './Locale_AutoDistributionConfig';
import Locale_CustomContractPropertiesDialog from './Locale_CustomContractPropertiesDialog';
import Locale_HelpSection from './Locale_HelpSection';
import Locale_PushNotificationAnnouncementViewer from './Locale_PushNotificationAnnouncementViewer';

export class Localization {
    public Name: string;
	public Common: Locale_Common;
    public Topbar: Locale_TopBar;
    public Timeline: Locale_Timeline;
    public Coachmarks: Locale_Coachmarks;
    public ProjectOverview: Locale_ProjectOverview;
    public ContentSettings: Locale_ContentSettings;
    public ProjectCapacity: Locale_ProjectCapacity;
    public LMCapacity: Locale_LMCapacity;
    public CapacityViews: Locale_CapacityViews;
    public Overview: Locale_Overview;
    public MoveRequestDialog: Locale_MoveRequestDialog;
    public MoveAllAllocationsDialog: Locale_MoveAllAllocationsDialog;
    public MatchingActivityDialog: Locale_MatchingActivityDialog;
    public ForwardRequestDialog: Locale_ForwardRequestDialog;
    public ContractRowMenu: Locale_ContractRowMenu;
    public ProjectRowMenu: Locale_ProjectRowMenu;
    public Projects: Locale_Projects;
    public ResourceCapacity: Locale_ResourceCapacity;
    public ResourceOverview: Locale_ResourceOverview;
    public ResourceRowMenu: Locale_ResourceRowMenu;
    public Resources: Locale_Resources;
    public ResourceSettings: Locale_ResourceSettings;
    public RootSettingsEditor: Locale_RootSettingsEditor;
    public SubSettingsAdministration: Locale_SubSettingsAdministration;
    public Main: Locale_Main;
    public Comments: Locale_Comments;
    public GridSettings: Locale_GridSettings;
    public Stickyness: Locale_Stickyness;
    public ProjectPicker: Locale_ProjectPicker;
    public ResourceEditor: Locale_ResourceEditor;
    public ResourcePicker: Locale_ResourcePicker;
    public SettingsEditor: Locale_SettingsEditor;
    public AnnouncementEditor: Locale_AnnouncementEditor;
    public AnnouncementViewer: Locale_AnnouncementViewer;
    public BlockingDialog: Locale_BlockingDialog;
    public BulkEditor: Locale_BulkEditor;
    public CountDownDialog: Locale_CountDownDialog;
    public EntityPropertyFilter: Locale_EntityPropertyFilter;
    public ListBulkEditor: Locale_ListBulkEditor;
    public LookupEditor: Locale_LookupEditor;
    public MailTemplates: Locale_MailTemplates;
    public Maintenance: Locale_Maintenance;
    public NotificationSender: Locale_NotificationSender;
    public ObjectListEditor: Locale_ObjectListEditor;
    public OverviewActiveToggler: Locale_OverviewActiveToggler;
    public PFErrorBoundary: Locale_PFErrorBoundary;
    public PFList: Locale_PFList;
    public ProjectDraftDialog: Locale_ProjectDraftDialog;
    public ProjectListItemPercentageBar: Locale_ProjectListItemPercentageBar;
    public ProjectVersionsDialog: Locale_ProjectVersionsDialog;
    public ThemeArea: Locale_ThemeArea;
    public UnitTypeToggler: Locale_UnitTypeToggler;
    public UserDetails: Locale_UserDetails;
    public UserSettings: Locale_UserSettings;
    public WorkPackageImportDialog: Locale_WorkPackageImportDialog;
    public WorkPackageSelector: Locale_WorkPackageSelector;
    public WorkPackageEditor: Locale_WorkPackageEditor;
    public AadGroupPicker: Locale_AadGroupPicker;
    public EntityPropertySelector: Locale_EntityPropertySelector;
    public FreezePeriodEditor: Locale_FreezePeriodEditor;
    public LookupOptionsEditor: Locale_LookupOptionsEditor;
    public PropertyMapping: Locale_PropertyMapping;
    public ReportFilterEditor: Locale_ReportFilterEditor;
    public ResourceSpecificWorkprofileEditor: Locale_ResourceSpecificWorkprofileEditor;
    public SettingsBackup: Locale_SettingsBackup;
    public ThemeDesigner: Locale_ThemeDesigner;
    public DynamicComponents: Locale_DynamicComponents;
    public Integrations: Locale_Integrations;
    public Migrations: Locale_Migrations;
    public AdminDashboard: Locale_AdminDashboard;
    public AlignContractMenu: Locale_AlignContractMenu;
    public Schedules: Locale_Schedules;
    public AutoAllocationDialog: Locale_AutoAllocationDialog;
    public FindAvailableCapacityDialog: Locale_FindAvailableCapacityDialog;
    public OverviewDefaultOptions: Locale_OverviewDefaultOptions;
    public Reboot: Locale_Reboot;
    public Upgrade: Locale_Upgrade;
    public WorkPackages: Locale_WorkPackages;
    public ContractVersionRetention: Locale_ContractVersionRetention;
    public ContractConstraints: Locale_ContractConstraints;
    public PickerFields: Locale_PickerFields;
    public CustomProperty: Locale_CustomProperty;
    public LookupProject: Locale_LookupProject;
    public LookupResource: Locale_LookupResource;
    public ContractInfoDto: Locale_ContractInfoDto;
    public MatchingActivityDto: Locale_MatchingActivityDto;
    public MoveAllAllocationsDto: Locale_MoveAllAllocationsDto;
    public MoveRequestDto: Locale_MoveRequestDto;
    public WorkPackageImportDto: Locale_WorkPackageImportDto;
    public MailTemplate: Locale_MailTemplate;
    public Announcements: Locale_Announcements;
    public Origin: Locale_Origin;
    public Project: Locale_Project;
    public Report: Locale_Report;
    public Resource: Locale_Resource;
    public Rule: Locale_Rule;
    public ThemeContainer: Locale_ThemeContainer;
    public Settings: Locale_Settings;
    public WorkProfile: Locale_WorkProfile;
    public LogSettings: Locale_LogSettings;
    public ScheduleJob: Locale_ScheduleJob;
    public ScheduleRun: Locale_ScheduleRun;
    public RecurrenceRule: Locale_RecurrenceRule;
    public AutoAlignContractNames: Locale_AutoAlignContractNames;
    public ProjectListItem: Locale_ProjectListItem;
    public DataService: Locale_DataService;
    public PluginInvoker: Locale_PluginInvoker;
	public ChartPanel: Locale_ChartPanel;
	public MailServiceConfig: Locale_MailServiceConfig;
	public AutoDistributionConfig: Locale_AutoDistributionConfig;
	public HelpSection: Locale_HelpSection;
	public pushNotificationAnnouncementViewer: Locale_PushNotificationAnnouncementViewer;

	public APIMessages: Locale_APIMessages;

	public CustomContractPropertiesDialog: Locale_CustomContractPropertiesDialog;

    constructor() {
        this.Common = new Locale_Common();
        this.Topbar = new Locale_TopBar();
        this.Timeline = new Locale_Timeline();
        this.Coachmarks = new Locale_Coachmarks();
        this.ProjectOverview = new Locale_ProjectOverview();
        this.ContentSettings = new Locale_ContentSettings();
        this.ProjectCapacity = new Locale_ProjectCapacity();
        this.LMCapacity = new Locale_LMCapacity();
        this.CapacityViews = new Locale_CapacityViews();
        this.Overview = new Locale_Overview();
        this.MoveRequestDialog = new Locale_MoveRequestDialog();
        this.MoveAllAllocationsDialog = new Locale_MoveAllAllocationsDialog();
        this.MatchingActivityDialog = new Locale_MatchingActivityDialog();
        this.ForwardRequestDialog = new Locale_ForwardRequestDialog();
        this.ContractRowMenu = new Locale_ContractRowMenu();
        this.ProjectRowMenu = new Locale_ProjectRowMenu();
        this.Projects = new Locale_Projects();
        this.ResourceCapacity = new Locale_ResourceCapacity();
        this.ResourceOverview = new Locale_ResourceOverview();
        this.ResourceRowMenu = new Locale_ResourceRowMenu();
        this.Resources = new Locale_Resources();
        this.ResourceSettings = new Locale_ResourceSettings();
        this.RootSettingsEditor = new Locale_RootSettingsEditor();
        this.SubSettingsAdministration = new Locale_SubSettingsAdministration();
        this.Main = new Locale_Main();
        this.Comments = new Locale_Comments();
        this.GridSettings = new Locale_GridSettings();
        this.Stickyness = new Locale_Stickyness();
        this.ProjectPicker = new Locale_ProjectPicker();
        this.ResourceEditor = new Locale_ResourceEditor();
        this.ResourcePicker = new Locale_ResourcePicker();
        this.SettingsEditor = new Locale_SettingsEditor();
        this.AnnouncementEditor = new Locale_AnnouncementEditor();
        this.AnnouncementViewer = new Locale_AnnouncementViewer();
        this.BlockingDialog = new Locale_BlockingDialog();
        this.BulkEditor = new Locale_BulkEditor();
        this.CountDownDialog = new Locale_CountDownDialog();
        this.EntityPropertyFilter = new Locale_EntityPropertyFilter();
        this.ListBulkEditor = new Locale_ListBulkEditor();
        this.LookupEditor = new Locale_LookupEditor();
        this.MailTemplates = new Locale_MailTemplates();
        this.Maintenance = new Locale_Maintenance();
        this.NotificationSender = new Locale_NotificationSender();
        this.ObjectListEditor = new Locale_ObjectListEditor();
        this.OverviewActiveToggler = new Locale_OverviewActiveToggler();
        this.PFErrorBoundary = new Locale_PFErrorBoundary();
        this.PFList = new Locale_PFList();
        this.ProjectDraftDialog = new Locale_ProjectDraftDialog();
        this.ProjectListItemPercentageBar = new Locale_ProjectListItemPercentageBar();
        this.ProjectVersionsDialog = new Locale_ProjectVersionsDialog();
        this.ThemeArea = new Locale_ThemeArea();
        this.UnitTypeToggler = new Locale_UnitTypeToggler();
        this.UserDetails = new Locale_UserDetails();
        this.UserSettings = new Locale_UserSettings();
        this.WorkPackageImportDialog = new Locale_WorkPackageImportDialog();
        this.WorkPackageSelector = new Locale_WorkPackageSelector();
        this.WorkPackageEditor = new Locale_WorkPackageEditor();
        this.AadGroupPicker = new Locale_AadGroupPicker();
        this.EntityPropertySelector = new Locale_EntityPropertySelector();
        this.FreezePeriodEditor = new Locale_FreezePeriodEditor();
        this.LookupOptionsEditor = new Locale_LookupOptionsEditor();
        this.PropertyMapping = new Locale_PropertyMapping();
        this.ReportFilterEditor = new Locale_ReportFilterEditor();
        this.ResourceSpecificWorkprofileEditor = new Locale_ResourceSpecificWorkprofileEditor();
        this.SettingsBackup = new Locale_SettingsBackup();
        this.ThemeDesigner = new Locale_ThemeDesigner();
        this.DynamicComponents = new Locale_DynamicComponents();
        this.Integrations = new Locale_Integrations();
        this.Migrations = new Locale_Migrations();
        this.AdminDashboard = new Locale_AdminDashboard();
        this.AlignContractMenu = new Locale_AlignContractMenu();
        this.Schedules = new Locale_Schedules();
        this.AutoAllocationDialog = new Locale_AutoAllocationDialog();
        this.FindAvailableCapacityDialog = new Locale_FindAvailableCapacityDialog();
        this.OverviewDefaultOptions = new Locale_OverviewDefaultOptions();
        this.Reboot = new Locale_Reboot();
        this.Upgrade = new Locale_Upgrade();
        this.WorkPackages = new Locale_WorkPackages();
        this.ContractVersionRetention = new Locale_ContractVersionRetention();
        this.ContractConstraints = new Locale_ContractConstraints();
        this.PickerFields = new Locale_PickerFields();
        this.CustomProperty = new Locale_CustomProperty();
        this.LookupProject = new Locale_LookupProject();
        this.LookupResource = new Locale_LookupResource();
        this.ContractInfoDto = new Locale_ContractInfoDto();
        this.MatchingActivityDto = new Locale_MatchingActivityDto();
        this.MoveAllAllocationsDto = new Locale_MoveAllAllocationsDto();
        this.MoveRequestDto = new Locale_MoveRequestDto();
        this.WorkPackageImportDto = new Locale_WorkPackageImportDto();
        this.MailTemplate = new Locale_MailTemplate();
        this.Announcements = new Locale_Announcements();
        this.Origin = new Locale_Origin();
        this.Project = new Locale_Project();
        this.Report = new Locale_Report();
        this.Resource = new Locale_Resource();
        this.Rule = new Locale_Rule();
        this.ThemeContainer = new Locale_ThemeContainer();
        this.Settings = new Locale_Settings();
        this.WorkProfile = new Locale_WorkProfile();
        this.LogSettings = new Locale_LogSettings();
        this.ScheduleJob = new Locale_ScheduleJob();
        this.ScheduleRun = new Locale_ScheduleRun();
        this.RecurrenceRule = new Locale_RecurrenceRule();
        this.AutoAlignContractNames = new Locale_AutoAlignContractNames();
        this.ProjectListItem = new Locale_ProjectListItem();
        this.DataService = new Locale_DataService();
        this.PluginInvoker = new Locale_PluginInvoker();
		this.APIMessages = new Locale_APIMessages();
		this.ChartPanel = new Locale_ChartPanel();
		this.MailServiceConfig = new Locale_MailServiceConfig();
		this.AutoDistributionConfig = new Locale_AutoDistributionConfig();
		this.CustomContractPropertiesDialog = new Locale_CustomContractPropertiesDialog();
		this.HelpSection = new Locale_HelpSection();
		this.pushNotificationAnnouncementViewer = new Locale_PushNotificationAnnouncementViewer();
    }
}
