import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildRoute } from '../../helpers/routeHelpers';
import { useUISettings } from "../../context/network/http/QueryProvider/queries/UISettings";

export const AccessCheck = () => {
	const goTo = useNavigate();
	
	const { data: uiSettings, isFetched } = useUISettings()

	// const { resource } = useStore(store => ({
	// 	resource: store.user?.Resource,
	// }));

	const didMount = useRef(false);

	useEffect(() => {
		// removing test for isFetched, as it never hits the check. is this okey? (ewi)
		if (isFetched) {
			// only do this on first mount
			if (didMount.current)
				return;
			didMount.current = true;
	
			// if (!resource?.UserTypes?.length)
			// uiSettings is null if we fail to get a user (e.g bad authentication)
			if (!uiSettings?.resource?.userTypes?.length) {
				goTo(buildRoute('/accessdenied'));
			}
		}
	});

	return null;
};
