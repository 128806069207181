import { ReactElement, useCallback, useState } from 'react';
import { IDropdownOption } from '@fluentui/react';
import { IFieldRenderProps, IFormItem, IPredefinedArrayFieldConfig, useFormContext, useValidation, useWatch } from 'spark-forms';
import { useEffectOnce } from '@pum-spark/react-hooks';
import { FilterDropdown } from '../../../../_components/FilterDropdown/FilterDropdown';

export interface PredefinedArrayFilterFieldProps<T extends IFormItem>
    extends IFieldRenderProps<T, IPredefinedArrayFieldConfig<T>, string | number | undefined> {
    config?: IPredefinedArrayFieldConfig<T>;
}

export const PredefinedArrayFilterField = <T extends IFormItem>({
    value: val,
    fieldName,
    config,
    disabled,
    path,
}: PredefinedArrayFilterFieldProps<T>): ReactElement | null => {
    const [value, setValue] = useState<string | number | undefined | Array<string> | Array<number>>(val);

    const { onChange: onChangeForm, parentItem } = useFormContext();
    const { validate, validationMessage } = useValidation(path);

    useWatch(path, () => setValue(val), [val]);

    const [placeHolder, setPlaceHolder] = useState('');

    const [options, setOptions] = useState<{ key: string | number; text: string }[]>();

    useEffectOnce(() => {
        // async fetch of values
        const asyncGetter = config?.predefinedOptions?.optionsAsync as (item: T) => Promise<{ key: string | number; text: string }[]>;
        if (asyncGetter != null) {
            setPlaceHolder('Fetching..');
            const fetch = async () => {
                try {
                    const options = await asyncGetter(parentItem as Readonly<T>);
                    setPlaceHolder('');
                    setOptions(options);
                } catch (error) {
                    setPlaceHolder('Failed getting items');
                    console.error(error);
                }
            };
            fetch();
        } else {
            // use static values
            setOptions(config?.predefinedOptions?.options);
        }
    });

    const onChange = useCallback(
        (option: IDropdownOption | undefined) => {
            if (option === undefined) return;

            let newValue = undefined;

            if (config?.multiSelect) {
                const parsed = (value as Array<string | number>) ?? [];
                const clone = [...parsed];

                if (option.selected) clone.push(option.key);
                else {
                    const currIndex = clone.indexOf(option.key);
                    if (currIndex > -1) {
                        clone.splice(currIndex, 1);
                    }
                }
                newValue = clone;
                setValue(clone as []);
            } else {
                newValue = option.key;
                setValue(newValue);
            }

            onChangeForm(fieldName, newValue);
            validate();
        },
        [config?.multiSelect, onChangeForm, fieldName, value, validate],
    );

    return (
        <FilterDropdown
            disabled={disabled}
            multiSelect={config?.multiSelect ?? false}
            options={options ?? []}
            selectedKey={config?.multiSelect ? undefined : value}
            selectedKeys={config?.multiSelect ? (value as string[] | number[]) : undefined}
            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => onChange(option)}
            errorMessage={validationMessage}
            onBlur={validate}
            placeholder={placeHolder}
            {...config?.componentProps}
        />
    );
};
