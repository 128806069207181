import { fieldOptionsFactory, IFormItem, IFormSchema, Watch } from 'spark-forms';
import { language } from '../../../Services/LocalizationService';
import { StringEx } from '../../../Utilities/StringEx';
import { objectUtil } from '../../helpers/object';
import { IRule } from './IRule';
import { IRecurrenceRule } from './recurrence/IRecurrenceRule';
import { recurrenceRuleSchema } from './recurrence/recurrenceRuleSchema';

export const ruleSchema: IFormSchema<IRule> = {
    fields: {
        name: fieldOptionsFactory.string({ 
			displayName: language.Common.Name,
			validation: {
				validationMark: 'required',
				validationRule: async item => {
					if (StringEx.isNullOrWhiteSpace(item.name)) {
						return language.Rule.RuleMustBeNamed;
					}
				}
			}
		}),
        recurring: fieldOptionsFactory.boolean({
            displayName: language.Rule.Recurring,
            description: language.Rule.RecurringDescription,
        }),
        start: fieldOptionsFactory.date({
            displayName: language.Common.Start,
            description: language.Rule.StartDescription,
            hide: item => item.recurring,
			validation: {
				validationMark: 'required',
				validationRule: async item => {
					if (!item.recurring && !item.start) {
						return language.Rule.StartMustBeSpecified;
					}
				}
			}
        }),
        end: fieldOptionsFactory.date({
            displayName: language.Common.End,
            description: language.Rule.EndDescription,
            hide: item => item.recurring,
			validation: {
				validationMark: 'required',
				validationRule: async item => {
					if (!item.recurring && !item.end) {
						return language.Rule.EndMustBeSpecified;
					}
					if (!item.recurring && item.start > item.end) {
						return language.Rule.EndMustBeAfterStart;
					}
				}
			}
        }),
        includeWeekends: fieldOptionsFactory.boolean({
            displayName: language.Rule.IncludeWeekends,
            description: language.Rule.IncludeWeekendsDescription,
            hide: item => item.recurring,
        }),
        work: fieldOptionsFactory.number({ 
			displayName: language.Common.Work, 
			description: language.Rule.WorkDescription,
			validation: {
				validationMark: 'required',
				validationRule: async item => {
					if (item.work == null || item.work < 0) {
						return language.Rule.WorkValidation;
					}
				}
			}
		}),
        recurrenceRule: fieldOptionsFactory.formItem({
            displayName: language.Rule.RecurrenceRuleSettings,
            config: { 
				schema: { 
					schema: recurrenceRuleSchema 
				},
				watch: {
					frequency: (item: IRecurrenceRule) => {
						const clone = objectUtil.deepClone(item);
						clone.day = null;
						clone.dayOfWeek = null;
						clone.daysOfWeek = null;
						clone.months = null;
						clone.ordinalWeek = null;
						return clone;
					}
				} as Watch<IFormItem>,
			},
            hide: item => !item.recurring,
        }),
    },
};
