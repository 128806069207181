import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ApiCalls } from '../../../api/api';
import { Project, QueryGroup, EntityType } from '../../../api/generated/data-contracts';
import { useStore } from '../../../context/store';
import { language } from '../../../Services/LocalizationService';
import { OverviewCreator } from '../../../_components/OverviewCreator/OverviewCreator';
import { PROJECT_OVERVIEW } from '../../../context/network/http/QueryProvider/queryKeys';
import { useProjectOverviewMenuItems } from './hooks/useProjectOverviewMenuItems';
import { ProjectForm } from './components/ProjectForm/ProjectForm';
import { ProjectBulkEditor } from './components/ProjectBulkEditor/ProjectBulkEditor';
import { getNestedEntityProperty, sortOverview } from '../_components/helpers/sortHelper';
import { useTPPermissions } from '../../../hooks/usePermissions';
import { useUISettings } from '../../../context/network/http/QueryProvider/queries/UISettings';
import { ViewFilterPanel } from '../../capacityViews/resourceplanner/components/ViewFilterPanel/ViewFilterPanel';
import { projectSchema } from '../../../forms/project/projectSchema';
import { generateProjectOverviewColumns } from './projectOverviewColumns';
import { originHelper } from '../../../forms/_builders/new/helpers/origins/originHelper';

export const ProjectOverview = () => {
    const queryClient = useQueryClient();
    const [disallowedProps, setDisallowedProps] = useState<string[]>([]);
    const [showBulkEdit, setShowBulkEdit] = useState(false);
    const setBlockDialog = useStore(store => store.setBlockDialog);
    const [onlyActive, setOnlyActive] = useState(true);
	const { data: uiSettings } = useUISettings();
	const isAllowedToEditProjects = useTPPermissions({ some: true, userTypes: uiSettings.settings.allowedToEditProjects });
	
	const [openPanel, setOpenFilterPanel] = useState(false);
	const [filter, setFilter] = useState<QueryGroup>();
	
    const queryKey = useMemo(() => [PROJECT_OVERVIEW, onlyActive, filter] as const, [onlyActive, filter]);
	
    const { data, isFetching, refetch } = useQuery(
        queryKey,
        async () => {
            try {
                const response = await ApiCalls.getProjectsForOverview2({ onlyActive, filter });
                return response.data as Project[];
            } catch (err) {
                setBlockDialog(null, {
                    title: language.ProjectOverview.FailedGettingProjects,
                    subText: typeof err === 'string' ? err : err.message,
                });
            }
        },
        { placeholderData: useRef([]).current as Project[] },
    );

    useEffect(() => {
        const getDis = async () => {
            const disProps = originHelper.getDisallowedProjectFields();
            setDisallowedProps(disProps);
        };
        getDis();
    }, []);

    const setProjects = useCallback(
        (updater: (items: Project[]) => Project[]) => {
            queryClient.setQueryData(queryKey, updater);
        },
        [queryKey, queryClient],
    );

    const [isNewlyCreated, setIsNewlyCreated] = useState(false);

    const menuItems = useProjectOverviewMenuItems({
        onlyActive,
        refetch,
        setIsNewlyCreated,
        setOnlyActive,
        setProjects,
        setShowBulkEdit,
		setOpenFilterPanel
    });

    const columns = useMemo(() => generateProjectOverviewColumns(), []);

    const onGetPanelHeaderText = useCallback(
        (project: Project) => {
            if (isNewlyCreated) {
                return `Creating New Project${project?.name ? `: ${project?.name}` : ''}`;
            }
            return `Edit: ${project?.name}`;
        },
        [isNewlyCreated],
    );

    const onClickSave = useCallback(
        async (createdProject: Project) => {
            const [newProject]: Project[] = await ApiCalls.upsertEntityReturnExpanded({ entity: EntityType.Project, items: [createdProject] }).then(
                res => res.data,
            );
            if (isNewlyCreated) {
                setProjects(projects => [newProject, ...projects]);
				setIsNewlyCreated(false);
            } else {
                setProjects(projects => projects.map(project => (project.id === newProject.id ? newProject : project)));
                refetch();
            }
        },
        [isNewlyCreated, refetch, setProjects],
    );

    const onClosePanel = useCallback(() => {
        setIsNewlyCreated(false);
    }, []);

    const onSubmitBulkEdit = useCallback(
        async (editedProjects: Project[]) => {
            const updatedProjects: Project[] = await ApiCalls.upsertEntityReturnExpanded({ entity: EntityType.Project, items: editedProjects }).then(
                res => res.data,
            );
            setProjects(projects => projects.map(project => updatedProjects.find(updatedProject => updatedProject.id === project.id) || project));
            refetch();
        },
        [refetch, setProjects],
    );

    const onSearchProjects = useCallback(
        (resources: Project[], searchInput: string) => {
            const columnNames = columns.map(col => col.fieldName);
			// always enable search by id
			columnNames.push('id');
            const text = searchInput.toLowerCase();
            return resources.filter(resource => {
                for (const key of columnNames) {
                    const value = getNestedEntityProperty(resource, key);
                    if (typeof value === 'string' && value.toLowerCase().includes(text)) {
                        return true;
                    }
                }
                return false;
            });
        },
        [columns],
    );

    return (
        <OverviewCreator
            items={data}
            onGetSearchPlaceholderText={onGetSearchPlaceholderText}
            onGetPanelHeaderText={onGetPanelHeaderText}
            loading={isFetching}
            onClickSave={onClickSave}
            onClosePanel={onClosePanel}
            columns={columns}
            menuItems={menuItems}
            searchFilter={onSearchProjects}
            panelContent={<ProjectForm />}
			searchDebounceTimout={300}
			onColumnSort={sortOverview}
			onShouldCancelItemInvocation={() => !isAllowedToEditProjects}
        >
            <ProjectBulkEditor show={showBulkEdit} setShow={setShowBulkEdit} originProperties={disallowedProps} onSubmit={onSubmitBulkEdit} />
			<ViewFilterPanel
                schema={projectSchema}
                entityType={'project'}
                customProperties={uiSettings.settings.projectCustomProperties}
                initialFilterValue={filter}
                headerText={'Filter editor'}
                onClickSave={filter => {
					setFilter(filter);
					setOpenFilterPanel(false);
				}}
                openPanel={openPanel}
                setOpenPanel={setOpenFilterPanel}
                onDismissPanel={() => {
                    setOpenFilterPanel(false);
                }}
            />
        </OverviewCreator>
    );
};

const onGetSearchPlaceholderText = (projects: Project[]) => `Search in ${projects.length} projects`;


