import React, { useState } from 'react';
import { DatePicker } from '@fluentui/react/lib/DatePicker';
import { IDropdownOption, Stack } from '@fluentui/react';
import { FilterDropdownMultiSelect } from '../../../../_components/FilterDropdownMultiSelect/FilterDropdownMultiSelect';
import { useStore } from '../../../../context/store';
import { useUISettings } from '../../../../context/network/http/QueryProvider/queries/UISettings';
import { datePickerHelper } from '../../../../helpers/datePickerHelper';
import { ApiCalls } from '../../../../api/api';
import { toDropdownOptions } from '../../../../Utilities/DropdownOptionEx';
import { language } from '../../../../Services/LocalizationService';
import { useQuery } from 'react-query';
import { useMemoQueryKey } from '../../../../context/network/http/QueryProvider/queries/useMemoQueryKey';
import { WORKPACKAGE_IMPORT } from '../../../../context/network/http/QueryProvider/queryKeys';

interface IPackageFormProps {
	defaultStartDate?: Date;
	onFormFieldChange: (fieldName: string, value: any) => void;
}

export const WorkpackageImportForm: React.FC<IPackageFormProps> = ({ defaultStartDate, onFormFieldChange }) => {
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

	const { settings } = useUISettings().data;
    const timeline = useStore(store => store.timeline);

	const qKey = useMemoQueryKey(WORKPACKAGE_IMPORT);
	const { data: packages = [] } = useQuery({
        queryKey: qKey,
        queryFn: () => ApiCalls.getAllWorkpackagesFlat(null).then(res => toDropdownOptions(res.data,  p => p.id, p => p.name, true)),
    });

	const handleDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (!option) return;
		const newSelectedItems = option.selected
			? [...selectedItems, option.key as string]
			: selectedItems.filter(key => key !== option.key);
		setSelectedItems(newSelectedItems);
		onFormFieldChange('selectedPackages', newSelectedItems);
    };

    const handleDateChange = (date: Date | null, field: string) => {
        onFormFieldChange(field, date);
    };

    return (
        <Stack>
            <FilterDropdownMultiSelect
				options={packages}
				onChange={handleDropdownChange} placeholder="Choose templates to import.." defaultSelectedKey={null} multiSelect />
            <DatePicker
                label={language.Common.Start}
                placeholder="Select a start date..."
                onSelectDate={date => handleDateChange(date, 'startDate')}
				value={defaultStartDate}
                minDate={datePickerHelper.getMinDateBoundary(timeline.resolution, settings.freezePeriod, settings.useISO8601)}
                calendarProps={{ restrictedDates: datePickerHelper.getRestrictedDates(timeline.resolution, 'first', settings.freezePeriod) }}
            />
            <DatePicker
                label={language.Common.End}
                placeholder={language.WorkPackageImportDto.UseDateOnlyWhenShrinkingOrExtendingPeriod}
                onSelectDate={date => handleDateChange(date, 'endDate')}
				minDate={datePickerHelper.getMinDateBoundary(timeline.resolution, settings.freezePeriod, settings.useISO8601)}
                calendarProps={{ restrictedDates: datePickerHelper.getRestrictedDates(timeline.resolution, 'last', settings.freezePeriod) }}
            />
        </Stack>
    );
};