import { IconButton } from '@fluentui/react';
import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { ApiCalls } from '../../api/api';
import { GridType } from '../../Entities/GridType';
import { ScenarioType } from '../../Entities/Scenario';
import { activeProposalStatesWithoutDraft } from '../../Entities/Versions/ProposalState';

interface IProps {
    projectId: string;
    gridType: GridType;
    refresh?: string;
    callback: (event: any) => void;
}

export const ScenariosAvailableIcon: FunctionComponent<IProps> = ({projectId, gridType, callback, refresh}) => {

	const { data: scenarios } = useQuery(
		['scenarios', projectId, gridType ],
        async () => {
            const response = await ApiCalls.getScenarios({
				scenarioType: ScenarioType.ProjectScenario,
				projectId: projectId,
				filterOnRbs: gridType === GridType.Allocation,
				gridType: gridType,
				states: activeProposalStatesWithoutDraft
			});
			return response.data;
        }
	)

    return (
        <>
            {scenarios?.length ? (
                <IconButton
                    iconProps={{
                        iconName: 'DocumentSet',
                    }}
                    title={'Scenarios available'}
                    styles={{ root: { position: 'absolute', right: '30px', bottom: '2px', fontSize: '16px', color: 'darkorange', cursor: 'pointer' } }}
                    onClick={callback}
                />
            ) : null}
        </>
    );
};
