import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { PFDialog } from './PFDialog';
import { ObjectEditor } from './ObjectEditor';
import { language } from '../../Services/LocalizationService';
import { ActivityDistributionConfig } from '../../Entities/ActivityDistribution/ActivityDistributionConfig';
import { DataService } from '../../Services/DataService';
import { EntityType } from '../../Entities/EntityTypes';
import { Project } from '../../Entities/Main/Project';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { PrimaryButton, Stack, Toggle } from '@fluentui/react';
// import { useStore } from '../../context/store';

interface IProps {
	distributionConfig?: ActivityDistributionConfig;
	projectId?: string;
	show: boolean;
	callback: (settings: ActivityDistributionConfig) => void;
	dismiss: () => void;
	hiddenProperties?: Array<string>;
}

enum View {
	Advanced = 1,
	Basic = 2,
}

const AutoDistributionSettingsDialog: FunctionComponent<IProps> = ({callback, dismiss: dismissProps, show, children, distributionConfig, hiddenProperties, projectId}) => {

	const [showDialog, setShowDialog] = useState<boolean>(false);
	const [settings, setSettings] = useState<ActivityDistributionConfig>();
	const [propertyLevel, setPropertyLevel] = useState<number>(View.Basic);
	const [project, setProject] = useState<Project>(null);

	useEffect(() => {
		let mounted = true
		DataService.Get<Project>(EntityType.Project, projectId).then(data => {
			if (mounted) {
				setProject(data)
			}
		})
		return () => {
			mounted = false;
		}
		// console.log("🚀 ~ projectId", projectId)
		// const [promise, cancel] = DataService.CancelableGet<Project>(EntityType.Project, projectId);
		// promise.then(data => setProject(data))
		// return cancel;
	}, [projectId])

	// const { project } = useStore(store => ({
	// 	project: store.project
	// }))

	// const isMounted = useIsMounted();
	// const isMounted = useRef(false);
	// useEffect(() => {
	// 	isMounted.current = true;
	// 	return () => {
	// 		isMounted.current = false;
	// 	}
	// }, [])
	
	useEffect(() => {
		// let mounted = true;
		if (project) {
			if (!settings) {
				if (distributionConfig != null) {
					setSettings({...distributionConfig});
				} else if (projectId != null) {
					// get project specific settings - fallback to default settings
					const proj = {...project} as Project;
					const distSettings = proj?.ActivityDistributionConfig ?? OverviewContext.Settings.DefaultActivityDistributionConfig;
					setSettings({...distSettings});
					
					// const [promise, cancel] =  DataService.CancelableGet<Project>(EntityType.Project, projectId);
					// promise.then(project => {
					// 	if (isMounted.current) {
					// 		// get project specific settings - fallback to default settings
					// 		const distSettings = project?.ActivityDistributionConfig ?? OverviewContext.Settings.DefaultActivityDistributionConfig;
					// 		setSettings({...distSettings});
					// 	}
					// });
					// return cancel
				}
			}
		}
	}, [distributionConfig, project, project?.ActivityDistributionConfig, projectId, settings]);
 
	useEffect(() => {
		setShowDialog(show);
	}, [show])

	// if using directly from project
	const saveOnProject = useCallback(async () : Promise<void> => {
		// re-fetch project
		const project = await DataService.Get<Project>(EntityType.Project, projectId);
		project.ActivityDistributionConfig = {...settings};
		await DataService.Upsert<Project>(EntityType.Project, [ project ]);
		callback({...settings});
		setSettings(null);
	}, [callback, projectId, settings])

	const save = useCallback(async () : Promise<void> => {
		callback({...settings});
		setSettings(null);
		setShowDialog(false);
	}, [callback, settings])

	const dismiss = useCallback(() : void => {
		setShowDialog(false);
		dismiss();
		setSettings(null);
		setPropertyLevel(View.Basic);
	}, [])

	// const fetchDistributionConfig = async () : Promise<void> => {
	// 	let project = await DataService.Get<Project>(EntityType.Project, props.projectId);
	// 	// get project specific settings - fallback to default settings
	// 	let distSettings = project?.ActivityDistributionConfig ?? OverviewContext.Settings.DefaultActivityDistributionConfig;
	// 	setSettings({...distSettings});
	// }

	// set reset-no-change-marker
	// then save as null if true
	const resetDistributionConfig = useCallback(async () : Promise<void> => {
		const project = await DataService.Get<Project>(EntityType.Project, projectId);
		// get project specific settings - fallback to default settings
		let distSettings = null;
		if (distributionConfig != null)
			distSettings = project?.ActivityDistributionConfig;
		if (distSettings == null)
			distSettings = OverviewContext.Settings.DefaultActivityDistributionConfig;
		setSettings(null);
		setSettings({...distSettings});
	}, [distributionConfig, projectId])

	if (settings == null) return null;

	return 	(
			<PFDialog
				title={ language.AutoDistributionConfig.ProjectSettingsDialogTitle }
				showDialog={showDialog}
				className="tp-autodistsettings"
				callback={ projectId && distributionConfig == null ? saveOnProject : save }
				dismissCallback={ dismiss }
				extraDialogFooterContent={(
					<div style={{position: "absolute", left: "45px"}}>
						<Stack styles={{root:{flexDirection: "row"}}}>
							<Toggle
								onText={language.AutoDistributionConfig.AdvancedSettings}
								offText={language.AutoDistributionConfig.SimpleSettings}
								onChange={(obj, value) => {
									setPropertyLevel(value ? View.Advanced : View.Basic);
								}}
								defaultChecked={false}
								styles={{ root: { marginBottom: 0 } }}
							/>
							<PrimaryButton
								text={"Reset settings"}
								onClick={resetDistributionConfig}
								styles={{ root: { marginBottom: 0 } }}
							/>
						</Stack>
					</div>
				)}
			>
				{ settings != null &&
					<ObjectEditor
						Item={settings}
						ItemType={ActivityDistributionConfig}
						DisabledProperties={[]}
						HiddenProperties={hiddenProperties ?? []}
						PropertyFilterLevel={propertyLevel}
						ChangesCallback={(item, prop, val) => setSettings({...item})}
					/>
				}
			</PFDialog>
	)
}

export default AutoDistributionSettingsDialog;