import { language } from '../../Services/LocalizationService';
import { DefaultButton, PrimaryButton, Spinner, Stack, Text } from '@fluentui/react';
import { useStore } from '../../context/store';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ApiCalls } from '../../api/api';
import { CONTRACT_CUSTOMPROPS } from '../../context/network/http/QueryProvider/queryKeys';
import { customPropertyUtil } from '../../forms/customProperty/helpers/customPropertyUtil';
import { LoadingSpinner } from '../Common/LoadingSpinner';
import { useCheckTPPermissions } from '../../hooks/usePermissions';
import {  CustomPropertyWithPermissions } from '../../api/generated/data-contracts';
import { getcustomPropertiesSchema } from '../../components_new/customPropertiesSchema';
import { useForm } from 'spark-forms';
import { TPBuilder } from '../../forms/_builders/new/TPBuilder';
import { FluentLabel, PivotGrouping, PivotGroupingContainer } from 'spark-forms-fluent8';

export const CustomContractPropertiesDialog = ({ ContractId, AllProperties }: { ContractId: string; AllProperties: any }) => {
	const checkPerms = useCheckTPPermissions();
    const [item, setItem] = useState<any>({});
	const localItem = useRef(item);

    const { isFetching } = useQuery(
        [CONTRACT_CUSTOMPROPS, ContractId],
        () =>
            ApiCalls.getContractCustomProperties(ContractId)
                .then(res => res.data)
                .catch(res => {
                    throw res.error;
                }),
        {
            onSuccess: customProps => {
                setItem({ ...customProps });
				localItem.current = { ...customProps };
            },
        },
    );
    const { setBlockDialog, addErrorNotification, addSuccesNotification } = useStore(store => ({
        setBlockDialog: store.setBlockDialog,
        addErrorNotification: store.addErrorNotification,
        addSuccesNotification: store.addSuccesNotification,
    }));

    const closeModal = useCallback(() => {
        setBlockDialog(null);
    }, [setBlockDialog]);

    const allowed = useCallback(
        (property: CustomPropertyWithPermissions): boolean => {
            if (property.editableBy?.length) {
                return checkPerms({ userTypes: property.editableBy, some: true });
            }
            return true;
        },
        [checkPerms],
    );

    const validationMessage = useMemo(() => {
        const props = customPropertyUtil.customProperties('contract');
        if (isFetching) {
            return '';
        }
        return props
            .reduce((message, prop) => {
                if (prop.required && allowed(prop)) {
                    const value: any = item?.[prop.id];
                    if (value == null || value === '') {
                        return message + `Please fill out "${prop.displayName}" ${prop.group ? `in group "${prop.group}"` : ''}\n\n`;
                    }
                }
                return message;
            }, '')
            .trim();
    }, [allowed, isFetching, item]);

    const { mutate, isLoading } = useMutation(
        async () =>
            ApiCalls.updateContractCustomProperties({ id: ContractId, customProperties: { ...item} })
                .then(res => res.data)
                .catch(res => {
                    throw res.error;
                }),
        {
            onError: (err: any) => {
                addErrorNotification(err.message);
            },
            onSuccess: success => {
                if (success) {
                    addSuccesNotification(language.CustomContractPropertiesDialog.SuccessUpdate + AllProperties.ContractName);
                    closeModal();
                } else {
                    addErrorNotification(language.CustomContractPropertiesDialog.FailedUpdate + AllProperties.ContractName);
                }
            },
        },
    );

	const [customPropertiesSchema, setCustomPropertiesSchema] = useState<any>(undefined);

	useEffect(() => {
		const getCustomPropertySchema = async () => {
			setCustomPropertiesSchema(await getcustomPropertiesSchema('contract', cp => !allowed(cp)))
		}
		getCustomPropertySchema();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const builderOptions = useMemo(() => ({
		labelComponent: FluentLabel
	}), []);

	const { Form } = useForm({
		item: localItem.current,
		onChange: setItem,
		schema: customPropertiesSchema,
		builder: TPBuilder,
		groupingContainer: PivotGroupingContainer,
		grouping: PivotGrouping,
		builderOptions,
	});

    return (
        <Stack styles={{ root: { width: 500, minHeight: 600 } }} verticalAlign="space-between">
            {isFetching || !customPropertiesSchema ? (
                <LoadingSpinner visible message={language.CustomContractPropertiesDialog.LoadingProperties} />
            ) : (
				<>
					{customPropertiesSchema && <Form />}
				</>
            )}
            <Stack horizontal horizontalAlign="space-between" verticalAlign="end">
                <Text styles={{ root: { '.ms-TextField-errorMessage': { whiteSpace: 'pre-wrap' } } }}>
                    {validationMessage}
                </Text>
                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
                    {isLoading && <Spinner label={language.CustomContractPropertiesDialog.UpdatingProperties} labelPosition="left" />}
                    <Stack horizontal grow={1} tokens={{childrenGap: 10}}>
                        <PrimaryButton disabled={Boolean(validationMessage)} onClick={() => mutate()}>
                            {language.Common.Save}
                        </PrimaryButton>
                        <DefaultButton onClick={closeModal}>{language.Common.Cancel}</DefaultButton>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};