import { useMemo } from 'react';
import { QueryFunction, useQuery } from 'react-query';
import { ApiCalls } from '../../../../../api/api';
import { Project, EntityType} from '../../../../../api/generated/data-contracts';
import { LocalStorage } from '../../../../../Utilities/LocalStorage';
import { fromMinutes } from '../helpers/queryHelper';
import { CURRENT_PROJECT_KEY } from '../queryKeys';

export const queryCurrentProject: QueryFunction<Project, string[]> = async ({ queryKey: [ project, projectId ] }) => {
    // console.log("🚀 ~ constqueryCurrentProject:QueryFunction<Project,string[]>= ~ project, projectId", project, projectId)
	const data = await ApiCalls.getEntity({ entity: EntityType.Project, id: projectId }).then(proj => proj.data);
	LocalStorage.set(CURRENT_PROJECT_KEY, data);
	return data;
};

// export const useCurrentProject = (projectId: string) => {
//     return useQuery(['project', projectId], queryCurrentProject, { cacheTime: fromMinutes(5), enabled: Boolean(projectId)});
// };
export const useCurrentProject = (projectId?: string) => {
	// The query will not execute unless a project id is provided
    // const query = useQuery(['project', projectId], queryCurrentProject, { cacheTime: fromMinutes(5), enabled: Boolean(projectId)});
    const query = useQuery([CURRENT_PROJECT_KEY, projectId], queryCurrentProject, { staleTime: fromMinutes(5), enabled: Boolean(projectId)});
    // const query = useQuery(['project', projectId], queryCurrentProject, { staleTime: 5000, enabled: Boolean(projectId)});
    // const query = useQuery(['project', projectId], queryCurrentProject, { cacheTime: fromMinutes(5), staleTime: fromMinutes(5), enabled: Boolean(projectId)});
	return useMemo(() => {
		if (!projectId) {
			const cachedProject = LocalStorage.get<Project>(CURRENT_PROJECT_KEY);
			// Attempt to provide the last loaded project from cache
			if (cachedProject) {
				return {
					...query,
					data: cachedProject,
				}
			}
		}
		// We have exhausted all attempts to provide meaningful data, the implementation must handle fallback
		return query;
	}, [projectId, query])
};
