import React, { FunctionComponent } from 'react';
import { TextField } from '@fluentui/react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { WorkWeek } from '../../../Entities/Main/WorkWeek';
 
interface IProps extends IDynamicEditorComponentProps {
	Item: WorkWeek;
	Update: (week: WorkWeek) => void;
	Key?: string;
}

const WorkWeekEditor: FunctionComponent<IProps> = props => {

	const Onchange = (prop: string, value: string) => {
		const item = props.Item;
		item[prop] = +value;
		props.Update(item);
	}

	const GenerateInputField = (prop: string) : JSX.Element => {
		return <TextField 
					key={ `workweek-${prop}` }
					styles={ { root: { width: "50px", paddingRight: "5px" }, field: { textAlign: "center" } } }
					label={prop.substring(0, 3)}
					type={"number"}
					disabled={ props.ReadOnly }
					defaultValue={props.Item[prop]+""}
					onChange={ (e, value) => Onchange(prop, value) }
					onClick={e => (e.currentTarget as HTMLInputElement).select()}
				/>
	}

	return 	(
		<div style={{ display: "flex", paddingLeft: "20px" }}>
			{Object.keys(props.Item).filter(_ => _.substring(_.length - 3) === "day").map(_ => GenerateInputField(_))}
		</div>
	)
}

export default WorkWeekEditor;