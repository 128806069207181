import { useCallback, useMemo, useState } from 'react';
import { ActivityItem, IconButton, Spinner, SpinnerSize, Text } from '@fluentui/react';
import { DateEx } from '../../../../Utilities/DateEx';
import { UserType } from '../../../../Entities/UserType';
import { language } from '../../../../Services/LocalizationService';
import { CommentTextField } from './CommentTextField';
import { Comment } from '../../../../api/generated/data-contracts';
import { useUISettings } from "../../../../context/network/http/QueryProvider/queries/UISettings";

export const CommentBox: React.FC<{
    comment: Comment;
    removeComment: (comment: Comment) => Promise<any>;
    canDelete?: boolean;
    editComment: (text: string, comment: Comment) => Promise<void>;
    isSuperUser: boolean;
}> = ({ comment, removeComment, canDelete, editComment, isSuperUser }) => {
	const { data: uiSettings } = useUISettings();
	const userResourceId = uiSettings.resource.id;
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const initials = useMemo(() => {
        let initials = comment.authorName.match(/\b\w/g) || ([] as any);
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        return initials;
    }, [comment.authorName]);


    const isOwnComment = comment.authorId === userResourceId;

    const handleEdit = useCallback(
        async (text: string) => {
            await editComment(text, comment);
			setEditMode(false);
            return true;
        },
        [comment, editComment],
    );

    return (
        // TODO: if short timestamp display as yesterday/40min ago
        <div className={'tp-comment-flex-container'}>
            <ActivityItem
                className={`tp-comments-list-item ${isOwnComment ? 'own-comment' : ''}`}
                activityDescription={
                    isOwnComment
                        ? language.Comments.ActivityDesctiption.OwnComment
                        : language.Comments.ActivityDesctiption.NotOwnComment.replace('[[authorname]]', comment.authorName).replace(
                              '[[authorrole]]',
                              UserType[comment.authorRole].toString(),
                          )
                }
                activityPersonas={[{ imageInitials: initials }]}
                comments={comment.text}
                timeStamp={DateEx.asUserLocalWithTimePretty(new Date(comment.timestamp))}
                onRenderComments={({ comments }) => {
                    if (editMode) {
                        return <CommentTextField value={comment.text} addComment={handleEdit} autoAdjustHeight onBlur={() => setEditMode(false)} />;
                    }
                    return (
                        <Text variant="small" className="tp-comment-text">
                            {comments}
                        </Text>
                    );
                }}
            />
            {loading ? (
                <div className="tp-comment-delete-loading">
                    <Spinner size={SpinnerSize.small} />
                </div>
            ) : (
                <div className="tp-comment-delete-btn">
                    {(isSuperUser || (isOwnComment && canDelete)) && (
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            title={language.Comments.Delete}
                            onClick={async () => {
                                setLoading(true);
                                try {
                                    await removeComment(comment);
                                } catch (error) {
                                    console.log('remove comment ~ error', error);
                                    setLoading(false);
                                }
                            }}
                        />
                    )}
                    {isOwnComment && (
                        <IconButton
                            iconProps={{ iconName: 'Edit' }}
                            title={language.Comments.Edit}
                            onClick={() => {
                                setEditMode(true);
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
