import { FunctionComponent, useEffect, useState } from 'react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { language } from '../../../Services/LocalizationService';
import { OnCommentReceivedTemplateConfig } from '../../../Entities/Mail/Template/TemplateConfig/OnCommentReceivedTemplateConfig';
import { MailTemplateDataType } from '../../../Entities/Mail/Template/MailTemplateDataType';
import { ContractChangesTemplateConfig } from '../../../Entities/Mail/Template/TemplateConfig/ContractChangesTemplateConfig';
import { MailTemplateConfig } from '../../../Entities/Mail/Template/TemplateConfig/MailTemplateConfig';
import { ObjectEditor } from '../ObjectEditor';
 
interface IProps extends IDynamicEditorComponentProps {
	Item: MailTemplateConfig;
	Update: (config: MailTemplateConfig) => void;
	Key?: string;
}

const MailTemplateConfigEditor: FunctionComponent<IProps> = props => {

	const newItem = () : MailTemplateConfig => {
		const newItem = new MailTemplateConfig();
		newItem.MailTemplateConfigType = props.Parent?.TemplateDataType;
		return newItem;
	}

	const getItemType = () : typeof MailTemplateConfig => {
		switch (props.Parent?.TemplateDataType) {
			case MailTemplateDataType.ContractChangesNotification:
				return ContractChangesTemplateConfig;
			case MailTemplateDataType.OnCommentReceived:
				return OnCommentReceivedTemplateConfig;
			case MailTemplateDataType.Unknown:
			default:
				return null;
		}
	}

	const [item, setItem] = useState<MailTemplateConfig>(props.Item ?? newItem());
	const [itemType, setItemType] = useState<() => typeof MailTemplateConfig>();

	useEffect(() => {
		const ity = getItemType();
		setItemType(() => ity);
		setItem(props.Item);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.Item]);

	const onChange = (prop: string, value: any) => {		
		const copy = {...item};
		copy[prop] = value;
		setItem(copy);
		props.Update(copy);
	}

	if (itemType == null) return <p>{language.MailTemplate.NoConfig}</p>

	return 	(
		<ObjectEditor
			Item={item}
			ItemType={getItemType()}
			DisabledProperties={[]}
			HiddenProperties={[]}
			ReadOnly={props.ReadOnly}
			ChangesCallback={(item, property, value) => onChange(property, value)}
		/>
	)
}

export default MailTemplateConfigEditor;