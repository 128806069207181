import { FunctionComponent } from 'react';
import { IconButton, MessageBar, MessageBarType } from "@fluentui/react";
import { useNavigate } from 'react-router';
import { buildRoute } from '../../../helpers/routeHelpers';

export const MovedToModernSettingsBanner = (props: {redirect: string, settingName: string}) => {
	const goTo = useNavigate();

	return (
		<MessageBar 
			messageBarType={MessageBarType.warning}
			styles={{root: { maxWidth: '75%' }}}
		>
				{`${props.settingName} has been moved to modern settings`}
				<IconButton 
					title='Go to setting'
					iconProps={{ iconName: 'Share' }}
					onClick={() => goTo(buildRoute(props.redirect))}
				/>
		</MessageBar>
	)
}

const MovedToModernSettings: FunctionComponent<{redirectPath: string, settingName: string}> = (props: {redirectPath: string, settingName: string}) => {
	return (
		<MovedToModernSettingsBanner redirect={props.redirectPath} settingName={props.settingName} />
	)
}
export default MovedToModernSettings;