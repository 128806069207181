import React, { memo, useMemo, useCallback } from 'react';
import { IDropdownOption, Stack } from '@fluentui/react';
import { RecursiveTree } from '../../helpers/buildRbsTree';
import { RbsList } from './components/RbsList';
import { FilterButton } from './components/FilterButton';
import { FilterDropdown } from '../../../../../../_components/FilterDropdown/FilterDropdown';
// import { FilterDropdown } from '@pum-spark/fluent8-components';


type TFilterListBase = {
    key: string;
    type: 'button' | 'rbs';
    title: string;
};

export type TFilterListRbs = TFilterListBase & {
    // setCurrentFilterLevel: React.Dispatch<React.SetStateAction<number>>;
    onChange: (rbsPart: string) => void;
    currentPath: string;
};

export type TFilterListButton = TFilterListBase & {
    onClick: () => void;
};

export type TFilterListItem = TFilterListRbs & TFilterListButton;

type TFilterListProps = {
    category: (TFilterListRbs | TFilterListButton)[];
    rbsTree: RecursiveTree;
	setCurrentFilterLevel: React.Dispatch<React.SetStateAction<number>>;
};

export const FilterList = memo(({ category, rbsTree, setCurrentFilterLevel }: TFilterListProps) => {

	const handleDropdownChange = useCallback((event: React.FormEvent<HTMLDivElement>, option: IDropdownOption<TFilterListButton>) => {
		option.data.onClick();
	}, [])

    const { allButtonChoice, options, rbsChoices } = useMemo(() => {
        const rbsChoices = category.filter(choice => choice.type === 'rbs') as TFilterListRbs[];
        const allButtonChoices = category.filter(choice => choice.type === 'button') as TFilterListButton[];
        const allButtonChoice = allButtonChoices[0];
        const buttonChoices = allButtonChoices.slice(1);
        const options = buttonChoices.map((buttonFilter): IDropdownOption<TFilterListButton> => {
            return {
                key: buttonFilter.key,
                text: buttonFilter.title,
                data: buttonFilter,
            };
        });
        return {
            options,
            allButtonChoice,
            rbsChoices,
        };
    }, [category]);

    return (
        <div>
            <Stack
                horizontal
                tokens={{ childrenGap: 10, padding: '10px 24px 10px 0' }}
            >
                {allButtonChoice && <FilterButton onClick={allButtonChoice.onClick}>{allButtonChoice.title}</FilterButton>}
                {Boolean(rbsChoices.length) &&
                    rbsChoices.map(cat => {
                        const { key, onChange, currentPath } = cat;
                        return (
                            <Stack
                                key={key}
                                styles={dropdownContainerStyles}
                                horizontal
                                wrap
                            >
                                <RbsList rbsTree={rbsTree} onChange={onChange} currentPath={currentPath} key={currentPath} />
                                <FilterButton
                                    onClick={() => {
                                        setCurrentFilterLevel(c => ++c);
                                    }}
                                >
                                    Done
                                </FilterButton>
                            </Stack>
                        );
                    })}
                {Boolean(options.length) && (
                    <Stack
                        styles={dropdownContainerStyles}
                        horizontal
                        wrap
                    >
                        <FilterDropdown
                            options={options}
                            onChange={handleDropdownChange}
                            calloutProps={dropdownCalloutStyles}
                            placeholder="Choose.."
							defaultSelectedKey={null}
                        />
                    </Stack>
                )}
            </Stack>
        </div>
    );
});

const dropdownContainerStyles = {
	root: {
		'.ms-Dropdown-title': {
			border: 'none',
		},
		'.ms-Dropdown-container, .ms-Dropdown, .ms-Dropdown-title': {
			backgroundColor: 'transparent',
		},
		backgroundColor: '#ebebeb',
		borderRadius: 16,
		padding: '0 10px',
	},
}

const dropdownCalloutStyles = { styles: { root: { minWidth: 'max-content' } } }
