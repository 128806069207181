import { FunctionComponent, useState, useEffect } from 'react';
import { Dropdown } from '@fluentui/react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { DropdownOptionEx } from '../../../Utilities/DropdownOptionEx';
import { ContentConfigurationService } from '../../../Services/ContentConfigurationService';
import { ThemeContainer } from '../../../Entities/ContentConfiguration/ThemeContainer';
import { guid } from '../../../helpers/guid';

interface IProps extends IDynamicEditorComponentProps {
    Item: string;
    Update: (value: string) => void;
    Key?: string;
}

const ThemeSelector: FunctionComponent<IProps> = props => {
    const [themes, setThemes] = useState<Array<ThemeContainer>>();

    useEffect(() => {
        let mounted = true;
        const fetchThemes = async () => {
            const themeItems = await ContentConfigurationService.GetThemes();
            if (mounted) setThemes(themeItems);
        };
        fetchThemes();
        return () => {
            mounted = false;
        };
    }, []);

    const onChange = (item: string): void => {
        props.Update(item);
    };

    return (
        <>
            {themes && (
                <Dropdown
                    onChange={(ev, option, idx) => onChange(option.key as string)}
                    options={DropdownOptionEx.toDropdownOptionsAddEmptyEntry(
                        themes,
                        _ => _.id,
                        _ => _.Name,
                        true,
                        guid.empty,
                        'Default',
                    )}
                    defaultSelectedKey={props.Item}
                    disabled={props.ReadOnly || !themes}
                />
            )}
        </>
    );
};

export default ThemeSelector;
