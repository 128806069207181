import { FunctionComponent, useCallback } from 'react';
import { DialogFooter, PrimaryButton, DefaultButton, Modal, Stack, Text, useTheme } from '@fluentui/react';
import { language } from '../../../Services/LocalizationService';
import { IBlockContent } from './pageBlockContext';
import { useStore } from '../../store';

export const BlockingDialog: FunctionComponent<IBlockContent> = ({
    dismissEnable = true,
    refreshEnabled,
    subText,
    title,
    children,
    onDismiss: onDismissProps,
    buttons,
    ...modalProps
}) => {
    const theme = useTheme();
    const setBlockModal = useStore(store => store.setBlockDialog);
    const onDismiss = useCallback(() => {
		// prevent dismiss of modal when refreshenabled - forcing users to refresh
		if (refreshEnabled) {
			return;
		}
        setBlockModal(null);
        onDismissProps?.();
    }, [onDismissProps, refreshEnabled, setBlockModal]);
    return (
        <Modal
            onDismiss={onDismiss}
            isOpen
            {...modalProps}
        >
            <Stack tokens={{ padding: theme.spacing.l1 }} styles={{ root: { minHeight: 200 } }} verticalAlign="space-between">
                <Stack>
                    {title && <Text variant="xLarge">{title}</Text>}
                    {subText && <Text variant="mediumPlus">{subText}</Text>}
                </Stack>
                <Stack>{children}</Stack>
				{(Boolean(buttons?.length) || dismissEnable || refreshEnabled) && (
					<DialogFooter>
						{buttons?.map(({ type, ...props }) => {
							const onClick: typeof props.onClick = ev => {
								if (props.onClick) {
									props.onClick(ev);
								}
								if (props.shouldDismiss) {
									onDismiss();
								}
							};

							if (!type || type === 'default') {
								return <DefaultButton key={props.text} {...props} onClick={onClick} />;
							}
							return <PrimaryButton key={props.text} {...props} onClick={onClick} />;
						})}
						{dismissEnable && <PrimaryButton onClick={onDismiss} text={language.Common.Ok} />}
						{refreshEnabled && <PrimaryButton onClick={() => window.location.reload()} text={language.BlockingDialog.Refresh} />}
					</DialogFooter>
				)}
            </Stack>
        </Modal>
    );
};
