import { fieldOptionsFactory, IFormSchema } from 'spark-forms';
import { Day } from '../../../../Entities/Recurrence/Day';
import { DayOfWeek } from '../../../../Entities/Recurrence/DayOfWeek';
import { Month } from '../../../../Entities/Recurrence/Month';
import { Ordinal } from '../../../../Entities/Recurrence/Ordinal';
import { RecurrenceFrequency } from '../../../../Entities/Recurrence/RecurrenceFrequency';
import { language } from '../../../../Services/LocalizationService';
import { DropdownOptionEx } from '../../../../Utilities/DropdownOptionEx';
import { IRecurrenceRule } from './IRecurrenceRule';

export const recurrenceRuleSchema: IFormSchema<IRecurrenceRule> = {
    fields: {
        frequency: fieldOptionsFactory.predefinedArray({
            displayName: language.Common.Frequency,
            description: language.RecurrenceRule.FrequencyDescription,
            config: {
                predefinedOptions: { options: DropdownOptionEx.enumToDropdownOptionsWithoutSome<RecurrenceFrequency>(RecurrenceFrequency, [1]) },
            },
            // onChanged: item => {
            // 	item.day = null;
            // 	item.dayOfWeek = null;
            // 	item.daysOfWeek = null;
            // 	item.months = null;
            // 	item.ordinalWeek = null;
            // 	return null;
            // }
        }),
        skip: fieldOptionsFactory.predefinedArray({
            displayName: language.RecurrenceRule.Skip,
            description: language.RecurrenceRule.SkipDescription,
            config: {
                predefinedOptions: {
                    options: DropdownOptionEx.toDropdownOptions(
                        Array.from({ length: 10 }, (v, k) => k + 1),
                        _ => _,
                        _ => _ + '',
                    ),
                },
            },
        }),
        months: fieldOptionsFactory.predefinedArray({
            displayName: language.RecurrenceRule.Months,
            description: language.RecurrenceRule.MonthsDescription,
            hide: (obj: IRecurrenceRule) => obj.frequency !== RecurrenceFrequency.Year,
            config: { multiSelect: true, predefinedOptions: { options: DropdownOptionEx.enumToDropdownOptions<Month>(Month) } },
        }),
        ordinalWeek: fieldOptionsFactory.predefinedArray({
            displayName: language.RecurrenceRule.OrdinalWeek,
            description: language.RecurrenceRule.OrdinalWeekDescription,
            config: { predefinedOptions: { options: DropdownOptionEx.enumToDropdownOptionsAddEmptyEntry<Ordinal>(Ordinal, null, 'Not used') } },
            hide: (obj: IRecurrenceRule) => (obj.frequency !== RecurrenceFrequency.Year && obj.frequency !== RecurrenceFrequency.Month) || obj.day != null,
        }),
        day: fieldOptionsFactory.predefinedArray({
            displayName: language.RecurrenceRule.Day,
            description: language.RecurrenceRule.DayDescription,
            config: {
                predefinedOptions: {
                    options: DropdownOptionEx.toDropdownOptionsAddEmptyEntry(
                        Array.from({ length: 31 }, (v, k) => k + 1),
                        _ => _,
                        _ => _ + '',
                        null,
                        null,
                        'Not used',
                    ),
                },
            },
            hide: (obj: IRecurrenceRule) =>
                obj.daysOfWeek?.length > 0 ||
                (obj.frequency !== RecurrenceFrequency.Year && obj.frequency !== RecurrenceFrequency.Month) ||
                obj.dayOfWeek != null,
            validation: {
                validationMark: 'required',
                validationRule: async (item: IRecurrenceRule) => {
                    return validateDay(item) ? undefined : language.RecurrenceRule.DayValidation;
                },
            },
        }),
        daysOfWeek: fieldOptionsFactory.predefinedArray({
            displayName: language.RecurrenceRule.DaysOfWeek,
            description: language.RecurrenceRule.DaysOfWeekDescription,
            config: { multiSelect: true, predefinedOptions: { options: DropdownOptionEx.enumToDropdownOptions<Day>(Day) } },
            hide: (obj: IRecurrenceRule) => obj.frequency !== RecurrenceFrequency.Week
        }),
        dayOfWeek: fieldOptionsFactory.predefinedArray({
            displayName: language.RecurrenceRule.DayOfWeek,
            description: language.RecurrenceRule.DayOfWeekValidation,
            config: { predefinedOptions: { options: DropdownOptionEx.enumToDropdownOptionsAddEmptyEntry<DayOfWeek>(DayOfWeek, null, 'Not used') } },
            hide: (obj: IRecurrenceRule) => obj.frequency === RecurrenceFrequency.Week || obj.day != null,
            validation: {
                validationRule: async item => {
                    if (item.dayOfWeek != null && item.ordinalWeek == null) {
                        return language.RecurrenceRule.DayOfWeekValidation;
                    }
                    return null;
                },
            },
        }),
        activeFrom: fieldOptionsFactory.date({
            displayName: language.RecurrenceRule.ActiveFrom,
            description: language.RecurrenceRule.ActiveFromDescription,
            validation: { 
				validationMark: 'required',
				validationRule: async item => {
					if (!item.activeFrom) {
						return language.RecurrenceRule.ActiveFromValidation;
					}
					return null;
				}
			},
        }),
        activeTo: fieldOptionsFactory.date({ displayName: language.RecurrenceRule.ActiveTo, description: language.RecurrenceRule.ActiveToDescription }),
    },
};

const validateDay = (rule: IRecurrenceRule): boolean => {
    if (rule.frequency === RecurrenceFrequency.Year) {
        if (rule.day == null) {
            return true;
        }
        if (rule.months == null && !rule.ordinalWeek) {
            return false;
        }
    }
    return true;
};
