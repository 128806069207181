import { produce } from '../helpers';
import { TPContext } from '../types';

export type ViewContext = {
    defaultViews: Record<string, string>
    readonly setDefaultView: (area: string, viewId: string) => void;
};

export const viewContext: TPContext<ViewContext> = (set, get, api) => {
    return {
		defaultViews: {},
        setDefaultView: (area, viewId) =>
            set(
                produce(store => {
                    store.defaultViews[area] = viewId
                }),
            ),
    };
};

// export const getPersistGridContext: PersistGetter = store => {
//     return { gridSettingsEnableFastDeletion: false };
// };
