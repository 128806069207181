/* eslint-disable react-refresh/only-export-components */
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { IDropdownOption } from '@fluentui/react';
import { DynamicProps } from './DynamicProps';
import PFDropdown from '../../PFDropdown';
import { ArrayEx } from '../../../../Utilities/ArrayEx';

interface IProps extends DynamicProps<string | Array<string | number>, IDropdownOption | string | number> {
    Multiselect?: boolean;
    PredefinedValues?: Array<any>;
    PredefinedValuesGetter?: (parent: any) => Array<any>;
    PredefinedValuesGetterAsync?: (parent: any) => Promise<Array<any>>;
    Searchable?: boolean;
}

const DynamicDropdown: FunctionComponent<IProps> = props => {
    const [items, setItems] = useState<Array<any>>(
        props.PredefinedValues || (props.PredefinedValuesGetter != null ? props.PredefinedValuesGetter(props.Parent) : null),
    );

    useEffect(() => {
        if (props.PredefinedValuesGetterAsync == null) return;
        const getter = async () => {
            const asyncItems = await props.PredefinedValuesGetterAsync(props.Parent);
            setItems(asyncItems);
        };
        getter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.PredefinedValuesGetterAsync]);

    return (
        <PFDropdown
            Key={props.Key}
            Readonly={props.ReadOnly}
            Multiselect={props.Multiselect}
            Options={items}
            Searchable={props.Searchable}
            SelectedKey={props.Multiselect ? undefined : (props.Value as string)}
            SelectedKeys={props.Multiselect ? (props.Value as Array<any>) || [] : undefined}
            OnChange={(event: React.FormEvent<HTMLDivElement>, allitems: Array<any>, option?: IDropdownOption, index?: number) =>
                props.OnChange(props.PropertyInfo.PropertyName, props.Multiselect ? option : option.key)
            }
        />
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return nextProps.Multiselect ? ArrayEx.simpleCompare(prevProps.Value as Array<any>, nextProps.Value as Array<any>) : prevProps.Value === nextProps.Value;
};

export default memo(DynamicDropdown, comparisonFn);
