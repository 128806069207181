import { Link } from '@fluentui/react';
import { useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { useOverviewSearchContext } from '../../../_components/OverviewCreator/context/OverviewCreatorSearchContext';

// eslint-disable-next-line react-refresh/only-export-components
export const highlightWrapper = (getter: (item?: any) => string) => {
    return (item?: any) => <HighlightComponent text={getter(item)} />;
};

const highlightStyle = { background: 'yellow', padding: 0 };

export const HighlightComponent = ({ text: textProps }: { text?: string }) => {
    const { searchText } = useOverviewSearchContext();
    const text = useMemo(() => {
		if(textProps && (textProps as any)?.type === 'a'){
			return (textProps as any).props.href;
		}
        if (typeof textProps !== 'string') {
            return '';
        }
        return textProps;
    }, [textProps]);

    if (textProps && (textProps as any)?.type === 'a') {
        return (
            <Link target={"_blank"} href={(textProps as any)?.props.href}>
                <Highlighter searchWords={[searchText]} textToHighlight={text} highlightStyle={highlightStyle} autoEscape />
            </Link>
        );
    }

    return <Highlighter searchWords={[searchText]} textToHighlight={text} highlightStyle={highlightStyle} autoEscape />;
};
