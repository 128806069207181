import { Stack } from '@fluentui/react';
import { useCallback, useMemo, useState, memo, useRef } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router';
import shallow from 'zustand/shallow';
import { RecoilRoot } from 'recoil';
import { ApiCalls } from '../../../api/api';
import { EntityType, GridData, GridRow, GridViewFilters } from '../../../api/generated/data-contracts';
import { useUISettings } from '../../../context/network/http/QueryProvider/queries/UISettings';
import { useStore } from '../../../context/store';
import { useUnitType } from '../../../Utilities/UnitTypeUtil';
import { Toolbar } from './components/Toolbar/Toolbar';
import { ProjectPlannerGrid } from './components/Grid/ProjectPlannerGrid';
import { AddRowData } from './components/Grid/AddRowData';
import { AddRowInfo, OnUpdateCellInfo, RowUpdateInfo } from '../_grid/useGrid';
import { GridSkeleton } from '../../../_components/Skeleton/GridSkeleton';
import { FilterPanel } from './components/FilterPanel/FilterPanel';
import { useControlledState } from '../../../hooks/useControlledState';
import { filterMapToArray, findMap } from '../_grid/helpers/map';
import { useProjectPlannerData } from './hooks/useProjectPlannerData';
import { CustomColumnFilter } from './components/Grid/components/CustomColumnFilter/CustomColumnFilter';
import { makeContractColumnRows, makeColumnRows } from './components/makeContractColumnRows';
import { stringVariantsCamelCase } from '../../../helpers/stringVariants';
import { TPError2 } from '../../../Entities/TPError';
import { fallbackView, fallbackViewId } from './fallbackGridView';

export const ProjectPlanner = () => {
    return (
        <RecoilRoot>
            <ProjectPlannerInner />
        </RecoilRoot>
    );
};

export const ProjectPlannerInner = memo(function ProjectPlannerInner() {
    const { pathname } = useLocation();
    const { currentUnitType } = useUnitType();
    const { data: uiSettings } = useUISettings();
    const views = useMemo(() => uiSettings.settings.gridViews.projectPlannerViews || [], [uiSettings.settings.gridViews.projectPlannerViews]);
    const { timelineResolution, defaultViewId, addWarningNotification } = useStore(store => {
        const defaultViewId = store.defaultViews[pathname] || views[0]?.id || fallbackViewId;
        return {
            timelineResolution: store.timeline.resolution,
            defaultViewId,
            addWarningNotification: store.addWarningNotification,
        };
    }, shallow);

    const [currentViewId, setCurrentViewId] = useState(defaultViewId || '');
    const currentView = useMemo(() => views.find(view => view.id === currentViewId) || views[0] || fallbackView, [currentViewId, views]);

    const [currentViewFilters, setCurrentViewFilters] = useControlledState<GridViewFilters | null>(() => currentView?.filters || null, [currentView]);
    const [viewHasFilterChanges, setViewHasFilterChanges] = useState(false);
    const gridRef = useRef<{ getUpdatedData: () => Promise<GridData> } | null>(null);

    const gridData = useProjectPlannerData({ currentUnitType, currentViewFilters, gridRef });

    const { mutateAsync } = useMutation(async (updates: OnUpdateCellInfo[]) => {
        return ApiCalls.addActivities(
            updates.map(({ newValue, relations, row, columnIndex }) => {
                const [contract, project] = relations;
                const contractId = contract.identity.id;
                const resourceId = row.identity.id;
                const projectId = project.identity.id;
                const start = row.columnRows[columnIndex].start;
                const end = row.columnRows[columnIndex].end;
                return {
                    projectId,
                    contractId,
                    contractName: contract.name,
                    resourceId,
                    work: newValue.toNumber(),
                    type: 2,
                    resolution: timelineResolution,
                    start,
                    end,
                };
            }),
        ).then(res => res.data);
    });

    const onUpdateCell = useCallback(
        async (updates: OnUpdateCellInfo[]) => {
            await mutateAsync(updates);
        },
        [mutateAsync],
    );

    const onClickSaveFilter = useCallback(
        (filters: GridViewFilters | null) => {
            setCurrentViewFilters(filters);
            setViewHasFilterChanges(true);
            setOpenPanel(false);
        },
        [setCurrentViewFilters],
    );
    const [openPanel, setOpenPanel] = useState(false);

    const { mutateAsync: enrichNewRow } = useMutation({
        mutationFn: async ({ row, updateRows }: { row: GridRow; updateRows: (row: GridRow) => any }) =>
            ApiCalls.enrichProjectPlannerGridRow(row)
                .then(res => res.data)
                .then(updateRows),
        onError: (error: any) => {
            console.error('ProjectPlannerInner ~ enrichNewRow:', error);
            if (error instanceof TPError2) {
                // Network error
                addWarningNotification(error.Message);
            } else if (error instanceof Error) {
                addWarningNotification(error.message);
            }
        },
    });

    const onAddRow = useCallback(
        async ({ parentRow, rows, abort, updateRows }: AddRowInfo, { resource, timelineInfo }: AddRowData) => {
            const existingRow = findMap(rows, row => {
				// check for worktype 2 (allocation) as well, to allow allocation on named requests
                return row.identity.id === resource.id && row.parentRowId === parentRow.id && row.metadata['WorkType'] === 2;
            });

            if (existingRow) {
                addWarningNotification(`${resource.name} is already added to the ${existingRow.name} contract`);
                return abort();
            }
            const getResourceRow = (parentRowId: string) => {
                const newRow: GridRow = {
                    id: crypto.randomUUID(),
                    parentRowId,
                    name: resource.name,
                    identity: { id: resource.id, entityType: EntityType.Resource },
                    columnRows: makeColumnRows(timelineInfo, uiSettings.settings.useISO8601),
                    metadata: {
                        Active: true,
                        ResourceType: 1,
                        WorkType: 2,
                    },
                };
                enrichNewRow({
                    row: newRow,
                    // We pass on update rows to let useMutation handle possible errors
                    updateRows,
                });
                // .then(updateRows);
                return newRow;
            };
            if (parentRow.identity.entityType === EntityType.Project) {
                const contractRowId = crypto.randomUUID();
                // TODO: support dynamic contract names with: const field = uiSettings.settings.enableDynamicContractNames ? language.CapacityViews.NameLabel : uiSettings.settings.contractDefaultName;
                const field = uiSettings.settings.contractDefaultName;
                return [
                    // Contract row
                    {
                        id: contractRowId,
                        parentRowId: parentRow.id,
                        name: stringVariantsCamelCase.getAsStringValue(resource, field) || resource.name,
                        identity: { entityType: EntityType.Contract, id: contractRowId },
                        columnRows: makeContractColumnRows(timelineInfo, uiSettings.settings.useISO8601),
                        metadata: null,
                    },
                    // Resource row
                    getResourceRow(contractRowId),
                ] as GridRow[];
            }
            return getResourceRow(parentRow.id);
        },
        [addWarningNotification, enrichNewRow, uiSettings.settings.contractDefaultName, uiSettings.settings.useISO8601],
    );

    return (
        <>
            <Stack styles={{ root: { width: '100%', height: '100%' } }}>
                <Toolbar
                    currentView={currentView}
                    setCurrentViewId={setCurrentViewId}
                    setOpenPanel={setOpenPanel}
                    setViewHasFilterChanges={setViewHasFilterChanges}
                    viewHasFilterChanges={viewHasFilterChanges}
                    views={views}
                    currentViewFilters={currentViewFilters}
                    rightItems={<CustomColumnFilter />}
                />
                {gridData ? (
                    <ProjectPlannerGrid data={gridData} ref={gridRef} onUpdateCells={onUpdateCell} onUpdateCellTree={onUpdateCellTree} onAddRow={onAddRow} />
                ) : (
                    <GridSkeleton columnWidth={296} squareRowHeader />
                )}
            </Stack>
            {openPanel && (
                <FilterPanel
                    onClickSave={onClickSaveFilter}
                    openPanel={openPanel}
                    setOpenPanel={setOpenPanel}
                    onDismissPanel={() => {
                        setOpenPanel(false);
                    }}
                    initialFilterValue={currentViewFilters}
                />
            )}
        </>
    );
});

const onUpdateCellTree = ({ columnIndex, diff, row, totalRows, setCellValue, rows, getRowRelationsToRoot }: RowUpdateInfo) => {
    const rightCell = row.columnRows[columnIndex].cells[2];
    if (rightCell) {
        setCellValue(rightCell, value => value.minus(diff));
    }
    totalRows.forEach(totalRow => {
        const totalCell =
            totalRow.identity.entityType === EntityType.Project
                ? totalRow.columnRows?.[columnIndex].cells[0] // project row has only 1 cell
                : totalRow.columnRows?.[columnIndex].cells[2];
        if (totalCell) {
            setCellValue(totalCell, value => value.plus(diff));
        }
    });
    const otherRows = filterMapToArray(rows, otherRow => {
        return otherRow.id !== row.id && otherRow.identity.id === row.identity.id;
    });
    otherRows.forEach(row => {
        const rightCell = row.columnRows[columnIndex].cells[2];
        if (rightCell) {
            setCellValue(rightCell, value => value.minus(diff));
        }
        const otherTotalRows = getRowRelationsToRoot(row.id)
            //
            .filter(otherRow => !totalRows.some(totalRow => totalRow.id === otherRow.id));
        otherTotalRows.forEach(totalRow => {
            if (totalRow.identity.entityType !== EntityType.Project) {
                const totalCell = totalRow.columnRows?.[columnIndex].cells[2];
                if (totalCell) {
                    setCellValue(totalCell, value => value.plus(diff));
                }
            }
        });
    });
};
