import { Callout, ColorPicker, DirectionalHint, IColor, Label } from '@fluentui/react';
import { IFormItem, IPropertyRenderProps, IDynamicPropertyComponentConfig } from '@wiberg/formbuilder';
import { PropsWithChildren, ReactElement, useState, useRef } from 'react';

interface IProps<T extends IFormItem> extends IPropertyRenderProps<T, IDynamicPropertyComponentConfig<T>, string | undefined> {
    config?: IDynamicPropertyComponentConfig<T>;
}

export const ColorField = <T extends IFormItem>(props: PropsWithChildren<IProps<T>>): ReactElement | null => {
    const [showDialog, setShowDialog] = useState(false);
    const [value, setValue] = useState<string>(props.value);

    const elementId = useRef(props.key + ((props as any).propertyName ?? 'croneditor')); // frombuilder: add propertyname to form

    const change = (value: string) => {
        setValue(value);
        props.onChange(value);
    };

    return (
        <>
            <div style={{ display: 'inline-flex' }}>
                <div
                    id={elementId.current}
                    style={{
                        backgroundColor: value,
                        cursor: 'pointer',
                        width: '20px',
                        height: '20px',
                        marginRight: '10px',
                        marginTop: '5px',
                        border: 'solid 1px black',
                    }}
                    onClick={() => setShowDialog(true)}
                ></div>
                <Label>{props.options.displayName}</Label>
            </div>
            {elementId.current && (
                <Callout
                    target={`#${elementId.current}`}
                    hidden={!showDialog}
                    directionalHint={DirectionalHint.leftCenter}
                    onDismiss={event => setShowDialog(false)}
                    setInitialFocus
                    gapSpace={10}
                >
                    <ColorPicker key={props.key} color={value} onChange={(event, color: IColor) => change(color.str)} />
                </Callout>
            )}
        </>
    );
};
