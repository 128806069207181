import { DateEx } from "../Utilities/DateEx";
import { DateResolution, FreezePeriod } from "../api/generated/data-contracts";

export function getFirstDayOfPeriod(resolution: DateResolution, useISO8601: boolean, date?: Date): Date {
	const today = date || DateEx.todaysDate();
	switch (resolution) {
		case DateResolution.Year: {
			return DateEx.getFirstDayOfYear(today);
		}
		case DateResolution.Quarter: {
			return DateEx.getFirstDayOfQuarter(today);
		}
		case DateResolution.Month: {
			return DateEx.getFirstDayOfMonth(today);
		}
		case DateResolution.Week: {
			return DateEx.getFirstDayOfWeek(today, useISO8601);
		}
		case DateResolution.Day: {
			return today;
		}
		default: {
			throw new Error("Unknown resolution!");
		}
	}
}

export function addPeriod(resolution: DateResolution, periods: number, useISO8601: boolean, date?: Date): Date {
	const today = date || DateEx.todaysDate();
	switch (resolution) {
		case DateResolution.Year: {
			const firstDayOfYear = DateEx.getFirstDayOfYear(today);
			return DateEx.addYear(firstDayOfYear, periods);
		}
		case DateResolution.Quarter: {
			const firstDayOfQuarter = DateEx.getFirstDayOfQuarter(today);
			return DateEx.addQuarter(firstDayOfQuarter, periods);
		}
		case DateResolution.Month: {
			const firstDayOfMonth = DateEx.getFirstDayOfMonth(today);
			return DateEx.addMonth(firstDayOfMonth, periods);
		}
		case DateResolution.Week: {
			const firstDayOfWeek = DateEx.getFirstDayOfWeek(today, useISO8601);
			return DateEx.addWeek(firstDayOfWeek, periods);
		}
		case DateResolution.Day: {
			return DateEx.addDay(today, periods);
		}
		default: {
			throw new Error("Unknown resolution!");
		}
	}
}

export function getStartUsingFreezePeriod(freezePeriod: FreezePeriod, inputResolution: DateResolution, useISO8601: boolean): Date {
	const today = DateEx.todaysDate();
	if (freezePeriod == null || freezePeriod.freezeBeforeTodayOnly)
		return getFirstDayOfPeriod(inputResolution, useISO8601, today);

	let newStart: Date;
	let first: Date;
	switch (freezePeriod.resolution) {
		case DateResolution.Year: {
			first = DateEx.getFirstDayOfYear(today);
			newStart = DateEx.addYear(first, freezePeriod.number);
			break;
		}
		case DateResolution.Quarter: {
			first = DateEx.getFirstDayOfQuarter(today);
			newStart = DateEx.addQuarter(first, freezePeriod.number);
			break;
		}
		case DateResolution.Month: {
			first = DateEx.getFirstDayOfMonth(today);
			newStart = DateEx.addMonth(first, freezePeriod.number);
			break;
		}
		case DateResolution.Week: {
			first = DateEx.getFirstDayOfWeek(today);
			newStart = DateEx.addWeek(first, freezePeriod.number);
			break;
		}
		case DateResolution.Day: {
			newStart = DateEx.addDay(today, freezePeriod.number);
			break;
		}
		default: {
			throw new Error("Unknown resolution!");
		}
	}

	const inputFirstDate = getFirstDayOfPeriod(inputResolution, useISO8601, newStart);
	if (inputFirstDate >= newStart)
		return inputFirstDate;
	else
		return addPeriod(inputResolution, 1, useISO8601, inputFirstDate);
}

export const resolutionHelper = {
	getFirstDayOfPeriod,
	addPeriod,
	getStartUsingFreezePeriod
}