import { useQuery } from "react-query";
import { useUISettings } from "../../../context/network/http/QueryProvider/queries/UISettings";
import { useSearchedFilteredData } from "../_components/panels/project/hooks/useSearchedFilteredData";
import { SearchBox, Stack } from "@fluentui/react";
import { PFSpinner } from "../../../Components/Common/Spinner";
import { RESOURCE_CAPACITY_PROJECTS } from "../../../context/network/http/QueryProvider/queryKeys";
import { ApiCalls } from "../../../api/api";
import { ListItem } from "./ListItem";


export const ResourceCapacityProjectDialog = (props: { selectCallback: (projectId: string, projectName: string) => void }) => {
	
	const { data: uiSettings } = useUISettings();
	
    const { data, isFetching } = useQuery([RESOURCE_CAPACITY_PROJECTS], async () => {
		const response = await ApiCalls.selfAllocatableProjects();
		return response.data;
	});
	
	const [searchFilteredData, searchOnChange, searchText] = useSearchedFilteredData(data, uiSettings.settings);

    return (
        <div>
			{isFetching ? (
                <PFSpinner />
            ) :
				<>
					<SearchBox onChange={searchOnChange} autoFocus />
					
					<div style={{height: '60vh', overflowY: 'auto'}}>
						<Stack>
							{searchFilteredData.map(item => {
								return (
									<ListItem
										key={item.id}
										item={item}
										onClick={() => props.selectCallback(item.id, item.name)}
										searchText={searchText}
										settings={uiSettings.settings}
									/>
								);
							})}
						</Stack>
					</div>
				</>
            }
        </div>
    );
};
