import { GridTotalColumnRow } from '../../../../../../api/generated/data-contracts';
import { useGridRowContext } from '../../../../_grid/contexts/rowContext';
import { SubTotalCell } from "./SubTotalCell";


export type TotalColumnProps = { totalColumnRow: GridTotalColumnRow, children?: React.ReactNode, expanded: boolean, isSubTotal: boolean; isAdminProject?: boolean; }

export const TotalColumnRow = ({ totalColumnRow, children, expanded, isSubTotal, isAdminProject }: TotalColumnProps) => {
	// console.log("🚀 ~ TotalColumnRow ~ isAdminProject", isAdminProject)
	const { toggleExpanded, canExpand } = useGridRowContext();

	return (
		<td className="tp-table-header-total" onClick={() => toggleExpanded()}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100%',
					width: '100%',
					flexDirection: 'column',
					// padding: '10px 0',
					// padding: '0',
					overflow: 'hidden',
					// height: "100%",
					padding: '.5px',
				}}
			>
				<div
					style={{
						//
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: 0,
						// gap: 10,
						// ...(isSubTotal && { gap: 10, padding: 10 }),
						...(isSubTotal && { gap: 10 }),
						width: '100%',
						height: '100%',
						...(canExpand && { cursor: 'pointer' }),
					}}
				>
					{totalColumnRow.cells.map((cell, i) => {
						// totalColumnRow.metadata
						if (isAdminProject && i === 0) {
							// return null;
							return (
								<div
									key={cell.id}
									style={{
										// width: '100%',
										width: 80,
										// height: '100%',
										display: 'flex',
										justifyContent: 'end',
										// paddingRight: 12,
										paddingRight: 8,
									}} />
							);
						}
						return (
							<SubTotalCell
								key={cell.id}
								// componentName="TotalCell"
								cell={cell}
								isRowExpanded={expanded}
								totalColumnRow={totalColumnRow} />
						);
					})}
				</div>
				{children}
			</div>
		</td>
	);
};
