import { Checkbox, TextField } from '@fluentui/react';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ITreeNode } from '../interfaces/ITreeNode';
import { TreeContext } from './TreeView';

export interface IProps {
    node: ITreeNode;
    parent: ITreeNode;
    collapsed?: boolean;
    readOnly: boolean;
    onSelectCallback?: (node: ITreeNode, checked: boolean) => void;
}

export const TreeViewSelectNode: FunctionComponent<IProps> = ({ node, readOnly, onSelectCallback }) => {
    const [selected, setSelected] = useState(node.selected);

    const ctx = useContext(TreeContext);

    useEffect(() => {
        setSelected(node.selected);
    }, [node.selected]);

    const isRoot = ctx.rootNodeId === node.id;
    if (isRoot && ctx.hideRoot) return null;

    return (
        <>
            <div>
                <Checkbox
                    disabled={readOnly || node.disabled}
                    checked={selected}
                    onChange={(ev, checked) => onSelectCallback?.(node, checked)}
                    styles={{ root: { paddingTop: '6px' } }}
                />
            </div>
            <TextField
                title={node.fullName}
                key={`node-${node.id}-input`}
                value={node.name}
                disabled={readOnly}
                onChange={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }}
            />
        </>
    );
};
