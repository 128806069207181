import { IconButton, TextField } from '@fluentui/react';
import { FunctionComponent, useContext } from 'react';
import { language } from '../../../../../../Services/LocalizationService';
import { EventEx } from '../../../../../../Utilities/EventEx';
import { JsonEx } from '../../../../../../Utilities/JsonEx';
import { ITreeNode } from '../interfaces/ITreeNode';
import { TreeContext } from './TreeView';

export interface IProps {
    node: ITreeNode;
    parent: ITreeNode;
    setCollapsed?: (collapsed: boolean) => void;
    delimiter: string;
    readOnly: boolean;
    onDeleteCallback?: (node: ITreeNode, parent: ITreeNode) => void;
    onAddCallback?: (node: ITreeNode) => void;
    onMoveCallback?: (source: string, sourceContainer: string, target: string) => boolean;
    onRenameCallback?: (node: ITreeNode, newName: string) => void;
}

interface IDragInfo {
    source: string;
    sourceContainer: string;
    target: string;
}

export const TreeViewManageNode: FunctionComponent<IProps> = ({
    node,
    parent,
    readOnly,
    delimiter,
    setCollapsed,
    onRenameCallback,
    onDeleteCallback,
    onAddCallback,
    onMoveCallback,
}) => {
    const ctx = useContext(TreeContext);

    const isRoot = ctx.rootNodeId === node.id;
    if (isRoot && ctx.hideRoot)
        return (
            <IconButton
                iconProps={{ iconName: 'Add' }}
                title={language.Common.Add}
                onClick={e => {
                    setCollapsed(false);
                    onAddCallback(node);
                }}
            />
        );

    return (
        <>
            <TextField
                title={node.fullName}
                draggable={false}
                key={`node-${node.id}-input`}
                defaultValue={node.name}
                readOnly={readOnly}
                onChange={(ev, val) => {
                    // name cannot contain delimiter
                    if (val.indexOf(delimiter) >= 0) {
                        ev.preventDefault();
                        ev.stopPropagation();
                        return;
                    }
                    onRenameCallback(node, val);
                }}
                data-nodeid={node.id}
                onDragEnter={e => {
                    EventEx.preventAndStop(e);
                    (e.target as any).classList.toggle('tp-treeview-dragover');
                }}
                onDragLeave={e => {
                    EventEx.preventAndStop(e);
                    (e.target as any).classList.toggle('tp-treeview-dragover');
                }}
                onDrop={e => {
                    e.preventDefault();
                    (e.target as any).classList.toggle('tp-treeview-dragover');
                    const info = JsonEx.parse<IDragInfo>(e.dataTransfer.getData('info'));
                    const success = onMoveCallback(info.source, info.sourceContainer, (e.currentTarget as Element).attributes['data-nodeid'].value);
                    if (success) setCollapsed(false);
                }}
                onDragOver={e => e.preventDefault()}
            />
            <IconButton
                iconProps={{ iconName: 'Add' }}
                title={language.Common.Add}
                onClick={e => {
                    setCollapsed(false);
                    onAddCallback(node);
                }}
            />
            <IconButton
                iconProps={{ iconName: 'Move' }}
                title="Move"
                draggable
                onDragStart={e => {
                    // Create the ghost element
                    const ghostElement = (e.target as any).parentElement.parentElement.cloneNode(true);
                    const ghostWrapper = document.createElement('div');
                    ghostWrapper.className = 'tp-treeview-ghostwrapper';
                    ghostWrapper.appendChild(ghostElement);
                    // Append it to `body`
                    document.body.appendChild(ghostWrapper);
                    // set data
                    e.dataTransfer.setData('info', JsonEx.stringify({ source: node.id, sourceContainer: parent.id } as IDragInfo));
                    // Customize the drag image
                    e.dataTransfer.setDragImage(ghostWrapper, 0, 15);
                }}
                onDragEnd={e => e.preventDefault()}
            />
            <IconButton
                className="tp-treeview-icon"
                iconProps={{ iconName: 'Delete' }}
                title={language.Common.Delete}
                onClick={() => onDeleteCallback(node, parent)}
            />
        </>
    );
};
