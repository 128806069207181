import React from 'react';
 
interface IProps {
	Title: string;
	spanOnlyOne: boolean;
	Offset: number;
	// MouseDown: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => void;
}

export const TimeLineHeaderCell: React.FunctionComponent<IProps> = props => {
	return 	(
		<div className={`tp-timeline-modern-header-cell${props.spanOnlyOne ? ' center-text' : ''}`} 
			style={{ paddingLeft: `${props.spanOnlyOne ? 0 : props.Offset || 30}px`}}
		>
			{props.Title}
		</div>
	)
}
