import { useMemo } from 'react';
import { ApiCalls } from '../../../../api/api';
import { DeleteDto, EntityType, Project } from '../../../../api/generated/data-contracts';
import { useStore } from '../../../../context/store';
import { tpEntityAddDefaults } from '../../../../forms/common/tpEntityFactory';
import { language } from '../../../../Services/LocalizationService';
import { deepCopy } from '../../../../Utilities/ObjectEx';
import { showInactivateConfirm } from '../helpers/projectActivation';
import { useConfirmDeleteOverviewDialog } from '../../_hooks/useConfirmDeleteOverviewDialog';
import { MenuItem } from '../../../../_components/OverviewCreator/components/OverviewCommandBar/OverviewCommandBar';
import { useCheckTPPermissions } from '../../../../hooks/usePermissions';
import { useUISettings } from '../../../../context/network/http/QueryProvider/queries/UISettings';
import { queryGroupHelper } from '../../../../Utilities/queryGroupHelper';
import { exportOverviewToExcel } from '../../../../Utilities/ExcelUtil';

export const useProjectOverviewMenuItems = ({
    //
    setIsNewlyCreated,
    setShowBulkEdit,
    setProjects,
    setOnlyActive,
    onlyActive,
    refetch,
	setOpenFilterPanel
}: {
    setIsNewlyCreated: (isNew: boolean) => void;
    setShowBulkEdit: (show: boolean) => void;
    setProjects: (updater: (items: Project[]) => Project[]) => void;
    setOnlyActive: React.Dispatch<React.SetStateAction<boolean>>;
    onlyActive: boolean;
    refetch: () => void;
	setOpenFilterPanel: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { data: uiSetting } = useUISettings();
    const confirmDelete = useConfirmDeleteOverviewDialog();
    const addErrorNotification = useStore(store => store.addErrorNotification);
    const checkPermissions = useCheckTPPermissions({ some: true });

    return useMemo((): MenuItem<Project>[] => {
        const isAllowedToCreateProjects = checkPermissions({ userTypes: uiSetting.settings.allowedToCreateProjects });
        const isAllowedToDeleteProjects = checkPermissions({ userTypes: uiSetting.settings.allowedToDeleteProjects });
        const isAllowedToInactivateProjects = checkPermissions({ userTypes: uiSetting.settings.allowedToInactivateProjects });
        const isAllowedToEditProjects = checkPermissions({ userTypes: uiSetting.settings.allowedToEditProjects });
        const items: MenuItem<Project>[] = [
            {
                key: 'new',
                text: 'New', //TODO LOCALIZATION
                iconProps: { iconName: 'Add' },
                onClick: (selectedItems, setPanelItem) => {
                    const emptyProject = {} as Project;
                    // This is a weird and uncomfortable api (scary mutations 😱)
                    tpEntityAddDefaults.project(emptyProject);
                    setPanelItem(emptyProject);
                    setIsNewlyCreated(true);
                },
                shouldDisable: () => !isAllowedToCreateProjects,
            },
            {
                key: 'edit',
                text: 'Edit', //TODO LOCALIZATION
                iconProps: { iconName: 'Edit' },
                onClick: (selectedItems, setPanelItem) => {
                    setPanelItem(selectedItems[0]);
                },
                shouldDisable: selectedItems => {
                    return !isAllowedToEditProjects || selectedItems.length > 1 || selectedItems.length === 0;
                },
            },
            {
                key: 'delete',
                text: 'Delete',//TODO LOCALIZATION
                iconProps: { iconName: 'Delete' },
                onClick: selectedProjects => {
                    confirmDelete(
                        async relatedData => {
                            const dto = { entity: EntityType.Project, items: selectedProjects } as DeleteDto;
                            try {
                                if (relatedData) {
                                    await ApiCalls.cascadeDeleteEntity(dto);
                                } else {
                                    await ApiCalls.deleteEntity(dto);
                                }
                                setProjects(projects => projects.filter(project => !selectedProjects.some(deletedProject => deletedProject.id === project.id)));
                                refetch();
                            } catch (error: any) {
                                console.error(error);
                            }
                        },
                        () => {},
                        selectedProjects.length > 1,
                        language.ProjectOverview,
                    );
                },
                shouldDisable: selectedItems => {
                    if (selectedItems.length === 0) {
                        return true;
                    }
                    // if (uiSetting.settings.allowedToDeleteProjects.length) {
                    //     return !isAllowedToDeleteProjects;
                    // }
                    return !isAllowedToDeleteProjects;
                },
            },
            {
                key: 'copy',
                text: 'Copy',//TODO LOCALIZATION
                iconProps: { iconName: 'Copy' },
                onClick: (selectedProjects, setPanelItem) => {
                    const project = deepCopy(selectedProjects[0]);
                    project.name = `Copy_${project.name}`;

                    // This is a weird and uncomfortable api (scary mutations 😱)
                    setPanelItem(Object.assign(project, tpEntityAddDefaults.project(project), { active: true, created: undefined, createdBy: undefined }));
                },
                shouldDisable: selectedProjects => {
                    return selectedProjects.length !== 1 || !isAllowedToCreateProjects;
                },
            },
            {
                key: 'bulk edit',
                text: 'Bulk edit',//TODO LOCALIZATION
                iconProps: { iconName: 'SetAction' },

                onClick: selectedProjects => {
                    const projectType = selectedProjects[0].projectType;
                    if (selectedProjects.some(_ => _.projectType !== projectType)) {
                        addErrorNotification('All selected projects must have the same type'); //TODO LOCALIZATION
                        return;
                    }
                    setShowBulkEdit(true);
                },
                shouldDisable: selectedProjects => {
                    const rootType = selectedProjects[0]?.projectType;
                    return (
                        !isAllowedToEditProjects ||
                        !selectedProjects ||
                        selectedProjects.length < 2 ||
                        selectedProjects.some(selectedProject => selectedProject?.projectType !== rootType)
                    );
                },
            },
            {
                key: 'addition actions',
                text: 'Additional actions', //TODO LOCALIZATION
                iconProps: { iconName: 'SetAction' },
                subMenuProps: {
                    items: [
                        {
                            key: 'Set active',
                            text: 'Set active', //TODO LOCALIZATION
                            subMenuProps: {
                                items: [
                                    {
                                        key: 'Activate',
                                        text: 'Activate',//TODO LOCALIZATION
                                        onClick: selectedProjects => {
                                            showInactivateConfirm(
                                                selectedProjects,
                                                true,
                                                () => {
                                                    const updatedProjects = selectedProjects.map(res => ({ ...res, active: true }));
                                                    setProjects(projects => {
                                                        return projects.map(
                                                            project => updatedProjects.find(updatedProject => updatedProject.id === project.id) || project,
                                                        );
                                                    });
													refetch();
                                                },
                                                loading => {},
                                            );
                                        },
                                    },
                                    {
                                        key: 'deactivate',
                                        text: 'Inactivate',//TODO LOCALIZATION
                                        onClick: selectedProjects => {
                                            showInactivateConfirm(
                                                selectedProjects,
                                                false,
                                                () => {
                                                    const updatedProjects = selectedProjects.map(proj => ({ ...proj, active: false }));
                                                    setProjects(projects => {
                                                        return projects.map(
                                                            project => updatedProjects.find(updatedProject => updatedProject.id === project.id) || project,
                                                        );
                                                    });
													refetch();
                                                },
                                                loading => {},
                                            );
                                        },
                                    },
                                ],
                            },
                            shouldDisable: selectedProjects => {
                                return !selectedProjects.length
										|| !isAllowedToInactivateProjects 
										|| !queryGroupHelper.isNullOrEmpty(uiSetting.settings.autoInactivateProjects);
                            },
                        },
                    ],
                },
            },
            {
                key: 'show active',
                text: onlyActive ? language.OverviewActiveToggler.ShowOnlyActive : language.OverviewActiveToggler.ShowActiveAndInactive,
                iconProps: {  iconName: onlyActive ? 'ReceiptCheck' : 'ReceiptUndelivered' },
                onClick: () => {
                    setOnlyActive(onlyActive => !onlyActive);
                },
            },
        ];
		if (uiSetting.settings.enableProjectOverviewsFilter) {
			items.push({
                key: 'Filter',
                text: 'Filter', //TODO LOCALIZATION
                iconProps: { iconName: 'Filter' },
                onClick: () => {
                    setOpenFilterPanel(true);
                },
            });
		}
		if (uiSetting.settings.overviewExportToExcelEnabled) {
			items.push({
				key: 'exportxlsx',
				text: 'Export to Excel',
				iconProps: { iconName: 'ExcelLogo16' },
				onClick: (selectedItems, spc, filteredItems, columns) => {
					exportOverviewToExcel('Project', filteredItems, columns);
				},
			});
		}
		
		return items;
    }, [addErrorNotification, checkPermissions, confirmDelete, onlyActive, refetch, setIsNewlyCreated, setOnlyActive, setOpenFilterPanel, setProjects, setShowBulkEdit, uiSetting.settings.allowedToCreateProjects, uiSetting.settings.allowedToDeleteProjects, uiSetting.settings.allowedToEditProjects, uiSetting.settings.allowedToInactivateProjects, uiSetting.settings.autoInactivateProjects, uiSetting.settings.enableProjectOverviewsFilter, uiSetting.settings.overviewExportToExcelEnabled]);
};
