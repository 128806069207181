import { FunctionComponent, useState, useEffect } from 'react';
import { Dropdown } from '@fluentui/react';
import { DropdownOptionEx } from '../../../Utilities/DropdownOptionEx';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { EntityType } from '../../../Entities/EntityTypes';
import { DataService } from '../../../Services/DataService';
import { DynamicQuery } from '../../../Entities/DynamicQueryBuilder/DynamicQuery';
import { Settings } from '../../../Entities/Main/Settings';
import { Plugin } from '../../../Services/PluginInvoker';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import { guid } from '../../../helpers/guid';

interface IProps extends IDynamicEditorComponentProps {
    Item: string;
    Update: (profile: string) => void;
    Key?: string;
}

const SettingsSelector: FunctionComponent<IProps> = props => {
    const [selectedSettings, setSelectedSettings] = useState<string>(props.Item);
    const [settings, setSettings] = useState<Array<Settings>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [autoSettings, setAutoSettings] = useState<Settings>();

    useEffect(() => {
        const fetchProfiles = async () => {
            // load all root and sub settings
            const query = new DynamicQuery('SettingsType < @0 AND ActiveMatchFilter == @1', [2, false]);
            const allSettings = await DataService.Query<Settings>(EntityType.Settings, query);
            // load auto setting
            const currentSetting = await Plugin.Invoke<Settings>(PluginIDs.GetSettings, props.Parent?.id);
            // check if not manually set and not root - then it must be auto
            if (!props.Item && guid.isGuidNotEmpty(currentSetting.id)) {
                const index = allSettings.findIndex(_ => _.id === currentSetting.id);
                if (index < 0) allSettings.push(currentSetting);
                setAutoSettings(currentSetting);
                setSelectedSettings(currentSetting.id);
            }
            setSettings(allSettings);
            setLoading(false);
        };
        fetchProfiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.Item]);

    return (
        <div>
            {!loading && (
                <Dropdown
                    key={props.Key}
                    disabled={props.ReadOnly || loading}
                    options={DropdownOptionEx.toDropdownOptionsAddEmptyEntryDisableSome(
                        settings,
                        _ => _.id,
                        _ => _.Name,
                        autoSettings != null ? [autoSettings.id] : [],
                    )}
                    defaultSelectedKey={selectedSettings}
                    onChange={(e, o) => props.Update(o.key as string)}
                />
            )}
        </div>
    );
};

export default SettingsSelector;
