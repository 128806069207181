import { PropertyType } from "../../../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyBasicInfo } from "../../../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyRender } from "../../../../Utilities/PropertyInfo/Render";
import { IObjectEditorItemType } from "../../../Main/IObjectEditorItemType";

export class LookupRbs implements IObjectEditorItemType {
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Rbs", 1)
	@PFPropertyRender(null, null, "RbsSelector")
	public RbsId: string; // guid

	constructor() {

	}
}