import { Navigate, Outlet } from 'react-router';
import { Checker, useCheckFeatureFlag } from '../../hooks/useCheckFeatureFlag';

export const RequireFeatureFlag: React.FC<{ checker: Checker; redirect?: string; fallback?: React.ReactNode }> = ({
    children,
    checker,
    redirect = '/',
    fallback,
}) => {
    const isPermitted = useCheckFeatureFlag(checker);
    if (!isPermitted) {
        if (fallback !== undefined) {
            return <>{fallback}</>;
        }
        return <Navigate to={redirect} replace />;
    }
    return <>{children !== undefined ? children : <Outlet />}</>;
};
