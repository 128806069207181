/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo, useEffect, useState } from 'react';
import { IColumn } from '@fluentui/react';
import { PFList } from '../../PFList';
import { KeyValue } from '../../../../Entities/KeyValue';
import { DynamicProps } from './DynamicProps';
import { language } from '../../../../Services/LocalizationService';

interface IProps extends DynamicProps<object, object> {
    Fixed?: boolean;
    ValueType?: 'text' | 'date' | 'number' | 'boolean' | 'color' | 'enum';
    ColumRenders?: { [key: string]: (item?: any, index?: number, column?: IColumn) => string };
}

const DynamicDictionary: FunctionComponent<IProps> = props => {
    const [items, setItems] = useState<Array<{ Key: string; Value: any }>>();
    //TODO: items mangler types
    useEffect(() => {
        const itemvalue = props.Value || {};
        const items = Object.keys(itemvalue).map(key => {
            return { Key: key, Value: itemvalue[key] };
        });
        setItems(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div key={props.Key}>
            <div>
                <PFList
                    DialogTitle={props.PropertyInfo.DisplayName}
                    ReadOnly={props.ReadOnly}
                    Columns={[
                        {
                            key: 'Key',
                            name: language.Common.Key,
                            fieldName: 'Key',
                            minWidth: 100,
                            valueType: 'text',
                            onRender:
                                props.ColumRenders && props.ColumRenders['Key']
                                    ? (item, idx, col) => <div dangerouslySetInnerHTML={{ __html: props.ColumRenders['Key'](item, idx, col) }} />
                                    : null,
                        },
                        {
                            key: 'Value',
                            name: language.Common.Value,
                            fieldName: 'Value',
                            minWidth: 200,
                            valueType: props.ValueType || 'text',
                            onRender:
                                props.ColumRenders && props.ColumRenders['Value']
                                    ? (item, idx, col) => <div dangerouslySetInnerHTML={{ __html: props.ColumRenders['Value'](item, idx, col) }} />
                                    : null,
                        },
                    ]}
                    ItemIdentifier={(item: KeyValue) => item.Key}
                    Items={items}
                    ItemType={KeyValue}
                    ChangesCallback={(items: Array<KeyValue>) => {
                        const value = {};
                        items.forEach(i => (value[i.Key] = i.Value));
                        props.OnChange(props.PropertyInfo.PropertyName, value);
                    }}
                    FixedItems={props.Fixed}
                    DisabledColumns={props.Fixed ? ['Key'] : []}
                />
            </div>
        </div>
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Value === nextProps.Value;
};

export default memo(DynamicDictionary, comparisonFn);
