import { FunctionComponent } from 'react';

interface IProps {
    CellTitle: string;
    Selected?: boolean;
    Index: number;
    MouseDown: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => void;
    MouseMove: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    ClassNameOverride?: string;
}

export const TimeLineCell: FunctionComponent<IProps> = props => {
    return (
        <div
            id={`tp-timeline-cell-${props.Index}`}
            onMouseDown={e => ('ontouchstart' in document.documentElement ? {} : props.MouseDown(e))}
            onMouseMove={e => props.MouseMove(e)}
            onTouchStart={e => ('ontouchstart' in document.documentElement ? props.MouseDown(e) : {})}
            onContextMenu={e => {
                e.preventDefault();
                e.stopPropagation();
                return false;
            }}
        >
            <div className={`${props.ClassNameOverride ? props.ClassNameOverride : 'tp-timeline-modern-element'} ${props.Selected ? 'selected' : ''}`}>
                {props.CellTitle}
            </div>
        </div>
    );
};
