import { useGridContext } from '../../../../_grid/contexts/gridContext';
import { useHeatmapBackground } from '../hooks/useHeatmapBackground';
import { useUISettings } from '../../../../../../context/network/http/QueryProvider/queries/UISettings';
// import { useFixedCellValue } from './gridComponentsPlannerViewV2';
import { GridCell, GridTotalColumnRow } from '../../../../../../api/generated/data-contracts';
import { useFixedCellValue } from '../../../../_grid/hooks/useFixedCellValue';

type ColoredTotalCellProps = {
    cell: GridCell;
    isRowExpanded: boolean;
    totalColumnRow: GridTotalColumnRow;
    totalCapacity?: number;
};

export function ColoredTotalCell({ cell, isRowExpanded, totalColumnRow }: ColoredTotalCellProps) {
    const { totalCapacity = 1 } = totalColumnRow?.metadata ?? {};
    const {
        data: {
            settings: {
                resourcePlannerSettings: {
                    gridSettings: {
                        //
                        heatMapTextColor,
                        heatMapClassicColors,
                        heatMapColorOffset,
                        heatMapColorSaturation,
                        heatMapInvertSpectrum,
                        heatMapLightness,
                        heatMapOverAllocation,
                        heatMapUnderAllocation,
                        equalAllocationColor,
                        overAllocationColor,
                        underAllocationColor,
                    },
                },
            },
        },
    } = useUISettings();

    const { useGridCellValue } = useGridContext();
    const stateValue = useGridCellValue(cell);
    const backgroundColor = useHeatmapBackground({
        value: stateValue.toNumber(),
        totalCapacity,
        maxOverAllocationPercent: heatMapOverAllocation,
        maxUnderAllocationPercent: heatMapUnderAllocation,
        colorSaturation: heatMapColorSaturation,
        lightness: heatMapLightness,
        colorOffset: heatMapColorOffset,
        invertSpectrum: heatMapInvertSpectrum,
        classicColors: heatMapClassicColors,
        equalAllocationColor,
        overAllocationColor,
        underAllocationColor,
    });
    const value = useFixedCellValue(stateValue);
    return (
        <div
            style={{
                //
                backgroundColor,
                ...(isRowExpanded
                    ? {
                          width: 80,
                          height: '2rem',
                      }
                    : {
                          width: '100%',
                          height: '100%',
                      }),
                transition: '.3s ease',
                lineHeight: '100%',
                position: 'relative',
            }}
        >
            <span
                style={{
                    position: 'absolute',
                    top: '50%',
                    fontWeight: 500,
                    ...(isRowExpanded
                        ? {
                              transform: 'translate(-100%, -50%)',
                              left: 'calc(100% - 8px)',
                          }
                        : {
                              transform: 'translate(-50%, -50%)',
                              left: '50%',
                          }),
                    transition: '.3s',
                    color: heatMapTextColor,
                }}
            >
                {value}
            </span>
        </div>
    );
}
