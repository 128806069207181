import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyFilter } from "../../Utilities/PropertyInfo/PropertyFilter";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";

export class GridActionOptions {
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.MatchRequestDefaultTicked", 1)
	@PFPropertyFilter({FilterLevel: 2})
	public MatchRequestDefaultTicked: boolean;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.CreateMatchingRequestDefaultTicked", 2)
	@PFPropertyFilter({FilterLevel: 2})
	public CreateMatchingRequestDefaultTicked: boolean;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Settings.ForwardRequestFullDefaultTicked", 3)
	@PFPropertyFilter({FilterLevel: 2})
	public ForwardRequestFullDefaultTicked: boolean;
}