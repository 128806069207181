import { FluentPropertyLabel } from "@wiberg/fluentui-builder";
import { IFormItemBuilder, FormLabel, IFormItem, IDynamicPropertyComponentConfig, IItemRenderProps, IFormItemPropertyOptions, IFormItemBuilderResult, buildPropertyRenderInfo, getValidationMarkForProperty, ValidationMark } from "@wiberg/formbuilder";
import { ITpPropertyTypes, tpPropertyTypes } from "../tpPropertyTypes";
import { AadGroupField } from "./components/AadGroupField";
import { PeriodSpanField } from "./components/PeriodSpanField";
import { ProjectField } from "./components/ProjectField";
import { RbsField } from "./components/RbsField";
import { ResourceField } from "./components/ResourceField";
import { TreeField } from "./components/TreeField";
import { DateWithTodayFilterField } from "./components/DateWithTodayFilterField";

export const createTpComplexBuilder = () : IFormItemBuilder => {

    const id: Readonly<string> = "tpComplexBuilder";
    
    const BuilderLabelRender: FormLabel = FluentPropertyLabel; // re-using the label from the fluent builder.

    const build = <T extends IFormItem, C extends IDynamicPropertyComponentConfig<T>>(renderProps: IItemRenderProps<T>, property: string, schema: IFormItemPropertyOptions<T, C>): IFormItemBuilderResult => {
        const { item } = renderProps;
        
        // guards
        if (item === null) throw Error("item is null");
        if (schema == null) throw Error("schema is null");

        // build the info needed for the render (tis is a supplied method)
        const info = buildPropertyRenderInfo(renderProps, schema, property);
        const validationMark: ValidationMark = getValidationMarkForProperty(renderProps, property);

        // add a label for the property name
        const WrapInLabel = (element: JSX.Element, addErrormessage?: boolean) : JSX.Element => {
            return (
                <div className="formbuilder-property" key={info.props.key}>
                    <BuilderLabelRender
                        key={`${info.key}-labelcontainer`}
                        propertySchema={schema}
                        hideLabel={info.props.options.hideLabel}
                        parentKey={info.key}
                        validationMark={validationMark}
                    />
                    { element }
                </div>
            )
        }

        // extending the default propertypes that ships with the formbuilder
        const tpPropertyType: ITpPropertyTypes = tpPropertyTypes;

        // render based on property type
        switch (schema.propertyType) {
            case tpPropertyType.rbs: return { found: true, element: WrapInLabel(<RbsField {...schema} {...info.props} />) };
			case tpPropertyType.tree: return { found: true, element: WrapInLabel(<TreeField {...schema} {...info.props} />) };
            case tpPropertyType.resource: return { found: true, element: WrapInLabel(<ResourceField {...schema} {...info.props} />) };
			case tpPropertyType.project: return { found: true, element: WrapInLabel(<ProjectField {...schema} {...info.props} />) };
			// case tpPropertyType.entityProperty: return { found: true, element: WrapInLabel(<MessageBarField {...schema} {...info.props} />) };
			case tpPropertyType.aadGroup: return { found: true, element: WrapInLabel(<AadGroupField {...schema} {...info.props} />) };
			case tpPropertyType.periodSpan: return { found: true, element: WrapInLabel(<PeriodSpanField {...schema} {...info.props} />) };
			case tpPropertyType.dateWithTodayFilter: return { found: true, element: WrapInLabel(<DateWithTodayFilterField {...schema} {...info.props} />) };
            default: return { found: false, element: undefined }
        }
    };

    return { id, build, loadingComponent: undefined, listComponent: undefined };
}