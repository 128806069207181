import { ICommandBarStyleProps, ICommandBarStyles } from '@fluentui/react/lib/CommandBar';
import { darkColors } from '../colors.default';

export const CommandBarStyles = (props: ICommandBarStyleProps): Partial<ICommandBarStyles> => {

	return {
		root: {
			backgroundColor: darkColors.bg,
		}
	};
};