import { CustomProperty, Origin, RootSettings } from "../api/generated/data-contracts";
import { upperCaseObj } from "../context/network/http/QueryProvider/helpers/queryHelper";
import { settings } from "../context/network/http/QueryProvider/queries/UISettings";
import { Settings } from "../Entities/Main/Settings";
import { changeCase } from "./changeKeysCase";

const getProperties = (properties: string[]) : string[] => {
	if (properties == null) return null;
	const parsedProperties = changeCase.arrayStringsToCamel(properties);
	// hardcoded replace for RBS property
	const idx = parsedProperties.indexOf("rBS");
	if (idx >= 0)
		parsedProperties.splice(parsedProperties.indexOf("rBS"), 1, "rbs");
	return parsedProperties;
}

const tempParseProperties = (properties: string[]) : string[] => {
	if (properties == null) return null;
	const parsedProperties = changeCase.arrayStringsToPascal(properties);
	// hardcoded replace for RBS property
	let idx = parsedProperties.indexOf("Rbs");
	if (idx >= 0)
		parsedProperties.splice(parsedProperties.indexOf("Rbs"), 1, "RBS");
	idx = parsedProperties.indexOf("Id");
	if (idx >= 0)
		parsedProperties.splice(parsedProperties.indexOf("Id"), 1, "id");
	return parsedProperties;
}
const tempParsePropertiesBack = (properties: string[]) : string[] => {
	if (properties == null) return null;
	const parsedProperties = changeCase.arrayStringsToCamel(properties);
	// hardcoded replace for RBS property
	let idx = parsedProperties.indexOf("rBS");
	if (idx >= 0)
		parsedProperties.splice(parsedProperties.indexOf("rBS"), 1, "rbs");
	idx = parsedProperties.indexOf("Id");
	return parsedProperties;
}

const tempParseProperty = (property: string) : string => {
	if (property === "id") return "id";
	if (property === "rbs") return "RBS";
	return changeCase.stringToPascal(property)
}
const tempParsePropertyBack = (property: string) : string => {
	if (property === "RBS") return "rbs";
	return changeCase.stringToCamel(property)
}

const getOverviewProperties = (type: 'resource' | 'project' | 'contract') : string[] => {
	const config = settings();
	let properties = [];
	switch (type) {
		case "resource":
			properties = config.resourceOverviewProperties;
			break;
		case "project":
			properties = config.projectOverviewProperties;
			break;
		case "contract":
			properties = config.contractOverviewProperties;
			break;
		default:
			break;
	}
	return getProperties(properties);
}

const getCustomProperties = (type: 'resource' | 'project' | 'contract') : CustomProperty[] => {
	const config = settings();
	switch (type) {
		case "resource":
			return config.resourceCustomProperties;
		case "project":
			return config.projectCustomProperties;
		case "contract":
			return config.contractCustomProperties;
		default:
			return [];
	}
}

const getOriginProperties = (origin: Origin, type: "resource" | "project" | "contract") : string[] => {
	let properties = [];
	switch (type) {
		case "resource":
			properties = origin.resourcePropertiesAllowed;
			break;
		case "project":
			properties = origin.projectPropertiesAllowed;
				break;
		default:
			break;
	}

	return getProperties(properties);
}

const parseSettingsToLegacy = (settings: RootSettings) => {
	return {
		...(upperCaseObj(settings)),
		...(settings.userSettingPropertiesAvailable && {
			UserSettingPropertiesAvailable: settings.userSettingPropertiesAvailable.map(refactorHelper.tempParseProperty),
		}),
		...(settings.subSettingPropertiesAvailable && {
			SubSettingPropertiesAvailable: settings.subSettingPropertiesAvailable.map(refactorHelper.tempParseProperty),
		}),
	} as Settings
}

/**
 * This is a helper for supporting all the minor quirks needed when doing a splitted refactor
 */
export const refactorHelper = {
	getOverviewProperties,
	getOriginProperties,
	tempParseProperties,
	tempParsePropertiesBack,
	tempParseProperty,
	tempParsePropertyBack,
	parseSettingsToLegacy,
	getCustomProperties
}