import { Suspense, useState, useMemo, useEffect } from 'react';
import { MainView, useRouteEnum } from '../../../Entities/MainView';
import ForwardRequestDialog from '../ForwardRequestDialog';
import { PFSpinner } from '../../Common/Spinner';
import { useStoreContext } from '../../../Services/StateStore';
import MoveRequestDialog from '../MoveRequestDialog';
import { PFErrorBoundary } from '../../Common/PFErrorBoundary';
import TimelineModern from '../Timeline/TimelineModern';
import MatchingActivityDialog from '../MatchingActivityDialog';
import { EntityInformationCallout } from '../../Common/EntityInformationCallout';
import { Link } from '@fluentui/react';
import { language } from '../../../Services/LocalizationService';
import FindAvailableCapacityDialog from '../AutoAllocate/FindAvailableCapacity/FindAvailableCapacity';
import { EnumPlacement, OverlayButton } from '../OverlayButton';
import { OverviewContext } from '../../../Utilities/Context/OverviewContext';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../../context/store';
import shallow from 'zustand/shallow';
import EnumEx from '../../../Utilities/EnumEx';
import { TimelineCoachmarks } from '../../../userGuidance/areas/TimelineCoachmarks';
import { useCheckTPPermissions } from '../../../hooks/usePermissions';
import { UserType } from '../../../api/generated/data-contracts';

export const Main = () => {
    const view = useRouteEnum();
    const { pathname } = useLocation();
    const goTo = useNavigate();

	const { setFastDeletionEnabled } = useStore(store => ({
		setFastDeletionEnabled: store.setGridSettingsEnableFastDeletionCallback
	}), shallow);

	useEffect(() => {
		setFastDeletionEnabled(false)
	}, [pathname, setFastDeletionEnabled])

    const isOnResourcePlanner = useMemo(() => pathname.startsWith('/resourceplanner'), [pathname]);
	const isOnProjectPlanner = useMemo(() => pathname.startsWith('/projectplanner'), [pathname]);

    const shouldBeWithTimeline = useMemo(() => {
        return (
            EnumEx.equalsAny(
                view,
                MainView.ProjectCapacity,
                MainView.CompareScenariosCapacity,
                MainView.DraftVersionedCapacity,
                MainView.LmCapacity,
                MainView.ResourceCapacity,
            ) || isOnResourcePlanner || isOnProjectPlanner
        );
    }, [isOnProjectPlanner, isOnResourcePlanner, view]);

    const { setUIContext, showOverlayButtons, entityCalloutInfo } = useStore(
        store => ({
            setUIContext: store.setUIContext,
            showOverlayButtons: store.ui.showOverlayButtons,
            entityCalloutInfo: store.entityCalloutInfo,
        }),
        shallow,
    );
    const [isFirstRender, setIsFirstRender] = useState(true);
    const checkPermission = useCheckTPPermissions({ some: true });
    return (
        <Suspense fallback={<PFSpinner />}>
            <PFErrorBoundary ComponentName="main view" Reset={() => Promise.resolve(goTo('/'))}>
                {(view === MainView.ProjectCapacity || view === MainView.LmCapacity) &&
                    showOverlayButtons &&
                    !OverviewContext.SingleProjectView &&
                    checkPermission({ userTypes: [UserType.LineManager, UserType.ProjectOwner, UserType.SuperUser] }) && (
                        <OverlayButton
                            id={'project-overlay-button'}
                            placement={EnumPlacement.left}
                            isFirstRender={isFirstRender}
                            setIsFirstRender={setIsFirstRender}
                            onClick={() => setUIContext({ showProjectPicker: true })}
                        >
                            {language?.Overview?.Project}
                        </OverlayButton>
                    )}

                {shouldBeWithTimeline ? (
                    <Timeline>
                        <Outlet />
                    </Timeline>
                ) : (
                    <Outlet />
                )}

                {((view === MainView.ResourceCapacity && checkPermission({ userTypes: [UserType.LineManager, UserType.SuperUser] })) ||
                    view === MainView.ProjectCapacity ||
                    view === MainView.LmCapacity ||
                    view === MainView.WorkPackages ||
                    view === MainView.ProjectPlanner ||
                    view === (MainView.DraftVersionedCapacity as any)) &&
                    showOverlayButtons &&
                    checkPermission({ userTypes: [UserType.LineManager, UserType.ProjectOwner, UserType.SuperUser] }) && (
                        <OverlayButton
                            id={'resource-overlay-button'}
                            placement={EnumPlacement.right}
                            isFirstRender={isFirstRender}
                            setIsFirstRender={setIsFirstRender}
                            onClick={() => setUIContext({ showResourcePicker: true })}
                        >
                            {language?.Overview?.Resource}
                        </OverlayButton>
                    )}
                <EntityInformationCallout Dto={entityCalloutInfo}>
                    {entityCalloutInfo?.Properties?.Url && (
                        <Link className="ms-CalloutExample-link" href={entityCalloutInfo.Properties.Url} target="_blank">
                            {language.Main.GoToProject}
                        </Link>
                    )}
                </EntityInformationCallout>
            </PFErrorBoundary>
        </Suspense>
    );
};

const Timeline: React.FC = ({ children }) => {
    const ctx = useStoreContext();
    const { timeline } = useStore(
        store => ({
            timeline: store.timeline,
        }),
        shallow,
    );
    return (
        <>
            <div className="top">
                <TimelineModern />
                <div id={'tp-timeline-modern-table'} style={{ position: 'absolute', top: 0, left: 0, height: 90, width: '100vw', pointerEvents: 'none' }} />
                <TimelineCoachmarks />
            </div>
            <div style={{ overflow: 'hidden', height: '100%' }}>
                <div id="bottom-main" className="bottom">
                    <div id="bottom-main-overlay" className="bottom-main-overlay-containers"></div>
                    {children}
                </div>
            </div>
            <ForwardRequestDialog Start={timeline.start} End={timeline.end} Resolution={timeline.resolution} />
            <MoveRequestDialog Start={timeline.start} End={timeline.end} Resolution={timeline.resolution} RowMenuItemInfo={ctx.Get(_ => _.MoveRequestInfo)} />
            <FindAvailableCapacityDialog rowMenuItemInfo={ctx.Get(_ => _.FindAvailableCapacityInfo)} />
            <MatchingActivityDialog
                Start={timeline.start}
                End={timeline.end}
                Resolution={timeline.resolution}
                RowMenuItemInfo={ctx.Get(_ => _.MatchingActivityInfo)}
            />
        </>
    );
};
