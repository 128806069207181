import { UserType } from "../UserType";
import EnumEx from "../../Utilities/EnumEx";
import { DropdownOption } from "../Fabric/DropdownOption";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyGrouping } from "../../Utilities/PropertyInfo/Grouping";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyValidation } from "../../Utilities/PropertyInfo/Validation";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { guid } from "../../helpers/guid";
import { OverviewContext } from "../../Utilities/Context/OverviewContext";
import { ResourceType } from "../ResourceType";
import { DropdownOptionEx } from "../../Utilities/DropdownOptionEx";
import { DateEx } from "../../Utilities/DateEx";
import { language } from "../../Services/LocalizationService";
import { IObjectEditorItemType } from "./IObjectEditorItemType";
import { UnitType } from "../UnitType";
import { UserContext } from "../../Utilities/Context/UserContext";
import { Permission } from "../../Services/Permission";
import { StringEx } from "../../Utilities/StringEx";
import { QueryGroup } from "../DynamicQueryBuilder/QueryGroup";
import { ITPENtity } from "./ITPEntity";
import { MessageBarType } from "@fluentui/react";
import { ResourceWithStringVariants } from "../../ListItems/ResourceWithStringVariants";

export type TResource = typeof Resource;

export class Resource implements IObjectEditorItemType, ITPENtity {
	public TypeName: string = "Resource";
	
	// BASIC
	@PFPropertyBasicInfo(PropertyType.MessageBar, null, 0, "_localization:Resource.ActiveDescription")
	@PFPropertyGrouping("Basic", 1, false)
	@PFPropertyRender((obj, i, col) => obj.Active?.toString(), { Options: (item: Resource) => {
 return { message: item.Active ? language.Resource.ActiveState : language.Resource.InActiveState, type: item.Active ? MessageBarType.success : MessageBarType.warning, width: "620px" } 
}}, null, (item: Resource) => !item.Created, null, 100)
	public Active: boolean;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.Name", 1)
	@PFPropertyGrouping("Basic", 1, false)
	@PFPropertyValidation("The resource must have a name.", (obj: Resource) => obj.Name && obj.Name !== "", null, () => true)
    public Name: string;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.Email", 2)
	@PFPropertyGrouping("Basic")
	@PFPropertyRender(null, null, null, (item: Resource) => item.ResourceType !== ResourceType.Named)
	public Email: string;
	// @PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Common.IsActive", 5)
	// @PFPropertyGrouping("Basic")
	// @PFPropertyOnChange((item: Resource) => item.ActiveChanged = DateEx.TodaysDate())
	// @PFPropertyRender(null, null, null, null, null, 100)
	// public Active: boolean;
	public ActiveChanged: Date;

	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Resource.ResourceType", 6)
	@PFPropertyGrouping("Basic")
	@PFPropertyRender((item: Resource, i, col) => ResourceType[item.ResourceType], { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<ResourceType>(ResourceType) }, null, null, null, 100)
	@PFPropertyValidation("_localization:Resource.ResourceTypeIsRequired", (obj: Resource) => obj.ResourceType !== null, null, () => true)
    public ResourceType: ResourceType;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.GenericResource", 9, "_localization:Resource.RelatedToGenericResource")
	@PFPropertyRender(null, { RoleSearchEnabled: true, Types: [ResourceType.Generic] }, "ResourcePicker", (item: Resource) => item.ResourceType !== ResourceType.Named, (item: Resource) => true)
	@PFPropertyGrouping("Basic")
	public RelatedGenericResourceId: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Resource.MainWorkProfile", 10)
	@PFPropertyGrouping("Basic")
	@PFPropertyRender(null, null, "WorkProfileSelector", (item: Resource) => item.ResourceType !== ResourceType.Named && item.ResourceType !== ResourceType.Generic && item.ResourceType !== ResourceType.Pool)
	public WorkProfileId?: string;
    @PFPropertyBasicInfo(PropertyType.Date, "_localization:Resource.EarliestAvailable", 11)
	@PFPropertyGrouping("Basic")
	@PFPropertyRender(null, null, null, (item: Resource) => item.ResourceType !== ResourceType.Named && item.ResourceType !== ResourceType.WorkProfile)
	@PFPropertyValidation("Earliest Available is a required field.", (obj: Resource) => obj.EarliestAvailable !== null, null, () => true)
    public EarliestAvailable: Date;
    @PFPropertyBasicInfo(PropertyType.Date, "_localization:Resource.LatestAvailable", 12)
	@PFPropertyGrouping("Basic")
	@PFPropertyRender(null, null, null, (item: Resource) => item.ResourceType !== ResourceType.Named && item.ResourceType !== ResourceType.WorkProfile)
	public LatestAvailable: Date;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Resource.Manager", 14)
	@PFPropertyRender((item: ResourceWithStringVariants, i, col) => item[col.fieldName] && item.ManagerAsString, { Types: [ResourceType.Named] }, "ResourcePicker")
	@PFPropertyGrouping("Basic")
    public Manager: string;

	
	// CAPACITY
	@PFPropertyBasicInfo(PropertyType.Number, "_localization:Resource.WorkingPercent", 1, "_localization:Resource.WorkingPercentDescription")
	@PFPropertyGrouping("Capacity")
	@PFPropertyRender(null, null, null, (item: Resource) => item.ResourceType !== ResourceType.Named || !OverviewContext.Settings.EnableResourceWorkingPercentAndUtilization)
	@PFPropertyValidation("_localization:Resource.WorkingPercentValidation", (obj: Resource) => obj.WorkingPercent >= 0 && obj.WorkingPercent <= 1, null, () => false)
	public WorkingPercent: number;
	@PFPropertyBasicInfo(PropertyType.Number, "_localization:Resource.Utilization", 2, "_localization:Resource.UtilizationDescription")
	@PFPropertyGrouping("Capacity")
	@PFPropertyRender(null, null, null, (item: Resource) => item.ResourceType !== ResourceType.Named || !OverviewContext.Settings.EnableResourceWorkingPercentAndUtilization)
	@PFPropertyValidation("_localization:Resource.UtilizationValidation", (obj: Resource) => obj.Utilization >= 0 && obj.Utilization <= 1, null, () => false)
	public Utilization: number;


	// PERMISSION
	@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Resource.UserTypes", 1)
	@PFPropertyGrouping("Permission", 2)
	@PFPropertyRender((item: Resource, i, col) => item[col.fieldName].map(_ => UserType[_]).join(", "), { PredefinedValues: EnumEx.all<UserType>(UserType).filter(_ => _ as any !== "None").map(e => new DropdownOption(UserType[e], e.toString())) }, null, (obj) => obj.ResourceType !== ResourceType.Named, (obj) => OverviewContext.Settings.EnableADGroupSecurity)
	public UserTypes: Array<UserType>;
	@PFPropertyBasicInfo(PropertyType.StringCopyValue, "_localization:Resource.UserPrincipalName", 2)
	@PFPropertyGrouping("Permission")
	@PFPropertyRender(null, null, null, (res: Resource) => res.ResourceType !== ResourceType.Named)
	public UserPrincipalName: string;
	
	
	// RBS
    @PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Rbs", 1, "_localization:Resource.RbsDescription")
    @PFPropertyGrouping("RBS", 3)
	@PFPropertyRender(null, { Type: "select", SingleSelect: true }, "RbsEditor", (resource: Resource) => resource.ResourceType === ResourceType.Pool || resource.ResourceType === ResourceType.WorkProfile, (resource: Resource) => (resource.ResourceType === ResourceType.Generic && (!!resource.Created && !OverviewContext.Settings.AllowChangeRbsAndCustomPropertiesOnGenericResource)) || (resource.id === UserContext.Resource.id && !Permission.IsSuperuser()))
	@PFPropertyValidation("_localization:Resource.RbsValidation", (obj: Resource) => obj.ResourceType === ResourceType.Pool || obj.ResourceType === ResourceType.Team || !StringEx.isNullOrWhiteSpace(obj.RBS), null, () => true)
	public RBS: string;
    @PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.AdditionalRbs", 2)
	@PFPropertyGrouping("RBS")
	@PFPropertyRender(null, { Type: "select", ButtonTitle: "_localization:Common.AdditionalRbs" }, "RbsEditor", (resource: Resource) => resource.ResourceType !== ResourceType.Named && resource.ResourceType !== ResourceType.Team, (resource: Resource) => ((resource.ResourceType === ResourceType.Generic && !!resource.Created)) || (resource.id === UserContext.Resource.id && !Permission.IsSuperuser()))
	public AdditionalRBS: Array<string>;


	// Custom Properties
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.CustomPropertiesCapitalized", 1, "Custom resource properties")
	@PFPropertyGrouping("Custom properties", 4)
	@PFPropertyRender(null, { Type: "resource" }, "CustomPropertiesSelector", (resource: Resource) => resource.ResourceType === ResourceType.Category || resource.ResourceType === ResourceType.Team || (resource.ResourceType === ResourceType.Pool && OverviewContext.Settings.EnableDynamicResourcePools))
	@PFPropertyValidation("_localization:Resource.CustomPropertyValidation", (item: Resource) => item.ResourceType === ResourceType.Category || item.ResourceType === ResourceType.Team || (item.ResourceType === ResourceType.Pool && OverviewContext.Settings.EnableDynamicResourcePools) || OverviewContext.Settings.ResourceCustomProperties.filter(_ => _.Required).every(_ => item.CustomProperties && item.CustomProperties[_.Id] != null), (item: Resource) => `Required fields: ${OverviewContext.Settings.ResourceCustomProperties.filter(_ => _.Required).map(_ => _.DisplayName).join(", ")}`, () => false)
	public CustomProperties: { [key: string]: string } = {};
	

	// SUPER DYNAMIC POOL MATCHER
	// TODO NEW FORM
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Resource.ResourcePoolMatcher", 9, "_localization:Resource.ResourcePoolMatcherDescription")
	@PFPropertyGrouping("Resource pool", 5)
	@PFPropertyRender(null, { ItemTypeAsString: "Resource", DialogTitleGetter: () => language.Resource.ResourcePoolMatcher }, "EntityFilterEditor", (res: Resource) => res.ResourceType !== ResourceType.Pool || !OverviewContext.Settings.EnableDynamicResourcePools)
	public Matcher: QueryGroup;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Resource.ExcludedFromPools", 10)
	@PFPropertyGrouping("Resource pool", 5)
	@PFPropertyRender(null, null, null, (item: Resource) => item.ResourceType !== ResourceType.Named, null, 100)
	public ExcludeFromPools: boolean;
	

	// SPECIFIC WORKPROFILE
	@PFPropertyBasicInfo(PropertyType.BooleanToggle, "_localization:Resource.EnableSpecificWorkProfile", 1, "_localization:Resource.EnableSpecificWorkProfileDescription")
	@PFPropertyGrouping("Specific work profile", 6)
	@PFPropertyRender(null, null, null, (item: Resource) =>  item.ResourceType !== ResourceType.Named)
	public EnableSpecificWorkprofile: boolean;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Resource.SpecificWorkProfile", 2)
	@PFPropertyGrouping("Specific work profile")
	@PFPropertyRender(null, null, "ResourceSpecificWorkprofileEditor", (item: Resource) => !item.EnableSpecificWorkprofile || item.ResourceType !== ResourceType.Named)
	public SpecificWorkprofileId: string;

	// SYSTEM
	@PFPropertyBasicInfo(PropertyType.StringCopyValue, "_localization:Common.Id", 1)
	@PFPropertyGrouping("System", 100)
	@PFPropertyRender(null, null, null, null, (res: Resource) => res.Created != null)
	public id: string;
	@PFPropertyBasicInfo(PropertyType.StringCopyValue, "_localization:Common.AzureId", 2)
	@PFPropertyGrouping("System")
	public AzureId: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.OriginIds", 3)
	@PFPropertyGrouping("System")
	@PFPropertyRender(null, null, "OriginIdRender")
	public OriginIds: { [key: string]: string };
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Origins", 4)
	@PFPropertyGrouping("System")
	@PFPropertyRender(null, null, "OriginRender")
	public Origins: Array<string>;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Created", 10)
	@PFPropertyGrouping("System")
	@PFPropertyRender(null, { DisplayFormat: (date: Date) => DateEx.asUserLocalWithTime(date) }, null, null, null, 120)
    public Created: Date;
    @PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Modified", 11)
	@PFPropertyGrouping("System")
	@PFPropertyRender(null, { DisplayFormat: (date: Date) => DateEx.asUserLocalWithTime(date) }, null, null, null, 120)
    public Modified: Date;
    @PFPropertyBasicInfo(PropertyType.String, "_localization:Common.CreatedBy", 12)
	@PFPropertyGrouping("System")
    public CreatedBy: string;
    @PFPropertyBasicInfo(PropertyType.String, "_localization:Common.ModifiedBy", 13)
	@PFPropertyGrouping("System")
	public ModifiedBy: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Settings", 20)
	@PFPropertyGrouping("System")
	@PFPropertyRender(null, null, "SettingsSelector")
	public Settings: string;
	// NOW USED THROUGH SETTINGS
	// @PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Common.Localization", 25)
	// @PFPropertyGrouping("System")
	// @PFPropertyRender(null, { PredefinedValuesGetterAsync: () => LocalizationService.GetAvailableLocales() }, null, (resource: Resource) => resource.ResourceType != ResourceType.Named)
	// public Localization: string;
	@PFPropertyBasicInfo(PropertyType.StringCopyValue, "_localization:Resource.TeamProject", 40)
	@PFPropertyGrouping("System")
	@PFPropertyRender(null, null, null, (resource: any) => resource.ResourceType !== ResourceType.Team, (resource: any) => true)
	public TeamProject: string; // guid
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Resource.LastSignIn", 50)
	@PFPropertyGrouping("System")
	@PFPropertyRender(null, { DisplayFormat: (date: Date) => DateEx.asUserLocalWithTime(date) }, null, null, _ => true, 120)
	public LastSignin: Date;

	// FAVOURITES - not used in editors
    public FavouriteProjects: Array<string>;
	public FavouriteResources: Array<string>;
	
	public DelegatedResourceId: string;
	public PreferredUnitType: UnitType;

	constructor() {
		// this.Rules = [];
		this.CustomProperties = {};
		this.Origins = [guid.empty];
		this.Active = true;
	}
}