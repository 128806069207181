import { Stack, Spinner, SpinnerSize } from '@fluentui/react';
import { Suspense } from 'react';

export const LazyLoadPageSuspender = ({ children, label = 'Loading..' }: { children: React.ReactNode; label?: string }) => {
    return <Suspense fallback={<PageSpinner label={label} />}>{children}</Suspense>;
};

const PageSpinner = ({ label }: { label: string }) => {
    return (
        <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { height: '100%', width: '100%' } }}>
            <Spinner label={label} size={SpinnerSize.large} />
        </Stack>
    );
};
