import { TimelineResolution } from "../TimelineResolution";
import { ActivityDistributionConfig } from "./ActivityDistributionConfig";

export class ActivityDistributionDto {
	Resolution: TimelineResolution;
	ContractId: string;
	ContractName: string;
	ResourceId: string;
	ResourceName: string;
	ProjectId: string;
	Start: Date;
	End: Date;
	EarliestStart: Date;
	LatestEnd: Date;
	Work: number;
	ScenarioId: string | null;
	ScenarioName: string;
	OnlyRequests: boolean;
	DistributionConfig: ActivityDistributionConfig;
	PerformDistribution: boolean;
}