import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { EnvironmentState } from '../../Entities/EnvironmentState';
import { useParams } from 'react-router-dom';
import { useDisableByEnvironment } from '../../Utilities/DisableElementByEnvironmentState';
import { useMemo } from 'react';

interface IProps {
    envStates: EnvironmentState[];
    disallowedOn?: string[];
    param?: string;
	redirect?: string;
	fallback?: React.ReactNode
}

export const RequireEnvironment: React.FC<IProps> = ({ envStates, disallowedOn, param, children, redirect = "/", fallback }) => {
    const location = useLocation();
    const isDisabledByEnv: boolean = useDisableByEnvironment(envStates);
    const paramVal = useParams()[param];
    const isDisallowed: boolean = useMemo(
        () => (disallowedOn && paramVal ? disallowedOn.includes(paramVal) && isDisabledByEnv : isDisabledByEnv),
        [disallowedOn, isDisabledByEnv, paramVal],
    );

    if (isDisallowed) {
		if (fallback !== undefined) {
            return <>{fallback}</>;
        }
        return <Navigate to={redirect} state={{ from: location }} />;
    }

    return <>{children !== undefined ? children : <Outlet />}</>;
};
