import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyFilter } from "../../Utilities/PropertyInfo/PropertyFilter";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";

export class AutoAlignContractNames {
	// rename the display name if other naming rules may come up
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Common.Enable", 1)
	@PFPropertyFilter({FilterLevel: 1})
	public WithGenericResource: boolean;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:AutoAlignContractNames.OnlyForNameMatch", 2, "_localization:AutoAlignContractNames.OnlyForNameMatchDescription")
	@PFPropertyFilter({FilterLevel: 1})
	public OnlyForNameMatch: boolean;
}