import { useMemo } from 'react';
import { GridType } from '../../../../../../Entities/GridType';
import { useState } from 'react';
import { ResourceListItem, RootSettings } from '../../../../../../api/generated/data-contracts';
import { getFieldsGroupBy, getPickerFields } from '../helpers';
import { generateAsStringGetters } from '../../helpers/generateAsStringGetters';
import { ResourceListItemWithMetadata } from '../../types';
import { useDebouncedEventHandler } from '../../../../../../hooks/useDebouncedHandler';

type useSearchedFilteredDataProps = {
    //
    data: ResourceListItem[];
    namedResources: ResourceListItem[];
    gridType: GridType;
    settings: RootSettings;
    delegationMode: boolean;
    searchGenericResourcesByName: boolean;
};

export const useSearchedFilteredData = ({
    data,
    gridType,
    settings,
    delegationMode,
    namedResources,
    searchGenericResourcesByName,
}: useSearchedFilteredDataProps) => {
    const [searchInputValue, setSearchInputValue] = useState('');
    const searchFieldsAsStringGetters = useMemo(() => {
        const pickerFields = getPickerFields(settings, gridType);
        return generateAsStringGetters(
            ['name', 'email', delegationMode && getFieldsGroupBy(settings, gridType), pickerFields.title, pickerFields.secondary, pickerFields.tertiary].filter(
                Boolean,
            ),
        );
    }, [delegationMode, gridType, settings]);

    const searchFilteredData: ResourceListItemWithMetadata[] = useMemo(() => {
        const value = searchInputValue.trim().toLowerCase();
        if (!value) {
            return data;
        }
        if (searchGenericResourcesByName) {
            const namedResourcesByGenericId = new Map(
                namedResources.reduce((acc, resource) => {
                    if (searchFieldsAsStringGetters.some(getter => getter(resource).toLowerCase().includes(value))) {
                        acc.push([resource.relatedGenericResourceId || '', resource]);
                    }
                    return acc;
                }, [] as [string, ResourceListItem][]),
            );
            return data.reduce((acc, resource) => {
                if (namedResourcesByGenericId.has(resource.id)) {
                    const namedResource = namedResourcesByGenericId.get(resource.id);
                    acc.push({
                        ...resource,
                        __meta: namedResource.name,
                    });
                } else if (searchFieldsAsStringGetters.some(getter => getter(resource).toLowerCase().includes(value))) {
                    acc.push(resource);
                }
                return acc;
            }, [] as ResourceListItemWithMetadata[]);
        }
        return data.filter(resource => searchFieldsAsStringGetters.some(getter => getter(resource).toLowerCase().includes(value)));
    }, [data, namedResources, searchFieldsAsStringGetters, searchGenericResourcesByName, searchInputValue]);

    const onChangeHandler = useDebouncedEventHandler((notUsedEvent: never, newValue: string | undefined) => {
        setSearchInputValue(newValue || '');
    });

    return [searchFilteredData, onChangeHandler, searchInputValue] as const;
};
