import { Dropdown, IconButton, TooltipHost } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import WorkPackageSelector from "../../../Components/Common/WorkPackageSelector";
import { WorkPackageDto } from "../../../Entities/Main/WorkPackage";
import { language } from "../../../Services/LocalizationService";
import { Plugin } from "../../../Services/PluginInvoker";
import { WorkPackagesCoachmarks } from "../../../userGuidance/areas/WorkPackagesCoachmarks";
import { DropdownOptionEx } from "../../../Utilities/DropdownOptionEx";
import { IDs } from "../../../Utilities/IDs";
import { PluginIDs } from "../../../Utilities/PluginIDs";
import WorkPackageEditor from "./WorkPackageEditor";
import WorkPackageTimeline from "./WorkPackageTimeline";


const WorkPackages = () => {

	const [dateRange, setDateRange] = useState<{Start: Date, End: Date}>();
	const [workPackage, setWorkPackage] = useState<WorkPackageDto>(null);
	const [allPackages, setAllPackages] = useState<Array<WorkPackageDto>>([]);

	useEffect(() => {
		const getter = async ()  => {
			const allPackages = await LoadAllPackagesFlat();
			setAllPackages(allPackages);
		};
		getter();
	}, [])

	const LoadAllPackagesFlat = async () : Promise<Array<WorkPackageDto>> => {
		const allPackages = await Plugin.Invoke<Array<WorkPackageDto>>(PluginIDs.GetAllWorkpackagesFlat, null);
		return allPackages;
	}

	const CreateNewPackage = async () => {
		const wp = new WorkPackageDto();
		wp.Name = IDs.makeId();
		await Plugin.Invoke(PluginIDs.CreateWorkPackage, wp);
		setWorkPackage(wp);
		const allClone = [ ...allPackages];
		allClone.push(wp);
		setAllPackages(allClone);
		SelectPackage(wp.Id);
	}

	const EditPackageName = async (newName: string) => {
		const clone = {...workPackage};
		clone.Name = newName;
		await Plugin.Invoke<boolean>(PluginIDs.EditWorkPackageName, clone);
		setWorkPackage(clone);
		// update name in list
		const idx = allPackages.findIndex(_ => _.Id === clone.Id);
		allPackages[idx].Name = clone.Name;
		setAllPackages([...allPackages]);
	}

	const AddSubPackages = async (packageIds: Array<string>) => {
		const clone = {...workPackage};
		// clone.SubWorkPackages = clone.SubWorkPackages || [];
		// clone.SubWorkPackages.push(...packageIds);
		clone.SubWorkPackages = packageIds || [];
		await Plugin.Invoke<boolean>(PluginIDs.UpdateWorkPackageSubs, { Id: clone.Id, SubWorkPackages: clone.SubWorkPackages });
		setWorkPackage(clone);
		// update name in list
		const idx = allPackages.findIndex(_ => _.Id === clone.Id);
		allPackages[idx] = clone;
		setAllPackages([...allPackages]);
	}

	const DeleteCurrentPackage = async () => {
		await Plugin.Invoke<boolean>(PluginIDs.DeleteWorkPackage, workPackage.Id);
		const idx = allPackages.findIndex(_ => _.Id === workPackage.Id);
		allPackages.splice(idx,1);
		setWorkPackage(null);
		setAllPackages([...allPackages]);
	}

	const SelectPackage = (id: string) => {
		const wp = allPackages.find(_ => _.Id === id);
		if (wp == null) return;
		setWorkPackage(wp);
	}

	const RenderButtonsContainer = (): JSX.Element => {
		return 	<div className="workpackages-buttons">
					<div className="workpackages-icons">
						<TooltipHost content={language.WorkPackages.CreateNewPackage} >
							<IconButton iconProps={ { iconName: "Add" } } onClick={ CreateNewPackage } />
						</TooltipHost>
					</div>
					<div className="workpackages-selector">
					<TooltipHost content={language.WorkPackages.SelectPackageForEdit}>
						<Dropdown placeholder={language.Common.Select} selectedKey={workPackage?.Id} options={DropdownOptionEx.toDropdownOptions(allPackages, _ => _.Id, _ => _.Name, true)} onChange={(e, option, idx) => SelectPackage(option.key as string)} />
					</TooltipHost>
					</div>
				</div>
	}

	const OnTimelineChanged = useCallback((start: Date, end: Date) => {
		setDateRange({Start: start, End: end})
	}, [])

	return (<>
				<div className="top">
					{/* <WorkPackageTimeline OnTimeLineChanged={(start, end) => setDateRange({Start: start, End: end}) } /> */}
					<WorkPackageTimeline OnTimeLineChanged={OnTimelineChanged } />
				</div>
				<div id="bottom-main" className="bottom">
					<div className="tp-workpackages-settingscontainer">
						{ RenderButtonsContainer() }
						<div className="tp-workpackages-importcontainer">
							<WorkPackageSelector ButtonEnabled={workPackage != null} ImportCallback={AddSubPackages} WorkPackage={workPackage} />
						</div>
					</div>
				
					{ workPackage &&
						<WorkPackageEditor 
							WorkPackage={workPackage} 
							Start={dateRange.Start} 
							End={dateRange.End}
							EditNameCallback={newName => EditPackageName(newName)} 
							DeletePackageCallback={DeleteCurrentPackage}
							GotoPackage={SelectPackage}
						/>
					}
				</div>
				<WorkPackagesCoachmarks />
			</>);
}

export default WorkPackages;