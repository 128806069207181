import React, { memo, useCallback, useMemo } from 'react';
import { GridType } from '../../../../../../../../Entities/GridType';
import { language } from '../../../../../../../../Services/LocalizationService';
import { ProjectListItem, RootSettings, UIStartupDto } from '../../../../../../../../api/generated/data-contracts';
import { IconButton, Spinner, Stack, PersonaCoin, Text, SpinnerSize, Label, Icon } from '@fluentui/react';
import { PanelUtil } from '../../../../../../../../Utilities/PanelUtil';
import { JsonEx } from '../../../../../../../../Utilities/JsonEx';
import { useMutation } from 'react-query';
import { ApiCalls } from '../../../../../../../../api/api';
import { OverviewContext } from '../../../../../../../../Utilities/Context/OverviewContext';
import { useStore } from '../../../../../../../../context/store';
import { EntityType } from '../../../../../../../../Entities/EntityTypes';
import { NavigateFunction } from 'react-router';
import { changeCase } from '../../../../../../../../helpers/changeKeysCase';
import { ProjectType } from '../../../../../../../../Entities/ProjectType';
import { EventEx } from '../../../../../../../../Utilities/EventEx';
import { ProjectChangesUtil } from '../../../../../../../../Utilities/ProjectChangesUtil';
import { Glimmer } from '../../../../../../../../Utilities/Glimmer';
import { ProjectListItemPercentageBar } from '../../../../../../../../Components/Common/ProjectListItemPercentageBar';
import { DateEx } from '../../../../../../../../Utilities/DateEx';
import Highlighter from 'react-highlight-words';
import shallow from 'zustand/shallow';
import { buildRoute } from '../../../../../../../../helpers/routeHelpers';

const getProjectText = (projectType: ProjectType) => {
    switch (projectType) {
        case ProjectType.Administrative: {
            return 'Admin';
        }
        case ProjectType.Team: {
            return 'Team';
        }
        case ProjectType.Default:
        default: {
            return 'Default';
        }
    }
};

const GetPickerFields = (settings: RootSettings, gridType: GridType) => {
    return settings.projectPickerDisplayFields;
};

const highlightStyle = { background: 'yellow', padding: 0 };

export const ListItem = memo(
    ({
        item,
        gridType,
        settings,
        // delegationMode,
        updateItem,
        navigate,
        pathname,
        setIsDragging,
        closeProjectPicker,
        setTimelineStart,
        uiSettings,
        showPercentage,
        searchText,
    }: {
        // delegationMode: boolean;
        showPercentage: boolean;
        item: ProjectListItem;
        settings: RootSettings;
        gridType: GridType;
        updateItem: (resource: ProjectListItem) => void;
        navigate: NavigateFunction;
        pathname: string;
        setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
        setTimelineStart: (date: Date) => void;
        closeProjectPicker: () => void;
        uiSettings: UIStartupDto;
        searchText: string;
    }) => {
        const initials = useMemo(() => {
            const fieldMap = GetPickerFields(settings, gridType);
            const initials = (item.name || '').match(/\b\w/g);
            const text = PanelUtil.getPickerPropertyValue(item, fieldMap, _ => _.iconOverride);
            if (fieldMap.iconOverride) {
                if (fieldMap.iconOverride === 'projectType') {
                    return getProjectText(item.projectType);
                }
                if (text === undefined) {
                    return ' ';
                }
                return text;
            }
            return ((initials?.shift() ?? '') + (initials?.pop() ?? '')).toUpperCase();
        }, [gridType, item, settings]);

        const { secondaryText, tertiaryText, text } = useMemo(() => {
            let thirdLine =
                item.projectType === ProjectType.Administrative
                    ? item.rbsAsString || language.Projects.TertiaryText.All
                    : item.projectType === ProjectType.Team
                    ? language.Projects.TertiaryText.Team
                    : PanelUtil.getPickerPropertyValue(item, settings.projectPickerDisplayFields, _ => _.tertiary) || '';

            const quarternary = PanelUtil.getPickerPropertyValue(item, settings.projectPickerDisplayFields, _ => _.quarternary);
            if (thirdLine && quarternary) {
                thirdLine += ' - ' + quarternary;
            } else if (quarternary) {
                thirdLine = quarternary;
            }

            return {
                // text: PanelUtil.getPickerPropertyValue(item, settings.projectPickerDisplayFields, _ => capitalize(_.title)) || '',
                text: PanelUtil.getPickerPropertyValue(item, settings.projectPickerDisplayFields, _ => _.title) || '',
                secondaryText: PanelUtil.getPickerPropertyValue(item, settings.projectPickerDisplayFields, _ => _.secondary) || '',
                tertiaryText: thirdLine,
            };
        }, [item, settings]);

        // TODO make this right once serialization is normalized and stable
        const serializedItem: any = useMemo(() => ({ ...changeCase.toPascal(item), Id: undefined, id: item.id }), [item]);

        const dragProps = useMemo(() => {
            return gridType === GridType.LmCapacityProjects
                ? {
                      onDragStart: (event: React.DragEvent<HTMLElement>) => {
                          event.dataTransfer.setData('projectlistitem', JsonEx.stringify(serializedItem));
                          event.dataTransfer.effectAllowed = 'all';
                          if (item.typeName !== 'Project') return;
                          Array.from(
                              document.querySelectorAll<HTMLDivElement>(`.tp-list-capacity-dropzone-overlay.tp-lmcapacity-projectdrop`),
                              _ => (_.style.display = 'block'),
                          );
                          setIsDragging(true);
                      },
                      onDragEnd: () => {
                          Array.from(document.querySelectorAll<HTMLDivElement>('.tp-list-capacity-dropzone-overlay'), _ => (_.style.display = 'none'));
                          setIsDragging(false);
                      },
                      draggable: true,
                  }
                : {};
        }, [gridType, item.typeName, serializedItem, setIsDragging]);

        const { mutate: toggleFavourite, isLoading: isLoadingToggleFavourite } = useMutation(
            () => ApiCalls.toggleFavourtie({ favouriteProject: item.id }).then(res => res.data),
            {
                onSuccess: success => {
                    updateItem({ ...item, favourite: !item.favourite });
                },
            },
        );

        const handleToggleFavourite = useCallback(
            (e: React.MouseEvent<any>) => {
                EventEx.preventAndStop(e);
                toggleFavourite();
            },
            [toggleFavourite],
        );

        const { entityCalloutInfo, setEntityCalloutInfo } = useStore(
            store => ({
                setEntityCalloutInfo: store.setEntityCalloutInfo,
                entityCalloutInfo: store.entityCalloutInfo,
            }),
            shallow,
        );

        const onClickCallout = useCallback(
            (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement>) => {
                EventEx.preventAndStop(e);
                if (entityCalloutInfo) {
                    setEntityCalloutInfo(null);
                } else {
                    setEntityCalloutInfo({
                        Id: item.id,
                        EntityType: EntityType.Project,
                        Target: `#detailicon-${item.id}`,
                        Properties: { Url: item.url },
                    });
                }
            },
            [entityCalloutInfo, item.id, item.url, setEntityCalloutInfo],
        );

        const handleProjectClick = useCallback(() => {
            if (pathname.startsWith('/planner')) {
                return;
            }
            // const link = `/${gridType == GridType.Allocation ? 'allocation' : 'request'}/${item.id}`;
            const link = buildRoute(gridType === GridType.Allocation ? 'allocation' : 'request', item.id);
            closeProjectPicker();
            navigate(link);
        }, [closeProjectPicker, gridType, item.id, navigate, pathname]);

        const coinSize = 65;

        return (
            <Stack data-panelitemid={item.id} className="tp-list-item noselect" {...dragProps} onClick={handleProjectClick}>
                <Stack
                    id={`pr_${item.id}`}
                    className="tp-list-item-resource-container"
                    horizontal
                    horizontalAlign="space-between"
                    tokens={{ padding: '8px 0 5px 0' }}
                >
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack tokens={{ padding: '5px 10px 0 5px' }}>
                            <Stack
                                styles={{ root: { position: 'relative' } }}
                                id={`persona-project-${item.id}`}
                                onMouseEnter={() => {
                                    setEntityCalloutInfo({
                                        Id: item.id,
                                        EntityType: EntityType.Project,
                                        Target: `#persona-project-${item.id}`,
                                        Properties: { Url: item.url },
                                    });
                                }}
                                onMouseLeave={() => {
                                    setEntityCalloutInfo(null);
                                }}
                            >
                                <PersonaCoin
                                    text={item.name}
                                    imageInitials={initials}
                                    coinSize={coinSize}
                                    styles={{ coin: { '.ms-Persona-initials': { borderRadius: 0 } } }}
                                />
                                {isLoadingToggleFavourite && (
                                    <Spinner
                                        styles={{
                                            root: { height: coinSize, width: coinSize, transform: 'scale(2.6)', position: 'absolute', top: -1, left: -1 },
                                        }}
                                        size={SpinnerSize.large}
                                    />
                                )}
                                <ProjectTypeIcon projectType={item.projectTypeAsString} />
                            </Stack>
                        </Stack>
                        <Stack>
                            {!ProjectChangesUtil.hasSeenChangesProject(item.id, item.lastModifiedByOthers as unknown as Date) &&
                                Glimmer.ShowGlimmer(gridType, uiSettings.resource.userTypes) && (
                                    <IconButton
                                        title={language.Projects.ProjectHasUnseenChanges}
                                        id={`changesicon-${item.id}`}
                                        iconProps={{ iconName: 'Glimmer' }}
                                        className="tp-projects-changes-icon"
                                    />
                                )}
                            <Text className="tp-list-item-resource-name" variant="large" nowrap={false}>
                                {text ? (
                                    //
                                    <Highlighter searchWords={[searchText]} textToHighlight={text} highlightStyle={highlightStyle} autoEscape />
                                ) : (
                                    <>&nbsp;</>
                                )}
                            </Text>
                            <Text variant="medium">
                                {secondaryText ? (
                                    <Highlighter searchWords={[searchText]} textToHighlight={secondaryText} highlightStyle={highlightStyle} autoEscape />
                                ) : (
                                    <>&nbsp;</>
                                )}
                            </Text>
                            <Text variant="medium">
                                {tertiaryText ? (
                                    <Highlighter searchWords={[searchText]} textToHighlight={tertiaryText} highlightStyle={highlightStyle} autoEscape />
                                ) : (
                                    <>&nbsp;</>
                                )}
                            </Text>
                        </Stack>
                    </Stack>
                    <Stack className="tp-list-item-project-icon-container">
                        <IconButton
                            id={`favouriteicon-${item.id}`}
                            iconProps={{ iconName: item.favourite ? 'UnPin' : 'Pinned' }}
                            title={item.favourite ? 'Remove from favorite projects' : 'Add to favorite projects'}
                            className="tp-projects-favourite-icon"
                            onClick={handleToggleFavourite}
                            disabled={isLoadingToggleFavourite}
                        />
                        <IconButton
                            id={`detailicon-${item.id}`}
                            iconProps={{ iconName: 'Info' }}
                            className="tp-projects-details-icon"
                            onClick={onClickCallout}
                            title="View details"
                        />
                        {(gridType === GridType.LmCapacityProjects || gridType === GridType.ResourcePlanner) && (
                            <IconButton
                                iconProps={{ iconName: 'CirclePlus', color: 'green' }}
                                className="tp-list-item-projects-add"
                                onClick={() => OverviewContext.AddProject(serializedItem)}
                                title={'Add project'}
                            />
                        )}
                        {item.firstUnfullfilled && (
                            <IconButton
                                //
                                id={`unfullfilledicon-${item.id}`}
                                iconProps={{ iconName: 'Lightbulb' }}
                                className="tp-projects-lightbulb-icon"
                                title={DateEx.asUserLocal(item.firstUnfullfilled as unknown as Date)}
                                onClick={e => {
                                    EventEx.preventAndStop(e);

                                    // this.props.Store.Set({ ProjectId: item.id }); // TODO Is this needed ?
                                    setTimelineStart(item.firstUnfullfilled as unknown as Date);
                                    handleProjectClick();
                                }}
                            />
                        )}
                        {!settings.hideAlignStatusOnProjectPanel &&
                            settings.enableAlignContract &&
                            settings.alignContractStatuses.length > 0 &&
                            item.alignStatus && (
                                <IconButton
                                    id={`alignedicon-${item.id}`}
                                    iconProps={{ iconName: 'TaskLogo' }}
                                    styles={{ root: { color: `${item.alignStatus.color} !important` } }}
                                    className="tp-projects-align-icon"
                                    title={item.alignStatus.name}
                                />
                            )}
                        {settings.enableScenarios && item.scenariosAvailable && (gridType === GridType.Request || gridType === GridType.Allocation) && (
                            <div className="tp-projects-scanario-btn-container">
                                <IconButton
                                    iconProps={{
                                        iconName: 'DocumentSet',
                                    }}
                                    title={'Scenarios available'}
                                    onClick={e => {
                                        EventEx.preventAndStop(e);
                                        return false;
                                    }}
                                />
                            </div>
                        )}
                    </Stack>
                </Stack>
                <div style={{ marginLeft: 64, marginRight: 8 }}>
                    {item.projectType === ProjectType.Administrative ? (
                        <Label styles={{ root: { paddingBottom: 0, paddingTop: 2, paddingLeft: 27 } }}>Administrative</Label>
                    ) : (
                        showPercentage && (
                            <ProjectListItemPercentageBar
                                Percentage={item.requestsFulfilled}
                                CategoryPercentage={item.requestsFulfilledWithCategory}
                                IsZeroActivityProject={
                                    item.firstUnfullfilled === null && item.requestsFulfilled === 100 && item.requestsFulfilledWithCategory === 0
                                }
                            />
                        )
                    )}
                </div>
            </Stack>
        );
    },
);

const ProjectTypeIcon = ({ projectType }: { projectType?: string }) => {
    const iconName = useMemo(() => {
        switch (projectType) {
            case 'Administrative': {
                return 'CalendarSettings';
            }
            case 'Team': {
                return 'Teamwork';
            }
            case 'Default':
            default: {
                return 'IssueTracking';
            }
        }
    }, [projectType]);
    return (
        <Stack
            styles={{
                root: {
                    position: 'absolute',
                    right: 0,
                    bottom: -15,
                    backgroundColor: '#ececec',
                    width: 33,
                    height: 33,
                    border: '1px solid #fff',
                },
            }}
            horizontalAlign="center"
            verticalAlign="center"
        >
            <Icon iconName={iconName} styles={{ root: { fontSize: 20 } }} />
        </Stack>
    );
};
