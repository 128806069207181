import { ContractInfoDto } from "../../../../../Entities/Dto/ContractInfoDto";
import { language } from "../../../../../Services/LocalizationService";
import { DateEx } from "../../../../../Utilities/DateEx";
import { StringEx } from "../../../../../Utilities/StringEx";
import { IWizardContext } from "../../../../Common/Wizard/StepWizard";

const validateForNull = (value: any, errorMessage: string, errorHandler: (msg: string) => void) : boolean => {
	if (value != null && value !== "" && value !== 0) return true;
	errorHandler(errorMessage);
	return false;
}

export const validateStep1 = (errorHandler: (msg: string) => void) => (dto: ContractInfoDto, ctx: IWizardContext) : boolean => {
	const resourceValidation = validateForNull(dto.ResourceId, language.FindAvailableCapacityDialog.ResourceMustBeSpecified, errorHandler);
	// TODO: only for LMs
	const nameValidation = validateForNull(dto.ContractName, language.FindAvailableCapacityDialog.ContractNameMustBeSpecified, errorHandler);
	return resourceValidation && nameValidation;
}

export const validateStep2 = (errorHandler: (msg: string) => void) => (dto: ContractInfoDto, ctx: IWizardContext) : boolean => {
	const startValidation = validateForNull(dto.Start, language.FindAvailableCapacityDialog.StartMustBeSpecified, errorHandler);
	const endValidation = ctx.getPropertyValue("action") === "distribute" ? validateForNull(dto.End, language.FindAvailableCapacityDialog.EndMustBeSpecified, errorHandler) : true;
	const workValidation = validateForNull(dto.Work, language.FindAvailableCapacityDialog.WorkMustBeSpecified, errorHandler);

	const startEndValidation = DateEx.isNullOrMin(dto.End) || new Date(dto.End) > new Date(dto.Start);
	if (!startEndValidation) {
		errorHandler(language.Common.EndGreaterThanStart)
	}
	const freezePeriodValidation = ContractInfoDto.ValidateStart(dto, "Start");
	if (!freezePeriodValidation) {
		errorHandler(language.ContractInfoDto.StartGreaterThanFreezePeriod)
	}
	const earliestValidation = dto.EarliestStart == null || ContractInfoDto.ValidateStart(dto, "EarliestStart");
	if (!earliestValidation) {
		errorHandler(language.ContractInfoDto.EarliestStartGreaterThanFreezePeriod)
	}
	const latestValidation = dto.LatestEnd == null || new Date(dto.LatestEnd) > new Date(dto.EarliestStart);
	if (!latestValidation) {
		errorHandler(language.ContractInfoDto.LatestEndGreaterThanEarliestStart)
	}
	return startValidation && endValidation && workValidation && startEndValidation && freezePeriodValidation && earliestValidation && latestValidation;
}

export const validateStep3 = (errorHandler: (msg: string) => void) => (dto: ContractInfoDto, ctx: IWizardContext) : boolean => {
	if (dto.UseScenario) {
		
		const scenarioValidation = dto.Scenario != null || !StringEx.isNullOrWhiteSpace(dto.ScenarioName);
		if (!scenarioValidation) {
			errorHandler(language.FindAvailableCapacityDialog.ScenarioNotFilledOut)
		}
		return scenarioValidation;
	}
	return true;
}