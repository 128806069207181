export default class Locale_Maintenance { 
	HeaderLabel: string = "When enabling maintenance, only admins and superusers are allowed accessing Team Planner. (A dialog will be displayed to regular users, trying to access Team PLanner)";
	TextField = {
		NotificationTitleMaintenanceBegins: "Notification title when maintenance begins",
		NotificationMessageMaintenanceBegins: "Notification message when maintenance begins",
		NotificationTitleMaintenanceEnds: "Notification title when maintenance ends",
		NotificationMessageMaintenanceEnds: "Notification message when maintenance ends"
	}
	Notification = {
		MaintenanceCapitalized: "Maintenance",
		Started: "started",
		Stopped: "stopped",
		Failed: "Failed",
		MaintenanceExclaimed: "maintenance!",
		Maintenance: "maintenance"
	}
}