/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, PropsWithChildren } from 'react';
import { NormalPeoplePicker, IBasePickerSuggestionsProps, IPersonaProps, IPersonaStyles, PeoplePickerItemSuggestion } from '@fluentui/react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { EventEx } from '../../../Utilities/EventEx';
import { LocalStorage } from '../../../Utilities/LocalStorage';
import { ArrayEx } from '../../../Utilities/ArrayEx';
import { language } from '../../../Services/LocalizationService';
import { Timeout } from '../../../types/runtimeTypes';

interface IProps<TEntity> extends IDynamicEditorComponentProps { 
    Item: string | Array<string>;
    Update: (value: string | Array<string>) => void;
    Key?: string;
	PickerOptions?: IPickerOptions<TEntity>;
	PickerOptionsGetter?: () => Promise<IPickerOptions<TEntity>>;
	styles?: any;
}

export interface IPickerItem extends IPersonaProps {
	Id: string;
	DisplayName: string;
	Description: string;
}

export interface IPickerOptions<TEntity> {
	ItemParser: (item: TEntity) => IPickerItem;
	EntityGetter: (item: any) => Promise<Array<TEntity>>;
	RecentItemsKey: string;
	MultiSelect?: boolean;
	ItemLimit?: number;

	Suggestions: IPickerSuggestionsOptions;
}

const personaStyles: Partial<IPersonaStyles> = {
	root: {
	  	height: 'auto',
	},
	secondaryText: {
		height: 'auto',
		whiteSpace: 'normal',
	},
	primaryText: {
		height: 'auto',
		whiteSpace: 'normal',
	},
};

export interface IPickerSuggestionsOptions {
	SuggestionsHeaderText: string;
	NoResultsFound: string;
	LoadingText: string;
	ShowRemoveButtons: boolean;
	SuggestionsAvailableAlertText: string;
	SuggestionsContainerText: string;
}

const GenericPicker: <TEntity>(props: PropsWithChildren<IProps<TEntity>>) => React.ReactElement = props => {

	const [pickerProps, setPickerProps] = useState<IPickerOptions<any>>()
    const [items, setItems] = useState<Array<IPickerItem>>();
	const [selected, setSelected] = useState(new Array<IPickerItem>());
	const [recentlyUsedIds, setRecentlyUsedIds] = useState<Array<string>>([]);

	// const isMounted = useIsMounted();

	useEffect(() => setPickerProps(props.PickerOptions), [props.PickerOptions]);
	useEffect(() => {
		if (props.PickerOptionsGetter == null) return;
		const fetch = async () => {
			const pp = await props.PickerOptionsGetter();
			setPickerProps(pp);
		};
		fetch();
	}, [props.PickerOptionsGetter]);

    useEffect(() => {
		if (pickerProps == null) return;

        const fetchAndParseEntities = async () => {
            const entities = await pickerProps.EntityGetter(props.Parent);
			const allItems = entities.map(_ => pickerProps.ItemParser(_));

			if (props.Item) {
				let items: Array<IPickerItem> = [];
				if (pickerProps.MultiSelect) {
					items = allItems.filter(_ =>  (props.Item as Array<string>).some(i => i.toLowerCase() === _.Id.toLowerCase()));
				} else {
					const item = allItems.find(_ => _.Id.toLowerCase() === (props.Item as string).toLowerCase());
					items = item ? [item] : []
				}

				let defaultSelected: Array<IPickerItem>;
				if (items.length > 0)
					defaultSelected = items;

				if (props.Item)
					setSelected(defaultSelected);
			}
			setItems(allItems);
			const recentResources = LocalStorage.get<Array<string>>(pickerProps.RecentItemsKey) || [];
			SetRecentlyUsedGroups(recentResources, allItems);
        };
		fetchAndParseEntities();
    }, [pickerProps]);

    const filterItemsByText = (filterText: string): IPickerItem[] => {
		return items.filter(item => (item.DisplayName && item.DisplayName.search(new RegExp(filterText, 'i')) > -1)
										|| (item.Id && item.Id.search(new RegExp(filterText, 'i')) > -1)
										|| (item.Description && item.Description.search(new RegExp(filterText, 'i')) > -1));
	}
	
	let _timer: Timeout;
    const onFilterChanged = (filterText: string, currentPersonas: IPickerItem[], limitResults?: number): PromiseLike<IPickerItem[]> => {
		const item = EventEx.delayAndReturn(_timer, () => {
			if (filterText) {
				let filteredPersonas: IPickerItem[] = filterItemsByText(filterText);
        
            filteredPersonas = limitResults ? filteredPersonas.splice(0, limitResults) : filteredPersonas;
            return filteredPersonas;
			} else {
				return [];
			}
		}, 600);
		_timer = item.timer;
		return item.result;
    };

    const getTextFromItem = (persona: IPickerItem): string => {
        return persona.DisplayName as string;
    }

    const onItemsChange = (items: IPickerItem[]): void => {
        setSelected(items);
        if (pickerProps.MultiSelect)
            props.Update(items.map(_ => _.Id));
        else
            props.Update(items.length === 0 ? null : items[0].Id);
    }

    const BuildSuggestionProps = () : IBasePickerSuggestionsProps => {
		return {
			suggestionsHeaderText: pickerProps.Suggestions.SuggestionsHeaderText,
			mostRecentlyUsedHeaderText: pickerProps.Suggestions.SuggestionsHeaderText,
			noResultsFoundText: pickerProps.Suggestions.NoResultsFound,
			loadingText: pickerProps.Suggestions.LoadingText,
			showRemoveButtons: pickerProps.Suggestions.ShowRemoveButtons,
			suggestionsAvailableAlertText: pickerProps.Suggestions.SuggestionsAvailableAlertText,
			suggestionsContainerAriaLabel: pickerProps.Suggestions.SuggestionsContainerText,
		} as IBasePickerSuggestionsProps;
	}
	
	const returnMostRecentlyUsed = (currentPersonas: IPickerItem[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
		const displayIds = recentlyUsedIds.filter(_ => currentPersonas.findIndex(s => s.Id === _) < 0)
		const recent = items.filter(_ => displayIds.findIndex(d => d === _.Id) >= 0);
		ArrayEx.sortByArray(recent, (item: IPickerItem) => item.Id, displayIds.reverse());
		return recent;
	}

	const updateRecentlyUsed = (selectedItem?: IPickerItem) : IPickerItem => {
		if (!selectedItem) return null;
		const recent = [...recentlyUsedIds];
		recent.push(selectedItem.Id);
		// restrict to 10 recently used resources
		if (recent.length > 10) recent.shift();
		SetRecentlyUsedGroups(recent, items, true);
		return selectedItem;
	}

	const SetRecentlyUsedGroups = (recentIds: Array<string>, allResources: Array<IPickerItem>, updateCache?: boolean) : void => {
		setRecentlyUsedIds(recentIds);
		if (updateCache)
			LocalStorage.set(pickerProps.RecentItemsKey, recentIds);
	}

	const RemoveRecentlyUsedItem = (item: IPickerItem) => {
		const recentResources = LocalStorage.get<Array<string>>(pickerProps.RecentItemsKey) || [];
		const index = recentResources.findIndex(_ => _ === item.Id);
		if (index < 0) return;
		recentResources.splice(index, 1);
		LocalStorage.set(pickerProps.RecentItemsKey, recentResources);
	}

	const OnRenderSuggestionItem = (personaProps: IPersonaProps, suggestionsProps: IBasePickerSuggestionsProps) => {
		return (
			<PeoplePickerItemSuggestion
				personaProps={{ ...personaProps, styles: personaStyles }}
				suggestionsProps={suggestionsProps}
			/>
		);
	};
 
    return  <> { pickerProps &&
					<NormalPeoplePicker
						itemLimit={pickerProps.MultiSelect ? (pickerProps.ItemLimit) : 1}
						onResolveSuggestions={onFilterChanged}
						onEmptyInputFocus={returnMostRecentlyUsed}
						onItemSelected={updateRecentlyUsed}
						getTextFromItem={getTextFromItem}
						pickerSuggestionsProps={BuildSuggestionProps()}
						className={'ms-PeoplePicker'}
						onRemoveSuggestion={RemoveRecentlyUsedItem}
						removeButtonAriaLabel={language.Common.Remove}
						onChange={onItemsChange}
						selectedItems={selected}
						disabled={props.ReadOnly || !items}
						onRenderSuggestionsItem={OnRenderSuggestionItem}
						styles={props.styles ?? null}
					/>
				}
            </>
}

export default GenericPicker;