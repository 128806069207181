import React, { useEffect } from 'react';
import classNames from 'classnames';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';


import styles from './Item.module.scss';
import { AnimationClassNames, Checkbox } from '@fluentui/react';
import { Remove } from './components/Remove/Remove';
import { Handle } from './components/Handle/Handle';

export interface Props {
    dragOverlay?: boolean;
    color?: string;
    disabled?: boolean;
    dragging?: boolean;
    handle?: boolean;
    handleProps?: any;
    height?: number;
    index?: number;
    fadeIn?: boolean;
    transform?: Transform | null;
    listeners?: DraggableSyntheticListeners;
    sorting?: boolean;
    style?: React.CSSProperties;
    transition?: string | null;
    wrapperStyle?: React.CSSProperties;
    value: React.ReactNode;
    onRemove?(): void;
    // renderItem?(args: {
    //     dragOverlay: boolean;
    //     dragging: boolean;
    //     sorting: boolean;
    //     index: number | undefined;
    //     fadeIn: boolean;
    //     listeners: DraggableSyntheticListeners;
    //     ref: React.Ref<HTMLElement>;
    //     style: React.CSSProperties | undefined;
    //     transform: Props['transform'];
    //     transition: Props['transition'];
    //     value: Props['value'];
    // }): React.ReactElement;
    onItemSelect?: (key: string, state: boolean) => void;
    checked?: boolean;
    id?: string;
    showOrder?: boolean;
}

export const Item = React.memo(
    React.forwardRef<HTMLLIElement, Props>(
        (
            {
                color,
                dragOverlay,
                dragging,
                disabled,
                fadeIn,
                handle,
                handleProps,
                height,
                index,
                listeners,
                onRemove,
                // renderItem,
                sorting,
                style,
                transition,
                transform,
                value,
                wrapperStyle,
                checked,
                onItemSelect,
                id,
                showOrder = true,
                ...props
            },
            ref,
        ) => {
            useEffect(() => {
                if (!dragOverlay) {
                    return;
                }

                document.body.style.cursor = 'grabbing';

                return () => {
                    document.body.style.cursor = '';
                };
            }, [dragOverlay]);

            return (
                <li
                    className={classNames(
                        //
                        styles.Wrapper,
                        fadeIn && styles.fadeIn,
                        sorting && styles.sorting,
                        dragOverlay && styles.dragOverlay,
                    )}
                    style={
                        {
                            ...wrapperStyle,
                            transition: [transition, wrapperStyle?.transition].filter(Boolean).join(', '),
                            '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
                            '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
                            '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
                            '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
                            '--index': index,
                            '--color': color,
                        } as React.CSSProperties
                    }
                    ref={ref}
                >
                    <div
                        className={classNames(
                            styles.Item,
                            dragging && styles.dragging,
                            handle && styles.withHandle,
                            dragOverlay && styles.dragOverlay,
                            disabled && styles.disabled,
                            color && styles.color,
                        )}
                        style={style}
                        // data-cypress="draggable-item"
                        {...(!handle ? listeners : undefined)}
                        {...props}
                        tabIndex={!handle ? 0 : undefined}
                    >
                        {showOrder && (
                            <div className={styles.OrderBadge}>
                                <span>{index + 1}</span>
                            </div>
                        )}
                        {/* <Text styles={{root: {marginRight: 10, display: "flex"}}}>{index}</Text> */}
                        <Checkbox checked={checked} onChange={(e, val) => onItemSelect?.(id, Boolean(val))} />
                        {value}
                        <span className={styles.Actions}>
                            {onRemove ? <Remove className={styles.Remove} onClick={onRemove} /> : null}
                            {handle && checked ? <Handle {...handleProps} className={AnimationClassNames.fadeIn100} {...listeners} /> : null}
                        </span>
                    </div>
                </li>
            );
        },
    ),
);
