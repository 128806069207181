import * as React from 'react';
import 'reflect-metadata';
import { QueryGroup } from '../../../Entities/DynamicQueryBuilder/QueryGroup';
import { PrimaryButton } from '@fluentui/react';
import { PFDialog } from '../PFDialog';
import DynamicEditorComponent, { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import EntityPropertyFilter from '../EntityPropertyFilter';
import { ObjectEx } from '../../../Utilities/ObjectEx';
import { Resource } from '../../../Entities/Main/Resource';

interface IProps<T extends object> extends IDynamicEditorComponentProps {
    Item: QueryGroup;
    Update: (group: QueryGroup) => void;
    ItemType: { new (): T };
    ItemTypeAsString?: string; // this is ONLY for allowing the resource form to co-exist while changing to the new forms (ewi)
    DialogTitle: string;
    DialogTitleGetter: () => string;
    ButtonText?: string;
    FilterSystemProperties?: boolean;
	ForcedIncludedFields?: string[];
}

interface IState {
    Item: QueryGroup;
    OriginalItem: QueryGroup;
    EditMode: boolean;
}

export default class EntityFilterEditor<T extends object> extends DynamicEditorComponent<IProps<T>, IState> {
    constructor(props: IProps<T>) {
        super(props);
        this.state = {
            EditMode: false,
            Item: ObjectEx.deepCopy(props.Item) ?? new QueryGroup(),
            OriginalItem: ObjectEx.deepCopy(props.Item) ?? new QueryGroup(),
        };
    }

    // this is ONLY for allowing the resource form to co-exist while changing to the new forms (ewi)
    private getItemType = (): any => {
        if (this.props.ItemTypeAsString) {
            switch (this.props.ItemTypeAsString) {
                case 'Resource':
                    return Resource;
                default:
                    break;
            }
        } else return this.props.ItemType;
    };

    componentWillReceiveProps(nextProps: IProps<T>) {
        if (!nextProps.Item) return;
        this.setState({ Item: nextProps.Item });
    }

    render() {
        return (
            <div>
                <PrimaryButton text={this.props.ButtonText ?? 'Edit filter'} onClick={() => this.setState({ EditMode: true })} />
                {this.state.EditMode && (
                    <PFDialog
                        title={this.props.DialogTitle ?? this.props.DialogTitleGetter()}
                        maxWidth={1500}
                        showDialog={this.state.EditMode}
                        callback={() => {
                            this.props.Update(this.state.Item);
                            this.setState({ EditMode: false, OriginalItem: ObjectEx.deepCopy(this.state.Item) });
                        }}
                        dismissCallback={() => {
                            this.props.Update(ObjectEx.deepCopy(this.state.OriginalItem));
                            this.setState({ EditMode: false, Item: ObjectEx.deepCopy(this.state.OriginalItem) });
                        }}
                        buttonDefaultDisabled={this.props.ReadOnly}
                    >
                        <EntityPropertyFilter
                            Item={{ ...this.state.Item }}
                            PropertyTypes={'Both'}
                            EntityType={this.getItemType()}
                            Update={group => this.setState({ Item: group })}
                            FilterSystemProperties={this.props.FilterSystemProperties == null ? true : this.props.FilterSystemProperties}
                            FilterArrayProperties
							ForcedIncludedFields={this.props.ForcedIncludedFields}
                            Readonly={this.props.ReadOnly}
                        />
                    </PFDialog>
                )}
            </div>
        );
    }
}
