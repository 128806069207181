import { FunctionComponent, useState, useEffect } from 'react';
import { PFDialog } from './PFDialog';
import { BulkEditor } from './BulkEditor';
import { ObjectEx } from '../../Utilities/ObjectEx';
import { language } from '../../Services/LocalizationService';

interface IProps {
    ItemType: { new (): any };
    Items: Array<any>;
    HiddenProperties: Array<string>;
    Update: (items: Array<any>) => void;
    Dismiss: () => void;
}

export const ListBulkEditor: FunctionComponent<IProps> = props => {
    const [items, setItems] = useState<Array<any>>(props.Items);
    const [okEnabled, setOkEnabled] = useState<boolean>(false);

    useEffect(
        () => () => {
            setItems(props.Items);
        },
        [props.Items],
    );

    const UpdateItems = (templateItem: any, properties: Array<string>): void => {
        const toChange = [...items];
        toChange.forEach(_ => {
            properties.forEach(p => {
                const value = ObjectEx.getNestedPropertyValueByString(templateItem, p);
                ObjectEx.setNestedPropertyValueByString(_, p, value);
            });
        });
        setItems(toChange);
    };

    return (
        <>
            <PFDialog
                buttonText={language.Common.Save}
                minWidth={500}
                maxWidth={800}
                showDialog={true}
                title={language.Common.BulkEdit}
                subText={language.ListBulkEditor.PropertiesToEdit}
                callback={() => props.Update(items)}
                buttonDefaultDisabled={true}
                dismissCallback={props.Dismiss}
                okButtonEnabled={okEnabled}
            >
                <BulkEditor
                    ItemType={props.ItemType}
                    HiddenProperties={props.HiddenProperties}
                    Changes={UpdateItems}
                    SelectCallback={enabled => setOkEnabled(enabled)}
                />
            </PFDialog>
        </>
    );
};
