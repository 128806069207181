import { useMemo } from 'react';
import { clamp, lerp, range } from '../../../../_grid/helpers/colors';

export const useHeatmapBackground = ({
    classicColors,
    maxOverAllocationPercent,
    maxUnderAllocationPercent,
    totalCapacity,
    value,
    colorSaturation,
    lightness,
    colorOffset = 0,
    invertSpectrum,
    equalAllocationColor,
    overAllocationColor,
    underAllocationColor,
}: {
    value: number;
    totalCapacity: number;
    classicColors: boolean;
    maxOverAllocationPercent: number;
    maxUnderAllocationPercent: number;
    colorSaturation?: number;
    lightness?: number;
    colorOffset?: number;
    invertSpectrum?: boolean;
    equalAllocationColor: string;
    overAllocationColor: string;
    underAllocationColor: string;
}) => {
    return useMemo(() => {
        if (classicColors) {
            if (value < 0) {
                return overAllocationColor;
			} else if (value === 0) {
				return equalAllocationColor;
            } else if (value > 0) {
                return underAllocationColor;
            }
        }
        return calcBackgroundColor({
            maxOverAllocationPercent,
            maxUnderAllocationPercent,
            totalCapacity,
            value,
            colorOffset,
            colorSaturation,
            invertSpectrum,
            lightness,
        });
    }, [
        classicColors,
        colorOffset,
        colorSaturation,
        equalAllocationColor,
        invertSpectrum,
        lightness,
        maxOverAllocationPercent,
        maxUnderAllocationPercent,
        overAllocationColor,
        totalCapacity,
        underAllocationColor,
        value,
    ]);
};

export const addPercent = (base: number, percent: number) => base * (percent / 100) + base;
const getHSLString = (deg: number, saturation = 90, lightness = 75) => `hsl(${deg.toFixed(0)}deg, ${saturation}%, ${lightness}%)`;
const calcDeg = (percent: number, invert?: boolean) => (invert ? clamp(lerp(0, 120, percent), 0, 120) : clamp(lerp(120, 0, percent), 0, 120));

export function calcBackgroundColor({
    maxOverAllocationPercent,
    maxUnderAllocationPercent,
    totalCapacity,
    value,
    colorSaturation,
    lightness,
    colorOffset = 0,
    invertSpectrum,
}: {
    value: number;
    totalCapacity: number;
    maxOverAllocationPercent: number;
    maxUnderAllocationPercent: number;
    colorSaturation?: number;
    lightness?: number;
    colorOffset?: number;
    invertSpectrum?: boolean;
}) {
    // 0deg = red
    // 60deg = yellow
    // 120deg = green

    // Over allocation
    if (value < 0) {
        const val = Math.abs(value);
        const maxOverAllocation = addPercent(totalCapacity, maxOverAllocationPercent);
        const ranged = range(0, maxOverAllocation, 0, 1, val);
        const deg = calcDeg(ranged, invertSpectrum);
        return getHSLString(deg + colorOffset, colorSaturation, lightness);
    }
    const val = value;
    const maxUnderAllocation = addPercent(totalCapacity, maxUnderAllocationPercent);
    const ranged = range(0, maxUnderAllocation, 0, 1, val);
    const deg = calcDeg(ranged, invertSpectrum);
    return getHSLString(deg + colorOffset, colorSaturation, lightness);
}
