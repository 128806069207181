import { useRecoilCallback } from 'recoil';
import { useStore } from '../../../../../../../../context/store';
import { useUISettings } from '../../../../../../../../context/network/http/QueryProvider/queries/UISettings';
import { language } from '../../../../../../../../Services/LocalizationService';
// import { ErrorMessageGetterWithRoundingValue } from '../../../../../../_grid/hooks/types';
import { createCellValueGetter } from '../../../../../../_grid/useGrid';
import { isOverAllocatedBigJs } from '../../../../../../../../helpers/math';
import { ErrorMessageGetterWithRoundingValue } from './types';


export const useCheckOverAllocationProjectPlanner = () => {
	const makeErrorNotification = useStore(store => store.addErrorNotification);
	const { data: uiSettings } = useUISettings();
	return useRecoilCallback(
		({ snapshot }): ErrorMessageGetterWithRoundingValue =>
			async ({ columnIndex, newCellValue, cellIndex, row, cell }) => {
				let errorMessage = undefined;
				if (!uiSettings.settings?.restrictOverAllocating) {
					return { errorMessage, changedValue: newCellValue };
				}
				
				const getCellValue = createCellValueGetter(snapshot);
				const currentCellValue = await getCellValue(cell);

				// Allow the user to gradually write down the value 
				if (newCellValue.lt(currentCellValue)) {
					return { errorMessage, changedValue: newCellValue };
				}
				
				const totalCell = row.columnRows[columnIndex].cells[cellIndex + 1];
				if (totalCell) {
					// return { errorMessage: language.CapacityViews.OverAllocation, changedValue: newCellValue };
					const freeCap = await getCellValue(totalCell);
					const maxValue = currentCellValue.plus(freeCap);
	
					if (isOverAllocatedBigJs(newCellValue, maxValue, currentCellValue)) {
						errorMessage = language.CapacityViews.OverAllocation;
						makeErrorNotification(errorMessage);
					}
				}
				return { errorMessage, changedValue: newCellValue };
			},
		[uiSettings.settings?.restrictOverAllocating, makeErrorNotification]
	);
};
