import { FunctionComponent } from 'react';
import { Callout, Persona, PersonaSize, Label, TooltipHost } from '@fluentui/react';
import { UserContext } from '../../Utilities/Context/UserContext';
import EnumEx from '../../Utilities/EnumEx';
import { UserType } from '../../Entities/UserType';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { UserSettings } from './UserSettings';
import { language } from '../../Services/LocalizationService';
import { useStore } from '../../context/store';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { LogoutButton } from './LogoutButton';

interface IProps {
    Visible: boolean;
}

export const UserDetails: FunctionComponent<IProps> = props => {
    const { data: uiSettings } = useUISettings();
    const { setUIContext } = useStore(store => ({
        setUIContext: store.setUIContext,
    }));

    return props.Visible ? (
        <Callout
            id="tp-currentuser-callout"
            className="tp-currentuser-callout"
            role="alertdialog"
            gapSpace={0}
            target={`#tp-topbar-currentuser`}
            onDismiss={() => setUIContext({ showCurrentUserInfo: false })}
            setInitialFocus={true}
            isBeakVisible={false}
            styles={{ calloutMain: { padding: 20 } }}
        >
            <Persona
                text={UserContext.Resource.Name}
                size={PersonaSize.size72}
                hidePersonaDetails={false}
                secondaryText={UserContext.Resource.Email}
                tertiaryText={EnumEx.asString(UserType, UserContext.Resource.UserTypes).join(', ')}
            />
            <div className="tp-userinfobox">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <Label>{`${language.UserDetails.Cycle}:`}</Label>
                            </td>
                            <td id="tp-release-cycle">
                                <Label>{uiSettings.version.cycle}</Label>
                            </td>
                            <td>
                                <p>&emsp;&emsp;</p>
                            </td>
                            <td>
                                <Label>{`${language.Common.Settings}:`}</Label>
                            </td>
                            <td id="tp-settings-name">
                                <Label>{OverviewContext.Settings.Name}</Label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Label>{`${language.UserDetails.Version}:`}</Label>
                            </td>
                            <td id="tp-app-version">
                                <Label>{uiSettings.version.app}</Label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr style={{ marginLeft: '20px', marginRight: '20px' }}></hr>
            </div>

            <div className="tp-actions">
                <div className="tp-action-container">
                    {Boolean(uiSettings.resource?.userTypes?.length) && (
                        <TooltipHost content={language.UserDetails.UserSettings} id="usersettingsbutton" calloutProps={{ gapSpace: 0 }}>
                            <UserSettings />
                        </TooltipHost>
                    )}
                    <LogoutButton />
                </div>
            </div>
        </Callout>
    ) : null;
};
