export class TPError {
    public Name: string;
    public Message: string;
    public StackTrace: string;
    public PluginId?: string;
    public CorrelationId: string;

    constructor() {}

    public TypeName: string = 'TPException';

    public static New = (name: string, message: string, stackTrace: string, pluginId?: string): TPError => {
        const error = new TPError();
        error.Name = name;
        error.Message = message;
        error.StackTrace = stackTrace;
        error.PluginId = pluginId;
        return error;
    };

	public AsString = () : string => {
		return `${new Date()}
		Exception: ${this.Name}
		Plugin: ${this.PluginId}
		Message: ${this.Message}
		StackTrace: ${this.StackTrace}
		CorrelationId: ${this.CorrelationId}`;
    };
}

export class TPError2 extends Error {
	public Name = 'ClientError';
    public Message: string;
    public StackTrace: string;
    public PluginId?: string;
    public CorrelationId: string;
	public TypeName: string = 'TPException';

    constructor(error?: any, Message?: string, StackTrace?: string, PluginId?: string) {
        super(Message, { cause: error });
        // this.Name = Name;
        this.Message = Message;
        this.StackTrace = StackTrace;
        this.PluginId = PluginId;
    }


    public static New = (name: string, message: string, stackTrace: string, pluginId?: string): TPError2 => {
        return new this(name, message, stackTrace, pluginId);
    };

    public Clone() {
        return TPError2.New(this.Name, this.Message, this.StackTrace, this.PluginId);
    }

    public AsString = (): string => {
        return `Exception: ${this.Name}
		Plugin: ${this.PluginId}
		Message: ${this.Message}
		StackTrace: ${this.StackTrace}
		CorrelationId: ${this.CorrelationId}`;
    };

    public toString() {
        return this.AsString();
    }

    public toJSON() {
        return JSON.stringify(this);
    }
}
