import { AnimationClassNames, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { getGridTypeFromRoute, GridType } from '../../Entities/GridType';
import { MainView, useRouteEnum } from '../../Entities/MainView';
import { UnitType } from '../../Entities/UnitType';
import { useControlledState } from '../../hooks/useControlledState';
import { useEvent } from '../../hooks/useEvent';
import { language } from '../../Services/LocalizationService';
import { useUnitType } from '../../Utilities/UnitTypeUtil';

export const UnitTypeToggler = () => {
    const { data: uiSetting } = useUISettings();
    const currentView = useRouteEnum();
    const {
        currentUnitType,
        setUnitTypeMutation: { isLoading, mutate, mutateAsync },
    } = useUnitType();
    const [unitType, setUnitType] = useControlledState(() => currentUnitType, [currentUnitType]);
    const [forceDisabled, setForceDisabled] = useState<boolean>(false);
    const [originalUnitType, setOriginalUnitType] = useState<UnitType>(currentUnitType);

    const { pathname } = useLocation();
    const isOnResourcePlanner = useMemo(() => pathname.startsWith('/resourceplanner'), [pathname]);
    const isOnProjectPlanner = useMemo(() => pathname.startsWith('/projectplanner'), [pathname]);
    const gridType = useMemo(() => getGridTypeFromRoute(pathname), [pathname]);
    const forcedOnView = useRef<MainView | null>(null);

    // const getTooltipText = useCallback()
    const tooltipText = useMemo(() => {
        const forceUnitTypeForRequest = uiSetting.settings.forceUnitTypeOnRequestView;
        const forceUnitTypeForAllocation = uiSetting.settings.forceUnitTypeOnAllocationView;
        if (currentView === MainView.WorkPackages && uiSetting.settings.unitType > 1) {
            // We don't know what an FTE is equivelant to so we force it to hours
            return language.UnitTypeToggler.UnitTypeLockedWhenEditingWorkPackages;
        } else if (currentView === MainView.ProjectCapacity && gridType === GridType.Request && forceUnitTypeForRequest != null) {
            return `${language.UnitTypeToggler.UnitTypeIsLockedTo} ${UnitType[forceUnitTypeForRequest].toString()}.`;
        } else if (currentView === MainView.ProjectCapacity && gridType === GridType.Allocation && forceUnitTypeForAllocation != null) {
            return `${language.UnitTypeToggler.UnitTypeIsLockedTo} ${UnitType[forceUnitTypeForAllocation].toString()}.`;
        }
        return '';
    }, [currentView, gridType, uiSetting.settings.forceUnitTypeOnAllocationView, uiSetting.settings.forceUnitTypeOnRequestView, uiSetting.settings.unitType]);

    useLayoutEffect(() => {
        // We should consider moving this logic somewhere else in the app
        // since UnitTypeToggler is conditionally rendered (see Navbar.tsx)
        // which means this won't run if it is not rendered.
        const handleForceUnitType = (newUnitType: UnitType, view: MainView, setOrigin = true) => {
            setForceDisabled(true);
            if (currentUnitType !== newUnitType) {
                mutate({ newUnitType, writeToPersistentCache: setOrigin });
                setUnitType(newUnitType);
                if (setOrigin) {
                    setOriginalUnitType(newUnitType);
                }
            }
            forcedOnView.current = view;
        };

        const forceUnitTypeForRequest = uiSetting.settings.forceUnitTypeOnRequestView;
        const forceUnitTypeForAllocation = uiSetting.settings.forceUnitTypeOnAllocationView;

        if (currentView === MainView.WorkPackages && uiSetting.settings.unitType > 1) {
            // We don't know what an FTE is equivelant to so we force it to hours
            handleForceUnitType(UnitType.Hours, MainView.WorkPackages, false);
        } else if (currentView === MainView.ProjectCapacity && gridType === GridType.Request && forceUnitTypeForRequest != null) {
            handleForceUnitType(forceUnitTypeForRequest, MainView.ProjectCapacity);
        } else if (currentView === MainView.ProjectCapacity && gridType === GridType.Allocation && forceUnitTypeForAllocation != null) {
            handleForceUnitType(forceUnitTypeForAllocation, MainView.ProjectCapacity);
        }
    }, [
        currentView,
        currentUnitType,
        gridType,
        uiSetting.settings.forceUnitTypeOnAllocationView,
        uiSetting.settings.forceUnitTypeOnRequestView,
        mutate,
        uiSetting.settings.unitType,
        setUnitType,
    ]);

    useLayoutEffect(() => {
        if (forcedOnView.current !== null && currentView !== forcedOnView.current) {
            mutate({ newUnitType: originalUnitType });
            setUnitType(originalUnitType);
            setForceDisabled(false);
            forcedOnView.current = null;
        }
    }, [currentView, mutate, originalUnitType, setUnitType]);

    const onChange = useEvent(() => {
        if (forceDisabled) return;
        const oldUnitType = unitType;
        const newUnitType = unitType === UnitType.FTE ? UnitType.Hours : UnitType.FTE;
        setUnitType(newUnitType);
        setOriginalUnitType(newUnitType);
        mutateAsync({ newUnitType })
            .then(success => {
                if (!success) {
                    // We treat this case as an error
                    throw new Error();
                }
            })
            .catch(() => {
                setUnitType(oldUnitType);
                setOriginalUnitType(oldUnitType);
            });
    });

    const tooltipContent = useMemo(
        () => (forceDisabled ? tooltipText : language.UnitTypeToggler.CurrentlyViewingUnitTypeClickToChange.replace('[[unittype]]', UnitType[unitType])),
        [forceDisabled, tooltipText, unitType],
    );

    const shouldRender = useMemo(() => {
        if (uiSetting.settings.unitType >= 2) {
            return (
                [
                    MainView.ProjectCapacity,
                    MainView.ResourceCapacity,
                    MainView.LmCapacity,
                    MainView.WorkPackages,
                    MainView.CompareScenariosCapacity,
                    MainView.DraftVersionedCapacity,
                ].some(_ => _ === currentView) ||
                isOnResourcePlanner ||
                isOnProjectPlanner
            );
        }
        return false;
    }, [currentView, isOnResourcePlanner, uiSetting.settings.unitType, isOnProjectPlanner]);

    if (!shouldRender) {
        return null;
    }

    return (
        <div className="tp-unittypetoggler">
            {isLoading && <Spinner className={'tp-unittypetoggler__loading-spinner ' + AnimationClassNames.fadeIn200} size={SpinnerSize.large} />}
            <TooltipHost content={tooltipContent} calloutProps={{ gapSpace: 11 }}>
                <input
                    className="tgl tgl-flip"
                    id="cb5"
                    type="checkbox"
                    checked={unitType === UnitType.Hours}
                    disabled={forceDisabled || isLoading}
                    onChange={onChange}
                />
                <label className="tgl-btn" data-tg-off="FTE" data-tg-on={'Hours'} htmlFor="cb5"></label>
            </TooltipHost>
        </div>
    );
};
