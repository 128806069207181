import { FreezePeriod } from '../Entities/FreezePeriod';
import { TimelineResolution } from '../Entities/TimelineResolution';
import { OverviewContext } from './Context/OverviewContext';
import { DateEx } from './DateEx';
import { ResolutionUtil } from './ResolutionUtil';

// used by ContractInfoDto.ts:7:32 MoveRequestDto.ts:9:32 WorkPackageImportDto.ts:4:32
export class DatePickerUtil {
    public static GetMinDateBoundary = (resolution: TimelineResolution, freezePeriod: FreezePeriod): Date => {
        if (freezePeriod && freezePeriod.FreezeBeforeTodayOnly) return DateEx.todaysDate();

        return ResolutionUtil.getStartUsingFreezePeriod(freezePeriod, resolution, OverviewContext.Settings.UseISO8601);
    };

    public static ValidateStart = (startDate: Date, resolution: TimelineResolution, freezePeriod: FreezePeriod): boolean => {
        const start = DatePickerUtil.GetMinDateBoundary(resolution, freezePeriod);
        return startDate >= start;
    };

    public static GetRestrictedDates = (resolution: TimelineResolution, freezePeriod: FreezePeriod, type: 'first' | 'last'): Array<Date> => {
        let restrict: (date: Date) => boolean;

        switch (resolution) {
            case TimelineResolution.Year:
                restrict =
                    type === 'first'
                        ? (date: Date) => !(date.getMonth() === 0 && date.getDate() === 1)
                        : (date: Date) => !(date.getMonth() === 11 && date.getDate() === 31);
                break;
            case TimelineResolution.Quarter:
                restrict =
                    type === 'first'
                        ? (date: Date) => DateEx.getFirstDayOfQuarter(date).getTime() !== date.getTime()
                        : (date: Date) => DateEx.lastDayOfQuarter(date).getTime() !== date.getTime();
                break;
            case TimelineResolution.Month:
                restrict = type === 'first' ? (date: Date) => date.getDate() !== 1 : (date: Date) => DateEx.lastDayOfMonth(date).getTime() !== date.getTime();
                break;
            case TimelineResolution.Week:
                restrict = type === 'first' ? (date: Date) => DateEx.firstWeekDay() !== date.getDay() : (date: Date) => DateEx.lastWeekDay() !== date.getDay();
                break;
            default:
                restrict = date => false;
                break;
        }

        const start = DatePickerUtil.GetMinDateBoundary(resolution, freezePeriod);
        const end = DateEx.addYear(start, 5);

        const restricted = new Array<Date>();
        let loop = new Date(start);
        while (loop <= end) {
            if (restrict(loop)) restricted.push(new Date(loop));
            const newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
        }
        return restricted;
    };
}
