
export const rounder = (value: number) : number => Math.round((value + Number.EPSILON ) * 100) / 100;

export const roundAllocation = (newValue: number, maxValue: number, onRounding: (roundedNew: number, roundedMax: number) => void) => {
	// const roundedMaxValue = Math.round(maxValue);
	// const roundedNewValue = Math.round(newValue);
	// Still not a perfect way to round (rounding is hard, maaaan) (ewi)
	// https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
	const roundedMaxValue = rounder(maxValue); // should we round to the digits that's configured? (ewi)
	const roundedNewValue = rounder(newValue); // 100 should be 10 for single digit
	onRounding(roundedNewValue, roundedMaxValue)
}

export const isOverAllocated = (newValue: number, maxValue: number, lastValue: number): boolean => {
	return (
		// Value is bigger than max allowed
		newValue > maxValue && 
		// Only allow downsizing allocation
		newValue > lastValue && 
		// Allow 0
		newValue !== 0
	)
};

