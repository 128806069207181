import { ReactElement, useState, useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { IFieldRenderProps, IFormItem, useFormContext, useValidation, useWatch } from "spark-forms";
import { FluentValidationMessage } from "spark-forms-fluent8";
import { ApiCalls } from "../../../../api/api";
import { Resource, ResourcePickerDto } from "../../../../api/generated/data-contracts";
import { ResourceType } from "../../../../Entities/ResourceType";
import { stringVariants } from "../../../../helpers/stringVariants";
import { language } from "../../../../Services/LocalizationService";
import EnumEx from "../../../../Utilities/EnumEx";
import { GenericPicker, IPickerItem, IPickerOptions, IPickerSuggestionsOptions } from "../../../xxx-components-refactor/GenericPicker";
import { IResourceFieldConfig } from "./IResourceFieldConfig";

export interface IResourceFieldProps<T extends IFormItem> extends IFieldRenderProps<T, IResourceFieldConfig<T>, string | string[] | undefined> {
    config?: IResourceFieldConfig<T>;
}

export const ResourceField = <T extends IFormItem>({ value, fieldName, config, disabled, path }: IResourceFieldProps<T>): ReactElement | null => {
    const [localValue, setLocalValue] = useState<string | string[] | undefined>(value);

    const { onChange: onChangeForm } = useFormContext();
    const { validate, validationMessage } = useValidation(path);

    useWatch(path, () => setLocalValue(value), [value]);

	const onChange = useCallback((val: string | string[] | undefined) => {
		setLocalValue(val);
		onChangeForm(fieldName, val);
		validate();
	}, [fieldName, onChangeForm, validate]);

	const {
        data: resources,
    } = useQuery(
        ['resourceField', config.excludeInactive, config.matchOnRbs, config.resourceTypes, config.onlyGenericResourcesInUse, config.onlyPoolResourcesInUse],
        async () => {
			const dto = {
				onlyActive: config.excludeInactive,
				rbsMatch: config.matchOnRbs,
				resourceTypes: config.resourceTypes != null ? config.resourceTypes : EnumEx.allAsNumber<ResourceType>(ResourceType),
				onlyGenericResourcesInUse: config.onlyGenericResourcesInUse,
				onlyPoolResourcesInUse: config.onlyPoolResourcesInUse,
			} as ResourcePickerDto;
			const response = await ApiCalls.getResourcesForPicker(dto);
			return response.data as Resource[];
        }
    );

	const getFieldStringValue = (item: Resource, field: keyof Resource) : string => {
		return stringVariants.getAsStringValue(item, field as string);
	}

	const buildOptions = useCallback((): IPickerOptions<Resource> => (
		{
			entityGetter: resources == null ? null : async () => resources,
			itemParser: (item: Resource) => (
				{
					id: item.id,
					displayName: getFieldStringValue(item, config.nameField),
					description: getFieldStringValue(item, config.descriptionField)
				} as IPickerItem
			),
			recentItemsKey: 'resourcepicker-recentlyused',
			multiSelect: config.multiSelect,
			suggestions: {
				loadingText: language.Common.Loading,
				noResultsFound: language.Common.NoResults,
				showRemoveButtons: true,
				suggestionsHeaderText: language.ResourcePicker.RecentlyUsedResources,
				suggestionsContainerText: language.Common.Resources,
				suggestionsAvailableAlertText: language.ResourcePicker.RecentlyUsedResources,
			} as IPickerSuggestionsOptions
		} as IPickerOptions<Resource>
	), [config, resources])
	
	const pickerOptions = useMemo(buildOptions, [buildOptions]);

	return (
		<>
			<GenericPicker
				styles={undefined}
				item={localValue}
				update={(values) => onChange(values)}
				pickerOptions={ pickerOptions }
				readOnly={disabled}
			/>
			<FluentValidationMessage message={validationMessage} />
		</>
	)
}
