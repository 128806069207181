import React from "react";

export function getNextSiblings(element: HTMLElement, predicate: (sibling: HTMLElement) => boolean): Array<HTMLElement> {
	let current = element;
	let stop = false;

	const found = new Array<HTMLElement>();
	while (current && !stop) {
		current = current.nextElementSibling as HTMLElement;
		if (current && predicate(current as HTMLElement)) {
			found.push(current as HTMLElement);
		} else
			stop = true;
	}

	return found;
}

export const htmlTitleIfOverFlowedProps = (() => {
	const mouseOver = (event: any) => {
		const target = event.target;
		if (!target.matches('[title]'))
			return;
		const title = target.title || target.getAttribute('data-title') || target.textContent;
		const overflowed = target.scrollWidth > target.clientWidth;

		target.title = overflowed ? title : '';
	};

	const mouseOut = (event: any) => {
		const target = event.target;
		if (target == null)
			return;
		if (!target.matches('[title]'))
			return;
		// 'mouseout' event fires even when move from parent to child element
		if (event.relatedTarget.parentNode === target)
			return;

		target.title = '';
	};
	return Object.freeze({ onMouseEnter: mouseOver, onMouseLeave: mouseOut })
})()

export function enableHTMLTitleIfOverflowed(element: JSX.Element): JSX.Element {
	const clone = React.cloneElement(
		element,
		{ ...htmlTitleIfOverFlowedProps }
	);
	return clone;
}

export function outsideClick(event: any, elements: Array<Element>) {
	// check outside click for multiple elements
	let clickedOut = true, i: number; 
	const len = elements.length;
	for (i = 0; i < len; i++) {
		if (event.target === elements[i] || elements[i].contains(event.target)) {
			clickedOut = false;
		}
	}
	if (clickedOut)
		return true;
	else
		return false;
}

export const DomEx = {
	enableHTMLTitleIfOverflowed,
	getNextSiblings,
	outsideClick,
}