import { Checkbox, Dialog, DialogType, IDialogContentProps, Label } from '@fluentui/react';
import { useCallback, useEffect, useRef, useState, FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { ApiCalls } from '../../api/api';
import { PushNotificationAnnouncementDto } from '../../api/generated/data-contracts';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { language } from '../../Services/LocalizationService';
import { LocalStorage } from '../../Utilities/LocalStorage';
import { StepWizard } from './Wizard/StepWizard';
import { WizardStep } from './Wizard/WizardStep';
import { MarkdownViewer } from './MarkdownViewer';
import { ArrayEx } from '../../Utilities/ArrayEx';

interface PushNotificationAnnouncementDialogProps {
    Show: boolean;
    DismissCallback: () => void;
    Items: Array<PushNotificationAnnouncementDto>;
    HideBoxChecked: boolean;
    ChangeCallback?: (currentIndex: number, newIndex: number) => void;
    HideCurrentCallback?: (event: any, checked: boolean) => void;
}

const CreateMarkdownStepContent = (markdown: string): JSX.Element => {
    return <MarkdownViewer Markdown={markdown} />;
};

export const PushNotificationAnnouncementDialog: FunctionComponent<PushNotificationAnnouncementDialogProps> = props => {
    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        title: language.pushNotificationAnnouncementViewer.PushNotificationAnnouncementsTitle,
        closeButtonAriaLabel: language.Common.Close,
    };

    return (
        <Dialog
            hidden={!props.Show}
            dialogContentProps={dialogContentProps}
            onDismiss={props.DismissCallback}
            modalProps={{
                isBlocking: true,
                className: 'tp-landing-page-announcements-dialogroot',
				styles: { main: { zIndex: 999 } },
            }}
        >
            <div className={'tp-announcement-container'}>
                <StepWizard
                    steps={props.Items.map((_, idx) => {
                        return {
                            index: idx,
                            content: () => CreateMarkdownStepContent(_.markdown),
                            hideNext: false,
                            hidePrevious: false,
                        } as WizardStep;
                    })}
                    completeCallback={props.DismissCallback}
                    changeCallback={props.ChangeCallback}
                />
            </div>
            <div className={'tp-announcement-checkbox'}>
                <Checkbox id="tppushnotificationannouncementcheckbox" onChange={props.HideCurrentCallback} checked={props.HideBoxChecked} />
                <Label disabled styles={{ root: { padding: 0 } }}>
                    {language.pushNotificationAnnouncementViewer.DontShowAgain}
                </Label>
            </div>
        </Dialog>
    );
};

interface PushNotificationAnnouncementViewerProps {
    AnnouncementsToShowCallback: (val: boolean) => void;
}

const PushNotificationAnnouncementViewer: FunctionComponent<PushNotificationAnnouncementViewerProps> = props => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [hideCurrentPNAnnouncement, setHideCurrentPNAnnouncement] = useState<boolean>(false);

    const currentItemIndex = useRef<number>(0);

    const { data: uiSettings } = useUISettings();

    const { data: pushNotificationAnnouncements } = useQuery(['pushNotificationAnnouncements', uiSettings.resource.id], async () => {
        const response = await ApiCalls.getPushNotificationAnnouncements(undefined);
        const allAnnouncements = response.data;
		const hiddenAnnouncements = LocalStorage.get<any>('tp-landing-page-pushnotificationannouncements') ?? {};
        const filteredAnnouncements = allAnnouncements.filter(_ => {
            if (hiddenAnnouncements[_.id]) return false;
            return true;
        });
        // no relevant announcements
        if (!filteredAnnouncements?.length) return;

		const sortedAnnouncements = ArrayEx.sort(filteredAnnouncements, 'Order');
        setShowDialog(true);
        return sortedAnnouncements;
    });

    const _localStorageHandler = useCallback(() => {
        LocalStorage.setProperty('tp-landing-page-pushnotificationannouncements', pushNotificationAnnouncements[currentItemIndex.current].id, hideCurrentPNAnnouncement);
    }, [pushNotificationAnnouncements, hideCurrentPNAnnouncement]);

    const ChangeCurrent = (currentIndex: number, newIndex: number) => {
        _localStorageHandler();
        currentItemIndex.current = newIndex;
        const lsAnnouncements = LocalStorage.get<any>('tp-landing-page-pushnotificationannouncements') ?? {};
        const currentContent = pushNotificationAnnouncements[currentItemIndex.current];
        setHideCurrentPNAnnouncement(lsAnnouncements[currentContent.id]);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const _hideCurrentPNAnnouncementHandler = (checked: boolean) => {
        setHideCurrentPNAnnouncement(checked);
    };

    useEffect(() => {
        if (pushNotificationAnnouncements) {
            _localStorageHandler();
        }
		props.AnnouncementsToShowCallback(showDialog);
    }, [_localStorageHandler, pushNotificationAnnouncements, hideCurrentPNAnnouncement, showDialog, props]);

    return pushNotificationAnnouncements?.length ? (
        <PushNotificationAnnouncementDialog
            Items={pushNotificationAnnouncements}
            DismissCallback={handleCloseDialog}
            Show={showDialog}
            ChangeCallback={ChangeCurrent}
            HideCurrentCallback={(event, checked) => _hideCurrentPNAnnouncementHandler(checked)}
            HideBoxChecked={hideCurrentPNAnnouncement}
        />
    ) : null;
};

export default PushNotificationAnnouncementViewer;
