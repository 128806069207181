export default class Locale_NotificationSender { 
	Title: string = "Title";
	Message: string = "Message";
	IsMarkdown: string = "Message is markdown (not supported yet)";
	ForceRefresh: string = "Force users to refresh page (ie. after refreshing cache objects)";
	BlockScreen: string = "Block the screen, allowing the user to dismiss the message";
	SpecifyTargetedUser: string = "If needed you can select specific users to send to. If not specified the notification is sent to all online users. You can also revert the user selection by excluding the selected users instead.";
	ExcludeUser: string = "Exclude the selected users, instead of sending only to them";
	Notify: string = "Notify";
	NotificationSent: string = "Notification sent!";
	FailedSendingNotification: string = "Failed sending notification!";
}