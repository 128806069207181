import { useCallback } from "react";
import shallow from "zustand/shallow";
import { useStore } from "../../../context/store";
import Locale_BaseOverview from "../../../Locales/Overviews.ts/BaseOverview";
import { language } from "../../../Services/LocalizationService";

export const useConfirmDeleteOverviewDialog = () => {

	const setBlockDialog = useStore(store => store.setBlockDialog);
	const { error, success } = useStore(
		store => ({
			error: store.addErrorNotification,
			success: store.addSuccesNotification
		}),
		shallow,
	);
	
	const confirm = useCallback((confirmCallback: (relatedData: boolean) => void, rejectCallback: () => void, multiple: boolean, languageObj: Locale_BaseOverview) => {
		
		setBlockDialog(null, {
			title: languageObj.ConfirmDeleteQuestionHeader, 
			subText: multiple ? languageObj.ConfirmDeleteQuestionMultiple : languageObj.ConfirmDeleteQuestionSingle, 
			isBlocking: true, 
			dismissEnable: false,
			buttons: [
				{
					text: language.Common.Delete,
					type: "primary",
					shouldDismiss: false,
					onClick: async () => {
						setBlockDialog(null, {
							title: languageObj.ConfirmCascadingTitle, 
							subText: languageObj.ConfirmCascadingSubtextSingle, 
							isBlocking: true, 
							dismissEnable: false,
							buttons: [
								{
									text: languageObj.ConfirmCascadingOkButton,
									type: "primary",
									shouldDismiss: true,
									onClick: async () => {
										try {
											confirmCallback(true);
											success(multiple ? languageObj.DeletedMultiple : languageObj.DeletedSingle);
										} catch (err) {
											error(err);
										}
									}
								},
								{
									text: languageObj.ConfirmCascadingCancelButton,
									type: "default",
									shouldDismiss: true,
									onClick: () => {
										try {
											confirmCallback(false);
											success(multiple ? languageObj.DeletedMultiple : languageObj.DeletedSingle);
										} catch (err) {
											error(err);
										}
									}
								}
							]
						})
					}
				},
				{
					text: language.Common.Cancel,
					type: "default",
					shouldDismiss: true,
					onClick: () => {
						rejectCallback();
					}
				}
			]
		})
	}, [error, setBlockDialog, success])

	return confirm;
}
