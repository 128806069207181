import { fluentUiValidationMessageElement } from '@wiberg/fluentui-builder';
import { IFormItem, IPropertyRenderProps } from '@wiberg/formbuilder';
import { PropsWithChildren, ReactElement, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ApiCalls } from '../../../../api/api';
import { language } from '../../../../Services/LocalizationService';
import { IPickerOptions, IPickerSuggestionsOptions, GenericPicker, IPickerItem } from '../../../xxx-components-refactor/GenericPicker';
import { IAadGroupFieldConfig } from './config/IAadGroupFieldConfig';
import { AAD_GROUP_FIELD } from '../../../../context/network/http/QueryProvider/queryKeys';

interface IProps<T extends IFormItem> extends IPropertyRenderProps<T, IAadGroupFieldConfig<T>, string | string[] | undefined> {
    config?: IAadGroupFieldConfig<T>;
}

export interface IAadGroup {
    id: string;
    displayName: string;
    description: string;
}

export const AadGroupField = <T extends IFormItem>({
    onChange,
    disabled,
    parent,
    value,
    config,
    errorMessage,
}: PropsWithChildren<IProps<T>>): ReactElement | null => {
    const { data: groups } = useQuery([AAD_GROUP_FIELD], async () => {
        const response = await ApiCalls.queryAadGroups(null);
        return response.data as IAadGroup[];
    });

    const buildOptions = useCallback(
        (): IPickerOptions<IAadGroup> =>
            ({
                entityGetter: async () => groups,
                itemParser: (item: IAadGroup) =>
                    ({
                        id: item.id,
                        displayName: item.displayName,
                        description: item.description,
                    } as IPickerItem),
                recentItemsKey: 'aadgrouppicker-recentlyused',
                multiSelect: config.multiSelect,
                suggestions: {
                    loadingText: language.Common.Loading,
                    noResultsFound: language.Common.NoResults,
                    showRemoveButtons: true,
                    suggestionsHeaderText: language.ResourcePicker.RecentlyUsedResources,
                    suggestionsContainerText: language.Common.Resources,
                    suggestionsAvailableAlertText: language.ResourcePicker.RecentlyUsedResources,
                } as IPickerSuggestionsOptions,
            } as IPickerOptions<IAadGroup>),
        [config.multiSelect, groups],
    );

    const pickerOptions = useMemo(buildOptions, [buildOptions]);

    return (
        <>
            <GenericPicker styles={undefined} item={value} update={values => onChange(values)} pickerOptions={pickerOptions} readOnly={disabled} />
            {errorMessage && fluentUiValidationMessageElement(errorMessage)}
        </>
    );
};
