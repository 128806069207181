import { GridType } from '../Entities/GridType';
import { UserType } from '../Entities/UserType';
import { OverviewContext } from './Context/OverviewContext';

export class Glimmer {

	public static ShowGlimmer = (GridType: GridType, UserTypes: UserType[]) : boolean => {
		if (OverviewContext.Settings.GlimmerConfiguration == null) return true;
		if(OverviewContext.Settings.GlimmerConfiguration.GridTypes.some(_ => _ === GridType) && OverviewContext.Settings.GlimmerConfiguration.UserTypes.some(_ => UserTypes.indexOf(_) >= 0))
			return false;

		return true;
	}
}