export default class Locale_Rule {
	RuleMustBeNamed: string = "The rule must have a name.";
	Recurring: string = "Recurring";
	RecurringDescription: string = "Whether or not the rule should be recurrent. Recurring rules supersedes regular rules, meaning that on the dates a recurrent rule overlaps the regular, the recurrent will win.";

	StartDescription: string = "The start date of the period the rule should be effective";
	StartMustBeSpecified: string = "Start must be specified, when rule isn't recurring";
	EndMustBeAfterStart: string = "End is earlier than start";
	
	EndDescription: string = "The end date of the period the rule should be effective. If not selected the rule runs forever.";
	EndMustBeSpecified: string = "End must be specified, when rule isn't recurring";

	IncludeWeekends: string = "Include weekends";
	IncludeWeekendsDescription: string = "Whether or not the rule should include weekends. Used when you need to make a rule with positive work, ie. when you need a resource to be available in the weekends";

	WorkDescription: string = "The amount of work hours available pr. day.";
	WorkValidation: string = "Work must be a non-negative number";

	RecurrenceRuleSettings: string = "Recurrence rule settings";
	Invalidrule: string = "Error validating rule";

	ValidFrom: string = "Valid From";
	ValidTo: string = "Valid To";

}