/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-refresh/only-export-components */
import React, { FunctionComponent, useState, useEffect } from 'react';
import { PFDialog } from '../Common/PFDialog';
import { WithStore } from '../../Services/StateStore';
import { StoreProps } from '../../Services/StoreProps';
import { Plugin } from '../../Services/PluginInvoker';
import { PluginIDs } from '../../Utilities/PluginIDs';
import { TimelineResolution } from '../../Entities/TimelineResolution';
import { MoveRequestDto } from '../../Entities/Dto/MoveRequestDto';
import { PFSpinner } from '../Common/Spinner';
import { ObjectEditor } from '../Common/ObjectEditor';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { ValidationRule } from '../../Entities/Validation/ValidationRule';
import { PropertyInfoService } from '../../Services/PropertyInfoService';
import { validationService } from '../../Services/ValidationService';
import { MoveRequestValueType } from '../../Entities/MoveRequestValueType';
import { PFErrorBoundary } from '../Common/PFErrorBoundary';
import { Label } from '@fluentui/react';
import { ContractResolutionCheckDto } from '../../Entities/Dto/ContractResolutionCheckDto';
import { language } from '../../Services/LocalizationService';
import { RowMenuItemInfo } from '../../Entities/Dto/RowMenuItemInfo';
import { useStore } from '../../context/store';

interface IProps extends StoreProps {
    Resolution: TimelineResolution;
    Start: Date;
    End: Date;
    RowMenuItemInfo: RowMenuItemInfo;
}

enum MoveRequestType {
    Unset,
    Contract,
    Project,
}

const MoveRequestDialog: FunctionComponent<IProps> = props => {
    const error = useStore(store => store.addErrorNotification);
    // const { error } = useExternalNotificationContext()
    const [dto, setDto] = useState<MoveRequestDto>();
    // let [sliderMax, setSliderMax] = useState<number>();
    const [loading, setLoading] = useState(false);
    const [resolutionCheck, setResolutionCheck] = useState<boolean>(true);
    const [validationRules, setValidationRules] = useState<Array<ValidationRule>>([]);

    const [info, setInfo] = useState(props.RowMenuItemInfo);
    const [moveType, setMoveType] = useState(MoveRequestType.Unset);

    const isMounted = React.useRef(null);

    useEffect(() => {
        if (!isMounted.current) {
            SetupDto();
            const properties = PropertyInfoService.GetPFProperties(MoveRequestDto, false);
            const validationRules = properties.filter(_ => _.Validation).map(_ => _.Validation);
            setValidationRules(validationRules);
        }
    }, []);

    useEffect(() => {
        if (isMounted.current || (dto && props.Resolution !== dto.Resolution)) SetupDto();
        return () => {
            isMounted.current = null;
        };
    }, [props.Resolution]);

    useEffect(() => {
        setInfo(props.RowMenuItemInfo);
        if (props.RowMenuItemInfo == null) setMoveType(MoveRequestType.Unset);
        else setMoveType(props.RowMenuItemInfo.ProjectId != null ? MoveRequestType.Project : MoveRequestType.Contract);
    }, [props.RowMenuItemInfo]);

    const SetupDto = async () => {
        const dtoObj = new MoveRequestDto();
        dtoObj.Resolution = props.Resolution;
        dtoObj.ValueType = MoveRequestValueType.Percentage;
        dtoObj.Value = 100;
        setDto(dtoObj);
    };

    const MoveRequest = async (): Promise<void> => {
        setLoading(true);

        dto.ProjectId = info.ProjectId;
        dto.ContractId = info.ContractId;
        dto.ResourceId = info.ResourceId;
        dto.Resolution = props.Resolution;

        const validated = validationService.validate(dto, validationRules, error);
        if (!validated) {
            setLoading(false);
            return;
        }

        try {
            if (moveType === MoveRequestType.Contract)
                await Plugin.InvokeNoResponse(PluginIDs.MoveRequestInTime, dto, language.MoveRequestDialog.FailedMovingRequest);
            else await Plugin.InvokeNoResponse(PluginIDs.MoveProjectRequestsInTime, dto, language.MoveRequestDialog.FailedMovingProjectRequests);
        } catch (error) {
            setLoading(false);
            return;
        }

        setLoading(false);
        const emptyDto = new MoveRequestDto();
        emptyDto.Resolution = props.Resolution;
        setDto(emptyDto);
        props.Store.Set({ ShowMoveRequestDialog: false, MoveRequestInfo: null });
        OverviewContext.RefreshProjectCapacity();
    };

    const DtoOnChange = (item: MoveRequestDto, prop, value) => {
        setDto(item);

        // only perform resolution check on single contract move
        if (moveType !== MoveRequestType.Contract) return;

        if (dto.FromStart) {
            // check for activities with different resolution in the selected timespan
            const checkDto = new ContractResolutionCheckDto();
            checkDto.ContractId = info.ContractId;
            checkDto.CheckRequests = true;
            checkDto.Resolution = props.Resolution;
            checkDto.Start = item.FromStart;
            checkDto.End = item.FromEnd;
            Plugin.Invoke(PluginIDs.ActivitiesHasThisResolution, checkDto).then(result => setResolutionCheck(!!result));
        }
    };

    return (
        props.Store.Get(_ => _.ShowMoveRequestDialog) && (
            <PFDialog
                title={moveType === MoveRequestType.Contract ? language.MoveRequestDialog.MoveRequest : language.MoveRequestDialog.MoveProjectRequests}
                subText={loading ? '' : moveType === MoveRequestType.Contract ? language.MoveRequestDialog.SubText : language.MoveRequestDialog.ProjectSubText}
                showDialog={props.Store.Get(_ => _.ShowMoveRequestDialog)}
                maxWidth={600}
                callback={async () => await MoveRequest()}
                dismissCallback={() => {
                    props.Store.Set({ ShowMoveRequestDialog: false, MoveRequestInfo: null });
                    setLoading(false);
                    SetupDto();
                }}
                okButtonEnabled={!loading}
            >
                <div className="tp-moverequest-dialog-content">
                    {loading ? (
                        <PFSpinner CustomClass="justoverridedefault" />
                    ) : (
                        <PFErrorBoundary ComponentName="move request dialog" Reset={SetupDto}>
                            {!resolutionCheck && (
                                <Label styles={{ root: { color: 'red' } }}>
                                    The selected timespan contains activities with a different resolution, these will all be merged to one, using the current
                                    resolution!
                                </Label>
                            )}
                            <ObjectEditor
                                Item={dto}
                                ItemType={MoveRequestDto}
                                ChangesCallback={(item: MoveRequestDto, prop: string, value: any) => DtoOnChange(item, prop, value)}
                                HiddenProperties={[]}
                                DisabledProperties={[]}
                            />
                        </PFErrorBoundary>
                    )}
                </div>
            </PFDialog>
        )
    );
};

export default WithStore<IProps>(MoveRequestDialog);
