
export const getUrl = (): string => {
    // if (window.location.href.indexOf(":3000") != -1)
    //   return "https://localhost:7161";
    return window.location.origin;
};

export const isDevelopment = () : boolean => {
	return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
}

export const Url = {
	getUrl, isDevelopment
}

export const isFullUrl = (entry: string): boolean => {
	const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm
	return expression.test(entry);
}