import { ApiCalls } from "../../../../api/api";
import { DeltaUpsertDto, EntityType, Resource } from "../../../../api/generated/data-contracts";
import { guid } from "../../../../helpers/guid";
import { language } from "../../../../Services/LocalizationService";
import { IOverviewActivation, overviewActivation } from "../../_components/helpers/overviewActivation";

export const showInactivateConfirm = (resources: Resource[], active: boolean, callback: () => void, indicateLoading: (value: boolean) => void) => {
	overviewActivation<Resource>({
		languageObj: language.ResourceOverview,
		items: resources,
		active: active,
		showInactiveCountdown: true,
		callback: callback,
		setActiveState: setActiveStateOnResources,
		indicateLoading: indicateLoading
	}).activation()
};

const setActiveStateOnResources = async (options: IOverviewActivation<Resource>) => {
	options.indicateLoading(true);
	const dto = {
		origin: guid.empty,
		entity: EntityType.Resource,
		items: options.items.map(_ => {
			return {
				typeName: "Resource",
				id: _.id,
				active: options.active
			}
		}),
		changedProperties: [ "Active" ],
		force: "2A02C94C-D08A-4F54-B215-FB3A3076E68C" // force upsert key on delta plugin
	} as DeltaUpsertDto;

	await ApiCalls.deltaUpsertEntity(dto);

	options.callback();
	options.indicateLoading(false);
};