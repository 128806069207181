import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { IObjectEditorItemType } from "../Main/IObjectEditorItemType";
import { TimelineResolution } from "../TimelineResolution";

export class MatchingActivityDto implements IObjectEditorItemType {
	public ContractId: string;
	public ResourceId: string;
	
	@PFPropertyBasicInfo(PropertyType.BooleanToggle, " ")
	@PFPropertyRender(null, { OnText: "_localization:MatchingActivityDto.ChoiceDisregardsEndSelection", OffText:  "_localization:MatchingActivityDto.ChoiceOnlyCreatesMatchingRequest"})
	public FullAllocation: boolean;

	public Start: Date;
	public End: Date;
	public Resolution: TimelineResolution;
}