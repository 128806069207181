
const newGuid = (): string => {
	return window.crypto.randomUUID();
}

// All primitives in JS is immutable and thus readonly at all times (compile and run).
// Left as an example
const empty: Readonly<string> = "00000000-0000-0000-0000-000000000000";

const isGuid = (guid: string) : boolean => {
	// for some reason guid from pwa doesn't specify UUID version ?? - ewi
	return new RegExp("^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$","i").test(guid)
	// return new RegExp("^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$","i").test(guid)
}

const isEmpty = (guid: string) : boolean => {
	return guid === empty;
}

const isGuidNotEmpty = (guid: string) : boolean => {
	return isGuid(guid) && guid !== empty;
}

export const guid = { newGuid, empty, isGuid, isEmpty, isGuidNotEmpty }