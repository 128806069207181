import * as React from 'react';
 
export interface IDynamicEditorComponentProps {
	Item: any;
	Parent?: any;
	ReadOnly?: boolean;
	Required?: boolean;
	Update?: (value: any) => void;
}

export default class DynamicEditorComponent<TProps extends IDynamicEditorComponentProps, TState> extends React.Component<TProps, TState> {  
    constructor(props) {
        super(props); 
	}
}