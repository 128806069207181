import React, { useRef, useLayoutEffect } from "react";
// import { getColorFromString } from "@fluentui/react/lib/Styling";
import { getColorFromString } from "@fluentui/react";
import { useTheme } from '../../../../Themes/themeContext';
import { useDragCtx } from './dragContext';
import DragHighterElement from './DragHighlighterElement';
import { Cell } from "../../../../Entities/Table/Cell";

interface IProps {
	DragHighlighterUpdate?: Array<(cells: Array<{Cell: Cell, ColumnIndex: number, Value: number}>) => void>;
}

const DragHighlighter: React.FC<IProps> = (props) => {
	const theme = useTheme();
	const dragCtx = useDragCtx();

	const bgColor = useRef(null);

	useLayoutEffect(() => {
		const { r, g, b } = getColorFromString(theme.palette.accent);
		bgColor.current = `rgba(${r},${g},${b},0.05)`
	}, [theme.palette]);

	return dragCtx.showDragBox ? <DragHighterElement bgColor={bgColor.current} {...props} /> : null;
};

export default DragHighlighter;

