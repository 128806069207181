import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useUISettings } from "../context/network/http/QueryProvider/queries/UISettings";
import { UserType } from '../api/generated/data-contracts';

export interface IusePermissions {
    userTypes: UserType[];
    not?: boolean;
    some?: boolean | null;
}

export const useTPPermissions = ({ userTypes, not, some }: IusePermissions) => {
    const checkPermission = useCheckTPPermissions();
    return useMemo(() => {
        return checkPermission({ userTypes, not, some });
    }, [checkPermission, not, some, userTypes]);
};

export const useCheckTPPermissions = (defaults?: Partial<IusePermissions>) => {
	const currentDefaults = useRef(defaults || {});
	useEffect(() => {
		currentDefaults.current = defaults
	}, [defaults])
	const { data: uiSettings } = useUISettings()
    const checkPermission = useCallback(
        // ({ userTypes, not, some }: IusePermissions) => {
        (paramObj: IusePermissions) => {
			const { userTypes, not, some } = {...currentDefaults.current, ...paramObj};
			// no permissions at all
			// if (!user?.Resource?.UserTypes?.length) {
			if (!uiSettings?.resource?.userTypes?.length) {
				return false;
			}

            const currentUsersTypes = uiSettings.resource.userTypes;
            if (some) {
                if (not) {
                    return !userTypes.some(perm => currentUsersTypes.includes(perm));
                }
                return userTypes.some(perm => currentUsersTypes.includes(perm));
            }
            if (not) {
                return !userTypes.every(perm => currentUsersTypes.includes(perm));
            }
            return userTypes.every(perm => currentUsersTypes.includes(perm));
        },
        [uiSettings?.resource?.userTypes],
    );
    return checkPermission;
};
