import produce from 'immer';
import { useMemo } from 'react';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { ApiCalls } from '../api/api';
import { UIStartupDto } from '../api/generated/data-contracts';
import { syncUISettings, useUISettings } from '../context/network/http/QueryProvider/queries/UISettings';
import { UI_SETTINGS_KEY } from '../context/network/http/QueryProvider/queryKeys';
import { queryClient } from '../context/network/http/QueryProvider/QueryProvider';
import { UnitType } from '../Entities/UnitType';

function getSettingsDefault(currentUnitType: UnitType): UnitType {
    switch (currentUnitType) {
        // return UnitType.Hours;
        case UnitType.FTE:
        case UnitType.BothFteAsPrimary:
            return UnitType.FTE;
        case UnitType.Hours:
        case UnitType.BothHoursAsPrimary:
        default:
            return UnitType.Hours;
    }
}

// export function getCurrentUnitType(startUpInfo?: UIStartupDto): UnitType {
export function getCurrentUnitType(): UnitType {
    const uiSettings = queryClient.getQueryData<UIStartupDto>(UI_SETTINGS_KEY);
    // only allow user specific if settings allow it
    const settingsUnitType = uiSettings.settings.unitType;
    if (settingsUnitType < 2) {
        return getSettingsDefault(settingsUnitType);
    }
    // user specific
    if (uiSettings.resource.preferredUnitType !== undefined && uiSettings.resource.preferredUnitType !== null) {
        return uiSettings.resource.preferredUnitType;
    }
    // hard setting
    return getSettingsDefault(uiSettings.settings.unitType);
}

type UnitTypeMutationParam = {
    newUnitType: UnitType;
    writeToPersistentCache?: boolean;
};

export function useUnitType(options?: Omit<UseMutationOptions<boolean, unknown, UnitTypeMutationParam, unknown>, 'mutationFn'>) {
    const queryClient = useQueryClient();

	const { data: currentUISettings } = useUISettings();

    const setUnitTypeMutation = useMutation(async ({ newUnitType, writeToPersistentCache = true }) => {
		
		// prevent excessive updates
		if (currentUISettings.resource.preferredUnitType === newUnitType) {
			return true;
		}

        const response = (await ApiCalls.updateCurrentResourceUnitType(newUnitType)).data;
        queryClient.setQueryData<UIStartupDto>(UI_SETTINGS_KEY, oldSettings => {
            const newSettings = produce(oldSettings, settings => {
                settings.resource.preferredUnitType = newUnitType;
            });
            if (writeToPersistentCache) {
                syncUISettings(newSettings);
            }
            return newSettings;
        });
        return response;
    }, options);

    const currentUnitType = useMemo(() => {
        const settingsUnitType = currentUISettings.settings.unitType;
        if (settingsUnitType < 2) {
            return getSettingsDefault(settingsUnitType);
        }
        return currentUISettings.resource.preferredUnitType ?? getSettingsDefault(settingsUnitType);
    }, [currentUISettings.resource.preferredUnitType, currentUISettings.settings.unitType]);

    return {
        // toggleUnitTypeMutation,
        setUnitTypeMutation,
        currentUnitType,
    };
}

export const UnitTypeUtil = {
    // toggleUnitType,
    // setUnitType,
    // getSettingsDefault,
    getCurrentUnitType,
};
