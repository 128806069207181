import { FunctionComponent, useRef, useState, useEffect, useCallback } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { AnimationClassNames } from '@fluentui/react/lib/Styling';
import WaitingDto from '../../Services/Hubs/Models/WaitingDto';
import { PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import { ArrayEx } from '../../Utilities/ArrayEx';
import { PluginIDs } from '../../Utilities/PluginIDs';
import { Plugin } from '../../Services/PluginInvoker';
import { useWaitingHubContext } from '../../context/network/websocket/WaitingHubContext';

interface IProps {
    DoneLoadingCache: () => void;
}

const WaitingCache: FunctionComponent<IProps> = props => {
    const [dtos, setDtos] = useState<WaitingDto[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [failed, setFailed] = useState(false);

    const { done, failed: waitingFailed, dtos: messageDtos } = useWaitingHubContext();

    const progressBar = useRef(null);

    const setProgressBar = useCallback((itemsLoaded: number, itemsToLoad: number) => {
        const percent = (itemsLoaded / itemsToLoad) * 100;
        progressBar.current.style.width = `${percent}%`;
    }, []);

    useEffect(() => {
        if (done) {
            props.DoneLoadingCache();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [done, props.DoneLoadingCache]);

    useEffect(() => {
        if (waitingFailed) {
            _setFailed();
        }
    }, [waitingFailed]);

    useEffect(() => {
        setDtos(messageDtos);
    }, [messageDtos]);

    useEffect(() => {
        if (!progressBar.current) return;
        setProgressBar(dtos.filter(_ => _.status === 'Succes').length, dtos.length);
    }, [dtos, setProgressBar]);

    const _setFailed = () => {
        setFailed(true);
        setShow(true);
    };

    const getStatusIcon = (status: string): JSX.Element => {
        switch (status) {
            case 'Succes':
                return <FontIcon iconName="SkypeCheck" />;
            case 'Running':
                return <Spinner size={SpinnerSize.xSmall} />;
            case 'Failed':
                return <FontIcon iconName="Error" />;
            default:
                return <FontIcon iconName="Clock" />;
        }
    };

    const retryCacheBuild = async () => {
        dtos.forEach(_ => {
            _.status = null;
        });
        setDtos([...dtos]);
        setFailed(false);
        await Plugin.InvokeNoResponse(PluginIDs.CacheInitializer, true, 'Failed to start cache initializer.', true);
    };

    return (
        // useDelayClass
        <Fabric className="waiting_hub">
            <div className="waiting_hub_container">
                <img id="tp3logo" className="tp_logo" src="/images/TeamPlanner_logo.png" alt="" />
                <Text styles={{ root: { textAlign: 'center' } }}>{'Team Planner is getting ready'}</Text>
                <Text styles={{ root: { textAlign: 'center' } }}>{'just waiting for the cache'}</Text>
                {failed && (
                    <>
                        <Text styles={{ root: { textAlign: 'center', color: 'red', paddingTop: '10px', paddingBottom: '5px' } }}>
                            {'Failed building one or more cache objects!'}
                        </Text>
                        <PrimaryButton text="Re-build cache" onClick={retryCacheBuild} />
                    </>
                )}
                <div className="wh_progress_bar">
                    <div className="wh_progress_bar_inner">
                        <div ref={progressBar} className="wh_progress_bar_inner_overlay" />
                    </div>
                </div>

                <div className="wh_container">
                    <div className="wh_container_toggle" onClick={() => setShow(s => !s)}>
                        <FontIcon iconName={show ? 'ChevronUpMed' : 'ChevronDownMed'} />
                    </div>
                    {ArrayEx.sort(dtos, 'name').map((_, idx) => (
                        <div key={idx} className={`wh_item ${_.status} ${show ? AnimationClassNames.fadeIn200 : 'item_hidden'}`}>
                            <span className="wh_item_status">{getStatusIcon(_.status)}</span>
                            <span className="wh_item_content">
                                <Text className="wh_item_name">{_.name}</Text>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </Fabric>
    );
};

export default WaitingCache;
