/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo, useEffect, useState } from 'react';
import Cron, { CronError } from 'react-js-cron';
import { DynamicProps } from './DynamicProps';
import { IconButton, Label, TextField } from '@fluentui/react';
import { PFDialog } from '../../PFDialog';
import { language } from '../../../../Services/LocalizationService';

type IProps = DynamicProps<string, string>;

const DynamicCron: FunctionComponent<IProps> = props => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [value, setValue] = useState<string>(props.Value);
    const [textValue, setTextValue] = useState<string>(props.Value);

    const [error, onError] = useState<CronError>();

    useEffect(() => {
        setTextValue(value);
    }, [value]);

    return (
        <div className="tp-croneditor-container">
            <IconButton iconProps={{ iconName: props.ReadOnly ? 'Zoom' : 'Edit' }} onClick={() => setEditMode(true)} />
            <Cron value={props.Value} setValue={value => {}} clearButton readOnly />
            <PFDialog
                title={language.DynamicComponents.CronEditor}
                minWidth={1000}
                maxWidth={1500}
                showDialog={editMode}
                callback={() => {
                    props.OnChange(props.PropertyInfo.PropertyName, value);
                    setEditMode(false);
                }}
                dismissCallback={() => {
                    setEditMode(false);
                    setValue(props.Value);
                }}
                eventBubbling={false}
                isBlocking
            >
                <Cron
                    value={value}
                    setValue={val => {
                        setValue(val);
                    }}
                    clearButton
                    readOnly={props.ReadOnly}
                    onError={onError}
                />
                <TextField value={textValue} onChange={(ev, val) => setTextValue(val)} onBlur={event => setValue(event.target.value)} />
                {error && <Label styles={{ root: { color: 'red' } }}>{error.description}</Label>}
            </PFDialog>
        </div>
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Value === nextProps.Value;
};

export default memo(DynamicCron, comparisonFn);
