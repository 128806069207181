import { useMemo } from 'react';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { CURRENT_PROJECT_KEY } from '../../context/network/http/QueryProvider/queryKeys';
import { Project } from '../../Entities/Main/Project';
import { useCheckTPPermissions } from '../../hooks/usePermissions';
import { LocalStorage } from '../../Utilities/LocalStorage';
import { getLegacyDeepLink, buildRoute } from '../../helpers/routeHelpers';
import { UserType } from '../../api/generated/data-contracts';

export const useGetDefaultRoute = () => {
    const { data: userSettings } = useUISettings();
    const checkPermissions = useCheckTPPermissions();
    const projectId: string | undefined = useMemo(() => {
        const cachedProject = LocalStorage.get<Project>(CURRENT_PROJECT_KEY);
        if (cachedProject) {
            return cachedProject.id;
        }
    }, []);

    return useMemo(() => {
        const legacyLink = getLegacyDeepLink();
        let legacyProjectId = null;
        // it's a legacy deep link
        if (legacyLink) {
            // shitty way of handling legacy deep link
            if (legacyLink.indexOf('legacylink')) {
                legacyProjectId = legacyLink.replace('/legacylink/', '');
            } else {
                return legacyLink;
            }
        }

        // LM or PM or Superuser
        if (checkPermissions({ userTypes: [UserType.LineManager, UserType.ProjectOwner, UserType.SuperUser], some: true })) {
            // force view type if not superuser
            if (checkPermissions({ userTypes: [UserType.LineManager] }) && !checkPermissions({ userTypes: [UserType.ProjectOwner, UserType.SuperUser] })) {
                return buildRoute('/allocation', legacyProjectId ?? projectId);
            } else {
                return buildRoute('/request', legacyProjectId ?? projectId);
            }
        } else if (checkPermissions({ userTypes: [UserType.Administrator] })) {
            // only admin role
            return buildRoute('/admindashboard');
        } else if (checkPermissions({ userTypes: [UserType.Resource] })) {
            // resource role
            return buildRoute('/resourcecapacity', userSettings?.resource?.id);
        }
    }, [checkPermissions, projectId, userSettings?.resource?.id]);
};
