import { memo, useMemo, CSSProperties } from 'react';
import { GridHeaderRow } from '../../../../../../api/generated/data-contracts';
import { VirtualItem } from '@tanstack/react-virtual';
import { COLUMN_WIDTH, TOP_HEADERS_HEIGHT, BORDER, ROOT_ROW_BG, COLUMN_STICKY_COUNT, CUSTOM_COLUMN_WIDTH } from '../CONSTANTS';
import { GridHeader } from './Headers/GridHeader';
import { TableHeader } from './Headers/TableHeader';
import { useSelectedColumnsValue } from '../context/customColumnContext';
import { useCustomColumnRange } from '../hooks/useCustomColumnRange';

export const HeaderContainer = memo(function HeaderContainer({
    start,
    totalWidth,
    headers,
    columns,
}: {
    // index: number;
    start: number;
    columns: VirtualItem[];
    totalWidth: number;
    headers: GridHeaderRow[];
}) {
    const style = useMemo(() => {
        return {
            position: 'sticky',
            transform: `translateY(${start}px)`,
            top: 0,
            left: 0,
            height: `${TOP_HEADERS_HEIGHT}px`,
            borderBottom: BORDER,
            width: `${totalWidth}px`,
            boxSizing: 'border-box',
            background: ROOT_ROW_BG,
            zIndex: 2,
        } as CSSProperties;
    }, [start, totalWidth]);
    const customColumns = useSelectedColumnsValue();
    const { range, getIndex } = useCustomColumnRange();
    const render = useMemo(() => {
        return columns.map(({ start, key, index }) => {
            if (index === 0) {
                return <GridHeader key={key} />;
            }
            if (range.includes(index)) {
                const headerIndex = index - COLUMN_STICKY_COUNT;
                const customColumn = customColumns[headerIndex];
                return (
                    <CustomHeader key={key} text={customColumn.key} index={index} start={start}>
                        {customColumn.text}
                    </CustomHeader>
                );
            }
            const headerIndex = getIndex(index);
            const header = headers[headerIndex];
            return <Header key={key} header={header} start={start} index={headerIndex} />;
        });
    }, [columns, customColumns, getIndex, headers, range]);
    return <div style={style}>{render}</div>;
});

const Header = ({ header: { name }, index, start, isCustom }: { header: GridHeaderRow; index: number; start: number; isCustom?: boolean }) => {
    const style = useMemo(() => {
        return {
            width: `${COLUMN_WIDTH}px`,
            borderBottom: BORDER,
            borderRight: BORDER,
            zIndex: 1,
            position: 'absolute',
            transform: `translateX(${start}px)`,
            top: 0,
            left: 0,
            height: `${TOP_HEADERS_HEIGHT}px`,
            boxSizing: 'border-box',
        } as CSSProperties;
    }, [start]);
    return (
        <div style={style}>
            <TableHeader id={name} index={index} isCustom={isCustom}>
                {name}
            </TableHeader>
        </div>
    );
};

const CustomHeader = ({ text, index, start, children }: { text: string; index: number; start: number; children: string }) => {
    const style = useMemo(() => {
        return {
            width: `${CUSTOM_COLUMN_WIDTH}px`,
            borderBottom: BORDER,
            borderRight: BORDER,
            zIndex: 1,
            position: 'absolute',
            transform: `translateX(${start}px)`,
            top: 0,
            left: 0,
            height: `${TOP_HEADERS_HEIGHT}px`,
            boxSizing: 'border-box',
        } as CSSProperties;
    }, [start]);
    return (
        <div style={style}>
            <TableHeader id={text} index={index} isCustom>
                {children}
            </TableHeader>
        </div>
    );
};
