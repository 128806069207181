import { FunctionComponent, useState } from 'react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { TreeViewItem } from '../../../Entities/TreeViewItem';
import { LookupValue, LookupValueNumber } from '../../../Entities/ContentConfiguration/Lookup/LookupValueTypes/LookupValue';
import { Lookup } from '../../../Entities/ContentConfiguration/Lookup/Lookup';
import { LookupType } from '../../../Entities/ContentConfiguration/Lookup/LookupType';
import { Dropdown, IDropdownOption, TextField } from '@fluentui/react';
import { DropdownOptionEx } from '../../../Utilities/DropdownOptionEx';
import { TreeMatchDirection } from '../../../Entities/MatchDirection';
import { LookupTree } from '../../../Entities/ContentConfiguration/Lookup/LookupValueTypes/LookupTree';
import { TreeItemEx } from '../../../Utilities/TreeItemEx';
import { TreeItem } from '../../../Entities/TreeItem';
import { PFDialog } from '../PFDialog';
import { ObjectEditor } from '../ObjectEditor';
import { LookupProject } from '../../../Entities/ContentConfiguration/Lookup/LookupValueTypes/LookupProject';
import { LookupResource } from '../../../Entities/ContentConfiguration/Lookup/LookupValueTypes/LookupResource';
import { LookupRbs } from '../../../Entities/ContentConfiguration/Lookup/LookupValueTypes/LookupRbs';
import ObjectListEditor, { ItemDialogType } from '../ObjectListEditor';
import { guid } from '../../../helpers/guid';
import { language } from '../../../Services/LocalizationService';
import { TreeEditor } from '../../../forms/_builders/tpComplexBuilder/components/tree/components/TreeEditor';
import { ITreeNode } from '../../../forms/_builders/tpComplexBuilder/components/tree/interfaces/ITreeNode';
import { treeViewRefactor } from '../../../Utilities/TreeViewRefactor';

interface IProps extends IDynamicEditorComponentProps {
    Item: Array<LookupValue>;
    Update: (value: any) => void;
    Key?: string;
    Parent: Lookup;
}

const LookupOptionsEditor: FunctionComponent<IProps> = props => {
    const [showImportDialog, setShowImportDialog] = useState<boolean>(false);
    const [importString, setImportString] = useState<string>('');
    const [lookupValues, setLookupValues] = useState<Array<LookupValue>>(props.Item);

    const OnChange = (items: Array<LookupValue>): void => {
        setLookupValues([...items]);
        props.Update([...items]);
    };

    const SpecialOnChange = (prop: string, value: any) => {
        // specials has always only one item
        const changed = [...props.Item];
        changed[0].Value[prop] = value;
        props.Update(changed);
    };

    const Import = (): void => {
        const newItems = importString.split('\n').map(_ => new LookupValue(_));
        const clone = [...props.Item];
        clone.push(...newItems);
        OnChange(clone);
        setShowImportDialog(false);
    };

    const GetEditor = (): JSX.Element => {
        if (props.Parent.LookupType === LookupType.Tree && !props.Item.length) props.Item.push(new LookupValue(new LookupTree()));
        if (props.Parent.LookupType === LookupType.Project && !props.Item.length) props.Item.push(new LookupValue(new LookupProject()));
        if (props.Parent.LookupType === LookupType.Resource && !props.Item.length) props.Item.push(new LookupValue(new LookupResource()));
        if (props.Parent.LookupType === LookupType.Rbs && !props.Item.length) props.Item.push(new LookupValue(new LookupRbs()));

        if (!props.Parent) return <></>;
        switch (props.Parent.LookupType) {
            case LookupType.String:
                return (
                    <ObjectListEditor
                        ItemIdentifier={(item: LookupValue) => item.Id}
                        ItemType={LookupValue}
                        HiddenColumnProperties={['Id']}
                        HiddenProperties={[]}
                        DisabledProperties={['Id']}
                        Items={[...lookupValues]}
                        UserSortEnabled
                        MultiSelect
                        DialogTitle={language.LookupOptionsEditor.EditValues}
                        DialogType={ItemDialogType.Dialog}
                        ChangesCallback={(items: Array<LookupValue>, item: LookupValue) => OnChange(items)}
                        ClonedItemChange={(_: LookupValue) => {
                            _.Value = `copy_${_.Value}`;
                            _.Id = guid.newGuid();
                        }}
                        AdditionalMenuItems={[
                            {
                                Title: language.Common.Import,
                                MultiSelect: true,
                                SelectionNeeded: false,
                                Action: () => {
                                    setShowImportDialog(true);
                                    return null;
                                },
                            },
                        ]}
                    />
                );
            case LookupType.Number:
                return (
                    <ObjectListEditor
                        ItemIdentifier={(item: LookupValueNumber) => item.Id}
                        ItemType={LookupValueNumber}
                        HiddenColumnProperties={['Id']}
                        HiddenProperties={[]}
                        DisabledProperties={['Id']}
                        Items={[...lookupValues]}
                        UserSortEnabled
                        MultiSelect
                        DialogTitle={language.LookupOptionsEditor.EditValues}
                        DialogType={ItemDialogType.Dialog}
                        ChangesCallback={(items: Array<LookupValueNumber>, item: LookupValueNumber) => OnChange(items)}
                        ClonedItemChange={(_: LookupValueNumber) => {
                            _.Value = `copy_${_.Value}`;
                            _.Id = guid.newGuid();
                        }}
                        AdditionalMenuItems={[
                            {
                                Title: language.Common.Import,
                                MultiSelect: true,
                                SelectionNeeded: false,
                                Action: () => {
                                    setShowImportDialog(true);
                                    return null;
                                },
                            },
                        ]}
                    />
                );
            case LookupType.Tree: {
                const tree = props.Item[0].Value as LookupTree;
                return (
                    <div className="tp-grouping-content">
                        <Dropdown
                            label={language.LookupOptionsEditor.MatchDirection}
                            disabled={props.ReadOnly}
                            options={DropdownOptionEx.enumToDropdownOptions<TreeMatchDirection>(TreeMatchDirection)}
                            defaultSelectedKey={tree.MatchDirection}
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => SpecialOnChange('MatchDirection', option.key)}
                        />
                        <TextField
                            key={props.Key}
                            label={language.LookupOptionsEditor.FullNameDelimiter}
                            maxLength={1}
                            defaultValue={tree.Delimiter}
                            onChange={(obj, value) => {
                                // update all exisiting fullnames
                                TreeItemEx.UpdateChildsRecursive(tree.Tree, (item: TreeItem) => (item.FullName = item.FullName.replace(tree.Delimiter, value)));
                                SpecialOnChange('Delimiter', value);
                            }}
                        />
                        <TreeEditor
                            readOnly={props.ReadOnly}
                            delimiter={tree.Delimiter}
                            tree={treeViewRefactor.parseToTreeNode(tree.Tree as TreeViewItem)}
                            selected={null}
                            type={'manage'}
                            onChanged={(item: ITreeNode) => SpecialOnChange('Tree', treeViewRefactor.parseToTreeView(item))}
                            dialogTitle="Edit"
                        />
                    </div>
                );
            }
            case LookupType.Project:
                return (
                    <ObjectEditor
                        Item={props.Item[0].Value as LookupProject}
                        ItemType={LookupProject}
                        DisabledProperties={[]}
                        HiddenProperties={[]}
                        ChangesCallback={(item, prop, val) => SpecialOnChange(prop, val)}
                    />
                );
            case LookupType.Resource:
                return (
                    <ObjectEditor
                        Item={props.Item[0].Value as LookupResource}
                        ItemType={LookupResource}
                        DisabledProperties={[]}
                        HiddenProperties={[]}
                        ChangesCallback={(item, prop, val) => SpecialOnChange(prop, val)}
                    />
                );
            case LookupType.Rbs:
                return (
                    <ObjectEditor
                        Item={props.Item[0].Value as LookupRbs}
                        ItemType={LookupRbs}
                        DisabledProperties={[]}
                        HiddenProperties={[]}
                        ChangesCallback={(item, prop, val) => SpecialOnChange(prop, val)}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            {GetEditor()}
            <PFDialog
                buttonText={language.Common.Import}
                maxWidth={800}
                className="tp-dialog"
                showDialog={showImportDialog}
                title={language.LookupOptionsEditor.ImportValuesFromText}
                callback={Import}
                buttonDefaultDisabled={false}
                dismissCallback={() => setShowImportDialog(false)}
            >
                <TextField
                    key={'lookupvalue-import-text'}
                    label={language.LookupOptionsEditor.ImportString}
                    multiline
                    onChange={(obj, value) => setImportString(value)}
                    autoFocus={true}
                />
            </PFDialog>
        </>
    );
};

export default LookupOptionsEditor;
