import { useMemo } from 'react';
import { useState } from 'react';
import { ProjectListItem, RootSettings } from '../../../../../../api/generated/data-contracts';
import { getPickerFields } from '../helpers';
import { generateAsStringGetters } from '../../helpers/generateAsStringGetters';
import { useDebouncedEventHandler } from '../../../../../../hooks/useDebouncedHandler';

export const useSearchedFilteredData = (data: ProjectListItem[], settings: RootSettings) => {
    const [searchInputValue, setSearchInputValue] = useState('');
    const searchFieldsAsStringGetters = useMemo(() => {
        const pickerFields = getPickerFields(settings);
        return generateAsStringGetters(['name', pickerFields.title, pickerFields.secondary, pickerFields.tertiary].filter(Boolean));
    }, [settings]);
    const searchFilteredData = useMemo(() => {
        const value = searchInputValue.trim().toLowerCase();
        if (!value) {
            return data;
        }
        return data.filter(resource =>
            searchFieldsAsStringGetters.some(getter => {
                const val = getter(resource);
                return val.toLowerCase().includes(value);
            }),
        );
    }, [data, searchFieldsAsStringGetters, searchInputValue]);
    const onChangeHandler = useDebouncedEventHandler((notUsedEvent: never, newValue: string | undefined) => {
        setSearchInputValue(newValue || '');
    });
    return [searchFilteredData, onChangeHandler, searchInputValue] as const;
};
