export default class Locale_Overview { 
	IdleChecker = {
		IdleMessage: "You have been idle for some time, and may have missed some notifications..",
		ReconnectMessage: "Reconnected to notifications..",
	}
	FailedGettingCache: string = "Failed getting cache";
	MaintenanteModeActive: string = 'TeamPlanner is in maintenance mode! Please tread lightly.. :)';
	ResourceDropZone: string = `Drop resource here to remove future [[gridtype]]s for the resource`
	Project: string = 'Project';
	Resource: string = 'Resource';
}