import { createContext } from '../../../hooks/createContext';

// import { createContext } from '../../../hooks/createContext';
export type OverviewCreatorContext<T = unknown> = {
	selectedItems: T[];
	item: T | null;
	dismissPanel: () => void;
	/**
	 * Starts the loading, calls and waits for onClickSave (if provided) 
	 * and returns a promise which resolves to the saved item after onClickSave is done with it
	 * 
	 * If called with an item, onClickSave will be called with this, otherwise it will fallback to the item in the context
	 */
	saveItem: (item?: T) => Promise<T>
	items: T[]
	loading: boolean;
	savingItem: boolean;
	setItem: React.Dispatch<React.SetStateAction<T | null>>;
	setValidation: (validationFunc: () => Promise<Record<string, string>> | undefined) => void;
};
const [useCtx, Provider] = createContext<OverviewCreatorContext>();

// eslint-disable-next-line react-refresh/only-export-components
export const useOverviewContext = <T = unknown>() => useCtx() as OverviewCreatorContext<T>;
export const OverviewContextProvider = Provider;
