export const functionThatReturnsTrue = () => true;

export const functionThatReturnsFalse = () => false;

export const noopFunction = () => {};

export const functionThatReturnsSingleParameter = <T>(x: T) => x;

export const asyncFunctionThatReturnsTrue = async () => true;

export const asyncFunctionThatReturnsFalse = async () => false;

export const asyncNoopFunction = async () => {};

export const asyncFunctionThatReturnsSingleParameter = async <T>(x: T) => x;

/**
 * Useful if the compiler complains about function signatures when using e.g. noopFunction
 * @param fn any function
 * @example
 * const noopFunction = () => {};
 * const defaultCallback = typecastFunction<(a: string, b: string) => void>(noopFunction);
 * // defaultCallback: (a: string, b: string) => void
 *
 * const defaultCallback = typecastFunction(noopFunction);
 * // If no signature is provided, the function gets typecasted to
 * // defaultCallback: (...args: any[]) => any
 */
export const typecastFunction = <Signature extends (...args: any[]) => any>(fn: (...args: any[]) => any) => {
    return fn as unknown as Signature;
};
