import React, { useEffect, useState, useMemo } from 'react';
import { IDropdownOption, DropdownMenuItemType, IStyleFunctionOrObject, IDropdownStyleProps, IDropdownStyles, Dropdown } from '@fluentui/react';
import { IFormItem, IFormSchema } from 'spark-forms';
import { CustomProperty } from '../api/generated/data-contracts';
import { language } from '../Services/LocalizationService';
import { DropdownOptionEx } from '../Utilities/DropdownOptionEx';
import { schemaHelper } from '../forms/_builders/new/helpers/schemaHelper';

interface IProps<T extends IFormItem> { 
	schema: IFormSchema<T>;
	customProperties?: CustomProperty[];
	propertyTypes: "fields" | "properties" | "both";
	onChange: (value: string | string[]) => void;
	value: string | string[];
	multiSelect?: boolean;
	filterSystemProperties?: boolean;
	filterArrayProperties?: boolean;
	hiddenProperties?: string[];
	onlyTheseProperties?: string[];
	removePrefixFromProperties?: boolean;
	placeHolder?: string;
	styles?: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
	label?: string;
	// searchable?: boolean;
	disabled?: boolean;
}

export const EntityPropertySelector = <T extends IFormItem>({ propertyTypes, schema, customProperties, disabled, onChange, value, filterSystemProperties, filterArrayProperties, hiddenProperties, multiSelect, placeHolder, styles, onlyTheseProperties }: React.PropsWithChildren<IProps<T>>) : React.ReactElement | null => {

	const [localValue, setLocalValue] = useState<string | string[]>(value);

	const [propertyOptions, setPropertyOptions] = useState<IDropdownOption[]>([]);
	
	const fields = useMemo(() => schemaHelper.getFields<T>(schema, { filterArrayProperties, filterSystemProperties, hiddenProperties, onlyTheseProperties })
	, [filterArrayProperties, filterSystemProperties, hiddenProperties, onlyTheseProperties, schema])

	const properties = useMemo(() => schemaHelper.getCustomProperties<T>(customProperties, { filterArrayProperties, filterSystemProperties, hiddenProperties, onlyTheseProperties })
	, [customProperties, filterArrayProperties, filterSystemProperties, hiddenProperties, onlyTheseProperties])

	useEffect(() => {
		const fieldHeader = [{ key: 'fieldsHeader', text: language.Common.Fields, itemType: DropdownMenuItemType.Header } as IDropdownOption ];
		const propertiesHeader = [{ key: 'propertiesHeader', text: language.Common.Properties, itemType: DropdownMenuItemType.Header } as IDropdownOption ];

		const fieldOptions = DropdownOptionEx.toDropdownOptions(fields, _ => _.internalName, _ => _.displayName);
		const customOptions = DropdownOptionEx.toDropdownOptions(properties, _ => `customProperties|${_.internalName}|`, _ => _.displayName);

		let propOptions = [];
		if (propertyTypes === 'fields' || propertyTypes === 'both') {
			propOptions = propOptions.concat(fieldHeader).concat(fieldOptions);
		}
		if (propertyTypes === 'properties' || propertyTypes === 'both') {
			propOptions = propOptions.concat(propertiesHeader).concat(customOptions)
		}
		
		setPropertyOptions(propOptions);
	}, [customProperties, fields, properties, propertyTypes])

	
	const change = (option: IDropdownOption) => {
		const value = option.key as string;
		if (!multiSelect) {
			onChange(value);
			setLocalValue(value);
			return;
		}
		const clone = localValue != null ? [...localValue] : [];
        if (option.selected) {
			clone.push(value);
		} else {
            const currIndex = clone.indexOf(value);
            if (currIndex > -1) {
                clone.splice(currIndex, 1);
            }
		}

		onChange(clone);
		setLocalValue(clone);
	}

	return (
		<Dropdown
			options={propertyOptions}
			multiSelect={multiSelect}
			placeholder={placeHolder}
			selectedKey={ multiSelect ? null : localValue as string }
			selectedKeys={ multiSelect ? localValue as any[] ?? [] : null }
			styles={styles}
			onChange={(_ev, option) => change(option)}
			disabled={disabled}
		/>
	)
}

export const EntityPropertySelector2 = <T extends IFormItem>({ propertyTypes, schema, customProperties, disabled, onChange, value, filterSystemProperties, filterArrayProperties, hiddenProperties, multiSelect, placeHolder, styles, onlyTheseProperties }: React.PropsWithChildren<IProps<T>>) : React.ReactElement | null => {

	const [localValue, setLocalValue] = useState<string | string[]>(value);

	const [propertyOptions, setPropertyOptions] = useState<IDropdownOption[]>([]);
	
	const fields = useMemo(() => schemaHelper.getFields<T>(schema, { filterArrayProperties, filterSystemProperties, hiddenProperties, onlyTheseProperties })
	, [filterArrayProperties, filterSystemProperties, hiddenProperties, onlyTheseProperties, schema])

	const properties = useMemo(() => schemaHelper.getCustomProperties<T>(customProperties, { filterArrayProperties, filterSystemProperties, hiddenProperties, onlyTheseProperties })
	, [customProperties, filterArrayProperties, filterSystemProperties, hiddenProperties, onlyTheseProperties])

	useEffect(() => {
		const fieldHeader = [{ key: 'fieldsHeader', text: language.Common.Fields, itemType: DropdownMenuItemType.Header } as IDropdownOption ];
		const propertiesHeader = [{ key: 'propertiesHeader', text: language.Common.Properties, itemType: DropdownMenuItemType.Header } as IDropdownOption ];

		const fieldOptions = DropdownOptionEx.toDropdownOptions(fields, _ => _.internalName, _ => _.displayName);
		const customOptions = DropdownOptionEx.toDropdownOptions(properties, _ => `customProperties.${_.internalName}`, _ => _.displayName);

		let propOptions = [];
		if (propertyTypes === 'fields' || propertyTypes === 'both') {
			propOptions = propOptions.concat(fieldHeader).concat(fieldOptions);
		}
		if (propertyTypes === 'properties' || propertyTypes === 'both') {
			propOptions = propOptions.concat(propertiesHeader).concat(customOptions)
		}
		
		setPropertyOptions(propOptions);
	}, [customProperties, fields, properties, propertyTypes])

	
	const change = (option: IDropdownOption) => {
		const value = option.key as string;
		if (!multiSelect) {
			onChange(value);
			setLocalValue(value);
			return;
		}
		const clone = localValue != null ? [...localValue] : [];
        if (option.selected) {
			clone.push(value);
		} else {
            const currIndex = clone.indexOf(value);
            if (currIndex > -1) {
                clone.splice(currIndex, 1);
            }
		}

		onChange(clone);
		setLocalValue(clone);
	}

	return (
		<Dropdown
			options={propertyOptions}
			multiSelect={multiSelect}
			placeholder={placeHolder}
			selectedKey={ multiSelect ? null : localValue as string }
			selectedKeys={ multiSelect ? localValue as any[] ?? [] : null }
			styles={styles}
			onChange={(_ev, option) => change(option)}
			disabled={disabled}
		/>
	)
}