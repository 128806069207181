import { guid } from "../../helpers/guid";
import { Rbs } from "../Rbs";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { IContentConfiguration } from "./ContentConfiguration";
import { ContentConfigurationType } from "./ContentConfigurationType";
import { RbsEx } from "../../Utilities/RbsEx";
import { PFPropertyGrouping } from "../../Utilities/PropertyInfo/Grouping";
import { IObjectEditorItemType } from "../Main/IObjectEditorItemType";

export class RbsStructure implements IContentConfiguration, IObjectEditorItemType {
	public typeName: string = "ContentConfiguration";

	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.Name", 1)
	public Name: string;
	// @PFPropertyBasicInfo(PropertyType.String, "Delimiter", 2)
	// @PFPropertyRender(null, {maxLength: 1})
	// public Delimiter: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Rbs", 3)
	@PFPropertyRender((item: RbsStructure, idx, col) => RbsEx.count(item.Rbs)+"", { Type: "manage" }, "RbsEditor")
	public Rbs: Rbs;

	// SYSTEM
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.Id", 1)
	@PFPropertyGrouping("System", 100)
	public id: string;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Created", 2)
	@PFPropertyGrouping("System")
	public created: Date;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Modified", 3)
	@PFPropertyGrouping("System")
	public modified: Date;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.CreatedBy", 4)
	@PFPropertyGrouping("System")
	public createdBy: string;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.ModifiedBy", 5)
	@PFPropertyGrouping("System")
	public modifiedBy: string;

	public SettingsOwnerId: string;
	public ContentConfigurationType: ContentConfigurationType;
	
	constructor() {
		this.id = guid.newGuid();
		this.ContentConfigurationType = ContentConfigurationType.Rbs;
		this.Rbs = RbsEx.createRoot();

	}
}