import { fluentUiValidationMessageElement } from '@wiberg/fluentui-builder';
import { IFormItem, IPropertyRenderProps } from '@wiberg/formbuilder';
import { PropsWithChildren, ReactElement, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { ApiCalls } from '../../../../api/api';
import { GetDto, EntityType } from '../../../../api/generated/data-contracts';
import { useUISettings } from '../../../../context/network/http/QueryProvider/queries/UISettings';
import { useStore } from '../../../../context/store';
import { IRbsFieldConfig } from './config/IRbsFieldConfig';
import { TreeEditor } from './tree/components/TreeEditor';
import { ITreeNode } from './tree/interfaces/ITreeNode';

interface IProps<T extends IFormItem> extends IPropertyRenderProps<T, IRbsFieldConfig<T>, string | string[] | ITreeNode | undefined> {
    config?: IRbsFieldConfig<T>;
}

// TODO: somehow the first time it's used in the 'EntityFilterEditor' it's multiselect - until clicking ok the first time (ewi)
export const RbsField = <T extends IFormItem>({ value, config, disabled, onChange, errorMessage }: PropsWithChildren<IProps<T>>): ReactElement | null => {
    const [selected, setSelected] = useState<string | string[] | undefined>();

    const { data: uiSettings } = useUISettings();

    const setBlockDialog = useStore(store => store.setBlockDialog);

    useEffect(() => {
        if (config.type === 'manage') setSelected(null);
        else setSelected(value as string | string[]);
    }, [config.type, value]);

    const { data: rbs } = useQuery(['rbsfield', uiSettings.settings.rbsId], async () => {
        try {
            if (config.type === 'manage') {
                return value as ITreeNode;
            } else {
                const dto = {
                    entity: EntityType.ContentConfiguration,
                    id: uiSettings.settings.rbsId,
                } as GetDto;
                const response = await ApiCalls.getEntity(dto);
                const structure = response.data as any;
                return structure.rbs;
            }
        } catch (err) {
            setBlockDialog(null, {
                title: 'Error',
                subText: typeof err === 'string' ? err : err.message,
            });
        }
    });

    if (rbs == null) return null;

    return (
        <>
            <TreeEditor
                tree={config.type === 'manage' ? (value as ITreeNode) : rbs}
                type={config.type}
                onChanged={onChange}
                delimiter={config.delimiter}
                buttonTitle={config.buttonTitle}
                dialogTitle={config.dialogTitle}
                multiSelect={config.multiSelect}
                selected={selected}
                readOnly={disabled}
                defaultExpandedDepth={config.defaultExpandedDepth}
                autoExpandSelected
            />
            {errorMessage && fluentUiValidationMessageElement(errorMessage)}
        </>
    );
};
