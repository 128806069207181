import { fluentUiValidationMessageElement } from '@wiberg/fluentui-builder';
import { IFormItem, IPropertyRenderProps, useIsMounted } from '@wiberg/formbuilder';
import { PropsWithChildren, ReactElement, useState, useEffect } from 'react';
import { ITreeNode } from './tree/interfaces/ITreeNode';
import { ITreeFieldConfig } from './config/ITreeFieldConfig';
import { TreeEditor } from './tree/components/TreeEditor';
import { changeCase } from '../../../../helpers/changeKeysCase';

interface IProps<T extends IFormItem> extends IPropertyRenderProps<T, ITreeFieldConfig<T>, string | string[] | ITreeNode | undefined> {
    config?: ITreeFieldConfig<T>;
}

export const TreeField = <T extends IFormItem>({ value, config, disabled, onChange, errorMessage }: PropsWithChildren<IProps<T>>): ReactElement | null => {
    const [tree, setTree] = useState<ITreeNode>();
    const [selected, setSelected] = useState<string | string[] | undefined>();
    const isMounted = useIsMounted();

    useEffect(() => {
        if (config.type === 'manage') setSelected(null);
        else setSelected(value as string | string[]);
    }, [config.type, value]);

    useEffect(() => {
        const fetchLookups = async () => {
            const treeItem = await changeCase.toCamel(config.treeGetter()); // why the fuck do I need to do this (ewi)
            if (!isMounted()) return;
            setTree(treeItem);
        };
        // skip fetch of tree
        if (config.type === 'manage') {
            setTree(value as ITreeNode);
        } else fetchLookups();
    }, [value, config.treeGetter, config.type, isMounted, config]);

    if (tree == null) return null;

    return (
        <>
            <TreeEditor
                tree={config.type === 'manage' ? (value as ITreeNode) : tree}
                type={config.type}
                onChanged={onChange}
                delimiter={config.delimiter}
                buttonTitle={config.buttonTitle}
                dialogTitle={config.dialogTitle}
                multiSelect={config.multiSelect}
                selected={selected}
                readOnly={disabled}
                defaultExpandedDepth={config.defaultExpandedDepth}
                autoExpandSelected
            />
            {errorMessage && fluentUiValidationMessageElement(errorMessage)}
        </>
    );
};
