import { IFormItemOptions, IFormSchema, ISchemaProvider, ValidationMark, ValidationEventType, } from '@wiberg/formbuilder';
import { IMoveAllActivitiesDto } from './IMoveAllActivitiesDto';
import { tpPropertyOptionsFactory as factory } from '../../../../../forms/_builders/tpPropertyOptionsFactory';
import { language } from '../../../../../Services/LocalizationService';
import { guid } from '../../../../../helpers/guid';
import { ResourceType } from '../../../../../Entities/ResourceType';

export const moveAllActivitiesSchema: ISchemaProvider<IMoveAllActivitiesDto> = {
    key: 'moveAllActivitiesSchema',
    getSchema: async (dynamicKey: "request" | "allocation" | "both"): Promise<IFormSchema<IMoveAllActivitiesDto>> => {
        return {
			options: {
				validation: {
					validationRules: {
						targetResourceId: {
							id: '3Ydwe',
							validationMark: ValidationMark.Required,
							validationRule: item => guid.isGuidNotEmpty(item.targetResourceId),
							validationMessage: language.MoveAllAllocationsDto.TargetResourceRequired,
							validateOn: ValidationEventType.Blur | ValidationEventType.Manual,
						}
					},
				},
				properties: {
					sourceResourceId: factory.resource({
						displayName: language.MoveAllAllocationsDto.SourceResource,
						disable: () => true,
						config: {
							nameField: "name",
							descriptionField: "email",
							resourceTypes: [ ResourceType.Named, ResourceType.Category ] //EnumEx.all(ResourceType)
						}
					}),
					targetResourceId: factory.resource({
						displayName: language.MoveAllAllocationsDto.TargetResource,
						config: {
							nameField: "name",
							descriptionField: "email",
							excludeInactive: true,
							matchOnRbs: true,
							resourceTypes: dynamicKey === 'request' ? [ ResourceType.Generic ] : [ ResourceType.Named, ResourceType.Category ]
						}
					}),
				},
			} as IFormItemOptions<IMoveAllActivitiesDto>,
		} as unknown as IFormSchema<IMoveAllActivitiesDto>;
    },
};
