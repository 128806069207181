import 'reflect-metadata';
import { PropertyInfoAreas } from './PropertyInfoAreas';
import { ValidationRule } from '../../Entities/Validation/ValidationRule';

export class PropertyValidation { 
	constructor(public ValidationRule: ValidationRule) {}
	public static PropertyInfoKey: PropertyInfoAreas = PropertyInfoAreas.Validation;
}

export const PFPropertyValidation = (errorText: string, action: (obj: any) => boolean, descriptionText?: (obj: any) => string, required?: (parent: any) => boolean): PropertyDecorator => {
    return (target, property) => {
      const classConstructor = target.constructor;
	  const metadata = Reflect.getMetadata(PropertyValidation.PropertyInfoKey, classConstructor) || {};
	  if(property as string === "Custom Properties") {
		  metadata[property] = null;
		  action = null;
	  } else
      	metadata[property] = new PropertyValidation(new ValidationRule(property as string, action, errorText, descriptionText, required));
      Reflect.defineMetadata(PropertyValidation.PropertyInfoKey, metadata, classConstructor);
    };
};
