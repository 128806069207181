import { useMemo } from "react";
import { useLocation } from "react-router";

export enum MainView {
    Empty = 0,
    ProjectCapacity = 1,
    ResourceCapacity = 4,
    ReportViewer = 5,
    // ChartViewer = 6,
    ResourceOverview = 7,
    ProjectOverview = 8,
    AdminDashboard = 9,
    LmCapacity = 11,
    WorkPackages = 12,
    ContentSettings = 13,
    CompareScenariosCapacity = 14,
    DraftVersionedCapacity = 15,
	ProjectPlanner = 16,
}

export const getRouteFromEnum = (view: MainView) => {
    switch (view) {
        case MainView.ProjectCapacity: {
			return 'request'; // what to do here? (ewi) (legacy)
        }
        case MainView.ProjectOverview: {
            return 'projectoverview';
        }
        case MainView.ContentSettings: {
            return 'contentsettings';
        }
        case MainView.AdminDashboard: {
            return 'admindashboard';
        }
        case MainView.WorkPackages: {
            return 'workpackages';
        }
        case MainView.ResourceOverview: {
            return 'resourceoverview';
        }
        case MainView.ResourceCapacity: {
            return 'resourcecapacity';
        }
        case MainView.LmCapacity: {
            return 'planner';
        }
		case MainView.ReportViewer: {
            return 'reportviewer';
        }
		case MainView.ProjectPlanner: {
            return 'projectplanner';
        }
        default: {
            return '';
        }
    }
};

export const getEnumFromRoute = (rawPath: string) => {
	// The first char of location.pathname is always "/",
	// start searching for the next "/" from char 1 and onwards
	let endSlashIndex = rawPath.indexOf("/", 1);
	if (endSlashIndex < 0) endSlashIndex = rawPath.length;
	const path = rawPath.slice(1, endSlashIndex);
	// const path = rawPath.slice(1, rawPath.indexOf("/", 1))

	// // Cut off the first slash "/whatever/"
	// let path = rawPath.slice(rawPath.indexOf("/") + 1);
	// // Cut off the next slash so we end up with the root path;
	// const endIndex = path.indexOf("/") >= 0
	// 					? path.indexOf("/")
	// 					: path.length;
	// path = path.slice(0, endIndex);
    switch (path) {
        case 'request': {
			return MainView.ProjectCapacity;
        }
		case 'allocation': {
			return MainView.ProjectCapacity;
        }
        case 'projectoverview': {
            return MainView.ProjectOverview;
        }
        case 'contentsettings': {
            return MainView.ContentSettings;
        }
        case 'admindashboard': {
            return MainView.AdminDashboard;
        }
        case 'workpackages': {
            return MainView.WorkPackages;
        }
        case 'resourceoverview': {
            return MainView.ResourceOverview;
        }
        case 'resourcecapacity': {
            return MainView.ResourceCapacity;
        }
        case 'planner': {
            return MainView.LmCapacity;
        }
		case 'reportviewer': {
            return MainView.ReportViewer;
        }
		case 'projectplanner': {
			return MainView.ProjectPlanner
		}
        // default: {
        //     return null;
        // }
        default: {
            return MainView.Empty;
        }
    }
};

export const useRouteEnum = () => {
	const { pathname } = useLocation();
	return useMemo(() => getEnumFromRoute(pathname), [pathname])
}