import React, { useCallback, useMemo, useRef } from 'react';
import { GridType } from '../../../../../Entities/GridType';
import { language } from '../../../../../Services/LocalizationService';
import EnumEx from '../../../../../Utilities/EnumEx';
import { useStore } from '../../../../../context/store';
import { useEffect, useState } from 'react';
import { useUISettings } from '../../../../../context/network/http/QueryProvider/queries/UISettings';
import { useTPPermissions } from '../../../../../hooks/usePermissions';
import { useQuery, useQueryClient } from 'react-query';
import { Breadcrumb, Checkbox, IBreadcrumbItem, SearchBox, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { ResultList } from './components/ResultList/ResultList';
import { FilterList } from '../components/FilterList/FilterList';
import { buildRbsTree } from '../helpers/buildRbsTree';
import { makeResourceFilterDefaults, resolveResourceFieldName } from './helpers';
import { BreadcrumbGroupTitle, TFilter } from '../types';
import { useSearchedFilteredData } from './hooks/useSearchedFilteredData';
import { useCurrentResourceCategory } from './hooks/useCurrentResourceCategory';
import { ResourceListItem, RootSettings, UserType } from '../../../../../api/generated/data-contracts';
import { ListItemShimmer } from '../components/ListItemShimmer';
import { ResourceType } from '../../../../../Entities/ResourceType';
import { ApiCalls } from '../../../../../api/api';
import { useControlledState } from '../../../../../hooks/useControlledState';
import { functionThatReturnsTrue } from '../../../../../helpers/functionPrimitives';

type ResourcePickerProps = {
    setRequestNamedResources: React.Dispatch<React.SetStateAction<boolean>>;
    requestNamedResources: boolean;
    isFetching: boolean;
    setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
    data: ResourceListItem[];
    queryKey: readonly [string, any];
    showAll: boolean;
    setShowAll: React.Dispatch<React.SetStateAction<boolean>>;
    gridType: GridType;
    isFetched: boolean;
};

export const ResourcePicker: React.FC<ResourcePickerProps> = ({
    setIsDragging,
    data,
    isFetching,
    requestNamedResources,
    setRequestNamedResources,
    queryKey,
    setShowAll,
    showAll,
    gridType,
    isFetched,
}) => {
    const { data: uiSettings } = useUISettings();
    const delegationMode = useStore(store => store.ui.delegationMode);
    const isSuperuser = useTPPermissions({ userTypes: [UserType.SuperUser] });

    const panelGroupings = useMemo(() => {
        if (EnumEx.equalsAny(gridType, GridType.Allocation, GridType.LmCapacityProjects, GridType.ResourceCapacity)) {
            // return uiSettings.settings.resourcePanelAllocationGroupings || [];

			// Custom properties might exist and might not. Make sure we only work with properties that actually exists
            return uiSettings.settings.resourcePanelAllocationGroupings?.filter(grouping => Boolean(resolveResourceFieldName(grouping, uiSettings.settings))) || [];
        }
        if (EnumEx.equalsAny(gridType, GridType.Request, GridType.WorkPackage)) {
            // return uiSettings.settings.resourcePanelRequestGroupings || [];
			
			// Custom properties might exist and might not. Make sure we only work with properties that actually exists
            return uiSettings.settings.resourcePanelRequestGroupings?.filter(grouping => Boolean(resolveResourceFieldName(grouping, uiSettings.settings))) || [];
        }
        return [];
    // }, [gridType, uiSettings.settings.resourcePanelAllocationGroupings, uiSettings.settings.resourcePanelRequestGroupings]);
    }, [gridType, uiSettings.settings]);

    const [filters, setFilters] = useState<TFilter[]>(() => makeResourceFilterDefaults(panelGroupings));
    const [breadcrumbTitles, setBreadcrumbTitles] = useControlledState(
        () => makeBreadcrumbDefaultTitles(panelGroupings, uiSettings.settings),
        [panelGroupings, uiSettings.settings],
    );

    const titles = useMemo(
        () =>
            panelGroupings.map(grouping => {
                return resolveResourceFieldName(grouping, uiSettings.settings) || (grouping === 'rbs' ? 'RBS' : grouping);
            }),
            // panelGroupings.reduce((groupings, grouping) => {
            //     // const name = resolveResourceFieldName(grouping, uiSettings.settings) || (grouping === 'rbs' ? 'RBS' : grouping);
            //     const name = grouping === 'rbs' ? 'RBS' : resolveResourceFieldName(grouping, uiSettings.settings);
			// 	if (name) {
			// 		groupings.push(name)
			// 	}
			// 	return groupings
            // }, [] as string[]),
        [panelGroupings, uiSettings.settings],
    );

    const [currentFilterLevel, setCurrentFilterLevel] = useState(0);

    const currentTitle = titles[currentFilterLevel - 1];

    const queryClient = useQueryClient();
    const skipNextReset = useRef(false);
    const updateItem = useCallback(
        (updatedResource: ResourceListItem) => {
            skipNextReset.current = true;
            queryClient.setQueryData<ResourceListItem[]>(queryKey, resources =>
                resources.map(resource => (resource.id === updatedResource.id ? updatedResource : resource)),
            );
        },
        [queryClient, queryKey],
    );

    const filteredData = useMemo(() => {
        return filters.slice(0, currentFilterLevel).reduce((acc, { filterFn }) => acc.filter(filterFn), data);
    }, [currentFilterLevel, data, filters]);

    const updateFilterState = useCallback(
        (grouping: string, filterFn: (resource: ResourceListItem) => boolean, breadcrumbText: string, filterDirection: number = 1) => {
            setFilters(filters =>
                filters.map(filter => {
                    if (filter.grouping === grouping) {
                        return { ...filter, filterFn };
                    }
                    return filter;
                }),
            );
            setBreadcrumbTitles(bcTitles =>
                bcTitles.map(bcTitle => {
                    if (bcTitle.grouping === grouping) {
                        return { ...bcTitle, title: breadcrumbText };
                    }
                    return bcTitle;
                }),
            );
            if (filterDirection) {
                setCurrentFilterLevel(level => level + filterDirection);
            }
        },
        [setBreadcrumbTitles],
    );

    const { currentCategory, resetCurrentRBSPath } = useCurrentResourceCategory({
        filterLevel: currentFilterLevel,
        data: filteredData,
        filters,
        settings: uiSettings.settings,
        panelGroupings,
        breadcrumbTitles,
        updateFilterState,
    });

    const breadcrumbItems = useMemo(() => {
        return [
            {
                key: 'root',
                text: '/',
                onClick: () => {
                    setCurrentFilterLevel(1);
                    setFilters(makeResourceFilterDefaults(panelGroupings));
                    resetCurrentRBSPath();
                    setBreadcrumbTitles(makeBreadcrumbDefaultTitles(panelGroupings, uiSettings.settings));
                },
            },
            ...filters.slice(0, currentFilterLevel - 1).map((_, slicedFilterIndex) => {
                const { title } = breadcrumbTitles[slicedFilterIndex];
                return {
                    key: title + slicedFilterIndex,
                    text: title,
                    onClick: () => {
                        setCurrentFilterLevel(slicedFilterIndex + 1);
                        setFilters(filters => {
                            return filters.map((filter, filterIndex) => {
                                if (filterIndex >= slicedFilterIndex) {
                                    return {
                                        ...filter,
                                        filterFn: functionThatReturnsTrue,
                                    };
                                }
                                return filter;
                            });
                        });
                    },
                };
            }),
        ] as IBreadcrumbItem[];
    }, [filters, currentFilterLevel, panelGroupings, resetCurrentRBSPath, setBreadcrumbTitles, uiSettings.settings, breadcrumbTitles]);

    useEffect(() => {
        if (!skipNextReset.current) {
            setFilters(makeResourceFilterDefaults(panelGroupings));
            setCurrentFilterLevel(1);
            resetCurrentRBSPath();
        }
        skipNextReset.current = false;
    }, [uiSettings.settings, panelGroupings, gridType, resetCurrentRBSPath]);

    const [searchGenericResourcesByName, setSearchGenericResourcesByName] = useState(false);

    const queryKeyForNamedResources = useMemo(() => {
        const [keyString, keyObject] = queryKey;
        return [
            keyString,
            'named-resources',
            { ...keyObject, selectAll: false, resourceTypes: [ResourceType.Named], matchRbs: false, delegationMode: false, genericResources: null },
        ] as const;
    }, [queryKey]);

    const { data: namedResources, isFetching: fetchingNamedResources } = useQuery(
        queryKeyForNamedResources,
        async ({ queryKey: [string, string2, reqObject] }) => {
            try {
                return ApiCalls.getResourcesForPanel(reqObject).then(res => res.data);
            } catch (error) {
                // The error is already handled before this
                return [];
            }
        },
        {
            placeholderData: useRef([] as ResourceListItem[]).current,
            enabled: searchGenericResourcesByName,
        },
    );

    const [searchFilteredData, searchOnChange, searchText] = useSearchedFilteredData({
        data: filteredData,
        namedResources,
        gridType,
        settings: uiSettings.settings,
        searchGenericResourcesByName,
        delegationMode,
    });

    const handleSearchAllClick = useCallback(
        (_: never, checked?: boolean) => {
            if (checked) {
                setSearchGenericResourcesByName(false);
            }
            // setShowAll(checked)
            setRequestNamedResources(checked);
        },
        [setRequestNamedResources],
    );

    const handleSearchGenericResourceClick = useCallback(
        (_: never, checked?: boolean) => {
            if (checked) {
                // setShowAll(false)
                setRequestNamedResources(false);
            }
            setSearchGenericResourcesByName(checked);
        },
        [setRequestNamedResources],
    );

    const rbsTree = useMemo(() => {
        return buildRbsTree(data);
    }, [data]);

    return (
        <Stack styles={{ root: { height: '100%' } }} tokens={{ childrenGap: 10 }}>
            <SearchBox className="tp-panel-searchbox tp-searcher" onChange={searchOnChange} autoFocus />
            <Stack horizontal horizontalAlign="space-between" verticalAlign="start" tokens={{ padding: '0 24px 0 0' }}>
                <Stack styles={{ root: { minHeight: 35 } }}>
                    {EnumEx.equalsAny(gridType, GridType.Allocation, GridType.LmCapacityProjects, GridType.ResourceCapacity) &&
                    (isSuperuser || uiSettings.settings.allowSearchAllResources) &&
                    !delegationMode ? (
                        <Checkbox
                            className="tp-list-checkbox"
                            label={language.Resources.SearchAllResources}
                            onChange={(e, checked) => setShowAll(checked)}
                            disabled={isFetching || fetchingNamedResources}
                            checked={showAll}
                        />
                    ) : null}
                    {uiSettings.settings.enableRequestNamedResources && gridType === GridType.Request && !delegationMode && (
                        <Checkbox
                            label={language.Resources.RequestBy}
                            className="tp-list-checkbox"
                            checked={requestNamedResources}
                            onChange={handleSearchAllClick}
                            disabled={isFetching || fetchingNamedResources}
                        />
                    )}
                    {uiSettings.settings.enableSearchNamedResources && gridType === GridType.Request && !delegationMode && (
                        <Checkbox
                            label={language.Resources.SearchByNamed}
                            className="tp-list-checkbox"
                            checked={searchGenericResourcesByName}
                            onChange={handleSearchGenericResourceClick}
                            disabled={isFetching || fetchingNamedResources}
                        />
                    )}
                </Stack>
                {(isFetching || fetchingNamedResources) && <Spinner size={SpinnerSize.large} labelPosition="right" label="Retrieving data.." />}
            </Stack>
            <Stack>
                {Boolean(panelGroupings.length) && (
                    <>
                        <Breadcrumb
                            styles={{ root: { '.ms-Breadcrumb-listItem:last-child .ms-Breadcrumb-itemLink': { fontWeight: 'inherit' } } }}
                            items={breadcrumbItems}
                        />
                        <Text variant="xLarge">{currentTitle || (searchFilteredData.length === 1 ? 'Result' : 'Results')} &nbsp;</Text>
                        <FilterList
                            // gridType as key ensures that the filter resets when the view changes
                            key={gridType}
                            category={currentCategory}
                            rbsTree={rbsTree}
                            setCurrentFilterLevel={setCurrentFilterLevel}
                        />
                    </>
                )}

                <Text>
                    {searchFilteredData.length} {searchFilteredData.length === 1 ? 'result' : 'results'}
                </Text>
            </Stack>
            {!isFetched && (
                <>
                    <ListItemShimmer />
                    <ListItemShimmer opacity={0.5} />
                </>
            )}
            <ResultList
                items={searchFilteredData}
                delegationMode={delegationMode}
                gridType={gridType}
                settings={uiSettings.settings}
                showAll={showAll}
                updateItem={updateItem}
                setIsDragging={setIsDragging}
                searchText={searchText}
            />
        </Stack>
    );
};

const makeBreadcrumbDefaultTitles = (panelGroupings: string[], settings: RootSettings) =>
    panelGroupings.map(grouping => {
        return {
            title: resolveResourceFieldName(grouping, settings) || (grouping === 'rbs' ? 'RBS' : grouping),
            grouping,
        } as BreadcrumbGroupTitle;
    });
