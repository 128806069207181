/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, useRef, memo } from 'react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { CustomPropertyUtil } from '../../../Utilities/CustomPropertyUtil';
import CustomPropertyRender from '../../../Utilities/CustomPropertyRender';
import { DebugOverrides } from '../../../Utilities/DebugOverrides';

interface IProps extends IDynamicEditorComponentProps {
    Item: { [key: string]: any };
    Update: (properties: { [key: string]: any }) => void;
    Key?: string;
    Type: 'resource' | 'project' | 'contract';
}

const CustomPropertiesSelector: FunctionComponent<IProps> = props => {
    const item = useRef<{ [key: string]: any }>({ ...props.Item });

    const OnChange = (prop: string, value: any) => {
        const copy = { ...item.current } || {};
        copy[prop] = value;
        item.current = copy;
        props.Update(copy);
    };

    return (
        <div className="tp-grouping-content">
            {CustomPropertyUtil.GetProperties(props.Type).map(_ => {
                return (
                    <CustomPropertyRender
                        key={_.Id}
                        Item={item.current[_.Id]}
                        Parent={props.Parent}
                        Property={_}
                        Type={props.Type}
                        Update={value => OnChange(_.Id, value)}
                        ReadOnly={props.ReadOnly && !DebugOverrides.Enabled}
                        Required={_.Required}
                        OverrideDisabledProps={DebugOverrides.Enabled}
                    />
                );
            })}
        </div>
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Item === nextProps.Item;
};

export default memo(CustomPropertiesSelector, comparisonFn);
