export default class Locale_ContentSettings { 
	SaveButton: string = 'Save';
	Save = {
		Lookups: `Lookup saved!`,
		RBS: `RBS saved!`,
		WorkProfiles: `Work Profile saved!`,
		Announcements: `Announcement saved!`,
		MailTemplates: `Mail Template saved!`,
		Unsaved: 'You have unsaved changes'
	}
	Delete = {
		Lookups: `Lookup deleted!`,
		RBS: `RBS deleted!`,
		WorkProfiles: `Work Profile deleted!`,
		Announcements: `Announcement deleted!`,
		MailTemplates: `Mail Template deleted!`,
		Unsaved: 'You have unsaved changes'
	}
	HeaderText = {
		Lookups: 'Lookups',
		RBS: 'RBS Structures',
		WorkProfiles: 'Work Profiles',
		Announcements: 'Announcements',
		MailTemplates: 'Mail Templates',
	}
	MenuItemLookup = {
		StringLookup: 'String lookup',
		NumberLookup: 'Number lookup',
		TreeLookup: 'Tree lookup',
		ResourceLookup: 'Resource lookup (in preview - no guarantees!)',
		ProjectLookup: 'Project lookup (in preview - no guarantees!)',
		RBSLookup: 'Rbs (in preview - no guarantees!)',
	}
}