import { useCallback, useLayoutEffect, useState, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import shallow from 'zustand/shallow';
import { ApiCalls } from '../../../../api/api';
import { PROJECT_PLANNER } from '../../../../context/network/http/QueryProvider/queryKeys';
import { useStore } from '../../../../context/store';
import { useMemoQueryKey } from '../../../../context/network/http/QueryProvider/queries/useMemoQueryKey';
import { usePrevious } from '../../../../hooks/usePrevious';
import { GridData, GridViewFilters, UnitType } from '../../../../api/generated/data-contracts';
import { useSelectedCustomColumnKeysValue } from '../components/Grid/context/customColumnContext';

const useDebouncedState = <T>(state: T, timeout = 1000) => {
    const [debouncedState, setDebouncedState] = useState(state);
    const timer = useRef<NodeJS.Timeout  | null>(null);
    useLayoutEffect(() => {
        if (state !== debouncedState) {
            const _timer = timer;
            _timer.current = setTimeout(() => {
                setDebouncedState(state);
            }, timeout);
            return () => {
                if (_timer.current !== null) {
                    clearTimeout(_timer.current!);
                }
            };
        }
    }, [debouncedState, state, timeout]);
    return debouncedState;
};

/**
 * This exist because we dont want to refetch the grid
 * when the user changes the order of the custom columns
 * or removes columns from view
 */
const useCustomColumnsForRequest = () => {
    const customColumns = useSelectedCustomColumnKeysValue();
    const [cols, setCols] = useState(customColumns);
    useLayoutEffect(() => {
        // Allow the user to de-select columns without refetcing, as long as all selected columns are still in state
        const same = customColumns.length <= cols.length && customColumns.every(col => cols.includes(col));
        if (!same) {
            setCols(customColumns);
        }
    }, [cols, customColumns]);

    return useDebouncedState(cols, 3000);
};

export const useProjectPlannerData = ({
    currentUnitType,
    currentViewFilters,
    gridRef,
}: {
    currentViewFilters: GridViewFilters | null;
    currentUnitType: UnitType;
    gridRef: React.MutableRefObject<{
        getUpdatedData: () => Promise<GridData>;
    }>;
}) => {
    const setBlockDialog = useStore(store => store.setBlockDialog);

    const { timelineStart, timelineEnd, timelineResolution } = useStore(store => {
        return {
            timelineEnd: store.timeline.end,
            timelineStart: store.timeline.start,
            timelineResolution: store.timeline.resolution,
        };
    }, shallow);

    const customColumns = useCustomColumnsForRequest();
    const queryKey = useMemoQueryKey(PROJECT_PLANNER, timelineStart, timelineEnd, timelineResolution, currentViewFilters, currentUnitType, customColumns);
    const prevQueryKey = usePrevious(queryKey);
    const { data: gridData } = useQuery({
        queryKey,
        queryFn: async () => {
            return (
                ApiCalls.getProjectPlannerGrid({
                    dateResolution: timelineResolution,
                    end: timelineEnd,
                    start: timelineStart,
                    resourceFilter: currentViewFilters.resources,
                    projectFilter: currentViewFilters.projects,
                    customColumns,
                })
                    // .then(res => res.data)
                    .then(
                        res =>
                            ({
                                ...res.data,
                                rows: res.data.rows.map(row => ({
                                    ...row,
                                    columnRows: row.columnRows.map(columnRow => ({
                                        ...columnRow,
                                        metadata: {
                                            ...columnRow.metadata,
                                            totalAllocation: 1,
                                            totalRequest: 1,
                                        },
                                    })),
                                })),
                            } as GridData),
                    )
                    .catch(error => {
                        // can we do something better? by typing errors in the backend? or using business codes? (ewi)
                        if (error?.title?.startsWith('MaxReturnedProjects') || error?.detail?.startsWith('MaxReturnedProjects')) {
                            setBlockDialog(null, {
                                title: `You're asking for too much :)`,
                                subText:
                                    'You apparently have a lot of resources. Try narrowing the result down by using the filters. And set a new default view if needed.',
                            });
                            return { rows: [], headers: [], metadata: {} } as GridData;
                        } else {
                            setBlockDialog(null, {
                                title: 'An unexpected error has occured',
                                subText: `Status: ${error?.title ?? 'unknown'}, Trace: ${error?.traceId ?? 'unknown'}`,
                            });
                            return { rows: [], headers: [], metadata: {} } as GridData;
                        }
                    })
            );
        },
    });

    const queryClient = useQueryClient();
    const updateQueryCache = useCallback(
        (qKey: any[]) => (data: GridData) => {
            queryClient.setQueryData<GridData>(qKey, () => data);
        },
        [queryClient],
    );

    useLayoutEffect(() => {
        const ref = gridRef.current;
        if (prevQueryKey !== queryKey && ref) {
            ref.getUpdatedData().then(updateQueryCache(prevQueryKey));
            return () => void ref.getUpdatedData().then(updateQueryCache(prevQueryKey));
        }
    }, [gridRef, prevQueryKey, queryKey, updateQueryCache]);

    return gridData;
};
