/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo } from 'react';
import { DynamicProps } from './DynamicProps';
import { ObjectEditor } from '../../ObjectEditor';
import { DebugOverrides } from '../../../../Utilities/DebugOverrides';
 
type IProps = DynamicProps<any, any>

const DynamicObject: FunctionComponent<IProps> = props => {

    return  <div key={ props.Key } style={ { paddingLeft: "20px" } }>
				<ObjectEditor
					ItemType={null}
					HiddenProperties={[]}
					DisabledProperties={[]}
					{...props.PropertyInfo.ComponentProps} 
					ReadOnly={props.ReadOnly && !DebugOverrides.Enabled} // override readonly, making ALL properties editable
					Item={ props.Value } 
					ChangesCallback={ (item, property, value) => props.OnChange(props.PropertyInfo.PropertyName, item) }
					key={ props.Key }
					KeyPrefix={props.PropertyInfo.PropertyName}
					PropertyFilterLevel={props.PropertyFilterLevel}
				/>
			</div>
}

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
	return prevProps.Value === nextProps.Value
			&& prevProps.PropertyFilterLevel === nextProps.PropertyFilterLevel;
};

export default memo(DynamicObject, comparisonFn);