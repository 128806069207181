export enum UnitType {
	Hours = 0,
	FTE = 1,
	BothHoursAsPrimary = 2,
	BothFteAsPrimary = 3
	//UserDefined = 4,
	//FTEalwaysOverwriteHours = 8,
	//HoursalwaysOverwriteFTE = 16,
	//FTEonRequestHoursOnAllocate = 32,
	//HoursOnRequestFTEonAllocate = 64,
	//FTEonMonthHoursOnWeek = 128,
	//HoursOnMonthFTEonWeeks = 256,
}