export default class Locale_GridSettings { 
	GridSettings: string = 'Grid settings';
	WhitespaceAmount: string = 'Whitespace amount';
	ShowPersona: string = 'Show persona icons';
	HidePersona: string = 'Hide persona icons';
	ShowGenericResources: string = 'Show requested resources';
	HideGenericresources: string = 'Hide requested resources';
	EnableFastDelete: string = 'Enable fast delete';
	DisableFastDelete: string = 'Disable fast delete';
	ExpandAll: string = 'Expand all';
	CollapseAll: string = 'Collapse all';
}