import { IconButton } from '@fluentui/react';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ITreeNode } from '../interfaces/ITreeNode';
import { TreeContext } from './TreeView';
import { TreeViewManageNode } from './TreeViewManageNode';
import { TreeViewSelectNode } from './TreeViewSelectNode';

export interface IProps {
    node: ITreeNode;
    type: 'select' | 'manage';
    parent: ITreeNode;
    defaultCollapsed?: boolean;
    delimiter: string;
    readOnly: boolean;
    depth: number;
    onSelectCallback?: (node: ITreeNode, checked: boolean) => void;
    onDeleteCallback?: (node: ITreeNode, parent: ITreeNode) => void;
    onAddCallback?: (node: ITreeNode) => void;
    onMoveCallback?: (source: string, sourceContainer: string, target: string) => boolean;
    onRenameCallback?: (node: ITreeNode, newName: string) => void;
}

export const TreeViewNode: FunctionComponent<IProps> = ({
    node,
    type,
    parent,
    readOnly,
    delimiter,
    defaultCollapsed,
    depth,
    onSelectCallback,
    onRenameCallback,
    onDeleteCallback,
    onAddCallback,
    onMoveCallback,
    children,
}) => {
    const [collapsed, setCollapsed] = useState(defaultCollapsed);
    const [treeNode, setTreeNode] = useState(node);

    const ctx = useContext(TreeContext);

    useEffect(() => {
        setTreeNode(node);
    }, [node]);

    const isRoot = ctx.rootNodeId === node.id;
    const hasChilds = node.children?.length > 0;

    return (
        <div key={`node-${node.id}`} style={{ display: 'flex', paddingTop: '3px', paddingLeft: hasChilds || isRoot ? null : '32px', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
                {hasChilds && (!ctx.hideRoot || ctx.rootNodeId !== node.id) && (
                    <IconButton
                        iconProps={{ iconName: collapsed ? 'ChevronRight' : 'ChevronDown' }}
                        className="tp-grouping-header-icon"
                        onClick={() => setCollapsed(!collapsed)}
                    />
                )}
                {type === 'select' ? (
                    <TreeViewSelectNode node={treeNode} parent={parent} readOnly={readOnly} onSelectCallback={onSelectCallback} />
                ) : (
                    <TreeViewManageNode
                        node={treeNode}
                        parent={parent}
                        readOnly={readOnly}
                        setCollapsed={col => setCollapsed(col)}
                        delimiter={delimiter}
                        onAddCallback={onAddCallback}
                        onDeleteCallback={onDeleteCallback}
                        onMoveCallback={onMoveCallback}
                        onRenameCallback={onRenameCallback}
                    />
                )}
            </div>
            <div
                style={{
                    paddingLeft: isRoot && ctx.hideRoot && type === 'select' ? null : '32px',
                    visibility: collapsed ? 'hidden' : 'unset',
                    height: collapsed ? '0px' : 'unset',
                }}
            >
                {children}
            </div>
        </div>
    );
};
