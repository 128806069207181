import React from "react";
import { HeaderName } from "../../Components/Overview/Table/Header/HeaderName";
import { HeaderRange } from "../../Components/Overview/Table/Header/HeaderRange";
import { SubHeaders } from "../../Components/Overview/Table/Header/SubHeaders";
import TableCellRow from "../../Components/Overview/Table/TableCellRow";
import { TableCustomColumn, TableCustomColumnInfo } from "../../Components/Overview/Table/TableCustomColumn";
import { BaseRow } from "../../Entities/Table/BaseRow";
import { Cell } from "../../Entities/Table/Cell";
import { CellRow } from "../../Entities/Table/CellRow";
import { CellType } from "../../Entities/Table/CellType";
import { TableStateManager } from "../../Services/TableStateManager";
import { guid } from "../../helpers/guid";
import { HeaderUtil } from "../HeaderUtil";
import { ObjectEx } from "../ObjectEx";

export class CustomColumnUtil {

	public static GenerateCustomColumnsHeader = (stateManager: TableStateManager) : JSX.Element => {
		const customCols = stateManager.Get(_ => _.CustomColumns);
		if (!customCols) return null;
		const headers = customCols.map(_ => _.Header);

		return <>{
					headers.map((_, idx) => {
						return <th 
									key={`headers-${idx}`} 
									className={"tp-table-header-total"}
								>
									<div className={HeaderUtil.HeaderStyle(_)}>
										<HeaderRange header={_} />
										<HeaderName header={_} />
										<SubHeaders header={_} />
									</div>
								</th>
					})}
			   	</>
	}

	public static GenerateCustomColumnCellRows = (stateManager: TableStateManager, row: BaseRow) : JSX.Element => {

		const customCols = stateManager.Get(_ => _.CustomColumns);
		if (!customCols) return null;
		const customCellRows = customCols.map(_ => CustomColumnUtil.BuildCellRow(row, _));
		
		return 	<>{
					customCellRows?.map((_, idx) => (
						<TableCellRow key={`${row.Id}-cellrow-custom-${idx}`} KeyPrefix={`${row.Id}`} CellRow={_} Index={idx + 100000} ParentRow={row} />
					))
				}</>
	}

	private static BuildCellRow = (row: BaseRow, customColumn: TableCustomColumn) : CellRow => {
		const info = CustomColumnUtil.GetCellInfo(row, customColumn);
		let props = { GrandTotal: true, CustomColumn: true };

		if (info?.Info == null) return CustomColumnUtil.BuildEmptyCellRow(props);
		
		// add extra props
		props = ObjectEx.mergeDeep(props, row.Properties, info?.Info?.Properties(row));

		const cellRow = new CellRow(null, null, props);
		const cell = CustomColumnUtil.BuildCell(row, cellRow, info.Info, info.CellType);
		cellRow.Cells = [ cell ];

		return cellRow;
	}

	private static GetCellInfo = (row: BaseRow, customColumn: TableCustomColumn) : { CellType: CellType, Info: TableCustomColumnInfo } => {
		switch (row.TypeName) {
			case "TopRow":
				return { CellType: CellType.Top, Info: customColumn.TopInfo };
			case "GroupContainerRow":
				return { CellType: CellType.Container, Info: customColumn.ContainerInfo };
			case "GroupRow":
				return { CellType: CellType.Group, Info: customColumn.GroupInfo };
			case "Row":
				return { CellType: CellType.Row, Info: customColumn.RowInfo };
			default:
				return null;
		}
	}

	private static BuildCell = (row: BaseRow, cellRow: CellRow, info: TableCustomColumnInfo, cellType: CellType) : Cell => {
		const cell = new Cell();
		cell.Value = info.ValueGetter(row);
		cell.Disabled = info.Disabled;
		cell.Id = guid.newGuid();
		cell.ParentId = cellRow.Id;
		cell.Type = cellType;
		cell.Properties = cellRow.Properties;
		return cell;
	}

	private static BuildEmptyCellRow = (properties: { [key: string]: any }) : CellRow => {
		const emptyCellRow = new CellRow(null, null, properties);
		const emptyCell = new Cell();
		emptyCell.Value = null;
		emptyCell.Disabled = true;
		emptyCell.Id = guid.newGuid();
		emptyCell.ParentId = emptyCellRow.Id;
		emptyCell.Properties = properties;
		emptyCellRow.Cells = [ emptyCell ];
		return emptyCellRow;
	}

	
}