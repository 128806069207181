import * as XLSX from 'xlsx';

export const exportOverviewToExcel = (overviewEntityOrName: string, filteredItems: any[], columns: any[]) : void => {
	const newFilteredItems = filteredItems.map(obj => {
		const newObj = {};
		columns.forEach(prop => {
			let propFieldName = prop.fieldName;
			const propFieldNameAsString = prop.fieldName + 'AsString';
			const propName = prop.name;
	
			let value = null;
			if (propFieldName.startsWith('customProperties.')) {
				propFieldName = prop.fieldName.substring('customProperties.'.length);
				value = obj.customPropertiesAsString?.[propFieldName] ?? obj.customProperties?.[propFieldName];
			} else {
				value = obj[propFieldNameAsString] ?? obj[propFieldName];
			}
	
			newObj[propName] = value?.length > 32767 ? value.substring(0, 32766) : value;
		});
		return newObj;
	});

	const worksheet = XLSX.utils.json_to_sheet(newFilteredItems);
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
	XLSX.writeFile(workbook, `${overviewEntityOrName} Overview Export.xlsx`);
}