import Locale_BaseOverview from "../../../../Locales/Overviews.ts/BaseOverview";
import { CountDownConfirm } from "../../../../Services/CountDownConfirm";
import { Confirm } from "../../../../Utilities/Confirm";

export interface IOverviewActivation<T> {
	items: T[];
	active: boolean;
	showInactiveCountdown: boolean;
	callback: () => void;
	languageObj: Locale_BaseOverview;
	setActiveState?: (options: IOverviewActivation<T>) => void;
	indicateLoading: (value: boolean) => void;
}

export const overviewActivation = <T>(options: IOverviewActivation<T>) => {

	const showInactivateConfirm = () => {
		const multiple = options.items.length > 1;
		const subtext = options.active
			? multiple
				? options.languageObj.ConfirmActiveSubtextMultiple
				: options.languageObj.ConfirmActiveSubtextSingle
			: multiple? options.languageObj.ConfirmInactiveSubtextMultiple
			: options.languageObj.ConfirmInactiveSubtextSingle;

		// show 'active state' dialog
		const confirmInfo = {
			show: true,
			title: options.active ? options.languageObj.ConfirmActiveTitle : options.languageObj.ConfirmInactiveTitle,
			subtext: subtext,
			confirmAction: () => (!options.active && options.showInactiveCountdown) ? showInactivateCountDownConfirm(options) : options.setActiveState(options),
			dismissAction: () => {},
			okButtonText: options.languageObj.ConfirmActiveStateOkButton,
			cancelButtonText: options.languageObj.ConfirmActiveStateCancelButton,
			hideCancelButton: true,
		};
		Confirm.Instance.Show(confirmInfo);
	};

	const showInactivateCountDownConfirm = (options: IOverviewActivation<T>): void => {
		CountDownConfirm.Instance.Fire(
			options.languageObj.ConfirmCountInactivateTitle,
			options.items.length > 1
				? options.languageObj.ConfirmCountInactivateSubtextMultiple
				: options.languageObj.ConfirmCountInactivateSubtextSingle,
			10,
			() => options.setActiveState(options),
			() => {},
		);
	};

return { activation: showInactivateConfirm }
}