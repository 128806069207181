/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Plugin } from '../../../Services/PluginInvoker';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import { TimelineResolution } from '../../../Entities/TimelineResolution';
import { Dropdown, IconButton, PrimaryButton, Stack, TextField, Toggle, TooltipHost } from '@fluentui/react';
import { OverviewContext } from '../../../Utilities/Context/OverviewContext';
import { LocalStorage } from '../../../Utilities/LocalStorage';
import { DropdownOptionEx } from '../../../Utilities/DropdownOptionEx';
import { IDs } from '../../../Utilities/IDs';
import { DropdownOption } from '../../../Entities/Fabric/DropdownOption';
import { JsonEx } from '../../../Utilities/JsonEx';
import { ResourceType } from '../../../Entities/ResourceType';
import { ResourcePanelItem } from '../../../ListItems/ResourcePanelItem';
import { ProjectPanelItem } from '../../../ListItems/ProjectPanelItem';
import { language } from '../../../Services/LocalizationService';
import { DomEx } from '../../../Utilities/DomEx';
import { Permission } from '../../../Services/Permission';
import ResourcePicker from '../../../Components/Common/DynamicLoaded/ResourcePicker';
import ProjectPicker from '../../../Components/Common/DynamicLoaded/ProjectPicker';
import { useStore } from '../../../context/store';

interface IProps {
    Timeline: Timeline;
    ChangesCallback: (projectIds?: Array<string>, resourceIds?: Array<string>) => Promise<void>;
    DisableInputs: boolean; // temp hack for fixing multiple grid data queries (please kill this beast soon!!!) (ewi)
}

interface Timeline {
    start: Date;
    end: Date;
    resolution: TimelineResolution;
}

interface DefaultViews {
    Projects: string[];
    Resources: string[];
}

enum DefaultViewIds {
    ProjId = 'PVDPROJ1',
    ResId = 'PVDRES1',
}

enum ViewMode {
    Create = 0,
    Edit = 1,
}

class LmCapacityView {
    Id: string;

    constructor(public Name: string, public Projects?: Array<string>, public Resources?: Array<string>) {
        this.Id = IDs.makeId();
        Projects = new Array<string>();
        Resources = new Array<string>();
    }
}

const NoViewSelected: LmCapacityView = { Id: null, Name: 'none' };

const PlannerViewSettingsHeader: FunctionComponent<IProps> = props => {
    const success = useStore(store => store.addSuccesNotification);
    const firstLoad = useRef<boolean>(true);

    const [selections, _setSelections] = useState<{ ResourceIds: Array<string>; ProjectIds: Array<string> }>();
    // ref is needed because updates can come from the outside
    const selectionsRef = useRef<{ ResourceIds: Array<string>; ProjectIds: Array<string> }>();
    const setSelections = (selection: { ResourceIds: Array<string>; ProjectIds: Array<string> }) => {
        selectionsRef.current = selection;
        _setSelections(selection);
    };

    const [autoRefresh, setAutoRefresh] = useState<boolean>(LocalStorage.get<boolean>('plannerview-autorefresh'));
    const preventAutoRefresh = useRef<boolean>(false);

    const [views, setViews] = useState<Array<LmCapacityView>>(LocalStorage.get('plannerview-views') || []);
    const [defaultViewIds, setDefaultViewIds] = useState<DefaultViews>();
    const [selectedViewId, setSelectedViewId] = useState<string>(LocalStorage.get<string>('plannerview-selectedviewid'));

    const [showViewText, setShowViewText] = useState<boolean>(false);
    const newViewText = useRef<string>('View');

    const [viewMode, setViewMode] = useState(LocalStorage.get('plannerview-selectedviewid') != null ? ViewMode.Edit : ViewMode.Create);

    const PickersOutEventHandler = function (e) {
        const pickerElements = document.getElementsByClassName('tp-expand-container');
        if (DomEx.outsideClick(e, pickerElements as any)) PickersOut();
    };

    const getView = (viewId: string) => views.find(_ => _.Id === viewId);

    useEffect(() => {
        const sViewId = LocalStorage.get<string>('plannerview-selectedviewid');
        if (sViewId != null && !IsViewDefault(sViewId)) {
            const views = LocalStorage.get<Array<LmCapacityView>>('plannerview-views');
            const sView = views.find(_ => _.Id === sViewId);
            if (sView != null) {
                const initialResources = sView.Resources;
                const initialProjects = sView.Projects;
                setSelections({ ResourceIds: initialResources || [], ProjectIds: initialProjects || [] });
            }
        }

        OverviewContext.AddResource = AddResourceToSelection;
        OverviewContext.AddProject = AddProjectToSelection;

        // enable outside click for project and resource picker
        window.addEventListener('click', PickersOutEventHandler);

        // willunmount
        return () => {
            OverviewContext.AddResource = () => {};
            OverviewContext.AddProject = () => {};
            window.removeEventListener('click', PickersOutEventHandler);
        };
    }, []);

    useEffect(() => {
        if (!props.Timeline) return;
        const fetchDefaultViews = async () => {
            const defaultViews = await Plugin.Invoke<DefaultViews>(PluginIDs.GetPlannerViewDefaultViews, {
                Start: props.Timeline.start,
                End: props.Timeline.end,
            });
            setDefaultViewIds(defaultViews);
        };
        fetchDefaultViews();
    }, [props.Timeline]);

    useEffect(() => {
        if (!defaultViewIds || views.some(_ => IsViewDefault(_.Id))) return;
        const clone = views || [];
        const defaultProjectView: LmCapacityView = {
            Name: language.LMCapacity.DefaultViews.Projects,
            Projects: defaultViewIds.Projects,
            Resources: [],
            Id: DefaultViewIds.ProjId,
        };
        const defaultResourceView: LmCapacityView = {
            Name: language.LMCapacity.DefaultViews.Resources,
            Projects: [],
            Resources: defaultViewIds.Resources,
            Id: DefaultViewIds.ResId,
        };
        const viewsWithDefaultViews = [...clone, defaultProjectView, defaultResourceView];
        setViews(viewsWithDefaultViews);
        if (selectedViewId == null || IsViewDefault(selectedViewId)) {
            setSelectedViewId(defaultResourceView.Id);
            setSelections({ ProjectIds: defaultResourceView.Projects, ResourceIds: defaultResourceView.Resources });
        }
    }, [defaultViewIds]);

    useEffect(() => {
        if (firstLoad.current) if (selections == null) return;
        selectionsRef.current = { ...selections };
        if ((!autoRefresh || preventAutoRefresh.current) && !firstLoad.current) return;
        preventAutoRefresh.current = false;
        firstLoad.current = false;
        const getView = async () => await props.ChangesCallback(selections.ProjectIds, selections.ResourceIds);
        getView();
    }, [selections]);

    // Persistence useeffects
    useEffect(() => {
        //exclude default views from local storage
        const withoutDefaults = views.filter(_ => !IsViewDefault(_.Id));
        LocalStorage.set('plannerview-views', withoutDefaults);
    }, [views]);

    useEffect(() => {
        LocalStorage.set('plannerview-selectedviewid', selectedViewId);
        if (IsViewNone(selectedViewId) || IsViewDefault(selectedViewId)) setViewMode(ViewMode.Create);
        else setViewMode(ViewMode.Edit);
    }, [selectedViewId]);
    useEffect(() => {
        LocalStorage.set('plannerview-autorefresh', autoRefresh);
    }, [autoRefresh]);

    const IsViewDefault = (viewId: string = selectedViewId): boolean => {
        return viewId === DefaultViewIds.ProjId || viewId === DefaultViewIds.ResId;
    };

    const IsViewNone = (viewId: string = selectedViewId): boolean => {
        return viewId == null;
    };

    const ClearAllSelections = (): void => {
        setSelectedViewId(DefaultViewIds.ResId);
        setSelections({ ProjectIds: [], ResourceIds: [] });
        props.ChangesCallback();
    };

    // TODO: change (ewi)
    const ViewChange = (viewId: string): void => {
        let view = getView(viewId);
        // view selection cleared
        if (!view) {
            view = { ...NoViewSelected };
            view.Projects = selectionsRef.current.ProjectIds;
            view.Resources = selectionsRef.current.ResourceIds;
        }
        setSelectedViewId(view.Id);
        UpdateSelection(view.Projects, view.Resources);
    };

    const CreateView = (event): void => {
        // escape
        if (event.keyCode === 27) {
            setShowViewText(false);
            return;
        }
        // not enter
        if (event.keyCode !== 13) return;
        const view = new LmCapacityView(newViewText.current, [...selections.ProjectIds], [...selections.ResourceIds]);
        const clone = [...views];
        clone.push(view);
        setViews(clone);
        setShowViewText(false);
        setSelectedViewId(view.Id);
    };

    const DeleteView = () => {
        const viewIndex = views.findIndex(_ => _.Id === selectedViewId);
        const clone = [...views];
        clone.splice(viewIndex, 1);
        setViews(clone);
        setViewMode(ViewMode.Create);
        success('View deleted', 2000);
    };

    const UpdateView = () => {
        const viewIndex = views.findIndex(_ => _.Id === selectedViewId);
        const clone = [...views];
        clone[viewIndex].Projects = selections.ProjectIds;
        clone[viewIndex].Resources = selections.ResourceIds;
        setViews(clone);
        success('View updated', 2000);
    };

    const UpdateSelection = (projects: Array<string>, resources: Array<string>) => {
        if (projectEditMode.current) {
            tempProjects.current = projects;
            return;
        }
        if (resourceEditMode.current) {
            tempResources.current = resources;
            return;
        }

        if (projects == null) projects = [...selectionsRef.current.ProjectIds];
        if (resources == null) resources = [...selectionsRef.current.ResourceIds];

        setSelections({ ProjectIds: projects, ResourceIds: resources });
        LocalStorage.set('plannerview-projects', projects);
        LocalStorage.set('plannerview-resources', resources);
    };

    const OnProjectDrop = event => {
        const project = JsonEx.parse(event.dataTransfer.getData('projectlistitem')) as ProjectPanelItem;
        AddProjectToSelection(project);
    };

    const AddProjectToSelection = (project: ProjectPanelItem) => {
        const projects = [...selectionsRef.current.ProjectIds];
        if (projects.indexOf(project.id) >= 0) return;
        projects.push(project.id);
        UpdateSelection(projects, null);
    };

    const OnResourceDrop = event => {
        const resource = JsonEx.parse(event.dataTransfer.getData('resourcelistitem')) as ResourcePanelItem;
        AddResourceToSelection(resource);
    };

    const AddResourceToSelection = (resource: ResourcePanelItem) => {
        const resources = [...selectionsRef.current.ResourceIds];
        if (resources.indexOf(resource.id) >= 0) return;
        resources.push(resource.id);
        UpdateSelection(null, resources);
    };

    const ResourcePickerTypes = (): Array<ResourceType> => {
        if (OverviewContext.Settings.AllowPlannerViewGenericResourceSelection)
            return [ResourceType.Named, ResourceType.Generic, ResourceType.Team, ResourceType.Category, ResourceType.Pool];
        else return [ResourceType.Named, ResourceType.Team, ResourceType.Category];
    };

    const ClearSelection = (typeToClear: string) => {
        let projects: string[];
        let resources: string[];

        if (typeToClear === 'project') {
            projects = [];
            resources = [...selections.ResourceIds];
        } else if (typeToClear === 'resource') {
            projects = [...selections.ProjectIds];
            resources = [];
        }
        preventAutoRefresh.current = false;
        setSelections({ ProjectIds: projects, ResourceIds: resources });
        if (IsViewDefault()) setSelectedViewId(null);
        LocalStorage.set('plannerview-projects', projects);
        LocalStorage.set('plannerview-resources', resources);
    };

    // PROJECT PICKER EDIT
    const projectEditMode = useRef(false);
    const tempProjects = useRef(null);

    const ProjectPickerHover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.currentTarget.classList.add('editmode');
        projectEditMode.current = true;
    };

    const ProjectPickerHoverOut = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.currentTarget.classList.remove('editmode');
        projectEditMode.current = false;
        if (tempProjects.current == null) return;
        UpdateSelection(tempProjects.current, null);
        tempProjects.current = null;
    };
    // END: PROJECT PICKER EDIT

    // PROJECT PICKER EDIT
    const resourceEditMode = useRef(false);
    const tempResources = useRef(null);

    const ResourcePickerHover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.currentTarget.classList.add('editmode');
        resourceEditMode.current = true;
    };

    const ResourcePickerHoverOut = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.currentTarget.classList.remove('editmode');
        resourceEditMode.current = false;
        if (tempResources.current == null) return;
        UpdateSelection(null, tempResources.current);
        tempResources.current = null;
    };

    const PickersOut = () => {
        const elements = Array.from(document.getElementsByClassName('tp-expand-container'));
        if (elements == null) return;
        elements.forEach(_ => _.classList.remove('editmode'));
        resourceEditMode.current = false;
        projectEditMode.current = false;
        if (tempResources.current == null && tempProjects.current == null) return;
        UpdateSelection(tempProjects.current, tempResources.current);
        tempResources.current = null;
        tempProjects.current = null;
    };
    // END: PROJECT PICKER EDIT

    const RenderPickers = (): JSX.Element => {
        return (
            <>
                {selections && (
                    <>
                        <div className="tp-expand-container" style={{ zIndex: 100000 }} onMouseEnter={ProjectPickerHover} onMouseLeave={ProjectPickerHoverOut}>
                            <ProjectPicker
                                Item={selections.ProjectIds}
                                Update={values => UpdateSelection(values as Array<string>, null)}
                                MultiSelect
                                PlaceHolder={language.LMCapacity.ProjectPickerPlaceholder}
                                ClearSelection={ClearSelection}
                                ReadOnly={props.DisableInputs}
                            />
                        </div>
                        <div className="tp-expand-container" style={{ top: '35px' }} onMouseEnter={ResourcePickerHover} onMouseLeave={ResourcePickerHoverOut}>
                            <ResourcePicker
                                Item={selections.ResourceIds}
                                Update={values => UpdateSelection(null, values as Array<string>)}
                                ExcludeInactive
                                RbsMatch={!(Permission.IsSuperuser() || OverviewContext.Settings.AllowSearchAllResources)}
                                MultiSelect
                                Types={ResourcePickerTypes()}
                                PlaceHolder={language.LMCapacity.ResourcePickerPlaceholder}
                                ClearSelection={ClearSelection}
                                ReadOnly={props.DisableInputs}
                            />
                        </div>
                        <div
                            className="tp-list-capacity-dropzone-overlay tp-lmcapacity-include-drop tp-lmcapacity-projectdrop"
                            onDrop={OnProjectDrop}
                            onDragEnter={e => e.preventDefault()}
                            onDragOver={e => e.preventDefault()}
                        >
                            <div className="tp-list-capacity-dropzone-text">{language.LMCapacity.AddProject}</div>
                        </div>
                        <div
                            className="tp-list-capacity-dropzone-overlay tp-lmcapacity-include-drop tp-lmcapacity-resourcedrop"
                            onDrop={OnResourceDrop}
                            onDragEnter={e => e.preventDefault()}
                            onDragOver={e => e.preventDefault()}
                        >
                            <div className="tp-list-capacity-dropzone-text">{language.LMCapacity.IncludeResource}</div>
                        </div>
                    </>
                )}
            </>
        );
    };
    const RenderButtonsContainer = (): JSX.Element => {
        return (
            <div className="lmcapacity-settings">
                <div className="lmcapacity-settings-buttons">
                    <div className="lmcapacity-settings-buttons-top">
                        <TooltipHost content={language.Common.AutoRefreshOnSelection}>
                            <Toggle
                                className="lmcapacity-icons-autorefresh"
                                disabled={props.DisableInputs}
                                defaultChecked={autoRefresh}
                                onChange={(event, checked) => setAutoRefresh(checked)}
                            />
                        </TooltipHost>
                        <TooltipHost content={language.Common.Refreshview}>
                            <IconButton
                                iconProps={{ iconName: 'Refresh' }}
                                disabled={props.DisableInputs}
                                onClick={() => {
                                    props.ChangesCallback(selectionsRef.current?.ProjectIds, selectionsRef.current?.ResourceIds);
                                }}
                            />
                        </TooltipHost>
                        <TooltipHost content={language.Common.ClearAllSelections}>
                            <IconButton iconProps={{ iconName: 'ClearFilter' }} disabled={props.DisableInputs} onClick={ClearAllSelections} />
                        </TooltipHost>
                    </div>
                    <div className="lmcapacity-settings-buttons-bottom">
                        <div>
                            {showViewText ? (
                                <TextField
                                    placeholder={language.LMCapacity.HitEnterToCreatePlaceholder}
                                    autoFocus
                                    onChange={(event, value) => (newViewText.current = value)}
                                    onKeyDown={CreateView}
                                    disabled={props.DisableInputs}
                                />
                            ) : (
                                <>
                                    {viewMode === ViewMode.Create ? (
                                        <PrimaryButton
                                            text={language.LMCapacity.CreateAsView}
                                            onClick={event => setShowViewText(true)}
                                            disabled={props.DisableInputs}
                                        />
                                    ) : (
                                        <Stack horizontal>
                                            <PrimaryButton text={language.LMCapacity.UpdateView} onClick={UpdateView} disabled={props.DisableInputs} />
                                            <IconButton iconProps={{ iconName: 'Delete' }} onClick={DeleteView} disabled={props.DisableInputs} />
                                        </Stack>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="lmcapacity-settings-dropdowns">
                    <div>
                        <Dropdown
                            defaultSelectedKey={selectedViewId}
                            options={[new DropdownOption('No view select', null), { key: IDs.makeId(), text: 'Views', itemType: 2 } as DropdownOption].concat(
                                DropdownOptionEx.toDropdownOptions(
                                    views,
                                    _ => _.Id,
                                    _ => _.Name,
                                    true,
                                ),
                            )}
                            onChange={(event, option, index) => ViewChange(option.key as string)}
                            disabled={props.DisableInputs}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return props.Timeline ? (
        <div className="tp-lmcapacity-settingscontainer">
            {RenderButtonsContainer()}
            <div className="tp-lmcapacity-pickercontainer">{RenderPickers()}</div>
        </div>
    ) : null;
};

export default PlannerViewSettingsHeader;
