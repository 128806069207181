import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton, IDialogStyles } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';

interface IProps {
    showDialog: boolean;
    title?: string;
    subText?: string;
    callback: () => void;
    dismissCallback: () => void;
    buttonText?: string;
    cancelButtonText?: string;
    noCancelButton?: boolean;
    noOkButton?: boolean;
    buttonDefaultDisabled?: boolean;
    minWidth?: number | string;
    maxWidth?: number | string;
    className?: string;
    okButtonEnabled?: boolean;
    eventBubbling?: boolean;
    extraDialogFooterContent?: JSX.Element;
    extraStyles?: Partial<IDialogStyles>;
    isBlocking?: boolean;
}

export const PFDialog: FunctionComponent<IProps> = props => {
    const [buttonEnabled, setButtonEnabled] = useState<boolean>(!props.buttonDefaultDisabled);
    const [okButtonText, setOkButtonText] = useState(props.buttonText);
    const [cancelButtonText, setCancelButtonText] = useState(props.cancelButtonText);

    const onOkButtonClick = () => {
        setButtonEnabled(!props.buttonDefaultDisabled);
        props.callback();
    };

    const onCancelButtonClick = () => {
        setButtonEnabled(!props.buttonDefaultDisabled);
        props.dismissCallback();
    };

    useEffect(() => {
        if (props.okButtonEnabled == null) setButtonEnabled(!props.buttonDefaultDisabled);
        else setButtonEnabled(props.okButtonEnabled);
    }, [props.buttonDefaultDisabled, props.okButtonEnabled]);

    useEffect(() => {
        setOkButtonText(props.buttonText);
    }, [props.buttonText]);

    useEffect(() => {
        setCancelButtonText(props.cancelButtonText);
    }, [props.cancelButtonText]);

    return (
        <Dialog
            hidden={!props.showDialog}
            minWidth={props.minWidth || 600}
            maxWidth={props.maxWidth || 600}
            onDismiss={e => props.dismissCallback()}
            styles={props.extraStyles}
            dialogContentProps={{
                type: DialogType.normal,
                title: props.title,
                subText: props.subText,
            }}
            modalProps={{
                isBlocking: props.isBlocking !== undefined ? props.isBlocking : true,
                containerClassName: `pf-dialog ${props.className ? props.className : 'ms-dialogMainOverride'}`,
                layerProps: {
                    eventBubblingEnabled: props.eventBubbling,
                },
                isClickableOutsideFocusTrap: true,
                ignoreExternalFocusing: true,
            }}
        >
            {props.children}

            <DialogFooter>
                {props.extraDialogFooterContent}
                {props.noOkButton ? null : <PrimaryButton disabled={!buttonEnabled} onClick={onOkButtonClick} text={okButtonText ?? 'Ok'} />}
                {props.noCancelButton ? null : <DefaultButton onClick={onCancelButtonClick} text={cancelButtonText ?? 'Cancel'} />}
            </DialogFooter>
        </Dialog>
    );
};
