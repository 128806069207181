import { EntityType } from '../../Entities/EntityTypes';
import { Resource } from '../../Entities/Main/Resource';
import { Settings } from '../../Entities/Main/Settings';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { UserContext } from '../../Utilities/Context/UserContext';
import { guid } from '../../helpers/guid';
import { ObjectEx } from '../../Utilities/ObjectEx';
import { DataService } from '../DataService';
import { refactorHelper } from '../../helpers/refactorHelper';

export class UserSettingsService {
    public static GetUserSettings = async (resource?: Resource): Promise<{ User: Settings; Root: Settings }> => {
        resource ??= UserContext.Resource;
        let loadedUserSettings: Settings;
        loadedUserSettings = await DataService.Get<Settings>(EntityType.Settings, resource.id);
        const root = await DataService.Get<Settings>(EntityType.Settings, guid.empty);
        // if no specific user setting available - clone from root
        if (loadedUserSettings == null) {
            loadedUserSettings = ObjectEx.deepCopy(root);
            (loadedUserSettings as Settings).SettingsType = 2;
            loadedUserSettings.Name = resource.Name;
            loadedUserSettings.id = resource.id;
            loadedUserSettings.OverrideSubSettingPropertiesAvailable = [];
        } else {
            // if user setting is available - add missing properties
            (loadedUserSettings as Settings).SettingsType = 2;
            loadedUserSettings = { ...root, ...loadedUserSettings };
        }
        return { User: loadedUserSettings, Root: root };
    };

    public static UpdateUserSettingsPartial = async (partial: Partial<Settings>, resource?: Resource, forceFullUpdateInSession?: boolean): Promise<void> => {
        const settings = await UserSettingsService.GetUserSettings(resource);

        // if not allowed to persist but want it changed for the browser session
        if (forceFullUpdateInSession) {
            const merged = { ...OverviewContext.Settings, ...partial };
            OverviewContext.Settings = refactorHelper.parseSettingsToLegacy(merged);
        }

        Object.keys(partial).forEach(key => {
            if (settings.Root.UserSettingPropertiesAvailable == null || settings.Root.UserSettingPropertiesAvailable.indexOf(key) < 0) delete partial[key];
        });

        // no allowed properties included
        if (Object.keys(partial).length === 0) return;

        const merged = { ...settings.User, ...partial };
        OverviewContext.Settings = refactorHelper.parseSettingsToLegacy(merged);

        await DataService.Upsert<Settings>(EntityType.Settings, [merged]);
    };
}
