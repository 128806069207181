import Locale_BaseOverview from "./Overviews.ts/BaseOverview";

export default class Locale_ProjectOverview implements Locale_BaseOverview {
	// NOt USED BUT NEEDED (ewi)
		ConfirmCountInactivateTitle: string;
		ConfirmCountInactivateSubtextSingle: string;
		ConfirmCountInactivateSubtextMultiple: string;

	
	FailedGettingProjects: string = "Failed getting projects";
	UpdatingPleaseWait: string = `Updating... please wait`;

	OverviewTitle: string = 'Project overview';
	DialogTitle: string = 'Project editor';

	UpdatedSingle: string = 'Project saved!';
	UpdatedMultiple: string = 'Projects saved!';

	DeletedSingle: string = "Project deleted!";
	DeletedMultiple: string = 'Projects deleted!';

	FailedUpdatingMultiple: string = "Failed updating projects!";
	FailedUpdatingSingle: string = 'Failed saving project!';
	
	FailedDeletingSingle: string = "Failed deleting project!";
	FailedDeletingMultiple: string = 'Failed deleting projects!';
	
	SearchPlaceHolder: string = `Search in [[numberofprojects]] projects..`;

	ConfirmDeleteQuestionHeader: string = "Delete";
	ConfirmDeleteQuestionSingle: string = "Are you sure you want to delete this project";
	ConfirmDeleteQuestionMultiple: string = "Are you sure you want to deletes these projects";
	ConfirmDeleteOk: string = "Yes";
	ConfirmDeleteCancel: string = "Cancel";

	ConfirmCountCascadingSubtextSingle: string = "ALL the related data for this project WILL BE DELETED, and cannot be undone!!";
	ConfirmCountCascadingSubtextMultiple: string = "ALL the related data for all these projects WILL BE DELETED, and cannot be undone!!";
	ConfirmCountCascadingTitle: string = "Are you really sure?!";
	ConfirmCascadingSubtextSingle: string = "Do you want to delete the selected project's related data, as well (contracts, requests, allocations)?";
	ConfirmCascadingSubtextMultiple: string = "Do you want to delete all the selected projects' related data, as well (contracts, requests, allocations)?";
	ConfirmCascadingTitle: string = "Project related data";
	ConfirmCascadingOkButton: string = "Delete ALL related data";
	ConfirmCascadingCancelButton: string = "Just delete the project";
	
	ActivateHeaderButton: string = "Set active";
	ActivateButton: string = "Activate";
	DeactivateButton: string = "Deactivate";

	ConfirmActiveTitle: string = "Are you sure you want to activate this project?";
	ConfirmInactiveTitle: string = "Are you sure you want to deactivate this project?";
	ConfirmActiveStateOkButton: string = "Ok";
	ConfirmActiveStateCancelButton: string = "Cancel";
	ConfirmActiveSubtextSingle: string = "Doing so makes it available throughout Team Planner.";
	ConfirmActiveSubtextMultiple: string = "Doing so makes them available throughout Team Planner.";
	ConfirmInactiveSubtextSingle: string = "Doing so makes it unavailable throughout Team Planner.";
	ConfirmInactiveSubtextMultiple: string = "Doing so makes them unavailable throughout Team Planner.";
	
	AutoInactivationEnabled: string = "Activation and deactivation of projects is controlled automatically, or you don't have permission to set this.";
}