import { CellRow, ICellRow } from "./CellRow";
import { GroupRow } from "./GroupRow";

export class BaseRow {
	public TypeName = "BaseRow";
	public CellRows: Array<CellRow>;

	// TODO: temp
	public Rows: Array<GroupRow>;
	
    constructor(public Id: string, public Name: string, public Properties: { [key: string]: any } = {}) {
        this.CellRows = new Array<CellRow>();
    }
}

export interface IBaseRow {
	TypeName: "BaseRow" | string;
	Id: string,
	Name: string,
	Properties: {
		[key: string]: any
	}
	// Temp
	// Rows: IGroupRow[];
	CellRows: ICellRow[]
}