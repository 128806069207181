/* eslint-disable react-refresh/only-export-components */
import { IDropdownOption, SelectableOptionMenuItemType } from '@fluentui/react';
import { FunctionComponent, memo } from 'react';
import { DropdownOption } from '../../Entities/Fabric/DropdownOption';
import { language } from '../../Services/LocalizationService';
import { Searcher } from './Searcher';

interface ISearchProps {
    Items: Array<any>;
    Readonly?: boolean;
    FilterCallback: (filterItems: Array<any>) => void;
}
export const FakeSearchHeaderItem: IDropdownOption = { key: '#tpsearcherkey#', text: '####', itemType: SelectableOptionMenuItemType.Header };
export const CreatePFDropDownFilteredItems = (
    filterItems: Array<any>,
    fakeSearchHeader: IDropdownOption,
    searchable: boolean,
    readonly: boolean,
): Array<any> => {
    // clone
    let clonedFilterItems = [...filterItems];
    // if empty, do nothing
    if (!clonedFilterItems?.length) {
        clonedFilterItems = clonedFilterItems ?? [];
        if (searchable) clonedFilterItems.unshift(fakeSearchHeader);
        return clonedFilterItems;
    }
    // auto parse if not correct type
    if (!Object.hasOwn(clonedFilterItems[0], 'key') || !Object.hasOwn(clonedFilterItems[0], 'text'))
        clonedFilterItems = clonedFilterItems.map(_ => new DropdownOption(_, _));

    // not using search or readonly
    if (!searchable || readonly) return clonedFilterItems;
    // already there
    if (clonedFilterItems.findIndex(_ => _.key === fakeSearchHeader.key) >= 0) return clonedFilterItems;
    // add search box using a fake header item
    clonedFilterItems.unshift(fakeSearchHeader);
    return clonedFilterItems;
};

const PFDropDownSearchComponent: FunctionComponent<ISearchProps> = props => {
    return (
        <Searcher
            Items={props.Items}
            OnSearch={(filterItems: Array<any>, searchText: string) => {
                // guard for when filtering is removing the searcher (TODO: better)
                const fil = CreatePFDropDownFilteredItems(filterItems, FakeSearchHeaderItem, true, props.Readonly);
                props.FilterCallback(fil);
            }}
            ClassName="tp-searcher-pfdropdown"
            Delay={200}
            Disabled={props.Readonly}
            SearchPlaceHolder={language.Common.Search}
            EnableTextHighlight={false}
            SearchFields={['text', 'key']}
        />
    );
};

const comparisonFn = (prevProps: ISearchProps, nextProps: ISearchProps) => {
    return prevProps.Items === nextProps.Items;
};

export default memo(PFDropDownSearchComponent, comparisonFn);
