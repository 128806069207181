import { Icon, Stack, IconButton, AnimationClassNames } from '@fluentui/react';
import { memo, useMemo, forwardRef, useImperativeHandle, CSSProperties, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { useDrag } from '@use-gesture/react';
import { useEvent } from '../../../../../../hooks/useEvent';
import { GridData, GridRow } from '../../../../../../api/generated/data-contracts';
import { DragBox } from '../../../../_grid/components/DragBox';
import { AllRowsClosedProvider, useAllRowsClosedContext } from '../../../../_grid/contexts/allRowsClosedContext';
import { ExpandedGridProvider, useExpandedGridContext } from '../../../../_grid/contexts/expandedContext';
import { GridContext, GridContextProvider, useGridContext } from '../../../../_grid/contexts/gridContext';
import { RowContext, GridRowContext } from '../../../../_grid/contexts/rowContext';
import { InternalGridData } from '../../../../_grid/grid-types';
import { UseGridProps, useGrid, ExpandedState } from '../../../../_grid/useGrid';
import { Row } from './Row/Row';
import { Header, SortOrder, useSortedByColumnState } from './Header';
import { sortRowProperty } from '../../../../_grid/helpers/sortRowProperty';

type ResourcePlannerGridProps = UseGridProps;

export const ResourcePlannerGrid = memo(
    forwardRef<{ getUpdatedData: () => Promise<GridData> }, ResourcePlannerGridProps>(function ResourcePlannerGrid(gridProps, ref) {
        const {
            gridData: { visibleRows, maxGridDepth, allClosed },
            gridMethods: {
                getRowAncestorTreeToRoot,
                getSubRows,
                updateCellValue,
                removeRow,
                addRow,
                updateMultipleCells,
                useGridCellState,
                useGridCellValue,
                getUpdatedData,
                moveRow,
                getRowsMap,
				setRootRows: setRows
            },
            staticGridData: { headers, name, metadata, id },
            expandedRows,
            setExpandedRows,
        } = useGrid(gridProps);

        const context = useMemo((): GridContext => {
            return {
                name,
                headers,
                properties: metadata,
                id,
                updateCellValue,
                getRowAncestorTreeToRoot,
                removeRow,
                addRow,
                readonly: gridProps.readonly,
                updateMultipleCells,
                maxGridDepth,
                useGridCellState,
                useGridCellValue,
                moveRow,
                getRowsMap,
				setRootRows: setRows
            };
        }, [
            name,
            headers,
            metadata,
            id,
            updateCellValue,
            getRowAncestorTreeToRoot,
            removeRow,
            addRow,
            gridProps.readonly,
            updateMultipleCells,
            maxGridDepth,
            useGridCellState,
            useGridCellValue,
            moveRow,
            getRowsMap,
			setRows
        ]);

        const expandedContext = useMemo(() => {
            return {
                expandedRows,
                setExpandedRows,
            };
        }, [expandedRows, setExpandedRows]);

        useImperativeHandle(
            ref,
            () => {
                return {
                    getUpdatedData,
                };
            },
            [getUpdatedData],
        );

        const scrollRef = useRef<HTMLDivElement>(null);

        return (
            <GridContextProvider value={context}>
                <ExpandedGridProvider value={expandedContext}>
                    <AllRowsClosedProvider value={allClosed}>
                        <div className={classNames('tp-grid', AnimationClassNames.fadeIn400)}>
                            <ScrollPane ref={scrollRef}>
                                <table>
                                    <thead>
                                        <tr className="grid-toprow">
                                            <GridHeader name={name} metadata={metadata} id={id} />
                                            {headers.map((header, i) => {
                                                return <Header key={header.name} index={i} header={header} />;
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {visibleRows.map(({ row, visible, nestingLevel, expanded, rowRelations }, i) => {
                                            return (
                                                <RowWrapper
                                                    key={row.id}
                                                    row={row}
                                                    setExpandedRows={setExpandedRows}
                                                    visible={visible}
                                                    expanded={expanded}
                                                    getRowAncestorTreeToRoot={getRowAncestorTreeToRoot}
                                                    getSubRows={getSubRows}
                                                    nestingLevel={nestingLevel}
                                                    // rowComponent={Row}
                                                    maxGridDepth={maxGridDepth}
                                                    rowIndex={i}
                                                    rowRelations={rowRelations}
                                                />
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <DragBox scrollRef={scrollRef} triggerMargins={{ marginLeft: 330 }} />
                            </ScrollPane>
                        </div>
                    </AllRowsClosedProvider>
                </ExpandedGridProvider>
            </GridContextProvider>
        );
    }),
);

const scrollPaneStyles: CSSProperties = {
    position: 'absolute',
    overflowY: 'auto',
    inset: 0,
};

const ScrollPane = forwardRef<HTMLDivElement, React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>((props, ref) => {
    return <div style={scrollPaneStyles} {...props} ref={ref} />;
});

type RowWrapperProps = {
    row: GridRow;
    rowIndex: number;
    setExpandedRows: React.Dispatch<React.SetStateAction<ExpandedState>>;
    visible: boolean;
    expanded: boolean;
    getRowAncestorTreeToRoot: (rowId: string) => GridRow[];
    getSubRows: (rowId: string) => GridRow[];
    // rowIndex: number;
    // rowComponent: (props: RowProps) => JSX.Element;
    nestingLevel: number;
    maxGridDepth: number;
    rowRelations: GridRow[];
};

const RowWrapper = memo(
    ({
        row,
        setExpandedRows,
        visible,
        getRowAncestorTreeToRoot,
        getSubRows,
        expanded,
        // rowComponent,
        nestingLevel,
        maxGridDepth,
        rowIndex,
        rowRelations,
    }: RowWrapperProps) => {
        const subRows = useMemo(() => getSubRows(row.id), [getSubRows, row.id]);
        const canExpand = Boolean(subRows.length);
        const toggleExpanded = useEvent((toggle?: boolean) => {
            if (canExpand) {
                setExpandedRows(state => {
                    const toggleState = toggle ?? !state[row.id];
                    const newState = { ...state, [row.id]: toggleState };
                    // Close children and grand children
                    if (!toggleState) {
                        return subRows.reduce((acc, subRow) => {
                            return { ...acc, [subRow.id]: false };
                        }, newState);
                    }
                    return newState;
                });
            }
        });

        const rowContext = useMemo(() => {
            const currentContext: RowContext = {
                row,
                getRowAncestorTreeToRoot,
                canExpand,
                nestingLevel,
                subRows,
                toggleExpanded,
                visible,
                rowIndex,
                rowRelations,
            };
            return currentContext;
        }, [row, getRowAncestorTreeToRoot, canExpand, nestingLevel, subRows, toggleExpanded, visible, rowIndex, rowRelations]);

        // const Row = rowComponent;

        return (
            <GridRowContext value={rowContext}>
                <Row
                    expanded={expanded}
                    getRowAncestorTreeToRoot={getRowAncestorTreeToRoot}
                    getSubRows={getSubRows}
                    row={row}
                    setExpandedRows={setExpandedRows}
                    visible={visible}
                    toggleExpanded={toggleExpanded}
                    canExpand={canExpand}
                    nestingLevel={nestingLevel}
                    subRows={subRows}
                    maxGridDepth={maxGridDepth}
                    rowRelations={rowRelations}
                />
            </GridRowContext>
        );
    },
);

type GridHeaderProps = Omit<InternalGridData, 'rows' | 'rowsList' | 'headers' | 'originalRows'>;

const GridHeader = ({ name, id }: GridHeaderProps) => {
    const { setExpandedRows } = useExpandedGridContext();
    const allRowsClosed = useAllRowsClosedContext();
    const bind = useDrag(({ down, memo, movement: [mx] }) => {
        if (!down) {
            return;
        }
        if (!memo) {
            return parseInt(document.documentElement.style.getPropertyValue('--gridRowWidth') || '300', 10);
        }
        const newX = memo + mx;
        document.documentElement.style.setProperty('--gridRowWidth', newX + 'px');
    }, {});
    return (
        <>
            <th>
                <div className="tp-grid__title-container">
                    <Stack horizontal horizontalAlign="start" verticalAlign="end" styles={{ root: { width: '100%', paddingBottom: 10 } }}>
                        <IconButton
                            iconProps={{ iconName: 'CalculatorAddition' }}
                            title="Expand all"
                            onClick={() => {
                                setExpandedRows(expanded => {
                                    return Object.fromEntries(Object.keys(expanded).map(key => [key, true]));
                                });
                            }}
                        />
                        <IconButton
                            iconProps={{ iconName: 'CalculatorSubtract' }}
                            title="Collapse all"
                            disabled={allRowsClosed}
                            onClick={() => {
                                setExpandedRows(expanded => {
                                    return Object.fromEntries(Object.keys(expanded).map(key => [key, false]));
                                });
                            }}
                        />
						<SortNameButton />
                    </Stack>
                </div>
            </th>
            <th className="header-filler">
                <div className="tp-grid-rowresize" {...bind()}>
                    <Icon iconName="ImportMirrored" className="tp-grid-rowresize-icon noselect" title="Resize resource column" />
                </div>
            </th>
        </>
    );
};

const SortNameButton = () => {
    const { setRootRows } = useGridContext();
    const [currentSortedValue, setCurrentSortedValue] = useSortedByColumnState();
    const id = 'name';
    const onClick = useCallback(async () => {
        let order: SortOrder = 'asc';
        if (currentSortedValue?.id === id) {
            order = currentSortedValue.order === 'desc' ? 'asc' : 'desc';
        }
        setRootRows(rows => rows.sort((a, b) => sortRowProperty(a.name, b.name, order)));
        setCurrentSortedValue({ id, isCustom: false, order });
    }, [currentSortedValue?.id, currentSortedValue?.order, setCurrentSortedValue, setRootRows]);
    const iconName = useMemo(() => {
        if (!currentSortedValue || currentSortedValue.id !== id) {
            return 'Sort';
        }
        if (currentSortedValue.order === 'asc') {
            return 'SortUp';
        }
        return 'SortDown';
    }, [currentSortedValue]);
    return <IconButton iconProps={{ iconName }} onClick={onClick} />;
};


