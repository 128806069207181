import { memo, useMemo, CSSProperties } from 'react';
import { ProjectColumnRow } from './ProjectColumnRow';
import { ColumnRowProps } from '../../ColumnRowProps';
import { ROW_HEIGHT, COLUMN_WIDTH, ROW_EXPANDED_HEIGHT, BORDER } from '../../../../CONSTANTS';

export const ProjectColumnContainer = memo(function ProjectColumnContainer({
    columnIndex,
    columnRow,
    rowId,
    isAdminProject,
    start,
    expanded,
}: ColumnRowProps & { start: number; expanded: boolean }) {
    const style = useMemo(() => {
        return {
            width: `${COLUMN_WIDTH}px`,
            position: 'absolute',
            transform: `translateX(${start}px)`,
            top: 0,
            left: 0,
            height: `${expanded ? ROW_EXPANDED_HEIGHT : ROW_HEIGHT}px`,
            boxSizing: 'border-box',
            borderBottom: BORDER,
            borderRight: BORDER,
			backgroundColor: "inherit",
        } as CSSProperties;
    }, [expanded, start]);

    return (
        <div style={style}>
            <ProjectColumnRow columnRow={columnRow} columnIndex={columnIndex} rowId={rowId} isAdminProject={isAdminProject} expanded={expanded} />
        </div>
    );
});
