import { Cell, ICell } from "./Cell";
import { guid } from "../../helpers/guid";

export class CellRow {
	public Id: string;
	public Cells: Array<Cell>;
    constructor(public Start: Date, public End: Date, public Properties: { [key: string]: any } = {}) {
		this.Cells = new Array<Cell>();
		this.Id = this.Id || guid.newGuid();
	}
}

export const makeCellRow = (rowInfo: Partial<ICellRow> & Omit<ICellRow, "Id" | "Cells">): ICellRow => {
	return {
		...rowInfo,
		Id: rowInfo.Id || guid.newGuid(),
		Cells: rowInfo.Cells || []
	}
}

export interface ICellRow {
	Id: string,
	Cells: ICell[],
	Start: Date,
	End: Date,
	Properties: {
		[key: string]: any
	}
}