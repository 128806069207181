import { memo, useMemo, CSSProperties } from 'react';
import { ROW_HEIGHT, BORDER, CUSTOM_COLUMN_WIDTH, ROW_EXPANDED_HEIGHT } from '../../../../CONSTANTS';
import { useGridRowContext } from '../../../../../../../_grid/contexts/rowContext';
import { ProjectCustomColumnCell } from '../../../../context/customColumnContext';

export const CustomColumnContainer = memo(function ContractColumnContainer({
    // columnIndex,
    // columnRow,
    // rowId,
    // isAdminProject,
    // index,
    start,
    option,
    rowCustomColumns,
	expanded

}: { start: number, expanded: boolean; } & Parameters<typeof ProjectCustomColumnCell>[0]) {
    const { toggleExpanded } = useGridRowContext();
    const style = useMemo(() => {
        return {
            width: `${CUSTOM_COLUMN_WIDTH}px`,
            position: 'absolute',
            transform: `translateX(${start}px)`,
            top: 0,
            left: 0,
            height: `${expanded ? ROW_EXPANDED_HEIGHT : ROW_HEIGHT}px`,
            boxSizing: 'border-box',
            borderBottom: BORDER,
            borderRight: BORDER,
        } as CSSProperties;
    }, [expanded, start]);
    return (
        <div style={style} onClick={() => toggleExpanded()}>
            <ProjectCustomColumnCell option={option} rowCustomColumns={rowCustomColumns} />
        </div>
    );
});
