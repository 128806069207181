import { noopFunction } from "./functionPrimitives";

const DEVELOPMENT_MODE = process.env.NODE_ENV === "development";
declare global {
	interface Window {
		/**
		 * Make the devLogger work in production mode
		 */
		__debug?: boolean
	}
}

export const devLogger = new Proxy(console, { get: (target, prop) => {
	const value = target[prop];
	if (typeof value === "function") {
		if (DEVELOPMENT_MODE || window.__debug) {
			return value
		} else {
			return noopFunction
		}
	}
	return value
}});
