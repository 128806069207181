import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { ContentConfigurationService } from '../../../Services/ContentConfigurationService';
import { RbsStructure } from '../../../Entities/ContentConfiguration/RbsStructure';
import { TreeViewItem } from '../../../Entities/TreeViewItem';
import { OverviewContext } from '../../../Utilities/Context/OverviewContext';
import { Rbs } from '../../../Entities/Rbs';
import { TreeEditor } from '../../../forms/_builders/tpComplexBuilder/components/tree/components/TreeEditor';
import { treeViewRefactor } from '../../../Utilities/TreeViewRefactor';
import { ITreeNode } from '../../../forms/_builders/tpComplexBuilder/components/tree/interfaces/ITreeNode';
import { IconButton, Stack, TextField } from '@fluentui/react';

interface IProps extends IDynamicEditorComponentProps {
	Item: string | Array<string> | TreeViewItem;
	Update: (item: string | Array<string> | TreeViewItem) => void;
	Type: "select" | "manage";
	SingleSelect?: boolean;
	DialogTitle?: string;
	ButtonTitle?: string;
	Delimiter?: string;
	DefaultExpandedDepth?: number;
}

const myRbsTemplate = "[[MyRbs]]";

const RbsEditor: FunctionComponent<IProps> = props => {
    const [rbs, setRbs] = useState<Rbs>();
	const [myRbsUsed, setMyRbsUsed] = useState<boolean>(props.Item === myRbsTemplate);

	const useMyRbs = useCallback(() => {
		if (!myRbsUsed) {
			props.Update(myRbsTemplate);
			setMyRbsUsed(true);
		} else {
			props.Update(null);
			setMyRbsUsed(false);
		}
	}, [myRbsUsed, props]);

	useEffect(() => {
		let mounted = true;
        const fetchLookups = async () => {
			const rbsStructureItems = await ContentConfigurationService.GetRbsStructures();
			if (!mounted) return;
			const rbsStructure = rbsStructureItems.find(_ => _.id === OverviewContext.Settings.RbsId) || new RbsStructure();
			setRbs(rbsStructure.Rbs);
				
        };
		// skip fetch of all rbs'
		if (props.Type === "manage") {
			setRbs(props.Item as TreeViewItem);
		} else fetchLookups();

		return () => {
			mounted = false;
		}
    }, [props.Item, props.Type])

	if (rbs == null) return null;

	return (
		<Stack horizontal>
			{ myRbsUsed && props.Type !== "manage"
				? <TextField value="My RBS" disabled />
				: <TreeEditor 
					tree={props.Type === "manage" ? treeViewRefactor.parseToTreeNode(props.Item as TreeViewItem) : treeViewRefactor.parseToTreeNode(rbs)} 
					type={props.Type} 
					onChanged={value => typeof(value) === "object" && !Array.isArray(value) ? props.Update(treeViewRefactor.parseToTreeView(value as ITreeNode)) : props.Update(value)}
					delimiter={props.Delimiter} 
					buttonTitle={props.ButtonTitle}
					dialogTitle={props.DialogTitle}
					multiSelect={!props.SingleSelect}
					selected={props.Type === "select" ? props.Item as string | Array<string> : null}
					readOnly={props.ReadOnly}
					defaultExpandedDepth={props.DefaultExpandedDepth}
					autoExpandSelected
				/>
			}
			{ props.Type !== "manage" &&
				<IconButton
					iconProps={
						!myRbsUsed ? 
						{ iconName: 'BulletedTreeList' } :
						{ iconName: 'Cancel' }
					}
					title={!myRbsUsed ? "Use dynamic 'My RBS' value" : "Clear dynamic 'My RBS' value"}
					onClick={useMyRbs}
				/>
			}
		</Stack>
	)
}

export default RbsEditor;