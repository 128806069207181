import { Notification, NotificationStatusType } from '../../Entities/Notification';
import { NotificationItem } from './NotificationItem';
import { Text } from '@fluentui/react/lib/Text';
import { Callout } from '@fluentui/react/lib/Callout';
import { Link } from '@fluentui/react/lib/Link';
import { ArrayEx } from '../../Utilities/ArrayEx';
import { FunctionComponent, useCallback } from 'react';
import { useEffect } from 'react';
import { useStore } from '../../context/store';

interface IProps {
    show: boolean;
    dismissCallback?: () => void;
    notificationsAvailableCallback?: (any: boolean, highestStatus: NotificationStatusType) => void;
    autoHideAfter?: number;
}

export const NotificationBar: FunctionComponent<IProps> = ({ show, dismissCallback, notificationsAvailableCallback, autoHideAfter }) => {
    const { setUIContext, notifications, setNotifications, clearNotifications } = useStore(store => ({
        setUIContext: store.setUIContext,
        notifications: store.notifications,
        setNotifications: store.setNotifications,
        clearNotifications: store.clearNotifications,
    }));

    useEffect(() => {
        if (notificationsAvailableCallback) {
            const maxStatus = Math.max(...(notifications ?? []).map(_ => _.StatusType));
            notificationsAvailableCallback(notifications?.length > 0, maxStatus);
        }
    }, [notifications, notificationsAvailableCallback]);

    useEffect(() => {
        if (notifications.length) {
            // settings says suppress show of notifications
            if (autoHideAfter < 0) return;
            // show notification callout
            setUIContext({ showNotifications: true });
            // autohide notification callout
            if (autoHideAfter > 0)
                setTimeout(() => {
                    setUIContext({ showNotifications: false });
                }, autoHideAfter);
        } else {
            setUIContext({ showNotifications: false });
        }
    }, [autoHideAfter, notifications.length, setUIContext]);

    const removeNotification = useCallback(
        (notification: Notification) => {
            setNotifications(notifications => notifications.filter(not => not.Id !== notification.Id));
        },
        [setNotifications],
    );

    const dismiss = useCallback((): void => {
        if (dismissCallback) dismissCallback();
    }, [dismissCallback]);

    const clear = useCallback(() => {
        clearNotifications();
        dismiss();
    }, [clearNotifications, dismiss]);

    return (
        <Callout
            hidden={!show}
            target=".tp-topbar-menu button[aria-label='notifications']"
            className="tp-notification-bar-container"
            doNotLayer
            gapSpace={-5}
            role="dialog"
            onDismiss={dismiss}
            setInitialFocus={false}
        >
            <div id="tp-notification-bar">
                {notifications.length <= 0 ? (
                    <Text styles={{ root: { padding: 4 } }}>No notifications..</Text>
                ) : (
                    <>
                        <Link id="notifications-dismiss" onClick={clear}>
                            Dismiss all
                        </Link>
                        <div className="divider initial" />
                        {ArrayEx.sortByNestedProperty([...notifications], 'TimeStamp', 'desc').map((_: Notification) => (
                            <div key={_.Id}>
                                <div className="divider" />
                                <NotificationItem notification={_} dismiss={removeNotification} />
                            </div>
                        ))}
                    </>
                )}
            </div>
        </Callout>
    );
};
