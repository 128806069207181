import { IPalette } from "@fluentui/react/lib/Styling";
import { IExtendedSemanticColors } from '../Theme.types';

export const darkColors = {
	bg: 'hsl(15, 3%, 23%)',
    bgHover: 'hsl(20, 2%, 19%)',
    bgExpandedHovered: 'hsl(20, 3%, 17%)',
    bgActive: 'hsl(20, 3%, 15%)',
    bgActiveHovered: 'hsl(20, 3%, 13%)',
};

export const palette: Partial<IPalette> = {
	themePrimary: '#0078d4',
	themeSecondary: '#379eff', // old #2b88d8
	themeTertiary: '#71afe5',
	themeLighterAlt: '#eff6fc',
	themeLighter: '#deecf9',
	themeLight: '#c7e0f4',
	themeDarkAlt: '#106ebe',
	themeDark: '#005a9e',
	themeDarker: '#004578',
	neutralLighterAlt: '#F9FAFA',
	neutralLighter: '#f4f4f6', // old - f3f2f1
	neutralLight: '#ECEDEE', // old - edebe9
	neutralQuaternaryAlt: '#e1dfdd',
	neutralQuaternary: '#d0d0d0',
	neutralTertiaryAlt: '#c8c6c4',
	neutralTertiary: '#a19f9d',
	neutralSecondary: '#605e5c',
	neutralPrimaryAlt: '#3b3a39',
	neutralPrimary: '#323130',
	neutralDark: '#201f1e',
	black: '#323130',
	white: '#ffffff',
	orange: "#bbb"
};

export const extendedSemanticColors: Partial<IExtendedSemanticColors> = {
/* DEFAULTS */
	bodyTextChecked: "#222120",
	inputText: "#222120",
	disabledText: "hsl(30, 2%, 47%)",

/* CUSTOMS */
	// Activity cell backgrounds
	activityOverBackground: '#FFEAD4',
	activityEqualBackground: '#E5F2E5',
	activityEqualContainsCategoryBackground: '#f8edff',
	activityZeroBackground: '#E5F2E5',
	activityUnderBackground: '#FFE5E5',
	// Capacity cell backgrounds
	capacityOverBackground: '#FFEAD4',
	capacityEqualBackground: '#E5F2E5',
	capacityUnderBackground: '#ECF4FF',
	// Overlay / navigation buttons
	overlayButtonBackground: darkColors.bg,
	overlayButtonBackgroundPressed: darkColors.bgExpandedHovered,
	overlayButtonText: palette.neutralLighter,

	searchHighligtColor: '#ffff00',

	tableBorderColorVertical: '#ebeeef',
	tableBorderColorHorizontal: '#ebeeef',
	tableDividerColor: '#c7ced1',
	tableDividerShadow: '1px 0px 3px #c7ced1',

	kpiInfinite: '#000000',
	kpiFree: "#ff0000",
	kpiOver: "#ffa500",
	kpiUsed: "#008000",
	kpiCategory: "#a62af5",
};

