import { UserType } from "../UserType";
import { guid } from "../../helpers/guid";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { ReportFilter } from "../ReportFilter";
import { MainView } from "../MainView";
import { DropdownOptionEx } from "../../Utilities/DropdownOptionEx";
import { IObjectEditorItemType } from "./IObjectEditorItemType";

export class Report implements IObjectEditorItemType {
	public TypeName: string = "Report";
	
	public Id: string;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Report.ReportName", 1)
	public Name: string;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Report.ReportEmbedUrl", 2)
	public Url: string;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Report.OpenNewTab", 3)
	public OpenInNewWindow: boolean;
	@PFPropertyBasicInfo(PropertyType.PredefinedArrayMulti, "_localization:Report.UserType", 4)
	@PFPropertyRender((item: Report, i, col) => item[col.fieldName].map(_ => UserType[_]).join(", "), { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutZeroOption<UserType>(UserType) })
	public UserTypes: Array<UserType>;
	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Common.View", 5, "_localization:Report.SelectReportToViewAvailability")
	@PFPropertyRender((item: Report, i, col) => item[col.fieldName] ? MainView[item[col.fieldName]] : "None", { PredefinedValues: DropdownOptionEx.enumToDropdownOptionsWithoutZeroOptionAddEmptyEntry<MainView>(MainView, 0, "Availability not filtered by view") })
	public View: MainView;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Filter", 6)
	@PFPropertyRender((obj: Report, idx, col) => obj.Filter != null ? "Filtered": "No filter", null, "ReportFilterEditor")
	public Filter: ReportFilter;

	constructor() {
		this.Id = this.Id || guid.newGuid();
	}
}