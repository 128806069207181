import { useCallback, useEffect, useRef, useState } from 'react';
import { HubConnectionBuilder, HubConnection, RetryContext, ILogger, HubConnectionState } from '@microsoft/signalr';
import { getAuthHeaders } from '../api/authConfig';

type IonMessage<T> = ((dto: T) => void) | ((dto: T) => void)[];
type TnextRetryHandler = (retryContext: RetryContext, destroyConnection: () => Promise<void>, makeConnection: () => Promise<HubConnection>) => number | null;
export interface IuseNotificationsProps<T> {
    connectOnMount?: boolean;
    url?: string | ((url: string) => string);
    /**
     * NOTE: These will be memoized until unmount.
     * and can only be changed through
     * @see useSignalRReturns
     * But its important that it/these has a stable reference
     */
    onMessage?: IonMessage<T>;
    eventName?: string;
    nextRetryDelayInMilliseconds?: TnextRetryHandler;
    logger?: ILogger;
    onStart?: () => void | Promise<void>;
}
export type useSignalRReturns<T> = {
    messages: T[];
    addHandler: (handler: (dto: T) => void) => void;
    removeHandler: (handler: (dto: T) => void) => void;
} & ReturnType<typeof useSignalR>;
// export const useNotifications = <T extends any | T >({ url, connectOnMount = true, onMessage }: IuseNotificationsProps = {}) => {
export const useSignalR = <T>({
    url,
    connectOnMount = true,
    onMessage,
    eventName = 'onMessage',
    nextRetryDelayInMilliseconds,
    logger,
    onStart,
}: IuseNotificationsProps<T> = {}) => {
    const Connection = useRef<HubConnection | null>(null);
    const [messages, setMessages] = useState<T[]>([]);
    const onMessages = useRef<Set<(dto: T) => void>>(
        onMessage
            ? Array.isArray(onMessage)
                ? new Set([...onMessage, (dto: T) => setMessages(curMessages => [dto, ...curMessages])])
                : new Set([onMessage, (dto: T) => setMessages(curMessages => [dto, ...curMessages])])
            : new Set([(dto: T) => setMessages(curMessages => [dto, ...curMessages])]),
    );

    const nextRetryHandler = useRef<TnextRetryHandler | undefined>(nextRetryDelayInMilliseconds);

    useEffect(() => {
        nextRetryHandler.current = nextRetryDelayInMilliseconds;
    }, [nextRetryDelayInMilliseconds]);

    const loggerFn = useRef<ILogger | undefined>(logger);

    const getUrl = useRef(
        typeof url === 'function'
            ? url(`${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`)
            : url || `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/signalr/notifications`,
    );

    useEffect(() => {
        getUrl.current =
            typeof url === 'function'
                ? url(`${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`)
                : url ||
                  `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/signalr/notifications`;
    }, [url]);

    const connectionHasBeenShutDown = useRef(false);
    const onStartConnection = useRef(onStart);

    const startConnection = useCallback(() => {
        if (Connection.current && Connection.current.state === HubConnectionState.Disconnected) {
            return Connection.current.start().then(() => {
                if (onStartConnection.current) {
                    onStartConnection.current();
                }
                return Connection.current;
            });
        }
    }, []);

    const stopConnection = useCallback(async () => {
        if (Connection.current && Connection.current.state !== HubConnectionState.Disconnected) {
            return Connection.current.stop().then(() => Connection.current);
        }
    }, []);

    const addHandler = useCallback(
        (handler: (dto: T) => void) => {
            if (Connection.current) {
                if (!onMessages.current.has(handler)) {
                    Connection.current.on(eventName, handler);
                    onMessages.current.add(handler);
                }
            }
        },
        [eventName],
    );

    const removeHandler = useCallback(
        (handler: (dto: T) => void) => {
            if (Connection.current) {
                if (onMessages.current.has(handler)) {
                    Connection.current.off(eventName, handler);
                    onMessages.current.delete(handler);
                    return true;
                }
                return false;
            }
            return false;
        },
        [eventName],
    );

    const shutDownConnection = useCallback(async () => {
        if (Connection.current) {
            if (Connection.current.state === HubConnectionState.Connected) {
                try {
                    await stopConnection();
                } catch (error) {
                    console.error('shutDownConnection ~ error', error);
                }
            }
            Connection.current = null;
            connectionHasBeenShutDown.current = true;
        }
    }, [stopConnection]);

    const makeConnection = useCallback(async () => {
        if (Connection.current) {
            onMessages.current.forEach(handler => {
                Connection.current?.off(eventName, handler);
            });
            await stopConnection();
        }

        // const full = url
        //     ? url
        //     : `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/signalr/notifications`;
        // Connection.current = new HubConnectionBuilder().withAutomaticReconnect().withUrl(`${full}/signalr/notifications`).build();
        // Connection.current = new HubConnectionBuilder().withAutomaticReconnect().withUrl(`${full}`, {transport: HttpTransportType.LongPolling}).build();

        const newConnection = new HubConnectionBuilder().withAutomaticReconnect(
            nextRetryHandler.current
                ? {
                      nextRetryDelayInMilliseconds: retryContext => {
                          return nextRetryHandler.current(retryContext, shutDownConnection, makeConnection);
                      },
                  }
                : undefined,
            // nextRetryHandler.current ? { nextRetryDelayInMilliseconds: nextRetryHandler.current } : undefined,
        );

        if (loggerFn.current) {
            newConnection.configureLogging(loggerFn.current);
        }
        newConnection.withUrl(getUrl.current, { headers: await getAuthHeaders() });
        // newConnection.withUrl(getUrl.current, { headers: authHeaders.current });
        Connection.current = newConnection.build();

        Connection.current.serverTimeoutInMilliseconds = 120000;

        onMessages.current.forEach(handler => {
            Connection.current?.on(eventName, handler);
        });
        connectionHasBeenShutDown.current = false;
        return Connection.current;
    }, [eventName, shutDownConnection, stopConnection]);

    const clearAllHandlers = useCallback(() => {
        if (Connection.current && onMessages.current.size) {
            onMessages.current.forEach(handler => {
                Connection.current?.off(eventName, handler);
            });
            onMessages.current.clear();
        }
    }, [eventName]);

    const clearMessages = useCallback(() => setMessages([]), []);

    const getConnection = useCallback(() => {
        return Connection.current;
    }, []);

    useEffect(() => {
        const shutDown = shutDownConnection;
        // Only start connection on mount and not when intentionally shut down
        if (Connection.current === null && connectOnMount && !connectionHasBeenShutDown.current) {
            makeConnection()
                .then(connection => connection.start())
                .then(() => {
                    console.info('Listening for notifications from teamplanner api..');
                })
                .then(() => {
                    if (onStartConnection.current) {
                        onStartConnection.current();
                    }
                })
                .catch(console.error);
            // const init = async () => {
            //     const connection = await makeConnection();
            //     await connection.start();
            //     console.info("Listening for notifications from teamplanner api..");
            // }
            // init();
        }
        return () => {
            if (Connection.current) {
                shutDown();
            }
        };
    }, [connectOnMount, makeConnection, shutDownConnection]);

    return {
        shutDownConnection,
        makeConnection,
        removeHandler,
        addHandler,
        stopConnection,
        startConnection,
        clearAllHandlers,
        clearMessages,
        messages,
        setMessages,
        getConnection,
    };
};
