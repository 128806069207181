/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo } from 'react';
import ObjectListEditor, { ItemDialogType } from '../../ObjectListEditor';
import { DynamicProps } from './DynamicProps';
 
type IProps = DynamicProps<Array<any>, object>

const DynamicObjectArray: FunctionComponent<IProps> = props => {

    return  <div key={ props.Key }>
				{/* <Label required={props.Validation && !props.ReadOnly}>{props.PropertyInfo.DisplayName}</Label> */}
				<div style={ { paddingLeft: "20px" } }>
					<ObjectListEditor 
						ItemIdentifier={null} 	// comes from decorator below
						ClonedItemChange={null} // comes from decorator below
						ItemType={null} 		// comes from decorator below
						ReadOnly={props.ReadOnly}
						HiddenColumnProperties={[]}
						HiddenProperties={[]}
						DisabledProperties={["id"]}
						Items={props.Value}
						UserSortEnabled={true}
						DialogTitle={props.PropertyInfo.DisplayName}
						DialogType={ItemDialogType.Dialog}
						{...props.PropertyInfo.ComponentProps} // from decorator
						ChangesCallback={ (items: Array<any>) => props.OnChange(props.PropertyInfo.PropertyName, items) }
					/>
				</div>
			</div>
}

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
	return prevProps.Value === nextProps.Value;
};

export default memo(DynamicObjectArray, comparisonFn);