/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo } from 'react';
import { DynamicProps } from './DynamicProps';
import { TextField } from '@fluentui/react';
import { JsonEx } from '../../../../Utilities/JsonEx';

type IProps = DynamicProps<string, string>;

const DynamicJson: FunctionComponent<IProps> = props => {
    const OnChange = (value: string) => {
        const jsonObj = JsonEx.tryParse<any>(value);
        props.OnChange(props.PropertyInfo.PropertyName, jsonObj);
    };

    return (
        <TextField
            key={props.Key}
            defaultValue={JsonEx.tryStringify(props.Value)}
            onChange={(obj, value) => OnChange(value)}
            disabled={props.ReadOnly}
            type={'text'}
            multiline={true}
            resizable={true}
        />
    );
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.Value === nextProps.Value;
};

export default memo(DynamicJson, comparisonFn);
