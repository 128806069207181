import { PrimaryButton } from '@fluentui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IFieldConfig, IFieldRenderProps, IFormSchema, useFormContext } from 'spark-forms';
import { CustomProperty, QueryGroup } from '../../../../api/generated/data-contracts';
import { PFDialog } from '../../../../Components/Common/PFDialog';
import { EntityPropertyFilter } from '../../../../components_new/EntityPropertyFilter';
import { objectUtil } from '../../../helpers/object';

export interface IEntityFieldFilterProps<T> {
    dialogTitle?: string;
    buttonText?: string;
    schema: () => IFormSchema<T>;
    customProperties?: () => CustomProperty[];
	forcedIncludedFields?: () => string[];
    entityType?: 'resource' | 'project' | 'contract' | 'other';
}

export const EntityFieldFilterEditor: React.FC<any> = <T,>({
    value,
    disabled,
    fieldName,
    options,
}: IFieldRenderProps<T, IFieldConfig<IEntityFieldFilterProps<T>>, QueryGroup | undefined>) => {
    const { onChange } = useFormContext();

    const [editMode, setEditMode] = useState<boolean>(false);
    const [localValue, setLocalValue] = useState<QueryGroup>();

    const { schema: schemaFunc, customProperties, forcedIncludedFields, entityType, buttonText, dialogTitle } = options?.config as IEntityFieldFilterProps<T>;

    useEffect(() => {
        setLocalValue(objectUtil.deepClone(value));
    }, [value]);

    const change = useCallback((group: QueryGroup) => {
        setLocalValue(objectUtil.deepClone(group));
    }, []);

    const schema = useMemo(() => schemaFunc(), [schemaFunc]);

    return (
        <div>
            <PrimaryButton text={buttonText ?? 'Edit filter'} onClick={() => setEditMode(true)} />
            {editMode && (
                <PFDialog
                    title={dialogTitle ?? 'Edit'}
                    maxWidth={1500}
                    showDialog={editMode}
                    callback={() => {
                        onChange(fieldName, objectUtil.deepClone(localValue));
                        setEditMode(false);
                    }}
                    dismissCallback={() => {
                        setEditMode(false);
                        setLocalValue(objectUtil.deepClone(value));
                    }}
                    buttonDefaultDisabled={disabled}
                >
                    <EntityPropertyFilter
                        item={localValue}
                        schema={schema}
                        customProperties={customProperties?.()}
                        onChanged={change}
                        filterSystemProperties
                        filterArrayProperties
						forcedIncludedFields={forcedIncludedFields?.()}
                        entityType={entityType}
                    />
                </PFDialog>
            )}
        </div>
    );
};
