export default class Locale_WorkPackageEditor { 
	CopiedDataLargerThanAvailableArea: string = "The copied data is larger than the available area in the grid! Affected cells have been updated properly and excess data has been discarded.";
	SuccessfullyRemovedFutureActivitiesFor: string = "Successfully removed future activities for"
	FailedChangingContractName: string = "Failed changing contract name! The contract does not really exists until work has been added.";
	FailedChangingContractGroupName: string = "Failed changing contract group name!";
	RenameWorkPackage: string = "Rename request template";
	RenameWorkPackageDescription: string = "Gives the request template another name";
	DeleteCurrentWorkPackage: string = "Delete the current request template";
	DeleteCurrentWorkPackageDescription: string = "Delete the template(cannot be undone)";
	ContractComesFromChildPackage: string = `This contract comes from a child template. Click to go to the child template.`
	GroupingContainsContractsFromChildPackages: string = `This grouping contains contracts from child templates.`;
	AddToGroup: string = "Add to group";
	MoveOutOfGroup: string = "Move out of group";
	DropHere: string = "Drop here";
	NewGroup: string = "New group";
	PackageName: string = "Template name";
	DeleteWorkPackage: string = "Delete request template";
	CannotBeUndone: string = "This cannot be undone!";
}