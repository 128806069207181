import { IconButton, Stack, TextField } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IDateFieldConfig, IFieldRenderProps, useFormContext, useWatch } from "spark-forms";
import { FluentDateField } from "spark-forms-fluent8";

const todayTemplate = "[[Today]]";

export const DateWithTodayFilterField: React.FC<any> = <T,>({
	value,
    disabled,
    options,
    fieldName,
	path
}: IFieldRenderProps<T, IDateFieldConfig<T>, Date | string | undefined>) => {

	const { onChange } = useFormContext();

    const [localValue, setLocalValue] = useState(value);

	useWatch(path, () => setLocalValue(value), [value]);

	useEffect(() => {
		setLocalValue(value);
	}, [value])
	

    const onLocalChange = useCallback((value: Date | string) => {
        setLocalValue(value);
        onChange(fieldName, value);
    }, [fieldName, onChange]);

	const todayUsed = useMemo(() => localValue === todayTemplate, [localValue]);

	const useToday = useCallback(() => {
		if (!todayUsed)
			onLocalChange(todayTemplate);
		else
			onLocalChange(null);
	}, [todayUsed, onLocalChange]);

	return 	(
		<Stack horizontal>
			{
				todayUsed
					? <TextField value="Today" disabled />
					: <FluentDateField
						value={localValue as Date}
						disabled={disabled}
						options={options}
						fieldName={fieldName}
						path={path}
					/>
			}
			<IconButton
				iconProps={
					!todayUsed ? 
					{ iconName: 'GotoToday' } :
					{ iconName: 'Cancel' }
				}
				title={!todayUsed ? "Use dynamic 'Today' value" : "Clear dynamic 'Today' value"}
				onClick={useToday}
			/>
		</Stack>
	)
}
