/* eslint-disable react-refresh/only-export-components */
import { useImperativeHandle, useContext, useState, useRef, forwardRef, useEffect, memo } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { KeyCodes } from '@fluentui/react';
import { TableContext } from './Table';
import { Cell } from '../../../Entities/Table/Cell';
import { TableStateManager } from '../../../Services/TableStateManager';
import { CellType } from '../../../Entities/Table/CellType';
import { useTheme } from '../../../Themes/themeContext';
import { useDragCtx } from './DragCopy/dragContext';
import DisabledTableCell from './DisabledTableCell';
import { IDraggedItem, IItem } from './DragCopy/DragTypes';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { NumberParser } from '../../../Utilities/NumberParser';
import { useStore } from '../../../context/store';
import { devLogger } from '../../../helpers/devLogger';

interface IProps {
    Cell: Cell;
    ColumnIndex: number;
    Index: number;
}

type IRef = any;

const numberParser = new NumberParser(navigator.language);

//TODO find type
const TableCell = forwardRef<IRef, IProps>((props, ref) => {
    const warning = useStore(store => store.addWarningNotification);
    const { setUIContext } = useStore(store => ({
        setUIContext: store.setUIContext,
    }));
    const ctx = useContext(TableContext) as TableStateManager;
    const theme = useTheme();
    const dragCtx = useDragCtx();

    const [isFocused, setIsFocused] = useState(false);
    const [editing, setEditing] = useState(false);
    const [valueAsString, setvalueAsString] = useState('');
    const [value, setValue] = useState(props.Cell.Value);
    const [valueChanged, setvalueChanged] = useState(false);
    const [bgColor, setBgColor] = useState('');
    const inputRef = useRef(null);
    const inputFieldRef = useRef(null);

    const GetCellStyles = () => {
        switch (props.Cell.Type) {
            case CellType.Top:
                return ctx.Get(_ => _.TopCellStyles) ? ctx.Get(_ => _.TopCellStyles)[props.Index](props.Cell, props.ColumnIndex) : null;
            case CellType.Group:
                return ctx.Get(_ => _.GroupCellStyles) ? ctx.Get(_ => _.GroupCellStyles)[props.Index](props.Cell, props.ColumnIndex) : null;
            case CellType.Row:
                return ctx.Get(_ => _.RowCellStyles) ? ctx.Get(_ => _.RowCellStyles)[props.Index](props.Cell, props.ColumnIndex) : null;
            case CellType.Container:
                return ctx.Get(_ => _.GroupContainerCellStyles) ? ctx.Get(_ => _.GroupContainerCellStyles)[props.Index](props.Cell, props.ColumnIndex) : null;
        }
    };

    useImperativeHandle(ref, () => ({
        getFieldPosition: () => (inputRef.current ? inputRef.current.getBoundingClientRect() : null),
        Cell: props.Cell,
        ColumnIndex: props.ColumnIndex,
        CellIndex: props.Index,
        getIsRendered: () => inputRef.current != null,
        setNewValue: (newValue: number, valueAsString: string) => {
            setvalueChanged(true);
            inputFieldRef.current.focus();
            setEditing(true);
            ctx.Get(_ => _.CellEditing)(true);
            setValue(newValue);
            setvalueAsString(valueAsString);
        },
    }));

    useEffect(() => {
        // TODO: move this out (ewi)
        // don't set colors for grandtotal column
        if (props.Cell.Properties.GrandTotal) return;
        const style = GetCellStyles() as any;
        if (style && style.field && style.field.backgroundColor) setBgColor(style.field.backgroundColor);
        props.Cell.ReRender = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.Cell.Type, props.Cell.ReRender]);

    const inputDragBoxStyle = mergeStyles({
        position: 'absolute',
        bottom: '-1px',
        right: '-1px',
        height: 6,
        width: 6,
        backgroundColor: theme.palette.accent,
        border: `1px solid ${theme.semanticColors.bodyBackground}`,
    });

    const CellExtraContent = ctx.Get(_ => _.CellExtraContent) ? ctx.Get(_ => _.CellExtraContent)(props.Cell, props.ColumnIndex, props.Index) : null;

    // TODO: REFACTOR THE HELL OUT OF THIS!!! - ewi
    return props.Cell.Disabled ? (
        <DisabledTableCell
            stringValue={numberParser.ParseToString(
                props.Cell.Value,
                ctx.Get(_ => _.GridCellDecimals),
            )}
            bgColor={bgColor}
            cellId={props.Cell.Id}
        />
    ) : (
        <span
            ref={inputRef}
            className={`tp-capacity-cell${props.Cell.Properties.CustomColumn ? ' customcolumn' : ''}${isFocused ? ' has-focus' : ''}`}
            style={{ zIndex: CellExtraContent ? 4 : 3 }}
        >
            <TextField
                data-cellid={props.Cell.Id}
                data-columnidx={props.ColumnIndex}
                data-cellrowidx={props.Index}
                componentRef={inputFieldRef}
                className="tp-capacity-input noselect"
                draggable={false}
                onDragOver={(e: any) => {
                    e.dataTransfer.dropEffect = 'none';
                    e.preventDefault();
                    return false;
                }}
                value={
                    editing || isFocused
                        ? valueAsString
                        : numberParser.ParseToString(
                              props.Cell.Value,
                              ctx.Get(_ => _.GridCellDecimals),
                          )
                }
                readOnly={dragCtx.isDragging}
                onBlur={e => {
                    setIsFocused(false);
                    if (dragCtx.isDragging) {
                        setEditing(false);
                        return;
                    }
                    dragCtx.setShowDragBox(false);
                    dragCtx.setDraggedItem(null);
                    // limit the amount to input
                    if (value > 100000) {
                        warning('The inputted value is too high and has not been saved! Please change it to a value below 100000'); //TODO LOCALIZATION
                        e.target.style.border = '1px solid red';
                        e.stopPropagation();
                        e.preventDefault();
                        return;
                    }
                    e.target.style.border = 'unset';
                    setEditing(false);
                    ctx.Get(_ => _.CellEditing)(false);
                    if (!valueChanged) return;
                    if (!ctx.Get(_ => _.CellUpdates)) return;
                    setUIContext({ gridHasChanged: true });
                    ctx.Get(_ => _.CellUpdates)[props.Index](props.Cell, props.ColumnIndex, value, e);
                    setvalueChanged(false);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onFocus={e => {
                    const parsedValue = numberParser.ParseToString(
                        props.Cell.Value,
                        ctx.Get(_ => _.GridCellDecimals),
                        false,
                    );
                    e.target.value = parsedValue;
                    setvalueAsString(parsedValue);
                    setIsFocused(true);
                    const { top, left, right, bottom } = inputRef.current.getBoundingClientRect();

                    dragCtx.setDraggedItem({
                        Cell: { ...props.Cell },
                        Value: numberParser.ParseToString(
                            props.Cell.Value,
                            ctx.Get(_ => _.GridCellDecimals),
                        ),
                        CellIndex: props.Index,
                        ColumnIndex: props.ColumnIndex,
                        top,
                        left,
                        // window.innerHeight/width is needed to find the real right/bottom position
                        right: window.innerWidth - right,
                        bottom: window.innerHeight - bottom,
                    } as IDraggedItem);
                    dragCtx.setShowDragBox(true);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onKeyDown={OnKeyDown}
                onKeyUp={e => {
                    OnKeyUp(
                        e as any,
                        ctx.Get(_ => _.EditableColumnCount),
                    );
                    if (e.shiftKey && e.keyCode === KeyCodes.right) {
                        e.preventDefault();
                        const nextCell = findNextCell(dragCtx.inputNodes, { Cell: props.Cell, ColumnIndex: props.ColumnIndex, CellIndex: props.Index });
                        nextCell && nextCell.value.setNewValue(value, valueAsString);
                    }
                }}
                onChange={(e, newValue) => {
                    setvalueChanged(true);
                    setEditing(true);
                    ctx.Get(_ => _.CellEditing)(true);
                    const parsedValue = numberParser.Parse(newValue);

                    setValue(parsedValue);
                    setvalueAsString(newValue);
                    // update draggedItemValue
                    const newDraggedItem = { ...dragCtx.draggedItem };
                    newDraggedItem.Cell.Value = parsedValue;
                    newDraggedItem.Value = parsedValue.toFixed(ctx.Get(_ => _.GridCellDecimals));
                    dragCtx.setDraggedItem(newDraggedItem);
                }}
                onPaste={e => {
                    dragCtx.copyFromExcel({
                        ColumnIndex: props.ColumnIndex,
                        CellIndex: props.Index,
                        Cell: props.Cell,
                        Value: numberParser.ParseToString(
                            props.Cell.Value,
                            ctx.Get(_ => _.GridCellDecimals),
                        ),
                    });
                    e.preventDefault();
                    setIsFocused(false);
                }}
                disabled={props.Cell.Disabled}
                onClick={Select.bind(this)}
            />
            {isFocused ? <div className={inputDragBoxStyle}></div> : null}
            {CellExtraContent}
        </span>
    );
});

const Select: any = (e: Event) => (e.currentTarget as HTMLInputElement).select();

const OnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // we handle tab ourselves
    if (e.keyCode === 9 || e.keyCode === 37) e.preventDefault();
    // intercept up and down
    if (e.keyCode === 38 || e.keyCode === 40) e.preventDefault();
    // intercept plus and minus
    if (e.keyCode === 187 || e.keyCode === 189) e.preventDefault();
    if (e.keyCode === 107 || e.keyCode === 109) e.preventDefault();
    // Shift and Right Arrow
    if (e.shiftKey && e.keyCode === 39) e.preventDefault();
    // backspace and delete - don't intercept
    if (e.keyCode === 8 || e.keyCode === 46) return;
    // numbers
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) return;
    // decimals
    if (e.keyCode === 188 || e.keyCode === 190 || e.keyCode === 110) return;
    // copy/paste
    if (e.ctrlKey && (e.keyCode === 67 || e.keyCode === 86)) return;
    // refresh page
    if ((e.ctrlKey && e.keyCode === 82) || e.keyCode === 116) return;

    e.preventDefault();
};

const OnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, columnCount: number) => {
    // TAB OR RIGHT
    if ((e.keyCode === 9 && !e.shiftKey) || e.keyCode === 39 || (e.keyCode === 9 && !e.shiftKey))
        SelectCell(e.currentTarget, index => index + 1, false, columnCount);
    // ENTER OR DOWN
    else if (e.keyCode === 13 || e.keyCode === 40) SelectCell(e.currentTarget, index => index + columnCount);
    // SHIFT+TAB OR ARROW LEFT
    else if ((e.keyCode === 9 && e.shiftKey) || e.keyCode === 37) SelectCell(e.currentTarget, index => index - 1, e.keyCode === 37, columnCount);
    // ARROW UP
    else if (e.keyCode === 38) SelectCell(e.currentTarget, index => index - columnCount);
};

const SelectCell = (current: HTMLInputElement, indexSelector: (index: number) => number, isMovingLeft?: boolean, columnCount?: number): void => {
    const newIndex = indexSelector(current.tabIndex);
    const newElement = document.querySelector(`.tp-capacity-input [tabindex = "${newIndex}"]`);
    if (newElement) {
        const newEl = newElement as HTMLInputElement;
        newEl.select();
        newEl.setSelectionRange(0, newEl.value.length);
    } else {
        current.blur();
        current.focus(); // refocus to prevent losing it entirely
    }

    //Scroll to cell
    shouldOffSet(current, newElement, newIndex, isMovingLeft || false, columnCount);
};

const shouldOffSet = (current: HTMLElement, newElement: Element, newIndex: number, isMovingLeft: boolean, columnCount: number) => {
    const currentColumn: number = parseInt(current?.getAttribute('data-columnidx'));
    const newColumn: number = parseInt(newElement?.getAttribute('data-columnidx'));

    const nextElement = document.querySelector(`.tp-capacity-input [tabindex = "${isMovingLeft ? newIndex - 1 : newIndex + 1}"]`);

    const nextColumn = parseInt(nextElement?.getAttribute('data-columnidx'));

    const isGoingToNextRow: boolean = newColumn < currentColumn;
    const isLastColumn: boolean = currentColumn === columnCount - 1;
    const isTooFewColumns: boolean = currentColumn === nextColumn;
    const isMovingToLastColumn: boolean = isMovingLeft === false && nextColumn === 0;

    if (isMovingLeft && isLastColumn) return;
    if (isGoingToNextRow || isLastColumn || isTooFewColumns || isMovingLeft || isMovingToLastColumn) {
        newElement?.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'end',
        });
    } else {
        nextElement?.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'end',
        });
    }
};

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
    const equal = prevProps.Cell.Value === nextProps.Cell.Value && !nextProps.Cell.ReRender;
    // nextProps.Cell.ReRender = false;
    return equal;
};

const findNextCell = (inputNodes: any, startCell: Partial<IItem>) => {
    let startCellFound = false;
    for (const [key, value] of inputNodes.entries()) {
        if (startCellFound && value.CellIndex === startCell.CellIndex && value.getIsRendered()) {
            devLogger.log(value.CellIndex, startCell.CellIndex);
            return { key, value };
        }

        if (!startCellFound && key === startCell.Cell.Id) startCellFound = true;
    }
};

export default memo(TableCell, comparisonFn);
