/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { Plugin } from '../../../Services/PluginInvoker';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import { TimelineResolution } from '../../../Entities/TimelineResolution';
import { TopRow } from '../../../Entities/Table/TopRow';
import Table from '../../../Components/Overview/Table/Table';
import { AnimationClassNames, IconButton, IContextualMenuItem, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, Stack } from '@fluentui/react';
import { Cell } from '../../../Entities/Table/Cell';
import { AddActivityDto, WorkType } from '../../../Entities/Dto/AddActivityDto';
import { OverviewContext } from '../../../Utilities/Context/OverviewContext';
import { ProjectCapacityService } from '../../../Services/ProjectCapacityService';
import { GroupRow } from '../../../Entities/Table/GroupRow';
import { useTheme } from '../../../Themes/themeContext';
import { EntityType } from '../../../Entities/EntityTypes';
import { EventEx } from '../../../Utilities/EventEx';
import { MessageBarButtonInfo } from '../../../Entities/Fabric/MessageBarButtonInfo';
import { Notification, NotificationStatusType } from '../../../Entities/Notification';
import { GroupContainerRow } from '../../../Entities/Table/GroupContainerRow';
import { guid } from '../../../helpers/guid';
import { ObjectEx } from '../../../Utilities/ObjectEx';
import { CapacityUtil } from '../../../Utilities/CapacityUtil';
import { Row } from '../../../Entities/Table/Row';
import { JsonEx } from '../../../Utilities/JsonEx';
import { GridType } from '../../../Entities/GridType';
import { CellUpdateDto } from '../../../Components/Overview/Table/DragCopy/CellUpdateDto';
import { UndoItem } from '../../../Entities/UndoItem';
import { ResourceType } from '../../../Entities/ResourceType';
import { CellType } from '../../../Entities/Table/CellType';
import { ResourcePanelItem } from '../../../ListItems/ResourcePanelItem';
import { language } from '../../../Services/LocalizationService';
import { UserSettingsService } from '../../../Services/Settings/UserSettingsService';
import PlannerViewSettingsHeader from './PlannerViewSettingsHeader';
import { IDs } from '../../../Utilities/IDs';
import { useStore } from '../../../context/store';
import { useNavigate } from 'react-router';
import { buildRoute } from '../../../helpers/routeHelpers';
import shallow from 'zustand/shallow';
import { LMCapacityCoachmarks } from '../../../userGuidance/areas/LMCapacityCoachmarks';
import { stringVariants } from '../../../helpers/stringVariants';
import { GridSkeleton } from '../../../Components/Skeleton/GridSkeleton';
import { useUnitType } from '../../../Utilities/UnitTypeUtil';
import { useUISettings } from '../../../context/network/http/QueryProvider/queries/UISettings';
import { RenderHideGenericResourceToggle } from '../../../Components/Common/RenderHideGenericResourceToggle';
import { capitalize } from '../../../context/network/http/QueryProvider/helpers/queryHelper';
import { Timeout } from '../../../types/runtimeTypes';
import { Text } from '@fluentui/react/lib/Text';
import { resetAlignStatus, shouldResetAlignStatus } from '../../../Utilities/GridUtils';
import { isOverAllocated, roundAllocation } from '../helpers';

const PlannerView = () => {
    const { timeline, setUIContext, setEntityCalloutInfo, addNotification, error, info, warning, success } = useStore(
        store => ({
            timeline: store.timeline,
            setUIContext: store.setUIContext,
            setEntityCalloutInfo: store.setEntityCalloutInfo,
            error: store.addErrorNotification,
            info: store.addInfoNotification,
            warning: store.addWarningNotification,
            addNotification: store.addNotification,
            success: store.addSuccesNotification,
        }),
        shallow,
    );
    const goTo = useNavigate();

    const { currentUnitType } = useUnitType();

    const { data: uiSettings } = useUISettings();

    const [view, _setView] = useState<TopRow>();
    const viewRef = useRef<TopRow>(view);
    const [loading, _setLoading] = useState(true);

    const setView = (view: TopRow) => {
        if (view != null) view.ChangeKey = IDs.makeId();
        viewRef.current = view; // keep updated
        _setView(view);
    };

    const setLoading = (loadingg: boolean) => {
        _setLoading(loadingg);
    };

    // ref is needed because updates can come from the outside
    const selectionsRef = useRef<{ ResourceIds: Array<string>; ProjectIds: Array<string> }>();
    const [selections, setSelections] = useState<{ ResourceIds: Array<string>; ProjectIds: Array<string> }>();

    const ignoreResolutionMismatch = useRef(false);

    const theme = useTheme();

    useEffect(() => {
        ForceRefreshData();
    }, [currentUnitType]);

    useEffect(() => {
        OverviewContext.RefreshLmCapacity = ForceRefreshData;
        // willunmount
        return () => (OverviewContext.RefreshLmCapacity = null);
    }, []);

    useEffect(() => {
        if (selections == null) return;
        if (timeline == null) return;
        selectionsRef.current = { ...selections };
        setLoading(true);
        const getView = async () => await LoadCapacity(selections.ProjectIds, selections.ResourceIds, timeline.start, timeline.end, timeline.resolution);
        getView();
    }, [selections]);

    useEffect(() => {
        if (selections == null || timeline == null) return;
        const getView = async () => await LoadCapacity(selections.ProjectIds, selections.ResourceIds, timeline.start, timeline.end, timeline.resolution);
        getView();
    }, [timeline]);

    const GetCapacity = async (start: Date, end: Date, resolution: TimelineResolution, projects: Array<string>, resources: Array<string>): Promise<TopRow> => {
        return await Plugin.Invoke<TopRow>(
            PluginIDs.GetLMCapacityView,
            { ProjectIds: projects ?? [], ResourceIds: resources ?? [], Start: start, End: end, DateResolution: resolution },
            'Failed getting LM capacity',
            true,
        );
    };

    const LoadCapacity = async (projects: Array<string>, resources: Array<string>, start: Date, end: Date, resolution: TimelineResolution) => {
        if (!ObjectEx.allNotNull(start, end, resolution)) {
            setLoading(false);
            return;
        }
        if (!resources?.length) setView(null);
        setLoading(true);
        const top = await GetCapacity(start, end, resolution, projects, resources);
        setView(top);
        setLoading(false);
    };

    const ForceRefreshData = async () => {
        if (selectionsRef.current?.ProjectIds && selectionsRef.current?.ResourceIds) {
            await LoadCapacity(selectionsRef.current.ProjectIds, selectionsRef.current.ResourceIds, timeline.start, timeline.end, timeline.resolution);
        }
    };

    const OnCellUpdate = (columnIndex: number, idx: number, cell: Cell, newValue: number, isUndo?: boolean, ignoreContainerName?: boolean): TopRow => {
        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);

        const group = flattenedGroups.find(_ => _.Id === cell.Properties.ContractId);
        const row = group.Rows.find(_ => _.Id === cell.Properties.ResourceId && !_.Properties.IsRequested);
        const cellRow = row.CellRows[columnIndex];

        // handle allocation on different resolution than request
        if (
            OverviewContext.Settings.EnableGridResolutionMismatchWarning &&
            !ignoreResolutionMismatch.current &&
            cellRow.Properties.RequestResolution &&
            (cellRow.Properties.RequestResolution.length > 1 ||
                (cellRow.Properties.RequestResolution.length === 1 && cellRow.Properties.RequestResolution[0] !== timeline.resolution))
        ) {
            warning(
                language.LMCapacity.OnCellUpdate.DifferentTimeResolution.replace(
                    '[[timelineresolution]]',
                    TimelineResolution[cellRow.Properties.RequestResolution[0]].toString(),
                ),
            );
            const notification = new Notification();
            notification.Message = language.CapacityViews.OnCellUpdate.NotificationMessage;
            notification.StatusType = NotificationStatusType.SevereWarning;
            notification.Buttons = [
                new MessageBarButtonInfo(language.CapacityViews.OnCellUpdate.Disable, () => (ignoreResolutionMismatch.current = true)),
                new MessageBarButtonInfo('Switch', () => OverviewContext.ChangeResolution(cellRow.Properties.RequestResolution[0])),
            ];
            addNotification(notification);
            return view;
        }

        // store action to be used in 'undo' feature
        if (!isUndo && OverviewContext.Settings.EnableGridUndo)
            SaveActions(timeline.resolution as any, WorkType.Allocation, idx, [{ Cell: cell, ColumnIndex: columnIndex, Value: cell.Value }]);

        if (uiSettings.settings?.restrictOverAllocating) {
            const lastValue = cellRow.Cells[idx].Value;
            // freeCap doesn't exist on category resources, thus infinity capacity
            const freeCap = cellRow.Cells[idx + 1]?.Value ?? Infinity;
            const maxValue = lastValue + freeCap;
            roundAllocation(newValue, maxValue, (roundedNew, roundedMax) => {
                if (roundedNew === roundedMax) {
                    newValue = maxValue;
                }
            });
            if (isOverAllocated(newValue, maxValue, lastValue)) {
                error(language.CapacityViews.OverAllocation);
                Undo();
                // Skip this cell update
                return view;
            }
        }

        // get container if any
        const container =
            group.Properties.ContainerId !== guid.empty ? (view.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow) : null;

        // save cell
        ProjectCapacityService.UpdateCellDomValue(view, flattenedGroups, container, group, row, cellRow, WorkType.Allocation, columnIndex, idx, newValue, true);
        const dto = ProjectCapacityService.BuildSaveCellDto(
            timeline.resolution as any,
            WorkType.Allocation,
            cell,
            cellRow,
            group.Name,
            ignoreContainerName ? null : container?.Name,
        );
        ProjectCapacityService.SaveCell(dto, cell.Id, error, true);
        // ctx.Set({GridHasChanges: true});
        setUIContext({ gridHasChanged: true });
        return view;
    };

    const OnMultipleCellsUpdate = (
        view: TopRow,
        resolution: TimelineResolution,
        workType: WorkType,
        idx: number,
        cells: Array<CellUpdateDto>,
        isUndo?: boolean,
        ignoreContainerName?: boolean,
    ): TopRow => {
        // store actions to be used in 'undo' feature
        if (!isUndo && OverviewContext.Settings.EnableGridUndo)
            SaveActions(
                resolution,
                workType,
                idx,
                cells.map(_ => {
                    return { Cell: _.Cell, ColumnIndex: _.ColumnIndex, Value: _.Cell.Value };
                }),
            );

        const flattenedGroups = ProjectCapacityService.GroupsFlattened(view);

        const dtos = cells.reduce((acc, item) => {
            const group = flattenedGroups.find(_ => _.Id === item.Cell.Properties.ContractId);
            const resource = group.Rows.find(_ => _.Id === item.Cell.Properties.ResourceId && _.Properties.IsRequested === item.Cell.Properties.IsRequested);
            const cellRow = resource.CellRows[item.ColumnIndex];
            // get container if any
            const container =
                group.Properties.ContainerId !== guid.empty ? (view.Groups.find(_ => _.Id === group.Properties.ContainerId) as GroupContainerRow) : null;

            // reset align status
            if (shouldResetAlignStatus(workType)) {
                group.Properties.AlignStatus = resetAlignStatus(workType);
            }
            if (uiSettings.settings?.restrictOverAllocating) {
                const lastValue = cellRow.Cells[idx].Value;
                // freeCap doesn't exist on category resources, thus infinity capacity
                const freeCap = cellRow.Cells[idx + 1]?.Value ?? Infinity;
                const maxValue = lastValue + freeCap;
                roundAllocation(item.Value, maxValue, (roundedNew: number, roundedMax: number) => {
                    if (roundedNew === roundedMax) {
                        item.Value = maxValue;
                    }
                });
                if (workType === WorkType.Allocation && isOverAllocated(item.Value, maxValue, lastValue)) {
                    error(language.CapacityViews.OverAllocation);
                    Undo();
                    // Skip this cell update
                    return acc;
                }
            }

            ProjectCapacityService.UpdateCellDomValue(
                view,
                flattenedGroups,
                container,
                group,
                resource,
                cellRow,
                workType,
                item.ColumnIndex,
                idx,
                item.Value,
                true,
            );

            const dto = ProjectCapacityService.BuildSaveCellDto(
                resolution,
                workType,
                item.Cell,
                cellRow,
                group.Name,
                ignoreContainerName ? null : container?.Name,
            );
            acc.push(dto);
            return acc;
        }, [] as AddActivityDto[]);

        ProjectCapacityService.SaveCells(
            dtos,
            cells.map(_ => _.Cell.Id),
            error,
            true,
        );
        setUIContext({ gridHasChanged: true });
        return view;
    };

    const savedActions = useRef<Array<UndoItem>>([]);

    const SaveActions = (resolution: TimelineResolution, workType: WorkType, idx: number, cells: Array<CellUpdateDto>) => {
        const item = new UndoItem(resolution, workType, idx, cells);
        savedActions.current.push(item);
    };

    const Undo = () => {
        if (!OverviewContext.Settings.EnableGridUndo) return;
        const item = savedActions.current.pop();
        if (item == null) {
            info(language.CapacityViews.Undo.NothingToUndo, 2000);
            return;
        }
        const updatedModel: TopRow = OnMultipleCellsUpdate(view, item.resolution, item.workType, item.idx, item.cells, true);
        // highlight Undone items
        ProjectCapacityService.HighlightCell(
            item.cells.map(_ => _.Cell.Id),
            true,
        );
        // update view
        setView({ ...updatedModel });
        // notify
        info(language.CapacityViews.Undo.UndidPreviousCellEdit, 2000);
    };

    const DragUpdates = () => {
        const updates = [
            (cells: Array<CellUpdateDto>) => {},
            (cells: Array<CellUpdateDto>) => {
                const updatedModel = OnMultipleCellsUpdate(view, timeline.resolution as any, WorkType.Allocation, 1, cells);
                setView({ ...updatedModel });
            },
        ];
        return updates;
    };

    const ExcelCopyUpdate = (cells: Array<CellUpdateDto>, excessiveCells: Array<CellUpdateDto>) => {
        const updatedModel = OnMultipleCellsUpdate(view, timeline.resolution as any, WorkType.Allocation, cells[0].CellIndex, cells);
        setView({ ...updatedModel });
        // highlight copied items
        ProjectCapacityService.HighlightCell(
            cells.map(_ => _.Cell.Id),
            true,
        );
        if (excessiveCells.length) {
            warning(language.CapacityViews.ExcelCopyUpdate);
        }
    };

    const GetPropertyValue = (item: ResourcePanelItem): string => {
        const field = OverviewContext.Settings.EnableDynamicContractNames
            ? language.CapacityViews.NameLabel
            : capitalize(OverviewContext.Settings.ContractDefaultName);
        return stringVariants.getAsStringValue(item, field);
    };

    const OnResourceAdd = async (event: any, row?: GroupRow) => {
        const resource = JsonEx.parse(event.dataTransfer.getData('resourcelistitem')) as ResourcePanelItem;
        await AddResource(resource, row);
    };

    const OnResourceAddToContainer = async (event: any, container: GroupContainerRow) => {
        const resource = JsonEx.parse(event.dataTransfer.getData('resourcelistitem')) as ResourcePanelItem;
        const groupName = (await GetPropertyValue(resource)) || resource.Name;
        const updatedModel = await ProjectCapacityService.AddResourceToContainer(
            view,
            container.Id,
            container.Id,
            resource.id,
            groupName,
            timeline.start,
            timeline.end,
            timeline.resolution,
            GridType.LmCapacityProjects,
        );
        if (updatedModel) {
            ProjectCapacityService.ForceRefreshCells(updatedModel);
            setView({ ...updatedModel });
            // add resource to selected in context
            const clone = [...selections.ResourceIds];
            clone.push(resource.id);
        }
    };

    const AddResource = async (resource: ResourcePanelItem, row: GroupRow) => {
        setUIContext({ scrollToAddedResource: resource.id });
        const updatedModel = await ProjectCapacityService.AddResource(
            view,
            row.Properties.ProjectId,
            resource.id,
            'NOTUSEDHERE',
            row.Id,
            timeline.start,
            timeline.end,
            timeline.resolution,
            GridType.LmCapacityProjects,
            warning,
            resource.RelatedGenericResourceId,
        );
        if (updatedModel) {
            ProjectCapacityService.ForceRefreshCells(updatedModel);
            setView({ ...updatedModel });
            // add resource to selected in context
            // prevent refresh or else newly added resource will dissapear
            // preventRefresh.current = true;
            const clone = [...selections.ResourceIds];
            clone.push(resource.id);
            // UpdateSelection(null, clone);
        }
        [].forEach.call(document.querySelectorAll('.tp-list-capacity-dropzone-overlay'), _ => (_.style.display = 'none'));
    };

    const RemoveResource = async (resource: Row, group: GroupRow): Promise<void> => {
        document.getElementById('tp-list-resources-dropzone-overlay').style.display = 'none';

        // remove resource
        const updatedModel = await ProjectCapacityService.OnResourceRemove(
            view,
            GridType.Allocation,
            resource,
            group,
            timeline.resolution as any,
            ProjectCapacityService.RemoveActivities,
        );
        if (updatedModel) {
            setView({ ...updatedModel });
            // NotificationService.Instance.Success(`${language.ProjectCapacity.RemoveResource} ${resource.Name}`);
            success(`${language.ProjectCapacity.RemoveResource} ${resource.Name}`);
        }
    };

    // TODO: make stable ref
    let _timer: Timeout;
    const OnProjectHover = (projectId: string): void => {
        const timer = EventEx.delay(
            _timer,
            () => {
                // ctx.Set({ EntityDetails: new EntityInformationCalloutDto(projectId, EntityType.Project, `#lm-capacity-group-hover-${projectId}`)});
                setEntityCalloutInfo({
                    Id: projectId,
                    EntityType: EntityType.Project,
                    Target: `#lm-capacity-group-hover-${projectId}`,
                });
            },
            800,
        );
        _timer = timer;
    };

    const OnProjectHoverLeave = (): void => {
        clearTimeout(_timer);
        _timer = null;
        // ctx.Set({ EntityDetails: null});
        setEntityCalloutInfo(null);
    };

    const RowSort = (group: GroupRow): Array<Row> => {
        group.Rows.sort((a, b) => {
            // if resourcetype is the same, sort by name
            if (a.Properties.ResourceType === b.Properties.ResourceType && !a.Properties.IsRequested === !b.Properties.IsRequested)
                return a.Name.localeCompare(b.Name);
            // generics is first
            if (a.Properties.IsRequested) return -1;
            // then requested that is also allocated
            if (a.Id === group.Properties.RequestedResourceId && !b.Properties.IsRequested) return -1;
            // then named
            if (a.Properties.ResourceType === ResourceType.Named && !b.Properties.IsRequested && b.Id !== group.Properties.RequestedResourceId) return -1;
            // last category and teams
            else return 1;
        });
        return group.Rows;
    };

    const GroupCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 0, colIdx, CellType.Group)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 1, colIdx, CellType.Group)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' } };
            },
        ];
        return styles;
    };

    const GroupContainerCellStyles = (): Array<any> => {
        const styles = [
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 0, colIdx, CellType.Container)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return { field: { backgroundColor: `${GetActivictyColor(cell, 1, colIdx, CellType.Container)} !important` } };
            },
            (cell: Cell, colIdx: number) => {
                return { root: { paddingRight: '10px' } };
            },
        ];
        return styles;
    };

    const GetActivictyColor = (cell: Cell, index: number, columnIndex: number, cellType: CellType): string => {
        return ProjectCapacityService.GetActivityColor(view, cell, index, columnIndex, cellType, theme);
    };

    const GetExtraMenuItems = (): Array<IContextualMenuItem> => {
        const menuItems: Array<IContextualMenuItem> = [];

        if (uiSettings.settings.enableToggleHideGenericResource) {
            menuItems.push({
                key: 'hideGenericResources',
                onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => (
                    <RenderHideGenericResourceToggle item={item} dismissMenu={dismissMenu} />
                ),
            });
        }
        return menuItems;
    };

    const RenderGrid = (): JSX.Element => {
        return loading ? (
            <Stack styles={{ root: { paddingTop: 80 } }}>
                <GridSkeleton />
            </Stack>
        ) : !view ? null : (
            <div
                onKeyDown={e => {
                    if (e.keyCode === 90 && e.ctrlKey) Undo();
                }}
                tabIndex={-1}
                style={{ outline: 'unset' }}
                className={AnimationClassNames.fadeIn200}
            >
                <Table
                    Name={language.Common.PlannerView}
                    Model={view}
                    AllCollapsed={true}
                    ContainerClassName="lm-capacity-table"
                    CellUpdates={[
                        (cell: Cell, columnIndex: number, value: number, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {}, // updates of requests are not possible
                        (cell: Cell, columnIndex: number, value: number, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            const updatedModel = OnCellUpdate(columnIndex, 1, cell, value);
                            viewRef.current = { ...updatedModel };
                        },
                    ]}
                    GroupCellStyles={GroupCellStyles()}
                    GroupContainerCellStyles={GroupContainerCellStyles()}
                    localStorageKey={'PlannerViewHideGenericResources'}
                    RowCellStyles={[
                        (cell: Cell) => {
                            return { root: { paddingLeft: '30px !important', paddingRight: '10px', cursor: 'unset' } } as IStyleFunctionOrObject<
                                ITextFieldStyleProps,
                                ITextFieldStyles
                            >;
                        },
                        (cell: Cell) => {
                            return { root: { paddingLeft: '30px !important', paddingRight: '10px', cursor: 'unset' } } as IStyleFunctionOrObject<
                                ITextFieldStyleProps,
                                ITextFieldStyles
                            >;
                        },
                        (cell: Cell, colIdx: number) => {
                            return {
                                root: { paddingRight: '10px' },
                                field: { backgroundColor: `${CapacityUtil.GetFreeCapacityColor(theme, cell.Value)} !important` },
                            };
                        },
                    ]}
                    GroupContainerExtraContent={(name: string, container: GroupContainerRow) => (
                        <>
                            <div className="tp-lmcapacity-gotoproject">
                                <IconButton
                                    iconProps={{ iconName: 'ViewOriginal' }}
                                    title={'Go to project'}
                                    onClick={() => (container.Properties.CanGoToProject ? goTo(buildRoute('allocation', container.Id)) : null)}
                                />
                            </div>
                            <div
                                className="tp-list-capacity-dropzone-overlay tp-grid-top"
                                onDrop={e => OnResourceAddToContainer(e, container)}
                                onDragEnter={e => e.preventDefault()}
                                onDragOver={e => e.preventDefault()}
                            >
                                <div className="tp-list-capacity-dropzone-text">New contract</div>
                            </div>
                            <div
                                id={`lm-capacity-group-hover-${container.Id}`}
                                className="lm-capacity-group-hover"
                                onMouseEnter={e => OnProjectHover(container.Id)}
                                onMouseLeave={OnProjectHoverLeave}
                            ></div>
                        </>
                    )}
                    GroupExtraContent={(group: GroupRow) => (
                        <>
                            <div
                                className="tp-list-capacity-dropzone-overlay tp-grid-group"
                                onDrop={e => OnResourceAdd(e, group)}
                                onDragEnter={e => e.preventDefault()}
                                onDragOver={e => e.preventDefault()}
                            >
                                <div className="tp-list-capacity-dropzone-text">Add to contract</div>
                            </div>
                        </>
                    )}
                    RowClick={(row: Row) => (row.Properties.ResourceType === ResourceType.Named ? goTo(buildRoute('resourcecapacity', row.Id)) : null)}
                    RowDraggable={(row: Row) => true}
                    RowDragStart={(row, group, event) => (document.getElementById('tp-list-resources-dropzone-overlay').style.display = 'block')}
                    RowDragEnd={async (row, group, event) => await RemoveResource(row, group)}
                    AllowContainerCollapse
                    AllowGroupCollapse
                    HideTopRow
                    DragHighlighterUpdate={DragUpdates()}
                    PasteFromExcel={OverviewContext.Settings.EnableCopyFromExcel && ExcelCopyUpdate}
                    GroupSort={(groups: Array<GroupRow>) => groups.sort((a, b) => a.Name.localeCompare(b.Name))}
                    RowSort={RowSort}
                    GridCellDecimals={ProjectCapacityService.GetGridDecimals()}
                    GridSettingsSpacingAmount={OverviewContext.Settings.GridSpacing}
                    GridSettingsHidePersonaIcons={OverviewContext.Settings.HidePersonaIconsInGrid}
                    SetGridSettingsHidePersonaIconsCallback={value =>
                        UserSettingsService.UpdateUserSettingsPartial({ HidePersonaIconsInGrid: value }, null, true)
                    }
                    SetGridSettingsSpacingAmountCallback={value => UserSettingsService.UpdateUserSettingsPartial({ GridSpacing: value }, null, true)}
                    EnableGridSettings={OverviewContext.Settings.EnableGridSettings}
                    GridSettingsExtraMenuItems={GetExtraMenuItems()}
                    TitleContentOverride={title => (
                        <Text className="tp-capacity-project-title">
                            <div className="truncate-text">{title}</div>
                        </Text>
                    )} // temp hack (ewi)
                />
            </div>
        );
    };

    return (
        <div className="lm-capacity">
            <PlannerViewSettingsHeader
                DisableInputs={loading}
                Timeline={timeline as any}
                ChangesCallback={async (projectIds, resourceIds) => setSelections({ ProjectIds: projectIds, ResourceIds: resourceIds })}
            />
            {RenderGrid()}
            <LMCapacityCoachmarks />
        </div>
    );
};

export default PlannerView;
