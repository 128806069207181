import { useCallback, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ApiCalls } from '../../../api/api';
import { ActiveFilter, EntityType, QueryGroup, Resource } from '../../../api/generated/data-contracts';
import { RESOURCE_OVERVIEW } from '../../../context/network/http/QueryProvider/queryKeys';
import { useStore } from '../../../context/store';
import { language } from '../../../Services/LocalizationService';
import { OverviewCreator } from '../../../_components/OverviewCreator/OverviewCreator';
import { IMoveResourceActivitiesInfo, ResourceOverviewExtra } from './ResourceOverviewExtra';
import { ResourceForm } from './components/ResourceForm/ResourceForm';
import { ResourceBulkEditor } from './components/ResourceBulkEditor/ResourceBulkEditor';
import { useResourceOverviewMenuItems } from './hooks/useResourceOverviewMenuItems';
import { getNestedEntityProperty, sortOverview } from '../_components/helpers/sortHelper';
import { useUISettings } from '../../../context/network/http/QueryProvider/queries/UISettings';
import { useTPPermissions } from '../../../hooks/usePermissions';
import { ViewFilterPanel } from '../../capacityViews/resourceplanner/components/ViewFilterPanel/ViewFilterPanel';
import { generateResourceOverviewColumns } from './resourceOverviewColumns';
import { resourceSchema } from '../../../forms/resource/resourceSchema';

export const ResourceOverview = () => {
    const queryClient = useQueryClient();
    const [showBulkEdit, setShowBulkEdit] = useState(false);
    const setBlockDialog = useStore(store => store.setBlockDialog);
    const [moveActivitiesInfo, setMoveActivitiesInfo] = useState<IMoveResourceActivitiesInfo>();
	const [activeFilter, setActiveFilter] = useState<ActiveFilter>(ActiveFilter.Active);

	const { data: uiSettings } = useUISettings();
	const isAllowedToEditResources = useTPPermissions({ some: true, userTypes: uiSettings.settings.allowedToEditResources });
	
	const [openPanel, setOpenFilterPanel] = useState(false);
	const [filter, setFilter] = useState<QueryGroup>();
	
    const queryKey = useMemo(() => [RESOURCE_OVERVIEW, activeFilter, filter] as const, [activeFilter, filter]);
	
    const { data, isFetching, refetch } = useQuery(
        [RESOURCE_OVERVIEW, activeFilter, filter],
        async () => {
            try {
                const response = await ApiCalls.getResourcesForOverview2({activeFilter, filter });
                return response.data as Resource[];
            } catch (err) {
                setBlockDialog(null, {
                    title: language.ResourceOverview.FailedGettingResources,
                    subText: typeof err === 'string' ? err : err.message,
                });
                return [];
            }
        },
        { placeholderData: useRef([]).current as Resource[] },
    );

    const setResources = useCallback(
        (updater: (items: Resource[]) => Resource[]) => {
            queryClient.setQueryData(queryKey, updater);
        },
        [queryKey, queryClient],
    );

    const [isNewlyCreated, setIsNewlyCreated] = useState(false);

    const menuItems = useResourceOverviewMenuItems({
        setResources,
        setIsNewlyCreated,
        setMoveActivitiesInfo,
		activeFilter: activeFilter,
		setActiveFilter: setActiveFilter,
        setShowBulkEdit,
        refetch,
		setOpenFilterPanel
    });

    const columns = useMemo(() => generateResourceOverviewColumns(), []);

    const onClickSave = useCallback(
        async (item: Resource) => {
            const [newResource]: Resource[] = await ApiCalls.upsertEntityReturnExpanded({ entity: EntityType.Resource, items: [item] }).then(res => res.data);
            if (isNewlyCreated) {
                setResources(resources => [newResource, ...resources]);
				setIsNewlyCreated(false);
            } else {
                setResources(resources => resources.map(resource => (resource.id === newResource.id ? newResource : resource)));
                refetch();
            }
        },
        [isNewlyCreated, refetch, setResources],
    );

    const onClosePanel = useCallback(() => {
        setIsNewlyCreated(false);
    }, []);

    const onGetPanelHeaderText = useCallback(
        (item: Resource) => {
            if (isNewlyCreated) {
                return `Creating New Resource${item?.name ? `: ${item?.name}` : ''}`;
            }
            return `Edit: ${item?.name}`;
        },
        [isNewlyCreated],
    );

    const onSubmitBulkEditor = useCallback(
        async (items: Resource[]) => {
            const updatedResources: Resource[] = await ApiCalls.upsertEntityReturnExpanded({ entity: EntityType.Resource, items }).then(res => res.data);
            setResources(resources => resources.map(resource => updatedResources.find(updatedResource => updatedResource.id === resource.id) || resource));
            refetch();
        },
        [refetch, setResources],
    );

    const onSearchResources = useCallback(
        (resources: Resource[], searchInput: string) => {
            const columnNames = columns.map(col => col.fieldName);
			// always enable search by id
			columnNames.push('id');
            const text = searchInput.toLowerCase();
            return resources.filter(resource => {
				for (const key of columnNames) {
                    const value = getNestedEntityProperty(resource, key);
                    if (typeof value === 'string' && value.toLowerCase().includes(text)) {
                        return true;
                    }
                }
                return false;
            });
        },
        [columns],
    );

    return (
        <OverviewCreator
            items={data}
            columns={columns}
            onGetSearchPlaceholderText={onGetSearchPlaceholderText}
            onGetPanelHeaderText={onGetPanelHeaderText}
            loading={isFetching}
            onClickSave={onClickSave}
            onClosePanel={onClosePanel}
            menuItems={menuItems}
            searchFilter={onSearchResources}
			searchDebounceTimout={300}
            panelContent={<ResourceForm />}
            onColumnSort={sortOverview}
			onShouldCancelItemInvocation={() => !isAllowedToEditResources}
        >
            <ResourceBulkEditor show={showBulkEdit} setShow={setShowBulkEdit} onSubmit={onSubmitBulkEditor} />
            <ResourceOverviewExtra moveActivitiesInfo={moveActivitiesInfo} />
			<ViewFilterPanel
                schema={resourceSchema}
                entityType={'resource'}
                customProperties={uiSettings.settings.resourceCustomProperties}
                initialFilterValue={filter}
                headerText={'Filter editor'}
                onClickSave={filter => {
					setFilter(filter);
					setOpenFilterPanel(false);
				}}
                openPanel={openPanel}
                setOpenPanel={setOpenFilterPanel}
                onDismissPanel={() => {
                    setOpenFilterPanel(false);
                }}
            />
        </OverviewCreator>
    );
};

const onGetSearchPlaceholderText = (items: Resource[]) => `Search in ${items.length} resources`;
