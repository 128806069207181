import { useMemo } from 'react';
import { ApiCalls } from '../../../../api/api';
import { ActiveFilter, DeleteDto, EntityType, Resource, ResourceType } from '../../../../api/generated/data-contracts';
import { useUISettings } from '../../../../context/network/http/QueryProvider/queries/UISettings';
import { useStore } from '../../../../context/store';
import { tpEntityAddDefaults } from '../../../../forms/common/tpEntityFactory';
import { language } from '../../../../Services/LocalizationService';
import { deepCopy } from '../../../../Utilities/ObjectEx';
import { showInactivateConfirm } from '../helpers/resourceActivation';
import { IMoveResourceActivitiesInfo } from '../ResourceOverviewExtra';
import { useConfirmDeleteOverviewDialog } from '../../_hooks/useConfirmDeleteOverviewDialog';
import { MenuItem } from '../../../../_components/OverviewCreator/components/OverviewCommandBar/OverviewCommandBar';
import { useCheckTPPermissions } from '../../../../hooks/usePermissions';
import { queryGroupHelper } from '../../../../Utilities/queryGroupHelper';
import { exportOverviewToExcel } from '../../../../Utilities/ExcelUtil';
// import * as ExcelJS from 'exceljs'

export const useResourceOverviewMenuItems = ({
    setIsNewlyCreated,
    setShowBulkEdit,
    setResources,
    setMoveActivitiesInfo,
	setActiveFilter,
	activeFilter,
    setOpenFilterPanel,
    refetch,
}: {
    setIsNewlyCreated: (isNew: boolean) => void;
    setShowBulkEdit: (show: boolean) => void;
    setResources: (updater: (items: Resource[]) => Resource[]) => void;
    setMoveActivitiesInfo: (value: React.SetStateAction<IMoveResourceActivitiesInfo>) => void;
	setActiveFilter: React.Dispatch<React.SetStateAction<ActiveFilter>>;
	activeFilter: ActiveFilter;
    setOpenFilterPanel: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}) => {
    const confirmDelete = useConfirmDeleteOverviewDialog();
    const { data: uiSetting } = useUISettings();
    const addErrorNotification = useStore(store => store.addErrorNotification);
    const checkPermissions = useCheckTPPermissions({ some: true });

    return useMemo((): MenuItem<Resource>[] => {
        const isAllowedToCreateResources = checkPermissions({ userTypes: uiSetting.settings.allowedToCreateResources });
        const isAllowedToDeleteResources = checkPermissions({ userTypes: uiSetting.settings.allowedToDeleteResources });
        const isAllowedToInactivateResources = checkPermissions({ userTypes: uiSetting.settings.allowedToInactivateResources });
        const isAllowedToEditResources = checkPermissions({ userTypes: uiSetting.settings.allowedToEditResources });
        const items: MenuItem<Resource>[] = [
            {
                key: 'new',
                text: 'New', //TODO LOCALIZATION
                iconProps: { iconName: 'Add' },
                onClick: (selectedItems, setPanelItem) => {
                    const emptyResource = {} as Resource;
                    // This is a weird and uncomfortable api (scary mutations 😱)
                    tpEntityAddDefaults.resource(emptyResource);
                    setPanelItem(emptyResource);
                    setIsNewlyCreated(true);
                },
                shouldDisable: () => !isAllowedToCreateResources,
            },
            {
                key: 'edit',
                text: 'Edit', //TODO LOCALIZATION
                iconProps: { iconName: 'Edit' },
                onClick: (selectedItems, setPanelItem) => {
                    setPanelItem(selectedItems[0]);
                },
                shouldDisable: selectedItems => {
                    return !isAllowedToEditResources || selectedItems.length > 1 || selectedItems.length === 0;
                },
            },
            {
                key: 'delete',
                text: 'Delete', //TODO LOCALIZATION
                iconProps: { iconName: 'Delete' },
                onClick: selectedResources => {
                    confirmDelete(
                        async relatedData => {
                            const dto = { entity: EntityType.Resource, items: selectedResources } as DeleteDto;
                            try {
                                if (relatedData) {
                                    await ApiCalls.cascadeDeleteEntity(dto);
                                } else {
                                    await ApiCalls.deleteEntity(dto);
                                }
                                setResources(resources =>
                                    resources.filter(resource => !selectedResources.some(selectedResource => selectedResource.id === resource.id)),
                                );
                                refetch();
                            } catch (error: any) {
                                console.error(error);
                            }
                        },
                        () => {},
                        selectedResources.length > 1,
                        language.ResourceOverview,
                    );
                },
                shouldDisable: selectedItems => {
                    if (selectedItems.length === 0) {
                        return true;
                    }
                    // if (uiSetting.settings.allowedToDeleteResources.length) {
                    //     return !isAllowedToDeleteResources;
                    // }
                    return !isAllowedToDeleteResources;
                },
            },
            {
                key: 'copy',
                text: 'Copy', //TODO LOCALIZATION
                iconProps: { iconName: 'Copy' },
                onClick: (selectedItems, setPanelItem) => {
                    const resource = deepCopy(selectedItems[0]);
                    resource.name = `Copy_${resource.name}`;

                    // This is a weird and uncomfortable api (scary mutations 😱)
                    setPanelItem(Object.assign(resource, tpEntityAddDefaults.resource(resource), { active: true, userPrincipalName: undefined, created: undefined, createdBy: undefined }));
                },
                shouldDisable: selectedResources => {
                    return selectedResources.length !== 1 || !isAllowedToCreateResources;
                },
            },
            {
                key: 'bulk edit',
                text: 'Bulk edit', //TODO LOCALIZATION
                iconProps: { iconName: 'TripleColumnEdit' },

                onClick: selectedResources => {
                    const resourceType = selectedResources[0].resourceType;
                    if (selectedResources.some(_ => _.resourceType !== resourceType)) {
                        addErrorNotification('All selected resources must have same resource type');
                        return;
                    }
                    setShowBulkEdit(true);
                },
                shouldDisable: selectedResources => {
                    const rootType = selectedResources[0]?.resourceType;
                    return (
                        !isAllowedToEditResources ||
                        !selectedResources ||
                        selectedResources.length < 2 ||
                        selectedResources.some(selectedResource => selectedResource?.resourceType !== rootType)
                    );
                },
            },
            {
                key: 'addition actions',
                text: 'Additional actions', //TODO LOCALIZATION
                iconProps: { iconName: 'SetAction' },
                subMenuProps: {
                    items: [
                        {
                            key: 'Set active',
                            text: 'Set active', //TODO LOCALIZATION
                            subMenuProps: {
                                items: [
                                    {
                                        key: 'Activate',
                                        text: 'Activate', //TODO LOCALIZATION
                                        onClick: selectedResources => {
                                            showInactivateConfirm(
                                                selectedResources,
                                                true,
                                                () => {
                                                    const updatedResources = selectedResources.map(res => ({ ...res, active: true }));
                                                    setResources(resources => {
                                                        return resources.map(
                                                            resource =>
                                                                updatedResources.find(updatedResource => updatedResource.id === resource.id) || resource,
                                                        );
                                                    });
                                                    refetch();
                                                },
                                                loading => {},
                                            );
                                        },
                                    },
                                    {
                                        key: 'deactivate',
                                        text: 'Inactivate', //TODO LOCALIZATION
                                        onClick: selectedResources => {
                                            showInactivateConfirm(
                                                selectedResources,
                                                false,
                                                () => {
                                                    const updatedResources = selectedResources.map(res => ({ ...res, active: false }));
                                                    setResources(resources => {
                                                        return resources.map(
                                                            resource =>
                                                                updatedResources.find(updatedResource => updatedResource.id === resource.id) || resource,
                                                        );
                                                    });
                                                    refetch();
                                                },
                                                loading => {},
                                            );
                                        },
                                    },
                                ],
                            },
                            shouldDisable: selectedResources => {
                                return (
                                    !selectedResources.length ||
                                    !isAllowedToInactivateResources ||
                                    !queryGroupHelper.isNullOrEmpty(uiSetting.settings.autoInactivateResources)
                                );
                            },
                        },
                        uiSetting?.settings?.enableMoveAllAllocations && {
                            key: 'move all allocations',
                            text: 'Move all allocations', //TODO LOCALIZATION
                            onClick: selectedItems => {
                                setMoveActivitiesInfo({ resourceId: selectedItems[0].id, type: 'allocation' });
                            },
                            shouldDisable: (selectedResources: Resource[]) => {
                                return (
                                    selectedResources.length !== 1 ||
                                    !(
                                        selectedResources[0].resourceType === ResourceType.Named ||
                                        // || selectedResources[0].resourceType === ResourceType.Team
                                        selectedResources[0].resourceType === ResourceType.Category
                                    )
                                );
                            },
                        },
                    ].filter(Boolean),
                },
            },
			{
				key: 'show active',
				text: getActiveFilterText(activeFilter),
				iconProps: { iconName:  getActiveFilterIcon(activeFilter)},
				subMenuProps: {
					items: [
						{
							key: 'ShowOnlyActive',
							text: language.OverviewActiveToggler.ShowOnlyActive,
							iconProps: { iconName: 'ReceiptCheck' },
							onClick: () => {
								setActiveFilter(ActiveFilter.Active);
							},
						},
						{
							key: 'ShowActiveAndInactive',
							text: language.OverviewActiveToggler.ShowActiveAndInactive,
							iconProps: { iconName: 'ReceiptTentative' },
							onClick: () => {
								setActiveFilter(ActiveFilter.Both);							
},
						},
						{
							key: 'ShowOnlyInactive',
							text: language.OverviewActiveToggler.ShowOnlyInactive,
							iconProps: { iconName: 'ReceiptUndelivered' },
							onClick: () => {
								setActiveFilter(ActiveFilter.Inactive);							
},
						},
					],
				},
			},
        ];

        if (uiSetting.settings.enableResourceOverviewsFilter) {
            items.push({
                key: 'Filter',
                text: 'Filter', //TODO LOCALIZATION
                iconProps: { iconName: 'Filter' },
                onClick: () => {
                    setOpenFilterPanel(true);
                },
            });
        }

		if (uiSetting.settings.overviewExportToExcelEnabled) {
			items.push({
				key: 'exportxlsx',
				text: 'Export to Excel',
				iconProps: { iconName: 'ExcelLogo16' },
				onClick: (selectedItems, spc, filteredItems, columns) => {
					exportOverviewToExcel('Resource', filteredItems, columns);
				},
			});
		}

        return items;
    }, [
        addErrorNotification,
        checkPermissions,
        confirmDelete,
        refetch,
        setIsNewlyCreated,
        setMoveActivitiesInfo,
		setActiveFilter,
		activeFilter,
        setOpenFilterPanel,
        setResources,
        setShowBulkEdit,
        uiSetting.settings.allowedToCreateResources,
        uiSetting.settings.allowedToDeleteResources,
        uiSetting.settings.allowedToEditResources,
        uiSetting.settings.allowedToInactivateResources,
        uiSetting.settings.autoInactivateResources,
        uiSetting.settings?.enableMoveAllAllocations,
        uiSetting.settings.enableResourceOverviewsFilter,
		uiSetting.settings.overviewExportToExcelEnabled
    ]);
};

const getActiveFilterText = (activeFilter: ActiveFilter) => {
	switch (activeFilter) {
		case ActiveFilter.Active:
			return language.OverviewActiveToggler.ShowingActive;
		case ActiveFilter.Inactive:
			return language.OverviewActiveToggler.ShowingInactive;
		case ActiveFilter.Both:
			return language.OverviewActiveToggler.ShowingActiveAndInactive;
	}
}

const getActiveFilterIcon = (activeFilter: ActiveFilter) => {
	switch (activeFilter) {
		case ActiveFilter.Active:
			return 'ReceiptCheck';
		case ActiveFilter.Inactive:
			return 'ReceiptUndelivered';
		case ActiveFilter.Both:
			return 'ReceiptTentative';
	}
}