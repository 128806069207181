import { Virtualizer } from '@tanstack/react-virtual';
import { Handler, Vector2, useDrag } from '@use-gesture/react';
import Big from 'big.js';
import { useCallback, useMemo, useRef } from 'react';
import { atom, useRecoilCallback, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { GridCell } from '../../../../../../api/generated/data-contracts';
import { useEvent } from '../../../../../../hooks/useEvent';
import { UpdateCellInfo } from '../../../../_grid/contexts/gridContext';
import { VisibleRow } from '../../../../_grid/useGrid';
import { useCheckOverAllocationProjectPlanner } from '../components/Cell/hooks/useCheckOverAllocationProjectPlanner';
import { useSelectedCustomColumnsCount } from '../context/customColumnContext';
import { DragContext, renderedCellsAtom } from '../context/dragContext';
import { getRowType } from '../helpers/getRowType';

// type BoundaryX = 'left' | 'right' | null;
// type BoundaryY = 'top' | 'bottom' | null;
// type ScrollAction = [BoundaryX, BoundaryY] | null;
type DragMemo = {
    initialVector: Vector2;
};

// const scrollAmount = 5;

export const useGridDragHandler = ({
    columnVirtualizer,
    rowVirtualizer,
    renderableRows,
    updateCellValue,
    scrollRef,
}: {
    rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
    columnVirtualizer: Virtualizer<HTMLDivElement, Element>;
    renderableRows: VisibleRow[];
    updateCellValue: (updatesOrUpdate: UpdateCellInfo | UpdateCellInfo[], shouldCallHandler?: unknown) => Promise<void>;
    scrollRef: React.MutableRefObject<HTMLDivElement>;
}) => {
    const setDragBoxState = useSetRecoilState(dragStateAtom);
    const resetDragBoxState = useResetRecoilState(dragStateAtom);

    const virtualMovement = useRef<Vector2>([0, 0]);



    // const autoScroll = useRecoilCallback(
    //     ({ set, snapshot }) =>
    //         async (scrollAction: ScrollAction) => {
    //             if (scrollAction) {
    //                 // autoScrolling.current = true;
    //                 const [outOfBoundsX, outOfBoundsY] = scrollAction;

    //                 const { endBottom, endLeft, endRight, endTop } = getScrollTargetState(rowVirtualizer.options.getScrollElement());
    //                 let [vmx, vmy] = virtualMovement.current;
    //                 const boxState = await snapshot.getPromise(dragStateAtom);

    //                 let dx: number | null = null;
    //                 let dy: number | null = null;
    //                 const { height, width, left, top } = boxState;
    //                 let newHeight = height;
    //                 let newWidth = width;
    //                 let newLeft = left;
    //                 let newTop = top;
    //                 // const [lastX, lastY] = getGridVector(lastXy.current);
    //                 // const [lastX, lastY] = lastXy.current;
    //                 if (outOfBoundsX === 'left' && !endLeft) {
    //                     dx = -scrollAmount;
    //                     newWidth = width - dx;
    //                     columnVirtualizer.scrollBy(-scrollAmount);
    //                 }
    //                 if (outOfBoundsX === 'right' && !endRight) {
    //                     dx = scrollAmount;
    //                     newLeft = left - dx;
    //                     newWidth = width + dx;
    //                     // newWidth = lastX - newLeft;
    //                     columnVirtualizer.scrollBy(scrollAmount);
    //                 }
    //                 if (outOfBoundsY === 'top' && !endTop) {
    //                     dy = -scrollAmount;
    //                     newHeight = height - dy;
    //                     rowVirtualizer.scrollBy(-scrollAmount);
    //                 }
    //                 if (outOfBoundsY === 'bottom' && !endBottom) {
    //                     dy = scrollAmount;
    //                     newTop = top - dy;
    //                     newHeight = height + dy;
    //                     rowVirtualizer.scrollBy(scrollAmount);
    //                 }
    //                 // if (outOfBoundsX === 'left' && !endLeft) {
    //                 //     dx = -scrollAmount;
    //                 //     newWidth = width - dx;
    //                 //     columnVirtualizer.scrollBy(-scrollAmount);
    //                 // }
    //                 // if (outOfBoundsX === 'right' && !endRight) {
    //                 //     dx = scrollAmount;
    //                 //     newLeft = left - dx;
    //                 //     newWidth = width + dx;
    //                 //     columnVirtualizer.scrollBy(scrollAmount);
    //                 // }
    //                 // if (outOfBoundsY === 'top' && !endTop) {
    //                 //     dy = -scrollAmount;
    //                 //     newHeight = height - dy;
    //                 //     rowVirtualizer.scrollBy(-scrollAmount);
    //                 // }
    //                 // if (outOfBoundsY === 'bottom' && !endBottom) {
    //                 //     dy = scrollAmount;
    //                 //     newTop = top - dy;
    //                 //     newHeight = height + dy;
    //                 //     rowVirtualizer.scrollBy(scrollAmount);
    //                 // }
    //                 if (dx !== null) {
    //                     vmx = vmx + dx;
    //                 }
    //                 if (dy !== null) {
    //                     vmy = vmy + dy;
    //                 }

    //                 virtualMovement.current = [vmx, vmy];
    //                 set(dragStateAtom, {
    //                     height: newHeight,
    //                     width: newWidth,
    //                     top: newTop,
    //                     left: newLeft,
    //                     visible: true,
    //                 });
    //             }
    //         },
    //     [columnVirtualizer, rowVirtualizer],
    // );

	// useEffect(() => {
    //     // Make the element scroll as long as the user is dragging out of bounds
    //     if (outOfBounds) {
    //         const timer = setInterval(() => {
    //             autoScroll(outOfBounds);
    //             // if (!autoScrolling.current) {
    // 			// 	autoScroll(outOfBounds).then(() => {
    // 			// 		autoScrolling.current = false;
    // 			// 	});
    // 			// }
    //         }, 5);
    //         return () => {
    //             clearInterval(timer);
    //         };
    //     }
    // }, [outOfBounds, autoScroll]);

    const customColumnCount = useSelectedCustomColumnsCount();
    const cellErrorHandler = useCheckOverAllocationProjectPlanner();
    const onDragEnd = useRecoilCallback(
        ({ snapshot }) =>
            async (endVector: Vector2, initialVector: Vector2, value: Big) => {
                const cells: UpdateCellInfo[] = [];
                for (const cellUpdate of getCellUpdatesFromArea({
                    columnVirtualizer,
                    rowVirtualizer,
                    endVector,
                    initialVector,
                    renderableRows,
                    customColumnCount,
                })) {
                    const { cell, cellIndex, columnIndex, row } = cellUpdate;
                    const { errorMessage } = await cellErrorHandler({
                        cell,
                        cellIndex,
                        columnIndex,
                        newCellValue: value,
                        row: row.row,
                        rowFamilyTree: row.rowRelations,
                    });
                    if (errorMessage) {
                        const renderedCells = await snapshot.getPromise(renderedCellsAtom);
                        const cellInfo = renderedCells.get(cell.id);
                        if (cellInfo?.onError) {
                            cellInfo.onError(errorMessage);
                        }
                        continue;
                    }
                    cells.push({
                        cell,
                        cellIndex,
                        columnIndex,
                        newValue: value,
                        rowId: row.row.id,
                    });
                }
                resetDragBoxState();
                virtualMovement.current = [0, 0];
                updateCellValue(cells);
            },
        [cellErrorHandler, columnVirtualizer, customColumnCount, renderableRows, resetDragBoxState, rowVirtualizer, updateCellValue],
    );

    const transform = useCallback(
        (v: Vector2): Vector2 => {
			if (scrollRef.current) {
				const { top, left } = scrollRef.current.getBoundingClientRect()
				return subtractVectors(v, [left, top])
			}
			return v
        },
        [scrollRef],
    );

	const getGridVector = useEvent(
        (v: Vector2) => addVectors(v, [columnVirtualizer.scrollOffset, rowVirtualizer.scrollOffset]),
    );
    const dragState = useRef(false);

    const bindDragHandler = useDrag(
        useEvent<Handler<'drag', PointerEvent | MouseEvent | TouchEvent | KeyboardEvent>>(
            ({ xy, memo, down, args: [value, inputRef], delta: [dx, dy] }) => {
                if (!memo) {
                    const gridXy = getGridVector(xy);
                    virtualMovement.current = [dx, dy];
                    return {
                        initialVector: gridXy,
                    } as DragMemo;
                }
                if (!down && memo) {
                    const gridXy = getGridVector(xy);
                    dragState.current = false;
                    onDragEnd(
                        gridXy,
                        (memo as DragMemo).initialVector,
                        value,
                    );
                    return;
                }
                if (down) {
                    dragState.current = true;
                    const [lastX, lastY] = virtualMovement.current;
                    const movementX = lastX + dx;
                    const movementY = lastY + dy;
                    const {
                        top: inputTop,
                        left: inputLeft,
                        width: inputWidth,
                        height: inputHeight,
                    } = (inputRef.current as HTMLDivElement).getBoundingClientRect();

                    setDragBoxState(currentPos => {
                        const { width, height } = currentPos;
                        let newWidth = !width ? inputWidth : width + dx;
                        let newHeight = !height ? inputHeight : height + dy;
                        let newTop = inputTop;
                        let newLeft = inputLeft;

                        if (movementX < 0) {
                            // User is dragging left from the drag point
                            newWidth = inputWidth;
                            const absMx = Math.abs(movementX);
                            if (absMx > inputWidth) {
                                // User is dragging left and are outside the input field
                                newLeft = inputLeft + movementX + inputWidth;
                                newWidth = absMx;
                            }
                        }
                        if (movementY < 0) {
                            // User is dragging up from the drag point
                            newHeight = inputHeight;
                            const absMy = Math.abs(movementY);
                            if (absMy > inputHeight) {
                                // User is dragging higher up than the input field
                                newTop = inputTop + movementY + inputHeight;
                                newHeight = absMy;
                            }
                        }

                        return {
                            height: newHeight,
                            width: newWidth,
                            top: newTop,
                            left: newLeft,
                            visible: true,
                        };
                    });
                    // const { endBottom, endLeft, endRight, endTop } = getScrollTargetState(rowVirtualizer.options.getScrollElement());
                    // let outOfBoundsX: BoundaryX = null;
                    // let outOfBoundsY: BoundaryY = null;
                    // const marginRight = 60;
                    // if (x + marginRight > right - rowHeaderSize - customColumnsWidth && !endRight) {
                    //     // if (x + marginRight > right - rowHeaderSize && !endRight) {
                    //     // console.log('RIGHT');
                    //     outOfBoundsX = 'right';
                    // }

                    // const marginLeft = 30;
                    // if (x + rowHeaderSize - marginLeft < rowHeaderSize - customColumnsWidth && !endLeft) {
                    //     // if (x + rowHeaderSize - marginLeft < rowHeaderSize && !endLeft) {
                    //     // console.log('LEFT');
                    //     outOfBoundsX = 'left';
                    // }

                    // const marginTop = 30;
                    // if (y + top - marginTop <= top + TOP_HEADERS_HEIGHT + inputHeight && !endTop) {
                    //     // console.log('TOP');
                    //     outOfBoundsY = 'top';
                    // }

                    // const marginBottom = 60;
                    // if (y + top + marginBottom >= bottom && !endBottom) {
                    //     // console.log('BOTTOM');
                    //     outOfBoundsY = 'bottom';
                    // }

                    // if (outOfBoundsX || outOfBoundsY) {
                    //     setOutOfBounds([outOfBoundsX, outOfBoundsY]);
                    // } else {
                    //     setOutOfBounds(null);
                    // }
                    // if (!autoScrolling.current) {
                    // }
                    virtualMovement.current = [movementX, movementY];
                    return memo;
                }
            },
        ),
        {
            bounds: scrollRef,
            transform,
        },
    );
    return useMemo(() => {
        return { bindDragHandler, dragState } as DragContext;
    }, [bindDragHandler]);
};

const addVectors = ([x1, y1]: Vector2, [x2, y2]: Vector2): Vector2 => [x1 + x2, y1 + y2];
const subtractVectors = ([x1, y1]: Vector2, [x2, y2]: Vector2): Vector2 => [x1 - x2, y1 - y2];

const getCellUpdatesFromArea = ({
    columnVirtualizer,
    endVector: [x, y],
    initialVector: [xInitial, yInitial],
    rowVirtualizer,
    renderableRows,
    customColumnCount,
}: {
    rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
    columnVirtualizer: Virtualizer<HTMLDivElement, Element>;
    renderableRows: VisibleRow[];
    initialVector: Vector2;
    endVector: Vector2;
    customColumnCount: number;
}) => {
    let startRow = rowVirtualizer.getVirtualItemForOffset(yInitial).index - 1;
    let endRow = rowVirtualizer.getVirtualItemForOffset(y).index - 1;

    if (startRow > endRow) {
        const temp = endRow;
        endRow = startRow;
        startRow = temp;
    }

    let startColumn = columnVirtualizer.getVirtualItemForOffset(xInitial).index - customColumnCount - 1;
    let endColumn = columnVirtualizer.getVirtualItemForOffset(x).index - customColumnCount - 1;

    if (startColumn > endColumn) {
        const temp = endColumn;
        endColumn = startColumn;
        startColumn = temp;
    }

    const rows = renderableRows.filter(({ row }, i) => {
        return i >= startRow && i <= endRow && getRowType(row) === 'resource';
    });

    const cellUpdates = rows.reduce((acc, row) => {
        row.row.columnRows.forEach((columnRow, columnRowIndex) => {
            if (columnRowIndex >= startColumn && columnRowIndex <= endColumn) {
                const cell = columnRow.cells[1];
                if (cell) {
                    acc.push({
                        cell,
                        cellIndex: 1,
                        columnIndex: columnRowIndex,
                        row,
                    });
                }
            }
        });
        return acc;
    }, [] as { cell: GridCell; cellIndex: number; columnIndex: number; row: VisibleRow }[]);
    // const insertPointInDom = ([x, y]: Vector2, bgColor = 'black') => {
    //     const el = rowVirtualizer.options.getScrollElement();
    //     const point = document.createElement('div');
    //     point.style.position = 'absolute';
    //     point.style.top = y + 'px';
    //     point.style.left = x + 'px';
    //     point.style.backgroundColor = bgColor;
    //     point.style.width = '100px';
    //     point.style.height = '100px';
    //     el.appendChild(point);
    //     setTimeout(() => {
    //         el.removeChild(point);
    //     }, 3000);
    // };
    // insertPointInDom([x, y]);
    // insertPointInDom([xInitial, yInitial]);
    return cellUpdates;
};

export type DragBoxState = {
    top: number;
    left: number;
    height: number;
    width: number;
    visible: boolean;
};

const dragStateAtom = atom<DragBoxState>({
    key: 'drag-box-v3',
    default: {
        top: 0,
        left: 0,
        height: 0,
        width: 0,
        visible: false,
    },
});

export const useDragStateValue = () => {
    return useRecoilValue(dragStateAtom);
};

export const useDragStateReset = () => {
    return useResetRecoilState(dragStateAtom);
};

// const scrollbarWidth = getScrollbarWidth();
// const getScrollTargetState = (currentScrollTarget: HTMLElement) => {
//     const { width, height } = currentScrollTarget.getBoundingClientRect();
//     const { scrollLeft, scrollWidth, scrollHeight, scrollTop } = currentScrollTarget;
//     let endRight = false;
//     let endLeft = false;
//     let endTop = false;
//     let endBottom = false;

//     if (scrollWidth < width || scrollWidth + scrollbarWidth - scrollLeft - width <= 0) {
//         // END right;
//         endRight = true;
//     }
//     if (scrollLeft === 0) {
//         // END left
//         endLeft = true;
//     }

//     if (scrollHeight < height || scrollHeight - scrollbarWidth - scrollTop - height <= 0) {
//         // END bottom
//         endBottom = true;
//     }
//     if (scrollTop === 0) {
//         // END top
//         endTop = true;
//     }

//     return {
//         endTop,
//         endBottom,
//         endLeft,
//         endRight,
//     };
// };

// const useCustomColumnTotalWidth = () => {
//     const customColumnCount = useSelectedCustomColumnsCount();
//     return customColumnCount * CUSTOM_COLUMN_WIDTH;
// };

// const getCoords = ([xInit, yInit]: Vector2, [x, y]: Vector2, [right, bottom]: Vector2) => {
//     if (xInit > x) {
//         const t = x;
//         x = xInit;
//         xInit = t;
//     }
//     if (yInit > y) {
//         const t = y;
//         y = yInit;
//         yInit = t;
//     }
//     return {
//         // display: 'block',
//         // position: 'fixed',
//         top: yInit,
//         left: xInit,
//         right: right - x,
//         bottom: bottom - y,
//         // backgroundColor: '#f0f',
//     };
// };
