import { Checkbox, Dialog, DialogType, IDialogContentProps, Label } from '@fluentui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { ApiCalls } from '../../api/api';
import { Announcement } from '../../api/generated/data-contracts';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { language } from '../../Services/LocalizationService';
import { ArrayEx } from '../../Utilities/ArrayEx';
import { LocalStorage } from '../../Utilities/LocalStorage';
import { StepWizard } from './Wizard/StepWizard';
import { WizardStep } from './Wizard/WizardStep';

interface AnnouncementDialogProps {
    Show: boolean;
    DismissCallback: () => void;
    Items: Array<Announcement>;
    HideBoxChecked: boolean;
    ChangeCallback?: (currentIndex: number, newIndex: number) => void;
    HideCurrentCallback?: (event: any, checked: boolean) => void;
}

export const AnnouncementDialog: FunctionComponent<AnnouncementDialogProps> = props => {
    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        title: language.AnnouncementViewer.Announcements,
        closeButtonAriaLabel: language.Common.Close,
    };

    return (
        <Dialog
            hidden={!props.Show}
            dialogContentProps={dialogContentProps}
            onDismiss={props.DismissCallback}
            modalProps={{
                isBlocking: true,
                className: 'tp-landing-page-announcements-dialogroot',
            }}
        >
            <div className={'tp-announcement-container'}>
                <StepWizard
                    steps={props.Items.map((_, idx) => {
                        return {
                            index: idx,
                            content: (next, prev, ctx) => <div dangerouslySetInnerHTML={{ __html: _.content }} />,
                            hideNext: false,
                            hidePrevious: false,
                        } as WizardStep;
                    })}
                    completeCallback={props.DismissCallback}
                    changeCallback={props.ChangeCallback}
                />
            </div>
            <div className={'tp-announcement-checkbox'}>
                <Checkbox id="tpanouncementcheckbox" onChange={props.HideCurrentCallback} checked={props.HideBoxChecked} />
                <Label disabled styles={{ root: { padding: 0 } }}>
                    {language.AnnouncementViewer.DontShowAgain}
                </Label>
            </div>
        </Dialog>
    );
};

const AnnouncementViewer: FunctionComponent<any> = () => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [hideCurrentAnnouncement, setHideCurrentAnnouncement] = useState<boolean>(false);

    const currentItemIndex = useRef<number>(0);

    const { data: uiSettings } = useUISettings();

    const { data: announcements } = useQuery(['announcements', uiSettings.resource.id], async () => {
        const response = await ApiCalls.getAnnouncementsForResource(undefined);
        const allAnnouncements = response.data as Announcement[];
        const hiddenAnnouncements = LocalStorage.get<any>('tp-landing-page-announcements') ?? {};
        const filteredAnnouncements = allAnnouncements.filter(_ => {
            if (hiddenAnnouncements[_.id]) return false;
            return true;
        });
        // no relevant announcements
        if (!filteredAnnouncements?.length) return;

        const sortedAnnouncements = ArrayEx.sort(filteredAnnouncements, 'Order');
        setShowDialog(true);
        return sortedAnnouncements;
    });

    const _localStorageHandler = useCallback(() => {
        LocalStorage.setProperty('tp-landing-page-announcements', announcements[currentItemIndex.current].id, hideCurrentAnnouncement);
    }, [announcements, hideCurrentAnnouncement]);

    const ChangeCurrent = (currentIndex: number, newIndex: number) => {
        _localStorageHandler();
        currentItemIndex.current = newIndex;
        const lsAnnouncements = LocalStorage.get<any>('tp-landing-page-announcements') ?? {};
        const currentContent = announcements[currentItemIndex.current];
        setHideCurrentAnnouncement(lsAnnouncements[currentContent.id]);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const _hideCurrentAnnouncementHandler = (checked: boolean) => {
        setHideCurrentAnnouncement(checked);
    };

    useEffect(() => {
        if (announcements) {
            _localStorageHandler();
        }
    }, [_localStorageHandler, announcements, hideCurrentAnnouncement]);

    return announcements?.length ? (
        <AnnouncementDialog
            Items={announcements}
            DismissCallback={handleCloseDialog}
            Show={showDialog}
            ChangeCallback={ChangeCurrent}
            HideCurrentCallback={(event, checked) => _hideCurrentAnnouncementHandler(checked)}
            HideBoxChecked={hideCurrentAnnouncement}
        />
    ) : null;
};

export default AnnouncementViewer;
