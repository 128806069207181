import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { DayOfWeek, IconButton, FirstWeekOfYear, Stack, TextField } from '@fluentui/react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import DynamicDatePicker from './DynamicPropertyComponents/DynamicDatePicker';

interface IProps extends IDynamicEditorComponentProps {
    Item: Date | string | undefined;
    Update: (value: string) => void;
    Key?: string;

    FirstDayOfWeek?: DayOfWeek;
    FirstWeekOfYear?: FirstWeekOfYear;
    DisplayFormat?: (date: Date) => string;
    MinDate?: (parent: any) => Date;
    MaxDate?: (parent: any) => Date;
    RestrictedDates?: (parent: any) => Array<Date>;
}

const todayTemplate = '[[Today]]';

const DatePickerWithTodayFilter: FunctionComponent<IProps> = props => {
    const [item, setItem] = useState(props.Item);

    const change = useCallback((value: any) => {
        setItem(value);
        props.Update(value);
    }, [props]);

    const todayUsed = useMemo(() => item === todayTemplate, [item]);

    const useToday = useCallback(() => {
        if (!todayUsed) change(todayTemplate);
        else change(null);
    }, [change, todayUsed]);

    return (
        <Stack horizontal>
            {todayUsed ? (
                <TextField value="Today" disabled />
            ) : (
                <DynamicDatePicker
                    {...(props as any)}
                    Value={item}
                    OnChange={(prop, val) => change(val)}
                    PropertyInfo={{ PropertyName: '' }} // fake this shit!!!!!
                />
            )}
            <IconButton
                iconProps={
					!todayUsed ? 
					{ iconName: 'GotoToday' } :
					{ iconName: 'Cancel' }
				}
				title={!todayUsed ? "Use dynamic 'Today' value" : "Clear dynamic 'Today' value"}
				onClick={useToday}
            />
        </Stack>
    );
};

export default DatePickerWithTodayFilter;
