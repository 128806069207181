import { PFPropertyBasicInfo } from "../../../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../../../Utilities/PropertyInfo/Render";
import { MailTemplateDataType } from "../MailTemplateDataType";
import { MailTemplateConfig } from "./MailTemplateConfig";
import { OnCommentReceivedRules } from "./OnCommentReceivedRules";

export class OnCommentReceivedTemplateConfig extends MailTemplateConfig {
	public MailTemplateConfigType: MailTemplateDataType = MailTemplateDataType.OnCommentReceived;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:MailTemplate.Rules", 10, "_localization:MailTemplate.RulesDescription")
	@PFPropertyRender(null, { ItemType: OnCommentReceivedRules })
	public Rules: OnCommentReceivedRules;
}