import { ProposalState } from "../../api/generated/data-contracts";
import { IPickerItem, IPickerOptions, IPickerSuggestionsOptions } from "../../Components/Common/DynamicLoaded/GenericPicker";
import { DataService } from "../../Services/DataService";
import { language } from "../../Services/LocalizationService";
import { Permission } from "../../Services/Permission";
import { OverviewContext } from "../../Utilities/Context/OverviewContext";
import { UserContext } from "../../Utilities/Context/UserContext";
import { DatePickerUtil } from "../../Utilities/DatePickerUtil";
import { PluginIDs } from "../../Utilities/PluginIDs";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyGrouping } from "../../Utilities/PropertyInfo/Grouping";
import { PFPropertyOnChange } from "../../Utilities/PropertyInfo/OnChange";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { PFPropertyValidation } from "../../Utilities/PropertyInfo/Validation";
import { StringEx } from "../../Utilities/StringEx";
import { EntityType } from "../EntityTypes";
import { IObjectEditorItemType } from "../Main/IObjectEditorItemType";
import { Resource } from "../Main/Resource";
import { ResourceType } from "../ResourceType";
import { ProjectScenario, ScenarioType } from "../Scenario";
import { TimelineResolution } from "../TimelineResolution";
import { activeProposalStates } from "../Versions/ProposalState";
import { Plugin } from "./../../Services/PluginInvoker";

const GetScenarios = async (info: ContractInfoDto) => {
	const result = await Plugin.Invoke<Array<{Start: Date, End: Date, Scenario: ProjectScenario}>>(PluginIDs.GetScenarios, { ScenarioType: ScenarioType.ProjectScenario, ProjectId: info.ProjectId, States: activeProposalStates, OwnerId: Permission.IsSuperuser() ? null : UserContext.Resource.id });
	const scenarios = result.map(_ => {
		_.Scenario.Start = _.Start;
		_.Scenario.End = _.End;
		return _.Scenario;
	});
	return scenarios;
}

const ScenarioPickerOptions = (info: ContractInfoDto): IPickerOptions<ProjectScenario> => {
	return {
		EntityGetter: (item: ContractInfoDto) => GetScenarios(item),
		ItemParser: (item: ProjectScenario) => {
			return { 
				Id: item.id,
				DisplayName: item.Name,
				Description: item.State.toString(),
				text: item.Name,
                secondaryText: ProposalState[item.State].toString(),
				tertiaryText: new Date(item.Start).toDateString()
			} as IPickerItem
		},
		RecentItemsKey: "scenariopicker-recentlyused",
		MultiSelect: false,
		Suggestions: {
			LoadingText: language.Common.Loading,
			NoResultsFound: language.Common.NoResults,
			ShowRemoveButtons: true,
			SuggestionsHeaderText: language.ContractInfoDto.RecentlyUsedScenarios,
			SuggestionsContainerText: language.Common.Scenarios,
			SuggestionsAvailableAlertText: language.ContractInfoDto.SuggestedScenarios
		} as IPickerSuggestionsOptions
	} as IPickerOptions<ProjectScenario>;
}

export class ContractInfoDto implements IObjectEditorItemType {
	// auto set
	public ProjectId: string;
	public ContractId: string;
	public ResourceName: string;

	@PFPropertyBasicInfo(PropertyType.LabelText, "_localization:ContractInfoDto.HasActivitiesMessage", 11)
	@PFPropertyRender(null, null, null, (item: ContractInfoDto) => !(item.HasActvities && item.ShouldLockDates))
	HasActivitiesMessage: any; // fake property
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Start", 12)
	@PFPropertyRender(null, { MinDate: dto => ContractInfoDto.GetMinDateBoundary(dto), RestrictedDates: dto => ContractInfoDto.GetRestrictedDates(dto, "first") }, null, null, item => item.HasActvities && item.ShouldLockDates)
	@PFPropertyValidation("_localization:ContractInfoDto.StartGreaterThanFreezePeriod", dto => ContractInfoDto.ValidateStart(dto, "Start"), null, () => true)
	public Start: Date;
	@PFPropertyBasicInfo(PropertyType.LabelText, "_localization:ContractInfoDto.EndExtraDescription", 13)
	@PFPropertyRender(null, null, null, (item: ContractInfoDto) => !item.IgnoreEndValidation)
	EndDescription: any; // fake property
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.End", 14, )
	@PFPropertyRender(null, { MinDate: dto => ContractInfoDto.GetMinDateBoundary(dto), RestrictedDates: dto => ContractInfoDto.GetRestrictedDates(dto, "last") }, null, null, item => item.HasActvities && item.ShouldLockDates)
	@PFPropertyValidation("_localization:Common.EndGreaterThanStart", dto => dto.End > dto.Start, null, (item: ContractInfoDto) => !item.IgnoreEndValidation)
	public End: Date;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.GenericResource", 16)
	@PFPropertyRender(null, { Types: [ResourceType.Generic, ResourceType.Pool], OnlyGenericResourcesInUse: true, OnlyPoolResourcesInUse: true, PickerProperties: { PrimaryTextField: "Name", SecondaryTextField: "RBS"} }, "ResourcePicker")
	@PFPropertyOnChange((obj: ContractInfoDto) => {
		// TODO: ugly shit (ewi)
		// if (StringEx.IsNullOrWhiteSpace(obj.ContractName) && !StringEx.IsNullOrWhiteSpace(obj.ResourceId)) {
			if (obj.ResourceId == null) return;
			DataService.GetSilent<Resource>(EntityType.Resource, obj.ResourceId)
				.then(_ => {
					obj.ResourceName = _?.Name;
					if (StringEx.isNullOrWhiteSpace(obj.ContractName))
						obj.ContractName = _?.Name;
				})
		// }
	})
	public ResourceId: string;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:ContractInfoDto.ContractName", 18)
	public ContractName: string;
	// @PFPropertyBasicInfo(PropertyType.Object, "Predecessors", 10)
	// @PFPropertyRender(null, { PickerOptionsGetter: () => ContractPickerOptions(this) }, "GenericPicker")
	// public Predecessors: Array<string>;
	// @PFPropertyBasicInfo(PropertyType.Object, "Successors", 15)
	// @PFPropertyRender(null, { PickerOptionsGetter: () => ContractPickerOptions(this) }, "GenericPicker")
	// public Successors: Array<string>;
	@PFPropertyBasicInfo(PropertyType.Number, "_localization:ContractInfoDto.Work", 20)
	@PFPropertyValidation("_localization:ContractInfoDto.WorkMustBeGreaterThanZero", dto => dto.Work > 0, null, () => true)
	public Work: number;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.EarliestStart", 30)
	@PFPropertyGrouping("Flexible timeframe")
	@PFPropertyRender(null, { MinDate: dto => ContractInfoDto.GetMinDateBoundary(dto), RestrictedDates: dto => ContractInfoDto.GetRestrictedDates(dto, "first") })
	@PFPropertyValidation("_localization:ContractInfoDto.EarliestStartGreaterThanFreezePeriod", dto => dto.EarliestStart == null || ContractInfoDto.ValidateStart(dto, "EarliestStart"), null)
	public EarliestStart?: Date;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.LatestEnd", 40)
	@PFPropertyGrouping("Flexible timeframe")
	@PFPropertyRender(null, { MinDate: dto => ContractInfoDto.GetMinDateBoundary(dto), RestrictedDates: dto => ContractInfoDto.GetRestrictedDates(dto, "last") })
	@PFPropertyValidation("_localization:ContractInfoDto.LatestEndGreaterThanEarliestStart", dto => dto.LatestEnd == null || dto.LatestEnd > dto.EarliestStart)
	public LatestEnd?: Date;

	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:ContractInfoDto.IncludeInScenario", 51)
	@PFPropertyOnChange((info: ContractInfoDto) => {
		info.CreateNewScenario = false;
		info.ScenarioName = null;
		info.Scenario = null;
	})
	@PFPropertyRender(null, null, null, null, () => !Permission.HasSomePermissions(...OverviewContext.Settings.FindAvailableCapacityUsingLiveDatePermissions))
	public UseScenario: boolean;
	@PFPropertyBasicInfo(PropertyType.BooleanToggle, "_localization:ContractInfoDto.ExistingOrNewScenario", 52)
	@PFPropertyRender(null, { OnText: "_localization:ContractInfoDto.CreateNewScenario", OffText: "_localization:ContractInfoDto.SelectExistingScenario" }, null, (info: ContractInfoDto) => !info.UseScenario)
	@PFPropertyOnChange((info: ContractInfoDto) => {
		info.ScenarioName = null;
		info.Scenario = null;
	})
	public CreateNewScenario: boolean;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Scenario", 55)
	@PFPropertyRender(null, { PickerOptionsGetter: () => ScenarioPickerOptions(this) }, "GenericPicker", (info: ContractInfoDto) => !info.UseScenario || info.CreateNewScenario)
	public Scenario: string;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:ContractInfoDto.NewScenarioName",56)
	@PFPropertyRender(null, null, null, (info: ContractInfoDto) => !info.UseScenario || !info.CreateNewScenario)
	public ScenarioName: string;

	public Resolution: TimelineResolution;

	public HasActvities: boolean;
	public ShouldLockDates?: boolean;

	public IgnoreEndValidation?: boolean;

	constructor(projectId?: string) {	
		this.Work = 0;
		this.ProjectId ??= projectId;
		this.UseScenario = true;
	}

	public static GetMinDateBoundary = (dto: ContractInfoDto) : Date => {
		return DatePickerUtil.GetMinDateBoundary(dto.Resolution, OverviewContext.Settings.FreezePeriod);
	}

	public static ValidateStart = (dto: ContractInfoDto, startProp: string) : boolean => {
		const start = ContractInfoDto.GetMinDateBoundary(dto);
		return new Date(dto[startProp]) >= start;
	}

	public static GetRestrictedDates = (dto: ContractInfoDto, type: "first" | "last") : Array<Date> => {
		return DatePickerUtil.GetRestrictedDates(dto.Resolution, OverviewContext.Settings.FreezePeriod, type);
	}
}