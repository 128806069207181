import { CommandBarButton, IButtonStyles } from '@fluentui/react';
import { useMemo, useCallback } from 'react';
import { GridView, GridViewType } from '../../../../../../api/generated/data-contracts';
import { ButtonText } from './ButtonText';

export const ListButton = ({
    currentView,
    view,
    viewHasFilterChanges,
    onClick,
    defaultViewId,
    purgeUndoStack,
}: {
    view: GridView;
    currentView: GridView;
    viewHasFilterChanges: boolean;
    onClick: (viewId: string) => void;
    defaultViewId: string;
    purgeUndoStack: () => void;
}) => {
    const onRenderCommandbarText = useMemo(() => onRenderListTextFunction(view, currentView, viewHasFilterChanges), [currentView, view, viewHasFilterChanges]);

    const handleOnClick = useCallback(() => {
        if (view.id !== currentView.id) {
            onClick(view.id);
            purgeUndoStack();
        }
    }, [view.id, currentView.id, onClick, purgeUndoStack]);

    const iconName = useMemo(() => {
        if (view.type === GridViewType.Global) {
            if (view.id === defaultViewId) {
                return 'GlobeFavorite';
            }
            return 'Globe';
        }
        if (view.id === defaultViewId) {
            return 'ContactHeart';
        }
        return 'Contact';
    }, [defaultViewId, view.id, view.type]);

    const iconProps = useMemo(() => {
        return {
            iconName,
        };
    }, [iconName]);

    return (
        <CommandBarButton
            iconProps={iconProps}
            text={view.name}
            style={{ ...(view.id === currentView.id && { backgroundColor: 'rgba(0,0,0,0.1)' }) }}
            onRenderText={onRenderCommandbarText}
            onClick={handleOnClick}
            styles={listButtonStyles}
        />
    );
};
const onRenderListTextFunction =
    (view: GridView, currentView: GridView, viewHasFilterChanges: boolean) =>
    ({ text }: { text: string }) => {
        return <ButtonText>{text + (view.id === currentView.id && viewHasFilterChanges ? '*' : '')}</ButtonText>;
    };

const listButtonStyles: IButtonStyles = { root: { height: 32 } };
