import { useEffect, useState, FunctionComponent } from "react";
import { AddActivityDto } from "../../../../Entities/Dto/AddActivityDto";
import { GridType } from "../../../../Entities/GridType";
import { GroupRow } from "../../../../Entities/Table/GroupRow";
import { Row } from "../../../../Entities/Table/Row";
import { TopRow } from "../../../../Entities/Table/TopRow";
import { TimelineResolution } from "../../../../Entities/TimelineResolution";
import { Plugin } from "../../../../Services/PluginInvoker";
import { VersionedProjectCapacityService } from "../../../../Services/VersionedProjectCapacityService";
import { DirectionalHint, Link, Stack, Text } from "@fluentui/react";
import { PluginIDs } from "../../../../Utilities/PluginIDs";
import DefaultProjectCapacity, { ExtraContentEvent } from "../../../../Components/Overview/DefaultProjectCapacity";
import { EntityInformationCallout } from "../../../../Components/Common/EntityInformationCallout";
import { language } from "../../../../Services/LocalizationService";
import { EntityType } from "../../../../Entities/EntityTypes";
import { EventEx } from "../../../../Utilities/EventEx";
import { GroupContainerRow } from "../../../../Entities/Table/GroupContainerRow";
import { guid } from "../../../../helpers/guid";
import { DataService } from "../../../../Services/DataService";
import { Scenario } from "../../../../Entities/Scenario";
import { CoachmarkInfo } from "../../../../userGuidance/CoachmarkInfo";
import { useStore } from "../../../../context/store";
import shallow from "zustand/shallow";
import { IEntityInfoCallout } from "../../../../context/contexts/entityCalloutContext";
import { useParams } from "react-router";
import { PFCoachmark } from "../../../../userGuidance/PFCoachmark";
import { useCurrentProject } from "../../../../context/network/http/QueryProvider/queries/project";
import { Timeout } from "../../../../types/runtimeTypes";
import { ProposalState } from "../../../../api/generated/data-contracts";

interface IProps {
    gridType: GridType;
}

const groupContainerExtraContent = (name: string, container: GroupContainerRow, onResourceAddToContainer: ExtraContentEvent, onGroupAdd: ExtraContentEvent) : JSX.Element => (
	<>
		<div className="tp-list-capacity-addcontainer-dropzone-overlay" onDrop={ e => onGroupAdd(e, container)} onDragEnter={ e => e.preventDefault() } onDragOver={ e => e.preventDefault() }>
			<div className="tp-list-capacity-dropzone-text">{language.ProjectCapacity.GroupContainerExtraContent.AddToGroup}</div>
		</div>
		<div className="tp-list-capacity-removefromcontainer-dropzone-overlay" onDrop={ e => onGroupAdd(e, container)} onDragEnter={ e => e.preventDefault() } onDragOver={ e => e.preventDefault() }>
			<div className="tp-list-capacity-dropzone-text">{language.ProjectCapacity.GroupContainerExtraContent.MoveOutOfGroup}</div>
		</div>
	</>
)

const groupExtraContent = (group: GroupRow, onResourceAdd: ExtraContentEvent) : JSX.Element => (
	<>
		<div className="tp-list-capacity-dropzone-overlay tp-grid-group" onDrop={ e => onResourceAdd(e, group)} onDragEnter={ e => e.preventDefault() } onDragOver={ e => e.preventDefault() }>
			<div className="tp-list-capacity-dropzone-text">Drop here</div>
		</div>
	</>
)

export const DraftProjectCapacity: FunctionComponent<IProps> = ({ gridType }) => {
    const { projectId, draftId } = useParams<{ projectId: string; draftId: string }>();
    const { timeline, error } = useStore(
        store => ({
            timeline: store.timeline,
            error: store.addErrorNotification,
        }),
        shallow,
    ); 
	
	const { data: project } = useCurrentProject(projectId)
	

    const [detailDto, setDetailDto] = useState<IEntityInfoCallout>(null);
    const [draft, setDraft] = useState<Scenario>();

    const _draftInfo = new CoachmarkInfo(
        'draft-project-capacity',
        `.tp-topbar button[name="Draft"]`,
        language.Coachmarks.DraftProjectCapacity.WorkingWithDrafts,
        DirectionalHint.bottomRightEdge,
    );

    useEffect(() => {
        const fetchDraft = async () => {
            setDraft(null);
            const draft = await DataService.Get<Scenario>(EntityType.Scenario, draftId);
            setDraft(draft);
        };
        fetchDraft();
    }, [draftId]);

    let _timer: Timeout;
    const OnProjectHover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        _timer = EventEx.delay(
            _timer,
            () => {
                setDetailDto({
                    Id: projectId,
                    EntityType: EntityType.Project,
                    Target: '.project-capacity-top-hover',
                    Properties: { Url: project?.url },
                });
                // hack to prevent element losing event when re-rendering
                setTimeout(() => {
                    (document.querySelector('.project-capacity-top-hover') as HTMLElement).addEventListener('mouseleave', OnProjectHoverLeave as any);
                }, 10);
            },
            1000,
        );
    };

    const OnProjectHoverLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        clearTimeout(_timer);
        _timer = null;
        setDetailDto(null);
        (document.querySelector('.project-capacity-top-hover') as HTMLElement).removeEventListener('mouseleave', OnProjectHoverLeave as any);
    };

    const updateCell = async (dto: AddActivityDto, cellId: string, ignoreContainerName?: boolean, extraConfig?: { [key: string]: any }): Promise<string> =>
        VersionedProjectCapacityService.saveCell(extraConfig.VersionedId, dto, draftId, ProposalState.Draft, cellId, error, ignoreContainerName);

    const updateCellsMultiple = async (
        dtos: AddActivityDto[],
        cellIds: string[],
        ignoreContainerName?: boolean,
        extraConfig?: { [key: string]: any },
    ): Promise<void> => {
        // not implemented yet
    };

    const titleContentOverride = (title: string): JSX.Element => (
        <>
            <div className="project-capacity-top-hover" onMouseEnter={OnProjectHover} onMouseLeave={OnProjectHoverLeave}>
                <Stack>
                    <Text className="tp-capacity-project-title">
                        <div className="truncate-text">{title}</div>
                    </Text>
                    <Text className="tp-capacity-project-title">
                        <div className="truncate-text draft-capacity-drafttitle">{draft?.Name}</div>
                    </Text>
                </Stack>
            </div>
        </>
    );

    if (draft == null) return null;

    return (
        <>
            <div className="draft-project-capacity">
                <DefaultProjectCapacity
                    projectId={projectId}
                    start={timeline.start}
                    end={timeline.end}
                    resolution={timeline.resolution as any}
                    gridType={gridType}
                    callbacks={{
                        getCapacityGrid: async (
                            projectId: string,
                            start: Date,
                            end: Date,
                            resolution: TimelineResolution,
                            gridType: GridType,
                            groupBy: string,
                        ) =>
                            Plugin.Invoke<TopRow>(
                                PluginIDs.GetDraftProjectCapacityView,
                                {
                                    ScenarioId: draftId,
                                    ProjectId: projectId,
                                    Start: start,
                                    End: end,
                                    DateResolution: resolution,
                                    GridType: gridType,
                                    GroupBy: groupBy,
                                },
                                'Failed getting grid data',
                                true,
                            ),
                        resourceRemove: async (
                            gridType: GridType,
                            contractId: string,
                            resourceId: string,
                            start: Date,
                            resolution: TimelineResolution,
                            groupProperties?: { [key: string]: any },
                        ) =>
                            Plugin.InvokeNoResponse(
                                PluginIDs.RemoveVersionedActivities,
                                { GridType: gridType, ContractId: groupProperties.VersionedId, ResourceId: resourceId, Start: start, Resolution: resolution },
                                'Failed removing activities',
                                true,
                            ),
                        updateCell: updateCell,
                        updateMultipleCells: updateCellsMultiple,
                        titleContentOverride: titleContentOverride,
                        changeContractName: async (groupId: string, newName: string) => console.log('change contract name'),
                        changeContractContainerName: async (groupIds: Array<string>, newName: string) => console.log('change container name'),
                        changeContractContainer: async (groupId: string, containerName: string) => console.log('change contract container'),
                        rowExtraContent: undefined,
                        groupExtraContent: groupExtraContent,
                        groupExtraTextContent: undefined,
                        groupContainerExtraContent: groupContainerExtraContent,
                        alterNewResourceRow: (row: Row) => {
                            row.Properties.ScenarioId = draftId;
                            row.Properties.VersionedId = guid.newGuid();
                        },
                    }}
                    customColumns={[]}
                    extraMenuItems={[]}
                    disableDragUpdates
                    refreshKey={draft.id}
                />
                <EntityInformationCallout Dto={detailDto}>
                    {detailDto && detailDto.Properties.Url && (
                        <Link className="ms-CalloutExample-link" href={detailDto.Properties.Url} target="_blank">
                            {language.ProjectCapacity.GoToProjectAtOrigin}
                        </Link>
                    )}
                </EntityInformationCallout>
                <PFCoachmark CoachmarkInfo={_draftInfo} />
            </div>
        </>
    );
};
