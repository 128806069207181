type Callback<Key, Type, CBReturnType = any> = (item: Type, index: number, key: Key, map: Map<Key, Type> | ReadOnlyMap<Map<Key, Type>>) => CBReturnType;
type CallbackWithAccumulator<Key, Type, Acc, CBReturnType = Acc> = (acc: Acc, item: Type, index: number, key: Key, map: Map<Key, Type>) => CBReturnType;
export type ReadOnlyMap<ImmutableMap extends Map<Key, Item>, Key = any, Item = any> = Omit<ImmutableMap, "set" | "delete" | "clear">;
export function reduceMap<K, T, Acc, Cb extends CallbackWithAccumulator<K, T, Acc>>(map: Map<K, T> | ReadOnlyMap<Map<K, T>>, cb: Cb, acc?: Acc) {
    let index = 0;
    let currentValue = acc;
    map.forEach((val, key, map) => {
        currentValue = cb(currentValue, val, index++, key, map);
    });
    return currentValue;
}

export function reduceRightMap<K, T, Acc, Cb extends CallbackWithAccumulator<K, T, Acc>>(map: Map<K, T>, cb: Cb, acc?: Acc) {
    return reduceMap(reverseMap(map), cb, acc);
}

export function mapMap<K, T, Cb extends Callback<K, T, [NewKey, NewType]>, NewKey = ReturnType<Cb>[0], NewType = ReturnType<Cb>[1]>(map: Map<K, T> | ReadOnlyMap<Map<K, T>>, cb: Cb) {
    return reduceMap(
        map,
        (acc, item, index, key, map) => {
            acc.set(...cb(item, index, key, map));
            return acc;
        },
        new Map<NewKey, NewType>(),
    );
}

export function mapMapToArray<K, T, Cb extends Callback<K, T>>(map: Map<K, T>, cb: Cb) {
    return reduceMap(
        map,
        (acc, item, index, key, map) => {
            acc.push(cb(item, index, key, map));
            return acc;
        },
        [] as ReturnType<Cb>[],
    );
}

export function findIndexInMap<K, T, Cb extends Callback<K, T, boolean>>(map: Map<K, T>, cb: Cb) {
    const notFound: number = -1;
    return reduceMap(
        map,
        (acc, item, index, key, map) => {
            if (acc !== notFound) {
                return acc;
            }
            if (cb(item, index, key, map)) {
                return index;
            }
            return acc;
        },
        notFound,
    );
}

export function findLastIndexInMap<K, T, Cb extends Callback<K, T, boolean>>(map: Map<K, T>, cb: Cb) {
    const notFound: number = -1;
    return reduceMap(
        map,
        (acc, item, index, key, map) => {
            if (cb(item, index, key, map)) {
                return index;
            }
            return acc;
        },
        notFound,
    );
}

export function indexOfInMap<K, T, S extends boolean>(map: Map<K, T>, item: S extends true ? K | T : T, includeKey?: S, fromIndex?: number) {
    const startOffset = !fromIndex ? 0 : fromIndex >= 0 ? fromIndex : map.size + fromIndex;
    return findIndexInMap(map, (val, index, key) => {
        if (index >= startOffset) {
            return Object.is(item, val) || (includeKey && Object.is(item, key));
        }
        return false;
    });
}

export function lastIndexOfInMap<K, T, S extends boolean>(map: Map<K, T>, item: S extends true ? K | T : T, includeKey?: S, fromIndex?: number) {
    const startOffset = !fromIndex ? 0 : fromIndex >= 0 ? fromIndex : map.size + fromIndex;
    return findLastIndexInMap(map, (val, index, key) => {
        if (index >= startOffset) {
            return Object.is(item, val) || (includeKey && Object.is(item, key));
        }
        return false;
    });
}

export function includesInMap<K, T, S extends boolean>(map: Map<K, T>, item: S extends true ? K | T : T, includeKey?: S) {
    return indexOfInMap(map, item, includeKey) !== -1;
}

export function reverseMap<K, T>(map: Map<K, T>) {
    return new Map([...map].reverse());
}

export function findMap<K, T, Cb extends Callback<K, T, boolean>>(map: Map<K, T>, cb: Cb) {
    return reduceMap(map, (acc, item, index, key, map) => {
        if (acc !== undefined) {
            return acc;
        }
        if (cb(item, index, key, map)) {
            return item;
        }
        return acc;
    }) as T | undefined;
}

export function atMap<K, T>(map: Map<K, T> | ReadOnlyMap<Map<K, T>>, index: number) {
    const indexToReturn = index >= 0 ? index : map.size + index;
    return reduceMap(map, (acc, item, i) => {
        if (acc !== undefined) {
            return acc;
        }
        if (indexToReturn === i) {
            return item;
        }
        return acc;
    }) as T | undefined;
}

/**
 * Has a similar call signature to Array.prototype.forEach
 * @param map the map to iterate over
 * @param cb callback function
 */
export function forEachMap<K, T, Cb extends Callback<K, T>>(map: Map<K, T>, cb: Cb) {
    reduceMap(map, (acc, item, index, key, map) => {
        cb(item, index, key, map);
    });
}

export function sortMap<K, T>(map: Map<K, T>, cb: (itemA: T, itemB: T, keyA: K, keyB: K) => number) {
    return new Map([...map].sort(([keyA, itemA], [keyB, itemB]) => cb(itemA, itemB, keyA, keyB)));
}

export function someMap<K, T, Cb extends Callback<K, T, boolean>>(map: Map<K, T>, cb: Cb) {
    return reduceMap(
        map,
        (acc, item, index, key, map) => {
            if (acc) {
                return acc;
            }
            return Boolean(cb(item, index, key, map));
        },
        false as boolean,
    );
}

export function everyMap<K, T, Cb extends Callback<K, T, boolean>>(map: Map<K, T>, cb: Cb) {
    return reduceMap(
        map,
        (acc, item, index, key, map) => {
            if (!acc) {
                return acc;
            }
            return Boolean(cb(item, index, key, map));
        },
        true as boolean,
    );
}

export function filterMap<K, T, Cb extends Callback<K, T, boolean>>(map: Map<K, T>, cb: Cb) {
    return reduceMap(
        map,
        (acc, item, index, key, map) => {
            if (cb(item, index, key, map)) {
                acc.set(key, item);
            }
            return acc;
        },
        new Map() as Map<K, T>,
    );
}

export function filterMapToArray<K, T, Cb extends Callback<K, T, boolean>>(map: Map<K, T> | ReadOnlyMap<Map<K, T>>, cb: Cb) {
    return reduceMap(
        map,
        (acc, item, index, key, map) => {
            if (cb(item, index, key, map)) {
                acc.push(item);
            }
            return acc;
        },
        // new Map() as Map<K, T>,
        [] as T[],
    );
}

const filterOut = Symbol.for('filterout');
type FilterOut = symbol;
export function filterMapMapStrict<K, T, Cb extends CallbackWithAccumulator<K, T, FilterOut, any>>(map: Map<K, T>, cb: Cb) {
    return reduceMap(
        map,
        (acc, item, index, key, map) => {
            const result = cb(filterOut, item, index, key, map);
            if (result !== filterOut) {
                acc.set(key, result);
            }
            return acc;
        },
        // new Map() as Map<K, Exclude<ReturnType<Cb>, FilterOut>>,
        new Map<K, Exclude<ReturnType<Cb>, FilterOut>>(),
    );
}

/**
 * Filters by returning undefined and maps the values from a callback.
 *
 * Maps to all values except undefined
 */
export function filterMapMap<K, T, Cb extends Callback<K, T, any>>(map: Map<K, T>, cb: Cb) {
    return reduceMap(
        map,
        (acc, item, index, key, map) => {
            const result = cb(item, index, key, map);
            if (result !== undefined) {
                acc.set(key, result);
            }
            return acc;
        },
        new Map<K, NonNullable<ReturnType<Cb>>>(),
    );
}
/**
 * Filters by returning undefined and maps the values from a callback.
 *
 * Maps to all values except undefined
 */
export function filterMapMapToArray<K, T, Cb extends Callback<K, T, any>>(map: Map<K, T>, cb: Cb) {
    return reduceMap(
        map,
        (acc, item, index, key, map) => {
            const result = cb(item, index, key, map);
            if (result !== undefined) {
                acc.push(result);
            }
            return acc;
        },
        [] as NonNullable<ReturnType<Cb>>[],
    );
}
