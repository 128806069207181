import { useMemo, useEffect } from 'react';
import { useControlledState } from '../../../../../../../../hooks/useControlledState';
import { useTheme } from '../../../../../../../../Themes/themeContext';
import { Callout, DirectionalHint, Icon, ILayerProps, IStackTokens, ITextProps, Stack, Text } from '@fluentui/react';

export const ErrorMessage = ({
	errorMessage, targetId, messageTimeout = 5000, directionalHint = DirectionalHint.topCenter,
	useTimout = true
}: {
	errorMessage: string;
	targetId: string;
	messageTimeout?: number;
	directionalHint?: DirectionalHint;
	useTimout?: boolean
}) => {
	const theme = useTheme();
	const [message, setMessage] = useControlledState(() => errorMessage, [errorMessage]);
	useEffect(() => {
		if (message && useTimout) {
			const timer = setTimeout(() => {
				setMessage('');
			}, messageTimeout);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [message, setMessage, messageTimeout, useTimout]);
	const { layerProps, stackTokens, textStyles } = useMemo((): {
		layerProps: ILayerProps;
		stackTokens: IStackTokens;
		textStyles: ITextProps['styles'];
	} => {
		return {
			layerProps: {
				styles: {
					root: {
						// we use filter so the callout and beak gets a consistent shadow all around them.
						// box-shadow make individual shadows for elements.
						filter: 'drop-shadow(0px 0px 10px rgba(0,0,0,0.1))',
						'.ms-Callout-main, .ms-Callout-beak': {
							backgroundColor: theme.semanticColors.errorBackground,
						},
					},
				},
			},
			stackTokens: {
				padding: 10,
			},
			textStyles: { root: { fontSize: 14, fontWeight: 500, display: 'flex', alignItems: 'center', gap: 6 } },
		};
	}, [theme.semanticColors.errorBackground]);
	if (!message) {
		return null;
	}
	return (
		<Callout target={`#${targetId}`} directionalHint={directionalHint} layerProps={layerProps}>
			<Stack tokens={stackTokens}>
				<Text styles={textStyles}>
					<Icon iconName="warning" /> {message}
				</Text>
			</Stack>
		</Callout>
	);
};
