import { FunctionComponent, useState, useEffect } from 'react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { DropdownOptionEx } from '../../../Utilities/DropdownOptionEx';
import { TPPluginInformation } from '../../../Entities/Dto/TPPluginInformation';
import { Plugin } from '../../../Services/PluginInvoker';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import PFDropdown from '../PFDropdown';

interface IProps extends IDynamicEditorComponentProps {
    Item: string;
    Update: (value: string) => void;
    Key?: string;
}

const PluginSelector: FunctionComponent<IProps> = props => {
    const [plugins, setPlugins] = useState<Array<TPPluginInformation>>();
    const [item, setItem] = useState(props.Item);

    useEffect(() => {
        let mounted = true;
        const fetchLookups = async () => {
            const pluginsItems = await GetPlugins();
            if (mounted) setPlugins(pluginsItems);
        };
        fetchLookups();
        return () => {
            mounted = false;
        };
    }, []);

    const GetPlugins = async (): Promise<Array<TPPluginInformation>> => {
        return await Plugin.Invoke<Array<TPPluginInformation>>(PluginIDs.PluginInformation, '', 'Failed getting plugin informations', true, null);
    };

    const OnChange = (changedItem: string): void => {
        props.Update(changedItem);
        setItem(changedItem);
    };

    return (
        <>
            <PFDropdown
                Searchable
                OnChange={(ev, allItems, option, idx) => OnChange(option.key as string)}
                Options={
                    plugins
                        ? DropdownOptionEx.toDropdownOptions(
                              plugins,
                              _ => _.Information.Id,
                              _ => _.Information.Name,
                          )
                        : []
                }
                SelectedKey={item}
                Readonly={props.ReadOnly || !plugins}
                ClassName="tp-pluginselector"
            />
        </>
    );
};

export default PluginSelector;
