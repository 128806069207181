/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { PFDialog } from '../Common/PFDialog';
import { WithStore } from '../../Services/StateStore';
import { StoreProps } from '../../Services/StoreProps';
import { Plugin } from '../../Services/PluginInvoker';
import { PluginIDs } from '../../Utilities/PluginIDs';
import { TimelineResolution } from '../../Entities/TimelineResolution';
import { TopRow } from '../../Entities/Table/TopRow';
import Table from './Table/Table';
import { Label } from '@fluentui/react/lib/Label';
import { Cell } from '../../Entities/Table/Cell';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { CellEx } from '../../Utilities/CellEx';
import { CellRow } from '../../Entities/Table/CellRow';
import { PFSpinner } from '../Common/Spinner';
import { ForwardRequestDto } from '../../Entities/Dto/ForwardRequestDto';
import { PFErrorBoundary } from '../Common/PFErrorBoundary';
import { ResourceType } from '../../Entities/ResourceType';
import { CellUpdateDto } from './Table/DragCopy/CellUpdateDto';
import { NumberEx } from '../../Utilities/NumberEx';
import { language } from '../../Services/LocalizationService';
import { IDs } from '../../Utilities/IDs';
import { PrimaryButton, Toggle } from '@fluentui/react';
import ResourcePicker from '../Common/DynamicLoaded/ResourcePicker';
import { ApiCalls } from '../../api/api';
import { ForwardFullRequestDto } from '../../api/generated/data-contracts';
import { useMutation } from 'react-query';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';

interface IProps extends StoreProps {
    Resolution: TimelineResolution;
    Start: Date;
    End: Date;
}

const ForwardRequestDialog: FunctionComponent<IProps> = props => {
    const [resource, setResource] = useState<string>();
    const [view, _setView] = useState<TopRow>();
    const invalidFields = useRef<Array<any>>([]);
    const [resolutionWarningFields, setResolutionWarningFields] = useState<Array<any>>([]);
    const [editingCell, setEditingCell] = useState<boolean>(true);
	const { data: uiSettings } = useUISettings();
    const [toggleIsChecked, setToggleIsChecked] = useState(!!uiSettings.settings.gridActionOptions?.forwardRequestFullDefaultTicked);

    const setView = (view: TopRow) => {
        if (view != null) {
            view.ChangeKey = IDs.makeId();
        }
        _setView(view);
    };

    useEffect(() => {
        const getView = async () => {
            if (resource == null) {
                setView(null);
                return;
            }
            const itemInfo = props.Store.Get(_ => _.ForwardRequestInfo);
            const info = new ForwardRequestDto(itemInfo.ContractId, itemInfo.ResourceId);
            info.Start = props.Start;
            info.End = props.End;
            info.DateResolution = props.Resolution;
            info.ToResourceId = resource;
            const top = await Plugin.Invoke<TopRow>(
                PluginIDs.GetForwardRequestView,
                info,
                language.ForwardRequestDialog.FailedCreatingForwardRequest,
                true,
                [],
            );
            setView(top);
        };
        getView();
    }, [props.End, props.Resolution, props.Start, props.Store, resource]);

    const OnCellUpdate = (
        columnIndex: number,
        idx: number,
        cell: Cell,
        newValue: number,
        event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): TopRow => {
        const group = view.Groups.find(_ => _.Id === cell.Properties.GroupId);
        const row = group.Rows.find(_ => _.Id === cell.Properties.RowId);

        // edited cell
        const cellRow = row.CellRows[columnIndex];
        cell = cellRow.Cells[idx];
        CellEx.Update(cell, newValue);

        // other cell
        const otherRow = group.Rows.find(_ => _.Id !== cell.Properties.RowId);
        const otherCellRow = otherRow.CellRows[columnIndex];
        const otherCell = otherCellRow.Cells[idx];

        CheckForResolutionMismatch(cellRow, otherCellRow, event);

        // group cell
        const groupRow = group.CellRows[columnIndex];
        const groupCell = groupRow.Cells[idx];

        newValue = CheckForForwardingLimit(newValue, cell, groupCell, event);

        CellEx.Update(otherCell, groupCell.Value - newValue);

        return view;
    };

    const CheckForForwardingLimit = (newValue: number, cell: Cell, groupCell: Cell, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): number => {
        // handle rounding issues
        if (NumberEx.round(groupCell.Value, 2) === newValue) {
            newValue = groupCell.Value;
        }
        if (groupCell.Value < newValue) {
            if (event && event.currentTarget) {
                event.currentTarget.style.border = 'solid 0.5px red';
            }
            if (invalidFields.current.indexOf(cell.Id) < 0) {
                invalidFields.current.push(cell.Id);
            }
        } else {
            const index = invalidFields.current.indexOf(cell.Id);
            if (index >= 0) {
                if (event && event.currentTarget) {
                    event.currentTarget.style.border = '';
                }
                invalidFields.current.splice(index, 1);
            }
        }

        return newValue;
    };

    const matchCell = (): void => {
        const group = view.Groups[0];
        const forwardRow = group.Rows[group.Rows.length - 1];

        forwardRow.CellRows.forEach((cr, crIndex) => {
			const isFreezePeriod = cr.Cells[0].Disabled;
			// Set index to 0 in case of column being within freeze period
            const cellIndex = cr.Cells[0].Disabled ? 0 : cr.Cells.findIndex(_ => {
                return !_.Disabled
            });

            const forwardCell = cr.Cells[cellIndex];
            // get total
            const totalCell = group.CellRows[crIndex].Cells[cellIndex];
            // get other row
            const otherRow = group.Rows.find(_ => _.Id !== forwardCell?.Properties?.RowId);
            const otherCell = otherRow.CellRows[crIndex].Cells[cellIndex];
            // update forward cell with group cell value
            CellEx.Update(forwardCell, isFreezePeriod ? 0 : totalCell.Value);
            // update group cell with '0'
           isFreezePeriod ? CellEx.Update(otherCell, otherRow.CellRows[crIndex].Cells[cellIndex].Value) : CellEx.Update(otherCell, 0);
        });

        setEditingCell(false);
        setView({ ...view });
    };

    const CheckForResolutionMismatch = (cellRow: CellRow, otherCellRow: CellRow, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // notify if the request your forwarding has a different resolution than the one currently selected
        if (
            cellRow.Cells[0].Value &&
            otherCellRow.Properties.RequestResolution &&
            (otherCellRow.Properties.RequestResolution.length > 1 ||
                (otherCellRow.Properties.RequestResolution.length === 1 && otherCellRow.Properties.RequestResolution[0] !== props.Resolution))
        ) {
            if (event && event.currentTarget) {
                event.currentTarget.style.border = 'solid 0.5px blue';
            }
            if (resolutionWarningFields.indexOf(otherCellRow.Id) < 0) {
                resolutionWarningFields.push(otherCellRow.Id);
            }
        } else {
            if (event && event.currentTarget) {
                event.currentTarget.style.border = '';
            }
            const index = resolutionWarningFields.indexOf(otherCellRow.Id);
            if (index >= 0) {
                resolutionWarningFields.splice(index, 1);
            }
        }

        setResolutionWarningFields([...resolutionWarningFields]);
    };

    const DragUpdates = () => {
        const updates = [
            (cells: Array<CellUpdateDto>) => {
                const updatedModel = OnMultipleCellsUpdate(view, props.Resolution, cells);
                setView({ ...updatedModel });
            },
        ];
        return updates;
    };

    const OnMultipleCellsUpdate = (view: TopRow, resolution: TimelineResolution, cells: Array<CellUpdateDto>, isUndo?: boolean): TopRow => {
        const newValue = cells[cells.length - 1].Value;

        cells.forEach(cell => {
            OnCellUpdate(cell.ColumnIndex, 0, cell.Cell, newValue);
        });

        return view;
    };

    const { isLoading: loading, mutateAsync } = useMutation(
        async () => {
            //is this needed?
            view.Groups[0].Rows[0].CellRows.forEach(cr => cr.Cells.forEach(_ => delete _.ReRender));
            view.Groups[0].Rows[1].CellRows.forEach(cr => cr.Cells.forEach(_ => delete _.ReRender));

            if (toggleIsChecked) {
                const contractId = props.Store.Get(_ => _.ForwardRequestInfo).ContractId;
                const dto = { contractId, targetResourceId: resource, resolution: props.Resolution as any } as ForwardFullRequestDto;
                await ApiCalls.forwardFullRequest(dto);
            } else {
                await Plugin.InvokeNoResponse(PluginIDs.ForwardRequest, { Row: view, Resolution: props.Resolution });
            }
        },
        {
            onSuccess: () => {
                props.Store.Set({ ShowForwardRequestDialog: false, ForwardRequestInfo: null });
                setResource(null);
                setView(null);
                setResolutionWarningFields([]);
                OverviewContext.RefreshProjectCapacity();
            },
        },
    );

    return (
        props.Store.Get(_ => _.ShowForwardRequestDialog) && (
            <PFDialog
                title={language.ForwardRequestDialog.ForwardRequestTitle}
                subText={language.ForwardRequestDialog.ForwardRequestSubtext}
                showDialog={props.Store.Get(_ => _.ShowForwardRequestDialog)}
                maxWidth={2000}
                eventBubbling={true}
                callback={mutateAsync}
                dismissCallback={() => {
                    props.Store.Set({ ShowForwardRequestDialog: false, ForwardRequestInfo: null });
                    setResource(null);
                    setView(null);
                    setResolutionWarningFields([]);
                }}
                okButtonEnabled={
                    (invalidFields.current.length === 0 && !loading && resource != null && !editingCell) || (toggleIsChecked && resource != null && !loading)
                }
                buttonDefaultDisabled={true}
            >
                <div className="tp-forwardrequest-dialog-content">
                    {loading ? (
                        <PFSpinner />
                    ) : (
                        <PFErrorBoundary ComponentName="forward request dialog" Reset={async () => setResource(null)}>
                            <Toggle
                                label={'Forward request based on'}
                                defaultChecked={toggleIsChecked}
                                onText={'Full contract'}
                                offText={'Timeline'}
                                onChange={(ev, checked?: boolean) => setToggleIsChecked(checked)}
                            />{' '}
                            {/*TODO Localization */}
                            {resolutionWarningFields.length > 0 && (
                                <Label styles={{ root: { color: 'red' } }}>
                                    The request you're forwarding, has a different time resolution, than the one currently selected!
                                </Label>
                            )}
                            <Label>Select Resource</Label>
                            <ResourcePicker
                                Item={null}
                                RoleSearchEnabled={true}
                                Update={val => {
                                    console.log(val);
                                    setResource(val as string);
                                }}
                                Types={
                                    OverviewContext.Settings.EnableRequestNamedResources ? [ResourceType.Generic, ResourceType.Named] : [ResourceType.Generic]
                                }
                                ExcludeInactive
                            />
                            {view && !toggleIsChecked && (
                                <div id="forward-request-container" className="forward-request-container">
                                    <PrimaryButton
                                        text={language.ForwardRequestDialog.ForwardEverything}
                                        onClick={matchCell}
                                        styles={{ root: { marginBottom: '10px' } }}
                                    />
                                    <Table
                                        Name=""
                                        Model={view}
                                        ContainerClassName="forward-request"
                                        HideTopRow
                                        CellUpdates={[
                                            (
                                                cell: Cell,
                                                columnIndex: number,
                                                value: number,
                                                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                                            ) => {
                                                const updatedModel = OnCellUpdate(columnIndex, 0, cell, value, event);
                                                setView({ ...updatedModel });
                                            },
                                        ]}
                                        CellEditing={(value: boolean) => setEditingCell(value)}
                                        DragHighlighterUpdate={DragUpdates()}
                                        DragIdPrefix="-fr"
                                        DragContainerClass=""
                                    />
                                </div>
                            )}
                        </PFErrorBoundary>
                    )}
                </div>
            </PFDialog>
        )
    );
};

export default WithStore<IProps>(ForwardRequestDialog);
