// import { Project } from "../Entities/Main/Project";
import { Plugin } from '../Services/PluginInvoker';
import { PluginIDs } from './PluginIDs';
import { OverviewContext } from './Context/OverviewContext';
import { Project, Resource } from '../api/generated/data-contracts';

// export class ResourceEx {
// 	// public static RbsMatch = (rbs: string) : boolean => {
// 	// 	return UserContext.Resource.RBS === rbs
// 	// 			|| (UserContext.Resource.AdditionalRBS && UserContext.Resource.AdditionalRBS.findIndex(_ => _ === rbs) >= 0);
// 	// }

// 	public static ProjectMatch = async (project: Project) : Promise<boolean> => {
// 		return UserContext.Resource.id === project.Owner
// 				// check for direct owner
// 				|| (project.AdditionalOwners && project.AdditionalOwners.findIndex(_ => _ === UserContext.Resource.id) >= 0)
// 				// check for owner through AAD group
// 				|| (OverviewContext.Settings.EnableProjectOwnerADGroup && project.AdditionalOwnersGroup != null && await Plugin.Invoke<boolean>(PluginIDs.IsCurrentResourceInOwnerGroup, project.id));
// 	}
// }

export async function projectMatch(project: Project, resource: Resource): Promise<boolean> {
    return (
        resource.id === project.owner ||
        // check for direct owner
        (project.additionalOwners &&
            project.additionalOwners.findIndex(_ => {
                // return _ === UserContext.Resource.id;
                return _ === resource.id;
            }) >= 0) ||
        // check for owner through AAD group
        (OverviewContext.Settings.EnableProjectOwnerADGroup &&
            project.additionalOwnersGroup != null &&
            (await Plugin.Invoke<boolean>(PluginIDs.IsCurrentResourceInOwnerGroup, project.id)))
    );

    // return UserContext.Resource.id === project.Owner
    // 	// check for direct owner
    // 	|| (project.AdditionalOwners && project.AdditionalOwners.findIndex(_ => _ === UserContext.Resource.id) >= 0)
    // 	// check for owner through AAD group
    // 	|| (OverviewContext.Settings.EnableProjectOwnerADGroup && project.AdditionalOwnersGroup != null && await Plugin.Invoke<boolean>(PluginIDs.IsCurrentResourceInOwnerGroup, project.id));
}

export const ResourceEx = {
    projectMatch,
};
