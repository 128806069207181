import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyFilter } from "../../Utilities/PropertyInfo/PropertyFilter";

export class ContractVersionRetention {
	@PFPropertyBasicInfo(PropertyType.Number, "_localization:Common.Day", 1, "_localization:ContractVersionRetention.NumberOfVersionOfResolutionToKeep".replace('[[resolution]]', "_localization:Common.Day"))
	@PFPropertyFilter({FilterLevel: 1})
	public Day: number;
	@PFPropertyBasicInfo(PropertyType.Number, "_localization:Common.Week", 1,"_localization:ContractVersionRetention.NumberOfVersionOfResolutionToKeep".replace('[[resolution]]', "_localization:Common.Week"))
	@PFPropertyFilter({FilterLevel: 1})
	public Week: number;
	@PFPropertyBasicInfo(PropertyType.Number, "_localization:Common.Month", 1, "_localization:ContractVersionRetention.NumberOfVersionOfResolutionToKeep".replace('[[resolution]]', "_localization:Common.Month"))
	@PFPropertyFilter({FilterLevel: 1})
	public Month: number;
}