import { useMemo, useState } from 'react';
import { ProjectListItem, RootSettings } from '../../../../../../api/generated/data-contracts';
import { TFilterListButton, TFilterListRbs } from '../../components/FilterList/FilterList';
import { resolveProjectFieldName } from '../helpers';
import { functionThatReturnsTrue } from '../../../../../../helpers/functionPrimitives';
import { BreadcrumbGroupTitle, TFilter } from '../../types';
import { useEvent } from '../../../../../../hooks/useEvent';

type TuseProjectCategoriesProps = {
    data: ProjectListItem[];
    settings: RootSettings;
    filterLevel: number;
    panelGroupings: string[];
    filters: TFilter[];
    breadcrumbTitles: BreadcrumbGroupTitle[];
    updateFilterState: (grouping: string, filterFn: (resource: ProjectListItem) => boolean, breadcrumbText: string, filterDirection?: number) => void;
};

export const useCurrentProjectCategory = ({
    data,
    settings,
    filterLevel,
    panelGroupings,
    breadcrumbTitles,
    filters,
    updateFilterState,
}: TuseProjectCategoriesProps) => {
    const [currentRBSPath, setCurrentRBSPath] = useState(() => {
        const existingFilterIndex = filters.findIndex(filter => filter.type === 'rbs');
        if (existingFilterIndex !== -1) {
            return breadcrumbTitles[existingFilterIndex].title;
        }
        return '';
    });

    const resetCurrentRBSPath = useEvent(() => {
        setCurrentRBSPath(() => {
            const existingFilterIndex = filters.findIndex(filter => filter.type === 'rbs');
            if (existingFilterIndex !== -1) {
                return breadcrumbTitles[existingFilterIndex].title;
            }
            return '';
        });
    });
    const categories = useMemo(() => {
        return panelGroupings.map((grouping, groupingIndex) => {
            const customPropName = 'customproperties';
            const virtualPropName = 'virtualproperty';
            const lowerCaseGrouping = grouping.toLowerCase();
            const isCustom = lowerCaseGrouping.startsWith(customPropName);
            const isVirtual = lowerCaseGrouping.startsWith(virtualPropName);
            const allTitle = `All ${resolveProjectFieldName(grouping, settings) || grouping}`;
            if (grouping === 'rbs') {
                return [
                    {
                        key: `all-${grouping}`,
                        title: 'All',
                        type: 'button',
                        onClick: () => {
                            setCurrentRBSPath('');
                            updateFilterState(grouping, functionThatReturnsTrue, allTitle);
                        },
                    },
                    {
                        type: 'rbs',
                        title: 'rbs',
                        key: 'rbs',
                        // setCurrentFilterLevel,
                        currentPath: currentRBSPath,
                        onChange: (newRbsPath: string) => {
                            setCurrentRBSPath(newRbsPath);
                            updateFilterState(grouping, newRbsPath ? res => res.rbsAsString?.startsWith(newRbsPath) : functionThatReturnsTrue, allTitle, 1);
                        },
                    },
                ] as (TFilterListRbs | TFilterListButton)[];
            }
            if (isVirtual) {
                return (settings.alignContractStatuses || []).reduce(
                    (acc, contractStatusSetting) => {
                        const { id, name } = contractStatusSetting;
                        const existsInProject = data.some(project => project.contractStatuses?.includes(id));
                        if (existsInProject) {
                            const title = name;
                            acc.push({
                                type: 'button',
                                title,
                                key: title,
                                onClick: () => {
                                    updateFilterState(grouping, (res: ProjectListItem) => res.contractStatuses?.includes(id), title);
                                },
                            });
                        }
                        return acc;
                    },
                    [
                        {
                            key: `all-${grouping}`,
                            title: 'All',
                            type: 'button',
                            onClick: () => {
                                updateFilterState(grouping, functionThatReturnsTrue, allTitle);
                            },
                        },
                    ] as TFilterListButton[],
                );
            }
            return data.reduce(
                (acc, project) => {
                    if (isCustom && project.customPropertiesAsString) {
                        const customPropGuid = grouping.slice(customPropName.length + 1);
                        const title = project.customPropertiesAsString[customPropGuid];
                        if (!title || acc.some(existing => existing.title === title)) {
                            return acc;
                        }
                        acc.push({
                            type: 'button',
                            title,
                            key: title,
                            onClick: () => {
                                updateFilterState(grouping, res => res.customPropertiesAsString?.[customPropGuid] === title, title);
                            },
                        });
                    } else {
                        const title = project[grouping + 'AsString'];
                        if (!title || acc.some(existing => existing.title === title)) {
                            return acc;
                        }
                        acc.push({
                            type: 'button',
                            title,
                            key: title,
                            onClick: () => {
                                updateFilterState(grouping, res => res?.[grouping + 'AsString'] === title, title);
                            },
                        });
                    }

                    return acc;
                },
                [
                    {
                        key: `all-${grouping}`,
                        title: 'All',
                        type: 'button',
                        onClick: () => {
                            updateFilterState(grouping, functionThatReturnsTrue, allTitle);
                        },
                    },
                ] as TFilterListButton[],
            );
        });
    }, [currentRBSPath, data, panelGroupings, settings, updateFilterState]);

    const currentCategory = useMemo(() => {
        return categories[filterLevel - 1] || [];
    }, [categories, filterLevel]);

    const sortedCategories = useMemo(() => {
        return currentCategory.sort((a, b) => {
            if (a.title === 'All' || b.title === 'All') {
                return 1;
            }
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        });
    }, [currentCategory]);

    return { currentCategory: sortedCategories, resetCurrentRBSPath };
};
