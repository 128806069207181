import { Text, ITextStyles } from "@fluentui/react/lib/Text";
import { useTheme } from '../../../Themes/themeContext';

interface IProps {
	stringValue: string;
	bgColor: string;
	cellId: string
}

const DisabledTableCell: React.FC<IProps> = (props) => {
	const theme = useTheme();
	
	const containerStyles: ITextStyles = { root: {
		marginLeft: 2,
		marginRight: 2,
		padding: 1,
		width: "var(--cellWidth)",
		height: 30
	}};

	const fieldStyles: ITextStyles = { root: {
		backgroundColor: props.bgColor,
		color: theme.semanticColors.disabledText,
		textAlign: "right",
		paddingRight: 8,
		display: "inline-block",
		height: 28,
		lineHeight: 28,
		width: "100%",
		borderRadius: theme.effects.roundedCorner2
	}};

	return (
		<Text className="tp-capacity-cell" styles={containerStyles}>
			<Text as="div" styles={fieldStyles} data-cellid={props.cellId}>
				{!props.stringValue ? null : props.stringValue}
			</Text>
		</Text>
	)
};

export default DisabledTableCell;