import { useEffect, useState } from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Text } from '@fluentui/react';
import { CountDownConfirm } from '../../Services/CountDownConfirm';
import { language } from '../../Services/LocalizationService';

class CountDownDialogInfo {
    constructor(public Title: string, public SubText: string, public Count: number, public Callback: () => void, public DismissCallback: () => void) {}
}

export const CountDownDialog = () => {
    const [info, setInfo] = useState<CountDownDialogInfo>(null);
    useEffect(
        () =>
            CountDownConfirm.Instance.Register((title, subtext, count, callback, dismiss) =>
                setInfo(new CountDownDialogInfo(title, subtext, count, callback, dismiss)),
            ),
        [],
    );
    useEffect(() => setCount(info?.Count), [info]);

    const [count, setCount] = useState<number>(null);

    useEffect(() => {
        if (count == null || count < 0) return;

        // exit when we reach 0
        if (count === 0) {
            info.Callback();
            setInfo(null);
            return;
        }

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setCount(count - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    return (
        info && (
            <Dialog
                hidden={false}
                onDismiss={() => window.location.reload()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: info.Title,
                    subText: info.SubText,
                    showCloseButton: false,
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { width: 450, textAlign: 'center' } },
                }}
            >
                <Text variant="xxLarge">{count}</Text>
                <DialogFooter>
                    <DefaultButton
                        onClick={() => {
                            info.Callback();
                            setInfo(null);
                        }}
                        text={language.CountDownDialog.IKnowWhatImDoing}
                    />
                    <PrimaryButton
                        onClick={() => {
                            info.DismissCallback();
                            setInfo(null);
                        }}
                        text={language.CountDownDialog.Cancel}
                    />
                </DialogFooter>
            </Dialog>
        )
    );
};
