import { StateManager } from './StateManager';
import { ITableState } from '../Components/Overview/Table/Table';
import { GroupContainerRow } from '../Entities/Table/GroupContainerRow';

export class TableStateManager extends StateManager<ITableState> {
    public RefreshTabIndex = () => {
        // regular cells
        const cells = Array.from<HTMLElement>(document.querySelectorAll('.tp-capacity-cell:not(.customcolumn) input'));
        cells.forEach((_, idx) => (_.tabIndex = idx));
        // custom column cells
        const customCells = Array.from<HTMLElement>(document.querySelectorAll('.tp-capacity-cell.customcolumn input'));
        customCells.forEach((_, idx) => (_.tabIndex = idx + 10000));
    };

    public ForceRefreshCells = (onlyEditable: boolean) => {
        const view = this.Get(_ => _.Model);
        view.Groups.forEach(group => {
            if (group.TypeName === 'GroupRow') {
                group.Rows.forEach(row => {
                    row.CellRows.forEach(cr => {
                        cr.Cells.forEach(cell => {
                            if (onlyEditable && cell.Disabled) return;
                            cell.ReRender = true;
                        });
                    });
                });
            } else if (group.TypeName === 'GroupContainerRow') {
                (group as GroupContainerRow).Groups.forEach(g => {
                    g.Rows.forEach(row => {
                        row.CellRows.forEach(cr => {
                            cr.Cells.forEach(cell => {
                                if (onlyEditable && cell.Disabled) return;
                                cell.ReRender = true;
                            });
                        });
                    });
                });
            }
        });
    };
}
