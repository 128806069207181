import { fieldTypes, IFieldTypes } from "spark-forms";

export interface ITpFieldTypes extends IFieldTypes {
    // simple
	cron: string,
    color: string
	numberSlider: string;
	messageBar: string;
	textfieldCopyvalue: string;
	hyperlink: string;

	// complex
	tree: string;
	rbs: string;
	rbsWithMyRbsFilter: string;
	resource: string;
	resourceWithMeFilter: string;
	project: string;
	entityProperty: string;
	aadGroup: string;
	periodSpan: string;
	dateWithTodayFilter: string;
	json: string;
	richText: string;
	predefinedArrayFilter: string
}

export const tpFieldTypes: ITpFieldTypes = {
    // default
	...fieldTypes,

	// simple
    cron: "cron",
    color: "color",
	numberSlider: "numberSlider",
	messageBar: "messageBar",
	textfieldCopyvalue: "textfieldCopyvalue",
	hyperlink: "hyperlink",

	// complex
	tree: "tree",
	rbs: "rbs",
	rbsWithMyRbsFilter: "rbsWithMyRbsFilter",
	resource: "resource",
	resourceWithMeFilter: "resourceWithMeFilter",
	project: "project",
	entityProperty: "entityProperty",
	aadGroup: "aadGroup",
	periodSpan: "periodSpan",
	dateWithTodayFilter: "dateWithTodayFilter",
	json: "json",
	richText: "richText",
	predefinedArrayFilter: "predefinedArrayFilter"
}