import { FunctionComponent, useState, useEffect } from 'react';
import { TextField, Toggle, Label, Link, IPalette, IconButton } from '@fluentui/react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { IThemesObj } from '../../../Themes/themeContext';
import { IExtendedTheme, IExtendedCustomizations } from '../../../Themes/Theme.types';
import { ObjectEditor } from '../ObjectEditor';
import { CustomThemeProps } from '../../../Themes/EditorModels/CustomThemeProps';
import { FluentUIThemeProps } from '../../../Themes/EditorModels/FluentUIThemeProps';
import { createCustomizations } from '../../../Themes/CreateCustomization';
import { language } from '../../../Services/LocalizationService';
import { useStore } from '../../../context/store';

interface IProps extends IDynamicEditorComponentProps {
    Item: IThemesObj;
    Update: (value: IThemesObj) => void;
    Key?: string;
}

const ThemeDesigner: FunctionComponent<IProps> = props => {
    const error = useStore(store => store.addErrorNotification);
    const [collapseFluentSettings, setCollapseFluentSettings] = useState<boolean>(true);
    const [collapseCssSettings, setCollapseCssSettings] = useState<boolean>(true);
    const [showAdvanced, setAdvancedEnabled] = useState<boolean>(props.Item ? props.Item.useAdvanced : false);

    const [theme, setTheme] = useState<IThemesObj>(props.Item);
    const [paletteString, setPaletteString] = useState<string>();
    const [customCss, setCustomCss] = useState<string>(props.Item ? props.Item.CssOverrides : null);
    const [customThemeProps, setCustomThemeProps] = useState<CustomThemeProps>(new CustomThemeProps());

    const [advancedThemeString, setAdvancedThemeString] = useState<string>();
    const [advancedCustomizationsString, setAdvancedCustomizationsString] = useState<string>();
    const [topBarAdvancedString, setTopBarAdvancedString] = useState<string>();
    const [topBarAdvancedCustomizationsString, setTopBarAdvancedCustomizationsString] = useState<string>();

    useEffect(() => {
        if (theme == null) {
            const themesObj = { theme: { palette: new FluentUIThemeProps(), semanticColors: new CustomThemeProps() } as IExtendedTheme } as IThemesObj;
            setTheme(themesObj);
            setPaletteString(GetOnlyBasicProps(themesObj.theme.palette));
        } else if (theme.useAdvanced) ToggleAdvanced(null, true);
        else {
            setPaletteString(GetOnlyBasicProps(theme.theme.palette));
            const customProps = new CustomThemeProps();
            Object.keys(theme.theme.semanticColors).forEach(_ => {
                if (Object.hasOwn(customProps, _)) customProps[_] = theme.theme.semanticColors[_];
            });
            setCustomThemeProps(customProps);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GetOnlyBasicProps = (palette: IPalette): string => {
        const obj = new FluentUIThemeProps();
        Object.keys(obj).forEach(_ => {
            if (Object.hasOwn(obj, _)) obj[_] = palette[_];
        });
        return JSON.stringify(obj, null, '\t');
    };

    const ChangePalette = (value: string): void => {
        const clone = { ...theme };
        // validate and error gracefully
        try {
            clone.theme.palette = JSON.parse(value) as IPalette;
        } catch {
            error('Failed parsing palette!');
            setPaletteString(value);
            return;
        }
        setTheme(clone);
        props.Update(clone);
    };

    const ChangeCustom = (prop: string, value: string): void => {
        const clone = { ...theme };
        clone.theme.semanticColors[prop] = value;
        setTheme(clone);
        props.Update(clone);
    };

    const ChangeCustomCss = (value: string): void => {
        const clone = { ...theme };
        clone.CssOverrides = value;
        setTheme(clone);
        props.Update(clone);
    };

    const ChangeAdvancedTheme = (prop: string, value: string): void => {
        const clone = { ...theme };
        // validate and error gracefully
        try {
            clone.theme[prop] = JSON.parse(value) as Partial<IExtendedTheme>;
        } catch {
            error('Failed parsing theme!');
            return;
        }
        setTheme(clone);
        props.Update(clone);
    };

    const ChangeAdvancedCustomizations = (prop: string, value: string): void => {
        const clone = { ...theme };
        // validate and error gracefully
        try {
            clone.theme[prop] = JSON.parse(value) as Partial<IExtendedCustomizations>;
        } catch {
            error('Failed parsing customization!');
            return;
        }
        setTheme(clone);
        props.Update(clone);
    };

    const ToggleAdvanced = (event: any, checked: boolean) => {
        setAdvancedEnabled(checked);
        const advancedNotSet = theme.customizations == null || theme.topbarTheme == null || theme.topbarCustomizations == null;

        const customize = advancedNotSet ? createCustomizations(theme) : null;

        const clone = { ...theme };
        clone.theme = advancedNotSet ? customize.customization.settings.theme : theme.theme;
        clone.customizations = advancedNotSet ? customize.customization : theme.customizations;
        clone.topbarTheme = advancedNotSet ? customize.topbarCustomization.settings.theme : theme.topbarTheme;
        clone.topbarCustomizations = advancedNotSet ? customize.topbarCustomization : theme.topbarCustomizations;

        setAdvancedThemeString(JSON.stringify(clone.theme, null, '\t'));
        setAdvancedCustomizationsString(JSON.stringify(clone.customizations, null, '\t'));
        setTopBarAdvancedString(JSON.stringify(clone.topbarTheme, null, '\t'));
        setTopBarAdvancedCustomizationsString(JSON.stringify(clone.topbarCustomizations, null, '\t'));

        clone.useAdvanced = checked;
        setTheme(clone);
        props.Update(clone);
    };

    return (
        <>
            {theme && (
                <>
                    <Toggle label={language.ThemeDesigner.UseAdvancedSettings} checked={showAdvanced} onChange={ToggleAdvanced} />
                    {!showAdvanced ? (
                        <>
                            <div style={{ display: 'flex' }}>
                                <IconButton
                                    iconProps={{ iconName: collapseFluentSettings ? 'ChevronRight' : 'ChevronDown' }}
                                    className="tp-grouping-header-icon"
                                    onClick={() => setCollapseFluentSettings(!collapseFluentSettings)}
                                />
                                <h3 className="tp-grouping-header noselect" onClick={() => setCollapseFluentSettings(!collapseFluentSettings)}>
                                    Fluent UI settings
                                </h3>
                            </div>
                            <div
                                className={
                                    collapseFluentSettings ? 'tp-grouping-content tp-grouping-collapsible tp-grouping-content-hidden' : 'tp-grouping-content'
                                }
                            >
                                <Label>
                                    {`${language.ThemeDesigner.GoTo}:`}
                                    <Link href="https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/" target="blank">
                                        Fluent UI Designer
                                    </Link>{' '}
                                    to design and export a palette. Then insert it here!
                                </Label>
                                <TextField
                                    multiline={true}
                                    value={paletteString}
                                    onChange={(event, value: string) => setPaletteString(value)}
                                    onBlur={event => ChangePalette(paletteString)}
                                    autoAdjustHeight
                                />
                            </div>

                            <div style={{ display: 'flex' }}>
                                <IconButton
                                    iconProps={{ iconName: collapseFluentSettings ? 'ChevronRight' : 'ChevronDown' }}
                                    className="tp-grouping-header-icon"
                                    onClick={() => setCollapseCssSettings(!collapseCssSettings)}
                                />
                                <h3 className="tp-grouping-header noselect" onClick={() => setCollapseCssSettings(!collapseCssSettings)}>
                                    Custom CSS overrides
                                </h3>
                            </div>
                            <div
                                className={
                                    collapseCssSettings ? 'tp-grouping-content tp-grouping-collapsible tp-grouping-content-hidden' : 'tp-grouping-content'
                                }
                            >
                                <TextField
                                    multiline={true}
                                    value={customCss}
                                    onChange={(event, value: string) => setCustomCss(value)}
                                    onBlur={event => ChangeCustomCss(customCss)}
                                    autoAdjustHeight
                                />
                            </div>

                            <ObjectEditor
                                ItemType={CustomThemeProps}
                                Item={customThemeProps}
                                HiddenProperties={[]}
                                DisabledProperties={[]}
                                ChangesCallback={(item: CustomThemeProps, prop: string, value: string) => ChangeCustom(prop, value)}
                            />
                        </>
                    ) : (
                        <>
                            <Label>{language.ThemeDesigner.AdvancedEditDoesNotMakeSemanticColors}</Label>
                            <TextField
                                label={language.Common.Theme}
                                multiline={true}
                                value={advancedThemeString}
                                onChange={(event, value: string) => setAdvancedThemeString(value)}
                                onBlur={event => ChangeAdvancedTheme('theme', advancedThemeString)}
                            />
                            <TextField
                                disabled
                                label={language.ThemeDesigner.Customizations}
                                multiline={true}
                                value={advancedCustomizationsString}
                                onChange={(event, value: string) => setAdvancedCustomizationsString(value)}
                                onBlur={event => ChangeAdvancedCustomizations('customizations', advancedCustomizationsString)}
                            />
                            <TextField
                                label={language.ThemeDesigner.TopbarTheme}
                                multiline={true}
                                value={topBarAdvancedString}
                                onChange={(event, value: string) => setTopBarAdvancedString(value)}
                                onBlur={event => ChangeAdvancedTheme('topbarTheme', topBarAdvancedString)}
                            />
                            <TextField
                                disabled
                                label={language.ThemeDesigner.TopbarCustomizations}
                                multiline={true}
                                value={topBarAdvancedCustomizationsString}
                                onChange={(event, value: string) => setTopBarAdvancedCustomizationsString(value)}
                                onBlur={event => ChangeAdvancedCustomizations('topbarCustomizations', topBarAdvancedCustomizationsString)}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default ThemeDesigner;
