import { ProjectMinifiedWithStringVariants, ResourceMinifiedWithStringVariants } from '../../../../../../api/generated/data-contracts';
import { QueryOperator } from '../../../../../../Entities/DynamicQueryBuilder/QueryOperator';
import { QueryCondition } from '../../../../../../Entities/DynamicQueryBuilder/QueryCondition';

export type QueryPart =
    | {
          operator: QueryOperator;
          property: string;
          typeName: string;
          value: string;
      }
    | QueryCondition;


export type QueryGroup = {
    parts?: QueryPart[];
};

export function createQueryGroup(selectedItems: (ResourceMinifiedWithStringVariants | ProjectMinifiedWithStringVariants)[]) {
    const lastIndex = selectedItems.length - 1;
    return {
        typeName: 'QueryGroup',
        parts: selectedItems.reduce((acc, item, i) => {
            if (i === lastIndex) {
                acc.push({
                    operator: QueryOperator.Equals,
                    property: 'id',
                    typeName: 'QueryExpression',
                    value: item.id,
                });
            } else {
                acc.push(
                    {
                        operator: QueryOperator.Equals,
                        property: 'id',
                        typeName: 'QueryExpression',
                        value: item.id,
                    },
                    QueryCondition.Or,
                );
            }
            return acc;
        }, [] as QueryPart[]),
    };
}
