import { guid } from "../../helpers/guid";
import { RecurrenceRule } from "../Recurrence/RecurrenceRule";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { PFPropertyOnChange } from "../../Utilities/PropertyInfo/OnChange";
import { RecurrenceFrequency } from "../../Entities/Recurrence/RecurrenceFrequency";
import { PFPropertyValidation } from "../../Utilities/PropertyInfo/Validation";
import { validationService } from "../../Services/ValidationService";
import { IObjectEditorItemType } from "./IObjectEditorItemType";
import { DateEx } from "../../Utilities/DateEx";

export class Rule implements IObjectEditorItemType {
	
	public Id: string;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.Name", 1)
	@PFPropertyValidation("_localization:Rule.RuleMustBeNamed", (obj: Rule) => obj.Name !== null && obj.Name !== "", undefined, () => true)
	public Name: string;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Rule.Recurring", 2, "_localization:Rule.RecurringDescription")
	public Recurring: boolean;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Start", 3, "_localization:Rule.StartDescription")
	@PFPropertyOnChange((obj: Rule) => obj.RecurrenceRule.ActiveFrom = obj.Start as Date)
	@PFPropertyRender(undefined, undefined, undefined, (obj: Rule) => obj.Recurring)
	@PFPropertyValidation("_localization:Rule.StartMustBeSpecified", (obj: Rule) => !obj.Recurring ? obj.Start !== undefined : true, undefined, () => true)
	public Start?: Date;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.End", 4, "_localization:Rule.EndDescription")
	@PFPropertyOnChange((obj: Rule) => obj.RecurrenceRule.ActiveTo = obj.End)
	@PFPropertyRender(undefined, undefined, undefined, (obj: Rule) => obj.Recurring)
	@PFPropertyValidation("_localization:Rule.EndMustBeSpecified", (obj: Rule) => !obj.Recurring ? obj.End !== undefined : true, undefined, () => true)
	public End?: Date;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:Rule.IncludeWeekends", 5, "_localization:Rule.IncludeWeekendsDescription")
	@PFPropertyRender(undefined, undefined, undefined, (obj: Rule) => obj.Recurring)
	public IncludeWeekends: boolean;
	@PFPropertyBasicInfo(PropertyType.Number, "_localization:Common.Work", 6, "_localization:Rule.WorkDescription")
	@PFPropertyValidation("_localization:Rule.WorkValidation", (obj: Rule) => obj.Work >= 0, undefined, () => true)
	public Work: number;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Rule.RecurrenceRuleSettings", 7)
	@PFPropertyRender((obj: Rule, idx, column: string) => obj.RecurrenceRule ? RecurrenceFrequency[obj.RecurrenceRule.Frequency].toString() : "None", { ItemType: RecurrenceRule, HiddenProperties: [] }, undefined, (obj: Rule) => !obj.Recurring)
	// @PFPropertyValidation("_localization:Rule.Invalidrule", (item: Rule) => item.Recurring ? validationService.validateThis(RecurrenceRule, item.RecurrenceRule, true) : true)
	// TODO Re-enable notifications
	@PFPropertyValidation("_localization:Rule.Invalidrule", (item: Rule) => item.Recurring ? validationService.validateThis(RecurrenceRule, item.RecurrenceRule) : true)
	public RecurrenceRule: RecurrenceRule;

	// Marker properties used to combine start and end from rule and recurring - to be displayed in objectlisteditor columns
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Rule.ValidFrom", 7)
	@PFPropertyRender((obj: Rule, idx, column: string) => DateEx.asUserLocal(obj.Recurring ? obj.RecurrenceRule.ActiveFrom : obj.Start as Date), undefined, undefined, (obj: Rule) => true)
	public ValidFrom?: Date;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Rule.ValidTo", 7)
	@PFPropertyRender((obj: Rule, idx, column: string) => DateEx.asUserLocal(obj.Recurring ? obj.RecurrenceRule.ActiveTo as Date : obj.End as Date), undefined, undefined, (obj: Rule) => true)
	public ValidTo?: Date;

    constructor() {
		this.Id = guid.newGuid();
		this.RecurrenceRule = new RecurrenceRule();
    }
}