import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useCurrentProject } from '../../context/network/http/QueryProvider/queries/project';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { useTPPermissions } from '../../hooks/usePermissions';
import { ReportEx } from '../../Utilities/ReportEx';

export const ReportViewer = () => {
    const { reportId } = useParams<{ reportId: string }>();
    const { data: uiSettings } = useUISettings();
    const userResourceId = uiSettings.resource.id;
    const { projectId } = useParams<{ projectId: string }>();
    const { data: project } = useCurrentProject(projectId);
    const currentReport = useMemo(() => {
        return uiSettings.settings.reports?.find(report => report.id === reportId);
    }, [reportId, uiSettings.settings.reports]);

    const isPermittedToView = useTPPermissions({ userTypes: currentReport?.userTypes || [], some: true });

    const reportUrl = useMemo(() => {
        if (!isPermittedToView || !currentReport) {
            return '';
        }
        const url = ReportEx.buildUrl(currentReport, project?.id, userResourceId);
        if (!url.startsWith('https://')) {
            return 'https://' + url;
        }
        return url;
    }, [currentReport, isPermittedToView, project?.id, userResourceId]);

    if (!isPermittedToView) {
        return (
            <div style={{ height: '100%' }}>
                <h2>You do not have permissions to view {currentReport?.name || 'this report'}</h2>
            </div>
        );
    }

    return (
        <div style={{ height: 'calc(100vh - 44px)' }}>
            {currentReport ? (
                <>
                    <h2>{currentReport.name}</h2>
                    <iframe
                        title={currentReport.name}
                        width="100%"
                        height="calc(100vh - 44px - 44px)"
                        src={reportUrl}
                        allowFullScreen={true}
                        style={{ height: 'calc(100vh - 44px - 44px)' }}
                    ></iframe>
                </>
            ) : (
                <div>Cannot find report with id: {reportId}</div>
            )}
        </div>
    );
};
