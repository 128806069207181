import { FunctionComponent, useRef } from 'react';
import { IDynamicEditorComponentProps } from './DynamicEditorComponent';
import { ObjectEditor } from '../ObjectEditor';

interface IProps extends IDynamicEditorComponentProps {
    ItemType: { new (): any };
    Key?: string;
}

const DynamicEditor: FunctionComponent<IProps> = props => {
    const item = useRef<any>({ ...(props.Item || (new props.ItemType() as any)) });

    return (
        <div key={props.Key} style={{ paddingLeft: '20px' }}>
            <ObjectEditor
                Item={item.current}
                ItemType={props.ItemType}
                HiddenProperties={[]}
                DisabledProperties={[]}
                ReadOnly={props.ReadOnly}
                ChangesCallback={(item, property, value) => props.Update(item)}
            />
        </div>
    );
};

export default DynamicEditor;
