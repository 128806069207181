/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { IDropdownOption, Dropdown, ISelectableOption, IDropdownStyleProps, IDropdownStyles, IStyleFunctionOrObject } from '@fluentui/react';
import PFDropDownSearchComponent, { CreatePFDropDownFilteredItems, FakeSearchHeaderItem } from './PFDropDownSearchComponent';
import { ArrayEx } from '../../Utilities/ArrayEx';

interface IProps<T> {
    Options: Array<T>;
    Multiselect?: boolean;
    Searchable?: boolean;
    SelectedKey?: string | number;
    SelectedKeys?: Array<string> | Array<number>;
    OnChange?: (event: React.FormEvent<HTMLDivElement>, allItems: Array<T>, option?: IDropdownOption, index?: number) => void;
    Key?: string;
    Readonly?: boolean;
    MultiselectDelimiter?: string;
    Styles?: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
    Placeholder?: string;
    ClassName?: string;
}

const PFDropdown: FunctionComponent<IProps<any>> = props => {
    const [items, setItems] = useState<Array<any>>(props.Options ?? []);
    const [filteredItems, setFilteredItems] = useState<Array<any>>(
        CreatePFDropDownFilteredItems(items, FakeSearchHeaderItem, props.Searchable, props.Readonly),
    );

    useEffect(() => {
        setItems(props.Options ?? []);
        setFilteredItems(CreatePFDropDownFilteredItems(props.Options ?? [], FakeSearchHeaderItem, props.Searchable, props.Readonly));
    }, [props.Options]);

    return (
        <>
            <Dropdown
                key={props.Key}
                placeholder={props.Placeholder}
                disabled={props.Readonly}
                multiSelect={props.Multiselect}
                multiSelectDelimiter={props.MultiselectDelimiter ?? ' | '}
                options={filteredItems}
                defaultSelectedKey={props.Multiselect ? undefined : props.SelectedKey}
                defaultSelectedKeys={props.Multiselect ? props.SelectedKeys || [] : undefined}
                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => props.OnChange(event, items, option, index)}
                onDismiss={() => setFilteredItems(CreatePFDropDownFilteredItems(items, FakeSearchHeaderItem, props.Searchable, props.Readonly))}
                calloutProps={{
                    directionalHintFixed: true,
                    setInitialFocus: true,
                    onLayerMounted: props.Searchable
                        ? () => {
                              // holy fuck how hard should it be to focus on first item!! grr fluent fuck!
                              // this is an ugly hack fo'sho'
                              // or maybe i'm just dumb!
                              setTimeout(() => {
                                  const group = document.querySelector('.tp-dropdown .ms-Dropdown-items div:first-child');
                                  group.classList.add('searchparent');
                                  const element = group.querySelector('.tp-searcher-pfdropdown input');
                                  if (!element) return;
                                  (element as any).focus();
                              }, 50);
                          }
                        : undefined,
                    className: `tp-dropdown ${props.ClassName}`,
                }}
                onRenderItem={(selectProps: ISelectableOption, defaultRender: (props?: ISelectableOption) => JSX.Element) => {
                    // use default render
                    if (!props.Searchable) return defaultRender(selectProps);
                    if (selectProps.key !== FakeSearchHeaderItem.key) return defaultRender(selectProps);
                    // if searcher (the fake header item) render search component
                    return (
                        <PFDropDownSearchComponent
                            Items={items}
                            Readonly={props.Readonly}
                            FilterCallback={items =>
                                setFilteredItems(CreatePFDropDownFilteredItems(items, FakeSearchHeaderItem, props.Searchable, props.Readonly))
                            }
                        />
                    );
                }}
                styles={props.Styles}
            />
        </>
    );
};

const comparisonFn = (prevProps: IProps<any>, nextProps: IProps<any>) => {
    return (
        (nextProps.Multiselect ? ArrayEx.simpleCompare(prevProps.SelectedKeys, nextProps.SelectedKeys) : prevProps.SelectedKey === nextProps.SelectedKey) &&
        ArrayEx.simpleCompare(prevProps.Options, nextProps.Options)
    );
};

export default memo(PFDropdown, comparisonFn);
