import { IRule } from "../workprofile/rule/IRule";

export const ruleUtil = {
	rulesOverlap: (rules: IRule[]) : { overlap: boolean, ranges: IRule[] } => {
		if (!rules) return {overlap: false, ranges: []};
		
		const sorted = rules.sort((previous, current) => {
			if (!previous.start || !current.start) return 0;
			// get the start date from previous and current
			const previousTime = new Date(previous.start).getTime();
			const currentTime = new Date(current.start).getTime();
			// if the previous is earlier than the current
			if (previousTime < currentTime) return -1;
			// if the previous time is the same as the current time
			if (previousTime === currentTime) return 0;
			// if the previous time is later than the current time
			return 1;
		});
		
		const result = sorted.reduce((result: { overlap: boolean, ranges: IRule[]}, current: IRule, idx: number, arr: IRule[]) => {
			// get the previous range
			if (idx === 0) return result;
			const previous = arr[idx-1];
			// check for null
			if (!previous.start || !current.start) return result;
			// check for any overlap
			const previousEnd = new Date(previous.end).getTime();
			const currentStart = new Date(current.start).getTime();
			const overlap = (previousEnd >= currentStart);
			// store the result
			if (overlap) {
				// yes, there is overlap
				result.overlap = true;
				// store the specific ranges that overlap
				result.ranges.push(...[previous, current ]);
			}
			return result;
		   	// seed the reduce  
		}, { overlap: false, ranges: [] });
		
		// return the final results  
		return result;
	}
}
