import { IFieldConfig, IFormItemFieldOptions, IFormSchema } from "spark-forms";
import { CustomProperty, CustomPropertyType } from "../../../../api/generated/data-contracts";

export interface IFieldGetterOptions {
	filterSystemProperties?: boolean;
	filterArrayProperties?: boolean;
	forcedIncludedFields?: string[];
	hiddenProperties?: string[];
	onlyTheseProperties?: string[];
}

export interface IField<T> {
	internalName: string;
	displayName: string;
	type: 'field' | 'custom';
	lookup?: string;
	options?: IFormItemFieldOptions<T, IFieldConfig<T>>;
}

const systemFields = [ "created", "modified", "createdBy", "modifiedBy", "origins", "originIds", "matcher" ];

const getFields = <T,>(schema: IFormSchema<T>, options?: IFieldGetterOptions) : IField<T>[] => {
	const { hiddenProperties, onlyTheseProperties, filterArrayProperties, forcedIncludedFields, filterSystemProperties } = options;
	const schemaFields = Object.entries(schema.fields);
	let standardFields: [string, IFormItemFieldOptions<T, IFieldConfig<T>>][];
	if (onlyTheseProperties) {
		standardFields = schemaFields.filter(([key, value]) => onlyTheseProperties.indexOf(key) >= 0)
	} else {
		standardFields = schemaFields // readability over performance (ewi)
							.filter(_ => _[0] !== 'customProperties')
							.filter(([, value]) => !filterArrayProperties || value.fieldType !== 'predefinedArray')
							.filter(([key, value]) => !filterSystemProperties || systemFields.indexOf(key) < 0 || value.config?.multiSelect)
							.filter(([key, value]) => !hiddenProperties || hiddenProperties.indexOf(key) < 0);
	}
	if (forcedIncludedFields?.length > 0) {
		forcedIncludedFields.forEach(field => {
			if (standardFields.find(([key,]) => key === field) === undefined) {
				const fieldToForce = schemaFields.find(([key,]) => key === field);
				standardFields.push(fieldToForce);
			}
		});
	}
	return standardFields.map(([key, value]) => ({ internalName: key, displayName: value.displayName, options: value, type: 'field' }));
}

const getCustomProperties = <T,>(customProperties: CustomProperty[], options?: IFieldGetterOptions) : IField<T>[] => {
	const { hiddenProperties, onlyTheseProperties, filterArrayProperties } = options;
	let custom = [];
	if (onlyTheseProperties) {
		custom = customProperties.filter(_ => onlyTheseProperties.indexOf(`customProperties.${_.id}`) >= 0)
	} else {
		custom = customProperties?.filter(_ => !filterArrayProperties || _.type !== CustomPropertyType.ChoiceMultiselect)
									.filter(_ => !hiddenProperties || hiddenProperties.indexOf(`customProperties.${_.id}`) < 0);
	}
	return custom.map(_ => ({ internalName: _.id, displayName: _.displayName, type: 'custom', lookup: _.lookup }));
}

export const schemaHelper = {
	getFields,
	getCustomProperties
}
