import React, { useState, useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import {
    Active,
    closestCenter,
    CollisionDetection,
    DragOverlay,
    DndContext,
    DropAnimation,
    MouseSensor,
    MeasuringConfiguration,
    PointerActivationConstraint,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
    defaultDropAnimationSideEffects,
    DragEndEvent,
    DragStartEvent,
} from '@dnd-kit/core';
import { arrayMove, useSortable, SortableContext, SortingStrategy, rectSortingStrategy, AnimateLayoutChanges, NewIndexGetter } from '@dnd-kit/sortable';
import { List } from './components/List/List';
import { Wrapper } from './components/Wrapper/Wrapper';
import { Item } from './components/Item/Item';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { useCustomColumnsValue, useSelectedCustomColumnKeysState } from '../../../../context/customColumnContext';

type Props = {
    activationConstraint?: PointerActivationConstraint;
    animateLayoutChanges?: AnimateLayoutChanges;
    adjustScale?: boolean;
    collisionDetection?: CollisionDetection;
    dropAnimation?: DropAnimation | null;
    getNewIndex?: NewIndexGetter;
    handle?: boolean;
    measuring?: MeasuringConfiguration;
    reorderItems?: typeof arrayMove;
    strategy?: SortingStrategy;
    useDragOverlay?: boolean;
    getItemStyles?(args: {
        id: UniqueIdentifier;
        index: number;
        isSorting: boolean;
        isDragOverlay: boolean;
        overIndex: number;
        isDragging: boolean;
    }): React.CSSProperties;
    wrapperStyle?(args: { active: Pick<Active, 'id'> | null; index: number; isDragging: boolean; id: UniqueIdentifier }): React.CSSProperties;
};

const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.5',
            },
        },
    }),
};

export function DraggableList({
    activationConstraint,
    animateLayoutChanges,
    adjustScale = false,
    collisionDetection = closestCenter,
    dropAnimation = dropAnimationConfig,
    getNewIndex,
    handle = false,
    measuring,
    reorderItems = arrayMove,
    strategy = rectSortingStrategy,
    useDragOverlay = true,
}: Props) {
    const items = useCustomColumnsValue();
    const [selectedKeys, setSelectedKeys] = useSelectedCustomColumnKeysState();

    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint,
        }),
        useSensor(TouchSensor, {
            activationConstraint,
        }),
    );

    const activeItems = useMemo(() => selectedKeys.map(key => items.find(item => item.key === key)), [items, selectedKeys]);
    const getIndex = useCallback((key: UniqueIdentifier) => activeItems.findIndex(item => item.key === key), [activeItems]);
    const activeIndex = useMemo(() => (activeId ? getIndex(activeId) : -1), [activeId, getIndex]);

    const handleToggle = useCallback(
        (key: string, state: boolean) => {
            setSelectedKeys(selectedKeys => (state ? [...selectedKeys, key] : selectedKeys.filter(selectedKey => selectedKey !== key)));
        },
        [setSelectedKeys],
    );

    const inactiveItems = useMemo(() => {
        return items.filter(item => !selectedKeys.includes(item.key));
    }, [items, selectedKeys]);

    const onDragEnd = useCallback(
        ({ over }: DragEndEvent) => {
            setActiveId(null);
            if (over) {
                const overIndex = getIndex(over.id);
                if (activeIndex !== overIndex) {
                    setSelectedKeys(keys => reorderItems(keys, activeIndex, overIndex));
                }
            }
        },
        [activeIndex, getIndex, reorderItems, setSelectedKeys],
    );

    const onDragStart = useCallback(({ active }: DragStartEvent) => {
        if (!active) {
            return;
        }
        setActiveId(active.id);
    }, []);

    const renderActiveItems = useMemo(() => {
        if (!activeItems.length) {
            return null;
        }
        return (
            <SortableContext items={activeItems.map(item => item.key)} strategy={strategy}>
                <Wrapper center fadeIn>
                    <List title="Active columns">
                        {activeItems.map(({ key, text }, index) => (
                            <SortableItem
                                key={key}
                                id={key}
                                handle={handle}
                                index={index}
                                animateLayoutChanges={animateLayoutChanges}
                                useDragOverlay={useDragOverlay}
                                getNewIndex={getNewIndex}
                                value={text}
                                onItemSelect={handleToggle}
                                checked={selectedKeys.includes(key)}
                            />
                        ))}
                    </List>
                </Wrapper>
            </SortableContext>
        );
    }, [activeItems, animateLayoutChanges, getNewIndex, handle, handleToggle, selectedKeys, strategy, useDragOverlay]);

    const renderInactiveItems = useMemo(() => {
        if (!inactiveItems.length) {
            return null;
        }
        return (
            <Wrapper center fadeIn>
                <List title="Inactive columns">
                    {inactiveItems.map(({ key, text }, index) => (
                        <Item
                            key={key}
                            id={key}
                            handle={handle}
                            index={index}
                            value={text}
                            onItemSelect={handleToggle}
                            checked={selectedKeys.includes(key)}
                            showOrder={false}
                        />
                    ))}
                </List>
            </Wrapper>
        );
    }, [handle, handleToggle, inactiveItems, selectedKeys]);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={collisionDetection}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragCancel={() => setActiveId(null)}
            measuring={measuring}
            modifiers={modifiers}
            autoScroll={false}
        >
            {renderActiveItems}
            {renderInactiveItems}
            {useDragOverlay
                ? createPortal(
                      <DragOverlay zIndex={9999999999999} adjustScale={adjustScale} dropAnimation={dropAnimation}>
                          {activeId ? <Item value={activeItems[activeIndex].text} handle={handle} dragOverlay checked={true} index={activeIndex} /> : null}
                      </DragOverlay>,
                      document.body,
                  )
                : null}
        </DndContext>
    );
}

const modifiers = [restrictToParentElement];

interface SortableItemProps {
    animateLayoutChanges?: AnimateLayoutChanges;
    disabled?: boolean;
    getNewIndex?: NewIndexGetter;
    id: UniqueIdentifier;
    index: number;
    handle: boolean;
    useDragOverlay?: boolean;
    value: string;
    onItemSelect: (key: string, state: boolean) => void;
    checked: boolean;
}

export function SortableItem({
    disabled,
    animateLayoutChanges,
    getNewIndex,
    handle,
    id,
    index,
    useDragOverlay,
    value,
    onItemSelect,
    checked,
}: SortableItemProps) {
    const {
        attributes,
        isDragging,
        isSorting,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
    } = useSortable({
        id,
        animateLayoutChanges,
        disabled,
        getNewIndex,
    });

    return (
        <Item
            ref={setNodeRef}
            value={value}
            disabled={disabled}
            dragging={isDragging}
            sorting={isSorting}
            handle={handle}
            handleProps={
                handle
                    ? {
                          ref: setActivatorNodeRef,
                      }
                    : undefined
            }
            index={index}
            transform={transform}
            transition={transition}
            listeners={listeners}
            dragOverlay={!useDragOverlay && isDragging}
            onItemSelect={onItemSelect}
            id={id as string}
            checked={checked}
            {...attributes}
        />
    );
}
