import { Handler, useDrag } from '@use-gesture/react';
import { useEffect, useMemo, useState, useLayoutEffect, useRef, useCallback } from 'react';
import { atom, useRecoilCallback, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { GridCell, GridRow } from '../../../../api/generated/data-contracts';
import { UpdateCellInfo, useGridContext } from '../contexts/gridContext';
import { mapMap } from '../helpers/map';
import { ErrorMessageGetterWithRoundingValue } from './types';
import { getScrollbarWidth } from '@fluentui/react';
import { createCellValueGetter, createCellValueSetter, useGridCellValue } from '../useGrid';
import { useGridRowContext } from '../contexts/rowContext';
import Big from 'big.js';
import { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types';
import { ImmutableRowsMap } from '../grid-types';

const scrollbarWidth = getScrollbarWidth();

type DraggableCellEntry<Checker extends ErrorMessageGetterWithRoundingValue = ErrorMessageGetterWithRoundingValue> = {
    cell: GridCell;
    restoreStoreValue: () => void;
    setLocalCellValue: (val: string) => void;
    inputRef: HTMLInputElement;
    onCellsUpdated?: () => void;
    // onGetUpdateCellErrorMessage?: Checker;
    onGetUpdateCellErrorMessage?: Checker;
    onError?: (err: string) => void;
} & Pick<UpdateCellInfo, 'cellIndex' | 'columnIndex' | 'rowId'>;

type DraggableCellRegitry = Map<string, DraggableCellEntry>;

const dragableCellRegistry = atom<DraggableCellRegitry>({
    key: 'draggable-cell-registry',
    default: new Map(),
});
export const useGridCellDragRegistryValue = () => useRecoilValue(dragableCellRegistry);

type DragBoxState = {
    top: number;
    left: number;
    height: number;
    width: number;
    visible: boolean;
    currentValue: Big | null;
};

const dragBoxStateAtom = atom<DragBoxState>({
    key: 'drag-box-position',
    default: {
        top: 0,
        left: 0,
        height: 0,
        width: 0,
        visible: false,
        currentValue: null,
    },
});
export const useDragBoxValue = () => useRecoilValue(dragBoxStateAtom);
export const useResetDragBoxState = () => useResetRecoilState(dragBoxStateAtom);

const currentScrollTargetAtom = atom<HTMLElement | null>({
    key: 'scroll-target',
    default: null,
});
export const useSetCurrentScrollTarget = () => useSetRecoilState(currentScrollTargetAtom);

type IntersectingCells = Map<string, DraggableCellEntry>;
const intersectingCellsAtom = atom<IntersectingCells>({
    key: 'intersecting-cells',
    default: new Map(),
});
export const useSetIntersectingCellsState = () => useSetRecoilState(intersectingCellsAtom);

const virtualMovementAtom = atom<[number, number]>({
    key: 'drag-virtual-movement',
    default: [0, 0],
});

export type TriggerMargins = {
    marginTop: number;
    marginBottom: number;
    marginLeft: number;
    marginRight: number;
};

const triggerMargin = 30;
const triggerMargins: TriggerMargins = {
    marginTop: triggerMargin,
    marginBottom: triggerMargin,
    marginLeft: triggerMargin,
    marginRight: triggerMargin,
};
const dragTriggerMarginsAtom = atom<TriggerMargins>({
    key: 'drag-trigger-margins',
    default: triggerMargins,
});

export const useDragTriggers = ({ marginBottom, marginLeft, marginRight, marginTop }: Partial<TriggerMargins> = {}) => {
    const set = useSetRecoilState(dragTriggerMarginsAtom);
    useEffect(() => {
        const triggers: TriggerMargins = { ...triggerMargins, marginBottom, marginLeft, marginRight, marginTop };
        set(triggers);
    }, [marginBottom, marginLeft, marginRight, set, marginTop]);
};

export type DragCellUpdaterInfo = {
    intersectingCells: DraggableCellEntry<ErrorMessageGetterWithRoundingValue>[];
    draggedCellValue: Big;
    getRowAncestorTreeToRoot: (rowId: string) => GridRow[];
    getCellValue: (cell: GridCell) => Promise<Big>;
	setCellValue: (cell: GridCell, valOrUpdater: Big | ((currentValue: Big) => Big)) => void;
	rows: ImmutableRowsMap;
	row: GridRow
};

export type DragCellUpdater = (info: DragCellUpdaterInfo) => Promise<UpdateCellInfo[]>;

export type useDragGridCellProps = Omit<DraggableCellEntry, 'restoreStoreValue'> & { cellUpdater?: DragCellUpdater };

type BoundaryX = 'left' | 'right' | null;
type BoundaryY = 'top' | 'bottom' | null;
type ScrollAction = [BoundaryX, BoundaryY] | null;

const scrollAmount = 15;

export const defaultDraggedCellUpdater: DragCellUpdater = async ({ intersectingCells, draggedCellValue, getRowAncestorTreeToRoot, getCellValue, row }) => {
    const updates: UpdateCellInfo[] = [];
    for (const { cell, cellIndex, columnIndex, rowId, onGetUpdateCellErrorMessage, onError } of intersectingCells) {
        let newValue = draggedCellValue;
        if (onGetUpdateCellErrorMessage) {
            const currentCellValue = await getCellValue(cell);
            const currentCellRowFamilyTree = getRowAncestorTreeToRoot(rowId);
            const { errorMessage, changedValue } = await onGetUpdateCellErrorMessage({
                cell,
                columnIndex,
                cellIndex,
                newCellValue: draggedCellValue,
                currentCellValue,
                rowFamilyTree: currentCellRowFamilyTree,
				row
            });
            if (errorMessage) {
                if (onError) {
                    onError(errorMessage);
                }
                continue;
            }
            newValue = changedValue;
        }
        updates.push({
            cell,
            cellIndex,
            columnIndex,
            rowId,
            newValue,
        });
    }
    return updates;
};

export const useDragGridCell = ({ cellUpdater = defaultDraggedCellUpdater, ...props }: useDragGridCellProps) => {
    const { inputRef } = props;
    const { updateCellValue, getRowAncestorTreeToRoot, getRowsMap } = useGridContext();
    const currentScrollTarget = useRecoilValue(currentScrollTargetAtom);
	const { row } = useGridRowContext()
    useHandleDraggableCellRegistration(props);

    const updateDraggedCells = useRecoilCallback(
        ({ snapshot, reset, set }) =>
            async () => {
                const intersectingCells = await snapshot.getPromise(intersectingCellsAtom);
                const { currentValue } = await snapshot.getPromise(dragBoxStateAtom);
                if (!intersectingCells.size || currentValue === null) {
                    // nothing to do here
                    return;
                }
                const getCellValue = createCellValueGetter(snapshot);
				const setCellValue = createCellValueSetter(set)
				const rows = getRowsMap()
                const updates = await cellUpdater({
                    draggedCellValue: currentValue,
                    getCellValue,
					setCellValue,
                    getRowAncestorTreeToRoot,
                    intersectingCells: [...intersectingCells.values()],
					rows,
					row
                });
                // Reset the box state before updating to prevent interfering with the snapshot work that updateCellValue does
                reset(dragBoxStateAtom);
                updateCellValue(updates)
                    .then(() => {
                        intersectingCells.forEach(({ onCellsUpdated }) => {
                            if (onCellsUpdated) {
                                onCellsUpdated();
                            }
                        });
                    })
                    .catch(err => {
                        console.error('Dragging error', err);
                    });
            },
        [cellUpdater, getRowAncestorTreeToRoot, getRowsMap, updateCellValue, row],
    );

    const [outOfBounds, setOutOfBounds] = useState<ScrollAction>(null);

    const scrollBounds = useRecoilCallback(
        ({ snapshot, set }) =>
            async ([x, y]: ScrollAction) => {
                if (currentScrollTarget) {
                    const boxState = await snapshot.getPromise(dragBoxStateAtom);
                    let dx: number | null = null;
                    let dy: number | null = null;
                    const { height, width, left, top } = boxState;
                    let newHeight = height;
                    let newWidth = width;
                    let newLeft = left;
                    let newTop = top;
                    const { endBottom, endLeft, endRight, endTop } = getScrollTargetState(currentScrollTarget);
                    if (x === 'right' && !endRight) {
                        dx = scrollAmount;
                        newLeft = left - dx;
                        newWidth = width + dx;
                    } else if (x === 'left' && !endLeft) {
                        dx = -scrollAmount;
                        newWidth = width - dx;
                    }
                    if (y === 'bottom' && !endBottom) {
                        dy = scrollAmount;
                        newTop = top - dy;
                        newHeight = height + dy;
                    } else if (y === 'top' && !endTop) {
                        dy = -scrollAmount;
                        newHeight = height - dy;
                    }
                    let [currentVmx, currentVmy] = await snapshot.getPromise(virtualMovementAtom);
                    if (dx !== null) {
                        currentScrollTarget.scrollLeft += dx;
                        currentVmx = currentVmx + dx;
                    }
                    if (dy !== null) {
                        currentVmy = currentVmy + dy;
                        currentScrollTarget.scrollTop += dy;
                    }
                    set(virtualMovementAtom, [currentVmx, currentVmy]);

                    set(dragBoxStateAtom, {
                        //
                        ...boxState,
                        height: newHeight,
                        width: newWidth,
                        top: newTop,
                        left: newLeft,
                    });
                }
            },
        [currentScrollTarget],
    );

    const dragState = useRef(false);

    const onDragHandler: Handler<'drag', PointerEvent | MouseEvent | TouchEvent | KeyboardEvent> = useRecoilCallback(
        ({ snapshot, set, reset }) =>
            async ({
                //
                down,
                delta: [dx, dy],
                xy: [x, y],
                dragging,
                args: [val],

            }) => {
                const value: string = val;
                if (!down || !dragging) {
                    dragState.current = false;
                    setOutOfBounds(null);
                    reset(virtualMovementAtom);
                    return updateDraggedCells();
                }
                dragState.current = true;
                const currentValue = new Big(value.replace(',', '.'));
                const currentPos = await snapshot.getPromise(dragBoxStateAtom);
                const [vmx, vmy] = await snapshot.getPromise(virtualMovementAtom);
                const movementX = vmx + dx;
                const movementY = vmy + dy;

                const { top: inputTop, left: inputLeft, width: inputWidth, height: inputHeight } = inputRef.getBoundingClientRect();
                const { width, height } = currentPos;
                let newWidth = !width ? inputWidth : width + dx;
                let newHeight = !height ? inputHeight : height + dy;
                let newTop = inputTop;
                let newLeft = inputLeft;

                if (movementX < 0) {
                    // User is dragging left from the drag point
                    newWidth = inputWidth;
                    const absMx = Math.abs(movementX);
                    if (absMx > inputWidth) {
                        // User is dragging left and are outside the input field
                        newLeft = inputLeft + movementX + inputWidth;
                        newWidth = absMx;
                    }
                }
                if (movementY < 0) {
                    // User is dragging up from the drag point
                    newHeight = inputHeight;
                    const absMy = Math.abs(movementY);
                    if (absMy > inputHeight) {
                        // User is dragging higher up than the input field
                        newTop = inputTop + movementY + inputHeight;
                        newHeight = absMy;
                    }
                }
                if (currentScrollTarget) {
                    const { right: elRight, left: elLeft, top: elTop, bottom: elBottom } = currentScrollTarget.getBoundingClientRect();
                    const { marginLeft } = await snapshot.getPromise(dragTriggerMarginsAtom);
                    const isOutOfBoundsRight = x >= elRight - triggerMargin;
                    const isOutOfBoundsLeft = x < marginLeft + elLeft;
                    const isOutOfBoundsBottom = y >= elBottom - triggerMargin;
                    const isOutOfBoundsTop = y < elTop + triggerMargin;
                    let outOfBoundsX: BoundaryX = null;
                    let outOfBoundsY: BoundaryY = null;
                    const { endBottom, endLeft, endRight, endTop } = getScrollTargetState(currentScrollTarget);
                    if (isOutOfBoundsRight && !endRight) {
                        outOfBoundsX = 'right';
                    }
                    if (isOutOfBoundsLeft && !endLeft) {
                        outOfBoundsX = 'left';
                    }
                    if (isOutOfBoundsBottom && !endBottom) {
                        outOfBoundsY = 'bottom';
                    }
                    if (isOutOfBoundsTop && !endTop) {
                        outOfBoundsY = 'top';
                    }
                    if (outOfBoundsX || outOfBoundsY) {
                        setOutOfBounds([outOfBoundsX, outOfBoundsY]);
                    } else {
                        setOutOfBounds(null);
                    }
                }

                set(virtualMovementAtom, [movementX, movementY]);
                set(dragBoxStateAtom, {
                    ...currentPos,
                    left: newLeft,
                    top: newTop,
                    width: newWidth,
                    height: newHeight,
                    currentValue,
                    visible: true,
                });
            },
        [currentScrollTarget, inputRef, updateDraggedCells],
    );

    useEffect(() => {
        // Make the element scroll as long as the user is dragging out of bounds
        if (outOfBounds) {
            const timer = setInterval(() => {
                scrollBounds(outOfBounds);
            }, 30);
            // }, 1000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [outOfBounds, onDragHandler, scrollBounds]);

    return [
        useDrag(onDragHandler, {
            bounds: currentScrollTarget || document.body,
			
        }),
        dragState,
    ] as readonly [(currentValue: string) => ReactDOMAttributes, Readonly<React.MutableRefObject<boolean>>];
};

const getScrollTargetState = (currentScrollTarget: HTMLElement) => {
    const { width, height } = currentScrollTarget.getBoundingClientRect();
    const { scrollLeft, scrollWidth, scrollHeight, scrollTop } = currentScrollTarget;
    let endRight = false;
    let endLeft = false;
    let endTop = false;
    let endBottom = false;

    if (scrollWidth < width || scrollWidth + scrollbarWidth - scrollLeft - width <= 0) {
        // console.log('END right!');
        endRight = true;
    }
    if (scrollLeft === 0) {
        // console.log('END left!');
        endLeft = true;
    }

    if (scrollHeight < height || scrollHeight - scrollbarWidth - scrollTop - height <= 0) {
        // console.log('END bottom!');
        endBottom = true;
    }
    if (scrollTop === 0) {
        // console.log('END top!');
        endTop = true;
    }

    return {
        endTop,
        endBottom,
        endLeft,
        endRight,
    };
};

const useHandleDraggableCellRegistration = ({
    cell,
    setLocalCellValue,
    inputRef,
    cellIndex,
    columnIndex,
    rowId,
    onCellsUpdated,
    onGetUpdateCellErrorMessage,
    onError,
}: useDragGridCellProps) => {
    const { visible } = useGridRowContext();
    const setCellRegistry = useSetRecoilState(dragableCellRegistry);
    const storeValue = useGridCellValue(cell);
    const restoreStoreValue = useCallback(() => {
        setLocalCellValue(storeValue.toString());
    }, [setLocalCellValue, storeValue]);

    const cellEntry = useMemo((): DraggableCellEntry => {
        return {
            cell,
            restoreStoreValue,
            setLocalCellValue,
            inputRef,
            cellIndex,
            columnIndex,
            rowId,
            onCellsUpdated,
            onGetUpdateCellErrorMessage,
            onError,
        };
    }, [cell, cellIndex, columnIndex, inputRef, rowId, setLocalCellValue, onCellsUpdated, onGetUpdateCellErrorMessage, onError, restoreStoreValue]);

    /**
     * We use useLayoutEffect to run all registration specific stuff synchronously with the DOM updates
     */
    useLayoutEffect(() => {
        // Deregister cell on unmount
        return () => {
            setCellRegistry(filterOutCellFromRegistry(cell.id));
        };
    }, [cell.id, setCellRegistry]);

    useLayoutEffect(() => {
        // Register cell when visible
        if (visible && inputRef) {
            setCellRegistry(registry => {
                if (registry.has(cell.id)) {
                    // Update registration if cellEntry props has changed
                    return mapMap(registry, (currentCellEntry, i, cellId) => {
                        if (cellId === cell.id) {
                            return [cellId, cellEntry];
                        }
                        return [cellId, currentCellEntry];
                    });
                }
                return new Map([...registry, [cell.id, cellEntry]]);
            });
        }
    }, [cell.id, inputRef, setCellRegistry, setLocalCellValue, visible, cellEntry]);

    useLayoutEffect(() => {
        // Deregister cell when not visible
        if (!visible) {
            setCellRegistry(filterOutCellFromRegistry(cell.id));
        }
    }, [cell.id, setCellRegistry, visible]);
};

const filterOutCellFromRegistry = (cellId: string) => (registry: DraggableCellRegitry) => new Map([...registry].filter(([id]) => id !== cellId));
