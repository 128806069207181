import { useMemo, useCallback } from 'react';
import { COLUMN_STICKY_COUNT } from '../CONSTANTS';
import { useSelectedCustomColumnsCount } from '../context/customColumnContext';

export const useCustomColumnRange = () => {
	const customColumnCount = useSelectedCustomColumnsCount();
	const range = useMemo(() => Array.from({ length: customColumnCount }, (_, i) => i + COLUMN_STICKY_COUNT), [customColumnCount]);
	const indexOffset = customColumnCount + COLUMN_STICKY_COUNT;
	const getIndex = useCallback((index: number) => index - indexOffset, [indexOffset]);
	return {
		indexOffset,
		range,
		getIndex
	};
};
