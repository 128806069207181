
import { IObjectEditorItemType } from './Main/IObjectEditorItemType';
import { guid } from '../helpers/guid';
import { Contract } from '../api/generated/data-contracts';
//*IGNORE no-unused-vars Rule on DateEx, It IS used!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DateEx } from '../Utilities/DateEx';
//*IGNORE no-unused-vars Rule on DECORATORS, they ARE used!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PFPropertyBasicInfo } from '../Utilities/PropertyInfo/BasicInfo';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PFPropertyGrouping } from '../Utilities/PropertyInfo/Grouping';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PropertyType } from '../Utilities/PropertyInfo/PropertyType';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PFPropertyRender } from '../Utilities/PropertyInfo/Render';

export class ContractItem implements Contract, IObjectEditorItemType {
    public typeName: string = 'Contract';

    @PFPropertyBasicInfo(PropertyType.String, '_localization:Common.Name', 1)
    @PFPropertyGrouping('Basic', 1, false)
    public Name: string;

    @PFPropertyBasicInfo(PropertyType.String, 'Project Id', 2) //TODO LOCALIZATION
    @PFPropertyGrouping('Basic', 1, false)
    public ProjectId: string;

    @PFPropertyBasicInfo(PropertyType.String, 'Resource Guid', 3) //TODO LOCALIZATION
    @PFPropertyGrouping('Basic', 1, false)
    public ResourceGuid: string;

    @PFPropertyBasicInfo(PropertyType.StringCopyValue, '_localization:Common.Id', 4)
    @PFPropertyGrouping('System', 10)
    @PFPropertyRender(null, null, null, null, (res: Contract) => res.created != null)
    public id: string;

    @PFPropertyBasicInfo(PropertyType.Date, '_localization:Project.Start', 5)
    @PFPropertyGrouping('Basic')
    @PFPropertyRender(null, { DisplayFormat: (date: Date) => DateEx.asUserLocalWithTime(date) }, null, null, null, 120)
    public Start: Date;

    @PFPropertyBasicInfo(PropertyType.Date, '_localization:Project.End', 6)
    @PFPropertyGrouping('Basic')
    @PFPropertyRender(null, { DisplayFormat: (date: Date) => DateEx.asUserLocalWithTime(date) }, null, null, null, 120)
    public End: Date;

	@PFPropertyBasicInfo(PropertyType.String, 'Contract status', 7)
    @PFPropertyGrouping('Basic')
    public AlignStatus: string;

	@PFPropertyBasicInfo(PropertyType.String, '_localization:Common.WorkPackage', 8)
    @PFPropertyGrouping('Basic', 99, false)
    public WorkPackage: string;

    @PFPropertyBasicInfo(PropertyType.Date, '_localization:Common.Created', 10)
    @PFPropertyGrouping('System')
    @PFPropertyRender(null, { DisplayFormat: (date: Date) => DateEx.asUserLocalWithTime(date) }, null, null, null, 120)
    public Created: Date;

    @PFPropertyBasicInfo(PropertyType.Date, '_localization:Common.Modified', 11)
    @PFPropertyGrouping('System')
    @PFPropertyRender(null, { DisplayFormat: (date: Date) => DateEx.asUserLocalWithTime(date) }, null, null, null, 120)
    public Modified: Date;

    @PFPropertyBasicInfo(PropertyType.String, '_localization:Common.CreatedBy', 12)
    @PFPropertyGrouping('System')
    public CreatedBy: string;

    @PFPropertyBasicInfo(PropertyType.String, '_localization:Common.ModifiedBy', 13)
    @PFPropertyGrouping('System')
    public ModifiedBy: string;

    @PFPropertyBasicInfo(PropertyType.Object, '_localization:Common.CustomPropertiesCapitalized', 13, 'Custom project properties') //TODO LOCALIZATION
    @PFPropertyRender(null, { Type: 'contract' }, 'CustomPropertiesSelector')
    @PFPropertyGrouping('Custom properties', 14)
    public CustomProperties: { [key: string]: string };

    constructor() {
        this.id = guid.newGuid();
        this.CustomProperties = {};
    }
}