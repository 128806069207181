import { PFPropertyBasicInfo } from "../../../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../../../Utilities/PropertyInfo/PropertyType";

export class OnCommentReceivedRules {
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:MailTemplate.HasPreviouslyCommented", 10, "_localization:MailTemplate.HasPreviouslyCommentedDescription")
	public HasPreviouslyCommented: boolean;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:MailTemplate.RbsMatch", 20, "_localization:MailTemplate.RbsMatchDescription")
    public RbsMatch: boolean;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:MailTemplate.AdditionalRbsMatch", 30, "_localization:MailTemplate.AdditionalRbsMatchDescription")
	public AdditionalRbsMatch: boolean;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:MailTemplate.OnlyMatchRbsDirectly", 40, "_localization:MailTemplate.OnlyMatchRbsDirectlyDescription")
	public OnlyMatchRbsDirectly: boolean;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:MailTemplate.IsProjectOwner", 50, "_localization:MailTemplate.IsProjectOwnerDescription")
    public IsProjectOwner: boolean;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:MailTemplate.IsAdditionalProjectOwner", 60, "_localization:MailTemplate.IsAdditionalProjectOwnerDescription")
	public IsAdditionalProjectOwner: boolean;
}