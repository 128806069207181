export const objectUtil = {
	deepClone <T>(source: T): T {
		return Array.isArray(source)
				? source.map(item => objectUtil.deepClone(item))
				: source instanceof Date
					? new Date(source.getTime())
					: source && typeof source === 'object'
						? Object.getOwnPropertyNames(source).reduce((o, prop) => {
								Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
								o[prop] = objectUtil.deepClone(source[prop]);
								return o;
						  }, Object.create(Object.getPrototypeOf(source)))
						: source as T;
	}
}