import { IStyleFunctionOrObject } from '@fluentui/react/lib/Utilities';
import { ITextFieldStyleProps, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { PersonaBase, PersonaCoinBase } from '@fluentui/react/lib/Persona';
import { TopRow } from '../../../Entities/Table/TopRow';
import TableTop from './TableTop';
import TableGroup from './TableGroup';
import { Row } from '../../../Entities/Table/Row';
import { GroupRow } from '../../../Entities/Table/GroupRow';
import { Cell } from '../../../Entities/Table/Cell';
import { TableStateManager } from '../../../Services/TableStateManager';
import { Header } from './Header/Header';
import TableGroupContainer from './TableGroupContainer';
import { GroupContainerRow } from '../../../Entities/Table/GroupContainerRow';
import { ArrayEx } from '../../../Utilities/ArrayEx';
import { DragContextProvider } from './DragCopy/dragContext';
import DragHighlighter from './DragCopy/DragHighlighter';
import { WithStore } from '../../../Services/StateStore';
import { StoreProps } from '../../../Services/StoreProps';
import { LocalStorage } from '../../../Utilities/LocalStorage';
import { CellUpdateDto } from './DragCopy/CellUpdateDto';
import { BaseRow } from '../../../Entities/Table/BaseRow';
import { guid } from '../../../helpers/guid';
import { IDs } from '../../../Utilities/IDs';
import { GridSettings } from '../../../Utilities/GridSettings';
import { IContextualMenuItem } from '@fluentui/react';
import { CellRow } from '../../../Entities/Table/CellRow';
import { HeaderRow } from '../../../Entities/Table/HeaderRow';
import { TableCustomColumn } from './TableCustomColumn';
import { Component, createContext } from 'react';

// import getClassNames from './styles';

// import { getTheme } from '@fluentui/react/lib/Styling';
// const theme = getTheme();

export interface ITableProps extends StoreProps {
    Name: string;
    Model: TopRow;
    ContainerClassName?: string;
    HideTopRow?: boolean;

    TopCellStyles?: Array<(cell: Cell, colIdx: number) => IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>>;
    GroupCellStyles?: Array<(cell: Cell, colIdx: number) => IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>>;
    GroupContainerCellStyles?: Array<(cell: Cell, colIdx: number) => IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>>;
    RowCellStyles?: Array<(cell: Cell, colIdx: number) => IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>>;

    CellUpdates?: Array<(cell: Cell, columnIndex: number, newValue: number, event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void>;

    CellEditing?: (value: boolean) => void;

    GroupContainerSort?: (containers: Array<GroupContainerRow>) => Array<GroupContainerRow>;
    GroupSort?: (groups: Array<GroupRow>) => Array<GroupRow>;
    GroupClick?: (row: GroupRow) => void;
    GroupContainerClick?: (row: GroupContainerRow) => void;
    GroupDraggable?: (group: GroupRow) => boolean;
    GroupDragStart?: (
        group: GroupRow,
        container: GroupContainerRow,
        event: React.DragEvent<typeof PersonaBase | typeof PersonaCoinBase | HTMLDivElement>,
    ) => void;
    GroupDragEnd?: (group: GroupRow, event: React.DragEvent<typeof PersonaBase | typeof PersonaCoinBase | HTMLDivElement>) => void;
    GroupClass?: (group: GroupRow) => string;
    GroupContainerClass?: (group: GroupRow) => string;

    RowSort?: (group: GroupRow) => Array<Row>;
    RowExtraContent?: (row: Row, group?: GroupRow) => JSX.Element;
    RowClick?: (row: Row) => void;
    RowDraggable?: (row: Row) => boolean;
    RowDragStart?: (row: Row, group: GroupRow, event: React.DragEvent<typeof PersonaBase | typeof PersonaCoinBase | HTMLDivElement>) => void;
    RowDragEnd?: (row: Row, group: GroupRow, event: React.DragEvent<typeof PersonaBase | typeof PersonaCoinBase | HTMLDivElement>) => void;
    RowClassName?: (row: Row) => string;
    RowPersonaProps?: { [key: string]: any };

    CellRowExtraContent?: (cellRow: CellRow, row: BaseRow) => JSX.Element;
    CellExtraContent?: (cell: Cell, columnIndex: number, cellIndex: number) => JSX.Element;
    ColumnHeaderExtraContent?: (headerRow: HeaderRow) => JSX.Element;

    GroupEditEnabled?: (group: GroupRow) => boolean;
    GroupEdit?: (group: GroupRow, oldName: string, newName: string) => void;
    GroupContainerEditEnabled?: (container: GroupContainerRow) => boolean;
    GroupContainerEdit?: (container: GroupContainerRow, oldName: string, contractIds: Array<string>) => void;
    GroupExtraContent?: (group: GroupRow) => JSX.Element;
    GroupExtraTextContent?: (group: GroupRow) => JSX.Element;
    GroupContainerExtraContent?: (name: string, container: GroupContainerRow) => JSX.Element;
    GroupContainerSuffixContent?: (name: string, container: GroupContainerRow) => JSX.Element;
    TopExtraContent?: (top: TopRow) => JSX.Element;
    TitleContentOverride?: (title: string) => JSX.Element;

    DragHighlighterUpdate?: Array<(cells: Array<{ Cell: Cell; ColumnIndex: number; Value: number }>) => void>;
    PasteFromExcel?: (cells: Array<CellUpdateDto>, excessiveCells: Array<CellUpdateDto>) => void;
    DragIdPrefix?: string;
    DragContainerClass?: string;

    AllowContainerCollapse?: boolean;
    AllowGroupCollapse?: boolean;
    AllCollapsed?: boolean;
    GridCellDecimals?: number;
    GridSettingsExtraMenuItems?: IContextualMenuItem[];
    GridSettingsSpacingAmount?: number;
    GridSettingsHidePersonaIcons?: boolean;
    EnableGridSettings?: boolean;
    SetGridSettingsHidePersonaIconsCallback?: (show: boolean) => void;
    SetGridSettingsHideGenericResourcesCallback?: (show: boolean) => void;
    SetGridSettingsEnableFastDeletionCallback?: (show: boolean) => void;
    SetGridSettingsSpacingAmountCallback?: (spacing: number) => void;

    CustomColumns?: Array<TableCustomColumn>;
    localStorageKey?: string;
}

export interface ITableState {
    Model: TopRow;
    ColumnCount: number;
    EditableColumnCount: number;

    TopCellStyles?: Array<(cell: Cell, colIdx: number) => IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>>;
    GroupCellStyles?: Array<(cell: Cell, colIdx: number) => IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>>;
    GroupContainerCellStyles?: Array<(cell: Cell, colIdx: number) => IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>>;
    RowCellStyles?: Array<(cell: Cell, colIdx: number) => IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>>;

    CellUpdates?: Array<(cell: Cell, columnIndex: number, newValue: number, event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void>;

    CellEditing?: (value: boolean) => void;

    GroupContainerSort?: (containers: Array<GroupContainerRow>) => Array<GroupContainerRow>;
    GroupSort?: (groups: Array<GroupRow>) => Array<GroupRow>;
    GroupClick?: (row: GroupRow) => void;
    GroupContainerClick?: (row: GroupContainerRow) => void;
    GroupDraggable?: (group: GroupRow) => boolean;
    GroupDragStart?: (
        group: GroupRow,
        container: GroupContainerRow,
        event: React.DragEvent<typeof PersonaBase | typeof PersonaCoinBase | HTMLDivElement>,
    ) => void;
    GroupDragEnd?: (group: GroupRow, event: React.DragEvent<typeof PersonaBase | typeof PersonaCoinBase | HTMLDivElement>) => void;
    GroupClass?: (group: GroupRow) => string;
    GroupContainerClass?: (group: GroupRow) => string;

    RowSort: (group: GroupRow) => Array<Row>;
    RowExtraContent: (row: Row, group?: GroupRow) => JSX.Element;
    RowClick?: (row: Row) => void;
    RowDraggable?: (row: Row) => boolean;
    RowDragStart?: (row: Row, group: GroupRow, event: React.DragEvent<typeof PersonaBase | typeof PersonaCoinBase | HTMLDivElement>) => void;
    RowDragEnd?: (row: Row, group: GroupRow, event: React.DragEvent<typeof PersonaBase | typeof PersonaCoinBase | HTMLDivElement>) => void;
    RowClassName?: (row: Row) => string;
    RowPersonaProps: { [key: string]: any };

    CellRowExtraContent?: (cellRow: CellRow, row: BaseRow) => JSX.Element;
    CellExtraContent?: (cell: Cell, columnIndex: number, cellIndex: number) => JSX.Element;
    ColumnHeaderExtraContent?: (headerRow: HeaderRow) => JSX.Element;

    GroupEditEnabled?: (group: GroupRow) => boolean;
    GroupEdit?: (group: GroupRow, oldName: string, newName: string) => void;
    GroupContainerEditEnabled?: (container: GroupContainerRow) => boolean;
    GroupContainerEdit?: (container: GroupContainerRow, oldName: string, contractIds: Array<string>) => void;
    GroupExtraContent?: (group: GroupRow) => JSX.Element;
    GroupExtraTextContent?: (group: GroupRow) => JSX.Element;
    GroupContainerExtraContent?: (name: string, container: GroupContainerRow) => JSX.Element;
    GroupContainerSuffixContent?: (name: string, container: GroupContainerRow) => JSX.Element;
    TopExtraContent?: (top: TopRow) => JSX.Element;
    TitleContentOverride?: (title: string) => JSX.Element;

    DragHighlighterUpdate?: Array<(cells: Array<{ Cell: Cell; ColumnIndex: number; Value: number }>) => void>;
    PasteFromExcel?: (cells: Array<CellUpdateDto>, excessiveCells: Array<CellUpdateDto>) => void;

    AllowContainerCollapse?: boolean;
    AllowGroupCollapse?: boolean;
    AllCollapsed: boolean;
    GridCellDecimals: number;
    GridSettingsExtraMenuItems?: IContextualMenuItem[];
    GridSettingsSpacingAmount?: number;
    GridSettingsHidePersonaIcons?: boolean;
    GridSettingsHideGenericResources?: boolean;
    GridSettingsEnableFastDeletion?: boolean;
    EnableGridSettings?: boolean;
    SetGridSettingsHidePersonaIconsCallback?: (show: boolean) => void;
    SetGridSettingsHideGenericResourcesCallback?: (show: boolean) => void;
    SetGridSettingsEnableFastDeletionCallback?: (show: boolean) => void;
    SetGridSettingsSpacingAmountCallback?: (spacing: number) => void;

    CustomColumns?: Array<TableCustomColumn>;
}

export const TableContext = createContext({});

class Table extends Component<ITableProps, ITableState> {
    constructor(props: ITableProps) {
        super(props);
        let GridSettingsHideGenericResources = false;
        if (props.localStorageKey) {
            try {
                GridSettingsHideGenericResources = LocalStorage.get(props.localStorageKey);
            } catch (error) {}
        }
        this.state = {
            Model: props.Model,
            ColumnCount: props.Model.CellRows.length,
            EditableColumnCount: props.Model.CellRows.filter(_ => !_.Properties.ColumnIsFrozen).length,
            RowCellStyles: props.RowCellStyles,
            TopCellStyles: props.TopCellStyles,
            GroupCellStyles: props.GroupCellStyles,
            GroupContainerCellStyles: props.GroupContainerCellStyles,
            CellUpdates: props.CellUpdates,
            GroupEdit: props.GroupEdit,
            GroupEditEnabled: props.GroupEditEnabled,
            GroupContainerEditEnabled: props.GroupContainerEditEnabled,
            GroupContainerEdit: props.GroupContainerEdit,
            GroupExtraContent: props.GroupExtraContent,
            GroupContainerExtraContent: props.GroupContainerExtraContent,
            GroupContainerSuffixContent: props.GroupContainerSuffixContent,
            GroupSort: props.GroupSort != null ? props.GroupSort : rows => rows,
            GroupContainerSort: props.GroupContainerSort,
            GroupClick: props.GroupClick,
            GroupContainerClick: props.GroupContainerClick,
            GroupDraggable: props.GroupDraggable ? props.GroupDraggable : group => false,
            GroupDragStart: props.GroupDragStart,
            GroupDragEnd: props.GroupDragEnd,
            GroupClass: props.GroupClass,
            GroupContainerClass: props.GroupContainerClass,
            RowSort: props.RowSort != null ? props.RowSort : group => group.Rows,
            RowClick: props.RowClick,
            RowDraggable: props.RowDraggable ? props.RowDraggable : row => false,
            RowDragStart: props.RowDragStart,
            RowDragEnd: props.RowDragEnd,
            RowExtraContent: props.RowExtraContent,
            RowClassName: props.RowClassName ? props.RowClassName : row => '',
            RowPersonaProps: props.RowPersonaProps,
            TopExtraContent: props.TopExtraContent,
            CellEditing: props.CellEditing != null ? props.CellEditing : () => {},
            DragHighlighterUpdate: props.DragHighlighterUpdate,
            AllowContainerCollapse: props.AllowContainerCollapse,
            AllowGroupCollapse: props.AllowGroupCollapse,
            GroupExtraTextContent: props.GroupExtraTextContent,
            AllCollapsed: props.AllCollapsed,
            TitleContentOverride: props.TitleContentOverride,
            PasteFromExcel: props.PasteFromExcel,
            GridCellDecimals: props.GridCellDecimals == null ? 2 : props.GridCellDecimals,
            GridSettingsExtraMenuItems: props.GridSettingsExtraMenuItems,
            GridSettingsSpacingAmount: props.GridSettingsSpacingAmount ?? 1,
            GridSettingsHidePersonaIcons: props.GridSettingsHidePersonaIcons ?? false,
            GridSettingsHideGenericResources: GridSettingsHideGenericResources,
            // GridSettingsHideGenericResources: props.GridSettingsHideGenericResources ?? false,
            EnableGridSettings: props.EnableGridSettings ?? false,
            SetGridSettingsHidePersonaIconsCallback: props.SetGridSettingsHidePersonaIconsCallback ?? (val => {}),
            SetGridSettingsHideGenericResourcesCallback: props.SetGridSettingsHideGenericResourcesCallback ?? (val => {}),
            SetGridSettingsEnableFastDeletionCallback: props.SetGridSettingsEnableFastDeletionCallback ?? (val => {}),
            SetGridSettingsSpacingAmountCallback: props.SetGridSettingsSpacingAmountCallback ?? (val => {}),
            CellExtraContent: props.CellExtraContent,
            CellRowExtraContent: props.CellRowExtraContent,
            ColumnHeaderExtraContent: props.ColumnHeaderExtraContent,
            CustomColumns: props.CustomColumns,
        };
        // set grid spacing
        GridSettings.SetSpacingAmount(this.state.GridSettingsSpacingAmount);
    }

    componentWillReceiveProps(nextProps: ITableProps) {
        const newModel = nextProps.Model;
        const modelChanged = newModel?.ChangeKey !== this.state.Model?.ChangeKey;

        const tempState: Partial<ITableState> = {
            RowExtraContent: nextProps.RowExtraContent,
            GroupExtraContent: nextProps.GroupExtraContent,
            GroupContainerSuffixContent: nextProps.GroupContainerSuffixContent,
        };

        if (!modelChanged && this.state.TitleContentOverride === nextProps.TitleContentOverride) {
            this.setState(tempState as ITableState);
            return;
        }

        const stateObj: Partial<ITableState> = {
            Model: modelChanged ? newModel : this.state.Model,
            ColumnCount: newModel && newModel.CellRows ? newModel.CellRows.length : 0,
            EditableColumnCount: newModel && newModel.CellRows ? newModel.CellRows.filter(_ => !_.Properties.ColumnIsFrozen).length : 0,
            GridCellDecimals: nextProps.GridCellDecimals == null ? 2 : nextProps.GridCellDecimals,
            CustomColumns: nextProps.CustomColumns,
            TitleContentOverride: nextProps.TitleContentOverride,
            ...tempState,
        };
        this.setState(stateObj as ITableState, () => {
            if (modelChanged) this.StateManager.RefreshTabIndex();
        });
    }

    private StateManager = new TableStateManager(this);

    private GetGroupContainers = (): Array<JSX.Element> => {
        const groups = this.state.Model.Groups;

        const renders = [];
        let sorted = new Array<BaseRow>();
        if (this.state.GroupContainerSort) {
            sorted = this.state.GroupContainerSort(groups.filter(_ => _.TypeName === 'GroupContainerRow') as Array<GroupContainerRow>);
            const singleGroups = [...groups.filter(_ => _.TypeName !== 'GroupContainerRow')] as Array<GroupRow>;
            const groupsSorted = this.state.GroupSort ? this.state.GroupSort(singleGroups) : ArrayEx.sort(singleGroups, 'Name');
            sorted.push(...groupsSorted);
        } else sorted = ArrayEx.sort(groups, 'Name');

        sorted.forEach(_ => {
            const shouldDisableMenu = _.CellRows.every(cellRow => cellRow.Cells.every(cell => !cell.Value));
            // handle different types of groups
            if (_.TypeName === 'GroupRow') {
                renders.push(<TableGroup key={_.Id} disabled={shouldDisableMenu} Group={_ as GroupRow} Collapsed={this.state.AllCollapsed} />);
            } else if (_.TypeName === 'GroupContainerRow') {
                renders.push(
                    <TableGroupContainer
                        key={_.Id === guid.empty ? IDs.makeId() : _.Id}
                        disabled={shouldDisableMenu}
                        Container={_ as GroupContainerRow}
                        Collapsed={this.state.AllCollapsed}
                    />,
                );
            }
        });
        return renders;
    };

    private GetExpandAllCallback = () => {
        return this.state.AllowGroupCollapse || this.state.AllowContainerCollapse
            ? () => {
                  this.StateManager.RefreshTabIndex();
                  this.state.Model.Groups.forEach(_ => {
                      LocalStorage.setProperty(
                          'tp-grid-collapsed',
                          `${this.state.Model.Id}-${_.TypeName === 'GroupContainerRow' ? _.Name : _.Id}`,
                          !this.state.AllCollapsed,
                      );
                  });
                  this.setState({ AllCollapsed: !this.state.AllCollapsed });
              }
            : null;
    };

    private GetCollapseClasses = (): string => {
        let classNames = '';
        if (this.state.AllowContainerCollapse) classNames += 'container-collapse ';
        if (this.state.AllowGroupCollapse) classNames += 'group-collapse';
        return classNames;
    };

    private ToggleHideGenericResources = () => {
        const newState = !this.state.GridSettingsHideGenericResources;
        if (this.props.localStorageKey) {
            try {
                LocalStorage.set(this.props.localStorageKey, newState);
            } catch (error) {}
        }
        this.setState({ GridSettingsHideGenericResources: newState });
    };

    render() {
        return this.state.Model ? (
            <TableContext.Provider value={{ ...this.StateManager, ToggleHideGenericResources: this.ToggleHideGenericResources } as any}>
                {/* <DragContextProvider dragContainerClass={this.props.DragContainerClass} elementIdSuffix={this.props.DragIdPrefix} resourceId={this.props.Store.Get(_ => _.ResourceId)} gridType={this.props.Store.Get(_ => _.GridType)} columnCount={this.state.ColumnCount} pasteFromExcel={this.props.PasteFromExcel}> */}
                <DragContextProvider
                    dragContainerClass={this.props.DragContainerClass}
                    elementIdSuffix={this.props.DragIdPrefix}
                    columnCount={this.state.ColumnCount}
                    pasteFromExcel={this.props.PasteFromExcel}
                >
                    <div className={`tp-capacity-container ${this.props.ContainerClassName} ${this.GetCollapseClasses()}`}>
                        <table id={`tp-capacity${this.props.DragIdPrefix ? this.props.DragIdPrefix : ''}`} className="tp-capacity">
                            <thead className="tp-capacity-header">
                                <Header
                                    headers={this.state.Model.Headers}
                                    pageTitle={this.props.Name as string}
                                    ExpandCallback={this.GetExpandAllCallback()}
                                    id={this.state.Model.Id}
                                />
                            </thead>
                            <tbody id={`tp-capacity-body${this.props.DragIdPrefix ? this.props.DragIdPrefix : ''}`} className="tp-capacity-body">
                                {!this.props.HideTopRow && (
                                    <TableTop
                                        key={this.state.Model.Id}
                                        Top={this.state.Model}
                                        Collapsed={this.state.AllCollapsed}
                                        ExpandCallback={this.GetExpandAllCallback()}
                                    />
                                )}
                                {this.GetGroupContainers()}
                            </tbody>
                        </table>
                        {this.state.DragHighlighterUpdate && <DragHighlighter DragHighlighterUpdate={this.state.DragHighlighterUpdate} />}
                    </div>
                </DragContextProvider>
            </TableContext.Provider>
        ) : null;
    }
}

// eslint-disable-next-line react-refresh/only-export-components
export default WithStore<ITableProps>(Table);
