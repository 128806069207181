import { FunctionComponent, useState, useEffect } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { PFDialog } from './PFDialog';
import { Plugin } from '../../Services/PluginInvoker';
import { PluginIDs } from '../../Utilities/PluginIDs';
import { TreeViewItem } from '../../Entities/TreeViewItem';
import { WorkPackageDto } from '../../Entities/Main/WorkPackage';
import { ArrayEx } from '../../Utilities/ArrayEx';
import { language } from '../../Services/LocalizationService';
import { treeViewRefactor } from '../../Utilities/TreeViewRefactor';
import { TreeView } from '../../forms/_builders/tpComplexBuilder/components/tree/components/TreeView';

interface IProps {
    WorkPackage: WorkPackageDto;
    ButtonEnabled: boolean;
    ImportCallback: (workPackageIds: Array<string>) => Promise<void>;
    ImportItems?: Array<TreeViewItem>;
}

const WorkPackageSelector: FunctionComponent<IProps> = props => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [buttonEnabled, setButtonEnabled] = useState<boolean>(props.ButtonEnabled);

    const [selected, setSelected] = useState<Array<string>>([]);
    const [tree, setTree] = useState<TreeViewItem>();

    useEffect(() => {
        const getter = async () => {
            await LoadPackages();
        };
        // fetch all items
        if (props.ImportItems == null && props.WorkPackage != null) getter();
        setSelected(props.WorkPackage?.SubWorkPackages || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.WorkPackage]);

    useEffect(() => {
        setButtonEnabled(props.ButtonEnabled);
    }, [props.ButtonEnabled]);

    const LoadPackages = async () => {
        const allPackages = await GetAllPackagesTree();
        const root = new TreeViewItem();
        root.Name = 'Root';
        root.Children = allPackages;

        // autoexpand
        ExpandSelected(root);

        setTree(root);
    };

    const ExpandSelected = (tree: TreeViewItem): boolean => {
        if (ArrayEx.isNullOrEmpty(tree?.Children)) return false;
        let hasCHildsSelected = false;
        for (const item of tree.Children) {
            // first to hit is the selected one
            const isSelectedItem = item.Selected && item.Disabled;
            if (isSelectedItem) {
                item.DefaultExpanded = true;
                tree.DefaultExpanded = true;
                continue;
            }
            const childSelected = ExpandSelected(item);
            if (childSelected) {
                hasCHildsSelected = true;
            }
        }
        return hasCHildsSelected;
    };

    const GetAllPackagesTree = async (): Promise<Array<TreeViewItem>> => {
        const allPackages = await Plugin.Invoke<Array<TreeViewItem>>(PluginIDs.GetAllWorkpackagesTree, props.WorkPackage);
        return allPackages;
    };

    return (
        <div className="tp-workpackage-importer">
            <PrimaryButton text={language.WorkPackageSelector.IncludeAnotherPackage} disabled={!buttonEnabled} onClick={ev => setShowDialog(true)} />
            <PFDialog
                title={language.WorkPackageSelector.IncludeAnotherWorkPackage}
                showDialog={showDialog}
                callback={async () => {
                    await props.ImportCallback([...selected]);
                    setShowDialog(false);
                }}
                dismissCallback={() => setShowDialog(false)}
            >
                <TreeView
                    tree={treeViewRefactor.parseToTreeNode(tree)}
                    type="select"
                    selectionChanged={(item, checked) => {
                        const clone = [...selected];
                        if (!checked) {
                            const index = clone.indexOf(item.id);
                            clone.splice(index, 1);
                        } else clone.push(item.id);
                        setSelected(clone);
                    }}
                    delimiter="."
                    defaultExpandedDepth={2}
                    autoSelectAndDisableChilds
                    autoExpandSelected
                    hideRootNode
                    multiSelect
                />
            </PFDialog>
        </div>
    );
};

export default WorkPackageSelector;
