/* eslint-disable react-refresh/only-export-components */
import { CommandBarButton, ICommandBarItemProps, Persona } from '@fluentui/react';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { buildRoute } from '../../../helpers/routeHelpers';
import { language } from '../../../Services/LocalizationService';
import { OverviewContext } from '../../../Utilities/Context/OverviewContext';
import { useStore } from '../../../context/store';
import { ReportEx } from '../../../Utilities/ReportEx';
import { getEnumFromRoute, MainView } from '../../../Entities/MainView';
import { Plugin } from '../../../Services/PluginInvoker';
import { PluginIDs } from '../../../Utilities/PluginIDs';
import { useCheckTPPermissions } from '../../../hooks/usePermissions';
import { Link } from 'react-router-dom';
import EnumEx from '../../../Utilities/EnumEx';
import { useUISettings } from '../../../context/network/http/QueryProvider/queries/UISettings';
import { useCurrentProject } from '../../../context/network/http/QueryProvider/queries/project';
import { UserType } from '../../../api/generated/data-contracts';

export const useNavbarItems = () => {
    const { data: uiSettings } = useUISettings();
    const goTo = useNavigate();

    const { pathname } = useLocation();
    const { projectId } = useParams<{ projectId: string }>();
    const { data: project } = useCurrentProject(projectId);

    const { setUIContext } = useStore(store => ({
        setUIContext: store.setUIContext,
    }));

    const checkPermissions = useCheckTPPermissions();

    const navbarFarItems = useMemo(() => {
        const commandbarFarItems: ICommandBarItemProps[] = [
            {
                key: 'name',
                name: '',
                onRender: _ => (
                    <div
                        id="tp-topbar-currentuser"
                        className="tp-topbar-currentuser-container"
                        onClick={() => setUIContext(ui => ({ ...ui, showCurrentUserInfo: !ui.showCurrentUserInfo }))}
                    >
                        <Persona coinSize={32} text={uiSettings.resource.name} hidePersonaDetails />
                    </div>
                ),
            },
        ];

        if (uiSettings.settings.enableHelpSection) {
            const link = 'help';
            commandbarFarItems.push({
                key: 'helpView',
                ariaLabel: 'Help view',
                iconProps: { iconName: 'Help', styles: { root: { fontSize: 18 } } },
                onRender: NavbarButton,
                iconOnly: true,
                data: {
                    active: '/' + link === pathname,
                    link,
                },
            });
        }

        if (OverviewContext.SingleProjectView) {
            return commandbarFarItems;
        }

        const isSuperUser = checkPermissions({ userTypes: [UserType.SuperUser] });

        if (checkPermissions({ userTypes: [UserType.Administrator] })) {
            const link = 'admindashboard';
            commandbarFarItems.push({
                key: 'adminDashboard',
                name: language.Topbar.AdminDashboard,
                ariaLabel: 'Admin Dashboard',
                iconProps: { iconName: 'Settings' },
                onRender: NavbarButton,
                data: {
                    active: pathname.startsWith('/' + link) || pathname.startsWith('/configuration'),
                    link,
                },
            });
        }

        if (isSuperUser) {
            const link = 'contentsettings';
            commandbarFarItems.push({
                key: 'contentSettings',
                name: language.Topbar.ContentSettings,
                ariaLabel: 'Content Settings',
                iconProps: { iconName: 'Settings' },
                onRender: NavbarButton,
                data: {
                    active: pathname.startsWith('/' + link),
                    link,
                },
            });
        }

        if (uiSettings.settings.enableWorkPackages && checkPermissions({ userTypes: uiSettings.settings.allowedToDesignWorkpackages, some: true })) {
            const link = 'workpackages';
            commandbarFarItems.push({
                key: 'workPackages',
                name: language.Topbar.WorkPackages,
                ariaLabel: 'Request Templates',
                iconProps: { iconName: 'Package' },
                onRender: NavbarButton,
                data: {
                    active: '/' + link === pathname,
                    link,
                },
            });
        }

        if ((uiSettings.settings.pmCanEditOwnProjects && checkPermissions({ userTypes: [UserType.ProjectOwner] })) || isSuperUser) {
            const link = 'projectoverview';
            commandbarFarItems.push({
                key: 'projectOverview',
                name: language.Topbar.ProjectOverview,
                ariaLabel: 'Project Overview',
                iconProps: { iconName: 'TaskManager' },
                onRender: NavbarButton,
                data: {
                    active: '/' + link === pathname,
                    link,
                },
            });
        }

        if ((uiSettings.settings.lmCanEditOwnResources && checkPermissions({ userTypes: [UserType.LineManager] })) || isSuperUser) {
            const link = 'resourceoverview';
            commandbarFarItems.push({
                key: 'resourceOverview',
                name: language.Topbar.ResourceOverview,
                ariaLabel: 'Resource Overview',
                iconProps: { iconName: 'PlayerSettings' },
                onRender: NavbarButton,
                data: {
                    active: '/' + link === pathname,
                    link,
                },
            });
        }

        if (checkPermissions({ userTypes: uiSettings.settings.allowedToSeeContractOverview ?? [], some: true })) {
            const link = 'contractoverview';
            commandbarFarItems.push({
                key: 'contractOverview',
                name: language.Topbar.ContractOverview,
                ariaLabel: 'Contract Overview',
                iconProps: { iconName: 'TaskManager' },
                onRender: NavbarButton,
                data: {
                    active: '/' + link === pathname,
                    link,
                },
            });
        }

        if (!uiSettings.isDelegated && checkPermissions({ userTypes: uiSettings.settings.allowedToDelegate ?? [], some: true })) {
            commandbarFarItems.push({
                key: 'delegation',
                name: language.Topbar.Delegation,
                iconProps: { iconName: 'ReminderPerson', id: 'navbardelegation' },
                iconOnly: true,
                onClick: (ev, item) => {
                    setUIContext({ showResourcePicker: true, delegationMode: true });
                },
            });
        }

        if (uiSettings.isDelegated) {
            commandbarFarItems.push({
                key: 'stopdelegation',
                name: language.Topbar.StopDelegation,
                ariaLabel: 'stopdelegation',
                iconProps: { iconName: 'BlockContact' },
                onClick: () => void Plugin.InvokeNoResponse(PluginIDs.StopDelegation).then(_ => window.location.reload()),
            });
        }

        return commandbarFarItems;
    }, [
        checkPermissions,
        pathname,
        setUIContext,
        uiSettings.isDelegated,
        uiSettings.resource.name,
        uiSettings.settings.allowedToDelegate,
        uiSettings.settings.allowedToDesignWorkpackages,
        uiSettings.settings.enableHelpSection,
        uiSettings.settings.enableWorkPackages,
        uiSettings.settings.lmCanEditOwnResources,
        uiSettings.settings.pmCanEditOwnProjects,
        uiSettings.settings.allowedToSeeContractOverview,
    ]);

    const navbarItems = useMemo((): Array<ICommandBarItemProps> => {
        const view = getEnumFromRoute(pathname);

        const commandbarItems: Array<ICommandBarItemProps> = [];
        const projectId = project?.id;

        // no access at all
        const hasAccess = checkPermissions({ some: true, userTypes: EnumEx.allAsNumber<UserType>(UserType) as any });
        if (!hasAccess) {
            return [];
        }

        if (checkPermissions({ userTypes: [UserType.SuperUser, UserType.ProjectOwner], some: true })) {
            const parentLink = buildRoute('request');
            const link = buildRoute(parentLink, projectId);
            commandbarItems.push({
                key: 'request',
                name: language.Topbar.Request,
                iconProps: { iconName: 'Family' },
                data: {
                    active: pathname.startsWith(parentLink),
                    link,
                },
                onRender: NavbarButton,
            });
        }

        const isSuperUserOrLineManager = checkPermissions({ userTypes: [UserType.SuperUser, UserType.LineManager], some: true });

        if (isSuperUserOrLineManager) {
            const parentLink = buildRoute('allocation');
            const link = buildRoute(parentLink, projectId);
            commandbarItems.push({
                key: 'allocation',
                name: language.Topbar.Allocation,
                iconProps: { iconName: 'ReminderGroup' },
                data: {
                    active: pathname.startsWith(parentLink),
                    link,
                },
                onRender: NavbarButton,
            });
            if (uiSettings.settings.enableLmCapacityView) {
                const parentLink = buildRoute('planner');
                const link = parentLink;
                commandbarItems.push({
                    key: 'planner',
                    name: language.Topbar.Planner,
                    iconProps: { iconName: 'PlannerLogo' },
                    data: {
                        active: pathname.startsWith(parentLink),
                        link,
                    },
                    onRender: NavbarButton,
                });
            }
            if (uiSettings.settings.enableProjectPlanner) {
                const parentLink = buildRoute('projectplanner');
                const link = parentLink;
                commandbarItems.push({
                    key: 'projectplanner',
                    name: language.Topbar.ProjectPlanner,
                    iconProps: { iconName: 'PlannerLogo' },
                    data: {
                        active: pathname.startsWith(parentLink),
                        link,
                    },
                    onRender: NavbarButton,
                });
            }
        }
        if (uiSettings.settings.enableResourcePlanner && checkPermissions({ userTypes: [UserType.LineManager, UserType.SuperUser], some: true })) {
            const parentLink = buildRoute('resourceplanner');
            const link = parentLink;
            commandbarItems.push({
                key: 'resource-planner',
                name: language.Topbar.ResourcePlanner,
                iconProps: { iconName: 'AddGroup' },
                data: {
                    active: pathname.startsWith(parentLink),
                    link,
                },
                onRender: NavbarButton,
            });
        }
        if (pathname.includes('/compare')) {
            commandbarItems.push({
                key: 'compareScenarios',
                name: language.Topbar.CompareScenarios,
                iconProps: { iconName: 'Compare' },
                onClick: (ev, item) => {},
                data: { active: true },
                onRender: NavbarButton,
            });
        }
        if (pathname.includes('/draft')) {
            commandbarItems.push({
                key: 'draftCapacity',
                name: language.Topbar.Draft,
                iconProps: { iconName: 'DoubleColumnEdit' },
                onClick: (ev, item) => {},
                data: { active: true },
                onRender: NavbarButton,
            });
        }

        if (
            uiSettings.settings.enableResourceCapacityView &&
            checkPermissions({ userTypes: [UserType.LineManager, UserType.SuperUser, UserType.Resource], some: true })
        ) {
            const itemTitle = isSuperUserOrLineManager ? language.Topbar.ResourceCapacity : language.Topbar.MyCapacity;
            const link = buildRoute('resourcecapacity');
            commandbarItems.push({
                key: 'resourceCapacity',
                name: itemTitle,
                iconProps: { iconName: 'UserGauge' },
                onClick: (ev, item) => {
                    setUIContext({ showResourcePicker: true });
                },
                data: {
                    active: pathname.startsWith(link),
                    link,
                },
                onRender: NavbarButton,
            });
        }

        const filteredReports = uiSettings.settings.reports.filter(_ => {
            // filter on permission
            if (!_.userTypes.some(r => uiSettings.resource.userTypes.indexOf(r as any) >= 0)) {
                return false;
            }
            // no filter on view
            if (_.view == null || _.view === MainView.Empty) {
                return true;
            }
            // filter on view
            if (_.view === view) {
                return true;
            }
            // keep if we're on the reportviewer view
            if (view === MainView.ReportViewer) {
                return true;
            }
            return false;
        });
        if (!(filteredReports.length < 1)) {
            const reportViewerRoute = buildRoute('reportviewer');
            commandbarItems.push({
                key: 'reports',
                name: language.Common.Reports,
                cacheKey: 'myCacheKey',
                iconProps: { iconName: 'AnalyticsReport' },
                subMenuProps: {
                    onMenuDismissed: () => setUIContext({ topbarMenuItemOpen: false }),
                    onMenuOpened: () => setUIContext({ topbarMenuItemOpen: true }),
                    items: filteredReports.map(_ => {
                        const link = buildRoute(reportViewerRoute, _.id);
                        return {
                            key: _.name,
                            text: _.name,
                            onClick: () => {
                                if (_.openInNewWindow) {
                                    window.open(ReportEx.buildUrl(_, project?.id, uiSettings.resource.id), '_blank');
                                } else {
                                    goTo(link);
                                }
                            },
                        };
                    }),
                },
            });
        }
        return commandbarItems;
    }, [
        checkPermissions,
        goTo,
        pathname,
        project?.id,
        setUIContext,
        uiSettings.resource.id,
        uiSettings.resource.userTypes,
        uiSettings.settings.enableLmCapacityView,
        uiSettings.settings.enableProjectPlanner,
        uiSettings.settings.enableResourceCapacityView,
        uiSettings.settings.enableResourcePlanner,
        uiSettings.settings.reports,
    ]);

    return {
        navbarItems,
        navbarFarItems,
    };
};

const NavbarButton = (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
    return item?.data?.link ? (
        <Link to={item?.data?.link} style={{ height: '100%', display: 'flex' }}>
            <CommandBarButton
                iconProps={item.iconProps}
                className={`menu-item ${item.data.active ? 'is-checked' : ''}`}
                // If the button is a link, let the Link component handle the click
                // onClick={item?.data?.link ? undefined : item.onClick}
                onClick={item.onClick}
            >
                {item.name}
            </CommandBarButton>
        </Link>
    ) : (
        <CommandBarButton
            iconProps={item.iconProps}
            className={`menu-item ${item.data.active ? 'is-checked' : ''}`}
            // If the button is a link, let the Link component handle the click
            // onClick={item?.data?.link ? undefined : item.onClick}
            onClick={item.onClick}
        >
            {item.name}
        </CommandBarButton>
    );
};
