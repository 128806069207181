import { ITreeNode } from "@pum-spark/tree-render";
import { ITreeNode as TPTreeNode } from "../../forms/_builders/tpComplexBuilder/components/tree/interfaces/ITreeNode";

const parseToModernTreeNode = (node: TPTreeNode) : ITreeNode => {
	if (node == null) return null;
	return {
		id: node.id,
		name: node.name,
		path: node.fullName,
		children: node.children?.map(_ => parseToModernTreeNode(_))
	} as ITreeNode;
}

const parseToLegacyTreeNode = (node: ITreeNode) : TPTreeNode => {
	if (node == null) return null;
	return {
		id: node.id,
		name: node.name,
		fullName: node.path,
		children: node.children?.map(_ => parseToLegacyTreeNode(_))
	} as TPTreeNode;
}

const getNodesInternal = (tree: ITreeNode, filter: (node: ITreeNode) => boolean, arr: ITreeNode[]) : ITreeNode[] => {
	if (!tree?.children?.length) {
		return arr;
	}
	for (const node of tree.children) {
		getNodesInternal(node, filter, arr);
		if (filter(node)) {
			arr.push(node);
		}
	}
	return arr;
}

const getNodeNames = (tree: ITreeNode, ids: string[]) : string[] => {
	return getNodes(tree, node => ids.indexOf(node.id) >= 0).map(_ => _.name);
}

const getNodes = (tree: ITreeNode, filter: (node: ITreeNode) => boolean) : ITreeNode[] => {
	const result = [];
	getNodesInternal(tree, filter, result);
	return result;
}

export const sortableTreeHelper = {
	parseToModernTreeNode, parseToLegacyTreeNode, getNodeNames
}