/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, memo } from 'react';
import { TextField } from '@fluentui/react';
import { DynamicProps } from './DynamicProps';
 
interface IProps extends DynamicProps<string, string | number> { 
	Type?: string;

	Multiline?: boolean;
	Resizable?: boolean;
}

const OnKeyDown = (e, type: string) => {
	// hack to disable 'e' in number fields
	// for some reason this is allowed in the fabric component
	if (type === "number" && e.keyCode === 69) e.preventDefault();
}

const DynamicTextField: FunctionComponent<IProps> = props => {

    return  <TextField 
				key={ props.Key }
				defaultValue={ props.Value != null ? props.Value : "" }
				onKeyDown={ event => OnKeyDown(event, props.Type ) }
				onChange={(obj, value) => {
					props.OnChange(props.PropertyInfo.PropertyName, props.Type === "number" ? +value : value); 
}
				}
				disabled={ props.ReadOnly }
				type={ props.Type || "text" }
				multiline={props.Multiline}
				resizable={props.Resizable}
			/>
}

const comparisonFn = (prevProps: IProps, nextProps: IProps) => {
	return prevProps.Value === nextProps.Value;
};

export default memo(DynamicTextField, comparisonFn);