import { IFormItemBuilder, FormLabel, IFormItem, IDynamicPropertyComponentConfig, IItemRenderProps, IFormItemPropertyOptions, IFormItemBuilderResult, buildPropertyRenderInfo, getValidationMarkForProperty, ValidationMark } from "@wiberg/formbuilder";
import { ColorField } from "./components/ColorField";
import { CronField } from "./components/CronField";
import { MessageBarField } from "./components/MessageBarField";
import { NumberSliderField } from "./components/NumberSliderField";
import { TextFieldCopyValue } from "./components/TextFieldCopyValue";
import { ITpPropertyTypes, tpPropertyTypes } from "../tpPropertyTypes";
import { FluentPropertyLabel } from "@wiberg/fluentui-builder";
import { TextFieldEnhanced } from "./components/TextFieldMultiline";

export const createTpSimpleBuilder = () : IFormItemBuilder => {

    const id: Readonly<string> = "tpSimpleBuilder";
    
    const BuilderLabelRender: FormLabel = FluentPropertyLabel; // re-using the label from the fluent builder.

    const build = <T extends IFormItem, C extends IDynamicPropertyComponentConfig<T>>(renderProps: IItemRenderProps<T>, property: string, schema: IFormItemPropertyOptions<T, C>): IFormItemBuilderResult => {
        const { item } = renderProps;
        
        // guards
        if (item === null) throw Error("item is null");
        if (schema == null) throw Error("schema is null");

        // build the info needed for the render (tis is a supplied method)
        const info = buildPropertyRenderInfo(renderProps, schema, property);
        const validationMark: ValidationMark = getValidationMarkForProperty(renderProps, property);

        // add a label for the property name
        const WrapInLabel = (element: JSX.Element, addErrormessage?: boolean) : JSX.Element => {
            return (
                <div className="formbuilder-property" key={info.props.key}>
                    <BuilderLabelRender
                        key={`${info.key}-labelcontainer`}
                        propertySchema={schema}
                        hideLabel={info.props.options.hideLabel}
                        parentKey={info.key}
                        validationMark={validationMark}
                    />
                    { element }
                </div>
            )
        }

        // extending the default propertypes that ships with the formbuilder
        const tpPropertyType: ITpPropertyTypes = tpPropertyTypes;

        // render based on property type
        switch (schema.propertyType) {
            case tpPropertyType.cron: return { found: true, element: WrapInLabel(<CronField {...schema} {...info.props} />) };
            case tpPropertyType.color: return { found: true, element: WrapInLabel(<ColorField {...schema} {...info.props} />) };
			case tpPropertyType.numberSlider: return { found: true, element: WrapInLabel(<NumberSliderField {...schema} {...info.props} />) };
			case tpPropertyType.messageBar: return { found: true, element: WrapInLabel(<MessageBarField {...schema} {...info.props} />) };
			case tpPropertyType.textfieldCopyvalue: return { found: true, element: WrapInLabel(<TextFieldCopyValue {...schema} {...info.props} />) };
			case tpPropertyType.multilineString: return { found: true, element: WrapInLabel(<TextFieldEnhanced {...schema} {...info.props} />) };
            default: return { found: false, element: undefined }
        }
    };

    return { id, build, loadingComponent: undefined, listComponent: undefined };
}