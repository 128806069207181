import { RouteObject } from 'react-router';
import { GridType } from '../Entities/GridType';
import { getRouteFromEnum, MainView } from '../Entities/MainView';
import { OverviewContext } from '../Utilities/Context/OverviewContext';
import { UrlEx } from '../Utilities/UrlEx';
import { guid } from './guid';
interface RouteMeta {
    relativePath: string;
    caseSensitive: boolean;
    childrenIndex: number;
    route: RouteObjectWithName;
	name?: string
}

interface RouteBranch {
    path: string;
    score: number;
    routesMeta: RouteMeta[];
}

export type RouteObjectWithName = Omit<RouteObject, "children"> & { name?: string, children?: RouteObjectWithName[] }

export function flattenRoutes(routes: RouteObjectWithName[], branches: RouteBranch[] = [], parentsMeta: RouteMeta[] = [], parentPath = ''): RouteBranch[] {
    routes.forEach((route, index) => {
        const foundName = parentsMeta.find(route => route.name !== undefined);
        const meta: RouteMeta = {
            relativePath: route.path || '',
            caseSensitive: route.caseSensitive === true,
            childrenIndex: index,
            route,
            name: foundName ? foundName.name : route.name,
        };

        if (meta.relativePath.startsWith('/')) {
            meta.relativePath = meta.relativePath.slice(parentPath.length);
        }

        const path = joinPaths([parentPath, meta.relativePath]);
        const routesMeta = parentsMeta.concat(meta);

        // Add the children before adding this route to the array so we traverse the
        // route tree depth-first and child routes appear before their parents in
        // the "flattened" version.
        if (route.children && route.children.length > 0) {
            flattenRoutes(route.children, branches, routesMeta, path);
        }

        // Routes without a path shouldn't ever match by themselves unless they are
        // index routes, so don't add them to the list of possible branches.
        if (route.path == null && !route.index) {
            return;
        }

        branches.push({ path, score: computeScore(path, route.index), routesMeta });
    });

    return branches;
}

const joinPaths = (paths: string[]): string => paths.join('/').replace(/\/\/+/g, '/');

const isSplat = (s: string) => s === '*';
const paramRe = /^:\w+$/;
const dynamicSegmentValue = 3;
const indexRouteValue = 2;
const emptySegmentValue = 1;
const staticSegmentValue = 10;
const splatPenalty = -2;

function computeScore(path: string, index: boolean | undefined): number {
    const segments = path.split('/');
    let initialScore = segments.length;
    if (segments.some(isSplat)) {
        initialScore += splatPenalty;
    }

    if (index) {
        initialScore += indexRouteValue;
    }

    return segments
        .filter(s => !isSplat(s))
        .reduce(
            (score, segment) => score + (paramRe.test(segment) ? dynamicSegmentValue : segment === '' ? emptySegmentValue : staticSegmentValue),
            initialScore,
        );
}

export const buildRoute = (...args: (string | number | null | undefined)[]) =>
    (
        args.reduce((route, part) => {
            if (part !== undefined && part !== null && part !== '') {
                return route + '/' + part;
            }
            return route;
        }, '') as string
    ).replace(/\/\/+/g, '/');

export const getLegacyDeepLink = () => {
    // handle "deep-link" to views
    const viewTypeParam = UrlEx.GetUrlParamHashCompatibility('viewType');
    const gridType = viewTypeParam ? GridType[viewTypeParam] : null;
    const viewParam = UrlEx.GetUrlParamHashCompatibility('view');
    const view: MainView = viewParam ? MainView[viewParam] : null;

    // handle "single project view"
    // When IFrame in CDS is showing TP - and pass parameters are selected, id represents projectid and therefore we select the project as default in app.
    const projId = UrlEx.GetUrlParamHashCompatibility('id');
    if (projId) {
        OverviewContext.SingleProjectView = true; //TODO: change this! (ewi)
		const route = buildRoute('legacylink', projId);
        return route;
    }

	// more crap incoming (ewi)
	const projectId = UrlEx.GetUrlParamHashCompatibility('projectId');
    if (projectId) {
		const route = buildRoute('legacylink', projectId);
        return route;
    }

    let resourceId = UrlEx.GetUrlParamHashCompatibility('resourceId');
    if (!guid.isGuidNotEmpty(resourceId)) {
        resourceId = null;
    }

    if (view) {
        if (window.location.hash) {
            // quitely reset the URL bar without refreshing the page
            window.history.replaceState({}, 'replace' + Math.random().toFixed(3), window.location.pathname);
        }

        const viewRoute = view === MainView.ProjectCapacity ? (gridType === GridType.Allocation ? 'allocation' : 'request') : getRouteFromEnum(view);

        let extraRouteParam = null;
        if (view === MainView.ResourceCapacity) {
            extraRouteParam = resourceId;
        }
        if (view === MainView.ProjectCapacity) {
            extraRouteParam = projId;
        }

        const route = buildRoute(viewRoute, extraRouteParam);
        return route;
    }
    return null;
};
