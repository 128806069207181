import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyValidation } from "../../Utilities/PropertyInfo/Validation";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { PFPropertyOnChange } from "../../Utilities/PropertyInfo/OnChange";
import { OverviewContext } from "../../Utilities/Context/OverviewContext";
import { PFPropertyGrouping } from "../../Utilities/PropertyInfo/Grouping";
import { guid } from "../../helpers/guid";
import { DateEx } from "../../Utilities/DateEx";
import { ResourceType } from "../ResourceType";
import { IObjectEditorItemType } from "./IObjectEditorItemType";
import { ProjectType } from "../ProjectType";
import { DropdownOptionEx } from "../../Utilities/DropdownOptionEx";
import { ProjectWithStringVariants } from "../../ListItems/ProjectWithStringVariants";
import { ITPENtity } from "./ITPEntity";
import { MessageBarType } from "@fluentui/react";
import { language } from "../../Services/LocalizationService";
import { ActivityDistributionConfig } from "../ActivityDistribution/ActivityDistributionConfig";

export class Project implements IObjectEditorItemType, ITPENtity {
	public TypeName: string = "Project";
	
	@PFPropertyBasicInfo(PropertyType.MessageBar, null, 0, "_localization:Project.ActiveDescription")
	@PFPropertyRender((obj, i, col) => obj.Active?.toString(), { Options: (item: Project) => {
 return { message: item.IsActive ? language.Project.ActiveState : language.Project.InActiveState, type: item.IsActive ? MessageBarType.success : MessageBarType.warning, width: "620px" } 
}}, null, (item: Project) => !item.Created, null, 100)
	@PFPropertyGrouping("Basic", 1, false)
    public IsActive: boolean;

	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.Name", 1)
	@PFPropertyValidation("_localization:Project.ProjectValidation", (obj: Project) => obj.Name && obj.Name !== "", null, () => true)
	@PFPropertyGrouping("Basic", 1, false)
	public Name: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Owner", 2)
	@PFPropertyRender((item: ProjectWithStringVariants, i, col) => item[col.fieldName] && item.OwnerAsString, { Types: [ResourceType.Named] }, "ResourcePicker")
	@PFPropertyValidation("_localization:Project.OwnerIsRequiredField", (obj: Project) => obj.Owner && obj.Owner !== "", null, () => true)
	@PFPropertyGrouping("Basic")
    public Owner: string;
	@PFPropertyBasicInfo(PropertyType.String, "_localization:Common.Url", 4)
	@PFPropertyGrouping("Basic")
	public Url: string;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Project.Start", 5)
	@PFPropertyGrouping("Basic")
	@PFPropertyRender(null, null, null, (item: Project) => item.ProjectType !== ProjectType.Default)
    public Start: Date;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Project.End", 6)
	@PFPropertyGrouping("Basic")
	@PFPropertyRender(null, null, null, (item: Project) => item.ProjectType !== ProjectType.Default)
    public End: Date;

	@PFPropertyBasicInfo(PropertyType.PredefinedArray, "_localization:Project.ProjectType", 6)
	@PFPropertyRender((item: Project) => ProjectType[item.ProjectType], { PredefinedValues: DropdownOptionEx.enumToDropdownOptions<ProjectType>(ProjectType) }, null, null, null, 100)
	@PFPropertyOnChange((obj: Project) => {
 if (obj.ProjectType !== ProjectType.Administrative) obj.RBS = null; 
})
	@PFPropertyGrouping("Type", 2)
	public ProjectType: ProjectType;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Project.ParentProject", 8, "_localization:Project.ParentProjectDescription")
	@PFPropertyRender((item: ProjectWithStringVariants, i, col) => item[col.fieldName] && item.ParentIdAsString, { IncludeInActive: true }, "ProjectPicker", (item: Project) => item.ProjectType === ProjectType.Team)
	@PFPropertyValidation("_localization:Project.AdministrativeProjectMustHaveParentProject", (obj: Project) => !OverviewContext.Settings.ForceParentOnAdministrativeProjects || obj.ParentId != null)
	@PFPropertyGrouping("Type")
	public ParentId: string;

	
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.AdditionalOwners", 3)
	@PFPropertyRender((item: ProjectWithStringVariants, i, col) => item[col.fieldName] && item.AdditionalOwnersAsString, { MultiSelect: true, Types: [ResourceType.Named] }, "ResourcePicker")
	@PFPropertyGrouping("Additional owners", 2)
	public AdditionalOwners: Array<string>;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.AdditionalOwnersAADGroup", 4)
	@PFPropertyRender(null, null, "AadGroupPicker", obj => !OverviewContext.Settings.EnableProjectOwnerADGroup)
	@PFPropertyGrouping("Additional owners")
	public AdditionalOwnersGroup: string; // guid


	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.CustomPropertiesCapitalized", 13, "Custom project properties")
	@PFPropertyRender(null, { Type: "project" }, "CustomPropertiesSelector")
	@PFPropertyValidation("_localization:Project.CustomPropertiesValidation", (item: Project) => item.ProjectType === ProjectType.Team || OverviewContext.Settings.ProjectCustomProperties.filter(_ => _.Required).every(_ => item.CustomProperties && item.CustomProperties[_.Id] != null), (item: Project) => `Required fields: ${OverviewContext.Settings.ProjectCustomProperties.filter(_ => _.Required).map(_ => _.DisplayName).join(", ")}`)
	@PFPropertyGrouping("Custom properties", 4)
	public CustomProperties: { [key: string]: string };
    
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Rbs", 7)
	@PFPropertyRender((item: ProjectWithStringVariants, i, col) => item[col.fieldName] && item.RBSAsString, { Type: "select", SingleSelect: true }, "RbsEditor", (item: Project) => !OverviewContext.Settings.ProjectsAlwaysShowRBS && item.ProjectType === ProjectType.Default)
	@PFPropertyGrouping("RBS", 5)
	public RBS: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.AdditionalRbs", 10)
	@PFPropertyRender(null, { Type: "select", ButtonTitle: "_localization:Common.AdditionalRbs" }, "RbsEditor", (project: Project) => project.ProjectType === ProjectType.Default)
	@PFPropertyGrouping("RBS")
	public AdditionalRBS: Array<string>;
	
	
	
	// SYSTEM
	@PFPropertyBasicInfo(PropertyType.StringCopyValue, "_localization:Common.Id", 1)
	@PFPropertyGrouping("System", 10)
	@PFPropertyRender(null, null, null, null, (res: Project) => res.Created != null)
	public id: string;
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.OriginIds", 3)
	@PFPropertyGrouping("System")
	@PFPropertyRender(null, null, "OriginIdRender")
	public OriginIds: { [key: string]: string };
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:Common.Origins", 4)
	@PFPropertyGrouping("System")
	@PFPropertyRender(null, null, "OriginRender")
	public Origins: Array<string>;
	@PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Created", 10)
	@PFPropertyGrouping("System")
	@PFPropertyRender(null, { DisplayFormat: (date: Date) => DateEx.asUserLocalWithTime(date) }, null, null, null, 120)
    public Created: Date;
    @PFPropertyBasicInfo(PropertyType.Date, "_localization:Common.Modified", 11)
	@PFPropertyGrouping("System")
	@PFPropertyRender(null, { DisplayFormat: (date: Date) => DateEx.asUserLocalWithTime(date) }, null, null, null, 120)
    public Modified: Date;
    @PFPropertyBasicInfo(PropertyType.String, "_localization:Common.CreatedBy", 12)
	@PFPropertyGrouping("System")
    public CreatedBy: string;
    @PFPropertyBasicInfo(PropertyType.String, "_localization:Common.ModifiedBy", 13)
	@PFPropertyGrouping("System")
	public ModifiedBy: string;
	@PFPropertyBasicInfo(PropertyType.StringCopyValue, "_localization:Project.TeamResource", 7)
	@PFPropertyGrouping("System")
	@PFPropertyRender((item: ProjectWithStringVariants, i, col) => item[col.fieldName] && item.TeamResourceAsString, null, null, (project: Project) => project.ProjectType !== ProjectType.Team, (project: Project) => true)
	public TeamResource: string; // guid

	// only used in project overview
	public OwnerName: string;

	// only used in fac settings
	public ActivityDistributionConfig: ActivityDistributionConfig;

	constructor() {
		this.id = guid.newGuid();
		this.CustomProperties = {};
		this.Origins = [guid.empty];
	}
}
