import { ITextFieldProps, TextField as FluentTextField } from '@fluentui/react';
import { forwardRef } from 'react';

export const TextFieldWithForwardRef = forwardRef<HTMLInputElement | null, ITextFieldProps>((props, ref) => {
	return <FluentTextField {...props} componentRef={(stuff: any) => {
		if (stuff?._textElement) {
			(ref as any).current = stuff._textElement.current;
		}
	}} />;
});
