import { TimelineResolution } from '../Entities/TimelineResolution';
import { DateEx } from './DateEx';
import { DateCell } from '../Components/Overview/Timeline/DateCell';
import { language } from '../Services/LocalizationService';
import { OverviewContext } from './Context/OverviewContext';
import { DateResolution, RootSettings } from '../api/generated/data-contracts';

export function generateDates(start: Date, end: Date, resolution: TimelineResolution): Array<DateCell> {
    switch (resolution) {
        case TimelineResolution.Year:
            return getYears(start, end).map((_, idx) => new DateCell(_.start, _.end, idx));
        case TimelineResolution.Quarter:
            return getQuarters(start, end).map((_, idx) => new DateCell(_.start, _.end, idx));
        case TimelineResolution.Month:
            return getMonths(start, end).map((_, idx) => new DateCell(_.start, _.end, idx));
        case TimelineResolution.Week:
            return getWeeks(start, end).map((_, idx) => new DateCell(_.start, _.end, idx));
        case TimelineResolution.Day:
            return getDays(start, end).map((_, idx) => new DateCell(_.start, _.end, idx));
        default:
            return null;
    }
}

export function generateHeaders(dates: Array<DateCell>, resolution: TimelineResolution, settings: RootSettings): { [key: string]: number } {
    const header = {};
    const months = [
        language.Timeline.Jan,
        language.Timeline.Feb,
        language.Timeline.Mar,
        language.Timeline.Apr,
        language.Timeline.May,
        language.Timeline.Jun,
        language.Timeline.Jul,
        language.Timeline.Aug,
        language.Timeline.Sep,
        language.Timeline.Oct,
        language.Timeline.Nov,
        language.Timeline.Dec,
    ];
    let headerFunction: (date: Date) => string;
    switch (resolution) {
        case TimelineResolution.Year:
            headerFunction = (date: Date) => Math.floor(date.getFullYear() / 1e1) + "0's";
            break;
        case TimelineResolution.Quarter:
            headerFunction = (date: Date) => date.getFullYear() + '';
            break;
        case TimelineResolution.Month:
            headerFunction = (date: Date) => date.getFullYear() + '';
            break;
        case TimelineResolution.Week:
            headerFunction = (date: Date) => `${months[date.getMonth()]} - ${date.getFullYear()}`;
            break;
        case TimelineResolution.Day:
            headerFunction = (date: Date) => {
                // const weekNumber = DateEx.getWeekNumber(date, OverviewContext.Settings.CultureInfo, OverviewContext.Settings.UseISO8601);
                const weekNumber = DateEx.getWeekNumber(date, settings.cultureInfo, settings.useISO8601);
                return `${language.Common.Week} ${weekNumber.week} - ${weekNumber.year}`;
            };
            break;
        default:
            break;
    }

    dates.forEach(_ => {
        let headerTitle = headerFunction(_.Start);

        if (resolution === TimelineResolution.Week) {
            // make week 1 to be in new year (for week view)
            if (_.Start.getFullYear() !== _.End.getFullYear()) headerTitle = headerFunction(_.End);
            // distribute weeks over months (using the rule, most days wins)
            else if (_.Start.getMonth() !== _.End.getMonth()) {
                const firstMonthDays = DateEx.daysInMonth(_.Start.getMonth(), _.Start.getFullYear()) - _.Start.getDate();
                const lastMonthDays = _.End.getDate();
                headerTitle = firstMonthDays > lastMonthDays ? headerFunction(_.Start) : headerFunction(_.End);
            }
        }

        if (!header[headerTitle]) header[headerTitle] = 0;
        header[headerTitle] += 1;
    });

    return header;
}

export function cellTitle(date: Date, end: Date, resolution: TimelineResolution, settings: RootSettings): string {
    switch (resolution) {
        case TimelineResolution.Year:
            return date.getFullYear() + '';
        case TimelineResolution.Quarter:
            return `Q${DateEx.getQuarter(date).quarter}`;
        case TimelineResolution.Month: {
            const months = [
                language.Timeline.Jan,
                language.Timeline.Feb,
                language.Timeline.Mar,
                language.Timeline.Apr,
                language.Timeline.May,
                language.Timeline.Jun,
                language.Timeline.Jul,
                language.Timeline.Aug,
                language.Timeline.Sep,
                language.Timeline.Oct,
                language.Timeline.Nov,
                language.Timeline.Dec,
            ];
            return months[date.getMonth()];
        }
        case TimelineResolution.Week:
            return DateEx.getWeekNumber(date, settings.cultureInfo, settings.useISO8601).week;
        case TimelineResolution.Day:
            return `${date.getDate()}.`;
        default:
            return null;
    }
}

export function getTimelineHistoricalStartEnd(resolution: TimelineResolution, settings: RootSettings): { start: Date; end: Date } {
    const dayBackwards = OverviewContext.AllowTimelineBackInTime() ? settings.timelineDayResolutionMaxDaysBackwards : 0;
    const backwards = OverviewContext.AllowTimelineBackInTime() ? settings.cacheContractConstraint?.backward ?? 0 : 0;

    let newStart: Date;
    let newEnd: Date;

    switch (settings.cacheContractConstraint.resolution) {
        case DateResolution.Day:
            newStart = DateEx.addDay(DateEx.todaysDate(), dayBackwards);
            newEnd = DateEx.addDay(DateEx.todaysDate(), settings.timelineDayResolutionMaxDaysForward);
            break;
        case DateResolution.Week:
            newStart = DateEx.addWeek(DateEx.todaysDate(), -backwards);
            newEnd = DateEx.addWeek(DateEx.todaysDate(), settings.cacheContractConstraint.forward);
            break;
        case DateResolution.Month:
            newStart = DateEx.addMonth(DateEx.todaysDate(), -backwards);
            newEnd = DateEx.addMonth(DateEx.todaysDate(), settings.cacheContractConstraint.forward);
            break;
        case DateResolution.Quarter:
            newStart = DateEx.addQuarter(DateEx.todaysDate(), -backwards);
            newEnd = DateEx.addQuarter(DateEx.todaysDate(), settings.cacheContractConstraint.forward);
            break;
        case DateResolution.Year:
            newStart = DateEx.addYear(DateEx.todaysDate(), -backwards);
            newEnd = DateEx.addYear(DateEx.todaysDate(), settings.cacheContractConstraint.forward);
            break;
        default:
            break;
    }

    switch (resolution) {
        case TimelineResolution.Day:
            return { start: newStart, end: newEnd };
        case TimelineResolution.Week:
            if (OverviewContext.AllowTimelineBackInTime()) {
                newStart = DateEx.getFirstDayOfWeek(newStart, settings.useISO8601);
            } else newStart = DateEx.getFirstDayOfWeek(undefined, settings.useISO8601);

            newEnd = DateEx.lastDayOfWeek(newEnd, settings.useISO8601);
            return { start: newStart, end: newEnd };
        case TimelineResolution.Month:
            if (OverviewContext.AllowTimelineBackInTime()) {
                newStart = DateEx.getFirstDayOfMonth(newStart);
            } else newStart = DateEx.getFirstDayOfMonth();

            newEnd = DateEx.lastDayOfMonth(newEnd);
            return { start: newStart, end: newEnd };
        case TimelineResolution.Quarter:
            if (OverviewContext.AllowTimelineBackInTime()) {
                newStart = DateEx.getFirstDayOfQuarter(newStart);
            } else newStart = DateEx.getFirstDayOfQuarter();

            newEnd = DateEx.lastDayOfQuarter(newEnd);
            return { start: newStart, end: newEnd };
        case TimelineResolution.Year:
            if (OverviewContext.AllowTimelineBackInTime()) {
                newStart = DateEx.getFirstDayOfYear(newStart);
            } else newStart = DateEx.getFirstDayOfYear();

            newEnd = DateEx.lastDayOfYear(newEnd);
            return { start: newStart, end: newEnd };
        default:
            break;
    }
}

// HELPERS

export function getYears(startDate: Date, stopDate: Date): Array<{ start: Date; end: Date }> {
    return GetDates(startDate, stopDate, d => addYears(d, 1));
}

export function getMonths(startDate: Date, stopDate: Date): Array<{ start: Date; end: Date }> {
    return GetDates(startDate, stopDate, d => addMonths(d, 1));
}

export function getQuarters(startDate: Date, stopDate: Date): Array<{ start: Date; end: Date }> {
    return GetDates(startDate, stopDate, d => addQuarters(d, 1));
}

export function getWeeks(startDate: Date, stopDate: Date): Array<{ start: Date; end: Date }> {
    return GetDates(startDate, stopDate, d => addWeeks(d, 1));
}

export function getDays(startDate: Date, stopDate: Date): Array<{ start: Date; end: Date }> {
    return GetDates(startDate, stopDate, d => addDays(d, 1));
}

export function addYears(date: Date, years: number): Date {
    return AddDates(date, d => d.setFullYear(d.getFullYear() + years));
}

export function addMonths(date: Date, months: number): Date {
    return AddDates(date, d => d.setMonth(d.getMonth() + months));
}

export function addQuarters(date: Date, quaters: number): Date {
    return AddDates(date, d => {
        const quarter = DateEx.addQuarter(d, quaters);
        d.setFullYear(quarter.getFullYear());
        d.setMonth(quarter.getMonth());
        d.setDate(quarter.getDate());
    });
}

export function addWeeks(date: Date, weeks: number): Date {
    return AddDates(date, d => d.setDate(d.getDate() + weeks * 7));
}

export function addDays(date: Date, days: number): Date {
    return AddDates(date, d => d.setDate(d.getDate() + days));
}

// base function
function AddDates(date: Date, addFunction: (d: Date) => void): Date {
    const newDate = new Date(date.valueOf());
    addFunction(newDate);
    return newDate;
}

// base function
function GetDates(startDate: Date, stopDate: Date, addFunction: (date: Date) => Date): Array<{ start: Date; end: Date }> {
    const dateArray = new Array<{ start: Date; end: Date }>();
    let currentDate = startDate;
    while (currentDate <= stopDate) {
        const values = { start: currentDate, end: addDays(addFunction(currentDate), -1) };
        dateArray.push(values);
        currentDate = addFunction(currentDate);
    }
    return dateArray;
}

export const TimelineUtil = {
    addDays,
    addMonths,
    addQuarters,
    addWeeks,
    addYears,
    cellTitle,
    generateDates,
    generateHeaders,
    getDays,
    getMonths,
    getQuarters,
    getTimelineHistoricalStartEnd,
    getWeeks,
    getYears,
};
