import { Navigate, Outlet } from 'react-router-dom';
import { useTPPermissions, IusePermissions } from '../../hooks/usePermissions';

interface IRequireAuthProps extends IusePermissions {
	redirect?: string;
	fallback?: React.ReactNode
}

// CONTEXT: subscribe to context(s) to determine
export const RequireAuth: React.FC<IRequireAuthProps> = ({ userTypes, not, some, children, redirect = "/accessdenied", fallback }) => {
    // const location = useLocation();
    const isPermitted = useTPPermissions({ userTypes, not, some });

    // no permissions for route
    if (!isPermitted) {
		if (fallback !== undefined) {
            return <>{fallback}</>;
        }
        return <Navigate to={redirect} replace />;
    }

    return <>{children !== undefined ? children : <Outlet />}</>;
};
