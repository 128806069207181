import { DefaultButton, Stack, Text } from '@fluentui/react';
import { useMemo } from 'react';
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom';
import { useGetDefaultRoute } from '../hooks/useGetDefaultRoute';

const safeDecode = (string: string) => {
    try {
        return decodeURI(string);
    } catch (error) {
        return string;
    }
};

export const NotFound: React.FC = () => {
	const { pathname } = useLocation();
	const { notfound } = useParams<{ notfound: string; }>();
	const goTo = useNavigate();
	const { path, onClick } = useMemo(() => {
		if (notfound === 'uri-bug') {
			return {
				path: window.location.origin + safeDecode(pathname.slice(8)),
				onClick: () => goTo(-2),
			};
		}
		return {
			path: window.location.origin + safeDecode(pathname),
			onClick: () => goTo(-1),
		};
	}, [goTo, notfound, pathname]);
	const defaultRotue = useGetDefaultRoute()
	return (
		<Stack styles={{ root: { height: '70vh' } }} verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: 10 }}>
			<Text variant="xxLarge">Page not found</Text>
			<Stack verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: 20 }}>
				<Text variant="large">
					<strong>{path}</strong> does not exist
				</Text>
				<Stack verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: 20, padding: 30 }}>
					<NavLink to={defaultRotue}>
						<DefaultButton>Click here to go to home</DefaultButton>
					</NavLink>
					<DefaultButton onClick={onClick}>Click here to go back</DefaultButton>
				</Stack>
			</Stack>
		</Stack>
	);
};
