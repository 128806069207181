import { useId } from '@fluentui/react-hooks';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '../../../../../../../Themes/themeContext';
import { defaultNumberParser } from '../../../../../../../Utilities/NumberParser';
import { useUnitType } from '../../../../../../../Utilities/UnitTypeUtil';
import { GridCell, UnitType } from '../../../../../../../api/generated/data-contracts';
import { useUISettings } from '../../../../../../../context/network/http/QueryProvider/queries/UISettings';
import { useStore } from '../../../../../../../context/store';
import { killEvent } from '../../../../../../../helpers/killEvent';
import Big, { bigZero } from '../../../../../../../helpers/math';
import { useAnimationProp } from '../../../../../../../hooks/useAnimationProp';
import { useControlledState } from '../../../../../../../hooks/useControlledState';
import { useGridContext } from '../../../../../_grid/contexts/gridContext';
import { useGridRowContext } from '../../../../../_grid/contexts/rowContext';
import { useDragContext } from '../../context/dragContext';
import { TextFieldWithForwardRef } from './TextField';
import { ErrorMessage } from './components/ErrorMessage';
import { useAnimationListener } from './hooks/useAnimationListener';
import { useCheckOverAllocationProjectPlanner } from './hooks/useCheckOverAllocationProjectPlanner';

const big100000 = new Big('100000');
type CellProps = {
    cell: GridCell;
    rowId: string;
    columnIndex: number;
    cellIndex: number;
};

export const Cell = memo(function Cell({ cell, cellIndex, columnIndex, rowId }: CellProps) {
    const { row } = useGridRowContext();
    const { updateCellValue, readonly, useGridCellValue, getRowAncestorTreeToRoot } = useGridContext();
    const [errorMessage, setErrorMessage] = useState('');
    const warning = useStore(store => store.addWarningNotification);
    const storeValue = useGridCellValue(cell);
    const [value, setValue] = useControlledState(() => String(storeValue), [storeValue]);
    const successHighlight = useAnimationListener(storeValue, 'update-success');
    const localChangeHighlight = useAnimationListener(value, 'update-local', 500);
    const [updateErrorHighlight, showUpdateErrorHighlight] = useAnimationProp('update-error', 1000);
    const ref = useRef<HTMLInputElement | null>(null);
    const [isFocused, setIsFocused] = useState(false);

    const onGetUpdateCellErrorMessage = useCheckOverAllocationProjectPlanner();

    const rowFamilyTree = useMemo(() => {
        return getRowAncestorTreeToRoot(rowId);
    }, [getRowAncestorTreeToRoot, rowId]);

    const onError = useCallback(
        (msg: string) => {
            setErrorMessage(msg);
            showUpdateErrorHighlight();
        },
        [showUpdateErrorHighlight],
    );

	useEffect(() => {
		setErrorMessage("");
	}, [storeValue])


    const { bindDragHandler, dragState } = useDragContext({
        cell,
        setLocalCellValue: setValue,
        columnIndex,
        cellIndex,
        rowId,
        onError,
    });

    const onBlurHandler = useCallback(
        async (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
            setIsFocused(false);
            if (dragState.current) {
                // We let useDragGridCell handle the updating when dragging
                return;
            }
            const parsedNumber = defaultNumberParser.Parse(value);
            if (Number.isNaN(parsedNumber)) {
                return setErrorMessage('Not a valid number');
            }
            const newValue = new Big(parsedNumber.toString());
            if (newValue.gte(bigZero) && !newValue.eq(storeValue)) {
                if (newValue.gt(big100000)) {
                    const message = 'The entered value is too high and has not been saved! Please change it to a value below 100000'; //TODO LOCALIZATION
                    warning(message);
                    onError(message);
                    e.stopPropagation();
                    e.preventDefault();
                    return;
                }
                const { errorMessage, changedValue } = await onGetUpdateCellErrorMessage({
                    cell,
                    cellIndex,
                    columnIndex,
                    newCellValue: newValue,
                    rowFamilyTree,
                    row,
                });
                if (errorMessage) {
                    onError(errorMessage);
                    return;
                }
                updateCellValue({ cell, cellIndex, columnIndex, newValue: changedValue, rowId }).catch(error => {
                    console.error(error);
                    setErrorMessage(error.message);
                });
                setErrorMessage('');
            }
        },
        [dragState, value, storeValue, onGetUpdateCellErrorMessage, cell, cellIndex, columnIndex, rowFamilyTree, row, updateCellValue, rowId, warning, onError],
    );

    const onChangeHandler = useCallback(
        (_: any, newValue?: string) => {
            if (newValue !== undefined) {
                setValue(newValue);
                setErrorMessage('');
            }
        },
        [setValue],
    );

    const onFocusHandler = useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        setIsFocused(true);
    }, []);

    useEffect(() => {
        if (isFocused) {
            ref.current.select();
        }
    }, [isFocused]);

    const theme = useTheme();
    const dragHandleStyle = useMemo((): React.CSSProperties => {
        return {
            position: 'absolute',
            bottom: '-1px',
            right: '-1px',
            height: 8,
            width: 8,
            backgroundColor: theme.palette.accent,
            border: `1px solid ${theme.semanticColors.bodyBackground}`,
            touchAction: 'none',
            display: isFocused ? 'block' : 'none',
            cursor: 'cell',
        };
    }, [isFocused, theme.palette.accent, theme.semanticColors.bodyBackground]);

    const tooltipId = useId('cell-tooltip');

    const displayValue = useCellDisplayValue(value, isFocused);
    const style: React.CSSProperties = useMemo(
        () => ({
            //
            ...(errorMessage && { border: '1px solid red' }),
            ...(!isFocused && localChangeHighlight),
            ...successHighlight,
            // error takes precedence
            ...updateErrorHighlight,
        }),
        [errorMessage, isFocused, localChangeHighlight, successHighlight, updateErrorHighlight],
    );
    return (
        <div className="tp-capacity-cell">
            <TextFieldWithForwardRef
                ref={ref}
                className="tp-capacity-input"
                value={displayValue}
                readOnly={readonly || row.metadata?.Active === false}
                disabled={readonly || row.metadata?.Active === false || cell.disabled}
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                style={style}
                onBlur={onBlurHandler}
                id={tooltipId}
                // We kill the drag event because it fucks with row dragging
                onDragStart={killEvent}
            />
            {/* <div {...bindDragHandler(value)} style={dragHandleStyle} draggable /> */}
            <div {...bindDragHandler(storeValue, ref)} style={dragHandleStyle} />
            <ErrorMessage errorMessage={errorMessage} targetId={tooltipId} />
        </div>
    );
});

const useCellDisplayValue = (value: string, isFocused: boolean) => {
    const { currentUnitType } = useUnitType();
    const { data } = useUISettings();
    return useMemo(() => {
        if (isFocused) {
            return value;
        }
        if (currentUnitType === UnitType.FTE) {
            return Number(value).toFixed(data.settings.gridDecimalsFTE);
        }
        return Number(value).toFixed(data.settings.gridDecimalsHours);
    }, [currentUnitType, data.settings.gridDecimalsFTE, data.settings.gridDecimalsHours, isFocused, value]);
};
