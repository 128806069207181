import React, { memo, useCallback, useMemo, useState } from 'react';
import { IconButton, Spinner, Stack, PersonaCoin, Text, SpinnerSize, Icon } from '@fluentui/react';
import Highlighter from 'react-highlight-words';
import shallow from 'zustand/shallow';
import { ProjectType } from '../../../Entities/ProjectType';
import { EntityType } from '../../../Entities/EntityTypes';
import { language } from '../../../Services/LocalizationService';
import { EventEx } from '../../../Utilities/EventEx';
import { PanelUtil } from '../../../Utilities/PanelUtil';
import { ProjectListItem, RootSettings } from '../../../api/generated/data-contracts';
import { useStore } from '../../../context/store';

const getProjectText = (projectType: ProjectType) => {
    switch (projectType) {
        case ProjectType.Administrative: {
            return 'Admin';
        }
        case ProjectType.Team: {
            return 'Team';
        }
        case ProjectType.Default:
        default: {
            return 'Default';
        }
    }
};

const highlightStyle = { background: 'yellow', padding: 0 };

export const ListItem = memo(
    ({
        item,
        settings,
        onClick,
        searchText,
    }: {
        item: ProjectListItem;
        settings: RootSettings;
        onClick: (project: ProjectListItem) => void;
        searchText: string;
    }) => {

		const [loading, setLoading] = useState(false);

		const initials = useMemo(() => {
            const fieldMap = settings.projectPickerDisplayFields;
            const initials = (item.name || '').match(/\b\w/g);
			const text = PanelUtil.getPickerPropertyValue(item, fieldMap, _ => _.iconOverride);
			if (fieldMap.iconOverride) {
				if (fieldMap.iconOverride === "projectType") {
					return getProjectText(item.projectType)
				}
				if (text === undefined) {
					return " "
				}
				return text
			}
            return ((initials?.shift() ?? '') + (initials?.pop() ?? '')).toUpperCase();
        }, [item, settings]);

        const { secondaryText, tertiaryText, text } = useMemo(() => {
            let thirdLine =
                item.projectType === ProjectType.Administrative
                    ? item.rbsAsString || language.Projects.TertiaryText.All
                    : item.projectType === ProjectType.Team
                    ? language.Projects.TertiaryText.Team
                    : PanelUtil.getPickerPropertyValue(item, settings.projectPickerDisplayFields, _ => _.tertiary) || '';

            const quarternary = PanelUtil.getPickerPropertyValue(item, settings.projectPickerDisplayFields, _ => _.quarternary);
            if (thirdLine && quarternary) {
                thirdLine += ' - ' + quarternary;
            } else if (quarternary) {
                thirdLine = quarternary;
            }

            return {
                // text: PanelUtil.getPickerPropertyValue(item, settings.projectPickerDisplayFields, _ => capitalize(_.title)) || '',
                text: PanelUtil.getPickerPropertyValue(item, settings.projectPickerDisplayFields, _ => _.title) || '',
                secondaryText: PanelUtil.getPickerPropertyValue(item, settings.projectPickerDisplayFields, _ => _.secondary) || '',
                tertiaryText: thirdLine,
            };
        }, [item, settings]);

        const { entityCalloutInfo, setEntityCalloutInfo } = useStore(
            store => ({
                setEntityCalloutInfo: store.setEntityCalloutInfo,
                entityCalloutInfo: store.entityCalloutInfo,
            }),
            shallow,
        );

        const onClickCallout = useCallback(
            (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement>) => {
                EventEx.preventAndStop(e);
                if (entityCalloutInfo) {
                    setEntityCalloutInfo(null);
                } else {
                    setEntityCalloutInfo({
                        Id: item.id,
                        EntityType: EntityType.Project,
                        Target: `#detailicon-${item.id}`,
                        Properties: { Url: item.url },
                    });
                }
            },
            [entityCalloutInfo, item.id, item.url, setEntityCalloutInfo],
        );

        const handleProjectClick = useCallback((project: ProjectListItem) => {
			setLoading(true);
            onClick(project);
        }, [onClick]);

        const coinSize = 65;

        return (
            <Stack data-panelitemid={item.id} className="tp-list-item noselect" onClick={() => handleProjectClick(item)}>
                <Stack
                    id={`pr_${item.id}`}
                    className="tp-list-item-resource-container"
                    horizontal
                    horizontalAlign="space-between"
                    tokens={{ padding: '8px 0 5px 0' }}
                >
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack tokens={{ padding: '5px 10px 0 5px' }}>
                            <Stack
                                styles={{ root: { position: 'relative' } }}
                                id={`persona-project-${item.id}`}
                                onMouseEnter={() => {
                                    setEntityCalloutInfo({
                                        Id: item.id,
                                        EntityType: EntityType.Project,
                                        Target: `#persona-project-${item.id}`,
                                        Properties: { Url: item.url },
                                    });
                                }}
                                onMouseLeave={() => {
                                    setEntityCalloutInfo(null);
                                }}
                            >
                                <PersonaCoin text={item.name} imageInitials={initials} coinSize={coinSize} styles={{coin: {'.ms-Persona-initials': { borderRadius: 0 } }}} />
                                {loading && (
                                    <Spinner
                                        styles={{
                                            root: { height: coinSize, width: coinSize, transform: 'scale(2.6)', position: 'absolute', top: -1, left: -1 },
                                        }}
                                        size={SpinnerSize.large}
                                    />
                                )}
                                <ProjectTypeIcon projectType={item.projectTypeAsString} />
                            </Stack>
                        </Stack>
                        <Stack>
                            <Text className="tp-list-item-resource-name" variant="large" nowrap={false}>
                                {text ? (
                                    //
                                    <Highlighter searchWords={[searchText]} textToHighlight={text} highlightStyle={highlightStyle} autoEscape />
                                ) : (
                                    <>&nbsp;</>
                                )}
                            </Text>
                            <Text variant="medium">
                                {secondaryText ? (
                                    <Highlighter searchWords={[searchText]} textToHighlight={secondaryText} highlightStyle={highlightStyle} autoEscape />
                                ) : (
                                    <>&nbsp;</>
                                )}
                            </Text>
                            <Text variant="medium">
                                {tertiaryText ? (
                                    <Highlighter searchWords={[searchText]} textToHighlight={tertiaryText} highlightStyle={highlightStyle} autoEscape />
                                ) : (
                                    <>&nbsp;</>
                                )}
                            </Text>
                        </Stack>
                    </Stack>
                    <Stack className="tp-list-item-project-icon-container">
                        <IconButton
                            id={`detailicon-${item.id}`}
                            iconProps={{ iconName: 'Info' }}
                            className="tp-projects-details-icon"
                            onClick={onClickCallout}
                            title="View details"
                        />
                    </Stack>
                </Stack>
            </Stack>
        );
    },
);

const ProjectTypeIcon = ({ projectType }: { projectType?: string }) => {
    const iconName = useMemo(() => {
        switch (projectType) {
            case 'Administrative': {
                // return 'DocumentManagement';
                return 'CalendarSettings';
            }
            case 'Team': {
                // return 'TextDocumentShared';
                return 'Teamwork';
            }
            case 'Default':
            default: {
                // return 'ReportDocument';
                return 'IssueTracking';
            }
        }
    }, [projectType]);
    return (
        <Stack
            styles={{
                root: {
                    position: 'absolute',
                    right: 0,
                    bottom: -15,
                    backgroundColor: '#ececec',
                    width: 33,
                    height: 33,
                    // borderRadius: '50%',
                    border: '1px solid #fff',
                },
            }}
            horizontalAlign="center"
            verticalAlign="center"
        >
            <Icon iconName={iconName} styles={{ root: { fontSize: 20 } }} />
        </Stack>
    );
};
