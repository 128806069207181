export class NumberParser {

	private locale: string;
	private group;
	private decimal;
	private numeral;
	private index;
	private decimalSeperator: string;

	constructor(locale) {
		this.locale = locale;
		const parts = (new Intl.NumberFormat(locale) as any).formatToParts(12345.6);
		const numerals = [...new Intl.NumberFormat(locale, {useGrouping: false}).format(9876543210)].reverse();
		const index = new Map(numerals.map((d, i) => [d, i]));
		this.group = new RegExp(`[${parts.find(d => d.type === "group").value}]`, "g");
		this.decimalSeperator = parts.find(d => d.type === "decimal").value;
		this.decimal = new RegExp(`[${this.decimalSeperator}]`);
		this.numeral = new RegExp(`[${numerals.join("")}]`, "g");
		this.index = d => index.get(d);
	}

	public Parse = (str: string) : number => {
		const string = parseFormat(str.trim(), this.decimalSeperator);
		return (string
		.replace(this.group, "")
		.replace(this.decimal, ".")
		.replace(this.numeral, this.index)) ? Number(string) : 0;
	}

	public ParseToString = (num: number, decimals: number, useGrouping: boolean = true) : string => {
		if (num == null) return null;
		return num.toLocaleString(this.locale, { minimumFractionDigits: decimals, maximumFractionDigits: decimals, useGrouping: useGrouping })
	}
}

const COMMA = ",";
const DOT = ".";
const ALL_COMMAS = /,/g;
const ALL_DOTS = /\./g;
const ALL_COMMAS_AND_DOTS = /[,.]/g

export const parseFormat = (str: string, localeDecimalSeperator = ".") => {
	// numbers above a thousand with decimals
	if (str.includes(DOT) && str.includes(COMMA)) {
		const lastCommaPos = str.lastIndexOf(COMMA);
		const lastDotPos = str.lastIndexOf(DOT);
		if (lastCommaPos > lastDotPos) {
			// comma for decimal seperator
			const decimals = str.slice(lastCommaPos + 1);
			return str.slice(0, lastCommaPos).replace(ALL_COMMAS_AND_DOTS, "") + DOT + decimals
		} else {
			// dot for decimal seperator
			const decimals = str.slice(lastDotPos + 1);
			return str.slice(0, lastDotPos).replace(ALL_COMMAS_AND_DOTS, "") + DOT + decimals
		}
	} 

	// .123 is common in english speaking countries
	if (str[0] === DOT) {
		return "0" + str;
	}

	// ,123 is some people using
	if (str[0] === COMMA) {
		return "0" + str.replace(COMMA, DOT);
	}

	// 1,23 
	const commaMatches = [...str.matchAll(ALL_COMMAS)];
	if (commaMatches.length === 1) {
		// 3,500 could be "3500" or "3.5" fallback to the users locale settting, best guess YOLO
		if (str.length === 5 && str[1] === COMMA && localeDecimalSeperator === DOT) {
			return str.replace(COMMA, "");
		}
		return str.replace(COMMA, DOT)
	}

	// 1.23
	const dotMatches = [...str.matchAll(ALL_DOTS)]; 
	if (dotMatches.length === 1) {
		// 3.500 could be "3500" or "3.5" fallback to the users locale settting, best guess YOLO
		if (str.length === 5 && str[1] === DOT && localeDecimalSeperator === COMMA) {
			return str.replace(DOT, "");
		}
		return str;
	}

	// this is an integer, remove all thousand seperators regardless of , or .
	return str.replace(ALL_COMMAS_AND_DOTS, "")
}

export const defaultNumberParser = new NumberParser(navigator.language);

// 1,234,567.89
// 1.234.567,89
// 1234567.89
