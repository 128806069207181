/* eslint-disable react-refresh/only-export-components */
import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { PFDialog } from '../Common/PFDialog';
import { WithStore } from '../../Services/StateStore';
import { StoreProps } from '../../Services/StoreProps';
import { Plugin } from '../../Services/PluginInvoker';
import { PluginIDs } from '../../Utilities/PluginIDs';
import { TimelineResolution } from '../../Entities/TimelineResolution';
import { PFSpinner } from '../Common/Spinner';
import { ObjectEditor } from '../Common/ObjectEditor';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';
import { PFErrorBoundary } from '../Common/PFErrorBoundary';
import { MatchingActivityDto } from '../../Entities/Dto/MatchingActivityDto';
import { RowMenuItemInfo } from '../../Entities/Dto/RowMenuItemInfo';
import { language } from '../../Services/LocalizationService';

interface IProps extends StoreProps {
    Resolution: TimelineResolution;
    Start: Date;
    End: Date;
    RowMenuItemInfo: RowMenuItemInfo;
}

enum MatchType {
    Request = 0,
    Allocation = 1,
}

const MatchingActivityDialog: FunctionComponent<IProps> = props => {
    const [dto, setDto] = useState<MatchingActivityDto>();
    const [loading, setLoading] = useState(false);
    const matchType = useRef<MatchType>(null);
    const dates = useRef({ Start: props.Start, End: props.End }).current;

    useEffect(() => {
        if (!props.RowMenuItemInfo) return;
        matchType.current = props.RowMenuItemInfo.Properties.MatchType;
        SetupDto();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.RowMenuItemInfo]);

    const SetupDto = async (): Promise<void> => {
        const dto = new MatchingActivityDto();
        dto.FullAllocation =
            matchType.current === MatchType.Request
                ? OverviewContext.Settings.GridActionOptions?.CreateMatchingRequestDefaultTicked
                : OverviewContext.Settings.GridActionOptions?.MatchRequestDefaultTicked;
        dto.Start = dates.Start ?? props.Start;
        dto.End = dates.End ?? props.End;
        dto.Resolution = props.Resolution;
        dto.ContractId = props.RowMenuItemInfo?.ContractId;
        dto.ResourceId = props.RowMenuItemInfo?.ResourceId;
        setDto(dto);
    };

    const EnsureDtoValues = (): MatchingActivityDto => {
        const clone = { ...dto };
        clone.Start = props.Start;
        clone.End = props.End;
        clone.Resolution = props.Resolution;
        clone.ContractId = props.RowMenuItemInfo?.ContractId;
        clone.ResourceId = props.RowMenuItemInfo?.ResourceId;
        return clone;
    };

    const CreateMatchingActivity = async (pluginId: string): Promise<void> => {
        setLoading(true);
        const dtoObj = EnsureDtoValues();
        await Plugin.InvokeNoResponse(pluginId, dtoObj, language.MatchingActivityDialog.FailedCreatingMatchingActivity, true);
        setLoading(false);

        const emptyDto = new MatchingActivityDto();
        emptyDto.Start = dates.Start;
        emptyDto.End = dates.End;
        emptyDto.Resolution = props.Resolution;
        setDto(emptyDto);
        props.Store.Set({ ShowMatchingActivityDialog: false, MatchingActivityInfo: null });
        OverviewContext.RefreshProjectCapacity();
    };

    return (
        props.Store.Get(_ => _.ShowMatchingActivityDialog) && (
            <PFDialog
                title={language.MatchingActivityDialog.CreateMatchingActivity.replace(
                    '[[matchtype]]',
                    matchType.current === MatchType.Request ? 'request' : 'allocation',
                )}
                subText={
                    matchType.current === MatchType.Request
                        ? language.MatchingActivityDto.CreateMatchingRequest
                        : language.MatchingActivityDto.CreateMatchingAllocation
                }
                showDialog={props.Store.Get(_ => _.ShowMatchingActivityDialog)}
                maxWidth={800}
                callback={async () =>
                    await CreateMatchingActivity(matchType.current === MatchType.Request ? PluginIDs.CreateMatchingRequest : PluginIDs.CreateMatchingAllocation)
                }
                dismissCallback={() => {
                    props.Store.Set({ ShowMatchingActivityDialog: false, MatchingActivityInfo: null });
                    setLoading(false);
                    dto.Resolution = props.Resolution;
                    setDto(dto);
                }}
                okButtonEnabled={!loading}
            >
                <div className="tp-matchrequest-dialog-content">
                    {loading ? (
                        <PFSpinner CustomClass="justoverridedefault" />
                    ) : (
                        <PFErrorBoundary ComponentName="create matching activity dialog" Reset={SetupDto}>
                            <ObjectEditor
                                Item={dto}
                                ItemType={MatchingActivityDto}
                                ChangesCallback={(item: MatchingActivityDto, prop, value) => setDto(item)}
                                HiddenProperties={[]}
                                DisabledProperties={[]}
                            />
                        </PFErrorBoundary>
                    )}
                </div>
            </PFDialog>
        )
    );
};

export default WithStore<IProps>(MatchingActivityDialog);
