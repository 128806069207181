import { useFixedCellValue } from '../../../../../../../_grid/hooks/useFixedCellValue';

const styles: React.CSSProperties = { paddingRight: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end' };
export const PlaceholderCell = ({ value = 0 }: { value?: number }) => {
    const fixedValue = useFixedCellValue(value);
    return (
        <div
            // key={cell.id}
            // className="tp-capacity-cell"
            style={styles}
        >
            {fixedValue}
        </div>
    );
};
