/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useRef, useEffect } from 'react';
import { ObjectEx } from '../../Utilities/ObjectEx';
import { DelayedSearchBox } from './DelayedSearchBox';

interface IProps {
    Items: Array<any>;
    SearchFields: Array<string>;
    OnSearch: (filtered: Array<any>, searchText: string, initial?: boolean) => void;
    EnableTextHighlight?: boolean;
    HighLightElements?: () => Array<Element>;
    ClassName?: string;
    Delay?: number;
    Disabled?: boolean;
    SearchPlaceHolder?: string;
    CustomPropertySelector?: (item: any, field: string) => string;
    CustomSearchFunction?: (items: any[], searchValueParts: Array<string>) => Array<any>;
    FilterOnItemsChange?: boolean;
    MinCharsForSearch?: number;
    onInputFocus?: (event: any) => void;
}

export const Searcher: FunctionComponent<IProps> = props => {
    const searchText = useRef<string>('');
    const highlightInProgess = useRef<boolean>(false);

    useEffect(() => {
        if (props.FilterOnItemsChange && searchText.current) FilterItems(searchText.current);
    }, [props.Items]);

    useEffect(() => {
        FilterItems(searchText.current, true);
    }, [props.CustomSearchFunction]);

    useEffect(() => {
        const newValueParts = searchText.current.split(' ');
        HighlightSearchText(newValueParts);
    }, [props.HighLightElements]);

    const FilterItems = (value: string, initial?: boolean): void => {
        if (!props.Items || !props.Items.length) return;
        if (props.MinCharsForSearch != null && value.length !== 0 && value.length < props.MinCharsForSearch) return;
        const newValueParts = value.split(' ');

        let filteredItems = [];
        if (props.CustomSearchFunction) filteredItems = props.CustomSearchFunction([...props.Items], newValueParts);
        else {
            const propertyGetter = (item: any, field: string) =>
                props.CustomPropertySelector ? props.CustomPropertySelector(item, field) : ObjectEx.getNestedPropertyValueByString(item, field);
            filteredItems = props.Items.filter(item =>
                props.SearchFields.some(sf => newValueParts.every(part => new RegExp(`${part}`, 'i').test(propertyGetter(item, sf)))),
            );
        }

        if (!initial) props.OnSearch(filteredItems, value);
        if (props.EnableTextHighlight && props.HighLightElements) HighlightSearchText(newValueParts);
    };

    const HighlightSearchText = (searchTextParts: Array<string>): void => {
        if (!props.HighLightElements) return;
        if (highlightInProgess.current) return;
        highlightInProgess.current = true;
        // clear highlight on new search
        const highlightElements = props.HighLightElements();
        highlightElements.forEach(_ => (_.innerHTML = _.innerHTML.replace(/(<span class="tp-searcher-highlight">|<\/span>)/gim, '')));
        // set highlights
        highlightElements.forEach(_ => {
            let html = _.innerHTML;

            const tagsRegEx = new RegExp('(<.+?>|&\\w+;)');

            searchTextParts.forEach((searchText, idx) => {
                if (searchText.length < props.MinCharsForSearch || 0) return;
                const htmlParts = html.split(tagsRegEx).filter(Boolean);
                html = htmlParts
                    .map(item =>
                        tagsRegEx.test(item) ? item : item.replace(new RegExp(`(${searchText})`, 'ig'), `<span class="tp-searcher-highlight">$1</span>`),
                    )
                    .join('');
            });
            _.innerHTML = html;
        });
        highlightInProgess.current = false;
    };

    return (
        <DelayedSearchBox
            ClassName={`tp-panel-searchbox ${props.ClassName ? props.ClassName : ''}`}
            Delay={props.Delay}
            Disabled={props.Disabled}
            SearchPlaceHolder={props.SearchPlaceHolder}
            OnChange={text => {
                searchText.current = text;
                FilterItems(text);
            }}
            onFocus={props.onInputFocus}
        />
    );
};
