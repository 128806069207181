import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetDefaultRoute } from '../hooks/useGetDefaultRoute';

export const DefaultRoute = () => {
	const goTo = useNavigate();
	const { pathname } = useLocation();
	const defaultUserRoute = useGetDefaultRoute()

	const didMount = useRef(false);
	
	useEffect(() => {
		// only do this on first mount
		if (didMount.current)
			return;
		didMount.current = true;
		
		// it's a deeplink
		if (pathname !== "/") {
			return;
		}
		goTo(defaultUserRoute);
	});

	return null;
};
