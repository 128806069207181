import { DirectionalHint } from "@fluentui/react";
import React, { FunctionComponent } from "react";
import { language } from "../../Services/LocalizationService";
import { CoachmarkInfo } from "../CoachmarkInfo";
import { PFCoachmark } from "../PFCoachmark";


export const WorkPackagesCoachmarks: FunctionComponent<any> = React.memo(props => {
	const _createWorkPackage = new CoachmarkInfo("work-packages-create", ".workpackages-icons button", language.Coachmarks.WorkPackages.CreateWorkPackage, DirectionalHint.rightCenter);
	const _pickWorkPackage = new CoachmarkInfo("work-packages-picker", ".workpackages-selector", language.Coachmarks.WorkPackages.PickWorkPackage, DirectionalHint.rightCenter);
	const _includeWorkPackage = new CoachmarkInfo("work-packages-include", ".tp-workpackages-importcontainer", language.Coachmarks.WorkPackages.IncludeWorkPackage, DirectionalHint.leftBottomEdge);
	
	return	<>
				<PFCoachmark CoachmarkInfo={_createWorkPackage} />
				<PFCoachmark CoachmarkInfo={_pickWorkPackage} />
				<PFCoachmark CoachmarkInfo={_includeWorkPackage} />
			</>
})

export const WorkPackagesTotalCoachmark: FunctionComponent<any> = React.memo(props => {
	const _totalRowMenu = new CoachmarkInfo("work-packages-total-row-menu", ".tp-table-header-total", language.Coachmarks.WorkPackages.TotalWorkMenu, DirectionalHint.rightCenter);
	
	return	<>
				<PFCoachmark CoachmarkInfo={_totalRowMenu} />
			</>
})

export const WorkPackagesTimelineCoachmark: FunctionComponent<any> = React.memo(props => {
	const _workPackageTimeline = new CoachmarkInfo("work-packages-timeline-period", ".tp-workpackages-timeline tbody tr td:nth-child(20)", language.Coachmarks.WorkPackages.WorkPackageTimeline, DirectionalHint.bottomCenter);
	
	return	<>
				<PFCoachmark CoachmarkInfo={_workPackageTimeline} />
			</>
})