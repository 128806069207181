import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Stack, Spinner, PrimaryButton, DefaultButton, Label } from '@fluentui/react';
import { MOVE_CONTRACT_PROJECTS } from '../../context/network/http/QueryProvider/queryKeys';
import { PFSpinner } from '../Common/Spinner';
import { useUISettings } from '../../context/network/http/QueryProvider/queries/UISettings';
import { ApiCalls } from '../../api/api';
import { DropdownOptionEx } from '../../Utilities/DropdownOptionEx';
import { MoveContractDto, ProjectMinifiedWithStringVariants } from '../../api/generated/data-contracts';
import { language } from '../../Services/LocalizationService';
import { stringVariantsCamelCase } from '../../helpers/stringVariants';
import { GenericPicker, IPickerItem, IPickerOptions, IPickerSuggestionsOptions } from '../../forms/xxx-components-refactor/GenericPicker';
import { useStore } from '../../context/store';
import { OverviewContext } from '../../Utilities/Context/OverviewContext';

export const MoveContractDialog = ({ ContractId }: { ContractId: string }) => {
    const { data: uiSettings } = useUISettings();
    const { setBlockDialog, addErrorNotification, addSuccesNotification } = useStore(store => store);

    const [dto, setDto] = useState<MoveContractDto>({ contractId: ContractId, includeCustomProperties: [] });
    const { data: pickerOptions, isFetching } = usePickerOptions(uiSettings);

    const closeModal = useCallback(() => setBlockDialog(null), [setBlockDialog]);
    const { mutate, isLoading } = useContractMutation(dto, addErrorNotification, addSuccesNotification, closeModal);

    const customProperties = useMemo(() => DropdownOptionEx.toDropdownOptions(uiSettings.settings.contractCustomProperties, cp => cp.id, cp => cp.displayName), [uiSettings]);

    const onChangeCustomProperties = (_event: any, option: { selected: boolean; key: string; }) => {
        const updatedOptions = option.selected
            ? [...dto.includeCustomProperties, option.key]
            : dto.includeCustomProperties.filter(key => key !== option.key);

        setDto(prev => ({ ...prev, includeCustomProperties: updatedOptions }));
    };

    return (
        <Stack styles={{ root: { width: 450, minHeight: 400 } }} verticalAlign="space-between">
            <Stack>
                { isFetching 
					? <PFSpinner /> 
					: <MoveContractForm 
							setDto={setDto} 
							pickerOptions={pickerOptions} 
							customProperties={customProperties} 
							onChangeCustomProperties={onChangeCustomProperties}
						/> 
					}
            </Stack>
            <ActionButtons isLoading={isLoading} dto={dto} mutate={mutate} closeModal={closeModal} />
        </Stack>
    );
};

const MoveContractForm = ({ pickerOptions, customProperties, onChangeCustomProperties, setDto }) => {
    return (
        <>
            <Label>Target project</Label>
            <GenericPicker
				item={undefined}
                pickerOptions={pickerOptions}
                // pickerType={'tag'}
                update={proj => setDto(prev => ({ ...prev, projectId: proj as string }))}
            />
			{/* KEEP THIS AS IT MAY BE USED LATER ON (ewi) */}
            {/* {
                customProperties.length > 0 &&
                <Dropdown 
                    label='Custom properties to copy'
                    multiSelect
                    options={customProperties}
                    onChange={onChangeCustomProperties}
                />
            } */}
        </>
    );
};

const ActionButtons = ({ isLoading, dto, mutate, closeModal }) => {
    return (
        <Stack horizontal horizontalAlign="space-between" verticalAlign="end">
            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
                {isLoading && <Spinner label={'Moving contract...'} labelPosition="left" />}
                <Stack horizontal grow={1} tokens={{childrenGap: 10}}>
                    <PrimaryButton 
                        disabled={dto.contractId == null || dto.projectId == null} 
                        onClick={() => mutate()}
                    >
                        {language.Common.Save}
                    </PrimaryButton>
                    <DefaultButton onClick={closeModal}>{language.Common.Cancel}</DefaultButton>
                </Stack>
            </Stack>
        </Stack>
    );
};

const useContractMutation = (dto, addErrorNotification, addSuccesNotification, closeModal) => {
    return useMutation(
        async () => ApiCalls.moveContractToProject(dto).catch(res => {
 throw res.error; 
}),
        {
            onError: (err: any) => addErrorNotification(`Failed moving contract: ${err.message}`),
            onSuccess: () => {
                addSuccesNotification(`Successfully moved contract: ${dto.contractId}`);
				OverviewContext.RefreshProjectCapacity(); // i know this is shite! (ewi) looking forward to grid refactor!!
                closeModal();
            }
        }
    );
};

const usePickerOptions = (uiSettings) => {
    return useQuery([MOVE_CONTRACT_PROJECTS, uiSettings.settings.moveContractTargetProjectFilter], async () => {
        const response = await ApiCalls.moveContractEligibleProjects();
        const projects = response.data;

        return {
            entityGetter: async () => projects,
            itemParser: (item: ProjectMinifiedWithStringVariants) => ({
				...item,
                id: item.id,
                displayName: stringVariantsCamelCase.getAsStringValue(item, 'name'),
                description: stringVariantsCamelCase.getAsStringValue(item, uiSettings.settings.projectSelectorDescriptionField ?? 'owner')
            } as IPickerItem),
			extraSearchFields: uiSettings.settings.projectSelectorExtraSearchFields,
            recentItemsKey: 'projectpicker-recentlyused',
            multiSelect: false,
            placeHolder: 'Search for project..',
            suggestions: {
                loadingText: language.Common.Loading,
                noResultsFound: language.Common.NoResults,
                showRemoveButtons: true,
                suggestionsHeaderText: language.ProjectPicker.RecentlyUsedProjects,
                suggestionsContainerText: language.Common.Resources,
                suggestionsAvailableAlertText: language.ProjectPicker.RecentlyUsedProjects
            } as IPickerSuggestionsOptions
        } as IPickerOptions<ProjectMinifiedWithStringVariants>;
    });
};