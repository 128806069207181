import React, { useRef, useState, useCallback } from 'react';
import { PrimaryButton, Label } from '@fluentui/react';
import { language } from '../../../Services/LocalizationService';
import cloneDeep from 'lodash/cloneDeep';
import { CustomProperty } from '../../../api/generated/data-contracts';
import { fieldOverrider, FieldPickType, Form, FormProvider, IFormSchema } from 'spark-forms';
import { EntityPropertySelector } from '../../../components_new/EntityPropertySelector';
import { TPBuilder } from '../../../forms/_builders/new/TPBuilder';
import { FluentLabel } from 'spark-forms-fluent8';
import { CustomPropertyRender } from '../../../forms/customProperty/_components/CustomPropertyRender';
import { originHelper } from '../../../forms/_builders/new/helpers/origins/originHelper';

interface IProps<T> {
	template?: Partial<T>;
	entityType:  "resource" | "project" | "contract" | "other";
    schema: IFormSchema<T>;
    customProperties?: CustomProperty[];
    hiddenProperties?: string[];
    onChange: (templateItem: any, properties: Array<string>) => void;
    selectCallback: (enabled: boolean) => void;
}

export const BulkEditor = <T,>({ schema, customProperties, template, hiddenProperties, onChange, selectCallback, entityType }: React.PropsWithChildren<IProps<T>>): React.ReactElement | null => {

    const getHiddenProperties = useCallback(() => {
        // props that doesn't make sense to bulk edit
        //  TODO: move to hidden properties? as they only make sense for resource and project (ewi)
        const irrelevantResourceProps = [ 'isActive', 'azureId', 'resourceType', 'userPrincipalName', 'name', 'relatedGenericResourceId', 'specificWorkprofileId', 'teamProject' ];
        const irrelevantProjectProps = [ 'active', 'name', 'projectType', 'url', 'teamResource' ];
        // combine dissallowed props
		const disabledFields = originHelper.getDisallowedFields(entityType as any, true);
        let disallowedProps = disabledFields.concat(irrelevantResourceProps).concat(irrelevantProjectProps);
		if (hiddenProperties != null)
			disallowedProps = disallowedProps.concat(hiddenProperties);
        return disallowedProps;
    }, [entityType, hiddenProperties]);

    const [showEditor, setShowEditor] = useState<boolean>(false);
    const templateItem = useRef(cloneDeep(template ?? {}));
    const hiddenProps = useRef<Array<string>>(getHiddenProperties());
    const [selectedProps, setSelectedProps] = useState<Array<string>>([]);
	const [selectedFields, setSelectedFields] = useState<Array<string>>([]);
	const [selectedCustomProps, setSelectedCustomProps] = useState<Array<string>>([]);

    const selectedPropertyChanged = (items: Array<string>): void => {
        setShowEditor(false);
		
		const fields = items.filter(_ => !_.startsWith("customProperties"));
		const custom = items.filter(_ => _.startsWith("customProperties")).map(_ => _.match(/CustomProperties\|(.*?)\|/i)[1]);
		setSelectedFields(fields);
		setSelectedCustomProps(custom);
		setSelectedProps([...items]);
        onChange(templateItem.current, [...items]);
    };

    const change = (item: T) => {
		templateItem.current = {...item};
        onChange({...item}, selectedProps);
    };

	const changeCustom = (property: string, value: any) => {
        const clone = {...templateItem.current};
		clone["customProperties"][property] = value;
        onChange({...clone}, selectedProps);
    };

    return (
        <>
            <EntityPropertySelector
                schema={schema}
				customProperties={customProperties}
                value={null}
                placeHolder={language.BulkEditor.SelectPropertiesToEdit}
                propertyTypes={'both'}
                filterSystemProperties
                hiddenProperties={hiddenProps.current}
                onChange={(items: Array<string>) => selectedPropertyChanged(items)}
                styles={{ root: { width: '100%' } }}
                multiSelect
                disabled={false}
            />

            <div className="tp-grouping-content">
                <Label styles={{ root: { fontSize: '10px' } }}>{language.BulkEditor.AllSelectedPropertiesWillBeSetOnTheSelectedItems}</Label>
                <PrimaryButton
                    text={language.Common.Select}
                    onClick={() => {
                        setShowEditor(true);
                        selectCallback(true);
						templateItem.current = cloneDeep(template ?? {});
                    }}
                    disabled={showEditor || !selectedProps.length}
                />
                {showEditor && (
                    <>
						<FormProvider
							onStateChange={change}
							builder={TPBuilder}
							builderOptions={{ labelComponent: FluentLabel }}
							item={templateItem.current}
							schema={fieldOverrider.alterSchema<T>(cloneDeep(schema), {
								hiddenProps: {
									fields: selectedFields,
									pickType: FieldPickType.Only
								}
							})}
						>
							<Form />
						</FormProvider>
						{
							selectedCustomProps?.map(selected => {
								return <CustomPropertyRender
									disabled={false}
									value={ templateItem.current["customProperties"][selected] }
									onChange={(value: any) => changeCustom(selected, value)}
									property={customProperties.find(_ => _.id === selected)}
									entityType={entityType}
									parent={null}
									propertyName={null}
									onBlur={null}
									options={null}
									noValidation
								/>
							})
						}
                    </>
                )}
            </div>
        </>
    );
};
