import { DataService } from "../../Services/DataService";
import { PFPropertyBasicInfo } from "../../Utilities/PropertyInfo/BasicInfo";
import { PFPropertyOnChange } from "../../Utilities/PropertyInfo/OnChange";
import { PropertyType } from "../../Utilities/PropertyInfo/PropertyType";
import { PFPropertyRender } from "../../Utilities/PropertyInfo/Render";
import { EntityType } from "../EntityTypes";
import { Resource } from "../Main/Resource";
import { ResourceType } from "../ResourceType";

export class ResourceConstraints {
	@PFPropertyBasicInfo(PropertyType.Object, "_localization:AutoDistributionConfig.Resource", 1, "_localization:AutoDistributionConfig.ResourceDescription")
	@PFPropertyRender((item: ResourceConstraints, i, col) => item.ResourceName, { Types: [ResourceType.Named], PickerProperties: { PrimaryTextField: "Name", SecondaryTextField: "RBS"} }, "ResourcePicker")
	@PFPropertyOnChange(async (item: ResourceConstraints) => item.ResourceName = (await DataService.GetExpanded<Resource>(EntityType.Resource, item.ResourceId))?.Name )
	ResourceId: string;
	ResourceName: string;
	@PFPropertyBasicInfo(PropertyType.NumberSlider, "_localization:AutoDistributionConfig.Priority", 5, "_localization:AutoDistributionConfig.PriorityDescription")
	@PFPropertyRender(null, { Min: 0, Max: 10 })
	Priority: number | null;
	@PFPropertyBasicInfo(PropertyType.NumberSlider, "_localization:AutoDistributionConfig.MinimumFreeCapacity", 10, "_localization:AutoDistributionConfig.MinimumFreeCapacityDescription")
	@PFPropertyRender(null, { Min: 0, Max: 100, Format: (value: number) => `${value}%` })
	MinimumFreeCapacity: number | null;
	@PFPropertyBasicInfo(PropertyType.NumberSlider, "_localization:AutoDistributionConfig.MinimumUtilization", 15, "_localization:AutoDistributionConfig.MinimumUtilizationDescription")
	@PFPropertyRender(null, { Min: 0, Max: 100, Format: (value: number) => `${value}%` })
	MinimumUtilization: number | null;
	@PFPropertyBasicInfo(PropertyType.NumberSlider, "_localization:AutoDistributionConfig.MaximumUtilization", 20, "_localization:AutoDistributionConfig.MaximumUtilizationDescription")
	@PFPropertyRender(null, { Min: 0, Max: 100, Format: (value: number) => `${value}%` })
	MaximumUtilization: number | null;
	@PFPropertyBasicInfo(PropertyType.Number, "_localization:AutoDistributionConfig.MaxHoursTotal", 22)
	MaximumTotalHours: number;
	@PFPropertyBasicInfo(PropertyType.Boolean, "_localization:AutoDistributionConfig.Exclude", 25, "_localization:AutoDistributionConfig.ExcludeDescription")
	Exclude: boolean;
}