const ids = new Set<string>();
const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function makeId(length = 5): string {
    let text = '';
    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    if (ids.has(text)) {
        return makeId();
    }

    ids.add(text);

    return text;
}

export const IDs = {
    makeId,
};
