import { useState } from 'react';
import { createContext } from '../../../hooks/createContext';
import { useSignalR, useSignalRReturns } from '../../../hooks/useSignalR';
import { HubLogger } from '../../../Logging/HubLogger';
import WaitingDto from '../../../Services/Hubs/Models/WaitingDto';

export type TWaitingHubContext = useSignalRReturns<WaitingDto[]> & { done: boolean; failed: boolean, dtos: WaitingDto[] };

const [useCtx, Provider] = createContext<TWaitingHubContext>();

// eslint-disable-next-line react-refresh/only-export-components
export const useWaitingHubContext = useCtx;

const logger = new HubLogger();

export const WaitingHubContext: React.FC = ({ children }) => {
    const [done, setDone] = useState(false);
    const [failed, setFailed] = useState(false);
	const [dtos, setDtos] = useState<WaitingDto[]>([]);

    const signalR = useSignalR<WaitingDto[]>({
        eventName: 'status',
        onMessage: responseDtos => {
			
			// done loading cache?
			if (responseDtos.length > 0 && responseDtos[0].status === "Done") {
				setDone(true);
            } else {
				// update status
				setDtos(responseDtos);
				// one or more cache objects have failed
				if (responseDtos.some(_ => _.status === 'Failed')) {
					setFailed(true);
				}
			}
        },
        url: url => `${url}/hubs/waiting`,
        onStart: async () => {
			console.info("Listening for cache updates..");
        },
        logger,
    });

    return <Provider value={{ ...signalR, failed, done, dtos }}>{children}</Provider>;
};
